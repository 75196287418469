import Vue from 'vue';
import utils from '../../../../Shared/utils.jsx';
import formAnyOf from './anyof.jsx';
import methods from '../../../../Shared/methods';

Vue.component('form-array-dsgn', {
    data: function () {
        return {
        }
    },
    created() {
    },
    computed: {
        ...utils.forms.computed,
        selected$: function () {
            return this.Root.SelectedNode && this.Root.SelectedNode == this;
        },
    },
    methods: {
        ...methods,
        handleClick(e) {
            this.Root.SelectNode(this);
            e.cancelBubble = true;
            e.stopPropagation();
        },
    },
    props: {
        name: '',
        schemakey: '',
        root: null,
        designmodel: null,
        schema: null,
        cmodel: null,
        extra: null,
        readonly: false,
        child: 0,
    },
    render(h) {
        const schema = utils.schema.resolve_Of(this.schema.items);
        const name = <span style={{ marginLeft: (this.child * 15) + "px", whiteSpace: "nowrap" }}>{this.name}</span>;

        const input = [];
        const cmodel = this.cmodel[this.schemakey] || [];

        for (let i = 0; i < cmodel.length; i++)
        {
            const item = cmodel[i];
            let Tag;
            switch (schema.type) {
                case 'string':
                    if (('anyOf' in schema))
                        Tag = 'form-anyof-dsgn';
                    else if (('enum' in schema) || ('titleMap' in schema))
                        Tag = 'form-select-dsgn';
                    else
                        Tag = 'form-input-dsgn';
                    break;

                case 'object':
                    Tag = 'property-grid-dsgn';
                    break;

                default:
                    continue;
            }

            input.push(
                <div>
                    <span>
                        <Tag
                            name={'[' + i + ']'}
                            schemakey={i}
                            schema={schema}
                            root={this.root}
                            cmodel={cmodel}
                            child={0}
                            readonly={schema.readonly || false}>
                        </Tag>
                        <button disabled>^</button>
                        <button disabled>v</button>
                        <button disabled>x</button>
                    </span>
                </div>
            );
        }

        input.push(
            <button disabled>Add Another</button>
        );

        return (
            <tr>
                <td style={{ width: "1px", "white-space": "nowrap", verticalAlign: "top" }}>
                    <div
                        class={{ 'designer-form': true }}
                        on-click={(e) => this.handleClick(e)}>
                        <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
                        {name}
                    </div>
                </td>
                <td style={{ backgroundColor: "silver" }}>
                    <div
                        class={{ 'designer-form': true }}
                        on-click={(e) => this.handleClick(e)}>
                        <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
                        {input}
                    </div>
                </td>
            </tr>
        );
    }
});