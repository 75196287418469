import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import BaseComponent from '../BaseComponentMixin.jsx';
import EventBus from '../../event-bus.js';
import api from '@/Services/api';
import careHelpfulFunctions from '../../careHelpfulFunctions.jsx';
import careService from '@/Services/careService.jsx';
import { PlainWrapper, ObjProxy } from '@/Shared/helperClasses';

/*
 * The purpose of this component is to allow an HSM to include a referenced user control HSM without
 * causing the parameters to be compiled into a proxy. The proxy is used for visual controls to insure
 * reactivity is associated with the visual component referencing elements of parameter objects. But
 * a non-visual HSM doesn't need this capability - all parameters should be immediately resolved and
 * the Input comes from the event that instigated the HSMADDChildHSM action, which was not easy using
 * the standard dynamic-user-control.
 * 
 * */

Vue.component('dynamic-user-control-hsm', {
    mixins: [BaseComponent],
    data: function () {
        return {
            key: null,
            control: {},
            headers: {},
            //controldataexpr: null,
            controlurlexpr: null,
            newscopeitems: {},
            uselocalscope: false,

            showMenu: false,
            x: 0,
            y: 0,

            dynamicData: false,
        }
    },
    created() {
        this.key = utils.generateUUID();

        if (this.controlData) {
            this.controldataexpr = this.utils.compileObject(this, this.controlData);

            // Intentionally not putting this in the data property - keeps it from getting modified by Vue (which it would do to make it reactive)
            this.controldatavalue = this.utils.evaluateObject(this.controldataexpr, this.inputcontext || this);
        }

        if (this.controlURL)
            this.controlurlexpr = this.utils.compile(this, this.controlURL);
    },
    destroyed() {
    },  
    async mounted() {
        await this.Refresh();
    },
    props: {
        controlURL: '',
        controlName: '',
        payload: null,
        controlEvents: null,
        inputcontext: null,
    },
    computed: {
        controlURLvalue: function () {
            if (this.controlurlexpr)
                return utils.evaluate(this.controlurlexpr, this.inputcontext || this);
            else
                return '';
        },
        controlDataValue: function () {
            return this.controldatavalue;
        },
        sizeStyle() {
            return { display: 'flex', flexDirection: 'column', flexGrow: '1', overflow: 'auto', position: 'relative', alignSelf: 'normal' };
        },
        Input: function () {
            return this.inputcontext.input;
        },
    },
    methods: {
        async refreshchildren(e) {
            utils.api.clearCache();
            await this.Refresh();
            this.key = utils.generateUUID();
        },
        async Refresh()
        {
            if (this.controlURL) {
                try {
                    //utils.debug(`UserControl loading from URL: ${this.controlURLvalue}`);
                    //utils.api.clearCache();

                    let apiRequest = {
                        method: 'GET',
                        url: `Document/UserControl/${this.controlURLvalue}`,
                        doNotUseWebsocket: true,
                        flatten: false,
                        cache: this.cacheControl || false
                    };
         
                    let c = await api.apiRequest(apiRequest);
                    let responseControl = c.data?.Result || c.data;
                    if (!responseControl) {
                        apiRequest.url = `Document/UserControl/public/${this.controlURLvalue}`;
                        c = await await api.apiRequest(apiRequest);
                        responseControl = c.data?.Result || c.data;
                    }

                    this.control = responseControl;
                    this.headers = c.headers;
                }
                catch (e) {
                    utils.warn(`UserControlHSM ${this.controlURL} failed to load`, e);
                    this.control = {};
                }
            }
            else
                this.control = {};

            if (this.controlEvents && this.controlEvents.OnControlLoaded)
                await utils.executeAndCompileAllActions(this.controlEvents.OnControlLoaded, null, this);
        },
        showContextMenu(e) {
            e.preventDefault()
            this.showMenu = false
            this.x = e.clientX
            this.y = e.clientY
            this.$nextTick(() => {
                this.showMenu = true
            })
        },
        openForLocalEdit(e, fn) {
            e.preventDefault();
            utils.openForLocalEdit({ Type: 'UserControl', Name: fn });
        },
        openForExternalEdit(e, fn, controlOwnerId, shared) {
            e.preventDefault();
            utils.OpenEditorForExternalEdit({ Type: 'UserControl', Name: fn, OwnerId: controlOwnerId }, shared);
        },
        openDocumentStandAlone(e, fn) {
            e.preventDefault();
            utils.OpenDocumentStandAlone({ Name: fn });
        },
    },
    render(h) {
        if (!this.control.ControlData)
            return (
                <transition name="component-fade" mode={document.hasFocus() ? 'out-in' : null}>
                    <div domPropsInnerHTML="<!-- No control -->">
                    </div>
                </transition>
            );

        let DynamicControl = utils.getDynamicComponent(h, this.control);

        if (!DynamicControl)
            DynamicControl = 'default-unknown';

        let debug;
        if (this.isdebug)
            debug = {
                controlURLvalue: this.controlURLvalue,
                controlCustomerId: this.headers?.customerid,
                refreshchildren: this.refreshchildren,
                openForLocalEdit: this.openForLocalEdit,
                openForExternalEdit: this.openForExternalEdit,
                openDocumentStandAlone: this.openDocumentStandAlone,
            };

        return (
            <transition name="component-fade" mode={document.hasFocus() ? 'out-in' : null}>
                <DynamicControl
                    class={{ 'c-UserControlHSM': true, 
                            [`c-name-${this.name || 'unnamed'}`]: true}}
                    key={this.key}
                    on={{ 'finished-render': (reference) => this.finishRenderHandler(reference) }}
                    type={this.control.ControlType}
                    name={this.control.ControlData.Name}
                    root={this.root}
                    asbase={this.asbase}
                    parentType={this.parentType}
                    controlData={this.control.ControlData}
                    paramData={this.controlDataValue}
                    payload={this.payload}
                    on={this.$listeners}
                    debug={debug}
                    scopeitems={this.newscopeitems}
                    controlscope={this.controldatavalue}
                    cacheControl={this.control.CacheControl}
                    controlEvents={this.control.Events}
                >
                </DynamicControl>
            </transition>
        );
    }
});

