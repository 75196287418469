import Vue from 'vue';
import utils from '../../../../Shared/utils.jsx';
import methods from '../../../../Shared/methods';

Vue.component('form-help-dsgn', {
    data: function () {
        return {
        }
    },
    created() {
    },
    computed: {
        ...utils.forms.computed,
        selected$: function () {
            return this.Root.SelectedNode && this.Root.SelectedNode == this;
        },
    },
    methods: {
        ...methods,
        handleClick(e) {
            this.Root.SelectNode(this);
            e.cancelBubble = true;
            e.stopPropagation();
        },
        sync(value) {
            this.schema.body = value;
        }
    },
    props: {
        name: '',
        schemakey: '',
        root: null,
        designmodel: null,
        schema: null,
        cmodel: null,
        extra: null,
        readonly: false,
        child: 0,
    },
    render(h) {
        const style = {
            margin: "5px",
            padding: "5px",
            borderColor: "green",
            borderStyle: "solid",
            borderWidth: "2px",
            borderRadius: "5px",
            whiteSpace: "normal",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
        };

        let content;
        if (this.selected$)
            content = <textarea on-input={(e) => this.sync(e.target.value)}>{this.schema.body}</textarea>;
        else
            content = this.schema.body;

        return (
            <tr>
                <td colspan="2" style={{ width: "90%", verticalAlign: "middle" }}>
                    <div
                        class={{ 'designer-form': true }}
                        style={style}
                        on-click={(e) => this.handleClick(e)}>
                        <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
                        {content}
                    </div>
                </td>
            </tr>
        );
    }
});