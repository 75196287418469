import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import EventBus from '../event-bus.js';

import utils from '../../Shared/utils.jsx';
import methods from '../../Shared/methods';

Vue.component('javascript', {
    mixins: [BaseComponent],
    data: function () {
        return {
            scopeEval: null,
        }
    },
    //Mounted Replaced with preRenderComplete
    computed: {
        scope() {
            try {
                if(this.scopeEval === null && this.controlData.Scope)
                    this.scopeEval = utils.compile(this, this.controlData.Scope);
                if(this.scopeEval)
                    return utils.evaluate(this.scopeEval, this);
                return '';
            }
            catch (e) {
                utils.warn('Scope could not evaluate expression: ' + this.controlData.Scope + '; ' + e);
                return '#444';
            }
        }
    },
    methods: {
        preRenderComplete() {
            if(this.scope === 'Global')
                this.Root.addJavascript(this, this.controlData.GlobalJavascript)
            this.finishRenderHandler(this);
        },
    },
    props: {
    },
    render(h) {
        return null;
    }
});