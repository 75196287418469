import Vue from 'vue';
import BaseComponent from './baseFormMixin.jsx';
import utils from '../../../Shared/utils.jsx';
import careHelpfulFunctions from '../../careHelpfulFunctions.jsx';

const baseFieldsetMixin = {
    mixins: [BaseComponent],
    data: () => ({
    }),
    props: {
    },
    methods: {
        usesColspan(type) {
            switch (type) {
                case 'fieldset':
                case 'conditional':
                case 'tabs':
                case 'help':
                case 'paragraph':
                    return true;
                default:
                    return false;
            }
        },
        generateElements(h, form, LayoutType) {
            const elements = [];
            for (let i = 0; i < form.length; i++) {
                const element = form[i];

                // Nice idea, but the current component does not have correct values for ParentModel (and potentially others) because "this"
                // refers to the fieldset, not the element. To marshal the "this" properties into the condition function that apply to the
                // element will require additional thought. (6/13/2021)
                //if (element.$$condition && typeof element.$$condition === 'function' && !element.$$condition(this, careHelpfulFunctions))
                //    continue;

                //if (!element.type)
                //    debugger;

                let elementType = (element.type || 'missing').toLowerCase();
                let formtype = `sform-${elementType}`;

                let modelkey;

                if (element.key) {
                    modelkey = [...element.key];

                    if (this.modelkey && this.modelkey.length > 0)
                        for (let j = 0; j < this.modelkey.length && j < modelkey.length; j++)
                            modelkey[j] = this.modelkey[j];
                }
                else
                    modelkey = this.modelkey;

                if (!element.$objectId)
                    element.$objectId = utils.generateUUID();

                // name={element.title || (element.key ? element.key[element.key.length - 1] : 'unnamed')}
                elements.push(
                    <LayoutType
                        class="sform-fieldset-group-item"
                        key={i + '_' + element.$objectId}
                        formtype={formtype}
                        colspan={this.usesColspan(element.type)}
                        xxname={this.getElementName(element)}
                        root={this.root}
                        element={element}
                        form={element.items}
                        cmodel={this.cmodel}
                        modelkey={modelkey}
                        depth={this.depth + 1}
                        fieldset_index={i}
                        autofocus={this.depth === 0 && i === 0}
                        layouttype={LayoutType}
                        appearance={this.appearance}
                        type="FormField"
                        nopadding={true}
                        noinput={this.noinput}
                        readonly={element.readonly || false}
                        schema={this.schema}
                        scopeitems={this.scopeitems}
                        controlscope={this.controlscope}
                    >
                    </LayoutType>
                );
            }
            return elements;
        }
    },
};

export default baseFieldsetMixin;