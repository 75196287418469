<template>
    <div style="display: inline-block; max-width: 400px;">
        <v-card-text>{{ label }}</v-card-text>
        <v-select v-model="choices"
                  class="ml-3 mr-3"
                  clearable
                  deletable-chips
                  multiple
                  outlined
                  dense
                  chips
                  v-on:change="updateFilter()"
                  :item-text="getItemText"
                  :items="items"
                  :menu-props="menuProps">
        </v-select>
    </div>
</template>

<script>
    export default {
        name: 'DropdownFilter',
        data: function () {
            return {
                choices: [],
                items: [],

                getItemText: (item) => item.text || '- No Value -',
                menuProps: {
                    offsetY: true,
                    contentClass: 'ag-custom-component-popup',
                },
            }
        },
        mounted() {
            const { api, colDef, column, columnApi, context } = this.params;

            const uniqueValues = {};
            this.params.api.forEachNode((node) => {
                const value = this.params.valueGetter({
                    api,
                    colDef,
                    column,
                    columnApi,
                    context,
                    data: node.data,
                    getValue: (field) => node.data[field],
                    node,
                });

                if (value !== undefined || value !== null)
                    uniqueValues[value] = true;
            });

            this.items = Object.keys(uniqueValues).map(k => ({ text: k, value: k }));
        },
        computed: {
            label: function () {
                return this.params.label || 'Select Filter Values:';
            },
        },
        methods: {
            updateFilter() {
                this.params.filterChangedCallback();
            },

            // The following are used by ag-grid //
            doesFilterPass(params) {
                const { node } = params;

                const value = this.params.valueGetter({
                    node,
                    data: node.data,
                    getValue: (field) => node.data[field],
                });

                return this.choices.length == 0 || this.choices.includes(value);
            },
            isFilterActive() {
                return this.choices.length > 0;
            },
            getModel() {
                if (this.active)
                    return { choices: this.choices };
            },
            setModel(model) {
                if (!model) {
                    this.choices = [];
                }
                else {
                    this.choices = model.choices;
                }
            },
        }
    }
</script>

<style scoped>

</style>