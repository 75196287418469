import Vue from 'vue';
import Vuetify from 'vuetify';
import utils from '../../../Shared/utils.jsx';
import EventBus from '../../event-bus.js';

Vue.component('display-notification-header', {
    data: () => ({
        content: null,
        isvisible: false,
        groups: {},
    }),
    props: {
    },
    created() {
        EventBus.$on('Action-DisplayNotificationHeader', this.performDisplayNotificationHeader);
        EventBus.$on('Action-RemoveNotificationHeader', this.performRemoveNotificationHeader);
    },
    destroyed() {
        EventBus.$off('Action-DisplayNotificationHeader', this.performDisplayNotificationHeader);
        EventBus.$off('Action-RemoveNotificationHeader', this.performRemoveNotificationHeader);
    },
    mounted() {
    },
    computed: {
        utils: function () {
            return utils;
        },
        Base: function () {
            return this;
        },
        GlobalVars: function () {
            return utils.global_variables;
        },
        ServiceVars: function () {
            return utils.service_variables;
        },
        System: function () {
            return {
                LocalStorage: function (key, value) {
                    return '';
                }
            };
        },
        Name: function () {
            return this.controlData ? (this.controlData.Name || 'unnamed') : 'no control data';
        },
        Root: function () {
            return this.root ? this.root._self : this;
        },
        Parent: function () {
            let p = this.$parent;

            // Look for a parent object that is a CallCorp control (skip native vue controls)
            while (p && !p.root) {
                if (p.$attrs && 'c_context' in p.$attrs)
                    p = p.$attrs.c_context;
                else
                    p = p.$parent;
            }

            return p;
        },
        Vars: function () {
            return this.vars;
        },
    },
    methods: {
        async performDisplayNotificationHeader(action) {
            utils.log(`DisplayNotificationHeader action handler`);

            if (action.ActionData && action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            try {
                const c = action.ActionData;
                if (!c.ControlData || !c.ControlData.Controls)
                    return

                const name = (action.groupexpn ? utils.evaluate(action.groupexpn, action.context) : '') || '__unnamed__';

                if (c.ControlData.Controls.length === 0) {
                    if (name in this.groups)
                        Vue.delete(this.groups, name);

                    this.isvisible = Object.keys(this.groups).length > 0;
                }
                else {
                    const id = utils.generateUUID();
                    const content = (
                        <stack-vertical
                            style="background-color: #dc3545; color: white; font-size: larger; text-align: center;"
                            key={id}
                            type="VerticalStack"
                            c_context={action.context}
                            name={c.ControlData ? c.ControlData.Name : ''}
                            root={action.context.root}
                            parentType=""
                            controlData={c.ControlData}
                            controlURL={c.ControlURL}>
                        </stack-vertical>
                    );
                    Vue.set(this.groups, name, content);
                    this.isvisible = true;
                }

                try {
                    await utils.success(action);
                } catch (e) { }
            }
            catch (e) {
                try {
                    await utils.failure(action, { Data: e });
                } catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
        async performRemoveNotificationHeader(action) {
            utils.log(`RemoveNotificationHeader action handler`);

            if (action.ActionData && action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            try {
                const name = (action.groupexpn ? utils.evaluate(action.groupexpn, action.context) : '') || '__unnamed__';

                if (name in this.groups)
                    Vue.delete(this.groups, name);

                this.isvisible = Object.keys(this.groups).length > 0;

                try {
                    await utils.success(action);
                } catch (e) { }
            }
            catch (e) {
                try {
                    await utils.failure(action, { Data: e });
                } catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
    },
    render(h) {
        if (this.isvisible) {
            return (
                <div>
                    {Object.keys(this.groups).map(k => this.groups[k])}
                </div>
            );
        }
        else
            return null;

        //return (
        //    <v-snackbar value={this.isvisible} timeout={-1} multi-line top light color="error">
        //        {this.content}
        //    </v-snackbar>
        //);
    }
});