import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import EventBus from '../event-bus.js';

import utils from '../../Shared/utils.jsx';
import methods from '../../Shared/methods';

Vue.component('search', {
    mixins: [BaseComponent],
    data: function () {
        return {
            Input: {
                SearchText: ''
            },
            debounceDelayEval: null,
            fontSizeEval: null,
            colorEval: null,
            cssClassEval: null,
            typingTimer: null
        }
    },
    computed: {
        debounceDelay(){
            if(this.debounceDelayEval === null && this.controlData.DebounceDelay)
                this.debounceDelayEval = utils.compile(this, this.controlData.DebounceDelay);
            if(this.debounceDelayEval)
                return utils.evaluate(this.debounceDelayEval, this);
            return '';
        },
        fontSize() {
            if(this.fontSizeEval === null && this.controlData.FontSize)
                this.fontSizeEval = utils.compile(this, this.controlData.FontSize);
            if(this.fontSizeEval)
                return utils.resolveFontSize(utils.evaluate(this.fontSizeEval, this));
            return '';
        },
        color() {
            if(this.colorEval === null && this.controlData.Color)
                this.colorEval = utils.compile(this, this.controlData.Color);
            if(this.colorEval)
                return utils.evaluate(this.colorEval, this);
            return '';
        },
        cssClass() {
            if(this.cssClassEval === null && this.controlData.CssClass)
                this.cssClassEval = utils.compile(this, this.controlData.CssClass);
            if(this.cssClassEval)
                return utils.evaluate(this.cssClassEval, this);
            return '';
        },
    },
    //Mounted Replaced with preRenderComplete
    methods: {
        preRenderComplete() {
            this.finishRenderHandler(this);
        },
        search() {
            if (this.typingTimer != null)
                clearTimeout(this.typingTimer); // clear any original timer that may still be active.

            // this will wait for .5 second for any additional typing (or additional async lookups to finish) so we don't fire the filter too quickly
            this.typingTimer = setTimeout(() =>
            {
                utils.executeAndCompileAllActions(this.controlData.SearchAction || this.controlData.SearchActions, this.Input, this);
            }, this.controlData.DebounceDelay || 500);
        }
    },
    props: {
    },
    render(h) {
        if(!this.todisplay)
            return null;
        
        try {
            return (
                <div
                    class={{ 'c-Search': true, [`c-name-${this.name || 'unnamed'}`]: true }}
                    v-show={this.isvisible} class="ma-1" style={this.sizeStyle}>
                    <v-text-field
                        on-input={(e) => this.search(e)}
                        style={{'font-size': utils.resolve}}
                        hide-details
                        dense
                        outlined
                        prepend-inner-icon="mdi-magnify"
                        vModel={this.Input.SearchText}
                    ></v-text-field>
                </div>
            );
        }
        catch (e) {
            utils.error('Search Render failed', e);
            return <div>Search Failed to Render {e}</div>;
        }
    }
});