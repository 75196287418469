import Vue from "vue";
import utils from "../../Shared/utils.jsx";
import BaseComponent from "./BaseComponentMixin.jsx";
import { appSettings } from "../../Shared/appSettings.js";

Vue.component("apple-button", {
    mixins: [BaseComponent],
    data: function() {
        return {};
    },
    computed: {
        buttonText() {
            return this.Translate("Sign in with Apple");
        },
        styles() {
            return {
                ...this.sizeStyle,
                ...utils.resolveStyleHints(this.styleHints, this),
            };
        },
    },

    //Created replaced with preRenderComplete
    methods: {
        async preRenderComplete() {
            this.startAppleApp();

            this.finishRenderHandler(this);
        },
        startAppleApp() {
            const appleId = appSettings.WhiteLabel.AppleSigninId;

            if (!appleId) return;

            AppleID.auth.init({
                clientId: appleId,
                scope: "email",
                redirectURI: window.location.origin,
                usePopup: true,
            });
        },
        async handleClick() {
            try {
                const result = await AppleID.auth.signIn({
                    clientId: appSettings.WhiteLabel.AppleSigninId,
                    scope: "email",
                    redirectURI: window.location.origin,
                    //state: '[STATE]',
                    //nonce: '[NONCE]',
                    usePopup: true,
                });

                const authArgs = {
                    ProviderType: "Apple",
                    token: result.authorization.id_token, // JWT (JSON Web Token)
                    clientId: appSettings.WhiteLabel.AppleSigninId,
                };

                await this.runActions(authArgs);
            } catch (err) {}
        },
        async runActions(authArgs) {
            await utils.executeAndCompileAllActions(this.controlData.Actions, authArgs, this);
        },
    },
    render(h) {
        try {
            if (!this.todisplay) return null;

            return (
                <div
                    style={this.styles}
                    class={{ "c-AppleButton d-flex": true, [`c-name-${this.name || "unnamed"}`]: true }}
                >
                    <v-btn elevation={0} on-click={this.handleClick} depressed block>
                        <div class="d-flex align-center ml-8" style="width: 100%">
                            <v-icon class="mr-4">mdi-apple</v-icon>
                            {this.buttonText}
                        </div>
                    </v-btn>
                </div>
            );
        } catch (e) {
            utils.error("AppleButton Render failed", e);
            return <div>AppleButton Failed to Render {e}</div>;
        }
    },
});
