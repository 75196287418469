import Vue from 'vue';
import BaseComponent from './baseFormMixin.jsx';
import utils from '../../../Shared/utils.jsx';

Vue.component('sform-missing', {
    mixins: [BaseComponent],
    data: () => ({
    }),
    props: {
    },
    created() {
    },
    mounted() {
        this.loadComplete();
    },
    computed: {
    },
    methods: {
    },
    render(h) {
        const style = {
            backgroundColor: 'pink',
        };
        return <span style={style}>Unknown field: {this.element?.key?.join('.') || 'No key'}</span>;
    }
});
