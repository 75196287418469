const regionCodeList = [
    { Name: "Afghanistan", RegionCode: "AF" },
    { Name: "Aland Islands", RegionCode: "AX" },
    { Name: "Albania", RegionCode: "AL" },
    { Name: "Algeria", RegionCode: "DZ" },
    { Name: "American Samoa", RegionCode: "AS" },
    { Name: "Andorra", RegionCode: "AD" },
    { Name: "Angola", RegionCode: "AO" },
    { Name: "Anguilla", RegionCode: "AI" },
    { Name: "Antarctica", RegionCode: "AQ" },
    { Name: "Antigua and Barbuda", RegionCode: "AG" },
    { Name: "Argentina", RegionCode: "AR" },
    { Name: "Armenia", RegionCode: "AM" },
    { Name: "Aruba", RegionCode: "AW" },
    { Name: "Ascension Island", RegionCode: "AC" },
    { Name: "Australia", RegionCode: "AU" },
    { Name: "Austria", RegionCode: "AT" },
    { Name: "Azerbaijan", RegionCode: "AZ" },
    { Name: "Bahamas", RegionCode: "BS" },
    { Name: "Bahrain", RegionCode: "BH" },
    { Name: "Bangladesh", RegionCode: "BD" },
    { Name: "Barbados", RegionCode: "BB" },
    { Name: "Belarus", RegionCode: "BY" },
    { Name: "Belgium", RegionCode: "BE" },
    { Name: "Belize", RegionCode: "BZ" },
    { Name: "Benin", RegionCode: "BJ" },
    { Name: "Bermuda", RegionCode: "BM" },
    { Name: "Bhutan", RegionCode: "BT" },
    { Name: "Bolivia", RegionCode: "BO" },
    { Name: "Bonaire, Sint Eustatius and Saba", RegionCode: "BQ" },
    { Name: "Bosnia and Herzegovina", RegionCode: "BA" },
    { Name: "Botswana", RegionCode: "BW" },
    { Name: "Brazil", RegionCode: "BR" },
    { Name: "British Indian Ocean Territory", RegionCode: "IO" },
    { Name: "British Virgin Islands", RegionCode: "VG" },
    { Name: "Brunei", RegionCode: "BN" },
    { Name: "Bulgaria", RegionCode: "BG" },
    { Name: "Burkina Faso", RegionCode: "BF" },
    { Name: "Burundi", RegionCode: "BI" },
    { Name: "Cambodia", RegionCode: "KH" },
    { Name: "Cameroon", RegionCode: "CM" },
    { Name: "Canada", RegionCode: "CA" },
    { Name: "Cape Verde", RegionCode: "CV" },
    { Name: "Cayman Islands", RegionCode: "KY" },
    { Name: "Central African Republic", RegionCode: "CF" },
    { Name: "Chad", RegionCode: "TD" },
    { Name: "Chile", RegionCode: "CL" },
    { Name: "China", RegionCode: "CN" },
    { Name: "Christmas Island", RegionCode: "CX" },
    { Name: "Cocos Islands", RegionCode: "CC" },
    { Name: "Colombia", RegionCode: "CO" },
    { Name: "Comoros", RegionCode: "KM" },
    { Name: "Cook Islands", RegionCode: "CK" },
    { Name: "Costa Rica", RegionCode: "CR" },
    { Name: "Croatia", RegionCode: "HR" },
    { Name: "Cuba", RegionCode: "CU" },
    { Name: "Curacao", RegionCode: "CW" },
    { Name: "Cyprus", RegionCode: "CY" },
    { Name: "Czech Republic", RegionCode: "CZ" },
    { Name: "Democratic Republic of the Congo", RegionCode: "CD" },
    { Name: "Denmark", RegionCode: "DK" },
    { Name: "Djibouti", RegionCode: "DJ" },
    { Name: "Dominica", RegionCode: "DM" },
    { Name: "Dominican Republic", RegionCode: "DO" },
    { Name: "East Timor", RegionCode: "TL" },
    { Name: "Ecuador", RegionCode: "EC" },
    { Name: "Egypt", RegionCode: "EG" },
    { Name: "El Salvador", RegionCode: "SV" },
    { Name: "Equatorial Guinea", RegionCode: "GQ" },
    { Name: "Eritrea", RegionCode: "ER" },
    { Name: "Estonia", RegionCode: "EE" },
    { Name: "Ethiopia", RegionCode: "ET" },
    { Name: "Falkland Islands", RegionCode: "FK" },
    { Name: "Faroe Islands", RegionCode: "FO" },
    { Name: "Fiji", RegionCode: "FJ" },
    { Name: "Finland", RegionCode: "FI" },
    { Name: "France", RegionCode: "FR" },
    { Name: "French Guiana", RegionCode: "GF" },
    { Name: "French Polynesia", RegionCode: "PF" },
    { Name: "Gabon", RegionCode: "GA" },
    { Name: "Gambia", RegionCode: "GM" },
    { Name: "Georgia", RegionCode: "GE" },
    { Name: "Germany", RegionCode: "DE" },
    { Name: "Ghana", RegionCode: "GH" },
    { Name: "Gibraltar", RegionCode: "GI" },
    { Name: "Greece", RegionCode: "GR" },
    { Name: "Greenland", RegionCode: "GL" },
    { Name: "Grenada", RegionCode: "GD" },
    { Name: "Guadeloupe", RegionCode: "GP" },
    { Name: "Guam", RegionCode: "GU" },
    { Name: "Guatemala", RegionCode: "GT" },
    { Name: "Guernsey", RegionCode: "GG" },
    { Name: "Guinea", RegionCode: "GN" },
    { Name: "Guinea-Bissau", RegionCode: "GW" },
    { Name: "Guyana", RegionCode: "GY" },
    { Name: "Haiti", RegionCode: "HT" },
    { Name: "Honduras", RegionCode: "HN" },
    { Name: "Hong Kong", RegionCode: "HK" },
    { Name: "Hungary", RegionCode: "HU" },
    { Name: "Iceland", RegionCode: "IS" },
    { Name: "India", RegionCode: "IN" },
    { Name: "Indonesia", RegionCode: "ID" },
    { Name: "Iran", RegionCode: "IR" },
    { Name: "Iraq", RegionCode: "IQ" },
    { Name: "Ireland", RegionCode: "IE" },
    { Name: "Isle of Man", RegionCode: "IM" },
    { Name: "Israel", RegionCode: "IL" },
    { Name: "Italy", RegionCode: "IT" },
    { Name: "Ivory Coast", RegionCode: "CI" },
    { Name: "Jamaica", RegionCode: "JM" },
    { Name: "Japan", RegionCode: "JP" },
    { Name: "Jersey", RegionCode: "JE" },
    { Name: "Jordan", RegionCode: "JO" },
    { Name: "Kazakhstan", RegionCode: "KZ" },
    { Name: "Kenya", RegionCode: "KE" },
    { Name: "Kiribati", RegionCode: "KI" },
    { Name: "Kosovo", RegionCode: "XK" },
    { Name: "Kuwait", RegionCode: "KW" },
    { Name: "Kyrgyzstan", RegionCode: "KG" },
    { Name: "Laos", RegionCode: "LA" },
    { Name: "Latvia", RegionCode: "LV" },
    { Name: "Lebanon", RegionCode: "LB" },
    { Name: "Lesotho", RegionCode: "LS" },
    { Name: "Liberia", RegionCode: "LR" },
    { Name: "Libya", RegionCode: "LY" },
    { Name: "Liechtenstein", RegionCode: "LI" },
    { Name: "Lithuania", RegionCode: "LT" },
    { Name: "Luxembourg", RegionCode: "LU" },
    { Name: "Macau", RegionCode: "MO" },
    { Name: "Macedonia", RegionCode: "MK" },
    { Name: "Madagascar", RegionCode: "MG" },
    { Name: "Malawi", RegionCode: "MW" },
    { Name: "Malaysia", RegionCode: "MY" },
    { Name: "Maldives", RegionCode: "MV" },
    { Name: "Mali", RegionCode: "ML" },
    { Name: "Malta", RegionCode: "MT" },
    { Name: "Marshall Islands", RegionCode: "MH" },
    { Name: "Martinique", RegionCode: "MQ" },
    { Name: "Mauritania", RegionCode: "MR" },
    { Name: "Mauritius", RegionCode: "MU" },
    { Name: "Mayotte", RegionCode: "YT" },
    { Name: "Mexico", RegionCode: "MX" },
    { Name: "Micronesia", RegionCode: "FM" },
    { Name: "Moldova", RegionCode: "MD" },
    { Name: "Monaco", RegionCode: "MC" },
    { Name: "Mongolia", RegionCode: "MN" },
    { Name: "Montenegro", RegionCode: "ME" },
    { Name: "Montserrat", RegionCode: "MS" },
    { Name: "Morocco", RegionCode: "MA" },
    { Name: "Mozambique", RegionCode: "MZ" },
    { Name: "Myanmar", RegionCode: "MM" },
    { Name: "Namibia", RegionCode: "NA" },
    { Name: "Nauru", RegionCode: "NR" },
    { Name: "Nepal", RegionCode: "NP" },
    { Name: "Netherlands Antilles", RegionCode: "AN" },
    { Name: "Netherlands", RegionCode: "NL" },
    { Name: "New Caledonia", RegionCode: "NC" },
    { Name: "New Zealand", RegionCode: "NZ" },
    { Name: "Nicaragua", RegionCode: "NI" },
    { Name: "Niger", RegionCode: "NE" },
    { Name: "Nigeria", RegionCode: "NG" },
    { Name: "Niue", RegionCode: "NU" },
    { Name: "Norfolk Island", RegionCode: "NF" },
    { Name: "North Korea", RegionCode: "KP" },
    { Name: "Northern Mariana Islands", RegionCode: "MP" },
    { Name: "Norway", RegionCode: "NO" },
    { Name: "Oman", RegionCode: "OM" },
    { Name: "Pakistan", RegionCode: "PK" },
    { Name: "Palau", RegionCode: "PW" },
    { Name: "Palestine", RegionCode: "PS" },
    { Name: "Panama", RegionCode: "PA" },
    { Name: "Papua New Guinea", RegionCode: "PG" },
    { Name: "Paraguay", RegionCode: "PY" },
    { Name: "Peru", RegionCode: "PE" },
    { Name: "Philippines", RegionCode: "PH" },
    { Name: "Pitcairn", RegionCode: "PN" },
    { Name: "Poland", RegionCode: "PL" },
    { Name: "Portugal", RegionCode: "PT" },
    { Name: "Puerto Rico", RegionCode: "PR" },
    { Name: "Qatar", RegionCode: "QA" },
    { Name: "Republic of the Congo", RegionCode: "CG" },
    { Name: "Reunion", RegionCode: "RE" },
    { Name: "Romania", RegionCode: "RO" },
    { Name: "Russia", RegionCode: "RU" },
    { Name: "Rwanda", RegionCode: "RW" },
    { Name: "Saint Barthelemy", RegionCode: "BL" },
    { Name: "Saint Helena", RegionCode: "SH" },
    { Name: "Saint Kitts and Nevis", RegionCode: "KN" },
    { Name: "Saint Lucia", RegionCode: "LC" },
    { Name: "Saint Martin", RegionCode: "MF" },
    { Name: "Saint Pierre and Miquelon", RegionCode: "PM" },
    { Name: "Saint Vincent and the Grenadines", RegionCode: "VC" },
    { Name: "Samoa", RegionCode: "WS" },
    { Name: "San Marino", RegionCode: "SM" },
    { Name: "Sao Tome and Principe", RegionCode: "ST" },
    { Name: "Saudi Arabia", RegionCode: "SA" },
    { Name: "Senegal", RegionCode: "SN" },
    { Name: "Serbia", RegionCode: "RS" },
    { Name: "Seychelles", RegionCode: "SC" },
    { Name: "Sierra Leone", RegionCode: "SL" },
    { Name: "Singapore", RegionCode: "SG" },
    { Name: "Sint Maarten", RegionCode: "SX" },
    { Name: "Slovakia", RegionCode: "SK" },
    { Name: "Slovenia", RegionCode: "SI" },
    { Name: "Solomon Islands", RegionCode: "SB" },
    { Name: "Somalia", RegionCode: "SO" },
    { Name: "South Africa", RegionCode: "ZA" },
    { Name: "South Korea", RegionCode: "KR" },
    { Name: "South Sudan", RegionCode: "SS" },
    { Name: "Spain", RegionCode: "ES" },
    { Name: "Sri Lanka", RegionCode: "LK" },
    { Name: "Sudan", RegionCode: "SD" },
    { Name: "Suriname", RegionCode: "SR" },
    { Name: "Svalbard and Jan Mayen", RegionCode: "SJ" },
    { Name: "Swaziland", RegionCode: "SZ" },
    { Name: "Sweden", RegionCode: "SE" },
    { Name: "Switzerland", RegionCode: "CH" },
    { Name: "Syria", RegionCode: "SY" },
    { Name: "Taiwan", RegionCode: "TW" },
    { Name: "Tajikistan", RegionCode: "TJ" },
    { Name: "Tanzania", RegionCode: "TZ" },
    { Name: "Thailand", RegionCode: "TH" },
    { Name: "Togo", RegionCode: "TG" },
    { Name: "Tokelau", RegionCode: "TK" },
    { Name: "Tonga", RegionCode: "TO" },
    { Name: "Trinidad and Tobago", RegionCode: "TT" },
    { Name: "Tristan da Cunha", RegionCode: "TA" },
    { Name: "Tunisia", RegionCode: "TN" },
    { Name: "Turkey", RegionCode: "TR" },
    { Name: "Turkmenistan", RegionCode: "TM" },
    { Name: "Turks and Caicos Islands", RegionCode: "TC" },
    { Name: "Tuvalu", RegionCode: "TV" },
    { Name: "U.S. Virgin Islands", RegionCode: "VI" },
    { Name: "Uganda", RegionCode: "UG" },
    { Name: "Ukraine", RegionCode: "UA" },
    { Name: "United Arab Emirates", RegionCode: "AE" },
    { Name: "United Kingdom", RegionCode: "GB" },
    { Name: "United States", RegionCode: "US" },
    { Name: "Uruguay", RegionCode: "UY" },
    { Name: "Uzbekistan", RegionCode: "UZ" },
    { Name: "Vanuatu", RegionCode: "VU" },
    { Name: "Vatican", RegionCode: "VA" },
    { Name: "Venezuela", RegionCode: "VE" },
    { Name: "Vietnam", RegionCode: "VN" },
    { Name: "Wallis and Futuna", RegionCode: "WF" },
    { Name: "Western Sahara", RegionCode: "EH" },
    { Name: "Yemen", RegionCode: "YE" },
    { Name: "Zambia", RegionCode: "ZM" },
    { Name: "Zimbabwe", RegionCode: "ZW" }
];

export default regionCodeList;