import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';
import BaseStackMixin from './BaseStackMixin.jsx';
import BaseComponent from './BaseComponentMixin.jsx';

Vue.component('dynamic-menu-list-dsgn', {
    mixins: [BaseComponent],
    data: function () {
        return {
            tab_state: {
                index: 0
            },
            model: [
                {
                    name: 'Sample 1',
                    icon: 'mdi mdi-cog',
                    tooltip: 'A sample',
                    item_data: {
                        MenuItemData: {
                            Badge: {
                                CountURL: '123',
                                ToolTip: 'Badge Tooltip',
                            }
                        }
                    }
                },
                {
                    name: 'Sample 2',
                    icon: 'mdi mdi-cogs',
                    tooltip: 'A sample 2',
                    item_data: {
                        MenuItemData: {
                            Badge: null
                        }
                    }
                }
            ],
        }
    },
    async created() {
        if (this.controlData.SourceType === 'Raw' && this.controlData.SourceRaw) {
            if (Array.isArray(this.controlData.SourceRaw))
                this.model = this.controlData.SourceRaw;
        }
    },
    computed: {
    },
    render(h) {
        if (!this.controlData)
            return null;

        const style = {
            minWidth: "25px",
            ...utils.getSize(this.controlData.SizeOptions, this.parentType, this.$parent),
            ...utils.getStyleHints(this.controlData.StyleHints),
        };

        let title;
        if (this.controlData.MenuTitle)
            title = (
                <v-card-title class="indigo white--text">
                    {this.controlData.MenuTitle}
                </v-card-title>
            );

        const scopedSlots = {
            label: ({ item }) => {
                let title;
                if (item.icon)
                    title = <span class="text-wrap"><v-icon small>{item.icon}</v-icon> {item.name}</span>;
                else
                    title = <span class="text-wrap">{item.name}</span>;

                if (item.item_data.MenuItemData) {
                    const badgeinfo = item.item_data.MenuItemData.Badge;
                    if (badgeinfo) {
                        title = <v-badge v-tooltip={item.tooltip} inline value={true} content={badgeinfo.CountURL} color="error">{title}</v-badge>;
                    }
                }
                return title;
            }
        };

        const tv = h('v-treeview', {
            props: {
                activatable: true,
                hoverable: true,
                transition: true,
                openOnClick: true,
                dense: true,
                class: 'ma-1',
                items: this.model || [],
                active: this.selected_values,
            },
            scopedSlots: scopedSlots,
        });

        return (
            <div
                class={{ 'c-DynamicMenuList': true, [`c-name-${this.name || 'unnamed'}`]: true, 'designer-container': true, selected: this.selected$ }}
                style={style}
                on-click={(e) => this.handleClick(e)}>
                <div class={{ "designer-container-hovering": true, 'designer-container-selected-clickthrough': this.selected$ }}></div>

                <v-card class="mx-auto">
                    {title}

                    {tv}
                </v-card>
            </div>
        );
    }
});