import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import utils from '../../Shared/utils.jsx';
import webSocket from '@/Services/webSocketService';
import EventBus from '@/Application/event-bus';
import careHelpfulFunctions from '@/Application/careHelpfulFunctions.jsx';
import apiService from '@/Services/api';
import defaultTheme from '@/plugins/themes/DefaultTheme';
import { CreateWebSocketConnection } from '@/Services/webSocketConnectionFactory';

Vue.component('rt-event-handler', {
    mixins: [BaseComponent],
    data: function () {
        return {
            isActive: false,
            readyToActivate: false,
            errorState: '',

            userlist: [],
            allusers: {},
            usermap: {},
            cache: {
                user_cache: {},
                intr_cache: {},

                user_filters: {
                    team: null,
                },
                intr_filters: {
                    channelType: null,
                    team: null,
                    queue: null,
                    legType: null,
                },
                widget_views: {

                },
            },
            contactStatesMapping: {
                Linked: 'Answered',
                Transfer: 'Transfer',
                Reading: 'Reading',
                Unlinked: 'Hold',
                Composing: 'Composing',
                Starting: 'Pending',
                Chatting: 'Chatting',
                WrapUp: 'WrapUp',
                Busy: 'Busy',
                Delivered: 'Delivered',
            },
            stateNameMapping: {
                Ready: 'Ready',
                NotReady: 'Not Ready',
                Outbound: 'Outbound',
                Inbound: 'Inbound',
                WrapUp: 'Wrap Up',
                Busy: 'Busy',
            },
            requestedViews: [],
        }
    },
    props: {},
    computed: {
        UserList: function () {
            let result = Enumerable.From(this.cache.user_cache)
                .Select(item => {
                    // Look over all state entities and find the most recent
                    const zero = '0001-01-01T00:00:00';
                    let qtime = Date.parse(item.Value.inqueue?.StartTime || zero);
                    let wtime = Date.parse(item.Value.wall?.StartTime || zero);
                    let stime = Date.parse(item.Value.state?.StartTime || zero);
                    let rtime = Date.parse(item.Value.ready?.StartTime || zero);
                    let last = new Date(Math.max(qtime, wtime, stime, rtime));
                    let agentlegid = '';
                    let activeContact = item.Value.activecontact || '';

                    let state = (item.Value.wall?.Name == 'Ready' ? item.Value.state?.Payload?.ReadyDisplayName : item.Value.state?.Payload?.NotReadyDisplayName) || item.Value.state?.Name;

                    state = this.stateNameMapping[state] || state; // map to beautified
                    let intr_list = null;
                    if (item.Value.interactions && Object.keys(item.Value.interactions).length > 0) {
                        intr_list = Object.keys(item.Value.interactions).map(i => {
                            const value = item.Value.interactions[i].Value;
                            const general = item.Value.interactions[i].Session.general || {};
                            if (general.Attributes?.AgentLegId)
                                agentlegid = general.Attributes?.AgentLegId;

                            let queue = value?.Attributes.Queue;
                            if (queue === "")
                                queue = general.Attributes?.Queue;

                            let itime = Date.parse(value.StartTime);
                            last = new Date(Math.max(last, itime));
                            activeContact = i; // how do we know what is there active contact?
                            let mappedName = this.contactStatesMapping[value?.Name] || value?.Name;
                            return {
                                SessionId: i,
                                State: mappedName,
                                ChannelType: value?.Attributes.ChannelType,
                                Queue: queue,
                                Direction: value?.Attributes.Direction,
                                Attributes: value?.Attributes,
                                StartTime: value.StartTime,
                                General: general,
                            };
                        });

                        if (intr_list.length == 1) {
                            let dir = intr_list[0].Direction;
                            let direction = dir.charAt(0).toUpperCase() + dir.slice(1);
                            state = `${direction} ${intr_list[0].ChannelType} ${intr_list[0].State}`;
                        }
                        else if (intr_list.length > 1)
                            state = 'Multiples';
                        
                    }

                    let color = item.Value.inqueue ? defaultTheme.green : defaultTheme.red; // green & red
                    if (intr_list) {
                        if (intr_list.every(i => i.State.toLowerCase() == 'wrapup' || i.State.toLowerCase() == 'wrap up'))
                            color = defaultTheme.wrapup;
                        else if (intr_list.every(i => i.Direction == 'inbound'))
                            color = defaultTheme.inbound;
                        else if (intr_list.every(i => i.Direction == 'outbound'))
                            color = defaultTheme.outbound;
                        else if (intr_list.length > 0)
                            color = defaultTheme.multiples;
                    }
                    let canBeSilentMonitored = false;
                    if (agentlegid && agentlegid.length > 0 && intr_list.findIndex(i => (i.Queue != "" && i.Queue != undefined && i.Queue != null)) >=0 )
                        canBeSilentMonitored = true;

                    return {
                        UserID: item.Key,
                        User: this.allusers[item.Key],
                        FullName: this.getUserFullName(item.Key) || 'Unknown',
                        LoggedIn: item.Value.wall ? true : false,
                        StateName: state,
                        Color: color,
                        ProfileID: item.Value.state?.Profile,
                        Interactions: intr_list,
                        IsReady: item.Value.inqueue ? true : false,
                        Team: item.Value.state?.Attributes.Team,
                        TimecardState: item.Value.state,
                        WallState: item.Value.wall,
                        ReadyState: item.Value.ready,
                        QueueState: item.Value.inqueue,
                        StartTime: last.toISOString(),
                        AgentLegId: agentlegid,
                        ActiveContact: activeContact,
                        OwnerId: item.Value.ownerid,
                        CanBeSilentMonitored: canBeSilentMonitored,
                    };
                });

            if (typeof this.cache.user_filters.team === 'string')
                result = result.Where(a => a.Team == this.cache.user_filters.team);
            else if (Array.isArray(this.cache.user_filters.team))
                result = result.Where(a => this.cache.user_filters.team.some(b => b == a.Team));

            const list = result.ToArray();
            const present = {};

            // Save list of users as an object map so consumers can easily lookup a user's state
            for (let i = 0; i < list.length; i++) {
                Vue.set(this.usermap, list[i].UserID, list[i]);
                present[list[i].UserID] = true;
            }

            // Remove any users that are no longer present
            for (let key in this.usermap)
                if (!(key in present))
                    Vue.delete(this.usermap, key);

            return list;
        },
        AllUsers: function () {
            let result = Enumerable.From(this.userlist)
                .Select(user => {
                    const item = this.usermap[user.UserID];

                    return {
                        UserID: user.UserID,
                        User: this.allusers[user.UserID],
                        FullName: user.FullName,
                        LoggedIn: !!item,
                        StateName: item ? item.StateName : 'LoggedOff',
                        Color: item?.Color || 'silver',
                        ProfileID: item ? item.ProfileID : null,
                        Interactions: item ? item.Interactions : null,
                        IsReady: item ? item.IsReady : null,
                        Team: item ? item.Team : null,
                        TimecardState: item ? item.TimecardState : null,
                        WallState: item ? item.WallState : null,
                        ReadyState: item ? item.ReadyState : null,
                        QueueState: item ? item.QueueState : null,
                        StartTime: item ? item.StartTime : null,
                    };
                });

            return result.ToArray();
        },
        Interactions: function () {
            let result = Enumerable.From(this.cache.intr_cache)
                .Where(item => item.Value.type != 'Unknown')
                .Select(item => ({
                    SessionId: item.Key,
                    ChannelType: item.Value.type,
                    StateName: (this.contactStatesMapping[item.Value.user?.Value.Name] || item.Value.user?.Value.Name) || (this.contactStatesMapping[item.Value.general?.Name] || item.Value.general?.Name) || 'Unknown',
                    UserState: (this.contactStatesMapping[item.Value.user?.Value.Name] || item.Value.user?.Value.Name) || 'NA',
                    GeneralState: (this.contactStatesMapping[item.Value.general?.Name] || item.Value.general?.Name) || 'Unknown',
                    CallerId: item.Value.general?.Attributes.CallerId,
                    CallerIdName: item.Value.general?.Attributes.CallerIdName,
                    CallerName: item.Value.general?.Attributes.CallerName,
                    Destination: item.Value.general?.Attributes.Destination,
                    Direction: item.Value.general?.Attributes.Direction,
                    LegType: item.Value.general?.Attributes.LegType,
                    Location: item.Value.general?.Attributes.Location,
                    MasterId: item.Value.general?.Attributes.MasterId,
                    Team: item.Value.general?.Attributes.Team,
                    TimeInQueue: item.Value.general?.Attributes.TimeInQueue,
                    InQueue: item.Value.inqueue ? true : false,
                    Queue: item.Value.general?.Attributes.Queue  || "",
                    Competencies: item.Value.inqueue?.Attributes.Skill,
                    Languages: item.Value.inqueue?.Attributes.Language,
                    TargetUserID: item.Value.inqueue?.Attributes.UserID,
                    Handling: item.Value.user ? true : false,
                    UserID: item.Value.user?.UserID,
                    FullName: item.Value.user ? this.getUserFullName(item.Value.user.UserID) : '',
                    StartTime: item.Value.general?.StartTime,
                    IsUniversalChat: item.Value.general?.Payload?.IsUniversalChat,
                    QueueCount: item.Value.general?.Payload?.QueueCount,
                    AgentLegId: item.Value.general?.Attributes.AgentLegId || '',
                    OriginalTimeInQueue: (item.Value.general?.Attributes.OriginalInQueueTime) ? item.Value.general?.Attributes.OriginalInQueueTime : item.Value.general?.StartTime,
                    OwnerId: item.Value.user?.User?.ownerid,
                }));

            if (typeof this.cache.intr_filters.channelType === 'string')
                result = result.Where(a => a.ChannelType == this.cache.intr_filters.channelType);
            else if (Array.isArray(this.cache.intr_filters.channelType))
                result = result.Where(a => this.cache.intr_filters.channelType.some(b => b == a.ChannelType));

            if (typeof this.cache.intr_filters.team === 'string')
                result = result.Where(a => a.Team == this.cache.intr_filters.team);
            else if (Array.isArray(this.cache.intr_filters.team))
                result = result.Where(a => this.cache.intr_filters.team.some(b => b == a.Team));

            if (typeof this.cache.intr_filters.queue === 'string')
                result = result.Where(a => a.Queue == this.cache.intr_filters.queue);
            else if (Array.isArray(this.cache.intr_filters.queue))
                result = result.Where(a => this.cache.intr_filters.queue.some(b => b == a.Queue));

            if (typeof this.cache.intr_filters.legType === 'string')
                result = result.Where(a => a.LegType == this.cache.intr_filters.legType);
            else if (Array.isArray(this.cache.intr_filters.legType))
                result = result.Where(a => this.cache.intr_filters.legType.some(b => b == a.LegType));

            return result.ToArray();
        },

        apiKey: function () {
            if (!this.apiKey_fn && this.controlData.ApiKey)
                this.apiKey_fn = utils.compile(this, this.controlData.ApiKey);

            return this.apiKey_fn ? utils.evaluate(this.apiKey_fn, this) : '';
        },
        apiBaseUri: function () {
            if (!this.apiBaseUri_fn && this.controlData.ApiBaseUrl)
                this.apiBaseUri_fn = utils.compile(this, this.controlData.ApiBaseUrl);

            return this.apiBaseUri_fn ? utils.evaluate(this.apiBaseUri_fn, this) : '';
        },
    },
    created() {
        if (this.controlData.PublishField) {

            // PublishField allows the location for the published data to be configurable.
            // If no PublishField is used, then simply read the data by accessing the control.
            // Ex: Control.rteventhandler1.UserList
            // Ex: Root.ChildControlByName('rteventhandler1').UserList

            // Assign a reference to the cache variable such that it will remain reactive.
            // Typical use is for PublishField to be in GlobalVars, for example:
            // GlobalVars.RTData

            // Create an object with getter methods to read the UserList and Interactions:
            const self = this;
            const accessor = {
                get UserList() {
                    return self.UserList;
                },
                get AllUsers() {
                    return self.AllUsers;
                },
                get UserMap() {
                    return self.usermap;
                },
                get Interactions() {
                    return self.Interactions;
                },
                get Views() {
                    return self.cache.widget_views;
                },
                get user_cache() {
                    return self.cache.user_cache;
                },
                get interaction_cache() {
                    return self.cache.intr_cache;
                },
                get UserFilters() {
                    return self.cache.user_filters;
                },
                get InteractionFilters() {
                    return self.cache.intr_filters;
                },
                get IsActive() {
                    return self.isActive;
                },
                APIRequest: this.APIRequest, // returns a function
                apipatch: this.apipatch,
                apiput: this.apiput,
                apipost: this.apipost,
                apidelete_: this.apidelete_,
                apiget: this.apiget,
                AddView: this.AddView,
                RemoveView: this.RemoveView,
                GetNamedListForRealTime: this.GetNamedListForRealTime,
                RemoveNamedListForRealTime: this.RemoveNamedListForRealTime,
                TryToGetResponse: this.TryToGetResponse,
            };

            // Using a function here to allow PublishField to contain dot notation or other valid Javascript syntax
            // The resulting code would then effectively do this (using GlobalVars.RTData as PublishField):
            // this.GlobalVars.RTData = accessor;

            let code = `base.${this.controlData.PublishField} = value;`;
            let func = new Function('base', 'value', code);
            func(this, accessor);
        }

        EventBus.$on('RealTimeBatch', this.rtEventReceived);
    },
    //Mounted Replaced with preRenderComplete
    destroyed() {
        EventBus.$off('RealTimeBatch', this.rtEventReceived);
        EventBus.$off('WebSocketConnected', this.activate);

        webSocket.StopRealTimeMonitor();
    },
    watch: {
        todisplay: function (value, old) {
            if (value && !old && this.readyToActivate)
                this.activate();
        }
    },
    methods: {
        async preRenderComplete() {
            this.finishRenderHandler(this);

            this.readyToActivate = true;

            if (!this.todisplay)
                return;

            //await this.activate();
        },
        async activate() {
            try {
                this.userlist = await this.APIRequest({ method: 'get', url: 'Apps/GetCustomerUsers', flatten: true });

                this.allusers = careHelpfulFunctions.toLookup(this.userlist, 'UserID');

                if (this.controlData.Native) {
                    await webSocket.StartRealTimeMonitor({});
                    this.RestoreViews();
                    this.isActive = true;
                    EventBus.$once('WebSocketConnected', this.activate);
                }
                else
                    this.connect();
            } catch (err) {
                utils.error('Error activating RTEventHandler', err);
            }
        },
        async connect() {
            // Establish a custom stand-alone websocket to listen for real-time events
            // from an arbitrary web server / call center.

            try {
                this.standAloneWebSocket = await CreateWebSocketConnection(this.apiBaseUri);
                await this.standAloneWebSocket.Connect(
                    //baseuri
                    this.apiBaseUri,
                    //queryParams
                    { 'apikey': this.apiKey },
                    //noticeCallback
                    (message, arg, connectionId, time) => { utils.log(`Notice: ${message}, ${arg}, ${connectionId}, ${time}`); },
                    //channelEventCallback
                    (event) => { utils.log(`Event: ${JSON.stringify(event)}`); },
                    //rtEventCallback
                    this.rtEventHandler,
                    //onCloseCallback
                    (error) => { utils.error("RTEventHandler onCloseCallback"); });
                await this.subscribe();
            }
            catch (err) {
                utils.warn(`RTEventHandler standalone WebSocket failedr: ${this.apiBaseUri}`, err);
            }
        },
        async subscribe() {
            // Cause the web server to send us RT events (real-time stats)
            try {
                await this.standAloneWebSocket.StartRealTimeMonitor({});
                this.isActive = true;
            }
            catch (e) {
                this.errorState = JSON.stringify(e);
                this.isActive = false;
                utils.warn(`RTEventHandler failed to invoke StartRealTimeMonitor: ${this.apiBaseUri}`, e);
            }
        },

        getUserFullName(userid) {
            const u = this.allusers[userid];
            return u ? u.FullName : 'Unknown';
        },

        rtEventHandler(value) {
            // This is only used in the cross-call center scenario
            const batch = [];
            for (let i = 0; i < value.length; i++) {

                utils.debug('signalR RTEvent: ' + value[i]);
                const eventData = JSON.parse(value[i]);
                batch.push(eventData);
            }
            this.rtEventReceived(batch);
        },
        rtEventReceived(batch) {
            utils.debug(`RTEvent batch: ${batch.length} event(s) received`);
            
            for (let i = 0; i < batch.length; i++)
                if (Array.isArray(batch[i]))
                    for (let j = 0; j < batch[i].length; j++) {
                        const item = batch[i][j];
                        switch (item.Operation) {
                            case 'Flush':
                                this.flushAll();
                                break;

                            case 'Add':
                            case 'Change':
                                this.processUpdate(item, false);
                                break;

                            case 'Remove':
                                this.processUpdate(item, true);
                                break;
                        }
                    }
                else {
                    switch (batch[i].Operation) {
                        case 'Flush':
                            this.flushAll();
                            break;

                        case 'Add':
                        case 'Change':
                            this.processUpdate(batch[i], false);
                            break;

                        case 'Remove':
                            this.processUpdate(batch[i], true);
                            break;
                    }
                }
        },
        flushAll() {
            this.cache.user_cache = {};
            this.cache.intr_cache = {};
        },
        processUpdate(item, remove) {
            switch (item.Type) {
                case 'ReadyUser':
                case 'UserState':
                case 'WallUser':
                    this.updateUser(item, remove);
                    break;

                case 'Endpoint':
                    switch (item.Gender) {
                        case 0: // Agent
                            this.updateUserInQueue(item, remove);
                            break;

                        case 1: // Caller
                            this.updateInteractionInQueue(item, remove);
                            break;
                    }
                    break;

                case 'UserCall':
                case 'UserContact':
                    this.updateUserContact(item, remove);
                    break;

                case 'Call':
                case "Callback":
                case 'EMail':
                case 'SMS':
                case 'Chat':
                    this.updateInteraction(item, remove);
                    break;

                case 'WidgetView':
                    this.updateWidgetView(item);
                    break;

                default:
                    if (item.Type.startsWith('CustomChannel~')) {
                        item.Type = item.Type.substr('CustomChannel~'.length);
                        this.updateInteraction(item, remove);
                    }
                    break;
            }
        },

        updateUser(item, remove) {
            const userid = item.ItemId.split(':')[0];
            const ownerid = item.OwnerId;
            let user = this.cache.user_cache[userid];
            
            if (!user && remove) {
                // Ignore a remove for an unknown user
                return;
            }
            if (!user && !remove) {
                user = {
                    ready: null,
                    state: null,
                    wall: null,
                    inqueue: null,
                    interactions: null,
                    ownerid: ownerid,
                };
                Vue.set(this.cache.user_cache, userid, user);
            }

            switch (item.Type) {
                case 'ReadyUser':
                    if (remove)
                        user.ready = null;
                    else {
                        item.Value.Attributes = utils.helpers.chf.toLookup(item.Value.Attributes, 'Type', 'Value', true, false);
                        user.ready = item.Value;
                    }
                    break;

                case 'UserState':
                    if (remove)
                        user.state = null;
                    else {
                        item.Value.Attributes = utils.helpers.chf.toLookup(item.Value.Attributes, 'Type', 'Value', false);
                        user.state = item.Value;
                    }
                    break;

                case 'WallUser':
                    if (remove)
                        user.wall = null;
                    else {
                        item.Value.Attributes = utils.helpers.chf.toLookup(item.Value.Attributes, 'Type', 'Value', true, false);
                        user.wall = item.Value;
                    }
                    break;
            }

            // If all state info is gone, remove the user
            if (remove && !user.ready && !user.state && !user.wall && !user.inqueue && !user.interactions)
                Vue.delete(this.cache.user_cache, userid);
        },
        updateInteraction(item, remove) {
            const sessionId = item.ItemId;
            let session = this.cache.intr_cache[sessionId];
            if (!session && remove) {
                // Ignore a remove for an unknown interaction
                return;
            }
            if (!session && !remove) {
                session = {
                    type: item.Type,
                    general: null,
                    user: null,
                    inqueue: null,
                };
                Vue.set(this.cache.intr_cache, sessionId, session);
            }

            if (remove) {
                session.general = null;
            }
            else {
                item.Value.Attributes = utils.helpers.chf.toLookup(item.Value.Attributes, 'Type', 'Value', true, false);
                session.type = item.Type;
                session.general = item.Value;
            }

            // If all state info is gone, remove the interaction
            if (remove && !session.general && !session.inqueue)
                Vue.delete(this.cache.intr_cache, sessionId);
        },

        updateUserInQueue(item, remove) {
            const userid = item.EndpointId;
            let user = this.cache.user_cache[userid];
            if (!user && remove) {
                // Ignore a remove for an unknown user
                return;
            }
            if (!user && !remove) {
                user = {
                    ready: null,
                    state: null,
                    wall: null,
                    inqueue: null,
                    interactions: null,
                };
                Vue.set(this.cache.user_cache, userid, user);
            }

            if (remove)
                user.inqueue = null;
            else {
                item.Attributes = utils.helpers.chf.toLookup(item.Attributes, 'Key', 'Value', true, false);
                if (item.Attributes.Queue)
                    item.Queue = utils.helpers.chf.toLookup(item.Attributes.Queue, 'Value', 'Rank', false);
                if (item.Attributes.Skill)
                    item.Skill = utils.helpers.chf.toLookup(item.Attributes.Skill, 'Value', 'Rank', false);
                if (item.Attributes.Language)
                    item.Language = utils.helpers.chf.toLookup(item.Attributes.Language, 'Value', 'Rank', false);
                user.inqueue = item;
            }

            // If all state info is gone, remove the user
            if (remove && !user.ready && !user.state && !user.wall && !user.inqueue && !user.interactions)
                Vue.delete(this.cache.user_cache, userid);
        },
        updateInteractionInQueue(item, remove) {
            const sessionId = item.EndpointId;
            let session = this.cache.intr_cache[sessionId];
            if (!session && remove) {
                // Ignore a remove for an unknown interaction
                return;
            }
            if (!session && !remove) {
                session = {
                    type: item.Type,
                    general: null,
                    user: null,
                    inqueue: null,
                };
                Vue.set(this.cache.intr_cache, sessionId, session);
            }

            if (remove) {
                session.inqueue = null;
            }
            else {
                item.Attributes = utils.helpers.chf.toLookup(item.Attributes, 'Key', 'Value', true, false);
                session.inqueue = item;
            }

            // If all state info is gone, remove the interaction
            if (remove && !session.general && !session.inqueue)
                Vue.delete(this.cache.intr_cache, sessionId);
        },

        updateUserContact(item, remove) {
            const userid = item.ItemId.split(':')[0];
            const sessionId = item.ItemId.split(':')[1];
            let user = this.cache.user_cache[userid];
            let session = this.cache.intr_cache[sessionId];
            if (!user && !session && remove) {
                // Ignore a remove for an unknown user and interaction
                return;
            }
            if (!user && !remove) {
                user = {
                    ready: null,
                    state: null,
                    wall: null,
                    inqueue: null,
                    interactions: null,
                };
                Vue.set(this.cache.user_cache, userid, user);
            }
            if (!session && !remove) {
                session = {
                    type: 'Unknown',
                    general: null,
                    user: null,
                    inqueue: null,
                };
                Vue.set(this.cache.intr_cache, sessionId, session);
            }
            if (remove) {
                Vue.delete(user.interactions, sessionId);
                if (session)
                    session.user = null;

                user.activecontact = '';
                
                if (Object.keys(user.interactions).length == 1) {
                    user.activecontact = Object.keys(user.interactions)[0]; // key is the sessionid
                }

                // If all state info is gone, remove the user
                if (!user.ready && !user.state && !user.wall && !user.inqueue && !user.interactions)
                    Vue.delete(this.cache.user_cache, userid);
            }
            else {
                if (!user.interactions)
                    Vue.set(user, 'interactions', {});

                Vue.set(user, 'activecontact', sessionId);

                if (item.Value.Attributes)
                    item.Value.Attributes = utils.helpers.chf.toLookup(item.Value.Attributes, 'Type', 'Value', true, false);

                session.user = { UserID: userid, User: user, Value: item.Value };
                Vue.set(user.interactions, sessionId, { Session: session, Value: item.Value });
            }
        },

        updateWidgetView(item) {
            Vue.set(this.cache.widget_views, item.Name, item.Data);
        },

        async APIRequest(options) {
            const apiOptions = { ...options };

            if (!this.controlData.Native)
            {
                apiOptions.url = this.apiBaseUri;

                if (options.url.startsWith('/'))
                    apiOptions.url += options.url;
                else
                    apiOptions.url += '/' + options.url;

                if (!apiOptions.headers)
                    apiOptions.headers = {};

                apiOptions.headers.apikey = this.apiKey;
                apiOptions.doNotUseWebsocket = true; // ON CROSS CENTER WE CANNOT USE WEBSOCKETS
            }

            return await apiService.apiRequest(apiOptions);
        },
        async apiget(url) {
            return await  this.APIRequest({
                method: 'GET',
                url: url,
                flatten: true
            });
        },
        async apidelete_(url) {
            return await this.APIRequest({
                method: 'DELETE',
                url: url,
                flatten: true
            });
        },
        async apipost(url, data) {
            return await this.APIRequest({
                method: 'POST',
                data: data,
                url: url,
                flatten: true
            });
        },
        async apiput(url, data) {
            return await this.APIRequest({
                method: 'PUT',
                data: data,
                url: url,
                flatten: true
            });
        },
        async apipatch(url, data) {
            return await  this.APIRequest({
                method: 'PATCH',
                data: data,
                url: url,
                flatten: true
            });
        },

        RestoreViews() {
            utils.debug(`RestoreViews() ${this.requestedViews.length} item(s)`);

            // Clone the array to allow the functions to modify it
            for (let view of [...this.requestedViews])
            {
                utils.debug(`RestoreViews() view:${JSON.stringify(view)}`);

                switch (view.type) {
                    case 'view':
                        this.AddView(view.name, view.args, true);
                        break;

                    case 'list':
                        this.GetNamedListForRealTime(view.listName, view.sortCol, view.descending, view.start, view.length, view.columns, view.filters, view.id);
                        break;
                }
            }
        },
        AddView(viewName, args, restore) {
            if (restore) {
                // No need to do any tracking if we are just restoring
                if (this.controlData.Native)
                    webSocket.AddRealTimeMonitorView(viewName, args);
                else if (this.standAloneWebSocket)
                    this.standAloneWebSocket.AddRealTimeMonitorView(viewName, args);
                return;
            }

            const idx = this.requestedViews.findIndex(a => a.type == 'view' && a.name == viewName && JSON.stringify(a.args) == JSON.stringify(args));

            if (idx >= 0)
                this.requestedViews[idx].refCount++;

            if (this.controlData.Native)
                webSocket.AddRealTimeMonitorView(viewName, args);
            else if (this.standAloneWebSocket)
                this.standAloneWebSocket.AddRealTimeMonitorView(viewName, args);

            // Save the view requested so that if we detect a reset websocket, we can re-register (ensure we don't add a duplicate)
            if (idx < 0)
                this.requestedViews.push({
                    type: 'view',
                    name: viewName,
                    args: args,
                    refCount: 1,
                });
        },
        RemoveView(viewName, args) {
            const idx = this.requestedViews.findIndex(a => a.type == 'view' && a.name == viewName && JSON.stringify(a.args) == JSON.stringify(args));

            if (idx >= 0)
                this.requestedViews[idx].refCount--;

            if (idx < 0 || this.requestedViews[idx].refCount <= 0) {
                // If there are no more references to this view, we should unsubscribe to stop the server from sending it
                if (this.controlData.Native)
                    webSocket.RemoveRealTimeMonitorView(viewName, args);
                else if (this.standAloneWebSocket)
                    this.standAloneWebSocket.RemoveRealTimeMonitorView(viewName, args);
            }

            if (idx >= 0 && this.requestedViews[idx].refCount <= 0) {
                this.requestedViews.splice(idx, 1);
                
                let cacheViewName = viewName;
                if (args && JSON.stringify(args) != '{}')
                cacheViewName += '~!!~' + JSON.stringify(args);
                Vue.delete(this.cache.widget_views, cacheViewName);
            }

        },
        async GetNamedListForRealTime(listName, sortCol, descending, start, length, columns, filters, id) {
            utils.debug(`GetNamedListForRealTime(listName:${listName}, sortCol:${sortCol}, descending:${descending}, start:${start}, length:${length}, columns:${JSON.stringify(columns)}, filters:${JSON.stringify(filters)}, id:${id})`);

            if (id === undefined || id === null)
                id = utils.generateUUID();

            // Before saving, ensure we don't add a duplicate
            var existingView = this.requestedViews.find(a => a.type == 'list' && a.id == id);

            const args = {
                listName: listName,
                id: id,
                sortCol: sortCol,
                descending: descending,
                start: start,
                length: length,
                columns: columns,
                filters: filters,
            };

            // Save the view requested so that if we detect a reset websocket, we can re-register
            if (!existingView)
                this.requestedViews.push({ type: 'list', ...args });
            else
                Object.assign(existingView, args);

            let data;
            if (this.controlData.Native)
                data = await webSocket.GetNamedListForRealTime(listName, id, sortCol, descending, start, length, columns, filters);
            else if (this.standAloneWebSocket)
                data = await this.standAloneWebSocket.GetNamedListForRealTime(listName, id, sortCol, descending, start, length, columns, filters);

            // SignalR backwards compatability
            var statusCode = data.StatusCode ? data.StatusCode : data.statusCode;
            var responseBody = data.ResponseBody ? data.ResponseBody : data.responseBody;

            if (data && statusCode == 200 && responseBody) {
                data = responseBody;

                // The data contains the snapshot of the report data we want, but it will also
                // be registered on the web server to send regular updates for that same query.
                // The RTData will arrive under the ViewHash, which is why we need to know. The
                // ID is part of the ViewHash, but if we need to alter our query parameters
                // because, for example, the user changes sort order, page number, etc., then
                // we need to update the same query without causing the server to cache yet
                // another view. So the ID is important for that.
                return {
                    Response: data,
                    ViewHash: `${listName}~!!~${id}`,
                    ID: id,
                };
            }

            throw `GetNamedListForRealTime failed: ${JSON.stringify(data)}`;
        },
        async RemoveNamedListForRealTime(listName, id) {
            utils.debug(`RemoveNamedListForRealTime(listName:${listName}, id:${id}`);

            // Before saving, ensure we don't add a duplicate
            const idx = this.requestedViews.findIndex(a => a.type == 'list' && a.id == id);

            // Save the view requested so that if we detect a reset websocket, we can re-register
            if (idx >= 0)
                this.requestedViews.splice(idx, 1);

            if (this.controlData.Native)
                await webSocket.RemoveNamedListForRealTime(listName, id);
            else if (this.standAloneWebSocket)
                await this.standAloneWebSocket.RemoveNamedListForRealTime(listName, id);

            var cacheViewName = listName + '~!!~' + id;
            Vue.delete(this.cache.widget_views, cacheViewName);
        },
        async TryToGetResponse() {
            if (this.controlData.Native)
                return await webSocket.TryToGetResponse();
            else if (this.standAloneWebSocket)
                return await this.standAloneWebSocket.TryToGetResponse();
        },
    },
    render(h) {
        if (this.isdebug) {
            let icon = <v-icon small color={this.isActive ? 'purple' : 'red'}>mdi-chart-pie</v-icon>;
            let tooltip;
            if (this.isActive)
                tooltip = 'Real-Time Event Listener Active (Debug is enabled)';
            else if (this.errorState)
                tooltip = this.errorState;
            else
                tooltip = 'Real-Time Event Listener Waiting... (Debug is enabled)';

            icon = utils.generateTooltip(h, icon, tooltip, 'right');

            return (
                <div class="c-RTEventHandler" style="border: 1px solid silver; border-radius: 3px;">
                    {icon}
                </div>
            );
        }

        return null;
    }
});