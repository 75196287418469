import Vue from 'vue';
import utils from '../../Shared/utils.jsx';
import careHelpfulFunctions from '../careHelpfulFunctions.jsx';
import BaseComponent from './BaseComponentMixin.jsx';
import EventBus from '../event-bus.js';

var baseStackMixin = {
    mixins: [BaseComponent],
    data: function () {
        return {
            master_key: null,
            container_list: [],
            sourceraw: null,
            sourceurl: null,
            model: null,
            feedbackItems: [],
            isFullScreen: false,
            renderCount: 0,

            displayFeedbackEval: null,
            directionEval: null,
            formSizeEval: null,
            verticalAlignChildrenEval: null,
            horizontalAlignChildrenEval: null,
            paddingEval: null,
            fullScreenCapableEval: null
        }
    },
    props: {},
    computed: {
        Vars: function () {
            if (this.container_list.length === 0)
                return this.vars;
            else
                return this.container_list[this.container_list.length - 1].vars;
        },
        breadCrumbText: function () {
            if (this.breadcrumbtext) 
                return utils.evaluate(this.breadcrumbtext, this);
            else
                return null;
        },
        totalItems() {
            // Add one to include the component itself.
            let total = 1;

            // Each of the controls in the controls array will be rendered at least once.
            if(Array.isArray(this.controlData.Controls)) {
                total += this.controlData.Controls.length;
            }
            
            // There is a model and it is an object. In this case, we will repeat all of the controls for each item in the model.
            if(this.model && typeof this.model === 'object') {
                if(Array.isArray(this.model))
                    total += total * this.model.length;
                else 
                    total += total * Object.keys(this.model).length;
            }

            return total;
        },
        feedbackItemsComputed() {
            if(this.feedbackItems.length) {
                let items = this.feedbackItems.map(item => {
                    let type = item.messageType;
                    let textColor = item.messageType;
                    let icon = 'mdi mdi-information';

                    if (type == 'Info') {
                        textColor = 'primary';
                        icon = 'mdi mdi-information';
                    }
                    else if (type == 'Warning') {
                        textColor = 'outbound';
                        icon = 'mdi mdi-alert'
                    }
                    else if (type == 'Error') {
                        textColor = 'error';
                        icon = 'mdi mdi-alert'
                    }
                    else if (type == 'Success') {
                        textColor = 'success';
                        icon = 'mdi mdi-information'
                    }

                    let body;
                    if (item.message)
                        body = <v-card-text style={{ padding: 0, color: textColor }}>{item.message}</v-card-text>;

                    return (
                        <v-alert dark text color={textColor} max-width='1000px' key={item.message} class="mb-3 mt-1 ml-2">
                            <template slot="prepend">
                                <v-icon color={textColor} class="mr-4">
                                    {icon}
                                </v-icon>
                            </template>
                            {body}
                        </v-alert>
                    );
                });
    
                return (
                    <div class="d-flex flex-column">
                        {items}
                    </div>
                );
            }
            return null;
        },
        displayFeedback() {
            if(!this.displayFeedbackEval && this.controlData.DisplayFeedback)
                this.displayFeedbackEval = utils.compile(this, this.controlData.DisplayFeedback);
            if(this.displayFeedbackEval)
                return !!(utils.evaluate(this.displayFeedbackEval, this)?.toLowerCase() === 'true' || this.Parent?.isbase);
            return !!(this.controlData?.DisplayFeedback?.toLowerCase() === 'true' || this.Parent?.isbase);
        },
        direction(){
            if(!this.directionEval && this.controlData.Direction)
                this.directionEval = utils.compile(this, this.controlData.Direction);
            if(this.directionEval)
                return utils.evaluate(this.directionEval, this);
            return 'Normal';
        },
        formSize(){
            if(!this.formSizeEval && this.controlData.FormSize)
                this.formSizeEval = utils.compile(this, this.controlData.FormSize);
            if(this.formSizeEval)
                return utils.evaluate(this.formSizeEval, this);
            return 'Normal';
        },
        verticalAlignChildren() {
            if(!this.verticalAlignChildrenEval && this.controlData.VerticalAlignChildren)
                this.verticalAlignChildrenEval = utils.compile(this, this.controlData.VerticalAlignChildren);
            if(this.verticalAlignChildrenEval)
                return utils.evaluate(this.verticalAlignChildrenEval, this);
            return '';
        },
        horizontalAlignChildren() {
            if(!this.horizontalAlignChildrenEval && this.controlData.HorizontalAlignChildren)
                this.horizontalAlignChildrenEval = utils.compile(this, this.controlData.HorizontalAlignChildren);
            if(this.horizontalAlignChildrenEval)
                return utils.evaluate(this.horizontalAlignChildrenEval, this);
            return '';
        },
        padding() {
            if(!this.paddingEval && this.controlData.Padding)
                this.paddingEval = utils.compile(this, this.controlData.Padding);
            if(this.paddingEval)
                return utils.evaluate(this.paddingEval, this);
            return '';
        },
        fullScreenCapable() {
            if(!this.fullScreenCapableEval && this.controlData.FullScreenCapable !== undefined)
                this.fullScreenCapableEval = utils.compile(this, this.controlData.FullScreenCapable !== undefined);
            if(this.fullScreenCapableEval)
                return utils.evaluate(this.fullScreenCapableEval, this);
            return '';
        }
    },
    async created() {
        this.master_key = utils.generateUUID();

        if (this.controlData.SourceType === 'Raw' && this.controlData.SourceRaw) {
            this.sourceraw = utils.compileObject(this, this.controlData.SourceRaw);
        }
        else if (this.controlData.SourceType === 'Url' && this.controlData.SourceUrl) {
            this.sourceurl = utils.compile(this, this.controlData.SourceUrl);
        }
        if (this.controlData.BreadCrumb && this.controlData.BreadCrumb.Text) {
            if (!this.breadcrumbtext)
                this.breadcrumbtext = utils.compile(this, this.controlData.BreadCrumb.Text);
        }

        // Subscribe to the UIAction_DisplayFeedback event
        if(this.displayFeedback) {
            this.$on('Action-DisplayFeedback', this.performDisplayFeedback);
            
            if(this.controlData.Name) {
                this.$on(`Action-DisplayFeedback:${this.controlData.Name}`, this.performDisplayFeedback);
                EventBus.$on(`Action-DisplayFeedback:${this.controlData.Name}`, this.performDisplayFeedback);
            }
        }

        // Subscribe to the UIAction_FullScreen event
        if(this.fullScreenCapable) {
            this.$on('Action-FullScreen', this.performFullScreen);
            
            if(this.controlData.Name) {
                this.$on(`Action-FullScreen:${this.controlData.Name}`, this.performFullScreen);
                EventBus.$on(`Action-FullScreen:${this.controlData.Name}`, this.performFullScreen);
            }
        }

        // Subscribe to a UIAction_ReplaceContent event
        this.$on('Action-ReplaceContent', this.performReplaceContent);
        this.$on('Action-Refresh', this.performRefresh);
        EventBus.$on('Action-Refresh', this.performRefresh);

        // Subscribe by name so Target can be directed to a specific container
        if (this.controlData.Name) {
            this.$on(`Action-ReplaceContent:${this.controlData.Name}`, this.performReplaceContent);
            this.$on(`Action-Refresh:${this.controlData.Name}`, this.performRefresh);
            EventBus.$on(`Action-Refresh:${this.controlData.Name}`, this.performRefresh);
        }

        // Switch isready to cause the render to run again
        this.isready = true;
    },
    //Mounted Replaced with preRenderComplete
    destroyed() {
        if (this.watcher$)
            this.watcher$();

        // Unsubscribe from the UIAction_DisplayFeedback event
        if(this.displayFeedback) {
            this.$off('Action-DisplayFeedback', this.performDisplayFeedback);
            
            if(this.controlData.Name) {
                this.$off(`Action-DisplayFeedback:${this.controlData.Name}`, this.performDisplayFeedback);
                EventBus.$off(`Action-DisplayFeedback:${this.controlData.Name}`, this.performDisplayFeedback);
            }
        }

        // Unsubscribe from the UIAction_FullScreen event
        if(this.fullScreenCapable) {
            this.$off('Action-FullScreen', this.performFullScreen);
            
            if(this.controlData.Name) {
                this.$off(`Action-FullScreen:${this.controlData.Name}`, this.performFullScreen);
                EventBus.$off(`Action-FullScreen:${this.controlData.Name}`, this.performFullScreen);
            }
        }

        this.$off('Action-ReplaceContent', this.performReplaceContent);
        this.$off('Action-Refresh', this.performRefresh);
        EventBus.$off('Action-Refresh', this.performRefresh);

        // Subscribe by name so Target can be directed to a specific container
        if (this.controlData.Name) {
            this.$off(`Action-ReplaceContent:${this.controlData.Name}`, this.performReplaceContent);
            this.$off(`Action-Refresh:${this.controlData.Name}`, this.performRefresh);
            EventBus.$off(`Action-Refresh:${this.controlData.Name}`, this.performRefresh);
        }
    },
    methods: {
        async preRenderComplete() {
            await this.Refresh();
            this.finishRenderHandler();
    
            // Add a manual watch to the results of the sourceraw expression - if it changes, refresh
            if (this.sourceraw)
                this.watcher$ = this.$watch(
                    function () {
                        try {
                            //utils.log('VerticalStack watching ' + this.controlData.SourceRaw);
                            return utils.evaluateObject(this.sourceraw, this);
                        }
                        catch (e) {
                            utils.log('VerticalStack watcher failed to evaluate expression ' + this.controlData.SourceRaw + '; ' + e);
                            return null;
                        }
                    },
                    function (val, oldval) {
                        utils.log('VerticalStack watcher change detected on ' + this.controlData.SourceRaw);
                        this.Refresh();
                    }
                );
        },
        async performRefresh(action) {
            utils.log(`BaseStackMixin ${this.name} Refresh action`);

            if (action.ActionData && action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            try {
                // Reload any dynamic content (sources)
                await this.Refresh();

                // Update the master key which will cause all child controls to reload
                this.master_key = utils.generateUUID();

                try {
                    await utils.success(action);
                } catch (e) { }
            }
            catch (e) {
                try {
                    await utils.failure(action, { Data: e });
                } catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
        async Refresh() {
            if (this.sourceraw)
                try {
                    this.model = utils.evaluateObject(this.sourceraw, this);
                }
                catch (e) {
                    utils.warn(this.type + ' failed to evaluate SourceRaw ' + this.controlData.SourceRaw + ': ' + e);
                }
            else if (this.sourceurl)
                try {
                    this.model = await utils.api.get(utils.evaluate(this.sourceurl, this));
                }
                catch (e) {
                    utils.warn(this.type + ' failed to evaluate SourceUrl ' + this.controlData.SourceUrl + ': ' + e);
                }
        },
        async performReplaceContent(action) {
            utils.log(`${this.type} ReplaceContent`);

            if (action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            try {
                action.controlData.id = utils.generateUUID();
                //action.vars = utils.evaluateObject(action.varsexpn, action.context);
                if (action.breadcrumbtextexpn) {
                    action.controlData.breadcrumbtext = utils.evaluate(action.breadcrumbtextexpn, action.context);
                }

                // Insure the container fully redraws
                //for (let i = 0; i < action.ActionData.ControlData.Controls.length; i++)
                //    action.ActionData.ControlData.Controls[i].$objectId = utils.generateUUID();

                //TODO: Need these to run, however, the technique for replacing content may be off. This
                // needs to run as a prerender the same as when a control is loading for the first time.
                // Which means Postrender will also need to happen as normal.
                // action.ActionData.ControlData.PrerenderActions

                action.controlData.Vars = utils.evaluateObject(action.varsexpn, action.context);

                if (action.ActionData.ControlData.BreadCrumb && action.ActionData.ControlData.BreadCrumb.Action == 'Append') {
                    this.container_list.push(action.controlData);
                }
                else {
                    this.container_list = [action.controlData];
                }

                try {
                    await utils.success(action);
                } catch (e) { }
            }
            catch (e) {
                try {
                    await utils.failure(action, { Status: e.status, StatusText: e.response.statusText });
                } catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
        performDisplayFeedback(action) {
            try {
                utils.log(`${this.type} DisplayFeedback ${action.ActionData.Title} ${action.ActionData.Body}`);
    
                if (action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;
    
                let messageGroup = action.messagegroup ? utils.evaluate(action.messagegroup) : '';
    
                if(messageGroup) {
                    // If there is a message group, the new messages will replace any existing items for 
                    // that message group. We'll loop through the array backwards so we don't mess things up 
                    // when we remove an item.
                    let i = this.feedbackItems.length;
                    while(i--) {
                        if(this.feedbackItems[i].messageGroup === messageGroup) {
                            this.feedbackItems.splice(i, 1);
                        }
                    }
                }
    
                if(action.messages.length) {
                    let finalArray = [];
    
                    action.messages.forEach(message => {
                       finalArray.push({
                           messageType: message.messagetype,
                           message: utils.evaluate(message.message),
                           messageGroup: messageGroup,
                       });
                    });
    
                    this.feedbackItems.push(...finalArray);
                }

                utils.success(action)
            } 
            catch (e) {
                utils.failure(action, { Status: e.status, StatusText: e.response.statusText })
            }
            finally {
                utils.complete(action);

                action.FinishFunc(true);
            }
        },
        performFullScreen(action) {
            try {
                utils.log(`ActionService FullScreen ${action.ActionData.Title} ${action.ActionData.Body}`);

                if (action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

                this.$el.webkitRequestFullScreen();
                this.isFullScreen = true;

                utils.success(action);
            }
            catch(e) {
                utils.failure(action, { Status: e.status, StatusText: e.response.statusText });
            }
            finally {
                utils.complete(action);

                action.FinishFunc(true);
            }
        },
        navToBreadCrumb(e, index) {
            if (index < 0)
                this.container_list.splice(0, this.container_list.length);
            else
                this.container_list.splice(index + 1, this.container_list.length - index);
        },

        generateItems(h, controlData, items, model, index, parentType) {
            let tempItems = []
            for (let i = 0; i < controlData.Controls.length; i++) {
                const c = controlData.Controls[i];
                if (!(typeof c === 'object'))
                    continue;

                let DynamicControl = utils.getDynamicComponent(h, c);

                if (!DynamicControl)
                    DynamicControl = 'default-unknown';

                if (!c.$objectId) c.$objectId = utils.generateUUID();

                let id = `${this.master_key}_${c.$objectId}`;
                if (index) id = `${index}_${id}`;

                tempItems.push(
                    <DynamicControl
                        key={id}
                        on={{ 'finished-render': () => this.finishRenderHandler() }}
                        type={c.ControlType}
                        name={c.ControlData ? c.ControlData.Name : ''}
                        root={this.root}
                        parentType={parentType}
                        controlData={c.ControlData}
                        controlURL={c.ControlURL}
                        sourceData={model}
                        cacheControl={c.CacheControl}
                        controlEvents={c.Events}>
                    </DynamicControl>
                );
            }
            if(this.direction === 'Reversed')
                tempItems.reverse();
            items.push(...tempItems);
        },
        getHeader(h, controlData) {
            if (controlData.HeaderControls && controlData.HeaderControls.length > 0) {
                const header = [];

                for (let i = 0; i < controlData.HeaderControls.length; i++) {
                    const c = controlData.HeaderControls[i];
                    let DynamicControl = utils.getDynamicComponent(h, c);
                    if (!DynamicControl)
                        DynamicControl = 'default-unknown';

                    if (!c.$objectId) c.$objectId = utils.generateUUID();

                    let id = `${this.master_key}_${c.$objectId}_${i}`;

                    header.push(
                        <DynamicControl
                            on={{ 'finished-render': () => this.finishRenderHandler() }}
                            key={id}
                            name={c.ControlData.Name}
                            root={this.root}
                            parentType="HorizontalStack"
                            controlData={c.ControlData}
                            controlURL={c.ControlURL}
                            controlName={c.Name}
                            scopeitems={this.scopeitems}
                            controlscope={this.controlscope}
                            cacheControl={this.CacheControl}
                            controlEvents={c.Events}>
                        </DynamicControl>
                    );
                }
                return header;
            }
            return null;
        },
        generateItemsFromModel(h, items, controlData, items_id, parentType) {
            if (this.model && typeof this.model === 'object') {
                if (Array.isArray(this.model))
                    for (let i = 0; i < this.model.length; i++) {
                        const item = this.model[i];
                        const item_index = `${i}_${items_id}`;
                        this.generateItems(h, controlData, items, item, item_index, parentType);
                    }
                else
                    Object.keys(this.model).forEach(key => {
                        const item = this.model[key];
                        const item_index = `${key}_${items_id}`;

                        this.generateItems(h, controlData, items, item, item_index, parentType);
                    });
            }
            else if (controlData.Controls)
                this.generateItems(h, controlData, items, null, items_id, parentType);
        },
        generateBreadCrumbs(h) {
            if (this.controlData.BreadCrumb && this.controlData.BreadCrumb.Text) {
                const crumbs = [];
                
                crumbs.push(<v-btn elevation={0} plain small disabled={this.container_list.length === 0} on-click={(e) => this.navToBreadCrumb(e, -1)}>{this.breadCrumbText}</v-btn>);
                crumbs.push(<v-icon x-small>mdi mdi-chevron-right</v-icon>);

                for (let i = 0; i < this.container_list.length; i++) {
                    const c = this.container_list[i];
                    if (c.BreadCrumb && c.BreadCrumb.Action == 'Append' && c.breadcrumbtext) {
                        const bctext = c.breadcrumbtext;
                        
                        crumbs.push(<v-btn elevation={0} plain small disabled={i === this.container_list.length - 1} on-click={(e) => this.navToBreadCrumb(e, i)}>{bctext}</v-btn>);
                        crumbs.push(<v-icon x-small>mdi mdi-chevron-right</v-icon>);
                    }
                }
                
                return (<div style="display: flex; flex-direction: row;">{crumbs}</div>);
            }
            else
                return null;
        },
    },
    props: {
        parentType: null,
    }
};

export default baseStackMixin;