import Vue from 'vue';
import utils from './utils.jsx';
import { appSettings } from './appSettings.js';
import token from '@/Services/token';
import careService from '@/Services/careService';
import careHelpfulFunctions from '@/Application/careHelpfulFunctions';

//let indent = 0;

function childControlByName(children, name, fromname) {
    if (!children || children.length === 0) return null;

    //const indent_chars = '-'.repeat(indent);
    //utils.debug(`${indent_chars} childControlByName(children:${children.length}, name:${name}) fromname:${fromname} children:[${children.map(c => c.name).join(',')}]`);

    //indent++;
    try {
        for (var i = 0; i < children.length; i++) {
            if (children[i].name == name)
                return children[i];

            let child = childControlByName(children[i].componentchildren || children[i].$children, name, children[i].name);
            if (child) return child;
        }
    }
    finally {
    //    indent--;
    }

    return null;
}
function childControlByType(children, type) {
    if (!children) return null;

    for (var i = 0; i < children.length; i++) {
        if (children[i].type == type)
            return children[i];

        let child = childControlByType(children[i].componentchildren || children[i].$children, type);
        if (child) return child;
    }

    return null;
}
function childControlWithEventName(children, eventname, target) {
    if (!children) return null;

    for (var i = 0; i < children.length; i++) {
        const c = children[i];
        if (c._events && (eventname in c._events) && (!target || (target && c.controlData?.Name === target)))
            return c;

        let child = childControlWithEventName(c.componentchildren || c.$children, eventname, target);
        if (child) return child;
    }

    return null;
}
function allChildrenWithEventName(children, eventname, list) {
    if (!children) return 0;

    for (var i = 0; i < children.length; i++) {
        const c = children[i];
        if (c._events && (eventname in c._events))
            list.push(c);

        allChildrenWithEventName(c.componentchildren || c.$children, eventname, list);
    }

    return list.length > 0;
}
function childControls(children, parent, list, level) {
    if (!children) return;

    for (var i = 0; i < children.length; i++) {
        list.push({ type: children[i].type, name: children[i].name, parent: parent, level: level });

        childControls(children[i].componentchildren || children[i].$children, children[i].name, list, level + 1);
    }
}
function allBaseChildControls(children, list) {
    if (!children) return;

    for (var i = 0; i < children.length; i++) {
        if (children[i].isbase) {
            list.push(children[i])
        }

        allBaseChildControls(children[i].componentchildren || children[i].$children, list);
    }
}
function getParent(p) {
    if (p.$attrs && ('c_context' in p.$attrs))
        return p.$attrs.c_context;
    else if ('c_context' in p)
        return p.c_context;
    else if (typeof p.$parent === 'function')
        return p.$parent();
    else
        return p.$parent;
}
function parentByName(p, name) {
    // c_context is set in a DialogBasicModal so we scan up from the source control instead of the dialog

    while (p && (p.name != name || (!p.controlData && !p.element && !p.isbase))) {
        if (p.$attrs && 'c_context' in p.$attrs)
            p = p.$attrs.c_context;
        else
            p = getParent(p);
    }

    return p;
}

const methods = {
    CallFunc: function (name, a, b, c, d, e, f, g) {
        let func;

        let p = this;

        // c_context is set in a DialogBasicModal so we scan up from the source control instead of the dialog
        if (this.$attrs && 'c_context' in this.$attrs)
            p = this.$attrs.c_context;

        while (p) {
            //console.log('CallFunc(' + name + ') scanning ' + p.name);
            if (p.Javascript && name in p.Javascript) {
                func = p.Javascript[name];
                break;
            }

            if (p.$attrs && 'c_context' in p.$attrs)
                p = p.$attrs.c_context;
            else
                p = getParent(p);
        }

        if (func)
            try {
                return func(a, b, c, d, e, f, g);
            }
            catch (e) {
                console.warn(`CallFunc(${name}) failed in ${p.name || p.Name || 'unnamed'}: ${e}`, e);
                console.log(func);
                throw (e);
                //if (p.controlData && p.controlData.Javascript)
                //    console.log(p.controlData.Javascript);
            }
        else
            console.warn('CallFunc(' + name + ') found no matches');

        return null;
    },
    PossibleParentByName: function () {
        const list = [];
        let p = this;

        // c_context is set in a DialogBasicModal so we scan up from the source control instead of the dialog

        while (p) {
            if (p.$attrs && 'c_context' in p.$attrs)
                p = p.$attrs.c_context;
            else
                p = getParent(p);

            if (p && p.controlData && p.controlData.Name)
                list.push(p.controlData.Name);
            else
                list.push('-');
        }

        return list;
    },
    PossibleParentByType: function () {
        const list = [];
        let p = this;

        // c_context is set in a DialogBasicModal so we scan up from the source control instead of the dialog
        let i = 0;
        while (p) {
            if (p && (p.controlData && (p.name || p.type || p.controlData.Name || p.$attrs.type)))
                list.push({ Name: p.name || p.controlData.Name || p.$attrs.name, Type: p.type || p.$attrs.type, HasCContext: 'c_context' in this.$attrs, uid: p._uid, isbase: p.isbase, index: i++ });
            else
                list.push(`-${p ? p._uid : 'null'}${p.name ? (' ' + p.name) : ''}-`);

            if (p.$attrs && 'c_context' in p.$attrs)
                p = p.$attrs.c_context;
            else
                p = getParent(p);
        }

        return list;
    },
    ParentByIndex: function (index) {
        let p = this;

        // c_context is set in a DialogBasicModal so we scan up from the source control instead of the dialog
        let i = 0;
        while (p) {
            if (p && (p.controlData && (p.name || p.type || p.controlData.Name || p.$attrs.type))) {
                if (i == index) break;
                i++;
            }

            if (p.$attrs && 'c_context' in p.$attrs)
                p = p.$attrs.c_context;
            else
                p = getParent(p);
        }

        return p;
    },
    FindDesigner: function () {
        let p = this;

        while (p) {
            p = getParent(p);
            if (p && p.controlData && p.controlData.Title === 'Designer' && p.controlData.Name === 'design1')
                return p;
        }
        return null;
    },
    FindDesignerChildHsm: function () {
        let p = this;

        while (p) {
            p = getParent(p);
            if (p && p.controlData && p.controlData.Title === 'Designer' && p.controlData.Name === 'design1' && p.Model && p.Model.Data && p.Model.Data.ControlType === 'Hsm') {
                return p.Model.Data;
            }
        }
        return null;
    },
    FindDesignerChildHsmStatements: function () {
        let p = this;

        while (p) {
            p = getParent(p);
            if (p && p.controlData && p.controlData.Title === 'Designer' && p.controlData.Name === 'design1' && p.Model && p.Model.Data && p.Model.Data.ControlType === 'Hsm') {
                return p.Model.Data.ControlData.ControlContainer.Controls.filter(c => c.ControlType === 'HSM_Statements').map(c => ({
                    Name: c.ControlData.Name,
                    Arguments: c.ControlData.Arguments,
                    ReturnType: c.ControlData.ReturnType,
                }));
            }
        }
        return [];
    },
    FindDesignerChildHsmStatement: function (name) {
        let p = this;

        while (p) {
            p = getParent(p);
            if (p && p.controlData && p.controlData.Title === 'Designer' && p.controlData.Name === 'design1' && p.Model && p.Model.Data && p.Model.Data.ControlType === 'Hsm') {
                return p.Model.Data.ControlData.ControlContainer.Controls.find(c => c.ControlType === 'HSM_Statements' && c.ControlData.Name === name);
            }
        }
        return undefined;
    },
    ParentByName: function (name, ignoreerrors, debug) {
        let parent = parentByName(this, name);
        if (!parent && name.includes('..'))
            // Angular named form items field..field for children of an array.
            // VuePortal uses field[].field for the same thing.
            parent = parentByName(this, name.replace('..', '[].'));

        if (!parent && !ignoreerrors)
            utils.warn(`ParentByName(${name}) did not find a match`);

        return parent;
    },
    ParentByType: function (type) {
        let p = this;

        // c_context is set in a DialogBasicModal so we scan up from the source control instead of the dialog

        while (p && (!p.type || (p.type && p.type !== type)) && ((p.controlData && p.controlData.Type && p.controlData.Type !== type) || !p.controlData || !p.controlData.Type)) {
            if (p.$attrs && 'c_context' in p.$attrs)
                p = p.$attrs.c_context;
            else
                p = getParent(p);
        }

        return p;
    },
    VarByName: function (name, returnparent, suppresserror) {
        let p = this;

        // c_context is set in a DialogBasicModal so we scan up from the source control instead of the dialog

        while (p) {
            if (p.Vars && name in p.Vars) {
                if (returnparent)
                    return p.Vars;
                else
                    return p.Vars[name];
            }

            if (p.$attrs && 'c_context' in p.$attrs)
                p = p.$attrs.c_context;
            else
                p = getParent(p);
        }

        if (!suppresserror)
            utils.warn(`VarByName('${name}') did not find a match`);

        return null;
    },
    GetParentAt: function (index) {
        let p = this;

        // c_context is set in a DialogBasicModal so we scan up from the source control instead of the dialog

        let i = 0;
        while (p && i < index) {
            if (p.$attrs && 'c_context' in p.$attrs)
                p = p.$attrs.c_context;
            else
                p = getParent(p);

            i++;
        }
        return p;
    },
    ParamByName: function (name, returnparent, suppresserror, log) {
        let p = this;

        // c_context is set in a DialogBasicModal so we scan up from the source control instead of the dialog

        while (p && (
            (
                (!(p.$attrs && 'paramData' in p.$attrs) || p.$attrs.paramData === null) &&
                (!('paramData' in p) || p.paramData === null) &&
                (!('paramDataFunc' in p) || p.paramDataFunc === null)
            ) ||
            (
                p.$attrs.paramData && !(name in p.$attrs.paramData)
            ) ||
            (
                p.paramData && !(name in p.paramData)
            ) ||
            (
                p.paramDataFunc && !(name in p.paramDataFunc)
            )
        )) {
            if (log)
                console.log(`Searching... ParamByName @${(p.Name || p.type || 'unnamed')}`);

            if (p.$attrs && 'c_context' in p.$attrs)
                p = p.$attrs.c_context;
            else
                p = getParent(p);
        }

        if (p) {
            if (log)
                console.log(`Found ParamByName @${(p.Name || p.type || 'unnamed')}`);

            if (returnparent)
                return p.$attrs.paramData || p.paramData || p.paramDataFunc;
            else
                return p.paramData ? p.paramData[name] : (p.$attrs.paramData ? p.$attrs.paramData[name] : (p.paramDataFunc ? p.paramDataFunc[name] : null));
        }
        else {
            if (log)
                console.log('Not found');

            if (!suppresserror)
                utils.warn(`ParamByName('${name}') did not find a match`);

            return '';
        }
    },
    ChildControlByName: function (name, ignoreErrors) {
        let child = childControlByName(this.componentchildren || this.$children, name);
        if (!child && name.includes('..'))
            // Angular named form items field..field for children of an array.
            // VuePortal uses field[].field for the same thing.
            child = childControlByName(this.componentchildren || this.$children, name.replace('..', '[].'));
        return child;
    },
    ChildControlByType: function (type, ignoreErrors) {
        return childControlByType(this.componentchildren || this.$children, type);
    },
    PossibleChildControls: function () {
        const list = [];

        childControls(this.componentchildren || this.$children, this.name, list, 0);

        return list;
    },
    InputByName: function (name) {
        let p = this;

        // c_context is set in a DialogBasicModal so we scan up from the source control instead of the dialog

        while (p && !('Input' in p && p.Input && (typeof p.Input === 'object') && name in p.Input)) {
            //console.log(`SourceData @${(p.controlData ? p.controlData.Name : 'unnamed')}`);
            if (p.$attrs && 'c_context' in p.$attrs)
                p = p.$attrs.c_context;
            else
                p = getParent(p);
        }

        if (p)
            return p.Input[name];
        else
            return null;
    },
    SourceData: function () {
        let p = this;

        // c_context is set in a DialogBasicModal so we scan up from the source control instead of the dialog
        while (p && !('sourceData' in p && p.sourceData) && (!('sourceData' in p.$attrs) || p.$attrs.sourceData === null || p.$attrs.sourceData === undefined)) {
            //console.log(`SourceData @${(p.controlData ? p.controlData.Name : 'unnamed')}`);
            if (p.$attrs && 'c_context' in p.$attrs)
                p = p.$attrs.c_context;
            else
                p = getParent(p);
        }

        if (p) {
            if ('sourceData' in p && typeof p.sourceData === 'function')
                return p.sourceData();
            else if ('sourceData' in p)
                return p.sourceData;

            if (typeof p.$attrs.sourceData === 'function')
                return p.$attrs.sourceData();
            else
                return p.$attrs.sourceData;
        }
        else
            return null;
    },
    SourceKey: function () {
        let p = this;

        // c_context is set in a DialogBasicModal so we scan up from the source control instead of the dialog

        //while (p && !('sourceIndex' in p) && (!('sourceIndex' in p.$attrs) || p.$attrs.sourceIndex === null)) {
        while (p && !('sourceIndex' in p && p.sourceIndex) && (!('sourceIndex' in p.$attrs) || p.$attrs.sourceIndex === null || p.$attrs.sourceIndex === undefined)) {
            //console.log(`SourceData @${(p.controlData ? p.controlData.Name : 'unnamed')}`);
            if (p.$attrs && 'c_context' in p.$attrs)
                p = p.$attrs.c_context;
            else
                p = getParent(p);
        }

        if (p) {
            if ('sourceIndex' in p)
                return p.sourceIndex;

            return p.$attrs.sourceIndex;
        }
        else
            return null;
    },
    RowData: function () {
        let p = this;

        // c_context is set in a DialogBasicModal so we scan up from the source control instead of the dialog

        while (p && !('rowData' in p) && (!('rowData' in p.$attrs) || p.$attrs.rowData === null) && !('rowDataTemp' in p)) {
            p = getParent(p);
        }

        if (p) {
            // rowDataTemp is used in the grid cells cor SortAndFilterTemplate
            if ('rowDataTemp' in p)
                return p.rowDataTemp;
            else if ('rowData' in p && typeof p.rowData === 'function')
                return p.rowData();
            else if ('rowData' in p)
                return p.rowData;

            if (typeof p.$attrs.rowData === 'function')
                return p.$attrs.rowData();
            else
                return p.$attrs.rowData;
        }
        else
            return null;
    },
    RowIndex: function () {
        let p = this;

        // c_context is set in a DialogBasicModal so we scan up from the source control instead of the dialog

        while (p && !('rowIndex' in p)) {
            if (p.$attrs && 'c_context' in p.$attrs)
                p = p.$attrs.c_context;
            else
                p = getParent(p);
        }

        if (p) {
            if ('rowIndex' in p)
                return p.rowIndex;
            else
                return -1;
        }
        else
            return -1;
    },
    AdditionalRowData: function (name) {
        let p = this;

        while (p && !('getAdditionalRowData' in p)) {
            if (p.$attrs && 'c_context' in p.$attrs)
                p = p.$attrs.c_context;
            else
                p = getParent(p);
        }

        if (p)
            return p.getAdditionalRowData(name);
        else
            return null;
    },
    Query: function (name) {
        return this.QueryString(name);
    },
    QueryString: function (name) {
        if (!this.Root.query || !(typeof this.Root.query === 'string'))
            return undefined;

        const parts = this.Root.query.substring(1).split('&');
        for (let i = 0; i < parts.length; i++) {
            const kv = parts[i].split('=');
            if (kv[0] == name)
                return kv.length > 1 ? decodeURIComponent(kv[1]) : '';
        }

        // Allow the route portion to include query parameters
        // Ex: /lobby?NoHeader=true#/Control/OmniChannel/v2/Chat/Preview?data=...
        if (this.Root.route && this.Root.route.includes('?')) {
            const idx = this.Root.route.indexOf('?');
            const route = this.Root.route.substr(idx + 1);
            const rparts = route.split('&');
            for (let i = 0; i < rparts.length; i++) {
                const kv = rparts[i].split('=');
                if (kv[0] == name)
                    return kv.length > 1 ? decodeURIComponent(kv[1]) : '';
            }
        }
        return undefined;
    },
    GenerateUUID: function () {
        var d = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    },
    isExpressionOnly(text) {
        let matchingExpression = new RegExp("{{.*?\\}\\}|{\\*.*?\\*\\}|{\\#.*?\\#\\}|{\\%.*?\\%\\}", "g"); // the "?" makes the expression minimal (not greedy), only capturing characters upto the first "#}"

        let matches = text.match(matchingExpression); // See if there are any matches for object substitution in the string

        return matches && matches.length === 1 && matches[0] === text;
    },
    Translate: function (text, isToolTip) {
        if (!text || typeof text === 'number')
            return text;

        if (this.isExpressionOnly(text))
            return text;

        // we ran into an issue where a multi-megabyte image was translated, creating a very large key- which resulted in replication issues.
        // to prevent that from happening in the future a key length protection was put in place. 500 was an arbitrary value and may need to be adjusted if it is determinded to be improper.
        if (text.length > 500)
            return text;


        let customerId = '50'; // if no customerid specified use the SystemId

        if (careService.globalTranslationList[token.CustomerID()] && (text in careService.globalTranslationList[token.CustomerID()]))
            customerId = token.CustomerID();

        let docInfo;

        // TODO: Implement doc info
        // if (c && ((c.$controlModel && c.$controlModel.DocInfo) || (c.DocInfo))) {
        //     docInfo = (c.$controlModel && c.$controlModel.DocInfo) ? c.$controlModel.DocInfo() : c.DocInfo();
        //     if (docInfo && docInfo.CustomerID)
        //         customerid = docInfo.CustomerID;
        // }

        let shouldTranslate = true;
        let shouldTranslateTooltip = true;
        let translatedText = text;

        if (this.controlData) {
            if ('ShouldTranslate' in this.controlData)
                shouldTranslate = this.controlData.ShouldTranslate;

            if ('ShouldTranslateTooltip' in this.controlData)
                shouldTranslateTooltip = this.controlData.ShouldTranslateTooltip;
        }

        if ((!isToolTip && shouldTranslate) || (isToolTip && shouldTranslateTooltip)) {
            //check translist for translation
            if (careService.globalTranslationList[customerId] && (text in careService.globalTranslationList[customerId])) {
                translatedText = careService.globalTranslationList[customerId][text] || text; // if the value is empty string "", return the text (this translation list is optimized to return "" if the key and value are the same)
            }
            else if ((text || "").trim()) {
                careService.updateTranslationList(text, docInfo);
            }
        }

        return appSettings.DebugTranslationPrefixSetting ? ("XX-" + translatedText) : translatedText;
    },
    FindParentWithEventListener(eventname, target, log) {
        let p = this;

        if (p._events && (eventname in p._events) && (!target || p.controlData?.Name === target)) {
            if (log)
                utils.log(`Found @this ${this.name || this.type}`);

            return p;
        }

        // c_context is set in a DialogBasicModal so we scan up from the source control instead of the dialog
        if (this.$attrs && 'c_context' in this.$attrs) {
            if (log)
                utils.log(`A c_context is in @${this.name || p.type}`);

            p = this.$attrs.c_context;
        }

        while (p && ((!p._events || !(eventname in p._events)) || (target && p.controlData?.Name !== target))) {
            if (log)
                utils.log(`Skipping @${p.name || p.type}`);

            p = getParent(p);
        }

        if (log)
            if (p)
                utils.log(`Found @${p.name || p.type}`);
            else
                utils.log('Not found');

        return p;
    },
    FindChildWithEventListener(eventname, target) {
        if (this._events && (eventname in this._events))
            return this;

        return childControlWithEventName(this.componentchildren || this.$children, eventname, target);
    },
    FindAllChildrenWithEventListener(eventname) {
        const list = [];
        if (this._events && (eventname in this._events))
            list.push(this);

        allChildrenWithEventName(this.componentchildren || this.$children, eventname, list);
        return list;
    },
    FindAllBaseContainers() {
        const list = [];

        allBaseChildControls(this.componentchildren || this.$children, list);
        return list;
    },
    addJavascript(context, code) {
        const finalContext = context || this;
        const api = utils.apiWrapper; // apiService;
        const chf = careHelpfulFunctions;
        const care = careService;

        let theJavascript = eval("new (" + code + ")(care, null, api, finalContext, chf);");

        if (this.Javascript)
            this.Javascript = { ...this.Javascript, ...theJavascript };
        else
            this.Javascript = eval("new (" + code + ")(null, null, api, this, chf);");

        for (let key in this.Javascript)
            if (typeof this.Javascript[key] === 'function')
                this.Javascript[key] = this.Javascript[key].bind(this.Javascript);
    }
}

export default methods;