import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import careHelpfulFunctions from '../../careHelpfulFunctions.jsx';
import methods from '../../../Shared/methods';
import filters from '../../../Shared/filters';
import computed from '../../../Shared/computed';

Vue.component('sform-card-layout', {
    data: () => ({
        readfunc: null,
        writefunc: null,
        readparentfunc: null,
    }),
    props: {
        formtype: null,
        colspan: false,
        xxname: '',
        root: null,
        form: null,
        element: null,
        cmodel: null,
        modelkey: null,
        fieldset_index: null,
        array_index: null,
        layouttype: null,
        condensed: false,
        depth: 0,
        type: null,
        noinput: null,
        autofocus: false,
        notitle: false,
        schema: null,
        scopeitems: null,
        controlscope: null,
        appearance: null,
        directives: null
    },
    created() {
        if (this.element && this.element.key) {
            this.readfunc = utils.forms.getModelElementReader(this.element.key, this.modelkey);
            this.writefunc = utils.forms.getModelElementWriter(this.element.key, this.modelkey);

            // Make another reader to return the parent .
            if (this.element.key.length <= 1) {
                this.readparentfunc = { func: (model) => model, code: '(model) => model' };
            }
            else {
                // Trim off the last element to refer to the parent
                const keys = this.element.key.slice(0, this.element.key.length - 1);
                this.readparentfunc = utils.forms.getModelElementReader(keys, this.modelkey);
            }

            //utils.debug(` +++ +++ Schema element ${this.name} setting reader to ${this.readfunc.code}`);
            //utils.debug(` +++ +++ Schema element ${this.name} setting writer to ${this.writefunc.code}`);
            //utils.debug(` +++ +++ Schema element ${this.name} setting ParentModel reader to ${this.readparentfunc.code}`);
            //utils.debug(` +++ +++ Schema element ${this.name} visible? ${this.condition ? 'YES' : 'NO'}`);

            //if (this.condition)
            //    this.govisible();
        }
    },
    async mounted() {
        //this.$watchfunc = this.$watch(
        //    function () { return this.condition; },
        //    function (newvalue) {
        //        if (newvalue)
        //            this.govisible();
        //        else
        //            this.gohidden();
        //    }
        //);
    },
    destroyed() {
        //this.$watchfunc();
    },
    computed: {
        ...computed,
        name: function () {
            // Use the key array to derive a name
            let name = '';
            if (this.element && this.element.key && Array.isArray(this.element.key) && this.element.key.length > 0) {
                name = this.element.key[0];
                for (let i = 1; i < this.element.key.length; i++) {
                    if (this.element.key[i] != '[]')
                        name += '.';

                    name += this.element.key[i];
                }
            }
            return name + '_card';
        },
        label: function () {
            if (this.element.title) {
                return this.element.title;// this.Translate(this.element.title);
            }
            else if (this.element.key) {
                let title = this.element.key[this.element.key.length - 1];
                if (title == '[]' && this.element.key.length > 1)
                    title = this.element.key[this.element.key.length - 2];
                return title;//this.Translate(title);
            }
            else
                return null;
        },
        description: function () {
            return this.element.description ? this.element.description : null;
        },
        condition: function () {
            if (this.element && this.element.$$condition && typeof this.element.$$condition === 'function')
                try {
                    return this.element.$$condition(this, careHelpfulFunctions);
                }
                catch (e) {
                    utils.warn(`Input element failed to evaluate condition for ${this.modelkey ? this.modelkey.join('.') : '?'} as ${this.layouttype}: ${this.element.condition || (this.element.schema ? (this.element.schema.condition || 'NA') : 'NA')}`, e);
                    return true;
                }
            else
                return true;
        },
        readonly: function () {
            if (this.$$readonly)
                return utils.evaluate(this.$$readonly, this);

            let expn = this.element.readonly || this.appearance?.readonly;
            if (typeof expn === 'string' && ((expn.substr(0, 2) === '{%' && expn.substr(expn.length - 2, 2) === '%}') || (expn.substr(0, 2) === '{#' && expn.substr(expn.length - 2, 2) === '#}'))) {
                expn = expn.substr(2, expn.length - 4);
                this.$$readonly = utils.compileExpression(this, expn);
                return utils.evaluate(this.$$readonly, this);
            }
            else
                return this.element.readonly || this.appearance?.readonly;
        },
        itemvalue: function () {
            try {
                return this.readfunc.func(this.cmodel);
            }
            catch (e) {
                utils.warn('Failed to evaluate readfunc: ' + this.readfunc.code, e);
                return null;
            }
        },
        elementkey: function () {
            if (!this.element.$objectId)
                this.element.$objectId = utils.generateUUID();

            return `${this.name}_${this.element.$objectId}`;
        },
        FieldValue: {
            get: function () {
                return this.itemvalue;
            },
            set: function (value) {
                this.writefunc.func(this.cmodel, value, Vue);
            }
        },
        ArrayIndex: function () {
            let p = this;
            while (p && (!('array_index' in p) || (typeof p.array_index === 'undefined'))) {
                p = p.$parent;
            }

            if (p)
                return p.array_index;
            else
                return -1;
        },
        FormModel: function () {
            return this.cmodel;
        },
        ParentModel: function () {
            try {
                if (this.readparentfunc)
                {
                    //utils.forms.insureModelPath(this.cmodel, this.element, this.schema);
                    return this.readparentfunc.func(this.cmodel);
                }
                else
                    return null;
            }
            catch (e) {
                utils.warn(`Failed to evaluate ${this.name} readparentfunc: ${this.readparentfunc ? this.readparentfunc.code : 'N/A'}`, e);
                return null;
            }
        },
    },
    methods: {
        ...methods,
        ...filters,
        //insureModelPath() {
        //    // This walks down from the root schema to insure the model is initialized up to the ParentModel
        //    if (this.element.key)
        //        for (let i = 0; i < this.element.key.length - 1; i++) {
        //            if (this.element.key[i] == '[]')
        //                // Bail out if we are walking through an array path
        //                // (We might make this work sometime, but I don't think it's necessary)
        //                break;

        //            const key = this.element.key.slice(0, i + 1);
        //            let m = this.cmodel;
        //            for (let j = 0; j < key.length - 1; j++)
        //                m = m[key[j]];
                    
        //            let s = this.schema;
        //            for (let i = 0; i < key.length; i++)
        //                s = s.properties[key[i]];
                    
        //            if (s.type == 'object' && !m[key[key.length - 1]])
        //                Vue.set(m, key[key.length - 1], {});
        //            else if (s.type == 'array' && !m[key[key.length - 1]])
        //                Vue.set(m, key[key.length - 1], []);
        //        }

        //},
        govisible() {
            //if (this.element.schema) {
            //    const value = utils.schema.getDefaultModel(this.element.schema);

            //    utils.debug(` +++ Schema element ${this.name} going visible - setting field to ${JSON.stringify(value)}`);

            //    this.writefunc.func(this.cmodel, value, Vue);
            //}
            if (!this.ParentModel) {
                //utils.debug(` +++ Schema element ${this.name} has no ParentModel (undefined)`);
                return;
            }

            // Fill in default value if not already populated
            const last = this.element.key[this.element.key.length - 1];
            if (last != '[]' && !(last in this.ParentModel)) {
                if ('default' in this.element) {
                    //utils.debug(` +++ Schema element ${this.name} going visible - setting field ${last} to ${this.element.default}`);
                    this.writefunc.func(this.cmodel, this.element.default, Vue);
                }
                else if (this.element.schema && ('default' in this.element.schema)) {
                    //utils.debug(` +++ Schema element ${this.name} going visible - setting field ${last} to ${this.element.schema.default}`);
                    this.writefunc.func(this.cmodel, this.element.schema.default, Vue);
                }
                else if (this.element.schema && this.element.schema.type == 'object') {
                    this.writefunc.func(this.cmodel, {}, Vue);
                }
                else if (this.element.schema && this.element.schema.type == 'array') {
                    this.writefunc.func(this.cmodel, [], Vue);
                }
            }
        },
        gohidden() {
            //this.writefunc.func(this.cmodel, null, Vue);

            // Fill in default value if not already populated
            const last = this.element.key[this.element.key.length - 1];
            if (last != '[]' && (last in this.ParentModel)) {
                //utils.debug(` +++ Schema element ${this.name} going hidden - removing ${last} from ParentModel`);
                Vue.delete(this.ParentModel, last);
            }
        },
        generateElements(h, form, LayoutType) {
            const elements = [];
            for (let i = 0; i < form.length; i++) {
                const element = form[i];

                let formtype = `sform-${element.type}`;

                let modelkey;

                if (element.key) {
                    modelkey = [...element.key];

                    if (this.modelkey && this.modelkey.length > 0)
                        for (let j = 0; j < this.modelkey.length && j < modelkey.length; j++)
                            if (!modelkey[j] || modelkey[j] == '[]')
                                modelkey[j] = this.modelkey[j];
                }
                else
                    modelkey = this.modelkey;

                if (!element.$objectId)
                    element.$objectId = utils.generateUUID();

                elements.push(
                    <LayoutType
                        key={element.$objectId}
                        formtype={formtype}
                        root={this.root}
                        element={element}
                        form={element.items}
                        cmodel={this.cmodel}
                        modelkey={modelkey}
                        depth={this.depth + 1}
                        fieldset_index={i}
                        appearance={this.appearance}
                        type="FormField"
                        nopadding={true}
                        noinput={this.noinput}
                        readonly={element.readonly || false}
                        schema={this.schema}
                        scopeitems={this.scopeitems}
                        controlscope={this.controlscope}
                        directives={this.directives}
                    >
                    </LayoutType>
                );
            }
            return elements;
        }
    },
    render() {
        if (!this.condition)
            return null;

        const appearance = {
            dense: true,
            hideDetails: false,
            outlined: true,
            singleLine: false,
            //label: this.label,
            //hint: this.description,
            readonly: this.readonly || this.element.noinput,
            disabled: this.readonly
        };

        const directives = [
            {
                name: "on-enter-default"
            }
        ];

        const Tag = this.formtype;

        let item = (
            <Tag
                key={this.elementkey}
                xxname={this.name}
                type={this.formtype}
                root={this.root}
                element={this.element}
                form={this.form}
                cmodel={this.cmodel}
                modelkey={this.modelkey}
                depth={this.depth}
                fieldset_index={this.fieldset_index}
                layouttype={this.layouttype}
                appearance={appearance}
                nopadding={true}
                noinput={this.noinput}
                readonly={this.readonly}
                labelText={this.label}
                hintText={this.description}
                autofocus={this.autofocus}
                notitle={this.notitle}
                schema={this.schema}
                scopeitems={this.scopeitems}
                controlscope={this.controlscope}
                directives={directives}
            >
            </Tag>
        );

        let items;

        if (this.form && this.form.length > 0 &&
            this.element.type != 'tabarray' &&
            this.element.type != 'array' &&
            this.element.type != 'fieldset' &&
            this.element.type != 'section' &&
            this.element.type != 'anyof' &&
            this.element.type != 'actions' &&
            this.element.type != 'conditional' &&
            this.element.schema?.type != 'array' &&
            this.element.schema?.type != 'object') {
            // A sub-items array exists on this element, which means we need to render more fields in a row
            // next to the current one. (Very odd way to describe this, but it's all odd anyway.)
            items = this.generateElements(h, this.form, this.layouttype);
        }
        else if (this.element.Controls && this.element.Controls.length > 0)
            items = utils.generateItemsFromArray(h, this, this.element.Controls, null, 'LayoutCard');

        if (items)
            item = (
                <transition name="component-fade" mode={document.hasFocus() ? 'out-in' : null} tag="div">
                    <div style="display: flex; gap: 5px;">
                        {item}
                        {items}
                    </div>
                </transition>
            );
        else
            item = (
                <transition name="component-fade" mode={document.hasFocus() ? 'out-in' : null} tag="div">
                    {item}
                </transition>
            );

        return item;

    }
});