import Vue from 'vue';
import HorizontalStack from './HorizontalStack.jsx';
import VerticalStack from './VerticalStack.jsx';
import BasicButton from './BasicButton.jsx';
import Text from './Text.jsx';
import Icon from './Icon.jsx';
//import SelectList from './SelectList.jsx';
import BasicForm from './BasicForm.jsx';
import BasicGrid from './BasicGrid.jsx';
import TreeView from './TreeView.jsx';
import DefaultUnknown from './DefaultUnknown.jsx';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

Vue.component('tile-item-dsgn', {
    data: function () {
        return {
        }
    },
    computed: {
        Root: function () {
            return this.root._self;
        },
        selected$: function () {
            return this.Root.SelectedNode && this.Root.SelectedModel == this.designmodel;
        }
    },
    methods: {
        ...methods,
        handleClick(e) {
            this.Root.SelectNode(this);
            e.cancelBubble = true;
            e.stopPropagation();
        },
        DesignModel: function () {
            return this.designmodel;
        },
    },
    props: {
        name: '',
        root: null,
        designmodel: null,
        controlData: {},
        parentType: '',
        titleBar: {}
    },
    render() {
        if (!this.controlData.Controls)
            return null;

        const items = [];

        for (var i = 0; i < this.controlData.Controls.length; i++) {
            const control = this.controlData.Controls[i];
            let DynamicControl = utils.getDynamicComponent(h, control);

            if (!DynamicControl)
                DynamicControl = 'default-unknown';

            DynamicControl += '-dsgn';

            items.push(
                <DynamicControl type={control.ControlType} name={control.ControlData.Name} root={this.root} designmodel={control}>
                </DynamicControl>
            );
        }

        const size = utils.getSize(this.controlData.SizeOptions, this.parentType, this.$parent);

        if (!('width' in size))
            size.width = "500px";

        if (!('height' in size))
            size.height = "700px";

        return (
            <div
                class={{ 'designer-container': true, selected: this.selected$ }}
                style={{ display: "flex", flexDirection: "column", margin: "5px", background: "#f0f0e0", overflow: "auto", ...size }}
                on-click={(e) => this.handleClick(e)}>
                <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
                <stack-vertical-dsgn name="TitleBar" root={this.root} designmodel={this} controlData={this.titleBar}></stack-vertical-dsgn>
                <div style={{ background: "white", height: "10px" }}></div>
                {items}
            </div>
        );
    }
});

Vue.component('layout-tile-dsgn', {
    data: function () {
        return {
        }
    },
    computed: {
        Root: function () {
            return this.root._self;
        },
        selected$: function () {
            return this.Root.SelectedNode && this.Root.SelectedModel == this.designmodel;
        }
    },
    methods: {
        ...methods,
        handleClick(e) {
            this.Root.SelectNode(this);
            e.cancelBubble = true;
            e.stopPropagation();
        },
        DesignModel: function () {
            return this.designmodel;
        },
    },
    props: {
        name: '',
        root: null,
        designmodel: null,
        parentType: '',
        controlData: {}
    },
    render(h) {
        if (!this.controlData.Tiles)
            return <div>No tiles</div>;

        let items = [];

        for (let i = 0; i < this.controlData.Tiles.length; i++)
        {
            const tile = this.controlData.Tiles[i];
            items.push(
                <tile-item-dsgn name={tile.Name} root={this.root} designmodel={tile} controlData={tile.ControlData} titleBar={tile.TitleBar}></tile-item-dsgn>
            );
        }

        const size = utils.getSize(this.controlData.SizeOptions, this.parentType, this.$parent);

        if (!('width' in size))
            size.width = "1200px";

        if (!('height' in size))
            size.height = "700px";

        return (
            <div
                class={{ 'designer-container': true, selected: this.selected$ }}
                style={{ display: "flex", flexDirection: "row", overflow: "auto", ...size }}
                on-click={(e) => this.handleClick(e)}>
                <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
                {items}
            </div>
        );
    }
});