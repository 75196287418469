import Vue from 'vue';
import utils from '../../Shared/utils.jsx';
import methods from '../../Shared/methods';
import filters from '../../Shared/filters';
import computed from '../../Shared/computed';

const contentDef = {
    data: () => ({
        name: 'AgContentDef',
        Input: {},
        rowData: null,

        root: null,
        type: null,
        rowDataArg: null,
        contentDefs: null,
        addrowdata: null,
        scopeitems: null,
        controlscope: null,
        loading: null,

        c_context: null,
    }),
    props: {
    },
    computed: {
        ...computed,
        rowIndex: function () {
            return this.params.rowIndex;
        },
    },
    methods: {
        ...methods,
        ...filters,
        getAdditionalRowData(name) {
            if ('$$additional_row_data' in this.rowData)
                return name ? this.rowData.$$additional_row_data[name] : this.rowData.$$additional_row_data;
            else
                return '';
        },
        handleGridExportText(val) {
            if(this.rowData) {
                if(!this.rowData.exportValues)
                    this.rowData.exportValues = {};
                
                if(!this.rowData.exportValues[this.params.callcorp.field])
                    this.rowData.exportValues[this.params.callcorp.field] = [];
                
                this.rowData.exportValues[this.params.callcorp.field].push(val);
            }
        }
    },
    created() {
        // To make the row data reactive, assign to the data:
        this.rowData = this.params.data;

        // Copy parameters into fields so our interpolations will work
        this.root = this.params.callcorp.root;
        this.type = this.params.callcorp.type;
        this.rowDataArg = this.params.callcorp.rowDataArg;
        this.contentDefs = this.params.callcorp.contentDefs;
        this.addrowdata = this.params.callcorp.addrowdata;
        this.scopeitems = this.params.callcorp.scopeitems;
        this.controlscope = this.params.callcorp.controlscope;
        this.c_context = this.params.callcorp.context;
        this.loading = this.params.callcorp.loading;

        // Add additional_row_data if not already there (first time for each row, we'll compute the AdditionalRowData, but then it is saved on the row)
        if (this.params.callcorp.addrowdata && this.rowData && !('$$additional_row_data' in this.rowData)) {
            Vue.set(this.rowData, '$$additional_row_data', {});

            for (let j = 0; j < this.params.callcorp.addrowdata.length; j++) {
                const add = this.params.callcorp.addrowdata[j];
                if (add.initValue)
                    Vue.set(this.rowData.$$additional_row_data, add.name, utils.evaluateObject(add.initValue, this));

                for (let i = 0; i < add.contentDefs.length; i++) {
                    const c = add.contentDefs[i];
                    if (c.displayexpr && !utils.evaluate(c.displayexpr, this))
                        continue;

                    switch (c.type) {
                        case 'RawInterpolated':
                            Vue.set(this.rowData.$$additional_row_data, add.name, utils.evaluateObject(c.valueexpr, this));
                            break;

                        case 'AsyncLookup':
                            Vue.set(this.rowData.$$additional_row_data, add.name, {});
                            const url = utils.evaluate(c.urlexpr, this);
                            utils.api.get(url, false, c.cache).then(function (res) {
                                //utils.debug(`AdditionalRowData[${j},${i}] ${add.name} res:${JSON.stringify(res)} (url:${url}; cache:${c.cache})`);
                                this.Input = { Data: res };
                                Vue.set(this.rowData.$$additional_row_data, add.name, utils.evaluateObject(c.valueexpr, this));
                            }.bind(this));
                            break;
                    }
                }
            }
        }
    },
    render(h) {
        const elements = [];

        if (this.loading && this.rowData === undefined) {
            return <div><div class="mdi-animate-spin" style="display: inline-block"><span class="mdi mdi-loading"></span></div>One moment please…</div>;
        }

        for (let k = 0; k < this.params.callcorp.contentDefs.length; k++) {
            const content = this.params.callcorp.contentDefs[k];

            if (!content.Controls) continue;

            if (content.displayExpression)
                if (!utils.evaluate(content.displayExpression, this)) continue;

            for (let j = 0; j < content.Controls.length; j++) {
                const control = content.Controls[j];

                let DynamicControl = utils.getDynamicComponent(h, control);
                if (!DynamicControl) {
                    elements.push(<span>Control type of {control.ControlType} is unknown</span>);
                    continue;
                }

                elements.push(
                    <DynamicControl
                        key={control.$objectId}
                        type={control.ControlType}
                        name={control.ControlData.Name}
                        root={this.params.callcorp.root}
                        controlData={control.ControlData}
                        parentType="ContentDef"
                        rowData={() => this.rowData}
                        controlURL={control.ControlURL}
                        controlName={control.Name}
                        scopeitems={this.params.callcorp.scopeitems}
                        on-grid-exported-text={this.handleGridExportText}
                        controlscope={this.params.callcorp.controlscope}
                        controlEvents={control.Events}>
                    </DynamicControl>
                );
            }
        }

        return <v-row class={{ 'c-AgContentDef': true, [`c-name-${this.name || 'unnamed'}`]: true }} no-gutters style="flex-wrap: nowrap; align-items: center;">{elements}</v-row>;
    }
};

export default contentDef;