import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

Vue.component('tree-label-dsgn', {
    data: () => ({
        title: null,
        titletext: null,
        schema: null,
    }),
    mounted() {
        if (this.typeSchema)
            this.Refresh();
    },
    computed: {
        ControlData: function () {
            return this.model.ControlData;
        },
        ActionData: function () {
            return this.model.ActionData;
        },
        ActionExpression: function () {
            return this.model.ActionExpression;
        },
        selected$: function () {
            return this.Root.SelectedModel == this.designmodel;
        },
    },
    methods: {
        ...methods,
        async Refresh() {
            this.schema = await utils.schema.get(this.typeSchema);
            const res = utils.schema.resolve_Of(this.schema);

            this.titletext = res.title;

            if (res.documentEditorTitle)
                this.title = utils.compile(this, res.documentEditorTitle);
            else
                this.title = null;
        }
    },
    props: {
        label: '',
        text: '',
        typeSchema: null,
        model: null,
        parent: null,
        designmodel: null,
    },
    render() {
        let title = this.titletext || this.text || '';
        if (this.title)
            title = utils.evaluate(this.title, this);

        let label = this.label;
        const suffix = title || (this.schema ? this.schema.title : '');
        if (suffix)
            label += ': ' + suffix;

        const color = ('$typeSchema' in this.model) ? 'black' : 'gray';

        if (this.parent.SelectedNode == this.model)
            return <span style={{ whiteSpace: "nowrap", fontWeight: "bold" }}> {label}</span>;
        else
            return <span style={{ whiteSpace: "nowrap", color: color }}> {label}</span>;
    }
});

Vue.component('tree-node-dsgn', {
    data: () => ({
        schema: null,
        title: null,
    }),
    methods: {
        ...methods,
        toggleVisible: function(key)
        {
            this.model[key] = this.model[key] === 'true' ? 'false' : 'true';
        }
    },
    props: {
        name: '',
        root: null,
        parent: null,
        model: null,
        level: 0,
    },
    render() {
        if (!this.model)
            return null;

        const elements = [];
        if (Array.isArray(this.model))
            for (let i = 0; i < this.model.length; i++) {
                const node = this.model[i];

                if (typeof node === 'object') {
                    const visible_key = '$' + i + '_visible';
                    if (!this.model[visible_key])
                        Vue.set(this.model, visible_key, this.level <= 0 ? 'true' : 'false');

                    const vis = this.model[visible_key] === 'true';

                    elements.push(
                        <div style={{ whiteSpace: "nowrap" }}>
                            <span on-click={() => this.toggleVisible(visible_key)}>[{vis ? '-' : '+'}]</span>
                            <tree-label-dsgn label={'(' + i + ')'} typeSchema={node.$typeSchema} parent={this.parent} model={node}></tree-label-dsgn>
                        </div>
                    );

                    if (vis)
                        elements.push(<tree-node-dsgn name={i} root={this.root} parent={this.parent} model={node} level={this.level + 1}></tree-node-dsgn>);
                }
                //else
                //    elements.push(<span>[{i}]</span>);
            }
        else
            for (let key in this.model)
            {
                if (key.substr(0, 1) === '$') continue;
                const node = this.model[key];

                if (typeof node === 'object')
                {
                    const visible_key = '$' + key + '_expanded';
                    if (!this.model[visible_key])
                        Vue.set(this.model, visible_key, this.level <= 0 ? 'true' : 'false');

                    const vis = this.model[visible_key] === 'true';

                    elements.push(
                        <div style={{ whiteSpace: "nowrap" }}>
                            <span on-click={() => this.toggleVisible(visible_key)}>[{vis ? '-' : '+'}]</span>
                            <tree-label-dsgn label={key} typeSchema={node.$typeSchema} parent={this.parent} model={node}></tree-label-dsgn>
                        </div>
                    );
                    if (vis)
                        elements.push(<tree-node-dsgn name={key} root={this.root} parent={this.parent} model={node} level={this.level + 1}></tree-node-dsgn>);
                }
                //else
                //    elements.push(<span>{key}</span>);
            }

        return (
            <div style={{ marginLeft: "15px" }}>
                {elements}
            </div>
        );
    }
});

Vue.component('tree-view-dsgn', {
    data: () => ({
        sourceurl: null,
        sourceraw: null,
        model: null,
        selectednode: {},
    }),
    mounted() {
        this.Refresh();
    },
    computed: {
        Root: function () {
            return this.root._self;
        },
        Model: function () {
            return this.model;
        },
        DesignModel: function () {
            return this.designmodel;
        },
        selected$: function () {
            return this.Root.SelectedModel == this.designmodel;
        },
    },
    methods: {
        ...methods,
        SelectNode(node)
        {
            this.selectednode = node;
        },
        handleClick(e) {
            this.Root.SelectNode(this);
            e.cancelBubble = true;
            e.stopPropagation();
        },
        async Refresh() {
            try {
                if (this.controlData.SourceType == 'Url' && this.controlData.SourceUrl)
                    this.model = await utils.api.get(this.controlData.SourceUrl);
                else if (this.controlData.SourceRaw)
                    this.model = this.controlData.SourceRaw;
                else
                    this.model = {};
            }
            catch (e)
            {
                this.model = {};
            }
        }
    },
    props: {
        name: '',
        root: null,
        designmodel: null,
        parentType: '',
        controlData: {}
    },
    render() {
        if (!this.model)
            return (
                <div
                    class="designer-container"
                    style={{ padding: "5px", overflow: "auto", ...utils.getSize(this.controlData.SizeOptions, this.parentType, this.$parent) }}
                    on-click={(e) => this.handleClick(e)}>
                    <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
                    <h1>{this.controlData.Title}</h1>
                </div>
            );
        else
            return (
                <div
                    class={{ 'designer-container': true, selected: this.selected$ }}
                    style={{ padding: "5px", overflow: "auto", ...utils.getSize(this.controlData.SizeOptions, this.parentType, this.$parent) }}
                    on-click={(e) => this.handleClick(e)}>
                    <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
                    <h2>{this.controlData.Title}</h2>
                    <div style={{ whiteSpace: "nowrap" }}>
                        <tree-label-dsgn label="Root" typeSchema={this.model.$typeSchema} parent={this._self} model={this.model}></tree-label-dsgn>
                    </div>
                    <tree-node-dsgn name={this.controlData.Name || 'Tree'} root={this.root} parent={this._self} model={this.model} level={0}></tree-node-dsgn>
                </div>
            );
    }
});