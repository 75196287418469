<template>
    <div :style="sizeStyle" style="width:fit-content" class="d-flex flex-column" v-if="this.todisplay" v-show="this.isvisible" :class="{ 'c-RoosterEditor': true, [`c-name-${this.name || 'unnamed'}`]: true }">
        <RoosterEditorBase 
            v-if="preRenderDone" 
            :readonly="readOnly" 
            :max-height="maxHeight" 
            :initial-content="source" 
            :disable-formatting="!enableFormatting" 
            :allow-send="showSendButton" 
            :send-on-enter="showSendButton"
            :send-disabled="sendDisabled"
            :disableImagePicker="!enableImagePicker"
            :disableQuickText="!enableQuickText"
            @input="sync"
            @send="doSend"/>
    </div>
</template>

<script>
import RoosterEditorBase from './vuecontrols/RoosterEditorBase.vue';
import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import careHelpfulFunctions from '../careHelpfulFunctions.jsx';

import utils from '../../Shared/utils.jsx';

export default {
    name: "RoosterEditor",
    mixins: [BaseComponent],
    props: {
        initialContent: {
            type: String,
            default: ''
        },
    },
    components: {
        RoosterEditorBase
    },
    data() {
        return {
            preRenderDone: false,
            readOnlyEval: null,
            maxHeightEval: null,
            enableFormattingEval: null,
            showSendButtonEval: null,
            setValueFunction: null,
            sourceUrlEval: null,
            sourceRawInterpolatedEval: null,
            data: null,
            model: "",
            value: '',
        }
    },
    computed: {
        readOnly() {
            try {
                if(this.readOnlyEval === null && this.controlData.Readonly)
                    this.readOnlyEval = utils.compile(this, this.controlData.Readonly);
                if(this.readOnlyEval)
                    return utils.evaluate(this.readOnlyEval, this);
                return false;
            }
            catch (e) {
                utils.warn('ReadOnly could not evaluate expression: ' + this.controlData.Readonly + '; ' + e);
                return false;
            }
        },
        maxHeight() {
            try {
                if(this.maxHeightEval === null && this.controlData.MaxHeight)
                    this.maxHeightEval = utils.compile(this, this.controlData.MaxHeight);
                if(this.maxHeightEval)
                    return utils.evaluate(this.maxHeightEval, this);
                return null;
            }
            catch (e) {
                utils.warn('MaxHeight could not evaluate expression: ' + this.controlData.MaxHeight + '; ' + e);
                return null;
            }
        },
        enableFormatting() {
            try {
                if(this.enableFormattingEval === null && this.controlData.EnableFormatting)
                    this.enableFormattingEval = utils.compile(this, this.controlData.EnableFormatting);
                if(this.enableFormattingEval)
                    return utils.evaluate(this.enableFormattingEval, this);
                return null;
            }
            catch (e) {
                utils.warn('EnableFormatting could not evaluate expression: ' + this.controlData.EnableFormatting + '; ' + e);
                return null;
            }
        },
        enableImagePicker() {
            return this.enableFormatting && this.controlData.EnableImagePicker;
        },
        enableQuickText() {
            return ('EnableQuickText' in this.controlData) ? this.controlData.EnableQuickText : true;
        },
        showSendButton() {
            try {
                if(this.showSendButtonEval === null && this.controlData.ShowSendButton)
                    this.showSendButtonEval = utils.compile(this, this.controlData.ShowSendButton);
                if(this.showSendButtonEval)
                    return utils.evaluate(this.showSendButtonEval, this);
                return null;
            }
            catch (e) {
                utils.warn('ShowSendButton could not evaluate expression: ' + this.controlData.ShowSendButton + '; ' + e);
                return null;
            }
        },
        sendDisabled() {
            if(!this.showSendButton)
                return true;
            try {
                if(this.sendDisabledEval === null && this.controlData.SendIsDisabled)
                    this.sendDisabledEval = utils.compile(this, this.controlData.SendIsDisabled);
                if(this.sendDisabledEval)
                    return utils.evaluate(this.sendDisabledEval, this);
                return false;
            }
            catch (e) {
                utils.warn('SendIsDisabled could not evaluate expression: ' + this.controlData.SendIsDisabled + '; ' + e);
                return false;
            }
        },
        publishField: {
            set(value){
                if(this.setValueFunction === null && this.controlData.PublishField) {
                    let vueSet = utils.helpers.convertSetValueToVueSet(this.controlData.PublishField, 'richText');
                    this.setValueFunction = new Function('richText', 'context', 'util', 'vue', `with (context) { ${vueSet} }`);
                }
                if(this.setValueFunction) {
                    this.setValueFunction(value, this, careHelpfulFunctions, Vue);
                }
                this.model = value;
            },
            get() {
                return this.model;
            }
        },
        sourceUrl() {
            return utils.evaluate(this.sourceUrlEval, this);
        },
        sourceRawInterpolated(){
            return utils.evaluate(this.sourceRawInterpolatedEval, this);
        },
        source() {
            switch(this.controlData.DataType.toLowerCase()) {
                case 'url':
                    return this.data;
                case 'raw': 
                case 'rawinterpolated': return this.sourceRawInterpolated;
                default: return '';
            }
        },

        Text() {
            return this.value;
        },
        HtmlRaw() {
            return this.model || '';
        },
    },
    methods: {
        async setup() {
            switch(this.controlData.DataType.toLowerCase()) {
                case 'url': {
                    this.sourceUrlEval = utils.compile(this, this.controlData.DataURL);
                    this.data = await utils.api.get(this.sourceUrl);
                    break;
                }
                case 'raw': 
                case 'rawinterpolated': {
                    this.sourceRawInterpolatedEval = utils.compile(this, this.controlData.Data);
                    break;
                }
            }

            this.finishRenderHandler(this);
        },
        sync(value, plain) {
            this.value = plain;
            this.publishField = value;
        },
        async preRenderComplete() {
            await this.setup();
            this.preRenderDone = true;
        },
        async doSend() {
            await utils.executeAndCompileAllActions(this.controlData.SendActions, { Text: this.model, PlainText: this.value }, this);
        },
        ClearRooster() {
            // ?
            utils.error('RoosterEditor.ClearRooster() Not implemented');
        },
        InsertText(text) {
            // c.editor.insertContent(text);
            utils.error('RoosterEditor.InsertText(text) Not implemented');
        },
    },
};
</script>
