import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import weekday from 'dayjs/plugin/weekday';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import objectSupport from 'dayjs/plugin/objectSupport';
import {appSettings} from '../Shared/appSettings';

import 'dayjs/locale/af/';
import 'dayjs/locale/am/';
import 'dayjs/locale/ar-dz/';
import 'dayjs/locale/ar-iq/';
import 'dayjs/locale/ar-kw/';
import 'dayjs/locale/ar-ly/';
import 'dayjs/locale/ar-ma/';
import 'dayjs/locale/ar-sa/';
import 'dayjs/locale/ar-tn/';
import 'dayjs/locale/ar/';
import 'dayjs/locale/az/';
import 'dayjs/locale/be/';
import 'dayjs/locale/bg/';
import 'dayjs/locale/bi/';
import 'dayjs/locale/bm/';
import 'dayjs/locale/bn/';
import 'dayjs/locale/bo/';
import 'dayjs/locale/bs/';
import 'dayjs/locale/ca/';
import 'dayjs/locale/cs/';
import 'dayjs/locale/cv/';
import 'dayjs/locale/cy/';
import 'dayjs/locale/da/';
import 'dayjs/locale/de-at/';
import 'dayjs/locale/de-ch/';
import 'dayjs/locale/de/';
import 'dayjs/locale/dv/';
import 'dayjs/locale/el/';
import 'dayjs/locale/en-au/';
import 'dayjs/locale/en-ca/';
import 'dayjs/locale/en-gb/';
import 'dayjs/locale/en-ie/';
import 'dayjs/locale/en-il/';
import 'dayjs/locale/en-in/';
import 'dayjs/locale/en-nz/';
import 'dayjs/locale/en-sg/';
import 'dayjs/locale/en-tt/';
import 'dayjs/locale/en/';
import 'dayjs/locale/eo/';
import 'dayjs/locale/es-do/';
import 'dayjs/locale/es-mx/';
import 'dayjs/locale/es-pr/';
import 'dayjs/locale/es-us/';
import 'dayjs/locale/es/';
import 'dayjs/locale/et/';
import 'dayjs/locale/eu/';
import 'dayjs/locale/fa/';
import 'dayjs/locale/fi/';
import 'dayjs/locale/fo/';
import 'dayjs/locale/fr-ca/';
import 'dayjs/locale/fr-ch/';
import 'dayjs/locale/fr/';
import 'dayjs/locale/fy/';
import 'dayjs/locale/ga/';
import 'dayjs/locale/gd/';
import 'dayjs/locale/gl/';
import 'dayjs/locale/gom-latn/';
import 'dayjs/locale/gu/';
import 'dayjs/locale/he/';
import 'dayjs/locale/hi/';
import 'dayjs/locale/hr/';
import 'dayjs/locale/ht/';
import 'dayjs/locale/hu/';
import 'dayjs/locale/hy-am/';
import 'dayjs/locale/id/';
import 'dayjs/locale/is/';
import 'dayjs/locale/it-ch/';
import 'dayjs/locale/it/';
import 'dayjs/locale/ja/';
import 'dayjs/locale/jv/';
import 'dayjs/locale/ka/';
import 'dayjs/locale/kk/';
import 'dayjs/locale/km/';
import 'dayjs/locale/kn/';
import 'dayjs/locale/ko/';
import 'dayjs/locale/ku/';
import 'dayjs/locale/ky/';
import 'dayjs/locale/lb/';
import 'dayjs/locale/lo/';
import 'dayjs/locale/lt/';
import 'dayjs/locale/lv/';
import 'dayjs/locale/me/';
import 'dayjs/locale/mi/';
import 'dayjs/locale/mk/';
import 'dayjs/locale/ml/';
import 'dayjs/locale/mn/';
import 'dayjs/locale/mr/';
import 'dayjs/locale/ms-my/';
import 'dayjs/locale/ms/';
import 'dayjs/locale/mt/';
import 'dayjs/locale/my/';
import 'dayjs/locale/nb/';
import 'dayjs/locale/ne/';
import 'dayjs/locale/nl-be/';
import 'dayjs/locale/nl/';
import 'dayjs/locale/nn/';
import 'dayjs/locale/oc-lnc/';
import 'dayjs/locale/pa-in/';
import 'dayjs/locale/pl/';
import 'dayjs/locale/pt-br/';
import 'dayjs/locale/pt/';
import 'dayjs/locale/rn/';
import 'dayjs/locale/ro/';
import 'dayjs/locale/ru/';
import 'dayjs/locale/rw/';
import 'dayjs/locale/sd/';
import 'dayjs/locale/se/';
import 'dayjs/locale/si/';
import 'dayjs/locale/sk/';
import 'dayjs/locale/sl/';
import 'dayjs/locale/sq/';
import 'dayjs/locale/sr-cyrl/';
import 'dayjs/locale/sr/';
import 'dayjs/locale/ss/';
import 'dayjs/locale/sv-fi/';
import 'dayjs/locale/sv/';
import 'dayjs/locale/sw/';
import 'dayjs/locale/ta/';
import 'dayjs/locale/te/';
import 'dayjs/locale/tet/';
import 'dayjs/locale/tg/';
import 'dayjs/locale/th/';
import 'dayjs/locale/tk/';
import 'dayjs/locale/tl-ph/';
import 'dayjs/locale/tlh/';
import 'dayjs/locale/tr/';
import 'dayjs/locale/tzl/';
import 'dayjs/locale/tzm-latn/';
import 'dayjs/locale/tzm/';
import 'dayjs/locale/ug-cn/';
import 'dayjs/locale/uk/';
import 'dayjs/locale/ur/';
import 'dayjs/locale/uz-latn/';
import 'dayjs/locale/uz/';
import 'dayjs/locale/vi/';
import 'dayjs/locale/x-pseudo/';
import 'dayjs/locale/yo/';
import 'dayjs/locale/zh-cn/';
import 'dayjs/locale/zh-hk/';
import 'dayjs/locale/zh-tw/';
import 'dayjs/locale/zh';

dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.extend(weekday);
dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(objectSupport);
dayjs.extend(timezone);

window.moment = dayjs;
window.dayjs = dayjs;

export default {
    setLocale(locale) {
        if(locale)
            dayjs.locale(this.getSupportedLocale(locale));
    },
    /**
     * Gets a supported locale based on the locale that the user selected. This is important because day.js doesn't have a locale 
     * for the default locale for each language. This means that en-us does not exist since us is it's default locale for the english
     * language.
     * 
     * @param {String} locale The locale to find in day.js
     * @returns 
     */
     getSupportedLocale(locale) {
        if(dayjs.Ls[locale])
            return locale;
        else if(locale.includes('-') && dayjs.Ls[locale.split('-')[0]])
            return locale.split('-')[0];
        return 'en';
    },
    get selectedDateLocale() {
        return this.getSupportedLocale(appSettings.LocaleKey);
    },
    getWeekdaysLong() {
        return dayjs.weekdays();
    },
    getWeekdaysShort() {
        return dayjs.weekdaysShort();
    },
    getWeekdaysMin() {
        return dayjs.weekdaysMin();
    },
    getMonthLong() {
        return dayjs.months();
    },
    getMonthShort() {
        return dayjs.monthsShort();
    },
    getTimezoneConvertedDate(date, timezone) {
        var d = dayjs(date);

        return dayjs.tz(d.format('YYYY-MM-DDTHH:mm:ss'), this.windowsTimeToIANATime(timezone));
    },
    getDateInLocale(date, timezone) {
        return dayjs(date).tz(careWindowsToMomentTimezoneMap[timezone]).format('LLL');
    },
    windowsTimeToIANATime(windowsTimezone) {
        if(Intl.supportedValuesOf('timeZone').includes(windowsTimezone))
            return windowsTimezone;

        if (windowsTimezone in careWindowsToMomentTimezoneMap) {
            return careWindowsToMomentTimezoneMap[windowsTimezone];
        }

        return dayjs.tz.guess();
    }
};


export const careWindowsToMomentTimezoneMap = {
    //WindowsTZ - MomentTZ
    "UTC-11": "Etc/GMT+11",
    "Dateline Standard Time": "Etc/GMT+12",
    "UTC-11": "Etc/GMT+11",
    "Aleutian Standard Time": "America/Adak",
    "Hawaiian Standard Time": "Etc/GMT+10",
    "Marquesas Standard Time": "Pacific/Marquesas",
    "Alaskan Standard Time": "America/Anchorage",
    "UTC-09": "Etc/GMT+9",
    "Pacific Standard Time (Mexico)": "America/Tijuana",
    "UTC-08": "Etc/GMT+8",
    "Pacific Standard Time": "PST8PDT",
    "US Mountain Standard Time": "Etc/GMT+7",
    "Mountain Standard Time (Mexico)": "America/Chihuahua",
    "Mountain Standard Time": "MST7MDT",
    "Central America Standard Time": "Etc/GMT+6",
    "Central Standard Time": "CST6CDT",
    "Easter Island Standard Time": "Pacific/Easter",
    "Central Standard Time (Mexico)": "America/Mexico_City",
    "Canada Central Standard Time": "America/Regina",
    "SA Pacific Standard Time": "Etc/GMT+5",
    "Eastern Standard Time (Mexico)": "America/Cancun",
    "Eastern Standard Time": "EST5EDT",
    "Haiti Standard Time": "America/Port-au-Prince",
    "Cuba Standard Time": "America/Havana",
    "US Eastern Standard Time": "America/Indianapolis",
    "Paraguay Standard Time": "America/Asuncion",
    "Mid-Atlantic Standard Time": "Etc/GMT+2",
    "Atlantic Standard Time": "America/Halifax",
    "Venezuela Standard Time": "America/Caracas",
    "Central Brazilian Standard Time": "America/Cuiaba",
    "SA Western Standard Time": "Etc/GMT+4",
    "Pacific SA Standard Time": "America/Santiago",
    "Turks And Caicos Standard Time": "America/Grand_Turk",
    "Newfoundland Standard Time": "America/St_Johns",
    "Tocantins Standard Time": "America/Araguaina",
    "E. South America Standard Time": "America/Sao_Paulo",
    "SA Eastern Standard Time": "Etc/GMT+3",
    "Argentina Standard Time": "America/Buenos_Aires",
    "Greenland Standard Time": "America/Godthab",
    "Montevideo Standard Time": "America/Montevideo",
    "Saint Pierre Standard Time": "America/Miquelon",
    "Bahia Standard Time": "America/Bahia",
    "UTC-02": "Etc/GMT+2",
    "Azores Standard Time": "Atlantic/Azores",
    "Cape Verde Standard Time": "Etc/GMT+1",
    "UTC": "Etc/GMT",
    "Morocco Standard Time": "Africa/Casablanca",
    "GMT Standard Time": "Europe/London",
    "Greenwich Standard Time": "Atlantic/Reykjavik",
    "W. Europe Standard Time": "Europe/Berlin",
    "Central Europe Standard Time": "Europe/Budapest",
    "Romance Standard Time": "Europe/Paris",
    "Central European Standard Time": "Europe/Warsaw",
    "W. Central Africa Standard Time": "Etc/GMT-1",
    "Namibia Standard Time": "Africa/Windhoek",
    "Jordan Standard Time": "Asia/Amman",
    "GTB Standard Time": "Europe/Bucharest",
    "Middle East Standard Time": "Asia/Beirut",
    "Egypt Standard Time": "Africa/Cairo",
    "E. Europe Standard Time": "Europe/Chisinau",
    "Syria Standard Time": "Asia/Damascus",
    "West Bank Standard Time": "Asia/Hebron",
    "South Africa Standard Time": "Etc/GMT-2",
    "FLE Standard Time": "Europe/Kiev",
    "Turkey Standard Time": "Europe/Istanbul",
    "Israel Standard Time": "Asia/Jerusalem",
    "Kaliningrad Standard Time": "Europe/Kaliningrad",
    "Libya Standard Time": "Africa/Tripoli",
    "Arabic Standard Time": "Asia/Baghdad",
    "Arab Standard Time": "Asia/Riyadh",
    "Belarus Standard Time": "Europe/Minsk",
    "E. Africa Standard Time": "Etc/GMT-3",
    "Iran Standard Time": "Asia/Tehran",
    "Arabian Standard Time": "Etc/GMT-4",
    "Astrakhan Standard Time": "Europe/Ulyanovsk",
    "Azerbaijan Standard Time": "Asia/Baku",
    "Russia Time Zone 3": "Europe/Samara",
    "Mauritius Standard Time": "Indian/Mauritius",
    "Georgian Standard Time": "Asia/Tbilisi",
    "Caucasus Standard Time": "Asia/Yerevan",
    "Afghanistan Standard Time": "Asia/Kabul",
    "West Asia Standard Time": "Etc/GMT-5",
    "Ekaterinburg Standard Time": "Asia/Yekaterinburg",
    "Pakistan Standard Time": "Asia/Karachi",
    "India Standard Time": "Asia/Calcutta",
    "Sri Lanka Standard Time": "Asia/Colombo",
    "Nepal Standard Time": "Asia/Katmandu",
    "Central Asia Standard Time": "Etc/GMT-6",
    "Bangladesh Standard Time": "Asia/Dhaka",
    "Omsk Standard Time": "Asia/Omsk",
    "Myanmar Standard Time": "Asia/Rangoon",
    "SE Asia Standard Time": "Etc/GMT-7",
    "Altai Standard Time": "Asia/Barnaul",
    "W. Mongolia Standard Time": "Asia/Hovd",
    "North Asia Standard Time": "Asia/Novokuznetsk",
    "N. Central Asia Standard Time": "Asia/Novosibirsk",
    "Tomsk Standard Time": "Asia/Tomsk",
    "China Standard Time": "Asia/Shanghai",
    "North Asia East Standard Time": "Asia/Irkutsk",
    "Singapore Standard Time": "Etc/GMT-8",
    "W. Australia Standard Time": "Australia/Perth",
    "Taipei Standard Time": "Asia/Taipei",
    "Ulaanbaatar Standard Time": "Asia/Ulaanbaatar",
    "North Korea Standard Time": "Asia/Pyongyang",
    "Aus Central W. Standard Time": "Australia/Eucla",
    "Transbaikal Standard Time": "Asia/Chita",
    "Tokyo Standard Time": "Etc/GMT-9",
    "Korea Standard Time": "Asia/Seoul",
    "Yakutsk Standard Time": "Asia/Yakutsk",
    "Cen. Australia Standard Time": "Australia/Adelaide",
    "AUS Central Standard Time": "Australia/Darwin",
    "E. Australia Standard Time": "Australia/Brisbane",
    "AUS Eastern Standard Time": "Australia/Sydney",
    "West Pacific Standard Time": "Etc/GMT-10",
    "Tasmania Standard Time": "Australia/Hobart",
    "Vladivostok Standard Time": "Asia/Vladivostok",
    "Lord Howe Standard Time": "Australia/Lord_Howe",
    "Bougainville Standard Time": "Pacific/Bougainville",
    "Russia Time Zone 10": "Asia/Srednekolymsk",
    "Magadan Standard Time": "Asia/Magadan",
    "Norfolk Standard Time": "Pacific/Norfolk",
    "Sakhalin Standard Time": "Asia/Sakhalin",
    "Central Pacific Standard Time": "Etc/GMT-11",
    "Russia Time Zone 11": "Asia/Kamchatka",
    "New Zealand Standard Time": "Pacific/Auckland",
    "UTC+12": "Etc/GMT-12",
    "Fiji Standard Time": "Pacific/Fiji",
    "Kamchatka Standard Time": "Pacific/Fiji",
    "Chatham Islands Standard Time": "Pacific/Chatham",
    "Tonga Standard Time": "Etc/GMT-13",
    "Samoa Standard Time": "Pacific/Apia",
    "Line Islands Standard Time": "Etc/GMT-14"
};