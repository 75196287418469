import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import EventBus from '../event-bus.js';

import utils from '../../Shared/utils.jsx';
import methods from '../../Shared/methods';

Vue.component('accordion-group', {
    mixins: [BaseComponent],
    data: function () {
        return {
            hideHeaderBarEval: null,
            groupTitleEval: null,
            isOpenEval: null
        }
    },
    props: {},
    computed: {
        hideHeaderBar() {
            try {
                if(this.hideHeaderBarEval === null && this.controlData.HideHeaderBar)
                    this.hideHeaderBarEval = utils.compileObject(this, this.controlData.HideHeaderBar);
                if(this.hideHeaderBarEval)
                    return utils.evaluateObject(this.hideHeaderBarEval, this);
                return '';
            }
            catch (e) {
                utils.warn('HideHeaderBar could not evaluate expression: ' + this.controlData.HideHeaderBar + '; ' + e);
                return '';
            }
        },
        groupTitle() {
            try {
                if(this.groupTitleEval === null && this.controlData.GroupTitle)
                    this.groupTitleEval = utils.compile(this, this.controlData.GroupTitle);
                if(this.groupTitleEval)
                    return utils.evaluate(this.groupTitleEval, this);
                return '';
            }
            catch (e) {
                utils.warn('GroupTitle could not evaluate expression: ' + this.controlData.GroupTitle + '; ' + e);
                return '';
            }
        },
        isOpen() {
            try {
                if(this.isOpenEval === null && this.controlData.IsOpen)
                    this.isOpenEval = utils.compileObject(this, this.controlData.IsOpen);
                if(this.isOpenEval)
                    return utils.evaluateObject(this.isOpenEval, this);
                return '';
            }
            catch (e) {
                utils.warn('IsOpen could not evaluate expression: ' + this.controlData.IsOpen + '; ' + e);
                return '';
            }
        }
    },
    created() {
        utils.debug(`AccordionGroup created`);
    },
    //Mounted Replaced with preRenderComplete
    methods: {
        Close() {
            this.$emit('close');
        },
        Open() {
            this.$emit('open');
        },
        ToggleIsOpen() {
            this.$emit('toggle-open')
        },
        preRenderComplete() {
            this.finishRenderHandler(this);
        }
    },
    render(h) {
        try {
            let content = [];
    
            // Creating the header bar
            if (!this.hideHeaderBar) {
                utils.debug(`AccordionGroup render in if(!this.hideHeaderBar)`);

                // The title can have pre title controls, a title, and header controls. We have to figure out which ones we have and 
                // render them accordingly. We're going to wrap everything in the expansion panel header, and in a div that will emulate a 
                // horizontal layout
                let titleContent = [];
    
                if(this.controlData?.PreTitleControls?.length)
                    titleContent.push(<div class="d-flex mr-2">{utils.generateItemsFromArray(h, this, this.controlData.PreTitleControls, null, 'HorizontalLayout', {'finished-render': (item) => this.finishRenderHandler(item) })}</div>);
    
                if(this.groupTitle)
                    titleContent.push(<div class="mr-2">{this.groupTitle}</div>);
    
                if(this.controlData?.HeaderControls?.length)
                    titleContent.push(<div class="d-flex mr-2">{utils.generateItemsFromArray(h, this, this.controlData.HeaderControls, null, 'HorizontalLayout', {'finished-render': (item) => this.finishRenderHandler(item) })}</div>);
    
                content.push(
                    <v-expansion-panel-header>
                        <div class="d-flex">
                            {titleContent}
                        </div>
                    </v-expansion-panel-header>
                );
            }
    
            if (this.controlData.AccordionBody) {
                utils.debug(`AccordionGroup render in if(this.controlData.AccordionBody)`);

                let body = utils.generateItemsFromArray(h, this, this.controlData.AccordionBody, null, 'VerticalLayout', { 'finished-render': (item) => this.finishRenderHandler(item) });
    
                content.push(
                    <v-expansion-panel-content>
                        <div class="d-flex flex-column">
                            {body}
                        </div>
                    </v-expansion-panel-content>
                )
            }
    
            return (
                <v-expansion-panel
                    class={{ 'c-AccordionGroup': true, [`c-name-${this.name || 'unnamed'}`]: true }}
                >
                    {content}
                </v-expansion-panel>
            );
        }
        catch(e) {
            utils.error('AccordionGroup Render failed', e);
            return <div>AccordionGroup Failed to Render {e}</div>;
        }
    }
});