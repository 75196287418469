import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

Vue.component('accordion-group-dsgn', {
    props: {
        name: '',
        root: null,
        designmodel: null,
        controlData: null,
    },
    methods: {
        generateItems(h, controls, parentType) {
            let items = [];
            for (var i = 0; i < controls.length; i++) {
                const control = controls[i];
                let DynamicControl = utils.getDynamicComponent(h, control);

                const _k = i + '_' + control.ControlType + '_' + control.$objectId;

                if (!DynamicControl)
                    DynamicControl = 'default-unknown';

                DynamicControl += '-dsgn';

                items.push(
                    <DynamicControl
                        key={_k}
                        type={control.ControlType}
                        name={control.ControlData.Name}
                        root={this.root}
                        designmodel={control}
                        parentType={parentType}
                        controlData={control.ControlData}>
                    </DynamicControl>
                );
            }
            return items;
        }
    },
    render(h) {
        try {
            let content = [];

            // Creating the header bar
            // The title can have pre title controls, a title, and header controls. We have to figure out which ones we have and 
            // render them accordingly. We're going to wrap everything in the expansion panel header, and in a div that will emulate a 
            // horizontal layout
            let titleContent = [];

            if (this.controlData?.PreTitleControls?.length)
                titleContent.push(<div class="d-flex mr-2">{this.generateItems(h, this.controlData.PreTitleControls, 'HorizontalLayout')}</div>);

            if (this.groupTitle)
                titleContent.push(<div class="mr-2">{this.groupTitle}</div>);

            if (this.controlData?.HeaderControls?.length)
                titleContent.push(<div class="d-flex mr-2">{this.generateItems(h, this.controlData.HeaderControls, 'HorizontalLayout')}</div>);

            content.push(
                <v-expansion-panel-header>
                    <div class="d-flex">
                        {titleContent}
                    </div>
                </v-expansion-panel-header>
            );

            if (this.controlData.AccordionBody) {
                utils.debug(`AccordionGroup render in if(this.controlData.AccordionBody)`);

                let body = this.generateItems(h, this.controlData.AccordionBody, 'VerticalLayout');

                content.push(
                    <v-expansion-panel-content>
                        <div class="d-flex flex-column">
                            {body}
                        </div>
                    </v-expansion-panel-content>
                )
            }

            return (
                <v-expansion-panel>
                    {content}
                </v-expansion-panel>
            );
        }
        catch (e) {
            utils.error('AccordionGroup Render failed', e);
            return <div>AccordionGroup Failed to Render {e}</div>;
        }
    }
});

Vue.component('accordion-dsgn', {
    data: function () {
        return {
            openedItems: [0],
        }
    },
    computed: {
        Root: function () {
            return this.root._self;
        },
        DesignModel: function () {
            return this.designmodel;
        },
        selected$: function () {
            return this.Root.SelectedNode && this.Root.SelectedModel == this.designmodel;
        },
        selectedStep$: function () {
            if (this.current_step >= 0 && this.current_step < this.controlData.Steps.length)
                return this.Root.SelectedNode && this.Root.SelectedModel == this.controlData.Steps[this.current_step];
            else
                return false;
        },
    },
    methods: {
        ...methods,
        handleClick(e) {
            this.Root.SelectNode(this);
            e.cancelBubble = true;
            e.stopPropagation();
        },

        openItem(index) {
            if (this.closeOthers)
                this.openedItems = [index];
            else if (!this.openedItems.includes(index)) {
                utils.debug(`Accordion ${this.name} openItem(index:${index})`);
                this.openedItems.push(index)
            }
        },
        closeItem(index) {
            if(this.openedItems.includes(index))
                this.openedItems = this.openedItems.filter(item => item != index);
        },
        toggleOpen(index){
            if(this.openedItems.includes(index))
                this.closeItem(index);
            else
                this.openItem(index);
        },

        generateItems(h, controls, parentType) {
            let items = [];
            for (var i = 0; i < controls.length; i++) {
                const control = controls[i];
                let DynamicControl = utils.getDynamicComponent(h, control);

                const _k = i + '_' + control.ControlType + '_' + control.$objectId;

                if (!DynamicControl)
                    DynamicControl = 'default-unknown';

                DynamicControl += '-dsgn';

                items.push(
                    <DynamicControl
                        key={_k}
                        type={control.ControlType}
                        name={control.ControlData.Name}
                        root={this.root}
                        designmodel={control}
                        parentType={parentType}
                        controlData={control.ControlData}>
                    </DynamicControl>
                );
            }
            return items;
        },
        getContent(groupTitle, controlData) {
            let content = [];

            // Creating the header bar
            // The title can have pre title controls, a title, and header controls. We have to figure out which ones we have and 
            // render them accordingly. We're going to wrap everything in the expansion panel header, and in a div that will emulate a 
            // horizontal layout
            let titleContent = [];

            if (controlData?.PreTitleControls?.length)
                titleContent.push(<div class="d-flex mr-2">{this.generateItems(h, controlData.PreTitleControls, 'HorizontalLayout')}</div>);

            if (groupTitle)
                titleContent.push(<div class="mr-2">{groupTitle}</div>);

            if (controlData?.HeaderControls?.length)
                titleContent.push(<div class="d-flex mr-2">{this.generateItems(h, controlData.HeaderControls, 'HorizontalLayout')}</div>);

            content.push(
                <v-expansion-panel-header>
                    <div class="d-flex">
                        {titleContent}
                    </div>
                </v-expansion-panel-header>
            );

            if (controlData.AccordionBody) {
                utils.debug(`AccordionGroup render in if(this.controlData.AccordionBody)`);

                let body = this.generateItems(h, controlData.AccordionBody, 'VerticalLayout');

                content.push(
                    <v-expansion-panel-content>
                        <div class="d-flex flex-column">
                            {body}
                        </div>
                    </v-expansion-panel-content>
                )
            }

            return (
                <v-expansion-panel>
                    {content}
                </v-expansion-panel>
            );
        }
    },
    props: {
        name: '',
        root: null,
        designmodel: null,
        parentType: '',
        controlData: {}
    },
    render(h) {

        const style = {
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            border: "1px solid #ddd",
            margin: "3px",
            borderRadius: "3px",
            /*zIndex: 2,*/
            ...utils.getSize(this.controlData.SizeOptions, this.parentType, this.$parent)
        };

        try {
            return (
                <div
                    class={{ 'designer-container': true, selected: this.selected$ }}
                    style={style}
                    on-click={(e) => this.handleClick(e)}>
                    <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>

                    <v-expansion-panels
                        
                        multiple
                        value={this.openedItems}
                    >
                        {this.controlData.AccordionGroup.map((groupItem, index) => this.getContent('', groupItem))}
                    </v-expansion-panels>
                </div>
            );
        }
        catch(e) {
            utils.error('Accordion Render failed', e);
            return <div>Accordion Failed to Render {e}</div>;
        }
    }
});