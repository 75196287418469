import Vue from 'vue';
import BaseComponent from './baseFormMixin.jsx';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

Vue.component('sform-paragraph', {
    mixins: [BaseComponent],
    data: () => ({
        bodyexpr: null,
    }),
    props: {
    },
    created() {
        if (this.element.body)
            this.bodyexpr = utils.compile(this, this.Translate(this.element.body));
    },
    mounted() {
        this.loadComplete();
    },
    computed: {
        body() {
            if(this.element.body)
                return <translation-container context={this} value={this.element.body} isPossibleHtml={true}></translation-container>;
            return null;
        }
    },
    methods: {
    },
    render() {
        return <p>{this.body}</p>;
    }
});