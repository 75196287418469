<template>
    <div style="display: flex; flex-direction: row; flex-wrap: wrap;" class="mt-1 mb-2">
        <div v-for="(col,j) in items" :key="j" style="width: 300px; max-width: 300px; display: flex; flex-direction: row;" class="mr-4">

            <v-btn text small class="text-normal subtitle-1" @click="toggleColumn(col)" color="primaryText" _color="(default_visibility[col.ColId] && !ReadOnly) ? 'blue-grey lighten-1' : 'grey darken-4'">
                <v-icon class="mr-1" :color="columnLookup[col.ColId]?.hide ? 'secondaryText' : 'primary'">
                    {{ columnLookup[col.ColId]?.hide ? 'mdi-checkbox-blank-outline' :'mdi-checkbox-marked' }}
                </v-icon>
                <span class="text-truncate" :style="{ maxWidth: columnLookup[col.ColId]?.hide ? '210px' : '180px' }">{{ col.Title }}</span>
            </v-btn>

            <v-spacer></v-spacer>

            <v-menu offset-y v-if="!columnLookup[col.ColId]?.hide && !ReadOnly">
                <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip bottom open-delay="350">
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn small icon v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                                <v-icon small style="color:lightGrey;">{{ default_visibility[col.ColId] ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
                            </v-btn>
                        </template>
                        Toggle Default Visibility
                    </v-tooltip>
                </template>

                <v-list dense>
                    <v-list-item-group :value="getDefaultHidden(col)" @change="(v) => setDefaultHidden(col,v)" color="primary">
                        <v-list-item class="mediumdense">
                            <v-list-item-title>Default Visible</v-list-item-title>
                        </v-list-item>
                        <v-list-item class="mediumdense">
                            <v-list-item-title>Default Hidden</v-list-item-title>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu>

            <v-tooltip bottom open-delay="350">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon small v-bind="attrs" v-on="on" :class="{ 'ma-0': true }" @click="showHelp(col)">
                        <v-icon small style="color: lightGrey;">mdi-information-slab-circle</v-icon>
                    </v-btn>
                </template>
                <span style="font-weight: bold;">{{ col.Category }} - {{ col.Title }}</span><br />
                <span>{{ getColumnHelp(col) }}</span><br />
                <span style="font-size: smaller; font-style: italic;" v-if="col.Sources">Source(s): {{ col.Sources.join(', ') }}</span>
                <br v-if="col.Dependencies && col.Dependencies.Modules.length" />
                <span style="font-size: smaller; font-style: italic;" v-if="col.Dependencies && col.Dependencies.Modules.length">Module(s): {{ col.Dependencies.Modules.join(', ') }}</span>
            </v-tooltip>
        </div>
    </div>
</template>

<script>
    import Vue from "vue";

    import common from './common.js';

    export default {
        data: function () {
            return {
                default_visibility: {}
            }
        },
        props: {
            ReadOnly: null,
            items: null,
            columnLookup: null,
            column_default_visibility: null,
            showHelp: null,
            addColumn: null,
            removeColumn: null,
            getColumnHelp: null,
        },
        created() {
            if (this.column_default_visibility)
                this.default_visibility = { ...this.column_default_visibility };
        },
        methods: {
            getDefaultHidden(col) {
                return this.default_visibility[col.ColId] ? 1 : 0;
            },
            setDefaultHidden(col, hidden) {
                if (hidden)
                    Vue.set(this.default_visibility, col.ColId, 1);
                else
                    Vue.delete(this.default_visibility, col.ColId);

                this.$emit('setdefaulthidden', col, hidden);
            },
            toggleColumn(col) {
                if (this.columnLookup[col.ColId]) {
                    this.columnLookup[col.ColId].hide = !this.columnLookup[col.ColId].hide;
                    if (this.columnLookup[col.ColId].hide)
                        this.setDefaultHidden(col.ColId, false);

                    this.$emit('setvisible', col, !this.columnLookup[col.ColId].hide);
                }
            },
        }
    }
</script>

<style scoped>
    .hidden-element {
        visibility: hidden;
    }

    .columns-two {
        column-count: 2;
    }

    .columns-three {
        column-count: 3;
    }

    .verydense {
        max-height: 24px;
        min-height: 24px;
    }

    .mediumdense {
        max-height: 28px;
        min-height: 28px;
    }

    p {
        font-size: 2em;
        text-align: center;
    }

    .metric_normal {
    }

    .metric_inuse {
        color: cornflowerblue;
    }

    .metric_usedup {
        color: silver;
    }
</style>