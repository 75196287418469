import Vue from 'vue';
import BaseComponent from './baseFormMixin.jsx';
import utils from '../../../Shared/utils.jsx';

Vue.component('sform-tabs', {
    mixins: [BaseComponent],
    data: () => ({
        selected_tab: 0,
        tabs_items: [],
        defaultTabexpn: null,
    }),
    props: {
    },
    created() {
        for (let tabi = 0; tabi < this.element.tabs.length; tabi++) {
            const tab = this.element.tabs[tabi];
            if (tab.title)
                tab.$$titleexpn = utils.compile(this, this.Translate(tab.title));
        }

        if (this.element.defaultTab)
            this.defaultTabexpn = utils.compile(this, this.element.defaultTab);
    },
    mounted() {
        if (this.defaultTabexpn) {
            const defaultTab = utils.evaluate(this.defaultTabexpn, this);
            const idx = this.element.tabs.findIndex(t => t.name == defaultTab);
            if (idx >= 0)
                this.selected_tab = idx;
        }
        this.loadComplete();
    },
    computed: {
        name: function () {
            return 'FormField_tabs';
        },
        SelectedTabIndex: function () {
            return this.selected_tab;
        },
    },
    methods: {
        getcondition(tab) {
            if (tab.condition && !tab.$$condition)
                tab.$$condition = utils.compileExpression(this, tab.condition);

            if (tab.$$condition)
                return utils.evaluate(tab.$$condition, this);
            else
                return true;
        },
    },
    render() {
        const tabs = [];
        const tab_body = [];

        for (let tabi = 0; tabi < this.element.tabs.length; tabi++) {
            const tab = this.element.tabs[tabi];

            const title = <translation-container context={this} value={tab.title ? tab.title : null}></translation-container>

            tabs.push(
                <v-tab key={tabi} class="text-capitalize" v-show={this.getcondition(tab)}>
                    {title}
                </v-tab>
            );

            tab_body.push(
                <v-tab-item key={tabi}>
                    <sform-fieldset
                        root={this.root}
                        form={tab.items}
                        cmodel={this.cmodel}
                        noinput={this.noinput}
                        layouttype={this.layouttype}
                        appearance={this.appearance}
                        schema={this.schema}
                        depth={0}>
                    </sform-fieldset>
                </v-tab-item>
            );
        }

        return (
            <v-card
                elevation={0}
                class={{ 'c-formTabs': true, [`c-name-${this.name || 'unnamed'}`]: true }}>
                <v-tabs
                    style={{ flexGrow: 0 }}
                    class="ma-1"
                    show-arrows
                    value={this.selected_tab}
                    on-change={(index) => this.selected_tab = index}
                    on-blur={this.onBlur}
                    on-focus={this.onFocus}>
                    {tabs}
                </v-tabs>

                <v-tabs-items value={this.selected_tab}>
                    {tab_body}
                </v-tabs-items>
            </v-card>
        );
    }
});