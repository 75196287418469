import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import careHelpfulFunctions from '../careHelpfulFunctions.jsx';
import AceEditor from './3rdParty/AceEditor.jsx';
import EventBus from '../event-bus.js';

import utils from '../../Shared/utils.jsx';
import methods from '../../Shared/methods';

Vue.component('text-editor', {
    mixins: [BaseComponent],
    data: function () {
        return {
            languageEval: null,
            sourceUrlEval: null,
            sourceRawInterpolatedEval: null,
            isRawEval: null, 
            model: null,
            setValueFunction: null,
            data: null
        }
    },
    async created() {
        this.publishField = this.source;

        EventBus.$on('Action-TextEditorRefresh', this.performTextEditorRefresh);
        this.$on('Action-TextEditorRefresh', this.performTextEditorRefresh);

        // Subscribe by name so Target can be directed to a specific container
        if (this.controlData.Name) {
            EventBus.$on(`Action-TextEditorRefresh:${this.controlData.Name}`, this.performTextEditorRefresh);
            this.$on(`Action-TextEditorRefresh:${this.controlData.Name}`, this.performTextEditorRefresh);
        }
    },
    //Mounted Replaced with preRenderComplete
    destroyed() {
        this.$off('Action-TextEditorRefresh', this.performTextEditorRefresh);

        if (this.controlData.Name) {
            this.$off(`Action-TextEditorRefresh:${this.controlData.Name}`, this.performTextEditorRefresh);
        }
    },
    computed: {
        publishField: {
            set(value){
                if(this.setValueFunction === null && this.controlData.PublishField) {
                    let vueSet = utils.helpers.convertSetValueToVueSet(this.controlData.PublishField, 'value');
                    this.setValueFunction = new Function('value', 'context', 'util', 'vue', `with (context) { ${vueSet} }`);
                }
                if(this.setValueFunction) {
                    this.setValueFunction(value, this, careHelpfulFunctions, Vue)
                }
                this.model = value;
            },
            get() {
                return this.model;
            }
        },
        language() {
            try {
                if(this.languageEval === null && this.controlData.Language)
                    this.languageEval = utils.compile(this, this.controlData.Language);
                if(this.languageEval)
                    return utils.evaluate(this.languageEval, this) || 'json';
                return 'json';
            }
            catch (e) {
                utils.warn('Language could not evaluate expression: ' + this.controlData.Language + '; ' + e);
                return '';
            }
        },
        sourceUrl() {
            try {
                if(this.sourceUrlEval === null && this.controlData.DataURL)
                    this.sourceUrlEval = utils.compile(this, this.controlData.DataURL);
                if(this.sourceUrlEval)
                    return utils.evaluate(this.sourceUrlEval, this);
                return '';
            }
            catch (e) {
                utils.warn('Source URL could not evaluate expression: ' + this.controlData.DataURL + '; ' + e);
                return '';
            }
        },
        sourceRawInterpolated() {
            try {
                if(this.sourceRawInterpolatedEval === null && this.controlData.Data)
                    this.sourceRawInterpolatedEval = utils.compileObject(this, this.controlData.Data)
                if(this.sourceRawInterpolatedEval)
                    return utils.evaluateObject(this.sourceRawInterpolatedEval, this);
                return '';
            }
            catch (e) {
                utils.warn('Sorce raw interpolated could not evaluate expression: ' + this.controlData.Data + '; ' + e);
                return '';
            }
        },
        source() {
            let responseData = [];
            switch(this.controlData.DataType.toLowerCase()) {
                case 'url':
                    responseData = this.data;
                    break;
                case 'raw': 
                case 'rawinterpolated': 
                    responseData = this.sourceRawInterpolated;
                    break;
            }
            if(this.isRaw)
                return responseData;
            return JSON.stringify(responseData, 4, 4);
        },
        isRaw() {
            try {
                if(this.isRawEval === null && this.controlData.IsRaw)
                    this.isRawEval = utils.compile(this, this.controlData.IsRaw);
                if(this.isRawEval)
                    return utils.evaluate(this.isRawEval, this);
                return '';
            }
            catch (e) {
                utils.warn('IsRaw could not evaluate expression: ' + this.controlData.IsRaw + '; ' + e);
                return '';
            }
        },
        styles() {
            let styles = {
                ...this.sizeStyle
            }

            if(this.controlData?.SizeOptions?.Height?.Mode === 'Auto' || this.controlData?.SizeOptions?.Height?.Mode === 'Fill')
                styles.minHeight = '100px';

            if(this.controlData?.SizeOptions?.Width?.Mode === 'Auto' || this.controlData?.SizeOptions?.Width?.Mode === 'Fill')
                styles.minWidth = '200px'

            return styles;
        }
    },
    methods: {
        async preRenderComplete() {
            await this.Refresh();
            this.finishRenderHandler(this);
        },
        async Refresh() {
            switch (this.controlData.DataType) {
                case 'URL': {
                    this.data = await utils.api.get(this.sourceUrl);
                    break;
                }
            }
            this.publishField = this.source;
        },
        async performTextEditorRefresh(action) {
            utils.log(`${this.type} ${this.name} TextEditorRefresh`);

            if (action.ActionData && action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            try {
                // Changing the key for the user control causes Vue to reload
                await this.Refresh();

                try {
                    await utils.success(action);
                } catch (e) { }
            }
            catch (e) {
                try {
                    await utils.failure(action);
                } catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
        onChange(value) {
            this.publishField = value;
        },
    },
    props: {
    },
    render(h) {
        if(!this.todisplay)
            return null;

        try {
            return (
                <div style={this.styles}
                    class={{ 'c-TextEditor': true, [`c-name-${this.name || 'unnamed'}`]: true }}>
                    <ace-editor v-show={this.isvisible} lang={this.language} content={this.source} on-change-content={this.onChange}></ace-editor>
                </div>
            );
        }
        catch(e){
            utils.error('TextEditor Render failed', e);
            return <div>TextEditor Failed to Render {e}</div>;
        }
    }
});