import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import EventBus from '../event-bus.js';

import utils from '../../Shared/utils.jsx';
import methods from '../../Shared/methods';

Vue.component('idle-timeout', {
    data: function () {
        return {
            fullScreen: false,
            enabled: true,
            warningTimer: null,
            logoutTimer: null,
            activityTimer: null,
            timeoutMinutes: 90,
            agentOptIn: false,
            mouseEventSet: false,
        }
    },
    created() {
        EventBus.$on(`Action-UserIdleReset`, this.idleResetAction);
        EventBus.$on(`Action-UserIdleEnable`, this.idleEnableAction);
    },
    destroyed(){
        EventBus.$off(`Action-UserIdleReset`, this.idleResetAction);
        EventBus.$off(`Action-UserIdleEnable`, this.idleEnableAction);
    },
    //Mounted Replaced with preRenderComplete
    computed: {
    },
    methods: {
        preRenderComplete() {
            this.finishRenderHandler(this);
        },
        async idleResetAction(action){
            /*********** ACTION DEBUG **********/
            utils.log(`IdleTimeout IdleTimeoutReset`);
            if (action.ActionData && action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;
            /**********************************/

            try {
                let timeoutMinutes = utils.evaluate(action.timeoutMinutes, action.context);
                let optIn = utils.evaluate(action.optIn, action.context);

                this.turnIdleTimeoutOnOrOff(timeoutMinutes, optIn);

                try {
                    await utils.success(action);
                } catch (e) { }
            }
            catch(e){
                try {
                    await utils.failure(action, { Status: e.status, StatusText: e.statusText });
                } catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
        async idleEnableAction(action){
            /*********** ACTION DEBUG **********/
            utils.log(`IdleTimeout IdleTimeoutReset`);
            if (action.ActionData && action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;
            /**********************************/

            try {
                this.enabled = utils.evaluate(action.enable, action.context);

                this.turnIdleTimeoutOnOrOff();

                try {
                    await utils.success(action);
                } catch (e) { }
            }
            catch(e){
                try {
                    await utils.failure(action, { Status: e.status, StatusText: e.statusText });
                } catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
        turnIdleTimeoutOnOrOff(timeoutOverride, optIn){
            this.cancelTimersAndMouseHandler();

            // Need to be able to set values even if the timers shouldn't run
            if (timeoutOverride)
                this.timeoutMinutes = timeoutOverride;            

            if (optIn !== undefined)
                this.agentOptIn = (optIn === "true");

            // only configure timers if not full screen, agent opts in, and enabled (meaning not in acd)
            if (!this.fullScreen && this.agentOptIn && this.enabled){
                this.mouseEventSet = true;
                document.addEventListener("mousemove", this.handleMouseMove);

                this.warningTimer = setTimeout(() => {
                    EventBus.$emit('UserIdleWarning');
                }, (this.timeoutMinutes - 1) * 60 * 1000); // warn at 1 minute before logout that they will be logged out

                this.logoutTimer = setTimeout(() => {
                    EventBus.$emit('UserIdleLogout');
                }, this.timeoutMinutes * 60 * 1000); // warn at 20 minutes that they will be logged out
            }
        },
        cancelTimersAndMouseHandler(){
            if (this.warningTimer){
                clearTimeout(this.warningTimer);
                this.warningTimer = null;
            }

            if (this.logoutTimer){
                clearTimeout(this.logoutTimer);
                this.logoutTimer = null;
            }

            if (this.mouseEventSet){
                document.removeEventListener("mousemove", this.handleMouseMove);
                this.mouseEventSet = false;
            }
        },
        fullScreenChange(e){
            if (document.fullscreenElement)
                this.fullScreen = true;
            else
                this.fullScreen = false;

            this.turnIdleTimeoutOnOrOff();
        },
        handleMouseMove(){
            if (this.activityTimer)
                clearTimeout(this.activityTimer);

            this.activityTimer = setTimeout(() =>
            {
                this.turnIdleTimeoutOnOrOff();
            }, 2 * 1000); // Reset timers once we have 2 seconds of no mouse movement
        }
    },
    props: {
    },
    render(h) {
        return null;
    }
});