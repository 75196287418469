import Vue from 'vue';
import BaseComponent from './baseFormMixin.jsx';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

Vue.component('sform-button', {
    mixins: [BaseComponent],
    data: () => ({
        titleexpr: null,
    }),
    props: {
    },
    created() {
    },
    mounted() {
        this.loadComplete();
    },
    computed: {
        title() {
            if(this.element.title)
                return <translation-container context={this} value={this.element.title}></translation-container>
            return null;
        },
    },
    methods: {
        click(e) {
            e.cancelBubble = true;
            e.stopPropagation();

            utils.executeAndCompileAllActions(this.element.Actions, {}, this);
        }
    },
    render() {
        let input = <v-btn elevation={0} class="mb-3 mr-3" small color="secondary" on-click={(e) => this.click(e)}>{this.title}</v-btn>;

        return input;
    }
});