import Vue from 'vue';
import BaseFieldsetComponent from './baseFieldsetMixin.jsx';
import utils from '../../../Shared/utils.jsx';

Vue.component('sform-conditional', {
    mixins: [BaseFieldsetComponent],
    data: () => ({
    }),
    props: {
    },
    created() {
    },
    methods: {

    },
    render(h) {
        if (!this.form)
            return null;


        return (
            <table width="100%">
                {this.generateElements(h, this.form, this.layouttype)}
            </table>
        );
    }
});