<template v-if="todisplay">

    <div class="CareLineChart c-carelinechart"
         :style="styles"
         v-show="isvisible">
        <GChart type="LineChart"
                :data="chartData"
                :options="chartOptions"
                :resizeDebounce="500"
                style="display:flex; flex-grow: 1;"/>
    </div>
</template>


<script>
    import utils from '@/Shared/utils.jsx';
    import BaseComponent from './BaseComponentMixin.jsx';
    import careHelpfulFunctions from '../careHelpfulFunctions.jsx';
    import Vue from 'vue';
    import { GChart } from 'vue-google-charts'

    export default {
        name: "LineChart",
        mixins: [BaseComponent],
        components: {
            GChart
        },
        data() {
            return {
                disabledEval: null,
                objectFitEval: null,
                sourcemodel: null,
                titleBacker: null,
                colorarray: null,
            }
       
        },
        created() {
            if (this.controlData.SourceType === 'Raw' && this.controlData.SourceRaw) {
                this.sourceraw = this.controlData.SourceRaw;
            }
            else if (this.controlData.SourceType === 'RawInterpolated' && this.controlData.SourceRaw) {
                this.sourceraw = utils.compileObject(this, this.controlData.SourceRaw);
            }
            else if (this.controlData.SourceType === 'URL' && this.controlData.SourceURL) {
                this.sourceurl = utils.compile(this, this.controlData.SourceURL);
            }
        },
        computed: {
            styles: function () {
                return {
                    ...utils.resolveStyleHints(this.styleHints, this),
                    ...this.sizeStyle,
                    display: 'flex',
                    flexDirection: 'column'
                }
            },
          
            sourcerawvalue: function () {
                if (this.controlData.SourceType === 'Raw')
                    return this.sourceraw;
                else
                    return this.sourceraw ? utils.evaluateObject(this.sourceraw, this) : null;
            },
            sourceurlvalue: function () {
                return this.sourceurl ? utils.evaluate(this.sourceurl, this) : '';
            },
            model: function () {

               

                switch (this.controlData.SourceType) {
                    case 'Raw':
                        return this.sourceraw;

                    case 'RawInterpolated':
                        let temp = this.sourceraw ? utils.evaluateObject(this.sourceraw, this) : null;
                        return temp;

                    case 'URL':
                        return this.sourcemodel;

                    default:
                        return null;
                }
            },
            value() {
                if (!this.value_fn && this.controlData.ValueExpression)
                    this.value_fn = utils.compileExpression(this, this.controlData.ValueExpression);

                return this.value_fn ? utils.evaluate(this.value_fn, this) : 0;
            },
            width() {
                if (!this.width_fn && this.controlData.ChartWidth)
                    this.width_fn = utils.compileExpression(this, this.controlData.ChartWidth);

                return this.width_fn ? utils.evaluate(this.width_fn, this) : '100%';
            },

            height() {
                if (!this.height_fn && this.controlData.ChartHeight)
                    this.height_fn = utils.compileExpression(this, this.controlData.ChartHeight);

                return this.height_fn ? utils.evaluate(this.height_fn, this) : '100%';
            },
            fontName() {
                if (!this.fontname_fn && this.controlData.FontName)
                    this.fontname_fn = utils.compileExpression(this, this.controlData.FontName);

                return this.fontname_fn ? utils.evaluate(this.fontname_fn, this) : 0;
            },
            fontSize() {
                if (!this.fontsize_fn && this.controlData.FontSize)
                    this.fontsize_fn = utils.compileExpression(this, this.controlData.FontSize);

                return this.fontsize_fn ? utils.evaluate(this.fontsize_fn, this) : 0;
            },
            backgroundcolor() {
                if (!this.backgroundcolor_fn && this.controlData.BackgroundColor)
                    this.backgroundcolor_fn = utils.compileExpression(this, this.controlData.BackgroundColor);

                return this.backgroundcolor_fn ? utils.evaluate(this.backgroundcolor_fn, this) : 0;
            },
            size() {
                if (!this.size_fn && this.controlData.SizeExpression)
                    this.size_fn = utils.compileExpression(this, this.controlData.SizeExpression);

                return this.size_fn ? utils.evaluate(this.size_fn, this) : 0;
            },
            rotate() {
                if (!this.rotate_fn && this.controlData.RotateExpression)
                    this.rotate_fn = utils.compileExpression(this, this.controlData.RotateExpression);

                return this.rotate_fn ? utils.evaluate(this.rotate_fn, this) : 0;
            },

            title: {
                set(value){
                    this.titleBacker = value;
                },
                get()
                {
                    if (this.titleBacker !== null && this.titleBacker !== undefined)
                        return this.titleBacker;

                    if (this.controlData.Title && !this.title_fn)
                        this.title_fn = utils.compile(this, this.controlData.Title);

                    return this.title_fn ? utils.evaluate(this.title_fn, this) : '';
                }
            },
            chartData() {
                return this.model;
            },
            chartOptions() {
                var options = {
                    colors: this.colorarray,
                    title: this.title,
                    titlePosition: this.controlData.titlePlacement,
                    legend: { position: 'bottom' },
                    height: this.height,
                    width: this.width,
                    backgroundColor: this.backgroundcolor,
                    fontName: this.fontName,
                    fontSize: this.fontSize,
                    tooltip: { isHtml: true },
                    axisTitlesPosition: this.controlData.AxisTitlesPosition,
                };

                if (this.controlData.TitleTextStyle) {
                    options.titleTextStyle = {
                        color: this.controlData.TitleTextStyle.Color || '',
                        fontName: this.controlData.TitleTextStyle.FontName || '',
                        fontSize: this.controlData.TitleTextStyle.FontSize || '',
                        bold: this.controlData.TitleTextStyle.Bold || '',
                        italic: this.controlData.TitleTextStyle.Italic || '',
                    };
                }

                if (this.controlData.Legend && this.controlData.Legend.Position) {
                    options.legend = {
                        position: this.controlData.Legend.Position,
                    };
                }



                if (this.controlData.ChartArea && this.controlData.ChartArea.Width && this.controlData.ChartArea.Height) {
                    options.chartArea = {
                        width: this.controlData.ChartArea.Width,
                        height: this.controlData.ChartArea.Height,
                    };
                }

                if (this.controlData.Series && Object.keys(this.controlData.Series).length > 0) {
                    options.series = {};
                    for (var i = 0; i < Object.keys(this.controlData.Series).length; i++) {
                        options.series[i] = {};
                        if (this.controlData.Series[i].Color)
                        {
                            options.series[i].color = this.controlData.Series[i].Color;
                        }

                        if (this.controlData.Series[i].LabelInLegend)
                            options.series[i].labelInLegend = this.controlData.Series[i].LabelInLegend;

                        if (this.controlData.Series[i].LineWidth)
                            options.series[i].lineWidth = this.controlData.Series[i].LineWidth;

                        if (this.controlData.Series[i].VisibleInLegend)
                            options.series[i].visibleInLegend = this.controlData.Series[i].VisibleInLegend;

                        if (this.controlData.Series[i].targetAxisIndex)
                            options.series[i].targetAxisIndex = this.controlData.Series[i].targetAxisIndex;
                    }
                }

                if (this.controlData.HAxis) {
                    options.hAxis = {};
                    if (this.controlData.HAxis.Title) {
                        options.hAxis.title = this.controlData.HAxis.Title;
                    }

                    if (this.controlData.HAxis.TitleTextStyle) {
                        options.hAxis.titleTextStyle = { color: this.controlData.HAxis.TitleTextStyle.Color };
                    }

                    if (this.controlData.HAxis.BaselineColor) {
                        options.hAxis.baselineColor = this.controlData.HAxis.BaselineColor;
                    }

                    if (this.controlData.HAxis.Format) {
                        if (this.controlData.HAxis.Format == 'auto' || !this.controlData.HAxis.Format) {
                            options.hAxis.format = null;
                        } else {
                            this.haxis_format_fn = utils.compileExpression(this, this.controlData.HAxis.Format);

                            options.hAxis.format = this.haxis_format_fn ? utils.evaluate(this.haxis_format_fn, this) : null;
                        }
                    }

                    if (this.controlData.HAxis.GridLines) {
                        options.hAxis.gridLines = {
                            color: this.controlData.HAxis.GridLines.Color,
                            count: this.controlData.HAxis.GridLines.Count,
                            units: this.controlData.HAxis.GridLines.Units,
                        };
                    }

                    if (this.controlData.HAxis.TextPosition) {
                        options.hAxis.textPosition = this.controlData.HAxis.TextPosition;
                    }

                    if (this.controlData.HAxis.TextStyle) {
                        options.hAxis.textStyle = {
                            color: this.controlData.HAxis.TextStyle.Color,
                            fontName: this.controlData.HAxis.TextStyle.FontName,
                            fontSize: this.controlData.HAxis.TextStyle.FontSize,
                            bold: this.controlData.HAxis.TextStyle.Bold,
                            italic: this.controlData.HAxis.TextStyle.Italic,
                        };
                    }

                    if (this.controlData.HAxis.Ticks && this.controlData.HAxis.Ticks.length > 0) {
                        options.hAxis.ticks = [];
                        for (var i = 0; i < this.controlData.HAxis.Ticks.length; i++) {
                            options.hAxis.ticks.push({
                                v: this.controlData.HAxis.Ticks[i].Value,
                                f: this.controlData.HAxis.Ticks[i].Text
                            });
                        }
                    }
                }

                if (this.controlData.VAxes || this.controlData.VAxis) {
                    // check to see if we only have legacy VAxis, push it into Vaxes array of vaxis objects
                    if (!this.controlData.VAxes || Object.keys(this.controlData.VAxes).length == 0) {
                        this.controlData.VAxes = {};


                        this.controlData.VAxes[0] = this.controlData.VAxis;
                        //this.controlData.VAxes.push(this.controlData.VAxis);
                    }

                    options.vAxes = {};
                    // configure the vaxis objects and push them onto the chart options vaxes array
                    for (var i = 0; i < Object.keys(this.controlData.VAxes).length; i++) {

                        var configuredVAxis;
                        if (this.controlData.VAxes[i].AlreadyConfigured)
                            configuredVAxis = this.controlData.VAxes[i];
                        else
                            configuredVAxis = this.configureVAxis(this.controlData.VAxes[i]);

                        options.vAxes[i] = configuredVAxis;
                        //options.vAxes.push(configuredVAxis);

                    }
                }

                if (this.controlData.PassedSeries) {
                    options.series = this.controlData.PassedSeries;
                }

                return options;
            }
        },
       
        methods: {
            SetColors(colors) {
                this.colorarray = colors;
            },
            updateData(data) {
                this.model = data;
            },

            SetTitle(value) {
                this.titleBacker = value;
            },

            async preRenderComplete() {
                this.finishRenderHandler(this);
                this.Refresh();
            },

            async Refresh() {
                if (this.controlData.SourceType === 'URL' && this.controlData.SourceURL) {
                    this.sourcemodel = await utils.api.get(this.sourceurlvalue);
                }
            },

            getContent(value) {
                if (!this.content_fn && this.controlData.Content)
                    this.content_fn = utils.compile(this, this.controlData.Content, false, 'value');

                return this.content_fn ? utils.evaluate(this.content_fn, this, false, null, false, value) : null;
            },
            
            configureVAxis(config) {
                var vconfig = {};
                if (config.Title)
                    vconfig.title = config.Title;

                if (config.MatchTitle)
                    vconfig.matchTitle = config.MatchTitle;

                if (config.TitleTextStyle) {
                    vconfig.titleTextStyle = {
                        color: config.TitleTextStyle.Color,
                        fontName: config.TitleTextStyle.FontName,
                        fontSize: config.TitleTextStyle.FontSize,
                        bold: config.TitleTextStyle.Bold,
                        italic: config.TitleTextStyle.Italic,
                    };
                }

                if (config.BaselineColor) {
                    vconfig.baselineColor = config.BaselineColor;
                }

                if (config.Format) {
                    if (config.Format == 'auto' || !config.Format) {
                        vconfig.format = null;
                    } else {
                        this.vaxis_format_fn = utils.compileExpression(this, config.Format);

                        vconfig.format = this.vaxis_format_fn ? utils.evaluate(this.vaxis_format_fn, this) : null;
                    }
                }

                if (config.GridLines) {
                    vconfig.gridLines = {
                        color: config.GridLines.Color,
                        count: config.GridLines.Count,
                        units: config.GridLines.Units,
                    };
                }

                if (config.TextPosition) {
                    vconfig.textPosition = config.TextPosition;
                }

                if (config.TextStyle) {
                    vconfig.textStyle = {
                        color: config.TextStyle.Color,
                        fontName: config.TextStyle.FontName,
                        fontSize: config.TextStyle.FontSize,
                        bold: config.TextStyle.Bold,
                        italic: config.TextStyle.Italic,
                    };
                }

                if (config.Ticks && config.Ticks.length > 0) {
                    vconfig.ticks = [];
                    for (var j = 0; j < config.Ticks.length; ++j) {
                        var actualTicks = config.Ticks[j]
                        if (actualTicks.length)
                            for (var i = 0; i < actualTicks.length; i++)
                                vconfig.ticks.push({
                                    v: actualTicks[i].Value,
                                    f: actualTicks[i].Text
                                });
                        else
                            vconfig.ticks.push({
                                v: actualTicks.Value,
                                f: actualTicks.Text
                            });
                    }
                }
                return vconfig;
            },
            VAxis() { return this.controlData.VAxis; },
            SetVAxis(value) { this.controlData.VAxis = value; },
            ConfigureVAxis(config) {
                return this.configureVAxis(config);
            },


            VAxes() { return this.controlData.VAxes; },
            //set SetVAxes(value) { this.controlData.VAxes = value; },
            SetVAxes(value) {
                // need to update chart options again, dont think it auto refreshs?
                this.controlData.VAxes = value;
                
            },
            SetSeries(value) {
                // need to update chart options again, dont think it auto refreshs?
                this.controlData.Series = value;
            },

            TitlePosition() { return this.controlData.TitlePosition; },
            SetTitlePosition(value) { this.controlData.TitlePosition = value; },

            TitleTextStyle() { return this.controlData.TitleTextStyle; },
            SetTitleTextStyle(value) { this.controlData.TitleTextStyle = value; },

            AxisTitlesPosition() { return this.controlData.AxisTitlesPosition; },
            SetAxisTitlesPosition(value) { this.controlData.AxisTitlesPosition = value; },

            ChartArea() { return this.controlData.ChartArea; },
            SetChartArea(value) { this.controlData.ChartArea = value; },

            Legend() { return this.controlData.Legend; },
            SetLegend(value) { this.controlData.Legend = value; },

            Series() { return this.controlData.Series; },
            


            addDates(data) {
                var dateCols = [];
                for (var i = 0; i < data.cols.length; i++) {
                    var col = data.cols[i];
                    if (col.type == 'date') {
                        dateCols.push(i);
                    }
                }

                for (var row of data.rows) {
                    for (var i of dateCols) {
                        row.c[i].v = new Date(row.c[i].v);
                    }
                }
                return data;
            },
        },
        watch: {
            todisplay: function (newval, oldval) {
                this.Refresh();
            }
        },
    }

</script>

<style scoped>
</style>
