import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import utils from '../../Shared/utils.jsx';
import EventBus from '../event-bus.js';

import VerticalTabs from './vuecontrols/verticalTabs.vue';

Vue.component('experimental-tab', {
    mixins: [BaseComponent],
    components: {
        VerticalTabs
    },
    data: function () {
        return {
            items: null,
            selected_index: 5,

            show_remove: false,
            menu_visible: false,
            x: 0,
            y: 0,
        }
    },
    async created() {
        if (this.controlData.SourceType == 'Raw') {
            this.sourcerawexpn = utils.compileObject(this, this.controlData.SourceRaw);
        }
        else if (this.controlData.SourceType == 'Url') {
            this.sourceurlexpn = utils.compile(this, this.controlData.SourceUrl);
        }
    },
    //Mounted Replaced with preRenderComplete
    destroyed() {
    },
    computed: {
        sourcerawvalue: function () {
            return this.sourcerawexpn ? utils.evaluateObject(this.sourcerawexpn, this) : null;
        },
        sourceurlvalue: function () {
            return this.sourceurlexpn ? utils.evaluate(this.sourceurlexpn, this) : '';
        },
        backgroundImage: function () {
            if (!this.bgimageexpn && this.controlData.BackgroundImageURL)
                this.bgimageexpn = utils.compile(this, this.controlData.BackgroundImageURL);

            if (this.bgimageexpn)
                return utils.evaluate(this.bgimageexpn, this);
            else
                return '';
        },
    },
    watch: {
    },
    methods: {
        tabChanged(index) {
            utils.debug(`Tab selected ${index}`);
            this.selected_index = index;
        },
        async preRenderComplete() {
            await this.Refresh();

            this.finishRenderHandler(this);
        },
        async Refresh() {
            if (this.controlData.SourceType == 'Raw') {
                this.items = this.sourcerawvalue;
            }
            else if (this.controlData.SourceType == 'Url') {
                const url = this.sourceurlvalue;
                if (url)
                    this.items = utils.api.get(url);
                else
                    this.items = null;
            }
        },
        add(e) {
            this.$emit('add');
        },
        toggleRemove(e) {
            this.show_remove = !this.show_remove;
        },
        remove(e, item) {
            e.cancelBubble = true;
            e.stopPropagation();
            this.$emit('remove', item);
        },
        showMenu(item) {

        },
    },
    props: {
    },
    render(h) {
        if (!this.todisplay || !this.controlData)
            return null;

        const style = {
            ...this.sizeStyle,
        };

        const items = this.items || [
            { id: 1, title: 'Tab Number One-1 item', icon: 'mdi-refresh' },
            { id: 2, title: 'Tab Number Two-2', icon: 'mdi-calendar-check' },
            { id: 3, title: 'Tab Number Three-3', icon: 'mdi-account-circle' },
            { id: 4, title: 'Tab Number Four-4', icon: 'mdi-close-circle' },
            { id: 5, title: 'Tab Number Five-5', icon: 'mdi-close-circle-outline' },
            { id: 6, title: 'Tab Number Six-6', icon: 'mdi-close-circle-outline' },
            { id: 7, title: 'Tab Number Seven-7', icon: 'mdi-close-circle-outline' },
            { id: 8, title: 'Tab Number Eight-8', icon: 'mdi-close-circle-outline' },
            { id: 9, title: 'Tab Number One-1', icon: 'mdi-refresh' },
            { id: 10, title: 'Tab Number Two-2', icon: 'mdi-calendar-check' },
            { id: 11, title: 'Tab Number Three-3 item', icon: 'mdi-account-circle' },
            { id: 12, title: 'Tab Number Four-4', icon: 'mdi-close-circle' },
            { id: 13, title: 'Tab Number Five-5', icon: 'mdi-close-circle-outline' },
            { id: 14, title: 'Tab Number Six-6', icon: 'mdi-close-circle-outline' },
            { id: 15, title: 'Tab Number Seven-7', icon: 'mdi-close-circle-outline' },
            { id: 16, title: 'Tab Number Eight-8', icon: 'mdi-close-circle-outline' },
        ];

        const scopedSlots = {};
        if (this.controlData.ShowTitle)
            scopedSlots.label = (item) =>
            (
                [
                    <span>{item.title}</span>,
                    <v-btn elevation={0} style={{ visibility: this.show_remove ? "visible" : "hidden" }} icon x-small class="ml-2" on-click={(e) => this.remove(e, item)}>
                        <v-icon small>mdi-close-circle</v-icon>
                    </v-btn>,
                ]
            );
        else if (this.controlData.ShowIcon)
            scopedSlots.label = (item) => {
                let icon = <v-icon class="mr-2 pl-2">{item.icon}</v-icon>;

                if (item.menu) {
                    const menuScopedSlots = {
                        activator: ({ on, attrs }) => <v-btn elevation={0} text block class="ma-0 pl-2 pr-1" {...{ on }} {...{ attrs }}><v-icon class="ml-0 mr-1">{item.icon}</v-icon></v-btn>
                    };

                    icon = (
                        <v-menu
                            top
                            offset-x
                            close-on-click
                            scopedSlots={menuScopedSlots}
                        >
                            <v-list dense>
                                <v-list-item-group color="primary">
                                    <v-list-item key={0} on-click={(e) => this.refreshchildren(e)}>
                                        <v-list-item-icon>
                                            <v-icon>mdi-refresh</v-icon>
                                        </v-list-item-icon>

                                        <v-list-item-content>
                                            <v-list-item-title>Reload</v-list-item-title>
                                            <v-list-item-subtitle>Flushes cache and reloads</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item key={1} on-click={(e) => this.openForLocalEdit(e, this.controlURLvalue)}>
                                        <v-list-item-icon>
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-list-item-icon>

                                        <v-list-item-content>
                                            <v-list-item-title>Edit</v-list-item-title>
                                            <v-list-item-subtitle>Local Tab</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item key={2} on-click={(e) => this.openForExternalEdit(e, this.controlURLvalue)}>
                                        <v-list-item-icon>
                                            <v-icon>mdi-pencil-box-multiple</v-icon>
                                        </v-list-item-icon>

                                        <v-list-item-content>
                                            <v-list-item-title>Edit</v-list-item-title>
                                            <v-list-item-subtitle>New Window</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item key={3} on-click={(e) => this.openDocumentStandAlone(e, this.controlURLvalue)}>
                                        <v-list-item-icon>
                                            <v-icon>mdi-play-box-multiple</v-icon>
                                        </v-list-item-icon>

                                        <v-list-item-content>
                                            <v-list-item-title>Open Stand-Alone</v-list-item-title>
                                            <v-list-item-subtitle>New Window</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>

                                </v-list-item-group>
                            </v-list>
                        </v-menu>
                    );
                }

                return icon;
            };

        let remove;
        if (this.controlData.ShowAddRemove) {
            if (this.show_remove)
                remove = <v-btn elevation={0} style="flex: 1 1 0;" small color="warning" on-click={(e) => this.toggleRemove(e)}>Cancel</v-btn>;
            else
                remove = <v-btn elevation={0} style="flex: 1 1 0;" small color="error" on-click={(e) => this.toggleRemove(e)}>Remove</v-btn>;
        }

        return (
            <VerticalTabs
                style={style}
                items={items}
                backgroundImage={this.backgroundImage}
                dark={this.controlData.Dark}
                scopedSlots={scopedSlots}
                value={this.selected_index}
                on-change={(index) => this.tabChanged(index)}
                on-menu={(item) => this.showMenu(item)}
            >

                <div v-show={this.controlData.ShowAddRemove} style="display: flex; justify-content: center;">
                    <v-btn elevation={0} style="flex: 1 1 0;" small color="primary" on-click={(e) => this.add(e)}>Add</v-btn>
                    {remove}
                </div>
            </VerticalTabs>
        );
        //{items.map((i, index) => <v-tab-item style="overflow: hidden;" key={i.id} value={index}>{i.content}</v-tab-item>)}
    }
});