import Vue from 'vue';
import BaseFieldsetComponent from './baseFieldsetMixin.jsx';
import utils from '../../../Shared/utils.jsx';

Vue.component('sform-fieldset', {
    mixins: [BaseFieldsetComponent],
    data: () => ({
    }),
    props: {
        nopadding: false,
        columns: null
    },
    created() {
    },
    methods: {
    },
    render() {
        if (!this.form)
            return null;

        var template = [];
        var rows = [];
        var rowMapped = [];
        const elements = this.generateElements(h, this.form, this.layouttype);
        
        if (this.columns > 1) {
            for (var i = 0; i < this.columns; i++) {
                template.push(undefined);
            }
            
            for (var i = 0; i < elements.length; i++) {
                var colIndex = i % this.columns;
                
                if (colIndex == 0) { // new row so make a new row
                    rows.push([...template]);
                }
                
                rows[rows.length - 1][colIndex] = elements[i];
            }

            rowMapped = rows.map((rowElements) => {
               var flds = rowElements.map((fld) => {
                   return (<v-col>{fld}</v-col>);
               });
   
               return (<v-row no-gutters style="gap: 10px">{ flds }</v-row>);
           });
        } else {
            rowMapped = elements;
        }

        let cardClass;
        if (this.nopadding)
            cardClass = { 'pa-0': true };
        else
            cardClass = { 'pb-1': true };

        switch (this.layouttype) {
            case 'sform-card-layout':
                {
                    let title;
                    if (this.element && this.Title && !this.element.notitle && !this.notitle)
                        title = (
                            <v-card-title>
                                {this.Title}
                            </v-card-title>
                        );

                    return (
                        <v-card elevation={0} class="pa-0 ma-0" style="flex-grow: 1;">
                            {title}
                            <v-card-text class={cardClass} style="flex-direction: column;">
                                {rowMapped}
                            </v-card-text>
                        </v-card>
                    );
                }
            case 'sform-table-layout':
            default:
                {
                    let title;
                    if (this.element && this.Title && !this.element.notitle)
                        title = (
                            <tr>
                                <td colspan="2">
                                    <h3 style="border-bottom: 1px solid #e5e5e5;">{this.Title}</h3>
                                </td>
                            </tr>
                        );

                    return (
                        <table width="100%">
                            {title}
                            {elements}
                        </table>
                    );
                }
        }
    }
});