import utils from '@/Shared/utils.jsx';
import token from '@/Services/token';

import { appSettings } from '@/Shared/appSettings';

import * as SIP from '../../care-sip-0.15.10.min.js'; // Use min version for production
export default class careWebRtcPhone {
    myPublicIP;
    callOptions = {};
    stripIpsCallback = [(description) => { return this.stripHostIpsFromSdp(description, this.myPublicIP); } ];
    RTPStatsInterval;
    // Initialize the last packet received count to -1 to make sure that we don't renegotiate on the first check. We always
    // renegotiate if the difference between our received and last received is 0.
    lastPacketReceivedCount = -1;
    rtpReinviteInProgress = false;

    constructor(remoteMediaElement) {
        this.videoRemote = remoteMediaElement;

        this.sipjs = {
            webSocket: null, // also known as UserAgent
            connected: false,
            phoneCall: null
        };

        //this.wsconnect = wsconnect;
        //this.hangup = hangup;
        //this.wsdisconnect = wsdisconnect;
        //this.call = call;
        //this.switchStream = switchStream;
        //this.sendDtmf = sendDtmf;
        //this.muteMicrophone = muteMicrophone;
    }

    log(type, message, obj) {
        if (appSettings.careService && appSettings.careControlHelperService) {
            //let care = appSettings.careService;
            //let controlHelper = appSettings.careControlHelperService;
            let safeObj = "";

            // Removed serializing the object, it's rather bloated and wasn't helful
            // if (obj)
            //    safeObj = ", "+JSON.stringify(controlHelper.makeSafeCopy(obj, null));

            let logMsg = "careWebRTC: [careWebRtcPhone] " + message + safeObj;

            if (type == "log")
                utils.debug(logMsg);
            else if (type == "info")
                utils.log(logMsg);
        }
        else {
            if (type == "log")
                utils.debug("careWebRTC: [careWebRtcPhone] " + message);
            else if (type == "info")
                utils.log("careWebRTC: [careWebRtcPhone] " + message, obj);
        }
    }

    async getIceSettingsAndLatency(IceServerType, ownerid, userid) {
        var timer = Date.now();

        let iceSettings = await utils.api.get(`Apps/ICE/GetIceSettings?IceServerType=${encodeURIComponent(IceServerType)}&ownerid=${encodeURIComponent(ownerid)}&userid=${encodeURIComponent(userid)}`);

        var timerEnd = Date.now();
        return {
            iceSettings: iceSettings,
            latency: timerEnd - timer
        };
    }


    async wsconnect(params, iceOptions, phoneUniqueInstanceId) {
        // make sure user has permission to microphone before connecting
        try {
            var iceTimeoutStrategy = iceOptions.IceTimeoutStrategy || "First Candidate";
            var iceServerType = iceOptions.IceServerType || "STUN, TURN & TLS";

            var latencyResult = await this.getIceSettingsAndLatency(iceServerType, "phoneUniqueInstanceId", phoneUniqueInstanceId);
            this.myPublicIP = latencyResult.iceSettings.SourceIP;

            var iceCheckingTimeout = 0;
            var stopIceGatherOnFirstViableCandidate = false;
            switch (iceTimeoutStrategy)
            {
                case "Gather Complete":
                    iceCheckingTimeout = 0; // wait for ice gather complete event
                    break;

                case "Latency Adjusted":
                    /* changed from default 5000 to baseTimeout + latency, mobile is extremely fast at this so separate mobilebaseTimeout */
                    iceCheckingTimeout = latencyResult.iceSettings.BaseIceTimeoutDesktopMs + (latencyResult.latency * latencyResult.iceSettings.IceTimeoutLatencyMultiplier);
                    console.debug(`webrtc: DesktopBaseTimeout: ${latencyResult.iceSettings.BaseIceTimeoutDesktopMs}, Latency: ${latencyResult.latency}, LatencyMultiplier: ${latencyResult.iceSettings.IceTimeoutLatencyMultiplier}, iceCheckingTimeout: ${iceCheckingTimeout}`);
                    break;

                case "First Candidate":
                default:
                    stopIceGatherOnFirstViableCandidate = true;
                    iceCheckingTimeout = 10000; // was 5000 but see a handful of agents each day that reach timeout - increasing to see if that helps
                    break;
            }

            await navigator.mediaDevices.getUserMedia({ audio: true });

            params.reconnectMaxAttempts = isNaN(params.reconnectMaxAttempts) ? -1 : parseInt(params.reconnectMaxAttempts);
            params.reconnectDelay = isNaN(params.reconnectDelay) ? 5 : parseInt(params.reconnectDelay) || 1;
            if (params.reconnectDelay > 60)
                params.reconnectDelay = Math.ceil(params.reconnectDelay / 1000);

            this.params = params;

            let options = {
                autostart: false, /* if this is true then we can't register the callbacks before it starts */
                uri: params.publicIdentity,
                //reliable: SIP.C.supported.SUPPORTED,
                rel100: SIP.C.supported.SUPPORTED,
                sessionDescriptionHandlerFactoryOptions: {
                    peerConnectionOptions: {
                        rtcConfiguration: {
                            iceServers: latencyResult.iceSettings.IceServers,
                            iceTransportPolicy: (iceServerType == "TURN" || iceServerType == "TLS") ? "relay" : "all"
                        },
                        iceCheckingTimeout: iceCheckingTimeout, 
                        stopIceGatherOnFirstViableCandidate: stopIceGatherOnFirstViableCandidate
                    }
                },
                transportOptions: {
                    wsServers: [params.wsUrl],
                    maxReconnectionAttempts: params.reconnectMaxAttempts >= 0 ? params.reconnectMaxAttempts : 10,
                    reconnectionTimeout: params.reconnectDelay,
                    traceSip: true,
                    connectionTimeout: 10 // 10 second websocket connectionTimeout (default is 5s)
                },
                authorizationUser: params.privateIdentity,
                password: params.password,
                register: false
            };

            this.sipjs.webSocket = new SIP.UA(options);

            const c = this;

            this.sipjs.webSocket.on('transportCreated', function (transport) {
                transport.on('connected', function (arg) {
                    c.sipjs.connected = true;
                    c.log('info', 'connected', arg);
                    params.onWSConnected && params.onWSConnected();
                });

                transport.on('disconnected', function (arg) {
                    c.log('log', c.sipjs.connected);

                    // if the websocket is disconnected (e.g. kamailio failover), it will send a code 1006 and then reattempt to connect. 
                    // We need to ignore the disconnect event to give it a chance to reconnect. If the reconnect attempt fails
                    // we will get another event with code 1000 - which will cause the real disconnect events to fire.
                    if (arg.code == 1006)
                        return;

                    if (!this.sipjs.connected)
                        params.onWSConnectionError && params.onWSConnectionError({ reason: "Server unreachable", source: arg });
                    else
                        params.onWSDisconnected && params.onWSDisconnected({ source: arg });

                    c.sipjs.connected = false;
                    c.log('info', 'disconnected', arg);
                });
            });


            // this is for incoming calls (which we don't currently support), immediatly rejected
            this.sipjs.webSocket.on('invite', function (session) {
                c.log('info', 'invite', session);
                session.reject();
            });

            this.sipjs.webSocket.start();

            return true;
        }
        catch (err) {
            return false;
        }
    }

    wsdisconnect() {
        if (this.sipjs.phoneCall) {
            this.log('info', 'wsdisconnect() (has sipjs.phoneCall : true) calling sipjs.phoneCall.close()');

            this.sipjs.phoneCall.close();
            delete this.sipjs.phoneCall;
        }

        if (this.sipjs.webSocket) {
            this.log('info', 'wsdisconnect() (has sipjs.webSocket : true) calling sipjs.webSocket.stop()');

            this.sipjs.webSocket.stop();
            delete this.sipjs.webSocket;
        }
    }


    stripHostIpsFromSdp(description, myPublicIP) {
        const that = this;

        const myPromise = new Promise((resolve, reject) => {

            // this modifier is called for both the outgoing sdp as well an incoming. We only want to modify the outgoing (i.e. offer)
            if (description.type == "offer")
            {
                // This is invoked at least twice before the call is actually place. Once, when the sdp is first created and before any ice candidates have been discovered and 
                // after ice canidate discovery is complete. We are only interested in modifying after the ice canidates are discovered. 
                // if there are no canidates yet, then simply return
                if (description.sdp.indexOf("a=candidate") == -1) {
                    resolve(description);
                    return;
                }

                // if the sdp has a local host entry matching the publicIP address returned by the call to GetIceSettings, then don't strip the entry
                // this means the client has an actual public IP assigned to their computer (very rare) and we don't want to strip that public IP from the local candidate list
                if (description.sdp.match(new RegExp(`candidate.*${myPublicIP.replaceAll(".", "\\.")}.*typ host`, 'g')))
                {
                    console.debug(`IP ${myPublicIP} found, sending all candidates`);
                    resolve(description);
                    return;
                }


                /* this remove host IPs from candidate list, leaving only STUN discovered (i.e. remote) IPs (they have "typ srflx" instead of "typ host") */
                let newsdp = description.sdp.replace(/a=candidate.*typ host.*[\r\n]*/g, '');

                /* If there are no candidates left after triming, restore full list  */
                if (newsdp.indexOf("a=candidate") == -1)
                {
                    resolve(description);
                }
                else
                {
                    description.sdp = newsdp;
                    resolve(description);
                }
            }
            else
                resolve(description);
        });

        return myPromise;
    }

    
    setupRemoteMedia(phoneCall) {
        // If there is a video track, it will attach the video and audio to the same element
        let pc = phoneCall.sessionDescriptionHandler.peerConnection;
        let remoteStream;
        if (pc.getReceivers) {
            remoteStream = new MediaStream();
            pc.getReceivers().forEach(function (receiver) {
                let track = receiver.track;
                if (track) {
                    remoteStream.addTrack(track);
                }
            });
        }
        else {
            remoteStream = pc.getRemoteStreams()[0];
        }

        const c = this;

        this.videoRemote.srcObject = remoteStream;
        this.videoRemote.play().catch(function () {
            c.log('info', "play was rejected");
        });
    }

    //Destination should be a sip address like: sip:1234@realm.com
    async call(destination, in_call_id, extraHeaders, audioId, iceOptions) {
        // make sure user has permission to access to microphone
        const c = this;

        try {
            await navigator.mediaDevices.getUserMedia({ audio: true });

            let audio = true;
            if (audioId) {
                audio = {
                    optional: [
                        {
                            sourceId: audioId
                        }
                    ]
                };
            }

            extraHeaders = extraHeaders || [];

            if (destination) {
                c.callOptions = {
                    params: {
                        callId: in_call_id
                    },
                    extraHeaders: extraHeaders,
                    sessionDescriptionHandlerOptions: {
                        constraints: {
                            audio: audio,
                            video: false
                        }
                    }
                };

                let phoneCall = this.sipjs.webSocket.invite(destination, c.callOptions, c.stripIpsCallback); // don't specify a strip function if sending all candidates

                phoneCall.on('progress', function (response) {
                    c.log('info', 'progress', response);
                    if (response instanceof SIP.IncomingResponse) {
                        c.params.onConnecting && c.params.onConnecting();
                    }
                });

                phoneCall.on('accepted', function (arg) {
                    c.log('info', 'accepted', arg);

                    c.setupRemoteMedia(phoneCall, audio);

                    phoneCall.sessionDescriptionHandler.on("addTrack", function () {
                        c.log('info', "A track has been added, triggering new remoteMedia setup");
                        setupRemoteMedia(audio);
                    });

                    phoneCall.sessionDescriptionHandler.on("addStream", function () {
                        // Deprecated.Note: This has been deprecated in the WebRTC api for the new addTrack event instead
                        c.log('info', "A stream has been added, trigger new remoteMedia setup");
                        setupRemoteMedia(audio);
                    });

                    phoneCall.sessionDescriptionHandler.on("iceConnectionConnected", () => {
                        c.log('info', "webrtc:iceConnectionConnected");
                        c.startRTPStatsInterval();
                    });

                    phoneCall.sessionDescriptionHandler.on("iceConnectionDisconnected", () => {
                        c.log('info', "webrtc:iceConnectionDisconnected");
        
                        // If the ice connection has disconnected, we'll try to force a reinvite.
                        c.restartIceAndReinvite("iceConnectionDisconnected");
        
                        // At this point there's no sense continuing to poll the RTP stats anymore. We'll stop doing that
                        // until the ice connection is reconnected.
                        c.stopRTPStatsInterval();
                    });

                    c.params.onAccepted && c.params.onAccepted();
                });

                phoneCall.on('rejected', function (arg) {
                    c.stopRTPStatsInterval();
                    c.log('info', 'rejected', arg);
                    let param = {
                        "code": arg ? arg.status_code : null,
                        "message": arg ? arg.reason_phrase : null,
                        "source": arg
                    };

                    c.params.onCancel && c.params.onCancel(param);
                });

                phoneCall.on('failed', function (reason, cause) {
                    c.log('info', 'failed', reason);

                    c.stopRTPStatsInterval();

                    if (cause == SIP.C.causes.WEBRTC_ERROR && reason && reason.careError) {
                        // Media aquisition error
                        let param = {
                            "code": "",
                            "message": "Cannot access microphone, may be blocked in browser settings", // reason.error.name,
                            "source": reason
                        };

                        c.params.onCancel && c.params.onCancel(param);
                    }
                });

                phoneCall.on('terminated', function (arg) {
                    c.log('info', 'terminated', arg);
                    c.stopRTPStatsInterval();
                });

                phoneCall.on('cancel', function (arg) {
                    c.log('info', 'cancel', arg);
                    delete c.sipjs.phoneCall;

                    c.params.onCancel && c.params.onCancel();
                });

                phoneCall.on('reinvite', function (arg) {
                    c.log('info', 'reinvite', arg);
                });

                phoneCall.on('referRequested', function (arg) {
                    c.log('info', 'referRequested', arg);
                });

                phoneCall.on('replaced', function (arg) {
                    c.log('info', 'replaced', arg);
                });

                phoneCall.on('dtmf', function (arg) {
                    c.log('info', 'dtmf', arg);
                });

                phoneCall.on('directionChanged', function (arg) {
                    c.log('info', 'directionChanged', arg);
                });

                phoneCall.on('bye', function (arg) {
                    c.stopRTPStatsInterval();
                    c.log('info', 'bye', arg);
                    delete c.sipjs.phoneCall;
                    c.params.onHangup && c.params.onHangup();
                });

                c.sipjs.phoneCall = phoneCall;
                window.WebRTC = c;
            }
            return true;
        }
        catch (err) {
            return false;
        }
    }

    startRTPStatsInterval() {
        this.stopRTPStatsInterval();

        this.RTPStatsInterval = setInterval(() => {
            return this.checkRTPStats();
        }, 1000);
    }

    stopRTPStatsInterval() {
        if(this.RTPStatsInterval)
            clearInterval(this.RTPStatsInterval);
        // Initialize the last packet received count to -1 to make sure that we don't renegotiate on the first check. We always
        // renegotiate if the difference between our received and last received is 0.
        this.lastPacketReceivedCount = -1;
        this.rtpReinviteInProgress = false;
    }

    async checkRTPStats() {
        let stats = await this.sipjs.phoneCall.sessionDescriptionHandler.peerConnection.getStats();
        stats.forEach(report => {
            if (report.type === 'inbound-rtp' && report.kind === 'audio') {
                let packetsReceived = report.packetsReceived;

                // If we've never received packets, we won't consider a reinvite.
                if(packetsReceived <= 0) {
                    this.log('info', "webrtc: RTP Stats timer fired. No packets received yet.");
                    return;
                }

                let packetDelta = packetsReceived - this.lastPacketReceivedCount;
                this.lastPacketReceivedCount = packetsReceived;

                // If we haven't received packets since our last report, we know there is some kind of problem. We'll restart our ICE session and send a reinvite.

                // IMPORTANT: We don't stop the RTPStatsInterval here because we don't actually get an iceConnectionConnected event when we do a renivite before the 
                // ICE connection state has disconnected. This interval should notice a disconnect after one second, and the ice connection state only changes after
                // 5 seconds of problems. Instead we use the rtpReinviteInProgress flag to prevent us from calling this multiple times.
                if(packetDelta === 0 && !this.rtpReinviteInProgress) {
                    // We'll track when we're trying a restart based on missed packets. If we continue to miss packets, we won't retry this again until
                    // we start receiving packets.
                    this.rtpReinviteInProgress = true;
                    this.log('info', "webrtc: 0 packets received. Triggering reinvite");
                    this.restartIceAndReinvite("MissedPackets");
                }
                // If the packet delta is not zero (we've started getting audio packets again) and we have a reinvite in progress, that means it worked and we should start tracking again.
                else if(packetDelta !== 0 && this.rtpReinviteInProgress){
                    this.log('info', "webrtc: Packets received. Restarting RTP stats timer");
                    this.rtpReinviteInProgress = false;
                }
            }
        });
    }

    async restartIceAndReinvite(reason) {
        try {
            this.log('info', "webrtc: Restarting ICE and sending re-INVITE.");

            if (!this.sipjs.phoneCall) {
                console.error("webrtc: Cannot reinvite: Call is not active.");
                return;
            }

            const peerConnection = this.sipjs.phoneCall.sessionDescriptionHandler.peerConnection;

            // Create a new offer with iceRestart to gather new ICE candidates
            const offerOptions = { iceRestart: true };
            const newOffer = await peerConnection.createOffer(offerOptions);

            // Set local description to start the ICE gathering process
            await peerConnection.setLocalDescription(newOffer);
            this.log('info', "webrtc: Local description set. Waiting for ICE candidates...");

            // Ensure all ICE candidates are gathered before proceeding
            await this.waitForIceGatheringComplete(peerConnection);

            this.callOptions.extraHeaders.push(`X-WS-ICE-RESTART: ${reason}`);

            this.log('info', "webrtc: Ice candidate gathering complete. Sending re-INVITE.");

            // Send re-INVITE with the new SDP
            this.sipjs.phoneCall.reinvite(this.callOptions, this.stripIpsCallback);

            this.log('info', "webrtc: Re-INVITE with ICE restart sent.");
        } catch (error) {
            console.error("webrtc: Error during ICE restart and re-INVITE:", error);
        }
    }

    // Helper to wait for ICE gathering to complete
    waitForIceGatheringComplete(peerConnection) {
        return new Promise((resolve) => {
            if (peerConnection.iceGatheringState === "complete") {
                resolve(); // Already complete
            } else {
                const checkState = () => {
                    if (peerConnection.iceGatheringState === "complete") {
                        peerConnection.removeEventListener("icegatheringstatechange", checkState);
                        console.debug("webrtc: ICE gathering completed.");
                        resolve();
                    }
                };
                peerConnection.addEventListener("icegatheringstatechange", checkState);
            }
        });
    }

    hangup(abortWithPrejudice) // abortWithPrejudice = true when a failover occurs and we need to terminate the current call (which won't exist on the new Freeswitch so there is no need to send a BYE) so we can start a new call
    {
        this.log('info', `hangup(abortWithPrejudice:${abortWithPrejudice}) has call: ${this.sipjs.phoneCall ? 'true' : 'false'}`, abortWithPrejudice);

        if (!this.sipjs.phoneCall)
            return;

        if (this.sipjs.phoneCall.session && !abortWithPrejudice) {
            this.log('info', `sipjs.phoneCall.bye()`);

            // the phoneCall.on("bye") should be invoked which will delete the phoneCall and call the hangup events
            this.sipjs.phoneCall.bye();
        }
        else {
            this.log('info', `sipjs.phoneCall.close()`);

            this.sipjs.phoneCall.close();
            delete this.sipjs.phoneCall;
            this.params.onHangup && this.params.onHangup();
        }
    }

    switchStream(constraints) {
        let phoneCall = this.sipjs.phoneCall;
        // Directly call the release method on the mediaStreamManager to
        // clean up the stream(s).
        phoneCall.mediaHandler.mediaStreamManager.release(
            phoneCall.mediaHandler.localMedia
        );

        // Remove localMedia to circumvent localMedia already being set.
        phoneCall.mediaHandler.localMedia = null;

        // Create the new stream via the mediaHandler.
        phoneCall.mediaHandler.getDescription(constraints);
    }

    //dtmf should be a character included in this list: '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '#', '*'.
    sendDtmf(digit) {
        let acceptedDTMF = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '#', '*'];

        if (acceptedDTMF.indexOf(digit) >= 0)
            this.sipjs.phoneCall.dtmf(digit);
        else
            this.log('log', 'dtmf not supported');
    }

    /*
    function muteMicrophone(mute, success, error)
    {
        if (this.sipjs.hasOwnProperty('phoneCall'))
        {
            if (mute)
                this.sipjs.phoneCall.mute();
            else
                this.sipjs.phoneCall.unmute();

            success && success();
        }
        else
        {
            error && error();
        }
    };
    */
}
