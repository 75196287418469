import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

Vue.component('nullable-type', {
    data: () => ({
        oneofschema: null,
        list: null,
    }),
    props: {
        name: null,
        cmodel: null,
        typeSchema: null,
        form_model: null,
        child: 0,
        root: null,
        schema: null,
        path: null,
        navigateTo: null,
        canNavigate: false,
        uniquerowkey: null,
        propertygrid: false,
    },
    methods: {
        async changed(value) {
            const s = await utils.schema.get(value);
            this.oneofschema = utils.schema.resolve_Of(s);
            this.form_model.oneOf[1] = utils.schema.getDefaultModel(this.oneofschema);
        }
    },
    async created() {
        if (this.form_model && this.form_model.oneOf && this.form_model.oneOf.length == 2) {
            const s = await utils.schema.get(this.form_model.oneOf[1].$typeSchema);
            this.oneofschema = utils.schema.resolve_Of(s);
        }
        this.list = await utils.schema.get('/Apps/Schema/AnyOf/schema/public/Platform.Schema.BaseTypes.v1/SchemaSchema');
    },
    render() {
        if (!this.list)
            return null;

        let element;
        if (this.form_model && this.form_model.oneOf && this.form_model.oneOf.length == 2)
            element = this.form_model.oneOf[1];

        const options = [];
        let value;
        let selected = false;
        for (let i = 0; i < this.list.anyOf.length; i++) {
            const opt = this.list.anyOf[i];

            if (element && (element.$typeSchema === opt.Id)) {
                value = opt.Id;
                selected = true;
            }
            options.push({ text: opt.title, value: opt.Id, disabled: false });
        }
        if (!selected)
            options.splice(0, 0, { text: "Please select one", value: "", disabled: true });

        //let select_checkbox;
        let navigate;
        //if (this.canNavigate && this.form_model) {
        //    //select_checkbox = <input type="checkbox" checked={this.name in this.selected} on-change={(e) => this.setselection(this.name, e.target.checked)} />;
        //    navigate = <v-btn color="blue lighten-2" flat small title="Edit" on-click={() => this.navigateTo(this, this.name)}>
        //        <v-icon>mdi mdi-open-in-new</v-icon>
        //    </v-btn>;
        //}

        let subelement;
        if (element) {
            subelement = (<property-grid
                name={element.title || this.name}
                schemakey={null}
                root={this.root}
                schema={this.oneofschema}
                form={null}
                cmodel={element}
                child={this.child}
                readonly={element.readonly || false}
                navigateToModel={null}
                canNavigate={this.canNavigate}
                navigateTo={(ctrl, prop, value) => this.NavigateTo(element, cmodel, key, ctrl, prop, value)}>
            </property-grid>);
        }

        const btn_styles = {
            'font-size': "x-small"
        };

        return (
            <tr>
                <td class={{ "property-grid": this.propertygrid }} colSpan="2" style={{ verticalAlign: "top" }}>
                    <span class={{ "property-grid": this.propertygrid }} style={{ whiteSpace: "nowrap", display: "flex", flexDirection: "row", alignItems: "center" }}>
                        {navigate}
                        Nullable Type:
                        <v-select solo dense hide-details items={options} value={value} style={{ width: "100%" }} on-change={(value) => this.changed(value)}>
                        </v-select>
                    </span>
                    {subelement}
                </td>
            </tr>
        );
    }
});
