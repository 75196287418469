import Vue from 'vue';
import BaseComponent from './baseFormMixin.jsx';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

import { appSettings } from '@/Shared/appSettings.js';

Vue.component('sform-date-time', {
    mixins: [BaseComponent],
    data: () => ({
        pickervisible: false,
        datevalue: ''
    }),
    props: {
    },
    created() {
    },
    mounted() {
        this.loadComplete();
    },
    computed: {
        itemvalue_aslocaldatetime: function () {
            // Cannot use ISO format directly "2200-07-22T06:00:00Z"
            // The format is "yyyy-MM-ddThh:mm" followed by optional ":ss" or ":ss.SSS".

            const dt = new Date(Date.parse(this.itemvalue));
            const year = dt.getFullYear();
            const month = (dt.getMonth() + 1).toString();
            const day = (dt.getDate()).toString();
            const hour = (dt.getHours()).toString();
            const minute = (dt.getMinutes()).toString();
            const second = (dt.getSeconds()).toString();

            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T${hour.padStart(2, '0')}:${minute.padStart(2, '0')}:${second.padStart(2, '0')}`;
        },
    },
    methods: {
        saveDate() {
            this.pickervisible = false;
        },
        cancelDate() {
            this.pickervisible = false;
        },
        dateChanged(value) {
            // Ex: "2021-03" or "2021-06-17"
        },
        sync_fromlocaldatetime(value) {
            const dt = new Date(value);
            this.sync(dt.toISOString());
        },
    },
    render() {
        let scopedSlots = {
            message: ({message}) => {
                return <translation-container context={this} value={message}></translation-container>
            }
        }

        let slots = [
            <translation-container slot="label" context={this} value={this.labelText}></translation-container>,
        ];

        const input = (
            <v-text-field
                class="caption pa-0 ma-0"
                style={{ width: "100%" }}
                outlined single-line dense hide-details
                value={this.itemvalue_aslocaldatetime}
                rules={this.rules}
                type="datetime-local"
                on-input={(value) => this.sync_fromlocaldatetime(value)}
                on-blur={this.onBlur}
                on-focus={this.onFocus}
                scopedSlots={scopedSlots}
                hint={this.hintText}
                persistent-hint={appSettings.DebugTranslationPrefixSetting}
            >{slots}</v-text-field>
        );

        if (this.appearance)
            input.componentOptions.propsData = { ...input.componentOptions.propsData, ...this.appearance };

        if (this.directives) {
            if (input.data.directives)
                input.data.directives = [...input.data.directives, ...this.directives];
            else
                input.data.directives = this.directives;
        }

        return input;

        /* Using the vuetify date-time pickers...
        let popup = (
            <v-menu
                close-on-content-click={false}
                value={this.pickervisible}
                on-input={(value) => this.pickervisible = value}
                transition="scale-transition"
                offset-y
                scopedSlots={{
                    activator: ({ on, attrs }) => {
                        const input =
                            <v-text-field
                                {...{ on }} {...{ attrs }}
                                class="caption pa-0 ma-0"
                                style={{ width: "100%" }}
                                append-icon="mdi-calendar"
                                outlined single-line dense hide-details
                                value={this.itemvalue}
                                type="datetime-local"
                                on-input={(value) => this.sync(value)}>
                            </v-text-field>;

                        if (this.appearance)
                            input.componentOptions.propsData = { ...input.componentOptions.propsData, ...this.appearance };

                        return input;
                    }
                }}>

                <v-card>
                    <v-row dense>
                        <v-col>
                            <v-date-picker
                                value={this.datevalue}
                                on-change={(e) => this.dateChanged(e)}
                                type="date"
                                no-title
                                scrollable>
                            </v-date-picker>

                            <v-time-picker
                                landscape
                                no-title
                            >
                            </v-time-picker>
                        </v-col>
                    </v-row>

                    <v-card-actions>
                        <v-btn text color="primary" on-click={(e) => this.cancelDate(e)}>
                            Cancel
                        </v-btn>
                        <v-btn text color="primary" on-click={(e) => this.saveDate(e)}>
                            OK
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-menu>
        );

        return popup;
        */
    }
});