import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

Vue.component('basic-popup-menu-dsgn', {
    data: function () {
        return {
        }
    },
    created() {
    },
    computed: {
        Root: function () {
            return this.root._self;
        },
        selected$: function () {
            return this.Root.SelectedNode && this.Root.SelectedModel == this.designmodel;
        },
    },
    methods: {
        ...methods,
        handleClick(e) {
            this.Root.SelectNode(this);
            e.cancelBubble = true;
            e.stopPropagation();
        },
    },
    props: {
        name: '',
        root: null,
        designmodel: null,
        parentType: '',
        controlData: {}
    },
    render(h) {
        const style = {
            maxWidth: "250px", // Just for the designer
            ...utils.getSize(this.controlData.SizeOptions, this.parentType, this.$parent),
            overflow: "hidden",
            whiteSpace: "nowrap",
            border: '1px solid black',
            padding: '2px',
            margin: '1px',
        };

        let icon;
        if (this.controlData.Icon && (this.controlData.Icon.includes('{{') || this.controlData.Icon.includes('{*')))
            icon = 'mdi mdi-help-circle';
        else
            icon = this.controlData.Icon;

        let text = this.controlData.Text;

        if (!text && !icon) {
            // This is found in the WizardButton
            if (this.controlData.Type)
                switch (this.controlData.Type) {
                    case 'Back': text = 'Back'; icon = 'mdi mdi-chevron-double-left'; break;
                    case 'Next': text = 'Next'; icon = 'mdi mdi-chevron-double-right'; break;
                    case 'Finish': text = 'Finish'; break;
                }
            else
                text = 'Untitled';
        }

        return (
            <div>
                <button
                    style={style}
                    class={{ 'designer-button': true, selected: this.selected$ }}
                    on-click={(e) => this.handleClick(e)}
                    type="button"
                    title={this.controlData.Tooltip}>
                    <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
                    {text} <i class={icon}></i>
                </button>
            </div>
        );
    }
});