<template>
    <div>
        <v-menu 
            v-if="showActivator"
            id="quick-text-menu"
            offset-y
            :max-height="maxHeight"
            close-on-content-click>
            <template v-if="showActivator" v-slot:activator="{ on, attrs }">
                <v-btn
                    medium
                    v-bind="attrs"
                    v-on="on"
                    color="transparent"
                    elevation="0"
                    tile
                    style="padding: 0;"
                >
                    {{ value ? value.split(',')[0] : '' }}
                    <v-icon color="black">mdi-menu-down</v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-item-group
                    v-model="focusIndex"
                    color="secondary"
                >
                    <v-list-item
                        v-model="focusIndex"
                        v-for="option in options"
                        :key="option.value"
                        @click="$emit('input', option.value)"
                    >
                        <v-list-item-icon v-if="value && option.value === value">
                            <v-icon>mdi-check</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ option.name }}</v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-menu>
        <v-menu 
            v-else
            id="quick-text-menu"
            offset-y
            :max-height="maxHeight"
            :absolute="!showActivator"
            :position-x="positionX"
            :position-y="positionY"
            v-model="showMenu"
            close-on-content-click>
            <template v-if="showActivator" v-slot:activator="{ on, attrs }">
                <v-btn
                    medium
                    v-bind="attrs"
                    v-on="on"
                    color="transparent"
                    elevation="0"
                    tile
                    style="padding: 0;"
                >
                    {{ value ? value.split(',')[0] : '' }}
                    <v-icon color="black">mdi-menu-down</v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-item-group
                    v-model="focusIndex"
                    color="secondary"
                >
                    <v-list-item
                        v-for="option in options"
                        :key="option.value"
                        @click="$emit('input', option.value)"
                    >
                        <v-list-item-icon v-if="value && option.value === value">
                            <v-icon>mdi-check</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ option.name }}</v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-menu>
        <div ref="focusHandler" tabindex="-1" @keyup.prevent.stop="handleKeyPress($event)"></div>
    </div>
</template>

<script>
export default {
    name: "DropdownMenu",
    props: {
        showActivator: {
            type: Boolean, 
            default: false
        },
        showMenu: {
            type: Boolean,
            default: null
        },
        value: {
            type: String,
            default: ''
        },
        options: {
            type: Array,
            default: () => []
        },
        maxHeight: {
            type: String,
            default: ''
        },
        positionX: {
            type: Number,
            default: null
        },
        positionY: {
            type: Number,
            default: null
        },
        supportSelection: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            focusIndex: null
        }
    },
    methods: {
        setupSelection() {
            this.focusIndex = 0;
            this.$refs.focusHandler.focus();
        },
        handleKeyPress(event) {
            
            if(event.code === 'ArrowDown' && this.focusIndex < this.options.length - 1) {
                this.focusIndex++;
            }
            else if (event.code === 'ArrowUp' && this.focusIndex > 0) {
                this.focusIndex--;
            }
            else if(event.code === 'Tab') {
                this.$emit('input', this.options[this.focusIndex].value);
            }
            else if(event.code === 'Enter') {
                this.$emit('input', this.options[this.focusIndex].value);
            }
        },
        selectCurrent() {
            this.$emit('input', this.options[this.focusIndex || 0].value);
        }
    }
};
</script>