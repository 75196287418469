import Vue from 'vue';
import utils from '../../../../Shared/utils.jsx';
import methods from '../../../../Shared/methods';

Vue.component('form-select-dsgn', {
    data: function () {
        return {
        }
    },
    created() {
    },
    computed: {
        ...utils.forms.computed,
        selected$: function () {
            return this.Root.SelectedNode && this.Root.SelectedNode == this;
        },
    },
    methods: {
        ...methods,
        handleClick(e) {
            this.Root.SelectNode(this);
            e.cancelBubble = true;
            e.stopPropagation();
        },
    },
    props: {
        name: '',
        schemakey: '',
        root: null,
        designmodel: null,
        schema: null,
        cmodel: null,
        extra: null,
        readonly: false,
        child: 0,
    },
    render(h) {
        const name = <span style={{ marginLeft: (this.child * 15) + "px" }}>{this.name}</span>;

        let desc;
        if (this.schema.description)
            desc = <i class="mdi mdi-information" title={this.schema.description} style={{ color: "silver", fontSize: "small", marginLeft: "3px" }}></i>;

        if (this.readonly)
            return (
                <tr>
                    <td style={{ width: "1px", "white-space": "nowrap" }}>
                        <div
                            class={{ 'designer-form': true }}
                            on-click={(e) => this.handleClick(e)}>
                            <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
                            {name}{desc}
                        </div>
                    </td>
                    <td>
                        <div
                            class={{ 'designer-form': true }}
                            on-click={(e) => this.handleClick(e)}>
                            <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
                            <input style={{ width: "100%" }} value={this.cmodel[this.schemakey]} disabled="disabled" />
                        </div>
                    </td>
                </tr>
            );

        const items = [];
        const schema = this.schema;
        const enums = schema.enum || schema.titleMap;
        if (Array.isArray(enums)) {
            if (!this.cmodel[this.schemakey])
                items.push(<option selected disabled="">Please select one</option>);

            for (var i = 0; i < enums.length; i++) {
                const item = enums[i];
                if (item && typeof item === 'object')
                {
                    if (this.cmodel[this.schemakey] === item.value)
                        items.push(<option key={i} value={item.value} selected>{item.name}</option>);
                }
                else
                {
                    if (this.cmodel[this.schemakey] === item)
                        items.push(<option key={i} selected>{item}</option>);
                }
            }
        }
        
        return (
            <tr>
                <td style={{ width: "1px", "white-space": "nowrap" }}>
                    <div
                        class={{ 'designer-form': true }}
                        on-click={(e) => this.handleClick(e)}>
                        <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
                        {name}{desc}
                    </div>
                </td>
                <td>
                    <div
                        class={{ 'designer-form': true }}
                        on-click={(e) => this.handleClick(e)}>
                        <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
                        <select disabled style={{ width: "100%" }}>
                            {items}
                        </select>
                    </div>
                </td>
            </tr>
        );
    }
});