import token from '@/Services/token';
import refreshToken from '@/Services/tokenEventListenerService';
import api from '@/Services/api';

export default {    
    childWindows: [],
    
    showChildWindow(args) {
        var controlCustomerID = args.OwnerId;

        if (!this.childWindows[controlCustomerID] || this.childWindows[controlCustomerID].closed) {
            // get a handle to the already opened debug window if there is one, or open new window
            const url = `/?ControlURL=v1/Vue/ExternalEditor_v2&type=${args.Type}&name=${encodeURIComponent(args.Name)}&id=${args.Id}`;

            const sessionStorage = window.sessionStorage;
            this.childWindows[controlCustomerID] = window.open(url, "_blank", "toolbar=no");
            this.childWindows[controlCustomerID].sessionStorage.clear();

            // If the Customer we're currently logged into doesn't own the document, but the user belongs to the owner's company, then get a token for that company
            if (controlCustomerID != token.CustomerID()) {
                api.get('/Apps/SecurityContexts/Available').then(result => {
                    var additionalSecurityContext;
                    var customerid = token.CustomerID();

                    result.forEach(user => {
                        user.SecurityContexts.forEach(securityContext => {
                            // we found the context for the control owner
                            if (securityContext.SecurityContextTypeID == 'Customer' && securityContext.ID == controlCustomerID) {
                                customerid = controlCustomerID;
                                additionalSecurityContext = securityContext.UserSecurityContextID;
                            }
                        });
                    });

                    refreshToken.refreshWithExtraToken(undefined, additionalSecurityContext).then(extraToken => {
                        this.childWindows[controlCustomerID].sessionStorage.setItem('AccessToken',extraToken.access_token);
                        this.childWindows[controlCustomerID].sessionStorage.setItem('RefreshToken',extraToken.refresh_token);
                        this.childWindows[controlCustomerID].sessionStorage.setItem('BandID', sessionStorage.getItem('BandID'));
                        this.childWindows[controlCustomerID].sessionStorage.setItem('UserID', sessionStorage.getItem('UserID'));
                        this.childWindows[controlCustomerID].sessionStorage.setItem('CustomerID', customerid);
                    });
                });
            }
            else
                refreshToken.refreshWithExtraToken(undefined, null).then(extraToken => {
                    this.childWindows[controlCustomerID].sessionStorage.setItem('AccessToken',extraToken.access_token);
                    this.childWindows[controlCustomerID].sessionStorage.setItem('RefreshToken',extraToken.refresh_token);
                    this.childWindows[controlCustomerID].sessionStorage.setItem('BandID', sessionStorage.getItem('BandID'));
                    this.childWindows[controlCustomerID].sessionStorage.setItem('UserID', sessionStorage.getItem('UserID'));
                    this.childWindows[controlCustomerID].sessionStorage.setItem('CustomerID', token.CustomerID());
                });
        } else {
            this.childWindows[controlCustomerID].dispatchEvent(new CustomEvent('event-bus-listener', { detail: {name: 'OpenDocumentEditor', args: args}}));
        }
        this.childWindows[controlCustomerID].focus();
    },
}