import Vue from 'vue';
import Vuetify from 'vuetify';
import EventBus from './event-bus.js';
import auth from './authenticate.jsx';
import inputdialog from './components/vuecontrols/inputdialog.jsx';
import dialogBasicModal from './components/actions/DialogBasicModal.jsx';

export default Vue.component('dialog-listener', {
    data: () => ({
        visible1: false,
        title: 'Test',
        body: 'More test',
        payload1: null,
        success: true,
        result: null,
    }),
    props: {

    },
    created() {
        console.log('dialog-listener waiting for SHOW_DIALOG');

        // "this" within the $on function does not refer to the Vue component. We must keep a reference separate.
        const dialog = this;

        EventBus.$on('SHOW_DIALOG', function (payLoad) {
            console.log('dialog-listener SHOW_DIALOG triggered');

            payLoad.componentOptions.propsData.okay = dialog.confirm;
            payLoad.componentOptions.propsData.cancel = dialog.cancel;

            dialog.payload1 = payLoad;
            dialog.visible1 = true;
        });

        EventBus.$on('LOGON', function (payLoad) {
            console.log('dialog-listener LOGON triggered');

            dialog.payload1 = <authenticate confirm={dialog.confirm} cancel={dialog.cancel} root={payLoad.root}></authenticate>;

            dialog.visible1 = true;
        });

        EventBus.$on('DISPLAY_BASIC_MODAL', function (action, context) {
            console.log('dialog-listener DISPLAY_BASIC_MODAL triggered');

            dialog.payload1 = (
                <display-basic-modal
                    root={context.root}
                    action={action}
                    context={context}>
                </display-basic-modal>
            );
            dialog.visible1 = true;
        });

        EventBus.$on('CLOSE_DIALOG', function (success, result) {
            console.log('dialog-listener CLOSE_DIALOG triggered: success:' + (success ? 'true':'false') + ', result:' + JSON.stringify(result));
            dialog.success = success;
            dialog.result = result;
            dialog.visible1 = false;
        });
    },
    methods: {
        confirm() {
            this.visible1 = false;
        },
        cancel() {
            this.visible1 = false;
        }
    },
    render(h) {
        console.log('Rendering dialog... visible1: ' + this.visible1);
        //if (!this.visible)
        //    return null;

        return (
            <div>
                <v-dialog value={this.visible1} persistent max-width="600" max-height="600">
                    {this.payload1}
                </v-dialog>
                <v-dialog value={this.visible2} persistent max-width="600" max-height="600">
                    {this.payload2}
                </v-dialog>
                <v-dialog value={this.visible3} persistent max-width="600" max-height="600">
                    {this.payload3}
                </v-dialog>
                <v-dialog value={this.visible4} persistent max-width="600" max-height="600">
                    {this.payload4}
                </v-dialog>
            </div>
        );
    }
});