<template>
    <v-menu offset-y left :close-on-content-click="false" v-model="showingMenu" @input="command=null">
        <template v-slot:activator="{ on, attrs }">
            <v-btn plain icon color="secondary" :elevation="0" v-bind="attrs" v-on="on" :class="buttonclass">
                <v-icon>mdi-menu</v-icon>
            </v-btn>
        </template>
        <v-list dense style="max-height: 600px; _overflow: auto;" class="overflow-y-auto">
            <v-list-item-group v-model="command">
                <v-list-item @click="showColumnSelector" v-if="debugMode && !hideColumSelector">
                    <v-list-item-icon>
                        <v-icon>mdi-view-grid-plus</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Show Column Selector</v-list-item-title>
                </v-list-item>

                <v-list-item @click="showDataFilters" v-if="debugMode">
                    <v-list-item-icon>
                        <v-icon>mdi-filter</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Show Data Filters</v-list-item-title>
                </v-list-item>

                <v-divider v-if="debugMode"></v-divider>

                <v-list-item @click="showAllColumns">
                    <v-list-item-icon>
                        <v-icon></v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Show all columns</v-list-item-title>
                </v-list-item>

                <v-divider></v-divider>
            </v-list-item-group>

            <v-list-item-group v-model="selection">
                <v-list-item v-for="(item,i) in items" :key="i" @click="toggleColumnVisible(item)">
                    <v-list-item-icon>
                        <v-icon small v-text="isHidden(item) ? '' : 'mdi-check'"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ item.Title }}</v-list-item-title>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </v-menu>
</template>

<script>
    import Vue from "vue";

    import common from './common.js';

    export default {
        data: function () {
            return {
                command: null,
                columns: [],
                selection: [],

                showingMenu: false,
                hiddencols: {},
            }
        },
        props: {
            column_list: {
                type: Array
            },
            column_order: null,
            column_default_visibility: null,
            columnDefs: null,
            debugMode: null,
            setColumnsVisible: null,

            hideColumSelector: null,

            btnclass: null,
        },
        created() {
            this.columns = this.column_list;
            this.hiddencols = { ...this.column_default_visibility };
        },
        computed: {
            buttonclass: function () {
                return { 'mt-0': true, 'ml-0': true, 'mr-2': true, 'mb-0': true, ...this.btnclass };
            },
            items: function () {
                return this.column_order.map(colId => {
                    const col = this.column_list.find(a => a.ColId == colId);
                    if (col)
                        return {
                            ColId: colId,
                            Title: col.Title,
                            Hidden: !!this.column_default_visibility[colId],
                        };
                    else
                        return {
                            ColId: 'unknown',
                            Title: 'Unknown',
                            Hidden: false,
                        };
                });
            }
        },
        methods: {
            isHidden(item) {
                return !!this.hiddencols[item.ColId];
            },
            toggleColumnVisible(item) {
                if (item.ColId in this.hiddencols)
                    Vue.delete(this.hiddencols, item.ColId);
                else
                    Vue.set(this.hiddencols, item.ColId, true);

                console.log(`toggleColumnVisible(col:${item.ColId}, value:${this.hiddencols[item.ColId]})`);
                this.setColumnsVisible([item.ColId], this.hiddencols[item.ColId] ? false : true);
            },
            showColumnSelector() {
                this.showingMenu = false;
                this.$emit('showcolumnselector');
            },
            showDataFilters() {
                this.showingMenu = false;
                this.$emit('showdatafilters');
            },
            showAllColumns() {
                this.showingMenu = false;
                this.hiddencols = [];
                this.setColumnsVisible(this.column_order, true);
            },
        }
    }
</script>

<style scoped>
</style>