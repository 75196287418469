import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

Vue.component('hsm-statement-schema', {
    data: function () {
        return {
            condition_expn: null,
            control_schema: null,
        }
    },
    created() {
        this.watcher$ = this.$watch('cmodel.StatementName', function (val, oldval) { this.Refresh(); });
    },
    destroyed() {
        if (this.watcher$)
            this.watcher$();
    },
    mounted() {
        this.Refresh();
    },
    computed: {
        ...utils.forms.computed,
    },
    methods: {
        ...methods,
        Refresh() {
            const c = this.FindDesignerChildHsmStatement(this.cmodel.StatementName);
            if (c) {
                this.control_schema = {
                    type: 'object',
                    properties: c.ControlData.Arguments,
                };
                //if (!this.control_schema.type)
                //    this.control_schema.type = 'object';
            }
        }
    },
    props: {
        name: '',
        schemakey: '',
        root: null,
        schema: null,
        cmodel: null,
        extra: null,
        readonly: false,
        child: 0,
    },
    render(h) {
        //if (!this.Condition)
        //    return null;

        let desc;
        if (this.schema.description)
            desc = <i class="mdi mdi-information" title={this.schema.description} style={{ color: "silver", fontSize: "small", marginLeft: "3px" }}></i>;

        if (!this.cmodel.Arguments)
            this.cmodel.Arguments = {};

        const name = <span style={{ marginLeft: (this.child * 15) + "px" }}>{this.name}</span>;
        let form;
        if (this.control_schema) {
            form = (
                <property-grid
                    name="hsmStatement"
                    root={this.root}
                    schema={this.control_schema}
                    form={["*"]}
                    cmodel={this.cmodel.Arguments}
                    child={0}>
                </property-grid>
            );
        }
        else
            form = <span>Loading...</span>;

        return (
            <tr>
                <td style={{ width: "1px", verticalAlign: "top" }}>
                    {name} {desc}
                </td>
                <td>
                    {form}
                </td>
            </tr>
        );
    }
});