import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import EventBus from '../event-bus.js';

import utils from '../../Shared/utils.jsx';
import methods from '../../Shared/methods';

import { Splitpanes, Pane } from 'splitpanes';

Vue.component('split-panes', {
    mixins: [BaseComponent],
    components: {
        Splitpanes,
        Pane,
    },
    data: function () {
        return {
        }
    },
    created() {
        for (let i = 0; i < this.controlData.Panes.length; i++) {
            const p = this.controlData.Panes[i];
            if (p.DisplayExpression)
                p.displayexpr = utils.compileExpression(this, p.DisplayExpression);
        }
    },
    //Created Replaced with preRenderComplete
    computed: {
    },
    methods: {
        async preRenderComplete() {
            this.finishRenderHandler(this);
        },
    },
    render(h) {
        if(!this.todisplay)
            return null;
        
        try {
            const panes = [];

            for (let i = 0; i < this.controlData.Panes.length; i++) {
                const p = this.controlData.Panes[i];
                if (p.displayexpr && !utils.evaluate(p.displayexpr, this))
                    continue;

                let paneStyle;
                if (p.FlexBoxContent || p.FlexBoxContent === undefined)
                    paneStyle = {
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: '1',
                        height: '100px',
                    };

                panes.push(
                    <pane
                        style="display: flex; flex-direction: column; overflow: auto;"
                        key={i}
                        size={p.InitialSize}
                        min-size={p.MinSize}
                        max-size={p.MaxSize}
                        dbl-click-splitter={p.AllowDoubleClick}
                    >
                        <div style={paneStyle}>
                            {utils.generateItemsFromArray(h, this, p.Controls, null, "VerticalLayout")}
                        </div>
                    </pane>
                );
            }

            const style = {
                ...this.sizeStyle,
                ...utils.resolveStyleHints(this.styleHints, this),
            //    display: "flex",
            //    flexDirection: "column",
            //    overflow: "auto",
            };

            return (
                <splitpanes
                    v-show={this.isvisible}
                    style={style}
                    class={{ 'default-theme': true, 'c-SplitPanes': true, [`c-name-${this.name || 'unnamed'}`]: true }}
                    horizontal={this.controlData.Orientation == 'Horizontal'}
                    push-other-panes={this.controlData.EnablePushAdjacent}
                    first-splitter={this.controlData.ShowFirstSplitter}
                    >
                    {panes}
                </splitpanes>
            );
        }
        catch (e) {
            utils.error('SplitPanes Render failed', e);
            return <div>SplitPanes Failed to Render {e}</div>;
        }
    }
});