import Vue from 'vue';
import utils from '../../Shared/utils.jsx';
import careHelpfulFunctions from '../careHelpfulFunctions.jsx';
import methods from '../../Shared/methods';
import computed from '../../Shared/computed';
import filters from '../../Shared/filters';
import apiService from '@/Services/api';
import { PlainWrapper } from '@/Shared/helperClasses';

var baseMixin = {
    data: function () {
        return {
            displayexpr: null,
            visibleexpr: null,
            prerender_actions: [],
            postrender_actions: [],
            prerender_complete: false,
            vars: {},
            styleHints: null,
            isready: false,
            renderCount: 0,
            finishedRender: false,
            finishedRenderChildren: [],
            todisplayfailed: 1,
        }
    },
    props: {
        name: '',
        root: null,
        type: null,
        parentType: '',
        controlData: {},
        //paramData: null,
        debug: null,
        scopeitems: null,
        controlscope: null,
        cacheControl: false
    },
    computed: {
        ...computed,
        Vars: function () {
            return this.vars;
        },
        todisplay: function () {
            if (!this.prerender_complete)
                return false;

            if (!this.displayexpr && this.controlData && this.controlData.DisplayExpression)
                this.displayexpr = utils.compileExpression(this, this.controlData.DisplayExpression);

            if (this.displayexpr) {
                //if (this.name == 'BtnLaunchDesktop')
                //    utils.debug(`${this.type} ${this.name} DisplayExpression:${this.controlData.DisplayExpression}`);

                const res = utils.evaluate(this.displayexpr, this, false,
                    () => {
                        // This is a callback that will only be called if evaluate throws an exception.
                        // If this happens, we read the todisplayfield (so Vue knows we care) and then
                        // after 100ms, we update todisplayfailed to cause Vue to re-evaluate todisplay.
                        // (cap the retry at 10 times with increasing delay each attempt)
                        if (this.todisplayfailed >= 0 && this.todisplayfailed < 10)
                            setTimeout(() => this.todisplayfailed++, 50 * this.todisplayfailed);
                    });

                //if (this.name == 'BtnLaunchDesktop')
                //    utils.debug(`${this.type} ${this.name} DisplayExpression:${this.controlData.DisplayExpression} = ${res ? 'True' : 'False'}`);

                //utils.debug(`DisplayExpression for ${this.name} ${this.type} evaluated to ${res ? 'true' : 'false'} ('${this.controlData.DisplayExpression}')`);
                return res;
            }
            else
                return true;
        },
        isvisible: function () {
            if (this.visibleexpr) {
                
                const res = utils.evaluate(this.visibleexpr, this, false,
                    () => {
                        // This is a callback that will only be called if evaluate throws an exception.
                        // If this happens, we read the todisplayfield (so Vue knows we care) and then
                        // after 100ms, we update todisplayfailed to cause Vue to re-evaluate todisplay.
                        // (cap the retry at 10 times with increasing delay each attempt)
                        if (this.todisplayfailed >= 0 && this.todisplayfailed < 10)
                            setTimeout(() => this.todisplayfailed++, 50 * this.todisplayfailed);
                    });

                return res;
            }
            else
                return true;
        },
        // This is the total number of child controls a component has. This should be overridden in controls that
        // have child controls.
        totalItems() {
            return 0;
        },

        sizeOptions() {
            return this.sizeOptionsObj ? utils.evaluateObject(this.sizeOptionsObj, this) : {};
        },
        sizeStyle() {
            const sizeOptions = { ...this.getSizeDefaults(), ...this.sizeOptions };
            return utils.getSize(sizeOptions, this.parentType, this.$parent);
        },
        Element() {
            return this.$el;
        }
    },
    async created() {
        if (this.isdebug) {
            utils.document_cache.loadInProgress[this.Location] = new Date().toLocaleTimeString();
            //utils.debug(`${this.LocationBrief} CREATE`);
        }

        if (this.controlData && this.controlData.VisibleExpression)
            this.visibleexpr = utils.compileExpression(this, this.controlData.VisibleExpression);

        if (this.controlData && this.controlData.Javascript)
            try {
				this.addJavascript(this, this.controlData.Javascript);
                //const api = utils.apiWrapper; // apiService;
                //const chf = careHelpfulFunctions;
                //this.Javascript = eval("new (" + this.controlData.Javascript + ")(null, null, api, this, chf);");

                //for (let key in this.Javascript)
                //    if (typeof this.Javascript[key] === 'function')
                //        this.Javascript[key] = this.Javascript[key].bind(this.Javascript);
            }
            catch (e) {
                utils.warn(`Failed evaluating Javascript in ${this.name} ${this.type}: ${this.controlData.Javascript}`, e);
            }

        if (this.controlData && this.controlData.Vars) {
            if (this.controlData.$nointerp)
                // Used in ReplaceContent action - the interpolation happens up front
                this.vars = this.controlData.Vars;
            else {
                const varsexpn = utils.compileObject(this, this.controlData.Vars);
                try {
                    this.vars = utils.evaluateObject(varsexpn, this);
                }
                catch (e) {
                    utils.error('error evaluating Vars: ' + e);
                }
            }
        }

        if (this.controlData && this.controlData.StyleHints)
            this.styleHints = utils.compileObject(this, this.controlData.StyleHints);

        if (this.controlData && this.controlData.SizeOptions)
            this.sizeOptionsObj = utils.compileObject(this, this.controlData.SizeOptions);

        /*******************************************************
         ****** As soon as we start async activities,      *****
         ****** the created() is assumed to be complete,   *****
         ****** so the mounted and render methods will run *****
         *******************************************************/

        // Execute all prerender actions now
        if (this.controlData) {
            try {
                await utils.executeAndCompileAllActions(this.controlData.PrerenderActions, null, this);
            } catch(e) {
                utils.error(e);
            }

        }

        this.prerender_complete = true;
        // Every component needs to implement this for the finished Render Complete actions 
        // so we don't call post-render complete before prerender finishes
        console.log(`${this.Name} BaseComponentMixin create invoking preRenderComplete()`);
        this.preRenderComplete();

        //if (this.controlscope && this.controlData && this.controlData.Name) {
        if (this.scopeitems && this.controlData && this.controlData.Name) {
            // Save an entry in the Control object for this item. Note: this will not be reactive.
            // Attempts to make it reactive have caused major issues - infinite update cycles.
            this.scopeitems[this.controlData.Name] = this; // new PlainWrapper(this);
        }
    },
    destroyed() {
        if (this.scopeitems && this.controlData.Name)
            delete this.scopeitems[this.controlData.Name];
    },
    methods: {
        ...methods,
        ...filters,
        getSizeDefaults() {
            // Override in child control if you need to adjust the defaults for size
            // Ex: return { Height: { Mode: "Fill" } }
            return {};
        },
        finishRenderHandler(component) {
            if(this.finishedRender)
                return;
            if(component)
                this.finishedRenderChildren.push(component);
            this.renderCount++;
            //utils.debug(`finishRenderHandler: ${this.name} <- ${component ? component.name : 'noname'} ${this.renderCount} / ${this.totalItems}`);
            if(this.renderCount >= this.totalItems) {
                this.renderComplete();
            }
        },
        async renderComplete() {
            this.finishedRender = true;
            if (this.controlData)
                try {
                    await utils.executeAndCompileAllActions(this.controlData.PostrenderActions, null, this);
                } catch (e) {
                    utils.warn(e);
                }

            if (this.isdebug) {
                //utils.debug(`${this.LocationBrief} FINISH`);
                delete utils.document_cache.loadInProgress[this.Location];
            }
            this.$emit('finished-render', this);
            this.postRenderComplete();
        },
        preRenderComplete() {
            return;
        },
        postRenderComplete() {
            return;
        },
    },
    
};

export default baseMixin;