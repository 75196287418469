import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';
import filters from '../../../Shared/filters';

/*********
 * 
 *   DESIGNER base component
 * 
 *   !!! Don't confuse this version with the normal version, this is the DESIGNER version !!!
 * 
 *********/

var baseDesignerMixin = {
    data: function () {
        return {
        }
    },
    computed: {
        utils: function () {
            return utils;
        },
        Base: function () {
            let p = this;

            while (p && !p.isbase)
                p = p.$parent;

            return p || this.root._self;
        },
        GlobalVars: function () {
            return utils.global_variables;
        },
        ServiceVars: function () {
            return utils.service_variables;
        },
        System: function () {
            return {
                LocalStorage: function (key, value) {
                    var context = "";

                    //if (this.careTokenService)
                    //    context = this.careTokenService.UserID() + '_' + this.careTokenService.CustomerID();

                    if (value === undefined)
                        return localStorage.getItem('user.' + context + '.' + key);
                    else if (value == null)
                        localStorage.removeItem('user.' + context + '.' + key);
                    else
                        localStorage.setItem('user.' + context + '.' + key, value);
                },
                UserLocalStorage: function (key, value) {
                    var context = "";

                    //if (this.careTokenService)
                    //    context = this.careTokenService.UserID();

                    if (value === undefined)
                        return localStorage.getItem('user.' + context + '.' + key);
                    else if (value == null)
                        localStorage.removeItem('user.' + context + '.' + key);
                    else
                        localStorage.setItem('user.' + context + '.' + key, value);
                },
                NoContextLocalStorage: function (key, value) {
                    if (value === undefined)
                        return localStorage.getItem('user.' + key);
                    else if (value == null)
                        localStorage.removeItem('user.' + key);
                    else
                        localStorage.setItem('user.' + key, value);
                },
            };
        },
        Name: function () {
            return this.controlData ? (this.controlData.Name || 'unnamed') : 'no control data';
        },
        Root: function () {
            return this.root._self;
        },
        Parent: function () {
            let p = this.$parent;

            // Look for a parent object that is a CallCorp control (skip native vue controls)
            while (p && !p.root) {
                if (p.$attrs && 'c_context' in p.$attrs)
                    p = p.$attrs.c_context;
                else
                    p = p.$parent;
            }

            return p;
        },
        Vars: function () {
            return this.controlData ? this.controlData.Vars : {};
        },
        selected$: function () {
            return this.Root.SelectedNode && this.Root.SelectedModel == this.designmodel;
        },
    },
    async created() {
    },
    async mounted() {
    },
    async updated() {
    },
    methods: {
        ...methods,
        ...filters,
        handleClick(e) {
            this.Root.SelectNode(this);
            e.cancelBubble = true;
            e.stopPropagation();
        },
    },
    props: {
        name: '',
        root: null,
        designmodel: null,
        type: null,
        parentType: '',
        controlData: {},
    },
};

export default baseDesignerMixin;