import Vue from 'vue';
import BaseComponent from './baseFormMixin.jsx';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

import { appSettings } from '@/Shared/appSettings.js';

Vue.component('sform-switch', {
    mixins: [BaseComponent],
    data: () => ({
    }),
    props: {
    },
    created() {
    },
    mounted() {
        this.loadComplete();
    },
    computed: {
        translatedhint: function () {
            return this.Translate(this.hintText);
        }
    },
    methods: {
    },
    render(h) {        
        let scopedSlots = {
            message: ({message}) => {
                return <translation-container context={this} value={message}></translation-container>
            }
        }

        let slots = [
            <translation-container slot="label" context={this} value={this.labelText}></translation-container>,
        ];

        if (this.hintText) {
            let icon = <v-icon small color="blue-grey lighten-4">mdi mdi-information</v-icon>;
            icon = utils.generateTooltip(h, icon, this.translatedhint, 'right');
            icon.data.slot = "label";
            slots.push(icon);
        }
        
        let input = (
            <v-switch
                class="caption pa-0 ma-0"
                outlined
                single-line
                dense
                inset
                hide-details
                style={{ width: "100%" }}
                input-value={this.itemvalue}
                rules={this.rules}
                on-change={(value) => this.sync(value == true)}
                scopedSlots={scopedSlots}
                hint={this.hintText}
                on-blur={this.onBlur}
                on-focus={this.onFocus}
                persistent-hint={appSettings.DebugTranslationPrefixSetting}
            >{slots}</v-switch>
        );

        if (this.appearance)
            input.componentOptions.propsData = { ...input.componentOptions.propsData, ...this.appearance };

        if (this.directives) {
            if (input.data.directives)
                input.data.directives = [...input.data.directives, ...this.directives];
            else
                input.data.directives = this.directives;
        }

        return input;
    }
});