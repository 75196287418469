import Vue from "vue";
import utils from "../../Shared/utils.jsx";
import BaseComponent from "./BaseComponentMixin.jsx";
import { appSettings } from "../../Shared/appSettings.js";

Vue.component("recaptcha", {
    mixins: [BaseComponent],
    data: function() {
        return {
            reCaptchaUserResponse: null,
        };
    },
    computed: {
        styles() {
            return {
                ...this.sizeStyle,
                ...utils.resolveStyleHints(this.styleHints, this),
            };
        },
    },
    //Created replaced with preRenderComplete
    methods: {
        async preRenderComplete() {
            this.initReCaptcha();

            this.finishRenderHandler(this);
        },
        async initReCaptcha() {
            var self = this;

            setTimeout(function() {
                grecaptcha.render("grecaptcha", {
                    sitekey: appSettings.WhiteLabel.RecaptchaSiteKey,
                    callback: function(response) {
                        self.reCaptchaUserResponse = response;
                        self.runActions({ UserResponse: response });
                    },
                    "expired-callback": function() {
                        self.reCaptchaUserResponse = "";
                    },
                    "data-size": "compact",
                });
            });
        },
        async runActions(payload) {
            await utils.executeAndCompileAllActions(this.controlData.Actions, payload, this);
        },
    },
    render(h) {
        try {
            if (!this.todisplay) return null;

            return (
                <div
                    style={this.styles}
                    class={{ "c-ReCaptcha d-flex": true, [`c-name-${this.name || "unnamed"}`]: true }}
                >
                    <div id="grecaptcha"></div>
                </div>
            );
        } catch (e) {
            utils.error("ReCaptcha Render failed", e);
            return <div>ReCaptcha Failed to Render {e}</div>;
        }
    },
});
