import Vue from 'vue';
import BaseComponent from './baseFormMixin.jsx';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';
import careHelpfulFunctions from '../../careHelpfulFunctions.jsx';
import { appSettings } from '@/Shared/appSettings.js';

Vue.component('sform-lookuplist', {
    mixins: [BaseComponent],
    data: () => ({
        dolookupexpn: null,
        lookupurlexpn: null,
        lookuprawexpn: null,

        displayexpnfunc: null,
        valueexpnfunc: null,

        item_text: null,
        item_value: null,
        item_disabled: undefined,
        lookupitems: [],
        loading: false,

        arrayfield: null,
        arrayelement: null,
        keyextractor: null,
    }),
    props: {
    },
    created() {
        if (this.element.formatData) {
            if (this.element.formatData.LookupExpression)
                this.dolookupexpn = utils.compileExpression(this, this.element.formatData.LookupExpression);

            if ((!this.element.formatData.LookupType || this.element.formatData.LookupType == 'URL') && this.element.formatData.LookupURL)
                this.lookupurlexpn = utils.compile(this, this.element.formatData.LookupURL);
            else if (this.element.formatData.LookupType == 'Interpolated' && this.element.formatData.LookupInterpolatedExpression)
                this.lookuprawexpn = utils.compileExpression(this, this.element.formatData.LookupInterpolatedExpression);

            if (this.element.formatData.DisplayType == 'Expression') {
                const expr = utils.parseExpressionWithFilters(this.element.formatData.DisplayExpression);
                this.displayexpnfunc = {
                    code: expr,
                    func: new Function('control', 'util', this.element.formatData.LookupModelAs, `with (control) return ${expr};`)
                };
            }
            if (this.element.formatData.ValueType == 'Expression') {
                const expr = utils.parseExpressionWithFilters(this.element.formatData.ValueExpression);
                this.valueexpnfunc = {
                    code: expr,
                    func: new Function('control', 'util', this.element.formatData.LookupModelAs, `with (control) return ${expr};`)
                };
            }

            switch (this.element.formatData.DisplayType) {
                case 'Fieldname': this.item_text = this.element.formatData.DisplayField; break;
                case 'Expression':
                    this.item_text = (data) => {
                        try {
                            return data.$emptyrow ? data[this.element.formatData.DisplayField || 'text'] : this.displayexpnfunc.func(this, careHelpfulFunctions, data);
                        }
                        catch (e) {
                            utils.warn(`LookupList DisplayType Expression failed to evaluate: ${this.displayexpnfunc.code}; original: ${this.element.formatData.DisplayExpression}`, e);
                            return '';
                        }
                    };
                    break;
            }
            switch (this.element.formatData.ValueType) {
                case 'Fieldname': this.item_value = this.element.formatData.ValueField; break;
                case 'Expression':
                    this.item_value = (data) => {
                        try {
                            return data.$emptyrow ? data[this.element.formatData.ValueField || 'value'] : this.valueexpnfunc.func(this, careHelpfulFunctions, data);
                        }
                        catch (e) {
                            utils.warn(`LookupList ValueType Expression failed to evaluate: ${this.valueexpnfunc.code}; original: ${this.element.formatData.ValueExpression}`, e);
                            return '';
                        }
                    };
                    break;
            }

            if (this.element.formatData.UniqueValues) {
                // If this item is marked for uniqueness, then we must locate the array where
                // the list of items are held. Then as we populate the items for the dropdown,
                // we can filter out items that have already been selected in another list.
                this.arrayfield = this.ParentByType('FormField_ArrayItem')?.Parent;
                if (this.arrayfield) {
                    // Find the model reference that will allow us to read the array element from all
                    // other array items.
                    const keys = [...this.element.key];  // Ex: Queues.[].QueueName
                    const arraykeys = this.ParentByType('FormField_ArrayItem').element.key; // Ex: Queues.[]
                    keys.splice(0, arraykeys.length); // Ex: QueueName - isolate the element from the array down

                    // This is sufficient for 99% of the use cases, but if the key happens to be in an array
                    // for example Queues.[].Another.[1].QueueName, then this will fail. But I don't expect this
                    // to ever be a real issue, so the effort to support it isn't worth it. (7/11/2021)
                    const item = keys.join('.');
                    if (item)
                        this.keyextractor = new Function('item', `return item.${item};`);
                    else
                        this.keyextractor = new Function('item', `return item;`);

                    this.arrayelement = this.arrayfield.FieldValue;
                    if (this.arrayelement) {
                        if (!this.arrayfield.chosenitems) this.arrayfield.chosenitems = {};
                        if (!this.arrayfield.availableitems) Vue.set(this.arrayfield, 'availableitems', {});
                        this.arrayfield.chosenitems[this.chosenitems_key] = Object.fromEntries(this.arrayelement.map(a => [this.keyextractor(a), true]));
                        this.item_disabled = (item) => this.getItemValue(item) != this.itemvalue && this.getItemValue(item) in this.arrayfield.chosenitems[this.chosenitems_key];
                    }
                }
            }
        }

        if (this.lookupurlexpn)
            this.lookupurl_watch$ = this.$watch(
                function () {
                    return this.lookupurl;
                },
                function (newval, oldval) {
                    this.Refresh();
                }
            );

        if (this.lookuprawexpn)
            this.lookupraw_watch$ = this.$watch(
                function () {
                    return this.lookupraw;
                },
                function (newval, oldval) {
                    this.Refresh();
                }
            );
    },
    destroyed() {
        if (this.lookupurl_watch$)
            this.lookupurl_watch$();

        if (this.lookupraw_watch$)
            this.lookupraw_watch$();

        if (this.arrayfield && this.arrayelement) {
            // Uniqueness has been requested and we have a reference to the array of items already picked.
            // We need to generate a lookup object containing every selected item so that we can disable
            // those that are already picked in the current dropdown list.
            if (!this.arrayfield.chosenitems) this.arrayfield.chosenitems = {};
            this.arrayfield.chosenitems[this.chosenitems_key] = Object.fromEntries(this.arrayelement.map(a => [this.keyextractor(a), true]));

            // Update the array container to control if the Add button is enabled or not
            Vue.set(this.arrayfield.availableitems, this.chosenitems_key, this.lookupitems && (this.arrayelement.length < this.lookupitems.length));
        }
    },
    async mounted() {
        await this.Refresh();
        this.loadComplete();
    },
    computed: {
        chosenitems_key: function () {
            // Generate a key that is made from the name. Ex: Queue.[0].Name ==> Queue__0__Name
            return this.name.replace(/\./g, '_').replace(/\[/g, '_').replace(/\]/g, '_');
        },
        tolookup: function () {
            if (this.dolookupexpn)
                return utils.evaluate(this.dolookupexpn, this);
            else
                return true;
        },
        lookupurl: function () {
            if (this.lookupurlexpn)
                try {
                    return utils.evaluate(this.lookupurlexpn, this, false, null, true);
                }
                catch (e) {
                    utils.warn(`LookupList lookupurl could not be evaluated: ${this.lookupurlexpn.code}`, e);
                    return '';
                }
            else
                return '';
        },
        lookupraw: function () {
            if (this.lookuprawexpn)
                try {
                    return utils.evaluate(this.lookuprawexpn, this, false, null, true);
                }
                catch (e) {
                    utils.warn(`LookupList lookupraw could not be evaluated: ${this.lookuprawexpn.code}`, e);
                    return [];
                }
            else
                return '';
        },
        OnChangeData: function () {
            // This computed property is mixed into the Input data for a schema OnChange event action (see baseFormMixin.jsx)
            return {
                TitleMap: this.lookupitems.map(i => ({
                    value: (typeof this.item_value === 'function') ? this.item_value(i) : i[this.item_value],
                    record: i,
                }))
            };
        },
        TitleMap: function () {
            return this.lookupitems.map(i => ({
                value: (typeof this.item_value === 'function') ? this.item_value(i) : i[this.item_value],
                record: i,
            }));
        },
    },
    methods: {
        async Refresh() {
            if (this.tolookup) {
                this.loading = true;

                if (this.lookupurlexpn) {
                    let url = this.lookupurl;
                    if (url) {
                        const res = await utils.api.get(url, false, this.element.formatData.CacheLookupResult);
                        if (res && typeof res === 'object' && Array.isArray(res))
                            this.lookupitems = res;
                        else
                            this.lookupitems = [];
                    }
                }
                else if (this.lookuprawexpn) {
                    this.lookupitems = this.lookupraw;
                }

                const allowsNull = this.element && this.element.schema && this.element.schema.$allows_null ? true : false;
                if (allowsNull)
                    this.lookupitems.push({ $emptyrow: true, [this.element.formatData.DisplayField || 'text']: '', [this.element.formatData.ValueField || 'value']: null });

                const allowCustom = this.element.formatData && this.element.formatData.AllowCustomInput;
                const defaultAsEmpty = this.element.formatData && this.element.formatData.DefaultAsEmpty;

                // If no item is already selected, auto-select the first unused item in the list
                if (!allowCustom && !allowsNull && !defaultAsEmpty && Array.isArray(this.lookupitems) && this.lookupitems.length > 0) {
                    if (typeof this.item_value === 'function') {
                        let idx = this.lookupitems.findIndex(a => this.itemvalue == this.item_value(a));
                        if (idx < 0) {
                            if (this.itemvalue !== undefined) {
                                const NotFoundObj = {
                                    $emptyrow: true,
                                    [this.element.formatData.DisplayField || 'text']: '-Not Found-',
                                    [this.element.formatData.ValueField || 'value']: this.itemvalue
                                };

                                this.lookupitems.push(NotFoundObj);
                                idx = this.lookupitems.length - 1;
                            }
                            else if (this.arrayfield && this.arrayfield.chosenitems && this.arrayfield.chosenitems[this.chosenitems_key])
                                idx = this.lookupitems.findIndex(a => !(this.item_value(a) in this.arrayfield.chosenitems[this.chosenitems_key]));
                            else
                                idx = 0;

                            if (idx >= 0)
                                this.setValue(this.item_value(this.lookupitems[idx]));
                        }
                    }
                    else if (typeof this.item_value === 'string') {
                        let idx = this.lookupitems.findIndex(a => this.itemvalue == a[this.item_value]);
                        if (idx < 0) {
                            if (this.itemvalue !== undefined && this.itemvalue !== "") {
                                const NotFoundObj = {
                                    $emptyrow: true,
                                    [this.element.formatData.DisplayField || 'text']: '-Not Found-',
                                    [this.element.formatData.ValueField || 'value']: this.itemvalue
                                };

                                this.lookupitems.push(NotFoundObj);
                                idx = this.lookupitems.length - 1;
                            }
                            else if (this.arrayfield && this.arrayfield.chosenitems && this.arrayfield.chosenitems[this.chosenitems_key])
                                idx = this.lookupitems.findIndex(a => !(a[this.item_value] in this.arrayfield.chosenitems[this.chosenitems_key]));
                            else
                                idx = 0;

                            if (idx >= 0)
                                this.setValue(this.lookupitems[idx][this.item_value]);
                        }
                    }

                    if (this.arrayfield && this.arrayelement)
                        // Update the array container to control if the Add button is enabled or not
                        Vue.set(this.arrayfield.availableitems, this.chosenitems_key, this.lookupitems && (this.arrayelement.length < this.lookupitems.length));
                }

                this.loading = false;
            }
        },
        getItemValue(item) {
            if (typeof this.item_value === 'function')
                return this.item_value(item);
            else if (typeof this.item_value === 'string')
                return item[this.item_value];
            else
                return null;
        },
        setValue(value) {
            this.sync(value);

            if (this.arrayfield && this.arrayelement) {
                // Uniqueness has been requested and we have a reference to the array of items already picked.
                // We need to generate a lookup object containing every selected item so that we can disable
                // those that are already picked in the current dropdown list.
                this.arrayfield.chosenitems[this.chosenitems_key] = Object.fromEntries(this.arrayelement.map(a => [this.keyextractor(a), true]));

                // Update the array container to control if the Add button is enabled or not
                Vue.set(this.arrayfield.availableitems, this.chosenitems_key, this.lookupitems && (this.arrayelement.length < this.lookupitems.length));
            }
        },
    },
    render() {
        const items = this.lookupitems || [];

        let Tag = 'v-select';
        let keyup = ()=>{};
        
        if (this.element.formatData.AllowCustomInput) {
            Tag = 'v-combobox';
            keyup = (e) => this.setValue(e.target.value);
        } else if (this.element.formatData.AutoCompleteInput) {
            Tag = 'v-autocomplete';
        }

        let scopedSlots = {
            message: ({ message }) => {
                return <translation-container context={this} value={message} autoOpenModal></translation-container>
            }
        };

        let slots = [
            <translation-container slot="label" context={this} value={this.labelText} autoOpenModal></translation-container>,
        ];

        // editable={true}
        let input = (
            <Tag
                class="caption pa-0 ma-0" outlined single-line dense hide-details
                
                style={{ width: "100%" }}
                menu-props={{ offsetY: true, auto: true }}
                value={this.itemvalue}
                rules={this.rules}
                items={items}
                loading={this.loading}
                item-text={this.item_text}
                item-value={this.item_value}
                item-disabled={this.item_disabled}
                return-object={false}
                on-change={(value) => this.setValue(value)}
                on-keyup={keyup}
                scopedSlots={scopedSlots}
                on-blur={this.onBlur}
                on-focus={this.onFocus}
                hint={this.hintText}
                persistent-hint={appSettings.DebugTranslationPrefixSetting}
            >{slots}</Tag>
        );

        if (this.appearance)
            input.componentOptions.propsData = { ...input.componentOptions.propsData, ...this.appearance };

        if (this.directives) {
            if (input.data.directives)
                input.data.directives = [...input.data.directives, ...this.directives];
            else
                input.data.directives = this.directives;
        }

        return input;
    }
});