const currencyCodeList = [
    { Code: "XUA", Name: "ADB Unit of Account" },
    { Code: "AFN", Name: "Afghani" },
    { Code: "DZD", Name: "Algerian Dinar" },
    { Code: "ARS", Name: "Argentine Peso" },
    { Code: "AMD", Name: "Armenian Dram" },
    { Code: "AWG", Name: "Aruban Florin" },
    { Code: "AUD", Name: "Australian Dollar" },
    { Code: "AZN", Name: "Azerbaijan Manat" },
    { Code: "BSD", Name: "Bahamian Dollar" },
    { Code: "BHD", Name: "Bahraini Dinar" },
    { Code: "THB", Name: "Baht" },
    { Code: "PAB", Name: "Balboa" },
    { Code: "BBD", Name: "Barbados Dollar" },
    { Code: "BYN", Name: "Belarusian Ruble" },
    { Code: "BZD", Name: "Belize Dollar" },
    { Code: "BMD", Name: "Bermudian Dollar" },
    { Code: "BOB", Name: "Boliviano" },
    { Code: "VES", Name: "Bolívar Soberano" },
    { Code: "XBA", Name: "Bond Markets Unit European Composite Unit (EURCO)" },
    { Code: "XBB", Name: "Bond Markets Unit European Monetary Unit (E.M.U.-6)" },
    { Code: "XBD", Name: "Bond Markets Unit European Unit of Account 17 (E.U.A.-17)" },
    { Code: "XBC", Name: "Bond Markets Unit European Unit of Account 9 (E.U.A.-9)" },
    { Code: "BRL", Name: "Brazilian Real" },
    { Code: "BND", Name: "Brunei Dollar" },
    { Code: "BGN", Name: "Bulgarian Lev" },
    { Code: "BIF", Name: "Burundi Franc" },
    { Code: "CVE", Name: "Cabo Verde Escudo" },
    { Code: "CAD", Name: "Canadian Dollar" },
    { Code: "KYD", Name: "Cayman Islands Dollar" },
    { Code: "XOF", Name: "CFA Franc BCEAO" },
    { Code: "XAF", Name: "CFA Franc BEAC" },
    { Code: "XPF", Name: "CFP Franc" },
    { Code: "CLP", Name: "Chilean Peso" },
    { Code: "XTS", Name: "Codes specifically reserved for testing purposes" },
    { Code: "COP", Name: "Colombian Peso" },
    { Code: "KMF", Name: "Comorian Franc" },
    { Code: "CDF", Name: "Congolese Franc" },
    { Code: "BAM", Name: "Convertible Mark" },
    { Code: "NIO", Name: "Cordoba Oro" },
    { Code: "CRC", Name: "Costa Rican Colon" },
    { Code: "CUP", Name: "Cuban Peso" },
    { Code: "CZK", Name: "Czech Koruna" },
    { Code: "GMD", Name: "Dalasi" },
    { Code: "DKK", Name: "Danish Krone" },
    { Code: "MKD", Name: "Denar" },
    { Code: "DJF", Name: "Djibouti Franc" },
    { Code: "STN", Name: "Dobra" },
    { Code: "DOP", Name: "Dominican Peso" },
    { Code: "VND", Name: "Dong" },
    { Code: "XCD", Name: "East Caribbean Dollar" },
    { Code: "EGP", Name: "Egyptian Pound" },
    { Code: "SVC", Name: "El Salvador Colon" },
    { Code: "ETB", Name: "Ethiopian Birr" },
    { Code: "EUR", Name: "Euro" },
    { Code: "FKP", Name: "Falkland Islands Pound" },
    { Code: "FJD", Name: "Fiji Dollar" },
    { Code: "HUF", Name: "Forint" },
    { Code: "GHS", Name: "Ghana Cedi" },
    { Code: "GIP", Name: "Gibraltar Pound" },
    { Code: "XAU", Name: "Gold" },
    { Code: "HTG", Name: "Gourde" },
    { Code: "PYG", Name: "Guarani" },
    { Code: "GNF", Name: "Guinean Franc" },
    { Code: "GYD", Name: "Guyana Dollar" },
    { Code: "HKD", Name: "Hong Kong Dollar" },
    { Code: "UAH", Name: "Hryvnia" },
    { Code: "ISK", Name: "Iceland Krona" },
    { Code: "INR", Name: "Indian Rupee" },
    { Code: "IRR", Name: "Iranian Rial" },
    { Code: "IQD", Name: "Iraqi Dinar" },
    { Code: "JMD", Name: "Jamaican Dollar" },
    { Code: "JOD", Name: "Jordanian Dinar" },
    { Code: "KES", Name: "Kenyan Shilling" },
    { Code: "PGK", Name: "Kina" },
    { Code: "HRK", Name: "Kuna" },
    { Code: "KWD", Name: "Kuwaiti Dinar" },
    { Code: "AOA", Name: "Kwanza" },
    { Code: "MMK", Name: "Kyat" },
    { Code: "LAK", Name: "Lao Kip" },
    { Code: "GEL", Name: "Lari" },
    { Code: "LBP", Name: "Lebanese Pound" },
    { Code: "ALL", Name: "Lek" },
    { Code: "HNL", Name: "Lempira" },
    { Code: "SLL", Name: "Leone" },
    { Code: "LRD", Name: "Liberian Dollar" },
    { Code: "LYD", Name: "Libyan Dinar" },
    { Code: "SZL", Name: "Lilangeni" },
    { Code: "LSL", Name: "Loti" },
    { Code: "MGA", Name: "Malagasy Ariary" },
    { Code: "MWK", Name: "Malawi Kwacha" },
    { Code: "MYR", Name: "Malaysian Ringgit" },
    { Code: "MUR", Name: "Mauritius Rupee" },
    { Code: "MXN", Name: "Mexican Peso" },
    { Code: "MXV", Name: "Mexican Unidad de Inversion (UDI)" },
    { Code: "MDL", Name: "Moldovan Leu" },
    { Code: "MAD", Name: "Moroccan Dirham" },
    { Code: "MZN", Name: "Mozambique Metical" },
    { Code: "BOV", Name: "Mvdol" },
    { Code: "NGN", Name: "Naira" },
    { Code: "ERN", Name: "Nakfa" },
    { Code: "NAD", Name: "Namibia Dollar" },
    { Code: "NPR", Name: "Nepalese Rupee" },
    { Code: "ANG", Name: "Netherlands Antillean Guilder" },
    { Code: "ILS", Name: "New Israeli Sheqel" },
    { Code: "TWD", Name: "New Taiwan Dollar" },
    { Code: "NZD", Name: "New Zealand Dollar" },
    { Code: "BTN", Name: "Ngultrum" },
    { Code: "KPW", Name: "North Korean Won" },
    { Code: "NOK", Name: "Norwegian Krone" },
    { Code: "MRU", Name: "Ouguiya" },
    { Code: "TOP", Name: "Pa'anga" },
    { Code: "PKR", Name: "Pakistan Rupee" },
    { Code: "XPD", Name: "Palladium" },
    { Code: "MOP", Name: "Pataca" },
    { Code: "CUC", Name: "Peso Convertible" },
    { Code: "UYU", Name: "Peso Uruguayo" },
    { Code: "PHP", Name: "Philippine Peso" },
    { Code: "XPT", Name: "Platinum" },
    { Code: "GBP", Name: "Pound Sterling" },
    { Code: "BWP", Name: "Pula" },
    { Code: "QAR", Name: "Qatari Rial" },
    { Code: "GTQ", Name: "Quetzal" },
    { Code: "ZAR", Name: "Rand" },
    { Code: "OMR", Name: "Rial Omani" },
    { Code: "KHR", Name: "Riel" },
    { Code: "RON", Name: "Romanian Leu" },
    { Code: "MVR", Name: "Rufiyaa" },
    { Code: "IDR", Name: "Rupiah" },
    { Code: "RUB", Name: "Russian Ruble" },
    { Code: "RWF", Name: "Rwanda Franc" },
    { Code: "SHP", Name: "Saint Helena Pound" },
    { Code: "SAR", Name: "Saudi Riyal" },
    { Code: "XDR", Name: "SDR (Special Drawing Right)" },
    { Code: "RSD", Name: "Serbian Dinar" },
    { Code: "SCR", Name: "Seychelles Rupee" },
    { Code: "XAG", Name: "Silver" },
    { Code: "SGD", Name: "Singapore Dollar" },
    { Code: "PEN", Name: "Sol" },
    { Code: "SBD", Name: "Solomon Islands Dollar" },
    { Code: "KGS", Name: "Som" },
    { Code: "SOS", Name: "Somali Shilling" },
    { Code: "TJS", Name: "Somoni" },
    { Code: "SSP", Name: "South Sudanese Pound" },
    { Code: "LKR", Name: "Sri Lanka Rupee" },
    { Code: "XSU", Name: "Sucre" },
    { Code: "SDG", Name: "Sudanese Pound" },
    { Code: "SRD", Name: "Surinam Dollar" },
    { Code: "SEK", Name: "Swedish Krona" },
    { Code: "CHF", Name: "Swiss Franc" },
    { Code: "SYP", Name: "Syrian Pound" },
    { Code: "BDT", Name: "Taka" },
    { Code: "WST", Name: "Tala" },
    { Code: "TZS", Name: "Tanzanian Shilling" },
    { Code: "KZT", Name: "Tenge" },
    { Code: "XXX", Name: "The codes assigned for transactions where no currency is involved" },
    { Code: "TTD", Name: "Trinidad and Tobago Dollar" },
    { Code: "MNT", Name: "Tugrik" },
    { Code: "TND", Name: "Tunisian Dinar" },
    { Code: "TRY", Name: "Turkish Lira" },
    { Code: "TMT", Name: "Turkmenistan New Manat" },
    { Code: "AED", Name: "UAE Dirham" },
    { Code: "UGX", Name: "Uganda Shilling" },
    { Code: "CLF", Name: "Unidad de Fomento" },
    { Code: "COU", Name: "Unidad de Valor Real" },
    { Code: "UYW", Name: "Unidad Previsional" },
    { Code: "UYI", Name: "Uruguay Peso en Unidades Indexadas (UI)" },
    { Code: "USD", Name: "US Dollar" },
    { Code: "USN", Name: "US Dollar (Next day)" },
    { Code: "UZS", Name: "Uzbekistan Sum" },
    { Code: "VUV", Name: "Vatu" },
    { Code: "CHE", Name: "WIR Euro" },
    { Code: "CHW", Name: "WIR Franc" },
    { Code: "KRW", Name: "Won" },
    { Code: "YER", Name: "Yemeni Rial" },
    { Code: "JPY", Name: "Yen" },
    { Code: "CNY", Name: "Yuan Renminbi" },
    { Code: "ZMW", Name: "Zambian Kwacha" },
    { Code: "ZWL", Name: "Zimbabwe Dollar" },
    { Code: "PLN", Name: "Zloty" },
];

export default currencyCodeList;