import Vue from 'vue';
import utils from './utils.jsx';
import apiService from '@/Services/api';
import { appSettings } from './appSettings';
import regionCodeList from './regionCodeList';
import currencyCodeList from './currencyCodes';
import token from '@/Services/token';

const computed = {
    utils: function () {
        return utils;
    },
    isdebug: function () {
        return appSettings.DEBUG;
    },
    showbase: function () {
        return appSettings.SHOWBASE;
    },
    apiService: function () {
        return apiService
    },
    Base: function () {
        let p = this;

        while (p && !p.isbase)
            if (p.$attrs && 'c_context' in p.$attrs)
                p = p.$attrs.c_context;
            else
                p = p.$parent;

        return p || this.root._self;
    },
    Params: function () {
        return this.controlscope || {};
    },
    Control: function () {
        //return (this.usecontroldatavalue ? this.controldatavalue : this.controlscope) || {};
        //return this.controlscope || {};
        return this.scopeitems;
    },
    GlobalVars: function () {
        return utils.global_variables;
    },
    ServiceVars: function () {
        return utils.service_variables;
    },
    Self: function() {
        return this;
    },
    System: function () { return appSettings; },
    Name: function () {
        return this.controlData ? (this.controlData.Name || 'unnamed') : 'no control data';
    },
    Root: function () {
        return this.root._self;
    },
    Parent: function () {
        let p = this.$parent;

        // Look for a parent object that is a CallCorp control (skip native vue controls)
        while (p && (!p.root || p.is_vstack_parent)) {
            if (p.$attrs && 'c_context' in p.$attrs)
                p = p.$attrs.c_context;
            else
                p = p.$parent;
        }

        return p;
    },
    Location: function () {
        let path = [];
        let p = this;
        while (p) {
            if ((p.name || p.type)) // && (!p.name || !p.name.includes('__parent')))
            {
                if (p.name && p.name.includes('__parent'))
                    path.push({ name: '', type: 'p', id: p._uid });
                else
                    path.push({ name: p.name, type: p.type, id: p._uid });
            }

            p = p.$parent;
        }
        if (path.length === 0)
            path.push({ type: this.$vnode.tag });

        return path.reverse().map(i => `${(i.name ? (i.name + '_' + i.id) : '') || ('[' + i.type + '_' + i.id + ']')}`).join('.');
    },
    LocationBrief: function () {
        let res = this.Location;
        let idx = res.indexOf('.staticTabContent_');
        if (idx >= 0) {
            res = res.substr(idx + 1);
            idx = res.indexOf('.');
            if (idx >= 0)
                res = '[base].' + res.substr(idx + 1);
        }

        return res;
        //return res.replace('[p].[ControlContainer].[p].[VerticalLayout].[p].mainContainer.[p].mainArea.dynamicTabContent.staticTabContent.', '[std].');
    },
    careRegionCodeList: function () {
        return regionCodeList;
    },
    careCurrencyCodeList: function () {
        return currencyCodeList;
    },
}

export default computed;