import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

Vue.component('control-designer-dsgn', {
    data: function () {
        return {
        }
    },
    computed: {
        Root: function () {
            return this.root._self;
        },
        selected$: function () {
            return this.Root.SelectedNode && this.Root.SelectedModel == this.designmodel;
        }
    },
    methods: {
        ...methods,
        handleClick(e) {
            this.Root.SelectNode(this);
            e.cancelBubble = true;
            e.stopPropagation();
        },
        DesignModel: function () {
            return this.designmodel;
        },
    },
    props: {
        name: '',
        root: null,
        designmodel: null,
        parentType: '',
        controlData: {}
    },
    render(h) {
        const publicChkbx = <input type="checkbox" checked="true" readonly />;
        const nameInput = <input type="text" size="10" value="Name" readonly />;
        const save = <button disabled>Save</button>;
        const savenew = <button disabled>Save As New</button>;
        const navup = <button disabled title="Navigate to Parent Container"> Up < i class="mdi mdi-arrow-up"></i></button>;

        const style = {
            backgroundColor: "#a0ffa0",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            ...utils.getSize(this.controlData.SizeOptions, this.parentType, this.$parent),
        };

        return (
            <div
                style={style}
                class={{ 'designer-container': true, selected: this.selected$ }}
                on-click={(e) => this.handleClick(e)}>
                <div style={{ flexGrow: "0", flexShrink: "0", minHeight: "24px", maxHeight: "24px", background: "silver" }}>
                    <span>Public: {publicChkbx} Name: {nameInput} {save} {savenew} {navup}</span>
                </div>

                <div style={{ display: "flex", flexDirection: "row", flexGrow: "1" }}>
                    <div class="designer-base" style={{ minWidth: "800px", maxWidth: "800px", overflow: "auto", backgroundColor: "antiquewhite" }}>
                        
                    </div>

                    <div style={{ borderStyle: 'solid', borderWidth: '1px', "box-sizing": "border-box", "overflow": "auto", backgroundColor: "pink" }}>
                        
                    </div>
                </div>

                <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
            </div>
        );
    }
});