import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';

Vue.component('hidden-container', {
    mixins: [BaseComponent],
    data: function () {
        return {
        }
    },
    //Mounted Replaced with preRenderComplete
    computed: {
    },
    methods: {
        preRenderComplete() {
            this.finishRenderHandler(this);
        },
    },
    props: {
    },
    render(h) {
        return null;
    }
});