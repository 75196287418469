<template class="c-ToolPanelChart">
    <v-card class="c-ToolPanel_1 mx-auto w-100 pa-0" :elevation="0" style="width: 100%; display: flex; flex-direction: column; min-width: 750px; max-width: 750px;" v-if="isvisible">
        <v-card class="mx-auto w-100" :elevation="0" color="extraLightGrey" style="overflow: auto; width: 100%; display: flex; flex-direction: column; flex-basis: 0; flex-grow: 1;">
            <v-expansion-panels mandatory accordion>
                <v-expansion-panel>
                    <v-expansion-panel-header hide-actions class="text-h6">
                        Chart Settings
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                        <v-card-subtitle class="mt-2 pb-0 consistentText" style="border-bottom: 2px solid silver;">
                            GENERAL
                        </v-card-subtitle>
                        <v-card-text class="mt-2 consistentText">
                            Applies to all series.
                        </v-card-text>

                        <v-card-text>
                            <v-text-field v-model="custom_chart_options.title.text" class="mb-2"
                                        @input="updateChart" outlined dense hide-details label="Title"></v-text-field>

                            <v-text-field v-model="custom_chart_options.subtitle.text" class="mb-2"
                                        @input="updateChart" outlined dense hide-details label="Sub-Title"></v-text-field>

                            <v-text-field :style="{ visibility: all_series.some(a => a.type == 'bar') ? 'visible' : 'hidden' }" class="mb-2"
                                        type="number"
                                        v-model="cornerRadius"
                                        @change="updateChart"
                                        outlined
                                        dense
                                        label="Corner Radius"
                                        hide-details></v-text-field>

                            <v-text-field type="number"
                                        v-model="maxValues" :rules="maxValuesRules"
                                        outlined
                                        dense
                                        label="Maximum Data Points"
                                        hint="Constrains the results of the query to this number of rows (default 100, 1-5000 allowed)"
                                        ></v-text-field>

                            <v-btn-toggle mandatory class="mr-3" v-model="general_type">
                                <v-btn title="Bar"><v-icon>mdi-chart-bar</v-icon></v-btn>
                                <v-btn title="Stacked Bar"><v-icon>mdi-chart-bar-stacked</v-icon></v-btn>
                                <v-btn title="Line"><v-icon>mdi-chart-line</v-icon></v-btn>
                                <v-btn title="Area"><v-icon>mdi-chart-areaspline-variant</v-icon></v-btn>
                                <v-btn title="Stacked Area"><v-icon>mdi-chart-areaspline</v-icon></v-btn>
                                <v-btn title="Pie"><v-icon>mdi-chart-pie</v-icon></v-btn>
                                <v-btn title="Donut"><v-icon>mdi-chart-donut</v-icon></v-btn>
                            </v-btn-toggle>

                            <v-btn-toggle mandatory v-if="[0,1,7].includes(general_type)" class="mr-3" v-model="general_direction">
                                <v-btn title="Vertical"><v-icon>mdi-chart-bar</v-icon></v-btn>
                                <v-btn title="Horizontal"><v-icon class="mdi-rotate-90">mdi-chart-bar</v-icon></v-btn>
                            </v-btn-toggle>

                            <v-btn-toggle mandatory v-if="[1,4].includes(general_type)" v-model="general_percent">
                                <v-btn title="Standard"><v-icon class="mdi-rotate-270">mdi-format-align-left</v-icon></v-btn>
                                <v-btn title="Normalized to 100"><v-icon class="mdi-rotate-270">mdi-format-align-justify</v-icon></v-btn>
                            </v-btn-toggle>

                        </v-card-text>
                    </v-expansion-panel-content>
                    <v-expansion-panel-content>
                        <v-card-subtitle class="mt-2 pb-0 consistentText" style="border-bottom: 2px solid silver;">
                            SPECIFIC
                        </v-card-subtitle>
                        <v-card-text class="mt-2 consistentText">
                            Series specific settings. For unique chart types per series, select the mixed chart above.
                        </v-card-text>

                        <v-card-text>
                            <v-tabs v-model="active_tab" class="chart-tabs">
                                <v-tab>Series</v-tab>
                                <v-tab>Axes</v-tab>
                                <v-tab>Legend</v-tab>

                                <v-tab-item style="background-color: #fafafa">
                                    <v-expansion-panels accordion focusable v-model="selectedPanel">
                                        <v-expansion-panel v-for="(item, i) in all_series" :key="i">
                                            <v-expansion-panel-header class="text-h6" color="grey lighten-5" style="font-size: 1rem !important">
                                                Series {{ i+1 }}: {{ legendFormatter({ value: item.yKey }) }}
                                            </v-expansion-panel-header>

                                            <v-expansion-panel-content color="grey lighten-5">
                                                <v-card-text>
                                                    <v-btn-toggle v-show="general_type < 5" mandatory class="mr-3" :value="getChart(item)" _change="(idx) => setChart(item, idx)">
                                                        <v-btn @click="setChartType(item, 'bar')" :disabled="isCustomTypeDisabled" title="Bar"><v-icon>mdi-chart-bar</v-icon></v-btn>
                                                        <v-btn @click="setChartType(item, 'bar', true)" :disabled="isCustomTypeDisabled" title="Stacked Bar"><v-icon>mdi-chart-bar-stacked</v-icon></v-btn>
                                                        <v-btn @click="setChartType(item, 'line')" :disabled="isCustomTypeDisabled" v-if="general_direction === 0" title="Line"><v-icon>mdi-chart-line</v-icon></v-btn>
                                                        <v-btn @click="setChartType(item, 'area')" :disabled="isCustomTypeDisabled" v-if="general_direction === 0" title="Area"><v-icon>mdi-chart-areaspline-variant</v-icon></v-btn>
                                                        <v-btn @click="setChartType(item, 'area', true)" :disabled="isCustomTypeDisabled" v-if="general_direction === 0" title="Stacked Area"><v-icon>mdi-chart-areaspline</v-icon></v-btn>
                                                    </v-btn-toggle>

                                                    <v-btn-toggle mandatory v-show="general_type < 5 && [1,4].includes(getChart(item))" :value="getNorm(item)" @change="(idx) => setNorm(item, idx)">
                                                        <v-btn :disabled="isCustomTypeDisabled" title="Standard"><v-icon class="mdi-rotate-270">mdi-format-align-left</v-icon></v-btn>
                                                        <v-btn :disabled="isCustomTypeDisabled" title="Normalized to 100"><v-icon class="mdi-rotate-270">mdi-format-align-justify</v-icon></v-btn>
                                                    </v-btn-toggle>

                                                    <v-btn v-if="item.override_type || item.override_stacked" title="Revert to Default" icon class="ml-5 mt-0" @click="revert(item)"><v-icon>mdi-undo</v-icon></v-btn>
                                                </v-card-text>

                                                <v-card-text>
                                                    <v-text-field v-if="item.override_type ? (item.override_type == 'bar' || item.override_type == 'line') : [0,1,2].includes(general_type)"
                                                                v-model="item.override_title"
                                                                @input="updateChart"
                                                                outlined
                                                                dense
                                                                hint="Customizes the series name as seen in the legend"
                                                                class="ma-2"
                                                                label="Series Name Override"></v-text-field>

                                                    <v-select v-if="item.override_stacked || generalTypes[general_type].stacked"
                                                            _vmodel="item.stackGroup"
                                                            :value="item.override_group || item.stackGroup"
                                                            @change="(value) => setStackGroup(item, value)"
                                                            :items="stackGroupItems"
                                                            :menu-props="{ offsetY: true }"
                                                            outlined
                                                            dense
                                                            hide-details
                                                            class="ma-2"
                                                            label="Group"></v-select>

                                                    <div v-if="item.override_type == 'line' || generalTypes[general_type].type == 'line' || item.override_type == 'area' || generalTypes[general_type].type == 'area'"
                                                        style="display: flex; flex-direction: row; flex-wrap: wrap;">
                                                        <v-checkbox v-model="item.marker_enabled"
                                                                    @change="updateChart"
                                                                    label="Show Marker"
                                                                    dense
                                                                    class="ma-2"
                                                                    hide-details></v-checkbox>

                                                        <v-checkbox v-model="item.label_enabled"
                                                                    @change="updateChart"
                                                                    label="Show Labels"
                                                                    dense
                                                                    class="ma-2"
                                                                    hide-details></v-checkbox>

                                                        <v-select v-if="item.marker_enabled"
                                                                :items="['circle','cross','diamond','heart','plus','pin','square','star','triangle']"
                                                                v-model="item.marker_shape"
                                                                @change="updateChart"
                                                                :menu-props="{ offsetY: true }"
                                                                outlined
                                                                dense
                                                                hide-details
                                                                class="ma-2"
                                                                label="Marker Shape"></v-select>

                                                        <v-text-field v-if="item.marker_enabled"
                                                                    v-model="item.marker_size"
                                                                    @input="updateChart"
                                                                    outlined
                                                                    dense
                                                                    hide-details
                                                                    class="ma-2"
                                                                    label="Marker Size"
                                                                    type="number"></v-text-field>

                                                        <v-text-field v-model="item.line_width"
                                                                    @input="updateChart"
                                                                    outlined
                                                                    dense
                                                                    hide-details
                                                                    class="ma-2"
                                                                    label="Line Width"
                                                                    type="number"></v-text-field>
                                                    </div>

                                                    <v-select :value="getItemAxis(item)" @change="(value) => setItemAxis(item, value)"
                                                            :items="chartOptionsLocal.axes.filter(a => a.keys).concat(extraAxes).map(a => a.title.text)"
                                                            :menu-props="{ offsetY: true }"
                                                            outlined
                                                            dense
                                                            hide-details
                                                            class="ma-2"
                                                            label="Axis">

                                                        <template v-slot:append-outer>
                                                            <v-btn small icon @click="addItemAxis(item)"><v-icon style="color: var(--v-primary-base)" title="Add">mdi-plus</v-icon></v-btn>
                                                        </template>
                                                    </v-select>
                                                    
                                                    <v-container v-if="item.type !== 'pie' && item.type !== 'donut'">
                                                        Color: <v-menu offset-y>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn small v-bind="attrs" v-on="on" class="ml-4">
                                                                    <v-btn x-small text disabled :style="{ backgroundColor: item.color }"></v-btn>
                                                                    <v-icon>mdi-menu-down</v-icon>
                                                                </v-btn>
                                                            </template>

                                                            <v-color-picker @input="updateChart"
                                                                            label="Color"
                                                                            v-model="item.color"
                                                                            :value="item.color"
                                                                            @update:color="({hex}) => item.color = hex"
                                                                            :swatches="color_swatches"
                                                                            show-swatches
                                                                            outlined
                                                                            dense
                                                                            hide-details></v-color-picker>
                                                        </v-menu>
                                                    </v-container>
                                                </v-card-text>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>

                                    <pre v-if="show_debug" style="max-height: 400px; overflow: auto; font-size: small;">
                                    {{ seriesJSON }}
                                    </pre>
                                </v-tab-item>

                                <v-tab-item>
                                    <v-expansion-panels accordion focusable v-model="selectedPanel">
                                        <v-expansion-panel v-for="(item, i) in chartOptionsLocal.axes.concat(extraAxes)" :key="i">
                                            <v-expansion-panel-header class="text-h6" color="grey lighten-5" style="font-size: 1rem !important">
                                                <v-icon left style="flex: none">
                                                    {{ (item.position == 'bottom' || item.position == 'top') ? 'mdi-alpha-x-box-outline' : 'mdi-alpha-y-box-outline' }}
                                                </v-icon>
                                                {{ item.title.text }}
                                                <v-btn icon small style="flex: 0 0 auto"
                                                    v-if="i >= chartOptionsLocal.axes.length"
                                                    @click="removeExtraAxis(i - chartOptionsLocal.axes.length)">
                                                    <v-icon small>mdi-close-circle</v-icon>
                                                </v-btn>
                                            </v-expansion-panel-header>

                                            <v-expansion-panel-content class="mt-4" color="grey lighten-5">
                                                <v-text-field label="Title" v-model="item.title.text" outlined dense hide-details @input="updateChart"></v-text-field>

                                                <v-select @change="updateChart"
                                                        class="mt-4"
                                                        label="Axis Type"
                                                        :items="['time','ordinal-time','category','number','log']"
                                                        :menu-props="{ offsetY: true }"
                                                        v-model="item.type"
                                                        outlined
                                                        dense
                                                        hide-details></v-select>

                                                <v-card-actions class="pl-0 pr-0">
                                                    Position:
                                                    
                                                    <v-radio-group v-model="item.position" row @change="updateChart" class="ml-2" v-if="item.position == 'bottom' || item.position == 'top'">
                                                        <v-radio value="top" label="Top"></v-radio>
                                                        <v-radio value="bottom" label="Bottom"></v-radio>
                                                    </v-radio-group>

                                                    <v-radio-group v-model="item.position" row @change="updateChart" class="ml-2" v-if="item.position == 'left' || item.position == 'right'">
                                                        <v-radio value="left" label="Left"></v-radio>
                                                        <v-radio value="right" label="Right"></v-radio>
                                                    </v-radio-group>
                                                    
                                                    <!--Color: <v-menu offset-y>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn small v-bind="attrs" v-on="on" class="ml-4">
                                                                <v-btn x-small text disabled :style="{ backgroundColor: item.label.color }"></v-btn>
                                                                <v-icon>mdi-menu-down</v-icon>
                                                            </v-btn>
                                                        </template>

                                                        <v-color-picker @input="updateChart"
                                                                        label="Color"
                                                                        _vmodel="item.label.color"
                                                                        :value="item.label.color"
                                                                        @update:color="({hex}) => item.label.color = hex"
                                                                        :swatches="color_swatches"
                                                                        show-swatches
                                                                        outlined
                                                                        dense
                                                                        hide-details></v-color-picker>
                                                    </v-menu>-->
                                                </v-card-actions>

                                                <v-card-actions class="pl-0 pr-0" style="gap: 16px;">
                                                    <v-text-field v-if="item.type == 'number'"
                                                                label="Tick Interval"
                                                                class="mt-0 pt-0"
                                                                outlined dense
                                                                hide-details
                                                                type="number"
                                                                :value="getTickInterval(item)"
                                                                @change="(v) => setTickInterval(item, v)"></v-text-field>

                                                    <v-text-field label="Tick Min Spacing"
                                                                outlined dense
                                                                hide-details
                                                                type="number"
                                                                v-model.number="item.tick.minSpacing"
                                                                @change="updateChart"></v-text-field>

                                                    <v-text-field label="Tick Max Spacing"
                                                                outlined dense
                                                                hide-details
                                                                type="number"
                                                                v-model.number="item.tick.maxSpacing"
                                                                @change="updateChart"></v-text-field>

                                                </v-card-actions>

                                                <v-card-subtitle class="pl-0 pr-0" v-if="item.keys">
                                                    Series: <v-chip v-for="(chip,j) in getAxisSeries(item.keys)" :key="j" class="ma-2">{{ chip }}</v-chip>
                                                    <!--{{ getAxisSeries(item.keys) }}-->
                                                </v-card-subtitle>
                                                
                                                <pre v-if="show_debug" style="max-height: 400px; overflow: auto; font-size: small;">
                                                {{ axesJSON }}
                                                </pre>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-tab-item>

                                <v-tab-item>
                                    <v-card color="grey lighten-5">
                                        <v-card-text>
                                            <v-select outlined dense hide-details
                                                    _class="mt-2"
                                                    label="Position"
                                                    :menu-props="{ offsetY: true }"
                                                    v-model="legend_position"
                                                    :items="legend_positions"></v-select>

                                            <v-checkbox v-if="isPivotting" dense hide-details label="Suppress Metric Titles Suffix" v-model="suppress_metric_suffix"></v-checkbox>
                                            <!--<v-checkbox dense hide-details label="Show Series Stroke" :disabled="disable_show_series_stroke" v-model="show_series_stroke"></v-checkbox>-->
                                        </v-card-text>
                                    </v-card>
                                </v-tab-item>

                            </v-tabs>

                            <v-icon v-if="false" small title="Toggle Debug" @click="show_debug=!show_debug">mdi-bug</v-icon>
                        </v-card-text>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card>
    </v-card>
</template>

<script>
    /* eslint-disable */

    import common from './common.js';

    export default {
        components: {
        },
        props: {
            column_list: {
                type: Array,
                required: false
            },
            settings: null,
            isvisible: null,
            initial_settings: null,
            chartOptions: null,
            pivot_columns: null,
            legendFormatter: null,
            notifychange: null,
            notifyrefresh: null,
            updateserialization: null,
        },
        data: function () {
            return {
                active_tab: 0,
                active_series_tab: 0,
                active_axes_tab: 0,
                show_debug: false,
                general_type: 0,
                general_direction: 0,
                general_percent: 0,
                maxValues: 100,
                selectedPanel: 0,

                maxValuesRules: [
                    value => (value > 0 && value <= 5000) || 'Between 1-5000 only'
                ],

                chartOptionsLocal: null,
                chartTypeSelection: 0,
                chartTypeList: [
                    { id: 0, fn: 'chart_bar_vert.png', name: 'Vertical Bar', settings: { type: 'bar' } },
                    { id: 1, fn: 'chart_bar_horz.png', name: 'Horizontal Bar', settings: { type: 'bar', direction: 'horizontal' } },
                    { id: 2, fn: 'chart_bar_vert_stacked.png', name: 'Vertical Bar Stacked', settings: { type: 'bar', stacked: true } },
                    { id: 3, fn: 'chart_bar_horz_stacked.png', name: 'Horizontal Bar Stacked', settings: { type: 'bar', direction: 'horizontal', stacked: true } },
                    { id: 4, fn: 'chart_bar_vert_stacked_100.png', name: 'Vertical Bar Stacked 100%', settings: { type: 'bar', stacked: true, normalizedTo: 100 } },
                    { id: 5, fn: 'chart_bar_horz_stacked_100.png', name: 'Horizontal Bar Stacked 100%', settings: { type: 'bar', direction: 'horizontal', stacked: true, normalizedTo: 100 } },
                    { id: 6, fn: 'chart_line.png', name: 'Line', settings: { type: 'line' } },
                    { id: 7, fn: 'chart_area.png', name: 'Area', settings: { type: 'area' } },
                    { id: 8, fn: 'chart_area_stacked.png', name: 'Area Stacked', settings: { type: 'area', stacked: true } },
                    { id: 9, fn: 'chart_pie.png', name: 'Pie', settings: { type: 'pie' } },
                    { id: 10, fn: 'chart_donut.png', name: 'Donut', settings: { type: 'donut' } },
                ],

                legend_positions: [
                    { text: 'Left', value: 'left' },
                    { text: 'Top', value: 'top' },
                    { text: 'Right', value: 'right' },
                    { text: 'Bottom', value: 'bottom' },
                ],
                legend_position: 'bottom',
                suppress_metric_suffix: false,
                show_series_stroke: false,
                disable_show_series_stroke: false,

                chartSeries: [],
                chartAxes: [],
                extraAxes: [],

                pivot_series: [],

                custom_chart_options: {
                    title: { text: '' },
                    subtitle: { text: '' },
                 },

                cornerRadius: 0,

                generalTypes: [
                    { type: 'bar' },
                    { type: 'bar', stacked: true },
                    { type: 'line' },
                    { type: 'area' },
                    { type: 'area', stacked: true },
                    { type: 'pie' },
                    { type: 'donut' },
                    { type: '' },
                ],
                customTypes: [
                    { type: 'bar' },
                    { type: 'bar', stacked: true },
                    { type: 'line' },
                    { type: 'area' },
                    { type: 'area', stacked: true },
                ],

                color_swatches: common.swatches,
                //[
                //    ['#FF0000', '#AA0000', '#550000'],
                //    ['#FFFF00', '#AAAA00', '#555500'],
                //    ['#00FF00', '#00AA00', '#005500'],
                //    ['#00FFFF', '#00AAAA', '#005555'],
                //    ['#0000FF', '#0000AA', '#000055'],
                //],
            }
        },
        watch: {
            general_percent: function (newv) {
                this.updateChart();
            },
            general_direction: function (newv) {
                this.updateChart();
            },
            general_type: function(newv) {
                this.updateChart();
            },
            chartOptions: function(newv) {
                console.log('received chartOptions:');
                console.log(JSON.stringify(newv.series, null, 3));

                // Merge updated options into local copy - series first:
                const updated_series = [];
                for(let i=0; i<newv.series.length; i++) {
                    const s = newv.series[i];
                    const f = this.chartOptionsLocal.series.find(a => a.yKey == s.yKey);

                    if (f) {
                        const new_s = { ...s, ...f, xKey: s.xKey };

                        updated_series.push(new_s);
                    }
                    else 
                        updated_series.push(s);
                }
                console.log('updated chartOptions:');
                console.log(JSON.stringify(updated_series,null,3));

                const updated_axes = [];
                for(let i=0; i<newv.axes.length; i++) {
                    const x = newv.axes[i];
                    const f = this.chartOptionsLocal.axes.find(a => a.title.text == x.title.text);

                    if (f) {
                        const new_a = { ...x, ...f };

                        if (x.keys)
                            for(let j=0; j<x.keys.length; j++) {
                                // Ensure all the keys in the received axis are contained elsewhere, otherwise, add them
                                if (!this.chartOptionsLocal.axes.concat(this.extraAxes).some(a => a.keys && a.keys.includes(x.keys[j])))
                                    new_a.keys.push(x.keys[j]);
                            }

                        updated_axes.push(new_a);
                    }
                    else
                        updated_axes.push(x);
                }

                this.chartOptionsLocal.series = updated_series;
                this.chartOptionsLocal.axes = updated_axes;
                this.updateChart(true);
            },
            //pivot_columns: function(newv) {
            //    this.generatePivotSeries(newv);
            //},
            cornerRadius: function(newv) {
                this.updateChart();
            },
            maxValues: function(newv) {
                if (newv > 0 && newv <= 5000)
                    this.notifychange(this.chartSeries, this.chartAxes, this.generalTypes[this.general_type], this.custom_chart_options, null, newv, true);
            },
            legend_position: function(newv) {
                this.updateChart(true);
            },
            suppress_metric_suffix: function (newv) {
                this.updateChart(true);
            },
            show_series_stroke: function (newv) {
                this.updateChart(true);
            },
        },
        created() {
            if (this.initial_settings && this.initial_settings.series) {
                this.chartOptionsLocal = {
                    series: this.initial_settings.series,
                    axes: this.initial_settings.axes,
                };
                this.custom_chart_options = this.initial_settings.options;
                this.legend_position = this.initial_settings.legend?.position || 'bottom';
                this.maxValues = this.initial_settings.maxValues;
                
                this.general_type = this.generalTypes.findIndex(a =>
                    a.type == this.initial_settings.typeSettings.type &&
                    a.stacked == this.initial_settings.typeSettings.stacked
                );

                if (this.initial_settings.typeSettings.direction)
                    this.general_direction = ['vertical', 'horizontal'].indexOf(this.initial_settings.typeSettings.direction);

                // Walk through each series and identify those that have override settings
                for (let s of this.chartOptionsLocal.series) {
                    if (s.type != this.initial_settings.typeSettings.type || s.stacked != this.initial_settings.typeSettings.stacked) {
                        s.override_type = s.type;
                        s.override_stacked = s.stacked;
                    }
                    if (s.normalizedTo == 100)
                        s.override_norm = '1';
                    if (s.title)
                        s.override_title = s.title;
                    else if (s.legendItemName)
                        s.override_title = s.legendItemName;

                    if (s.marker && s.marker.enabled) {
                        if (s.marker.shape)
                            s.marker_shape = s.marker.shape;
                        if (s.marker.size)
                            s.marker_size = s.marker.size.toString();
                    }

                    if (s.label && s.label.enabled)
                        s.label_enabled = s.label.enabled;

                    if (s.strokeWidth)
                        s.line_width = s.strokeWidth.toString();

                    if (s.stroke)
                        s.color = s.stroke;

                    if(s.fill)
                        s.color = s.fill;
                }
            }
            else
                this.chartOptionsLocal = {
                    series: [ ...this.chartOptions.series ],
                    axes: [ ...this.chartOptions.axes.map(x => ({ ...x })) ],
                };
        },
        mounted() {
        },
        computed: {
            all_series: function() {
                return this.chartOptionsLocal.series; // .concat(this.pivot_series);
            },
            chartTypes: function() {
                return this.chartTypeList.map(a => a.settings);
            },
            seriesJSON: function() {
                return JSON.stringify(this.chartSeries,null,3);
            },
            axesJSON: function () {
                return JSON.stringify(this.chartOptionsLocal.axes, null, 3);
            },
            stackGroupItems: function() {
                return [
                    ...new Set(this.chartOptionsLocal.series.filter(a => a.stackGroup).map(a => a.stackGroup)),
                    'customGroup1',
                    'customGroup2',
                    'customGroup3',
                    'customGroup4',
                    'customGroup5',
                    'customGroup6',
                ];
            },
            isCustomTypeDisabled: function() {
                return this.chartOptionsLocal.series.length == 1;
            },
            isPivotting: function() {
                return this.pivot_columns && this.pivot_columns.length > 0;
            },
        },
        methods: {
            updateChart(refreshOnly) {
                //console.log('chartOptionsLocal.series:');
                //console.log(JSON.stringify(this.chartOptionsLocal.series,null,3));

                const chart = this.generalTypes[this.general_type];
                const series = [];
                this.disable_show_series_stroke = false;
                for (let s of this.all_series) {
                    const new_s = {
                        type: s.override_type || chart.type,
                        xKey: s.xKey,
                        yKey: s.yKey,
                    };
                    if (new_s.type == 'bar') {
                        new_s.direction = ['vertical', 'horizontal'][this.general_direction];

                        if (this.cornerRadius)
                            new_s.cornerRadius = parseInt(this.cornerRadius);
                    }
                    if (s.override_stacked === true)
                        new_s.stacked = true;
                    else if (s.override_stacked === false)
                        new_s.stacked = false;
                    else if (chart.stacked)
                        new_s.stacked = chart.stacked;

                    if (new_s.stacked && (s.override_group || s.stackGroup))
                        new_s.stackGroup = s.override_group || s.stackGroup;

                    if (s.override_norm) {
                        // If overridden, honor the either set or unset state (if not set, leave out)
                        if (s.override_norm == '1')
                            new_s.normalizedTo = 100;
                    }
                    else if (this.general_percent)
                        // If not overridden, honor the general setting
                        new_s.normalizedTo = 100;

                    if (s.override_title)
                        switch (new_s.type){
                            case 'line': new_s.title = s.override_title; break;
                            case 'bar': new_s.legendItemName = s.override_title; break;
                        }

                    if (new_s.type == 'line' || new_s.type == 'area') {
                        if (s.marker_enabled)
                            new_s.marker = { enabled: true, shape: s.marker_shape || 'circle', size: parseInt(s.marker_size || '5') };
                        else {
                            new_s.marker = { enabled: false };
                            this.show_series_stroke = true;
                            this.disable_show_series_stroke = true;
                        }

                        if (s.label_enabled)
                            new_s.label = { enabled: true };

                        if (s.line_width && s.line_width != '0')
                            new_s.strokeWidth = parseInt(s.line_width);
                    }

                    if (s.color) {
                        switch (new_s.type){
                            case 'area':
                            case 'bar': new_s.fill = s.color; break;
                            case 'line': 
                            default: new_s.stroke = s.color; break;
                        }
                    }

                    series.push(new_s);
                }
                //console.log('updateChart series:');
                //console.log(JSON.stringify(series,null,3));

                this.chartSeries = series;

                const axes = [];
                for (let a of this.chartOptionsLocal.axes) {
                    const new_a = {
                        type: a.type,
                        position: a.position,
                        title: { text: a.title.text },
                        tick: a.tick,
                    };

                    if (a.keys)
                        new_a.keys = [ ...a.keys ];

                    if ('min' in a)
                        new_a.min = a.min;

                    if ('color' in a)
                        new_a.color = a.color;

                    axes.push(new_a);
                }
                for (let a of this.extraAxes) {
                    const new_a = {
                        type: a.type,
                        position: a.position,
                        title: a.title,
                        tick: a.tick,
                    };

                    if (a.keys)
                        new_a.keys = [ ...a.keys ];

                    if ('min' in a)
                        new_a.min = a.min;

                    if ('color' in a)
                        new_a.color = a.color;

                    axes.push(new_a);
                }

                // Go back over the series and handle any that have been moved to a different axes
                for (let s of this.all_series)
                    if (s.override_axis) {
                        let x_from = axes.find(a => a.keys && a.keys.some(k => k == s.yKey));
                        let x_goto = axes.find(a => a.keys && a.title.text == s.override_axis);

                        if (x_from && x_goto) {
                            const old_idx = x_from.keys.findIndex(a => a == s.yKey);
                            if (old_idx >= 0)
                                x_from.keys.splice(old_idx, 1);

                            x_goto.keys.push(s.yKey);
                        }
                    }


                //console.log('updateChart axes:');
                //console.log(JSON.stringify(axes, null, 3));

                this.chartAxes = axes;

                if (chart.type == 'bar')
                    chart.direction = ['vertical', 'horizontal'][this.general_direction];

                let legend = {
                    enabled: true,
                    position: this.legend_position,
                    suppress_metric_suffix: this.suppress_metric_suffix,
                    showSeriesStroke: this.show_series_stroke,
                };

                // Notify container of the customizations only used to save the report
                //this.updateserialization({
                //    series: this.chartOptionsLocal.series,
                //    axes: this.chartOptionsLocal.axes.concat(this.extraAxes),
                //    options: this.custom_chart_options,
                //    legend: legend,
                //    maxValues: this.maxValues,
                //    typeSettings: chart,
                //});

                if (refreshOnly)
                    this.notifyrefresh(series, axes, chart, this.custom_chart_options, legend);
                else
                    this.notifychange(series, axes, chart, this.custom_chart_options, legend, this.maxValues);
            },
            //generatePivotSeries() {
            //    if (!this.pivot_columns || this.chartOptionsLocal.series.length < 1) {
            //        console.log('pivot_series empty');
            //        this.pivot_series = [];
            //        return;
            //    }

            //    const chart = this.generalTypes[this.general_type];

            //    // Use the first normal series to detect the type, direction, xKey, etc.
            //    const s0 = this.chartOptionsLocal.series[0];
            //    const series = [];
            //    for (let i = 0; i < this.pivot_columns.length; i++) {
            //        const s = this.pivot_columns[i];

            //        // Preserve any customizations already done by reading the previous series value (if any)
            //        const old_s = this.pivot_series.find(a => a.yKey == s);
                    
            //        const new_s = {
            //            ...old_s,
            //            xKey: s0.xKey,
            //            yKey: s,
            //        };

            //        series.push(new_s);
            //    }

            //    console.log('pivot_series:');
            //    console.log(JSON.stringify(series, null, 3));

            //    this.pivot_series = series;
            //},
            setChart(item, idx) {
                const chart = this.customTypes[idx];
                const dirs = ['vertical', 'horizontal'];

                // If we have selected the same chart type as the general
                // type setting, then don't include an override_type, the
                // series type will remain sync'ed with the general type.
                if (idx == this.general_type) {
                    delete item.override_type;
                    delete item.override_stacked;
                }
                else {
                    // The override type will supercede the general type for
                    // this series. No changes to the general type will affect it.
                    item.override_type = chart.type;
                    item.override_stacked = chart.stacked;
                }
                console.log(`setChart(item:${item.type},idx:${idx})`);
                console.log(JSON.stringify(item,null,3));
                this.updateChart();
            },
            setChartType(item, type, stacked) {
                const chart = this.generalTypes[this.general_type];

                if (type == chart.type && stacked == chart.stacked) {
                    delete item.override_type;
                    delete item.override_stacked;
                }
                else
                {
                    item.override_type = type;
                    item.override_stacked = !!stacked;
                }

                console.log(`setChartType(item:${item.yKey},type:${type},stacked:${stacked})`);
                console.log(JSON.stringify(item, null, 3));
                this.updateChart();
            },
            getChart(item) {
                // Return the index in the button group that represents which
                // chart type is active for the current series (item). If item
                // has a override_type field, then we will return the index
                // based on that field. Otherwise, we will return the index
                // based on the regular type field, which is changed only
                // through changing the general_type button group.
                
                const chart = this.generalTypes[this.general_type];

                // Search for the matching index in the custom types array based on either
                // the overridden settings or the general settings. If not overridden, the
                // selection will stay synchronized with the general settings.
                const idx = this.customTypes.findIndex(a => {
                    let found = a.type == (item.override_type || chart.type);

                    if (['bar','area'].includes(item.override_type || chart.type)) {
                        if (item.override_stacked === true)
                            found = found && a.stacked;
                        else if (item.override_stacked === false)
                            found = found && !a.stacked;
                        else
                            found = found && a.stacked == chart.stacked;
                    }
                    else
                        found = found && !a.stacked;

                    return found;
                });

                //console.log(`getChart(item:${JSON.stringify(item)}); customTypes[${idx}]`);

                return idx;
            },
            setNorm(item, idx) {
                if (idx == this.general_percent)
                    delete item.override_norm;
                else
                    item.override_norm = `${idx}`; // '0' or '1' -- can't detect 0 easily, so store as strings

                this.updateChart();
            },
            getNorm(item) {
                if (item.override_norm)
                    return parseInt(item.override_norm);
                else
                    return item.normalizedTo ? 1 : 0;
            },
            setStackGroup(item, value) {
                if (value == item.stackGroup)
                    delete item.override_group;
                else
                    item.override_group = value;

                this.updateChart();
            },
            revert(item) {
                delete item.override_type;
                delete item.override_stacked;

                this.updateChart();
            },

            getImage(id){
                console.log(`getImage(id: ${id})`);

                const item = this.chartTypeList.find(a => a.id === id);
                
                if (item)
                    return item.fn;

                return '';
            },
            getTitle(id) {
                const item = this.chartTypeList.find(a => a.id === id);

                if (item)
                    return item.name;

                return '';
            },
            getIcon(item) {
                const chart = this.generalTypes[this.general_type];

                if (!item.override_type)
                    return 'mdi-checkbox-blank-outline'; // checkbox-blank-outline // mdi-chevron-down-box-outline

                switch (item.override_type || chart.type) {
                    case 'bar':
                        if (item.override_stacked || chart.stacked)
                            return 'mdi-chart-bar-stacked';
                        else
                            return 'mdi-chart-bar';

                    case 'line':
                        return 'mdi-chart-line';

                    case 'area':
                        if (item.override_stacked || chart.stacked)
                            return 'mdi-chart-areaspline';
                        else
                            return 'mdi-chart-areaspline-variant';

                    default:
                        return 'mdi-chart-box-outline';
                }
            },

            getItemAxis(item) {
                if (item.override_axis)
                    return item.override_axis;

                let x = this.chartOptionsLocal.axes.find(a => a.keys && a.keys.some(k => k == item.yKey));
                if (!x)
                    x = this.extraAxes.find(a => a.keys.some(k => k == item.yKey));

                if (x)
                    return x.title.text;

                return '';
            },
            addItemAxis(item) {
                let old_axis = this.chartOptionsLocal.axes.find(a => a.keys && a.keys.some(k => k == item.yKey));
                if (old_axis) {
                //    const old_idx = old_axis.keys.findIndex(a => a == item.yKey);
                //    if (old_idx >= 0)
                //        old_axis.keys.splice(old_idx, 1);
                }
                else {
                    old_axis = this.extraAxis.find(a => a.keys.some(k => k == item.yKey));
                    //if (old_axis) {
                    //    const old_idx = old_axis.keys.findIndex(a => a == item.yKey);
                    //    if (old_idx >= 0)
                    //        old_axis.keys.splice(old_idx, 1);
                    //}
                }

                if (!old_axis)
                    return;

                let position = old_axis.position;
                if (position == 'left') position = 'right'; else if (position == 'right') position = 'left';
                if (position == 'top') position = 'bottom'; else if (position == 'bottom') position = 'top';

                this.extraAxes.push({
                    type: old_axis.type,
                    position: position,
                    title: { text: `${old_axis.title.text}_2` },
                    label: { ...old_axis.label },
                    tick: {},
                    keys: [ item.yKey ],
                    min: old_axis.min,
                });

                item.override_axis = `${old_axis.title.text}_2`;

                this.updateChart();
            },
            setItemAxis(item, value) {
                // Rather than update the axes keys list to truly move the
                // series to another axes, we are going to just save a field
                // called 'override_axis' with the name of the target axes.
                // If the value that we are setting it to matches the pre-existing
                // axes name, then remove the override flag, it is just being
                // reverted.

                let x_from = this.chartOptionsLocal.axes.find(a => a.keys && a.keys.some(k => k == item.yKey));
                if (!x_from) x_from = this.extraAxes.find(a => a.keys.some(k => k == item.yKey));
                if (x_from) {
                    if (x_from.title.text == value)
                        delete item.override_axis;
                    else
                        item.override_axis = value;
                }

                //let x_from = this.chartOptionsLocal.axes.find(a => a.keys && a.keys.some(k => k == item.yKey));
                //let x_goto = this.chartOptionsLocal.axes.find(a => a.keys && a.title.text == value);

                //if (!x_from) x_from = this.extraAxes.find(a => a.keys.some(k => k == item.yKey));
                //if (!x_goto) x_goto = this.extraAxes.find(a => a.title.text == value);



                //if (x_from) {
                //    const old_idx = x_from.keys.findIndex(a => a == item.yKey);
                //    if (old_idx >= 0)
                //        x_from.keys.splice(old_idx, 1);
                //}

                //if (x_goto)
                //    x_goto.keys.push(item.yKey);

                this.updateChart();
            },
            removeExtraAxis(index) {
                this.extraAxes.splice(index, 1);
                this.updateChart();
            },
            getAxisSeries(keys) {
                return keys.map(a => this.legendFormatter({ value: a })); // .join(', ');
            },

            getTickInterval(item) {
                if (item.tick && item.tick.interval)
                    return item.tick.interval;
                else
                    return 0;
            },
            setTickInterval(item, v) {
                if (!item.tick)
                    item.tick = { };

                if (!v)
                    delete item.tick.interval;
                else
                    item.tick.interval = parseFloat(v);

                this.updateChart(true);
            },
        }
    }
</script>

<style scoped>
    .columns-three {
        column-count: 3;
    }

    .verydense {
        max-height: 24px;
        min-height: 24px;
    }

    .mediumdense {
        max-height: 28px;
        min-height: 28px;
    }

    p {
        font-size: 2em;
        text-align: center;
    }

    .metric_normal {

    }
    .metric_inuse {
        color: cornflowerblue;
    }
    .metric_usedup {
        color: silver;
    }
    
    .chart-tabs.v-tabs >>> .v-tabs-bar {
        background-color: #fafafa !important;
    }
    .consistentText {
        color: var(--v-black-base) !important; 
        opacity: 0.8 !important; 
        font-size: 14px !important; 
        text-rendering: optimizelegibility !important; 
        letter-spacing:0.1 !important;
    }
</style>