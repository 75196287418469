import EventBus from '@/Application/event-bus';
import $q from '@/Services/promiseHelper';
import care from '@/Services/careService';
import token from '@/Services/token';
import api from '@/Services/api';

export default {    
    handleRefreshAsyncBound: null,

    created() {
        this.handleRefreshAsyncBound = this.handleRefreshAsync.bind(this);

        EventBus.$on('RefreshAccessTokenAsync', this.handleRefreshAsyncBound);
    },

    destroyed() {
        EventBus.$off('RefreshAccessTokenAsync', this.handleRefreshAsyncBound);
    },

    async handleRefreshAsync() {
        try {
            let response = await this.refreshAccessToken(1);

            if(response.data.Result.success)
                token.UpdateToken(response.data.Result);
        }
        catch(e) {
            care.logError(e, true);
        }
    },

    refreshWithExtraToken(removeTypes, addTypes) {
        let promise = $q.defer();

        // get 2 tokens, return one back to caller
        this.refreshAccessToken(2, removeTypes, addTypes).then(function (response){
            if (response.data.Result.success){
                // update the local token with the first token in the response
                token.UpdateToken(response.data.Result);

                // return the second token for use in opening a child window with it's own accessToken
                promise.resolve(response.data.Result.tokens[1]);
            }
            else {
                promise.reject();
            }
        },
        function(error) {
            promise.reject(error);
        });

        return promise.promise;
    },

    refreshAccessToken(tokenCount, securityContextsToRemove, securityContextsToAdd){
            care.logInfo("Refreshing AccessToken");

            let tokenRequests = [];

            for (let x = 0; x < tokenCount; x++){
                let tokenRequest = {};

                // the first token will not remove any types. Additional tokens will remove the specified security context types
                if (x > 0){
                    if (securityContextsToRemove)
                        tokenRequest.removeTypes = securityContextsToRemove;

                    if (securityContextsToAdd)
                        tokenRequest.addTypes = [ securityContextsToAdd ];
                }

                tokenRequests.push(tokenRequest);
            }

            // return the promise from the api
            return api.apiRequest({
                method: 'POST',
                url: '/Apps/RefreshTokenWithOptions',
                data: {
                    access_token: token.AccessToken(),
                    refresh_token: token.RefreshToken(),
                    tokens: tokenRequests
                },
                addAccessTokenInHeader: false, // do not add access token to header when refreshing (it maybe expired and would be verified & rejected before processing the RefreshToken request)
                doNotQueue: true // because the refresh_token is passed in the "data" element, if it is expired, queueing the request and running later would just refresh with an expired token and cause constant reauthentication. 
            });
        }
}