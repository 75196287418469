import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';

Vue.component('sform-table-layout', {
    data: () => ({
    }),
    props: {
        formtype: null,
        colspan: false,
        name: '',
        root: null,
        form: null,
        element: null,
        cmodel: null,
        modelkey: null,
        fieldset_index: null,
        layouttype: null,
        condensed: false,
        depth: 0,
    },
    created() {
    },
    computed: {
    },
    methods: {
        getLabel(element) {
            if (element.title)
                return element.title;
            else if (element.key) {
                let title = element.key[element.key.length - 1];
                if (title == '[]' && element.key.length > 1)
                    title = element.key[element.key.length - 2];
                return title;
            }
            else
                return null;
        }
    },
    render() {
        //if (!this.Condition)
        //    return null;

        const Tag = this.formtype;

        const item = (
            <Tag
                name={this.name}
                root={this.root}
                element={this.element}
                form={this.form}
                cmodel={this.cmodel}
                modelkey={this.modelkey}
                depth={this.depth}
                fieldset_index={this.fieldset_index}
                layouttype={this.layouttype}
                appearance={{ dense: true, hideDetails: true, outlined: true, singleLine: true }}
                readonly={this.readonly}>
            </Tag>
        );

        if (this.colspan) {
            return (
                <tr class="pa-0 ma-0">
                    <td colspan="2" class={{ "property-grid": this.condensed }}>
                        {item}
                    </td>
                </tr>
            );
        }
        else {
            let desc;
            if (this.element.description)
                desc = <v-icon small v-tooltip={this.element.description}>mdi mdi-information</v-icon>;

            const label = <span style={{ whiteSpace: "nowrap" }}>{this.getLabel(this.element)} {desc}</span>;

            const style = {
                width: "1px"
            };
            const classes = {};
            if (this.formtype == 'sform-array') {
                style.verticalAlign = 'top';
                classes["pt-2"] = true;
            }

            return (
                <tr class="pa-0 ma-0">
                    <td style={style} class={classes}>
                        {label}
                    </td>
                    <td class="pa-0 ma-0" class={{ "property-grid": this.condensed }}>
                        {item}
                    </td>
                </tr>
            );
        }
    }
});