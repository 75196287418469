import Vue from 'vue';

Vue.component('resize-observer', {
    methods: {
        notify() {
            this.$emit('notify')
        },
        addResizeHandlers() {
            this._resizeObject.contentDocument.defaultView.addEventListener('resize', this.notify)
            if (this._w !== this.$el.offsetWidth || this._h !== this.$el.offsetHeight) {
                this.notify()
            }
        },
        removeResizeHandlers() {
            if (this._resizeObject && this._resizeObject.onload) {
                if (this._resizeObject.contentDocument) {
                    this._resizeObject.contentDocument.defaultView.removeEventListener('resize', this.notify)
                }
                delete this._resizeObject.onload
            }
        },
    },
    mounted() {
        this.$nextTick(() => {
            this._w = this.$el.offsetWidth
            this._h = this.$el.offsetHeight
        })
        const object = document.createElement('object')
        this._resizeObject = object
        object.setAttribute('style', 'display: block; position: absolute; top: 0; left: 0; height: 100%; width: 100%; overflow: hidden; pointer-events: none; z-index: -1;')
        object.setAttribute('aria-hidden', 'true')
        object.onload = this.addResizeHandlers
        object.type = 'text/html'
        object.data = 'about:blank'
        this.$el.appendChild(object)
    },
    beforeDestroy() {
        this.removeResizeHandlers()
    },
    render(e) {
        return <div class="resize-observer" tabindex="-1"></div>;
    }
});