import Vue from 'vue';
import BaseComponent from './baseFormMixin.jsx';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

import { appSettings } from '@/Shared/appSettings.js';
import api from '@/Services/api.jsx';
import token from '@/Services/token.jsx';

Vue.component('sform-mediaselector', {
    mixins: [BaseComponent],
    data: () => ({
        soundPlaying: false,
        deviceType: 'all',
        deviceTypes: {
            'Microphone': 'audioinput',
            'Speaker': 'audiooutput',
            'Camera': 'videoinput',
        },
        context: null,
        micStream: null,
        micLevel: 0,

        devices: null,
        deviceAccessBlocked: false,
        selectedDevice: null,

        soundurlexpn: null,
    }),
    props: {
    },
    async created() {
        this.deviceType = this.element.formatData.DeviceType || 'all';
        const sourceInfos = await navigator.mediaDevices.enumerateDevices();
        const devices = [];
        for (let i = 0; i < sourceInfos.length; i++) {
            const sourceInfo = sourceInfos[i];

            // If it's the kind of device we are looking for, then add it.
            if (sourceInfo.kind != this.deviceTypes[this.deviceType] && this.deviceType != 'all') {
                continue;
            }

            const device = {
                id: sourceInfo.deviceId,
                type: sourceInfo.kind,
            };

            // Determine the name of the device
            if (sourceInfo.kind === 'audioinput') {
                device.name = sourceInfo.label || 'microphone ' + (this.devices.length + 1);
            }
            else if (sourceInfo.kind === 'audiooutput') {
                device.name = sourceInfo.label || 'speakers ' + (this.devices.length + 1);
            }
            else if (sourceInfo.kind === 'videoinput') {
                device.name = sourceInfo.label || 'camera ' + (this.devices.length + 1);
            }
            else {
                device.name = sourceInfo.label;
            }

            if (this.itemvalue == device.id)
                this.selectedDevice = device;

            devices.push(device);
        }
        this.devices = devices;

        if (this.element.formatData.SoundURL)
            this.soundurlexpn = utils.compile(this, this.element.formatData.SoundURL);

        if (this.deviceType == 'Microphone') {
            if (this.selectedDevice)
                this.microphoneChanged(this.selectedDevice.id);

            this.$watchitemvalue = this.$watch('itemvalue', function (newvalue) {
                this.microphoneChanged(newvalue);
            });
        }
    },
    destroyed() {
        utils.debug(`formMediaSelector ${this.name} is destroyed`);

        if (this.$watchitemvalue)
            this.$watchitemvalue();

        this.closeMicAnalyser();
    },
    mounted() {
        this.loadComplete();
    },
    computed: {
        soundurl: function () {
            return this.soundurlexpn ? utils.evaluate(this.soundurlexpn, this) : '';
        },
        signalIcon: function () {
            switch (this.micLevel) {
                case 0: return 'mdi-signal-cellular-outline';
                case 1: return 'mdi-signal-cellular-1';
                case 2: return 'mdi-signal-cellular-2';
                case 3: return 'mdi-signal-cellular-3';
                default: return 'mdi-signal-off';
            }
        },
        playIcon: function () {
            if (this.soundPlaying)
                return 'mdi-stop';
            else
                return 'mdi-play';
        },
    },
    methods: {
        async microphoneChanged(newid) {
            utils.debug(`microphoneChanged to ${newid}`);

            const constraints = {
                audio: { deviceId: newid },
                video: false,
            };

            try {
                const stream = await navigator.mediaDevices.getUserMedia(constraints);
                this.closeMicAnalyser();
                this.setupMicAnalyser(stream);
            }
            catch (e) {
                if (['PermissionDeniedError', 'PermissionDismissedError'].indexOf(e.name) != -1) {
                    this.deviceAccessBlocked = true;
                }
            }
        },

        changeDevice(deviceid) {
            this.selectedDevice = this.devices.find(d => d.id == deviceid); // device;
            this.sync(deviceid);
        },
        closeMicAnalyser() {
            const c = this;
            if (c.context) {
                c.micStream.getAudioTracks()[0].stop();;
                c.javascriptNode.disconnect(c.context.destination);
                c.analyser.disconnect(c.javascriptNode);
                c.microphone.disconnect(c.analyser);
                c.context.close();

                c.context = undefined;
                c.microphone = undefined;
                c.analyser = undefined;
                c.javascriptNode = undefined;
                c.micStream = undefined;
            }
        },
        setupMicAnalyser(stream) {
            const c = this;
            c.context = new AudioContext();
            c.microphone = c.context.createMediaStreamSource(stream);
            c.analyser = c.context.createAnalyser();
            c.javascriptNode = c.context.createScriptProcessor(2048, 1, 1);
            c.micStream = stream;


            c.analyser.smoothingTimeConstant = 0.3;
            c.analyser.fftSize = 1024;

            c.microphone.connect(c.analyser);
            c.analyser.connect(c.javascriptNode);
            c.javascriptNode.connect(c.context.destination);

            c.javascriptNode.onaudioprocess = function () {
                if (c.analyser) // sometimes is blank because closeMicAnalyser is called while some async audio is being processed after the var is cleared
                {
                    // get the average, bincount is fftsize / 2
                    const array = new Uint8Array(c.analyser.frequencyBinCount);
                    c.analyser.getByteFrequencyData(array);
                    const average = c.getAverageVolume(array); // here's the volume

                    if (average > 0 && average < 20) {
                        c.micLevel = 1;
                    }
                    else if (average >= 20 && average < 40) {
                        c.micLevel = 2;
                    }
                    else if (average >= 40) {
                        c.micLevel = 3;
                    }
                    else {
                        c.micLevel = 0;
                    }
                }
            }
        },
        getAverageVolume(array) {
            let values = 0;
            let average;

            var length = array.length;

            // get all the frequency amplitudes
            for (var i = 0; i < length; i++) {
                values += array[i];
            }

            average = values / length;
            return average;
        },

        PlaySound() {
            if (!this.selectedDevice) {
                utils.warn('Cannot play, no speaker device is selected');
                return;
            }

            if (this.soundPlaying) {
                this.Stop();
                return;
            }

            let standardizedUrl = api.standardizeUrl(this.soundurl);

            const this_ref = this;

            fetch(standardizedUrl, {
                method: "GET",
                mode: "cors",
                credentials: "include",
                headers: {
                    "access_token": token.AccessToken()
                }
            })
                .then((response) => response.blob())
                .then((audioBlob) => {
                    this.blobUrl = URL.createObjectURL(audioBlob);
                    this.audio = new Audio();
                    this.audio.setSinkId(this.selectedDevice.id);
                    this.audio.crossOrigin = "use-credentials";
                    this.audio.src = this.blobUrl;
                    this.audio.addEventListener("canplaythrough", event => {
                        this_ref.soundPlaying = true;
                    });
                    this.audio.addEventListener("ended", function () {
                        this_ref.soundPlaying = false;
                    });
                    this.audio.play();
                });
        },
        Stop() {
            if (this.audio) {
                this.audio.pause();
                delete this.audio;
                URL.revokeObjectURL(this.blobUrl);
            }
            this.soundPlaying = false;
        },
    },
    render() {
        if (!this.devices)
            return null;

        let scopedSlots = {
            message: ({ message }) => {
                return <translation-container context={this} value={message}></translation-container>
            }
        }

        let slots = [
            <translation-container slot="label" context={this} value={this.labelText}></translation-container>,
        ];

        switch (this.element.formatData.DeviceType) {
            case 'Microphone':
                slots.push(
                    <v-icon slot="append-outer">
                        {this.signalIcon}
                    </v-icon>
                );
                break;

            case 'Speaker':
                if (!this.element.formatData.HidePlayButton)
                    slots.push(
                        <v-btn elevation={0} disabled={!this.selectedDevice || !this.itemvalue} small icon slot="append-outer" on-click={() => this.PlaySound()}>
                            <v-icon>
                                {this.playIcon}
                            </v-icon>
                        </v-btn>
                    );
                break;
        }

        utils.debug(`formMediaSelector rendering ${this.labelText} ${this.name} with ${this.itemvalue}`);

        let input = (
            <v-select
                class="caption pa-0 ma-0" outlined dense hide-details
                style={{ width: "100%" }}
                menu-props={{ offsetY: true, auto: true }}
                value={this.itemvalue}
                items={this.devices}
                item-text="name"
                item-value="id"
                return-object={false}
                on-change={(value) => this.changeDevice(value)}
                on-blur={this.onBlur}
                on-focus={this.onFocus}
                scopedSlots={scopedSlots}
                hint={this.hintText}
                persistent-hint={appSettings.DebugTranslationPrefixSetting}
            >
                {slots}
            </v-select>
        );

        if (this.appearance)
            input.componentOptions.propsData = { ...input.componentOptions.propsData, ...this.appearance };

        if (this.directives) {
            if (input.data.directives)
                input.data.directives = [...input.data.directives, ...this.directives];
            else
                input.data.directives = this.directives;
        }

        return input;
    }
});