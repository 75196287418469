import Vue from 'vue';
import utils from '../../../../Shared/utils.jsx';
import methods from '../../../../Shared/methods';

Vue.component('replace-content-action-dsgn', {
    data: function () {
        return {
        }
    },
    computed: {
        Root: function () {
            return this.root._self;
        },
        Vars: function () {
            if (!this.controlData.Vars)
                Vue.set(this.controlData, 'Vars', {});

            return this.controlData.Vars;
        },
        DesignModel: function () {
            return this.designmodel;
        },
        selected$: function () {
            return this.Root.SelectedNode && this.Root.SelectedModel == this.designmodel;
        },
    },
    methods: {
        ...methods,
        handleClick(e) {
            this.Root.SelectNode(this);
            e.cancelBubble = true;
            e.stopPropagation();
        },
    },
    props: {
        name: '',
        root: null,
        designmodel: null,
        parentType: '',
        controlData: {}
    },
    render(h) {
        const container = (
            <stack-vertical-dsgn root={this.root} type={this.type} parentType="VerticalStack" designmodel={this.controlData.ControlData} controlData={this.controlData.ControlData}>
            </stack-vertical-dsgn>
        );

        return (
            <div
                class={{ 'designer-container': true, selected: this.selected$ }}
                style={{ margin: "5px", padding: "5px" }}
                on-click={(e) => this.handleClick(e)}>
                <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
                {container}
            </div>
        );
    }
});