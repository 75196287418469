import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import utils from '../../../Shared/utils.jsx';

Vue.component('dynamic-tree-dsgn', {
    mixins: [BaseComponent],
    data: function () {
        return {
            model: [],
        }
    },
    async created() {
        if (this.controlData.SourceType === 'Raw' && this.controlData.SourceRaw) {
            this.model = this.controlData.SourceRaw || this.dummyData;
        }
        else if (this.controlData.SourceType === 'Url' && this.controlData.SourceUrl) {
            if (this.controlData.SourceUrl.includes('{'))
                this.model = this.dummyData;
            else
                this.model = (await utils.api.get(this.controlData.SourceUrl)) || this.dummyData;
        }
    },
    computed: {
        dummyData: function () {
            const dummydata = [];

            let dummykey = 'id';
            let dummytext = 'name';
            let dummychildren = 'children';

            if (this.controlData.ItemKey) dummykey = this.controlData.ItemKey;
            if (this.controlData.ItemText) dummytext = this.controlData.ItemText;
            if (this.controlData.ItemChildren) dummychildren = this.controlData.ItemChildren;

            dummydata.push({
                [dummykey]: 'Item0',
                [dummytext]: 'Value0',
                [dummychildren]: [{
                    [dummykey]: 'ChildItem0',
                    [dummytext]: 'ChildValue0',
                }],

                [dummykey]: 'Item1',
                [dummytext]: 'Value1',
                [dummychildren]: [{
                    [dummykey]: 'ChildItem1',
                    [dummytext]: 'ChildValue1',
                }],
            });
            return dummydata;
        },
    },
    methods: {
    },
    render(h) {
        try {
            const style = {
                ...utils.getStyleHints(this.controlData.StyleHints),
                ...utils.getSize(this.controlData.SizeOptions, this.parentType, this.$parent),
                minWidth: "25px",
                overflow: "auto",
            };

            const scopedSlots = {};

            const props = {
                activatable: true,
                hoverable: true,
                transition: true,
                openOnClick: true,
                dense: true,
                class: 'ma-1',
                items: this.model || this.dummyData,
                active: this.selected_values,
            };

            if (this.controlData.ItemText) props.itemText = this.controlData.ItemText;
            if (this.controlData.ItemKey) props.itemKey = this.controlData.ItemKey;
            if (this.controlData.ItemDisabled) props.itemDisabled = this.controlData.ItemDisabled;
            if (this.controlData.ItemChildren) props.itemChildren = this.controlData.ItemChildren;

            // JSX cannot handle an event with a colon in the middle (update:active) so I have to declare this manually:
            const tv = h('v-treeview', {
                props: props,
                scopedSlots: scopedSlots,
            });

            return (
                <div
                    class={{ 'c-DynamicTreeDsgn': true, [`c-name-${this.name || 'unnamed'}`]: true, 'designer-container': true, selected: this.selected$ }}
                    on-click={(e) => this.handleClick(e)}
                    style={style}>
                    <div class={{ "designer-container-hovering": true, 'designer-container-selected-clickthrough': this.selected$ }}></div>
                    {tv}
                </div>
            );
        }
        catch (e) {
            utils.error('DynamicTreeDsgn Render failed', e);
            return <div>DynamicTreeDsgn Failed to Render {e}</div>;
        }
    }
});