import Vue from 'vue';
import BaseComponent from './baseFormMixin.jsx';
import utils from '../../../Shared/utils.jsx';

Vue.component('sform-section', {
    mixins: [BaseComponent],
    data: () => ({
    }),
    props: {
    },
    created() {
    },
    mounted() {
        this.loadComplete();
    },
    computed: {
    },
    methods: {
    },
    render(h) {
        if (!this.schema || !this.form)
            return null;

        return (
            <sform-fieldset
                layouttype={this.layouttype}
                root={this.root}
                form={this.form}
                cmodel={this.cmodel}
                noinput={this.noinput}
                schema={this.schema}
                scopeitems={this.scopeitems}
                controlscope={this.controlscope}
                depth={0}
                nopadding={true}
                columns={this.element?.ControlData?.columns}>
            </sform-fieldset>
        );
    }
});
