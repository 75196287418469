import Vue from 'vue';
import utils from '../../Shared/utils.jsx';
import BaseComponent from './BaseComponentMixin.jsx';
import careHelpfulFunctions from '../careHelpfulFunctions.jsx';

Vue.component('dropdown-list', {
    mixins: [BaseComponent],
    data: function () {
        return {
            sourceurlexpn: null,
            sourcerawexpn: null,
            selectedvalueexpn: null,
            labelexpn: null,

            selected_item: null,
            items: [],
            nameField: 'Name',
            valueField: 'Value',
        }
    },
    async created() {
        if (this.controlData.SourceType == 'URL' || (!this.controlData.SourceType && this.controlData.SourceURL))
            this.sourceurlexpn = utils.compile(this, this.controlData.SourceURL);

        if (this.controlData.SourceType == 'Raw')
            this.sourcerawexpn = utils.compileObject(this, this.controlData.Source);

        if (this.controlData.SelectedValue) {
            if (this.controlData.SelectedValue.startsWith('{#') || this.controlData.SelectedValue.startsWith('{%'))
                this.selectedvalueexpn = utils.compileObject(this, this.controlData.SelectedValue);
            else
                this.selectedvalueexpn = utils.compile(this, this.controlData.SelectedValue);
        }

        if (!this.controlData.FieldMappings.AutoSource) {
            let name = utils.compile(this, this.controlData.FieldMappings.Name);
            let value = utils.compile(this, this.controlData.FieldMappings.Value);

            this.nameField = (item) => {
                this.sourceData = item;
                return utils.evaluate(name, this);
            };
            this.valueField = (item) => {
                this.sourceData = item;
                return utils.evaluate(value, this);
            };
        }
        if (this.controlData.PublishField) {
            let vueSet = utils.helpers.convertSetValueToVueSet(this.controlData.PublishField, 'value');
            this.publishexpn = new Function('context', 'util', 'value', 'vue', `with (context) { ${vueSet} }`);
        }
    },
    //Mounted Replaced with preRenderComplete
    computed: {
        label: function () {
            if (this.controlData.Label)
                this.labelexpn = utils.compile(this, this.controlData.Label);

            if (this.labelexpn)
                return utils.evaluate(this.labelexpn, this);
            else
                return '';
        },

        styles: function () {
            return {
                ...utils.resolveStyleHints(this.styleHints, this),
            };
        },
        sourceurlvalue: function () {
            if (this.sourceurlexpn)
                return utils.evaluate(this.sourceurlexpn, this);
            else
                return '';
        },
        sourcerawvalue: function () {
            if (this.sourcerawexpn)
                return utils.evaluateObject(this.sourcerawexpn, this);
            else
                return null;
        },
        selectedvalue: function () {
            if (this.selectedvalueexpn) {
                if (this.controlData.SelectedValue.startsWith('{#') || this.controlData.SelectedValue.startsWith('{%'))
                    return utils.evaluateObject(this.selectedvalueexpn, this);
                else
                    return utils.evaluate(this.selectedvalueexpn, this);
            }
            else
                return null;
        },
        SelectedValue: function () {
            // This is the public accessor
            if (this.selected_item)
                return this.getValue(this.selected_item);
            else
                return null;
        }
    },
    methods: {
        async preRenderComplete() {
            await this.Refresh();
            this.finishRenderHandler(this);

            if (this.controlData.FireActionsInitially)
                await sync(this.selected_item);
        },
        async Refresh() {
            if (this.sourceurlvalue)
                try {
                    this.items = await utils.api.get(this.sourceurlvalue);
                }
                catch (e) {
                    utils.warn(`DropdownList ${this.name} failed to retrieve SourceURL: ${this.controlData.SourceURL}`, e);
                }

            if (this.sourcerawexpn)
                this.items = this.sourcerawvalue;

            if (this.controlData.FieldMappings.AutoSource && this.items && this.items.length > 0) {
                // Auto-detect name and value fields
                const i0 = this.items[0];
                if ('name' in i0) this.nameField = 'name';
                else if ('Name' in i0) this.nameField = 'Name';
                else if ('title' in i0) this.nameField = 'title';
                else if ('Title' in i0) this.nameField = 'Title';
                else {
                    const keys = Object.keys(i0);
                    const idx = keys.findIndex(k => typeof i0[k] === 'string');
                    this.nameField = idx >= 0 ? keys[idx] : 'name';
                }

                if ('value' in i0) this.valueField = 'value';
                else if ('Value' in i0) this.valueField = 'Value';
                else if ('id' in i0) this.valueField = 'id';
                else if ('Id' in i0) this.valueField = 'Id';
                else if ('ID' in i0) this.valueField = 'ID';
                else {
                    const keys = Object.keys(i0);
                    const idx = keys.findIndex(k => k != this.nameField);
                    this.valueField = idx >= 0 ? keys[1] : nameField;
                }
            }

            this.selected_item = this.items.find(item => this.getValue(item) === this.selectedvalue); // computed property
        },
        async sync(value) {
            this.selected_item = value;
            if (this.publishexpn)
                this.publishexpn(this, careHelpfulFunctions, this.getValue(value), Vue);

            //let item = value; // this.items.find(item => this.getValue(item) === this.getValue(value));

            let name = this.getName(value);
            let itemv = this.getValue(value);
            let index = this.items.findIndex(item => this.getValue(item) === itemv);

            let actionData = {
                Value: itemv, 
                SourceItem: {
                    Name: name,
                    Value: itemv,
                    SourceItem: {
                        Name: name,
                        Value: itemv,
                    },
                    SourceIndex: index
                },
                SourceIndex: index
            }

            await utils.executeAndCompileAllActions(this.controlData.Actions, actionData, this);

            if (typeof value === 'object')
                utils.log(`DropdownList changed to: ${JSON.stringify(value)} (object)`);
            else
                utils.log(`DropdownList changed to: ${value} (value)`);
        },
        getValue(item) {
            if (typeof this.valueField === 'function')
                return this.valueField(item);
            else if (item)
                return item[this.valueField];
            else
                return null;
        },
        getName(item) {
            if(typeof this.nameField === 'function')
                return this.nameField(item);
            else 
                return item[this.nameField];
        },

        getItemIndex(value) {
            return this.source.findIndex(item => this.getValue(item) === value);
        }
    },
    props: {
    },
    render(h) {
        if (!this.todisplay)
            return null;

        return (
            <div class="ma-1" style={this.sizeStyle} v-show={this.isvisible}>
                <v-select
                    class={{ 'c-DropdownList': true, [`c-name-${this.name || 'unnamed'}`]: true }}
                    style={this.styles}
                    outlined
                    dense
                    hide-details
                    menu-props={{ offsetY: true, auto: true }}
                    item-text={this.nameField}
                    item-value={this.valueField}
                    label={this.label}
                    value={this.selectedvalue}
                    items={this.items}
                    return-object={true}
                    on-change={this.sync}
                ></v-select>
            </div>
        );
    }
});