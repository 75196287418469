import Vue from "vue";
import utils from "../../Shared/utils.jsx";
import BaseComponent from "./BaseComponentMixin.jsx";
import { appSettings } from "../../Shared/appSettings.js";

Vue.component("youtube-video", {
    mixins: [BaseComponent],
    data: function() {
        return {
            sourceTypeEval: null,
            localizedVideoIdEval: null,
            urlEval: null,
            localizedVideoUrl: null
        };
    },
    computed: {
        sourceType() {
            try {
                if (this.sourceTypeEval === null && this.controlData.SourceType)
                    this.sourceTypeEval = utils.compile(this, this.controlData.SourceType);
                    
                if (this.sourceTypeEval) {
                    return utils.evaluate(this.sourceTypeEval, this);
                } 
                
                return "URL";
            } catch (e) {
                utils.warn("Source type could not evaluate expression: " + this.controlData.SourceType + "; " + e);
                return "URL";
            }
        },
        url() {
            try {
                if (this.localizedVideoUrl) {
                    return this.localizedVideoUrl; 
                }

                if (this.urlEval === null && this.controlData.URL) {
                    this.urlEval = utils.compile(this, this.controlData.URL);
                }

                if (this.urlEval) {
                    return utils.evaluate(this.urlEval, this);
                }

                return "";
            } catch (e) {
                utils.warn("URL could not evaluate expression: " + this.controlData.URL + "; " + e);
                return "";
            }
        },
        styles() {
            return {
                ...this.sizeStyle,
                ...utils.resolveStyleHints(this.styleHints, this)
            }
        }
    },
    //Created replaced with preRenderComplete
    methods: {
        async preRenderComplete() {
            if (this.controlData.SourceType === "Localized Video") {
                await this.getLocalizedVideo(this.controlData.LocalizedVideoID); 
            }

            this.finishRenderHandler(this);

        },
        async getLocalizedVideo(id) {
            const compiledId = utils.compile(this, id);
            const videoId = utils.evaluate(compiledId, this);
            const url = `Apps/Common/LocalizedVideo?id=${videoId}`;

            const res = await utils.api.get(url);
            const videoList = res.Videos;

            let localizedUrl;

            if (!videoList.length) {
                return;
            }
            else if (videoList.length === 1) {
                localizedUrl = videoList[0].Link;
            }
            else {
                const languageKey = appSettings.LanguageKey;

                localizedUrl = this.findVideoByLocale(videoList, languageKey);

                if (!localizedUrl) {
                    localizedUrl = this.findVideoByLocale(videoList, 'en')
                }
                
                if (!localizedUrl) {
                    localizedUrl = videoList[0].Link;
                }
            }

            this.localizedVideoUrl = localizedUrl;
        }, 
        findVideoByLocale(videoList, locale) {
            var video = videoList.find(item => item.Locale == locale);
            
            if (video) {
                return video.Link;
            }
            else 
            {
                return null;
            }
        }
    },
    props: {},
    render(h) {
        try {
            if (!this.todisplay) return null;

            return (
                <div style={this.styles} class={{ 'c-YouTubeVideo d-flex': true, [`c-name-${this.name || 'unnamed'}`]: true}}>

                    <iframe 
                        src={this.url}
                        v-show={this.isvisible}
                        frameborder="0"
                        allow="autoplay; encrypted-media"
                        allowfullscreen
                        class="flex-grow-1"
                        >
                    </iframe>
                </div>
            );
        } catch (e) {
            utils.error("YouTubeVideo Render failed", e);
            return <div>YouTubeVideo Failed to Render {e}</div>;
        }
    },
});
