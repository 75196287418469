import 'jointjs/dist/joint.core.css';

import { dia } from 'jointjs/src/core.mjs';
import * as standard from 'jointjs/src/shapes/standard.mjs';
import * as linkTools from 'jointjs/src/linkTools/index.mjs';
import * as elementTools from 'jointjs/src/elementTools/index.mjs';
import * as highlighters from 'jointjs/src/highlighters/index.mjs';

export default {
	install: function (Vue) {
		let joint = { dia };
		joint.shapes = { standard };
		joint.linkTools = linkTools;
		joint.elementTools = elementTools;
		joint.highlighters = highlighters;
		Object.defineProperty(Vue.prototype, '$joint', { value: joint });
	}
};