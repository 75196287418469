import Vue from 'vue';
import BaseComponent from './baseFormMixin.jsx';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';
import { Container, Draggable } from 'vue-smooth-dnd';

// DnD implemented via:
// https://kutlugsahin.github.io/vue-smooth-dnd

Vue.component('sform-array', {
    mixins: [BaseComponent],
    components: { Container, Draggable },
    data: () => ({
        formitem: null,
        chosenitems: null,
        availableitems: null,
    }),
    props: {
        layouttype: null,
    },
    created() {
        if (this.form && this.form.length > 0) {
            this.formitem = this.form[0];
        }
    },
    mounted() {
        this.loadComplete();
    },
    computed: {
        addenabled: function () {
            if (this.availableitems && typeof this.availableitems === 'object')
                return Object.keys(this.availableitems).every(k => this.availableitems[k]);
            else
                return true;
        },
        addLabel: function () {
            return this.element.add || 'Add Another';
        },
    },
    methods: {
        addNewItem(e) {
            if (!this.itemvalue)
                this.sync([]); // If the array itself is unassigned, initialize to an empty array

            if (this.itemvalue)
                this.itemvalue.push(utils.forms.mergeDefaultModel(null, this.element.schema.items, this));
        },
        reOrderItems(result) {
            // Ex: {"removedIndex":2,"addedIndex":0}
            const item = this.itemvalue[result.removedIndex];
            this.itemvalue.splice(result.removedIndex, 1);
            this.itemvalue.splice(result.addedIndex, 0, item);
        },
    },
    render() {
        if (!this.form || this.form.length < 1)
            return null;

        let element;
        let Tag;
        let formtype;
        if (this.form.length > 1) {
            // A manually defined array can have multiple items, wrap in a fieldset
            // discard
            element = {
                type: 'fieldset',
                items: this.form,
            };
            Tag = `sform-${element.type}`;
        }
        else {
            element = this.form[0];
            Tag = this.layouttype;
            formtype = `sform-${element.type}`;
        }

        const items = [];
        const actions = [];

        let modelkeyroot;

        if (element.key) {
            modelkeyroot = [...element.key];

            if (this.modelkey && this.modelkey.length > 0)
                for (let j = 0; j < this.modelkey.length && j < modelkeyroot.length; j++)
                    modelkeyroot[j] = this.modelkey[j];
        }

        if (this.itemvalue)
            for (let i = 0; i < this.itemvalue.length; i++) {
                // Take the index from the arra and replace the indexer expression []
                // within the key expression to include the literal index [{i}]
                let modelkey = modelkeyroot ? modelkeyroot.map(k => k == '[]' ? `[${i}]` : k) : [];
                let divide;
                if (i < this.itemvalue.length - 1)
                    divide = <v-divider></v-divider>;

                // name={element.title || (modelkey && modelkey.length > 0 ? modelkey[modelkey.length - 1] : '')}
                items.push(
                    <Draggable style="padding: 5px;" key={modelkey.join('/')} index={i}>
                        <div class="draggable-item" style="display: flex;">
                            <Tag
                                style="flex-grow: 1; max-width:93%"
                                name={this.getElementName(element)}
                                root={this.root}
                                element={element}
                                form={element.items}
                                cmodel={this.cmodel}
                                modelkey={modelkey}
                                depth={this.depth + 1}
                                array_index={i}
                                layouttype={this.layouttype}
                                formtype={formtype}
                                appearance={this.appearance}
                                nopadding={true}
                                type="FormField_ArrayItem"
                                notitle={true}
                                readonly={element.readonly || false}
                                schema={this.schema}
                                scopeitems={this.scopeitems}
                                controlscope={this.controlscope}
                            >
                            </Tag>
                            <span v-show={this.itemvalue.length > 1 && !this.element.nodrag} class="column-drag-handle" style="padding:0 10px; margin-top: 5px; cursor: grab;">&#x2630;</span>
                            <v-btn
                                elevation={0}
                                v-show={!this.noinput && !this.element.readonly}
                                v-tooltip="Remove" text fab x-small
                                on-click={(e) => this.itemvalue.splice(i, 1)}>

                                <v-icon small>mdi mdi-close</v-icon>
                            </v-btn>
                        </div>
                        {divide}
                    </Draggable>
                );
            }

        if (!this.noinput && !this.element.readonly)
            actions.push(
                <v-btn small elevation={0} color="primary" disabled={!this.addenabled} on-click={(e) => this.addNewItem(e)}>
                    {this.addLabel} <v-icon small>mdi mdi-plus</v-icon>
                </v-btn>
            );

        let title;
        if (!this.element.notitle)
            title = (
                <v-card-title>
                    {this.Title}
                </v-card-title>
            );

        return (
            <v-card elevation={0} outlined class="mb-3 card-array" style="background-color: #ebebeb">
                {title}

                <Container v-show={this.itemvalue && this.itemvalue.length > 0} on-drop={(res) => this.reOrderItems(res)} drag-handle-selector=".column-drag-handle">
                    {items}
                </Container>

                <v-card-actions>
                    {actions}
                </v-card-actions>
            </v-card>
        );

        //return (
        //    <tr class="pa-0 ma-0">
        //        <td style={{ width: "1px", verticalAlign: "top" }}>
        //            <span style={{ whiteSpace: "nowrap" }}>{title} {desc}</span>
        //        </td>
        //        <td class="pa-0 ma-0" class={{ "property-grid": this.condensed }}>
        //            <v-card>
        //                <v-card-text v-show={items.length > 0}>

        //                    <Container on-drop={(res) => this.reOrderItems(res)} drag-handle-selector=".column-drag-handle">
        //                        {items}
        //                    </Container>

        //                </v-card-text>
        //                <v-card-actions>
        //                    {actions}
        //                </v-card-actions>
        //            </v-card>
        //        </td>
        //    </tr>
        //);
    }
});