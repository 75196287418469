import Vue from 'vue';
import utils from '../../Shared/utils.jsx';
import BaseComponent from './BaseComponentMixin.jsx';

Vue.component('basic-tab', {
    mixins: [BaseComponent],
    data: function () {
        return {
            selected_tab: null,
        }
    },
    props: {},
    created() {
        for (let tabi = 0; tabi < this.controlData.TabControls.length; tabi++) {
            const tab = this.controlData.TabControls[tabi];
            if (tab.DisplayExpression)
                tab.todisplayexpn = utils.compileExpression(this, tab.DisplayExpression);
            if (tab.Title)
                tab.titleexpr = utils.compile(this, tab.Title);
        }
    },
    //Mounted Replaced with preRenderComplete
    computed: {
        styles: function () {
            return {
                display: "flex",
                flexDirection: "column",
                flexGrow: "1",
                ...this.sizeStyle,
                ...utils.resolveStyleHints(this.styleHints, this),
                overflowX: "clip",
                whiteSpace: "nowrap",
            };
        },
        bodystyles: function () {
            return {
                display: "flex",
                flexDirection: "column",
                flexGrow: "1",
                overflow: "auto",
            };
        },
        classes() {
            return {
                'c-BasicTab': true, 
                [`c-name-${this.Name}`]: true
            }
        },

        Tab: function () {
            // Public property
            const tab = this.controlData.TabControls[this.selected_tab];
            if (tab)
                return {
                    Title: tab.titleexpr ? utils.evaluate(tab.titleexpr, this) : 'Untitled',
                    Controls: tab.Controls,
                };
            else
                return {};
        },
    },
    methods: {
        preRenderComplete() {
            this.finishRenderHandler(this);
        },
    },
    render(h) {
        if (!this.todisplay)
            return null;

        try {
            const tabs = [];
            const tab_body = [];
    
            for (let tabi = 0; tabi < this.controlData.TabControls.length; tabi++) {
                const tab = this.controlData.TabControls[tabi];
                if (tab.todisplayexpn)
                    if (!utils.evaluate(tab.todisplayexpn, this))
                        continue;

                const title = <translation-container context={this} value={tab.Title ? tab.Title : 'Untitled'}></translation-container>;

                tabs.push(
                    <v-tab key={tabi} class="text-capitalize">
                        {title}
                    </v-tab>
                );
    
                const items = [];
    
                for (let i = 0; i < tab.Controls.length; i++) {
                    const c = tab.Controls[i];
    
                    if (!(typeof c === 'object'))
                        continue;
    
                    let DynamicControl = utils.getDynamicComponent(h, c);
    
                    if (!DynamicControl)
                        DynamicControl = 'default-unknown';
    
                    if (!c.$objectId) c.$objectId = utils.generateUUID();
    
                    let id = c.$objectId;
    
                    items.push(
                        <DynamicControl
                            key={id}
                            type={c.ControlType}
                            name={c.ControlData.Name ? c.ControlData.Name : ''}
                            root={this.root}
                            parentType="BasicTab"
                            controlData={c.ControlData}
                            controlURL={c.ControlURL}
                            controlName={c.Name}
                            scopeitems={this.scopeitems}
                            controlscope={this.controlscope}
                            cacheControl={this.CacheControl}
                            controlEvents={c.Events}>
                        </DynamicControl>
                    );
                }
    
                tab_body.push(
                    <v-tab-item key={tabi}>
                        {items}
                    </v-tab-item>
                );
            }
        
            return (
                <v-card
                    elevation={0}
                    class={{ 'c-BasicTab': true, [`c-name-${this.name || 'unnamed'}`]: true }}
                    class={this.classes} v-show={this.isvisible} style={this.styles}>
                    <v-tabs
                        style={{flexGrow: 0}}
                        class="ma-1"
                        value={this.selected_tab}
                        on-change={(index) => this.selected_tab=index}>
                        {tabs}
                    </v-tabs>
    
                    <v-tabs-items value={this.selected_tab} style={this.bodystyles}>
                        {tab_body}
                    </v-tabs-items>
                </v-card>
            );
        }
        catch (e) {
            utils.error('BasicTab Render failed', e);
            return <div>BasicTab Failed to Render {e}</div>;
        }
    }
});