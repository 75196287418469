<template v-if="todisplay">

    <div v-show="isvisible" class="MultiSelectList c-caremultiselectlist"
         :style="styles">
        <div class="careDynamicControlContent MultiSelectList" >
            <v-list>
                <v-list-item-group :value="selectedIndexValues" multiple>
                    <v-list-item v-for="(item, i) in sourceItems"
                                 :key="i"
                                 @click="itemSelected(item, true, true)"
                                 :disabled ="!isAvailable(item)"
                                 :class="{isSelected: isItemSelected(item), disabled: !isAvailable(item)}">
                        <MultiSelectListItem v-bind:sourceData="item.data" v-bind:template="controlData.MultiSelectListItemTemplate" v-bind:index="i" v-bind:key="i">
                        </MultiSelectListItem>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </div>
    </div>


</template>


<script>
    import utils from '@/Shared/utils.jsx';
    import BaseComponent from './BaseComponentMixin.jsx';
    import careHelpfulFunctions from '../careHelpfulFunctions.jsx';
    import Vue from 'vue';
    import MultiSelectListItem from './MultiSelectListItem.vue';
    import _ from 'lodash';

    export default {
        name: "MultiSelectList",
        mixins: [BaseComponent],
        components: {
            MultiSelectListItem
        },
        data() {
            return {
                //sourceItems: [],
                selectedValues: [],
                selectedTypes: [],
                numberOfAllowedTypes: null,
                thetypekey: null,
            };
            
        },
        created() {
            if (this.controlData.SourceType === 'Raw' && this.controlData.Source) {
                this.sourceraw = utils.compileObject(this, this.controlData.Source);
            }
            else if (this.controlData.SourceType === 'RawInterpolated' && this.controlData.Source) {
                this.sourceraw = utils.compileObject(this, this.controlData.Source);
            }
            else if (this.controlData.SourceType === 'URL' && this.controlData.SourceURL) {
                this.sourceurl = utils.compile(this, this.controlData.SourceURL);
            }



        },
        computed: {
            styles: function () {
                return {
                    ...utils.resolveStyleHints(this.styleHints, this),
                    ...this.sizeStyle,
                    display: 'flex',
                    flexDirection: 'column'
                }
            },
          
            sourcerawvalue: function () {
                if (this.controlData.SourceType === 'Raw')
                    return this.sourceraw;
                else
                    return this.sourceraw ? utils.evaluateObject(this.sourceraw, this) : null;
            },
            sourceurlvalue: function () {
                return this.sourceurl ? utils.evaluate(this.sourceurl, this) : '';
            },
            model: function () {
                switch (this.controlData.SourceType) {
                    case 'Raw':
                        return this.sourceraw ? utils.evaluateObject(this.sourceraw, this) : null;


                    case 'RawInterpolated':
                        return this.sourceraw ? utils.evaluateObject(this.sourceraw, this) : null;

                    case 'URL':
                        return this.sourcemodel;

                    default:
                        return null;
                }
            },
            sourceItems: function () {

                var mappedItems = [];
                // loop over items in the source

                this.model.forEach(function (item, index, array) {
                    mappedItems.push({
                        data: item,
                        index: index
                    });
                });

                return mappedItems;
            },
            SelectedItems: function() {
                return this.selectedValues;
            },
            SelectedTypes: function () {
                return this.selectedTypes;
            },
            ClearSelectedItems: function () {
                return this.clearSelectedItems();
            },
            selectedIndexValues: function () {
                // any item in the selectedValues, grab index from the sourceItems
                return this.selectedValues.map(x => this.sourceItems.findIndex((y) => _.isEqual(y.data, x.data)));
            },
            numberOfAllowedTypesComputed: function () {
                try {

                    if (this.numberOfAllowedTypes === null && this.controlData.AllowedTypes !== null)
                        this.numberOfAllowedTypes = utils.compile(this, this.controlData.AllowedTypes);

                    let numberOfAllowedTypes = utils.evaluate(this.numberOfAllowedTypes, this) || 2;
                    return numberOfAllowedTypes;
                } catch(e) {
                    utils.warn("Allowed Types could not evaluate expression: " + this.controlData.AllowedTypes + "; " + e);
                    return "";
                }
            },
            thetypekeyComputed: function () {
                
                try {
                    if (this.thetypekey === null && this.controlData.TypeKey !== null)
                        this.thetypekey = utils.compile(this, this.controlData.TypeKey);

                    let thetypekey = utils.evaluate(this.thetypekey, this);
                    return thetypekey;
                } catch (e) {
                    utils.warn("Type Key could not evaluate expression: " + this.controlData.TypeKey + "; " + e);
                    return "";
                }
            },
        },
       
        methods: {

            mapItems(sourceItems)
            {
                var mappedItems = [];
                
                sourceItems.forEach((item, index, array) => {
                    mappedItems.push({
                        data: item,
                        index: index
                    });
                });

                this.sourceItems = mappedItems;

               
            },
            setSelectedItem(itemKeys, propertyToMatch) {
                itemKeys.forEach((itemKey) => {
                    this.sourceItems.forEach((sourceItem) => {
                        if (_.isEqual(sourceItem.data[propertyToMatch], itemKey)) {
                            this.itemSelected(sourceItem, false, false);
                        }
                    });
                });
            },
            clearSelectedItems() {
                this.selectedValues = [];
                this.selectedTypes = [];

            },
            async itemSelected(item, runAction, deselect) {
                if (!this.isItemSelected(item)) {
                    // check to see if item is allwed by type
                    if (this.typeSelected(item)) {
                        this.selectedValues.push(item);
                    }

                }
                else {
                    // remove the selection
                    if (deselect) {
                        this.selectedValues = this.selectedValues.filter(function (existingItem) {
                            return existingItem !== item;
                        });

                        // check to see if we should remove metrictype
                        this.removeType(item);
                    }
                }
                // if this is called from schema-form, this lets the model finish updating before triggering the actions.
                if (runAction) {
                    let inputObject = { Item: item, SourceItem: item.data, SourceIndex: item.index };
                    await utils.executeAndCompileAllActions(this.controlData.Actions, inputObject, this);
                    
                }
            },

            

            isItemSelected(item) {
                var rtnVal = false;
                for (var x = 0; x < this.selectedValues.length; x++) {
                    if ((item == this.selectedValues[x]) || _.isEqual(item, this.selectedValues[x].data) || item.Value == this.selectedValues[x].data.Value) {
                        rtnVal = true;
                        break;
                    }
                }
                return rtnVal;
            },
            isAvailable(item) {
                if (this.selectedTypes.length < this.numberOfAllowedTypesComputed) {
                    return true;
                }
                else {
                    return this.isTypeSelected(item);
                }
            },
            isTypeSelected(item) {
                var rtnVal = false;
                for (var x = 0; x < this.selectedTypes.length; x++) {
                    if ((item.data[this.thetypekeyComputed] == this.selectedTypes[x][this.thetypekeyComputed])
                        || _.isEqual(item.data[this.thetypekeyComputed], this.selectedValues[x][this.thetypekeyComputed])) {
                        rtnVal = true;
                        break;
                    }
                }
                return rtnVal;
            },
            typeSelected(item) {
                if (this.selectedTypes.length < this.numberOfAllowedTypesComputed) {
                    if (this.selectedTypes.length == 0)
                        this.selectedTypes.push(item.data);
                    else if (!this.isTypeSelected(item)) // we may already have the type
                    {
                        // new type push it on 
                        this.selectedTypes.push(item.data);
                    }
                    return true;
                }
                else {
                    // we already have nth type, see if this item is one of them
                    if (this.isTypeSelected(item)) {
                        return true;
                    }
                }
                return false;
            },
            removeType(item) {
                if (this.shouldRemoveType(item)) {
                    // remove the selection
                    this.selectedTypes = this.selectedTypes.filter((existingItem) =>  {
                        return existingItem[this.thetypekeyComputed] !== item.data[this.thetypekeyComputed];
                    });
                }
            },
            shouldRemoveType(item) {
                // get all current types
                var remove = true;
                for (var x = 0; x < this.selectedValues.length; x++) {
                    // look through current selections to see if we have a type match
                    if ((item.data[this.thetypekeyComputed] == this.selectedValues[x].data[this.thetypekeyComputed])) {
                        remove = false;
                        break;
                    }
                }
                return remove;
            },


            
            IsItemSelected(item) {
                return this.isItemSelected(item);
            },
           
            IsTypeSelected (item) {
                return this.isTypeSelected(item);
            },
            SetSelectedItem(itemKeys, propertyToMatch) {
                return this.setSelectedItem(itemKeys, propertyToMatch);
            },
            

            async preRenderComplete() {
                this.finishRenderHandler(this);
                this.Refresh();
            },
            async Refresh() {
                if (this.controlData.SourceType === 'URL' && this.controlData.SourceURL) {
                    this.sourcemodel = await utils.api.get(this.sourceurlvalue);
                }
            },

            getContent(value) {
                if (!this.content_fn && this.controlData.Content)
                    this.content_fn = utils.compile(this, this.controlData.Content, false, 'value');

                return this.content_fn ? utils.evaluate(this.content_fn, this, false, null, false, value) : null;
            },


        },
    }

</script>

<style scoped>
    .c-caremultiselectlist {
        overflow-y: auto;
        overflow-x: hidden;
    }
</style>
