import Vue from 'vue';
import utils from '../../Shared/utils.jsx';
import BaseComponent from './BaseComponentMixin.jsx';
import { appSettings } from '@/Shared/appSettings.js';

Vue.component('auto-complete', {
    mixins: [BaseComponent],
    data: function () {
        return {
            phrenderer: null,
            Input: {},
            Value: "",

            uniqueref: null,
            selected: -1,
            list: [],
            widthList: '100%',
            showingList: false,
            x: 0,
            y: 0,
            w: 400,
        }
    },
    props: {},
    created() {
        this.uniqueref = utils.generateUUID();

        if (this.controlData.ItemFields) {
            this.displayexpn = utils.compile(this, this.controlData.ItemFields.DisplayText);
            this.titleexpn = utils.compile(this, this.controlData.ItemFields.Title);
            this.subtitleexpn = utils.compile(this, this.controlData.ItemFields.SubTitle);
            this.iconexpn = utils.compile(this, this.controlData.ItemFields.Icon);
        }
    },
    //Mounted Replaced with preRenderComplete
    computed: {
        placeholder: function () {
            try {
                if (!this.phrenderer && this.controlData.PlaceHolder)
                    this.phrenderer = utils.compile(this, this.controlData.PlaceHolder);

                return this.phrenderer ? utils.evaluate(this.phrenderer, this) : '';
            }
            catch (e)
            {
                utils.warn('placeholder could not evaluate expression: ' + this.controlData.PlaceHolder + '; ' + e);
                return '';
            }
        },
        label: function () {
            return this.placeholder;
        },
        urlvalue: function () {
            if (!this.urlexpn && this.controlData.SourceURL)
                this.urlexpn = utils.compile(this, this.controlData.SourceURL);

            return this.urlexpn ? utils.evaluate(this.urlexpn, this) : '';
        },
        sourcevalue: function () {
            if (!this.sourceexpn && this.controlData.SourceRaw)
                this.sourceexpn = utils.compileObject(this, this.controlData.SourceRaw);

            return this.sourceexpn ? utils.evaluateObject(this.sourceexpn, this) : null;
        },
        filteredList: function () {
            if (this.searchby && this.controlData.FilterFields && this.controlData.FilterFields.length > 0)
                return this.list.filter(a => {
                    for (let i = 0; i < this.controlData.FilterFields.length; i++)
                        if (a.value[this.controlData.FilterFields[i]] &&
                            a.value[this.controlData.FilterFields[i]].toLowerCase().includes(this.searchby.toLowerCase()))
                            return true;

                    return false;
                });
            else
                return this.list;
        },

        Text: {
            get: function () {
                return this.Value;
            },
            set: function (newvalue) {
                this.Value = newvalue;
            },
        },
    },
    methods: {
        sync(v) {
            this.Value = v || '';
            this.searchby = v;

            if (!v) {
                // Clear was clicked
                this.showingList = false;
                return;
            }

            utils.debug(`AutoComplete.on-change KeyPressActions { Text:${this.Value}, Length:${this.Value.length} }`);
            utils.executeAndCompileAllActions(this.controlData.KeyPressActions,
                {
                    Text: this.Value,
                    Length: this.Value.length
                }, this);
        },
        Clear() {
            this.Value = '';
            utils.debug(`AutoComplete.Clear()`);
        },
        preRenderComplete() {
            this.finishRenderHandler(this);
        },

        async loadList() {
            let data;
            switch (this.controlData.SourceType) {
                case 'Raw':
                    data = this.controlData.SourceRaw;
                    break;

                case 'RawInterpolated':
                    data = this.sourcevalue;
                    break;

                case 'URL':
                    data = await utils.api.get(this.urlvalue);
                    break;

                default:
                    utils.warn(`AutoComplete ${this.name} SourceType of ${this.controlData.SourceType} is not implemented`);
                    return;
            }

            this.list = data.map(a => {
                this.sourceData = a;

                return {
                    text: utils.evaluate(this.displayexpn, this),
                    title: utils.evaluate(this.titleexpn, this),
                    subtitle: utils.evaluate(this.subtitleexpn, this),
                    icon: utils.evaluate(this.iconexpn, this),
                    value: a,
                };
            });
            utils.debug(`AutoComplete.loadList()`);
        },
        selectItem(value) {
            this.Value = value.text;
            this.selected = -1;
            utils.debug(`AutoComplete.selectItem(value:${value})`);
        },
        async showList(items) {
            this.showingList = false;
            const rect = this.$refs[this.uniqueref].$el.getBoundingClientRect();

            this.x = rect.left;
            this.y = rect.bottom;
            this.w = rect.width;

            if (items)
                this.list = items;
            else
                await this.loadList();

            this.$nextTick(() => {
                this.showingList = true
            });
            utils.debug(`AutoComplete.showList(...)`);
        },
        click(e) {
            e.preventDefault();
            //this.searchBy = '';
            //this.showList();

            utils.debug(`AutoComplete.click() executing ClickActions { Text:${this.Value}, Length:${this.Value.length} }`);
            utils.executeAndCompileAllActions(this.controlData.ClickActions,
                {
                    Text: this.Value,
                    Length: this.Value.length
                }, this);

            if (this.controlData.OpenOnClick)
                this.showList();
        },
        keydown(e) {
            // handle Enter, Down, and Up
            //debugger;
            utils.debug(`AutoComplete.keydown(e.key:${e.key})`);

            switch (e.key) {
                case 'ArrowDown':
                    if (this.showingList && this.list.length > 0) {
                        this.selected++;
                        if (this.selected >= this.list.length)
                            this.selected = 0;
                    }
                    break;

                case 'ArrowUp':
                    if (this.showingList && this.list.length > 0) {
                        this.selected--;
                        if (this.selected < 0)
                            this.selected = this.list.length - 1;
                    }
                    break;

                case 'Tab':
                    break;

                case 'Enter':
                    e.preventDefault();

                    if (this.Value && (!this.showingList || this.filteredList.length < 1))
                        ;//this.dialPhone();
                    else if (this.showingList && this.selected >= 0 && this.filteredList.length > 0 && this.selected < this.filteredList.length) {
                        const choice = this.filteredList[this.selected];
                        this.Value = choice.text;
                        this.showingList = false;
                    }
                    break;
            }
        },

        IsDropdownOpen() {
            return this.showingList;
        },
        OpenDropdown(items, textfield, filterfields, sortfields) {
            // Ex: null,null,['fullname'],['fullname']
            utils.debug(`AutoComplete.OpenDropdown(...)`);

            this.showList();
        },
        CloseDropdown() {
            utils.debug(`AutoComplete.CloseDropdown()`);

            this.showingList = false;
        },
    },
    render(h) {
        if (!this.todisplay)
            return null;

        const style = {
            ...this.sizeStyle,
            ...utils.resolveStyleHints(this.styleHints, this),
            overflow: "hidden",
            whiteSpace: "nowrap",
            display: "inline-block"
        };

        let scopedSlots = {
            message: ({ message }) => {
                return <translation-container context={this} value={message}></translation-container>
            }
        }

        let slots;
        if (this.labelText)
            slots = [
                <translation-container slot="label" context={this} value={this.labelText}></translation-container>,
            ];

        let input = (
            <v-text-field
                class="mt-1 mb-1"
                
                outlined dense autofocus hide-details clearable
                ref={this.uniqueref}
                label={this.label}
                placeholder={this.placeholder}
                scopedSlots={scopedSlots}
                value={this.Value}
                on-input={(v) => {
                    this.sync(v);
                }}
                on-click={(e) => this.click(e)}
                on-keydown={(e) => this.keydown(e)}
                
                persistent-hint={appSettings.DebugTranslationPrefixSetting}>
                {slots}
            </v-text-field>
        );

        let menu_items = [];
        for (let i = 0; i < this.filteredList.length; i++) {
            const item = this.filteredList[i];

            menu_items.push(
                <v-list-item key={i} on-click={(e) => this.selectItem(item)}>
                    <v-list-item-icon>
                        <v-icon small>{item.icon}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            {item.title}
                        </v-list-item-title>

                        <v-list-item-subtitle>
                            {item.subtitle}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            );
        }

        return (
            <v-form class={{ 'c-AutoComplete': true, [`c-name-${this.name || 'unnamed'}`]: true }} style={style}>
                {input}

                <v-menu value={this.showingList} on-input={(value) => this.showingList = value}
                    position-x={this.x}
                    position-y={this.y}
                    min-width={this.w}
                    max-height={400}
                    absolute
                    offset-y
                >
                    <v-list multiple>
                        <v-list-item-group value={this.selected} on-change={(idx) => this.selected = idx}>
                            {menu_items}
                        </v-list-item-group>
                    </v-list>
                </v-menu>
            </v-form>
        );
    }
});