import Vue from "vue";
import utils from "../../Shared/utils.jsx";
import BaseComponent from "./BaseComponentMixin.jsx";
import { appSettings } from "../../Shared/appSettings.js";

Vue.component("acd-direct-button", {
    mixins: [BaseComponent],
    data: function() {
        return {
            mgr: null,
        };
    },
    computed: {
        buttonText() {
            return this.Translate("Sign in with ACD Direct");
        },
        autoLogin() {
            if (!this.autologinexpn && this.controlData.AutoLogin)
                this.autologinexpn = utils.compile(this, this.controlData.AutoLogin);

            return this.autologinexpn ? utils.evaluate(this.autologinexpn, this) : false;
        },
        styles() {
            return {
                ...this.sizeStyle,
                ...utils.resolveStyleHints(this.styleHints, this),
            };
        },
    },

    //Created replaced with preRenderComplete
    methods: {
        async preRenderComplete() {
            await this.startACDDirectApp();

            this.finishRenderHandler(this);

            if (this.autoLogin === true || this.autoLogin === "true")
                this.handleClick();
        },
        async startACDDirectApp() {
            if (!appSettings.WhiteLabel.ACDDirectId) return;

            await loadFile(`//${window.location.hostname}/StaticContent/oidc-client-js/v1.11.5/oidc-client.min.js`, "js");

            const settings = {
                //authority: 'https://staging.callswithoutwalls.com/openId/', // staging
                authority: "https://www.callswithoutwalls.com/openId/", // production
                popup_redirect_uri: window.location.origin + "/OidcRedirect/ACDDirect",
                client_id: appSettings.WhiteLabel.ACDDirectId,
                response_type: "id_token",
                scope: "openid",
            };

            this.mgr = new Oidc.UserManager(settings);
        },
        async handleClick() {
            try {
                const result = await this.mgr.signinPopup({
                    state: "some data",
                });

                const authArgs = {
                    ProviderType: "ACDDirect",
                    token: result.id_token, // JWT (JSON Web Token)
                    clientId: appSettings.WhiteLabel.ACDDirectId,
                };

                await this.runActions(authArgs);
            } catch (err) {}
        },
        async runActions(authArgs) {
            await utils.executeAndCompileAllActions(this.controlData.Actions, authArgs, this);
        },
    },
    render(h) {
        try {
            if (!this.todisplay) return null;

            return (
                <div
                    style={this.styles}
                    class={{ "c-ACDDirectButton d-flex": true, [`c-name-${this.name || "unnamed"}`]: true }}
                >
                    <v-btn elevation={0} on-click={this.handleClick} depressed block>
                        <div class="d-flex align-center ml-8" style="width: 100%">
                            <v-icon class="mr-4" color="#c79b68">
                                mdi-headset
                            </v-icon>
                            {this.buttonText}
                        </div>
                    </v-btn>
                </div>
            );
        } catch (e) {
            utils.error("ACDDirectButton Render failed", e);
            return <div>ACDDirectButton Failed to Render {e}</div>;
        }
    },
    loadFile(filename, filetype) {
        return new Promise((resolve) => {
            let fileRef;
            if (filetype === "js") {
                //if filename is a external JavaScript file
                fileRef = document.createElement("script");
                fileRef.setAttribute("type", "text/javascript");
                fileRef.setAttribute("src", filename);
                fileRef.onload = resolve;
            }
            if (typeof fileRef !== "undefined") document.getElementsByTagName("head")[0].appendChild(fileRef);
        });
    },
});
