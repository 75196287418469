import Vue from 'vue';
import BaseComponent from './baseFormMixin.jsx';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

import { appSettings } from '@/Shared/appSettings.js';

Vue.component('sform-email', {
    mixins: [BaseComponent],
    data: () => ({
    }),
    props: {
    },
    created() {
    },
    mounted() {
        this.loadComplete();
    },
    computed: {

    },
    methods: {
    },
    render() {
        let scopedSlots = {
            message: ({message}) => {
                return <translation-container context={this} value={message}></translation-container>
            }
        }

        let slots = [
            <translation-container slot="label" context={this} value={this.labelText}></translation-container>,
        ];

        const input = (
            <v-text-field
                class="caption pa-0 ma-0"
                style={{ width: "100%" }}
                outlined single-line dense hide-details
                value={this.itemvalue}
                type="email"
                rules={this.rules}
                on-input={(value) => this.sync(value)}
                scopedSlots={scopedSlots}
                hint={this.hintText}
                persistent-hint={appSettings.DebugTranslationPrefixSetting}
                on-blur={this.onBlur}
                on-focus={this.onFocus}
            >{slots}</v-text-field>
        );

        if (this.appearance)
            input.componentOptions.propsData = { ...input.componentOptions.propsData, ...this.appearance };
            
        if (this.directives) {
            if (input.data.directives)
                input.data.directives = [...input.data.directives, ...this.directives];
            else
                input.data.directives = this.directives;
        }

        return input;
    }
});