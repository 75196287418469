import Vue from 'vue';
import utils from '../../../../Shared/utils.jsx';
import methods from '../../../../Shared/methods';

Vue.component('dynamic-user-action-dsgn', {
    data: function () {
        return {
            action: {},
        }
    },
    created() {
        this.watcher$ = this.$watch('designmodel.UserAction.ActionURL', function (val, oldval) {
            this.Refresh();
        });
    },
    destroyed() {
        this.watcher$();
    },  
    async mounted() {
        await this.Refresh();
    },
    props: {
        name: '',
        root: null,
        designmodel: null,
        controlData: null,
    },
    computed: {
        Root: function () {
            return this.root._self;
        },
        selected$: function () {
            return this.Root.SelectedNode && this.Root.SelectedModel == this.designmodel;
        },
    },
    methods: {
        ...methods,
        async Refresh()
        {
            const a = await utils.api.get('Apps/UI/Action/' + this.designmodel.ActionData.UserAction.ActionURL);
            this.action = a;
        },
        SelectNode(node) {
            // Ignore node arg, we will only allow the designer to edit the action definition, not
            // individual members of a user action (because it is in a separate document)
            this.Root.SelectNode(this);
        },
        handleClick(e) {
            this.Root.SelectNode(this);
            e.cancelBubble = true;
            e.stopPropagation();
        },
        open(e) {
            this.Root.ExternalNavigateToName('Action', this.designmodel.ActionData.UserAction.ActionURL);
            //this.Root.ExternalNavigate('Apps/UIWhole/Action/' + this.designmodel.ActionData.UserAction.ActionURL);

            e.cancelBubble = true;
            e.stopPropagation();
        },
    },
    render(h) {
        if (!this.action.ActionData)
            return <div>Not assigned...</div>;

        let DynamicControl = utils.getDynamicAction(this.action);

        if (!DynamicControl)
            DynamicControl = 'default-unknown';

        DynamicControl += '-dsgn';

        const open_title = "Open " + this.designmodel.ActionData.UserAction.ActionURL;

        return (
            <div
                class={{ 'designer-container': true }}
                on-click={(e) => this.handleClick(e)}>
                <div class="design-options" title={open_title} on-click={(e) => this.open(e)} style={{ top: "4px", left: "4px", display: this.selected$ ? "block" : "none" }}>Open</div>
                <div class={{ "designer-user-control-hovering": true, 'designer-user-control-selected': this.selected$ }}></div>
                <DynamicControl type={this.action.ActionType} name={this.action.ActionData.Name} root={this} controlData={this.action.ActionData}>
                </DynamicControl>
            </div>
        );
    }
});

