import EventBus from '@/Application/event-bus';
import token from '@/Services/token';
import api from '@/Services/api';
import utils from '@/Shared/utils';
import care from '@/Services/careService';
import webSocket from '@/Services/webSocketService';
import _ from 'lodash';
import {appSettings, careGlobalVars} from '@/Shared/appSettings';

export default {
    subscribedSession: [],
    heartBeatTimer: null,
    undeliveredBuffer: [],
    registered: false,

    registerAndStartBound : null,
    stopChannelHeartbeatBound: null,
    handleChannelEventBound: null,
    handleUnsubscribeEventBound: null,

    //This method is only called from the App.Vue when the site gets created so we can subscribe
    created() {
        utils.log(`App.vue, serverEventCoordinator.created()`);

        this.registerAndStartBound = this.registerAndStart.bind(this);
        this.stopChannelHeartbeatBound = this.stopChannelHeartbeat.bind(this);
        this.handleChannelEventBound = this.handleChannelEvent.bind(this);
        this.handleUnsubscribeEventBound = this.unsubscribeForAllEventsHandler.bind(this);

        EventBus.$on('WebSocketConnected', this.registerAndStartBound);
        EventBus.$on('WebSocketDisconnected', this.stopChannelHeartbeatBound);
        EventBus.$on('ChannelEvent', this.handleChannelEventBound);
        EventBus.$on('Action-UnsubscribeFromAllServerEvents', this.handleUnsubscribeEventBound)
    },
    //This method is only called from the App.Vue when the site gets destroyed so we can unsubscribe
    destroyed() {
        utils.log(`App.vue, serverEventCoordinator.destroyed()`);

        EventBus.$off('WebSocketConnected', this.registerAndStartBound);
        EventBus.$off('WebSocketDisconnected', this.stopChannelHeartbeatBound);
        EventBus.$off('ChannelEvent', this.handleChannelEventBound);
        EventBus.$off('Action-UnsubscribeFromAllServerEvents', this.handleUnsubscribeEventBound)
    },
    registerAndStart() {
        this.registerChannel();
        this.startChannelHeartbeat();
    },
    registerChannel() {
        var channelName = token.GetChannelName();

        utils.debug(`serverEventCoordinator registering channel ${channelName}`);

        api.get(`Apps/Eventing/Register/${channelName}?timeToLive=60000&expires=900000`)
            .then(async (response) => {
                care.logInfo(response);
                this.registered = true;

                // this "Subscribe" is different than the "SubscribeForSessionEvents". This sets up the callback on webserver for receiveing events from the channel
                // whereas SubscribeForSessionEvents binds the channel for certain events
                utils.debug(`serverEventCoordinator calling webSocket.Subscribe(${channelName})`);
                await webSocket.Subscribe(channelName);

                // when restablishing websocket, resubscribe to all sessions. This is needed because if the computer goes to sleep and all subscriptions expire on the server, we will need to resubscribe
                // to create the queues again. Because we don't know the reason for the reconnect (could be from sleeping) we always resubscribe on connecting.
                this.reSubscribeToAllSessions();
            });
    },
    reSubscribeToAllSessions() {
        let channelName = token.GetChannelName();

        for (let x = 0; x < this.subscribedSession.length; x++) {
            let session = this.subscribedSession[x];

            api.get(`Apps/Eventing/Subscribe/${session.sessionId}/${channelName}`)
                .then(() => {
                    session.subscribeSuccessful = true;
                });
        }
    },
    // uniqueId is used to remove subscriptions when controls are destroyed
    // eventNames is a comma separate list of eventNames to subscribe to 
    subscribeForSessionEvents(sessionId, uniqueId, eventNames, callbackFunction) {
        if (!sessionId)
            return;

        uniqueId = uniqueId || "";
        let session = this.subscribedSession.find(session => session.sessionId === sessionId);
        
        // add to list if not already there
        if (!session) {
            session = {
                sessionId: sessionId,
                subscribeSuccessful: false,
                callbacks: []
            };

            this.subscribedSession.push(session);
        }

        if (!session.subscribeSuccessful && this.registered) {
            // resubscribing (i.e. binding) to a session you've previously subscribed to doesn't cause any problem
            let channelName = token.GetChannelName();
            api.get(`Apps/Eventing/Subscribe/${sessionId}/${channelName}`)
                .then(() => {
                    session.subscribeSuccessful = true;
                });
        }

        let eventNamesList = (eventNames ? eventNames.split(',') : [ "" ]);

        eventNamesList.forEach(eventName => {
            session.callbacks.push({
                uniqueId: uniqueId,
                eventName: eventName,
                callbackFunction: callbackFunction
            });
        });

        this.undeliveredBuffer_AttemptDelivery();
    },
    // this is generally done prior to switching companies or logging out. Both of which will remove the token needed to unsubscribe from events, so this just removes ALL subscriptions while we still have the token
    async unsubscribeForAllEventsHandler(action) {
        utils.log(`serverEventCoordinator UnsubscribeForAllEvents`);

        if (action.ActionData && action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

        try {
            for (let x = this.subscribedSession.length - 1; x >= 0; x--) {
                await this.unsubscribeForSessionEvents(this.subscribedSession[x].sessionId);
            }

            try {
                await utils.success(action);
            } catch (e) { }
        }
        catch (e) {
            try {
                await utils.failure(action, { Data: e });
            } catch (e) { }
        }
        finally {
            try {
                await utils.complete(action);
            }
            catch (e) { }

            // Complete the promise for the executeAction method
            action.FinishFunc(true);
        }
    },
    // eventNames is optional, if not specified all subscriptions for uniqueId will be removed
    // eventNames is a comma separate list of eventNames to subscribe to 
    async unsubscribeForSessionEvents(sessionId, uniqueId, eventNames) {
        if (!sessionId)
            return;

        uniqueId = uniqueId || "";

        const promises = [];

        // loop reversed because we may remove items as we loop
        for (var x = this.subscribedSession.length - 1; x >= 0; x--)
        {
            if (sessionId == this.subscribedSession[x].sessionId)
            {
                let session = this.subscribedSession[x];

                // loop reversed because we may remove items as we loop
                for (let y = session.callbacks.length - 1; y >= 0; y--)
                {
                    let eventNamesList = (eventNames ? eventNames.split(',') : [""]);

                    for (let z = 0; z < eventNamesList.length; z++)
                    {
                        let eventName = eventNamesList[z];

                        if ((!uniqueId || session.callbacks[y].uniqueId == uniqueId) &&
                            (!eventName || session.callbacks[y].eventName == eventName))
                        {
                            session.callbacks.splice(y, 1);
                            break;
                        }
                    }   
                }
    
                // if there are no more callbacks, unregister from receiving events for this sessionId
                if (session.callbacks.length == 0)
                {
                    this.subscribedSession.splice(x, 1);
                    promises.push(api.get(`Apps/Eventing/Unsubscribe/${sessionId}/${token.GetChannelName()}`));
                }
            }
        }
        await Promise.all(promises);
    },
    handleChannelEvent(event, fromUndeliveredBuffer) {
        let subscriberFound = false;

        care.logInfo(`ServerEventCoordinator event received: ${event.EventName}`);

        let cbs = [];

        // invoke all callbacks regstered for session/eventname
        for (let x = 0; x < this.subscribedSession.length; x++)
        {
            if (event.SessionId == this.subscribedSession[x].sessionId)
            {
                let session = this.subscribedSession[x];

                for (let y = 0; y < session.callbacks.length; y++)
                {
                    if (!session.callbacks[y].eventName || event.EventName == session.callbacks[y].eventName) {
                        cbs.push(session.callbacks[y]);
                        subscriberFound = true;
                    }
                }
            }
        }

        for (let x = 0; x < cbs.length; x++)
        {
            care.logInfo('ServerEventCoordinator event received: ' + event.EventName + ' firing callback ' + (x + 1) + ' of ' + cbs.length + '...');
            cbs[x].callbackFunction(event);
        }

        // if there was no subscriber, then buffer the message in case we are dealing with a simple timing issue (such as phone dialing) where the subscribing cannot be done ahead of time.
        if (!subscriberFound && !fromUndeliveredBuffer)
            this.undeliveredBuffer_QueueEvent(event);

        return subscriberFound;
    },
    undeliveredBuffer_QueueEvent(event) {
        this.undeliveredBuffer_RemoveExpired();

        let expireTime = new Date();
        expireTime.setSeconds(expireTime.getSeconds() + 5); // we only buffer messages for 5 seconds

        // add new items to top of list (not bottom), this helps in removing items as we loop to deliver them
        this.undeliveredBuffer.unshift({
            expireTime: expireTime,
            event: event
        });
    },
    undeliveredBuffer_AttemptDelivery() {
        let currentDate = new Date();

        // oldest messages are at bottom of list
        //var expiredBuffer = this.undeliveredBuffer.filter(buffer => currentDate > buffer.expireTime || this.handleChannelEvent(buffer.event, true));
        //expiredBuffer.forEach(buf => _.pull(this.undeliveredBuffer, buf));
        for (let x = this.undeliveredBuffer.length - 1; x >= 0; x--)
        {
            if (currentDate > this.undeliveredBuffer[x].expireTime || // expired items won't be delivered
                this.handleChannelEvent(this.undeliveredBuffer[x].event, true))
            {
                this.undeliveredBuffer.splice(x, 1); // removed expired or delivered item from buffer
            }
        }
    },
    undeliveredBuffer_RemoveExpired() {
        let currentDate = new Date();

        // oldest messages are at bottom of list
        for (let x = this.undeliveredBuffer.length - 1; x >= 0; x--)
        {
            if (currentDate > this.undeliveredBuffer[x].expireTime) {
                this.undeliveredBuffer.splice(x, 1); // removed expired item from buffer
            }
            else
                break; // stop looping as soon as we find an unexpired item
        }
    },
    // Implement a separate HeartBeat to keep channel from expiring 
    startChannelHeartbeat() {
        this.stopChannelHeartbeat(); // remove existing timer before creating new one

         // Subscribe for User Events (if a websocket disconnects and reconnects this will re-subscribe, which isn't necessary, but doesn't cause a problem
        this.subscribeForSessionEvents(this.getHeartBeatSessionId(), "careServerEventCoordinatorService", "HeartBeat", this.heartBeatReceived);

        care.logDebug("ServerEventCoordinatorService starting heartbeat");
        this.heartBeatTimer = setInterval(this.sendChannelHeartbeat.bind(this), 30000); // heartbeat every 30 seconds
    },
    stopChannelHeartbeat() {
        // remove existing timer before creating new one
        if (this.heartBeatTimer) {
            care.logDebug("ServerEventCoordinatorService stopping heartBeatTimer");

            clearInterval(this.heartBeatTimer);
            this.heartBeatTimer = null;
        }
    },
    sendChannelHeartbeat() {
        let sessionId = this.getHeartBeatSessionId();

        care.logDebug("ServerEventCoordinatorService sending heartbeat");

        // This will send the heart beat, the response will come async through the "handleChannelEvent"
        api.post(`Apps/Eventing/Publish/${sessionId}`,
                {
                    "SessionId": sessionId,
                    "EventName": "HeartBeat",
                    "EventTime": new Date().toISOString(),
                    "Content": ""
                });
    },
    getHeartBeatSessionId() {
        return `${token.GetUserEventKey()}_HeartBeat_${appSettings.InstanceGuid }`;
    },
    heartBeatReceived(event) {
        let eventTime = event.EventTime;
        let rtt = new Date() - new Date(eventTime);
        care.logInfo("Heartbeat Round Trip time: " + rtt + "ms");
    }
}