import Vue from 'vue';
import Vuetify from 'vuetify';
import utils from '../../Shared/utils.jsx';
import Bar from '../../Assets/Bar.png';
import BarWithLine from '../../Assets/BarWithLine.png';
import BlankCard from '../../Assets/BlankCard.jpg';
import Donut from '../../Assets/Donut.png';
import Numeric from '../../Assets/Numeric.png';
import Table from '../../Assets/Table.png';

Vue.component('card-browser', {
    data: () => ({
        selected_tab: 0,
        dashboardCards: [],
        reportCards: [],
        filter: '',
        selectedCardId: '',
        imageMap: {
            "Bar": Bar,
            "BarWithLine": BarWithLine,
            "Donut": Donut,
            "Numeric": Numeric,
            "Table": Table,
        }
    }),
    props: {
        confirm: null,
        cancel: null,
    },
    async created() {},
    mounted() {
        this.Refresh();
    },
    methods: {
        async Refresh() {
            var allCards = await utils.api.get('Apps/Platform/Schema/RealTime/v1/WidgetDefinition/ListAllPossibleByID');
            var dCards = allCards.DashboardCards;
            await this.getThumbnails(dCards);
            this.dashboardCards = dCards;

            this.reportCards = allCards.ReportCards;
            
        },
        returnControl(id, title) {
            this.confirm(id, title);
            this.selectedCardId = null;
        },
        search(value) {
            this.filter = value;
        },
        highlightCard(card) {
            this.selectedCardId = card.ID;
        },
        select() {
            if(this.dashboardCards.length == 0)
                return;
            
            let selectedCard = this.dashboardCards.find(c => c.ID == this.selectedCardId);
            if(selectedCard && !selectedCard?.UserControl)
                return;
            else if (!selectedCard) {
                selectedCard = this.reportCards.find(c => c.ID == this.selectedCardId);
                if(!selectedCard)
                    return;
            }

            this.confirm(selectedCard);
            this.selectedCardId = null;
        },
        async getThumbnails(widgetDefs){
            widgetDefs.forEach(w => {
                w.thumbnail = this.imageMap[w.Image] || BlankCard;
            })
           
            return;
        },
        getCardStyle(card){
            let style = {};
            if(card.ID == this.selectedCardId)
            {
                style = {
                    border: "2px solid var(--v-primaryActive-base)"
                }
            }
            return style;
        },
    },
    render(h) {
        let ditems;
        if (this.dashboardCards.length > 0){
            ditems = this.dashboardCards.filter(c => ((!this.filter || c.Name.toLowerCase().includes(this.filter.toLowerCase())) && c.UserControl)).map(c => (
                <v-col cols="4" class="my-2">
                    <v-card 
                        class="content pa-2 mx-2" 
                        height="100%" hover on-click={() => this.highlightCard(c)} 
                        style={this.getCardStyle(c)}>
                        <v-img src={c.thumbnail}/>
                        <v-card-text class="text--primary" style="word-break: normal">{c.Name}</v-card-text>
                    </v-card>
                </v-col>
            ));
        }
        else
            ditems = <tr><td>Loading...</td></tr>;

        let ritems;
        if (this.reportCards.length > 0){
            ritems = this.reportCards.filter(c => !this.filter || c.Name.toLowerCase().includes(this.filter.toLowerCase()) ).map(c => (
                <v-col cols="4" class="my-2">
                    <v-card 
                        class="content pa-2 mx-2" 
                        height="100%" hover on-click={() => this.highlightCard(c)} 
                        style={this.getCardStyle(c)}>
                        <v-img src={c.thumbnail}/>
                        <v-card-text class="text--primary" style="word-break: normal"> {c.Name} </v-card-text>
                        <v-card-subtitle color="grey lighten-1" style="font-size: 12px; padding: 0 10px;" >Shared By: {c.IsSystem ? 'System' : (utils.global_variables.Users[c.CreatedBy]?.fullname || 'Unknown')}</v-card-subtitle>
                    </v-card>
                </v-col>
            ));
        }
        else
            ritems = <tr><td>No Reports available</td></tr>; 

        return (
            <v-card style={{maxHeight:'715px'}}>
                <v-app-bar flat color="rgba(0, 0, 0, 0)" style={{ backgroundColor: 'var(--v-navigation-base)', maxHeight: '60px', height: '60px' }}>
                    <v-toolbar-title class="title white--text pl-0">
                        Add new card
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-btn elevation={0} color="white" icon on-click={this.cancel}>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    
                </v-app-bar>

                <div style={{ display: 'flex', alignItems: 'center' }} class="my-4 mx-5">
                    <v-text-field
                        style="max-width: 300px;"
                        dense hide-details
                        clear-icon="mdi-close-circle"
                        clearable
                        placeholder="Quick Search"
                        prepend-inner-icon="mdi-magnify"
                        on-input={(value) => this.search(value)}
                    ></v-text-field>
                    <v-spacer />
                    <v-btn icon class="ma-1" elevation={0} on-click={() => this.Refresh()}><v-icon>mdi-refresh</v-icon></v-btn>
                </div>
                <v-tabs style={{padding: "0 10px"}} value={this.selected_tab} on-change={(index) => this.selected_tab = index}>
                    <v-tab>Dashboard Cards</v-tab>
                    <v-tab>Shared Reports</v-tab>
                </v-tabs>
                <v-tabs-items value={this.selected_tab} style={{padding: "0 10px"}}>
                    <v-tab-item>
                        <div style={{overflow: 'auto', height: '475px'}}>
                            <v-container >
                                <v-row no-gutters>
                                    {ditems}
                                </v-row>
                            </v-container>
                        </div>
                    </v-tab-item>
                    <v-tab-item>
                        <div style={{overflow: 'auto', height: '475px'}}>
                            <v-container >
                                <v-row no-gutters>
                                    {ritems}
                                </v-row>
                            </v-container>
                        </div>
                    </v-tab-item>
                </v-tabs-items>
                

                
                <v-card-actions class="mt-2" style="justify-content: flex-end;">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" class="ma-1" elevation={0} on-click={() => this.select()}>Add</v-btn>
                </v-card-actions>
            </v-card>
        );
    }
});