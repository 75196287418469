<template>
    <div style="margin-top: 2px;">
        <template v-for="(item,i) in icons">
            <v-tooltip bottom :key="i" v-if="!item.MenuItems || !item.MenuItems.length">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon small v-bind="attrs" v-on="on" @click="(e) => buttonClick(e, item)" :disabled="!getIconEnabled(item)" v-show="getIconDisplay(item)" :class="{ 'hidden-element': !getIconVisible(item) }">
                        <v-icon small>{{ item.Icon }}</v-icon>
                    </v-btn>
                </template>
                {{ item.Tooltip }}
            </v-tooltip>

            <v-menu offset-y v-else :key="i">
                <template v-slot:activator="{ on: mnu, attrs }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn icon small v-bind="attrs" v-on="{ ...tooltip, ...mnu }" @click="(e) => buttonClick(e, item)" :disabled="!getIconEnabled(item)" v-show="getIconDisplay(item)" :class="{ 'hidden-element': !getIconVisible(item) }">
                                <v-icon small>{{ item.Icon }}</v-icon>
                            </v-btn>
                        </template>
                        {{ item.Tooltip }}
                    </v-tooltip>
                </template>
                <v-list>
                    <v-list-item v-for="(menu, mi) in item.MenuItems" :key="mi" @click="(e) => menuClick(e, menu)" :disabled="!getMenuEnabled(menu)" v-show="getIconDisplay(item)" :class="{ 'hidden-element': !getIconVisible(item) }">
                        <v-list-item-title>{{ getMenuTitle(menu) }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>
    </div>
</template>

<script>
    import utils from '@/Shared/utils.jsx';

    export default {
        data: function () {
            return {
            }
        },
        props: {
        },
        created() {
        },
        computed: {
            icons: function () {
                if (this.params && this.params.customdata) {
                    return this.params.customdata.Icons;
                }
                else
                    return [];
            },
        },
        methods: {
            getIconDisplay(item) {
                if (!item.displayExpn)
                    return true;

                return utils.evaluate(item.displayExpn, this.params.customdata.context, false, false, false, this.params.data);
            },
            getIconVisible(item) {
                if (!item.visibleExpn)
                    return true;

                return utils.evaluate(item.visibleExpn, this.params.customdata.context, false, false, false, this.params.data);
            },
            getIconEnabled(item) {
                if (!item.enabledExpn)
                    return true;

                return utils.evaluate(item.enabledExpn, this.params.customdata.context, false, false, false, this.params.data);
            },

            getMenuVisible(menu) {
                if (!menu.visibleExpn)
                    return true;

                return utils.evaluate(menu.visibleExpn, this.params.customdata.context, false, false, false, this.params.data);
            },
            getMenuEnabled(menu) {
                if (!menu.enabledExpn)
                    return true;

                return utils.evaluate(menu.enabledExpn, this.params.customdata.context, false, false, false, this.params.data);
            },

            getMenuTitle(menu) {
                return menu.titleExpn ? utils.evaluate(menu.titleExpn, this, null, null, null, this.params.data) : menu.Title;
            },
            async buttonClick(e, item) {
                //alert('clicked ' + JSON.stringify(item));

                for(let action of item.Actions)
                    await utils.executeAction(action, { Data: this.params.data }, this.params.customdata.context, this.params.customdata.controlscope, this.params.customdata.scopeitems);
            },
            async menuClick(e, menu) {
                for (let action of menu.Actions)
                    await utils.executeAction(action, { Data: this.params.data }, this.params.customdata.context, this.params.customdata.controlscope, this.params.customdata.scopeitems);
            },
        }
    }
</script>

<style scoped>
    .hidden-element {
        visibility: hidden;
    }
</style>