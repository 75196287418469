import Vue from "vue";
import utils from "../../Shared/utils.jsx";
import BaseComponent from "./BaseComponentMixin.jsx";
import { appSettings } from "../../Shared/appSettings.js";
import { times, debounce } from "lodash";
import "./css/InfiniteConversationView.css";

Vue.component("infinite-conversation-view-message", {
    mixins: [BaseComponent],
    props: {},
});

Vue.component("infinite-conversation-view", {
    mixins: [BaseComponent],
    data: function() {
        return {
            // functionality
            doneWithTop: false,
            conversation: [],
            topDateText: null,
            messageCount: 0,
            renderedCount: 0,
            isLoading: false,
            scrollPosition: null,

            // evals
            dataTypeEval: null,
            dataURLEval: null,
            dataRawEval: null,
            rowsPerFetchEval: null,
            deconsolidateMessagesEval: null,
            messageItemControlTemplateEval: null,
        };
    },
    created() {
        this.namelookupurl_expn = utils.compile(this, this.controlData.MessageProperties.NameLookupURL, false, 'Input');
        this.key_expn = utils.compile(this, this.controlData.MessageProperties.Key, false, 'Input');
        this.name_expn = utils.compile(this, this.controlData.MessageProperties.Name, false, 'Input');
        this.text_expn = utils.compile(this, this.controlData.MessageProperties.Text, false, 'Input');
        this.date_expn = utils.compile(this, this.controlData.MessageProperties.Date, false, 'Input');
        this.direction_expn = utils.compile(this, this.controlData.MessageProperties.Direction, false, 'Input');
        this.color_expn = utils.compile(this, this.controlData.MessageProperties.Color, false, 'Input');
        this.icon_expn = utils.compile(this, this.controlData.MessageProperties.Icon, false, 'Input');
        this.sendername_expn = utils.compile(this, this.controlData.MessageProperties.SenderName, false, 'Input');
    },
    computed: {
        dataType() {
            const defaultVal = "Url";

            try {
                if (this.dataTypeEval === null && this.controlData.DataType)
                    this.dataTypeEval = utils.compile(this, this.controlData.DataType);

                if (this.dataTypeEval) {
                    return utils.evaluate(this.dataTypeEval, this);
                }

                return defaultVal;
            } catch (e) {
                utils.warn("DataType could not evaluate expression: " + this.controlData.DataType + "; " + e);

                return defaultVal;
            }
        },
        dataURL() {
            const defaultVal = "";

            try {
                if (this.dataURLEval === null && this.controlData.DataURL)
                    this.dataURLEval = utils.compile(this, this.controlData.DataURL);

                if (this.dataURLEval) {
                    return utils.evaluate(this.dataURLEval, this);
                }

                return defaultVal;
            } catch (e) {
                utils.warn("DataURL could not evaluate expression: " + this.controlData.DataURL + "; " + e);

                return defaultVal;
            }
        },
        dataRaw() {
            const defaultVal = [];

            try {
                if (this.dataRawEval === null && this.controlData.DataRaw)
                    this.dataRawEval = utils.compileObject(this, this.controlData.DataRaw);

                if (this.dataRawEval) {
                    return utils.evaluateObject(this.dataRawEval, this);
                }

                return defaultVal;
            } catch (e) {
                utils.warn("DataRaw could not evaluate expression: " + this.controlData.DataType + "; " + e);

                return defaultVal;
            }
        },
        rowsPerFetch() {
            const defaultVal = 50;

            try {
                if (this.rowsPerFetchEval === null && this.controlData.RowsPerFetch)
                    this.rowsPerFetchEval = utils.compile(this, this.controlData.RowsPerFetch);

                if (this.rowsPerFetchEval) {
                    return utils.evaluate(this.rowsPerFetchEval, this);
                }

                return defaultVal;
            } catch (e) {
                utils.warn("RowsPerFetch could not evaluate expression: " + this.controlData.RowsPerFetch + "; " + e);

                return defaultVal;
            }
        },
        deconsolidateMessages() {
            const defaultVal = false;

            try {
                if (this.deconsolidateMessagesEval === null && this.controlData.DeconsolidateMessages)
                    this.deconsolidateMessagesEval = utils.compile(this, this.controlData.DeconsolidateMessages);

                if (this.deconsolidateMessagesEval) {
                    return utils.evaluate(this.deconsolidateMessagesEval, this);
                }

                return defaultVal;
            } catch (e) {
                utils.warn(
                    "DeconsolidateMessages could not evaluate expression: " +
                        this.controlData.DeconsolidateMessages +
                        "; " +
                        e
                );

                return defaultVal;
            }
        },
        messageItemControlTemplate() {
            const defaultVal = "";

            try {
                if (this.messageItemControlTemplateEval === null && this.controlData.MessageItemControlTemplate)
                    this.messageItemControlTemplateEval = utils.compile(
                        this,
                        this.controlData.MessageItemControlTemplate
                    );

                if (this.messageItemControlTemplateEval) {
                    return utils.evaluate(this.messageItemControlTemplateEval, this);
                }

                return defaultVal;
            } catch (e) {
                utils.warn(
                    "MessageItemControlTemplate could not evaluate expression: " +
                        this.controlData.MessageItemControlTemplate +
                        "; " +
                        e
                );

                return defaultVal;
            }
        },
        styles() {
            return {
                ...this.sizeStyle,
                ...utils.resolveStyleHints(this.styleHints, this),
                overflow: "auto",
            };
        },
    },
    methods: {
        async preRenderComplete() {
            await this.init();

            await this.finishRenderHandler(this);
        },
        async init() {
            // Url
            if (this.dataType === "Url") {
                await this.loadInitialMessages();
            }
            // RawInterpolated
            else {
                await this.loadInitialMessages();
                this.doneWithTop = true; // there are no more messages to retrieve
            }
        },
        async loadInitialMessages() {
            this.isLoading = true;
            const messages = await this.getItems();
            await this.addMessageLines(messages, "bottom");
            this.isLoading = false;

            return true;
        },
        async loadMoreMessages() {
            if (this.doneWithTop || !this.conversation.length || this.isLoading) {
                return;
            }

            this.isLoading = true;

            this.scrollPosition = this.$refs.scrollArea.scrollHeight;

            const key = this.conversation[0].key;
            const items = await this.getItems(key);
            items.splice(items.length - 1, 1); // The last message is already in the list

            await this.addMessageLines(items, "top");

            this.isLoading = false;

            return true;
        },
        async getItems(key) {
            if (this.dataType === "RawInterpolated") {
                this.doneWithTop = true;
                return this.dataRaw;
            } else {
                const separator = this.dataURL.indexOf("?") == -1 ? "?" : "&";

                let url = `${this.dataURL}${separator}maxRows=${this.rowsPerFetch}`;

                if (key) {
                    url += `&StartTimeStamp=${key}`;
                }

                try {
                    let messages = await utils.api.get(url);
                    this.messageCount = messages.length;

                    // get message count

                    messages.reverse();

                    return messages;
                } catch (err) {
                    return null;
                }
            }
        },
        async addMessageLines(messages, whereToAdd) {
            if (!messages.length) {
                return;
            }

            let newMessages = [];
            let baseScopedContext = utils.getBaseScopedContext(this);
            const propertiesTemplate = this.controlData.MessageProperties;

            for (let message of messages) {
                if (message) {
                    baseScopedContext.rowData = message;

                    this.rowData = message;

                    //const lookupurl = utils.evaluate(this.namelookupurl_expn, this, false, null, false, message);
                    const lookupres = {}; //await utils.api.get(lookupurl);

                    // Get interpolated values
                    let messageProperties = {
                        NameLookupURL: utils.evaluate(this.namelookupurl_expn, this, false, null, false, lookupres),
                        Key: utils.evaluate(this.key_expn, this, false, null, false, lookupres),
                        Name: utils.evaluate(this.name_expn, this, false, null, false, lookupres),
                        Text: utils.evaluate(this.text_expn, this, false, null, false, lookupres),
                        Date: utils.evaluate(this.date_expn, this, false, null, false, lookupres),
                        Direction: utils.evaluate(this.direction_expn, this, false, null, false, lookupres),
                        Color: utils.evaluate(this.color_expn, this, false, null, false, lookupres),
                        Icon: utils.evaluate(this.icon_expn, this, false, null, false, lookupres),
                        SenderName: utils.evaluate(this.sendername_expn, this, false, null, false, lookupres),
                    };
                    //Object.keys(propertiesTemplate).forEach((key) => {
                    //    const compiled = utils.compile(baseScopedContext, propertiesTemplate[key]);
                    //    const evaluated = utils.evaluate(compiled, baseScopedContext);

                    //    messageProperties[key] = evaluated;
                    //});

                    let newMessage = {
                        key: messageProperties.Key,
                        direction: messageProperties.Direction.charAt(0).toUpperCase() + messageProperties.Direction.slice(1),
                        nameLookupURL: messageProperties.NameLookupURL,
                        name: messageProperties.Name, // this will be set by the lookup code
                        color: messageProperties.Color || "white", //randomColor,
                        icon: messageProperties.Icon,
                        senderName: messageProperties.SenderName,
                        date: messageProperties.Date ? new Date(messageProperties.Date) : undefined,
                        dateString: undefined, // set below
                        timeString: undefined, // set below
                        originalMessage: message,
                        messageGroup: [message],
                        isDivider: false,
                        isNotesAndDisps: false,
                    };

                    if (messageProperties.Date) {
                        // Getting date and time and formatting them properly
                        let dateString = newMessage.date.toLocaleDateString();
                        let timeString = newMessage.date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
                        if (timeString.startsWith("0")) timeString = timeString.substring(1);

                        newMessage.dateString = dateString;
                        newMessage.timeString = timeString;
                    }

                    newMessages.push(newMessage);
                }
            }

            // add to conversation
            if (whereToAdd === "top") {
                if (newMessages.length) {
                    this.conversation = newMessages.concat(this.conversation);
                } else {
                    this.doneWithTop = true;
                }
            } else {
                this.conversation = this.conversation.concat(newMessages);
            }

            if (propertiesTemplate) {
                if (propertiesTemplate.Date) {
                    // consolidate messages within same min
                    if (!this.deconsolidateMessages) {
                        this.consolidateMessages();
                    }

                    // add date dividers
                    this.addDividers();
                }

                // do name lookups
                if (propertiesTemplate.NameLookupURL) {
                    await this.resolveMessageNames();
                }
            }

            return;
        },
        consolidateMessages() {
            let index = 0;

            // go through conversation messages. Add message to next if they are from same source within same minute
            while (true) {
                if (index > this.conversation.length - 2)
                    //  note the -2, this is because we always want a "next" message avail - so we loop up to the next to last message
                    break;

                let thisMessage = this.conversation[index];
                let nextMessage = this.conversation[index + 1];

                if (
                    !(thisMessage.isDivider || nextMessage.isDivider) &&
                    thisMessage.direction == nextMessage.direction &&
                    thisMessage.nameLookupURL == nextMessage.nameLookupURL &&
                    (nextMessage.date - thisMessage.date) / 1000 < 60
                ) {
                    // consolidate message - add thisMessage to next and remove thisMessage

                    if (nextMessage.messageGroup.length > 1) {
                        thisMessage.messageGroup.push(...nextMessage.messageGroup);
                    } else {
                        thisMessage.messageGroup.push(nextMessage.originalMessage);
                    }

                    nextMessage.messageGroup = thisMessage.messageGroup;
                    this.conversation.splice(index, 1);
                    // don't increment index because we just merged records and try consolidation again.
                }

                index++;
            }
            return;
        },
        async resolveMessageNames() {
            /**
             * Note (10/22/2021): This was not currently used in the Angular implementation, so it is not implemented here. 
             * - Benton
             */
            return;
        },
        addDividers() {
            // We want to make sure we have a date at the beginning of the conversation string
            let firstMessage = this.conversation[0];
            let firstMessageKey = firstMessage.key;

            if (firstMessage.messageGroup && firstMessage.messageGroup.length > 1) {
                firstMessageKey = firstMessage.messageGroup[0].timestamp;
            }

            let firstDateDivider = {
                key: firstMessageKey,
                date: firstMessage.date,
                dateString: firstMessage.date.toLocaleDateString(),
                isDivider: true,
                isNotesAndDisps: false,
            };

            this.conversation.splice(0, 0, firstDateDivider);

            let lastDivider = firstDateDivider;
            for (let messageIndex = 1; messageIndex < this.conversation.length; messageIndex++) {
                let message = this.conversation[messageIndex];

                if (message) {
                    // If we already have a divider at this location, we need to remove it. This happens when
                    // the message at the top of the conversation is not the first of that day.
                    if (message.isDivider && this.isSameDay(message.date, lastDivider.date)) {
                        this.conversation.splice(messageIndex, 1);
                    }
                    // A divider has already been placed at this spot, there is no need to add another one
                    else if (message.isDivider) {
                        lastDivider = message;
                    }
                    // skip notes and dispositions container
                    else if (message.isNotesAndDisps) {
                        continue;
                    }
                    // If the next message is on a later day, we want to place a divider between them
                    else if (this.isLaterDay(lastDivider.date, message.date)) {
                        let newDateString = message.date.toLocaleDateString();

                        let dividerMessage = {
                            key: message.key,
                            date: message.date,
                            dateString: newDateString,
                            isDivider: true,
                            isNotesAndDisps: false,
                        };

                        this.conversation.splice(messageIndex, 0, dividerMessage);

                        lastDivider = dividerMessage;
                    }

                    let nextMsgIndex = messageIndex + 1;
                    if (nextMsgIndex < this.conversation.length) {
                        let nextMessage = this.conversation[nextMsgIndex];
                        if (
                            message.originalMessage &&
                            "interactionid" in message.originalMessage &&
                            nextMessage.originalMessage &&
                            "interactionid" in nextMessage.originalMessage &&
                            message.originalMessage.interactionid != nextMessage.originalMessage.interactionid &&
                            "nd_data" in message.originalMessage
                        ) {
                            let dividerMessage = {
                                key: message.key,
                                data: {
                                    interactionid: message.originalMessage.interactionid,
                                    nd_data: message.originalMessage.nd_data,
                                },
                                isDivider: false,
                                isNotesAndDisps: true,
                            };

                            messageIndex += 1;
                            this.conversation.splice(messageIndex, 0, dividerMessage);
                        }
                    }
                }
            }

            let lastMessage = this.conversation[this.conversation.length - 1];
            if (!lastMessage.isNotesAndDisps && "nd_data" in lastMessage.originalMessage) {
                let dividerMessage = {
                    key: lastMessage.key,
                    data: {
                        interactionid: lastMessage.originalMessage.interactionid,
                        nd_data: lastMessage.originalMessage.nd_data,
                    },
                    isDivider: false,
                    isNotesAndDisps: true,
                };

                this.conversation.push(dividerMessage);
            }

            return;
        },
        isLaterDay(date1, date2) {
            if (!date1 || !date2) {
                return false;
            }

            return (
                date2.getDate() > date1.getDate() ||
                date2.getMonth() > date1.getMonth() ||
                date2.getFullYear() > date1.getFullYear()
            );
        },
        isSameDay(date1, date2) {
            return (
                date2.getDate() == date1.getDate() &&
                date2.getMonth() == date1.getMonth() &&
                date2.getFullYear() == date1.getFullYear()
            );
        },
        scrollBottom: debounce(function() {
            Vue.nextTick(() => {
                if (this.scrollPosition) {
                    this.$refs.scrollArea.scrollTop = this.$refs.scrollArea.scrollHeight - this.scrollPosition;
                } else {
                    this.$refs.scrollArea.scrollTop = this.$refs.scrollArea.scrollHeight;
                }
            }, this);
        }, 50),
        setDateDisplay() {
            // Get the container and all of its divider children.
            let messagesContainer = this.$refs.scrollArea;
            let dateDividers = document.querySelectorAll(".date-divider");
            if (messagesContainer && dateDividers.length) {
                // Keeping track of the current divider. When we find the first element that is in the scrollable window, we'll set the content
                // of the top divider.
                let currentDivider;
                dateDividers.forEach((divider) => {
                    divider = divider.parentElement;
                    if (messagesContainer.scrollTop + messagesContainer.offsetTop >= divider.offsetTop) {
                        currentDivider = divider;
                    }
                });

                // We found the top divider. Make it visible and set its contents.
                if (currentDivider) {
                    this.topDateText = currentDivider.innerText;
                } else {
                    this.topDateText = "";
                }
            }
        },
        // Event handlers
        handleScroll: debounce(function(e) {
            const scrollAreaRef = this.$refs.scrollArea;

            if (scrollAreaRef.scrollTop === 0) {
                this.loadMoreMessages();
            }

            this.setDateDisplay();

            return;
        }, 50),
        handleFinishMsgRender(e) {
            this.renderedCount += 1;

            if (this.renderedCount >= this.messageCount) {
                Vue.nextTick(() => this.scrollBottom(), this);
            }
        },

        AddLine(message) {
            this.addMessageLines([message], 'bottom');
        },
        Clear() {
            this.conversation = [];
        },
        ScrollBottom() {

        },
    },
    render(h) {
        try {
            if (!this.todisplay) return null;
            // generate items of controls

            const elements = this.conversation.map((item, itemIndex) => {
                // date divider
                if (item.isDivider) {
                    return (
                        <div>
                            <v-divider style={{ 'border-color': 'white' }}></v-divider>
                            <div class="message-container date-divider">{item.dateString}</div>
                        </div>
                    );
                }

                // notes and disps divider
                else if (item.isNotesAndDisps) {
                    let items = utils.generateItemsFromArray(h, this, this.controlData.NotesAndDispsControlTemplate, item.data, 'VerticalLayout');
                    return <div class="d-flex flex-column flex-grow-1 notes-disps-container">{items}</div>;
                }

                // messages
                else {
                    const messages = item.messageGroup.map((message, msgIndex) => {
                        const controls = utils.generateItemsFromArray(
                            h,
                            this,
                            this.messageItemControlTemplate,
                            message,
                            "InfiniteConversationView",
                            { "finished-render": (e) => this.handleFinishMsgRender(e) }
                        );

                        return (
                            <div
                                key={itemIndex + "_" + msgIndex}
                                class={{
                                    "d-flex": true,
                                    "flex-column": true,
                                    "align-end": item.direction && item.direction.toLowerCase() === "outbound",
                                    "align-start": item.direction && item.direction.toLowerCase() !== "outbound",
                                }}
                                style="alert-primary"
                            >
                                <div class={{"d-flex align-center" : true,
                                            "justify-end": item.direction && item.direction.toLowerCase() === "outbound",
                                            "justify-start": item.direction && item.direction.toLowerCase() !== "outbound",
                                            "flex-row-reverse": item.direction && item.direction.toLowerCase() !== "outbound",
                                    }}>
                                    <small class="ma-2">{item.timeString}</small>
                                    <div class="d-flex">
                                        {controls}
                                    </div>
                                </div>
                                <small>
                                    {item.originalMessage.channeltype}
                                </small>
                            </div>
                        );
                    });

                    return <div key={itemIndex}>{messages}</div>;
                }
            });

            return (
                <div
                    style={this.styles}
                    class={{
                        "c-InfiniteConversationView": true,
                        [`c-name-${this.name || "unnamed"}`]: true,
                        CareInfiniteConversationView: true,
                        'pa-5': true
                    }}
                    ref="scrollArea"
                    on-scroll={this.handleScroll}
                >
                    {/* sticky top date */}
                    <div v-show={this.topDateText} class="date-container sticky-top">
                        <div class="hovering-date">{this.topDateText}</div>
                    </div>

                    {/* messages */}
                    <div>{elements}</div>
                </div>
            );
        } catch (e) {
            utils.error("InfiniteConversationView Render failed", e);
            return <div>InfiniteConversationView Failed to Render {e}</div>;
        }
    },
});
