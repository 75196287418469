import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import './css/BasicButton.css';
import token from '@/Services/token';

Vue.component('sound-player', {
    mixins: [BaseComponent],
    data: function () {
        return {
            State: 'Stopped',
        }
    },
    //Mounted Replaced with preRenderComplete
    computed: {
    },
    methods: {
        preRenderComplete() {
            this.finishRenderHandler(this);
        },
        PlaySound(fn) {
            let src;
            if (fn.startsWith('http'))
                src = fn;
            else 
                src = new URL(fn, token.GetApiBaseURL()).href;
            
            const this_ref = this;

            fetch(src, {
                method: "GET",
                mode: "cors",
                credentials: "include",
                headers: {
                    "access_token": token.AccessToken()
                }
            })
                .then((response) => response.blob())
                .then((audioBlob) => {
                    this.blobUrl = URL.createObjectURL(audioBlob);
                    this.audio = new Audio();
                    this.audio.crossOrigin = "use-credentials";
                    this.audio.src = this.blobUrl;
                    this.audio.addEventListener("canplaythrough", event => {
                        this_ref.State = 'Playing';
                    });
                    this.audio.addEventListener("ended", function () {
                        this_ref.State = 'Stopped';
                    });
                    this.State = 'Loading';
                    this.audio.play();
                });           
        },
        Stop() {
            if (this.audio) {
                this.audio.pause();
                delete this.audio;
                URL.revokeObjectURL(this.blobUrl);
            }
            this.State = 'Stopped';
        }
    },
    props: {
    },
    render(h) {
        return null;
    }
});