<template class="c-ToolPanel">
    <div class="c-ToolPanel_1 mx-auto w-100 pa-0" style="width: 100%; display: flex; flex-direction: column; max-width: 750px;" v-show="isvisible">
        <div style="overflow: auto; width: 100%; display: flex; flex-direction: column; flex-basis: 0; flex-grow: 1;">
            <v-expansion-panels accordion mandatory v-model="selectedPanel">

                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <span>
                            <v-icon>mdi-database-settings</v-icon>
                            Report Fields
                        </span>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                        <ToolReportFields :isvisible="true"
                                          :column_list="column_list"
                                          :datetime_interval="datetime_interval"
                                          :openadvancedsettings="openadvancedsettings"
                                          :notifychange="notifyreportchange">
                        </ToolReportFields>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <span>
                            <v-icon :color="Object.keys(column_filters).length > 0 ? 'primary' : ''">mdi-filter</v-icon>
                            Data Filters
                        </span>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                        <ToolReportFilters :isvisible="true"
                                           :column_list="column_list"
                                           :column_filters="column_filters"
                                           :notifychange="notifyreportchange"
                                           @filterschanged="filerschanged">
                        </ToolReportFilters>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <span>
                            <v-icon>mdi-chart-bar</v-icon>
                            Chart Options
                        </span>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                        <ToolReportChart :isvisible="true"
                                         :column_list="column_list"
                                         :chartOptions="preparedChartOptions"
                                         :legendFormatter="legendFormatter"
                                         :notifychange="notifychartchange"
                                         :notifyrefresh="notifychartrefresh">
                        </ToolReportChart>
                    </v-expansion-panel-content>
                </v-expansion-panel>

            </v-expansion-panels>
        </div>
    </div>
</template>

<script>
    /* eslint-disable */

    import Vue from "vue";
    import utils from '@/Shared/utils.jsx';
    import common from './common.js';

    import ToolReportFields  from './agGridToolReportFields.vue';
    import ToolReportFilters from './agGridToolReportFilters.vue';
    import ToolReportChart   from './agGridToolReportChart.vue';

    export default {
        components: {
            ToolReportFields,
            ToolReportFilters,
            ToolReportChart,
        },
        props: {
            column_list: {
                type: Array
            },
            column_filters: {
                type: Object
            },
            isvisible: null,
            datetime_interval: null,
            openadvancedsettings: null,
            preparedChartOptions: null,
            legendFormatter: null,
            notifyreportchange: null,
            notifychartchange: null,
            notifychartrefresh: null,
        },
        data: function () {
            return {
                selectedPanel: 0,
            }
        },
        watch: {
        },
        created() {
        },
        mounted() {
        },
        computed: {
        },
        methods: {
            filerschanged(filters) {
                this.$emit('filterschanged', filters);
            }
        }
    }
</script>

<style scoped>
    .columns-three {
        column-count: 3;
    }

    .verydense {
        max-height: 24px;
        min-height: 24px;
    }

    .mediumdense {
        max-height: 28px;
        min-height: 28px;
    }
</style>