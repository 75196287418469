const WINDOWS_TO_IANA = [
    {
        windowsName: 'Dateline Standard Time',
        territory: '001',
        iana: ['Etc/GMT+12'],
    },
    {
        windowsName: 'Dateline Standard Time',
        territory: 'ZZ',
        iana: ['Etc/GMT+12'],
    },
    {
        windowsName: 'UTC-11',
        territory: '001',
        iana: ['Etc/GMT+11'],
    },
    {
        windowsName: 'UTC-11',
        territory: 'AS',
        iana: ['Pacific/Pago_Pago'],
    },
    {
        windowsName: 'UTC-11',
        territory: 'NU',
        iana: ['Pacific/Niue'],
    },
    {
        windowsName: 'UTC-11',
        territory: 'UM',
        iana: ['Pacific/Midway'],
    },
    {
        windowsName: 'UTC-11',
        territory: 'ZZ',
        iana: ['Etc/GMT+11'],
    },
    {
        windowsName: 'Aleutian Standard Time',
        territory: '001',
        iana: ['America/Adak'],
    },
    {
        windowsName: 'Aleutian Standard Time',
        territory: 'US',
        iana: ['America/Adak'],
    },
    {
        windowsName: 'Hawaiian Standard Time',
        territory: '001',
        iana: ['Pacific/Honolulu'],
    },
    {
        windowsName: 'Hawaiian Standard Time',
        territory: 'CK',
        iana: ['Pacific/Rarotonga'],
    },
    {
        windowsName: 'Hawaiian Standard Time',
        territory: 'PF',
        iana: ['Pacific/Tahiti'],
    },
    {
        windowsName: 'Hawaiian Standard Time',
        territory: 'UM',
        iana: ['Pacific/Johnston'],
    },
    {
        windowsName: 'Hawaiian Standard Time',
        territory: 'US',
        iana: ['Pacific/Honolulu'],
    },
    {
        windowsName: 'Hawaiian Standard Time',
        territory: 'ZZ',
        iana: ['Etc/GMT+10'],
    },
    {
        windowsName: 'Marquesas Standard Time',
        territory: '001',
        iana: ['Pacific/Marquesas'],
    },
    {
        windowsName: 'Marquesas Standard Time',
        territory: 'PF',
        iana: ['Pacific/Marquesas'],
    },
    {
        windowsName: 'Alaskan Standard Time',
        territory: '001',
        iana: ['America/Anchorage'],
    },
    {
        windowsName: 'Alaskan Standard Time',
        territory: 'US',
        iana: [
            'America/Anchorage',
            'America/Juneau',
            'America/Metlakatla',
            'America/Nome',
            'America/Sitka',
            'America/Yakutat',
        ],
    },
    {
        windowsName: 'UTC-09',
        territory: '001',
        iana: ['Etc/GMT+9'],
    },
    {
        windowsName: 'UTC-09',
        territory: 'PF',
        iana: ['Pacific/Gambier'],
    },
    {
        windowsName: 'UTC-09',
        territory: 'ZZ',
        iana: ['Etc/GMT+9'],
    },
    {
        windowsName: 'Pacific Standard Time (Mexico)',
        territory: '001',
        iana: ['America/Tijuana'],
    },
    {
        windowsName: 'Pacific Standard Time (Mexico)',
        territory: 'MX',
        iana: ['America/Tijuana', 'America/Santa_Isabel'],
    },
    {
        windowsName: 'UTC-08',
        territory: '001',
        iana: ['Etc/GMT+8'],
    },
    {
        windowsName: 'UTC-08',
        territory: 'PN',
        iana: ['Pacific/Pitcairn'],
    },
    {
        windowsName: 'UTC-08',
        territory: 'ZZ',
        iana: ['Etc/GMT+8'],
    },
    {
        windowsName: 'Pacific Standard Time',
        territory: '001',
        iana: ['America/Los_Angeles'],
    },
    {
        windowsName: 'Pacific Standard Time',
        territory: 'CA',
        iana: ['America/Vancouver'],
    },
    {
        windowsName: 'Pacific Standard Time',
        territory: 'US',
        iana: ['America/Los_Angeles'],
    },
    {
        windowsName: 'Pacific Standard Time',
        territory: 'ZZ',
        iana: ['PST8PDT'],
    },
    {
        windowsName: 'US Mountain Standard Time',
        territory: '001',
        iana: ['America/Phoenix'],
    },
    {
        windowsName: 'US Mountain Standard Time',
        territory: 'CA',
        iana: ['America/Creston', 'America/Dawson_Creek', 'America/Fort_Nelson'],
    },
    {
        windowsName: 'US Mountain Standard Time',
        territory: 'MX',
        iana: ['America/Hermosillo'],
    },
    {
        windowsName: 'US Mountain Standard Time',
        territory: 'US',
        iana: ['America/Phoenix'],
    },
    {
        windowsName: 'US Mountain Standard Time',
        territory: 'ZZ',
        iana: ['Etc/GMT+7'],
    },
    {
        windowsName: 'Mountain Standard Time (Mexico)',
        territory: '001',
        iana: ['America/Chihuahua'],
    },
    {
        windowsName: 'Mountain Standard Time (Mexico)',
        territory: 'MX',
        iana: ['America/Chihuahua', 'America/Mazatlan'],
    },
    {
        windowsName: 'Mountain Standard Time',
        territory: '001',
        iana: ['America/Denver'],
    },
    {
        windowsName: 'Mountain Standard Time',
        territory: 'CA',
        iana: ['America/Edmonton', 'America/Cambridge_Bay', 'America/Inuvik', 'America/Yellowknife'],
    },
    {
        windowsName: 'Mountain Standard Time',
        territory: 'MX',
        iana: ['America/Ojinaga'],
    },
    {
        windowsName: 'Mountain Standard Time',
        territory: 'US',
        iana: ['America/Denver', 'America/Boise'],
    },
    {
        windowsName: 'Mountain Standard Time',
        territory: 'ZZ',
        iana: ['MST7MDT'],
    },
    {
        windowsName: 'Yukon Standard Time',
        territory: '001',
        iana: ['America/Whitehorse'],
    },
    {
        windowsName: 'Yukon Standard Time',
        territory: 'CA',
        iana: ['America/Whitehorse', 'America/Dawson'],
    },
    {
        windowsName: 'Central America Standard Time',
        territory: '001',
        iana: ['America/Guatemala'],
    },
    {
        windowsName: 'Central America Standard Time',
        territory: 'BZ',
        iana: ['America/Belize'],
    },
    {
        windowsName: 'Central America Standard Time',
        territory: 'CR',
        iana: ['America/Costa_Rica'],
    },
    {
        windowsName: 'Central America Standard Time',
        territory: 'EC',
        iana: ['Pacific/Galapagos'],
    },
    {
        windowsName: 'Central America Standard Time',
        territory: 'GT',
        iana: ['America/Guatemala'],
    },
    {
        windowsName: 'Central America Standard Time',
        territory: 'HN',
        iana: ['America/Tegucigalpa'],
    },
    {
        windowsName: 'Central America Standard Time',
        territory: 'NI',
        iana: ['America/Managua'],
    },
    {
        windowsName: 'Central America Standard Time',
        territory: 'SV',
        iana: ['America/El_Salvador'],
    },
    {
        windowsName: 'Central America Standard Time',
        territory: 'ZZ',
        iana: ['Etc/GMT+6'],
    },
    {
        windowsName: 'Central Standard Time',
        territory: '001',
        iana: ['America/Chicago'],
    },
    {
        windowsName: 'Central Standard Time',
        territory: 'CA',
        iana: ['America/Winnipeg', 'America/Rainy_River', 'America/Rankin_Inlet', 'America/Resolute'],
    },
    {
        windowsName: 'Central Standard Time',
        territory: 'MX',
        iana: ['America/Matamoros'],
    },
    {
        windowsName: 'Central Standard Time',
        territory: 'US',
        iana: [
            'America/Chicago',
            'America/Indiana/Knox',
            'America/Indiana/Tell_City',
            'America/Menominee',
            'America/North_Dakota/Beulah',
            'America/North_Dakota/Center',
            'America/North_Dakota/New_Salem',
        ],
    },
    {
        windowsName: 'Central Standard Time',
        territory: 'ZZ',
        iana: ['CST6CDT'],
    },
    {
        windowsName: 'Easter Island Standard Time',
        territory: '001',
        iana: ['Pacific/Easter'],
    },
    {
        windowsName: 'Easter Island Standard Time',
        territory: 'CL',
        iana: ['Pacific/Easter'],
    },
    {
        windowsName: 'Central Standard Time (Mexico)',
        territory: '001',
        iana: ['America/Mexico_City'],
    },
    {
        windowsName: 'Central Standard Time (Mexico)',
        territory: 'MX',
        iana: ['America/Mexico_City', 'America/Bahia_Banderas', 'America/Merida', 'America/Monterrey'],
    },
    {
        windowsName: 'Canada Central Standard Time',
        territory: '001',
        iana: ['America/Regina'],
    },
    {
        windowsName: 'Canada Central Standard Time',
        territory: 'CA',
        iana: ['America/Regina', 'America/Swift_Current'],
    },
    {
        windowsName: 'SA Pacific Standard Time',
        territory: '001',
        iana: ['America/Bogota'],
    },
    {
        windowsName: 'SA Pacific Standard Time',
        territory: 'BR',
        iana: ['America/Rio_Branco', 'America/Eirunepe'],
    },
    {
        windowsName: 'SA Pacific Standard Time',
        territory: 'CA',
        iana: ['America/Coral_Harbour'],
    },
    {
        windowsName: 'SA Pacific Standard Time',
        territory: 'CO',
        iana: ['America/Bogota'],
    },
    {
        windowsName: 'SA Pacific Standard Time',
        territory: 'EC',
        iana: ['America/Guayaquil'],
    },
    {
        windowsName: 'SA Pacific Standard Time',
        territory: 'JM',
        iana: ['America/Jamaica'],
    },
    {
        windowsName: 'SA Pacific Standard Time',
        territory: 'KY',
        iana: ['America/Cayman'],
    },
    {
        windowsName: 'SA Pacific Standard Time',
        territory: 'PA',
        iana: ['America/Panama'],
    },
    {
        windowsName: 'SA Pacific Standard Time',
        territory: 'PE',
        iana: ['America/Lima'],
    },
    {
        windowsName: 'SA Pacific Standard Time',
        territory: 'ZZ',
        iana: ['Etc/GMT+5'],
    },
    {
        windowsName: 'Eastern Standard Time (Mexico)',
        territory: '001',
        iana: ['America/Cancun'],
    },
    {
        windowsName: 'Eastern Standard Time (Mexico)',
        territory: 'MX',
        iana: ['America/Cancun'],
    },
    {
        windowsName: 'Eastern Standard Time',
        territory: '001',
        iana: ['America/New_York'],
    },
    {
        windowsName: 'Eastern Standard Time',
        territory: 'BS',
        iana: ['America/Nassau'],
    },
    {
        windowsName: 'Eastern Standard Time',
        territory: 'CA',
        iana: [
            'America/Toronto',
            'America/Iqaluit',
            'America/Montreal',
            'America/Nipigon',
            'America/Pangnirtung',
            'America/Thunder_Bay',
        ],
    },
    {
        windowsName: 'Eastern Standard Time',
        territory: 'US',
        iana: [
            'America/New_York',
            'America/Detroit',
            'America/Indiana/Petersburg',
            'America/Indiana/Vincennes',
            'America/Indiana/Winamac',
            'America/Kentucky/Monticello',
            'America/Louisville',
        ],
    },
    {
        windowsName: 'Eastern Standard Time',
        territory: 'ZZ',
        iana: ['EST5EDT'],
    },
    {
        windowsName: 'Haiti Standard Time',
        territory: '001',
        iana: ['America/Port-au-Prince'],
    },
    {
        windowsName: 'Haiti Standard Time',
        territory: 'HT',
        iana: ['America/Port-au-Prince'],
    },
    {
        windowsName: 'Cuba Standard Time',
        territory: '001',
        iana: ['America/Havana'],
    },
    {
        windowsName: 'Cuba Standard Time',
        territory: 'CU',
        iana: ['America/Havana'],
    },
    {
        windowsName: 'US Eastern Standard Time',
        territory: '001',
        iana: ['America/Indianapolis'],
    },
    {
        windowsName: 'US Eastern Standard Time',
        territory: 'US',
        iana: ['America/Indianapolis', 'America/Indiana/Marengo', 'America/Indiana/Vevay'],
    },
    {
        windowsName: 'Turks And Caicos Standard Time',
        territory: '001',
        iana: ['America/Grand_Turk'],
    },
    {
        windowsName: 'Turks And Caicos Standard Time',
        territory: 'TC',
        iana: ['America/Grand_Turk'],
    },
    {
        windowsName: 'Paraguay Standard Time',
        territory: '001',
        iana: ['America/Asuncion'],
    },
    {
        windowsName: 'Paraguay Standard Time',
        territory: 'PY',
        iana: ['America/Asuncion'],
    },
    {
        windowsName: 'Atlantic Standard Time',
        territory: '001',
        iana: ['America/Halifax'],
    },
    {
        windowsName: 'Atlantic Standard Time',
        territory: 'BM',
        iana: ['Atlantic/Bermuda'],
    },
    {
        windowsName: 'Atlantic Standard Time',
        territory: 'CA',
        iana: ['America/Halifax', 'America/Glace_Bay', 'America/Goose_Bay', 'America/Moncton'],
    },
    {
        windowsName: 'Atlantic Standard Time',
        territory: 'GL',
        iana: ['America/Thule'],
    },
    {
        windowsName: 'Venezuela Standard Time',
        territory: '001',
        iana: ['America/Caracas'],
    },
    {
        windowsName: 'Venezuela Standard Time',
        territory: 'VE',
        iana: ['America/Caracas'],
    },
    {
        windowsName: 'Central Brazilian Standard Time',
        territory: '001',
        iana: ['America/Cuiaba'],
    },
    {
        windowsName: 'Central Brazilian Standard Time',
        territory: 'BR',
        iana: ['America/Cuiaba', 'America/Campo_Grande'],
    },
    {
        windowsName: 'SA Western Standard Time',
        territory: '001',
        iana: ['America/La_Paz'],
    },
    {
        windowsName: 'SA Western Standard Time',
        territory: 'AG',
        iana: ['America/Antigua'],
    },
    {
        windowsName: 'SA Western Standard Time',
        territory: 'AI',
        iana: ['America/Anguilla'],
    },
    {
        windowsName: 'SA Western Standard Time',
        territory: 'AW',
        iana: ['America/Aruba'],
    },
    {
        windowsName: 'SA Western Standard Time',
        territory: 'BB',
        iana: ['America/Barbados'],
    },
    {
        windowsName: 'SA Western Standard Time',
        territory: 'BL',
        iana: ['America/St_Barthelemy'],
    },
    {
        windowsName: 'SA Western Standard Time',
        territory: 'BO',
        iana: ['America/La_Paz'],
    },
    {
        windowsName: 'SA Western Standard Time',
        territory: 'BQ',
        iana: ['America/Kralendijk'],
    },
    {
        windowsName: 'SA Western Standard Time',
        territory: 'BR',
        iana: ['America/Manaus', 'America/Boa_Vista', 'America/Porto_Velho'],
    },
    {
        windowsName: 'SA Western Standard Time',
        territory: 'CA',
        iana: ['America/Blanc-Sablon'],
    },
    {
        windowsName: 'SA Western Standard Time',
        territory: 'CW',
        iana: ['America/Curacao'],
    },
    {
        windowsName: 'SA Western Standard Time',
        territory: 'DM',
        iana: ['America/Dominica'],
    },
    {
        windowsName: 'SA Western Standard Time',
        territory: 'DO',
        iana: ['America/Santo_Domingo'],
    },
    {
        windowsName: 'SA Western Standard Time',
        territory: 'GD',
        iana: ['America/Grenada'],
    },
    {
        windowsName: 'SA Western Standard Time',
        territory: 'GP',
        iana: ['America/Guadeloupe'],
    },
    {
        windowsName: 'SA Western Standard Time',
        territory: 'GY',
        iana: ['America/Guyana'],
    },
    {
        windowsName: 'SA Western Standard Time',
        territory: 'KN',
        iana: ['America/St_Kitts'],
    },
    {
        windowsName: 'SA Western Standard Time',
        territory: 'LC',
        iana: ['America/St_Lucia'],
    },
    {
        windowsName: 'SA Western Standard Time',
        territory: 'MF',
        iana: ['America/Marigot'],
    },
    {
        windowsName: 'SA Western Standard Time',
        territory: 'MQ',
        iana: ['America/Martinique'],
    },
    {
        windowsName: 'SA Western Standard Time',
        territory: 'MS',
        iana: ['America/Montserrat'],
    },
    {
        windowsName: 'SA Western Standard Time',
        territory: 'PR',
        iana: ['America/Puerto_Rico'],
    },
    {
        windowsName: 'SA Western Standard Time',
        territory: 'SX',
        iana: ['America/Lower_Princes'],
    },
    {
        windowsName: 'SA Western Standard Time',
        territory: 'TT',
        iana: ['America/Port_of_Spain'],
    },
    {
        windowsName: 'SA Western Standard Time',
        territory: 'VC',
        iana: ['America/St_Vincent'],
    },
    {
        windowsName: 'SA Western Standard Time',
        territory: 'VG',
        iana: ['America/Tortola'],
    },
    {
        windowsName: 'SA Western Standard Time',
        territory: 'VI',
        iana: ['America/St_Thomas'],
    },
    {
        windowsName: 'SA Western Standard Time',
        territory: 'ZZ',
        iana: ['Etc/GMT+4'],
    },
    {
        windowsName: 'Pacific SA Standard Time',
        territory: '001',
        iana: ['America/Santiago'],
    },
    {
        windowsName: 'Pacific SA Standard Time',
        territory: 'CL',
        iana: ['America/Santiago'],
    },
    {
        windowsName: 'Newfoundland Standard Time',
        territory: '001',
        iana: ['America/St_Johns'],
    },
    {
        windowsName: 'Newfoundland Standard Time',
        territory: 'CA',
        iana: ['America/St_Johns'],
    },
    {
        windowsName: 'Tocantins Standard Time',
        territory: '001',
        iana: ['America/Araguaina'],
    },
    {
        windowsName: 'Tocantins Standard Time',
        territory: 'BR',
        iana: ['America/Araguaina'],
    },
    {
        windowsName: 'E. South America Standard Time',
        territory: '001',
        iana: ['America/Sao_Paulo'],
    },
    {
        windowsName: 'E. South America Standard Time',
        territory: 'BR',
        iana: ['America/Sao_Paulo'],
    },
    {
        windowsName: 'SA Eastern Standard Time',
        territory: '001',
        iana: ['America/Cayenne'],
    },
    {
        windowsName: 'SA Eastern Standard Time',
        territory: 'AQ',
        iana: ['Antarctica/Rothera', 'Antarctica/Palmer'],
    },
    {
        windowsName: 'SA Eastern Standard Time',
        territory: 'BR',
        iana: [
            'America/Fortaleza',
            'America/Belem',
            'America/Maceio',
            'America/Recife',
            'America/Santarem',
        ],
    },
    {
        windowsName: 'SA Eastern Standard Time',
        territory: 'FK',
        iana: ['Atlantic/Stanley'],
    },
    {
        windowsName: 'SA Eastern Standard Time',
        territory: 'GF',
        iana: ['America/Cayenne'],
    },
    {
        windowsName: 'SA Eastern Standard Time',
        territory: 'SR',
        iana: ['America/Paramaribo'],
    },
    {
        windowsName: 'SA Eastern Standard Time',
        territory: 'ZZ',
        iana: ['Etc/GMT+3'],
    },
    {
        windowsName: 'Argentina Standard Time',
        territory: '001',
        iana: ['America/Buenos_Aires'],
    },
    {
        windowsName: 'Argentina Standard Time',
        territory: 'AR',
        iana: [
            'America/Buenos_Aires',
            'America/Argentina/La_Rioja',
            'America/Argentina/Rio_Gallegos',
            'America/Argentina/Salta',
            'America/Argentina/San_Juan',
            'America/Argentina/San_Luis',
            'America/Argentina/Tucuman',
            'America/Argentina/Ushuaia',
            'America/Catamarca',
            'America/Cordoba',
            'America/Jujuy',
            'America/Mendoza',
        ],
    },
    {
        windowsName: 'Greenland Standard Time',
        territory: '001',
        iana: ['America/Godthab'],
    },
    {
        windowsName: 'Greenland Standard Time',
        territory: 'GL',
        iana: ['America/Godthab'],
    },
    {
        windowsName: 'Montevideo Standard Time',
        territory: '001',
        iana: ['America/Montevideo'],
    },
    {
        windowsName: 'Montevideo Standard Time',
        territory: 'UY',
        iana: ['America/Montevideo'],
    },
    {
        windowsName: 'Magallanes Standard Time',
        territory: '001',
        iana: ['America/Punta_Arenas'],
    },
    {
        windowsName: 'Magallanes Standard Time',
        territory: 'CL',
        iana: ['America/Punta_Arenas'],
    },
    {
        windowsName: 'Saint Pierre Standard Time',
        territory: '001',
        iana: ['America/Miquelon'],
    },
    {
        windowsName: 'Saint Pierre Standard Time',
        territory: 'PM',
        iana: ['America/Miquelon'],
    },
    {
        windowsName: 'Bahia Standard Time',
        territory: '001',
        iana: ['America/Bahia'],
    },
    {
        windowsName: 'Bahia Standard Time',
        territory: 'BR',
        iana: ['America/Bahia'],
    },
    {
        windowsName: 'UTC-02',
        territory: '001',
        iana: ['Etc/GMT+2'],
    },
    {
        windowsName: 'UTC-02',
        territory: 'BR',
        iana: ['America/Noronha'],
    },
    {
        windowsName: 'UTC-02',
        territory: 'GS',
        iana: ['Atlantic/South_Georgia'],
    },
    {
        windowsName: 'UTC-02',
        territory: 'ZZ',
        iana: ['Etc/GMT+2'],
    },
    {
        windowsName: 'Azores Standard Time',
        territory: '001',
        iana: ['Atlantic/Azores'],
    },
    {
        windowsName: 'Azores Standard Time',
        territory: 'GL',
        iana: ['America/Scoresbysund'],
    },
    {
        windowsName: 'Azores Standard Time',
        territory: 'PT',
        iana: ['Atlantic/Azores'],
    },
    {
        windowsName: 'Cape Verde Standard Time',
        territory: '001',
        iana: ['Atlantic/Cape_Verde'],
    },
    {
        windowsName: 'Cape Verde Standard Time',
        territory: 'CV',
        iana: ['Atlantic/Cape_Verde'],
    },
    {
        windowsName: 'Cape Verde Standard Time',
        territory: 'ZZ',
        iana: ['Etc/GMT+1'],
    },
    {
        windowsName: 'UTC',
        territory: '001',
        iana: ['Etc/UTC'],
    },
    {
        windowsName: 'UTC',
        territory: 'ZZ',
        iana: ['Etc/UTC', 'Etc/GMT'],
    },
    {
        windowsName: 'GMT Standard Time',
        territory: '001',
        iana: ['Europe/London'],
    },
    {
        windowsName: 'GMT Standard Time',
        territory: 'ES',
        iana: ['Atlantic/Canary'],
    },
    {
        windowsName: 'GMT Standard Time',
        territory: 'FO',
        iana: ['Atlantic/Faeroe'],
    },
    {
        windowsName: 'GMT Standard Time',
        territory: 'GB',
        iana: ['Europe/London'],
    },
    {
        windowsName: 'GMT Standard Time',
        territory: 'GG',
        iana: ['Europe/Guernsey'],
    },
    {
        windowsName: 'GMT Standard Time',
        territory: 'IE',
        iana: ['Europe/Dublin'],
    },
    {
        windowsName: 'GMT Standard Time',
        territory: 'IM',
        iana: ['Europe/Isle_of_Man'],
    },
    {
        windowsName: 'GMT Standard Time',
        territory: 'JE',
        iana: ['Europe/Jersey'],
    },
    {
        windowsName: 'GMT Standard Time',
        territory: 'PT',
        iana: ['Europe/Lisbon', 'Atlantic/Madeira'],
    },
    {
        windowsName: 'Greenwich Standard Time',
        territory: '001',
        iana: ['Atlantic/Reykjavik'],
    },
    {
        windowsName: 'Greenwich Standard Time',
        territory: 'BF',
        iana: ['Africa/Ouagadougou'],
    },
    {
        windowsName: 'Greenwich Standard Time',
        territory: 'CI',
        iana: ['Africa/Abidjan'],
    },
    {
        windowsName: 'Greenwich Standard Time',
        territory: 'GH',
        iana: ['Africa/Accra'],
    },
    {
        windowsName: 'Greenwich Standard Time',
        territory: 'GL',
        iana: ['America/Danmarkshavn'],
    },
    {
        windowsName: 'Greenwich Standard Time',
        territory: 'GM',
        iana: ['Africa/Banjul'],
    },
    {
        windowsName: 'Greenwich Standard Time',
        territory: 'GN',
        iana: ['Africa/Conakry'],
    },
    {
        windowsName: 'Greenwich Standard Time',
        territory: 'GW',
        iana: ['Africa/Bissau'],
    },
    {
        windowsName: 'Greenwich Standard Time',
        territory: 'IS',
        iana: ['Atlantic/Reykjavik'],
    },
    {
        windowsName: 'Greenwich Standard Time',
        territory: 'LR',
        iana: ['Africa/Monrovia'],
    },
    {
        windowsName: 'Greenwich Standard Time',
        territory: 'ML',
        iana: ['Africa/Bamako'],
    },
    {
        windowsName: 'Greenwich Standard Time',
        territory: 'MR',
        iana: ['Africa/Nouakchott'],
    },
    {
        windowsName: 'Greenwich Standard Time',
        territory: 'SH',
        iana: ['Atlantic/St_Helena'],
    },
    {
        windowsName: 'Greenwich Standard Time',
        territory: 'SL',
        iana: ['Africa/Freetown'],
    },
    {
        windowsName: 'Greenwich Standard Time',
        territory: 'SN',
        iana: ['Africa/Dakar'],
    },
    {
        windowsName: 'Greenwich Standard Time',
        territory: 'TG',
        iana: ['Africa/Lome'],
    },
    {
        windowsName: 'Sao Tome Standard Time',
        territory: '001',
        iana: ['Africa/Sao_Tome'],
    },
    {
        windowsName: 'Sao Tome Standard Time',
        territory: 'ST',
        iana: ['Africa/Sao_Tome'],
    },
    {
        windowsName: 'Morocco Standard Time',
        territory: '001',
        iana: ['Africa/Casablanca'],
    },
    {
        windowsName: 'Morocco Standard Time',
        territory: 'EH',
        iana: ['Africa/El_Aaiun'],
    },
    {
        windowsName: 'Morocco Standard Time',
        territory: 'MA',
        iana: ['Africa/Casablanca'],
    },
    {
        windowsName: 'W. Europe Standard Time',
        territory: '001',
        iana: ['Europe/Berlin'],
    },
    {
        windowsName: 'W. Europe Standard Time',
        territory: 'AD',
        iana: ['Europe/Andorra'],
    },
    {
        windowsName: 'W. Europe Standard Time',
        territory: 'AT',
        iana: ['Europe/Vienna'],
    },
    {
        windowsName: 'W. Europe Standard Time',
        territory: 'CH',
        iana: ['Europe/Zurich'],
    },
    {
        windowsName: 'W. Europe Standard Time',
        territory: 'DE',
        iana: ['Europe/Berlin', 'Europe/Busingen'],
    },
    {
        windowsName: 'W. Europe Standard Time',
        territory: 'GI',
        iana: ['Europe/Gibraltar'],
    },
    {
        windowsName: 'W. Europe Standard Time',
        territory: 'IT',
        iana: ['Europe/Rome'],
    },
    {
        windowsName: 'W. Europe Standard Time',
        territory: 'LI',
        iana: ['Europe/Vaduz'],
    },
    {
        windowsName: 'W. Europe Standard Time',
        territory: 'LU',
        iana: ['Europe/Luxembourg'],
    },
    {
        windowsName: 'W. Europe Standard Time',
        territory: 'MC',
        iana: ['Europe/Monaco'],
    },
    {
        windowsName: 'W. Europe Standard Time',
        territory: 'MT',
        iana: ['Europe/Malta'],
    },
    {
        windowsName: 'W. Europe Standard Time',
        territory: 'NL',
        iana: ['Europe/Amsterdam'],
    },
    {
        windowsName: 'W. Europe Standard Time',
        territory: 'NO',
        iana: ['Europe/Oslo'],
    },
    {
        windowsName: 'W. Europe Standard Time',
        territory: 'SE',
        iana: ['Europe/Stockholm'],
    },
    {
        windowsName: 'W. Europe Standard Time',
        territory: 'SJ',
        iana: ['Arctic/Longyearbyen'],
    },
    {
        windowsName: 'W. Europe Standard Time',
        territory: 'SM',
        iana: ['Europe/San_Marino'],
    },
    {
        windowsName: 'W. Europe Standard Time',
        territory: 'VA',
        iana: ['Europe/Vatican'],
    },
    {
        windowsName: 'Central Europe Standard Time',
        territory: '001',
        iana: ['Europe/Budapest'],
    },
    {
        windowsName: 'Central Europe Standard Time',
        territory: 'AL',
        iana: ['Europe/Tirane'],
    },
    {
        windowsName: 'Central Europe Standard Time',
        territory: 'CZ',
        iana: ['Europe/Prague'],
    },
    {
        windowsName: 'Central Europe Standard Time',
        territory: 'HU',
        iana: ['Europe/Budapest'],
    },
    {
        windowsName: 'Central Europe Standard Time',
        territory: 'ME',
        iana: ['Europe/Podgorica'],
    },
    {
        windowsName: 'Central Europe Standard Time',
        territory: 'RS',
        iana: ['Europe/Belgrade'],
    },
    {
        windowsName: 'Central Europe Standard Time',
        territory: 'SI',
        iana: ['Europe/Ljubljana'],
    },
    {
        windowsName: 'Central Europe Standard Time',
        territory: 'SK',
        iana: ['Europe/Bratislava'],
    },
    {
        windowsName: 'Romance Standard Time',
        territory: '001',
        iana: ['Europe/Paris'],
    },
    {
        windowsName: 'Romance Standard Time',
        territory: 'BE',
        iana: ['Europe/Brussels'],
    },
    {
        windowsName: 'Romance Standard Time',
        territory: 'DK',
        iana: ['Europe/Copenhagen'],
    },
    {
        windowsName: 'Romance Standard Time',
        territory: 'ES',
        iana: ['Europe/Madrid', 'Africa/Ceuta'],
    },
    {
        windowsName: 'Romance Standard Time',
        territory: 'FR',
        iana: ['Europe/Paris'],
    },
    {
        windowsName: 'Central European Standard Time',
        territory: '001',
        iana: ['Europe/Warsaw'],
    },
    {
        windowsName: 'Central European Standard Time',
        territory: 'BA',
        iana: ['Europe/Sarajevo'],
    },
    {
        windowsName: 'Central European Standard Time',
        territory: 'HR',
        iana: ['Europe/Zagreb'],
    },
    {
        windowsName: 'Central European Standard Time',
        territory: 'MK',
        iana: ['Europe/Skopje'],
    },
    {
        windowsName: 'Central European Standard Time',
        territory: 'PL',
        iana: ['Europe/Warsaw'],
    },
    {
        windowsName: 'W. Central Africa Standard Time',
        territory: '001',
        iana: ['Africa/Lagos'],
    },
    {
        windowsName: 'W. Central Africa Standard Time',
        territory: 'AO',
        iana: ['Africa/Luanda'],
    },
    {
        windowsName: 'W. Central Africa Standard Time',
        territory: 'BJ',
        iana: ['Africa/Porto-Novo'],
    },
    {
        windowsName: 'W. Central Africa Standard Time',
        territory: 'CD',
        iana: ['Africa/Kinshasa'],
    },
    {
        windowsName: 'W. Central Africa Standard Time',
        territory: 'CF',
        iana: ['Africa/Bangui'],
    },
    {
        windowsName: 'W. Central Africa Standard Time',
        territory: 'CG',
        iana: ['Africa/Brazzaville'],
    },
    {
        windowsName: 'W. Central Africa Standard Time',
        territory: 'CM',
        iana: ['Africa/Douala'],
    },
    {
        windowsName: 'W. Central Africa Standard Time',
        territory: 'DZ',
        iana: ['Africa/Algiers'],
    },
    {
        windowsName: 'W. Central Africa Standard Time',
        territory: 'GA',
        iana: ['Africa/Libreville'],
    },
    {
        windowsName: 'W. Central Africa Standard Time',
        territory: 'GQ',
        iana: ['Africa/Malabo'],
    },
    {
        windowsName: 'W. Central Africa Standard Time',
        territory: 'NE',
        iana: ['Africa/Niamey'],
    },
    {
        windowsName: 'W. Central Africa Standard Time',
        territory: 'NG',
        iana: ['Africa/Lagos'],
    },
    {
        windowsName: 'W. Central Africa Standard Time',
        territory: 'TD',
        iana: ['Africa/Ndjamena'],
    },
    {
        windowsName: 'W. Central Africa Standard Time',
        territory: 'TN',
        iana: ['Africa/Tunis'],
    },
    {
        windowsName: 'W. Central Africa Standard Time',
        territory: 'ZZ',
        iana: ['Etc/GMT-1'],
    },
    {
        windowsName: 'Jordan Standard Time',
        territory: '001',
        iana: ['Asia/Amman'],
    },
    {
        windowsName: 'Jordan Standard Time',
        territory: 'JO',
        iana: ['Asia/Amman'],
    },
    {
        windowsName: 'GTB Standard Time',
        territory: '001',
        iana: ['Europe/Bucharest'],
    },
    {
        windowsName: 'GTB Standard Time',
        territory: 'CY',
        iana: ['Asia/Nicosia', 'Asia/Famagusta'],
    },
    {
        windowsName: 'GTB Standard Time',
        territory: 'GR',
        iana: ['Europe/Athens'],
    },
    {
        windowsName: 'GTB Standard Time',
        territory: 'RO',
        iana: ['Europe/Bucharest'],
    },
    {
        windowsName: 'Middle East Standard Time',
        territory: '001',
        iana: ['Asia/Beirut'],
    },
    {
        windowsName: 'Middle East Standard Time',
        territory: 'LB',
        iana: ['Asia/Beirut'],
    },
    {
        windowsName: 'Egypt Standard Time',
        territory: '001',
        iana: ['Africa/Cairo'],
    },
    {
        windowsName: 'Egypt Standard Time',
        territory: 'EG',
        iana: ['Africa/Cairo'],
    },
    {
        windowsName: 'E. Europe Standard Time',
        territory: '001',
        iana: ['Europe/Chisinau'],
    },
    {
        windowsName: 'E. Europe Standard Time',
        territory: 'MD',
        iana: ['Europe/Chisinau'],
    },
    {
        windowsName: 'Syria Standard Time',
        territory: '001',
        iana: ['Asia/Damascus'],
    },
    {
        windowsName: 'Syria Standard Time',
        territory: 'SY',
        iana: ['Asia/Damascus'],
    },
    {
        windowsName: 'West Bank Standard Time',
        territory: '001',
        iana: ['Asia/Hebron'],
    },
    {
        windowsName: 'West Bank Standard Time',
        territory: 'PS',
        iana: ['Asia/Hebron', 'Asia/Gaza'],
    },
    {
        windowsName: 'South Africa Standard Time',
        territory: '001',
        iana: ['Africa/Johannesburg'],
    },
    {
        windowsName: 'South Africa Standard Time',
        territory: 'BI',
        iana: ['Africa/Bujumbura'],
    },
    {
        windowsName: 'South Africa Standard Time',
        territory: 'BW',
        iana: ['Africa/Gaborone'],
    },
    {
        windowsName: 'South Africa Standard Time',
        territory: 'CD',
        iana: ['Africa/Lubumbashi'],
    },
    {
        windowsName: 'South Africa Standard Time',
        territory: 'LS',
        iana: ['Africa/Maseru'],
    },
    {
        windowsName: 'South Africa Standard Time',
        territory: 'MW',
        iana: ['Africa/Blantyre'],
    },
    {
        windowsName: 'South Africa Standard Time',
        territory: 'MZ',
        iana: ['Africa/Maputo'],
    },
    {
        windowsName: 'South Africa Standard Time',
        territory: 'RW',
        iana: ['Africa/Kigali'],
    },
    {
        windowsName: 'South Africa Standard Time',
        territory: 'SZ',
        iana: ['Africa/Mbabane'],
    },
    {
        windowsName: 'South Africa Standard Time',
        territory: 'ZA',
        iana: ['Africa/Johannesburg'],
    },
    {
        windowsName: 'South Africa Standard Time',
        territory: 'ZM',
        iana: ['Africa/Lusaka'],
    },
    {
        windowsName: 'South Africa Standard Time',
        territory: 'ZW',
        iana: ['Africa/Harare'],
    },
    {
        windowsName: 'South Africa Standard Time',
        territory: 'ZZ',
        iana: ['Etc/GMT-2'],
    },
    {
        windowsName: 'FLE Standard Time',
        territory: '001',
        iana: ['Europe/Kiev'],
    },
    {
        windowsName: 'FLE Standard Time',
        territory: 'AX',
        iana: ['Europe/Mariehamn'],
    },
    {
        windowsName: 'FLE Standard Time',
        territory: 'BG',
        iana: ['Europe/Sofia'],
    },
    {
        windowsName: 'FLE Standard Time',
        territory: 'EE',
        iana: ['Europe/Tallinn'],
    },
    {
        windowsName: 'FLE Standard Time',
        territory: 'FI',
        iana: ['Europe/Helsinki'],
    },
    {
        windowsName: 'FLE Standard Time',
        territory: 'LT',
        iana: ['Europe/Vilnius'],
    },
    {
        windowsName: 'FLE Standard Time',
        territory: 'LV',
        iana: ['Europe/Riga'],
    },
    {
        windowsName: 'FLE Standard Time',
        territory: 'UA',
        iana: ['Europe/Kiev', 'Europe/Uzhgorod', 'Europe/Zaporozhye'],
    },
    {
        windowsName: 'Israel Standard Time',
        territory: '001',
        iana: ['Asia/Jerusalem'],
    },
    {
        windowsName: 'Israel Standard Time',
        territory: 'IL',
        iana: ['Asia/Jerusalem'],
    },
    {
        windowsName: 'South Sudan Standard Time',
        territory: '001',
        iana: ['Africa/Juba'],
    },
    {
        windowsName: 'South Sudan Standard Time',
        territory: 'SS',
        iana: ['Africa/Juba'],
    },
    {
        windowsName: 'Kaliningrad Standard Time',
        territory: '001',
        iana: ['Europe/Kaliningrad'],
    },
    {
        windowsName: 'Kaliningrad Standard Time',
        territory: 'RU',
        iana: ['Europe/Kaliningrad'],
    },
    {
        windowsName: 'Sudan Standard Time',
        territory: '001',
        iana: ['Africa/Khartoum'],
    },
    {
        windowsName: 'Sudan Standard Time',
        territory: 'SD',
        iana: ['Africa/Khartoum'],
    },
    {
        windowsName: 'Libya Standard Time',
        territory: '001',
        iana: ['Africa/Tripoli'],
    },
    {
        windowsName: 'Libya Standard Time',
        territory: 'LY',
        iana: ['Africa/Tripoli'],
    },
    {
        windowsName: 'Namibia Standard Time',
        territory: '001',
        iana: ['Africa/Windhoek'],
    },
    {
        windowsName: 'Namibia Standard Time',
        territory: 'NA',
        iana: ['Africa/Windhoek'],
    },
    {
        windowsName: 'Arabic Standard Time',
        territory: '001',
        iana: ['Asia/Baghdad'],
    },
    {
        windowsName: 'Arabic Standard Time',
        territory: 'IQ',
        iana: ['Asia/Baghdad'],
    },
    {
        windowsName: 'Turkey Standard Time',
        territory: '001',
        iana: ['Europe/Istanbul'],
    },
    {
        windowsName: 'Turkey Standard Time',
        territory: 'TR',
        iana: ['Europe/Istanbul'],
    },
    {
        windowsName: 'Arab Standard Time',
        territory: '001',
        iana: ['Asia/Riyadh'],
    },
    {
        windowsName: 'Arab Standard Time',
        territory: 'BH',
        iana: ['Asia/Bahrain'],
    },
    {
        windowsName: 'Arab Standard Time',
        territory: 'KW',
        iana: ['Asia/Kuwait'],
    },
    {
        windowsName: 'Arab Standard Time',
        territory: 'QA',
        iana: ['Asia/Qatar'],
    },
    {
        windowsName: 'Arab Standard Time',
        territory: 'SA',
        iana: ['Asia/Riyadh'],
    },
    {
        windowsName: 'Arab Standard Time',
        territory: 'YE',
        iana: ['Asia/Aden'],
    },
    {
        windowsName: 'Belarus Standard Time',
        territory: '001',
        iana: ['Europe/Minsk'],
    },
    {
        windowsName: 'Belarus Standard Time',
        territory: 'BY',
        iana: ['Europe/Minsk'],
    },
    {
        windowsName: 'Russian Standard Time',
        territory: '001',
        iana: ['Europe/Moscow'],
    },
    {
        windowsName: 'Russian Standard Time',
        territory: 'RU',
        iana: ['Europe/Moscow', 'Europe/Kirov'],
    },
    {
        windowsName: 'Russian Standard Time',
        territory: 'UA',
        iana: ['Europe/Simferopol'],
    },
    {
        windowsName: 'E. Africa Standard Time',
        territory: '001',
        iana: ['Africa/Nairobi'],
    },
    {
        windowsName: 'E. Africa Standard Time',
        territory: 'AQ',
        iana: ['Antarctica/Syowa'],
    },
    {
        windowsName: 'E. Africa Standard Time',
        territory: 'DJ',
        iana: ['Africa/Djibouti'],
    },
    {
        windowsName: 'E. Africa Standard Time',
        territory: 'ER',
        iana: ['Africa/Asmera'],
    },
    {
        windowsName: 'E. Africa Standard Time',
        territory: 'ET',
        iana: ['Africa/Addis_Ababa'],
    },
    {
        windowsName: 'E. Africa Standard Time',
        territory: 'KE',
        iana: ['Africa/Nairobi'],
    },
    {
        windowsName: 'E. Africa Standard Time',
        territory: 'KM',
        iana: ['Indian/Comoro'],
    },
    {
        windowsName: 'E. Africa Standard Time',
        territory: 'MG',
        iana: ['Indian/Antananarivo'],
    },
    {
        windowsName: 'E. Africa Standard Time',
        territory: 'SO',
        iana: ['Africa/Mogadishu'],
    },
    {
        windowsName: 'E. Africa Standard Time',
        territory: 'TZ',
        iana: ['Africa/Dar_es_Salaam'],
    },
    {
        windowsName: 'E. Africa Standard Time',
        territory: 'UG',
        iana: ['Africa/Kampala'],
    },
    {
        windowsName: 'E. Africa Standard Time',
        territory: 'YT',
        iana: ['Indian/Mayotte'],
    },
    {
        windowsName: 'E. Africa Standard Time',
        territory: 'ZZ',
        iana: ['Etc/GMT-3'],
    },
    {
        windowsName: 'Iran Standard Time',
        territory: '001',
        iana: ['Asia/Tehran'],
    },
    {
        windowsName: 'Iran Standard Time',
        territory: 'IR',
        iana: ['Asia/Tehran'],
    },
    {
        windowsName: 'Arabian Standard Time',
        territory: '001',
        iana: ['Asia/Dubai'],
    },
    {
        windowsName: 'Arabian Standard Time',
        territory: 'AE',
        iana: ['Asia/Dubai'],
    },
    {
        windowsName: 'Arabian Standard Time',
        territory: 'OM',
        iana: ['Asia/Muscat'],
    },
    {
        windowsName: 'Arabian Standard Time',
        territory: 'ZZ',
        iana: ['Etc/GMT-4'],
    },
    {
        windowsName: 'Astrakhan Standard Time',
        territory: '001',
        iana: ['Europe/Astrakhan'],
    },
    {
        windowsName: 'Astrakhan Standard Time',
        territory: 'RU',
        iana: ['Europe/Astrakhan', 'Europe/Ulyanovsk'],
    },
    {
        windowsName: 'Azerbaijan Standard Time',
        territory: '001',
        iana: ['Asia/Baku'],
    },
    {
        windowsName: 'Azerbaijan Standard Time',
        territory: 'AZ',
        iana: ['Asia/Baku'],
    },
    {
        windowsName: 'Russia Time Zone 3',
        territory: '001',
        iana: ['Europe/Samara'],
    },
    {
        windowsName: 'Russia Time Zone 3',
        territory: 'RU',
        iana: ['Europe/Samara'],
    },
    {
        windowsName: 'Mauritius Standard Time',
        territory: '001',
        iana: ['Indian/Mauritius'],
    },
    {
        windowsName: 'Mauritius Standard Time',
        territory: 'MU',
        iana: ['Indian/Mauritius'],
    },
    {
        windowsName: 'Mauritius Standard Time',
        territory: 'RE',
        iana: ['Indian/Reunion'],
    },
    {
        windowsName: 'Mauritius Standard Time',
        territory: 'SC',
        iana: ['Indian/Mahe'],
    },
    {
        windowsName: 'Saratov Standard Time',
        territory: '001',
        iana: ['Europe/Saratov'],
    },
    {
        windowsName: 'Saratov Standard Time',
        territory: 'RU',
        iana: ['Europe/Saratov'],
    },
    {
        windowsName: 'Georgian Standard Time',
        territory: '001',
        iana: ['Asia/Tbilisi'],
    },
    {
        windowsName: 'Georgian Standard Time',
        territory: 'GE',
        iana: ['Asia/Tbilisi'],
    },
    {
        windowsName: 'Volgograd Standard Time',
        territory: '001',
        iana: ['Europe/Volgograd'],
    },
    {
        windowsName: 'Volgograd Standard Time',
        territory: 'RU',
        iana: ['Europe/Volgograd'],
    },
    {
        windowsName: 'Caucasus Standard Time',
        territory: '001',
        iana: ['Asia/Yerevan'],
    },
    {
        windowsName: 'Caucasus Standard Time',
        territory: 'AM',
        iana: ['Asia/Yerevan'],
    },
    {
        windowsName: 'Afghanistan Standard Time',
        territory: '001',
        iana: ['Asia/Kabul'],
    },
    {
        windowsName: 'Afghanistan Standard Time',
        territory: 'AF',
        iana: ['Asia/Kabul'],
    },
    {
        windowsName: 'West Asia Standard Time',
        territory: '001',
        iana: ['Asia/Tashkent'],
    },
    {
        windowsName: 'West Asia Standard Time',
        territory: 'AQ',
        iana: ['Antarctica/Mawson'],
    },
    {
        windowsName: 'West Asia Standard Time',
        territory: 'KZ',
        iana: ['Asia/Oral', 'Asia/Aqtau', 'Asia/Aqtobe', 'Asia/Atyrau'],
    },
    {
        windowsName: 'West Asia Standard Time',
        territory: 'MV',
        iana: ['Indian/Maldives'],
    },
    {
        windowsName: 'West Asia Standard Time',
        territory: 'TF',
        iana: ['Indian/Kerguelen'],
    },
    {
        windowsName: 'West Asia Standard Time',
        territory: 'TJ',
        iana: ['Asia/Dushanbe'],
    },
    {
        windowsName: 'West Asia Standard Time',
        territory: 'TM',
        iana: ['Asia/Ashgabat'],
    },
    {
        windowsName: 'West Asia Standard Time',
        territory: 'UZ',
        iana: ['Asia/Tashkent', 'Asia/Samarkand'],
    },
    {
        windowsName: 'West Asia Standard Time',
        territory: 'ZZ',
        iana: ['Etc/GMT-5'],
    },
    {
        windowsName: 'Ekaterinburg Standard Time',
        territory: '001',
        iana: ['Asia/Yekaterinburg'],
    },
    {
        windowsName: 'Ekaterinburg Standard Time',
        territory: 'RU',
        iana: ['Asia/Yekaterinburg'],
    },
    {
        windowsName: 'Pakistan Standard Time',
        territory: '001',
        iana: ['Asia/Karachi'],
    },
    {
        windowsName: 'Pakistan Standard Time',
        territory: 'PK',
        iana: ['Asia/Karachi'],
    },
    {
        windowsName: 'Qyzylorda Standard Time',
        territory: '001',
        iana: ['Asia/Qyzylorda'],
    },
    {
        windowsName: 'Qyzylorda Standard Time',
        territory: 'KZ',
        iana: ['Asia/Qyzylorda'],
    },
    {
        windowsName: 'India Standard Time',
        territory: '001',
        iana: ['Asia/Calcutta'],
    },
    {
        windowsName: 'India Standard Time',
        territory: 'IN',
        iana: ['Asia/Calcutta'],
    },
    {
        windowsName: 'Sri Lanka Standard Time',
        territory: '001',
        iana: ['Asia/Colombo'],
    },
    {
        windowsName: 'Sri Lanka Standard Time',
        territory: 'LK',
        iana: ['Asia/Colombo'],
    },
    {
        windowsName: 'Nepal Standard Time',
        territory: '001',
        iana: ['Asia/Katmandu'],
    },
    {
        windowsName: 'Nepal Standard Time',
        territory: 'NP',
        iana: ['Asia/Katmandu'],
    },
    {
        windowsName: 'Central Asia Standard Time',
        territory: '001',
        iana: ['Asia/Almaty'],
    },
    {
        windowsName: 'Central Asia Standard Time',
        territory: 'AQ',
        iana: ['Antarctica/Vostok'],
    },
    {
        windowsName: 'Central Asia Standard Time',
        territory: 'CN',
        iana: ['Asia/Urumqi'],
    },
    {
        windowsName: 'Central Asia Standard Time',
        territory: 'IO',
        iana: ['Indian/Chagos'],
    },
    {
        windowsName: 'Central Asia Standard Time',
        territory: 'KG',
        iana: ['Asia/Bishkek'],
    },
    {
        windowsName: 'Central Asia Standard Time',
        territory: 'KZ',
        iana: ['Asia/Almaty', 'Asia/Qostanay'],
    },
    {
        windowsName: 'Central Asia Standard Time',
        territory: 'ZZ',
        iana: ['Etc/GMT-6'],
    },
    {
        windowsName: 'Bangladesh Standard Time',
        territory: '001',
        iana: ['Asia/Dhaka'],
    },
    {
        windowsName: 'Bangladesh Standard Time',
        territory: 'BD',
        iana: ['Asia/Dhaka'],
    },
    {
        windowsName: 'Bangladesh Standard Time',
        territory: 'BT',
        iana: ['Asia/Thimphu'],
    },
    {
        windowsName: 'Omsk Standard Time',
        territory: '001',
        iana: ['Asia/Omsk'],
    },
    {
        windowsName: 'Omsk Standard Time',
        territory: 'RU',
        iana: ['Asia/Omsk'],
    },
    {
        windowsName: 'Myanmar Standard Time',
        territory: '001',
        iana: ['Asia/Rangoon'],
    },
    {
        windowsName: 'Myanmar Standard Time',
        territory: 'CC',
        iana: ['Indian/Cocos'],
    },
    {
        windowsName: 'Myanmar Standard Time',
        territory: 'MM',
        iana: ['Asia/Rangoon'],
    },
    {
        windowsName: 'SE Asia Standard Time',
        territory: '001',
        iana: ['Asia/Bangkok'],
    },
    {
        windowsName: 'SE Asia Standard Time',
        territory: 'AQ',
        iana: ['Antarctica/Davis'],
    },
    {
        windowsName: 'SE Asia Standard Time',
        territory: 'CX',
        iana: ['Indian/Christmas'],
    },
    {
        windowsName: 'SE Asia Standard Time',
        territory: 'ID',
        iana: ['Asia/Jakarta', 'Asia/Pontianak'],
    },
    {
        windowsName: 'SE Asia Standard Time',
        territory: 'KH',
        iana: ['Asia/Phnom_Penh'],
    },
    {
        windowsName: 'SE Asia Standard Time',
        territory: 'LA',
        iana: ['Asia/Vientiane'],
    },
    {
        windowsName: 'SE Asia Standard Time',
        territory: 'TH',
        iana: ['Asia/Bangkok'],
    },
    {
        windowsName: 'SE Asia Standard Time',
        territory: 'VN',
        iana: ['Asia/Saigon'],
    },
    {
        windowsName: 'SE Asia Standard Time',
        territory: 'ZZ',
        iana: ['Etc/GMT-7'],
    },
    {
        windowsName: 'Altai Standard Time',
        territory: '001',
        iana: ['Asia/Barnaul'],
    },
    {
        windowsName: 'Altai Standard Time',
        territory: 'RU',
        iana: ['Asia/Barnaul'],
    },
    {
        windowsName: 'W. Mongolia Standard Time',
        territory: '001',
        iana: ['Asia/Hovd'],
    },
    {
        windowsName: 'W. Mongolia Standard Time',
        territory: 'MN',
        iana: ['Asia/Hovd'],
    },
    {
        windowsName: 'North Asia Standard Time',
        territory: '001',
        iana: ['Asia/Krasnoyarsk'],
    },
    {
        windowsName: 'North Asia Standard Time',
        territory: 'RU',
        iana: ['Asia/Krasnoyarsk', 'Asia/Novokuznetsk'],
    },
    {
        windowsName: 'N. Central Asia Standard Time',
        territory: '001',
        iana: ['Asia/Novosibirsk'],
    },
    {
        windowsName: 'N. Central Asia Standard Time',
        territory: 'RU',
        iana: ['Asia/Novosibirsk'],
    },
    {
        windowsName: 'Tomsk Standard Time',
        territory: '001',
        iana: ['Asia/Tomsk'],
    },
    {
        windowsName: 'Tomsk Standard Time',
        territory: 'RU',
        iana: ['Asia/Tomsk'],
    },
    {
        windowsName: 'China Standard Time',
        territory: '001',
        iana: ['Asia/Shanghai'],
    },
    {
        windowsName: 'China Standard Time',
        territory: 'CN',
        iana: ['Asia/Shanghai'],
    },
    {
        windowsName: 'China Standard Time',
        territory: 'HK',
        iana: ['Asia/Hong_Kong'],
    },
    {
        windowsName: 'China Standard Time',
        territory: 'MO',
        iana: ['Asia/Macau'],
    },
    {
        windowsName: 'North Asia East Standard Time',
        territory: '001',
        iana: ['Asia/Irkutsk'],
    },
    {
        windowsName: 'North Asia East Standard Time',
        territory: 'RU',
        iana: ['Asia/Irkutsk'],
    },
    {
        windowsName: 'Singapore Standard Time',
        territory: '001',
        iana: ['Asia/Singapore'],
    },
    {
        windowsName: 'Singapore Standard Time',
        territory: 'BN',
        iana: ['Asia/Brunei'],
    },
    {
        windowsName: 'Singapore Standard Time',
        territory: 'ID',
        iana: ['Asia/Makassar'],
    },
    {
        windowsName: 'Singapore Standard Time',
        territory: 'MY',
        iana: ['Asia/Kuala_Lumpur', 'Asia/Kuching'],
    },
    {
        windowsName: 'Singapore Standard Time',
        territory: 'PH',
        iana: ['Asia/Manila'],
    },
    {
        windowsName: 'Singapore Standard Time',
        territory: 'SG',
        iana: ['Asia/Singapore'],
    },
    {
        windowsName: 'Singapore Standard Time',
        territory: 'ZZ',
        iana: ['Etc/GMT-8'],
    },
    {
        windowsName: 'W. Australia Standard Time',
        territory: '001',
        iana: ['Australia/Perth'],
    },
    {
        windowsName: 'W. Australia Standard Time',
        territory: 'AU',
        iana: ['Australia/Perth'],
    },
    {
        windowsName: 'Taipei Standard Time',
        territory: '001',
        iana: ['Asia/Taipei'],
    },
    {
        windowsName: 'Taipei Standard Time',
        territory: 'TW',
        iana: ['Asia/Taipei'],
    },
    {
        windowsName: 'Ulaanbaatar Standard Time',
        territory: '001',
        iana: ['Asia/Ulaanbaatar'],
    },
    {
        windowsName: 'Ulaanbaatar Standard Time',
        territory: 'MN',
        iana: ['Asia/Ulaanbaatar', 'Asia/Choibalsan'],
    },
    {
        windowsName: 'Aus Central W. Standard Time',
        territory: '001',
        iana: ['Australia/Eucla'],
    },
    {
        windowsName: 'Aus Central W. Standard Time',
        territory: 'AU',
        iana: ['Australia/Eucla'],
    },
    {
        windowsName: 'Transbaikal Standard Time',
        territory: '001',
        iana: ['Asia/Chita'],
    },
    {
        windowsName: 'Transbaikal Standard Time',
        territory: 'RU',
        iana: ['Asia/Chita'],
    },
    {
        windowsName: 'Tokyo Standard Time',
        territory: '001',
        iana: ['Asia/Tokyo'],
    },
    {
        windowsName: 'Tokyo Standard Time',
        territory: 'ID',
        iana: ['Asia/Jayapura'],
    },
    {
        windowsName: 'Tokyo Standard Time',
        territory: 'JP',
        iana: ['Asia/Tokyo'],
    },
    {
        windowsName: 'Tokyo Standard Time',
        territory: 'PW',
        iana: ['Pacific/Palau'],
    },
    {
        windowsName: 'Tokyo Standard Time',
        territory: 'TL',
        iana: ['Asia/Dili'],
    },
    {
        windowsName: 'Tokyo Standard Time',
        territory: 'ZZ',
        iana: ['Etc/GMT-9'],
    },
    {
        windowsName: 'North Korea Standard Time',
        territory: '001',
        iana: ['Asia/Pyongyang'],
    },
    {
        windowsName: 'North Korea Standard Time',
        territory: 'KP',
        iana: ['Asia/Pyongyang'],
    },
    {
        windowsName: 'Korea Standard Time',
        territory: '001',
        iana: ['Asia/Seoul'],
    },
    {
        windowsName: 'Korea Standard Time',
        territory: 'KR',
        iana: ['Asia/Seoul'],
    },
    {
        windowsName: 'Yakutsk Standard Time',
        territory: '001',
        iana: ['Asia/Yakutsk'],
    },
    {
        windowsName: 'Yakutsk Standard Time',
        territory: 'RU',
        iana: ['Asia/Yakutsk', 'Asia/Khandyga'],
    },
    {
        windowsName: 'Cen. Australia Standard Time',
        territory: '001',
        iana: ['Australia/Adelaide'],
    },
    {
        windowsName: 'Cen. Australia Standard Time',
        territory: 'AU',
        iana: ['Australia/Adelaide', 'Australia/Broken_Hill'],
    },
    {
        windowsName: 'AUS Central Standard Time',
        territory: '001',
        iana: ['Australia/Darwin'],
    },
    {
        windowsName: 'AUS Central Standard Time',
        territory: 'AU',
        iana: ['Australia/Darwin'],
    },
    {
        windowsName: 'E. Australia Standard Time',
        territory: '001',
        iana: ['Australia/Brisbane'],
    },
    {
        windowsName: 'E. Australia Standard Time',
        territory: 'AU',
        iana: ['Australia/Brisbane', 'Australia/Lindeman'],
    },
    {
        windowsName: 'AUS Eastern Standard Time',
        territory: '001',
        iana: ['Australia/Sydney'],
    },
    {
        windowsName: 'AUS Eastern Standard Time',
        territory: 'AU',
        iana: ['Australia/Sydney', 'Australia/Melbourne'],
    },
    {
        windowsName: 'West Pacific Standard Time',
        territory: '001',
        iana: ['Pacific/Port_Moresby'],
    },
    {
        windowsName: 'West Pacific Standard Time',
        territory: 'AQ',
        iana: ['Antarctica/DumontDUrville'],
    },
    {
        windowsName: 'West Pacific Standard Time',
        territory: 'FM',
        iana: ['Pacific/Truk'],
    },
    {
        windowsName: 'West Pacific Standard Time',
        territory: 'GU',
        iana: ['Pacific/Guam'],
    },
    {
        windowsName: 'West Pacific Standard Time',
        territory: 'MP',
        iana: ['Pacific/Saipan'],
    },
    {
        windowsName: 'West Pacific Standard Time',
        territory: 'PG',
        iana: ['Pacific/Port_Moresby'],
    },
    {
        windowsName: 'West Pacific Standard Time',
        territory: 'ZZ',
        iana: ['Etc/GMT-10'],
    },
    {
        windowsName: 'Tasmania Standard Time',
        territory: '001',
        iana: ['Australia/Hobart'],
    },
    {
        windowsName: 'Tasmania Standard Time',
        territory: 'AU',
        iana: ['Australia/Hobart', 'Australia/Currie', 'Antarctica/Macquarie'],
    },
    {
        windowsName: 'Vladivostok Standard Time',
        territory: '001',
        iana: ['Asia/Vladivostok'],
    },
    {
        windowsName: 'Vladivostok Standard Time',
        territory: 'RU',
        iana: ['Asia/Vladivostok', 'Asia/Ust-Nera'],
    },
    {
        windowsName: 'Lord Howe Standard Time',
        territory: '001',
        iana: ['Australia/Lord_Howe'],
    },
    {
        windowsName: 'Lord Howe Standard Time',
        territory: 'AU',
        iana: ['Australia/Lord_Howe'],
    },
    {
        windowsName: 'Bougainville Standard Time',
        territory: '001',
        iana: ['Pacific/Bougainville'],
    },
    {
        windowsName: 'Bougainville Standard Time',
        territory: 'PG',
        iana: ['Pacific/Bougainville'],
    },
    {
        windowsName: 'Russia Time Zone 10',
        territory: '001',
        iana: ['Asia/Srednekolymsk'],
    },
    {
        windowsName: 'Russia Time Zone 10',
        territory: 'RU',
        iana: ['Asia/Srednekolymsk'],
    },
    {
        windowsName: 'Magadan Standard Time',
        territory: '001',
        iana: ['Asia/Magadan'],
    },
    {
        windowsName: 'Magadan Standard Time',
        territory: 'RU',
        iana: ['Asia/Magadan'],
    },
    {
        windowsName: 'Norfolk Standard Time',
        territory: '001',
        iana: ['Pacific/Norfolk'],
    },
    {
        windowsName: 'Norfolk Standard Time',
        territory: 'NF',
        iana: ['Pacific/Norfolk'],
    },
    {
        windowsName: 'Sakhalin Standard Time',
        territory: '001',
        iana: ['Asia/Sakhalin'],
    },
    {
        windowsName: 'Sakhalin Standard Time',
        territory: 'RU',
        iana: ['Asia/Sakhalin'],
    },
    {
        windowsName: 'Central Pacific Standard Time',
        territory: '001',
        iana: ['Pacific/Guadalcanal'],
    },
    {
        windowsName: 'Central Pacific Standard Time',
        territory: 'AQ',
        iana: ['Antarctica/Casey'],
    },
    {
        windowsName: 'Central Pacific Standard Time',
        territory: 'FM',
        iana: ['Pacific/Ponape', 'Pacific/Kosrae'],
    },
    {
        windowsName: 'Central Pacific Standard Time',
        territory: 'NC',
        iana: ['Pacific/Noumea'],
    },
    {
        windowsName: 'Central Pacific Standard Time',
        territory: 'SB',
        iana: ['Pacific/Guadalcanal'],
    },
    {
        windowsName: 'Central Pacific Standard Time',
        territory: 'VU',
        iana: ['Pacific/Efate'],
    },
    {
        windowsName: 'Central Pacific Standard Time',
        territory: 'ZZ',
        iana: ['Etc/GMT-11'],
    },
    {
        windowsName: 'Russia Time Zone 11',
        territory: '001',
        iana: ['Asia/Kamchatka'],
    },
    {
        windowsName: 'Russia Time Zone 11',
        territory: 'RU',
        iana: ['Asia/Kamchatka', 'Asia/Anadyr'],
    },
    {
        windowsName: 'New Zealand Standard Time',
        territory: '001',
        iana: ['Pacific/Auckland'],
    },
    {
        windowsName: 'New Zealand Standard Time',
        territory: 'AQ',
        iana: ['Antarctica/McMurdo'],
    },
    {
        windowsName: 'New Zealand Standard Time',
        territory: 'NZ',
        iana: ['Pacific/Auckland'],
    },
    {
        windowsName: 'UTC+12',
        territory: '001',
        iana: ['Etc/GMT-12'],
    },
    {
        windowsName: 'UTC+12',
        territory: 'KI',
        iana: ['Pacific/Tarawa'],
    },
    {
        windowsName: 'UTC+12',
        territory: 'MH',
        iana: ['Pacific/Majuro', 'Pacific/Kwajalein'],
    },
    {
        windowsName: 'UTC+12',
        territory: 'NR',
        iana: ['Pacific/Nauru'],
    },
    {
        windowsName: 'UTC+12',
        territory: 'TV',
        iana: ['Pacific/Funafuti'],
    },
    {
        windowsName: 'UTC+12',
        territory: 'UM',
        iana: ['Pacific/Wake'],
    },
    {
        windowsName: 'UTC+12',
        territory: 'WF',
        iana: ['Pacific/Wallis'],
    },
    {
        windowsName: 'UTC+12',
        territory: 'ZZ',
        iana: ['Etc/GMT-12'],
    },
    {
        windowsName: 'Fiji Standard Time',
        territory: '001',
        iana: ['Pacific/Fiji'],
    },
    {
        windowsName: 'Fiji Standard Time',
        territory: 'FJ',
        iana: ['Pacific/Fiji'],
    },
    {
        windowsName: 'Chatham Islands Standard Time',
        territory: '001',
        iana: ['Pacific/Chatham'],
    },
    {
        windowsName: 'Chatham Islands Standard Time',
        territory: 'NZ',
        iana: ['Pacific/Chatham'],
    },
    {
        windowsName: 'UTC+13',
        territory: '001',
        iana: ['Etc/GMT-13'],
    },
    {
        windowsName: 'UTC+13',
        territory: 'KI',
        iana: ['Pacific/Enderbury'],
    },
    {
        windowsName: 'UTC+13',
        territory: 'TK',
        iana: ['Pacific/Fakaofo'],
    },
    {
        windowsName: 'UTC+13',
        territory: 'ZZ',
        iana: ['Etc/GMT-13'],
    },
    {
        windowsName: 'Tonga Standard Time',
        territory: '001',
        iana: ['Pacific/Tongatapu'],
    },
    {
        windowsName: 'Tonga Standard Time',
        territory: 'TO',
        iana: ['Pacific/Tongatapu'],
    },
    {
        windowsName: 'Samoa Standard Time',
        territory: '001',
        iana: ['Pacific/Apia'],
    },
    {
        windowsName: 'Samoa Standard Time',
        territory: 'WS',
        iana: ['Pacific/Apia'],
    },
    {
        windowsName: 'Line Islands Standard Time',
        territory: '001',
        iana: ['Pacific/Kiritimati'],
    },
    {
        windowsName: 'Line Islands Standard Time',
        territory: 'KI',
        iana: ['Pacific/Kiritimati'],
    },
    {
        windowsName: 'Line Islands Standard Time',
        territory: 'ZZ',
        iana: ['Etc/GMT-14'],
    },
];

const ianaToWindows_list = WINDOWS_TO_IANA.flatMap(k => k.iana.map(i => ({ name: i, value: k })));
const ianaToWindows_map = ianaToWindows_list.reduce((previous, current) => (previous[current.name] = current.value, previous), {});

const windowsToIana_list = WINDOWS_TO_IANA.map(k => (
    {
        name: k.territory + '~' + k.windowsName,
        value: k.iana
    }
));

const windowsToIana_map = windowsToIana_list.reduce((previous, current) => (previous[current.name] = current.value, previous), {});

export default {
    WINDOWS_TO_IANA: WINDOWS_TO_IANA,

    ianaToWindows: function (iana) {
        return ianaToWindows_map[iana].windowsName;
    },

    ianaToWindowsTerritory: function (iana) {
        return ianaToWindows_map[iana].territory;
    },

    ianaSimilar: function (iana) {
        return ianaToWindows_map[iana].iana;
    },

    windowsToIana: function (territory, windows, all, index) {
        const key = territory + '~' + windows;
        if (all)
            return windowsToIana_map[key];
        else if (index !== undefined)
            return windowsToIana_map[key][index];
        else
            return windowsToIana_map[key][0];
    },
};