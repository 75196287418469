<template v-if="todisplay">
    <v-tooltip top v-show="isvisible">
        <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" v-bind:class="cssclasscomputed">
                <v-icon v-bind:color="colorcomputed">
                    {{lefticoncomputed}}
                </v-icon>

                <v-list-item-content>
                    <v-list-item-title>{{textcomputed}}</v-list-item-title>
                </v-list-item-content>

                <v-list-item-icon>
                    <v-icon>
                        {{righticoncomputed}}
                    </v-icon>
                </v-list-item-icon>

                <div class="mslRightTextContext">
                    {{righttextcomputed}}
                </div>

            </div>
        </template>
        <span>{{tooltipcomputed}}</span>
    </v-tooltip>

</template>


<script>
    import utils from '@/Shared/utils.jsx';
    import BaseComponent from './BaseComponentMixin.jsx';
    import careHelpfulFunctions from '../careHelpfulFunctions.jsx';
    import Vue from 'vue';
    

    export default {
        name: "MultiSelectListItem",
        mixins: [BaseComponent],
        components: {
        },
        props: ['sourceData','template'],
        data() {
            return {
                righticon: null,
                lefticon: null,
                text: null,
                tooltip: null,
                typex: null,
                value: null,
                color: null,
                cssclass: null,
                righttext: null,
                hovercolor: null,
            };
           
        },
        created() {
          
        },
        computed: {
            styles: function () {
                return {
                    ...utils.resolveStyleHints(this.styleHints, this),
                    ...this.sizeStyle,
                    display: 'flex',
                    flexDirection: 'column'
                }
            },
          
            // NEED TO COMPILE/EVAL THE ITEM ATTRIBUTES

            righticoncomputed() {
                try {
                    if (this.righticon === null && this.template.RightIcon !== null)
                        this.righticon = utils.compile(this, this.template.RightIcon);

                    let righticon = utils.evaluate(this.righticon, this);

                    if (righticon && this.iconStyle === "mdi" && !righticon.startsWith("mdi-")) righticon = `mdi-${righticon}`;

                    return righticon;
                } catch (e) {
                    utils.warn("Right Icon could not evaluate expression: " + this.template.RightIcon + "; " + e);
                    return "";
                }
            },
            lefticoncomputed() {
                try {
                    if (this.lefticon === null && this.template.LeftIcon !== null)
                        this.lefticon = utils.compile(this, this.template.LeftIcon);

                    let lefticon = utils.evaluate(this.lefticon, this);

                    if (lefticon && this.iconStyle === "mdi" && !lefticon.startsWith("mdi-")) lefticon = `mdi-${lefticon}`;

                    return lefticon;
                } catch (e) {
                    utils.warn("Left Icon could not evaluate expression: " + this.template.LeftIcon + "; " + e);
                    return "";
                }
            },
            //Text
            textcomputed() {
                try {
                    if (this.text === null && this.template.Text !== null)
                        this.text = utils.compile(this, this.template.Text);

                    let text = utils.evaluate(this.text, this);

                    
                    return text;
                } catch (e) {
                    utils.warn("Text could not evaluate expression: " + this.template.Text + "; " + e);
                    return "";
                }
            },
            // Tooltip
            tooltipcomputed() {
                try {
                    if (this.tooltip === null && this.template.Tooltip !== null)
                        this.tooltip = utils.compile(this, this.template.Tooltip);

                    let tooltip = utils.evaluate(this.tooltip, this);

                    return tooltip;
                } catch (e) {
                    utils.warn("Tooltip could not evaluate expression: " + this.template.Tooltip + "; " + e);
                    return "";
                }
            },
            //Type
            typecomputed() {
                try {
                    if (this.typex === null && this.template.Type !== null)
                        this.typex = utils.compile(this, this.template.Type);

                    let typex = utils.evaluate(this.typex, this);
                                        
                    return typex;
                } catch (e) {
                    utils.warn("Type could not evaluate expression: " + this.template.Type + "; " + e);
                    return "";
                }
            },
            //Value
            valuecomputed() {
                try {
                    if (this.value === null && this.template.Value !== null)
                        this.value = utils.compile(this, this.template.Value);

                    let value = utils.evaluate(this.value, this);

                    return value;
                } catch (e) {
                    utils.warn("Value could not evaluate expression: " + this.template.Value + "; " + e);
                    return "";
                }
            },
            //Color
            colorcomputed() {
                try {
                    if (this.color === null && this.template.Color !== null)
                        this.color = utils.compile(this, this.template.Color);

                    let color = utils.evaluate(this.color, this);

                    return color;

                } catch (e) {
                    utils.warn("Color could not evaluate expression: " + this.template.Color + "; " + e);
                    return "";
                }
            },
            //CssClass
            cssclasscomputed() {
                try {
                    if (this.cssclass === null && this.template.CssClass !== null)
                        this.cssclass = utils.compile(this, this.template.CssClass);

                    let cssclass = utils.evaluate(this.cssclass, this);
                    return cssclass;
                } catch (e) {
                    utils.warn("CssClass could not evaluate expression: " + this.template.CssClass + "; " + e);
                    return "";
                }
            },
            //RightText
            righttextcomputed() {
                try {
                    if (this.righttext === null && this.template.RightText !== null)
                        this.righttext = utils.compile(this, this.template.RightText);

                    let righttext = utils.evaluate(this.righttext, this);

                    return righttext;
                } catch (e) {
                    utils.warn("RightText could not evaluate expression: " + this.template.RightText + "; " + e);
                    return "";
                }
            },

            //HoverColor
            hovercolorcomputed() {
                try {
                    if (this.hovercolor === null && this.template.HoverColor !== null)
                        this.hovercolor = utils.compile(this, this.template.HoverColor);

                    let hovercolor = utils.evaluate(this.hovercolor, this);

                    return hovercolor;
                } catch (e) {
                    utils.warn("Left Icon could not evaluate expression: " + this.template.HoverColor + "; " + e);
                    return "";
                }
            },

            
        },
       
        methods: {

        },
    }

</script>

<style scoped>
    .flexRow {
        display:flex;
        flex-direction:row;
        flex-grow: 1;
    }
</style>
