import Vue from 'vue';
import utils from '../../Shared/utils.jsx';
import BaseComponent from './BaseComponentMixin.jsx';

Vue.component('split-button', {
    mixins: [BaseComponent],
    data: function () {
        return {
            textrenderer: null,
            tooltiprenderer: null,
            iconrenderer: null,
            isdisabledeval: null,
            menuactions: [],
        }
    },
    async created() {
        this.isdisabledeval = utils.compile(this, this.controlData.IsDisabled);
    },
    //Mounted Replaced with preRenderComplete
    computed: {
        isdisabled: function () {
            return utils.evaluate(this.isdisabledeval, this);
        },
        styles: function () {
            return {
                ...this.sizeStyle,
                ...utils.resolveStyleHints(this.styleHints, this),
                overflow: "hidden",
                whiteSpace: "nowrap",
            };
        }
    },
    methods: {
        preRenderComplete() {
            this.finishRenderHandler(this);
        },
        async doClick(e) {
            e.cancelBubble = true;
            e.stopPropagation();

            await utils.executeAndCompileAllActions(this.controlData.MenuActions, null, this);
        },
    },
    props: {
    },
    render(h) {
        if (!this.todisplay)
            return null;

        //const style = {
        //    ...utils.getSize(this.controlData.SizeOptions, this.parentType, this.$parent),
        //    ...utils.resolveStyleHints(this.styleHints, this),
        //    overflow: "hidden",
        //    whiteSpace: "nowrap",
        //};

        let content = [];

        content.push(<v-icon>mdi mdi-menu-down</v-icon>);

        const flat = this.controlData.Themes ? this.controlData.Themes.includes("Flat") : false;
        const small = this.controlData.Themes ? this.controlData.Themes.includes("Condensed") : false;

        const menu = null; // <v-menu offset-y></v-menu>;

        return (
            <div
                class={{ 'c-SplitButton': true, [`c-name-${this.name || 'unnamed'}`]: true }}
                style={{ display: "flex", flexDirection: "row" }}>
                <v-btn elevation={0}
                    flat={flat}
                    small={small}
                    style={this.styles}
                    on-click={(e) => this.doClick(e)}
                    type="button"
                    title={this.tooltip}
                    disabled={this.isdisabled == 'true'}>
                    {content}
                </v-btn>
                {menu}
            </div>
        );
    }
});