import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import formAnyOf from './anyof.jsx';
import methods from '../../../Shared/methods';
import EventBus from '../../event-bus.js';
import { Container, Draggable } from 'vue-smooth-dnd';

Vue.component('form-array-label', {
    data: function () {
        return {
            titleexpn: null
        }
    },
    async mounted() {
        if (this.cmodel.$typeSchema) {
            const schema = await utils.schema.get(this.cmodel.$typeSchema);
            const res = utils.schema.resolve_Of(schema);

            // {{ActionExpression ? '?(' + ActionExpression.substring(0,8) + (ActionExpression.length>8?'...':'') + ') ' : ''}} {{ActionData.Expression}}
            if (res.documentEditorTitle) {
                // Build a proxy to enable the title expression to refer to missing elements in the model without throwing an exception
                const handler1 = {
                    has: function (oTarget, sKey) {
                        return sKey !== '_self';
                    },
                    get: function (target, prop, receiver) {
                        return prop in target ? target[prop] : null;
                    }
                };
                this.model_proxy = new Proxy(this.cmodel, handler1);

                this.titleexpn = utils.compile(this, res.documentEditorTitle);
            }
        }
    },
    computed: {
        title: function () {
            if (this.titleexpn)
                try {
                    return utils.evaluate(this.titleexpn, this.model_proxy);
                }
                catch (e) {
                    utils.warn('Array documentEditorTitle failed to evaluate: ' + this.titleexpn + '; ' + e);
                    return null;
                }
            else
                return null;
        },
    },
    props: {
        cmodel: null,
        visible: false,
    },
    render(h) {
        const style = {
            //display: "inline",
            fontFamily: "Arial",
            fontSize: "small", // x-small before
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxWidth: "350px",
            marginTop: "2px",
            marginBottom: "1px",
        };
        return <div title={this.title} style={style}>{this.title}</div>;
    }
});

function toggleVisible(control, key) {
    const default_value = 'true';
    const value = control.visible_keys[key] || localStorage.getItem(key);

    if (!value) {
        // No setting, save the opposite of the default_value
        const new_value = default_value === 'true' ? 'false' : 'true';
        localStorage.setItem(key, new_value);
        Vue.set(control.visible_keys, key, new_value);
        utils.log('Saved ' + key + ' as ' + new_value);
    }
    else {
        const new_value = value === 'true' ? 'false' : 'true';
        if (new_value === default_value) {
            // When the value is the default, remove the entry
            localStorage.removeItem(key);
            Vue.delete(control.visible_keys, key);
            utils.log('Deleted ' + key);
        }
        else {
            // Otherwise, we must store the value
            localStorage.setItem(key, new_value);
            Vue.set(control.visible_keys, key, new_value);
            utils.log('Saved ' + key + ' as ' + new_value);
        }
    }
}

function getVisibility(control, key) {
    const default_value = 'true';
    let value = control.visible_keys[key];
    if (!value) {
        value = localStorage.getItem(key);
        if (value)
            Vue.set(control.visible_keys, key, value);
    }

    if (!value)
        return default_value === 'true';
    else
        return value === 'true';
}


Vue.component('form-array', {
    data: function () {
        return {
            selected:[],
            visible_keys: {},

            uniquekey: null,
            asjson: false,
        }
    },
    created() {
        this.uniquekey = utils.generateUUID();
    },
    computed: {
        ...utils.forms.computed,
        anySelected: function () {
            if (!this.selected || this.selected.length == 0) return false;
            for (let i = 0; i < this.selected.length; i++)
                if (this.selected[i]) return true;
            return false;
        }
    },
    methods: {
        ...methods,
        async addAnother() {
            if (!this.cmodel[this.schemakey])
                Vue.set(this.cmodel, this.schemakey, []);

            //const s = await utils.schema.resolve(this.schema.items);
            const schema = utils.schema.resolve_Of(this.schema.items);
            const newmodel = utils.schema.getDefaultModel(schema);
            this.cmodel[this.schemakey].push(newmodel);
        },
        removeItem(index) {
            this.cmodel[this.schemakey].splice(index, 1);
        },
        moveItemUp(index) {
            if (index > 0)
            {
                const item = this.cmodel[this.schemakey][index];
                this.cmodel[this.schemakey].splice(index, 1);
                this.cmodel[this.schemakey].splice(index - 1, 0, item);
            }
        },
        moveItemDown(index) {
            if (index < this.cmodel[this.schemakey].length - 1) {
                const item = this.cmodel[this.schemakey][index];
                this.cmodel[this.schemakey].splice(index, 1);
                this.cmodel[this.schemakey].splice(index + 1, 0, item);
            }
        },

        setSelection(index, checked) {
            while (index >= this.selected.length)
                this.selected.push(false);

            Vue.set(this.selected, index, checked);
        },
        copy() {
            const controls = this.cmodel[this.schemakey];
            const selected = [];
            for (let i = 0; i < this.selected.length; i++)
                if (this.selected[i])
                    selected.push(controls[i]);

            localStorage.setItem('careClipboard', JSON.stringify(selected));
        },
        cut() {
            const controls = this.cmodel[this.schemakey];
            const selected = [];
            for (let i = 0; i < this.selected.length; i++)
                if (this.selected[i])
                    selected.push(controls[i]);

            localStorage.setItem('careClipboard', JSON.stringify(selected));

            for (let i = this.selected.length - 1; i >= 0; i--)
                if (this.selected[i])
                    controls.splice(i, 1);

            this.selected = [];
        },
        paste() {
            const rawbuffer = localStorage.getItem('careClipboard');
            if (rawbuffer && typeof rawbuffer === 'string')
            {
                try {
                    const data = JSON.parse(rawbuffer);
                    if (typeof data === 'object' && Array.isArray(data)) {
                        if (!this.cmodel[this.schemakey])
                            Vue.set(this.cmodel, this.schemakey, []);

                        const controls = this.cmodel[this.schemakey];
                        for (let i = 0; i < data.length; i++)
                        {
                            if ('$objectId' in data[i]) data[i].$objectId = utils.generateUUID();
                            controls.push(data[i]);
                        }
                    }
                }
                catch (e)
                {
                    alert('Failed to parse data from clipboard buffer: ' + e);
                }
            }
        },
        selectAll() {
            const controls = this.cmodel[this.schemakey];

            this.selected = new Array(controls.length);

            for (let i = 0; i < this.selected.length; i++)
                Vue.set(this.selected, i, true);
        },
        selectNone() {
            this.selected = [];
        },
        extractor(control, prop, index) {
            const accept = async (value) => {
                const newmodel = control.cmodel[index];
                let newcontrol;
                let replaced;
                let type;
                switch (this.schema.items.Id) {
                    case '/Apps/Schema/AnyOf/schema/public/Platform.Schema.BaseTypes.v1/DynamicControl':
                        // Build a new user control and embed a reference in place of control.cmodel[index]
                        type = 'UserControl';
                        newcontrol = {
                            $typeSchema: '/schema/public/Platform.Schema.Documents.v1/Document_UserControl',
                            Name: value,
                            Data: newmodel,
                            Public: false,
                            ControlDataSchema: {
                                type: 'object',
                                additionalProperties: true,
                                properties: {},
                            },
                            $DeployID: 'dev',
                        };
                        replaced = {
                            $typeSchema: '/schema/public/Platform.Schema.DynamicControls.v1/DynamicControl_Reference',
                            PrimitiveControl: false,
                            CacheControl: true,
                            ControlData: {},
                            ControlURL: newcontrol.Name,
                            $objectId: utils.generateUUID(),
                        };
                        break;

                    case '/Apps/Schema/AnyOf/schema/public/Platform.Schema.BaseTypes.v1/UIAction':
                        // Build a new user action and embed a reference in place of control.cmodel[index]
                        type = 'Action';
                        newcontrol = {
                            $typeSchema: '/schema/public/Platform.Schema.Documents.v1/Document_UserAction',
                            Name: value,
                            Data: newmodel,
                            Public: false,
                            ActionDataSchema: {
                                type: 'object',
                                additionalProperties: true,
                                properties: {},
                            },
                            $DeployID: 'dev',
                        };
                        replaced = {
                            $typeSchema: '/schema/public/Platform.Schema.UIActions.v1/UIAction_Reference',
                            PrimitiveAction: true,
                            ActionType: 'ReferenceUserAction',
                            ActionData: {
                                UserAction: {
                                    ActionData: {},
                                    ActionURL: newcontrol.Name,
                                    CacheAction: true,
                                }
                            },
                            RunAsync: false,
                            $objectId: utils.generateUUID(),
                        };
                        break;

                    default:
                        return;
                }

                if (this.root.saveExtractedEntity) {
                    // Save the new control or action
                    const success = await this.root.saveExtractedEntity(type, newcontrol);

                    // Replace the current item with the new content which points to the newly saved entity
                    if (success)
                        Vue.set(control.cmodel, index, replaced);
                }
            };

            let title;
            let label;
            switch (this.schema.items.Id) {
                case '/Apps/Schema/AnyOf/schema/public/Platform.Schema.BaseTypes.v1/DynamicControl':
                    title = 'Extract User Control';
                    label = 'New User Control Name';
                    break;

                case '/Apps/Schema/AnyOf/schema/public/Platform.Schema.BaseTypes.v1/UIAction':
                    title = 'Extract User Action';
                    label = 'New User Action Name';
                    break;

                default:
                    return;
            }

            EventBus.$emit('SHOW_DIALOG', (
                <input-dialog
                    root={this.root}
                    title={title}
                    label={label}
                    accept={accept}>
                </input-dialog>
            ));
        },
        reOrderItems(result) {
            // Ex: {"removedIndex":2,"addedIndex":0}
            const cmodel = this.cmodel[this.schemakey] || [];
            // Note: the indexes are off by 1 for some reason, compensating (-1)
            const item = cmodel[result.removedIndex - 1];
            cmodel.splice(result.removedIndex - 1, 1);
            cmodel.splice(result.addedIndex - 1, 0, item);
        },

        sync(text) {
            try {
                this.cmodel[this.schemakey] = JSON.parse(text);
            }
            catch (e) {
                utils.warn('array in propertygrid could not parse JSON', e);
            }
        },
    },
    props: {
        name: '',
        schemakey: '',
        root: null,
        schema: null,
        cmodel: null,
        extra: null,
        readonly: false,
        path: null,
        child: 0,
        navigateTo: null,
        canNavigate: false,
        propertygrid: false,
    },
    render(h) {
        if (!this.Condition || !this.schema || !this.schema.items)
            return null;

        let desc;
        if (this.schema.description)
            desc = <i class="mdi mdi-information" title={this.schema.description} style={{ color: "silver", marginLeft: "3px" }}></i>;

        const schema = utils.schema.resolve_Of(this.schema.items);
        const name = <span style={{ marginLeft: (this.child * 15) + "px" }}>{this.name}</span>;

        const heads = [];
        const input = [];
        const foots = [];
        const cmodel = this.cmodel[this.schemakey] || [];

        const visible_key = [...(this.path || []), this.schemakey].join('.');
        const vis = getVisibility(this, visible_key);
        const icon = vis ? <i class="mdi mdi-menu-down"></i> : <i class="mdi mdi-chevron-right-box-outline"></i>;
        const openclose = <span style={{ cursor: "pointer" }} on-click={() => toggleVisible(this, visible_key)}>{icon}</span>;

        const jsonico = this.asjson ? <i class="mdi mdi-eye-off" title="JSON view"></i> : <i class="mdi mdi-eye" title="JSON view"></i>;
        const jsontgl = <span style={{ cursor: "pointer", color: "gray" }} on-click={() => this.asjson =! this.asjson}>{jsonico}</span>;

        const tdstyle = {
            backgroundColor: "#f8f8f8",
        };

        if (vis) {
            if (this.asjson || typeof cmodel === 'string') {
                // Allows viewing/editing the raw JSON of the element model
                const rawjson = JSON.stringify(cmodel, null, 4);
                return (
                    <tr>
                        <td style={{ width: "1px", verticalAlign: "top", whiteSpace: "nowrap" }}>
                            {name} {jsontgl} {openclose} {desc}
                        </td>

                        <td style={{ width: "100%", height: "100px" }}>
                            <div style={{ resize: "vertical", width: "97%", height: "100px", borderStyle: "solid", borderColor: "silver", borderWidth: "1px", padding: "5px", overflow: "auto", position: "relative" }}>
                                <ace-editor
                                    editorId={'Editor_' + this.uniquekey}
                                    lang="json"
                                    on-change-content={(text) => this.sync(text)}
                                    content={rawjson}>
                                </ace-editor>
                            </div>
                        </td>

                    </tr>
                );
            }

            const btn_styles = {
                'font-size': "x-small"
            };

            if (this.canNavigate) {
                input.push(
                    <span class={{ "property-grid": this.propertygrid }} style="display:flex; gap: 5px;">
                        <v-btn elevation={0} x-small color="blue-grey" class="white--text" style={btn_styles} disabled={!this.anySelected} on-click={this.copy}>Copy</v-btn>
                        <v-btn elevation={0} x-small color="blue-grey" class="white--text" style={btn_styles} disabled={!this.anySelected} on-click={this.cut}>Cut</v-btn>
                        <v-btn elevation={0} x-small color="blue-grey" class="white--text" style={btn_styles} on-click={this.paste}>Paste</v-btn>

                        <v-btn elevation={0} x-small color="blue-grey" class="white--text" style={btn_styles} on-click={this.selectAll}>Select All</v-btn>
                        <v-btn elevation={0} x-small color="blue-grey" class="white--text" style={btn_styles} on-click={this.selectNone}>Select None</v-btn>
                    </span>
                );
            }

            let Tag = utils.schema.getElementType(schema);
            if (!Tag && schema.type == 'object')
                Tag = 'property-grid';

            if (Tag)
                for (let i = 0; i < cmodel.length; i++) {
                    const item = cmodel[i];

                    let select_checkbox;
                    let item_label;
                    let style = {
                        marginBottom: "1px",
                        borderBottomWidth: "1px",
                        borderBottomStyle: "solid",
                        borderBottomColor: "silver",
                        borderRadius: "3px",
                    };

                    if (this.canNavigate) {
                        select_checkbox = <input type="checkbox" checked={this.selected.length > i && this.selected[i]} on-change={(e) => this.setSelection(i, e.target.checked)} />;
                        item_label = (
                            <form-array-label key={i + '_' + (item ? item.$typeSchema : 'null')} cmodel={item} visible={true}>
                            </form-array-label>
                        );
                    }

                    if (item && typeof item === 'object' && !item.$objectId)
                        item.$objectId = utils.generateUUID();

                    input.push(
                        <Draggable key={(item && typeof item === 'object') ? item.$objectId : (item || 'newitem')} index={i} style={style}>
                            <span class={{ "draggable-item":true, "property-grid": this.propertygrid }}>
                                {select_checkbox}
                                <Tag
                                    name={'[' + i + ']'}
                                    schemakey={i}
                                    schema={schema}
                                    root={this.root}
                                    cmodel={cmodel}
                                    form_model={this.$attrs.form_model}
                                    child={0}
                                    readonly={schema.readonly || false}
                                    navigateTo={(control, prop, value) => this.navigateTo(control, prop, value || item)}
                                    canNavigate={this.canNavigate}
                                    extractor={(control, prop) => this.extractor(control, prop, i)}
                                    propertygrid={this.propertygrid}
                                >
                                </Tag>
                                <v-btn elevation={0} icon small color="blue-grey" title="Move Up" style={btn_styles} disabled={i === 0} on-click={() => this.moveItemUp(i)}><v-icon small>mdi mdi-arrow-up</v-icon></v-btn>
                                <v-btn elevation={0} icon small color="blue-grey" title="Move Down" style={btn_styles} disabled={i == cmodel.length - 1} on-click={() => this.moveItemDown(i)}><v-icon small>mdi mdi-arrow-down</v-icon></v-btn>
                                <v-btn elevation={0} icon small color="blue-grey" title="Delete" style={btn_styles} on-click={() => this.removeItem(i)}><v-icon small>mdi mdi-close</v-icon></v-btn>
                                <span v-show={cmodel.length > 1} class="column-drag-handle" style="float:right; padding:0 10px; margin-top: 5px; cursor: grab;">&#x2630;</span>
                            </span>
                            <br />{item_label}
                        </Draggable>
                    );
                }

            foots.push(
                <span class={{ "property-grid": this.propertygrid }}>
                    <v-btn elevation={0} x-small color="blue-grey" class="white--text" style={btn_styles} on-click={() => this.addAnother()}>Add Another <v-icon small right>mdi mdi-plus</v-icon></v-btn>
                </span>
            );
        }
        else
            heads.push(<span>{cmodel.length} Item(s)</span>);

        return (
            <tr>
                <td style={{ width: "1px", verticalAlign: "top", whiteSpace: "nowrap" }}>
                    {name} {jsontgl} {openclose} {desc}
                </td>
                <td style={tdstyle}>
                    {heads}
                    <Container on-drop={(res) => this.reOrderItems(res)} drag-handle-selector=".column-drag-handle">
                        {input}
                    </Container>
                    {foots}
                </td>
            </tr>
        );
    }
});