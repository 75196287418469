import Vue from 'vue';
import BasicButton from './BasicButton.jsx';
import Text from './Text.jsx';
import Icon from './Icon.jsx';
//import SelectList from './SelectList.jsx';
import BasicForm from './BasicForm.jsx';
import BasicGrid from './BasicGrid.jsx';
import TreeView from './TreeView.jsx';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';
import controlPicker from './controlPicker.jsx';
import BaseStackMixin from './BaseStackMixin.jsx';
import BaseComponentMixin from './BaseComponentMixin.jsx';

Vue.component('stack-vertical-content-dsgn', {
    mixins: [BaseComponentMixin],
    data: function () {
        return {
            pickervisible: false,
            picker: null,
            pickertarget: 'body',
        }
    },
    computed: {
        direction() {
            if (this.controlData.Direction)
                return this.controlData.Direction;
            return 'Normal';
        },
        stackClasses() {
            let classes = [];
            if (this.isVertical) {
                if (this.controlData.HorizontalAlignChildren) {
                    switch (this.controlData.HorizontalAlignChildren.toLowerCase()) {
                        case 'middle': {
                            classes.push('align-center');
                            break;
                        }
                        case 'right': {
                            classes.push('align-end');
                            break;
                        }
                        default:
                            classes.push('align-start');
                            break;
                    }
                }
                else {
                    classes.push('align-start');
                }

                if (this.controlData.VerticalAlignChildren) {
                    switch (this.controlData.VerticalAlignChildren.toLowerCase()) {
                        case 'center': {
                            classes.push('justify-center');
                            break;
                        }
                        case 'bottom': {
                            classes.push('justify-end');
                            break;
                        }
                        case 'spacearound': {
                            classes.push('justify-space-around');
                            break;
                        }
                        case 'spacebetween': {
                            classes.push('justify-space-between');
                            break;
                        }
                        case 'spaceevenly': {
                            classes.push('justify-space-evenly');
                            break;
                        }
                        default:
                            classes.push('justify-start');
                            break;
                    }
                }
                else {
                    classes.push('justify-start');
                }
            }
            else {
                if (this.controlData.VerticalAlignChildren) {
                    switch (this.controlData.VerticalAlignChildren.toLowerCase()) {
                        case 'center': {
                            classes.push('align-center');
                            break;
                        }
                        case 'bottom': {
                            classes.push('align-end');
                            break;
                        }
                        default:
                            classes.push('align-start');
                            break;
                    }
                }
                else {
                    classes.push('align-start');
                }

                if (this.controlData.HorizontalAlignChildren) {
                    switch (this.controlData.HorizontalAlignChildren.toLowerCase()) {
                        case 'middle': {
                            classes.push('justify-center');
                            break;
                        }
                        case 'right': {
                            classes.push('justify-end');
                            break;
                        }
                        case 'spacearound': {
                            classes.push('justify-space-around');
                            break;
                        }
                        case 'spacebetween': {
                            classes.push('justify-space-between');
                            break;
                        }
                        case 'spaceevenly': {
                            classes.push('justify-space-evenly');
                            break;
                        }
                        default:
                            classes.push('justify-start');
                            break;
                    }
                }
                else {
                    classes.push('justify-start');
                }
            }

            return classes
        },
        isVertical() {
            return this.type && (this.type.toLowerCase().includes('vertical') || this.type.toLowerCase() === 'controlcontainer');
        },
        isHorizontal() {
            return this.type && this.type.toLowerCase().includes('horizontal');
        },
        overflowSetting() {
            if (this.isHorizontal && this.parentType === "ContentDef")
                return 'hidden';
            else
                return 'auto';
        },
    },
    mounted() {
    },
    methods: {
        generateItems(h, controlData, items, model, index, parentType) {
            let tempItems = []
            for (let i = 0; i < controlData.Controls.length; i++) {
                const c = controlData.Controls[i];
                if (!(typeof c === 'object'))
                    continue;

                let DynamicControl = utils.getDynamicComponent(h, c);

                if (!DynamicControl)
                    DynamicControl = 'default-unknown';

                DynamicControl += '-dsgn';    

                if (!c.$objectId) c.$objectId = utils.generateUUID();

                let id = `${this.master_key}_${c.$objectId}`;
                if (index) id = `${index}_${id}`;

                tempItems.push(
                    <DynamicControl
                        key={id}
                        type={c.ControlType}
                        name={c.ControlData ? c.ControlData.Name : ''}
                        root={this.root}
                        designmodel={c}
                        parentType={parentType}
                        controlData={c.ControlData}
                        controlURL={c.ControlURL}
                        sourceData={model}
                        sourceIndex={i}>
                    </DynamicControl>
                );
            }
            if (this.direction === 'Reversed')
                tempItems.reverse();
            items.push(...tempItems);
        },
        getHeader(h, controlData) {
            if (controlData.HeaderControls && controlData.HeaderControls.length > 0) {
                const header = [];

                for (let i = 0; i < controlData.HeaderControls.length; i++) {
                    const c = controlData.HeaderControls[i];
                    let DynamicControl = utils.getDynamicComponent(h, c);
                    if (!DynamicControl)
                        DynamicControl = 'default-unknown';

                    DynamicControl += '-dsgn';

                    if (!c.$objectId) c.$objectId = utils.generateUUID();

                    let id = `${this.master_key}_${c.$objectId}_${i}`;

                    header.push(
                        <DynamicControl
                            on={{ 'finished-render': () => this.finishRenderHandler() }}
                            type={c.ControlType}
                            key={id}
                            name={c.ControlData ? (c.ControlData.Name || 'unnamed') : 'unnamed'}
                            root={this.root}
                            designmodel={c}
                            parentType="HorizontalStack"
                            controlData={c.ControlData}
                            controlURL={c.ControlURL}>
                        </DynamicControl>
                    );
                }
                return header;
            }
            return null;
        },
        generateItemsFromModel(h, items, controlData, items_id, parentType) {
            if (controlData.Controls)
                this.generateItems(h, controlData, items, null, items_id, parentType);
        },

        addControl(e, target) {
            this.pickertarget = target;
            this.picker = <control-picker confirm={this.confirmAddControl} cancel={this.cancelAddControl}></control-picker>;
            this.pickervisible = true;
            e.cancelBubble = true;
            e.stopPropagation();
        },
        async confirmAddControl(id, title) {
            this.pickervisible = false;

            try {
                const schema_ = await utils.schema.get(id);
                const schema = utils.schema.resolve_Of(schema_)
                const model = utils.schema.getDefaultModel(schema);

                switch (this.pickertarget) {
                    case 'header':
                        if (!this.controlData.HeaderControls)
                            Vue.set(this.controlData, 'HeaderControls', []);

                        this.controlData.HeaderControls.push(model);
                        // Hmmm. How to select the node, it isn't created yet, not until it is rendered when this layout stack re-renders
                        //this.Root.SelectNode(model);
                        break;

                    case 'body':
                        if (!this.controlData.Controls)
                            Vue.set(this.controlData, 'Controls', []);

                        this.controlData.Controls.push(model);
                        //this.Root.SelectNode(model);
                        break;
                }
            }
            catch (e) {
                alert(e);
            }
        },
        cancelAddControl() {
            this.pickervisible = false;
        },
        pickerInput(value) {
            this.pickervisible = value;
        },
        generatePicker(h) {
            return (
                <v-dialog value={this.pickervisible} max-width="860" scrollable on-input={(value) => this.pickerInput(value)}>
                    {this.picker}
                </v-dialog>
            );
        },
    },
    props: {
        type: null,
        parentType: null,
        asbase: false,
    },
    render(h) {
        let items = [];
        let items_id = '_0';

        let controlData = this.controlData;

        let header = this.getHeader(h, controlData);
        let headerContent = null;
        if (this.selected$ || header) {
            if (this.selected$) {
                if (!header) header = [];
                header.push(
                    <v-btn v-tooltip="Add control to header" x-small icon style={{ zIndex: 992 }} on-click={(e) => this.addControl(e, 'header')}>
                        <v-icon small color="blue darken-2">mdi mdi-plus-circle</v-icon>
                    </v-btn>
                );
            }
            if (header)
                headerContent =
                    <div style={{ display: "flex", flexDirection: "row", flexGrow: "0", flexShrink: "0", padding: "2px", paddingLeft: "8px" }}>
                        {header}
                    </div>;
        }

        this.generateItemsFromModel(h, items, controlData, items_id, this.type);

        if (this.selected$)
            items.push(
                <v-btn v-tooltip="Add control to body" x-small icon style={{ zIndex: 992 }} on-click={(e) => this.addControl(e, 'body')}>
                    <v-icon small color="blue darken-2">mdi mdi-plus-circle</v-icon>
                </v-btn>
            );

        const style = {
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            ...utils.getStyleHints(controlData.StyleHints),
            minWidth: "15px",
            flexGrow: "1",
            flexShrink: "1"
        };

            //...utils.getSize(controlData.SizeOptions, this.parentType, this.$parent),

        const bodyStyle = {
            overflow: this.overflowSetting,
            display: "flex",
            flexDirection: this.isVertical ? "column" : 'row',
            flexGrow: 1,
            gap: this.padding ? this.padding + 'px' : ''
        };

        return (
            <div
                class={{ 'c-StackContent-Dsgn': true, [`c-name-${this.name || 'unnamed'}`]: true, 'designer-container': true, selected: this.selected$ }}
                style={style}
                on-click={(e) => this.handleClick(e)}>
                <div class={{ "designer-container-hovering": true, 'designer-container-selected-clickthrough': this.selected$ }}></div>

                {headerContent}
                <div style={bodyStyle}
                    class={this.stackClasses}>
                    {items}
                </div>

                {this.generatePicker(h)}
            </div>
        );
    }
});