import Vue from 'vue';
import utils from '../../../../Shared/utils.jsx';
import methods from '../../../../Shared/methods';

Vue.component('form-section-dsgn', {
    data: function () {
        return {
        }
    },
    created() {
    },
    computed: {
        ...utils.forms.computed,
        selected$: function () {
            return this.Root.SelectedNode && this.Root.SelectedNode == this;
        },
    },
    methods: {
        ...methods,
        handleClick(e) {
            this.Root.SelectNode(this);
            e.cancelBubble = true;
            e.stopPropagation();
        },
    },
    props: {
        name: '',
        schemakey: '',
        root: null,
        designmodel: null,
        schema: null,
        cmodel: null,
        extra: null,
        readonly: false,
        child: 0,
    },
    render(h) {
        const name = <span style={{ whiteSpace: "nowrap" }}>Section</span>;

        var template = [];
        var rows = [];
        var rowMapped = [];
        var cols = this.schema.ControlData.columns > 0 ? this.schema.ControlData.columns : 1;

        for (var i = 0; i < cols; i++) {
            template.push(undefined);
        }
        
        for (var i = 0; i < this.schema.items.length; i++) {
            var colIndex = i % cols;
            
            if (colIndex == 0) { // new row so make a new row
                rows.push([...template]);
            }
            
            rows[rows.length - 1][colIndex] = this.schema.items[i];
        }

        rowMapped = rows.map((rowElements) => {
            var flds = rowElements.map((fld) => {
                return (<div class="col">{fld.title ? fld.title : fld.key}</div>);
            });

            return (<div class="container designer-form" style={{ padding: 0 }} on-click={(e) => this.handleClick(e)}>
                <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
                <div class="row no-gutters">{ flds }</div>
            </div>);
        });

        return (
            <tr>
                <td>
                    <div class="designer-form" on-click={(e) => this.handleClick(e)}>
                        <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
                        { name }
                    </div>
                </td>
                <td>{rowMapped}</td>
            </tr>
        );
    }
});