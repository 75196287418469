import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

Vue.component('hsm-state-dsgn', {
    data: function () {
        return {

        }
    },
    created() {

    },
    computed: {

    },
    methods: {

    },
    props: {
        state: null,
        parentState: null,
        isSelected: false,
        hsm: null,
        size: 0,
    },
    render(h) {
        const state_style = {
            borderStyle: "solid",
            borderWidth: "1px",
            borderRadius: "5px",
            overflow: "hidden",
        };
        switch (this.size) {
            case 1:
                state_style.maxWidth = "60px";
                state_style.minWidth = "60px";
                state_style.maxHeight = "18px";
                state_style.minHeight = "18px";
                state_style.margin = "5px";
                state_style.padding = "2px";
                break;
            case 2:
                state_style.maxWidth = "160px";
                state_style.minWidth = "160px";
                state_style.maxHeight = "100px";
                state_style.minHeight = "100px";
                state_style.margin = "10px";
                state_style.padding = "5px";
                state_style.display = "flex";
                state_style.flexDirection = "row";
                state_style.flexWrap = "wrap";
                break;
            case 3:
                //state_style.Width = "90%";
                //state_style.Height = "90%";
                state_style.flexGrow = "1";
                state_style.margin = "10px";
                state_style.padding = "10px";
                state_style.display = "flex";
                state_style.flexDirection = "row";
                state_style.flexWrap = "wrap";
                state_style.alignContent = "flex-start";
                state_style.overflow = "auto";
                break;
        }

        const items = [];

        if (this.size > 1 && this.state.ChildStates)
            for (let i = 0; i < this.state.ChildStates.length; i++)
            {
                const child = this.state.ChildStates[i];

                items.push(
                    <hsm-state-dsgn
                        size={this.size - 1}
                        state={child}
                        parentState={this.state}
                        hsm={this.hsm}
                        isSelected={(state) => this.isSelected(state)}>
                    </hsm-state-dsgn>
                );
            }

        const font_sizes = ['', 'xx-small', 'small', 'medium'];

        const title_style = {
            fontSize: font_sizes[this.size],
            width: "100%",
            maxHeight: "24px",
            textAlign: "center",
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
            backgroundColor: "antiquewhite",
        };

        let title;
        if (this.size == 2)
            title = <div style={title_style}><span title="Click to Drill Into" style={{ cursor: "pointer" }} on-click={(e) => this.hsm.drill(e, this.state)}>{this.state.StateName}</span></div>;
        else
            title = <div style={title_style}>{this.state.StateName}</div>;

        return (
            <div
                style={state_style}
                class="designer-hsmstate"
                on-click={(e) => this.hsm.selectState(e, this.state, this.parentState)}>
                <div title={this.state.StateName} class={{ "designer-hsmstate-hovering": true, "designer-hsmstate-selected": this.isSelected(this.state) }}></div>
                {title}
                {items}
            </div>
        );
    }
});

function findParent(root, targetstate) {
    let list;
    if ('States' in root)
        list = root.States;
    else if ('ChildStates' in root)
        list = root.ChildStates;
    else
        return null;

    for (let i = 0; i < list.length; i++)
    {
        const state = list[i];
        if (state == targetstate)
            return root;

        if ('ChildStates' in state)
        {
            const c = findParent(state, targetstate);
            if (c) return c;
        }
    }
    return null;
}

Vue.component('hsm-new-dsgn', {
    data: function () {
        return {
            topstate: null,
            current_state: null,
        }
    },
    created() {
        if (this.controlData.States && this.controlData.States.length === 1)
            this.topstate = this.controlData.States[0];
    },
    destroyed() {
    },  
    computed: {
        Root: function () {
            return this.root._self;
        },
        selected$: function () {
            return this.Root.SelectedNode && this.Root.SelectedModel == this.designmodel;
        },
    },
    methods: {
        ...methods,
        handleClick(e) {
            this.Root.SelectNode(this);
            e.cancelBubble = true;
            e.stopPropagation();
        },
        selectState(e, state, topstate) {
            //if (topstate)
            //    this.topstate = topstate;

            this.Root.SelectNode(this, state);
            this.current_state = state;
            e.cancelBubble = true;
            e.stopPropagation();
        },
        drill(e, state) {
            this.topstate = state;
            this.Root.SelectNode(this, state);
            this.current_state = state;

            e.cancelBubble = true;
            e.stopPropagation();
        },
        navUp(e) {
            const p = findParent(this.controlData, this.topstate);

            if (p && (p.StateName || p.Name)) {
                this.current_state = this.topstate;
                this.topstate = p;

                this.Root.SelectNode(this, this.current_state);
            }
            else if (!p)
            {
                this.current_state = this.topstate;
                this.topstate = null;

                this.Root.SelectNode(this);
            }

            e.cancelBubble = true;
            e.stopPropagation();
        }
    },
    props: {
        name: '',
        root: null,
        designmodel: null,
        parentType: '',
        controlData: {}
    },
    render(h) {
        const items = [];
        let title;

        const size = {
            Width: { Mode: "Fill" },
            Height: { Mode: "Fill" }
        };

        const style = {
            ...utils.getSize(size, this.parentType, this.$parent),
            padding: "5px",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignContent: "flex-start",
        };

        if (!this.topstate || this.topstate == this.controlData) {
            // This renders each top level state as a medium size box
            for (let i = 0; i < this.controlData.States.length; i++) {
                const s = this.controlData.States[i];
                items.push(
                    <hsm-state-dsgn
                        size={2}
                        state={s}
                        hsm={this}
                        isSelected={(state) => this.current_state == state}>
                    </hsm-state-dsgn>
                );
            }

            return (
                <div
                    class={{ 'designer-container': true, selected: this.selected$ }}
                    on-click={(e) => this.handleClick(e)}
                    style={style}>
                    <div class={{ "designer-container-hovering": true, 'designer-hsm-selected': this.selected$ }}></div>
                    <div class="design-options" on-click={(e) => this.navUp(e)} style={{ top: "4px", right: "4px", display: this.topstate ? "block" : "none" }}>Parent</div>
                    {items}
                </div>
            );
        }

        // This renders one top level state full screen
        return (
            <div
                class={{ 'designer-container': true, selected: this.selected$ }}
                on-click={(e) => this.handleClick(e)}
                style={style}>
                <div class={{ "designer-container-hovering": true, 'designer-hsm-selected': this.selected$ }}></div>
                <div class="design-options" on-click={(e) => this.navUp(e)} style={{ top: "4px", right: "4px", display: this.topstate ? "block" : "none" }}>Parent</div>
                <hsm-state-dsgn
                    size={3}
                    state={this.topstate}
                    hsm={this}
                    isSelected={(state) => this.current_state == state}>
                </hsm-state-dsgn>
            </div>
        );
    }
});