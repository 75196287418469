import Vue from 'vue';
import utils from '../../../../Shared/utils.jsx';
import methods from '../../../../Shared/methods';

Vue.component('form-number-dsgn', {
    data: function () {
        return {
        }
    },
    created() {
    },
    computed: {
        ...utils.forms.computed,
        selected$: function () {
            return this.Root.SelectedNode && this.Root.SelectedNode == this;
        },
    },
    methods: {
        ...methods,
        handleClick(e) {
            this.Root.SelectNode(this);
            e.cancelBubble = true;
            e.stopPropagation();
        },
    },
    props: {
        name: '',
        schemakey: '',
        root: null,
        designmodel: null,
        schema: null,
        cmodel: null,
        extra: null,
        readonly: false,
        child: 0,
    },
    render(h) {
        const name = <span style={{ marginLeft: (this.child * 15) + "px", whiteSpace: "nowrap" }}>{this.name}</span>;
        const placeholder = this.schema.placeholder || '';

        let input;
        if (this.readonly)
            input = <input type="number" style={{ width: "100%" }} value={this.cmodel[this.schemakey]} disabled="disabled" placeholder={placeholder} />;
        else
            input = <input type="number" style={{ width: "100%" }} value={this.cmodel[this.schemakey]} placeholder={placeholder} />;

        let desc;
        if (this.schema.description)
            desc = <i class="mdi mdi-information" title={this.schema.description} style={{ color: "silver", fontSize: "small", marginLeft: "3px" }}></i>;

        return (
            <tr>
                <td style={{ width: "1px", "white-space": "nowrap" }}>
                    <div
                        class={{ 'designer-form': true }}
                        on-click={(e) => this.handleClick(e)}>
                        <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
                        {name}{desc}
                    </div>
                </td>
                <td>
                    <div
                        class={{ 'designer-form': true }}
                        on-click={(e) => this.handleClick(e)}>
                        <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
                        {input}
                    </div>
                </td>
            </tr>
        );
    }
});