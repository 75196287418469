import Vue from 'vue';
import BaseComponent from './baseFormMixin.jsx';
import utils from '../../../Shared/utils.jsx';
import { appSettings } from '@/Shared/appSettings.js';

Vue.component('sform-referenceddocumentschema', {
    mixins: [BaseComponent],
    data: () => ({
        control_schema: null,
        form_all: null,
    }),
    props: {
    },
    created() {
        if (this.element.schema)
            this.watcher$ = this.$watch('element.schema.formatData.DocumentName', function (val, oldval) { this.Refresh(); });
    },
    destroyed() {
        if (this.watcher$)
            this.watcher$();
    },
    async mounted() {
        await this.loadComplete();
        await this.Refresh();
    },
    computed: {
    },
    methods: {
        async Refresh() {
            if (!this.element.schema)
                return;

            const doctype = this.element.schema.formatData.DocumentType; // UserControl or UserAction
            const docname = this.element.schema.formatData.DocumentName;

            if (docname) {
                let doc1 = await utils.api.get('Apps/UIWhole/' + doctype + '/' + docname);

                if (!doc1)
                    doc1 = await utils.api.get('Apps/UIWhole/' + doctype + '/public/' + docname);

                switch (doctype) {
                    case 'UserControl': this.control_schema = doc1.ControlDataSchema; break;
                    case 'Action': this.control_schema = doc2.ActionDataSchema; break;
                    default:
                        this.control_schema = null;
                        return;
                }

                if (this.control_schema) {
                    await utils.schema.resolve(this.control_schema);
                    this.form_all = utils.forms.generateFormWithSchema(['*'], this.control_schema);
                }
            }
            else
                this.control_schema = null;
        }
    },
    render(h) {
        if (!this.control_schema || !this.form_all)
            return null;

        return (
            <sform-fieldset
                layouttype="sform-card-layout"
                root={this.root}
                form={this.form_all}
                cmodel={this.itemvalue}
                noinput={this.noinput}
                schema={this.control_schema}
                scopeitems={this.scopeitems}
                controlscope={this.controlscope}
                depth={0}>
            </sform-fieldset>
        );
    }
});