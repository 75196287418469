import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

Vue.component('basic-tab-dsgn', {
    data: function () {
        return {
            tab_state: {
                index: 0
            },
            pickervisible: false,
            picker: null,
        }
    },
    created() {
    },
    computed: {
        Root: function () {
            return this.root._self;
        },
        DesignModel: function () {
            return this.designmodel;
        },
        selected$: function () {
            let anytabs = false;
            if (this.controlData && this.controlData.TabControls)
                for (let i = 0; i < this.controlData.TabControls.length; i++)
                    if (this.Root.SelectedModel == this.controlData.TabControls[i]) {
                        anytabs = true;
                        break;
                    }

            return this.Root.SelectedNode && (this.Root.SelectedModel == this.designmodel || anytabs);
        },
    },
    methods: {
        ...methods,
        handleClick(e) {
            this.Root.SelectNode(this);
            e.cancelBubble = true;
            e.stopPropagation();
        },
        selectTab(e, index) {
            this.tab_state.index = index;
            if ('$typeSchema' in this.controlData.TabControls[index])
                this.Root.SelectNode(this, this.controlData.TabControls[index]);

            e.cancelBubble = true;
            e.stopPropagation();
        },
        async addTab(e) {
            let s = await utils.schema.get('/schema/public/Platform.Schema.DynamicControls.v1/DynamicControl_BasicTab_TabItem');
            s = utils.schema.resolve_Of(s);
            const tabItem = utils.schema.getDefaultModel(s);
            tabItem.Title = 'Untitled';

            if (!this.controlData.TabControls)
                Vue.set(this.controlData, 'TabControls', []);

            this.controlData.TabControls.push(tabItem);
            this.tab_state.index = this.controlData.TabControls.length - 1;
            this.Root.SelectNode(this, tabItem);

            e.cancelBubble = true;
            e.stopPropagation();
        },
        deleteTab(e, index) {
            this.controlData.TabControls.splice(index, 1);
            if (this.tab_state.index >= this.controlData.TabControls.length) {
                this.tab_state.index = this.controlData.TabControls.length - 1;
                if (this.tab_state.index >= 0)
                    this.Root.SelectNode(this, this.controlData.TabControls[this.tab_state.index]);
            }

            e.cancelBubble = true;
            e.stopPropagation();
        },
        addControl(e, target) {
            this.picker = <control-picker confirm={this.confirmAddControl} cancel={this.cancelAddControl}></control-picker>;
            this.pickervisible = true;
            e.cancelBubble = true;
            e.stopPropagation();
        },
        async confirmAddControl(id, title) {
            this.pickervisible = false;

            try {
                const schema_ = await utils.schema.get(id);
                const schema = utils.schema.resolve_Of(schema_)
                const model = utils.schema.getDefaultModel(schema);
                const selectedTab = this.controlData.TabControls[this.tab_state.index];
                if (!selectedTab.Controls)
                    Vue.set(selectedTab, 'Controls', []);

                selectedTab.Controls.push(model);
            }
            catch (e) {
                alert(e);
            }
        },
        cancelAddControl() {
            this.pickervisible = false;
        },
        pickerInput(value) {
            this.pickervisible = value;
        },
    },
    props: {
        name: '',
        type: '',
        root: null,
        designmodel: null,
        parentType: '',
        controlData: {}
    },
    render(h) {
        if (!this.controlData)
            return null;

        let items = [];
        const general_tab_style = {
            float: "left",
            borderTop: "1px solid black",
            borderLeft: "1px solid black",
            borderRight: "1px solid black",
            borderRadius: "5px 5px 0 0",
            maxWidth: "250px",
            minWidth: "50px",
            padding: "2px 5px 1px"
        };

        const tabs = this.controlData.TabControls || [];
        for (let tabi = 0; tabi < tabs.length; tabi++) {
            const tab = tabs[tabi];
            if (!tab.$objectid)
                tab.$objectid = utils.generateUUID();

            const tab_style = {
                ...general_tab_style
            };

            let icon;
            if (tabi === this.tab_state.index) {
                tab_style.backgroundColor = "silver";
                tab_style.fontWeight = "bold";

                if (this.selected$)
                    icon = (
                        <span style={{ color: "maroon", marginLeft: "3px", cursor: "pointer" }}
                            class="designer-container-selected-clickableitem"
                            on-click={(e) => this.deleteTab(e, tabi)}>
                            <i class="mdi mdi-close-circle" title="Delete Tab" />
                        </span>
                    );
            }
            else {
                tab_style.backgroundColor = "gray";
                tab_style.color = "silver";
            }


            items.push(
                <div
                    key={tab.$objectid + '_' + tabi}
                    style={tab_style}
                    class="designer-container-selected-clickableitem"
                    on-click={(e) => this.selectTab(e, tabi)}>
                    <span style={{ cursor: "pointer" }}>{tab.Title}</span>{icon}
                </div>
            );
        }
        if (tabs.length === 0)
            items.push(
                <div
                    key={-1}
                    style={general_tab_style}>
                    <span style={{ cursor: "pointer" }}>Empty Tabs - Click + to add</span>
                </div>
            );

        if (this.selected$) {
            // Include an Add button to add a new tab
            const addtab = (
                <span style={{ marginLeft: "5px", cursor: "pointer", color: "blue" }}>
                    <i class="mdi mdi-plus-circle designer-container-selected-clickableitem" title="Add Tab" on-click={(e) => this.addTab(e)} />
                </span>
            );
            items.push(<div style={{ float: "left" }}> {addtab}</div>);
        }

        const activetab = tabs[this.tab_state.index];
        const body = [];

        if (activetab && activetab.Controls)
            for (var i = 0; i < activetab.Controls.length; i++) {
                const control = activetab.Controls[i];
                let DynamicControl = utils.getDynamicComponent(h, control);

                const _k = i + '_' + control.ControlType + '_' + control.$objectId;

                if (!DynamicControl)
                    DynamicControl = 'default-unknown';

                DynamicControl += '-dsgn';

                body.push(
                    <DynamicControl key={_k} type={control.ControlType} name={control.ControlData.Name} root={this.root} designmodel={control} parentType="VerticalStack" controlData={control.ControlData}>
                    </DynamicControl>
                );
            }

        const style = {
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            minWidth: "25px",
            ...utils.getSize(this.controlData.SizeOptions, this.parentType, this.$parent),
            ...utils.getStyleHints(this.controlData.StyleHints),
        };

        if (this.selected$ && activetab)
            body.push(
                <div class="property-grid">
                    {utils.generateTooltip(h,
                        <v-btn small icon style={{ zIndex: 2 }} on-click={(e) => this.addControl(e, 'body')}>
                            <v-icon small color="blue darken-2">mdi mdi-plus-circle</v-icon>
                        </v-btn>,
                        'Add control to tab', 'right')}
                </div>
            );

        return (
            <div
                class={{ 'designer-container': true, selected: this.selected$ }}
                style={style}
                on-click={(e) => this.handleClick(e)}>
                <div class={{ "designer-container-hovering": true, 'designer-container-selected-clickthrough': this.selected$ }}></div>

                <div>
                    {items}
                </div>

                {body}

                <v-dialog value={this.pickervisible} max-width="860" scrollable on-input={(value) => this.pickerInput(value)}>
                    {this.picker}
                </v-dialog>
            </div>
        );
    }
});