import Vue from 'vue';
import utils from '../../Shared/utils.jsx';
import methods from '../../Shared/methods';
import careHelpfulFunctions from '../careHelpfulFunctions.jsx';
import BaseComponent from './BaseComponentMixin.jsx';
import EventBus from '../event-bus.js';
import dialogBasicModal from './actions/DialogBasicModal.jsx';
import DisplayBasicConfirmModal from './actions/DialogBasicConfirmModal.jsx';
import NotificationHeader from './actions/DisplayNotificationHeader.jsx';
import { SnotifyPosition, SnotifyToast, SnotifyToastConfig } from 'vue-snotify';
import api from '@/Services/api';
import token from '@/Services/token.jsx';
import { appSettings } from '@/Shared/appSettings';
import careService from '@/Services/careService.jsx';
import html2canvas from 'html2canvas';
import ChildWithTokenOpener from './ChildWithTokenOpener';
Vue.component('child-with-token-opener', ChildWithTokenOpener);
import cachingService from '@/Services/cachingService';
import apiService from '@/Services/api';
import { clone } from 'lodash';

const cloneDeep = require('lodash.clonedeep');

export default Vue.component('action-service', {
    data: function () {
        return {
            current_dialog: 0,
            dialog_content: [null, null, null, null, null],
            dialog_data: [null, null, null, null, null],
            dialog_visible: [false, false, false, false, false],
            toastitems: [],
            show_overlay: false,
            overlay: { opacity: undefined }
        }
    },

    created() {
        appSettings.careActionService = this;

        EventBus.$on('v-toast', this.toast);

        EventBus.$on('Action-Alert', this.performAlert);
        EventBus.$on('Action-ApiRequest', this.performAPI);
        EventBus.$on('Action-Broadcast', this.performBroadcast);
        EventBus.$on('Action-OpenChildWindow', this.performOpenChildWindow);
        EventBus.$on('Action-ClosePopup', this.performClosePopup);
        EventBus.$on('Action-DeleteValue', this.performDeleteValue);
        EventBus.$on('Action-DisplayBasicModal', this.performDisplayBasicModal);
        EventBus.$on('Action-DisplayConfirmModal', this.performDisplayConfirmModal);
        EventBus.$on('Action-DisplayPopup', this.performDisplayPopup);
        EventBus.$on('Action-EvaluateExpression', this.performEvaluateExpression);
        EventBus.$on('Action-FileDownload', this.performDownload);
        EventBus.$on('Action-FlushControlCache', this.performFlushControlCache);
        EventBus.$on('Action-FlushUrlCache', this.performFlushUrlCache);
        EventBus.$on('Action-ForEach', this.performForEach);
        EventBus.$on('Action-IfElse', this.performIfElse);
        EventBus.$on('Action-LogEntry', this.performLogEntry);
        EventBus.$on('Action-ReferenceUserAction', this.performReferenceUserAction);
        EventBus.$on('Action-RunActions', this.performRunActions);
        EventBus.$on('Action-SetValue', this.performSetValue);
        EventBus.$on('Action-UserIdleReset', this.performUserIdleReset);
        EventBus.$on('Action-ShowWaitOverlay', this.performShowWaitOverlay);
        EventBus.$on('Action-HideWaitOverlay', this.performHideWaitOverlay);
        EventBus.$on('Action-Sleep', this.performSleep);
        EventBus.$on('Action-Screenshot', this.performScreenshot);
        EventBus.$on('Action-HelpTourStop', this.performHelpTourStop);
        EventBus.$on('Action-SetLanguage', this.performSetLanguage);
        EventBus.$on('Action-RunControlCommand', this.performRunControlCommand);
        EventBus.$on('Action-OpenDocumentEditor', this.performOpenDocumentEditor);
        EventBus.$on('Action-ValidateForm', this.performValidateForm);

        //temporary until we implement
        EventBus.$on('Action-Badge', this.performBadge);

    },
    destroyed() {
        EventBus.$off('v-toast', this.toast);

        // hot reload runs destroyed, but created does not get re-run, so if we do this, we lose all subscriptions after hot reload (testing....)
        EventBus.$off('Action-Alert', this.performAlert);
        EventBus.$off('Action-ApiRequest', this.performAPI);
        EventBus.$off('Action-Broadcast', this.performBroadcast);
        EventBus.$off('Action-OpenChildWindow', this.performOpenChildWindow);
        EventBus.$off('Action-ClosePopup', this.performClosePopup);
        EventBus.$off('Action-DeleteValue', this.performDeleteValue);
        EventBus.$off('Action-DisplayBasicModal', this.performDisplayBasicModal);
        EventBus.$off('Action-DisplayConfirmModal', this.performDisplayConfirmModal);
        EventBus.$off('Action-DisplayPopup', this.performDisplayPopup);
        EventBus.$off('Action-EvaluateExpression', this.performEvaluateExpression);
        EventBus.$off('Action-FileDownload', this.performDownload);
        EventBus.$off('Action-FlushControlCache', this.performFlushControlCache);
        EventBus.$off('Action-FlushUrlCache', this.performFlushUrlCache);
        EventBus.$off('Action-ForEach', this.performForEach);
        EventBus.$off('Action-IfElse', this.performIfElse);
        EventBus.$off('Action-LogEntry', this.performLogEntry);
        EventBus.$off('Action-ReferenceUserAction', this.performReferenceUserAction);
        EventBus.$off('Action-RunActions', this.performRunActions);
        EventBus.$off('Action-SetValue', this.performSetValue);
        EventBus.$off('Action-UserIdleReset', this.performUserIdleReset);
        EventBus.$off('Action-ShowWaitOverlay', this.performShowWaitOverlay);
        EventBus.$off('Action-HideWaitOverlay', this.performHideWaitOverlay);
        EventBus.$off('Action-Sleep', this.performSleep);
        EventBus.$off('Action-Screenshot', this.performScreenshot);
        EventBus.$off('Action-HelpTourStop', this.performHelpTourStop);
        EventBus.$off('Action-SetLanguage', this.performSetLanguage);
        EventBus.$off('Action-RunControlCommand', this.performRunControlCommand);
        EventBus.$off('Action-OpenDocumentEditor', this.performOpenDocumentEditor);
        EventBus.$off('Action-ValidateForm', this.performValidateForm);
    },
    methods: {
        toast(info) {
            const settings = {
                bodyMaxLength: 100,
                titleMaxLength: 40,
                backdrop: -1,
                position: SnotifyPosition.rightBottom,
                timeout: info.timeout == 0 ? 0 : info.timeout || 5000,
                showProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                id: info.name,
            };

            let toast;
            switch (info.type) {
                case 'success': toast = this.$snotify.success(info.subtext, info.text, settings); break;
                case 'info': toast = this.$snotify.info(info.subtext, info.text, settings); break;
                case 'warning': toast = this.$snotify.warning(info.subtext, info.text, settings); break;
                case 'error': toast = this.$snotify.error(info.subtext, info.text, settings); break;
            }

            if (info.ontap)
                toast.on('click', (t) => {
                    utils.executeAndCompileAllActions(info.ontap, { Title: t.title, Body: t.body }, info.context);
                });

            if (info.onhide)
                toast.on('hidden', (t) => {
                    utils.executeAndCompileAllActions(info.onhide, { Title: t.title, Body: t.body }, info.context);
                });
        },
        removetoast(id) {
            this.$snotify.remove(id);
        },

        getNextDialog() {
            for (let index = 0; index < this.dialog_visible.length; index++)
                if (!this.dialog_visible[index] && this.dialog_content[index] === null)
                    return index;

            throw 'Dialogs are all in use!';
        },
        releaseDialog() {
            if (this.current_dialog >= 0 && this.current_dialog < this.dialog_visible.length) {
                this.dialog_content[this.current_dialog] = null;
                this.dialog_data[this.current_dialog] = null;
                this.dialog_visible[this.current_dialog] = false;
            }
        },

        dialog_close(index) {
            Vue.set(this.dialog_visible, index, false);
            
            // After 300ms, clear the content to allow everything within to be disposed
            setTimeout(() => { Vue.set(this.dialog_content, index, null) }, 300);
        },

        displayToast(action, successful, res) {
            // Note: To send a toast from outside this unit, emit v-toast through the event bus:
            // EventBus.$emit('v-toast', { ... });

            if (action.ActionData.DefaultToast) {
                if (successful)
                    this.toast({ text: 'Success', subtext: 'The operation completed successfully', type: 'success', icon: 'mdi mdi-check' });
                else
                    this.toast({
                        text: 'Error',
                        subtext: res ? res.StatusText : '',
                        type: 'error',
                        icon: 'mdi mdi-alert',
                        ontap: [
                            {
                                "PrimitiveAction": true,
                                "ActionType": "DisplayConfirmModal",
                                "RunAsync": false,
                                "ActionData": {
                                    "OkayButtonText": "Okay",
                                    "CancelButtonText": "Cancel",
                                    "Debug": {},
                                    "Title": "Error " + (res ? res.Status : ''),
                                    "Message": res ? res.StatusText : ''
                                }
                            }
                        ],
                        context: action.context,
                    });
            }
            else if (action.ActionData.DefaultToast === false && action.ActionData.Toast) {
                let toast = successful ? action.ActionData.Toast.SuccessToast : action.ActionData.Toast.ErrorToast;
                if (!toast)
                    return;

                if (toast.Title || toast.Message) {
                    action.context.Input = { ...res };
                    const title = toast.title ? utils.evaluate(toast.title, action.context) : 'Saved';
                    const message = toast.title ? utils.evaluate(toast.message, action.context) : '';

                    this.toast({ text: title, subtext: message, type: successful ? 'success' : 'error', ontap: toast.OnTapActions, context: action.context });
                }
            }
        },

        async performAPI(action) {
            //utils.log(`ActionService API ${action.ActionData.URL}`);

            if (action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            let input;

            try {
                let headers;
                if (action.headers_fn)
                    // Evaluates to an array of Name, Value
                    headers = utils.evaluateObject(action.headers_fn, action.context);
                else if (action.headers_list) {
                    // Construct an array of Name, Value
                    headers = [];
                    for (let i = 0; i < action.headers_list.length; i++) {
                        const h = action.headers_list[i];
                        const n = utils.evaluate(h.namefn, action.context);
                        const v = utils.evaluate(h.valuefn, action.context);
                        headers.push({
                            Name: n,
                            Value: v,
                        });
                    }
                }

                let headers_obj;
                if (headers) {
                    // Transform the headers Name Value array into an object
                    headers_obj = {};
                    for (let i = 0; i < headers.length; i++)
                        headers_obj[headers[i].Name] = headers[i].Value;
                }

                let apiRequest = {
                    method: utils.evaluate(action.method, action.context),
                    url: utils.evaluate(action.url, action.context),
                    data: action.data ? utils.evaluateObject(action.data, action.context) : null,
                    headers: headers_obj,
                    flatten: false,
                    addAccessTokenInHeader: action.ActionData.IncludeAccessToken,
                    doNotUseWebsocket: action.ActionData.DoNotUseWebsocket,
                };

                let res;
                let provider;
                if (action.apiprovider)
                    provider = utils.evaluate(action.apiprovider, action.context);

                if (provider && provider.APIRequest)
                    // Optionally defer the API to another implementation (RTEventHandler is one)
                    res = await provider.APIRequest(apiRequest);
                else
                    res = await api.apiRequest(apiRequest);

                input = {
                    Status: res.status || '200',
                    StatusText: res.statusText || 'Success',
                    Data: (res.data && typeof res.data == 'object' && 'Result' in res.data) ? res.data.Result : res.data,
                    Output: res.data?.Output,
                    RawResult: res,
                };

                this.displayToast(action, true, input);

                try {
                    await utils.success(action, input);
                } catch (e) { }
            }
            catch (e) {
                // SignalR backwards compatability
                var statusCode = e.StatusCode ? e.StatusCode : e.statusCode;
                var reasonPhrase = e.ReasonPhrase ? e.ReasonPhrase : e.reasonPhrase;

                this.displayToast(action, false, { Status: statusCode, StatusText: reasonPhrase || e.statusText});

                try {
                    await utils.failure(action, { Status: statusCode, StatusText: reasonPhrase || e.statusText, RawResult: e  });
                } catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action, input);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
        async performAlert(action) {
            utils.log(`ActionService Alert ${action.ActionData.Message}`);

            if (action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            try {
                const msg = utils.evaluate(action.message, action.context);

                alert(msg);
            }
            catch (e) {
                utils.error(`ActionService Alert ${action.ActionData.Message} failed: ${e}`);
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
        async performBroadcast(action) {
            utils.log(`ActionService Broadcast ${action.ActionData.Message} ${action.ActionData.MessageData}`);

            if (action.ActionData && action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            try {
                const message = utils.evaluate(action.message, action.context);
                const messagedata = utils.evaluateObject(action.messagedata, action.context);

                utils.debug(`Broadcast MessageData:${messagedata}`);

                EventBus.$emit(message, messagedata);

                try {
                    await utils.success(action);
                } catch (e) { }
            }
            catch (e) {
                try {
                    await utils.failure(action, { Data: e });
                } catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
        async performOpenChildWindow(action){
            if (action.ActionData && action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            try {
                let targetWindowName = '_blank';
                let url = '';
                let specs = '';

                if(action.targetWindowName)
                    targetWindowName = utils.evaluate(action.targetWindowName, action.context);
                if(action.URL)
                    url = utils.evaluate(action.URL, action.context);
                if(action.specs)
                    specs = utils.evaluate(action.specs, action.context);

                // because child windows inherit a copy of the parent's sessionStorage, we throw a value in that tells our system to clear the storage.
                // this is because we don't want the access token inhertied to the child window giving it a security context when that wasn't intended.
                if (window.sessionStorage)
                {
                    window.sessionStorage.setItem("ChildWindowClearSessionStorage", "true");
                }

                window.open(url, targetWindowName, specs);

                if (window.sessionStorage)
                {
                    window.sessionStorage.removeItem("ChildWindowClearSessionStorage");
                }

                try {
                    await utils.success(action);
                } catch (e) { }
            }
            catch (e) {
                try {
                    await utils.failure(action, { Data: e });
                } catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
        async performDisplayPopup(action) {
            utils.log(`ActionService DisplayPopup ${action.ActionData.Title} ${action.ActionData.Body}`);

            if (action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            try {
                this.toast({
                    name: action.ActionData.Name,
                    text: action.title ? utils.evaluate(action.title, action.context) : '',
                    subtext: action.body ? utils.evaluate(action.body, action.context) : '',
                    timeout: action.timeout ? (action.timeout.code == 0 ? 0 : (utils.evaluate(action.timeout, action.context) || 5000)) : 5000,
                    type: action.ActionData.Type,
                    ontap: action.ActionData.TapActions,
                    onhide: action.ActionData.HiddenActions,
                    context: action.context
                });

                try {
                    await utils.success(action);
                } catch (e) { }
            }
            catch (e) {
                try {
                    await utils.failure(action, { Data: e });
                } catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
        async performDownload(action) {
            utils.log(`FileDownload ${action.ActionData.FileName}`);

            if (action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            try {
                const fileURL = utils.evaluate(action.fileURL, action.context);
                const target = utils.evaluate(action.target, action.context);
                const filename = utils.evaluate(action.filename, action.context);

                let url = api.standardizeUrl(fileURL);

                //New way of downloading - this instructs the browser to treat the URL as something to donwload (as opposed to navigating to it)
                url += (url.indexOf('?') > 0 ? '&' : '?');
                url += 'access_token=' + encodeURIComponent(token.AccessToken());
                // Construct the <a> element
                let link = document.createElement("a");
                link.download = filename;
                if (target)
                    link.target = target;
                // Construct the uri for data download. This is disabled for now, but we might want to use it later. It allows us to download raw data from a variable instead of using a URL.
                //var uri = 'data:application/octet-stream;charset=utf-8;base64,' + btoa(action.ActionData.data)
                link.href = url;
                document.body.appendChild(link); //We append the link to the body for Firefox compatibility.
                link.click();
                // Cleanup the DOM
                document.body.removeChild(link);
                
                try {
                    await utils.success(action);
                } catch (e) { }
            }
            catch (e) {
                this.displayToast(action, false, e.response);

                try {
                    await utils.failure(action, { Status: e.status, StatusText: e.response.statusText });
                } catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
        async performClosePopup(action) {
            utils.log(`ActionService ClosePopup ${action.ActionData.Title} ${action.ActionData.Body}`);

            if (action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            try {
                const id = utils.evaluate(action.popupname, action.context);
                this.removetoast(id);

                try {
                    await utils.success(action);
                } catch (e) { }
            }
            catch (e) {
                try {
                    await utils.failure(action, { Data: e });
                } catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
        async performSetValue(action) {
            utils.log(`ActionService SetValue ${action.ActionData.Field} = ${action.ActionData.Value} (code:${action.expression.code})`);

            if (action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            try {
                let value = utils.evaluateObject(action.value, action.context);

                if (action.ActionData.DeepCopy)
                    value = cloneDeep(value);

                action.expression.func(value, action.context, careHelpfulFunctions, Vue);

                try {
                    await utils.success(action);
                }
                catch (e) { }
            }
            catch (e) {
                utils.warn(`SetValue failed, value: ${action.ActionData.Value}; compiled statement: ${action.expression.code}`, e);

                try {
                    await utils.failure(action);
                }
                catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
        async performDeleteValue(action) {
            utils.log(`ActionService DeleteValue ${action.ActionData.IsArray ? 'IsArray' : 'IsObject'} ${action.ActionData.PropertyName || action.ActionData.ArrayIndex} (code:${action.expr.code})`);

            if (action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            try {
                let value;
                if (action.propname)
                    value = utils.evaluate(action.propname, action.context);

                action.expr.func(value, action.context, careHelpfulFunctions, Vue);
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
        async performEvaluateExpression(action) {
            utils.log(`ActionService EvaluateExpression ${action.ActionData.Expression}`);

            if (action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            try {
                if (action.ActionData.ResolveActionPromiseManually) {
                    // The code inside action.expression will manually call
                    // ActionPromiseResolve or ActionPromiseReject. We don't
                    // need to wait around as that will trigger the FinishFunc
                    // which is normally called below. So we will exit now.

                    action.context.ActionPromiseResolve = async function (result) {
                        //utils.log(`*** Succeeded invoking Action ${action.ActionType} ActionData:${JSON.stringify(action.ActionData)}`);
                        try {
                            await utils.success(action, { Data: result });
                        }
                        catch (e) { }
                        try {
                            await utils.complete(action);
                        }
                        catch (e) { }

                        action.FinishFunc(true, result);
                    };
                    action.context.ActionPromiseReject = async function (result) {
                        utils.log(`*** Failed invoking Action ${action.ActionType} ActionData:${JSON.stringify(action.ActionData)}`);
                        try {
                            await utils.failure(action, { Data: result });
                        }
                        catch (e) { }
                        try {
                            await utils.complete(action);
                        }
                        catch (e) { }

                        action.FinishFunc(false, result);
                    };
                }

                let result;
                if (!action.ActionData.Expression || action.ActionData.Expression.includes('PlantUML')) // Ignore all references to PlantUML since they involve referencing old Angular code structures that do not exist
                    result = true;
                else
                    result = utils.evaluate(action.expression, action.context);

                if (action.ActionData.ResolveActionPromiseManually)
                    // Bail here since the success/failure/complete and FinishFunc will be triggered
                    // when the Javascript explicitly calls ActionPromiseResolve or ActionPromiseReject
                    return;

                if (result)
                    await utils.success(action, { Data: result });
                else
                    await utils.failure(action, { Data: result });
            }
            finally {
                if (!action.ActionData.ResolveActionPromiseManually) {

                    try {
                        await utils.complete(action);
                    }
                    catch (e) { }

                    // Complete the promise for the executeAction method
                    action.FinishFunc(true);
                }
            }
        },
        async performIfElse(action) {
            utils.log(`ActionService IfElse (${action.ActionData.Expressions.length} expression(s))`);

            if (action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            try {
                for (let i = 0; i < action.ActionData.Expressions.length; i++) {
                    const e = action.ActionData.Expressions[i];
                    let result = !e.expression || utils.evaluate(e.expression, action.context)
                    if (result) {
                        //utils.log(` IfElse (${e.Expression}) condition met, invoking ${e.Actions ? e.Actions.length : 0} action(s)`);
                        await utils.executeAndCompileAllActions(e.Actions, { Result: result }, action.context);
                        break;
                    }
                    //else
                    //    utils.log(` IfElse (${e.Expression}) condition not met`);
                }

                await utils.success(action);
            }
            catch (e) {
                try {
                    await utils.failure(action, { Data: e });
                } catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
        async performDisplayConfirmModal(action) {
            utils.log(`ActionService DisplayConfirmModal`);

            if (action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            const index = this.getNextDialog();
            const key = utils.generateUUID();

            // Function to be called when the dialog is closed, this will complete
            // the DisplayBasicModal action and pass back the dialog result.
            const handleResult = async (result) => {
                this.dialog_close(index);

                if (result && result.Success)
                    try {
                        await utils.success(action, { Success: true, DialogResult: result.DialogResult });
                    } catch (e) { }
                else
                    try {
                        await utils.failure(action, { Success: false, DialogResult: result.DialogResult });
                    } catch (e) { }

                try {
                    await utils.complete(action);
                }
                catch (e) { }

                action.FinishFunc(true);
            };

            Vue.set(this.dialog_content, index, (
                <display-basic-confirm-modal
                    key={key}
                    name={action.ActionData.Name}
                    type="BasicConfirmModal"
                    isbase={true}
                    root={action.context.root || 'it failed'}
                    action={action}
                    context={action.context}
                    c_context={action.context}
                    controlData={{}}
                    on-closeme={handleResult}
                    scopeitems={action.context.scopeitems}
                    controlscope={action.context.controlscope}
                >
                </display-basic-confirm-modal>
            ));
            Vue.set(this.dialog_visible, index, true);
            Vue.set(this.dialog_data, index, action);
            this.current_dialog = index;
        },
        async performDisplayBasicModal(action) {
            utils.log(`ActionService DisplayBasicModal`);

            if (action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            const index = this.getNextDialog();
            const key = utils.generateUUID();

            // Function to be called when the dialog is closed, this will complete
            // the DisplayBasicModal action and pass back the dialog result.
            const handleResult = async (result) => {
                this.dialog_close(index);

                // Store this so a ReferencedUserAction can read it
                action.Input = result;

                if (result && result.Success)
                    try {
                        await utils.success(action, { DialogResult: result.DialogResult });
                    } catch (e) { }
                else
                    try {
                        await utils.failure(action, { DialogResult: result.DialogResult });
                    } catch (e) { }

                try {
                    await utils.complete(action, { DialogResult: result.DialogResult });
                }
                catch (e) { }

                action.FinishFunc(true);
            };

            Vue.set(this.dialog_visible, index, true);
            Vue.set(this.dialog_data, index, action.ActionData);
            Vue.set(this.dialog_content, index, (
                <display-basic-modal
                    key={key}
                    name={action.ActionData.Name}
                    type="BasicModal"
                    isbase={true}
                    root={action.context.root || 'it failed'}
                    action={action}
                    context={action.context}
                    c_context={action.context}
                    controlData={{}}
                    on-closeme={result => this.performConfirmCloseDialog(result, handleResult)}
                    scopeitems={action.context.scopeitems}
                    controlscope={action.context.controlscope}
                >
                </display-basic-modal>
            ));
            this.current_dialog = index;
        },
        async performConfirmCloseDialog(result, doClose) {
            if (result.AllowClose) {
                doClose(result);
                return;
            }

            const index = this.getNextDialog();
            const key = utils.generateUUID();

            // Function to be called when the dialog is closed, this will complete
            // the DisplayBasicModal action and pass back the dialog result.
            const handleResult = async (r) => {
                this.dialog_close(index);

                if (r.Success)
                    doClose(result);
            };

            Vue.set(this.dialog_content, index, (
                <v-card key={key} on-closeme={handleResult}>
                    <v-app-bar flat color="rgba(0, 0, 0, 0)" style={{ backgroundColor: 'var(--v-navigation-base)', maxHeight: '60px', height: '60px' }}>
                        <v-toolbar-title class="title white--text pl-0">
                            Unsaved Changes
                        </v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-btn elevation={0} color="white" icon on-click={(e) => handleResult({ Success: false })}>
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-app-bar>

                    <v-card-text class="mt-2">
                        Are you sure you want to exit without saving?
                    </v-card-text>

                    <v-card-actions style="justify-content: flex-end;">
                        <v-btn color="primary" elevation={0} outlined on-click={(e) => handleResult({ Success: false })}>Cancel</v-btn>
                        <v-btn color="primary" elevation={0} on-click={(e) => handleResult({ Success: true })}>Confirm</v-btn>
                    </v-card-actions>
                </v-card>
            ));
            Vue.set(this.dialog_visible, index, true);
            this.current_dialog = index;
        },
        async performRunActions(action) {
            utils.log(`ActionService RunActions`);

            if (action.ActionData && action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            try {
                let actions = utils.evaluateObject(action.actions, action.context);
                let context = action.context;
                if (action.newcontext)
                    context = utils.evaluateObject(action.newcontext, action.context);

                await utils.executeAndCompileAllActions(actions, {}, context);

                try {
                    await utils.success(action);
                } catch (e) { }
            }
            catch (e) {
                try {
                    await utils.failure(action);
                } catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }

            // Complete the promise for the executeAction method
            action.FinishFunc(true);
        },
        async performForEach(action) {
            utils.log(`ActionService ForEach ${action.ActionData.Source}`);

            if (action.ActionData && action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            try {
                let source;
                if (action.source)
                    source = utils.evaluateObject(action.source, action.context);

                if (source && typeof source === 'object' && Array.isArray(source))
                    for (let i = 0; i < source.length; i++)
                        await utils.executeAndCompileAllActions(action.ActionData.Actions, { Data: source[i] }, action.context);
                else if (source && typeof source === 'object')
                    for (let key in source)
                        await utils.executeAndCompileAllActions(action.ActionData.Actions, { Data: source[key] }, action.context);

                try {
                    await utils.success(action);
                } catch (e) { }
            }
            catch (e) {
                try {
                    await utils.failure(action, { Status: e.status, StatusText: e.statusText });
                } catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
        async performLogEntry(action) {
            utils.log(`ActionService LogEntry ${action.ActionData.Message}`);

            if (action.ActionData && action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            try {
                const message = action.message ? utils.evaluate(action.message, action.context) : '';

                switch (action.ActionData.LogLevel) {
                    case 'Info': utils.log(message); break;

                    case 'Debug': utils.debug(message); break;

                    case 'Error': utils.error(message); break;
                }

                try {
                    await utils.success(action, { Data: message });
                } catch (e) { }
            }
            catch (e) {
                try {
                    await utils.failure(action, { Status: e.status, StatusText: e.statusText });
                } catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
        async performFlushControlCache(action) {
            cachingService.clearCache('APICache');

            action.FinishFunc(true);
        },
        async performFlushUrlCache(action) {
            cachingService.clearCache('APICache');

            action.FinishFunc(true);
        },
        async performUserIdleReset(action) {
            action.FinishFunc(true);
        },
        async performReferenceUserAction(action) {
            utils.log(`ActionService ReferenceUserAction ${action.ActionData.UserAction.ActionURL}`);

            if (action.ActionData && action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            try {
                let input;

                if (action.referencedAction) {
                    // This version was compiled up front (utils.compileAction)
                    await utils.executeAction(action.referencedAction, action.context.Input, action.context);

                    input = action.referencedAction.compiled().Input;
                }
                else {
                    // The up front compile was unsuccessful, so we are doing it now
                    const url = utils.evaluate(action.actionURL, action.context);

                    let apiRequest = {
                        method: 'GET',
                        url: `Document/Action/${url}`,
                        doNotUseWebsocket: true,
                        flatten: true,
                        cache: action.ActionData.UserAction.CacheAction
                    };

                    const res = await apiService.apiRequest(apiRequest);

                    await utils.compileAction(action.context, res);

                    if (action.ActionData.UserAction.ActionData) {
                        // Get the compiled action from the newly compiled version so that I can save essential data in it
                        const resolved_compiled = res.compiled();

                        // Build a function that will generate action data to be read as ParamByName(...)
                        resolved_compiled.actiondatafunc = utils.compileObject(action.context, action.ActionData.UserAction.ActionData);
                        //resolved_compiled.preactionExpn = actionExpn;
                    }

                    await utils.executeAction(res, action.context.Input, action.context);

                    input = res.compiled().Input;
                }

                try {
                    if (!input || !(typeof input === 'object') || !('Success' in input) || input.Success)
                        await utils.success(action, input);
                    else
                        await utils.failure(action, input);
                } catch (e) { }
            }
            catch (e) {
                try {
                    await utils.failure(action);
                } catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
        async performShowWaitOverlay(action) {
            utils.log(`ActionService ShowWaitOverlay`);

            if (action.ActionData && action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            try {
                if (action.ActionData.OpacityLevel)
                    this.overlay.opacity = action.ActionData.OpacityLevel;
                else
                    this.overlay.opacity = undefined;

                this.show_overlay = true;

                if (action.ActionData.TimedOverlay && action.ActionData.Timer > 0)
                    setTimeout(() => { this.show_overlay = false; }, action.ActionData.Timer);

                try {
                    await utils.success(action);
                } catch (e) { }
            }
            catch (e) {
                try {
                    await utils.failure(action);
                } catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
        async performHideWaitOverlay(action) {
            utils.log(`ActionService HideWaitOverlay`);

            if (action.ActionData && action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            try {
                this.show_overlay = false;

                try {
                    await utils.success(action);
                } catch (e) { }
            }
            catch (e) {
                try {
                    await utils.failure(action);
                } catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
        async performSleep(action) {
            utils.log(`ActionService Sleep Duration:${action.ActionData.Duration}`);

            if (action.ActionData && action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            if (action.ActionData.Duration > 0)
                setTimeout(async () => {
                    utils.debug(`Sleep expired`);

                    try {
                        await utils.success(action);
                    } catch (e) { }

                    try {
                        await utils.complete(action);
                    }
                    catch (e) { }

                    // Complete the promise for the executeAction method
                    action.FinishFunc(true);
                }, action.ActionData.Duration);
        },
        async performScreenshot(action) {
            utils.log(`ActionService Screenshot`);

            if (action.ActionData && action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            const screen = action.context.Root.$refs.mainscreen.$el;

            try {
                const canvas = await html2canvas(screen, {
                    backgroundColor: '#FFFFFF',
                    useCORS: true
                });

                if (navigator.msSaveBlob) { // IE10+ 
                    try {
                        await utils.failure(action, { Error: 'IE is not supported' });
                    } catch (e) { }
                }
                else {
                    let imageurl = canvas.toDataURL('image/png');

                    try {
                        await utils.success(action, { ImageUrl: imageurl });
                    } catch (e) { }
                }
            }
            catch (e) {
                try {
                    await utils.failure(action, { Error: e });
                } catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
        //temporary until we implement badge
        async performBadge(action) {
            action.FinishFunc(true);
        },
        async performHelpTourStop(action) {
            action.FinishFunc(true);
        },
        async performSetLanguage(action) {
            utils.log(`ActionService SetLanguage ${action.ActionData.Language}`);

            if (action.ActionData && action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            const lang = utils.evaluate(action.language, action.context);

            try {
                careService.setSelectedLanguage(lang);

                try {
                    await utils.success(action);
                } catch (e) { }
            }
            catch (e) {
                try {
                    await utils.failure(action, { Error: e });
                } catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
        async performRunControlCommand(action) {
            utils.log(`ActionService RunControlCommand ${action.ActionData.ControlName} ${action.ActionData.CommandName}`);

            if (action.ActionData && action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            try {
                const controlName = utils.evaluate(action.controlName, action.context);
                const commandName = utils.evaluate(action.commandName, action.context);
                const input = utils.evaluate(action.input, action.context);

                if (action.context.Control[controlName] && action.context.Control[controlName][commandName]) {
                    const cmd = action.context.Control[controlName][commandName];
                    if (cmd && cmd.Actions && Array.isArray(cmd.Actions))
                        await utils.executeAndCompileAllActions(cmd.Actions, input, action.context, cmd.ControlScope, cmd.ScopeItems);

                    try {
                        await utils.success(action);
                    } catch (e) { }
                }
                else
                    try {
                        await utils.failure(action, { Error: `ControlName:${controlName} and CommandName:${commandName} not found` });
                    } catch (e) { }

            }
            catch (e) {
                try {
                    await utils.failure(action, { Error: e });
                } catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
        async performOpenDocumentEditor(action) {
            utils.log(`ActionService OpenDocumentEditor ${action.ActionData.DocumentName || action.ActionData.DocumentURL} ${action.ActionData.DocumentType}`);

            if (action.ActionData && action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            try {
                const docName = utils.evaluate(action.docName, action.context);
                const docUrl = utils.evaluate(action.docUrl, action.context);
                const docType = utils.evaluate(action.docType, action.context);
                const folder = utils.evaluate(action.defaultFolder, action.context);
                const menuId = utils.evaluate(action.menuId, action.context);
                const getDocumentBy = utils.evaluate(action.getDocumentBy, action.context);

                utils.openForLocalEdit({ Name: docName, URL: docUrl, Type: docType, Folder: folder, Id: menuId, GetDocumentBy: getDocumentBy });

                try {
                    await utils.success(action);
                } catch (e) { }
            }
            catch (e) {
                try {
                    await utils.failure(action, { Error: e });
                } catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
        async performValidateForm(action) {
            utils.log(`ActionService ValidateForm`);

            if (action.ActionData && action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            try {
                // Find all children that are listening for ValidateForm (all BasicForms)
                const targets = action.context.Base.FindAllChildrenWithEventListener(`Action-${action.ActionType}`);
                const all_errors = [];
                for (let i = 0; i < targets.length; i++) {
                    const form = targets[i];
                    const errors = form.Validate();
                    for (let j = 0; j < errors.length; j++)
                        all_errors.push(errors[j]);

                    utils.log(`BasicForm ${form.name} validation discovered ${errors.length} error(s) [${errors.map(e => e.element + ':' + e.error).join('; ')}]`);
                }

                try {
                    if (all_errors.length === 0)
                        await utils.success(action);
                    else
                        await utils.failure(action, { Errors: all_errors });
                } catch (e) { }
            }
            catch (e) {
                utils.warn(`ActionService ValidateForm failed to validate`, e);

                try {
                    await utils.failure(action);
                } catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },

        actionBroadcaster(broadcast) {
            utils.executeAndCompileAllActions(broadcast.actions, broadcast.input, broadcast.parent || broadcast.context)
        }
    },
    props: {
        app: null,
    },
    render(h) {
        const dialogs = this.dialog_content.map((dialogContent,index) => 
            h("v-dialog", {
                props:{
                    value: this.dialog_visible[index],
                    scrollable: true,
                    persistent: true,
                    'content-class': this.dialog_data[index]?.AllowResize ? 'dialog-resize' : 'dialog-size',
                    width: 735,
                    fullscreen: this.dialog_data[index]?.StartMaximized
                },
                on: {
                    'click:outside': (e) => {
                        dialogContent.componentInstance.clickedOutside(e);
                    }
                }
            }, [dialogContent])
        ).filter((dialog,index) => this.dialog_visible[index]);

        return (
            <div>
                {dialogs}
                <display-notification-header></display-notification-header>
                <vue-snotify></vue-snotify>
                <v-overlay value={this.show_overlay} opacity={this.overlay.opacity} z-index={9999}>
                    <v-progress-circular
                        indeterminate
                        size="64"
                    ></v-progress-circular>
                </v-overlay>
                <idle-timeout></idle-timeout>
                <child-with-token-opener></child-with-token-opener>
            </div>
        );
    }
});