import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import BaseComponent from './BaseComponentMixin.jsx';

Vue.component('navigation-drawer-dsgn', {
    mixins: [BaseComponent],
    data: function () {
        return {
            model: null,
            mini: true,
        }
    },
    async created() {
        if (this.controlData.SourceType === 'Raw' && this.controlData.SourceRaw) {
            if (Array.isArray(this.controlData.SourceRaw))
                this.model = this.controlData.SourceRaw;
            else
                this.model = [
                    {
                        Icon: 'mdi mdi-home-variant',
                        Title: 'Home',
                        location: 'Top'
                    },
                    {
                        Icon: 'mdi mdi-view-list-outline',
                        Title: 'Administration',
                        location: 'Top'
                    },
                    {
                        Icon: 'mdi mdi-account',
                        Title: 'User',
                        location: 'Bottom'
                    }
                ];
        }
        else if (this.controlData.SourceType === 'Url' && this.controlData.SourceUrl) {
            this.sourceurl = utils.compile(this, this.controlData.SourceUrl);

            try {
                this.model = await utils.api.get(utils.evaluate(this.sourceurl, this));
            }
            catch (e) { }
        }
    },
    render(h) {
        let name;
        if (!this.name)
            name = this.type;
        else
            name = this.name + ':' + this.type || 'Unnamed';

        let items_top = [];
        let items_bot = [];

        if (this.model) {
            //this.bot_start_index = this.model.findIndex(m => m.location == 'Bottom');

            for (let tabi = 0; tabi < this.model.length; tabi++) {
                const tab = this.model[tabi];
                let items;
                if (tab.location == 'Top')
                    items = items_top;
                else
                    items = items_bot;

                if (!tab.$objectid)
                    tab.$objectid = utils.generateUUID();

                if (tab.Icon && tab.Icon.includes('{'))
                    tab.Icon = 'mdi mdi-phone';

                let icon;
                if (false && tab.Tooltip) {
                    if (tab.Icon)
                        icon = <v-tooltip right scopedSlots={{ activator: ({ on, attrs }) => <v-icon {...{ on }} {...{ attrs }}>{tab.Icon}</v-icon> }}>{tab.Tooltip}</v-tooltip>;
                }
                else {
                    if (tab.Icon)
                        icon = <v-icon>{tab.Icon}</v-icon>;
                }

                // Example of how to do a badge, either an icon or a literal string can be done:
                //icon = <v-badge color="error" icon="mdi-lock" overlap>{icon}</v-badge>;
                //icon = <v-badge color="error" content="3" overlap>{icon}</v-badge>;

                const listitem = (
                    <v-list-item key={tabi} link>
                        <v-list-item-icon>{icon}</v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{tab.Title || tab.Tooltip}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                );

                items.push(listitem);
            }

            this.bot_start_index = items_top.length;
        }

        const style = {
            ...utils.getSize(this.controlData.SizeOptions, this.parentType, this.$parent),
            //maxWidth: "86px"
        };

        const useDark = this.controlData.DarkTheme || false;

        const scopedSlots = {
            append: () => (
                <v-list nav dense shaped>
                    <v-list-item-group>
                        {items_bot}
                    </v-list-item-group>
                </v-list>
            )
        };

        return (
            <div
                class={{ 'c-NavigationDrawer': true, [`c-name-${this.name || 'unnamed'}`]: true, 'designer-container': true, selected: this.selected$ }}
                style={style}
                on-click={(e) => this.handleClick(e)}>
                <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>

                <v-navigation-drawer
                    absolute={false}
                    permanent={false}
                    expand-on-hover={false}
                    mini-variant={this.mini}
                    disable-resize-watcher
                    permanent
                    dark={useDark}
                    src="https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg"
                    scopedSlots={scopedSlots}>

                    <v-list nav dense shaped>
                        <v-list-item-group>
                            {items_top}
                        </v-list-item-group>
                    </v-list>

                </v-navigation-drawer>
            </div>
        );

        //return (
        //    <div
        //        class={{ 'designer-container': true, selected: this.selected$ }}
        //        style={{ backgroundColor: "#a0ffa0", ...utils.getSize(this.controlData ? this.controlData.SizeOptions : null, this.parentType, this.$parent)  }}
        //        on-click={(e) => this.handleClick(e)}>
        //        <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
        //        {name}<br />
        //    </div>
        //);
    }
});