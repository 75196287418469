import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';
import BaseStackMixin from './BaseStackMixin.jsx';

Vue.component('dynamic-tab-content-dsgn', {
    mixins: [BaseStackMixin],
    data: function () {
        return {
            tab_state: {
                index: 0
            },
        }
    },
    render(h) {
        let items = [];
        let items_id = '_0';

        let controlData = this.controlData;

        this.generateItemsFromModel(h, items, controlData, items_id, 'VerticalStack');

        if (this.selected$)
            items.push(
                <v-btn v-tooltip="Add control to body" x-small icon style={{ zIndex: 992 }} on-click={(e) => this.addControl(e, 'body')}>
                    <v-icon small color="blue darken-2">mdi mdi-plus-circle</v-icon>
                </v-btn>
            );

        const style = {
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            minWidth: "25px",
            ...utils.getSize(controlData.SizeOptions, this.parentType, this.$parent),
            ...utils.getStyleHints(controlData.StyleHints),
        };

        return (
            <div
                class={{ 'c-DynamicTabContent': true, [`c-name-${this.name || 'unnamed'}`]: true, 'designer-container': true, selected: this.selected$ }}
                style={style}
                on-click={(e) => this.handleClick(e)}>
                <div class={{ "designer-container-hovering": true, 'designer-container-selected-clickthrough': this.selected$ }}></div>

                {items}

                {this.generatePicker(h)}
            </div>
        );
    }
});