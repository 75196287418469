import Vue from 'vue';
import utils from '../../Shared/utils.jsx';
import BaseComponent from './BaseComponentMixin.jsx';
import { appSettings } from '@/Shared/appSettings';
import browserHelpers from '@/Shared/browserHelpers';
import token from '@/Services/token.jsx';
import careWebRtcPhone from './javascript/webrtc/v1.0/care-webrtc';
import EventBus from '../event-bus.js';

Vue.component('web-rtc-phone', {
    mixins: [BaseComponent],
    data: function () {
        return {
            phoneUniqueInstanceId: null,
            unsupportedBrowser: false,
            httpsRequired: false,
            webRtcPhone: null,
            loaded: false,
            speakerId: null,
            microphoneId: null,

            state: 'init',
        }
    },
    created() {
        //this.callcorpVideoRemote = document.getElementById('callcorpVideoRemote');
        //this.localVideo = document.getElementById("localVideo");
        //this.remoteVideo = document.getElementById("remoteVideo"); // not used I think

        this.phoneUniqueInstanceId = appSettings.InstanceGuid;
    },
    destroyed() {
        EventBus.$off('Action-WebRTCConnectWebSocket', this.performWebRTCConnectWebSocket);
        this.$off('Action-WebRTCConnectWebSocket', this.performWebRTCConnectWebSocket);

        if (this.name) {
            EventBus.$off(`Action-WebRTCConnectWebSocket:${this.name}`, this.performWebRTCConnectWebSocket);
            this.$off(`Action-WebRTCConnectWebSocket:${this.name}`, this.performWebRTCConnectWebSocket);
        }
    },
    //Mounted Replaced with preRenderComplete
    computed: {
        // Public properties //
        SpeakerId: {
            get: function () {
                if (this.speakerId)
                    return this.speakerId;
                
                if (!this.speakerIdexpn && this.controlData.SpeakerIdField)
                    this.speakerIdexpn = utils.compile(this, this.controlData.SpeakerIdField);
    
                return this.speakerIdexpn ? utils.evaluate(this.speakerIdexpn, this) : '';   
            },
            set: function (value) {
                if (this.speakerId !== value) {
                    this.speakerId = value;
                    const remoteMediaElement = this.$refs.callcorpVideoRemote;

                    if (remoteMediaElement.setSinkId) {
                        remoteMediaElement.setSinkId(value).then(
                            function () { },
                            function () {
                                remoteMediaElement.setSinkId('default');
                            }
                        );
                    }
                }
            }
        },
        MicrophoneId: {
            get: function () {
                if (this.microphoneId)
                    return this.microphoneId;
                
                if (!this.microphoneIdexpn && this.controlData.MicrophoneIdField)
                    this.microphoneIdexpn = utils.compile(this, this.controlData.MicrophoneIdField);

                return this.microphoneIdexpn ? utils.evaluate(this.microphoneIdexpn, this) : '';
            },
            set: function (value) {
                if (this.microphoneId !== value) {
                    this.microphoneId = value;
                    if (this.webRtcPhone && this.webRtcPhone.sipjs.phoneCall && this.webRtcPhone.sipjs.phoneCall.mediaHandler) {
                        let audio = true;
                        if (this.microphoneId) {
                            audio = { deviceId: this.microphoneId };
                        }

                        this.webRtcPhone.switchStream({
                            constraints: {
                                audio: audio,
                                video: false
                            }
                        });
                    }
                }
            }
        },
        Streams: function () {
            return this.getStreams();
        }
        // ----------------- //
    },
    methods: {
        iceOptionsExpression: function () {
            // internal interpolated property

            if (!this.iceOptionsExpressionexpn && this.controlData.ICEOptionsExpression)
                this.iceOptionsExpressionexpn = utils.compileExpression(this, this.controlData.ICEOptionsExpression);

            return this.iceOptionsExpressionexpn ? utils.evaluate(this.iceOptionsExpressionexpn, this) : '';
        },

        preRenderComplete() {
            this.init();

            this.finishRenderHandler(this);
        },

        // Public methods //
        DisconnectWebSocket() {
            utils.debug(`careWebRTC: WebRtcPhone.DisconnectWebSocket()`);
            this.disconnectWebSocket();
        },
        Call(callId, recoveryApp, targetSessionId) {
            utils.debug(`careWebRTC: WebRtcPhone.Call(callId:${callId}, ${targetSessionId})`);
            this.call(callId, recoveryApp, targetSessionId);
        },
        Abort() {
            utils.debug(`careWebRTC: WebRtcPhone.Abort()`);
            this.hangup(true);
        },
        Hangup() {
            utils.debug(`careWebRTC: WebRtcPhone.Hangup()`);
            this.hangup(false);
        },
        SendDtmf(digit) {
            utils.debug(`careWebRTC: WebRtcPhone.SendDtmf(digit:${digit})`);
            this.sendDtmf(digit);
        },
        // ------------- //

        init() {
            if (window.location.protocol != "https:") {
                this.httpsRequired = true;
            }
            else if (window.mozRTCPeerConnection || navigator.webkitGetUserMedia || navigator.getUserMedia || navigator.mozGetUserMedia || (navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
                //const remoteMediaElement = $element.find('#callcorpVideoRemote')[0];

                this.webRtcPhone = new careWebRtcPhone(this.$refs.callcorpVideoRemote);
                this.loaded = true;
            }
            else {
                this.unsupportedBrowser = true;
            }

            EventBus.$on('Action-WebRTCConnectWebSocket', this.performWebRTCConnectWebSocket);
            this.$on('Action-WebRTCConnectWebSocket', this.performWebRTCConnectWebSocket);
            //care.subscribe(careActionType.WEBRTC_CONNECT_WEBSOCKET, connectWebSocketHandler, $scope); // note that the scope for this subscribe is "$scope"

            if (this.name) {
                EventBus.$on(`Action-WebRTCConnectWebSocket:${this.name}`, this.performWebRTCConnectWebSocket);
                this.$on(`Action-WebRTCConnectWebSocket:${this.name}`, this.performWebRTCConnectWebSocket);
            }
            //care.subscribeByName(careActionType.WEBRTC_CONNECT_WEBSOCKET, c.controlData.Name, connectWebSocketHandler, $scope); // note that the scope for this subscribe is "$scope"

            //care.finishRender(c.$controlModel, c.finishedRenderCallback); // DynamicControls should invoke when done rendering (can be invoked multiple times if control is re-rendered)

            this.state = 'idle';
        },

        async localWSConnectionError(error) {
            this.onWSConnectionError(error);
            //actionInfo.Finished(careActionCompletionStatus.FAIL, { Message: error.reason });

            await utils.failure(this.action, { Message: error.reason });
            await utils.complete(this.action);

            // Complete the promise for the executeAction method
            this.action.FinishFunc(true);

            this.state = 'failed';
        },
        async localWSConnected() {
            this.onWSConnected();
            //actionInfo.Finished(careActionCompletionStatus.SUCCESS);

            await utils.success(this.action);
            await utils.complete(this.action);

            // Complete the promise for the executeAction method
            this.action.FinishFunc(true);

            this.state = 'connected';
        },
        async localMicMissingError(result) {
            if (!result) {
                this.micMissingError(result);
                //actionInfo.Finished(careActionCompletionStatus.FAIL);

                await utils.failure(this.action);
                await utils.complete(this.action);

                // Complete the promise for the executeAction method
                this.action.FinishFunc(true);

                this.state = 'failed';
            }
        },
        async performWebRTCConnectWebSocket(action) {
            utils.log(`WebRtcPhone WebRTCConnectWebSocket`);

            this.state = 'connecting';

            if (action.ActionData && action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            try {
                // Save for use in callbacks
                this.action = action;

                if (this.httpsRequired) {
                    const reason = "Https Required";
                    utils.error('careWebRTC: ' + reason);
                    this.localWSConnectionError({ reason: reason });
                }
                else if (this.unsupportedBrowser) {
                    const reason = "Unsupported browser";
                    utils.error('careWebRTC: ' + reason);
                    this.localWSConnectionError({ reason: reason });
                }
                else if (!this.loaded) {
                    const reason = "WebRTC Phone not loaded";
                    utils.error('careWebRTC: ' + reason);
                    this.localWSConnectionError({ reason: reason });
                }
                else {
                    const webRtcPhoneParams = {
                        wsUrl: 'wss://' + token.BandID() + appSettings.WhiteLabel.WssSuffix + '/' + encodeURIComponent(this.phoneUniqueInstanceId),
                        publicIdentity: "sip:websocket_" + this.phoneUniqueInstanceId + "@callcorp.webrtc;transport=ws", // the publicIdentity "websocket_<guid>" identifies this unique user agent (phone)
                        onWSConnected: this.localWSConnected, // WebSocket established
                        onAccepted: this.onAccepted, // OB Call Answered
                        onHangup: this.onHangup, // SIP Bye Message received (server initiated hangup)
                        onCancel: this.onCancel, // Call rejected
                        onConnecting: this.onConnecting, // Sip progress message
                        onWSDisconnected: this.onWSDisconnected,
                        onWSConnectionError: this.localWSConnectionError,
                        reconnectMaxAttempts: 6, // Unlimited autoreconnect attempts
                        reconnectDelay: 4000 // New autoreconnect attempt every 4 seconds
                    };

                    utils.log("careWebRTC: WebRTC Connecting...");
                    const result = await this.webRtcPhone.wsconnect(webRtcPhoneParams, this.iceOptionsExpression(), this.phoneUniqueInstanceId);
                    this.localMicMissingError(result);
                }
            }
            catch (e) {
                utils.warn(`careWebRTC: WebRtcPhone ${this.name} failed WebRTCConnectWebSocket`, e);

                try {
                    await utils.failure(action);

                    // Complete the promise for the executeAction method
                    action.FinishFunc(true);

                    this.state = 'failed';
                } catch (e) { }
            }
        },

        // the callId must be a guid and should have been retrieved from server using api /Apps/Data/NewGuid.
        async call(callId, recoveryApp, targetSessionId) {
            utils.log("WebRTC call(callId:" + callId + ", recoveryApp: " + recoveryApp + ") in progress...");

            this.state = 'calling';

            callId = callId || utils.generateUUID();

            const { microphoneId, speakerId } = await browserHelpers.checkDevices(this.MicrophoneId, this.SpeakerId);

            this.makeCall(callId, microphoneId, speakerId, recoveryApp, this.iceOptionsExpression(), targetSessionId);
        },

        makeCall(callId, microphoneId, speakerId, recoveryApp, iceOptions, targetSessionId) {
            utils.log("careWebRTC: WebRTC makeCall(callId:" + callId + ",microphoneId:" + microphoneId + ",speakerId:" + speakerId + ") in progress...");

            const remoteMediaElement = this.$refs.callcorpVideoRemote;

            if (!remoteMediaElement) {
                utils.log("careWebRTC: WebRTC makeCall failed to locate callcorpVideoRemote element - null returned");
                return;
            }

            const c = this;

            const doMakeCall = async function () {
                const extraHeaders = [
                    "X-OwnerID: " + token.CustomerID(),
                    "X-UserID: " + token.UserID()
                ];

                // if WebRTC leg is being recovered because of a Freeswitch failover, then add a header to associate the new call with the original call we are replacing/recovering
                if (recoveryApp) {
                    extraHeaders.push("X-RecoveryApp: " + recoveryApp);
                }
                if (targetSessionId)
                    extraHeaders.push(`X-TargetSessionId: ${targetSessionId}`);

                // the phone number we dial (session_<CustomerSecurityContextID>) is used by kamailio to determine the proper freeswitch to route the call to
                // the headers X-OwnerID & X-UserID are the equivilent of the CustomerSecurityContextID, and are used by freeswitch to associate call to customer and user
                const result = await c.webRtcPhone.call(
                    "sip:session_" + token.CustomerSecurityContextID() + "@callcorp.webrtc", // phone number we are dialing
                    callId, /* pass in the call-id (guid) generated by server api */
                    extraHeaders, // extra headers to pass in sip invite ["X-ExampleHeader: 1"]
                    microphoneId,
                    iceOptions
                );

                c.micMissingError(result);
            };

            if (remoteMediaElement.setSinkId) {
                remoteMediaElement.setSinkId(speakerId)
                    .then(
                        function () {
                            utils.log("careWebRTC: WebRTC makeCall.setSinkId(speakerId:" + speakerId + ") about to invoke c.webRtcPhone.call(...)");
                            doMakeCall();
                        }
                    )
                    .catch(function (error) {
                        utils.log("WebRTC makeCall.setSinkId(speakerId:" + speakerId + ") failed with " + error);
                    });
            }
            else {
                doMakeCall();
            }
        },
        getStreams() {
            const streams = {
                Remote: this.webRtcPhone.sipjs.phoneCall.getRemoteStreams()[0],
                Local: this.webRtcPhone.sipjs.phoneCall.getLocalStreams()[0]
            };
            return streams;
        },
        hangup(abortWithPrejudice) {
            utils.log("careWebRTC: Starting Hangup" + (abortWithPrejudice ? " (aborting call)" : ""));
            this.webRtcPhone.hangup(abortWithPrejudice);
        },
        sendDtmf(digit) {
            this.webRtcPhone.sendDtmf(digit);
        },
        disconnectWebSocket() {
            if (this.webRtcPhone)
                this.webRtcPhone.wsdisconnect();
        },
        onConnecting() {
            utils.log("careWebRTC: WebRTC Connecting...");
            this.runActions(this.controlData.OnCallConnecting);
        },
        onHangup() {
            utils.log("careWebRTC: Hangup");
            this.runActions(this.controlData.OnHangup);
        },
        onCancel(status) {
            let message = 'Your call has been canceled';

            if (status && status.message)
                message += ': ' + status.message;

            if (status && status.code)
                message += ' (' + status.code + ')';

            utils.log("careWebRTC: WebRTC Cancel: " + message);
            this.runActions(this.controlData.OnCallRejected, { Message: message });
        },
        // OB phone call answered
        onAccepted() {
            utils.log("careWebRTC: WebRTC Accepted");
            this.state = 'accepted';
            this.runActions(this.controlData.OnCallAnswered);
        },
        onWSConnected() {
            utils.log("careWebRTC: WebRTC WebSocket connected");
            this.state = 'connected';
            this.runActions(this.controlData.OnWebSocketConnected);
        },
        onWSDisconnected() {
            utils.log("careWebRTC: WebRTC WebSocket Disconnected");
            this.state = 'disconnected';
            this.runActions(this.controlData.OnWebSocketDisconnected); // You have been disconnected
        },
        onWSConnectionError(error) {
            utils.log("careWebRTC: WebRTC WebSocket Connection Error: " + error.reason);
            this.state = 'error';
            this.runActions(this.controlData.OnWebSocketConnectionError, { Message: error.reason }); // Could not reach the server
            this.disconnectWebSocket(); // stop retrying
        },
        runActions(actions, input) {
            // causes a digest like $apply(),only it won't get an error if it is already in a digest cycle. Because this could be invoked from within or outside a digest, we do the safe method
            utils.log("careWebRTC: start webRTCPhoneActions.runActions ");
            utils.executeAndCompileAllActions(actions, input, this);
        },
        micMissingError(result) {
            if (!result) {
                const error = {
                    name: 'missing_mic',
                    reason: 'Please verify that our site has permission to access your microphone.'
                };
                this.state = 'missing_mic';
                this.onWSConnectionError(error);
            }
        },
    },
    props: {
    },
    render(h) {
        let media = <video id="callcorpVideoRemote" ref="callcorpVideoRemote" style="display: none;"></video>;

        if (this.isdebug)
            return (
                <div class="c-WebRtcPhone" style="border: 1 solid silver; border-radius: 3px; margin: 2px; padding: 2px;">
                    <v-icon small color="purple">mdi-monitor-cellphone</v-icon>
                    {media} {this.state}
                </div>
            );

        return media;
    }
});