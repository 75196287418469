import Vue from 'vue';
import utils from '@/Shared/utils.jsx';
import BaseComponent from '../BaseComponentMixin.jsx';

/*
 * This control represents a unique state within an HSM
 * 
 * */
Vue.component('hsm-state-new', {
    mixins: [BaseComponent],
    data: function () {
        return {
            timeoutexpn: null,

            state_references: {},

            event_handlers: {},
        }
    },
    created() {
        //utils.error(`HSMState:${this.parentHSM.name}:${this.name}:${this._uid}:${this.type} create...`);

        if (this.controlData.Timeout)
            this.timeoutexpn = utils.compile(this, this.controlData.Timeout);

        this.parentHSM.addStateReference(this.fullpath, this);
        if (this.parentState)
            this.parentState.addStateReference(this.name, this);

        const c = this;

        if (this.controlData.EventHandlers)
            for (let i = 0; i < this.controlData.EventHandlers.length; i++) {
                const handler = this.controlData.EventHandlers[i];
                if (handler.NextStateData)
                    handler.nextstatedataexpn = utils.compile(this, handler.NextStateData);

                // Add a method that will return the true next state path, which gets overridden
                // if a HSMSetNextState action executes within the event actions of the handler
                handler.GetNextState = () => {
                    let path = handler.NextStatePathOverride;
                    if (!path)
                        return null;

                    handler.NextStatePathOverride = null;
                    return {
                        NextStatePath: path || handler.NextStatePath,
                        ExternalTransition: handler.ExternalTransition,
                        Data: handler.NextStateData,
                    };
                };
                handler.SetNextState = (action) => {
                    // Take the action's context and alter the parent to point at this state object - allows ParentByType('HSM_State') to work
                    action.context.c_context = c;

                    handler.NextStatePathOverride = utils.evaluate(action.nextstatepath, action.context);
                    handler.ExternalTransition = action.ActionData.ExternalTransition;
                    if (action.data)
                        handler.NextStateData = utils.evaluateObject(action.data, action.context);
                    else
                        handler.NextStateData = null;

                    utils.debug(`HSMState ${c.name} SetNextState (NextStatePathOverride:${handler.NextStatePathOverride}, NextStateData:${JSON.stringify(handler.NextStateData)})`);
                };

                // Save each event handler by the event name for quick lookup
                this.event_handlers[handler.EventName] = handler;
            }
    },
    destroyed() {
        //utils.error(`HSMState:${this.parentHSM.name}:${this.fullpath} destroyed - ${this.$timeoutfunc ? 'Clearing timeout' : ''}`);
        if (this.$timeoutfunc) {
            clearTimeout(this.$timeoutfunc);
        }

        this.parentHSM.clearSenderEvents('Timeout', this.fullpath);
    },
    props: {
        parentHSM: null,
        parentState: null,
        visible: false,
    },
    methods: {
        preRenderComplete() {
            //utils.debug(`HSMState:${this.name}:${this._uid} preRenderComplete`);

            this.finishRenderHandler(this);
        },

        handleEvent(e) {

        },
        addStateReference(name, component) {
            this.state_references[name] = component;
        },
        getEventHandler(eventname) {
            return this.event_handlers[eventname];
        },
        async Enter(data) {
            //utils.debug(`HSMState:${this.name} Entering...`);
            //if (this.timeoutvalue)
            //    utils.error(`HSMState:${this.parentHSM.name}:${this.fullpath} Enter - Setting timeout at ${this.timeoutvalue}`);

            await utils.executeAndCompileAllActions(this.controlData.EnterActions, { Data: data }, this);

            if (this.timeoutvalue) {
                this.$timeoutfunc = setTimeout(() => {
                    //utils.error(`HSMState:${this.parentHSM.name}:${this.fullpath} Firing Timeout`);
                    this.parentHSM.sendEvent('Timeout');
                }, this.timeoutvalue);
            }

            //utils.debug(`HSMState:${this.name} Entered`);
        },
        async Exit(data) {
            //utils.debug(`HSMState:${this.name} Exitting...`);

            if (this.$timeoutfunc) {
                //utils.error(`HSMState:${this.parentHSM.name}:${this.fullpath} Exit - Clearing timeout`);
                clearTimeout(this.$timeoutfunc);
                this.$timeoutfunc = null;
            }

            await utils.executeAndCompileAllActions(this.controlData.ExitActions, { Data: data }, this);

            //utils.debug(`HSMState:${this.name} Exitted`);
        },
    },
    computed: {
        totalItems() {
            let total = 0;

            // Each state will have to be rendered
            if (this.controlData.ChildStates && Array.isArray(this.controlData.ChildStates))
                total += this.controlData.ChildStates.length;

            // Add one to include the component itself.
            total++;

            return total;
        },
        timeoutvalue: function () {
            let value = 0;
            if (this.timeoutexpn)
                value = utils.evaluate(this.timeoutexpn, this);

            if (typeof value === 'string')
                value = parseInt(value);

            return value;
        },
        fullpath: function () {
            let name = this.name;
            let p = this.parentState;
            while (p) {
                name = `${p.name}.${name}`;
                p = p.parentState;
            }
            
            return name;
        },
        states_components: function () {
            const h = this.$createElement;
            if (!this.controlData.ChildStates || this.controlData.ChildStates.length === 0)
                return null;
            else
                return this.controlData.ChildStates.map(s => (
                    <hsm-state-new
                        key={s.StateName}
                        on={{ 'finished-render': (reference) => this.finishRenderHandler(reference) }}
                        name={s.StateName}
                        root={this.root}
                        parentHSM={this.parentHSM}
                        parentState={this}
                        type="HSM_State"
                        controlData={s}
                        visible={this.visible}
                    >
                    </hsm-state-new>
                ));
        },

        UniqueID: function () {
            return this.parentHSM.id;
        },
        FinalState: function () {
            return this.controlData.FinalState;
        },
        componentchildren: function () {
            return Object.keys(this.parentHSM.child_references).map(k => this.parentHSM.child_references[k]);
        },
    },
    render(h) {
        try {
            const isactive = this.fullpath == this.parentHSM.currentStatePath;

            let style;
            if (this.visible)
                style = {
                    padding: "5px",
                    margin: "2px",
                    borderRadius: "5px",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: "gray",
                    backgroundColor: isactive ? "red" : "white",
                    display: "flex",
                    flexWrap: "wrap",
                };

            return (
                <div style={style}>
                    {this.visible ? `${this.name} [${this._uid}]` : null}
                    {this.states_components}
                </div>
            );
        }
        catch (e) {
            return <div>Error in HSMState:{JSON.stringify(e)}</div>;
        }
    }
});