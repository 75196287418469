import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import util from '../../careHelpfulFunctions.jsx';

Vue.component('schema-item', {
    data: () => ({
        schema: null,
        list: null,
    }),
    props: {
        name: null,
        cmodel: null,
        typeSchema: null,
        renamed: null,
        changed: null,
        deleted: null,
        moveup: null,
        movedown: null,
        selected: null,
        setselection: null,
        navigateTo: null,
        canNavigate: false,
        propertygrid: false,
    },
    async created() {
        if (this.typeSchema) {

            const s = await utils.schema.get(this.typeSchema);
            this.schema = utils.schema.resolve_Of(s);
        }
        this.list = await utils.schema.get('/Apps/Schema/AnyOf/schema/public/Platform.Schema.BaseTypes.v1/SchemaSchema');
    },
    render() {
        if (!this.list)
            return null;

        const options = [];
        let value;
        let selected = false;
        for (let i = 0; i < this.list.anyOf.length; i++)
        {
            const opt = this.list.anyOf[i];

            if (this.schema && (this.schema.title === opt.title))
            {
                value = opt.Id;
                selected = true;
            }
            options.push({ text: opt.title, value: opt.Id, disabled: false });
        }
        if (!selected)
            options.splice(0, 0, { text: "Please select one", value: "", disabled: true });

        let select_checkbox;
        let navigate;
        if (this.canNavigate && this.typeSchema) {
            select_checkbox = <input type="checkbox" checked={this.name in this.selected} on-change={(e) => this.setselection(this.name, e.target.checked)} />;
          //select_checkbox = <v-checkbox solo hide-details input-value={this.name in this.selected} on-change={(value) => this.setselection(this.name, value)}></v-checkbox>;
            navigate = <v-btn elevation={0} color="blue lighten-2" x-small icon title="Edit" on-click={() => this.navigateTo(this, this.name)}>
                <v-icon small>mdi mdi-open-in-new</v-icon>
                </v-btn>;
        }

        const btn_styles = {
            'font-size': "x-small"
        };

        return (
            <tr>
                <td style={{ width: "250px" }}>
                    <span class="property-grid" style={{ whiteSpace: "nowrap", display: "flex", flexDirection: "row", alignItems: "center"  }}>
                        {select_checkbox}
                        {navigate}
                        <v-text-field solo single-line dense hide-details value={this.name} on-change={(value) => this.renamed(this.name, value)}></v-text-field>
                    </span>
                </td>
                <td class="property-grid">
                    <v-select solo dense hide-details items={options} value={value} style={{ width: "100%" }} on-change={(value) => this.changed(this.name, value)}>
                    </v-select>
                </td>
                <td class="property-grid" style={{ whiteSpace: "nowrap" }}>
                    <v-btn elevation={0} icon x-small color="blue-grey" title="Move Up" style={btn_styles} on-click={() => this.moveup(this.name)}><v-icon x-small>mdi mdi-arrow-up</v-icon></v-btn>
                    <v-btn elevation={0} icon x-small color="blue-grey" title="Move Down" style={btn_styles} on-click={() => this.movedown(this.name)}><v-icon x-small>mdi mdi-arrow-down</v-icon></v-btn>
                    <v-btn elevation={0} icon x-small color="blue-grey" title="Delete" style={btn_styles} on-click={() => this.deleted(this.name)}><v-icon x-small>mdi mdi-close</v-icon></v-btn>
                </td>
            </tr>
        );
    }
});

Vue.component('schema-properties', {
    data: () => ({
        selected: {},
    }),
    computed: {
        ...utils.forms.computed,
        anySelected: function () {
            return Object.keys(this.selected).length > 0;
        }
    },
    props: {
        name: '',
        schema: null,
        schemakey: null,
        root: null,
        cmodel: null,
        navigate: null,
        canNavigate: false,
        propertygrid: false,
    },
    methods: {
        async itemChanged(prop, value) {
            let s = await utils.schema.get(value);
            s = utils.schema.resolve_Of(s);
            const m = utils.schema.getDefaultModel(s);
            Vue.set(this.cmodel[this.schemakey], prop, m);
        },
        itemRenamed(prop, value) {
            if (prop == value)
                return; // Don't do anything, the name didn't change

            const oldobj = this.cmodel[this.schemakey];
            if (value in oldobj) {
                alert('Property ' + value + ' is already used');
                return; // Can't rename to the name of an existing property
            }
            const properties = util.renameProperty(oldobj, prop, value);
            Vue.set(this.cmodel, this.schemakey, properties);
        },
        itemDeleted(prop) {
            Vue.delete(this.cmodel[this.schemakey], prop);
        },
        addAnother() {
            const properties = this.cmodel[this.schemakey]; 
            let key = 'Unnamed';
            let dup = 1;
            while (key in properties)
                key = 'Unnamed_' + (dup++);

            Vue.set(properties, key, { $objectId: utils.generateUUID() });
        },
        moveDown(prop) {
            const properties = this.cmodel[this.schemakey];
            const newobj = util.reorderProperty(properties, prop, 1);
            Vue.set(this.cmodel, this.schemakey, newobj);
        },
        moveUp(prop) {
            const properties = this.cmodel[this.schemakey];
            const newobj = util.reorderProperty(properties, prop, -1);
            Vue.set(this.cmodel, this.schemakey, newobj);
        },
        setSelection(name, checked) {
            if (checked)
                Vue.set(this.selected, name, checked);
            else
                Vue.delete(this.selected, name);
        },
        copy() {
            const properties = this.cmodel[this.schemakey];
            const selected = [];
            for (let key in this.selected)
                selected.push({ key: key, value: properties[key] });

            localStorage.setItem('careClipboard', JSON.stringify(selected));
        },
        cut() {
            const properties = this.cmodel[this.schemakey];
            const selected = [];
            for (let key in this.selected) {
                selected.push({ key: key, value: properties[key] });
                Vue.delete(properties, key);
            }

            localStorage.setItem('careClipboard', JSON.stringify(selected));

            this.selected = {};
        },
        paste() {
            const rawbuffer = localStorage.getItem('careClipboard');
            if (rawbuffer && typeof rawbuffer === 'string') {
                try {
                    const data = JSON.parse(rawbuffer);
                    if (typeof data === 'object' && Array.isArray(data)) {
                        if (!this.cmodel[this.schemakey])
                            Vue.set(this.cmodel, this.schemakey, {});

                        const properties = this.cmodel[this.schemakey];
                        for (let i = 0; i < data.length; i++) {
                            const item = data[i];
                            if (!('key' in item && 'value' in item)) continue;

                            let key = item.key;
                            let dup = 1;
                            while (key in properties)
                                key = item.key + '_' + (dup++);

                            if (item.value.$objectId)
                                item.value.$objectId = utils.generateUUID();

                            Vue.set(properties, key, item.value);
                        }
                    }
                }
                catch (e) {
                    alert('Failed to parse data from clipboard buffer: ' + e);
                }
            }
        },
        selectAll() {
            const properties = this.cmodel[this.schemakey];
            for (let key in properties)
                Vue.set(this.selected, key, true);
        },
        selectNone() {
            this.selected = {};
        },
    },
    render(h) {
        const elements = [];
        const cmodel = this.cmodel[this.schemakey];
        for (let key in cmodel) {
            if (key.substr(0, 1) === '$') continue;
            const item = cmodel[key];

            elements.push(
                <schema-item
                    key={item.$objectId}
                    name={key}
                    cmodel={cmodel}
                    typeSchema={item.$typeSchema}
                    changed={(prop, value) => this.itemChanged(prop, value)}
                    renamed={(prop, value) => this.itemRenamed(prop, value)}
                    deleted={(prop, value) => this.itemDeleted(prop, value)}
                    moveup={(prop) => this.moveUp(prop)}
                    movedown={(prop) => this.moveDown(prop)}
                    selected={this.selected}
                    setselection={(prop, checked) => this.setSelection(prop, checked)}
                    navigateTo={(control, prop) => this.navigate(control, prop, item)}
                    canNavigate={this.canNavigate}
                    propertygrid={this.propertygrid}>
                </schema-item>);
        }

        const btn_styles = {
            'font-size': "x-small"
        };
        const selections = (
            <span class="property-grid" style="display:flex; gap: 5px;">
                <v-btn elevation={0} x-small color="blue-grey" class="white--text" style={btn_styles} disabled={!this.anySelected} on-click={this.copy}>Copy</v-btn>
                <v-btn elevation={0} x-small color="blue-grey" class="white--text" style={btn_styles} disabled={!this.anySelected} on-click={this.cut}>Cut</v-btn>
                <v-btn elevation={0} x-small color="blue-grey" class="white--text" style={btn_styles} on-click={this.paste}>Paste</v-btn>

                <v-btn elevation={0} x-small color="blue-grey" class="white--text" style={btn_styles} on-click={this.selectAll}>Select All</v-btn>
                <v-btn elevation={0} x-small color="blue-grey" class="white--text" style={btn_styles} on-click={this.selectNone}>Select None</v-btn>
            </span>
        );

        return (
            <table style={{ width: "100%", borderStyle: "solid", borderColor: "silver", borderWidth: "1px" }}>
                <tr>
                    <td colspan="3">
                        {selections}
                    </td>
                </tr>
                <tr>
                    <th>Property Name</th>
                    <th>Property Type</th>
                </tr>
                {elements}
                <tr>
                    <td colspan="3" class="property-grid">
                        <v-btn elevation={0} x-small color="blue-grey" class="white--text" on-click={() => this.addAnother()}><v-icon small left>mdi mdi-plus</v-icon> Add Another</v-btn>
                    </td>
                </tr>
            </table>
        );
    }
});