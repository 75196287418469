import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

function hasNonEmptyValue(text) {
    if (!text)
        return false;

    if (typeof text === 'string')
        if (!text.trim())
            return false;

    return true;
}

Vue.component('fast-grid-dsgn', {
    data: () => ({
        displayexpr: null,
        schemaurl: null,
        schemaraw: null,
        modelurl: null,
        modelvalue: null,
        schema: null,
        model: {},
        pickervisible: false,
        picker: null,
        pickercdef: null,
    }),
    created() {
    },
    mounted() {
        this.Refresh();
    },
    computed: {
        Root: function () {
            return this.root._self;
        },
        Model: function () {
            return this.model;
        },
        selected$: function () {
            return this.Root.SelectedNode && this.Root.SelectedNode == this;
        },
    },
    methods: {
        ...methods,
        handleClick(e) {
            this.Root.SelectNode(this);
            e.cancelBubble = true;
            e.stopPropagation();
        },
        selectColumn(e, index) {
            this.Root.SelectNode(this, this.controlData.ColumnDefs[index]);
            e.cancelBubble = true;
            e.stopPropagation();
        },
        deleteColumn(e, index) {
            this.controlData.ColumnDefs.splice(index, 1);
            e.cancelBubble = true;
            e.stopPropagation();
        },
        addColumn(e) {
            if (!this.controlData.ColumnDefs)
                Vue.set(this.controlData, 'ColumnDefs', []);

            this.controlData.ColumnDefs.push({
                $typeSchema: '/schema/public/Platform.Schema.DynamicControls.v1/DynamicControl_Grid_ColumnDef',
                Align: 'Left',
                DisplayName: '',
                Field: 'field' + (this.controlData.ColumnDefs.length+1),
                Sortable: true,
                Visible: true,
                Width: 'Auto',
            });
            this.Root.SelectNode(this, this.controlData.ColumnDefs[this.controlData.ColumnDefs.length - 1]);
            e.cancelBubble = true;
            e.stopPropagation();
        },
        async Refresh() {
            if (this.controlData.SchemaURL)
                try {
                    this.schema = await utils.schema.get(this.controlData.SchemaURL);
                }
                catch (e) {
                    utils.warn('Design BasicGrid schemaurl ' + this.controlData.SchemaURL + ' failed to load: ' + e);
                    this.schema = null;
                }

            if (this.controlData.DataType == 'URL' && this.controlData.DataURL)
                try {
                    this.model = await utils.api.get(this.controlData.DataURL);
                }
                catch (e) {
                    utils.warn('Design BasicGrid DataURL ' + this.controlData.DataURL + ' failed to load: ' + e);
                    this.model = [{}];
                }
            else if (this.controlData.DataType == 'Raw' && this.controlData.Data && Array.isArray(this.controlData.Data)) {
                this.model = this.controlData.Data;
            }
            else
                this.model = [{}];
        },

        addControl(cdef) {
            this.pickercdef = cdef;
            this.picker = <control-picker confirm={this.confirmAddControl} cancel={this.cancelAddControl}></control-picker>;
            this.pickervisible = true;
        },
        async confirmAddControl(id, title) {
            this.pickervisible = false;

            try {
                const schema_ = await utils.schema.get(id);
                const schema = utils.schema.resolve_Of(schema_)
                const model = utils.schema.getDefaultModel(schema);
                this.pickercdef.Controls.push(model);
            }
            catch (e) {
                alert(e);
            }
        },
        cancelAddControl() {
            this.pickervisible = false;
        },
        pickerInput(value) {
            this.pickervisible = value;
        },
        getColumnDefTitle(cdef) {
            if (hasNonEmptyValue(cdef.DisplayName))
                return cdef.DisplayName;
            else
                return cdef.Field || cdef.Name || 'Unnamed';
        },
    },
    props: {
        name: '',
        root: null,
        designmodel: null,
        parentType: '',
        controlData: {}
    },
    render() {
        let headercontrols;
        const elements = [];

        if (this.controlData.GridHeaderControls && this.controlData.GridHeaderControls.length > 0) {
            headercontrols = [];
            for (let j = 0; j < this.controlData.GridHeaderControls.length; j++) {
                const control = this.controlData.GridHeaderControls[j];

                let DynamicControl = utils.getDynamicComponent(h, control);
                if (!DynamicControl) {
                    elements.push(<span>Control type of {control.ControlType} is unknown</span>);
                    continue;
                }
                DynamicControl += '-dsgn';

                headercontrols.push(
                    <DynamicControl type={control.ControlType} name={control.ControlData.Name} root={this.root} controlData={control.ControlData} designmodel={control}>
                    </DynamicControl>
                );
            }
        }

        let header;
        if (this.controlData.ColumnDefs && this.controlData.ColumnDefs.length > 0)
        {
            header = [];
            for (let i = 0; i < this.controlData.ColumnDefs.length; i++) {
                const cdef = this.controlData.ColumnDefs[i];

                header.push(
                    <th style={{ width: "150px" }}>
                        <span
                            class={{ 'design-clickable': this.selected$ }}
                            title="Edit Column"
                            style={{ cursor: "pointer", display: "inline" }}
                            on-click={(e) => this.selectColumn(e, i)}>
                            {this.getColumnDefTitle(cdef)}
                            <span
                                class="design-options"
                                title="Delete Column"
                                style={{ cursor: "pointer", fontSize: "smaller", display: this.selected$ ? "inline" : "none", color: "blue" }}
                                on-click={(e) => this.deleteColumn(e, i)}>
                                <i class="mdi mdi-close"></i>
                            </span>
                        </span>
                        <span style="visibility: hidden;">I</span>
                    </th>
                );
                // The "I" within the hidden span above is required to keep the cell visible since the other two elements are using absolute position
            }
            elements.push(<tr>{header}</tr>);
        }
        else if (this.model && this.model.length > 0)
        {
            header = [];
            const sample = this.model[0];
            for (let head in sample)
            {
                header.push(
                    <th>
                        {head}
                    </th>
                );
            }
            elements.push(<tr>{header}</tr>);
        }

        if (!this.model)
            elements.push(<tr><td>Loading...</td></tr>);
        else {
            let c = this.model.length;
            if (c > 10) c = 10;
            for (let i = 0; i < c; i++) {
                const row = this.model[i];
                if (!row.$objectId)
                    row.$objectId = utils.generateUUID();

                elements.push(
                    <grid-row-dsgn
                        key={row.$objectId}
                        selected$={this.selected$}
                        firstrow={i === 0}
                        addControl={this.addControl}
                        root={this.root}
                        schema={this.schema}
                        cmodel={row}
                        columnDefs={this.controlData.ColumnDefs}
                        even={i % 2 === 0}
                    >
                    </grid-row-dsgn>
                );
            }
        }

        let designer_class = {
            'designer-container-selected-clickthrough': this.selected$
        };
        if (!this.selected$)
            designer_class["designer-container-hovering"] = true;
        else
            designer_class["designer-container-hovering__selected"] = true;

        if (headercontrols)
            headercontrols =
                <div style="display: flex; flex-direction: row;">
                    {headercontrols}
                </div>;

        return (
            <div
                class={{ 'designer-form': true, selected: this.selected$ }}
                style={{ borderStyle: 'solid', borderWidth: '1px', position: "relative", padding: "5px", overflow: "auto", ...utils.getSize(this.controlData.SizeOptions, this.parentType, this.$parent) }}
                on-click={(e) => this.handleClick(e)}>
                <div class={designer_class}></div>

                <div key="1" class="design-options" title="Add Column" on-click={(e) => this.addColumn(e)} style={{ top: "1px", right: "1px", display: this.selected$ ? "block" : "none" }}><i class="mdi mdi-plus-circle"></i></div>
                {headercontrols}
                <table disabled style={{ border: "1px", width: "100%" }}>
                    {elements}
                </table>

                <v-dialog value={this.pickervisible} max-width="860" scrollable on-input={(value) => this.pickerInput(value)}>
                    {this.picker}
                </v-dialog>
            </div>
        );
    }
});