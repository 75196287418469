class PromiseWrapper {
    resolve;
    reject;
    promise;

    constructor(){
        this.resolve = {};
        this.reject = {};
        this.promise = new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
    }
}

export default {
    defer() {
        return new PromiseWrapper();
    },

    all(promises) {
        return Promise.all(promises);
    }
}
