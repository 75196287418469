import Vue from 'vue';
//import SchemaForm from './form/SchemaForm.jsx';
import PropertyGrid from './form/PropertyGrid.jsx';

import Accordion from './designer/Accordion.jsx';
import ApexGeneralChart from './designer/ApexGeneralChart.jsx';
import AudioPlayer from './designer/AudioPlayer.jsx';
import BackgroundSvc from './designer/BackgroundService.jsx';
import BasicLink from './designer/BasicLink.jsx';
import BasicPopupMenu from './designer/BasicPopupMenu.jsx';
import BasicTab from './designer/BasicTab.jsx';
import BasicTree from './designer/BasicTree.jsx';
import CareCenterMenu from './designer/CareCenterMenu.jsx';
import CareCenterPopupMenu from './designer/CareCenterPopupMenu.jsx';
import ControlContainerDsgn from './designer/ControlContainer.jsx';
import ControlDesignerDsgn from './designer/ControlDesigner.jsx';
import DisplayBasicModal from './designer/actions/DisplayBasicModal.jsx';
import DocumentBootstrap from './designer/DocumentBootstrap.jsx';
import DropdownList from './designer/DropdownList.jsx';
import DynamicControlContainer from './designer/DynamicControlContainer.jsx';
import DynamicMenuList from './designer/DynamicMenuList.jsx';
import DynamicTab from './designer/DynamicTab.jsx';
import DynamicTabContent from './designer/DynamicTabContent.jsx';
import DynamicTree from './designer/DynamicTree.jsx';
import FastGrid from './designer/FastGrid.jsx';
import ForEach from './designer/ForEach.jsx';
import HSM from './designer/HSM.jsx';
import HorizontalStack from './designer/HorizontalStack.jsx';
import HtmlTable from './designer/HtmlTable.jsx';
import IconPopupMenu from './designer/IconPopupMenu.jsx';
import Avatar from './designer/Avatar.jsx';
import LayoutTile from './designer/LayoutTile.jsx';
import MicAnalyzer from './designer/MicAnalyzer.jsx';
import NavigationDrawer from './designer/NavigationDrawer.jsx';
import ProgressBar from './designer/ProgressBar.jsx';
import ProgressCircle from './designer/ProgressCircle.jsx';
import RealTimeGrid from './designer/RealTimeGrid.jsx';
import ReplaceContent from './designer/actions/ReplaceContent.jsx';
import SelectList from './designer/SelectList.jsx';
import SplitPanes from './designer/SplitPanes.jsx';
import Tab from './designer/actions/Tab.jsx';
import UserAction from './designer/actions/UserAction.jsx';
import UserControl from './designer/UserControl.jsx';
import VerticalStack from './designer/VerticalStack.jsx';
import Watcher from './designer/Watcher.jsx';
import Wizard from './designer/Wizard.jsx';

import utils from '../../Shared/utils.jsx';
import methods from '../../Shared/methods';
import filters from '@/Shared/filters.js';
import api from '@/Services/api';

function formatDate(date) {
    var month = '' + (date.getMonth() + 1);
    var day = '' + date.getDate();
    var year = date.getFullYear();
    var hour = '' + date.getHours();
    if (hour.length == 1) hour = '0' + hour;
    var min = '' + date.getMinutes();
    if (min.length == 1) min = '0' + min;
    var sec = '' + date.getSeconds();
    if (sec.length == 1) sec = '0' + sec;

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [month, day, year].join('-') + ' ' + hour + ':' + min;
};

Vue.component('control-designer', {
    data: function () {
        return {
            displayexpr: null,
            mode: null,
            viewjson: false,
            viewdsgn: true,
            sourceurl: null,
            sourceraw: null,

            sel_schemaurl: null,
            sel_schemaraw: null,
            sel_modelurl: null,
            sel_modelvalue: null,
            sel_model: null,
            sel_schema: null,

            model: null,
            schema: null,
            selectednode: null,
            selectedmodel: null,
            originalname: null,
            actions: [],
            externalactions: [],
            updatemodelactions: [],

            reset_breadcrumb: 0,
            can_autoreplace: false,
            forceOverwrite: false,
            modify_UserName: '',

            ControlURL: '',
            dynamicControl: '',
            type: '',
            designmodel: null,
            cData: null,
            history: [],
            designer_width: 800,

            temp_rawjson: '',
            rawjson_ischanged: false,

            save_in_progress: false,
            doc_revisions: [],
            doc_changes: [],
            max_months_history: 18,
            edits: null,
            editing_since: null,

            js_enabled: false,
            js_open: false,
            js_code: null,
            hsm_errors_open: false,
            hsm_errors: [],
        }
    },
    async created() {
        if (this.controlData.DisplayExpression)
            this.displayexpr = utils.compileExpression(this, this.controlData.DisplayExpression);

        if (this.controlData.SourceType === 'Url' && this.controlData.SourceUrl) {
            this.sourceurl = utils.compile(this, this.controlData.SourceUrl);

            this.$watch(
                function () {
                    return utils.evaluate(this.sourceurl, this);
                },
                function (val, oldval) {
                    utils.log('ControlDesigner detected change in sourceurl; old:' + oldval + ', new:' + val + '; refreshing');
                    this.Refresh();
                    this.viewjson = false;
                }
            );
        }
        else if (this.controlData.SourceType === 'Raw' && this.controlData.SourceRaw) {
            this.sourceraw = utils.compileObject(this, this.controlData.SourceRaw);

            this.$watch(
                function () {
                    try {
                        return utils.evaluateObject(this.sourceraw, this);
                    }
                    catch (e) {
                        utils.warn('Watch on ControlDesigner SourceRaw ' + this.controlData.SourceRaw + ' failed to evaluate: ' + e);
                        return null;
                    }
                },
                function (val, oldval) {
                    const valstr = JSON.stringify(val);
                    const oldvalstr = JSON.stringify(oldval);

                    if (valstr !== oldvalstr) {
                        this.Refresh();
                        this.viewjson = false;
                    }
                },
                {
                    deep: false
                }
            );
        }

        if (this.controlData.SelectedSchema.Type == 'URL' && this.controlData.SelectedSchema.Definition) {
            this.sel_schemaurl = utils.compile(this, this.controlData.SelectedSchema.Definition);

            this.schemaurl_watch$ = this.$watch(
                function () {
                    try {
                        return utils.evaluate(this.sel_schemaurl, this);
                    }
                    catch (e) {
                        utils.warn('ControlDesigner sel_schemaurl ' + this.controlData.SelectedSchema.Definition + ' failed to evaluate: ' + e);
                        return '';
                    }
                },
                function (val, oldval) {
                    utils.log('ControlDesigner detected change in sel_schemaurl; old:' + oldval + ', new:' + val + '; refreshing selected');
                    this.RefreshSelected();
                }
            );
        }
        else if (this.controlData.SelectedSchema.Type == 'Raw' && this.controlData.SelectedSchema.Definition) {
            this.sel_schemaraw = utils.compileObject(this, this.controlData.SelectedSchema.Definition);

            this.schemaraw_watch$ = this.$watch(
                function () {
                    return utils.evaluateObject(this.sel_schemaraw, this);
                },
                function (val, oldval) {
                    utils.log('ControlDesigner detected change in sel_schemaraw; refreshing selected');
                    this.RefreshSelected();
                }
            );
        }
        else
            this.sel_schema = this.controlData.SelectedSchema.Definition;

        if (this.controlData.SelectedModel.Type == 'URL' && this.controlData.SelectedModel.Definition) {
            this.sel_modelurl = utils.compile(this, this.controlData.SelectedModel.Definition);

            this.modelurl_watch$ = this.$watch(
                function () {
                    return utils.evaluate(this.sel_modelurl, this);
                },
                function (val, oldval) {
                    utils.log('ControlDesigner detected change in sel_modelurl; old:' + oldval + ', new:' + val + '; refreshing selected');
                    this.RefreshSelected();
                }
            );
        }
        else if (this.controlData.SelectedModel.Type == 'Raw' && this.controlData.SelectedModel.Definition)
            this.sel_model = this.controlData.SelectedModel.Definition;
        else if (this.controlData.SelectedModel.Type == 'RawInterpolated' && this.controlData.SelectedModel.Definition) {
            this.sel_modelvalue = utils.compileObject(this, this.controlData.SelectedModel.Definition);

            this.modelvalue_watch$ = this.$watch(
                function () {
                    try {
                        return utils.evaluateObject(this.sel_modelvalue, this);
                    }
                    catch (e) {
                        utils.warn('ControlDesigner sel_modelvalue ' + this.controlData.SelectedModel.Definition + ' failed to evaluate: ' + e);
                        return null;
                    }
                },
                function (val, oldval) {
                    utils.log('ControlDesigner detected change in sel_modelvalue; refreshing selected');
                    this.RefreshSelected();
                },
                {
                    deep: false
                }
            );
        }

        //if (this.controlData.Actions)
        //    for (var i = 0; i < this.controlData.Actions.length; i++)
        //        this.actions.push(await utils.compileAction(this, this.controlData.Actions[i]));

        //if (this.controlData.ExternalActions)
        //    for (var i = 0; i < this.controlData.ExternalActions.length; i++)
        //        this.externalactions.push(await utils.compileAction(this, this.controlData.ExternalActions[i]));

        //if (this.controlData.UpdateModelActions)
        //    for (var i = 0; i < this.controlData.UpdateModelActions.length; i++)
        //        this.updatemodelactions.push(await utils.compileAction(this, this.controlData.UpdateModelActions[i]));

        // Trigger a function every 60 seconds to insure we keep the Redis tracking info up to date
        const tmp = this;
        this.timeoutReference = window.setTimeout(async () => {
            tmp.refreshTracking();
        }, 10 * 1000);

        this.$emit('finished-render', this);
    },
    mounted() {
        this.Refresh();
    },
    beforeDestroy() {
        this.clearTracking();

        if (this.timeoutReference)
            window.clearTimeout(this.timeoutReference);

        this.timeoutReference = null;
    },
    computed: {
        Root: function () {
            return this.root._self;
        },
        Model: function () {
            return this.model;
        },
        SelectedNode: function () {
            return this.selectednode;
        },
        SelectedModel: function () {
            return this.selectedmodel;
        },
        hasNameChanged: function () {
            switch (this.mode) {
                case 'UserControl':
                case 'Action':
                case 'UserDefinedArea':
                    return this.model && (this.model.Name !== this.originalname);

                case 'MenuItem':
                    return this.model && this.model.MenuItemData && (this.model.MenuItemData.Title !== this.originalname);

                case 'Schema':
                    return this.model && (this.model.Namespace + '.' + this.model.Name) !== this.originalname;

                case 'Cassandra':
                    return this.model && (this.model.Keyspace !== this.originalname);

                default:
                    return false;
            }
        },
        modify_DateTime: function () {
            if (!this.model || !this.model.$Modify_DateTime)
                return '';

            const dt = new Date(Date.parse(this.model.$Modify_DateTime));
            return formatDate(dt);
        },
    },
    methods: {
        ...methods,
        ...filters,
        async Refresh() {
            utils.log('Refresh');
            if (this.controlData.SourceType == 'Url' && this.sourceurl)
                try {
                    const url = utils.evaluate(this.sourceurl, this);
                    if (url) {
                        let apiRequest = {
                            method: 'GET',
                            url: url,
                            doNotUseWebsocket: true,
                            flatten: true
                        };
                        this.model = await api.apiRequest(apiRequest);
                    }
                    // this.model = await utils.api.get();
                }
                catch (e) {
                    utils.warn('ControlDesigner Source Url ' + this.controlData.SourceUrl + ' failed to evaluate: ' + e);
                }
            else if (this.sourceraw)
                try {
                    this.model = utils.evaluateObject(this.sourceraw, this);
                }
                catch (e) {
                    utils.warn('ControlDesigner Source Raw ' + this.controlData.SourceRaw + ' failed to evaluate: ' + e);
                }

            this.editing_since = new Date();
            this.history = [];
            this.reset_breadcrumb = 0;
            this.save_in_progress = false;
            this.can_autoreplace = false;
            this.forceOverwrite = false;
            this.rawjson_ischanged = false;
            this.modelHasChanged();
            try {
                this.fullName = await utils.api.get('Apps/User/FullName');
            }
            catch (e) {
                utils.log('Apps/User/FullName failed: ' + e);
            }
            if (!this.fullName) this.fullName = 'Query Error';
        },
        async RefreshSelected() {
            if (this.controlData.SelectedSchema.Type == 'URL' && this.sel_schemaurl)
                try {
                    const url = utils.evaluate(this.sel_schemaurl, this);
                    if (url)
                        this.sel_schema = await utils.schema.get(url);
                }
                catch (e) {
                    utils.warn('ControlDesigner sel_schemaurl ' + this.controlData.SelectedSchema.Definition + ' failed to evaluate: ' + e);
                    this.sel_schema = null;
                }
            else if (this.sel_schemaraw) {
                this.sel_schema = utils.evaluateObject(this.sel_schemaraw, this);
                await utils.schema.resolve(this.sel_schema);
            }
            else if (this.sel_schema)
                await utils.schema.resolve(this.sel_schema);

            if (this.controlData.SelectedModel.Type == 'URL' && this.sel_modelurl) {
                const url = utils.evaluate(this.sel_modelurl, this);
                if (url) {
                    let apiRequest = {
                        method: 'GET',
                        url: url,
                        doNotUseWebsocket: true,
                        flatten: true
                    };
                    this.sel_model = await api.apiRequest(apiRequest);
                }
                // this.sel_model = await utils.api.get(url);
            }
            else if (this.controlData.SelectedModel.Type == 'RawInterpolated' && this.sel_modelvalue)
                try {
                    this.sel_model = utils.evaluateObject(this.sel_modelvalue, this);
                }
                catch (e) {
                    utils.warn('ControlDesigner sel_modelvalue ' + this.controlData.SelectedModel.Definition + ' failed to evaluate: ' + e);
                    this.sel_model = {};
                }
            else if (this.controlData.SelectedModel.Type == 'Raw' && this.controlData.SelectedModel.Definition)
                this.sel_model = this.controlData.SelectedModel.Definition;
            else
                this.sel_model = {};

            if (this.sel_schema && this.sel_model) {
                const resolved = utils.schema.resolve_Of(this.sel_schema);

                for (var key in resolved.properties) {
                    const element = resolved.properties[key];

                    if (('default' in element) && !(key in this.sel_model)) {
                        Vue.set(this.sel_model, key, element['default']);
                    }
                    else if (element.type === 'object' && !(key in this.sel_model)) {
                        const model = utils.schema.getDefaultModel(element);
                        Vue.set(this.sel_model, key, model);
                    }
                }
            }
        },
        nameHasChanged() {
            switch (this.mode) {
                case 'UserControl':
                case 'Action':
                case 'UserDefinedArea':
                    this.originalname = this.model ? this.model.Name : '';
                    break;
                case 'MenuItem':
                    this.originalname = this.model ? this.model.MenuItemData.Title : '';
                    break;
                case 'Schema':
                    this.originalname = this.model ? (this.model.Namespace + '.' + this.model.Name) : '';
                    break;
                case 'Cassandra':
                    this.originalname = this.model ? this.model.Keyspace : '';
                    break;
            }
            this.editing_since = new Date();
        },
        modelHasChanged() {
            if (this.model)
                switch (this.model.$typeSchema) {
                    case "/schema/public/Platform.Schema.Documents.v1/Document_UserControl":
                    case "/schema/public/Platform.Schema.Documents.v1/Document_UserControlTest":this.mode = 'UserControl'; break;
                    case "/schema/public/Platform.Schema.Documents.v1/Document_UserAction": this.mode = 'Action'; break;
                    case "/schema/public/Platform.Schema.Documents.v1/Document_UserDefinedArea": this.mode = 'UserDefinedArea'; break;
                    case "/schema/public/Platform.Schema.Documents.v1/Document_CareCenterMenuItem":
                    case "/schema/public/Platform.Schema.Documents.v1/Document_CareCenterMenuChildMenuItem":
                    case "/schema/public/Platform.Schema.Documents.v1/Document_CareCenterMenuPopupMenuItem":
                    case "/schema/public/Platform.Schema.Documents.v1/Document_BackgroundServices":
                        this.mode = 'MenuItem';
                        break;

                    case "/schema/public/Platform.Schema.Documents.v1/Document_Schema": this.mode = "Schema"; break;
                    case "/schema/public/Platform.Schema.Documents.v1/Document_CassandraKeyspace": this.mode = "Cassandra"; break;

                    default:
                        if ('ControlType' in this.model)
                            this.mode = 'UserControl';
                        else
                            this.mode = 'Unknown';
                        break;
                }

            this.loadDocRevisions();
            this.nameHasChanged();
            this.modelDataHasChanged();
            if (this.model)
                this.readModifyUserName();

            this.selectednode = null;
            this.selectedmodel = null;
            this.history = [];

            utils.executeAndCompileAllActions(this.controlData.Actions, { SelectedModel: this.selectedmodel }, this);
            //for (var i = 0; i < this.actions.length; i++)
            //    utils.executeAction(this.actions[i], null, this);
        },
        modelDataHasChanged() {
            if (this.modelData_watch$) {
                this.modelData_watch$(); // Release watch
                this.modelData_watch$ = null;
            }

            if (this.model) {
                switch (this.model.$typeSchema) {
                    case "/schema/public/Platform.Schema.Documents.v1/Document_UserControl":
                    case "/schema/public/Platform.Schema.Documents.v1/Document_UserControlTest":
                        this.dynamicControl = utils.getDynamicComponent(null, this.model.Data);
                        this.type = this.model.Data.ControlType;
                        this.designmodel = this.model.Data;
                        this.cData = this.model.Data.ControlData;
                        // Monitor the Data because this isn't watched anywhere else
                        this.modelData_watch$ = this.$watch(
                            function () {
                                return this.model.Data;
                            },
                            function (val, oldval) {
                                utils.log('modelData_watch$ fired for UserControl');
                                this.modelDataHasChanged();
                            });
                        break;

                    case "/schema/public/Platform.Schema.Documents.v1/Document_UserAction":
                        this.dynamicControl = utils.getDynamicAction(this.model.Data);
                        if (this.dynamicControl) {
                            this.type = this.model.Data.ActionType;
                            this.designmodel = this.model.Data;
                            this.cData = this.model.Data.ActionData;                            
                        }
                        else {
                            this.type = this.model.Data.ActionType; // 'Unknown';
                            this.designmodel = this.model.Data;
                            this.cData = this.model.Data.ActionData ? this.model.Data.ActionData : this.model.Data;
                        }
                        // Monitor the Data because this isn't watched anywhere else
                        this.modelData_watch$ = this.$watch(
                            function () {
                                return this.model.Data;
                            },
                            function (val, oldval) {
                                utils.log('modelData_watch$ fired for UserAction');
                                this.modelDataHasChanged();
                            });
                        break;

                    case "/schema/public/Platform.Schema.Documents.v1/Document_UserDefinedArea":
                        this.dynamicControl = 'stack-vertical';
                        this.type = 'VerticalStack';
                        this.designmodel = this.model;
                        this.cData = this.model;
                        break;

                    case "/schema/public/Platform.Schema.Documents.v1/Document_CareCenterMenuPopupMenuItem":
                        this.dynamicControl = 'carecenter-popupmenu';
                        this.type = this.model.MenuItemType;
                        this.designmodel = this.model;
                        this.cData = this.model.MenuItemData;
                        break;

                    case "/schema/public/Platform.Schema.Documents.v1/Document_CareCenterMenuItem":
                    case "/schema/public/Platform.Schema.Documents.v1/Document_CareCenterMenuChildMenuItem":
                        this.dynamicControl = 'carecenter-menu';
                        this.type = this.model.MenuItemType;
                        this.designmodel = this.model;
                        this.cData = this.model.MenuItemData.ControlData;
                        break;

                    case "/schema/public/Platform.Schema.Documents.v1/Document_BackgroundServices":
                        this.dynamicControl = 'background-service';
                        this.type = this.model.MenuItemType;
                        this.designmodel = this.model;
                        this.cData = this.model.MenuItemData;
                        break;

                    case "/schema/public/Platform.Schema.Documents.v1/Document_Schema":
                        this.type = 'Unknown';
                        this.designmodel = null;
                        this.cData = null;
                        break;

                    case "/schema/public/Platform.Schema.Documents.v1/Document_CassandraKeyspace":
                        this.type = 'Unknown';
                        this.designmodel = null;
                        this.cData = null;
                        break;

                    default:
                        if ('ControlType' in this.model) {
                            this.dynamicControl = utils.getDynamicComponent(null, this.model);
                            this.type = this.model.ControlType;
                            this.designmodel = this.model;
                            this.cData = this.model.ControlData;
                        }
                        else {
                            this.type = 'Unknown';
                            this.designmodel = this.model;
                            this.cData = this.model.Data ? this.model.Data.ControlData : {};
                        }
                        break;
                }

                if (!this.dynamicControl)
                    this.dynamicControl = 'default-unknown';

                this.dynamicControl += '-dsgn';
            }
        },
        async readModifyUserName() {
            if (this.model.$Modify_UserID) {
                const uri = 'Apps/Common/UserInfo/' + this.model.$Modify_UserID;
                const res = await utils.api.get(uri);

                this.modify_UserName = res.FullName;
            }
            else
                this.modify_UserName = '';
        },
        sync(prop, value) {
            let model = this.model;
            if (prop.includes('.')) {
                const parts = prop.split('.');
                for (let i = 0; i < parts.length - 1; i++)
                    model = model[parts[i]];

                Vue.set(model, parts[parts.length - 1], value);
            }
            else
                Vue.set(model, prop, value);
        },
        syncSchemaName(value) {
            let newname = value;
            let newns = '';
            const idx = newname.lastIndexOf('.');
            if (idx >= 0) {
                newns = newname.substr(0, idx);
                newname = newname.substr(idx + 1);
            }
            Vue.set(this.model, 'Namespace', newns);
            Vue.set(this.model, 'Name', newname);
        },
        async saveKeyspace() {
            const uri = 'Apps/Raw/CassandraKeyspace';

            if (this.model.$DeployID == 'sync') this.model.$DeployID = 'dev';

            this.save_in_progress = true;
            const data = utils.cleanModel(this.model);
            const res = await utils.api.request('POST', uri, data, false, true);
            this.save_in_progress = false;

            if (res.Result) {
                // Update these so we can save again
                this.model._id = res.Output.Id;
                this.model._rev = res.Output.Rev;
                this.nameHasChanged();
                this.loadDocRevisions();
            }
            else
                alert("Could not save! " + JSON.stringify(res, null, 4));
        },
        async saveKeyspaceAsNew() {
            const uri = 'Apps/Raw/CassandraKeyspace';

            const old_id = this.model._id;
            const old_rev = this.model._rev;

            Vue.delete(this.model, '_id');
            Vue.delete(this.model, '_rev');

            if (this.model.$DeployID == 'sync') this.model.$DeployID = 'dev';

            try {
                this.save_in_progress = true;
                const res = await utils.api.request('POST', uri, utils.cleanModel(this.model), false, true);
                this.save_in_progress = false;

                if (res.Result) {
                    // Update these so we can save again
                    this.model._id = res.Output.Id;
                    this.model._rev = res.Output.Rev;
                    this.nameHasChanged();
                    this.doc_revisions = [];
                }
                else {
                    // Restore these so we can save again
                    this.model._id = old_id;
                    this.model._rev = old_rev;

                    alert("Could not save! " + JSON.stringify(res, null, 4));
                }
            }
            catch (e) {
                // Restore these so we can save again
                this.model._id = old_id;
                this.model._rev = old_rev;

                alert("Error saving: " + e);
                this.save_in_progress = false;
            }
        },
        async saveUserControl(type, autoReplace) {
            let rev;
            let uri = 'Apps/Raw/UI/' + (type || 'UserControl');
            if (autoReplace) {
                uri += '?AutoReplace=' + (autoReplace ? 'true' : 'false');
                rev = this.model._rev;
                Vue.delete(this.model, '_rev');
            }

            if (this.model.$DeployID == 'sync') this.model.$DeployID = 'dev';

            this.save_in_progress = true;
            const data = utils.cleanModel(this.model);
            let res;
            try {
                res = await utils.api.request('POST', uri, data, false, true);
            }
            catch (e) {
                alert(`${JSON.stringify(e, null, 4)}`);
            }
            finally {
                this.save_in_progress = false;
            }

            if (res && res.Result) {
                //alert('Saved! ' + JSON.stringify(res, null, 4));

                // Update these so we can save again
                this.model._id = res.Output.Id;
                this.model._rev = res.Output.Rev;
                this.forceOverwrite = false;
                this.can_autoreplace = false;
                this.originalname = this.model.Name;
                this.loadDocRevisions();
            }
            else {
                alert("Could not save! " + JSON.stringify(res, null, 4));

                if (rev)
                    this.model._rev = rev;

                if (res && res.Output && res.Output.StatusCode == 409) // 409 : Conflict
                    this.can_autoreplace = true;
            }
        },
        async saveMenuItem(autoReplace) {
            // POST, URL: https://api-dev1.callcorplab.com/Apps/Raw/UI/MenuItem
            let rev;
            let uri = 'Apps/Raw/UI/MenuItem';
            if (autoReplace) {
                uri += '?AutoReplace=' + (autoReplace ? 'true' : 'false');
                rev = this.model._rev;
                Vue.delete(this.model, '_rev');
            }

            if (this.model.$DeployID == 'sync') this.model.$DeployID = 'dev';

            this.save_in_progress = true;
            const data = utils.cleanModel(this.model);
            const res = await utils.api.request('POST', uri, data, false, true);
            this.save_in_progress = false;

            if (res.Result) {
                //alert('Saved! ' + JSON.stringify(res, null, 4));

                // Update these so we can save again
                this.model._id = res.Output.Id;
                this.model._rev = res.Output.Rev;
                this.forceOverwrite = false;
                this.can_autoreplace = false;
                this.originalname = this.model.MenuItemData.Title;
                this.loadDocRevisions();
            }
            else {
                alert("Could not save! " + JSON.stringify(res, null, 4));

                if (rev)
                    this.model._rev = rev;

                if (res.Output.StatusCode == 409) // 409 : Conflict
                    this.can_autoreplace = true;
            }
        },
        async saveSchema() {
            const uri = 'Apps/Raw/Schema';

            if (this.model.$DeployID == 'sync') this.model.$DeployID = 'dev';

            this.save_in_progress = true;
            const data = utils.cleanModel(this.model);
            let res;
            try {
                res = await utils.api.request('POST', uri, data, false, true);
            }
            catch (e) {
                alert(`${JSON.stringify(e, null, 4)}`);
            }
            finally {
                this.save_in_progress = false;
            }

            if (res && res.Result) {
                // Update these so we can save again
                this.model._id = res.Output.Id;
                this.model._rev = res.Output.Rev;
                this.originalname = this.model.Namespace + '.' + this.model.Name;
                this.loadDocRevisions();
            }
            else
                alert("Could not save! " + JSON.stringify(res, null, 4));
        },
        async saveUserControlAsNew(type) {
            const uri = 'Apps/Raw/UI/' + (type || 'UserControl');

            const old_id = this.model._id;
            const old_rev = this.model._rev;

            Vue.delete(this.model, '_id');
            Vue.delete(this.model, '_rev');

            if (this.model.$DeployID == 'sync') this.model.$DeployID = 'dev';

            this.save_in_progress = true;
            const res = await utils.api.request('POST', uri, utils.cleanModel(this.model), false, true);
            this.save_in_progress = false;

            if (res.Result) {
                // Update these so we can save again
                this.model._id = res.Output.Id;
                this.model._rev = res.Output.Rev;
                this.nameHasChanged();

                //alert('Saved as New! ' + JSON.stringify(res, null, 4));
                this.doc_revisions = [];
            }
            else {
                // Restore these so we can save again
                this.model._id = old_id;
                this.model._rev = old_rev;

                alert("Could not save! " + JSON.stringify(res, null, 4));
            }
        },
        async saveMenuItemAsNew() {
            const uri = 'Apps/Raw/UI/MenuItem';

            const old_id = this.model._id;
            const old_rev = this.model._rev;

            Vue.delete(this.model, '_id');
            Vue.delete(this.model, '_rev');

            if (this.model.$DeployID == 'sync') this.model.$DeployID = 'dev';

            this.save_in_progress = true;
            const res = await utils.api.request('POST', uri, utils.cleanModel(this.model), false, true);
            this.save_in_progress = false;

            if (res.Result) {
                // Update these so we can save again
                this.model._id = res.Output.Id;
                this.model._rev = res.Output.Rev;
                this.nameHasChanged();

                //alert('Saved as New! ' + JSON.stringify(res, null, 4));
                this.doc_revisions = [];
            }
            else {
                // Restore these so we can save again
                this.model._id = old_id;
                this.model._rev = old_rev;

                alert("Could not save! " + JSON.stringify(res, null, 4));
            }
        },
        async saveSchemaAsNew() {
            const uri = 'Apps/Raw/Schema';

            const old_id = this.model._id;
            const old_rev = this.model._rev;

            Vue.delete(this.model, '_id');
            Vue.delete(this.model, '_rev');

            if (this.model.$DeployID == 'sync') this.model.$DeployID = 'dev';

            try {
                this.save_in_progress = true;
                const res = await utils.api.request('POST', uri, utils.cleanModel(this.model), false, true);
                this.save_in_progress = false;

                if (res.Result) {
                    // Update these so we can save again
                    this.model._id = res.Output.Id;
                    this.model._rev = res.Output.Rev;
                    this.nameHasChanged();
                    this.doc_revisions = [];
                }
                else {
                    // Restore these so we can save again
                    this.model._id = old_id;
                    this.model._rev = old_rev;

                    alert("Could not save! " + JSON.stringify(res, null, 4));
                }
            }
            catch (e) {
                // Restore these so we can save again
                this.model._id = old_id;
                this.model._rev = old_rev;

                alert("Error saving: " + e);
                this.save_in_progress = false;
            }
        },
        async saveExtractedEntity(type, newmodel) {
            const uri = 'Apps/Raw/UI/' + (type || 'UserControl');

            this.save_in_progress = true;
            const res = await utils.api.request('POST', uri, utils.cleanModel(newmodel), false, true);
            this.save_in_progress = false;

            if (!res.Result) {
                alert("Could not save! " + JSON.stringify(res, null, 4));
            }
            return res.Result;
        },
        selectNodeInternal(node, model) {
            // node: The parent object that is currently in the property grid
            // model: The object that is being navigated to, which will become the object in the property grid
            this.selectednode = node;
            this.selectedmodel = model || node.designmodel;

            // This fires the ControlDesigner's Actions, which can/may alter the property grid for us (can't remember yet)
            utils.executeAndCompileAllActions(this.controlData.Actions, { SelectedModel: this.selectedmodel }, this);
            //for (var i = 0; i < this.actions.length; i++)
            //    utils.executeAction(this.actions[i], null, this);

            // This looks specifically for UserActions and checks to see if there is a designer. If so, the ControlDesigner
            // will save the previous designer into a breadcrumb and change the display to show the action's designer.

            // We also wish to render a designer for the BasicPopupMenu's controls (they display when clicking on the button).
            // To do this, we need to know what the parent (node) is and that we are attempting to view the control's controls.
            // Discovered that node.designmodel.ControlType == 'BasicPopupMenu'
            if (model && node && node.designmodel) {
                let dynamicControl = utils.getDynamicHelperControl(node.designmodel.ControlType, model);
                if (dynamicControl) {
                    this.history.push({
                        dynamicControl: this.dynamicControl,
                        type: this.type,
                        designmodel: this.designmodel,
                        cData: this.cData,
                    });

                    dynamicControl += '-dsgn';

                    this.dynamicControl = dynamicControl;
                    this.type = model.ControlType;
                    this.designmodel = model;
                    this.cData = model.ControlData;
                    return;
                }
            }

            if (model && model.$typeSchema && model.$typeSchema.includes('/schema/public/Platform.Schema.UIActions.v1/UIAction_')) {
                let dynamicAction = utils.getDynamicAction(model);
                if (!dynamicAction)
                    return;

                this.history.push({
                    dynamicControl: this.dynamicControl,
                    type: this.type,
                    designmodel: this.designmodel,
                    cData: this.cData,
                });

                dynamicAction += '-dsgn';

                this.dynamicControl = dynamicAction; // 'stack-vertical-dsgn';
                this.type = model.ActionType;
                this.designmodel = model;
                this.cData = model.ActionData;
            }
        },
        SelectNode(node, model) {
            this.selectednode = node;
            this.selectedmodel = model || node.designmodel;

            utils.executeAndCompileAllActions(this.controlData.Actions, { SelectedModel: this.selectedmodel }, this);

            //for (var i = 0; i < this.actions.length; i++)
            //    utils.executeAction(this.actions[i], null, this);

            // Tell property grid to clear the breadcrumb since we are being navigated externally.
            this.$emit('reset-breadcrumb');
        },
        ExternalNavigate(url) {
            utils.log('ControlDesigner.ExternalNavigate(url:' + url + ')');
            this.ControlURL = url;

            utils.executeAndCompileAllActions(this.controlData.ExternalActions, { ControlURL: url }, this);
            //for (var i = 0; i < this.externalactions.length; i++)
            //    utils.executeAction(this.externalactions[i], null, this);
        },
        ExternalNavigateToName(type, name) {
            utils.executeAndCompileAllActions(this.controlData.OpenExternalActions, { Type: type, Name: name }, this);
        },
        navigateUp() {
            if (this.selectednode) {
                this.selectNodeInternal(this.selectednode.$parent);
            }
        },
        navigateToModel(value) {
            this.selectNodeInternal(this.selectednode, value);
        },
        navigateBreadcrumb(index) {
            const bc = this.history[index];
            this.dynamicControl = bc.dynamicControl;
            this.type = bc.type;
            this.designmodel = bc.designmodel;
            this.cData = bc.cData;

            if (index === 0)
                this.history = [];
            else
                this.history = this.history.slice(0, index);

            //for (var i = 0; i < this.actions.length; i++)
            //    utils.executeAction(this.actions[i]);
        },

        splitterMouseDown(e) {
            document.addEventListener('mousemove', this.splitterMouseMove);
            document.addEventListener('mouseup', this.splitterMouseUp);
            this.initialX = e.clientX;
            this.initialWidth = this.designer_width;
            e.cancelBubble = true;
            e.stopPropagation();
        },
        splitterMouseMove(e) {
            const dx = e.clientX - this.initialX;
            this.designer_width = this.initialWidth + dx;
            e.cancelBubble = true;
            e.stopPropagation();
        },
        splitterMouseUp(e) {
            document.removeEventListener('mousemove', this.splitterMouseMove);
            document.removeEventListener('mouseup', this.splitterMouseUp);
            e.cancelBubble = true;
            e.stopPropagation();
        },

        togglerawjson(visible) {
            if (visible) {
                this.temp_rawjson = JSON.stringify(this.model, null, 4);
                this.rawjson_ischanged = false;
            }
            this.viewjson = visible;
        },
        rawjsonchanged(text) {
            this.temp_rawjson = text;
            this.rawjson_ischanged = true;
        },
        commitrawjson() {
            try {
                const obj = JSON.parse(this.temp_rawjson);
                this.rawjson_ischanged = false;

                for (let prop in this.model)
                    if (this.model.hasOwnProperty(prop))
                        delete this.model[prop];
                
                for (let prop in obj)
                    if (obj.hasOwnProperty(prop))
                        Vue.set(this.model, prop, obj[prop]);

                this.modelHasChanged();

                return true;
            }
            catch (e) {
                alert(e);
                return false;
            }
        },
        revertrawjson() {
            this.temp_rawjson = JSON.stringify(this.model, null, 4);
            this.rawjson_ischanged = false;
        },
        test() {
            const origin = window.location.origin;
            const path = window.location.pathname;
            const name = (this.model.Public ? 'public/' : '') + this.model.Name;
            //const access_token = encodeURIComponent(this.Root.Root.access_token);

            const url = origin + path + '#' + name;

            window.open(url, '_blank');
        },
        async verifyHsm() {
            const hsmname = (this.model.Public ? 'public/' : '') + this.model.Name;
            const url = 'Apps/HSM/Errors/' + hsmname + '?forceReload=true';
            try {
                const res = await utils.api.get(url);
                if (res)
                    this.hsm_errors = res;
                else
                    this.hsm_errors = [];
            }
            catch (e) {
                utils.log(JSON.stringify(e));

                this.hsm_errors = [{
                    Type: 'Critical',
                    Name: this.model.Name,
                    Message: e.response.statusText,
                    ObjectId: ''
                }];
            }
        },
        revert(e) {
            this.model = null;
            this.Refresh();
        },
        async loadDocRevisions() {
            let db = 'ui';

            if (this.model && this.model._id)
                this.doc_revisions = await utils.api.get('Apps/DocumentRevisions/' + db + '?id=' + encodeURIComponent(this.model._id));
            else
                this.doc_revisions = [];
        },
        async loadAuditHistory() {
            if (this.model && this.model._id)
                // Apps/AuditHistory/ChangesById?DocumentID=83b91d21-c43b-4c89-8733-549ff6a6ff28&sortcol=time&startKey=[]&descending=true&maxMonths=12
                this.doc_changes = await utils.api.get('Apps/AuditHistory/ChangesById?DocumentId=' + encodeURIComponent(this.model._id) + '&sortcol=time&startKey=[]&descending=true&maxMonths=' + this.max_months_history);
            else
                this.doc_changes = [];
        },
        async loadRevision(rev) {
            let db = 'ui';
            let url = 'Apps/Document/' + db + '/' + this.model._id + '?rev=' + rev;
            this.ExternalNavigate(url);
        },
        async loadAuditHistoryVersion(moddatetime) {
            let doc = this.doc_changes.find(a => a.time == moddatetime);
            let url;
            if (doc == null)
                url = 'Apps/Raw/UI/' + this.model.$doctype + '/' + this.model._id;
            else
                url = 'Apps/AuditHistory/ChangedContent/' + doc.document_refid;

            this.ExternalNavigate(url);
        },
        async refreshTracking() {
            if (this.model && this.model._id) {
                const obj = {
                    user: this.fullName || 'Unknown',
                    since: this.editing_since ? this.editing_since.toString() : new Date(),
                    revision: this.model ? this.model._rev : '',
                };
                try {
                    const edits = await utils.api.request('POST', 'Apps/UIExt/Tracker?key=' + this.model._id, obj);
                    Vue.set(this, 'edits', edits);
                }
                catch (e) {
                    utils.error('Error posting to edit tracker', e);
                }
            }
            const tmp = this;
            this.timeoutReference = window.setTimeout(async () => {
                tmp.refreshTracking();
            }, 10 * 1000);
        },
        clearTracking() {
            if (!this.model || !this.model._id) return;
            utils.api.request('DELETE', 'Apps/UIExt/Tracker?key=' + this.model._id);
        },

        js_code_changed(text) {
            if (this.js_code)
                this.js_code.Javascript = text;
        },
        findNode(parent, id) {
            if (parent && typeof parent === 'object' && '$objectId' in parent)
                if (parent['$objectId'] == id)
                    return parent;
                else
                    utils.log(' '.repeat(this.indent) + '$objectId = ' + parent['$objectId']);

            if (!parent)
                return null;

            this.indent = this.indent + 1;

            const keys = Object.keys(parent);
            for (let j = 0; j < keys.length; j++) {
                const key = keys[j];
                const child = parent[key];
                utils.log(' '.repeat(this.indent) + 'key:' + key);

                if (typeof child === 'object') {
                    if (Array.isArray(child)) {
                        utils.log(' '.repeat(this.indent) + 'is array');
                        for (let i = 0; i < child.length; i++) {
                            const node = this.findNode(child[i], id);
                            if (node) return node;
                        }
                    }
                    else {
                        utils.log(' '.repeat(this.indent) + 'is object, scanning child');
                        const node = this.findNode(child, id);
                        if (node) return node;
                    }
                }
                else
                    utils.log(' '.repeat(this.indent) + key + ' : ' + child);
            }
            this.indent = this.indent - 1;
            return null;
        },
        hsmNavigateTo: function (id) {
            this.indent = 0;
            let node = this.findNode(this.model, id);
            if (node) {
                this.navigateToModel(node);

                //this.selectednode = node;

                //for (var i = 0; i < this.actions.length; i++)
                //    utils.executeAction(this.actions[i]);
            }
        },
        getHsmErrors: function (h) {
            const style = {
                overflow: "auto",
                display: "flex",
                "flex-direction": "column",
                "min-height": "100px",
                "min-width": "600px",
                "padding": "5px 10px 0 0",
                "margin": "0",
                "resize": "both"
            };
            const errors = [];
            for (let i = 0; i < this.hsm_errors.length; i++) {
                const e = this.hsm_errors[i];
                let jump;
                if (e.ObjectId)
                    jump = <span style={{ cursor: "pointer" }} on-click={() => this.hsmNavigateTo(e.ObjectId)}>Goto</span>;
                else
                    jump = <span>Error</span>;

                errors.push(
                    <tr>
                        <td style={{ padding: "0 5px 0 5px", border: "1px solid silver" }}>{jump}</td>
                        <td style={{ padding: "0 5px 0 5px", border: "1px solid silver" }}>{e.Type}</td>
                        <td style={{ padding: "0 5px 0 5px", border: "1px solid silver" }}>{e.Message}</td>
                    </tr>
                );
            }
            return (
                <div style={style}>
                    <div style={{ display: "flex", flexDirection: "row", flexGrow: "1" }}>
                        <div style={{
                            display: "flex", flexDirection: "columns", flexGrow: "1",
                            borderStyle: 'solid', borderWidth: '1px', "box-sizing": "border-box", "overflow": "auto",
                            backgroundColor: "white",
                            color: "black",
                            overflow: "auto"
                        }}>
                            <table width="100%" style={{ borderCollapse: "collapse", borderColor: "silver" }}>
                                <tr>
                                    <th></th>
                                    <th>Type</th>
                                    <th>Error</th>
                                </tr>
                                {errors}
                            </table>
                        </div>
                    </div>
                </div>);
        },
        refreshJSCode: function () {
            this.js_enabled = false;
            this.js_open = false;
            this.js_code = null;
        },
        toggleJSOpen: function () {
            this.js_open = !this.js_open;
            this.js_enabled = true;
        },
        getJavascriptEditor: function (h) {
            const style = {
                overflow: "auto",
                display: "flex",
                "flex-direction": "column",
                "min-height": "300px",
                "min-width": "600px",
                "padding": "0 10px 10px 10px",
                "margin": "0",
                "resize": "both",
                "direction": "rtl",
                "transform": "rotate(180deg)"
            };

            if (!this.js_enabled)
                return null;

            return h('div', {
                staticStyle: style,
                directives: [{
                    name: "show",
                    rawName: "v-show",
                    value: (this.js_open),
                    expression: "js_open"
                }]
            }, [
                <div style={{ display: "flex", flexDirection: "row", flexGrow: "1", "transform": "rotate(180deg)" }}>
                    <div style={{ display: "flex", flexDirection: "columns", flexGrow: "1", borderStyle: 'solid', borderWidth: '1px', "box-sizing": "border-box", "overflow": "auto" }}>
                        <ace-editor
                            editorId="editor_js_code"
                            lang="javascript"
                            content={this.js_code.Javascript}
                            on-change-content={(text) => this.js_code_changed(text)}>
                        </ace-editor>
                    </div>
                </div>
            ]);
        }
    },
    props: {
        name: '',
        root: null,
        parentType: '',
        controlData: {}
    },
    render(h) {
        if (!this.model)
            return <div>Loading...</div>;

        const deployoptions = [];
        deployoptions.push({ text: "Not Assigned", value: null });
        if (Array.isArray(this.controlData.Deployments))
        {
            for (let i = 0; i < this.controlData.Deployments.length; i++)
            {
                const dep = this.controlData.Deployments[i];
                deployoptions.push({ text: dep.Name, value: dep.Value });
            }
        }

        const styleLink = "https://docs.google.com/presentation/d/1O-ReVkqQJXzXr5DUkY8YSSqIbuLqm4f511zjdYSmnzg/edit#slide=id.p";
        let styleID = <a href={styleLink} target="_blank">Style Guide</a>;

        let deployID;
        const deploytitle = "Sets the deployment flag for this document";
        if (this.viewjson)
            deployID = <span style="margin-right: 15px;" v-tooltip={deploytitle}>Test Only <input type="checkbox" checked={this.model.$DeployID === 'ignore'} disabled /></span>;
        else
            deployID = <span style="margin-right: 15px;" v-tooltip={deploytitle}>Test Only <input type="checkbox" checked={this.model.$DeployID === 'ignore'} on-change={(e) => this.model.$DeployID = e.target.checked ? 'ignore' : 'none'} /></span>;

        let publicChkbx;
        if (this.viewjson)
            publicChkbx = <span style="margin-left: 10px;">Public: <input type="checkbox" style={{ margin: "5px" }} checked={this.model.Public} disabled /></span>;
        else
            publicChkbx = <span style="margin-left: 10px;">Public: <input type="checkbox" style={{ margin: "5px" }} checked={this.model.Public} on-change={(e) => this.sync("Public", e.target.checked)} /></span>;

        let viewJsonChkbx;
        if (this.viewjson)
            viewJsonChkbx = <v-btn elevation={0} x-small v-tooltip="WYSIWYG" color="white" on-click={(e) => this.togglerawjson(false)}><v-icon small>mdi mdi-cog</v-icon></v-btn>;
        else
            viewJsonChkbx = <v-btn elevation={0} x-small v-tooltip="Edit JSON" color="navy" on-click={(e) => this.togglerawjson(true)}><v-icon small>mdi mdi-cog</v-icon></v-btn>;

        let viewDesgnChkbx;
        if (this.mode == 'Schema')
            viewDesgnChkbx = null;
        else if (this.viewjson)
            viewDesgnChkbx = <v-btn elevation={0} x-small disabled><v-icon small color="gray">mdi mdi-window-restore</v-icon></v-btn>;
        else if (this.viewdsgn)
            viewDesgnChkbx = <v-btn elevation={0} x-small v-tooltip="Hide Designer" on-click={(e) => this.viewdsgn = false}><v-icon small color="blue">mdi mdi-window-restore</v-icon></v-btn>;
        else
            viewDesgnChkbx = <v-btn elevation={0} x-small v-tooltip="Show Designer" on-click={(e) => this.viewdsgn = true}><v-icon small color="navy">mdi mdi-window-restore</v-icon></v-btn>;

        let navup;
        if (!this.viewjson) {
            if (this.selectedmodel)
                navup = <v-btn elevation={0} x-small v-tooltip="Navigate to Parent Container" on-click={() => this.navigateUp()}> Up <v-icon small>mdi-arrow-up</v-icon></v-btn>;
            else
                navup = <v-btn elevation={0} x-small disabled> Up <v-icon small>mdi-arrow-up</v-icon></v-btn>;
        }

        const readOnly = this.VarByName('Customer') && (this.VarByName('Customer').CustomerID !== this.model.CustomerID);
        let readOnlyText;
        if (readOnly)
            readOnlyText = <span title="You are not the owner of this document" style={{ color: "red" }}>Read-Only</span>;

        let header, header2;
        let nameLabel;
        let nameInput;
        let autoreplace;
        let save;
        let savenew;
        let test;
        let compile;
        let audit_history;
        switch (this.mode) {
            case 'UserControl':
            case 'Action':
            case 'UserDefinedArea':
                {
                    nameLabel = "Name:";
                    if (this.viewjson)
                        nameInput = <v-text-field outlined single-line dense hide-details value={this.model.Name} readonly></v-text-field>;
                    else
                        nameInput = <v-text-field outlined background-color="white" single-line dense hide-details value={this.model.Name} on-input={(value) => this.sync("Name", value)}></v-text-field>;

                    if (this.save_in_progress)
                        save = <v-btn elevation={0} x-small title="Saving..." disabled>Save</v-btn>;
                    else if (readOnly)
                        save = <v-btn elevation={0} x-small title="Document is read-only, you do not own it" disabled>Save</v-btn>;
                    else if (this.rawjson_ischanged)
                        save = <v-btn elevation={0} x-small title="Commit first, JSON has changed" disabled>Save</v-btn>;
                    else
                        save = <v-btn elevation={0} x-small on-click={() => this.saveUserControl(this.mode, this.forceOverwrite)}>{this.hasNameChanged ? 'Rename' : 'Save'}</v-btn>;

                    if (this.can_autoreplace)
                        autoreplace = <span>Force Overwrite: <input type="checkbox" checked={this.forceOverwrite} on-change={(e) => this.forceOverwrite = e.target.checked} /></span>;

                    if (this.save_in_progress)
                        savenew = <v-btn elevation={0} x-small disabled>Save As New</v-btn>;
                    else if ((this.hasNameChanged || readOnly) && !this.rawjson_ischanged)
                        savenew = <v-btn elevation={0} x-small on-click={() => this.saveUserControlAsNew(this.mode)}>Save As New</v-btn>;
                    else
                        savenew = <v-btn elevation={0} x-small disabled>Save As New</v-btn>;

                    test = <v-btn elevation={0} x-small on-click={() => this.test()}>Test</v-btn>;

                    if (this.model.Data && this.model.Data.ControlType == 'Hsm')
                        compile = <v-btn elevation={0} x-small on-click={() => this.verifyHsm()}>Verify HSM</v-btn>;

                    break;
                }

            case 'MenuItem':
                {
                    nameLabel = "Menu Title:";
                    if (this.viewjson)
                        nameInput = <v-text-field outlined single-line dense hide-details value={this.model.MenuItemData.Title} readonly></v-text-field>;
                    else
                        nameInput = <v-text-field outlined background-color="white" single-line dense hide-details value={this.model.MenuItemData.Title} on-input={(value) => this.sync("MenuItemData.Title", value)}></v-text-field>;

                    if (this.save_in_progress)
                        save = <v-btn elevation={0} x-small title="Saving..." disabled>Save</v-btn>;
                    else if (readOnly)
                        save = <v-btn elevation={0} x-small title="Document is read-only, you do not own it" disabled>Save</v-btn>;
                    else if (this.rawjson_ischanged)
                        save = <v-btn elevation={0} x-small title="Commit first, JSON has changed" disabled>Save</v-btn>;
                    else
                        save = <v-btn elevation={0} x-small on-click={() => this.saveMenuItem(this.forceOverwrite)}>{this.hasNameChanged ? 'Rename' : 'Save'}</v-btn>;

                    if (this.can_autoreplace)
                        autoreplace = <span>Force Overwrite: <input type="checkbox" checked={this.forceOverwrite} on-change={(e) => this.forceOverwrite = e.target.checked} /></span>;

                    if (this.save_in_progress)
                        savenew = <v-btn elevation={0} x-small disabled>Save As New</v-btn>;
                    else if ((this.hasNameChanged || readOnly) && !this.rawjson_ischanged)
                        savenew = <v-btn elevation={0} x-small on-click={() => this.saveMenuItemAsNew()}>Save As New</v-btn>;
                    else
                        savenew = <v-btn elevation={0} x-small disabled>Save As New</v-btn>;

                    break;
                }

            case 'Schema':
                {
                    viewDesgnChkbx = null;
                    nameLabel = "Schema Name:";
                    if (this.viewjson)
                        nameInput = <v-text-field outlined single-line dense hide-details value={this.model.Namespace + '.' + this.model.Name} readonly></v-text-field>;
                    else
                        nameInput = <v-text-field outlined background-color="white" single-line dense hide-details value={this.model.Namespace + '.' + this.model.Name} on-input={(value) => this.syncSchemaName(value)}></v-text-field>;

                    if (this.save_in_progress)
                        save = <v-btn elevation={0} x-small title="Saving..." disabled>Save</v-btn>;
                    else if (readOnly)
                        save = <v-btn elevation={0} x-small title="Document is read-only, you do not own it" disabled>Save</v-btn>;
                    else if (this.rawjson_ischanged)
                        save = <v-btn elevation={0} x-small title="Commit first, JSON has changed" disabled>Save</v-btn>;
                    else
                        save = <v-btn elevation={0} x-small on-click={() => this.saveSchema()}>{this.hasNameChanged ? 'Rename' : 'Save'}</v-btn>;

                    if (this.save_in_progress)
                        savenew = <v-btn elevation={0} x-small disabled>Save As New</v-btn>;
                    else if ((this.hasNameChanged || readOnly) && !this.rawjson_ischanged)
                        savenew = <v-btn elevation={0} x-small on-click={() => this.saveSchemaAsNew()}>Save As New</v-btn>;
                    else
                        savenew = <v-btn elevation={0} x-small disabled>Save As New</v-btn>;

                    break;
                }

            case 'Cassandra':
                {
                    // Cassandra files are never public, disable checkbox
                    publicChkbx = null;
                    viewDesgnChkbx = null;
                    nameLabel = "Keyspace:";
                    if (this.viewjson)
                        nameInput = <v-text-field outlined single-line dense hide-details value={this.model.Keyspace} readonly></v-text-field>;
                    else
                        nameInput = <v-text-field outlined background-color="white" single-line dense hide-details value={this.model.Keyspace} on-input={(value) => this.sync("Keyspace", value)}></v-text-field>;

                    if (this.save_in_progress)
                        save = <v-btn elevation={0} x-small title="Saving..." disabled>Save</v-btn>;
                    else if (readOnly)
                        save = <v-btn elevation={0} x-small title="Document is read-only, you do not own it" disabled>Save</v-btn>;
                    else if (this.rawjson_ischanged)
                        save = <v-btn elevation={0} x-small title="Commit first, JSON has changed" disabled>Save</v-btn>;
                    else
                        save = <v-btn elevation={0} x-small on-click={() => this.saveKeyspace()}>{this.hasNameChanged ? 'Rename' : 'Save'}</v-btn>;

                    if (this.save_in_progress)
                        savenew = <v-btn elevation={0} x-small disabled>Save As New</v-btn>;
                    else if ((this.hasNameChanged || readOnly) && !this.rawjson_ischanged)
                        savenew = <v-btn elevation={0} x-small on-click={() => this.saveKeyspaceAsNew()}>Save As New</v-btn>;
                    else
                        savenew = <v-btn elevation={0} x-small disabled>Save As New</v-btn>;

                    break;
                }
        }

        if (this.doc_changes && this.doc_changes.length) {
            let items = this.doc_changes.map(a => <option value={a.time}>{this.f_date(a.time, 'medium')}</option>);
            // If the current document is in the changes list, don't bother adding it. Otherwise, put the current at the top of the list
            if (!this.doc_changes.find(a => a.time == this.model.$Modify_DateTime))
                items = [
                    <option value={this.model.$Modify_DateTime}>{this.f_date(this.model.$Modify_DateTime, 'medium')}</option>,
                    ...items
                ];
            audit_history = (
                <select value={this.model.$Modify_DateTime} on-change={(e) => this.loadAuditHistoryVersion(e.target.value)}>
                    {[
                        <option value="_nothing_">Latest</option>,
                        ...items
                    ]}
                </select>
            );
        }

        header = (this.VarByName('Customer') && (this.VarByName('Customer').CustomerID == "50")) ? [styleID, deployID, viewJsonChkbx, viewDesgnChkbx, publicChkbx, nameLabel, nameInput] : [deployID, viewJsonChkbx, viewDesgnChkbx, publicChkbx, nameLabel, nameInput];
        //header = [styleID, deployID, viewJsonChkbx, viewDesgnChkbx, publicChkbx, nameLabel, nameInput];
        header2 = [readOnlyText, save, savenew, autoreplace, navup, compile]; // Removed test as 2nd from last (8/17/23) not useful anyway

        const style = {
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            ...utils.getSize(this.controlData.SizeOptions, this.parentType, this.$parent),
        };

        let revision_list;
        if (this.doc_revisions.length > 0)
            revision_list = (
                <select value={this.model._rev} on-change={(e) => this.loadRevision(e.target.value)}>
                    {this.doc_revisions.filter(a => a.status == 'available').map(a => <option value={a.rev}>{a.rev.split('-')[0]}</option>)}
                </select>
            );

        if (this.viewjson)
        {
            let commitjson;
            if (this.rawjson_ischanged)
                commitjson = [<v-btn elevation={0} x-small on-click={() => this.commitrawjson()}>Commit</v-btn>, <v-btn elevation={0} x-small on-click={() => this.revertrawjson()}>Cancel</v-btn> ];
            else
                commitjson = <v-btn elevation={0} x-small disabled>Commit</v-btn>;

            //v-tooltip - 3rd party tooltip (must be installed)
            //{header2} {commitjson} <i class="mdi mdi-information" title={'Logged in under customer ID ' + this.Root.CustomerID}></i> <i class="mdi mdi-information" title={'Document customer ID ' + this.model.CustomerID}></i> Revision History: {revision_list}
            return (
                <div style={style}>
                    <div class="property-grid" style={{ display: "flex", flexDirection: "row", flexGrow: "0", flexShrink: "0", minHeight: "24px", background: "silver", alignItems: "center", gap: "5px" }}>
                        {header}
                    </div>
                    <div class="property-grid" style={{ display: "flex", flexDirection: "row", flexGrow: "0", flexShrink: "0", minHeight: "24px", maxHeight: "24px", background: "silver", alignItems: "center", gap: "5px" }}>
                        {header2} 
                        {commitjson} 
                        <v-icon v-tooltip={'Logged in under customer ID ' + this.Root.CustomerID}>mdi-information</v-icon>
                        <v-icon v-tooltip={'Document customer ID ' + this.model.CustomerID}>mdi-information-outline</v-icon>
                        <span>Revision History: {revision_list}</span>
                        <span>{audit_history}<v-btn small icon onClick={(e) => this.loadAuditHistory()}><v-icon v-tooltip='Refresh Change Log'>mdi-refresh</v-icon></v-btn></span>
                    </div>

                    <div style={{ display: "flex", flexDirection: "row", flexGrow: "1" }}>
                        <div style={{ display: "flex", flexDirection: "columns", flexGrow: "1", borderStyle: 'solid', borderWidth: '1px', "box-sizing": "border-box", "overflow": "auto" }}>
                            <ace-editor
                                editorId="editorA"
                                lang="json"
                                content={this.temp_rawjson}
                                on-change-content={(text) => this.rawjsonchanged(text)}>
                            </ace-editor>
                        </div>
                    </div>
                </div>
            );
        }

        const form = ["*"];

        const DynamicControl = this.dynamicControl;

        let breadcrumbs;
        if (this.history.length > 0) {
            const crumb_style = {
                cursor: "pointer",
                color: "white",
                backgroundColor: "gray",
                //textDecoration: "underline",
                fontSize: "small",
                fontFamily: "Arial",
                margin: "3px",
                padding: "2px",
                borderRadius: "2px",
            };
            const bcitems = [];
            for (let i = 0; i < this.history.length; i++)
            {
                const bc = this.history[i];
                bcitems.push(
                    <span style={crumb_style} on-click={() => this.navigateBreadcrumb(i)}>
                        <i class="mdi mdi-menu-left" /> {bc.cData.Name || bc.type}
                    </span>
                );
            }
            breadcrumbs = <div style={{ minHeight: "30px", display: "flex", flexDirection: "horizontal" }}>{bcitems}</div>;
        }

        let designer;
        let splitter;
        if (this.viewdsgn && (this.mode != 'Schema' && this.mode != 'Cassandra')) {
            designer = (
                <div class="designer-base" style={{ display: "flex", flexDirection: "column", minWidth: this.designer_width + "px", maxWidth: this.designer_width + "px", overflow: "auto" }}>
                    {breadcrumbs}
                    <DynamicControl
                        key={this.designmodel ? this.designmodel.$objectId : '_id_'}
                        root={this}
                        type={this.type}
                        parentType="VerticalStack"
                        designmodel={this.designmodel}
                        controlData={this.cData}
                        controlName={this.Name}
                        scopeitems={this.scopeitems}
                        controlscope={this.controlscope}>
                    </DynamicControl>
                </div>
            );
            splitter = (
                <div
                    style={{
                        backgroundColor: "gray", width: "5px", minWidth: "5px", maxWidth: "5px", overflow: "hidden", cursor: "ew-resize"
                    }}
                    on-mousedown={(e) => this.splitterMouseDown(e)}>
                </div>
            );
        }

        const editing = [];
        let outofdate;
        if (this.edits) {
            const colors = [ 'orange', 'yellow', 'cyan', 'magenta', 'lime'];
            for (let i = 0; i < this.edits.length; i++) {
                if (this.edits[i].user == this.fullName)
                    continue;

                let thisrev, thatrev;
                if (this.model && this.model._rev && this.edits[i].revision) {
                    thisrev = parseInt( this.model._rev.split('-')[0] );
                    thatrev = parseInt( this.edits[i].revision.split('-')[0] );
                    if (thatrev > thisrev)
                        outofdate = (
                            <span>
                                <i style={{ color: 'red' }} class="mdi mdi-alert warning-blinking" title="You are not editing the latest version!" />
                            </span>
                        );
                }

                const color = colors[i % colors.length];
                const dt = new Date(this.edits[i].since);
                editing.push(
                    <span>
                        <i style={{ color: color }} class="mdi mdi-account" title={'Currently being edited by ' + this.edits[i].user + ' since ' + dt.toLocaleTimeString() + ' revision ' + thatrev} />
                    </span>
                );
            }
        }

        let revision;
        if (this.model._rev)
            revision = <span title={this.model._rev}>{this.model._rev.split('-')[0]}</span>;
        else
            revision = <span>New</span>;

        if (!this.js_code) {
            if (this.model.Data && this.model.Data.ControlData && this.model.Data.ControlData.Javascript)
                this.js_code = this.model.Data.ControlData;
        }

        let jseditor;
        if (this.js_code)
        {
            let js_refresh;
            if (this.js_open)
                js_refresh = <i style={{ color: "white", cursor: "pointer" }} title="Refresh" class="mdi mdi-cached" onClick={(e) => this.refreshJSCode()} />;

            jseditor = (
                <div style={{ position: "fixed", bottom: "0", zIndex: "999", backgroundColor: "maroon", color: "white", marginLeft: "5px", padding: "2px", borderRadius: "5px 5px 0 0" }}>
                    {this.getJavascriptEditor(h)}
                    <span style={{ cursor: "pointer", margin: "0 10px" }} onClick={(e) => this.toggleJSOpen()}>Javascript</span>
                    {js_refresh}
                </div>);
        }

        let hsmerrors;
        if (this.hsm_errors.length > 0)
            hsmerrors = (
                <div style={{ position: "fixed", bottom: "0", zIndex: "999", backgroundColor: "red", color: "white", marginLeft: "100px", padding: "2px 5px 2px 10px", borderRadius: "5px 5px 0 0" }}>
                    {this.hsm_errors_open ? this.getHsmErrors(h) : null}
                    <span style={{ cursor: "pointer" }} onClick={(e) => this.hsm_errors_open = !this.hsm_errors_open}>HSM Errors</span>
                </div>);

        return (
            <div style={style}>
                <div class="property-grid" style={{ display: "flex", flexDirection: "row", flexGrow: "0", flexShrink: "0", minHeight: "24px", background: "silver", alignItems: "center", gap: '5px' }}>
                    {header}
                </div>
                <div class="property-grid" style={{ display: "flex", flexDirection: "row", flexGrow: "0", flexShrink: "0", minHeight: "24px", maxHeight: "24px", background: "silver", alignItems: "center", gap: '5px' }}>
                    {outofdate}
                    {header2} 
                    <v-icon v-tooltip={'Document customer ID ' + this.model.CustomerID}>mdi-information-outline</v-icon>
                    <span>Revision:{revision_list} ({this.modify_UserName} @ {this.modify_DateTime})</span>
                    <span>{audit_history}<v-btn small icon onClick={(e) => this.loadAuditHistory()}><v-icon v-tooltip='Refresh Change Log'>mdi-refresh</v-icon></v-btn></span>
                    {editing}
                </div>

                <div style={{ display: "flex", flexDirection: "row", flexGrow: "1", overflow: "auto" }}>
                    {designer}
                    {splitter}

                    <div style={{ display: "flex", flexDirection: "columns", flexGrow: "1", borderStyle: 'solid', borderWidth: '1px', "box-sizing": "border-box", "overflow": "auto" }}>
                        <property-grid name={this.model.Name || this.model.MenuName} root={this} parentType="VerticalStack" schema={this.sel_schema} form={form} cmodel={this.sel_model} child={0} navigateToModel={this.navigateToModel}></property-grid>
                    </div>
                </div>

                {jseditor}
                {hsmerrors}
            </div>
        );
    }
});