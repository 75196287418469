import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import EventBus from '../event-bus.js';

import utils from '../../Shared/utils.jsx';
import methods from '../../Shared/methods';

Vue.component('timeout', {
    mixins: [BaseComponent],
    data: function () {
        return {
            delay_expn: 0,
            actions: [],
            actions_compiled: false,
        }
    },
    computed: {
    },
    created() {
        this.delay_expn = utils.compileExpression(this, this.controlData.Delay);
    },
    //Mounted Replaced with preRenderComplete
    destroyed() {
        this.Stop();
    },
    methods: {
        preRenderComplete() {
            if (this.controlData.StartImmediately)
                this.Start();
            this.finishRenderHandler(this);
        },
        Start() {
            const delay = utils.evaluate(this.delay_expn, this);

            if (!this.timeout) {
                this.timeout = setTimeout(() => {
                    this.invokeActions();
                    this.timeout = null;
                }, delay);
            }
        },
        Stop() {
            if (this.timeout)
                clearTimeout(this.timeout);

            this.timeout = null;
        },
        async invokeActions() {
            if (this.controlData.Actions && !this.actions_compiled) {
                for (var i = 0; i < this.controlData.Actions.length; i++)
                    try {
                        const a = await utils.compileAction(this, this.controlData.Actions[i])
                        if (a)
                            this.actions.push(a);
                    }
                    catch (e) {
                        utils.error(`BroadcastListener ${this.name} Actions[${i}] error compiling: ${e}`);
                    }

                this.actions_compiled = true;
            }

            for (var i = 0; i < this.actions.length; i++)
                await utils.executeAction(this.actions[i], null, this);
        }
    },
    props: {
    },
    render(h) {
        return null;
    }
});