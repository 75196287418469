import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import BaseComponent from './BaseComponentMixin.jsx';

Vue.component('dynamic-control-container-dsgn', {
    mixins: [BaseComponent],
    data: function () {
        return {
        }
    },
    render(h) {
        let name;
        if (!this.name)
            name = this.type;
        else
            name = this.name + ':' + this.type || 'Unnamed';

        const style = {
            paddingRight: "4px",
            paddingBottom: "4px",
            ...utils.getSize(this.controlData ? this.controlData.SizeOptions : null, this.parentType, this.$parent)
        };
//                    <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>

        return (
            <div
                class={{ 'designer-container': true, selected: this.selected$ }}
                style={style}
                on-click={(e) => this.handleClick(e)}>

                <v-sheet class="mx-auto pa-1" elevation={4} style="display: flex; flex-direction: column; flex-grow: 1;">
                    <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
                    {name}<br />
                    <v-icon>mdi mdi-puzzle-edit</v-icon>
                </v-sheet>
            </div>
        );
    }
});