import Vue from 'vue';
import BaseComponent from './baseFormMixin.jsx';
import utils from '@/Shared/utils.jsx';
import cDateRangePicker from '@/Application/components/vuecontrols/DateRangePicker';

import { appSettings } from '@/Shared/appSettings.js';


Vue.component('sform-daterangepicker', {
    mixins: [BaseComponent],
    components: {cDateRangePicker},
    data: () => ({
        titleRenderer: null,
        defaultRenderer: null,
    }),
    props: {
    },
    created() {
    },
    mounted() {
        this.loadComplete();
    },
    computed: {
        default: function() {
            try {
                if (this.defaultRenderer === null && (this.element.default || this.element.schema.default))
                    this.defaultRenderer = utils.compile(this, this.element.default || this.element.schema.default);

                if (this.defaultRenderer)
                    return utils.evaluate(this.defaultRenderer, this);
                else
                    return '';
            }
            catch (e)
            {
                utils.warn('Default could not evaluate expression: ' + (this.element.default || this.element.schema.default) + '; ' + e);
                return '';
            }
        },
    },
    methods: {
        applyDateRangeHandler(date) {
            this.sync(date);
        },
    },
    render() {
        try {

            let dateRangePicker = <c-Date-Range-Picker
                type={this.element?.schema?.format || this.element?.type}
                allowTime={this.element?.formatData?.AllowTime}
                default={this.default}
                placeholder={this.element?.title}
                message={this.element?.schema?.description || this.element?.description}
                allowFutureDate={this.element?.formatData?.AllowFutureDate}
                allowPastDate={this.element?.formatData?.AllowPastDate}
                on-apply-date-range={this.applyDateRangeHandler}
                on-blur={this.onBlur}
                on-focus={this.onFocus}
                noinput={this.noinput}

                timePickerIncrement={this.element?.formatData?.TimePickerIncrement}
            ></c-Date-Range-Picker>

        if (this.appearance)
            dateRangePicker.componentOptions.propsData = { ...dateRangePicker.componentOptions.propsData, ...this.appearance };

        return dateRangePicker;
        }
        catch (e) {
            utils.error('DateRangePicker Render failed', e);
            return <div>DateRangePicker Failed to Render {e}</div>;
        }
    }
});