import Vue from 'vue';
import SchemaForm from './form/SchemaForm.jsx';
import PropertyGrid from './form/PropertyGrid.jsx';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

Vue.component('basic-form-dsgn', {
    data: () => ({
        displayexpr: null,
        schemaurl: null,
        schemaraw: null,
        modelurl: null,
        modelvalue: null,
        schema: {},
        model: {},
        form: ['*'],
    }),
    created() {
    },
    mounted() {
        this.Refresh();
    },
    computed: {
        Root: function () {
            return this.root._self;
        },
        Model: function () {
            return this.model;
        },
        DesignModel: function () {
            return this.designmodel;
        },
        selected$: function () {
            return this.Root.SelectedNode && this.Root.SelectedNode == this;
        },
    },
    methods: {
        ...methods,
        handleClick(e) {
            this.Root.SelectNode(this);
            e.cancelBubble = true;
            e.stopPropagation();
        },
        selectSchema(e) {
            this.Root.SelectNode(this, this.controlData.Schema);
            e.cancelBubble = true;
            e.stopPropagation();
        },
        selectModel(e) {
            this.Root.SelectNode(this, this.controlData.Model);
            e.cancelBubble = true;
            e.stopPropagation();
        },
        selectForm(e) {
            this.Root.SelectNode(this, this.controlData.Form);
            e.cancelBubble = true;
            e.stopPropagation();
        },
        selectFormElement(element) {
            this.Root.SelectNode(this, element);
        },
        async Refresh() {
            try {
                if (this.controlData.Schema.Type === 'URL' && this.controlData.Schema.Definition)
                    this.schema = await utils.schema.get(this.controlData.Schema.Definition);
                else if (this.controlData.Schema.Definition) 
                    this.schema = this.controlData.Schema.Definition;
                else
                    this.schema = null;
            }
            catch (e)
            {
                this.schema = null;
            }

            try {
                if (this.controlData.Model.Type === 'URL' && this.controlData.Model.Definition)
                    this.model = await utils.api.get(this.controlData.Model.Definition);
                else if (this.controlData.Model.Definition && typeof this.controlData.Model.Definition === 'object')
                    this.model = this.controlData.Model.Definition;
                else
                    this.model = {};
            }
            catch (e)
            {
                this.model = {};
            }

            try {
                if (this.controlData.Form.Type === 'URL' && this.controlData.Form.Definition)
                    this.form = await utils.api.get(this.controlData.Form.Definition);
                else if (this.controlData.Form.Definition)
                    this.form = this.controlData.Form.Definition;
            }
            catch (e) {
                this.form = ['*'];
            }

            if (this.schema) {
                const resolved = utils.schema.resolve_Of(this.schema);

                for (var key in resolved.properties) {
                    const element = resolved.properties[key];

                    if (('default' in element) && !(key in this.model)) {
                        Vue.set(this.model, key, element['default']);
                    }
                    else if (element.type === 'object' && !(key in this.model)) {
                        const model = utils.schema.getDefaultModel(element);
                        Vue.set(this.model, key, model);
                    }
                }
            }
        }
    },
    props: {
        name: '',
        root: null,
        designmodel: null,
        parentType: '',
        controlData: {}
    },
    render() {
        let Control = 'schema-form-dsgn';
        if (this.controlData.FormType === 'PropertyGrid')
            Control = 'property-grid-dsgn';

        let title;
        if (this.controlData.Title)
            title = <h1>{this.controlData.Title}</h1>;


        return (
            <div
                class={{ 'designer-form': true, selected: this.selected$ }}
                style={{ position: "relative", borderStyle: 'solid', borderWidth: '1px', padding: "5px", ...utils.getSize(this.controlData.SizeOptions, this.parentType, this.$parent) }}
                on-click={(e) => this.handleClick(e)}>

                <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
                <div key="1" class="design-options" on-click={(e) => this.selectForm(e)} style={{ top: "4px", right: "124px", display: this.selected$ ? "block" : "none" }}>Form</div>
                <div key="2" class="design-options" on-click={(e) => this.selectModel(e)} style={{ top: "4px", right: "64px", display: this.selected$ ? "block" : "none" }}>Model</div>
                <div key="3" class="design-options" on-click={(e) => this.selectSchema(e)} style={{ top: "4px", right: "4px", display: this.selected$ ? "block" : "none" }}>Schema</div>

                {title}
                <Control name={this.controlData.Name || 'BasicForm'} designmodel={this.designmodel} root={this.root} schema={this.schema} form={this.form} cmodel={this.model} child={0} selected={this.selected$} selectFormElement={this.selectFormElement}></Control>
            </div>
        );
    }
});