
<template>
    <div :style="sizeStyle">
        <v-text-field
            v-show="isvisible"
            :class="{'c-DateRangePicker': true, [`c-name-${name || 'unnamed'}`]: true, 'mt-1': true }"
            dense
            outlined
            @click="datePickerOpen = true"
            readonly
            :hint="message"
            :value="displayValue"
            :hide-details="hideDetails"
            :disabled="readonly"
        >
            <translation-container slot="label" :context="context" :value="placeholderText"></translation-container>
            <translation-container slot="message" :context="context" :value="message"></translation-container>
            
            <span slot="prepend-inner">
                <v-icon ignoreslot="prepend"
                        v-if="isSingleDatePicker && !disabled"
                        :disabled="cannotMoveBackward"
                        @click="moveDay(-1)">mdi-chevron-left</v-icon>

                <v-icon @click="datePickerOpen = true" _vif="(!disabled)" :disabled="disabled">mdi-calendar-cursor</v-icon>

                <v-menu right
                        :disabled="disabled"
                        offset-y
                        :nudge-bottom="message ? 33 : 13"
                        :nudge-left="25"
                        :close-on-content-click="false"
                        v-model="datePickerOpen">
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on"></span>
                    </template>
                    <v-card style="display: flex;">
                        <v-list dense :flat="false" v-if="isDateRangePicker">
                            <v-list-item-group>
                                <v-list-item v-for="p in presets" :key="p.label" @click="setRange(p.range)">
                                    <v-list-item-content>
                                        <v-list-item-title>{{p.label}}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                        <FunctionalCalendar class="vfc-noshadow-callcorp"
                                            :configs="calendarConfigs"
                                            :value="functionalCalendarFormattedData"
                                            @input="onCalendarInput">
                            <div slot="footer" class="drp-footer">
                                <div v-if="isDateRangePicker && allowTime" class="drp-time-picker">
                                    <div class="drp-left-time-picker">
                                        <v-select class="drp-tp-option drp-ltp-hour"
                                                  outlined
                                                  dense
                                                  hide-details
                                                  :menu-props="{ offsetY: true, auto: true }"
                                                  v-model="startHour"
                                                  :items="timePickerHours"></v-select>
                                        :
                                        <v-select class="drp-tp-option drp-ltp-min"
                                                  outlined
                                                  dense
                                                  hide-details
                                                  :menu-props="{ offsetY: true, auto: true }"
                                                  :disabled="timePickerIncrement == 60"
                                                  v-model="startMin"
                                                  :items="timePickerMin"></v-select>
                                        <v-select class="drp-tp-option drp-ltp-meridiem"
                                                  outlined
                                                  dense
                                                  hide-details
                                                  :menu-props="{ offsetY: true, auto: true }"
                                                  v-model="startMeridiem"
                                                  v-if="!is24HourLocale"
                                                  :items="['AM', 'PM']"></v-select>
                                    </div>

                                    <div class="drp-right-time-picker">
                                        <v-select class="drp-tp-option drp-rtp-hour"
                                                  outlined
                                                  dense
                                                  hide-details
                                                  :menu-props="{ offsetY: true, auto: true }"
                                                  v-model="endHour"
                                                  :items="timePickerHours"></v-select>
                                        :
                                        <v-select class="drp-tp-option drp-rtp-min"
                                                  outlined
                                                  dense
                                                  hide-details
                                                  :disabled="timePickerIncrement == 60"
                                                  :menu-props="{ offsetY: true, auto: true }"
                                                  v-model="endMin"
                                                  :items="timePickerMin"></v-select>
                                        <v-select class="drp-tp-option drp-rtp-meridiem"
                                                  outlined
                                                  dense
                                                  hide-details
                                                  :menu-props="{ offsetY: true, auto: true }"
                                                  v-model="endMeridiem"
                                                  v-if="!is24HourLocale"
                                                  :items="['AM', 'PM']"></v-select>
                                    </div>
                                </div>
                                <div v-if="isSingleDatePicker && allowTime" class="dp-time-picker">
                                    <div class="dp-time-picker">
                                        <v-select class="drp-tp-option dp-ltp-hour"
                                                  outlined
                                                  dense
                                                  hide-details
                                                  :menu-props="{ offsetY: true, auto: true }"
                                                  v-model="selectedHour"
                                                  :items="timePickerHours"></v-select>
                                        :
                                        <v-select class="drp-tp-option dp-ltp-min"
                                                  outlined
                                                  dense
                                                  hide-details
                                                  :menu-props="{ offsetY: true, auto: true }"
                                                  v-model="selectedMinute"
                                                  :items="timePickerMin"></v-select>
                                        <v-select class="drp-tp-option dp-ltp-meridiem"
                                                  outlined
                                                  dense
                                                  hide-details
                                                  :menu-props="{ offsetY: true, auto: true }"
                                                  v-model="selectedMeridiem"
                                                  v-if="!is24HourLocale"
                                                  :items="['AM', 'PM']"></v-select>
                                    </div>
                                </div>
                                <div class="drp-footer-btns">
                                    <span></span>
                                    <v-btn depressed
                                           text
                                           @click="cancelSelection()">Cancel</v-btn>
                                    <v-btn depressed
                                           color="primary"
                                           text
                                           @click="applySelection()">Apply</v-btn>
                                </div>
                            </div>
                        </FunctionalCalendar>
                    </v-card>
                </v-menu>
            </span>
            <v-icon 
                slot="append" 
                v-if="isSingleDatePicker && !disabled" 
                :disabled="cannotMoveForward"
                @click="moveDay(1)">mdi-chevron-right</v-icon>
        </v-text-field>
    </div>
</template>

<script>
/*
 * See: https://github.com/ManukMinasyan/vue-functional-calendar?ref=madewithvuejs.com
 */
    import BaseComponent from '@/Application/components/BaseComponentMixin.jsx';
    import {appSettings} from '@/Shared/appSettings';
    import localeService from '@/Services/locale';
    import dayjs from 'dayjs';
    import { FunctionalCalendar } from 'vue-functional-calendar';
    import _ from 'lodash';
    import Vue from 'vue';

    export default {
        name: 'c-Date-Range-Picker',
        mixins: [BaseComponent],
        components: {
            FunctionalCalendar
        },
        props: {
            default: {
                type: [Object,String, Date]
            },
            type: {
                type: String,
                default: ''
            },
            allowTime: {
                type: Boolean,
                default: false,
            },
            timePickerIncrement: {
                type: Number,
                default: 1,
            },
            isDisabled: {
                type: Boolean,
                default: false,
            },
            placeholder: {
                type: String,
                default: ''
            },
            message: {
                type: String,
                default: ''
            },
            allowFutureDate: {
                type: Boolean,
                default: true
            },
            allowPastDate: {
                type: Boolean,
                default: true
            },
            futureDateLimit: {
                type: Number,
            },
            pastDateLimit: {
                type: Number,
            },
            dense: {
                type: Boolean,
            },
            hideDetails: {
                type: Boolean,
                default: true
            },
            outlined: {
                type: Boolean,
            },
            readonly: {
                type: Boolean,
            },
            singleLine: {
                type: Boolean,
            },
            noinput: {
                type: Boolean
            }
        },
        data: function () {
            return {
                calendarData: {
                    dateRange: {
                        start: dayjs().startOf('day').toISOString(),
                        end: dayjs().endOf('day').toISOString()
                    },
                    selectedDate: dayjs().toISOString()
                },
                // The funcional calendar wants us to pass an empty string when we've selected a start date and not an end date. 
                // We don't want to do that because all we want to track is the set start and end date. We'll set flags here so that
                // when the functional calendar is nulling things out, we can show it what it wants to see instead of nulling out our source of truth
                functionalCalendarDataEmptyData: {
                    start: false,
                    end: false, 
                    selected: false
                },
                lastSavedData: {},
                defaultModel: null,
                datePickerOpen: false,
                actualTimePickerIncrement: 1,
                calendarConfigs: {},
                presets: [
                    { label: 'Today', range: [dayjs().startOf('day'), dayjs().endOf('day')] },
                    { label: 'Yesterday', range: [this.allowPastDate ? dayjs().subtract(1, 'day').startOf('day') : dayjs().startOf('day'), this.allowPastDate ? dayjs().subtract(1, 'day').endOf('day') : dayjs().endOf('day')] },
                    { label: 'Last 7 Days', range: [this.allowPastDate ? dayjs().subtract(6, 'day').startOf('day') : dayjs().startOf('day'), dayjs().endOf('day')] },
                    { label: 'Last 30 Days', range: [this.allowPastDate ? dayjs().subtract(29, 'day').startOf('day') : dayjs().startOf('day'), dayjs().endOf('day')] },
                    { label: 'This Month', range: [this.allowPastDate ? dayjs().startOf('month') : dayjs().startOf('day'), this.allowFutureDate ? dayjs().endOf('month') : dayjs().endOf('day')] },
                    { label: 'Last Month', range: [this.allowPastDate ? dayjs().subtract(1, 'month').startOf('month') : dayjs().startOf('day'), this.allowPastDate ? dayjs().subtract(1, 'month').endOf('month') : dayjs().startOf('day')] },
                    { label: 'Last 3 Months', range: [this.allowPastDate ? dayjs().subtract(3, 'month').startOf('month') : dayjs().startOf('day'), this.allowPastDate ? dayjs().endOf('month') : dayjs().add(1, 'day').startOf('day')] },
                    { label: 'Last 6 Months', range: [this.allowPastDate ? dayjs().subtract(6, 'month').startOf('month') : dayjs().startOf('day'), this.allowPastDate ? dayjs().endOf('month') : dayjs().add(1, 'day').startOf('day')] },

                    //{ label: 'Today', range: [dayjs().startOf('day'), dayjs().add(1, 'day').startOf('day')] },
                    //{ label: 'Yesterday', range: [this.allowPastDate ? dayjs().subtract(1, 'day').startOf('day') : dayjs().startOf('day'), this.allowPastDate ? dayjs().startOf('day') : dayjs().add(1, 'day').startOf('day')] },
                    //{ label: 'Last 7 Days', range: [this.allowPastDate ? dayjs().subtract(6, 'day').startOf('day') : dayjs().startOf('day'), dayjs().add(1, 'day').startOf('day')] },
                    //{ label: 'Last 30 Days', range: [this.allowPastDate ? dayjs().subtract(29, 'day').startOf('day') : dayjs().startOf('day'), dayjs().add(1, 'day').startOf('day')] },
                    //{ label: 'This Month', range: [this.allowPastDate ? dayjs().startOf('month') : dayjs().startOf('day'), this.allowFutureDate ? dayjs().add(1, 'month').startOf('month') : dayjs().add(1, 'day').startOf('day')] },
                    //{ label: 'Last Month', range: [this.allowPastDate ? dayjs().subtract(1, 'month').startOf('month') : dayjs().startOf('day'), this.allowPastDate ? dayjs().startOf('month') : dayjs().add(1, 'day').startOf('day')] },
                    //{ label: 'Last 3 Months', range: [this.allowPastDate ? dayjs().subtract(3, 'month').startOf('month') : dayjs().startOf('day'), this.allowPastDate ? dayjs().add(1, 'month').startOf('month') : dayjs().add(1, 'day').startOf('day')] },
                    //{ label: 'Last 6 Months', range: [this.allowPastDate ? dayjs().subtract(6, 'month').startOf('month') : dayjs().startOf('day'), this.allowPastDate ? dayjs().add(1, 'month').startOf('month') : dayjs().add(1, 'day').startOf('day')] },
                ],
                context: this
            }
        },
        created() {            
            this.actualTimePickerIncrement = this.timePickerIncrement > 0 ? this.timePickerIncrement : 1;

            this.calendarConfigs = {
                sundayStart: dayjs().weekday(0).day() === 0, //will return 0 if locale starts on sunday, else it will return 1,
                dateFormat: 'mm/dd/yyyy',
                placeholder: 'mm/dd/yyyy',
                isDatePicker: this.isSingleDatePicker,
                isDateRange: this.isDateRangePicker,
                isMultiple: this.isDateRangePicker,
                withTimePicker: false, //can't ever use the timepicker that comes with this because it doesn't work for date range and is terrible
                calendarsCount: 2,
                dayNames: this.getWeekdaysNameListWithMondayFirst(),
                monthNames: localeService.getMonthLong(),
                shortMonthNames: localeService.getMonthShort(),
                disabledDates: this.disabledDates,
                limits: this.limitDates
            };

            this.updateDefaultModel(this.default);
            this.initializeData();

            if (!this.disabled)
                this.applySelection();
        },
        watch: {
            default: function(newvalue) {
                this.updateDefaultModel(newvalue);
                this.initializeData();
            }
        },
        computed: {
            disabled: function() {
                return this.readonly || this.isDisabled || this.noinput;
            },
            cannotMoveForward: function() {
                return !this.allowFutureDate && dayjs(this.calendarData.selectedDate).endOf('day') >= dayjs().endOf('day')
            },
            cannotMoveBackward: function() {
                return !this.allowPastDate && dayjs(this.calendarData.selectedDate).startOf('day') <= dayjs().startOf('day')
            },
            disabledDates: function() {
                let disabledDates = [];
                if(!this.allowFutureDate)
                    disabledDates.push('afterToday')
                if(!this.allowPastDate)
                    disabledDates.push('beforeToday')
                
                return disabledDates;
            },
            limitDates: function() {
                let limitDates = {};
                if(this.futureDateLimit)
                    limitDates.max = dayjs().add(this.futureDateLimit, 'day').format('MM/DD/YYYY');
                else
                    limitDates.max = !this.allowFutureDate ? dayjs().format('MM/DD/YYYY') : dayjs().add(1000, 'year').format('MM/DD/YYYY');
                
                if(this.pastDateLimit)
                    limitDates.min = dayjs().add(-(this.pastDateLimit), 'day').format('MM/DD/YYYY');
                else
                    limitDates.min = !this.allowPastDate ? dayjs().format('MM/DD/YYYY') : dayjs(0).format('MM/DD/YYYY');

                return _.isEmpty(limitDates) ? false : limitDates;
            },
            timePickerHours: function() {
                if(this.is24HourLocale)
                    return  [...Array(24).keys()].map(hour => this.formatTime(hour));
                return  [...Array(12).keys()].map(hour => this.formatTime(hour + 1));
            },
            timePickerMin: function() {
                var availableMins = [...Array(60).keys()].filter((_,index) => index % (this.actualTimePickerIncrement) === 0).map(min => this.formatTime(min));
                if(this.timePickerIncrement == 60)
                    availableMins.push("59");
                return availableMins;
            },
            startHour: {
                get() {
                    if(this.is24HourLocale)
                        return this.formatTime(dayjs(this.calendarData.dateRange.start).hour());
                    else 
                        return this.formatTime(dayjs(this.calendarData.dateRange.start).hour() % 12 || 12);
                },
                set(hour) {
                    this.updateStartDate(null, hour);
                }
            },
            startMin: {
                get() {
                    return this.formatTime(dayjs(this.calendarData.dateRange.start).minute());
                },
                set(minute) {
                    this.updateStartDate(null, null, minute);
                }
            },
            startMeridiem: {
                get() {
                    return dayjs(this.calendarData.dateRange.start).format('a').toUpperCase();
                },
                set(meridiem) {
                    this.updateStartDate(null, null, null, meridiem);
                }
            },
            endHour: {
                get() {
                    if(this.is24HourLocale)
                        return this.formatTime(dayjs(this.calendarData.dateRange.end).hour());
                    else 
                        return this.formatTime(dayjs(this.calendarData.dateRange.end).hour() % 12 || 12);
                },
                set(hour) {
                    this.updateEndDate(null, hour);
                }
            },
            endMin: {
                get() {
                    return this.formatTime(dayjs(this.calendarData.dateRange.end).minute());
                },
                set(minute) {
                    this.updateEndDate(null, null, minute);
                }
            },
            endMeridiem: {
                get() {
                    return dayjs(this.calendarData.dateRange.end).format('a').toUpperCase();
                },
                set(meridiem) {
                    this.updateEndDate(null, null, null, meridiem);
                }
            },
            selectedHour: {
                get() {
                    if(this.is24HourLocale)
                        return this.formatTime(dayjs(this.calendarData.selectedDate).hour());
                    else
                        return this.formatTime(dayjs(this.calendarData.selectedDate).hour() % 12 || 12);
                },
                set(hour) {
                    this.updateSelectedDate(null, hour);
                }
            },
            selectedMinute: {
                get() {
                    return this.formatTime(dayjs(this.calendarData.selectedDate).minute());
                },
                set(minute) {
                    this.updateSelectedDate(null, null, minute);
                }
            },
            selectedMeridiem: {
                get() {
                    return dayjs(this.calendarData.selectedDate).format('a').toUpperCase();
                },
                set(meridiem) {
                    this.updateSelectedDate(null, null, null, meridiem);
                }
            },
            displayValue: function () {
                if (this.allowTime) {
                    if (this.isDateRangePicker)
                        return `${dayjs(this.calendarData.dateRange.start).format('l LTS')} - ${dayjs(this.calendarData.dateRange.end).format('l LTS')}`;
                    else if (this.isSingleDatePicker)
                        return `${dayjs(this.calendarData.selectedDate).format('l LTS')}`;
                    else
                        return null;
                }
                else {
                    if (this.isDateRangePicker)
                        return `${dayjs(this.calendarData.dateRange.start).format('l')} - ${dayjs(this.calendarData.dateRange.end).format('l')}`;
                    else if (this.isSingleDatePicker)
                        return `${dayjs(this.calendarData.selectedDate).format('l')}`;
                    else
                        return null;
                }
            },
            functionalCalendarFormattedData() {
                return {
                    dateRange: {
                        start: this.functionalCalendarDataEmptyData.start ? '' : dayjs(this.calendarData.dateRange.start).startOf('day').format('M/D/YYYY'),
                        end: this.functionalCalendarDataEmptyData.end ? '' : dayjs(this.calendarData.dateRange.end).endOf('day').format('M/D/YYYY')
                    },
                    selectedDate: this.functionalCalendarDataEmptyData.selected ? '' : dayjs(this.calendarData.selectedDate).startOf('day').format('M/D/YYYY')
                };
            },
            placeholderText: function() {
                if(this.placeholder)
                    return this.placeholder;
                return `Select Date ${this.isDateRangePicker ? 'Range' : ''}`
            },
            is24HourLocale: function() {
                return !dayjs.Ls[localeService.selectedDateLocale]?.formats?.LT.toLowerCase().includes('a');
            },
            isSingleDatePicker: function() {
                return ['Single Date', 'SingleDatePicker', 'singledatepicker', 'date-time'].includes(this.type);
            },
            isDateRangePicker: function() {
                return ['Date Range', 'DateRangePicker', 'daterangepicker'].includes(this.type);
            },
            
        },
        methods: {
            async preRenderComplete() {
                this.finishRenderHandler(this);
            },
            formatTime(num) {
                return Intl.NumberFormat(appSettings.LocaleKey, { minimumIntegerDigits: 2 }).format(num);
            },
            onCalendarInput(value) {
                if(value.dateRange.start){
                    this.updateStartDate(value.dateRange.start);
                    Vue.set(this.functionalCalendarDataEmptyData, 'start', false);
                }
                else
                    Vue.set(this.functionalCalendarDataEmptyData, 'start', true);
                
                if(value.dateRange.end) {
                    this.updateEndDate(value.dateRange.end);
                    Vue.set(this.functionalCalendarDataEmptyData, 'end', false);
                }
                else 
                    Vue.set(this.functionalCalendarDataEmptyData, 'end', true);
                
                if(value.selectedDate) {
                    this.updateSelectedDate(value.selectedDate);
                    Vue.set(this.functionalCalendarDataEmptyData, 'selected', false);
                }
                else 
                    Vue.set(this.functionalCalendarDataEmptyData, 'selected', true);
            },
            setRange(range) {
                this.setStartDateTime(range[0].toISOString());
                this.setEndDateTime(range[1].toISOString());
            },
            updateStartDate(startDate, startHour, startMinute, startMeridiem) {
                let originalStartDate = dayjs(this.calendarData.dateRange.start);

                // Figuring out the start date. This can either be kept as it is currently is, or passed to this function
                let localStartDate = originalStartDate;
                if(startDate)
                    localStartDate = dayjs(startDate);

                let localStartHour = originalStartDate.hour();
                if(startHour || startMeridiem) {
                    if(!this.is24HourLocale) {
                        // If the user passed a meridiem, we want to take that. If not we want to get a default. 
                        let localStartMeridiem = startMeridiem ? startMeridiem : originalStartDate.format('a');
                        
                        // If the user passed a start hour, we want to use that. If not, we want to get a default.
                        localStartHour = (parseInt(`${startHour ? startHour : localStartHour}`) % 12) + (localStartMeridiem.toLowerCase() == 'pm' ? 12 : 0)
                    }
                    else {
                        localStartHour = parseInt(`${startHour}`);
                    }
                }
                
                let localStartMinute = originalStartDate.minute();
                if(startMinute)
                    localStartMinute = startMinute;

                localStartMinute = Math.ceil(localStartMinute/this.actualTimePickerIncrement)*this.actualTimePickerIncrement

                this.setStartDateTime(dayjs({ year: localStartDate.year(), month: localStartDate.month(), day: localStartDate.date(), hour: localStartHour, minute: localStartMinute }));
            },
            setStartDateTime(startDate) {
                if(startDate != '')
                    startDate = dayjs(startDate).toISOString();

                Vue.set(this.calendarData.dateRange, 'start', startDate);
            },
            updateEndDate(endDate, endHour, endMinute, endMeridiem) {
                let originalEndDate = dayjs(this.calendarData.dateRange.end);

                let localEndDate = originalEndDate;
                if(endDate)
                    localEndDate = dayjs(endDate);

                let localEndHour = originalEndDate.hour();
                if(endHour || endMeridiem) {
                    if(!this.is24HourLocale) {
                        // If the user passed a meridiem, we want to take that. If not we want to get a default. 
                        let localEndMeridiem = endMeridiem ? endMeridiem : originalEndDate.format('a');
                        
                        // If the user passed a start hour, we want to use that. If not, we want to get a default.
                        localEndHour = (parseInt(`${endHour ? endHour : localEndHour}`) % 12) + (localEndMeridiem.toLowerCase() == 'pm' ? 12 : 0)
                    }
                    else {
                        localEndHour = parseInt(`${endHour}`);
                    }
                }
                
                let localEndMinute = originalEndDate.minute();
                if(endMinute)
                    localEndMinute = endMinute;

                localEndMinute = Math.floor(localEndMinute / this.actualTimePickerIncrement) * this.actualTimePickerIncrement;

                //debugger;

                if (this.timePickerIncrement == 60 && endDate != null)
                    this.setEndDateTime(dayjs({ year: localEndDate.year(), month: localEndDate.month(), day: localEndDate.date() }).endOf('day'));
                else if (this.timePickerIncrement == 60)
                    this.setEndDateTime(dayjs({ year: localEndDate.year(), month: localEndDate.month(), day: localEndDate.date(), hour: localEndHour }).endOf('hour'));
                else
                    this.setEndDateTime(dayjs({ year: localEndDate.year(), month: localEndDate.month(), day: localEndDate.date(), hour: localEndHour, minute: localEndMinute /*, second: 59, millisecond: 999*/ }));
            },
            setEndDateTime(endDate) {
                if(endDate != '')
                    endDate = dayjs(endDate).toISOString();

                Vue.set(this.calendarData.dateRange, 'end', endDate);
            },
            updateSelectedDate(selectedDate, selectedHour, selectedMinute, selectedMeridiem) {
                let originalSelectedDate = dayjs(this.calendarData.selectedDate);

                let localSelectedDate = originalSelectedDate;
                if(selectedDate)
                    localSelectedDate = dayjs(selectedDate);

                let localSelectedHour = originalSelectedDate.hour();
                if(selectedHour || selectedMeridiem) {
                    if(!this.is24HourLocale) {
                        // If the user passed a meridiem, we want to take that. If not we want to get a default. 
                        let localSelectedMeridiem = selectedMeridiem ? selectedMeridiem : originalSelectedDate.format('a');
                        
                        // If the user passed a start hour, we want to use that. If not, we want to get a default.
                        localSelectedHour = (parseInt(`${selectedHour ? selectedHour : localSelectedHour}`) % 12) + (localSelectedMeridiem.toLowerCase() == 'pm' ? 12 : 0)
                    }
                    else {
                        localSelectedHour = parseInt(`${selectedHour}`);
                    }
                }
                
                let localSelectedMinute = originalSelectedDate.minute();
                if(selectedMinute)
                    localSelectedMinute = selectedMinute;

                this.setSelectedDateTime(dayjs({ year: localSelectedDate.year(), month: localSelectedDate.month(), day: localSelectedDate.date(), hour: localSelectedHour, minute: localSelectedMinute }));
            },
            setSelectedDateTime(selectedDate) {
                if(selectedDate) {
                    selectedDate = dayjs(selectedDate).toISOString();
                    Vue.set(this.calendarData, 'selectedDate', selectedDate);
                }
            },
            cancelSelection() {
                this.undoChanges();
                
                this.datePickerOpen = false;
                this.$emit('clear-date-range');
            },
            applySelection() {
                this.setLastSavedData()
                this.datePickerOpen = false;

                if(this.isDateRangePicker) {
                    let startDate = this.calendarData.dateRange.start;
                    let endDate = this.calendarData.dateRange.end;
                    this.defaultModel = {startDate,endDate};

                    this.$emit('apply-date-range', {startDate,endDate});
                } else if(this.isSingleDatePicker) {
                    let selectedDate = this.calendarData.selectedDate;
                    this.defaultModel = selectedDate;

                    this.$emit('apply-date-range', selectedDate);
                }
            },
            moveDay(direction) {
                this.updateSelectedDate(dayjs(this.calendarData.selectedDate).add(direction, 'day'));
                this.defaultModel = selectedDate;
                this.setLastSavedData();

                this.$emit('apply-date-range', this.calendarData.selectedDate);
            },
            setLastSavedData() {
                this.lastSavedData = _.cloneDeep(this.calendarData);
            },
            undoChanges() {
                this.calendarData = _.cloneDeep(this.lastSavedData);
            },
            initializeData() {
                this.initializeStartDate();
                this.initializeEndDate();
                this.initializeSelectedDate();
                this.setLastSavedData();
            },
            initializeStartDate() {
                // We are dealing with a date range picker and we have a default model that is a non empty object
                if(this.isDateRangePicker && typeof this.defaultModel === 'object' && !_.isEmpty(this.defaultModel)) {
                    let startDate = this.defaultModel.startDate;
                    if(startDate === 'today')
                        this.setStartDateTime(dayjs().startOf('day'));
                    // The start date must be MM-DD-YYYY, which day.js can parse
                    else 
                        this.setStartDateTime(this.defaultModel.startDate);    
                }
                else {
                    this.setStartDateTime(dayjs().startOf('day'));
                }
            },
            initializeEndDate() {
                // We are dealing with a date range picker and we have a default model that is a non empty object
                if(this.isDateRangePicker && typeof this.defaultModel === 'object' && !_.isEmpty(this.defaultModel)) {
                    let endDate = this.defaultModel.endDate;
                    if(endDate === 'today')
                        this.setEndDateTime(dayjs().endOf('day'));
                    // The start date must be MM-DD-YYYY, which day.js can parse
                    else 
                        this.setEndDateTime(this.defaultModel.endDate);    
                }
                else {
                    this.setEndDateTime(dayjs().endOf('day'));
                }
            },
            initializeSelectedDate() {
                if (this.isSingleDatePicker && typeof this.defaultModel === 'string' && this.defaultModel == 'today')
                    this.setSelectedDateTime(dayjs().startOf('day'));
                else if (this.isSingleDatePicker && (typeof this.defaultModel === 'object' || !this.defaultModel))
                    this.setSelectedDateTime(dayjs().startOf('day'));
                // The date must be MM-DD-YYYY format, which day.js can parse
                else
                    this.setSelectedDateTime(this.defaultModel);
            },
            updateDefaultModel: function(newvalue) {
                if(this.isDateRangePicker) {
                    if(typeof newvalue === 'string' && newvalue.toLowerCase() == 'today')
                        this.defaultModel = newvalue
                    else if (newvalue && !_.isEmpty(newvalue))
                        try {
                            this.defaultModel = JSON.parse(newvalue)
                        } catch {
                            this.defaultModel = newvalue
                        }
                } else {
                    if(newvalue instanceof Date)
                        this.defaultModel = newvalue.toISOString();
                    else
                        this.defaultModel = newvalue;
                }
            },
            /**
             * Benton Smith (3/28/2022): The functional calendar reqires Monday to be the first week day name in the list of week days, but day.js 
             * returns Sunday as the first day of the week. We're simply going to switch that around here to comply with the 
             * functional calendar's requriements. 
             */
            getWeekdaysNameListWithMondayFirst() {
                let days = _.cloneDeep(localeService.getWeekdaysMin());
                days.push(days.shift());
                return days;
            }
        }
    }
</script>

<style scoped>
    
    .drp-footer {
        width: 100%;
    }
    .drp-footer-btns {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        font-family: sans-serif; 
        gap:5px
    }
    
    .drp-time-picker {
        display: flex;
        margin-bottom: 10px;
    }

    .dp-time-picker {
        display: flex;
        margin-bottom: 10px;
        align-items: center;
    }

    .drp-tp-option {
        width:min-content;
        font-family: sans-serif; 
    }

    .drp-left-time-picker {
        width: 50%;
        display: flex;
        justify-content: center;
        padding: 0 28px 0 5px;
        align-items: center;
    }

    .drp-right-time-picker {
        width: 50%;
        display: flex;
        justify-content: center;
        padding: 0 5px 0 25px;
        align-items: center;
    }


</style>