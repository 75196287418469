import Vue from 'vue';
import BaseComponent from './baseFormMixin.jsx';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';
import careHelpfulFunctions from '../../careHelpfulFunctions.jsx';
import { appSettings } from '@/Shared/appSettings.js';
import DropdownMultiSelectBase from '../vuecontrols/DropdownMultiSelectBase';

Vue.component('sform-dropdownmultiselect', {
    mixins: [BaseComponent],
    data: () => ({
        dolookupexpn: null,
        lookupurlexpn: null,
        lookuprawexpn: null,

        displayexpnfunc: null,
        valueexpnfunc: null,

        item_text: null,
        item_value: null,
        lookupitems: [],
    }),
    components: {
        DropdownMultiSelectBase
    },
    props: {
    },
    created() {
        if (this.element.schema && this.element.schema.formatData) {
            const formatData = this.element.schema.formatData;

            if (formatData.LookupExpression)
                this.dolookupexpn = utils.compileExpression(this, formatData.LookupExpression);

            if ((!formatData.LookupType || formatData.LookupType == 'URL') && formatData.LookupURL)
                this.lookupurlexpn = utils.compile(this, formatData.LookupURL);
            else if (formatData.LookupType == 'Interpolated' && formatData.LookupInterpolatedExpression)
                this.lookuprawexpn = utils.compileExpression(this, formatData.LookupInterpolatedExpression);

            if (formatData.DisplayType == 'Expression') {
                const expr = utils.parseExpressionWithFilters(formatData.DisplayExpression);
                this.displayexpnfunc = {
                    code: expr,
                    func: new Function('control', 'util', formatData.LookupModelAs, `with (control) return ${expr};`)
                };
            }
            if (formatData.ValueType == 'Expression') {
                const expr = utils.parseExpressionWithFilters(formatData.ValueExpression);
                this.valueexpnfunc = {
                    code: expr,
                    func: new Function('control', 'util', formatData.LookupModelAs, `with (control) return ${expr};`)
                };
            }

            switch (formatData.DisplayType) {
                case 'Fieldname': this.item_text = formatData.DisplayField; break;
                case 'Expression':
                    this.item_text = (data) => {
                        try {
                            return this.displayexpnfunc.func(this, careHelpfulFunctions, data);
                        }
                        catch (e) {
                            utils.warn(`DropdownMutltiSelect DisplayType Expression failed to evaluate: ${this.displayexpnfunc.code}; original: ${formatData.DisplayExpression}`, e);
                            return '';
                        }
                    };
                    break;
            }
            switch (formatData.ValueType) {
                case 'Fieldname': this.item_value = formatData.ValueField; break;
                case 'Expression':
                    this.item_value = (data) => {
                        try {
                            return this.valueexpnfunc.func(this, careHelpfulFunctions, data);
                        }
                        catch (e) {
                            utils.warn(`DropdownMutltiSelect ValueType Expression failed to evaluate: ${this.valueexpnfunc.code}; original: ${formatData.ValueExpression}`, e);
                            return '';
                        }
                    };
                    break;
            }
        }

        // Using a manual watch to avoid having the watch function called immediately

        if (this.lookupurlexpn)
            this.lookupurl_watch$ = this.$watch(
                function () {
                    return this.lookupurl;
                },
                function (newval, oldval) {
                    this.Refresh();
                }
            );

        if (this.lookuprawexpn)
            this.lookupraw_watch$ = this.$watch(
                function () {
                    return this.lookupraw;
                },
                function (newval, oldval) {
                    this.Refresh();
                }
            );
    },
    destroyed() {
        if (this.lookupurl_watch$)
            this.lookupurl_watch$();

        if (this.lookupraw_watch$)
            this.lookupraw_watch$();
    },
    computed: {
        tolookup: function () {
            if (this.dolookupexpn)
                return utils.evaluate(this.dolookupexpn, this);
            else
                return true;
        },
        lookupurl: function () {
            if (this.lookupurlexpn)
                try {
                    return utils.evaluate(this.lookupurlexpn, this, false, null, true);
                }
                catch (e) {
                    utils.warn(`DropdownMutltiSelect lookupurl could not be evaluated: ${this.lookupurlexpn.code}`, e);
                    return '';
                }
            else
                return '';
        },
        lookupraw: function () {
            if (this.lookuprawexpn)
                try {
                    return utils.evaluate(this.lookuprawexpn, this, false, null, true);
                }
                catch (e) {
                    utils.warn(`DropdownMutltiSelect lookupraw could not be evaluated: ${this.lookuprawexpn.code}`, e);
                    return [];
                }
            else
                return '';
        },
    },
    async mounted() {
        await this.Refresh();
        this.loadComplete();
    },
    methods: {
        async Refresh() {
            if (this.tolookup) {
                if (this.lookupurlexpn) {
                    let url = this.lookupurl;
                    if (url)
                        this.lookupitems = await utils.api.get(url, false, this.element.schema ? this.element.schema.formatData.CacheLookupResult : true);
                }
                else if (this.lookuprawexpn) {
                    this.lookupitems = this.lookupraw;
                }
            }
        },
    },
    render() {
        let slots = [
            <translation-container slot="label" context={this} value={this.labelText}></translation-container>,
        ];

        const items = this.lookupitems || [];

        let input = (
            <DropdownMultiSelectBase
                class="ma-0"
                nameField={this.item_text}
                valueField={this.item_value}
                ccmodel={this.itemvalue}
                on-input={(value) => this.sync(value)}
                on-blur={this.onBlur}
                on-focus={this.onFocus}
                hint={this.hintText}
                persistent-hint={appSettings.DebugTranslationPrefixSetting}
                source={items}
                selectionLimit={this.element.formatData?.SelectionLimit || this.element.schema?.formatData?.SelectionLimit}
                showCheckAll={this.element.formatData?.ShowCheckAll || this.element.schema?.formatData?.ShowCheckAll}
                showUncheckAll={this.element.formatData?.ShowUncheckAll || this.element.schema?.formatData?.ShowUncheckAll}
                dynamicButtonText={this.element.formatData?.DynamicButtonText || this.element.schema?.formatData?.DynamicButtonText}
                dynamicItemCount={this.element.formatData?.DynamicItemCount || this.element.schema?.formatData?.DynamicItemCount}
            >{slots}</DropdownMultiSelectBase>
        );

        if (this.appearance)
            input.componentOptions.propsData = { ...input.componentOptions.propsData, ...this.appearance };

        if (this.directives) {
            if (input.data.directives)
                input.data.directives = [...input.data.directives, ...this.directives];
            else
                input.data.directives = this.directives;
        }

        return input;
    }
});