import Vue from 'vue';
import utils from '../../Shared/utils.jsx';
import BaseComponent from './BaseComponentMixin.jsx';
import careHelpfulFunctions from '../careHelpfulFunctions.jsx';
import EventBus from '../event-bus.js';

Vue.component('dynamic-menu-list', {
    mixins: [BaseComponent],
    data: function () {
        return {
            menutitle: null,
            sourceraw: null,
            sourceurl: null,
            model: null,
            tab_state: {
                index: 0
            },
            selected_item: null,
            selected_data: null,
            selected_values: [],

            cached_content: null,

            contextMenuItem: null,
            showMenu: false,
            x: 0,
            y: 0,
        }
    },
    async created() {
        if (this.controlData.SourceType === 'Raw' && this.controlData.SourceRaw) {
            this.sourceraw = utils.compileObject(this, this.controlData.SourceRaw);
        }
        else if (this.controlData.SourceType === 'Url' && this.controlData.SourceUrl) {
            this.sourceurl = utils.compile(this, this.controlData.SourceUrl);
        }

        EventBus.$on('Action-SelectMenuItem-Menu', this.performSelectMenuItem);
    },
    //Mounted Replaced with preRenderComplete
    destroyed() {
        EventBus.$off('Action-SelectMenuItem-Menu', this.performSelectMenuItem);
    },
    computed: {
        MenuTitle() {
            const title = this.controlData.MenuTitle;
            if (title)
                return (
                    <translation-container context={this} value={title}></translation-container>
                );
            return null;
        },
        SelectedId: function () {
            return this.selected_item;
        },
        Menus: function () {
            return utils.document_cache.menus;
        },
        DocumentCache: function () {
            // This used to be in the SourceRaw:
            // "{# utils.document_cache.getMenuTree(SourceData().MenuItemData.ChildMenuName) #}"

            return utils.document_cache;
        },
        MenuData: function () {
            return this.model;
        },
        MenuContent: function () {
            return this.cached_content;
        },
        AllItems: function () {
            // Returns a flattened array of all menu items
            const flat = [];
            this.getFlatModel(this.model, flat);
            return flat;
        },

        labelForceRefresh: function () {
            return this.Translate('Force Refresh');
        },
        menuTitleBackgroundColor: function () {
            if (!this.titlebgcolorexpn && this.controlData.MenuTitleBackgroundColor)
                this.titlebgcolorexpn = utils.compile(this, this.controlData.MenuTitleBackgroundColor);

            return this.titlebgcolorexpn ? utils.evaluate(this.titlebgcolorexpn, this) : undefined;
        },
        menuItemsFiltered: function () {
            if (this.model) {
                let model = [];
                this.filterChildren(this.model, model);
                return model;
            }
            else
                return [];
        },
    },
    methods: {
        async preRenderComplete() {
            await this.Refresh();
            this.finishRenderHandler(this);
        },
        async performSelectMenuItem(action) {
            // Note: this action is only triggered by the NavigationDrawer after it receives the base SelectMenuItem. This
            // is to allow the menu to materialize before trying to select it.
            utils.log(`DynamicMenuList SelectMenuItem-Menu ${action.ActionData.MenuItemName}`);

            if (action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            try {
                const name = utils.evaluate(action.menuname, action.context);

                utils.executeAndCompileAllActions(this.controlData.SelectMenuActions, { Data: name }, this);

                try {
                    await utils.success(action);
                } catch (e) { }
            }
            catch (e) {
                try {
                    await utils.failure(action, { Data: e });
                } catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
        filterChildren: function (sourcemodel, finalmodel) {
            for (let i = 0; i < sourcemodel.length; i++) {
                const item = sourcemodel[i];
                const dest = { ...item };
                if (dest.item_data && dest.item_data.MenuItemData.ControlData && dest.item_data.MenuItemData.ControlData.DisplayExpression && !dest.$displayexpn)
                    dest.$displayexpn = utils.compileExpression(this, dest.item_data.MenuItemData.ControlData.DisplayExpression);

                if (dest.item_data && dest.item_data.MenuItemData.ControlData && dest.item_data.MenuItemData.ControlData.DisplayExpression && dest.$displayexpn) {
                    if (!utils.evaluate(dest.$displayexpn, this))
                        continue;
                }

                if (item.children) {
                    const children = [];
                    this.filterChildren(item.children, children);
                    dest.children = children;

                    // if there are no children do not add the parent
                    if (children.length > 0)
                        finalmodel.push(dest);
                }
                else 
                    finalmodel.push(dest);
            }
        },
        getFlatModel: function (model, flat) {
            for (let i = 0; i < model.length; i++) {
                const a = model[i];
                flat.push(a);
                if (a.children)
                    this.getFlatModel(a.children, flat);
            }
        },
        getChildren: function (data, parent, outargs) {
            let path = parent.MenuItemData.Title;
            if (parent.MenuPath)
                path = parent.MenuPath + '::' + path;

            const list = data.filter(m => m.MenuPath == path).sort((a, b) => parseInt(a.Index) - parseInt(b.Index));

            const result = [];
            for (let i = 0; i < list.length; i++) {
                const item = list[i];
                const child = {
                    id: `${outargs.index++}`,
                    name: item.MenuItemData.Title,
                    icon: item.MenuItemData.Icon,
                    item_data: item,
                }
                outargs.content.push(child);
                const children = utils.document_cache.getChildren(data, item, outargs);
                if (children && children.length > 0)
                    child.children = children;

                result.push(child);
            }

            return result;
        },
        getMenuTree: function (data) {
            const result = [];
            const content = [];
            const outargs = { index: 0, content: content };

            let root = data.filter(m => !m.MenuPath).sort((a, b) => parseInt(a.Index) - parseInt(b.Index));
            for (let i = 0; i < root.length; i++) {
                const node = root[i];
                const child = {
                    id: `${outargs.index++}`,
                    name: node.MenuItemData.Title,
                    icon: node.MenuItemData.Icon,
                    item_data: node,
                };
                outargs.content.push(child);
                const children = this.getChildren(data, node, outargs);
                if (children && children.length > 0)
                    child.children = children;

                result.push(child);
            }
            this.cached_result = result;
            this.cached_content = content;
            return result;
        },
        async Refresh() {
            let model;
            if (this.sourceraw)
                try {
                    model = utils.evaluateObject(this.sourceraw, this);
                }
                catch (e) {
                    utils.warn('DynamicTab failed to evaluate SourceRaw ' + this.controlData.SourceRaw + ': ' + e);
                }
            else if (this.sourceurl)
                try {
                    model = await utils.api.get(utils.evaluate(this.sourceurl, this));
                }
                catch (e) {
                    utils.warn('DynamicTab failed to evaluate SourceUrl ' + this.controlData.SourceUrl + ': ' + e);
                }

            if (this.controlData.SourceAsMenuItems) {
                if (!model)
                    debugger;

                // Convert the source model into a tree (it is being provided as an array of MenuItems)
                this.model = this.getMenuTree(model);

                const defaultIndex = this.model.findIndex(a => a.item_data.MenuItemData.IsDefault);
                if (defaultIndex >= 0) {
                    this.onInput([this.model[defaultIndex].id]);
                }
            }
            else
                this.model = model;
        },
        findItem(parent, id) {
            if (parent && typeof parent === 'object' && Array.isArray(parent))
                for (let i = 0; i < parent.length; i++) {
                    const child = parent[i];
                    if (child.id == id)
                        return child;

                    if (child.children) {
                        const item = this.findItem(child.children, id);
                        if (item)
                            return item;
                    }
                }
            return null;
        },
        async onInput(selectedvalues) {
            // selectedvalues is an array of Ids that match the id property within the selected menu item
            if (this.selected_data && this.selected_item && this.selected_data.item_data?.MenuItemData?.OnDeactivateActions) { //deactivate the previously selected menu
                await utils.executeAndCompileAllActions(this.selected_data.item_data.MenuItemData.OnDeactivateActions, { Data: this.selected_item, SelectedItem: this.selected_data }, this);
                this.selected_values = [];
                this.selected_item = null;
                this.selected_data = null;
            }

            if (selectedvalues.length > 0) { //activate and set the menu that is now selected
                this.selected_values = selectedvalues;
                this.selected_item = selectedvalues[0];

                this.selected_data = this.findItem(this.model, this.selected_item); //get menu item

                if (this.selected_data?.item_data?.MenuItemData?.OnActivateActions) {
                    await utils.executeAndCompileAllActions(this.selected_data.item_data.MenuItemData.OnActivateActions, { Data: this.selected_item, SelectedItem: this.selected_data }, this);
                }

                await utils.executeAndCompileAllActions(this.controlData.Actions, { Data: this.selected_item, SelectedItem: this.selected_data }, this);
            }
        },
        async lookupBadge(url, tooltip, item) {
            if (url) {
                const promise = utils.api.get(url);
                const tooltipexpr = utils.compile(this, tooltip);
                const res = await promise;
                this.Input = {
                    Count: (typeof res === 'object' && res && 'Result' in res) ? res.Result : res,
                };
                if (this.Input.Count > 0) {
                    const value = utils.evaluate(tooltipexpr, this);
                    Vue.set(item, 'badgecontent', this.Input.Count < 100 ? `${this.Input.Count}` : "99+");
                    Vue.set(item, 'tooltip', value);
                    Vue.set(item, 'showbadge', true);
                }
                else {
                    Vue.set(item, 'showbadge', false);
                }
            }
        },
        findBadges(item) {
            const badgelist = [];

            if (item.item_data.MenuItemData) {
                const badgeinfo = item.item_data.MenuItemData.Badge;
                if (badgeinfo && !item.badgecontent && (badgeinfo.LookupType == 'URL' || (!badgeinfo.LookupType && badgeinfo.CountURL))) {
                    badgelist.push(badgeinfo);
                }
            }
            if (item.children) {
                const ci = item.children.filter(a => a.item_data && a.item_data.MenuItemData && a.item_data.MenuItemData.Badge && (a.item_data.MenuItemData.Badge.LookupType || a.item_data.MenuItemData.Badge.CountURL));
                for (let i = 0; i < ci.length; i++) {
                    const c = ci[i];
                    const b = this.findBadges(c);
                    for (let j = 0; j < b.length; j++)
                        badgelist.push(b[j]);
                }
            }
            return badgelist;
        },
        SelectItem(id) {
            this.selected_values = [id];
        },
        menuItemRefresh(e, item) {
            e.cancelBubble = true;
            e.stopPropagation();

            utils.api.clearCache();

            if (item.MenuItemData && item.MenuItemData.ControlData)
                item.MenuItemData.ControlData.$objectId = utils.generateUUID();
        },
        showContextMenu(e, item) {
            this.contextMenuItem = item;
            e.preventDefault()
            this.showMenu = false
            this.x = e.clientX
            this.y = e.clientY
            this.$nextTick(() => {
                this.showMenu = true
            })
        },
        getMenuNameAndPath(menu) {
            let name = menu.MenuName + ' / ';
            if (menu.MenuPath)
                name += menu.MenuPath + ' / ';

            name += menu.MenuItemData.Title;

            return name;
        },
        openForLocalEdit(e, item) {
            e.preventDefault()
            utils.openForLocalEdit({ Type: 'MenuItem', Id: item._id, Name: this.getMenuNameAndPath(item) });
        },
        openForExternalEdit(e, item, controlOwnerId, shared) {
            e.preventDefault();
            utils.OpenEditorForExternalEdit({ Type: 'MenuItem', Id: item._id, Name: this.getMenuNameAndPath(item),  OwnerId: controlOwnerId }, shared);
        },
    },
    props: {
    },
    render(h) {
        if (!this.todisplay || !this.controlData)
            return null;

        const style = {
            minWidth: "25px",
            ...this.sizeStyle,
            ...utils.resolveStyleHints(this.styleHints, this),
        };

        let title;
        if (this.MenuTitle) // class="indigo"
            title = (
                <v-card-title class="white--text" style={{ backgroundColor: this.menuTitleBackgroundColor }}>
                    {this.MenuTitle}
                </v-card-title>
            );

        const scopedSlots = {
            label: ({ item }) => {
                //if (item.item_data && item.item_data.MenuItemData.ControlData && item.item_data.MenuItemData.ControlData.DisplayExpression && !item.$displayexpn)
                //    item.$displayexpn = utils.compileExpression(this, item.item_data.MenuItemData.ControlData.DisplayExpression);
                
                //if (item.item_data && item.item_data.MenuItemData.ControlData && item.item_data.MenuItemData.ControlData.DisplayExpression && item.$displayexpn)
                //    item.disabled = !utils.evaluate(item.$displayexpn, this);

                let title;
                if (item.icon)
                    title = <div class="d-flex"><v-icon small class="mr-1">{item.icon}</v-icon><translation-container class="text-wrap" context={this} value={item.name}></translation-container></div>;
                else
                    title = <div class="d-flex"><translation-container class="text-wrap" context={this} value={item.name}></translation-container></div>;

                if (item.badgecontent) {
                    title = <v-badge v-tooltip={item.tooltip} inline value={item.showbadge} content={item.badgecontent} color="error">{title}</v-badge>;
                }
                else if (item.item_data && item.item_data.MenuItemData && item.item_data.MenuItemData.badgecontent) {
                    const badgeinfo = item.item_data.MenuItemData.badgecontent;

                    title = <v-badge inline value={badgeinfo.showbadge && badgeinfo.count} content={badgeinfo.count < 100 ? badgeinfo.count : '99+'} color="error">{title}</v-badge>;

                    if (badgeinfo.tooltip && Array.isArray(badgeinfo.tooltip) && badgeinfo.tooltip.length > 0)
                        title = utils.generateTooltip(h, title, badgeinfo.tooltip.map(t => <span>{t}<br /></span>), 'top');
                }
                
                return title;
            },
            append: ({ item }) => {
                let refresh;

                // For the selected menu item, add a refresh button
                if (this && this.selected_values.some(a => a == item.id)) {
                    refresh = <v-btn elevation={0} small icon
                        on-click={(e) => this.menuItemRefresh(e, item.item_data)}
                        on-contextmenu={(e) => this.showContextMenu(e, item.item_data)}>
                        <v-icon small>mdi-refresh</v-icon>
                    </v-btn>;

                    refresh = utils.generateTooltip(h, refresh, this.labelForceRefresh, 'right');
                }

                return refresh;
            },
        };

        // JSX cannot handle an event with a colon in the middle (update:active) so I have to declare this manually:
        const tv = h('v-treeview', {
            props: {
                activatable: true,
                hoverable: true,
                transition: true,
                openOnClick: true,
                dense: true,
                class: 'ma-1',
                items: this.menuItemsFiltered || [],
                active: this.selected_values,
            },
            scopedSlots: scopedSlots,
            on: {
                'update:active': (e) => this.onInput(e),
            }
        });

        let contextMenu;
        if (this.isdebug && this.contextMenuItem)
            contextMenu = (
                <v-menu
                    value={this.showMenu}
                    position-x={this.x}
                    position-y={this.y}
                    absolute
                    offset-y
                    close-on-click
                >
                    <v-list two-line>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>{this.contextMenuItem.$ModuleMetaData.ModuleName}</v-list-item-title>
                                <v-list-item-subtitle>{this.getMenuNameAndPath(this.contextMenuItem)}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                        <v-divider></v-divider>

                        <v-list-item-group color="primary">
                            <v-list-item key={0} on-click={(e) => this.menuItemRefresh(e, this.contextMenuItem)}>
                                <v-list-item-icon>
                                    <v-icon>mdi-refresh</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title>Reload</v-list-item-title>
                                    <v-list-item-subtitle>Flushes cache and reloads</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item key={1} v-show={this.contextMenuItem.CustomerID == this.root.CustomerID} on-click={(e) => this.openForLocalEdit(e, this.contextMenuItem)}>
                                <v-list-item-icon>
                                    <v-icon>mdi-pencil</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title>Edit Menu Item</v-list-item-title>
                                    <v-list-item-subtitle>Local Tab</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item key={2} on-click={(e) => this.openForExternalEdit(e, this.contextMenuItem, this.contextMenuItem.CustomerID, true)}>
                                <v-list-item-icon>
                                    <v-icon>mdi-pencil-box-multiple</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title>Edit Menu Item</v-list-item-title>
                                    <v-list-item-subtitle>New Window</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item key={3} on-click={(e) => this.openForExternalEdit(e, this.contextMenuItem, this.contextMenuItem.CustomerID, false)}>
                                <v-list-item-icon>
                                    <v-icon>mdi-pencil-box-multiple-outline</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title>Edit Menu Item</v-list-item-title>
                                    <v-list-item-subtitle>New Browser Tab</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                        </v-list-item-group>
                    </v-list>
                </v-menu>
            );
        
        return (
            <div
                class={{ 'c-DynamicMenuList': true, [`c-name-${this.name || 'unnamed'}`]: true }}
                style={style}>
                <v-card class="mx-auto" elevation={0} outlined style="border-radius: unset !important;">
                    {title}

                    {tv}
                </v-card>
                {contextMenu}
            </div>
        );
    }
});