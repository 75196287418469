import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import utils from '../../Shared/utils.jsx';

Vue.component('dynamic-tab-content', {
    mixins: [BaseComponent],
    data: function () {
        return {
            sourceraw: null,
            sourceurl: null,
            dynamictabs: null,
            tab_state: {
                index: 0
            },
            selected_tab: -1,
            selected_tab_expr: null,
            model: null,
            tabRenderCount: {},
            renderedTabs: new Set()
        }
    },
    created() {
        if (this.controlData.SourceType === 'Raw' && this.controlData.SourceRaw) {
            this.sourceraw = utils.compileObject(this, this.controlData.SourceRaw);
        }
        else if (this.controlData.SourceType === 'Url' && this.controlData.SourceUrl) {
            this.sourceurl = utils.compile(this, this.controlData.SourceUrl);
        }

        if (this.controlData.ActiveTabIndex)
            this.selected_tab_expr = utils.compileExpression(this, this.controlData.ActiveTabIndex);

        if (this.controlData.ListenForTabEvents) {
            this.$on('Action-Close', this.closeTabAction);
            this.$on('Action-RenameTab', this.performRenameTab);

            // Subscribe by name so Target can be directed to a specific control
            if (this.controlData.Name) {
                this.$on(`Action-Close:${this.controlData.Name}`, this.closeTabAction);
                this.$on(`Action-RenameTab:${this.controlData.Name}`, this.performRenameTab);
            }
        }

        if (this.controlData.DynamicTabsControl) {
            const dynamictabsexpn = utils.compileExpression(this, this.controlData.DynamicTabsControl);
            this.dynamictabs = utils.evaluate(dynamictabsexpn, this);
        }
    },
    destroyed() {
        if (this.controlData.ListenForTabEvents) {
            this.$off('Action-Close', this.closeTabAction);
            this.$off('Action-RenameTab', this.performRenameTab);

            // Subscribe by name so Target can be directed to a specific control
            if (this.controlData.Name) {
                this.$off(`Action-Close:${this.controlData.Name}`, this.closeTabAction);
                this.$off(`Action-RenameTab:${this.controlData.Name}`, this.performRenameTab);
            }
        }
    },
    //Mounted Replaced with preRenderComplete
    computed: {
        activeTabIndex: function () {
            let index;
            if (this.selected_tab_expr)
                index = utils.evaluate(this.selected_tab_expr, this);
            else
                return 0;

            if (typeof index === 'string')
                return parseInt(index);
            else
                return index;
        },
        Menus: function () {
            return utils.document_cache.menus;
        },
        sourcerawvalue: function () {
            return (this.sourceraw) ? utils.evaluateObject(this.sourceraw, this) : null;
        },
        sourceurlvalue: function () {
            return this.sourceurl ? utils.evaluate(this.sourceurl, this) : '';
        },
        // This is the total number of child controls a component has. This should be overridden in child controls that don't 
        // have their children stored on a property called Controls.
        totalItems() {
            let count = 0;

            // These dynamically load, so I can't wait for them at initialization
            //if (this.model && typeof this.model === 'object') {
            //    if (Array.isArray(this.model))
            //        count = this.model.length;
            //    else
            //        count = Object.keys(this.model).length;
            //}

            // Add one to include the component itself.
            if (Array.isArray(this.controlData.Controls))
                count = (this.controlData.Controls.length * count) + 1;

            return count;
        },
        sources() {
            let sources = [];

            if (this.model && typeof this.model === 'object') {
                if (Array.isArray(this.model))
                    for (let i = 0; i < this.model.length; i++) {
                        sources.push( this.model[i]);
                    }
                else
                    Object.keys(this.model).forEach(key => {
                        sources.push(this.model[key]);
                    });
            }

            return sources;
        }
    },
    watch: {
        sourcerawvalue: function () {
            this.Refresh();
        },
        sourceurlvalue: function () {
            this.Refresh();
        },
        activeTabIndex: function (newval, oldval) {
            //utils.debug(`DynamicTabContent ${this.name} activeTabIndex changed from ${oldval} to ${newval}`);

            this.doChangeSelectedTab(newval);
        },
        selected_tab: function (newval, oldval) {
            utils.debug(`DynamicTabContent ${this.name} selected_tab (watch) has changed from ${oldval} to ${newval}`);
        },
    },
    methods: {
        async preRenderComplete() {
            await this.Refresh();
            this.finishRenderHandler(this);
        }, 
        async Refresh() {
            if (this.sourceraw)
                try {
                    this.model = this.sourcerawvalue;
                }
                catch (e) {
                    utils.warn('DynamicTabContent failed to evaluate SourceRaw ' + this.controlData.SourceRaw + ': ' + e);
                }
            else if (this.sourceurl)
                try {
                    this.model = await utils.api.get(this.sourceurlvalue);
                }
                catch (e) {
                    utils.warn('DynamicTabContent failed to evaluate SourceUrl ' + this.controlData.SourceUrl + ': ' + e);
                }

            this.renderedTabs.clear();
            this.tabRenderCount = {};
            Vue.nextTick(() => this.selected_tab = this.activeTabIndex);
        },
        onChangeTagIndex(index) {
            if (!(index === undefined)) {
                this.selected_tab = index;
            }
        },
        async performRenameTab(action) {
            if (this.dynamictabs) {
                this.dynamictabs.performRenameTab(action);
                return;
            }

            utils.log(`DynamicTabContent RenameTab ${action.ActionData.TabTitle}`);

            if (action.ActionData && action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            if (action.tabtitle) {
                const title = utils.evaluate(action.tabtitle, action.context);

                await utils.executeAndCompileAllActions(this.controlData.RenameActions, { TabTitle: title, Index: this.selected_tab }, this);

                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
        async closeTabAction(action) {
            // This handles the Close action which is emitted by UIAction_Close, which is typically broadcast from a child
            if (this.dynamictabs) {
                this.dynamictabs.closeTabAction(action);
                return;
            }

            utils.log(`DynamicTabContent closeTabAction`);

            if (action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            await utils.executeAndCompileAllActions(this.controlData.CloseActions, { Data: this.selected_tab }, this);

            try {
                await utils.complete(action);
            }
            catch (e) { }

            // Complete the promise for the executeAction method
            action.FinishFunc(true)
        },
        getItemByIndex(index) {
            if(index >= 0 && index < this.sources.length)
                return this.sources[index];
            return null;
        },
        updateTabRenderStatus(index) {
            if(!this.renderedTabs.has(index)) {
                if(!this.tabRenderCount[index])
                    this.tabRenderCount[index] = 1;
                else
                    this.tabRenderCount[index] = this.tabRenderCount[index] + 1;
                
                if(this.tabRenderCount[index] === this.controlData.Controls.length) {
                    this.renderedTabs.add(index);
                    this.$nextTick(async () => {
                        await utils.executeAndCompileAllActions(this.controlData.OnActivateActions, { Data: this.getItemByIndex(this.selected_tab) }, this);
                    });
                }
                
            }
        },
        handleFinishedRender(reference, index) {
            this.finishRenderHandler(reference);
            this.updateTabRenderStatus(index);
        },

        async doChangeSelectedTab(newval) {
            if (this.controlData.OnDeActivateActions && this.selected_tab >= 0) {
                const data = this.getItemByIndex(this.selected_tab);
                if (data)
                    utils.executeAndCompileAllActions(this.controlData.OnDeActivateActions, { Data: data }, this);
            }

            await Vue.nextTick();

            //utils.debug(`DynamicTabContent ${this.name} changing selected_tab from ${this.selected_tab} to ${newval}`);
            this.selected_tab = newval;

            if (this.renderedTabs.has(this.selected_tab)) {
                await Vue.nextTick();

                utils.executeAndCompileAllActions(this.controlData.OnActivateActions, { Data: this.getItemByIndex(this.selected_tab) }, this);
            }            
        },
    },
    props: {
    },
    render(h) {
        if (!this.todisplay || !this.controlData) {
            return null;
        }
        //utils.log(`DynamicTabContent ${this.Name} rendering; model:${this.model ? this.model.length : 0}`);

        const style = {
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            //flexGrow: "1",
            ...this.sizeStyle,
            ...utils.resolveStyleHints(this.styleHints, this),
        };

        //utils.log(` * * * DynamicTabContent:${this.name} style display:${style.display},flexDirection:${style.flexDirection},flexGrow:${style.flexGrow},overflow:${style.overflow} * * *`);

        const items = [];

        if (this.sources.length > 0)
            // Repeat all controls for each element in the source
            for (let k = 0; k < this.sources.length; k++) {
                const src = this.sources[k];
                const elements = [];

                if (!src.ID)
                    src.ID = utils.generateUUID();

                for (let i = 0; i < this.controlData.Controls.length; i++) {
                    const c = this.controlData.Controls[i];
                    if (!(typeof c === 'object'))
                        continue;

                    let DynamicControl = utils.getDynamicComponent(h, c);

                    if (!DynamicControl)
                        DynamicControl = 'default-unknown';

                    if (!c.$objectId) c.$objectId = utils.generateUUID();

                    let id = `${c.$objectId}_${src.ID}_${i}`;

                    elements.push(
                        <DynamicControl
                            key={id}
                            type={c.ControlType}
                            name={c.ControlData.Name || `unnamed_source_${k}_${i}`}
                            root={this.root}
                            asbase={true}
                            parentType="VerticalStack"
                            controlData={c.ControlData}
                            controlURL={c.ControlURL}
                            sourceData={src}
                            on={{ 'finished-render': (reference) => this.handleFinishedRender(reference, k) }}
                            controlName={c.Name}
                            scopeitems={this.scopeitems}
                            controlscope={this.controlscope}
                            cacheControl={c.CacheControl}
                            controlEvents={c.Events}
                        >
                        </DynamicControl>
                    );
                }

                items.push(
                    <v-tab-item transition="none" key={src.ID} value={k} style={style}>
                        {elements}
                    </v-tab-item>
                );
            }
        else
            // Emit one control per page
            for (let i = 0; i < this.controlData.Controls.length; i++) {
                const c = this.controlData.Controls[i];
                if (!(typeof c === 'object'))
                    continue;

                let DynamicControl = utils.getDynamicComponent(h, c);

                if (!DynamicControl)
                    DynamicControl = 'default-unknown';

                if (!c.$objectId) c.$objectId = utils.generateUUID();

                items.push(
                    <v-tab-item transition="none" key={i} value={i} style={style}>
                        <DynamicControl
                            type={c.ControlType}
                            name={c.ControlData.Name || `unnamed_literal_${i}`}
                            root={this.root}
                            asbase={true}
                            parentType="VerticalStack"
                            controlData={c.ControlData}
                            controlURL={c.ControlURL}
                            on={{ 'finished-render': (reference) => this.finishRenderHandler(reference) }}
                            controlName={c.Name}
                            scopeitems={this.scopeitems}
                            controlscope={this.controlscope}
                            cacheControl={c.CacheControl}
                            controlEvents={c.Events}
                        >
                        </DynamicControl>
                    </v-tab-item>
                );
            }

        let vertical = false;
        switch (this.controlData.TransitionDirection) {
            case 'Vertical': vertical = true; break;
        }
        let reverse = false;
        if (this.controlData.TransitionReversed)
            reverse = true;

        return (
            <v-tabs-items
                class="ma-1"
                class={{ 'c-DynamicTabContent': true, [`c-name-${this.name || 'unnamed'}`]: true }}
                style={style}
                vertical={vertical}
                reverse={reverse}
                value={this.selected_tab}
                touchless>
                {items}
            </v-tabs-items>
        );
    }
});

//on-change={ (index) => this.onChangeTagIndex(index) }