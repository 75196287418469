import Vue from 'vue';
import utils from '@/Shared/utils.jsx';
import BaseComponent from '../BaseComponentMixin.jsx';

/*
 * This control exists to allow the HSM to have prerender actions and a place to store non-visible controls (timers, etc.)
 * 
 * */
Vue.component('hsm-container', {
    mixins: [BaseComponent],
    data: function () {
        return {
            id: utils.generateUUID(),
        }
    },
    props: {
        parentHSM: null,
    },
    created() {
        //utils.debug(`HSM-container:${this.parentHSM?.name}:${this.name}:${this._uid} create...`);
    },
    methods: {
        preRenderComplete() {
            //utils.debug(`HSM-container:${this.parentHSM?.name}:${this.name}:${this._uid} preRenderComplete`);
            this.finishRenderHandler(this);
        },
        generateItems(h, controlData, items, model, index, parentType) {
            for (let i = 0; i < controlData.Controls.length; i++) {
                const c = controlData.Controls[i];
                if (!(typeof c === 'object'))
                    continue;

                let DynamicControl = utils.getDynamicComponent(h, c);

                if (!DynamicControl)
                    DynamicControl = 'default-unknown';

                if (!c.$objectId) c.$objectId = utils.generateUUID();

                let id = `${this.id}_${c.$objectId}`;
                if (index) id = `${index}_${id}`;

                items.push(
                    <DynamicControl
                        key={id}
                        on={{ 'finished-render': (reference) => this.finishRenderHandler(reference) }}
                        type={c.ControlType}
                        name={c.ControlData ? c.ControlData.Name : ''}
                        root={this.root}
                        parentType={parentType}
                        controlData={c.ControlData}
                        controlURL={c.ControlURL}
                        sourceData={model}
                        sourceIndex={i}
                        controlName={c.Name}
                        scopeitems={this.scopeitems}
                        controlscope={this.controlscope}
                        controlEvents={c.Events}
                    >
                    </DynamicControl>
                );
            }
        },
    },
    computed: {
        totalItems() {
            let total = 0;

            // Each control will have to be rendered
            if (this.controlData.Controls && Array.isArray(this.controlData.Controls))
                total += this.controlData.Controls.length;

            // Add one to include the component itself.
            total++;

            return total;
        },
    },
    render(h) {
        if (this.controlData.Controls?.length > 0) {
            const items = [];
            this.generateItems(h, this.controlData, items, null, null, this.parentType);
            return <div>{items}</div>;
        }
        else
            return null;
    }
});