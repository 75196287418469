import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import utils from '../../../Shared/utils.jsx';

import { Splitpanes, Pane } from 'splitpanes';

Vue.component('split-panes-dsgn', {
    mixins: [BaseComponent],
    components: {
        Splitpanes,
        Pane,
    },
    data: function () {
        return {
            master_key: null,

            pickervisible: false,
            picker: null,
            pickertarget: 'body',
        }
    },
    created() {
        this.master_key = utils.generateUUID();
    },
    //Created Replaced with preRenderComplete
    computed: {
    },
    methods: {
        generateItems(h, controlData, index, parentType) {
            const items = [];
            for (let i = 0; i < controlData.Controls.length; i++) {
                const c = controlData.Controls[i];
                if (!(typeof c === 'object'))
                    continue;

                let DynamicControl = utils.getDynamicComponent(h, c);

                if (!DynamicControl)
                    DynamicControl = 'default-unknown';

                DynamicControl += '-dsgn';

                if (!c.$objectId) c.$objectId = utils.generateUUID();

                let id = `${this.master_key}_${c.$objectId}`;
                if (index) id = `${index}_${id}`;

                items.push(
                    <DynamicControl
                        key={id}
                        type={c.ControlType}
                        name={c.ControlData ? c.ControlData.Name : ''}
                        root={this.root}
                        designmodel={c}
                        parentType={parentType}
                        controlData={c.ControlData}
                        controlURL={c.ControlURL}>
                    </DynamicControl>
                );
            }
            return items;
        },

        addPane(e) {
            e.cancelBubble = true;
            e.stopPropagation();

            if (!this.controlData.Panes)
                Vue.set(this.controlData, 'Panes', []);

            this.controlData.Panes.push({
                DisplayExpression: '',
                Controls: [],
                AllowDoubleClick: true,
            });
        },
        addControl(e, target) {
            this.pickertarget = target;
            this.picker = <control-picker confirm={this.confirmAddControl} cancel={this.cancelAddControl}></control-picker>;
            this.pickervisible = true;
            e.cancelBubble = true;
            e.stopPropagation();
        },
        async confirmAddControl(id, title) {
            this.pickervisible = false;

            try {
                const schema_ = await utils.schema.get(id);
                const schema = utils.schema.resolve_Of(schema_)
                const model = utils.schema.getDefaultModel(schema);

                const pane = this.controlData.Panes[this.pickertarget];

                if (!pane.Controls)
                    Vue.set(pane, 'Controls', []);

                pane.Controls.push(model);
                //this.Root.SelectNode(model);
            }
            catch (e) {
                alert(e);
            }
        },
        cancelAddControl() {
            this.pickervisible = false;
        },
        pickerInput(value) {
            this.pickervisible = value;
        },
        generatePicker(h) {
            return (
                <v-dialog value={this.pickervisible} max-width="860" scrollable on-input={(value) => this.pickerInput(value)}>
                    {this.picker}
                </v-dialog>
            );
        },
        resizeEvent(e) {
            for (let i = 0; i < e.length && i < this.controlData.Panes.length; i++) {
                this.controlData.Panes[i].InitialSize = parseFloat(e[i].size.toFixed(2));
            }
        },
    },
    render(h) {
        try {
            const panes = [];

            if (this.selected$ && (!this.controlData.Panes || !this.controlData.Panes.length)) {
                panes.push(
                    <pane>
                        <v-btn v-tooltip="Add pane" x-small icon style={{ zIndex: 992 }} on-click={(e) => this.addPane(e)}>
                            <v-icon small color="red darken-2">mdi mdi-plus-circle</v-icon>
                        </v-btn>
                    </pane>
                );
            }
            else if (this.controlData.Panes)
                for (let i = 0; i < this.controlData.Panes.length; i++) {
                    const p = this.controlData.Panes[i];

                    let btn;
                    if (this.selected$)
                        btn = (
                            <v-btn v-tooltip="Add control to pane" x-small icon style={{ zIndex: 992 }} on-click={(e) => this.addControl(e, i)}>
                                <v-icon small color="blue darken-2">mdi mdi-plus-circle</v-icon>
                            </v-btn>
                        );

                    let btn2;
                    if (this.selected$ && i === this.controlData.Panes.length - 1)
                        btn2 = (
                            <v-btn v-tooltip="Add pane" x-small icon style={{ zIndex: 992 }} on-click={(e) => this.addPane(e)}>
                                <v-icon small color="red darken-2">mdi mdi-plus-circle</v-icon>
                            </v-btn>
                        );

                    panes.push(
                        <pane
                            style="display: flex; flex-direction: column;"
                            key={i}
                            size={p.InitialSize}
                            min-size={p.MinSize}
                            max-size={p.MaxSize}
                        >
                            {this.generateItems(h, p, i, "VerticalLayout")}
                            {btn}
                            {btn2}
                        </pane>
                    );
                }

            const style = {
                ...utils.getStyleHints(this.controlData.StyleHints),
                ...utils.getSize(this.controlData.SizeOptions, this.parentType, this.$parent),
                minWidth: "15px",
            };

            return (
                <div
                    style={style}
                    class={{ 'c-SplitPanesDsgn': true, [`c-name-${this.name || 'unnamed'}`]: true, 'designer-container': true, selected: this.selected$ }}
                    on-click={(e) => this.handleClick(e)}
                >
                    <div class={{ "designer-container-hovering": true, 'designer-container-selected-clickthrough': this.selected$ }}></div>
                    <splitpanes
                        class={{ 'default-theme': true }}
                        horizontal={this.controlData.Orientation == 'Horizontal'}
                        push-other-panes={this.controlData.EnablePushAdjacent}
                        first-splitter={this.controlData.ShowFirstSplitter}
                        on-resize={(e) => this.resizeEvent(e)}
                    >
                        {panes}
                    </splitpanes>

                    {this.generatePicker(h)}
                </div>
            );
        }
        catch (e) {
            utils.error('SplitPanesDsgn Render failed', e);
            return <div>SplitPanesDsgn Failed to Render {e}</div>;
        }
    }
});