import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import EventBus from '../event-bus.js';

import utils from '../../Shared/utils.jsx';
import methods from '../../Shared/methods';

Vue.component('basic-tree-node', {
    props: {
        sourceData: {
            type: Object,
            default: () => {}
        },
        leaf: {
            type: Boolean,
            default: true
        },
        open: {
            type: Boolean, 
            default: false
        },
        nodeTemplate: {
            type: Array,
            default: () => []
        },
        root: {
            type: Object, 
            default: null
        },
        parent: {
            type: Object,
            default: null
        }
    },

    render(h) {
        //if(!this.leaf)
        //    return (
        //        <span>{this.sourceData.Key}</span>
        //    )
        //else
        {
            let items = utils.generateItemsFromArray(h, this.parent, this.nodeTemplate, null, 'BasicTree');
            return (
                <div class="d-flex flex-column c-BasicTree-node">
                    {items}
                </div>
            )
        }
    }
});

Vue.component('basic-tree', {
    mixins: [BaseComponent],
    //Created Replaced with preRenderComplete
    data() {
        return {
            sourceUrlEval: null,
            data: {},
            sourceRawInterpolatedEval: null,
            expandedNodeExpressionEval: null,
            selectedNodeExpressionEval: null,
            open: [],
            selectedNodeData: [],
            expandedNodeData: [],
            Node: {},
        }
    },
    watch: {
        sourceUrl: async function() {
            this.data = await utils.api.get(utils.evaluate(this.sourceUrlEval, this));
        }
    },
    computed: {
        sourceUrl() {
            return utils.evaluate(this.sourceUrlEval, this);
        },
        sourceRawInterpolated() {
            return utils.evaluateObject(this.sourceRawInterpolatedEval, this);
        },
        sourceData() {
            switch(this.controlData.SourceType) {
                case 'Url':
                case 'Raw': 
                    return this.data;
                case 'RawInterpolated': return this.sourceRawInterpolated;
                default: return [];
            }
        },
        expandedNodeExpression() {
            if(this.expandedNodeExpressionEval === null && this.controlData.ExpandedNodeExpression)
                this.expandedNodeExpressionEval = utils.compileExpression(this, this.controlData.ExpandedNodeExpression);
            if(this.expandedNodeExpressionEval)
                return utils.evaluate(this.expandedNodeExpressionEval, this);
            return '';
        },
        openNodes() {
            return this.findExpandedNodes(this.sourceData);
        },
        selectedNode() {
            if(this.selectedNodeExpressionEval === null && this.controlData.SelectedNodeExpressionEval)
                this.selectedNodeExpressionEval = utils.compileExpression(this, this.controlData.SelectedNodeExpressionEval)
            if(this.selectedNodeExpressionEval)
                return this.findSelectedNode(this.sourceData);
            return [];
        },
        styles() {
            return {
                ...utils.resolveStyleHints(this.styleHints, this),
                ...this.sizeStyle
            }
        }
    },
    methods: {
        async preRenderComplete() {
            switch(this.controlData.SourceType) {
                case 'Url':
                    this.sourceUrlEval = utils.compile(this, this.controlData.SourceUrl);
                    this.data = await utils.api.get(utils.evaluate(this.sourceUrlEval, this));
                    break;
                case 'Raw': 
                    this.data = this.controlData.SourceRaw;
                    break;
                case 'RawInterpolated':
                    this.sourceRawInterpolatedEval = utils.compileObject(this, this.controlData.SourceRaw);
                    break;
            }
            this.finishRenderHandler(this);
        },
        findSelectedNode(nodes) {
            nodes.forEach(node => {
                this.Node = node;
                if(utils.evaluate(this.selectedNodeExpressionEval, this))
                    this.selectedNode = [node.Key];
                if(node[this.controlData.ChildArrayName] && node[this.controlData.ChildArrayName].length > 0)
                    this.findSelectedNode(node[this.controlData.ChildArrayName])
            });
            this.Node = {};
            return;
        },
        findExpandedNodes(nodes) {
            let expandedNodes = new Set();
            this.findExpandedNodesRecursive(nodes, expandedNodes);
            this.Node = {};
            return Array.from(expandedNodes);
        },
        findExpandedNodesRecursive(nodes, expandedNodes) {
            nodes.forEach(node => {
                this.Node = node;
                if(this.expandedNodeExpression){
                    expandedNodes.add(node.Key)
                    this.findAncestorNodes(node, this.sourceData).forEach(item => expandedNodes.add(item));
                }
                // depth first traverse
                if(node[this.controlData.ChildArrayName] && node[this.controlData.ChildArrayName].length > 0)
                    this.findExpandedNodesRecursive(node[this.controlData.ChildArrayName], expandedNodes);
            });
        },
        findAncestorNodes(node, nodes) {
            let ancestorNodes = new Set();
            this.findAncestorNodesRecursive(node, nodes, ancestorNodes);
            return Array.from(ancestorNodes);
        },
        findAncestorNodesRecursive(childNode, nodes, ancestorNodes) {
            nodes.forEach(node => {
                if(node.Key === childNode.Key)
                    return;
                else {
                    if(node[this.controlData.ChildArrayName] && node[this.controlData.ChildArrayName].length > 0) {
                        ancestorNodes.add(node.Key);
                        this.findAncestorNodesRecursive(childNode, node[this.controlData.ChildArrayName], ancestorNodes);
                    }
                    else 
                        return;
                }
            })
        },
    },
    props: {},
    render(h) {
        if(!this.todisplay)
            return null;
        try {
            let treeView = h('v-treeview', {
                props: {
                    activatable: true,
                    hoverable: true,
                    transition: true,
                    openOnClick: true,
                    dense: true,
                    class: 'ma-1',
                    items: this.sourceData || [],
                    open: this.openNodes,
                    itemKey: 'Key',
                    itemText: 'Key',
                    itemChildren: this.controlData.ChildArrayName || 'children'
                },
                scopedSlots: {
                    label: ({ item, leaf, open }) => 
                        <basic-tree-node
                            sourceData={item}
                            leaf={leaf}
                            open={open}
                            nodeTemplate={this.controlData.TreeNodeTemplate}
                            root={this.root}
                            parent={this}
                        ></basic-tree-node>
                },
            });

            // JSX cannot handle an event with a colon in the middle (update:active) so I have to declare this manually:
            return(
                <div
                    class={{ 'c-BasicTree': true, [`c-name-${this.name || 'unnamed'}`]: true }}
                    v-show={this.isvisible} style={this.styles}>
                    {treeView}
                </div>
            ) 
        }
        catch (e) {
            utils.error('BasicTree Render failed', e);
            return <div>BasicTree Failed to Render {e}</div>;
        }
    }
});