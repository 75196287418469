import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

Vue.component('icon-popup-menu-dsgn', {
    data: function () {
        return {
        }
    },
    created() {
    },
    computed: {
        Root: function () {
            return this.root._self;
        },
        selected$: function () {
            return this.Root.SelectedNode && this.Root.SelectedModel == this.designmodel;
        },
        iconClass: function () {
            let icon;
            if (!this.controlData.Icon || this.controlData.Icon.includes('{{') || this.controlData.Icon.includes('{*'))
                icon = 'mdi mdi-help-circle';
            else
                icon = this.controlData.Icon || 'mdi mdi-help-circle';

            let iconClass = icon;
            iconClass += (this.controlData.FlipHorizontally) ? ' mdi mdi-flip-vertical ' : '';
            iconClass += (this.controlData.FlipVertically) ? ' mdi mdi-flip-horizontal ' : '';
            iconClass += (this.controlData.Border) ? ' mdi mdi-border-all-variant ' : '';
            iconClass += (this.controlData.Animation == "Spin") ? ' mdi-animate-spin ' : '';
            iconClass += (this.controlData.Animation == "Pulse") ? ' mdi-animate-pulse ' : '';
            return iconClass;
        },
        iconStyles: function () {
            const styles = {};

            if (this.controlData.FontSize)
                styles['font-size'] = utils.resolveFontSize(this.controlData.FontSize);

            if (this.controlData.Color)
                styles.color = this.controlData.Color;
            else
                styles.color = '#444';

            return styles;
        }
    },
    methods: {
        ...methods,
        handleClick(e) {
            this.Root.SelectNode(this);
            e.cancelBubble = true;
            e.stopPropagation();
        },
    },
    props: {
        name: '',
        root: null,
        designmodel: null,
        parentType: '',
        controlData: {}
    },
    render(h) {
        return (
            <div
                style={{ ...utils.getSize(this.controlData.SizeOptions, this.parentType, this.$parent), ...this.iconStyles }}
                class={{ 'CareIcon': true, 'designer-container': true, selected: this.selected$ }}
                title={!this.controlData.Caption ? this.controlData.Tooltip : ''}
                on-click={(e) => this.handleClick(e)}>
                <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
                <i class={this.iconClass}></i>
                <div class="CareIconCaption">{this.controlData.Caption}</div>
            </div>
        );
    }
});