import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import utils from '../../Shared/utils.jsx';
import careHelpfulFunctions from '../careHelpfulFunctions.jsx';
import EventBus from '../event-bus.js';

Vue.component('navigation-drawer-popupmenuitem', {
    mixins: [BaseComponent],
    data: function () {
        return {
            iconexpn: null,
            titleexpn: null,
        }
    },
    created() {
        this.iconexpn = utils.compile(this, this.controlData.Icon);
        this.titleexpn = utils.compile(this, this.controlData.Title);
    },
    props: {
        myroot: null,
        child: null,
        dense: false,
    },
    computed: {
        icon: function () {
            return utils.evaluate(this.iconexpn, this);
        },
        title: function () {
            return utils.evaluate(this.titleexpn, this);
        },
    },
    methods: {
        selectPopupMenuItem(e) {
            utils.executeAndCompileAllActions(this.controlData.Actions, null, this);
        },
    },
    render(h) {
        if (!this.todisplay)
            return null;
        // v-show={this.todisplay}
        return (
            <v-list-item on-click={(e) => this.selectPopupMenuItem(e)}>
                <v-list-item-icon><v-icon dense={this.dense}>{this.icon}</v-icon></v-list-item-icon>
                <v-list-item-title>{this.title}</v-list-item-title>
            </v-list-item>
        );
    }
});

Vue.component('navigation-drawer-item', {
    mixins: [BaseComponent],
    data: function () {
        return {
            iconexpn: null,
            popupShown: false
        }
    },
    created() {
        this.iconexpn = utils.compile(this, this.data.Icon.Icon);
        this.tooltipexpn = utils.compile(this, this.data.Icon.Tooltip);
    },
    props: {
        tabi: 0,
        data: null,
        dense: false,
    },
    methods: {
        selectPopupMenuItem(e, menu) {
            if (menu.Data && menu.Data.MenuItemData)
                utils.executeAndCompileAllActions(menu.Data && menu.Data.MenuItemData.Actions, null, this);
        },
        preRenderComplete() {
            this.finishRenderHandler(this);
        },
        runMenuActions(e, tab) {
            e.cancelBubble = true;
            e.stopPropagation();

            if (tab.Actions)
                utils.executeAndCompileAllActions(tab.Actions, null, this);
        },
        itemClicked(e) {
            // debugger;
            // e.stopPropagation();
        },
        togglePopupMenu(e) {
            this.popupShown = !this.popupShown;
            e.stopPropagation();
        }
    },
    computed: {
        icon: function () {
            return utils.evaluate(this.iconexpn, this);
        },
        tooltip: function () {
            return utils.evaluate(this.tooltipexpn, this);
        },
    },
    render(h) {
        //if (!this.todisplay)
        //    return null;

        let icon;
        let click = this.itemClicked;
        const tab = this.data;

        if (tab.kind == 'PopupMenu') {
            click = this.togglePopupMenu;
            
            let menuActivator;
            if (this.icon)
                menuActivator = <v-icon dense={this.dense}>{this.icon}</v-icon>;

            if (this.tooltip)
                menuActivator = utils.generateTooltip(h, menuActivator, this.tooltip, 'right');

            let childItems = [];
            if (tab.ChildData)
                for (let k = 0; k < tab.ChildData.length; k++) {
                    const child = tab.ChildData[k];

                    if (!child.$objectid)
                        child.$objectid = utils.generateUUID();

                    if (child.Divider)
                        childItems.push(<v-divider></v-divider>);

                    //utils.debug(`NavigationDrawerItem Icon:${child.Data.MenuItemData.Icon} Title:${child.Data.MenuItemData.Title} DisplayExpression:'${child.Data.MenuItemData.DisplayExpression}'`);
                    childItems.push(
                        <navigation-drawer-popupmenuitem
                            class={{ 'c-NavigationDrawerPopupMenuItem': true, [`c-name-${(this.name + '_' + k) || 'unnamed'}`]: true }}
                            key={child.$objectid}
                            name={this.name + '_' + k}
                            root={this.root}
                            type="NavigationDrawerPopupMenuItem"
                            parentType="NavigationDrawerItem"
                            controlData={{
                                Icon: child.Data.MenuItemData.Icon,
                                ItemClass: child.Data.MenuItemData.ItemClass,
                                Title: child.Data.MenuItemData.Title,
                                DisplayExpression: child.Data.MenuItemData.DisplayExpression,
                                Actions: child.Data.MenuItemData.Actions,
                                PrerenderActions: [], // child.Data.MenuItemData.PrerenderActions,
                            }}
                        >
                        </navigation-drawer-popupmenuitem>
                    );
                }

            icon = (
                <v-menu
                    top
                    offset-x
                    nudge-bottom="12"
                    nudge-right="15"
                    value={this.popupShown}
                    on-input={(value) => this.popupShown = value}
                    scopedSlots={{
                        activator: ({ on }) => {
                            menuActivator.data.on = { ...menuActivator.data.on, ...on };
        
                            return menuActivator;
                        }
                    }}
                >
                    <v-list dense flat={false}>
                        <v-list-item-group
                            value={this.selected_popupitem}
                            on-change={(e) => this.selected_popupitem = []}>

                            {childItems}
                        </v-list-item-group>
                    </v-list>
                </v-menu>
            );
        }
        else if (tab.kind == 'ActionsOnly') {
            if (this.icon) {
                const tabicon = this.icon;
                click = (e) => this.runMenuActions(e, tab);

                if (tabicon)
                    icon = <v-icon dense={this.dense}>{tabicon}</v-icon>;

                icon = <v-btn elevation={0} icon small>{icon}</v-btn>;

                if (this.tooltip)
                    icon = utils.generateTooltip(h, icon, this.tooltip, 'right');
            }
        }
        else if (tab.kind == 'StaticTab') {
            if (this.icon) {
                const tabicon = this.icon;

                if (tabicon)
                    icon = <v-icon dense={this.dense}>{tabicon}</v-icon>;
            }

            if (this.controlData.badgecontent) {
                const badgeinfo = this.controlData.badgecontent;

                if (badgeinfo.showbadge && badgeinfo.count) {
                    let tooltip = careHelpfulFunctions.copy(badgeinfo.tooltip || []);
                    if (!tooltip)
                        tooltip = [];

                    if (Array.isArray(tooltip) && this.tooltip)
                        tooltip.splice(0, 0, this.tooltip);

                    if (tooltip && Array.isArray(tooltip) && tooltip.length > 0) {
                        icon = utils.generateTooltip(h, icon, tooltip.map(t => <span>{t}<br /></span>), 'right');
                    }

                    icon = <v-badge offset-x={badgeinfo.count < 10 ? 6 : badgeinfo.count >= 100 ? 18 : 11} value={badgeinfo.showbadge && badgeinfo.count} content={badgeinfo.count < 100 ? badgeinfo.count : '99+'} color="error">{icon}</v-badge>;
                }
                else if (this.tooltip)
                    icon = utils.generateTooltip(h, icon, this.tooltip, 'right');
            }
            else {
                if (this.tooltip)
                    icon = utils.generateTooltip(h, icon, this.tooltip, 'right');
            }
        }

        return (
            <v-list-item key={this.tabi} link v-show={this.todisplay} on-click={(e) => click(e)}>
                <v-list-item-icon>{icon}</v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{tab.Title || tab.Tooltip}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        );
    }
});

Vue.component('navigation-drawer', {
    mixins: [BaseComponent],
    data: function () {
        return {
            sourceraw: null,
            sourceurl: null,
            model: null,
            tab_state: {
                index: 0
            },
            mini: true,
            bot_start_index: 0,

            selected_tab: -1,
            selected_tab_top: 0,
            selected_tab_bot: -1,
            selected_tab_expr: null,

            selected_popupitem: -1,

            actions: [],
            Javascript: null,

            dense_icons: false,
        }
    },
    async created() {
        if (this.controlData.ActiveTabIndex)
            this.selected_tab_expr = utils.compileExpression(this, this.controlData.ActiveTabIndex);

        if (this.controlData.SourceType === 'Raw' && this.controlData.SourceRaw) {
            this.sourceraw = utils.compileObject(this, this.controlData.SourceRaw);
        }
        else if (this.controlData.SourceType === 'Url' && this.controlData.SourceUrl) {
            this.sourceurl = utils.compile(this, this.controlData.SourceUrl);
        }

        if (this.controlData.IconSize)
            this.dense_icons = this.controlData.IconSize == 'Dense';

        EventBus.$on('Action-SelectMenuItem', this.performSelectMenuItem);

        // Add a manual watch to the results of the selected_tab_expr - if it changes, change the selected tab
        this.watcher$ = this.$watch(
            function () {
                try {
                    return utils.evaluate(this.selected_tab_expr, this);
                }
                catch (e) {
                    return null;
                }
            },
            function (val, oldval) {
                let index;
                if (typeof val === 'string')
                    index = parseInt(val);
                else
                    index = val;

                utils.debug(`NavigationDrawer.$watch(index:${index}) (val:${val},oldva:${oldval})`);

                if (index >= this.bot_start_index)
                    this.changeTabBot(index - this.bot_start_index);
                else
                    this.changeTabTop(index);
            }
        );
    },
    //Mounted Replaced with preRenderComplete
    destroyed() {
        EventBus.$off('Action-SelectMenuItem', this.performSelectMenuItem);
    },
    computed: {
        totalItems() {
            let total = 0;

            // Each of the tabs in the controls array will be rendered at least once.
            if (Array.isArray(this.model)) {
                total += this.model.length;
            }

            // Add one to include the component itself.
            total++;

            return total;
        },
        ActiveTabIndex: function () {
            let index;
            if (this.selected_tab_expr)
                index = utils.evaluate(this.selected_tab_expr, this);
            else
                return 0;

            if (typeof index === 'string')
                return parseInt(index);
            else
                return index;
        },
        Menus: function () {
            return utils.document_cache.menus;
        },

        backgroundColor: function () {
            if (!this.bgcolorexpn && this.controlData.BackgroundColor)
                this.bgcolorexpn = utils.compile(this, this.controlData.BackgroundColor);

            return this.bgcolorexpn ? utils.evaluate(this.bgcolorexpn, this) : undefined;
        },
        backgroundImage: function () {
            if (!this.bgimageexpn && this.controlData.BackgroundImage)
                this.bgimageexpn = utils.compile(this, this.controlData.BackgroundImage);

            return this.bgimageexpn ? utils.evaluate(this.bgimageexpn, this) : undefined;
        },
    },
    methods: {
        async preRenderComplete() {
            await this.Refresh();
            this.finishRenderHandler(this);
        },
        async performSelectMenuItem(action) {
            utils.log(`NavigationDrawer SelectMenuItem ${action.ActionData.MenuItemName}`);

            if (action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            try {
                const name = utils.evaluate(action.menuname, action.context);

                utils.executeAndCompileAllActions(this.controlData.SelectMenuActions, { Data: name }, this);

                // Create a delayed action to signal the menu item since the menu may not be materialized yet. By waiting,
                // we allow the menu to load and register for the SelectMenuItem-Menu event.
                this.System.$timeout(() => { EventBus.$emit('Action-SelectMenuItem-Menu', action); }, 250);

                try {
                    await utils.success(action);
                } catch (e) { }
            }
            catch (e) {
                try {
                    await utils.failure(action, { Data: e });
                } catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },

        async Refresh() {
            if (this.sourceraw)
                try {
                    this.model = utils.evaluateObject(this.sourceraw, this);
                }
                catch (e) {
                    utils.warn('DynamicTab failed to evaluate SourceRaw ' + this.controlData.SourceRaw + ': ' + e);
                }
            else if (this.sourceurl)
                try {
                    this.model = await utils.api.get(utils.evaluate(this.sourceurl, this));
                }
                catch (e) {
                    utils.warn('DynamicTab failed to evaluate SourceUrl ' + this.controlData.SourceUrl + ': ' + e);
                }

        },
        async changeTab(index) {
            utils.debug(`NavigationDrawer.changeTab(index:${index}) - firing Actions`);

            let runActions = true;
            let i = index;

            if (isNaN(i))
                i = this.selected_tab;

            if (i > -1 && this.model && this.model.length > i) {
                var menuItem = this.model[i];

                if (menuItem?.kind && (menuItem.kind == "PopupMenu" || menuItem.kind == "ActionsOnly")) {
                    runActions = false; //do not run actions for popup menus or action only menus
                }
            }
            
            // Perform Actions that will actually change the visible static tab
            if (runActions)
                utils.executeAndCompileAllActions(this.controlData.Actions, { Data: index }, this);

            // index of selected tab
            this.selected_tab = index;
        },
        changeTabTop(index) {
            utils.debug(`NavigationDrawer.changeTabTop(index:${index})`);

            if (!isNaN(index) && index > -1 && this.model && this.model.length > index) {
                const menuItem = this.model[index];
                
                if (menuItem?.kind && (menuItem.kind == "PopupMenu" || menuItem.kind == "ActionsOnly")) {
                    const old_tab = this.selected_tab_top;
                    this.selected_tab_top = index;
                    Vue.nextTick(() => this.selected_tab_top = old_tab);
                    return;
                }
            }

            this.selected_tab_top = index;
            this.selected_tab_bot = -1;

            if (this.controlData.UseRouter) {
                if (this.model[index] && this.model[index].type != 'OpenTabs' && !this.$route.fullPath.endsWith(`#/${this.controlData.RoutingName}/${this.model[index].$objectid}`))
                    this.$router.push({ path: `#/${this.controlData.RoutingName}/${this.model[index].$objectid}` });
                else
                    this.changeTab(index);
            }
            else
                this.changeTab(index);
        },
        changeTabBot(index) {
            utils.debug(`NavigationDrawer.changeTabBot(index:${index}) this.selected_tab_bot:${this.selected_tab_bot}`);

            if (!isNaN(index) && (index + this.bot_start_index) > -1 && this.model && this.model.length > (index + this.bot_start_index)) {
                const menuItem = this.model[index + this.bot_start_index];
                
                if (menuItem?.kind && (menuItem.kind == "PopupMenu" || menuItem.kind == "ActionsOnly")) {
                    const old_tab = this.selected_tab_bot;
                    this.selected_tab_bot = index;
                    Vue.nextTick(() => this.selected_tab_bot = old_tab);
                    return;
                }
            }

            this.selected_tab_top = -1;
            this.selected_tab_bot = index;

            if (this.controlData.UseRouter) {
                if (this.model[index + this.bot_start_index] && this.model[index + this.bot_start_index].type != 'OpenTabs' && !this.$route.fullPath.endsWith(`#/${this.controlData.RoutingName}/${this.model[index + this.bot_start_index].$objectid}`))
                    this.$router.push({ path: `#/${this.controlData.RoutingName}/${this.model[index + this.bot_start_index].$objectid}` });
                else
                    this.changeTab(index + this.bot_start_index);
            }
            else
                this.changeTab(index + this.bot_start_index);
        },
        selectPopupMenuItem(e, menu) {
            //e.cancelBubble = true;
            //e.stopPropagation();
            //alert('Popup Menu ' + menu.Title);
            //this.selected_popupitem = -1;
            //debugger;

            if (menu.Data && menu.Data.MenuItemData)
                utils.executeAndCompileAllActions(menu.Data && menu.Data.MenuItemData.Actions, null, this);
        },
        SetActiveTabIndex(index) {
            utils.debug(`NavigationDrawer.SetActiveTabIndex(index:${index}) note selected_tab:${this.selected_tab}`);

            if (index !== this.selected_tab)
                this.changeTab(index);
        },
    },
    watch: {
        //selected_tab: function (newval, oldval) {
        //    utils.debug(`NavigationDrawer.selected_tab changed from ${oldval} to ${newval}`);
        //},
        //selected_tab_bot: function(newval, oldval) {
        //    utils.debug(`NavigationDrawer.selected_tab_bot changed from ${oldval} to ${newval}`);
        //},
        $route: function (newVal, oldVal) {
            if (this.controlData.UseRouter) {
                //need to check for /Tab/guid and set the active index to open tabs if necessary
                var rgx = new RegExp(`${this.controlData.RoutingName}/([^/]*)`, 'ig');
                var matches = [...newVal.fullPath.matchAll(rgx)];
                
                if (matches[0] && matches[0].length >= 2) {
                    utils.debug(`Navigation newVal: ${newVal.fullPath}; oldVal: ${oldVal.fullPath};`);
                    var index = this.model.findIndex(o => o.$objectid == matches[0][1]);
                    this.changeTab(index);
                } 
            }
        }
    },
    props: {
    },
    render(h) {
        if (!this.todisplay || !this.controlData)
            return null;

        let items_top = [];
        let items_bot = [];

        if (this.model) {
            for (let tabi = 0; tabi < this.model.length; tabi++) {
                const tab = this.model[tabi];
                let items;
                if (tab.location == 'Top')
                    items = items_top;
                else
                    items = items_bot;

                if (!tab.$objectid)
                    tab.$objectid = utils.generateUUID();

                items.push(
                    <navigation-drawer-item
                        on={{ 'finished-render': (reference) => this.finishRenderHandler(reference) }}
                        class={{ 'c-NavigationDrawerItem': true, [`c-name-${(this.name + '_' + tabi) || 'unnamed'}`]: true }}
                        key={tab.$objectid}
                        tabi={tabi}
                        name={this.name + '_' + tabi}
                        root={this.root}
                        type="NavigationDrawerItem"
                        parentType="NavigationDrawer"
                        data={tab}
                        dense={this.dense_icons}
                        controlData={tab.Data.MenuItemData}>
                    </navigation-drawer-item>
                );
            }

            this.bot_start_index = items_top.length;
        }

        const style = {
            ...this.sizeStyle,
            //maxWidth: "86px"
        };

        const useDark = this.controlData.DarkTheme || false;

        const scopedSlots = {
            append: () => (
                <v-list nav dense>
                    <v-list-item-group
                        on-change={(e) => this.changeTabBot(e)}
                        value={this.selected_tab_bot}>

                        {items_bot}
                    </v-list-item-group>
                </v-list>
            )
        };

        return (
            <v-navigation-drawer
                id="vmenuNavAnchor"
                class={{ 'c-NavigationDrawer': true, [`c-name-${this.name || 'unnamed'}`]: true }}
                absolute={false}
                permanent={false}
                expand-on-hover={false}
                mini-variant={this.mini}
                disable-resize-watcher
                permanent
                dark={useDark}
                color={this.backgroundColor}
                src={this.backgroundImage}
                style={style}
                scopedSlots={scopedSlots}
                touchless>

                <v-list nav dense>
                    <v-list-item-group
                        on-change={(e) => this.changeTabTop(e)}
                        value={this.selected_tab_top}>

                        {items_top}
                    </v-list-item-group>
                </v-list>

            </v-navigation-drawer>
        );
    }
});