<template>
    <v-hover v-slot="{ hover }" :key="item.ColId" open-delay="50" close-delay="50">
        <v-list-item class="ma-0 pa-0 mediumdense mr-4" active-class="deep-purple--text text--accent-4"
                     :key="item.ColId"
                     :value="item.ColId" style="max-width: 260px;">

            <template v-slot:default="{ active }">
                <v-icon dense class="mr-2" v-text="getIcon(item.SourceType, active)"></v-icon>

                <v-list-item-content dense>
                    <v-list-item-title dense :class="getMetricItemClass(item)">{{ item.Title }}</v-list-item-title>
                </v-list-item-content>

                <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small v-bind="attrs" v-on="on" :class="{ 'hidden-element': !hover, 'ma-0': true }" @click="metricMenu = []">
                            <v-icon small>mdi-plus-circle-outline</v-icon>
                        </v-btn>
                    </template>

                    <v-list dense v-if="item.MetricAggs.Type == 'function' || item.MetricAggs.Type == 'filter' || item.MetricAggs.Type == 'script' || item.MetricAggs.Type == 'filter_script'">
                        <v-list-item-group v-model="metricMenu" color="primary">
                            <template v-for="func in item.MetricAggs.FunctionList">
                                <v-list-item :key="func" @click="addMetric(item, func)" :disabled="isDisabledMetric(item, func)">
                                    <v-list-item-icon><v-icon>{{ metric_types[func].icon }}</v-icon></v-list-item-icon>
                                    <v-list-item-title>{{ metric_types[func].text }}</v-list-item-title>
                                </v-list-item>
                            </template>
                        </v-list-item-group>
                    </v-list>

                    <v-list dense v-if="item.MetricAggs.Type == 'bucket_script'">
                        <v-list-item-group v-model="metricMenu" color="primary">
                            <v-list-item @click="addMetric(item, 'bucket')" :disabled="isDisabledMetric(item, 'bucket')">
                                <v-list-item-icon><v-icon>{{ item.MetricAggs.BucketScript.Icon }}</v-icon></v-list-item-icon>
                                <v-list-item-title>{{ item.MetricAggs.BucketScript.Label }}</v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-menu>

                <v-tooltip bottom open-delay="350">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small v-bind="attrs" v-on="on" :class="{ 'hidden-element': !hover, 'ma-0': true }" @click="showHelp(item)">
                            <v-icon small>mdi-help-circle-outline</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ getMetricHelp(item) }}</span>
                </v-tooltip>

            </template>

        </v-list-item>
    </v-hover>
</template>

<script>
    module.exports = {
        data: function () {
            return {
                metricMenu: [],
            }
        },
        props: {
            item: Object,
            showHelp: null,
            isDisabledMetric: null,
            addMetric: null,
            getMetricHelp: null,
            getMetricItemClass: null,
            getIcon: null,
            metric_types: null,
        },
        created() {
        },
        methods: {
        }
    }
</script>

<style scoped>
    .hidden-element {
        visibility: hidden;
    }

    .columns-two {
        column-count: 2;
    }

    .columns-three {
        column-count: 3;
    }

    .verydense {
        max-height: 24px;
        min-height: 24px;
    }

    .mediumdense {
        max-height: 28px;
        min-height: 28px;
    }

    p {
        font-size: 2em;
        text-align: center;
    }

    .metric_normal {
    }

    .metric_inuse {
        color: cornflowerblue;
    }

    .metric_usedup {
        color: silver;
    }
</style>