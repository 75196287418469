import Vue from 'vue';

Vue.directive('on-enter-default', {
    bind(el) {
        el.onkeydown = (event) => {
            // Enter
            if (event.keyCode == 13){

                // Textareas report when 'enter' is pressed, but we want to ignore textareas since they don't submit forms.
                if (event.target.nodeName == 'BUTTON' ||
                    (event.target.nodeName == 'TEXTAREA' && !event.target.attributes["ignore-enter"])){
                    return;
                }

                //twitter emoji text area is a div, but has a class of twemoji-textarea
                if (event.target.classList.toString().indexOf("textarea") > -1){
                    return;
                }

                var defaultButtons = document.querySelectorAll("button[is-default='true']:not([disabled]):not([style='display:none']):not([style='visibility:hidden'])");

                for (var button of defaultButtons){
                    button.click();
                    event.preventDefault();
                    return;
                }

                // no default button was found, but if ignore-enter = true we still ignore the enter
                if (event.target.nodeName == 'TEXTAREA' && event.target.attributes["ignore-enter"]){
                    event.preventDefault();
                    return;
                }
            }
        };
    }
});