import Vue from 'vue';
import utils from '../../Shared/utils.jsx';
import BaseComponent from './BaseComponentMixin.jsx';
import EventBus from '@/Application/event-bus';

Vue.component('basic-text', {
    mixins: [BaseComponent],
    data: function () {
        return {
            textrenderer: null,
            tooltiprenderer: null,
            colorEval: null,
            fontSizeEval: null,
            wrapTextEval: null,
            cssClassEval: null,
            async_url: null,
            async_text: null,
            async_tooltip: null,
            Input: {},

            textvalue: null,
        }
    },
    created() {
        this.textvalue = this.controlData.Text || '';
    },
    computed: {
        //text() {
        //    if (this.controlData.Text)
        //        return this.controlData.Text;
        //    return '';
        //},
        tooltip() {
            try {
                if(this.tooltiprenderer === null && this.controlData.Tooltip)
                    this.tooltiprenderer = utils.compile(this, this.Translate(this.controlData.Tooltip));
                if(this.tooltiprenderer)
                    return utils.evaluate(this.tooltiprenderer, this);
                return '';
            }
            catch (e) {
                utils.warn('Tooltip could not evaluate expression: ' + this.controlData.Tooltip + '; ' + e);
                return '';
            }
        },
        wrapText() {
            try {
                if(this.wrapTextEval === null && this.controlData.WrapText)
                    this.wrapTextEval = utils.compile(this, this.controlData.WrapText);
                if(this.wrapTextEval)
                    return utils.evaluate(this.wrapTextEval, this) == 'true' ? 'normal' : 'nowrap';
                return 'nowrap';
            }
            catch (e) {
                utils.warn('Wrap text could not evaluate expression: ' + this.controlData.WrapText + '; ' + e);
                return 'nowrap';
            }
        },
        fontSize() {
            try {
                if(this.fontSizeEval === null && this.controlData.FontSize)
                this.fontSizeEval = utils.compile(this, this.controlData.FontSize);
                if(this.fontSizeEval)
                return utils.resolveFontSize(utils.evaluate(this.fontSizeEval, this));
                return '';
            }
            catch (e) {
                utils.warn('Font size could not evaluate expression: ' + this.controlData.FontSize + '; ' + e);
                return '14px';
            }
        },
        color() {
            try {
                if(this.colorEval === null && this.controlData.Color)
                    this.colorEval = utils.compile(this, this.controlData.Color);
                if(this.colorEval)
                    return utils.evaluate(this.colorEval, this);
                return '';
            }
            catch (e) {
                utils.warn('Color could not evaluate expression: ' + this.controlData.Color + '; ' + e);
                return '#444';
            }
        },
        cssClasses() {
            try {
                let classes = {};
                if(this.cssClassEval === null && this.controlData.CssClass)
                    this.cssClassEval = utils.compile(this, this.controlData.CssClass);
                if(this.cssClassEval)
                    return utils.evaluate(this.cssClassEval, this)?.split(' ')?.forEach(item => classes[item] = true);

                

                return classes;
            }
            catch (e) {
                utils.warn('CssClasses could not evaluate expression: ' + this.controlData.CssClass + '; ' + e);
                return {};
            }
        },
        customClasses() {
            let classes = { 'c-Text': true, [`c-name-${this.name || 'unnamed'}`]: true };

            let customClasses = "";
            if (this.styleHints && this.styleHints.CustomClasses) {
                customClasses = utils.resolveCustomClasses(this.styleHints, this);
                if (customClasses) {
                    customClasses = customClasses.split(' ');
                    for (let i = 0; i < customClasses.length; i++) {
                        classes[customClasses[i]] = true;
                    }
                }
            }

            return classes;
        },
        textStyles() {
            let styles = {};

            if (this.fontSize)
                styles['font-size'] = this.fontSize;

            if (this.color)
                styles.color = this.color;
            //else
            //    styles.color = '#444';

            return {
                ...styles,
                ...utils.resolveStyleHints(this.styleHints, this),
                overflow: "visible", // "hidden",
                whiteSpace: this.wrapText,
                //lineHeight: this.wrapText == "normal" ? "normal" : "inherit", // Messes up all grid rows, reduces height, looks bad
                display: "inline-block"
            };
        },
        size() {
            return { ...this.sizeStyle, display: 'flex', flexDirection: 'column' };
        },
    },
    //Created Replaced with preRenderComplete
    mounted() {
        //if (this.controlData.AsyncLookup === true) {
        //    this.LookupText();
        //}
    },
    methods: {
        preRenderComplete() {
            if (this.controlData.AsyncLookup === true) {
                this.async_url = utils.compile(this, this.controlData.AsyncOptions.URL);
                this.async_text = utils.compile(this, this.controlData.AsyncOptions.Text);
                this.async_tooltip = utils.compile(this, this.controlData.AsyncOptions.Tooltip);
            }
            this.finishRenderHandler(this);

            if (this.controlData.AsyncLookup === true) {
                this.LookupText();
            }
        },
        async LookupText() {
            try {
                const url = utils.evaluate(this.async_url, this);
                if (url) {
                    const res = await utils.api.get(url);
                    if (res)
                        Vue.set(this.Input, 'Data', res);
                    else
                        Vue.set(this.Input, 'Data', '');

                    // Update text with the expression from the async text property - should cause translation component to re-evaluate
                    this.textvalue = this.controlData.AsyncOptions.Text;
                    //this.textrenderer = this.async_text;
                    this.tooltiprenderer = this.async_tooltip;
                }
            }
            catch (e)
            {
                utils.warn('Async lookup failed to evaluate url: ' + this.controlData.AsyncOptions.URL + '; ' + e, e);
            }
        }
    },
    props: {
    },
    render(h) {
        try {
            if (!this.todisplay)
                return null;
    
            return (
                <div
                    class={this.customClasses}
                    style={this.size}
                    v-show={this.isvisible}>
                    <translation-container
                        context={this}
                        value={this.textvalue}
                        class={this.cssClasses}
                        style={this.textStyles}
                        title={this.tooltip}
                        on-grid-exported-text={(val) => this.$emit("grid-exported-text", val)}
                    ></translation-container>
                </div>
            );
        }
        catch(e) {
            utils.error('Text Render failed', e);
            return <div>Text Failed to Render {e}</div>;
        }
    }
});