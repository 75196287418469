import Vue from 'vue';
import formInput from './input.jsx';
import formButton from './button.jsx';
import formInputMulti from './inputmultiline.jsx';
import formNumber from './number.jsx';
import formSection from './section.jsx';
import formCheckbox from './checkbox.jsx';
import formSelect from './select.jsx';
import formAnyOf from './anyof.jsx';
import formArray from './array.jsx';
import formTabarray from './tabarray.jsx';
import formHelp from './help.jsx';
import formParagraph from './paragraph.jsx';
import formTypeschema from './typeschema.jsx';
import LookupList from './lookupList.jsx';
import utils from '../../../../Shared/utils.jsx';
import methods from '../../../../Shared/methods';
import fieldPicker from './../fieldPicker.jsx';

Vue.component('schema-form-dsgn', {
    data: () => ({
        tab_state: {},
        properties: [],
        pickervisible: false,
        picker: null,
        pickertarget: 'body',
    }),
    props: {
        name: '',
        schemakey: null,
        root: null,
        schema: null,
        form: null,
        cmodel: null,
        designmodel: null,
        child: 0,
        readonly: false,
        extra: null,
        selected: null,
        selectFormElement: null,
    },
    methods: {
        addControl(e, target) {
            this.pickertarget = target;
            this.picker = <field-picker schema={this.schema} confirm={this.confirmAddControl} cancel={this.cancelAddControl}></field-picker>;
            this.pickervisible = true;
            e.cancelBubble = true;
            e.stopPropagation();
        },
        async confirmAddControl(fields) {
            this.pickervisible = false;

            //const autofield = await utils.schema.get('/schema/public/Platform.Schema.FormControls.v1/FormControl_AutoField');
            //const schema = utils.schema.resolve_Of(autofield);

            if (this.form && this.form.length === 1 && (typeof this.form[0] === 'string') && this.form[0] == '*')
                this.form.splice(0, 1);

            for (let key in fields) {
                const v = fields[key];
                if (!v) continue;

                const fieldSchema = utils.schema.resolve_Of(v.type);

                const m = utils.schema.getDefaultModel(fieldSchema); // schema);
                m.key = key;
                m.$objectid = utils.generateUUID();
                this.form.push(m);
            }
        },
        cancelAddControl() {
            this.pickervisible = false;
        },
        pickerInput(value) {
            this.pickervisible = value;
        },
        addTab(e, tabs, state) {
            const tabItem = {
                $typeSchema: '/schema/public/Platform.Schema.FormControls.v1/FormControl_Tabs_TabItem',
                title: 'Untitled',
                name: '',
                items: []
            };
            tabs.push(tabItem);
            state.index = tabs.length - 1;

            e.cancelBubble = true;
            e.stopPropagation();

            this.selectFormElement(tabItem);

            this.$forceUpdate();
        },
        selectTab(e, state, tabi, tabItem) {
            state.index = tabi;
            if ('$typeSchema' in tabItem)
                this.selectFormElement(tabItem);
            
            e.cancelBubble = true;
            e.stopPropagation();
        },
        deleteTab(e, state, element, tabi) {
            element.tabs.splice(tabi, 1);
            if (state.index >= element.tabs.length)
                state.index = element.tabs.length - 1;

            const tabItem = element.tabs.length > 0 ? element.tabs[element.tabs.length - 1] : null;
            if ('$typeSchema' in tabItem)
                this.selectFormElement(tabItem);
            else
                this.selectFormElement(null);

            e.cancelBubble = true;
            e.stopPropagation();

            this.$forceUpdate();
        }
    },
    render() {
        let schema = this.schema;
        if (!schema)
            schema = { type: 'object' };

        let elements = [];
        let child = this.child;

        schema = utils.schema.resolve_Of(schema);

        if (schema.type !== 'object')
            return <div>Schema must be an object</div>;

        const form = {};
        let properties = []; //this.properties;

        if (this.form)
            for (let i = 0; i < this.form.length; i++) {
                const form_item = this.form[i];
                if (typeof form_item === 'string') {
                    if (form_item === '*') {
                        for (let key in schema.properties)
                            if (key.substr(0, 1) !== '$' && !properties.includes(key))
                                properties.push(key);
                    }
                    else
                        properties.push(form_item);
                }
                else if (typeof form_item === 'object' && form_item.AllSchemaElements) {
                    for (let key in schema.properties)
                        if (key.substr(0, 1) !== '$' && !properties.includes(key))
                            properties.push(key);
                }
                else if (typeof form_item === 'object' && 'key' in form_item) {
                    properties.push(form_item.key);
                    form[form_item.key] = form_item;
                }
                else if (typeof form_item === 'object' && !('key' in form_item)) {
                    properties.push(form_item);
                }
            }

        for (let i = 0; i < properties.length; i++)
        {
            let childform = null;
            const key = properties[i];
            //if (key.substr(0, 1) == '$') continue;

            let element;

            if (typeof key === 'object') {
                element = key;
            }
            else {
                const value = this.cmodel[key];
                if ('properties' in schema) {
                    if (key in schema.properties)
                        element = utils.schema.resolve_Of(schema.properties[key]);
                    else if (key.includes('.')) {
                        // Dot notation for the key: field.subfield.etc
                        const parts = key.split('.');
                        let s = schema;
                        for (let x = 0; s && x < parts.length; x++) {
                            const subkey = parts[x];
                            if ('properties' in s && subkey in s.properties)
                                s = s.properties[subkey];
                        }
                        if (s)
                            element = utils.schema.resolve_Of(s);
                    }
                }
                else
                    element = form[key];

                if (!element)
                    continue;

                element = {
                    ...element,
                    ...form[key],
                };
            }

            let cmodel = this.cmodel;
            let Tag;
            child = this.child;

            if (element)
                if ('format' in element)
                    switch (element.format) {
                        case 'TextEditor':
                            Tag = 'form-input-multiline';
                            break;

                        case 'ReferencedDocumentSchema':
                            Tag = 'referenced-document-schema';
                            break;

                        case 'LookupList':
                            Tag = 'string-lookup-list-dsgn';
                            break;
                    }

            if (element && !Tag)
                switch (element.type) {
                    case 'layoutWrap':
                    case 'actions':
                        elements.push(
                            <schema-form-dsgn name={this.name} designmodel={this.designmodel} root={this.root} schema={this.schema} form={element.items} cmodel={this.cmodel} child={child} selected={this.selected}>
                            </schema-form-dsgn>
                        );
                        continue;

                    case 'tabs':
                        let state;
                        if (!(element.$objectid in this.tab_state))
                            Vue.set(this.tab_state, element.$objectid, { index: 0 });

                        state = this.tab_state[element.$objectid];

                        const tabs = [];
                        for (let tabi = 0; tabi < element.tabs.length; tabi++) {
                            if (!('$typeSchema' in element.tabs[tabi]))
                                element.tabs[tabi]['$typeSchema'] = '/schema/public/Platform.Schema.FormControls.v1/FormControl_Tabs_TabItem';

                            const tab_style = {
                                float: "left",
                                borderTop: "1px solid black",
                                borderLeft: "1px solid black",
                                borderRight: "1px solid black",
                                padding: "2px 5px 1px"
                            };
                            if (tabi === state.index) {
                                tab_style.fontWeight = "bold";
                            }
                            else {
                                tab_style.backgroundColor = "gray";
                                tab_style.color = "silver";
                            }

                            let icon;
                            if (this.selected && state.index === tabi)
                                icon = (
                                    <span class="designer-form" style={{ zIndex: 32, color: "maroon", marginLeft: "3px", cursor: "pointer" }}
                                        on-click={(e) => this.deleteTab(e, state, element, tabi)}>
                                        <i class="mdi mdi-close-circle" title="Delete Tab" />
                                    </span>
                                );

                            tabs.push(
                                <div  style={tab_style} on-click={(e) => this.selectTab(e, state, tabi, element.tabs[tabi])}>
                                    <span class={{ 'designer-form': this.selected }} style={{ zIndex: 32, cursor: "pointer" }}>{element.tabs[tabi].title}</span>{icon}</div>);
                        }

                        if (this.selected) {
                            // Include an Add button to add a new tab
                            const addtab = (
                                <span class="designer-form" style={{ zIndex: 32, marginLeft: "5px", cursor: "pointer", color: "blue" }}>
                                    <i class="mdi mdi-plus-circle" title="Add Tab" on-click={(e) => this.addTab(e, element.tabs, state)} />
                                </span>
                            );
                            tabs.push(<div style={{ float: "left" }}> {addtab}</div>);
                        }

                        // Now push the contents of just the active tab
                        if (state.index >= 0 && state.index < element.tabs.length)
                            tabs.push(
                                <schema-form-dsgn name={this.name} designmodel={this.designmodel} root={this.root} schema={this.schema} form={element.tabs[state.index].items} cmodel={this.cmodel} child={child} selected={this.selected}>
                                </schema-form-dsgn>
                            );

                        elements.push(
                            <div>
                                {tabs}
                            </div>
                        );
                        continue;

                    case 'button':
                        Tag = 'form-button-dsgn'; break;
                    case 'help':
                        Tag = 'form-help-dsgn'; break;
                    case 'paragraph':
                        Tag = 'form-paragraph-dsgn'; break;
                    case 'textarea':
                    case 'textareaWithHeaderBar': Tag = 'form-input-multiline-dsgn'; break;

                    case 'string':
                    case 'text':
                    case 'phonenumber':
                        if (('anyOf' in element))
                            Tag = 'form-anyof-dsgn';
                        else if (('enum' in element) || ('titleMap' in element))
                            Tag = 'form-select-dsgn';
                        else
                            Tag = 'form-input-dsgn';
                        break;
                    case 'integer':
                    case 'number': Tag = 'form-number-dsgn'; break;
                    case 'boolean': Tag = 'form-checkbox-dsgn'; break;
                    case 'array': Tag = 'form-array-dsgn'; break;
                    case 'section': Tag = 'form-section-dsgn'; break;
                    case 'tabarray':
                        Tag = 'form-tabarray-dsgn';
                        break;
                    case 'fieldset':
                        elements.push(
                            <schema-form-dsgn name={this.name} designmodel={this.designmodel} root={this.root} schema={this.schema} form={element.items} cmodel={this.cmodel} child={child} selected={this.selected}>
                            </schema-form-dsgn>
                        );
                        continue;
                        //Tag = 'form-fieldset-dsgn'; break;
                    case 'object':
                        Tag = 'schema-form-dsgn';
                        if (!cmodel[key])
                            Vue.set(cmodel, key, {});
                        cmodel = cmodel[key];
                        childform = ['*'];
                        child = 1;
                        break;
                    default:
                        if ('$typeSchema' in element) {
                            const typeSchema = element.$typeSchema;
                            const parts = typeSchema.split('/');
                            elements.push(<form-typeschema-dsgn name={element.title || key} value={parts[parts.length - 1]}></form-typeschema-dsgn>);
                            continue;
                        }

                        let type = ('anyOf' in element) ? 'anyOf' :
                            ('allOf' in element) ? 'allOf' :
                                ('oneOf' in element) ? 'oneOf' :
                                    ('unknown:' + element.type);

                        utils.log('skipping type: ' + type);
                        Tag = 'div';
                        break;
                }

            if (Tag)
                elements.push(
                    <Tag
                        name={element.title || key}
                        schemakey={key}
                        schema={element}
                        root={this.root}
                        cmodel={cmodel}
                        form={childform}
                        designmodel={(typeof key === 'object' ? key : form[key])}
                        child={child}
                        readonly={element.readonly || false}
                        selected={this.selected}>
                    </Tag>
                );
        }

        let addbtn;
        if (this.selected)
            addbtn = (
                <div class="property-grid designer-form">
                    <v-btn v-tooltip="Add field to form" x-small text icon style={{ zIndex: 32 }} on-click={(e) => this.addControl(e, 'body')}>
                        <v-icon small color="blue darken-2">mdi mdi-plus-circle</v-icon>
                    </v-btn>
                </div>
            );

        if (this.child > 0)
            return (
                <tr>
                    <td colspan="2">
                        <table>
                            <tr>
                                <td colspan="2">
                                    <h3>{this.name}</h3>
                                </td>
                            </tr>
                            {elements}
                            <tr>
                                {addbtn}

                                <v-dialog value={this.pickervisible} max-width="860" scrollable on-input={(value) => this.pickerInput(value)}>
                                    {this.picker}
                                </v-dialog>
                            </tr>
                        </table>
                    </td>
                </tr>
            );
        else
            return (
                <table style={{ width: "100%" }}>
                    {elements}
                    <tr>
                        {addbtn}

                        <v-dialog value={this.pickervisible} max-width="860" scrollable on-input={(value) => this.pickerInput(value)}>
                            {this.picker}
                        </v-dialog>
                    </tr>
                </table>
            );
     
    }
});