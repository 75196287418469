import Vue from "vue";
import utils from "../../Shared/utils.jsx";
import BaseComponent from "./BaseComponentMixin.jsx";
import { appSettings } from "../../Shared/appSettings.js";

//import GoogleLogin from 'vue-google-login';
//import { OAuth2Client } from "@byteowls/capacitor-oauth2";

//import GSignInButton from 'vue-google-signin-button'
//Vue.use(GSignInButton)

Vue.component('g-signin-button', {
    name: 'g-signin-button',
    data: function () {
        return {
            notready: true,
        };
    },
    render: function render(h) {
        //return d('button', { attrs: { class: 'g-signin-button' }, ref: 'signinBtn' }, this.$slots.default)
        return (
            <button disabled={this.notready} class="g-signin-button" ref="signinBtn">
                {this.$slots.default}
            </button>
        );
    },
    props: {
        params: {
            type: Object,
            required: !0,
            default: function _default() { return {} }
        }
    },
    mounted: function mounted() {
        var _this = this;
        return window.gapi ?
            this.params.client_id ?
                void window.gapi.load('auth2', function () {
                    var d = window.gapi.auth2.init(_this.params);

                    _this.notready = false;

                    d.attachClickHandler(_this.$refs.signinBtn, {}, function (e) {
                        _this.$emit('success', e)
                    },
                        function (e) {
                            _this.$emit('error', e), _this.$emit('failure', e)
                        })
                }) :
                void a('params.client_id must be specified.') :
            void a('"https://apis.google.com/js/api:client.js" needs to be included as a <script>.')
    }
});

function warn(text, err) {
    console.warn(text);

    if (err && err.stack)
        console.log(err.stack);
    else if (err && typeof err === 'object')
        console.log(JSON.stringify(err));
}

Vue.component("google-button", {
    mixins: [BaseComponent],
    components: {
        //GoogleLogin,
    },
    data: function() {
        return {
            checkStatusOnInitEval: null,
            auth2: null,
            isSignedInToGoogle: false,
            userGoogleProfile: {},

            clientId: null,
            renderParams: {},
        };
    },
    created() {
        this.clientId = appSettings.WhiteLabel.GoogleClientId;
    },
    computed: {
        checkStatusOnInit() {
            try {
                if (this.checkStatusOnInitEval === null && this.controlData.CheckStatusOnInit)
                    this.checkStatusOnInitEval = utils.compile(this, this.controlData.CheckStatusOnInit);

                if (this.checkStatusOnInitEval) {
                    return utils.evaluate(this.checkStatusOnInitEval, this);
                }

                return false;
            } catch (e) {
                utils.warn(
                    "CheckStatusOnInit could not evaluate expression: " + this.controlData.CheckStatusOnInit + "; " + e
                );

                return false;
            }
        },
        buttonText() {
            return this.Translate("Sign in with Google");
        },
        styles() {
            return {
                ...this.sizeStyle,
                ...utils.resolveStyleHints(this.styleHints, this),
            };
        },

        // Copied from Angular control
        GoogleEmail() {
            return this.userGoogleProfile.Profile ? this.userGoogleProfile.Profile.getEmail() : "";
        },
        GoogleId() {
            return this.userGoogleProfile.Profile ? this.userGoogleProfile.Profile.getId() : "";
        },
        GoogleName() {
            return this.userGoogleProfile.Profile ? this.userGoogleProfile.Profile.getName() : "";
        },
        GoogleImage() {
            return this.userGoogleProfile.Profile ? this.userGoogleProfile.Profile.getImageUrl() : "";
        },
        GoogleToken() {
            return this.userGoogleProfile.token;
        },
        SignedInToGoogle() {
            return this.isSignedInToGoogle;
        },
        GoogleAuthArgs() {
            return this.userGoogleProfile.authargs;
        },
    },
    //Created replaced with preRenderComplete
    methods: {
        async preRenderComplete() {
            //this.startGoogleApp();

            this.finishRenderHandler(this);
        },
        startGoogleApp() {
            if (!appSettings.WhiteLabel.GoogleClientId) return;

            var self = this;

            gapi.load("auth2", function () {
                gapi.auth2
                    .init({
                        client_id: appSettings.WhiteLabel.GoogleClientId,
                        cookiepolicy: "none",
                        fetch_basic_profile: true,
                    })
                    .then(function (param) {
                        self.auth2 = param;

                        if (self.checkStatusOnInit) {
                            self.checkGoogleLoginState(false);
                        }
                    });
            });
        },
        async checkGoogleLoginState(shouldRunActions = true) {
            const auth2 = this.auth2;

            if (auth2.isSignedIn.get()) {
                var user = auth2.currentUser.get();
                var authResponse = user.getAuthResponse();
                var idToken = authResponse.id_token;

                var authargs = {
                    ProviderType: "Google",
                    token: idToken,
                };

                var profile = user.getBasicProfile();

                this.userGoogleProfile = { Profile: profile, token: idToken, authargs: authargs };
                this.isSignedInToGoogle = true;

                // don't run actions on Init, only gather the information for display. If the button is clicked then run actions
                if (shouldRunActions) {
                    this.runActions();
                }
            } else {
                this.userGoogleProfile = {};
                this.isSignedInToGoogle = false;

                await auth2.signIn();
                this.checkGoogleLoginState(shouldRunActions);
            }
        },
        runActions(authargs) {
            console.log(`GoogleButton.runActions() About to invoke Actions...`);

            utils.executeAndCompileAllActions(this.controlData.Actions, authargs, this);

            console.log(`GoogleButton.runActions() exitting`);
        },
        async removeGoogleAppPermissions() {
            await auth2.signOut();
        },

        // Copied from Angular control
        RunActions() {
            return this.runActions();
        },
        RemoveGoogleAppPermissions: function () {
            return this.removeGoogleAppPermissions();
        },

        on_success(c, googleUser) {
            utils.debug(`GoogleButton.on_success: ${JSON.stringify(googleUser)}`);
            try {
                this.isSignedInToGoogle = true;

                const authResponse = googleUser.getAuthResponse();
                const idToken = authResponse.id_token;

                const authargs = {
                    ProviderType: "Google",
                    token: idToken,
                };

                console.log(`authargs set`);

                const profile = googleUser.getBasicProfile();
                this.userGoogleProfile = { Profile: profile, token: idToken, authargs: authargs };

                console.log(`userGoogleProfile set`);

                c.runActions(authargs);

                console.log(`runActions complete`);

                //utils.executeAndCompileAllActions(this.controlData.Actions, authargs, this);
            }
            catch (err) {
                warn(`GoogleButton.on_success failed`, err);
            }
            finally {
                console.log(`GoogleButton.on_success finally`);
            }
        },

        on_failure(error) {
            utils.debug(`google on_failure: ${error}`);
        },
        on_current_user(e) {
            utils.debug(`google on_current_user: ${e}`);
        },

        OnGoogleAuthSuccess(idToken) {
            utils.debug(idToken);
            // Receive the idToken and make your magic with the backend
        },
        OnGoogleAuthFail(error) {
            utils.debug(error);
        },
    },
    render(h) {
        try {
            if (!this.todisplay) return null;

            return (
                <div
                    style={this.styles}
                    class={{ "c-GoogleButton d-flex": true, [`c-name-${this.name || "unnamed"}`]: true }}
                >
                    <g-signin-button
                        class="v-btn v-btn--block v-btn--has-bg theme--light v-size--default"
                        params={{ client_id: this.clientId }}
                        on-success={(e) => this.on_success(this, e)}
                        on-error={(e) => this.on_failure(e)}>
                        <img
                            class="mr-4"
                            src={`//${window.location.hostname}/StaticContent/login-icons/google-icon.svg`}
                            style="height: 22px;"
                        />
                        {this.buttonText}
                    </g-signin-button>
                </div>
            );
        } catch (e) {
            utils.error("GoogleButton Render failed", e);
            return <div>GoogleButton Failed to Render {e}</div>;
        }
    },
});

/*
                    <v-btn
                        on-click={() => this.launchGoogleLogin()}
                        depressed block
                    >
                        <img
                            class="mr-4"
                            src={`${process.env.BASE_URL}/login-icons/google-icon.svg`}
                            style="height: 22px;"
                        />
                        {this.buttonText}
                    </v-btn>



class="v-btn v-btn--block v-btn--has-bg theme--light v-size--default"
params={{ client_id: this.clientId }}
on-success={(e) =>
    this.on_success(e)
}
on-failure={(e) =>
    this.on_failure(e)
}
on-current-user={(e) =>
    this.on_current_user(e)
}

*/
