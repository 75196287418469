import Vue from 'vue';
import BaseFieldsetComponent from './baseFieldsetMixin.jsx';
import utils from '../../../Shared/utils.jsx';

Vue.component('sform-actions', {
    mixins: [BaseFieldsetComponent],
    data: () => ({
    }),
    props: {
    },
    created() {
    },
    methods: {
    },
    render() {
        if (!this.form)
            return null;

        const rows = this.generateElements(h, this.form, this.layouttype);

        return (
            <v-row>
                {rows}
            </v-row>
        );
    }
});