import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import EventBus from '../event-bus.js';

import utils from '../../Shared/utils.jsx';
import methods from '../../Shared/methods';

Vue.component('html-component', {
    mixins: [BaseComponent],
    data: function () {
        return {
            wordWrapEval: null,
            sourceUrlEval: null,
            sourceRawInterpolatedEval: null,
            data: null,
        }
    },
    //Created Replaced with preRenderComplete
    computed: {
        wordWrap() {
            try {
                if(this.wordWrapEval === null && this.controlData.WordWrap)
                    this.wordWrapEval = utils.compile(this, this.controlData.WordWrap);
                if(this.wordWrapEval)
                    return utils.evaluate(this.wordWrapEval, this);
                return false;
            }
            catch (e) {
                utils.warn('WordWrap could not evaluate expression: ' + this.controlData.WordWrap + '; ' + e);
                return false;
            }
        },
        sourceUrl() {
            if (!this.sourceUrlEval && this.controlData.DataURL)
                this.sourceUrlEval = utils.compile(this, this.controlData.DataURL);

            return this.sourceUrlEval ? utils.evaluate(this.sourceUrlEval, this) : '';
        },
        source() {
            switch(this.controlData.DataType.toLowerCase()) {
                case 'url':
                case 'raw':
                    if (typeof this.data === 'function')
                        return this.data();
                    else
                        return this.data;

                case 'rawinterpolated':
                    return utils.evaluate(this.sourceRawInterpolatedEval, this);

                default:
                    return '';
            }
        },
        style() {
            return {
                ...this.sizeStyle,
                wordBreak: this.wordWrap ? 'break-word' : null,
                whiteSpace: this.wordWrap ? 'pre-wrap' : null,
                overflow: 'auto',
            }
        }
    },
    methods: {
        async preRenderComplete() {
            switch (this.controlData.DataType.toLowerCase()) {
                case 'url':
                    const data = await utils.api.get(this.sourceUrl);
                    this.data = () => data;
                    break;

                case 'rawinterpolated':
                    this.sourceRawInterpolatedEval = utils.compile(this, this.controlData.Data);
                    break;

                case 'raw':
                    this.data = () => this.controlData.Data;
                    break;
            }
    
            this.finishRenderHandler(this);
        },
    },
    render(h) {
        if(!this.todisplay)
            return null;

        try {
            return (
                <div v-show={this.isvisible} style={this.style} class={{ 'c-Html': true, [`c-name-${this.name || 'unnamed'}`]: true }}>
                    <div domPropsInnerHTML={this.source}></div>
                </div>
            );
        }
        catch (e) {
            utils.error('Html Render failed', e);
            return <div>Html Failed to Render {e}</div>;
        }
    }
});