import Vue from "vue";
import utils from "../../Shared/utils.jsx";
import BaseComponent from "./BaseComponentMixin.jsx";
import "./css/BasicButton.css";

Vue.component("basic-button", {
    mixins: [BaseComponent],
    data: function() {
        return {
            iconrenderer: null,
            iconpositioner: null,
            icongapeval: null,
            iconStyleEval: null,
            flipHorizontallyEval: null,
            flipVerticallyEval: null,
            borderEval: null,
            animationEval: null,
            colorEval: null,
            cssClassEval: null,
            tooltiprenderer: null,
            tooltipLocationEval: null,
            isDefaultEval: null,
            isdisabledeval: null,
            stopPropagationEval: null,
            isdisabledfailed: 1,
            isActive: false,

            form_changed_indicator: null,
        };
    },
    props: {},
    created() {
        if (this.controlData.HeaderControlType) {
            switch (this.controlData.HeaderControlType) {
                case "SaveButton":
                    if (!this.controlData.Text)
                        this.controlData.Text = "Save";
                    this.controlData.Color = "primary";

                    // Scan the parent hierarchy for a variable named $$form_changed_indicator, if found, associate the enabled value
                    let p = this.$parent;
                    while (p && !('cc_form_changed_indicator' in p))
                        p = p.$parent;

                    if (p) {
                        p.cc_form_changed_active = true;
                        p.cc_form_changed_button = this;
                        this.form_changed_indicator = () => p.formChangedIndicator;
                    }
                    break;

                case "RemoveButton":
                    if (!this.controlData.Text)
                        this.controlData.Text = "Remove";
                    this.controlData.Color = "danger";
                    break;

                case "NewButton":
                    if (!this.controlData.Text)
                        this.controlData.Text = "New";
                    break;

                case "CloneButton":
                    if (!this.controlData.Text)
                        this.controlData.Text = "Save as New";
                    break;

                case "WizardButton":
                    if (!this.controlData.Text)
                        this.controlData.Text = "Wizard";
                    break;

                case "EditButton":
                    if (!this.controlData.Text)
                        this.controlData.Text = "Edit";
                    break;

                case "ExternalLinkButton":
                    if (!this.controlData.Text)
                        this.controlData.Text = "New Tab";
                    break;
            }
        }
    },
    //Mounted Replaced with preRenderComplete
    computed: {
        IsActive: {
            get() {
                return this.isActive;
            },
            set(value) {
                this.isActive = value;
            }
        },
        text() {
            if (this.controlData.Text) return this.controlData.Text;
            else return "";
        },
        icon() {
            try {
                if (this.iconrenderer === null) this.iconrenderer = utils.compile(this, this.controlData.Icon);
                let icon = utils.evaluate(this.iconrenderer, this);

                if (icon && this.iconStyle === "mdi" && !icon.startsWith("mdi-")) icon = `mdi-${icon}`;

                return icon;
            } catch (e) {
                utils.warn("Icon could not evaluate expression: " + this.controlData.IconStyle + "; " + e);
                return "";
            }
        },
        iconStyle() {
            try {
                if (this.iconStyleEval === null && this.controlData.IconStyle)
                    this.iconStyleEval = utils.compile(this, this.controlData.IconStyle);
                if (this.iconStyleEval) return utils.evaluate(this.iconStyleEval, this);
                return "mdi";
            } catch (e) {
                utils.warn("IconStyle could not evaluate expression: " + this.controlData.IconStyle + "; " + e);
                return "mdi";
            }
        },
        iconPosition() {
            try {
                if (this.iconpositioner === null && this.controlData.IconPosition)
                    this.iconpositioner = utils.compile(this, this.controlData.IconPosition);
                if (this.iconpositioner) return utils.evaluate(this.iconpositioner, this);
                return "right";
            } catch (e) {
                utils.warn("IconPosition could not evaluate expression: " + this.controlData.IconPosition + "; " + e);
                return "";
            }
        },
        iconGap() {
            try {
                if (this.icongapeval === null && this.controlData.IconGap)
                    this.icongapeval = utils.compile(this, this.controlData.IconGap);
                if (this.icongapeval) return utils.evaluate(this.icongapeval, this);
                return 10;
            } catch (e) {
                utils.warn("IconGap could not evaluate expression: " + this.controlData.IconGap + "; " + e);
                return 10;
            }
        },
        flipHorizontally() {
            try {
                if (this.flipHorizontallyEval === null && this.controlData.FlipHorizontally)
                    this.flipHorizontallyEval = utils.compile(this, this.controlData.FlipHorizontally);
                if (this.flipHorizontallyEval) return utils.evaluate(this.flipHorizontallyEval, this);
                return false;
            } catch (e) {
                utils.warn(
                    "flipHorizontally could not evaluate expression: " + this.controlData.FlipHorizontally + "; " + e
                );
                return false;
            }
        },
        flipVertically() {
            try {
                if (this.flipVerticallyEval === null && this.controlData.flipVertically)
                    this.flipVerticallyEval = utils.compile(this, this.controlData.flipVertically);
                if (this.flipVerticallyEval) return utils.evaluate(this.flipVerticallyEval, this);
                return false;
            } catch (e) {
                utils.warn(
                    "flipVertically could not evaluate expression: " + this.controlData.flipVertically + "; " + e
                );
                return false;
            }
        },
        border() {
            try {
                if (this.borderEval === null && this.controlData.Border)
                    this.borderEval = utils.compile(this, this.controlData.Border);
                if (this.borderEval) return utils.evaluate(this.borderEval, this);
                return false;
            } catch (e) {
                utils.warn("border could not evaluate expression: " + this.controlData.Border + "; " + e);
                return false;
            }
        },
        animation() {
            try {
                if (this.animationEval === null && this.controlData.Animation)
                    this.animationEval = utils.compile(this, this.controlData.Animation);
                if (this.animationEval) return utils.evaluate(this.animationEval, this);
                return "None";
            } catch (e) {
                utils.warn("animation could not evaluate expression: " + this.controlData.Animation + "; " + e);
                return "None";
            }
        },
        color() {
            try {
                let theColor = "secondary";
                if (this.colorEval === null && this.controlData.Color)
                    this.colorEval = utils.compile(this, this.controlData.Color);
                if (this.colorEval) theColor = utils.evaluate(this.colorEval, this);

                if (this.isOutlined && theColor) theColor = theColor.replace("outline-", "");
                else theColor = theColor || "secondary";
                // This helps with backwards compatibility for a version of Bootstrap that used the word 'default' to describe the secondary button
                if (theColor === "default") return "secondary";
                else if (theColor === "danger") return "error";
                return theColor;
            } catch (e) {
                utils.warn("color could not evaluate expression: " + this.controlData.Color + "; " + e);
                return "secondary";
            }
        },
        cssClass() {
            try {
                if (this.cssClassEval === null && this.controlData.CssClass)
                    this.cssClassEval = utils.compile(this, this.controlData.CssClass);
                if (this.cssClassEval) return utils.evaluate(this.cssClassEval, this);
                return "";
            } catch (e) {
                utils.warn("cssClass could not evaluate expression: " + this.controlData.CssClass + "; " + e);
                return "";
            }
        },
        tooltip() {
            try {
                if (this.tooltiprenderer === null && this.controlData.Tooltip)
                    this.tooltiprenderer = utils.compile(this, this.Translate(this.controlData.Tooltip));
                if (this.tooltiprenderer) {
                    return utils.evaluate(this.tooltiprenderer, this);
                }
                return "";
            } catch (e) {
                utils.warn("tooltip could not evaluate expression: " + this.controlData.Tooltip + "; " + e);
                return "";
            }
        },
        tooltipLocation() {
            try {
                let location = "";
                if (this.tooltipLocationEval === null && this.controlData.TooltipLocation)
                    this.tooltipLocationEval = utils.compile(this, this.controlData.TooltipLocation);
                if (this.tooltipLocationEval) location = utils.evaluate(this.tooltipLocationEval, this);

                return location.split("-")[0].toLowerCase() || "top";
            } catch (e) {
                utils.warn(
                    "tooltipLocation could not evaluate expression: " + this.controlData.TooltipLocation + "; " + e
                );
                return "top";
            }
        },
        isDefault() {
            try {
                if (this.isDefaultEval === null && this.controlData.IsDefault)
                    this.isDefaultEval = utils.compile(this, this.controlData.IsDefault);
                if (this.isDefaultEval) return utils.evaluate(this.isDefaultEval, this);
                return false;
            } catch (e) {
                utils.warn("Icon could not evaluate expression: " + this.controlData.IsDefault + "; " + e);
                return false;
            }
        },
        isdisabled() {
            try {
                if (this.form_changed_indicator)
                    return !this.form_changed_indicator();

                if (this.isdisabledeval === null && this.controlData.IsDisabled)
                    this.isdisabledeval = utils.compile(this, this.controlData.IsDisabled);

                if (this.isdisabledeval) {
                    const res = utils.evaluate(this.isdisabledeval, this, false, () => {
                        // This is a callback that will only be called if evaluate throws an exception.
                        // If this happens, we read the isdisabledfailed (so Vue knows we care) and then
                        // after 100ms, we update isdisabledfailed to cause Vue to re-evaluate todisplay.
                        // (cap the retry at 10 times with increasing delay each attempt)
                        if (this.isdisabledfailed >= 0 && this.isdisabledfailed < 10)
                            setTimeout(() => this.isdisabledfailed++, 50 * this.isdisabledfailed);
                    });

                    return JSON.parse(res);
                }
                return false;
            } catch (e) {
                utils.warn("isdisabled could not evaluate expression: " + this.controlData.IsDisabled + "; " + e);
                return false;
            }
        },
        stopPropagation() {
            try {
                if (this.stopPropagationEval === null && this.controlData.StopPropagation)
                    this.stopPropagationEval = utils.compile(this, this.controlData.StopPropagation);
                if (this.stopPropagationEval) return utils.evaluate(this.stopPropagationEval, this);
                return false;
            } catch (e) {
                utils.warn(
                    "stopPropagation could not evaluate expression: " + this.controlData.StopPropagation + "; " + e
                );
                return false;
            }
        },
        styles() {
            return {
                ...this.sizeStyle,
                overflow: "hidden",
                whiteSpace: "nowrap",
                ...utils.resolveStyleHints(this.styleHints, this),
            };
        },
        isOutlined() {
            return this.controlData.Color && this.controlData.Color.startsWith("outline-");
        },
        buttonClasses() {
            let classes = {
                ThemeRightConnector: this.themes.rightConnector,
                ThemeLeftConnector: this.themes.leftConnector,
            };

            classes[this.cssClass] = true;
            return classes;
        },
        buttonStyles() {
            const styles = {};

            if (this.controlData.StyleHints && this.controlData.StyleHints.TextAlign)
                switch (this.controlData.StyleHints.TextAlign) {
                    case 'Left':
                        styles['justify-content'] = 'left';
                        break;

                    case 'Center':
                        styles['justify-content'] = 'center';
                        break;

                    case 'Right':
                        styles['justify-content'] = 'right';
                        break;
                }
            else if (this.controlData.StyleHints && this.controlData.StyleHints.JustifyContent)
                styles['justify-content'] = this.controlData.StyleHints.JustifyContent;

            return styles;
        },
        iconClasses() {
            return {
                "mdi mdi-flip-vertical": this.flipVertically,
                "mdi mdi-flip-horizontal": this.flipHorizontally,
                "mdi mdi-border-all-variant": this.border,
                "mdi-animate-spin": this.animation === "Spin",
                "mdi-animate-pulse": this.animation === "Pulse",
            };
        },
        themes() {
            return {
                flat: this.controlData.Themes
                    ? this.controlData.Themes.includes("Flat") ||
                      this.controlData.Themes.includes("FlatWhite") ||
                      this.controlData.Themes.includes("Transparent")
                    : false,
                small: this.controlData.Themes
                    ? this.controlData.Themes.includes("Condensed") ||
                      this.controlData.Themes.includes("CondensedBordered")
                    : false,
                condensedBordered: this.controlData.Themes
                    ? this.controlData.Themes.includes("CondensedBordered")
                    : false,
                transparent: this.controlData.Themes
                    ? this.controlData.Themes.includes("Transparent") || this.controlData.Themes.includes("Condensed")
                    : false,
                leftConnector: this.controlData.Themes ? this.controlData.Themes.includes("LeftConnector") : false,
                rightConnector: this.controlData.Themes ? this.controlData.Themes.includes("RightConnector") : false,
                fab: this.controlData.Themes ? this.controlData.Themes.includes("FloatingAction") : false,
            };
        },
    },
    methods: {
        async doClick(e) {
            e.cancelBubble = true;
            if (this.stopPropagation) e.stopPropagation();

            await utils.executeAndCompileAllActions(this.controlData.Actions, null, this);
        },
        preRenderComplete() {
            this.finishRenderHandler(this);
        },
        ToggleIsActive() {
            this.isActive = !this.isActive;
        }
    },
    render(h) {
        try {
            if (!this.todisplay) return null;

            let content = [];

            if (this.text && this.icon) {
                if (this.iconPosition === "Left")
                    content.push(
                        <v-icon
                            style={{ "margin-right": this.iconGap + "px" }}
                            medium={!this.themes.small}
                            class={this.iconClasses}
                            left
                        >
                            {this.icon}
                        </v-icon>
                    );
                content.push(<translation-container context={this} value={this.text}></translation-container>);
                if (!this.iconPosition || this.iconPosition === "Right")
                    content.push(
                        <v-icon
                            style={{ "margin-left": this.iconGap + "px" }}
                            medium={!this.themes.small}
                            class={this.iconClasses}
                            right
                        >
                            {this.icon}
                        </v-icon>
                    );
            } else if (this.text)
                content.push(<translation-container context={this} value={this.text}></translation-container>);
            else if (this.icon) {
                if (this.styles.color)
                    content.push(
                        <v-icon class={this.iconClasses} style={{ color: this.styles.color }} size="20px">
                            {this.icon}
                        </v-icon>
                    );
                else
                    content.push(
                        <v-icon class={this.iconClasses} size="20px">
                            {this.icon}
                        </v-icon>
                    );
            }

            //get sizes
            let width, height, minWidth, minHeight, maxWidth, maxHeight;

            if (this.themes.small && !this.text){
                width = "32px"; //custom size in place of the small vuetify option
                height = "32px";
            }

            if (this.sizeOptions && this.sizeOptions.Width) {
                if (this.sizeOptions.Width.Mode === "Fixed")
                    width = `${this.sizeOptions.Width.Value}${this.sizeOptions.Width.Units == "Percent" ? "%" : this.sizeOptions.Width.Units == "Em" ? "em" : "px"}`;

                if (this.sizeOptions.Width.Min)
                    minWidth = `${this.sizeOptions.Width.Min}${this.sizeOptions.Width.MinUnits == "Percent" ? "%" : this.sizeOptions.Width.MinUnits == "Em" ? "em" : "px"}`;

                if (this.sizeOptions.Width.Max)
                    maxWidth = `${this.sizeOptions.Width.Max}${this.sizeOptions.Width.MaxUnits == "Percent" ? "%" : this.sizeOptions.Width.MaxUnits == "Em" ? "em" : "px"}`;
            }

            if (this.sizeOptions && this.sizeOptions.Height) {
                if (this.sizeOptions.Height.Mode === "Fixed")
                    height = `${this.sizeOptions.Height.Value}${this.sizeOptions.Height.Units == "Percent" ? "%" : this.sizeOptions.Height.Units == "Em" ? "em" : "px"}`;

                if (this.sizeOptions.Height.Min)
                    minHeight = `${this.sizeOptions.Height.Min}${this.sizeOptions.Height.MinUnits == "Percent" ? "%" : this.sizeOptions.Height.MinUnits == "Em" ? "em" : "px"}`;

                if (this.sizeOptions.Height.Max)
                    maxHeight = `${this.sizeOptions.Height.Max}${this.sizeOptions.Height.MaxUnits == "Percent" ? "%" : this.sizeOptions.Height.MaxUnits == "Em" ? "em" : "px"}`;
            }

            let button = (
                <v-btn
                    id={this.activatorId}
                    icon={!this.text && !this.themes.fab}
                    fab={this.themes.fab}
                    class={this.buttonClasses}
                    style={this.buttonStyles}
                    color={this.color}
                    outlined={this.isOutlined || this.themes.condensedBordered}
                    depressed={this.themes.flat}
                    text={this.themes.transparent}
                    x-small={this.themes.small && !!this.text}
                    small={this.themes.fab && !this.text}
                    medium={!this.themes.small && !this.themes.fab}
                    on-click={(e) => this.doClick(e)}
                    type="button"
                    disabled={this.isdisabled == "true" || this.isdisabled == true}
                    is-default={this.isDefault}
                    block={this.sizeOptions && this.sizeOptions.Width && this.sizeOptions.Width.Mode === "Fill"}
                    min-width={minWidth}
                    max-width={maxWidth}
                    min-height={minHeight}
                    max-height={maxHeight}
                    width={width}
                    height={height}
                    elevation={0}
                >
                    {content}
                </v-btn>
            );

            let finalContent = utils.generateTooltip(h, button, this.tooltip, this.tooltipLocation);

            return (
                <div
                    class={{ "c-BasicButton": true, [`c-name-${this.name || "unnamed"}`]: true, "ma-1": true }}
                    v-show={this.isvisible}
                    style={this.styles}

                >
                    {finalContent}
                </div>
            );
        } catch (e) {
            utils.error("BasicButton Render failed", e);
            return <div>Button Failed to Render {e}</div>;
        }
    },
});
