import Vue from 'vue';
import Vuetify from 'vuetify';
import EventBus from './event-bus.js';
import utils from '../Shared/utils.jsx';

Vue.component('authenticate', {
    data: () => ({
        e1: true,
        state: 'credentials',
        base_uri: 'https://api.callcorplab.com',
        username: '',
        password: '',
        showerror: false,
        error_message: '',
        items: [
            'https://api.callcorplab.com',
            'https://api-staging.callcorplab.com',
            'https://api.total.care'
        ],
        context_list: [],
    }),
    props: {
        root: null,
        confirm: null,
        cancel: null,
    },
    mounted() {
        var credentials = localStorage['credentials'];
        if (credentials) {
            var credentials_obj = JSON.parse(credentials);
            if (credentials_obj.ProviderType == 'Native' || credentials_obj.ProviderType == '.CARE') {
                this.username = credentials_obj.UniqueID;
                this.password = credentials_obj.Password;
            }
        }
        var last_baseuri = localStorage['base_uri'];
        if (last_baseuri) {
            this.base_uri = last_baseuri;
        }
    },
    methods: {
        sync(prop, value) {
            this[prop] = value;

            if (prop == 'base_uri') {
                // localStorage is just so we don't have to keep typing it every time we reload this page
                localStorage['base_uri'] = value;

                // sessionStorage is what the Vue app will use for apis
                sessionStorage['base_uri'] = value;
            }
        },
        async confirm_validate() {
            var authargs = {
                ProviderType: 'Native',
                UniqueID: this.username,
                Password: this.password,
                MFAToken: 'KADgy96n02eTtRjHTLOyqDE/MmXfNYXzjsXIDWRijcp9N9yxToZjmbXtPdvOgqyoWunIz7KIHYekJwO8M63r02bv/SNbHW3/M9S75oyqIZhcMToXZd1MojTbMbuTWdRy5RfqMzdNUArWROL6KGC/tBC7qElr7s1pshjD9NkBqJx/TQUTOB28z9ze3WF6YKH3vnVopE1SuCjYITlp6oAK9Q==',
            };

            sessionStorage.removeItem('access_token');
            sessionStorage['base_uri'] = this.base_uri;

            try {
                const res = await utils.api.request('POST', 'Apps/AuthenticateExt', authargs);
                if (res.success) {
                    if (authargs.ProviderType == 'Native' || authargs.ProviderType == '.CARE')
                        localStorage['credentials'] = JSON.stringify(authargs);

                    this.access_token = res.access_token;
                    this.refresh_token = res.refresh_token;

                    sessionStorage.setItem('access_token', this.access_token);

                    this.context_list = [];
                    for (var i = 0; i < res.user_list.length; ++i) {
                        const user = res.user_list[i];
                        const ctx = {
                            type: 'user',
                            name: user.FirstName + ' ' + user.LastName,
                            id: user.userid,
                            contexts: [],
                        };
                        this.context_list.push(ctx);

                        for (var j = 0; j < user.SecurityContexts.length; j++) {
                            const context = user.SecurityContexts[j];
                            if (context.SecurityContextTypeID == 'Customer') {
                                utils.log('context: ' + JSON.stringify(context));
                                ctx.contexts.push({
                                    Name: context.Name,
                                    UserSecurityContextID: context.UserSecurityContextID,
                                    SecurityContextTypeID: context.SecurityContextTypeID,
                                });
                            }
                        }
                    }
                    this.state = 'choosecarecenter';
                }
                else {
                    this.error_message = 'Credentials Invalid';
                    this.showerror = true;
                }
            }
            catch (e) {
                // Inform of the error
                this.error_message = e;
                this.showerror = true;
            }
        },
        async selectContext(userSecurityContextID) {
            const uri = 'Apps/SecurityContext/Add?userSecurityContextID=' + userSecurityContextID;
            const res = await utils.api.get(uri);

            this.access_token = res.access_token;
            this.root.setTokens(this.access_token, this.refresh_token, res.expires_in);

            this.state = 'credentials';
            this.confirm();
        },
    },
    render(h) {
        let content;
        switch (this.state) {
            case 'credentials':
                content = (
                    <v-card>
                        <v-card-title class="headline">
                            Logon
                        </v-card-title>

                        <v-card-subtitle>
                            Please enter your credentials
                        </v-card-subtitle>

                        <v-card-text>
                            <v-text-field
                                label="Username"
                                required
                                value={this.username}
                                on-input={(value) => this.sync('username', value)}>
                            </v-text-field>
                            <v-text-field
                                label="Password"
                                required
                                append-icon={this.e1 ? 'visibility' : 'visibility_off'}
                                append-icon-cb={() => (this.e1 = !this.e1)}
                                type={this.e1 ? 'password' : 'text'}
                                value={this.password}
                                on-input={(value) => this.sync('password', value)}>
                            </v-text-field>
                            <v-select
                                label="API URL"
                                combobox
                                items={this.items}
                                value={this.base_uri}
                                on-change={(e) => this.sync('base_uri', e.target ? e.target.value : e)}
                            ></v-select>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation={0} plain on-click={this.confirm_validate}>Confirm</v-btn>
                            <v-btn elevation={0} plain on-click={this.cancel}>Cancel</v-btn>
                        </v-card-actions>
                        <v-snackbar
                            timeout={10000}
                            color="red"
                            multi-line={true}
                            value={this.showerror}>
                            {this.error_message}
                        </v-snackbar>
                    </v-card>
                );
                break;

            case 'choosecarecenter':
                const items = [];
                for (let i = 0; i < this.context_list.length; i++) {
                    const ctx = this.context_list[i];
                    if (ctx.type == 'user') {
                        for (let j = 0; j < ctx.contexts.length; j++) {
                            const context = ctx.contexts[j];
                            items.push(
                                <v-list-item
                                    key={context.UserSecurityContextID}
                                    ripple={true}
                                    on-click={(e) => this.selectContext(context.UserSecurityContextID)}>

                                    <v-list-item-icon>
                                        <v-icon>mdi mdi-earth</v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-content>
                                        <v-list-item-title>{context.Name}</v-list-item-title>
                                        <v-list-item-subtitle >{ctx.name}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            );
                            // title> class="grey--text text--darken-4"

                            if (j < ctx.contexts.length - 1)
                                items.push(<v-divider key={context.UserSecurityContextID + '_' + j}></v-divider>)
                        }
                    }
                }
                content = (
                    <v-card>
                        <v-card-title class="headline">Choose your Call Center</v-card-title>
                        <div style="max-height:600px; overflow-y: scroll">
                            <v-list two-line dense>
                                {items}
                            </v-list>
                        </div>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation={0} plain on-click={this.cancel}>Cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                );
                break;
        }


        return content;
    }
});