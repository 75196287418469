import Vue from 'vue';
import utils from '../../Shared/utils.jsx';
import BaseComponent from './BaseComponentMixin.jsx';
import './css/Icon.css';

Vue.component('basic-icon', {
    mixins: [BaseComponent],
    data: function () {
        return {
            iconrenderer: null,
            iconStyleEval: null,
            iconColorEval: null,
            colorEval: null,
            iconGlowColorEval: null,
            fontSizeEval: null,
            captionEval: null,
            captionFontSizeEval: null,
            tooltipEval: null,
            tooltipPlacementEval: null,
            rotationEval: null,
            flipHorizontallyEval: null,
            flipVerticallyEval: null,
            borderEval: null,
            animationEval: null,
            disabledEval: null,
            asyncUrl: null,
            asyncIcon: null,
            asyncColor: null,
            asyncTooltip: null,
            asyncCaption: null,
            asyncRotation: null,
            Input: {}
        }
    },
    async created() {
        if(this.controlData.AsyncLookup === true) {
            this.asyncUrl = utils.compile(this, this.controlData.AsyncOptions.URL);

            if(this.controlData.AsyncOptions.Icon)
                this.asyncIcon = utils.compile(this, this.controlData.AsyncOptions.Icon);
            
            if(this.controlData.AsyncOptions.Color)
                this.asyncColor = utils.compile(this, this.controlData.AsyncOptions.Color);

            if(this.controlData.AsyncOptions.Tooltip)
                this.asyncTooltip = utils.compile(this, this.controlData.AsyncOptions.Tooltip);
            
            if(this.controlData.AsyncOptions.Caption)
                this.asyncCaption = utils.compile(this, this.controlData.AsyncOptions.Caption);

            if(this.controlData.AsyncOptions.Rotation)
                this.asyncRotation = utils.compile(this, this.controlData.AsyncOptions.Rotation);
        }
    },
    //Mounted Replaced with preRenderComplete
    watch: {
        asyncUrlComputed: function() {
            if(this.controlData.AsyncLookup === true)
                this.asyncLookup();
        },
    },
    computed: {
        icon() {
            if(this.iconrenderer === null)
                this.iconrenderer = utils.compile(this, this.controlData.Icon);
            let icon = utils.evaluate(this.iconrenderer, this);

            if(this.iconStyle === 'mdi' && !icon.startsWith('mdi-'))
                icon = `mdi-${icon}`;

            return icon;
        },
        iconStyle() {
            try {
                if(this.iconStyleEval === null && this.controlData.IconStyle)
                    this.iconStyleEval = utils.compile(this, this.controlData.IconStyle);
                if(this.iconStyleEval)
                    return utils.evaluate(this.iconStyleEval, this);
                return 'mdi';
            }
            catch (e) {
                utils.warn('IconStyle could not evaluate expression: ' + this.controlData.IconStyle + '; ' + e);
                return 'mdi';
            }
        },
        iconColor() {
            try {
                if(this.iconColorEval === null && this.controlData.Color)
                    this.iconColorEval = utils.compile(this, this.controlData.Color);
                if(this.iconColorEval)
                    return utils.evaluate(this.iconColorEval, this);
                return '#444';
            }
            catch (e) {
                utils.warn('Color could not evaluate expression: ' + this.controlData.Color + '; ' + e);
                return '#444';
            }
        },
        iconGlowColor() {
            try {
                if(this.iconGlowColorEval === null && this.controlData.GlowColor)
                    this.iconGlowColorEval = utils.compile(this, this.controlData.GlowColor);
                if(this.iconGlowColorEval)
                    return utils.evaluate(this.iconGlowColorEval, this);
                return '';
            }
            catch (e) {
                utils.warn('GlowColor could not evaluate expression: ' + this.controlData.GlowColor + '; ' + e);
                return '';
            }
        },
        fontSize() {
            try {
                if(this.fontSizeEval === null && this.controlData.FontSize)
                    this.fontSizeEval = utils.compile(this, this.controlData.FontSize);
                if(this.fontSizeEval)
                    return utils.resolveFontSize(utils.evaluate(this.fontSizeEval, this));
                return '';
            }
            catch (e) {
                utils.warn('FontSize could not evaluate expression: ' + this.controlData.FontSize + '; ' + e);
                return '';
            }
        },
        caption() {
            if(this.controlData.Caption)
                return (
                    <translation-container context={this} value={this.controlData.Caption}></translation-container>
                );
            return null;
        },
        captionFontSize() {
            try {
                if(this.captionFontSizeEval === null && this.controlData.CaptionFontSize)
                    this.captionFontSizeEval = utils.compile(this, this.controlData.CaptionFontSize);
                if(this.captionFontSizeEval)
                    return utils.resolveFontSize(utils.evaluate(this.captionFontSizeEval, this));
                return this.fontSize;
            }
            catch (e) {
                utils.warn('CaptionFontSize could not evaluate expression: ' + this.controlData.CaptionFontSize + '; ' + e);
                return this.fontSize;
            }
        },
        tooltip() {
            try {
                if(this.tooltipEval === null && this.controlData.Tooltip)
                    this.tooltipEval = utils.compile(this, this.Translate(this.controlData.Tooltip));
                if(this.tooltipEval)
                    return utils.evaluate(this.tooltipEval, this);
                return '';
            }
            catch (e) {
                utils.warn('Tooltip could not evaluate expression: ' + this.controlData.Tooltip + '; ' + e);
                return '';
            }
        },
        tooltipPlacement() {
            try {
                if(this.tooltipPlacementEval === null && this.controlData.TooltipPlacement)
                    this.tooltipPlacementEval = utils.compile(this, this.controlData.TooltipPlacement);
                let placement = '';
                if(this.tooltipPlacementEval)
                    placement = utils.evaluate(this.tooltipPlacementEval, this);
                return placement.split('-')[0].toLowerCase() || 'top';
            }
            catch (e) {
                utils.warn('TooltipPlacement could not evaluate expression: ' + this.controlData.TooltipPlacement + '; ' + e);
                return '';
            }
        },
        rotation() {
            try {
                if(this.rotationEval === null && this.controlData.Rotation)
                    this.rotationEval = utils.compile(this, this.controlData.Rotation);
                if(this.rotationEval)
                    return utils.evaluate(this.rotationEval, this);
                return '';
            }
            catch (e) {
                utils.warn('Rotation could not evaluate expression: ' + this.controlData.Rotation + '; ' + e);
                return '';
            }
        },
        flipHorizontally() {
            try {
                if(this.flipHorizontallyEval === null && this.controlData.FlipHorizontally)
                    this.flipHorizontallyEval = utils.compile(this, this.controlData.FlipHorizontally);
                if(this.flipHorizontallyEval)
                    return utils.evaluate(this.flipHorizontallyEval, this);
                return false;
            }
            catch (e) {
                utils.warn('FlipHorizontally could not evaluate expression: ' + this.controlData.FlipHorizontally + '; ' + e);
                return false;
            }
        },
        flipVertically() {
            try {
                if(this.flipVerticallyEval === null && this.controlData.FlipVertically)
                    this.flipVerticallyEval = utils.compile(this, this.controlData.FlipVertically);
                if(this.flipVerticallyEval)
                    return utils.evaluate(this.flipVerticallyEval, this);
                return false;
            }
            catch (e) {
                utils.warn('FlipVertically could not evaluate expression: ' + this.controlData.FlipVertically + '; ' + e);
                return false;
            }
        },
        border() {
            try {
                if(this.borderEval === null && this.controlData.Border)
                    this.borderEval = utils.compile(this, this.controlData.Border);
                if(this.borderEval)
                    return utils.evaluate(this.borderEval, this);
                return false;
            }
            catch (e) {
                utils.warn('Border could not evaluate expression: ' + this.controlData.Border + '; ' + e);
                return false;
            }
        },
        animation() {
            try {
                if(this.animationEval === null && this.controlData.Animation)
                    this.animationEval = utils.compile(this, this.controlData.Animation);
                if(this.animationEval)
                    return utils.evaluate(this.animationEval, this);
                return '';
            }
            catch (e) {
                utils.warn('Animation could not evaluate expression: ' + this.controlData.Animation + '; ' + e);
                return '';
            }
        },
        disabled() {
            try {
                if(this.disabledEval === null && this.controlData.IsDisabled)
                    this.disabledEval = utils.compile(this, this.controlData.IsDisabled);
                if(this.disabledEval) {
                    let value = utils.evaluate(this.disabledEval, this);
                    return (value === true || value === 'true');
                }
                return false;
            }
            catch (e) {
                utils.warn('IsDisabled could not evaluate expression: ' + this.controlData.IsDisabled + '; ' + e);
                return false;
            }
        },
        iconClasses: function () {
            return {
                'mdi-flip-vertical': this.flipVertically, 
                'mdi-flip-horizontal': this.flipHorizontally,
                'mdi-border-all-variant': this.border,
                'mdi-animate-spin': this.animation === 'Spin',
                'mdi-animate-pulse': this.animation === 'Pulse',
            }
        },
        iconStyles() {
            const styles = {};

            // The Vuetify icon is by default scaled to 1.3. This means that a container won't grow properly to its size since it doesn't actually add to the component's size. 
            // If we add a 15% margin, we know that it will be the correct size. 
            styles.margin = '15%';

            if(this.iconGlowColor)
                styles['text-shadow'] = `0 0 0.2em ${this.iconGlowColor}`;

            if (this.fontSize)
                styles['font-size'] = this.fontSize;

            if(this.rotation)
                styles.transform = `rotate(${this.rotation}deg)`;

            if (this.iconColor)
                styles.color = this.iconColor;

            return styles;
        },
        containerStyles() {
            let styles = {
                ...utils.resolveStyleHints(this.styleHints, this),
                ...this.sizeStyle,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            };

            if (this.controlData.Actions && this.controlData.Actions.length > 0 && !this.disabled)
                styles.cursor = "pointer";

            return styles;
        },
        asyncUrlComputed() {
            try {
                if(this.asyncUrl)
                    return utils.evaluate(this.asyncUrl, this)
                return ''
            }
            catch (e) {
                utils.warn('asyncUrlComputed could not evaluate expression: ' + this.asyncUrl + '; ' + e);
                return false;
            }
        },
    },
    methods: {
        async preRenderComplete() {
            if(this.controlData.AsyncLookup === true)
                await this.asyncLookup();
            this.finishRenderHandler(this);
        },
        async onClick(e) {
            if(this.disabled)
                return;
            
            e.cancelBubble = true;
            e.stopPropagation();

            await utils.executeAndCompileAllActions(this.controlData.Actions, null, this);
        },
        async asyncLookup() {
            try {
                const res = await utils.api.get(this.asyncUrlComputed);
                if (res)
                    Vue.set(this.Input, 'Data', res);
                else
                    Vue.set(this.Input, 'Data', '');

                if(this.asyncIcon)
                    this.iconrenderer = this.asyncIcon;
                if(this.asyncColor)
                    this.colorEval = this.asyncColor;
                if(this.asyncTooltip)
                    this.tooltipEval = this.asyncTooltip;
                if(this.asyncCaption)
                    this.captionEval = this.asyncCaption;
                if(this.asyncRotation)
                    this.rotationEval = this.asyncRotation;
            }
            catch (e)
            {
                utils.warn('Async lookup failed to evaluate url: ' + this.controlData.AsyncOptions.URL + '; ' + e, e);
            }
        }
    },
    props: {
    },
    render(h) {
        if (!this.todisplay)
            return null;

        try {
            // Removed: color={this.iconColor} and put in style (vuetify colors don't support all the same colors)
            let icon = (<v-icon dense style={this.iconStyles} class={this.iconClasses} >{this.icon}</v-icon>);
    
            let finalContent = utils.generateTooltip(h, icon, this.tooltip, this.tooltipPlacement);
    
            return (
                <div
                    style={this.containerStyles}
                    class={{ 'c-Icon': true, [`c-name-${this.name || 'unnamed'}`]: true }}
                    on-click={(e) => this.onClick(e)}>
                    {finalContent}
                    <div class="c-icon-caption" style={{fontSize: this.captionFontSize}}>{ this.caption }</div>
                </div>
            );
        }
        catch (e) {
            utils.error('Icon Render failed', e);
            return <div>Icon Failed to Render {e}</div>;
        }
    }
});