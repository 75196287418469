import Vue from 'vue';
import utils from '../../Shared/utils.jsx';
import careHelpfulFunctions from '../careHelpfulFunctions.jsx';
import BaseComponent from './BaseComponentMixin.jsx';
import "./css/RealTimeGrid.css";

/*
    This grid is based on the Vuetify data grid because Ag-Grid's version that supports server-side data sources
    requires the enterprise license for all features. The Vuetify version is not as advanced, but does offer
    enough features for the initial proof of concept, which is for real-time data grids that rely upon the
    new back-end real-time data feeds. (10/19/2022)
*/

const CellButton = {
    data: () => ({
        type: 'CellButton',
    }),
    created() {
        if (this.btn.Condition && !this.btn.$$Condition)
            this.btn.$$Condition = utils.compileExpression(this, this.btn.Condition, this.cd.RowModelName || 'row');

        if (this.btn.Icon && !this.btn.$$Icon)
            this.btn.$$Icon = utils.compile(this, this.btn.Icon, false, this.cd.RowModelName || 'row');

        if (this.btn.Color && !this.btn.$$Color)
            this.btn.$$Color = utils.compile(this, this.btn.Color, false, this.cd.RowModelName || 'row');

        if (this.btn.Tooltip && !this.btn.$$Tooltip)
            this.btn.$$Tooltip = utils.compile(this, this.btn.Tooltip, false, this.cd.RowModelName || 'row');
    },
    props: {
        btn: null,
        cd: null,
        c_context: null,
        rowData: null,
    },
    methods: {
        rowMenuClick(e, data, btn, m) {
            //e.cancelBubble = true;
            //e.preventDefault();
            utils.executeAndCompileAllActions(m.Actions, { RowIndex: -1, Data: this.rowData.item }, this.c_context);
        },
        menuOpenClose(state, data, btn, m) {
            if (btn.OnMenuOpen)
                utils.executeAndCompileAllActions(btn.OnMenuOpen, { RowIndex: -1, Data: this.rowData.item, State: state }, this.c_context);
        },
        rowCommandClick(e, data, btn) {
            e.cancelBubble = true;
            e.preventDefault();
            utils.executeAndCompileAllActions(btn.Actions, { RowIndex: -1, Data: this.rowData.item }, this.c_context);
        },
        //used in the grid to calculate the value of the command column.
        getEvaluationResults(button, context, data) {
            var condition = (button.$$Condition ? utils.evaluate(button.$$Condition, context, false, null, false, data) : null);

            return {
                condition: condition,
                color: (button.$$Color && condition ? utils.evaluate(button.$$Color, context, false, null, false, data) : null),
                icon: (button.$$Icon && condition ? utils.evaluate(button.$$Icon, context, false, null, false, data) : null)
            };
        }
    },
    render(h) {
        const data = this.rowData.item;
        const btn = this.btn;

        if (btn.$$Condition && !utils.evaluate(btn.$$Condition, this.c_context, false, null, false, data))
            return null;

        let color;
        if (btn.$$Color)
            color = utils.evaluate(btn.$$Color, this.c_context, false, null, false, data);

        let icon;
        if (btn.$$Icon)
            icon = utils.evaluate(btn.$$Icon, this.c_context, false, null, false, data);

        let b;
        if (btn.Menu && btn.Menu.length > 0) {
            const scopedSlots = {
                activator: ({ on, attrs }) =>
                    <v-btn elevation={0}
                        v-show={!btn.$$Condition || utils.evaluate(btn.$$Condition, this.c_context, false, null, false, data)}
                        icon small
                        {...{ on }}
                        {...{ attrs }}
                    >
                        <v-icon
                            style={{ color: color }}
                            small>
                            {icon}
                        </v-icon>
                    </v-btn>
            };

            const items = [];
            for (let i = 0; i < btn.Menu.length; i++) {
                const m = btn.Menu[i];
                if (m.Separator)
                    items.push(<v-divider></v-divider>);
                else {
                    if (m.EnabledExpression && !m.$$enabledexpn)
                        m.$$enabledexpn = utils.compileExpression(this, m.EnabledExpression, this.cd.RowModelName || 'row');

                    if (m.Icon && !m.$$iconexpn)
                        m.$$iconexpn = utils.compile(this, m.Icon, this.cd.RowModelName || 'row');

                    if (m.Icon && m.IconColor && !m.$$iconcolorexpn)
                        m.$$iconcolorexpn = utils.compile(this, m.IconColor, this.cd.RowModelName || 'row');

                    let menuicon;
                    if (m.$$iconexpn) {
                        let iconcolor;
                        if (m.$$iconcolorexpn)
                            iconcolor = utils.evaluate(m.$$iconcolorexpn, this.c_context, false, null, false, data);

                        menuicon =
                            <v-list-item-icon>
                                <v-icon style={{ color: iconcolor }} small>{utils.evaluate(m.$$iconexpn, this.c_context, false, null, false, data)}</v-icon>
                            </v-list-item-icon>;
                    }

                    items.push(
                        <v-list-item
                            key={i}
                            disabled={(m.$$enabledexpn && !utils.evaluate(m.$$enabledexpn, this.c_context, false, null, false, data)) ? true : false}
                            on-click={(e) => this.rowMenuClick(e, data, btn, m)}>
                            {menuicon}
                            <v-list-item-content>
                                <v-list-item-title>{m.Title}</v-list-item-title>
                            </v-list-item-content>

                        </v-list-item>
                    );
                }
            }

            b = (
                <v-menu
                    offset-x
                    close-on-click={true}
                    close-on-content-click={true}
                    scopedSlots={scopedSlots}
                    on-input={(state) => this.menuOpenClose(state, data, btn)}
                >
                    <v-list dense>
                        <v-list-item-group>
                            {items}
                        </v-list-item-group>
                    </v-list>
                </v-menu>
            );
        }
        else if (btn.Actions && btn.Actions.length > 0)
            b = (
                <v-btn elevation={0}
                    icon small
                    on-click={(e) => this.rowCommandClick(e, data, btn)}>
                    <v-icon
                        style={{ color: color }}
                        small>
                        {icon}
                    </v-icon>
                </v-btn>
            );
        else
            b = (
                <v-btn elevation={0}
                    style={{ cursor: 'default' }}
                    depressed
                    icon small>
                    <v-icon
                        style={{ color: color }}
                        small>
                        {icon}
                    </v-icon>
                </v-btn>
            );

        if (btn.$$Tooltip) {
            const tooltip = utils.evaluate(btn.$$Tooltip, this.c_context, false, null, false, data);
            if (tooltip)
                b = utils.generateTooltip(h, b, tooltip, 'right');
        }

        return b;
    }
};

Vue.component('real-time-grid', {
    mixins: [BaseComponent],
    components: {
        CellButton,
    },
    data: function () {
        return {
            id: null,
            gridName: null,

            totalRows: 0,
            rowdata: [],
            loading: false,
            options: {},
            dense: false,
            seachText: "",
            
            viewname: 'UserList~!!~null',
            // listName: 'AllUsers',
            sortCol: 'StartTime',
            descending: false,
            start: 0,
            length: 10,
            defaultRowsPerPage: 5,

            selected_items: [],
            selectableKey: "id",
            // searchFilters: {},
            column_visibility: null,

            menu_is_open: false,
        }
    },
    async created() {
        this.gridName = `RealTimeGrid_${this.controlData.Name || 'Unnamed'}`;
        const settings = this.System.LocalStorage(this.gridName + '.ColumnSettings');
        if (settings)
            this.column_visibility = JSON.parse(settings);
        else if (this.controlData.ColumnDefs && this.controlData.ColumnDefs.length)
            this.column_visibility = careHelpfulFunctions.arrayToObject(this.controlData.ColumnDefs, a => a.Field, b => b.Visible);
        else
            this.column_visibility = [];

        const options = this.System.LocalStorage(this.gridName + '.Options');
        if (options)
            this.options = { ...JSON.parse(options), page: 1, groupBy: [], groupDesc: [], mustSort: false, multiSort: false };

        if (this.controlData.RTEventSource)
            this.rteventsource_func = utils.compileExpression(this, this.controlData.RTEventSource);
        
        if (this.controlData.ListSource)
            this.listNameExpn = utils.compileObject(this, this.controlData.ListSource);

        if (this.controlData.Filters)
            this.filtersexpn = utils.compileObject(this, this.controlData.Filters);

        if (this.controlData.SelectOptions && this.controlData.SelectOptions.Enable && this.controlData.SelectOptions.SelectableExpression)
            this.selectableexpn = utils.compileExpression(this, this.controlData.SelectOptions.SelectableExpression, 'row');

        this.dense = !!this.controlData.Dense;

        if (this.controlData.SelectOptions && this.controlData.SelectOptions.Enable && this.controlData.SelectOptions.SelectableKey)
            this.selectableKey = this.controlData.SelectOptions.SelectableKey

        if(this.controlData.DefaultRowsPerPage)
            this.defaultRowsPerPage = this.controlData.DefaultRowsPerPage;
    },
    //Mounted Replaced with preRenderComplete
    destroyed() {
        if (this.datawatch$)
            this.datawatch$();

        if (this.filters_watch$)
            this.filters_watch$();

        if (this.viewname && this.id)
            this.rtEvents.RemoveNamedListForRealTime(this.viewname, this.id);
    },  
    computed: {
        headers: function () {
            // Generate a list of headers that are physically in the grid - invisible columns are excluded
            let headers = this.generateHeaders();

            return [
                ...headers.filter(h => this.column_visibility[h.value]),
                // {
                //     value: '_menu_',
                //     text: '',
                //     align: 'end',
                //     sortable: false,
                // }
            ];
        },
        filters: function () {
            return this.filtersexpn ? utils.evaluateObject(this.filtersexpn, this) : null;
        },
        listName: function() {
            return this.listNameExpn ? utils.evaluateObject(this.listNameExpn, this) : null;
        },
        serverProperties: function () {
            // Generate a list of columns to send to the web server to be included in the server push
            const columns = this.generateHeaders().filter(a => a.value && a.value != '_menu_').map(a => a.value);
            var serverProperties = columns;
            if(this.controlData.AdditionalProperties) {
                const additionalProperties = this.controlData.AdditionalProperties.filter(a => a).map(b => b.trim());
                serverProperties = _.uniq([...columns,...additionalProperties]);
            }

            return serverProperties;
        },
        footer: function () {
            return {
                itemsPerPageOptions: Array.from({length: 30}, (_, i) => i + 1) //initialize 1 to 30
            };
        },
        showSelect: function () {
            return this.controlData.SelectOptions.Enable;
        },
        singleSelect: function () {
            return !this.controlData.SelectOptions.MultiSelect;
        },
        menuContent: function () {
            // Return a popup menu that lists all available columns with a check box to turn on and off each

            const h = this.$createElement;

            const menus = [];

            if (this.controlData.ColumnDefs && this.controlData.ColumnDefs.length)
                for (let c of this.controlData.ColumnDefs.filter(cd => cd.Field)) {
                    menus.push(
                        <v-list-item key={`${c.Field}`} on-click={() => { this.menu_is_open = false; this.toggleColumnVisibility(c.Field); }}>
                            <v-list-item-icon>
                                <v-icon >{this.column_visibility[c.Field] ? 'mdi-check' : ''}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{c.DisplayName || c.Field}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    );
                }

            const scopedSlots = {
                activator: ({ on, attrs }) =>
                    <v-btn elevation={0} plain
                        {...{ on }}
                        {...{ attrs }}
                    >
                        <v-icon>
                            mdi-menu
                        </v-icon>
                    </v-btn>
            };

            return (
                <v-menu
                    offset-y
                    bottom left
                    close-on-click={true}
                    close-on-content-click={false}
                    scopedSlots={scopedSlots}
                    value={this.menu_is_open}
                    on-input={(state) => this.menu_is_open = state}
                >
                    <v-list dense style="max-height: 600px" class="overflow-y-auto">
                        <v-list-item-group>
                            {menus}
                        </v-list-item-group>
                    </v-list>
                </v-menu>
            );
        },
        rtEvents: function () {
            if (this.rteventsource_func)
                return utils.evaluate(this.rteventsource_func, this);
            else
                return null;
        },
        scopedSlots: function () {
            const h = this.$createElement;

            const slots = {
                ['top']: () => 
                    <div style="display:flex;">
                        { this.controlData.EnableFilter ?
                            <v-text-field
                                style="max-width: 300px;"
                                class="mb-1"
                                dense hide-details
                                clear-icon="mdi-close-circle"
                                clearable
                                placeholder="Quick Search"
                                prepend-inner-icon="mdi-magnify"
                                value={this.searchText}
                                on-input={(value) => this.updateSearchFilter(value)}
                            ></v-text-field> : 
                            <div></div>
                        } 
                        <v-spacer/>
                        {this.menuContent}
                    </div>,
            };


            if (this.controlData.ColumnDefs && this.controlData.ColumnDefs.length)
                for (let cd of this.controlData.ColumnDefs) {
                    const name = `item.${cd.Field}`;
                    
                    let field_extractor = cd.field_extractor;
                    if (!field_extractor)
                        field_extractor = new Function('f', `return f.${cd.Field};`);

                    if (cd.Condition && !cd.condition_expn)
                        cd.condition_expn = utils.compileExpression(this, cd.Condition, [cd.RowModelName || 'row', cd.ItemModelName || 'item']);

                    if(cd.ActionOnlyColumn) {
                        if (cd.condition_expn)
                            slots[name] = (node) => utils.evaluate(cd.condition_expn, this, false, null, false, [node.item, field_extractor(node.item)], true) ? this.actionButtons(node, cd) : '';
                        else
                            slots[name] = (node) => this.actionButtons(node, cd);
                    } else {
                        let dataType;
                        
                        if (cd.DataType && cd.DataType != 'default')
                            dataType = cd.DataType;
        
                        let valueGetter;
                        if (cd.Value && !cd.value_expn)
                            cd.value_expn = utils.compile(this, cd.Value, false, [cd.RowModelName || 'row', cd.ItemModelName || 'item']);
        
                        if (cd.condition_expn && cd.value_expn)
                            valueGetter = (node) => utils.evaluate(cd.condition_expn, this, false, null, false, [node.item, field_extractor(node.item)], true) ? utils.evaluate(cd.value_expn, this, false, null, false, [node.item, field_extractor(node.item)], true) : '';
                        else if (cd.value_expn)
                            valueGetter = (node) => utils.evaluate(cd.value_expn, this, false, null, false, [node.item, field_extractor(node.item)], true);
                    
                        let valueFormatter;
                        if (cd.CellFilter && !cd.cellfilter_expn) {
                            const filter = `{{ row.${cd.Field} | ${cd.CellFilter} }}`;
                            cd.cellfilter_expn = utils.compile(this, filter, false, ['row', cd.ItemModelName || 'item']);
                        }
                        if (cd.cellfilter_expn && cd.condition_expn)
                            valueFormatter = (node) => utils.evaluate(cd.condition_expn, this, false, null, false, [node.item, field_extractor(node.item)], true) ? utils.evaluate(cd.cellfilter_expn, this, false, null, false, [node.item, field_extractor(node.item)], true) : '';
                        else if (cd.cellfilter_expn && !cd.condition_expn)
                            valueFormatter = (node) => utils.evaluate(cd.cellfilter_expn, this, false, null, false, [node.item, field_extractor(node.item)], true);
                        else if (cd.condition_expn && !valueGetter)
                            valueFormatter = (node) => utils.evaluate(cd.condition_expn, this, false, null, false, [node.item, field_extractor(node.item)], true) ? field_extractor(node.item) : '';
        
                        if (!valueGetter && !valueFormatter) {
                            const code = `return typeof data === 'object' ? JSON.stringify(data) : data;`;
                            const func = new Function('data', code);
                            valueFormatter = (node) => {
                                try { return func(field_extractor(node.item)); } catch (e) {
                                    utils.warn(`Grid cell failed to evaluate ${code}`, e); return '';
                                }
                            };
                        }
                        
                        if (valueFormatter)
                            slots[name] = valueFormatter;
        
                        if (valueGetter)
                            slots[name] = valueGetter;
                    }
                }

            if (!Object.keys(slots).length)
                return undefined;

            return slots;
        },

        //-- Public Properties --//

        SelectedRowsAsArray: {
            get: function () {
                return this.selected_items;
            },
            set: function (value) {
                this.selected_items = value;
            }
        },
    },
    methods: {
        preRenderComplete() {
            this.finishRenderHandler(this);

            if (!this.GlobalVars.RTData.Views[this.viewname])
                Vue.set(this.GlobalVars.RTData.Views, this.viewname, { Total: 0, Data: [] });

            this.datawatch$ = this.$watch(
                function () {
                    return this.GlobalVars.RTData.Views[this.viewname];
                },
                function (newv, oldv) {
                    this.rowdata = this.GlobalVars.RTData.Views[this.viewname].Data;
                    this.totalRows = this.GlobalVars.RTData.Views[this.viewname].Total;
                }
            );

            if (!this.filters_watch$)
                this.filters_watch$ = this.$watch(
                    function (newvalue) {
                        return this.filters;
                    },
                    function (val, oldval) {
                        this.getDataFromApi();
                    },
                    {
                        deep: true
                    }
                );

            if (!this.listname_watch$)
                this.listname_watch$ = this.$watch(
                    function (newvalue) {
                        return this.listName;
                    },
                    function (val, oldval) {
                        this.getDataFromApi();
                    },
                    {
                        deep: true
                    }
                );
        },
        updateSearchFilter(filter) {
            this.searchText = filter;

            this.getDataFromApi();
        },
        async getDataFromApi() {
            this.loading = true;

            // request data

            let res;
            let filter = this.filters;
            
            if(!filter)
                filter = {};

            if(this.searchText) {
                filter["_any_"] = this.searchText;
            }

            

            try {
                res = await this.rtEvents.GetNamedListForRealTime(
                    this.listName, this.sortCol, this.descending, this.start, this.length, this.serverProperties, filter, this.id);

            }
            catch (error) {
                this.loading = false;
                return;
            }

            let viewname = this.viewname;
            if (res && res.Response) {
                viewname = res.ViewHash;
                this.id = res.ID;
                const rawdata = res.Response;
                Vue.set(this.GlobalVars.RTData.Views, viewname,
                    {
                        Total: rawdata.Total,
                        Data: rawdata.Data,
                    });

                this.rowdata = rawdata.Data;
                this.totalRows = rawdata.Total;

                if (res.ViewHash !== this.viewname && this.viewname) {
                    // No need to remove the old view, subscribing to a new view updates the old view and we don't have to clear it
                    // this.rtEvents.RemoveNamedListForRealTime(this.viewname, this.id);
                }
                this.viewname = viewname;
                this.loading = false;
                return;
            }

            if (!this.GlobalVars.RTData.Views[viewname])
                Vue.set(this.GlobalVars.RTData.Views, viewname, { Total: 0, Data: [] });

            this.rowdata = this.GlobalVars.RTData.Views[viewname].Data;
            this.totalRows = this.GlobalVars.RTData.Views[viewname].Total;

            this.viewname = viewname;
            this.loading = false;
        },
        updateOptions(args) {
            // Ex: {"page":1,"itemsPerPage":10,"sortBy":["FullName"],"sortDesc":[true],"groupBy":[],"groupDesc":[],"mustSort":false,"multiSort":false}
            utils.debug('RealTimeGrid.updateOptions: ' + JSON.stringify(args));

            this.sortCol = args.sortBy.length > 0 ? args.sortBy[0] : null;
            this.descending = args.sortDesc.length > 0 ? args.sortDesc[0] : false;
            this.start = (args.page - 1) * args.itemsPerPage;
            this.length = args.itemsPerPage;

            this.options = args;
            this.getDataFromApi();

            // Save only the options we care to save
            const tosave = { itemsPerPage: args.itemsPerPage, sortBy: args.sortBy, sortDesc: args.sortDesc };

            this.System.LocalStorage(this.gridName + '.Options', JSON.stringify(tosave));
        },
        toggleColumnVisibility(fieldName) {
            if (this.column_visibility[fieldName])
                Vue.set(this.column_visibility, fieldName, false);
            else
                Vue.set(this.column_visibility, fieldName, true);

            this.System.LocalStorage(this.gridName + '.ColumnSettings', JSON.stringify(this.column_visibility));

            this.getDataFromApi();
        },
        clickItem(e, column, row, value) {
            utils.executeAndCompileAllActions(column.Advanced.Actions, { Data: row, Value: value, Column: column }, this);
        },
        selectedItemsChanged(items) {
            this.selected_items = items;
        },
        actionButtons(item, cd) {
            let buttons = [];
                        
            for(let cb of cd.CommandButtons) {
                buttons.push(
                    <CellButton
                        btn={cb}
                        cd={cd}
                        c_context={this}
                        rowData={item}
                    >
                    </CellButton>
                )
            }
            return buttons;
        },
        generateHeaders() {
            const headers = [];

            if (this.controlData.ColumnDefs)
                for (let c of this.controlData.ColumnDefs) {
                    let cdef = {
                        value: c.Field,
                        text: c.DisplayName || c.Field,
                        align: 'start',
                        sortable: !c.ActionOnlyColumn,
                    };
                    if (c.Align !== undefined)
                        cdef.align = c.Align;
                    if (c.Sortable !== undefined)
                        cdef.sortable = c.Sortable;

                    headers.push(cdef);
                }

            return headers;
        },

        //-- Public Methods --//

        ClearSelection() {
            this.selected_items = [];
        },
    },
    props: {
    },
    render(h) {
        if (!this.todisplay)
            return null;

        const style = {
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            padding: '8px',
            ...this.sizeStyle,
        };

        this.rowdata.forEach((r) => {
            if (this.selectableexpn)
                r.isSelectable = utils.evaluate(this.selectableexpn, this, false, null, false, r);
        })

        const grid = (
            <div
                v-show={this.isvisible}
                class={{ 'c-RealTimeGrid': true, [`c-name-${this.name || 'unnamed'}`]: true, 'ag-theme-alpine': true }}
                style={style}
            >
                <v-data-table
                    style="display:flex; flex-direction:column; height:100%;"
                    headers={this.headers}
                    footer-props={this.footer}
                    items={this.rowdata}
                    options={this.options} {...{ on: { 'update:options': this.updateOptions } }}
                    server-items-length={this.totalRows}
                    loading={this.loading}
                    items-per-page={this.defaultRowsPerPage}
                    dense={this.controlData.Dense}
                    scopedSlots={this.scopedSlots}
                    show-select={this.showSelect}
                    single-select={this.singleSelect}
                    selectable-key="isSelectable"
                    item-key={this.selectableKey}
                    on-input={(items) => this.selectedItemsChanged(items)}
                    value={this.selected_items}
                    calculate-widths
                    fixed-header={true}
                >
                </v-data-table>
            </div>
        );

        return grid;
    }
});