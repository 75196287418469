import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import EventBus from '../event-bus.js';

import utils from '../../Shared/utils.jsx';
import methods from '../../Shared/methods';

Vue.component('basic-toggle', {
    mixins: [BaseComponent],
    data: function () {
        return {
            defaultEval: null,
            colorEval: null,
            tooltipEval: null,
            tooltipLocationEval: null,
            classEval: null,
            value: false
        }
    },
    props: {},
    created() {
        this.value = (utils.evaluate(utils.compile(this, this.controlData.Default), this) == 'true');
    },
    //Mounted Replaced with preRenderComplete
    computed: {
        ToggleState() {
            return this.value;
        },
        label() {
            if(this.value) 
                return this.controlData.OnLabel ? this.controlData.OnLabel : ''
            else
                return this.controlData.OffLabel ? this.controlData.OffLabel : ''
        },
        color() {
            try {
                let theColor = 'primary';
                if(this.colorEval === null && this.controlData.OnColor)
                    this.colorEval = utils.compile(this, this.controlData.OnColor);
                if(this.colorEval)
                    theColor = utils.evaluate(this.colorEval, this);
                
                theColor = theColor || 'primary'
                
                // This helps with backwards compatibility for a version of Bootstrap that used the word 'default' to describe the secondary button
                if(theColor === 'default')
                    return 'secondary';
                else if(theColor === 'danger')
                    return 'error';
                return theColor;
            }
            catch (e) {
                utils.warn('Color could not evaluate expression: ' + this.controlData.OnColor + '; ' + e);
                return 'primary';
            }
        },
        tooltip() {
            try {
                if(this.tooltipEval === null && this.controlData.Tooltip)
                    this.tooltipEval = utils.compile(this, this.Translate(this.controlData.Tooltip));
                if(this.tooltipEval)
                    return utils.evaluate(this.tooltipEval, this);
                return '';
            }
            catch (e) {
                utils.warn('Tooltip could not evaluate expression: ' + this.controlData.Tooltip + '; ' + e);
                return '';
            }
        },
        tooltipLocation() {
            try {
                if(this.tooltipLocationEval === null && this.controlData.TooltipLocation)
                    this.tooltipLocationEval = utils.compile(this, this.Translate(this.controlData.TooltipLocation));
                if(this.tooltipLocationEval)
                    return utils.evaluate(this.tooltipLocationEval, this).split('-')[0].toLowerCase() || 'top';
                return 'top';
            }
            catch (e) {
                utils.warn('Tooltiplocation could not evaluate expression: ' + this.controlData.TooltipLocation + '; ' + e);
                return 'top';
            }
        },
        class() {
            try {
                if(this.classEval === null && this.controlData.Class)
                    this.classEval = utils.compile(this, this.Translate(this.controlData.Class));
                if(this.classEval)
                    return utils.evaluate(this.classEval, this).split('-')[0].toLowerCase() || 'top';
                return '';
            }
            catch (e) {
                utils.warn('Class could not evaluate expression: ' + this.controlData.Class + '; ' + e);
                return '';
            }
        }
    },
    methods: {
        async onChange(value) {
            await utils.executeAndCompileAllActions(this.controlData.Actions, { Value: value }, this);
        },
        preRenderComplete() {
            this.finishRenderHandler(this);
        },
        SetToggleState(value) {
            this.value = value;
        }
    },
    render(h) {
        if(!this.todisplay)
            return null;

        try {

            let content = (
                <v-switch
                    inset
                    dense
                    hide-details="auto"
                    vModel={this.value}
                    on-change={this.onChange}
                    color={this.color}
                    class={{[this.class]: true, 'ma-1': true}}
                >
                    <translation-container slot="label" context={this} value={this.label}></translation-container>
                </v-switch>
            );

            let finalContent = utils.generateTooltip(h, content, this.tooltip, this.tooltipLocation);

            return (
                <div class={{ 'c-BasicToggle': true, [`c-name-${this.name || 'unnamed'}`]: true }}
                    v-show={this.isvisible} style={this.sizeStyle}>
                    {finalContent}
                </div>
            );
        }
        catch(e) {
            utils.error('BasicToggle Render failed', e);
            return <div>BasicToggle Failed to Render {e}</div>;
        }
        
    }
});