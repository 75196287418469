import Vue from 'vue';
import utils from '../../Shared/utils.jsx';
import BaseStackMixin from './BaseStackMixin.jsx';
import VerticalStackContent from './VerticalStackContent.jsx';
import EventBus from '../event-bus.js';
import methods from '../../Shared/methods';
import computed from '../../Shared/computed';
import careHelpfulFunctions from '../../Shared/careHelpfulFunctions.jsx';
const cloneDeep = require('lodash.clonedeep')

Vue.component('stack-vertical', {
    data: function () {
        return {
            items_id: '_0',
            displayexpr: null,
            visibleexpr: null,
            container_list: [],
            display_list: [],
            isFullScreen: false,
            renderCount: 0,
            finishedRender: false,
            finishedRenderChildren: [],
            is_vstack_parent: true,
        }
    },
    async created() {
        if (this.isdebug) {
            utils.document_cache.loadInProgress[this.Location] = new Date().toLocaleTimeString();
            //utils.debug(`${this.LocationBrief} CREATE`);
        }

        if (this.controlData.DisplayExpression)
            this.displayexpr = utils.compileExpression(this, this.controlData.DisplayExpression);

        if (this.controlData.VisibleExpression)
            this.visibleexpr = utils.compileExpression(this, this.controlData.VisibleExpression);

        if (this.controlData.BreadCrumb && this.controlData.BreadCrumb.Text) {
            if (!this.breadcrumbtext)
                this.breadcrumbtext = utils.compile(this, this.controlData.BreadCrumb.Text);
        }

        if (this.controlData && this.controlData.SizeOptions)
            this.sizeOptionsObj = utils.compileObject(this, this.controlData.SizeOptions);

        // Subscribe to the UIAction_FullScreen event
        if (this.fullScreenCapable) {
            this.$on('Action-FullScreen', this.performFullScreen);

            if (this.controlData.Name) {
                this.$on(`Action-FullScreen:${this.controlData.Name}`, this.performFullScreen);
                EventBus.$on(`Action-FullScreen:${this.controlData.Name}`, this.performFullScreen);
            }
        }

        if (this.asbase || this.controlData.IsBase) {
            // Subscribe to a UIAction_ReplaceContent event
            this.$on('Action-ReplaceContent', this.performReplaceContent);
            this.$on('Action-Refresh', this.performRefresh);
        }

        // Subscribe by name so Target can be directed to a specific container
        if (this.controlData.Name) {
            this.$on(`Action-ReplaceContent:${this.controlData.Name}`, this.performReplaceContent);
            this.$on(`Action-Refresh:${this.controlData.Name}`, this.performRefresh);
            EventBus.$on(`Action-ReplaceContent:${this.controlData.Name}`, this.performReplaceContent);
        }
    },
    computed: {
        ...computed,
        todisplay: function () {
            let displayExpn = this.displayexpr;
            if (this.container_list.length > 0)
                displayExpn = this.display_list[this.display_list.length - 1];

            if (displayExpn) {
                const res = utils.evaluate(displayExpn, this, false,
                    () => {
                        // This is a callback that will only be called if evaluate throws an exception.
                        // If this happens, we read the todisplayfield (so Vue knows we care) and then
                        // after 100ms, we update todisplayfailed to cause Vue to re-evaluate todisplay.
                        // (cap the retry at 10 times with increasing delay each attempt)
                        if (this.todisplayfailed >= 0 && this.todisplayfailed < 10)
                            setTimeout(() => this.todisplayfailed++, 50 * this.todisplayfailed);
                    });

                //utils.debug(`DisplayExpression for ${this.name} ${this.type} evaluated to ${res ? 'true' : 'false'} ('${this.controlData.DisplayExpression}')`);
                return res;
            }
            else
                return true;
        },
        isvisible: function () {
            if (this.visibleexpr) {

                const res = utils.evaluate(this.visibleexpr, this, false,
                    () => {
                        // This is a callback that will only be called if evaluate throws an exception.
                        // If this happens, we read the todisplayfield (so Vue knows we care) and then
                        // after 100ms, we update todisplayfailed to cause Vue to re-evaluate todisplay.
                        // (cap the retry at 10 times with increasing delay each attempt)
                        if (this.todisplayfailed >= 0 && this.todisplayfailed < 10)
                            setTimeout(() => this.todisplayfailed++, 50 * this.todisplayfailed);
                    });

                return res;
            }
            else
                return true;
        },
        verticalAlignChildren() {
            if (!this.verticalAlignChildrenEval && this.controlData.VerticalAlignChildren)
                this.verticalAlignChildrenEval = utils.compile(this, this.controlData.VerticalAlignChildren);
            if (this.verticalAlignChildrenEval)
                return utils.evaluate(this.verticalAlignChildrenEval, this);
            return '';
        },
        horizontalAlignChildren() {
            if (!this.horizontalAlignChildrenEval && this.controlData.HorizontalAlignChildren)
                this.horizontalAlignChildrenEval = utils.compile(this, this.controlData.HorizontalAlignChildren);
            if (this.horizontalAlignChildrenEval)
                return utils.evaluate(this.horizontalAlignChildrenEval, this);
            return '';
        },
        breadCrumbText: function () {
            if (this.breadcrumbtext)
                return utils.evaluate(this.breadcrumbtext, this);
            else
                return null;
        },
        totalItems() {
            return 0;
        },
        fullScreenCapable() {
            if (!this.fullScreenCapableEval && this.controlData.FullScreenCapable !== undefined)
                this.fullScreenCapableEval = utils.compile(this, this.controlData.FullScreenCapable !== undefined);
            if (this.fullScreenCapableEval)
                return utils.evaluate(this.fullScreenCapableEval, this);
            return '';
        },
        name: function () {
            return this.controlData.Name + '__parent';
        },

        style() {
            let controlData = this.controlData;

            // Ignore the size for replace content (no size field is available, so just use the original size)
            //if (this.container_list.length > 0) {
            //    controlData = this.container_list[this.container_list.length - 1];
            //}

            let sizeOptions;
            if (controlData.SizeOptions) {
                const sizeOptionsObj = utils.compileObject(this, controlData.SizeOptions);
                sizeOptions = utils.evaluateObject(sizeOptionsObj, this);
            }

            const style = {
                overflow: 'auto',
                display: "flex",
                flexDirection: "column",
                ...utils.resolveStyleHints(this.styleHints, this),
                width: this.isFullScreen ? '100%' : '', // Support full screen
                ...utils.getSize(sizeOptions, this.parentType, this.$parent),
            };
            if ((this.asbase || this.controlData.IsBase) && this.showbase) {
                style.border = "solid 1px blue";
                style.margin = "2px";
                style.padding = "2px";
            }

            return style;
        },

        Input: function () {
            if ('input' in this.$attrs)
                return this.$attrs.input;
            else
                return null;
        },
    },
    destroyed() {
        // Unsubscribe from the UIAction_FullScreen event
        if (this.fullScreenCapable) {
            this.$off('Action-FullScreen', this.performFullScreen);

            if (this.controlData.Name) {
                this.$off(`Action-FullScreen:${this.controlData.Name}`, this.performFullScreen);
                EventBus.$off(`Action-FullScreen:${this.controlData.Name}`, this.performFullScreen);
            }
        }

        this.$off('Action-ReplaceContent', this.performReplaceContent);
        this.$off('Action-Refresh', this.performRefresh);

        // Subscribe by name so Target can be directed to a specific container
        if (this.controlData.Name) {
            this.$off(`Action-ReplaceContent:${this.controlData.Name}`, this.performReplaceContent);
            this.$off(`Action-Refresh:${this.controlData.Name}`, this.performRefresh);
            EventBus.$off(`Action-ReplaceContent:${this.controlData.Name}`, this.performReplaceContent);
        }

    },
    mounted() {
        if (!this.todisplay)
            this.renderComplete();
    },
    methods: {
        ...methods,
        async performReplaceContent(action) {
            utils.log(`${this.type} ${this.controlData.Name} ReplaceContent`);

            if (action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            try {
                const actionControlData = cloneDeep(action.controlData);

                actionControlData.id = utils.generateUUID();
                actionControlData.$nointerp = true;

                //action.vars = utils.evaluateObject(action.varsexpn, action.context);
                if (action.breadcrumbtextexpn) {
                    actionControlData.breadcrumbtext = utils.evaluate(action.breadcrumbtextexpn, action.context);
                }

                actionControlData.Vars = utils.evaluateObject(action.varsexpn, action.context);

                let displayexpn = null;
                if (actionControlData.DisplayExpression)
                    displayexpn = utils.compileExpression(this, actionControlData.DisplayExpression);
                
                if (action.ActionData.ControlData.BreadCrumb && action.ActionData.ControlData.BreadCrumb.Action == 'Append') {
                    this.container_list.push(actionControlData);
                    this.display_list.push(displayexpn);
                }
                else {
                    this.container_list = [actionControlData];
                    this.display_list = [displayexpn];
                }

                try {
                    await utils.success(action);
                } catch (e) { }
            }
            catch (e) {
                utils.warn(`VerticalStack_parent ${this.name} ReplaceContent failed`, e);

                try {
                    await utils.failure(action);
                } catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
        async performRefresh(action) {
            utils.log(`${this.type} ${this.controlData.Name} Refresh`);

            if (action.ActionData?.Debug && action.ActionData?.Debug.BreakPoint) debugger;

            try {
                // Changing the key for the user control causes Vue to reload
                this.items_id = utils.generateUUID();
                
                try {
                    await utils.success(action);
                } catch (e) { }
            }
            catch (e) {
                try {
                    await utils.failure(action);
                } catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
        performFullScreen(action) {
            try {
                utils.log(`ActionService FullScreen ${action.ActionData.Title} ${action.ActionData.Body}`);

                if (action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

                this.$el.webkitRequestFullScreen();
                this.isFullScreen = true;

                utils.success(action);
            }
            catch (e) {
                utils.failure(action, { Status: e.status, StatusText: e.response.statusText });
            }
            finally {
                utils.complete(action);

                action.FinishFunc(true);
            }
        },
        navToBreadCrumb(e, index) {
            if (index < 0)
                this.container_list.splice(0, this.container_list.length);
            else
                this.container_list.splice(index + 1, this.container_list.length - index);
        },
        generateBreadCrumbs(h) {
            if (this.controlData.BreadCrumb && this.controlData.BreadCrumb.Text) {
                const crumbs = [];
                
                crumbs.push(
                    <v-btn elevation={0} text small
                        class="text-capitalize pl-2 pr-2"
                        style={{ color: this.container_list.length === 0 ? 'gray !important' : 'black !important' }}
                        disabled={this.container_list.length === 0}
                        on-click={(e) => this.navToBreadCrumb(e, -1)}>{this.breadCrumbText}</v-btn>
                );
                crumbs.push(<v-icon x-small>mdi mdi-chevron-right</v-icon>);

                for (let i = 0; i < this.container_list.length; i++) {
                    const c = this.container_list[i];
                    if (c.BreadCrumb && c.BreadCrumb.Action == 'Append' && c.breadcrumbtext) {
                        const bctext = c.breadcrumbtext;

                        crumbs.push(
                            <v-btn elevation={0} text small
                                class="text-capitalize pl-2 pr-2"
                                style={{ color: i === this.container_list.length - 1 ? 'gray !important' : 'black !important' }}
                                disabled={i === this.container_list.length - 1}
                                on-click={(e) => this.navToBreadCrumb(e, i)}>{bctext}</v-btn>
                        );
                        crumbs.push(<v-icon x-small>mdi mdi-chevron-right</v-icon>);
                    }
                }
                
                return (<div style="display: flex; flex-direction: row;">{crumbs}</div>);
            }
            else
                return null;
        },
        finishRenderHandler(component) {
            if (this.finishedRender)
                return;
            this.renderCount++;
            this.finishedRenderChildren.push(component);

            if (this.renderCount >= this.totalItems) {
                this.renderComplete();
            }
        },
        async renderComplete() {
            this.finishedRender = true;
            if (this.isdebug) {
                //utils.debug(`${this.LocationBrief} FINISH *`);
                delete utils.document_cache.loadInProgress[this.Location];
            }
            this.$emit('finished-render', this);
        },
    },
    props: {
        root: null,
        parentType : null,
        controlData: null,
        sourceIndex: null,
        sourceData: null,
        type: null,
        asbase: false,
        debug: null,
        scopeitems: null,
        controlscope: null,
    },
    render(h) {
        try {
            // This component contains the ReplaceContent action listener and then
            // just acts as a simple wrapper for stack-vertical-content where the
            // content lives.

            if (!this.todisplay)
                return null;

            let items_id = this.items_id;
    
            let controlData = this.controlData;
    
            if (this.container_list.length > 0) {
                controlData = this.container_list[this.container_list.length - 1];
                items_id = `_${this.items_id}_${controlData.id || this.container_list.length}`;
            }
    
            const breadcrumb = this.generateBreadCrumbs(h);

            return (
                <div
                    class={{ 
                        [`c-${this.type}`]: true, 
                        [`c-name-${this.name || 'unnamed'}`]: true}}
                    style={this.style}
                    v-show={this.isvisible}>
                        
                    {breadcrumb}
                    <transition appear name="component-fade" mode={document.hasFocus() ? 'out-in' : null}>
                        <stack-vertical-content
                            key={items_id}
                            on={{ 'finished-render': (reference) => this.finishRenderHandler(reference) }}
                            type={this.type}
                            name={controlData.Name}
                            root={this.root}
                            parentType={this.parentType}
                            controlData={controlData}
                            sourceIndex={this.sourceIndex}
                            sourceData={this.sourceData}
                            asbase={this.asbase}
                            debug={this.debug}
                            scopeitems={this.scopeitems}
                            controlscope={this.controlscope}
                            on-grid-exported-text={(val) => this.$emit("grid-exported-text", val)}
                            input={this.Input}
                        >
                        </stack-vertical-content>
                    </transition>
                </div>
            );
        }
        catch (e) {
            utils.error('VerticalLayout Render failed', e);
            return <div>VerticalLayout Failed to Render {e}</div>;
        }
    }
});