import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

Vue.component('form-select', {
    data: function () {
        return {
            condition_expn: null
        }
    },
    created() {
    },
    computed: {
        ...utils.forms.computed,
    },
    methods: {
        ...methods,
        sync(prop, value) {
            if (Array.isArray(this.cmodel))
            {
                if (prop < this.cmodel.length)
                    Vue.set(this.cmodel, prop, value);
                else {
                    while (this.cmodel.length < prop)
                        this.cmodel.push(null);

                    this.cmodel.push(value);
                }
            }
            else
            {
                if (!(prop in this.cmodel))
                    Vue.set(this.cmodel, prop, value);
                else
                    this.cmodel[prop] = value
            }
        }
    },
    props: {
        name: '',
        schemakey: '',
        root: null,
        schema: null,
        cmodel: null,
        extra: null,
        readonly: false,
        child: 0,
        propertygrid: false,
    },
    render(h) {
        if (!this.Condition)
            return null;

        let desc;
        if (this.schema.description)
            desc = <i class="mdi mdi-information" title={this.schema.description} style={{ color: "silver", fontSize: "small", marginLeft: "3px" }}></i>;

        const name = <span style={{ marginLeft: (this.child * 15) + "px" }}>{this.name}</span>;
        if (this.readonly)
            return (
                <tr>
                    <td style={{ width: "1px" }}>
                        <span style={{ whiteSpace: "nowrap" }}>{name} {desc}</span>
                    </td>
                    <td class={{ "property-grid": this.propertygrid }} style={{ whiteSpace: "nowrap" }}>
                        <v-combobox solo hide-details class="pa-0 ma-0" disabled value={this.cmodel[this.schemakey]} items={schema.enum || schema.titleMap}>
                        </v-combobox>
                    </td>
                </tr>
            );

        const schema = this.schema;

        if (schema.formatData && schema.formatData.AllowCustomInput) {
            return (
                <tr>
                    <td style={{ width: "1px" }}>
                        <span style={{ whiteSpace: "nowrap" }}>{name} {desc}</span>
                    </td>
                    <td class={{ "property-grid": this.propertygrid }} style={{ whiteSpace: "nowrap" }}>
                        <v-combobox
                            solo dense hide-details
                            return-object={false}
                            class="pa-0 ma-0"
                            value={this.cmodel[this.schemakey]}
                            items={schema.enum || schema.titleMap}
                            item-text="name"
                            on-change={(e) => this.sync(this.schemakey, e.target ? e.target.value : e)}
                            on-keyup={(e) => this.sync(this.schemakey, e.target ? e.target.value : e)}>
                        </v-combobox>
                    </td>
                </tr>
            );
        }

        const items = [];
        const enums = schema.enum || schema.titleMap;
        if (Array.isArray(enums)) {
            if (!this.cmodel[this.schemakey])
                items.push({ text: "Please select one", value: "", disabled: true });
            else if (schema.$allows_null)
                items.push({ text: "", value: null, disabled: false });

            for (var i = 0; i < enums.length; i++) {
                const item = enums[i];
                if (item && typeof item === 'object')
                {
                    items.push({ text: item.name, value: item.value, disabled: false });
                }
                else
                {
                    items.push({ text: item, value: item, disabled: false });
                }
            }
        }

        return (
            <tr>
                <td style={{ width: "1px" }}>
                    <span style={{ whiteSpace: "nowrap" }}>{name} {desc}</span>
                </td>
                <td class={{ "property-grid": this.propertygrid }} style={{ whiteSpace: "nowrap" }}>
                    <v-select
                        solo dense hide-details
                        class="pa-0 ma-0"
                        menu-props={{ offsetY: true, auto: true }}
                        value={this.cmodel[this.schemakey]}
                        items={items}
                        on-change={(value) => this.sync(this.schemakey, value)}>
                    </v-select>
                </td>
            </tr>
        );
    }
});