import Vue from 'vue';
import BaseComponent from './baseFormMixin.jsx';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

import { appSettings } from '@/Shared/appSettings.js';

Vue.component('sform-textareawithheaderbar', {
    mixins: [BaseComponent],
    data: () => ({
    }),
    props: {
    },
    created() {
    },
    mounted() {
        this.loadComplete();
    },
    computed: {
        textvalue: function () {
            if (typeof this.itemvalue === 'object')
                return JSON.stringify(this.itemvalue);
            else
                return this.itemvalue;
        }
    },
    methods: {
    },
    render() {
        let scopedSlots = {
            message: ({message}) => {
                return <translation-container context={this} value={message}></translation-container>
            }
        }

        let slots = [
            <translation-container slot="label" context={this} value={this.labelText}></translation-container>,
        ];

        const input = (
            <v-textarea
                class="caption pa-0 ma-0 scrollbar-style-1" outlined single-line dense hide-details
                style={{ width: "100%" }}
                rows={this.element.formatData.Rows || 2}
                value={this.textvalue}
                on-input={(value) => this.sync(value)}
                on-blur={this.onBlur}
                on-focus={this.onFocus}
                scopedSlots={scopedSlots}
                hint={this.hintText}
                persistent-hint={appSettings.DebugTranslationPrefixSetting}
            >{slots}</v-textarea>
        );

        if (this.appearance)
            input.componentOptions.propsData = { ...input.componentOptions.propsData, ...this.appearance };

        return (
            <v-card elevation={0} outlined class="mb-3 pb-0">
                <v-card-title class="pb-0 pt-0 pl-1">
                    <stack-vertical
                        type="HorizontalLayout"
                        name={this.name}
                        root={this.root}
                        parentType="InputWithHeaderBar"
                        controlData={this.element.formatData}
                        controlName={this.Name}
                        scopeitems={this.scopeitems}
                        controlscope={this.controlscope}
                    >
                    </stack-vertical>
                </v-card-title>
                <v-card-actions class="pb-0">
                    {input}
                </v-card-actions>
            </v-card>
        );
    }
});