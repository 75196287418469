export let FONT_SIZE_OPTIONS = [
    {
        "name": "8pt",
        "value": "8pt",
    },
    {
        "name": "9pt",
        "value": "9pt",
    },
    {
        "name": "10pt",
        "value": "10pt",
    },
    {
        "name": "11pt",
        "value": "11pt",
    },
    {
        "name": "12pt",
        "value": "12pt",
    },
    {
        "name": "14pt",
        "value": "14pt",
    },
    {
        "name": "16pt",
        "value": "16pt",
    },
    {
        "name": "18pt",
        "value": "18pt",
    },
    {
        "name": "20pt",
        "value": "20pt",
    },
    {
        "name": "22pt",
        "value": "22pt",
    },
    {
        "name": "24pt",
        "value": "24pt",
    },
    {
        "name": "26pt",
        "value": "26pt",
    },
    {
        "name": "28pt",
        "value": "28pt",
    },
    {
        "name": "36pt",
        "value": "36pt",
    },
    {
        "name": "48pt",
        "value": "48pt",
    },
    {
        "name": "72pt",
        "value": "72pt",
    }
]
export let FONT_FAMILY = [
    {
        "name": "Arial",
        "value": "Arial, sans-serif"
    },
    {
        "name": "Helvetica",
        "value": "Helvetica, sans-serif"
    },
    {
        "name": "Verdana",
        "value": "Verdana, sans-serif"
    },
    {
        "name": "Trebuchet MS",
        "value": "Trebuchet MS, sans-serif"
    },
    {
        "name": "Gill Sans",
        "value": "Gill Sans, sans-serif"
    },
    {
        "name": "Noto Sans",
        "value": "Noto Sans, sans-serif"
    },
    {
        "name": "Avantgarde",
        "value": "Avantgarde, TeX Gyre Adventor, URW Gothic L, sans-serif"
    },
    {
        "name": "Optima",
        "value": "Optima, sans-serif"
    },
    {
        "name": "Arial Narrow",
        "value": "Arial Narrow, sans-serif"
    },
    {
        "name": "Sans-serif",
        "value": "sans-serif"
    },
    {
        "name": "Times New Roman",
        "value": "Times, Times New Roman, serif"
    },
    {
        "name": "Didot",
        "value": "Didot, serif"
    },
    {
        "name": "Georgia",
        "value": "Georgia, serif"
    },
    {
        "name": "Palatino",
        "value": "Palatino, URW Palladio L, serif"
    },
    {
        "name": "Bookman",
        "value": "Bookman, URW Bookman L, serif"
    },
    {
        "name": "New Century Schoolbook",
        "value": "New Century Schoolbook, TeX Gyre Schola, serif"
    },
    {
        "name": "American Typewriter",
        "value": "American Typewriter, serif"
    },
    {
        "name": "Serif",
        "value": "serif"
    },
    {
        "name": "Andale Mono",
        "value": "Andale Mono, monospace"
    },
    {
        "name": "Courier New",
        "value": "Courier New, monospace"
    },
    {
        "name": "Courier",
        "value": "Courier, monospace"
    },
    {
        "name": "FreeMono",
        "value": "FreeMono, monospace"
    },
    {
        "name": "OCR A Std",
        "value": "OCR A Std, monospace"
    },
    {
        "name": "DejaVu Sans Mono",
        "value": "DejaVu Sans Mono, monospace"
    },
    {
        "name": "Monospace",
        "value": "monospace"
    },
    {
        "name": "Comic Sans",
        "value": "Comic Sans MS, Comic Sans, cursive"
    },
    {
        "name": "Apple Chancery",
        "value": "Apple Chancery, cursive"
    },
    {
        "name": "Bradley Hand",
        "value": "Bradley Hand, cursive"
    },
    {
        "name": "Brush Script Std",
        "value": "Brush Script MT, Brush Script Std, cursive"
    },
    {
        "name": "Snell Roundhand",
        "value": "Snell Roundhand, cursive"
    },
    {
        "name": "URW Chancery L",
        "value": "URW Chancery L, cursive"
    },
    {
        "name": "Cursive",
        "value": "cursive"
    },
    {
        "name": "Impact",
        "value": "Impact, fantasy"
    },
    {
        "name": "Luminari",
        "value": "Luminari, fantasy"
    },
    {
        "name": "Chalkduster",
        "value": "Chalkduster, fantasy"
    },
    {
        "name": "Jazz LET",
        "value": "Jazz LET, fantasy"
    },
    {
        "name": "Blippo",
        "value": "Blippo, fantasy"
    },
    {
        "name": "Stencil Std",
        "value": "Stencil Std, fantasy"
    },
    {
        "name": "Marker Felt",
        "value": "Marker Felt, fantasy"
    },
    {
        "name": "Trattatello",
        "value": "Trattatello, fantasy"
    },
    {
        "name": "Fantasy",
        "value": "fantasy"
    }
]

export let HEADING_STYLES = [
    {
        "name": "Normal text",
        "value": "Normal text"
    },
    {
        "name": "Heading 1",
        "value": "Heading 1"
    },
    {
        "name": "Heading 2",
        "value": "Heading 2"
    },
    {
        "name": "Heading 3",
        "value": "Heading 3"
    },
    {
        "name": "Heading 4",
        "value": "Heading 4"
    },
    {
        "name": "Heading 5",
        "value": "Heading 5"
    },
    {
        "name": "Heading 6",
        "value": "Heading 6"
    }    
]
