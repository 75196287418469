import Vue from 'vue';
import BaseComponent from './baseFormMixin.jsx';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

Vue.component('sform-help', {
    mixins: [BaseComponent],
    data: () => ({
        titleexpr: null,
        bodyexpr: null,
        colorexpr: null,
    }),
    props: {
    },
    created() {
        // if (this.element.title)
        //     this.titleexpr = utils.compile(this, this.Translate(this.element.title));

        // if (this.element.body)
        //     this.bodyexpr = utils.compile(this, this.Translate(this.element.body));
    },
    mounted() {
        this.loadComplete();
    },
    computed: {
        title() {
            if(this.element.title)
                return <translation-container context={this} value={this.element.title}></translation-container>;
            else if (this.element.titleExpression) {
                if (!this.titleexpr)
                    this.titleexpr = utils.compileExpression(this, this.element.titleExpression);

                return utils.evaluate(this.titleexpr, this);
            }

            return null;
        },
        body() {
            if (this.element.body)
                return <translation-container context={this} value={this.element.body} isPossibleHtml={true}></translation-container>;
            else if (this.element.bodyExpression) {
                if (!this.bodyexpr)
                    this.bodyexpr = utils.compileExpression(this, this.element.bodyExpression);

                return utils.evaluate(this.bodyexpr, this);
            }

            return null;
        },
        color() {
            if (this.element.color)
                return this.element.color;
            else if (this.element.colorExpression) {
                if (!this.colorexpr)
                    this.colorexpr = utils.compileExpression(this, this.element.colorExpression);

                return utils.evaluate(this.colorexpr, this);
            }

            return null;
        },
    },
    methods: {
    },
    render() {
        let type = this.color;
        let textColor = this.color;
        let icon = 'mdi mdi-information';

        if (type == 'info') {
            textColor = 'primary';
            icon = 'mdi mdi-information';
        }
        else if (type == 'warning') {
            textColor = 'outbound';
            icon = 'mdi mdi-alert'
        }

        let title;
        let body;

        if (this.title && this.body) {
            title = <v-card-title style={{ padding: 0, color: textColor }}>{this.title}</v-card-title>;
            body = <v-card-text style={{ padding: 0, color: textColor }}>{this.body}</v-card-text>;
        }
        else if (this.title)
            title = <v-card-text style={{ padding: 0, color: textColor }}>{this.title}</v-card-text>;
        else if (this.body)
            body = <v-card-text style={{ padding: 0, color: textColor }}>{this.body}</v-card-text>;

        return (
            <v-alert dark text color={textColor} class="mb-3">
                <template slot="prepend">
                    <v-icon color={textColor} class="mr-4">
                        {icon}
                    </v-icon>
                </template>
                {title}
                {body}
            </v-alert>
        );
    }
});