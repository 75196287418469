import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

Vue.component('referenced-document-schema', {
    data: function () {
        return {
            condition_expn: null,
            control_schema: null,
        }
    },
    created() {
        switch (this.schema.formatData.DocumentType)
        {
            case 'UserControl':
                this.watcher$ = this.$watch('cmodel.ControlURL', function (val, oldval) { this.Refresh(); });
                break;

            case 'Action':
                this.watcher$ = this.$watch('cmodel.ActionURL', function (val, oldval) { this.Refresh(); });
                break;
        }
    },
    destroyed() {
        if (this.watcher$)
            this.watcher$();
    },
    async mounted() {
        this.Refresh();
    },
    computed: {
        ...utils.forms.computed,
    },
    methods: {
        ...methods,
        async Refresh() {
            const doctype = this.schema.formatData.DocumentType; // UserControl or UserAction

            switch (doctype) {
                case 'UserControl':
                    let doc1 = await utils.api.get('Apps/UIWhole/' + doctype + '/' + this.cmodel.ControlURL);
                    if (!doc1)
                        doc1 = await utils.api.get('Apps/UIWhole/' + doctype + '/public/' + this.cmodel.ControlURL);
                    if (doc1)
                        this.control_schema = await utils.schema.resolve(doc1.ControlDataSchema);
                    break;

                case 'Action':
                    const doc2 = await utils.api.get('Apps/UIWhole/' + doctype + '/' + this.cmodel.ActionURL);
                    if (!doc2)
                        doc2 = await utils.api.get('Apps/UIWhole/' + doctype + '/public/' + this.cmodel.ActionURL);
                    if (doc2)
                        this.control_schema = await utils.schema.resolve(doc2.ActionDataSchema);
                    break;

                case 'Schema':
                    const property = this.cmodel[this.schema.formatData.Property];
                    utils.log('Property: ' + property);
                    break;

                default:
                    return;
            }
        }
    },
    props: {
        name: '',
        schemakey: '',
        root: null,
        schema: null,
        cmodel: null,
        extra: null,
        readonly: false,
        child: 0,
    },
    render(h) {
        if (!this.Condition)
            return null;

        let desc;
        if (this.schema.description)
            desc = <i class="mdi mdi-information" title={this.schema.description} style={{ color: "silver", fontSize: "small", marginLeft: "3px" }}></i>;

        const doctype = this.schema.formatData.DocumentType; // UserControl or UserAction
        const choice =
            doctype === 'UserControl' ?
                this.cmodel.ControlURL :
                this.cmodel.ActionURL; // current choice (for actions, it is different, how do I determine?)

        const name = <span style={{ marginLeft: (this.child * 15) + "px" }}>{this.name}</span>;
        let form;
        if (this.control_schema) {
            form = (
                <property-grid
                    name={choice}
                    root={this.root}
                    schema={this.control_schema}
                    form={["*"]}
                    cmodel={doctype === 'UserControl' ? this.cmodel.ControlData : this.cmodel.ActionData}
                    child={0}
                    navigateToModel={this.root.navigateToModel}
                >
                </property-grid>
            );
        }
        else
            form = <span>Loading...</span>;

        return (
            <tr>
                <td style={{ width: "1px", verticalAlign: "top" }}>
                    {name} {desc}
                </td>
                <td>
                    {form}
                </td>
            </tr>
        );
    }
});