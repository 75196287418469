import Vue from 'vue';
import BaseComponent from './baseFormMixin.jsx';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';
import token from '@/Services/token';

import { appSettings } from '@/Shared/appSettings.js';

const libphonenumber = require('google-libphonenumber');

Vue.component('sform-phonenumber', {
    mixins: [BaseComponent],
    data: () => ({
        customerRegionCode: null,
    }),
    props: {
    },
    created() {
        this.phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
        this.customerRegionCode = token.CustomerRegionCode();
        if (!this.customerRegionCode)
            this.customerRegionCode = 'US';
    },
    mounted() {
        this.loadComplete();
    },
    computed: {
        formattedvalue: function () {
            return this.f_phoneNumber(this.itemvalue);
        },
    },
    methods: {
        syncformatted(value) {
            if (value) {
                try {
                    const parsedPhoneNumber = this.phoneUtil.parse(value, this.customerRegionCode);
                    this.sync(this.phoneUtil.format(parsedPhoneNumber, libphonenumber.PhoneNumberFormat.E164));
                }
                catch (e) {
                    utils.warn(`Warning! phone number failed to parse: ${value}`, e);
                    this.sync(value);
                }
            }
        },
    },
    render() {
        //if (!this.Condition)
        //    return null;

        let desc;
        if (this.element.description)
            desc = <v-icon small v-tooltip={this.element.description}>mdi mdi-information</v-icon>;

        const title = <span style={{ marginLeft: (this.child * 15) + "px" }}>{this.Title}</span>;

        let scopedSlots = {
            message: ({message}) => {
                return <translation-container context={this} value={message}></translation-container>
            }
        }

        let slots = [
            <translation-container slot="label" context={this} value={this.labelText}></translation-container>,
        ];

        let input = (
            <v-text-field 
                class="caption pa-0 ma-0" 
                outlined 
                single-line 
                dense 
                hide-details 
                style={{ width: "100%" }} 
                value={this.formattedvalue}
                rules={this.rules}
                on-input={(value) => this.temp_value = value}
                on-focus={() => {
                    this.temp_value = this.itemvalue;
                    this.onBlur();
                }}
                on-blur={() => {
                    this.syncformatted(this.temp_value);
                    this.onFocus();
                }}
                persistent-hint={appSettings.DebugTranslationPrefixSetting}
                hint={this.hintText}
                scopedSlots={scopedSlots}
            >{slots}</v-text-field>
        );

        if (this.appearance)
            input.componentOptions.propsData = { ...input.componentOptions.propsData, ...this.appearance };

        if (this.directives) {
            if (input.data.directives)
                input.data.directives = [...input.data.directives, ...this.directives];
            else
                input.data.directives = this.directives;
        }

        return input;

        //return (
        //    <tr class="pa-0 ma-0">
        //        <td style={{ width: "1px" }}>
        //            <span style={{ whiteSpace: "nowrap" }}>{title} {desc}</span>
        //        </td>
        //        <td class="pa-0 ma-0" class={{ "property-grid": this.condensed }}>
        //            {input}
        //        </td>
        //    </tr>
        //);
    }
});