import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import utils from '../../Shared/utils.jsx';

import Griddy from './vuecontrols/griddy.vue';

Vue.component('slim-grid', {
    mixins: [BaseComponent],
    components: {
        Griddy
    },
    data: () => ({
        datatable_id: null,

        modelfunc: null,
        modelurl: null,
        modelvalue: null,

        loading: false,
        search: '',

        itemsPerPage: 30,
        footerProps: {
            showFirstlastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        },
    }),
    created() {
        this.datatable_id = `data-table-${utils.generateUUID()}`;
        this.itemsPerPage = this.controlData.ItemsPerPage || 30;

        if ((this.controlData.DataType === 'URL' || this.controlData.DataType === undefined) && this.controlData.DataURL) {
            this.modelurl = utils.compile(this, this.controlData.DataURL);
        }
        else if (this.controlData.DataType === 'Raw' && this.controlData.Data) {
            this.modelvalue = utils.compileObject(this, this.controlData.Data);
        }
    },
    computed: {
        headers: function () {
            const hdrs = [];
            for (let i = 0; i < this.controlData.ColumnDefs.length; i++) {
                const hdr = this.controlData.ColumnDefs[i];
                if (hdr.Visible)
                    hdrs.push({
                        text: hdr.DisplayName || hdr.Name || hdr.Field,
                        value: (hdr.Type == 'Value' || hdr.Type == 'Link') ? hdr.Field : `$$field_${i}`,
                        align: hdr.Align,
                        sortable: hdr.Sortable,
                        filterable: true,
                        groupable: false,
                        divider: false,
                        class: undefined,
                        cellClass: undefined,
                        width: undefined,
                        filter: undefined,
                        sort: undefined,
                    });
            }

            return hdrs;
        },
        simpleheaders: function () {
            const h = this.$createElement;
            const hdrs = [];
            for (let i = 0; i < this.controlData.ColumnDefs.length; i++) {
                const hdr = this.controlData.ColumnDefs[i];
                if (hdr.Visible)
                    hdrs.push(
                        <th class="text-left">
                            {hdr.DisplayName || hdr.Name || hdr.Field}
                        </th>
                    );
            }

            return (
                <tr>
                    {hdrs}
                </tr>
            );
        },
        simplerows: function () {
            const h = this.$createElement;
            const items = [];
            for (let j = 0; j < this.dataSource.length; j++) {
                const row = this.dataSource[j];
                const cells = [];

                for (let i = 0; i < this.controlData.ColumnDefs.length; i++) {
                    const hdr = this.controlData.ColumnDefs[i];
                    if (hdr.Visible) {
                        let value;

                        if (hdr.Value && !hdr.Value_expn)
                            hdr.Value_expn = utils.compile(this, hdr.Value, false, hdr.RowModelName);

                        if (hdr.Value_expn)
                            value = utils.evaluate(hdr.Value_expn, this, false, null, false, row);
                        else
                            value = row[hdr.Field];

                        switch (hdr.Type) {
                            case 'Value':
                                break;

                            case 'Link':
                                value = <a href="javascript:void(0)">{value}</a>;
                                break;

                            case 'Icons':
                                const icons = [];
                                for (let k = 0; k < hdr.Icons.length; k++) {
                                    const ico = hdr.Icons[k];
                                    if (ico.Condition && !ico.Condition_expn)
                                        ico.Condition_expn = utils.compileExpression(this, ico.Condition, hdr.RowModelName);

                                    let condition = true;
                                    if (ico.Condition_expn)
                                        condition = utils.evaluate(ico.Condition_expn, this, false, null, false, row);

                                    let icon = <v-icon v-show={condition} small color={ico.Color}>{ico.Icon}</v-icon>;

                                    if (ico.Tooltip)
                                        icon = utils.generateTooltip(h, icon, ico.Tooltip, 'right');

                                    icons.push(icon);
                                }
                                value = icons;
                                break;
                        }

                        cells.push(<td>{value}</td>);
                    }
                }

                items.push(
                    <tr key={j}>
                        {cells}
                    </tr>
                );
            }
            return items;
        },
        giddy_headers: function () {
            const hdrs = [];
            for (let i = 0; i < this.controlData.ColumnDefs.length; i++) {
                const hdr = this.controlData.ColumnDefs[i];
                if (hdr.Visible)
                    hdrs.push({
                        name: hdr.Name || hdr.Field || `$$field_${i}`,
                        label: hdr.DisplayName || hdr.Name || hdr.Field,
                        field: hdr.Field || `$$field_${i}`,
                        width: '200',
                    });
            }

            return hdrs;
        },
        keyFields: function () {
            return this.controlData.KeyFields;
        },

        scopedSlots: function () {
            const h = this.$createElement;
            const slots = {};
            for (let i = 0; i < this.controlData.ColumnDefs.length; i++) {
                const hdr = this.controlData.ColumnDefs[i];
                if (hdr.Type == 'Value' && hdr.Value) {
                    if (!hdr.Value_func)
                        hdr.Value_func = utils.compile(this, hdr.Value, false, hdr.RowModelName);

                    if (hdr.Value_func)
                        slots[`item.${hdr.Field}`] = ({ item }) => utils.evaluate(hdr.Value_func, this, false, null, false, item);
                }
                else if (hdr.Type == 'Link' && !hdr.Value) {
                    if (!hdr.Value_func)
                        hdr.Value_func = utils.compile(this, `row.${hdr.Field}`, false, 'row');

                    slots[`item.${hdr.Field}`] = ({ item }) => <a href="javascript:void(0)">{utils.evaluate(hdr.Value_func, this, false, null, false, item)}</a>;
                }
                else if (hdr.Type == 'Link' && hdr.Value) {
                    if (!hdr.Value_func)
                        hdr.Value_func = utils.compile(this, hdr.Value, false, 'row');

                    slots[`item.${hdr.Field}`] = ({ item }) => <a href="javascript:void(0)">{utils.evaluate(hdr.Value_func, this, false, null, false, item)}</a>;
                }
                else if (hdr.Type == 'Icons' && hdr.Icons.length > 0) {
                    slots[`item.$$field_${i}`] = ({ item }) => {
                        const icons = [];
                        for (let j = 0; j < hdr.Icons.length; j++) {
                            const ico = hdr.Icons[j];

                            if (ico.Condition && !ico.Condition_expn)
                                ico.Condition_expn = utils.compileExpression(this, ico.Condition, hdr.RowModelName);

                            if (ico.Condition_expn && !utils.evaluate(ico.Condition_expn, this, false, null, false, item))
                                continue;

                            if (ico.Color && !ico.Color_expn)
                                ico.Color_expn = utils.compile(this, ico.Color, false, hdr.RowModelName);

                            let color;
                            if (ico.Color_expn)
                                color = utils.evaluate(ico.Color_expn, this, false, null, false, item);

                            if (ico.Actions && ico.Actions.length > 0)
                                icons.push(<v-btn elevation={0} small icon on-click={(e) => this.clickIcon(e, hdr, ico, item)}><v-icon small color={color}>{ico.Icon}</v-icon></v-btn>);
                            else
                                icons.push(<v-icon small color={color}>{ico.Icon}</v-icon>);
                        }
                        return icons;
                    };
                }
                else if (hdr.Type == 'Actions') {

                }
            }

            return slots;
        },
        dataSource: function () {
            return this.modelfunc ? this.modelfunc() : [];
        },
        modelurlvalue: function () {
            if (this.modelurl)
                return utils.evaluate(this.modelurl, this);
            else
                return '';
        },
        modelrawvalue: function () {
            if (this.modelvalue)
                return utils.evaluateObject(this.modelvalue, this);
            else
                return [];
        },
    },
    //Mounted Replaced with preRenderComplete
    methods: {
        async preRenderComplete() {
            this.finishRenderHandler(this);
            await this.Refresh();

            const self = this;
            self.updateTable();
            //window.onresize = function (event) {
            //    self.updateTable();
            //};
        },
        async Refresh(clearSelectedRows) {
            if (clearSelectedRows) {
            }

            this.loading = true;
            try {
                let model;
                if (this.controlData.DataType == 'URL' && this.modelurl) {
                    model = this.modelurlvalue ? await utils.api.get(this.modelurlvalue, false, false) : [];

                    if (!this.modelurl_watch$)
                        this.modelurl_watch$ = this.$watch(
                            function (newvalue) {
                                return this.modelurlvalue;
                            },
                            function (val, oldval) {
                                this.Refresh();
                            }
                        );
                }
                else if (this.controlData.DataType == 'Raw' && this.modelvalue)
                    try {
                        model = this.modelrawvalue;

                        if (!this.modelvalue_watch$)
                            this.modelvalue_watch$ = this.$watch(
                                function () {
                                    try {
                                        return this.modelrawvalue;
                                    }
                                    catch (e) {
                                        utils.warn('SlimGrid modelvalue ' + this.controlData.Model.Definition + ' failed to evaluate: ' + e);
                                        return null;
                                    }
                                },
                                function (val, oldval) {
                                    this.Refresh();
                                },
                                {
                                    deep: false
                                }
                            );
                    }
                    catch (e) {
                        utils.warn('BasicGrid modelvalue ' + this.controlData.Data + ' failed to evaluate: ' + e);
                        model = [];
                    }
                else
                    model = [];

                this.modelfunc = () => model;
            }
            finally {
                this.loading = false;
            }
        },
        rowCommandClick(e, data, btn) {
            e.cancelBubble = true;
            e.preventDefault();
            utils.executeAndCompileAllActions(btn.Actions, { RowIndex: data.rowIndex, Data: data.row.data }, this);
        },
        clickIcon(e, hdr, ico, item) {
            e.cancelBubble = true;
            e.preventDefault();
            utils.executeAndCompileAllActions(ico.Actions, { Data: item }, this);
        },

        updateTable() {
            const tableHeight = document.getElementById(this.datatable_id).offsetHeight - 54 - 58;
            this.itemsPerPage = parseInt(tableHeight / 32);
            utils.debug(`** SlimGrid: tableHeight: ${tableHeight}, itemsPerPage:${this.itemsPerPage}`);

            if (this.dataSource.length < this.itemsPerPage)
                this.itemsPerPage = this.dataSource.length;
            
            if (this.itemsPerPage > 1)
                this.itemsPerPage--;

            if (!this.footerProps.itemsPerPageOptions.includes(this.itemsPerPage)) {
                if (this.footerProps.itemsPerPageOptions.length == this.optionsLength) {
                    this.footerProps.itemsPerPageOptions.unshift(this.itemsPerPage);
                } else {
                    this.footerProps.itemsPerPageOptions.shift();
                    this.footerProps.itemsPerPageOptions.unshift(this.itemsPerPage);
                }
            }
        },
    },
    props: {},
    _render1() {
        if (!this.todisplay)
            return null;

        const style = {
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            padding: '8px',
            ...this.sizeStyle,
            
        };

        let scopedSlots = {};

        return (
            <v-data-table
                id={this.datatable_id}
                class={{ 'c-BasicGrid': true, [`c-name-${this.name || 'unnamed'}`]: true, 'elevation-1': true }}
                style={style}
                calculate-widths={true}
                dense
                loading={this.loading}
                search={this.search}
                headers={this.headers}
                items={this.dataSource}
                items-per-page={this.itemsPerPage}
                footer-props={this.footerProps}
                scopedSlots={this.scopedSlots}
            >
                <v-text-field
                    slot="top"
                    value={this.search}
                    style="flex-grow: 0"
                    class="mx-4" label="Search"
                    on-input={(v) => this.search = v}>
                </v-text-field>
            </v-data-table>
        );
    },
    _render2() {
        if (!this.todisplay)
            return null;

        const style = {
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            padding: '8px',
            ...this.sizeStyle,
        };

        return (
            <v-simple-table
                id={this.datatable_id}
                class={{ 'c-SlimGrid': true, [`c-name-${this.name || 'unnamed'}`]: true, 'elevation-1': true }}
                style={style}
                fixed-header
                dense
            >
                <thead>
                    {this.simpleheaders}
                </thead>
                <tbody>
                    {this.simplerows}
                </tbody>
            </v-simple-table>
        );
    },
    render() {
        if (!this.todisplay)
            return null;

        const style = {
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            padding: '8px',
            ...this.sizeStyle,
        };

        return (
            <Griddy
                id={this.datatable_id}
                class={{ 'c-SlimGrid': true, [`c-name-${this.name || 'unnamed'}`]: true, 'elevation-1': true }}
                style={style}
                data-source={this.dataSource}
                key-fields={this.keyFields}
                columns={this.giddy_headers}
            >
            </Griddy>
        );
    }
});