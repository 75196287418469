<template>
    <v-container style="background-color: var(--v-extraLightGrey-base);">
        <v-sheet dense v-for="(item,i) in rules" :key="i" color="extraLightGrey">
            <v-sheet class="d-flex flex-row flex-nowrap">
                <v-row dense>
                    <v-col cols="6">
                        <v-select outlined dense hide-details
                                  label="Condition"
                                  :items="condition_types"
                                  :menu-props="{ offsetY: true }"
                                  v-model="item.condition"
                                  @change="updateReport"
                                  background-color="white"></v-select>
                    </v-col>

                    <v-col cols="6" v-if="item.condition != 'inRange'">
                        <v-text-field type="number" label="Value"
                                      outlined dense hide-details :placeholder="placeholder"
                                      v-model.number="item.value"
                                      :disabled="item.condition == 'missing'"
                                      @input="updateReport"
                                      background-color="white">
                            <template v-slot:append>
                                <span class="mt-1">{{ units }}</span>
                            </template>
                        </v-text-field>
                    </v-col>

                    <v-col cols="3" v-if="item.condition == 'inRange'">
                        <v-text-field type="number" label="From"
                                      outlined dense hide-details :placeholder="placeholder"
                                      v-model.number="item.value"
                                      @input="updateReport"
                                      background-color="white">
                            <template v-slot:append>
                                <span class="mt-1">{{ units }}</span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="3" v-if="item.condition == 'inRange'">
                        <v-text-field type="number" label="To"
                                      outlined dense hide-details :placeholder="placeholder"
                                      v-model.number="item.valueto"
                                      @input="updateReport"
                                      background-color="white">
                            <template v-slot:append>
                                <span class="mt-1">{{ units }}</span>
                            </template>
                        </v-text-field>
                    </v-col>

                    
                </v-row>
                <v-btn icon class="mr-n9" v-if="i > 0" @click="removeCondition()">
                    <v-icon>mdi-window-close</v-icon>
                </v-btn>
            </v-sheet>
                <v-row v-if="i < 1" v-show="rules.length > 1" justify="center">
                    <v-radio-group :value="item.combiner" @change="(value) => setItemCombiner(item, i, value)"  mandatory row class="mt-6 mb-1 ml-1">
                        <v-radio label="AND" value="AND">
                        </v-radio>
                        <v-radio label="OR" value="OR">
                        </v-radio>
                    </v-radio-group>
                </v-row>
        </v-sheet>
        <v-hover >
            <v-btn v-show="rules.length == 1" elevation="0" class="px-0 customHoverButton" @click="addCondition()">
                <v-icon left class="mr-1">
                    mdi-plus
                </v-icon>
                Add Rule
            </v-btn>
        </v-hover>
    </v-container>
</template>

<script>
    import { appSettings } from '@/Shared/appSettings';

    import _ from 'lodash';

    const getCurrencySymbol = (locale, currency) => (0).toLocaleString(locale, { style: 'currency', currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\d/g, '').trim();

    export default {
        data: function () {
            return {
                rules: [{ condition: 'greaterThan', value: '', valueto: 60, combiner: undefined }],
                condition_types: [
                    { text: 'Greater than', value: 'greaterThan' },
                    { text: 'Greater than or equal to', value: 'greaterThanOrEqual' },
                    { text: 'Less than', value: 'lessThan' },
                    { text: 'Less than or equal to', value: 'lessThanOrEqual' },
                    { text: 'Equals', value: 'equals' },
                    { text: 'Does not equal', value: 'notEquals' },
                    { text: 'Between', value: 'inRange' },
                    { text: 'Missing', value: 'missing' },
                ],
            }
        },
        props: {
            item: Object,
            value: null,
        },
        created() {
            if (this.value) {
                // Convert from the standard filter format to an array of rules
            }

            const self = this;
            this.updateReportDebouncer = _.debounce(function() {
                /*
                    Note the rules list is restricted to 1 or 2 items. We might allow more in the future, but then we
                    would need some way to indicate groups. But ag-grid did not support more than two, so we are probably ok.
                */
                console.log('debounce updateReport');

                if (self.rules.length < 1 || self.rules.some(a => (a.value === '' && a.condition != 'missing') || (a.condition == 'inRange' && a.valueto === ''))) {
                    // Either an empty list (shouldn't happen) or an empty value on the first rule means no filter
                    self.$emit('change', null);
                    return;
                }

                if (self.rules.length == 1)
                    // Single rule scenario is the simplest format (made popular by ag-grid)
                    self.$emit('change', {
                        filterType: "number",
                        type: self.rules[0].condition,
                        filter: this.item.SourceType == 'percentColumn' ? self.rules[0].value / 100 : self.rules[0].value,
                        filterTo: this.item.SourceType == 'percentColumn' ? self.rules[0].valueto / 100 : self.rules[0].valueto,
                    });
                else
                    // Multiple rule scenario puts the conditions into an array
                    self.$emit('change', {
                        filterType: "number",
                        operator: self.rules[0].combiner,
                        conditions: self.rules.map(a => ({
                            type: a.condition,
                            filter: this.item.SourceType == 'percentColumn' ? a.value / 100 : a.value,
                            filterTo: this.item.SourceType == 'percentColumn' ? a.valueto / 100 : a.valueto,
                        }))
                    });
            }, 500);

            if (this.value && this.value.type) {
                this.rules = [{
                    condition: this.value.type,
                    value: this.item.SourceType == 'percentColumn' ? this.value.filter * 100 : this.value.filter,
                    valueto: this.item.SourceType == 'percentColumn' ? this.value.filterTo * 100 : this.value.filterTo,
                }];
            }
            else if (this.value && this.value.conditions && this.value.conditions.length == 2) {
                this.rules = [{
                    condition: this.value.conditions[0].type,
                    value: this.item.SourceType == 'percentColumn' ? this.value.conditions[0].filter * 100 : this.value.conditions[0].filter,
                    valueto: this.item.SourceType == 'percentColumn' ? this.value.conditions[0].filterTo * 100 : this.value.conditions[0].filterTo,
                    combiner: this.value.operator,
                },{
                    condition: this.value.conditions[1].type,
                    value: this.item.SourceType == 'percentColumn' ? this.value.conditions[1].filter * 100 : this.value.conditions[1].filter,
                    valueto: this.item.SourceType == 'percentColumn' ? this.value.conditions[1].filterTo * 100 : this.value.conditions[1].filterTo,
                }];
            }
        },
        watch: {
            rules: function (newv) {
                if (newv.every(a => a.value !== ''))
                    this.updateReport();
            },
        },
        computed: {
            units: function() {
                return this.item.SourceType == 'durationColumn' ? 'sec' :
                    this.item.SourceType == 'percentColumn' ? '%' :
                        this.item.SourceType == 'currencyColumn' ? getCurrencySymbol(appSettings.LocaleKey, appSettings.Currency || 'USD') :
                            '#';
            },
            placeholder: function () {
                return this.item.SourceType == 'durationColumn' ? 'Enter value in seconds' :
                    this.item.SourceType == 'percentColumn' ? 'Enter percentage value' :
                        this.item.SourceType == 'currencyColumn' ? 'Enter currency value' :
                            'Enter value';
            }
        },
        methods: {
            updateReport() {
                console.log('updateReport');
                this.updateReportDebouncer();
            },
            setItemCombiner(item, index, value) {
                console.log(`setItemCombiner[${index}] to ${value}:`);
                console.log(JSON.stringify(item,null,3));

                item.combiner = value;
                this.updateReport();
            },
            addCondition() {
                this.rules.push({ condition: 'greaterThan', value: 0, valueto: 0, combiner: undefined });
            },
            removeCondition() {
                this.rules.pop();
            }
        }
    }
</script>

<style scoped>
    .verydense {
        max-height: 24px;
        min-height: 24px;
    }

    .mediumdense {
        max-height: 28px;
        min-height: 28px;
    }
    .customHoverButton:before {
        background-color: unset !important;
        transition: unset !important;
    }
    .customHoverButton {
        background-color:unset !important;
        color: var(--v-primary-base);
    }
    .customHoverButton:hover {
        filter: brightness(80%);
    }

</style>