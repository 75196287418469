import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

Vue.component('basic-icon-dsgn', {
    data: function () {
        return {
        }
    },
    created() {
    },
    computed: {
        Root: function () {
            return this.root._self;
        },
        selected$: function () {
            return this.Root.SelectedNode && this.Root.SelectedModel == this.designmodel;
        },
        icon() {
            let icon = this.controlData.Icon;

            if (this.controlData.IconStyle === 'mdi' && !icon.startsWith('mdi-'))
                icon = `mdi-${icon}`;

            return icon;
        },
        iconClasses: function () {
            //let icon;
            //if (!this.controlData.Icon || this.controlData.Icon.includes('{{') || this.controlData.Icon.includes('{*'))
            //    icon = 'mdi mdi-help-circle';
            //else
            //    icon = this.controlData.Icon || 'mdi mdi-help-circle';

            let iconClass = '';
            iconClass += (this.controlData.FlipHorizontally) ? ' mdi mdi-flip-vertical ' : '';
            iconClass += (this.controlData.FlipVertically) ? ' mdi mdi-flip-horizontal ' : '';
            iconClass += (this.controlData.Border) ? ' mdi mdi-border-all-variant ' : '';
            iconClass += (this.controlData.Animation == "Spin") ? ' mdi-animate-spin ' : '';
            iconClass += (this.controlData.Animation == "Pulse") ? ' mdi-animate-pulse ' : '';
            return iconClass;
        },
        iconStyles: function () {
            const styles = {};

            styles.margin = '15%';

            if (this.controlData.GlowColor)
                styles['text-shadow'] = `0 0 0.2em ${this.controlData.GlowColor}`;

            if (this.controlData.FontSize)
                styles['font-size'] = utils.resolveFontSize(this.controlData.FontSize);

            if (this.controlData.Rotation)
                styles.transform = `rotate(${this.controlData.Rotation}deg)`;

            if (this.controlData.Color)
                styles.color = this.controlData.Color;

            return styles;
        },
        sizeStyle() {
            return utils.getSize(this.controlData.SizeOptions, this.parentType, this.$parent);
        },
        containerStyles() {
            let styles = {
                ...utils.resolveStyleHints(this.controlData.StyleHints, this),
                ...this.sizeStyle,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            };
            return styles;
        },
    },
    methods: {
        ...methods,
        handleClick(e) {
            this.Root.SelectNode(this);
            e.cancelBubble = true;
            e.stopPropagation();
        },
    },
    props: {
        name: '',
        root: null,
        rowData: null,
        designmodel: null,
        parentType: '',
        controlData: {}
    },
    render(h) {
        let icon = <v-icon dense style={this.iconStyles} class={this.iconClasses} >{this.icon}</v-icon>;

        return (
            <div
                style={this.containerStyles}
                class={{ 'c-Icon': true, 'designer-container': true, selected: this.selected$ }}
                on-click={(e) => this.handleClick(e)}>
                <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
                {/*<i class={this.iconClass}></i>*/}
                {icon}
                <div class="c-icon-caption" style={{ fontSize: this.controlData.CaptionFontSize }}>{this.controlData.Caption }</div>
            </div>
        );
    }
});