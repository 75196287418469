import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import utils from '../../Shared/utils.jsx';
import careHelpfulFunctions from '../careHelpfulFunctions.jsx';

//import VueApexCharts from 'vue-apexcharts'
//Vue.use(VueApexCharts);
//Vue.component('apexchart', VueApexCharts);

Vue.component('bar-chart', {
    mixins: [BaseComponent],
    components: {
    },
    data: function () {
        return {
            tooltipEval: null,
            tooltipPlacementEval: null,
            disabledEval: null,
            objectFitEval: null,
            sourcemodel: null,
        }
    },
    created() {
        if (this.controlData.SourceType === 'Raw' && this.controlData.SourceRaw) {
            this.sourceraw = this.controlData.SourceRaw;
        }
        else if (this.controlData.SourceType === 'RawInterpolated' && this.controlData.SourceRaw) {
            this.sourceraw = utils.compileObject(this, this.controlData.SourceRaw);
        }
        else if (this.controlData.SourceType === 'URL' && this.controlData.SourceURL) {
            this.sourceurl = utils.compile(this, this.controlData.SourceURL);
        }
    },
    //Created Replaced with preRenderComplete
    computed: {
        style() {
            return {
                ...utils.resolveStyleHints(this.styleHints, this),
                ...this.sizeStyle,
                display: 'flex',
                flexDirection: 'column',
                overflow: "none"
            };
        },
        tooltip() {
            try {
                if (this.tooltipEval === null && this.controlData.Tooltip)
                    this.tooltipEval = utils.compile(this, this.Translate(this.controlData.Tooltip));
                if (this.tooltipEval)
                    return utils.evaluate(this.tooltipEval, this);
                return '';
            }
            catch (e) {
                utils.warn('Tooltip could not evaluate expression: ' + this.controlData.Tooltip + '; ' + e);
                return '';
            }
        },
        tooltipPlacement() {
            try {
                if (this.tooltipPlacementEval === null && this.controlData.TooltipPlacement)
                    this.tooltipPlacementEval = utils.compile(this, this.controlData.TooltipPlacement);
                let placement = '';
                if (this.tooltipPlacementEval)
                    placement = utils.evaluate(this.tooltipPlacementEval, this);
                return placement.split('-')[0].toLowerCase() || 'top';
            }
            catch (e) {
                utils.warn('TooltipPlacement could not evaluate expression: ' + this.controlData.TooltipPlacement + '; ' + e);
                return '';
            }
        },
        legendPlacement() {
            try {
                if (this.legendPlacementEval === null && this.controlData.LegendPlacement)
                    this.legendPlacementEval = utils.compile(this, this.controlData.LegendPlacement);
                let placement = '';
                if (this.legendPlacementEval)
                    placement = utils.evaluate(this.legendPlacementEval, this);
                return placement.split('-')[0].toLowerCase() || 'top';
            }
            catch (e) {
                utils.warn('Legend Placement could not evaluate expression: ' + this.controlData.LegendPlacement + '; ' + e);
                return '';
            }
        },
        color() {
            if (!this.color_fn && this.controlData.Color)
                this.color_fn = utils.compile(this, this.controlData.Color);

            return this.color_fn ? utils.evaluate(this.color_fn, this) : undefined;
        },
        value() {
            if (!this.value_fn && this.controlData.ValueExpression)
                this.value_fn = utils.compileExpression(this, this.controlData.ValueExpression);

            return this.value_fn ? utils.evaluate(this.value_fn, this) : 0;
        },
        width() {
            if (!this.width_fn && this.controlData.WidthExpression)
                this.width_fn = utils.compileExpression(this, this.controlData.WidthExpression);

            return this.width_fn ? utils.evaluate(this.width_fn, this) : 0;
        },
        size() {
            if (!this.size_fn && this.controlData.SizeExpression)
                this.size_fn = utils.compileExpression(this, this.controlData.SizeExpression);

            return this.size_fn ? utils.evaluate(this.size_fn, this) : 0;
        },
        rotate() {
            if (!this.rotate_fn && this.controlData.RotateExpression)
                this.rotate_fn = utils.compileExpression(this, this.controlData.RotateExpression);

            return this.rotate_fn ? utils.evaluate(this.rotate_fn, this) : 0;
        },

        title() {
            if (this.controlData.Title && !this.title_fn)
                this.title_fn = utils.compile(this, this.controlData.Title);

            return this.title_fn ? utils.evaluate(this.title_fn, this) : '';
        },
        

        sourcerawvalue: function () {
            if (this.controlData.SourceType === 'Raw')
                return this.sourceraw;
            else
                return this.sourceraw ? utils.evaluateObject(this.sourceraw, this) : null;
        },
        sourceurlvalue: function () {
            return this.sourceurl ? utils.evaluate(this.sourceurl, this) : '';
        },
        model: function () {
            switch (this.controlData.SourceType) {
                case 'Raw':
                    return this.sourceraw;

                case 'RawInterpolated':
                    return this.sourceraw ? utils.evaluateObject(this.sourceraw, this) : null;

                case 'URL':
                    return this.sourcemodel;

                default:
                    return null;
            }
        },
        labelFormatter: function () {
            const self = this;

            return function (val, opts) {
                // Causes the actual values to be shown in each pie slice where
                // the val parameter is the percentage, which is less useful.
                switch (self.controlData.LabelFormat) {
                    case 'time': return careHelpfulFunctions.durationSecondsAsString(opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex]);
                    case 'percentage': return opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].toFixed(0) + '%';
                    case 'label': return self.model.labels[opts.seriesIndex];
                    case 'value': return opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex];
                    case 'label value': return self.model.labels[opts.seriesIndex] + ' ' + opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex];
                    case 'series value': return opts.w.config.series[opts.seriesIndex].name + ' ' + opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex];
                    case 'series value time': return opts.w.config.series[opts.seriesIndex].name + ' - ' + careHelpfulFunctions.durationSecondsAsString(opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex]);
                    case 'none': return '';
                }
                return opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex];
            };
        },
        legendFormatter: function () {
            const self = this;

            return function (val, opts) {
                // Causes the actual values to be shown in each pie slice where
                // the val parameter is the percentage, which is less useful.
                switch (self.controlData.LegendFormat) {
                    case 'time': return careHelpfulFunctions.durationSecondsAsString(opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex]);
                    case 'percentage': return opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].toFixed(0) + '%';
                    case 'label': return self.model.labels[opts.seriesIndex];
                    case 'value': return opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex];
                    case 'label value': return self.model.labels[opts.seriesIndex] + ' ' + opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex];
                    case 'series value': return opts.w.config.series[opts.seriesIndex].name + ' ' + opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex];
                    case 'series value time': return opts.w.config.series[opts.seriesIndex].name + ' - ' + careHelpfulFunctions.durationSecondsAsString(opts.w.config.series[opts.seriesIndex].data[0]);
                    case 'none': return '';
                }
                return opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex];
            };
        },
        tooltipFormatter: function () {
            const self = this;

            return function (val, opts) {
                // Causes the actual values to be shown in each pie slice where
                // the val parameter is the percentage, which is less useful.
                switch (self.controlData.ToolTipFormat) {
                    case 'time': return careHelpfulFunctions.durationSecondsAsString(opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex]);
                    case 'percentage': return opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].toFixed(0) + '%';
                    case 'label': return self.model.labels[opts.seriesIndex];
                    case 'value': return opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex];
                    case 'label value': return self.model.labels[opts.seriesIndex] + ' ' + opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex];
                    case 'series value': return opts.w.config.series[opts.seriesIndex].name + ' ' + opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex];
                    case 'series value time': return opts.w.config.series[opts.seriesIndex].name + ' - ' + careHelpfulFunctions.durationSecondsAsString(opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex]);
                    case 'none': return '';
                }
                return opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex];
            };
        },
        xaxisCategories() {
            return this.model.xaxis
        },
        chartData() {
            return this.model.data;
            //return [1,2,3,4,5]; // [44, 55, 41, 17, 15];
        },
        chartOptions() {
            return {
                title: {
                    text: this.title,
                },
                dataLabels: {
                    enabled: ('DataLabels' in this.controlData) ? this.controlData.DataLabels?.Enabled : true,
                    formatter: this.labelFormatter,

                },
                noData: {
                    text: this.Translate(this.model.nodata || "Not Enough Data To Display"),
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: 'black',
                        fontSize: '1.2em',
                    }
                },
                chart: {
                    type: 'bar',
                    stacked: this.controlData.IsStacked,
                    stackType: this.controlData.StackType || 'normal',
                    width: '100%',
                    height: '100%',
                    toolbar: {
                        show: this.controlData.ShowToolbar,
                    },
                    sparkline: {
                        enabled: this.controlData.EnableSparkLine
                    },
                    parentHeightOffset: 0,
                },
                colors: this.model.colors,
                grid: {
                    show: false,
                    padding: {
                        left: 0,
                        right: 0
                    }
                },
                stroke: {
                    show: false,
                    width: 0
                },
                plotOptions: {
                    bar: {
                        horizontal: this.controlData.IsHorizontal,
                    },
                },
                xaxis: {
                    categories: this.xaxisCategories,
                    
                    labels: {
                        show: this.controlData.ShowXAxisLabels,
                    },
                    axisBorder: {
                        show: this.controlData.ShowXAxisLabels,
                    },
                    axisTicks: {
                        show: this.controlData.ShowXAxisLabels,
                    },
                },
                yaxis: {
                    show: this.controlData.ShowYAxisLabels,
                    axisBorder: {
                        show: this.controlData.ShowYAxisLabels,
                    },
                    axisTicks: {
                        show: this.controlData.ShowYAxisLabels,
                    },
                },
                legend: {
                    horizontalAlign: this.controlData.LegendPositionAlign || 'left',
                    offsetX: this.controlData.LegendPositionOffset || 40,
                    show: this.controlData.LegendPosition != 'none',
                    position: this.controlData.LegendPosition != 'none' ? (this.controlData.LegendPosition || 'right') : 'right',
                    formatter: this.legendFormatter,
                },
                tooltip: {
                    y: {
                        formatter: this.tooltipFormatter,
                    }
                }
            };
        },
    },
    methods: {
        async preRenderComplete() {
            this.finishRenderHandler(this);
            this.Refresh();
        },
        async Refresh() {
            if (this.controlData.SourceType === 'URL' && this.controlData.SourceURL) {
                this.sourcemodel = await utils.api.get(this.sourceurlvalue);
            }
        },

        getContent(value) {
            if (!this.content_fn && this.controlData.Content)
                this.content_fn = utils.compile(this, this.controlData.Content, false, 'value');

            return this.content_fn ? utils.evaluate(this.content_fn, this, false, null, false, value) : null;
        },
    },
    render(h) {
        if (!this.todisplay || !this.model)
            return null;

        try {
            let item = (
                <div class="barchartwrapper" style={this.style} >
                    <apexchart
                        type="bar"
                        v-show={this.isvisible}
                        class={{ 'c-BarChart': true, [`c-name-${this.name || 'unnamed'}`]: true }}
                        height="100%"
                        width="100%"
                        series={this.chartData}
                        options={this.chartOptions}
                    >
                    </apexchart>
                </div>
            );

            if (this.tooltip)
                item = utils.generateTooltip(h, item, this.tooltip, this.tooltipPlacement);

            return item;
        }
        catch (e) {
            utils.error('BarChart Render failed', e);
            return <div>BarChart Failed to Render {e}</div>;
        }
    }
});