import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

function generateItems(h, control, items, model, index) {
    if (!control.controlData || !control.controlData.TreeNodeTemplate)
        return;

    for (let i = 0; i < control.controlData.TreeNodeTemplate.length; i++) {
        const c = control.controlData.TreeNodeTemplate[i];
        if (!(typeof c === 'object'))
            continue;

        let DynamicControl = utils.getDynamicComponent(h, c);

        if (!DynamicControl)
            DynamicControl = 'default-unknown';

        DynamicControl += '-dsgn';

        if (!c.$objectId) c.$objectId = utils.generateUUID();

        let id = c.$objectId;
        if (index) id += '_' + index;

        items.push(
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <i class="mdi mdi-play" /><DynamicControl key={id} type={c.ControlType} name={c.ControlData ? c.ControlData.Name : ''} root={control.root} designmodel={c} parentType="VerticalStack" controlData={c.ControlData} sourceData={model}>
                </DynamicControl>
            </div>
        );
    }
}

Vue.component('basic-tree-dsgn', {
    data: function () {
        return {
            model: [],
            pickervisible: false,
            picker: null,
            pickertarget: 'body',
        }
    },
    computed: {
        Root: function () {
            return this.root._self;
        },
        Vars: function () {
            if (!this.controlData.Vars)
                Vue.set(this.controlData, 'Vars', {});

            return this.controlData.Vars;
        },
        DesignModel: function () {
            return this.designmodel;
        },
        selected$: function () {
            return this.Root.SelectedNode && this.Root.SelectedModel == this.designmodel;
        },
    },
    mounted() {
        this.Refresh();
    },
    methods: {
        ...methods,
        handleClick(e) {
            this.Root.SelectNode(this);
            e.cancelBubble = true;
            e.stopPropagation();
        },
        addControl(e, target) {
            this.pickertarget = target;
            this.picker = <control-picker confirm={this.confirmAddControl} cancel={this.cancelAddControl}></control-picker>;
            this.pickervisible = true;
            e.cancelBubble = true;
            e.stopPropagation();
        },
        async confirmAddControl(id, title) {
            this.pickervisible = false;

            try {
                const schema_ = await utils.schema.get(id);
                const schema = utils.schema.resolve_Of(schema_)
                const model = utils.schema.getDefaultModel(schema);

                switch (this.pickertarget) {
                    case 'header':
                        if (!this.controlData.HeaderControls)
                            Vue.set(this.controlData, 'HeaderControls', []);

                        this.controlData.HeaderControls.push(model);
                        // Hmmm. How to select the node, it isn't created yet, not until it is rendered when this layout stack re-renders
                        //this.Root.SelectNode(model);
                        break;

                    case 'body':
                        if (!this.controlData.Controls)
                            Vue.set(this.controlData, 'Controls', []);

                        this.controlData.Controls.push(model);
                        //this.Root.SelectNode(model);
                        break;
                }
            }
            catch (e) {
                alert(e);
            }
        },
        cancelAddControl() {
            this.pickervisible = false;
        },
        pickerInput(value) {
            this.pickervisible = value;
        },
        async Refresh() {
            if (this.controlData.SourceRaw)
                this.model = this.controlData.SourceRaw;
            // Ignore this for now -- rendering the source data in design mode is kind of annoying
            //else if (this.controlData.SourceUrl)
            //    try {
            //        this.model = await utils.api.get(this.controlData.SourceUrl);
            //    }
            //    catch (e) {
            //        utils.warn('VerticalStack failed to evaluate SourceUrl ' + this.controlData.SourceUrl + ': ' + e);
            //    }
        }
    },
    props: {
        name: '',
        root: null,
        designmodel: null,
        parentType: '',
        controlData: {}
    },
    render(h) {
        let items = [];

        if (this.model && typeof this.model === 'object' && Array.isArray(this.model) && this.model.length > 0) {
            for (let i = 0; i < (this.model.length < 10 ? this.model.length : 10); i++) {
                const item = this.model[i];

                generateItems(h, this, items, item, i);
            }
        }
        else
            generateItems(h, this, items, null, null);

        if (this.selected$)
            items.push(
                <div class="property-grid">
                    {utils.generateTooltip(h,
                        <v-btn flat icon style={{ zIndex: 2 }} on-click={(e) => this.addControl(e, 'body')}>
                            <v-icon color="blue darken-2">mdi mdi-plus-circle</v-icon>
                        </v-btn>,
                        'Add control to body', 'right')}
                </div>
            );

        const style = {
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            minWidth: "25px",
            ...utils.getSize(this.controlData.SizeOptions, this.parentType, this.$parent),
            ...utils.getStyleHints(this.controlData.StyleHints),
        };

        return (
            <div
                class={{ 'designer-container': true, selected: this.selected$ }}
                style={style}
                on-click={(e) => this.handleClick(e)}>
                <div class={{ "designer-container-hovering": true, 'designer-container-selected-clickthrough': this.selected$ }}></div>

                {items}

                <v-dialog value={this.pickervisible} max-width="860" scrollable on-input={(value) => this.pickerInput(value)}>
                    {this.picker}
                </v-dialog>
            </div>
        );
    }
});