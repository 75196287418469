import Vue from 'vue';
import BaseComponent from './baseFormMixin.jsx';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';
import careHelpfulFunctions from '../../careHelpfulFunctions.jsx';

import { appSettings } from '@/Shared/appSettings.js';

Vue.component('sform-select', {
    mixins: [BaseComponent],
    data: () => ({
        titleMap: null,
        formatData: null,

        dolookupexpn: null,
        lookupurlexpn: null,
        lookuprawexpn: null,

        displayexpnfunc: null,
        valueexpnfunc: null,

        item_text: 'name',
        item_value: 'value',
        item_disabled: undefined,
        lookupitems: null,

        arrayfield: null,
        arrayelement: null,
        keyextractor: null,
    }),
    props: {
    },
    created() {
        if (this.element.formatData)
            this.formatData = this.element.formatData;
        else if (this.element.schema && this.element.schema.formatData)
            this.formatData = this.element.schema.formatData;

        if (this.formatData && (this.formatData.LookupType || this.formatData.LookupURL || this.formatData.LookupInterpolatedExpression)) {
            if (this.formatData.LookupExpression)
                this.dolookupexpn = utils.compileExpression(this, this.formatData.LookupExpression);

            if ((!this.formatData.LookupType || this.formatData.LookupType == 'URL') && this.formatData.LookupURL)
                this.lookupurlexpn = utils.compile(this, this.formatData.LookupURL);
            else if (this.formatData.LookupType == 'Interpolated' && this.formatData.LookupInterpolatedExpression)
                this.lookuprawexpn = utils.compileExpression(this, this.formatData.LookupInterpolatedExpression);

            if (this.formatData.DisplayType == 'Expression') {
                const expr = utils.parseExpressionWithFilters(this.formatData.DisplayExpression);
                this.displayexpnfunc = {
                    code: expr,
                    func: new Function('control', 'util', this.formatData.LookupModelAs, `with (control) return ${expr};`)
                };
            }
            if (this.formatData.ValueType == 'Expression') {
                const expr = utils.parseExpressionWithFilters(this.formatData.ValueExpression);
                this.valueexpnfunc = {
                    code: expr,
                    func: new Function('control', 'util', this.formatData.LookupModelAs, `with (control) return ${expr};`)
                };
            }

            switch (this.formatData.DisplayType) {
                case 'Fieldname': this.item_text = this.formatData.DisplayField; break;
                case 'Expression':
                    this.item_text = (data) => {
                        try {
                            return data.$emptyrow ? data[this.element.formatData.DisplayField || 'name'] : this.displayexpnfunc.func(this, careHelpfulFunctions, data);
                        }
                        catch (e) {
                            utils.warn(`LookupList DisplayType Expression failed to evaluate: ${this.displayexpnfunc.code}; original: ${this.formatData.DisplayExpression}`, e);
                            return '';
                        }
                    };
                    break;
            }
            switch (this.formatData.ValueType) {
                case 'Fieldname': this.item_value = this.formatData.ValueField; break;
                case 'Expression':
                    this.item_value = (data) => {
                        try {
                            return data.$emptyrow ? data[this.element.formatData.ValueField || 'value'] : this.valueexpnfunc.func(this, careHelpfulFunctions, data);
                        }
                        catch (e) {
                            utils.warn(`LookupList ValueType Expression failed to evaluate: ${this.valueexpnfunc.code}; original: ${this.formatData.ValueExpression}`, e);
                            return '';
                        }
                    };
                    break;
            }
        }
        else if (this.element.enum && !this.element.titleMap)
            this.titleMap = this.element.enum.map(i => ({ name: i, value: i }));
        else
            this.titleMap = this.element.titleMap.map(i => ({ name: i.name, value: i.value }));

        if (this.formatData && this.formatData.UniqueValues) {
            // If this item is marked for uniqueness, then we must locate the array where
            // the list of items are held. Then as we populate the items for the dropdown,
            // we can filter out items that have already been selected in another list.
            this.arrayfield = this.ParentByType('FormField_ArrayItem')?.Parent;
            if (this.arrayfield) {
                // Find the model reference that will allow us to read the array element from all
                // other array items.
                const keys = [...this.element.key];  // Ex: Queues.[].QueueName
                const arraykeys = this.ParentByType('FormField_ArrayItem').element.key; // Ex: Queues.[]
                keys.splice(0, arraykeys.length); // Ex: QueueName - isolate the element from the array down

                // This is sufficient for 99% of the use cases, but if the key happens to be in an array
                // for example Queues.[].Another.[1].QueueName, then this will fail. But I don't expect this
                // to ever be a real issue, so the effort to support it isn't worth it. (7/11/2021)
                this.keyextractor = new Function('item', `return item${keys.length ? `.${keys.join('.')}` : ''};`);

                this.arrayelement = this.arrayfield.FieldValue;
                if (this.arrayelement) { 
                    if (!this.arrayfield.chosenitems) this.arrayfield.chosenitems = {};
                    if (!this.arrayfield.availableitems) Vue.set(this.arrayfield, 'availableitems', {});
                    this.arrayfield.chosenitems[this.chosenitems_key] = Object.fromEntries(this.arrayelement.map(a => [this.keyextractor(a), true]));
                    this.item_disabled = (item) =>  this.getItemValue(item) != this.itemvalue && this.getItemValue(item) in this.arrayfield.chosenitems[this.chosenitems_key];
                }
            }
        }
    },
    destroyed() {
        if (this.arrayfield && this.arrayelement) {
            // Uniqueness has been requested and we have a reference to the array of items already picked.
            // We need to generate a lookup object containing every selected item so that we can disable
            // those that are already picked in the current dropdown list.
            if (!this.arrayfield.chosenitems) this.arrayfield.chosenitems = {};
            this.arrayfield.chosenitems[this.chosenitems_key] = Object.fromEntries(this.arrayelement.map(a => [this.keyextractor(a), true]));

            const items = this.lookupitems || this.titleMap;
            // Update the array container to control if the Add button is enabled or not
            Vue.set(this.arrayfield.availableitems, this.chosenitems_key, items && (this.arrayelement.length < items.length));
        }
    },
    async mounted() {
        await this.Refresh();
        this.loadComplete();
    },
    computed: {
        chosenitems_key: function () {
            // Generate a key that is made from the name. Ex: Queue.[0].Name ==> Queue__0__Name
            return this.name.replace(/\./g, '_').replace(/\[/g, '_').replace(/\]/g, '_');
        },
        tolookup: function () {
            if (this.dolookupexpn)
                return utils.evaluate(this.dolookupexpn, this);
            else
                return true;
        },
        lookupurl: function () {
            if (this.lookupurlexpn)
                try {
                    return utils.evaluate(this.lookupurlexpn, this, false, null, true);
                }
                catch (e) {
                    utils.warn(`LookupList lookupurl could not be evaluated: ${this.lookupurlexpn.code}`, e);
                    return '';
                }
            else
                return '';
        },
        lookupraw: function () {
            if (this.lookuprawexpn)
                try {
                    return utils.evaluate(this.lookuprawexpn, this, false, null, true);
                }
                catch (e) {
                    utils.warn(`LookupList lookupraw could not be evaluated: ${this.lookuprawexpn.code}`, e);
                    return [];
                }
            else
                return '';
        },
        OnChangeData: function () {
            // This computed property is mixed into the Input data for a schema OnChange event action (see baseFormMixin.jsx)
            const items = this.lookupitems || this.titleMap || [];
            return {
                TitleMap: items.map(i => ({
                    value: (typeof this.item_value === 'function') ? this.item_value(i) : i[this.item_value],
                    record: i,
                }))
            };
        },
        TitleMap: function () {
            const items = this.lookupitems || this.titleMap || [];
            return items.map(i => ({
                value: (typeof this.item_value === 'function') ? this.item_value(i) : i[this.item_value],
                record: i,
            }));
        },
        listitems: function () {
            let items = this.lookupitems || this.titleMap || [];

            if (this.element && this.element.schema && this.element.schema.$allows_null)
                return [{ $emptyrow: true, [this.formatData?.DisplayField || 'name']: '', [this.formatData?.ValueField || 'value']: null }, ...items ];

            return items;
        },
    },
    watch: {
        lookupurl: function () {
            this.Refresh();
        },
        lookupraw: function () {
            this.Refresh();
        },
    },
    methods: {
        async Refresh() {
            if (this.tolookup) {
                if (this.lookupurlexpn) {
                    let url = this.lookupurl;
                    if (url)
                        try {
                            this.lookupitems = await utils.api.get(url, false, this.formatData.CacheLookupResult);
                        }
                        catch (e) {
                            utils.warn(`Failed in form select lookupurl:${url}`, e);
                            this.lookupitems = [];
                        }
                }
                else if (this.lookuprawexpn) {
                    this.lookupitems = this.lookupraw;
                }
            }

            const items = this.lookupitems || this.titleMap;

            const allowCustom = this.formatData && this.formatData.AllowCustomInput;
            const defaultAsEmpty = this.element.formatData && this.element.formatData.DefaultAsEmpty;

            const allowsNull = this.element && this.element.schema && this.element.schema.$allows_null ? true : false;

            // If no item is already selected, auto-select the first unused item in the list
            if (!allowCustom && !allowsNull && !defaultAsEmpty && Array.isArray(items) && items.length > 0) {
                if (typeof this.item_value === 'function') {
                    let idx = items.findIndex(a => this.itemvalue == this.item_value(a));
                    if (idx < 0) {
                        if (this.itemvalue !== undefined) {
                            const NotFoundObj = {
                                $emptyrow: true,
                                [this.element.formatData.DisplayField || 'text']: '-Not Found-',
                                [this.element.formatData.ValueField || 'value']: null
                            };

                            items.push(NotFoundObj);
                            idx = items.length - 1;
                        }
                        else if (this.arrayfield && this.arrayfield.chosenitems && this.arrayfield.chosenitems[this.chosenitems_key])
                            idx = items.findIndex(a => !(this.item_value(a) in this.arrayfield.chosenitems[this.chosenitems_key]));
                        else
                            idx = 0;

                        if (idx >= 0)
                            this.setValue(this.item_value(items[idx]));
                    }
                }
                else if (typeof this.item_value === 'string') {
                    let idx = items.findIndex(a => this.itemvalue == a[this.item_value]);
                    if (idx < 0) {
                        if (this.itemvalue !== undefined && this.itemvalue !== "") {
                            const NotFoundObj = {
                                $emptyrow: true,
                                [this.element.formatData.DisplayField || 'text']: '-Not Found-',
                                [this.element.formatData.ValueField || 'value']: null
                            };

                            items.push(NotFoundObj);
                            idx = items.length - 1;
                        }
                        else if (this.arrayfield && this.arrayfield.chosenitems && this.arrayfield.chosenitems[this.chosenitems_key])
                            idx = items.findIndex(a => !(a[this.item_value] in this.arrayfield.chosenitems[this.chosenitems_key]));
                        else
                            idx = 0;

                        if (idx >= 0)
                            this.setValue(items[idx][this.item_value]);
                    }
                }

                if (this.arrayfield && this.arrayelement)
                    // Update the array container to control if the Add button is enabled or not
                    Vue.set(this.arrayfield.availableitems, this.chosenitems_key, items && (this.arrayelement.length < items.length));
            }
        },
        getItemValue(item) {
            if (typeof this.item_value === 'function')
                return this.item_value(item);
            else if (typeof this.item_value === 'string')
                return item[this.item_value];
            else
                return null;
        },
        setValue(value) {
            this.sync(value);

            if (this.arrayfield && this.arrayelement) {
                // Uniqueness has been requested and we have a reference to the array of items already picked.
                // We need to generate a lookup object containing every selected item so that we can disable
                // those that are already picked in the current dropdown list.
                this.arrayfield.chosenitems[this.chosenitems_key] = Object.fromEntries(this.arrayelement.map(a => [this.keyextractor(a), true]));

                // Update the array container to control if the Add button is enabled or not
                Vue.set(this.arrayfield.availableitems, this.chosenitems_key, (this.lookupitems && this.arrayelement.length < this.lookupitems.length) || (this.titleMap && this.arrayelement.length < this.titleMap.length));
            }
        },
    },
    render() {
        //const items = this.listitems; // this.lookupitems || this.titleMap || [];

        let Tag = 'v-select';
        let keyup = ()=>{};
        
        if (this.formatData?.AllowCustomInput) {
            Tag = 'v-combobox';
            keyup = (e) => this.setValue(e.target.value);
        } else if (this.formatData?.AutoCompleteInput) {
            Tag = 'v-autocomplete';
        }

        let scopedSlots = {
            message: ({ message }) => {
                return <translation-container context={this} value={message} autoOpenModal></translation-container>
            },
        };
        if (appSettings.DebugTranslationPrefixSetting)
            scopedSlots.item = ({ item, on }) => {
                return (
                    <v-list-item
                        inputValue={this.getItemValue(item) == this.itemvalue}
                        value={this.getItemValue(item)}
                        disabled={this.item_disabled ? this.item_disabled(item) : false}
                        on={on}>
                        <v-list-item-content>
                            <v-list-item-title>
                                <translation-container
                                    context={this}
                                    value={typeof this.item_text === 'function' ? this.item_text(item) : item[this.item_text]}>
                                </translation-container>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                );
            };

        let slots = [
            <translation-container slot="label" context={this} value={this.labelText} autoOpenModal></translation-container>,
        ];
        
        let input = (
            <Tag
                class="caption pa-0 ma-0" outlined single-line dense hide-details
                style={{ width: "100%" }}
                menu-props={{ offsetY: true, auto: true }}
                value={this.itemvalue}
                rules={this.rules}
                items={this.listitems}
                item-text={this.item_text}
                item-value={this.item_value}
                item-disabled={this.item_disabled}
                return-object={false}
                on-change={(value) => this.setValue(value)}
                on-keyup={keyup}
                on-blur={this.onBlur}
                on-focus={this.onFocus}
                scopedSlots={scopedSlots}
                hint={this.hintText}
                persistent-hint={appSettings.DebugTranslationPrefixSetting}
            >{slots}</Tag>
        );

        if (this.appearance)
            input.componentOptions.propsData = { ...input.componentOptions.propsData, ...this.appearance };

        if (this.directives) {
            if (input.data.directives)
                input.data.directives = [...input.data.directives, ...this.directives];
            else
                input.data.directives = this.directives;
        }

        return input;
    }
});