import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import utils from '../../Shared/utils.jsx';
import eventCoordinator from '@/Services/serverEventCoordinator';

Vue.component('server-event-listener', {
    mixins: [BaseComponent],
    data: function () {
        return {
            id: utils.generateUUID(),
            lastSessionId: null,
        }
    },
    props: {},
    computed: {
    },
    created() {
    },
    //Mounted Replaced with preRenderComplete
    destroyed() {
        if (this.lastSessionId)
            this.unsubscribe(this.lastSessionId, this.eventname);
    },
    computed: {
        sessionid: function () {
            if (!this.sessionidexpn)
                this.sessionidexpn = utils.compile(this, this.controlData.SessionID)

            return utils.evaluate(this.sessionidexpn, this);
        },
        eventname: function () {
            if (!this.eventnameexpn)
                this.eventnameexpn = utils.compile(this, this.controlData.EventName);

            return utils.evaluate(this.eventnameexpn, this);
        },
    },
    watch: {
        sessionid: function (newvalue, oldvalue) {
            if (oldvalue)
                this.unsubscribe(oldvalue, this.eventname);

            if (newvalue)
                this.subscribe(newvalue, this.eventname);

            this.lastSessionId = newvalue;
        },
    },
    methods: {
        preRenderComplete() {
            if (this.sessionid)
                this.subscribe(this.sessionid, this.eventname);

            this.lastSessionId = this.sessionid;

            this.finishRenderHandler(this);
        },
        onServerEventRecieved(eventData) {
            utils.executeAndCompileAllActions(this.controlData.Actions, { EventData: eventData }, this);
        },

        subscribe(sessionid, eventname) {
            if (this.controlData.AllowMultipleSessionIDs)
                sessionid.split(' ').forEach(s => eventCoordinator.subscribeForSessionEvents(s, this.id, eventname, this.onServerEventRecieved));
            else
                eventCoordinator.subscribeForSessionEvents(sessionid, this.id, eventname, this.onServerEventRecieved);
        },
        unsubscribe(sessionid, eventname) {
            if (this.controlData.AllowMultipleSessionIDs)
                sessionid.split(' ').forEach(s => eventCoordinator.unsubscribeForSessionEvents(s, this.id, eventname));
            else
                eventCoordinator.unsubscribeForSessionEvents(sessionid, this.id, eventname);
        },
    },
    render(h) {
        return null;
    }
});