<template>
    <v-card flat>
        <v-tabs v-model="tab" _background-color="deep-purple accent-4" __background-color="grey" _dark>
            <v-tab v-if="categories">
                <span color="primary" dot offset-y="20" offset-x="30" :value="!allCategoriesSelected">
                    Categories
                    <!--<v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon dense color="grey lighten-3" class="ml-1" v-bind="attrs" v-on="on">mdi-information-slab-circle</v-icon>
                        </template>
                        Controls visibility of metric categories
                    </v-tooltip>-->
                </span>
            </v-tab>

            <v-tab v-if="datatypes">
                <span color="primary" dot offset-y="20" offset-x="30" :value="!allDataTypesSelected">
                    Data Types
                    <!--<v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon dense color="grey lighten-3" class="ml-1" v-bind="attrs" v-on="on">mdi-information-slab-circle</v-icon>
                        </template>
                        Controls visibility of metrics by data type
                    </v-tooltip>-->
                </span>
            </v-tab>

            <v-tab v-if="debugMode && sources">
                <span color="primary" dot offset-y="20" offset-x="30" :value="!allSourcesSelected">
                    Sources
                    <!--<v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon dense color="grey lighten-3" class="ml-1" v-bind="attrs" v-on="on">mdi-information-slab-circle</v-icon>
                        </template>
                        Controls visibility of metrics by index source
                    </v-tooltip>-->
                </span>
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item v-if="categories">
                <v-card flat _style="max-height: 500px;">
                    <v-card-subtitle class="mt-2">Filter by category to focus on data related to specific functional areas.</v-card-subtitle>
                    <v-btn small text color="primary" @click="() => allCategoriesSelected ? selectNoCategories() : selectAllCategories()">{{ allCategoriesSelected ? 'DESELECT ALL' : 'SELECT ALL' }}</v-btn>

                    <v-list flat dense style="max-height: 500px; overflow: auto;">
                        <v-list-item-group multiple v-model="category_selection" color="primary">
                            <template v-for="item in categories">
                                <v-list-item :key="item.text" class="mediumdense" dense>
                                    <template v-slot:default="{ active }">
                                        <v-list-item-action>
                                            <v-checkbox :input-value="active"></v-checkbox>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            {{ item.text }}
                                        </v-list-item-content>
                                    </template>
                                </v-list-item>
                            </template>
                        </v-list-item-group>
                    </v-list>
                </v-card>
            </v-tab-item>

            <v-tab-item v-if="datatypes">
                <v-card flat _style="max-height: 500px;">
                    <v-card-subtitle class="mt-2">Filter by data type (e.g. only show items applicable to durations).</v-card-subtitle>
                    <v-btn small text color="primary" @click="() => allDataTypesSelected ? selectNoDataTypes() : selectAllDataTypes()">{{ allDataTypesSelected ? 'DESELECT ALL' : 'SELECT ALL' }}</v-btn>
                    <v-list flat dense style="max-height: 500px; overflow: auto;">
                        <v-list-item-group multiple v-model="datatypes_selection" color="primary">
                            <template v-for="item in datatypes">
                                <v-list-item :key="item.text" class="mediumdense">
                                    <template v-slot:default="{ active }">
                                        <v-list-item-action>
                                            <v-checkbox :input-value="active"></v-checkbox>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            {{ item.text }}
                                        </v-list-item-content>
                                    </template>
                                </v-list-item>
                            </template>
                        </v-list-item-group>
                    </v-list>
                </v-card>
            </v-tab-item>

            <v-tab-item v-if="debugMode && sources">
                <v-card flat _style="max-height: 500px;">
                    <v-card-subtitle class="mt-2">Filters by source (e.g. only show items applicable to surveys).</v-card-subtitle>
                    <v-btn small text color="primary" @click="() => allSourcesSelected ? selectNoSources() : selectAllSources()">{{ allSourcesSelected ? 'DESELECT ALL' : 'SELECT ALL' }}</v-btn>
                    <v-list flat dense style="max-height: 500px; overflow: auto;">
                        <v-list-item-group multiple v-model="sources_selection" color="primary">
                            <template v-for="item in sources">
                                <v-list-item :key="item.text" class="mediumdense">
                                    <template v-slot:default="{ active }">
                                        <v-list-item-action>
                                            <v-checkbox :input-value="active"></v-checkbox>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            {{ lookupSourceType(item.text) || `(${item.text}) *Missing src` }}
                                        </v-list-item-content>
                                    </template>
                                </v-list-item>
                            </template>
                        </v-list-item-group>
                    </v-list>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
    </v-card>

    <!--<v-list>
        <v-list-item-group multiple v-model="selection" color="primary">
            <template v-for="item in columns">
                <v-subheader v-if="item.divider" :key="item.text + '2'">
                    {{ item.text }}
                    <v-spacer></v-spacer>
                    <v-btn small plain title="Select All" @click="selectAllOf(item.text)">All</v-btn>
                </v-subheader>

                <v-list-item v-if="!item.divider" :key="item.text" class="mediumdense">
                    <template v-slot:default="{ active }">
                        <v-list-item-action>
                            <v-checkbox :input-value="active"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                            {{ item.text }}
                        </v-list-item-content>
                    </template>
                </v-list-item>
            </template>
        </v-list-item-group>
    </v-list>-->
</template>

<script>
    import common from './common.js';

    export default {
        data: function () {
            return {
                tab: 0,
                datatypes_selection: [],
                category_selection: [],
                sources_selection: [],
            }
        },
        props: {
            debugMode: null,
            datatypes: null,
            categories: null,
            sources: null,
            initial_datatypes_selection: null,
            initial_category_selection: null,
            initial_sources_selection: null,
        },
        created() {
            if (this.initial_datatypes_selection)
                this.datatypes_selection = this.initial_datatypes_selection;
            if (this.initial_category_selection)
                this.category_selection = this.initial_category_selection;
            if (this.initial_sources_selection)
                this.sources_selection = this.initial_sources_selection;
        },
        watch: {
            datatypes_selection: function (newv) {
                this.$emit('datatypes_changed', newv);
                this.notifyIfAnyFilters();
            },
            category_selection: function (newv) {
                this.$emit('categories_changed', newv);
                this.notifyIfAnyFilters();
            },
            sources_selection: function (newv) {
                this.$emit('sources_changed', newv);
                this.notifyIfAnyFilters();
            },
        },
        computed: {
            allDataTypesSelected: function () {
                if (this.datatypes_selection && this.datatypes)
                    return this.datatypes_selection.length >= this.datatypes.length;
                else
                    return false;
            },
            allCategoriesSelected: function () {
                if (this.category_selection && this.categories)
                    return this.category_selection.length >= this.categories.length;
                else
                    return false;
            },
            allSourcesSelected: function () {
                if (this.sources_selection && this.sources)
                    return this.sources_selection.length >= this.sources.length;
                else
                    return false;
            },
        },
        methods: {
            notifyIfAnyFilters() {
                this.$emit('anyfilters', !(this.allDataTypesSelected && this.allCategoriesSelected && this.allSourcesSelected));
            },
            lookupSourceType(text) {
                return common.sourceTypes[text];
            },
            selectAllDataTypes() {
                this.datatypes_selection = this.datatypes.map((a, i) => i);
            },
            selectNoDataTypes() {
                this.datatypes_selection = [];
            },
            selectAllCategories() {
                this.category_selection = this.categories.map((a, i) => i);
            },
            selectNoCategories() {
                this.category_selection = [];
            },
            selectAllSources() {
                this.sources_selection = this.sources.map((a, i) => i);
            },
            selectNoSources() {
                this.sources_selection = [];
            },
        }
    }
</script>

<style scoped>

</style>