<template>
    <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-btn text x-small v-bind="attrs" v-on="on" class="ma-0">
                {{ label }}{{ items[selection]?.text || 'Not selected' }}
                <v-icon small>mdi-menu-down</v-icon>
            </v-btn>
        </template>

        <v-list>
            <v-list-item-group v-model="selection" color="primary">

                <v-list-item v-for="item in items" :key="item.value" class="mediumdense">
                    <template v-slot:default>

                        <!--<v-list-item-action>
                            <v-checkbox :input-value="active"></v-checkbox>
                        </v-list-item-action>-->

                        <v-list-item-title>
                            {{ item.text }}
                        </v-list-item-title>

                    </template>
                </v-list-item>

            </v-list-item-group>
        </v-list>
    </v-menu>
</template>

<script>
    module.exports = {
        data: function () {
            return {
                selection: [2],
            }
        },
        props: {
            label: String,
            items: Array,
            value: Number,
        },
        watch: {
            selection: function(newv) {
                this.$emit('input', newv);
            }
        },
        created() {
            this.selection = this.value;
        },
    }
</script>

<style scoped>

    .mediumdense {
        max-height: 28px;
        min-height: 28px;
    }

</style>