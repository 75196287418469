import Vue from 'vue';
import HorizontalStack from './HorizontalStack.jsx';
import VerticalStack from './VerticalStack.jsx';
import BasicButton from './BasicButton.jsx';
import Text from './Text.jsx';
import Icon from './Icon.jsx';
//import SelectList from './SelectList.jsx';
import BasicForm from './BasicForm.jsx';
import BasicGrid from './BasicGrid.jsx';
import TreeView from './TreeView.jsx';
import DefaultUnknown from './DefaultUnknown.jsx';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';
import controlPicker from './controlPicker.jsx';

function flatMap(arr, mapFunc) {
    const result = [];
    for (const [index, elem] of arr.entries()) {
        const x = mapFunc(elem, index, arr);
        // We allow mapFunc() to return non-Arrays
        if (Array.isArray(x)) {
            result.push(...x);
        } else {
            result.push(x);
        }
    }
    return result;
}
function getMenus(menu)
{
    let items = [menu.element];
    for (let i = 0; i < menu.children.length; i++)
        items = [...items, ...getMenus(menu.children[i])];

    return items;
}
function findParentMenu(parent, path)
{
    let targetname = path;
    if (path.includes('::'))
        targetname = path.split('::')[0];

    const menu = parent.find(m => m.name === targetname);
    if (!menu)
        return null;

    if (path.includes('::')) {
        const index = path.indexOf('::');
        targetname = path.substr(index + 2);
        return findParentMenu(menu.children, targetname);
    }
    return menu;
}
function addPlusButton(h, context, level, menus) {
    for (let i = 0; i < menus.length; i++) {
        const menu = menus[i];
        if (menu.children.length > 0) {
            const lastchild = menu.children[menu.children.length - 1];
            let lastindex = "0";
            let menupath = "";
            if (lastchild.menu) {
                lastindex = lastchild.menu.Index;
                menupath = lastchild.menu.MenuPath;
            }

            const next_index = parseInt(lastindex) + 1;

            menu.children.push({
                menu: null,
                name: '',
                element: (
                    <tr>
                        <td colspan="4" style={{ width: "100%", borderBottom: "1px solid silver" }}>
                            <span style={{ paddingLeft: (15 + 30 * level) + "px" }}>
                                <i class="mdi mdi-plus" title={'Add Submenu Item to ' + menu.name + ' after index ' + lastindex} style={{ cursor: "pointer" }} on-click={(e) => context.addChildMenuItem(e, next_index, menupath)}></i>
                            </span>
                        </td>
                    </tr>
                ),
                children: [],
            });

            addPlusButton(h, context, level + 1, menu.children);
        }
        else if (menu.menu && (!menu.menu.MenuItemData.ControlData || !menu.menu.MenuItemData.ControlData.Controls || menu.menu.MenuItemData.ControlData.Controls.length == 0)) {
            // This is a top-level menu with no body, we will assume it is for child menus and add a plus, if not, when they add a body, this will disappear.
            let menupath = menu.menu.MenuPath || '';
            if (menupath) menupath += '::';
            menupath += menu.menu.MenuItemData.Title;

            const next_index = menu.menu.Index + "1";

            menu.children.push({
                menu: null,
                name: '',
                element: (
                    <tr>
                        <td colspan="4" style={{ width: "100%", borderBottom: "1px solid silver" }}>
                            <span style={{ paddingLeft: (15 + 30 * level) + "px" }}>
                                <i class="mdi mdi-plus" title={'Add Submenu Item to ' + menu.name} style={{ cursor: "pointer" }} on-click={(e) => context.addChildMenuItem(e, next_index, menupath)}></i>
                            </span>
                        </td>
                    </tr>
                ),
                children: [],
            });
        }
    }
}
function getPreviousTopMenu(index, childMenus) {
    index--;
    while (index >= 0)
    {
        const menu = childMenus[index];
        if (!menu.MenuPath) return menu;
        index--;
    }
    return null;
}
function getNextTopMenu(index, childMenus) {
    index++;
    while (index < childMenus.length) {
        const menu = childMenus[index];
        if (!menu.MenuPath) return menu;
        index++;
    }
    return null;
}
function getPreviousChildMenu(menuPath, index, childMenus) {
    // Scan childMenus looking for the same MenuPath, find the one just before the one with the specified index
    let menu;
    for (let i = 0; i < childMenus.length; i++) {
        if (childMenus[i].MenuPath == menuPath) {
            if (childMenus[i].Index == index && menu) return menu;
            menu = childMenus[i];
        }
    }
    return null;
}
function getNextChildMenu(menuPath, index, childMenus) {
    // Scan childMenus looking for the same MenuPath, find the one just after the one with the specified index
    let menu;
    for (let i = 0; i < childMenus.length; i++) {
        if (childMenus[i].MenuPath == menuPath) {
            if (childMenus[i].Index == index && !menu)
                menu = childMenus[i];
            else if (menu)
                return childMenus[i];
        }
    }
    return null;
}

Vue.component('carecenter-menu-dsgn', {
    data: function () {
        return {
            childMenus: null,
            deleteEnabled: false,
            pickervisible: false,
            picker: null,
            pickertarget: 'body',
        }
    },
    created() {
        if (this.designmodel && this.designmodel._id)
            this.$watch('designmodel._id', function (val, oldval) {
                if ((!this.controlData || !this.controlData.Controls || this.controlData.Controls.length === 0) && this.designmodel.MenuItemData.ChildMenuType)
                    this.RefreshChildMenus();
                else
                    this.childMenus = null;
            });
    },
    computed: {
        Root: function () {
            return this.root._self;
        },
        selected$: function () {
            return this.Root.SelectedNode && this.Root.SelectedModel == this.designmodel;
        }
    },
    mounted() {
        if ((!this.controlData || !this.controlData.Controls || this.controlData.Controls.length === 0) && this.designmodel.MenuItemData.ChildMenuType)
            this.RefreshChildMenus();
        else
            this.childMenus = null;

    },
    methods: {
        ...methods,
        handleClick(e) {
            this.Root.SelectNode(this);
            e.cancelBubble = true;
            e.stopPropagation();
        },
        DesignModel: function () {
            return this.designmodel;
        },
        async RefreshChildMenus() {
            switch (this.designmodel.MenuItemData.ChildMenuType) {
                case 'MenuName':
                    this.childMenus = await utils.api.get('Apps/MenuItems/ListAll/' + this.designmodel.MenuItemData.ChildMenuName);
                    break;

                case 'URL':
                    this.childMenus = await utils.api.get(this.designmodel.MenuItemData.ChildMenuURL);
                    break;

                case 'PopupMenu':
                    this.childMenus = await utils.api.get('Apps/MenuItems/ListAll/' + this.designmodel.MenuItemData.PopupMenuName);
                    break;

                default:
                    return;
            }
            this.childMenus.sort((a, b) => a.Index - b.Index);
        },
        jumpToMenu(e, menu) {
            this.childMenus = null;
            this.Root.ExternalNavigate('Apps/UI/MenuItem/' + menu._id);

            e.cancelBubble = true;
            e.stopPropagation();
        },
        addChildMenuItem(e, index, path) {
            this.childMenus = null;
            let url = 'Apps/SchemaBootstrap/NewEmptyDocument/MenuChild';
            url += '?MenuName=' + this.designmodel.MenuItemData.ChildMenuName;
            url += '&MenuIndex=' + index;
            url += '&MenuPath=' + (path || '');
            url += '&uuid=' + utils.generateUUID(); // Causes the URL to be unique to insure the Vue engine will re-render

            this.Root.ExternalNavigate(url);

            e.cancelBubble = true;
            e.stopPropagation();
        },
        async swapMenuIndexes(e, menu1, menu2) {
            const url = 'Apps/UI/SwapMenuItemIndexes/' + menu1._id + '/' + menu2._id;
            const res = await utils.api.get(url);
            if (res.Menu1.Success.Success && res.Menu2.Success.Success)
                this.RefreshChildMenus();
            else
                alert(JSON.stringify(res, null, 4));
        },
        async deleteMenu(e, menu) {
            const url = 'Apps/UI/MenuItem/' + menu._id;
            const res = await utils.api.request('DELETE', url);
            if (res === true)
                this.RefreshChildMenus();
            else
                alert(JSON.stringify(res, null, 4));
        },
        addControl(e, target) {
            this.pickertarget = target;
            this.picker = <control-picker confirm={this.confirmAddControl} cancel={this.cancelAddControl}></control-picker>;
            this.pickervisible = true;
            e.cancelBubble = true;
            e.stopPropagation();
        },
        async confirmAddControl(id, title) {
            this.pickervisible = false;

            try {
                const schema_ = await utils.schema.get(id);
                const schema = utils.schema.resolve_Of(schema_)
                const model = utils.schema.getDefaultModel(schema);

                switch (this.pickertarget) {
                    case 'header':
                        if (!this.controlData.HeaderControls)
                            Vue.set(this.controlData, 'HeaderControls', []);

                        this.controlData.HeaderControls.push(model);
                        // Hmmm. How to select the node, it isn't created yet, not until it is rendered when this layout stack re-renders
                        //this.Root.SelectNode(model);
                        break;

                    case 'body':
                        if (!this.controlData.Controls)
                            Vue.set(this.controlData, 'Controls', []);

                        this.controlData.Controls.push(model);
                        //this.Root.SelectNode(model);
                        break;
                }
            }
            catch (e) {
                alert(e);
            }
        },
        cancelAddControl() {
            this.pickervisible = false;
        },
        pickerInput(value) {
            this.pickervisible = value;
        },
    },
    props: {
        name: '',
        root: null,
        designmodel: null,
        controlData: {}
    },
    render(h) {
        let icontitle;
        if (this.designmodel.MenuItemData.Icon && this.designmodel.MenuItemData.Icon.Icon)
            icontitle = (
                <span style={{ fontSize: "x-large" }}>
                    <i title={this.designmodel.MenuItemData.Icon.Tooltip} class={this.designmodel.MenuItemData.Icon.Icon}></i> {this.designmodel.MenuItemData.Title}
                </span>
            );
        else if (this.designmodel.MenuItemData.Icon)
            icontitle = (
                <span style={{ fontSize: "x-large" }}>
                    <i title={this.designmodel.MenuItemData.Icon.Tooltip} class={this.designmodel.MenuItemData.Icon}></i> {this.designmodel.MenuItemData.Title}
                </span>
            );
        else
            icontitle = (
                <span style={{ fontSize: "x-large" }}>
                    <i title="Undefined" class="mdi mdi-help-circle"></i> {this.designmodel.MenuItemData.Title}
                </span>
            );

        if (this.childMenus) {
            const menus = [];

            const style = {
                textDecoration: "underline",
                cursor: "pointer",
                overflow: "hidden",
                whiteSpace: "nowrap",
                display: "inline-block",
                padding: "5px 15px 5px 15px",
                width: "300px",
            };

            for (let i = 0; i < this.childMenus.length; i++) {
                const menu = this.childMenus[i];
                let icon;
                if (menu.MenuItemData.Icon)
                    icon = <i class={menu.MenuItemData.Icon.Icon}></i>;

                let label = menu.MenuItemData.Title;
                let menuname;
                if (menu.MenuItemData.Name)
                    menuname = <span style={{ color: "gray" }}>({menu.MenuItemData.Name})</span>;

                if (menu.MenuPath) continue;

                const lastmenu = getPreviousTopMenu(i, this.childMenus);
                const nextmenu = getNextTopMenu(i, this.childMenus);

                let moveUp;
                let moveDown;
                let deleteicon;
                if (lastmenu)
                    moveUp = <i class="mdi mdi-menu-up simple_link" title="Move Up" on-click={(e) => this.swapMenuIndexes(e, menu, lastmenu)}></i>;
                if (nextmenu)
                    moveDown = <i class="mdi mdi-menu-down simple_link" title="Move Down" on-click={(e) => this.swapMenuIndexes(e, menu, nextmenu)}></i>;
                if (this.deleteEnabled)
                    deleteicon = <i class="mdi mdi-close simple_link" on-click={(e) => this.deleteMenu(e, menu)} title="Delete"></i>;

                menus.push({
                    menu: menu,
                    name: menu.MenuItemData.Title,
                    element: (
                        <tr key={menu._id}>
                            <td style={{ width: "100%", borderBottom: "1px solid silver" }}>
                                <span
                                    title="Edit Menu"
                                    style={style}
                                    on-click={(e) => this.jumpToMenu(e, menu)}>
                                    {icon} {label} {menuname}
                                </span>
                            </td>
                            <td style={{ whiteSpace: "nowrap", fontSize: "smaller", borderBottom: "1px solid silver", paddingRight: "5px", alignItems: "right" }}>
                                {menu.Role}
                            </td>
                            <td style={{ whiteSpace: "nowrap", fontSize: "smaller", borderBottom: "1px solid silver" }}>
                                {deleteicon} [{menu.Index}]
                            </td>
                            <td style={{ borderBottom: "1px solid silver" }}>
                                <span style={{ whiteSpace: "nowrap", color: "gray", marginLeft: "10px" }}>
                                    {moveUp} {moveDown}
                                </span>
                            </td>
                        </tr>
                    ),
                    children: [],
                });
            }
            let lastindex_top = "0";
            if (menus.length > 0)
            {
                const lastmenu = menus[menus.length - 1];
                lastindex_top = lastmenu.menu.Index;
            }
            const next_index = parseInt(lastindex_top) + 1;
            menus.push({
                menu: null,
                name: '',
                element: (
                    <tr>
                        <td colspan="4">
                            <span style={{ paddingLeft: "15px" }}>
                                <i class="mdi mdi-plus" title={'Add Top Level Menu Item after index ' + lastindex_top} style={{ cursor: "pointer" }} on-click={(e) => this.addChildMenuItem(e, next_index)}></i>
                            </span>
                        </td>
                    </tr>
                ),
                children: [],
            });

            for (let i = 0; i < this.childMenus.length; i++) {
                const menu = this.childMenus[i];
                let icon;
                if (menu.MenuItemData.Icon)
                    icon = <i class={menu.MenuItemData.Icon.Icon}></i>;

                let label = menu.MenuItemData.Title;
                let menuname;
                if (menu.MenuItemData.Name)
                    menuname = <span style={{ color: "gray" }}>({menu.MenuItemData.Name})</span>;

                if (menu.MenuPath) {
                    const parent = findParentMenu(menus, menu.MenuPath);
                    if (parent) {
                        const lastmenu = getPreviousChildMenu(menu.MenuPath, menu.Index, this.childMenus);
                        const nextmenu = getNextChildMenu(menu.MenuPath, menu.Index, this.childMenus);

                        let moveUp;
                        let moveDown;
                        let deleteicon;
                        if (lastmenu)
                            moveUp = <i class="mdi mdi-menu-up simple_link" title="Move Up" on-click={(e) => this.swapMenuIndexes(e, menu, lastmenu)}></i>;
                        if (nextmenu)
                            moveDown = <i class="mdi mdi-menu-down simple_link" title="Move Down" on-click={(e) => this.swapMenuIndexes(e, menu, nextmenu)}></i>;
                        if (this.deleteEnabled)
                            deleteicon = <i class="mdi mdi-close simple_link" on-click={(e) => this.deleteMenu(e, menu)} title="Delete"></i>;

                        const count = (menu.MenuPath.match(/::/g) || []).length + 1;
                        parent.children.push({
                            menu: menu,
                            name: menu.MenuItemData.Title,
                            element: (
                                <tr key={menu._id}>
                                    <td style={{ width: "100%", borderBottom: "1px solid silver" }}>
                                        <span
                                            title="Edit Menu"
                                            style={{ ...style, paddingLeft: (15 + 30 * count) + "px" }}
                                            on-click={(e) => this.jumpToMenu(e, menu)}>
                                            {icon} {label} {menuname}
                                        </span>
                                    </td>
                                    <td style={{ whiteSpace: "nowrap", fontSize: "smaller", borderBottom: "1px solid silver", paddingRight: "5px", alignItems: "right" }}>
                                        {menu.Role}
                                    </td>
                                    <td style={{ whiteSpace: "nowrap", fontSize: "smaller", borderBottom: "1px solid silver" }}>
                                        {deleteicon} [{menu.Index}]
                                    </td>
                                    <td style={{ borderBottom: "1px solid silver" }}>
                                        <span style={{ whiteSpace: "nowrap", color: "gray", marginLeft: "10px" }}>
                                            {moveUp} {moveDown}
                                        </span>
                                    </td>
                                </tr>
                            ),
                            children: [],
                        });

                        continue;
                    }
                    else
                        label = [menu.MenuPath, <i class="mdi mdi-menu-right" style={{ marginLeft: "3px", marginRight: "3px" }}></i>, label];
                }
            }

            addPlusButton(h, this, 1, menus);

            let allmenus = [];
            for (let i = 0; i < menus.length; i++)
                allmenus = [...allmenus, ...getMenus(menus[i])];

            const tablestyle = {
                margin: "10px",
                padding: "10px",
                borderStyle: "solid",
                borderColor: "black",
                borderWidth: "1px",
                borderRadius: "5px",
            };

            return (
                <div
                    class={{ 'designer-container': true, selected: this.selected$ }}
                    style={tablestyle}
                    on-click={(e) => this.handleClick(e)}>
                    <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
                    <div class="design-options" on-click={(e) => this.deleteEnabled = !this.deleteEnabled} style={{ top: "4px", right: "4px", display: this.selected$ ? "block" : "none" }}>{this.deleteEnabled ? 'Cancel' : 'Delete Items'}</div>

                    {icontitle}
                    <table style={{ borderCollapse: "collapse", border: "1px solid silver" }}>
                        {allmenus}
                    </table>
                </div>
            );
        }
        else if (!this.controlData || !this.controlData.Controls)
            return null;

        let items = [];

        if (this.selected$ || (this.controlData.HeaderControls && this.controlData.HeaderControls.length > 0)) {
            utils.getHeaderControls(h, this.controlData, items, this.root, "ControlContainer", this.addControl, this.selected$);
        }

        for (let i = 0; i < this.controlData.Controls.length; i++)
        {
            const control = this.controlData.Controls[i];
            let DynamicControl = utils.getDynamicComponent(h, control);

            if (!DynamicControl)
                DynamicControl = 'default-unknown';

            DynamicControl += '-dsgn';

            items.push(
                <DynamicControl type={control.ControlType} name={control.ControlData.Name} root={this.root} designmodel={control} controlData={control.ControlData}>
                </DynamicControl>
            );
        }

        if (this.selected$) {
            items.push(
                <div class="property-grid">
                    {utils.generateTooltip(h,
                        <v-btn text small icon style={{ zIndex: 2 }} slot="activator" on-click={(e) => this.addControl(e, 'body')}>
                            <v-icon small color="blue darken-2">mdi mdi-plus-circle</v-icon>
                        </v-btn>,
                     'Add control to body', 'right')}
                </div>
            );
        }

        return (
            <div
                class={{ 'designer-container': true, selected: this.selected$ }}
                style={{ ...utils.getSize(this.controlData.SizeOptions) }}
                on-click={(e) => this.handleClick(e)}>
                <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
                {icontitle}
                {this.name}<br />
                {items}

                <v-dialog value={this.pickervisible} max-width="860" scrollable on-input={(value) => this.pickerInput(value)}>
                    {this.picker}
                </v-dialog>
            </div>
        );
    }
});