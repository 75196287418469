<template v-if="todisplay">
    <form v-show="isvisible" enctype="multipart/form-data" novalidate :class="classes">
                   
                    <v-file-input class="input-file ma-1"
                                type="file"
                                v-model="files"
                                outlined                   
                                dense
                                show-size
                                :hint="tooltip"   
                                persistent-hint               
                                :placeholder="Translate(labelText)"
                                prepend-icon=""
                                prepend-inner-icon="mdi-cloud-upload-outline"
                                :error-messages="errors"
                                :success-messages="successes"
                                :loading="isSaving"
                                :multiple="multiple"
                                :disabled="isSaving"
                                :accept="aceptTypes"
                                @change="filesChange">                                  

                    <template v-slot:selection="{ index, text }">
                        <v-chip v-if="index < 2"
                                color="deep-purple accent-4"
                                dark
                                label
                                small>
                            {{ text }}
                        </v-chip>

                        <span v-else-if="index === 2"
                            class="text-overline grey--text text--darken-3 mx-2">
                            +{{ files.length - 2 }} File(s)
                        </span>
                    </template>

                    <template v-slot:append-outer v-if="files.length > 0">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn elevation="0" small icon @click="doUpload"
                                    v-bind="attrs"
                                    v-on="on">
                                    <v-icon class="blink-text" color="pink">mdi-folder-upload-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Upload Now</span>
                        </v-tooltip>
                    </template>

                    </v-file-input>
                
            </form>
</template>

<script>
import Vue from 'vue';
import utils from '../../Shared/utils.jsx';
import BaseComponentMixin from './BaseComponentMixin.jsx';
import api from '@/Services/api';

    export default {
        name: 'BasicFileUpload',
        mixins: [BaseComponentMixin],
        data: function () {
            return {
                files: [],
                finalUploadURL: '',
                queryString: '',

                isSaving: false,
                successes: [],
                errors: [],

                uploadURLexpn: null,
                uploadPathexpn: null,
                aceptTypesexpn: null,
                labelText: 'To upload, click to browse'
            }
        },
        computed: {
            classes() {
                return { [`c-${this.type}`]: true, [`c-name-${this.name || 'unnamed'}`]: true };
            },
            styles() {
                return {
                    ...utils.resolveStyleHints(this.styleHints, this),
                    ...this.sizeStyle,
                }
            },
            uploadURL() {
                if (!this.uploadURLexpn && this.controlData.UploadURL)
                    this.uploadURLexpn = utils.compile(this, this.controlData.UploadURL);

                return this.uploadURLexpn ? utils.evaluate(this.uploadURLexpn, this) : '';
            },
            uploadPath() {
                if (!this.uploadPathexpn && this.controlData.UploadPath)
                    this.uploadPathexpn = utils.compile(this, this.controlData.UploadPath);

                return this.uploadPathexpn ? utils.evaluate(this.uploadPathexpn, this) : '';
            },
            aceptTypes() {
                if (!this.aceptTypesexpn && this.controlData.AcceptTypes)
                    this.aceptTypesexpn = utils.compile(this, this.controlData.AcceptTypes);

                return this.aceptTypesexpn ? utils.evaluate(this.aceptTypesexpn, this) : '';
            },
            multiple() {
                return this.controlData.AllowMultiple;
            },
            tooltip() {
                if (this.controlData.Tooltip)
                    return this.controlData.Tooltip;
                return '';
             },
            
        },
        created() {
            if (this.controlData.Label) {
                this.labelText = this.controlData.Label;
            }
        },
        methods: {
            preRenderComplete() {
                this.finishRenderHandler(this);
            },
            async filesChange(fileList) {
                this.errors = [];
                this.successes = [];

                this.files = [];
                
                if (!fileList) return;
                if (Array.isArray(fileList) && !fileList.length) return;

                if (Array.isArray(fileList))
                    this.files = fileList; //list of files selected.
                else
                    this.files.push(fileList); //fileList is the single file that was selected.
                

                this.constructUploadURL();

                if (!this.controlData.UploadFilesAutomatically)
                    return;

                await this.uploadFileList(this.files);
            },
            constructUploadURL() {
                let finalURL = this.uploadURL || "/Apps/FileBinary2";

                if(finalURL == "/Apps/FileBinary2")
                {
                    this.finalUploadURL = finalURL;
                    this.queryString = "?FullNameWithPath=";
                    
                    if (this.uploadPath) {
                        if (this.uploadPath.substr(0, 1) == '/') // skip the leading / if exists
                            this.queryString += this.uploadPath.substr(1, this.uploadPath.length);
                        else
                            this.queryString += this.uploadPath;

                        if (this.uploadPath.substr(this.uploadPath.length - 1, 1) != '/') // if the uploadPath doesn't end with / append it
                            this.queryString += "/";
                    }
                } else {
                    if (finalURL.substr(finalURL.length - 1, 1) != '/') // if the url doesn't end with / append it
                        finalURL += "/";

                    if (this.uploadPath) {
                        if (this.uploadPath.substr(0, 1) == '/') // skip the leading / if exists
                            finalURL += this.uploadPath.substr(1, this.uploadPath.length);
                        else
                            finalURL += this.uploadPath;

                        if (finalURL.substr(finalURL.length - 1, 1) != '/') // if the url doesn't end with / append it
                            finalURL += "/";
                    }


                    this.finalUploadURL = finalURL;
                }
                
                utils.debug(`finalUploadURL:${this.finalUploadURL}    querystring:${this.queryString}`);
            },
            readFileAsync(file) {
                const c = this;

                return new Promise((resolve, reject) => {
                    var reader = new FileReader();
                    reader.onload = function (event) {
                        file.data = event.target.result; // event.target.result varies depending on which method was called on the reader (readAsDataURL || readAsArrayBuffer || readAsText) 

                        utils.executeAndCompileAllActions(c.controlData.FileUploadActions, file, c);

                        resolve(file);
                    };

                    reader.onerror = reject;

                    if (this.controlData.ActionType == 'DataURL')
                        reader.readAsDataURL(file);
                    else if (this.controlData.ActionType == 'BinaryData')
                        reader.readAsArrayBuffer(file);
                    else
                        reader.readAsText(file);
                });
            },
            async uploadFileList(fileList) {
                this.isSaving = true;
                try {
                    const res = fileList.map(f => this.uploadFile(f));
                    await Promise.all(res);

                    this.files = [];
                    this.errors = [];
                    this.successes.push(this.Translate(`Uploaded {{COUNT}} file(s) successfully`).replace('{{COUNT}}', `${fileList.length}`));

                    await utils.executeAndCompileAllActions(this.controlData.Actions, fileList, this);
                }
                catch (e) {
                    utils.warn('uploadFileList failed', e);
                    this.errors.push(this.Translate(e.statusText));
                    await utils.executeAndCompileAllActions(this.controlData.FailureActions, e, this);
                }
                finally {
                    this.isSaving = false;
                }
                return;
            },
            async uploadFile(file) {
                if (this.controlData.ActionType == 'DataURL' || this.controlData.ActionType == 'BinaryData' || this.controlData.ActionType == 'TextData') {
                    return await this.readFileAsync(file);
                }
                else // c.controlData.ActionType == 'UploadURL'
                {
                    var data = new FormData(); //FILL FormData WITH FILE DETAILS.

                    data.append("uploadedFile", file);
                    var fileName = encodeURIComponent(file.name);

                    try {
                        const response = await api.apiRequest({
                            method: 'POST',
                            data: data,
                            url: this.finalUploadURL + this.queryString + fileName,
                            doNotUseWebsocket: true,
                            multipart: true // required to set the headers properly to allow multi-part data to be uploaded
                        });                    

                        if (!this.controlData.SuppressUploadToast)
                            ;//toastr.success('', 'File ' + file.name + ' uploaded successfully.');

                        if (response.data && response.data.Result)
                            file.data = response.data.Result;
                        else if (response.data)
                            file.data = response.data;

                        await utils.executeAndCompileAllActions(this.controlData.FileUploadActions, file, this);

                        return file;
                    }
                    catch (error) {
                        await utils.executeAndCompileAllActions(this.controlData.FileUploadFailureActions, { error, file }, this);

                        throw (error);
                    };
                }
            },
            doUpload() {
                this.uploadFileList(this.files);
            },
        },
        props: {
        },
    };
</script>

<style lang="scss" scoped>
    .input-file {
        min-width: 280px;
    }

    .blink-text {
        animation: blink 1s linear infinite;
    }

    @keyframes blink {
        0% {
            opacity: .33;
        }
        50% {
            opacity: .66;
        }
        100% {
            opacity: 1;
        }
    }
</style>