import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

Vue.component('basic-text-dsgn', {
    data: function () {
        return {
        }
    },
    created() {
    },
    computed: {
        Root: function () {
            return this.root._self;
        },
        textStyles: function () {
            const styles = {};

            if (this.controlData.FontSize)
                styles['font-size'] = utils.resolveFontSize(this.controlData.FontSize);

            if (this.controlData.Color)
                styles.color = this.controlData.Color;
            else
                styles.color = '#444';

            return styles;
        },
        selected$: function () {
            return this.Root.SelectedNode && this.Root.SelectedModel == this.designmodel;
        },
    },
    methods: {
        ...methods,
        handleClick(e) {
            this.Root.SelectNode(this);
            e.cancelBubble = true;
            e.stopPropagation();
        },
    },
    props: {
        name: '',
        root: null,
        designmodel: null,
        parentType: '',
        controlData: {}
    },
    render(h) {
        const style = {
            ...utils.getSize(this.controlData.SizeOptions, this.parentType, this.$parent),
            ...this.textStyles,
            overflow: "hidden",
            whiteSpace: "nowrap",
            display: "inline-block"
        };

        return (
            <div style={style}>
                <span
                    class={{ 'designer-container': true, selected: this.selected$ }}
                    on-click={(e) => this.handleClick(e)}
                    title={this.controlData.Tooltip}>
                    <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
                    {this.controlData.Text || 'Empty'}
                </span>
            </div>
        );
    }
});