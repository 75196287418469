/*
    Product maintains a list of colors for this file at https://docs.google.com/spreadsheets/d/143ehmL_jT0D6cjzJzHrF74hW0NlQPWh2XgX64wBLHcQ/edit#gid=0
    This is meant to allow the designers, product, and developers to all view the same color reference.
    Colors listed below must stay in sync with this file in order to yield expected results.
    Also verify list with CSSVariables.prj > GetColorNames
    Should be the same in vue and standaloneagent
*/
export default {
    /* Text */
    primaryText: '#28283C',
    secondaryText: '#828294',
    tertiaryText: '#A6A6B6',
    stateText: '#C8C8DC',

    primary: '#2c92ff',
    primaryActive: '#187EEB',

    /* Controls */
    header: '#28283C',
    navigation: '#3c3c4f',
    accent: '#82B1FF',
    error: '#ef4a41',
    destructive: '#ef4a41',
    lightgrey: '#828296',
    lightGreyBorder: '#6E6E82',
    stateBackground: '#3C3C50',
    lightBlueBackground: '#505064',
    darkGreyIcon: '#A6A6B6',

    /*  Bootstrap Variables.  Pending name and color verification from designer */
    blue: '#2C92FF',
    green: '#4AB574',
    yellow: '#FFDE51',
    red: '#EF4A41',
    cyan: '#E3EEFE',
    success: '#4AB574',
    danger: '#EF4A41',
    warning: '#FFDE51',
    info: '#2c92ff',
    lightGrey: '#828296',
    extraLightGrey: '#fafafa',
    white: '#FFF',
    black: '#000',
    blueExtraLight: '#FAFAFA',
    lightGreyHover: '#5e5e6d',

    /* Dashboard */
    asa: '#2C92FF',
    att: '#702CFF',
    awrapt: '#0155A3',

    /* Phone */
    sentmessage: '#d5e9ff',
    altsentmessage: '#dcf7c3',
    wrapup: '#0155A3',
    multiples: '#702CFF',
    inbound: '#2C92FF',
    outbound: '#E07A1A',
    ready: '#4ab574',
    notready: '#ef4a41',
    fullcapacity: '#FFDE51',
    receivedmessage: '#F0F0F0',

    /* Not Ready States */
    notready1: '#ef4a41', /* same as notready */
    notready2: '#d7433b',
    notready3: '#bf3b34',
    notready4: '#a7342e',
    notready5: '#8f2c27',
    notready6: '#782521',
    notready7: '#601e1a',
    notready8: '#481613',
    notready9: '#300f0d',

    /* Chart States */
    busy: '#828296',
    linked: '#2C92FF',
    transfer: '#0155A3',
    reading: '#702CFF',
    unlinked: '#0155A3',
    composing: '#E07A1A',
    starting: '#828296',
    chatting: '#702CFF',
    hold: '#0155A3',
    answered: '#2C92FF',
    pending: '#828296',

    /* User States */
    userState1: '#FFDE51',
    userState2: '#EDA926',
    userState3: '#E07A1A',
    userState4: '#D5562E',
    userState5: '#B5322C',
    userState6: '#7D1D4E',
    userState7: '#8D6FCC',
    userState8: '#702CFF',
    userState9: '#3B02B6',
    userState10: '#78B3F0',
    userState11: '#4994EC',
    userState12: '#0155A3',
    userState13: '#74CDDE',
    userState14: '#4DA8BE',
    userState15: '#39818D'
}