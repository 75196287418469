<template>
    <div :id="editorId" :style="sizeStyle" v-if="this.todisplay" v-show="this.isvisible" :class="{ 'c-FileDiff': true, [`c-name-${this.name || 'unnamed'}`]: true }">
        <div v-html="prettyHtml" />
    </div>
</template>

<script>
    import utils from '@/Shared/utils.jsx';
    import BaseComponent from './BaseComponentMixin.jsx';
    import api from '@/Services/api';
    import Vue from 'vue';

    import * as Diff2Html from 'diff2html';
    import 'diff2html/bundles/css/diff2html.min.css';
    import * as diff from 'diff';
    
    export default {
        name: "FileDiff",
        mixins: [BaseComponent],
        components: {
        },
        data() {
            return {
                editorId: null,

                urlLeftEval: null,
                urlRightEval: null,

                textLeftEval: null,
                textRightEval: null,

                language: 'javascript',
                leftData: null,
                rightData: null,
            }
        },
        created() {
            this.editorId = utils.generateUUID();
        },
        //Mounted Replaced with preRenderComplete
        watch: {
        },
        methods: {
            async preRenderComplete() {
                const tasks = [];

                if (this.controlData.LeftType == 'Url')
                     tasks.push(api.get(this.urlLeft)); // this.leftData

                if (this.controlData.RightType == 'Url')
                    tasks.push(api.get(this.urlRight)); // this.rightData

                if (tasks.length == 2) {
                    const [leftData, rightData] = await Promise.all(tasks);
                    this.leftData = leftData;
                    this.rightData = rightData;
                }
                else if (tasks.length == 1) {
                    if (this.controlData.LeftType == 'Url') {
                        const [res] = await Promise.all(tasks);
                        this.leftData = res;
                    }
                    else if (this.controlData.RightType == 'Url') {
                        const [res] = await Promise.all(tasks);
                        this.rightData = res;
                    }
                }

                console.log('leftData:');
                console.log(JSON.stringify(this.leftData));
                console.log('rightData:');
                console.log(JSON.stringify(this.rightData));
            },
        },
        computed: {
            urlLeft() {
                try {
                    if (!this.urlLeftEval && this.controlData.LeftUrl) {
                        this.urlLeftEval = utils.compile(this, this.controlData.LeftUrl);
                    }
                    return this.urlLeftEval ? utils.evaluate(this.urlLeftEval, this) : '';
                }
                catch (e) {
                    utils.warn('LeftUrl could not evaluate expression: ' + this.controlData.LeftUrl + '; ' + e);
                    return '';
                }
            },
            urlRight() {
                try {
                    if (!this.urlRightEval && this.controlData.RightUrl) {
                        this.urlRightEval = utils.compile(this, this.controlData.RightUrl);
                    }
                    return this.urlRightEval ? utils.evaluate(this.urlRightEval, this) : '';
                }
                catch (e) {
                    utils.warn('RightUrl could not evaluate expression: ' + this.controlData.RightUrl + '; ' + e);
                    return '';
                }
            },

            textLeft() {
                try {
                    if (!this.textLeftEval && this.controlData.LeftText) {
                        this.textLeftEval = utils.compile(this, this.controlData.LeftText);
                    }
                    return this.textLeftEval ? api.standardizeUrl(utils.evaluate(this.textLeftEval, this)) : '';
                }
                catch (e) {
                    utils.warn('LeftText could not evaluate expression: ' + this.controlData.LeftText + '; ' + e);
                    return '';
                }
            },
            textRight() {
                try {
                    if (!this.textRightEval && this.controlData.RightText) {
                        this.textRightEval = utils.compile(this, this.controlData.RightText);
                    }
                    return this.textRightEval ? api.standardizeUrl(utils.evaluate(this.textRightEval, this)) : '';
                }
                catch (e) {
                    utils.warn('RightText could not evaluate expression: ' + this.controlData.RightText + '; ' + e);
                    return '';
                }
            },

            oldString() {
                let res = this.rightData || this.textRight;

                if (!res)
                    return '';
                
                switch (this.controlData.TextType) {
                    case 'json':
                        if (typeof res === 'string')
                            return JSON.stringify(JSON.parse(res), null, 4);
                        else if (typeof res === 'object')
                            return JSON.stringify(res, null, 4);
                        else
                            return res.toString();

                    default:
                        return res;
                }
            },
            newString() {
                let res = this.leftData || this.textLeft;

                if (!res)
                    return '';

                switch (this.controlData.TextType) {
                    case 'json':
                        if (typeof res === 'string')
                            return JSON.stringify(JSON.parse(res), null, 4);
                        else if (typeof res === 'object')
                            return JSON.stringify(res, null, 4);
                        else
                            return res.toString();

                    default:
                        return res;
                }
            },
            diffs() {
                return diff.createPatch("File", this.oldString, this.newString);
            },
            prettyHtml() {
                return Diff2Html.html(this.diffs, {
                    outputFormat: 'line-by-line',
                });
            },
        }
    };
</script>

<style>
.d2h-code-linenumber {
    position: relative !important;
}

.d2h-diff-tbody > tr {
    display: flex;
}

.d2h-diff-tbody > tr > td:nth-child(2) {
    flex-grow: 1;
}

.d2h-code-line {
    padding: 0;
}
</style>
