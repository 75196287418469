import Vue from 'vue';
import BasicButton from './BasicButton.jsx';
import Text from './Text.jsx';
import Icon from './Icon.jsx';
//import SelectList from './SelectList.jsx';
import BasicForm from './BasicForm.jsx';
import BasicGrid from './BasicGrid.jsx';
import TreeView from './TreeView.jsx';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';
import controlPicker from './controlPicker.jsx';
import BaseStackMixin from './BaseStackMixin.jsx';

Vue.component('stack-horizontal-dsgn', {
    mixins: [BaseStackMixin],
    data: function () {
        return {
        }
    },
    computed: {
        horizontalStackClasses() {
            let classes = [];
            if (this.controlData.VerticalAlignChildren) {
                switch (this.controlData.VerticalAlignChildren.toLowerCase()) {
                    case 'top':
                        classes.push('align-start');
                        break;
                    case 'center': {
                        classes.push('align-center');
                        break;
                    }
                    case 'bottom': {
                        classes.push('align-end');
                        break;
                    }
                }
            }

            if (this.controlData.HorizontalAlignChildren) {
                switch (this.controlData.HorizontalAlignChildren.toLowerCase()) {
                    case 'left':
                        classes.push('justify-start');
                        break;
                    case 'middle': {
                        classes.push('justify-center');
                        break;
                    }
                    case 'right': {
                        classes.push('justify-end');
                        break;
                    }
                    case 'spacearound': {
                        classes.push('justify-space-around');
                        break;
                    }
                    case 'spacebetween': {
                        classes.push('justify-space-between');
                        break;
                    }
                    case 'spaceevenly': {
                        classes.push('justify-space-evenly');
                        break;
                    }
                }
            }

            return classes
        },
    },
    render(h) {
        let items = [];
        let items_id = '_0';

        let controlData = this.controlData;

        this.generateItemsFromModel(h, items, controlData, items_id, 'HorizontalStack');

        let header = this.getHeader(h, controlData);
        if (this.selected$ || header) {
            if (!header) header = [];
            if (this.selected$)
                header.push(
                    <v-btn v-tooltip="Add control to header" x-small icon style={{ zIndex: 992 }} on-click={(e) => addControl(e, 'header')}>
                        <v-icon small color="blue darken-2">mdi mdi-plus-circle</v-icon>
                    </v-btn>
                );
        }

        if (this.selected$)
            items.push(
                <v-btn v-tooltip="Add control to body" x-small icon style={{ zIndex: 992 }} on-click={(e) => this.addControl(e, 'body')}>
                    <v-icon small color="blue darken-2">mdi mdi-plus-circle</v-icon>
                </v-btn>
            );

        items.push(this.generatePicker(h));

        const breadcrumb = this.generateBreadCrumbs(h);

        const style = {
            overflow: this.parentType == "ContentDef" ? "hidden" : "auto",
            display: "flex",
            flexDirection: "column",
            ...utils.getSize(controlData.SizeOptions, this.parentType, this.$parent),
            ...utils.getStyleHints(controlData.StyleHints),
        };

        const items_style = {
            overflow: this.parentType == "ContentDef" ? "hidden" : "auto",
            display: "flex",
            flexDirection: "row",
            flexGrow: 1,
            gap: this.padding ? this.padding + 'px' : ''
        };

        if (this.controlData.SizeOptions && this.controlData.SizeOptions.Width.Mode === 'Auto' && this.parentType === 'VerticalStack') {
            // In this unique case, we must use the opposite direction on the parent and embed another div
            // to force the 'auto' width -- otherwise, 
            items_style.flexDirection = 'column';
            delete items_style.gap;
            items = (
                <div class="property-grid" style={{ display: "flex", flexDirection: "row", flexGrow: "0", flexShrink: "0", gap: this.padding ? this.padding + 'px' : '' }}>
                    {items}
                </div>
            );
        }

        return (
            <div
                class={{ 'c-HorizontalStack': true, [`c-name-${this.name || 'unnamed'}`]: true, 'designer-container': true, selected: this.selected$ }}
                style={style}
                on-click={(e) => this.handleClick(e)}>
                <div class={{ "designer-container-hovering": true, 'designer-container-selected-clickthrough': this.selected$ }}></div>
                {header}
                {breadcrumb}
                <div style={items_style}
                    class={this.horizontalStackClasses}>
                    {items}
                </div>
            </div>
        );
    }
});