import Vue from 'vue';
import BaseComponent from './baseFormMixin.jsx';
import utils from '../../../Shared/utils.jsx';
import { appSettings } from '@/Shared/appSettings.js';

const commonControl = {
    mixins: [BaseComponent],
    data: () => ({
    }),
    props: {
    },
    created() {
    },
    mounted() {
        if (this.autofocus)
            setTimeout(() => {
                const item = this.$refs[this.autofocus_id];
                if (item) {
                    this.$refs[this.autofocus_id].focus();

                    let inputEl = this.$refs[this.autofocus_id].$el.querySelector('input');
                    inputEl.select();

                    //this.input = 'test value'
                    setTimeout(() => {
                        inputEl.select();
                    }, 200);
                }
            }, 250);

        this.loadComplete();
    },
    computed: {
    },
    methods: {
    },
    render(h) {
        // This is a working example of a custom component built using a .vue file that will properly use slots in a v-text-input:
        //import Demotextinput from './demoTextInput.vue';
        //components: {
        //  Demotextinput,
        //}
        //let input1 = <Demotextinput value={this.itemvalue} on-input={(value) => this.sync(value)}></Demotextinput>;
        //if (this.appearance)
        //    input1.data.attrs = { ...input1.data.attrs, ...this.appearance };
        //return input1;

        let scopedSlots = {
            message: ({message}) => {
                return <translation-container context={this} value={message}></translation-container>
            }
        }

        let slots = [
            <translation-container slot="label" context={this} value={this.labelText}></translation-container>,
        ];

        let input =
            <v-text-field
                type="text"
                class="caption pa-0 ma-0"
                ref={this.autofocus ? this.autofocus_id : undefined}
                prefix={this.element.fieldAddonLeft}
                outlined single-line dense hide-details
                style={{ width: "auto" }}
                value={this.itemvalue}
                rules={this.rules}
                on-input={(value) => this.sync(value)}
                on-blur={this.onBlur}
                on-focus={this.onFocus}
                scopedSlots={scopedSlots}
                hint={this.hintText}
                persistent-hint={appSettings.DebugTranslationPrefixSetting}
            >{slots}</v-text-field>;

        if (this.appearance)
            input.componentOptions.propsData = { ...input.componentOptions.propsData, ...this.appearance };

        if (this.directives) {
            if (input.data.directives)
                input.data.directives = [...input.data.directives, ...this.directives];
            else
                input.data.directives = this.directives;
        }

        return input;
    }
};

Vue.component('sform-text', commonControl);
Vue.component('sform-string', commonControl);