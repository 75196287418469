<template>
    <v-menu offset-y :close-on-content-click="false" :close-on-click="false" v-model="menuopen">
        <template v-slot:activator="{ on, attrs }">
            <v-btn text x-small v-bind="attrs" v-on="on" class="ma-0">
                {{ label || 'Column filters' }}
                <v-icon small>mdi-menu-down</v-icon>
            </v-btn>
        </template>
        <v-card @mouseleave="menuopen = false">
            <v-list>
                <v-list-item-group multiple v-model="selection" color="primary">
                    <template v-for="item in columngroups">
                        <v-subheader v-if="item.divider" :key="item.text + '2'">{{ item.text }}</v-subheader>
                        <!--<v-divider v-if="item.divider" :key="item.text"></v-divider>-->

                        <v-list-item v-if="!item.divider" :key="item.text" class="mediumdense">
                            <template v-slot:default="{ active }">
                                <v-list-item-action>
                                    <v-checkbox :input-value="active"></v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <!--<v-checkbox :input-value="active" :label="item.text"></v-checkbox>-->
                                    {{ item.text }}
                                </v-list-item-content>
                            </template>
                        </v-list-item>
                    </template>
                </v-list-item-group>
            </v-list>
        </v-card>
    </v-menu>
</template>

<script>
    module.exports = {
        data: function () {
            return {
                menuopen: false,
                selection: [],
            }
        },
        props: {
            label: String,
            columngroups: Array,
            columngroups_selection: Array,
        },
        watch: {
            selection: function (newv) {
                this.$emit('change', newv);
            }
        },
        created() {
            this.selection = [ ...this.columngroups_selection ];
        },
    }
</script>

<style scoped>

    .mediumdense {
        max-height: 28px;
        min-height: 28px;
    }

</style>