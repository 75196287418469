import Vue from 'vue';
import utils from '../../Shared/utils.jsx';
import BaseComponent from './BaseComponentMixin.jsx';
import './css/BasicLink.css';

Vue.component('basic-link', {
    mixins: [BaseComponent],
    data: function () {
        return {
            textrenderer: null,
            fontSizeEval: null,
            wrapTextEval: null,
            colorEval: null,
            tooltiprenderer: null,
            tooltipLocationEval: null,
            isdisabledeval: null,
            exportCallbacks: null,
            asyncUrl: null,
            asyncText: null,
            asyncTooltip: null,
            asyncColor: null,
            Input: {},
            // activatorId: '',
            link: null
        }
    },
    props: {},
    created() {
        if(this.controlData.AsyncLookup === true) {
            this.asyncUrl = utils.compile(this, this.controlData.AsyncOptions.URL);

            if(this.controlData.AsyncOptions.Text)
                this.asyncText = utils.compile(this, this.controlData.AsyncOptions.Text);
            
            if(this.controlData.AsyncOptions.Tooltip)
                this.asyncTooltip = utils.compile(this, this.controlData.AsyncOptions.Tooltip);

            if(this.controlData.AsyncOptions.Color)
                this.asyncColor = utils.compile(this, this.controlData.AsyncOptions.Color);
        }
    },
    //Mounted Replaced with preRenderComplete
    watch: {
        asyncUrlComputed: function() {
            if(this.controlData.AsyncLookup === true)
                this.lookupText();
        },
    },
    computed: {
        text() {
            if(this.controlData.Text)
                return (
                    <translation-container context={this} value={this.controlData.Text}></translation-container>
                );
            return null;
        },
        fontSize() {
            if(this.fontSizeEval === null && this.controlData.FontSize)
                this.fontSizeEval = utils.compile(this, this.controlData.FontSize);
            if(this.fontSizeEval)
                return utils.evaluate(this.fontSizeEval, this);
            return '14px';
        },
        wrapText() {
            if(this.wrapTextEval === null && this.controlData.WrapText)
                this.wrapTextEval = utils.compile(this, this.controlData.WrapText);
            if(this.wrapTextEval)
                return utils.evaluate(this.wrapTextEval, this);
            return 'nowrap';
        },
        color() {
            if(this.colorEval === null && this.controlData.Color)
                this.colorEval = utils.compile(this, this.controlData.Color);
            if(this.colorEval)
                return utils.evaluate(this.colorEval, this);
            return '';
        },
        tooltip() {
            if(this.tooltiprenderer === null && this.controlData.Tooltip)
                this.tooltiprenderer = utils.compile(this, this.Translate(this.controlData.Tooltip));
            if(this.tooltiprenderer) {
                // if(!this.activatorId) this.activatorId = utils.generateUniqueDomId();
                return utils.evaluate(this.tooltiprenderer, this);
            }
            return null;
        },
        tooltipLocation() {
            let location = '';
            if(this.tooltipLocationEval === null && this.controlData.TooltipLocation)
                this.tooltipLocationEval = utils.compile(this, this.controlData.TooltipLocation);
            if(this.tooltipLocationEval)
                location = utils.evaluate(this.tooltipLocationEval, this);
            
            return location.split('-')[0].toLowerCase() || 'top';
        },
        disabled() {
            if(this.isdisabledeval === null && this.controlData.Disabled)
                this.isdisabledeval = utils.compile(this, this.controlData.Disabled);
            if(this.isdisabledeval)
                return utils.evaluate(this.isdisabledeval, this) == 'true';
            return false;
        },
        asyncUrlComputed() {
            if(this.asyncUrl)
                return utils.evaluate(this.asyncUrl, this)
            return ''
        },
        textStyles() {
            const styles = {};

            if (this.controlData.FontSize)
                styles['font-size'] = utils.resolveFontSize(this.controlData.FontSize);

            if (this.controlData.Color)
                try {
                    styles.color = this.color;
                }
                catch (e) {
                    utils.warn('Color could not evaluate expression: ' + this.controlData.Color + '; ' + e);
                    styles.color = "blue";
                }
            else
                styles.color = 'blue';

            return styles;
        },
        styles() {
            return {
                ...this.textStyles,
                ...utils.resolveStyleHints(this.styleHints, this),
                overflow: "hidden",
                whiteSpace: this.wrapText,
                //display: "inline-block"
            }
        },
    },
    methods: {
        async doClick(e) {
            if(!this.disabled) {
                e.cancelBubble = true;
                e.stopPropagation();
    
                await utils.executeAndCompileAllActions(this.controlData.Actions, null, this);
            }
        },
        async lookupText() {
            try {
                const res = await utils.api.get(this.asyncUrlComputed);
                if (res)
                    Vue.set(this.Input, 'Data', res);
                else
                    Vue.set(this.Input, 'Data', '');

                if(this.asyncText)
                    this.textrenderer = this.asyncText;
                if(this.asyncTooltip)
                    this.tooltiprenderer = this.asyncTooltip;
                if(this.asyncColor)
                    this.colorEval = this.asyncColor;
            }
            catch (e)
            {
                utils.warn('Async lookup failed to evaluate url: ' + this.controlData.AsyncOptions.URL + '; ' + e, e);
            }
        },
        async preRenderComplete() {
            if(this.controlData.AsyncLookup === true)
                await this.lookupText();
            this.finishRenderHandler(this);
        },
    },
    render(h) {
        if (!this.todisplay)
            return null;

        try {
            let link = <a
                    id={this.activatorId}
                    style={this.styles}
                    class={{disabled: this.disabled, thelink: true}}
                    on-click={(e) => this.doClick(e)}
                    href={!this.disabled ? 'javascript:void(0)' : false}>
                    {this.text}
                </a>;

            let content = utils.generateTooltip(h, link, this.tooltip, this.tooltipLocation);

            return (
                <div
                    class={{ 'c-BasicLink': true, [`c-name-${this.name || 'unnamed'}`]: true }}
                    v-show={this.isvisible} style={this.sizeStyle}>
                        {content}
                </div>
            );
        }
        catch (e) {
            utils.error('BasicLink Render failed', e);
            return <div>BasikLink Failed to Render {e}</div>;
        }
    }
});