import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import BaseComponent from './BaseComponentMixin.jsx';

Vue.component('audio-player-dsgn', {
    mixins: [BaseComponent],
    data: function () {
        return {
        }
    },
    render(h) {
        let name;
        if (!this.name)
            name = this.type;
        else
            name = this.name + ':' + this.type || 'Unnamed';

        return (
            <div
                class={{ 'designer-container': true, selected: this.selected$ }}
                style={{ backgroundColor: "#a0ffa0", ...utils.getSize(this.controlData ? this.controlData.SizeOptions : null, this.parentType, this.$parent) }}
                on-click={(e) => this.handleClick(e)}>
                <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
                {name}<br />
            </div>
        );
    }
});