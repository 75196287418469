import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import utils from '../../Shared/utils.jsx';
import EventBus from '../event-bus.js';

Vue.component('dynamic-tab', {
    mixins: [BaseComponent],
    data: function () {
        return {
            sourceraw: null,
            sourceurl: null,
            model: null,
            tab_state: {
                index: 0
            },
            selected_tab_expr: null,
            selectionHistory: [],
        }
    },
    async created() {
        if (this.controlData.ActiveTabIndex)
            this.selected_tab_expr = utils.compileExpression(this, this.controlData.ActiveTabIndex);

        if (this.controlData.SourceType === 'Raw' && this.controlData.SourceRaw) {
            this.sourceraw = utils.compileObject(this, this.controlData.SourceRaw);
        }
        else if (this.controlData.SourceType === 'Url' && this.controlData.SourceUrl) {
            this.sourceurl = utils.compile(this, this.controlData.SourceUrl);
        }

        if (this.controlData.ListenForTabEvents) {
            EventBus.$on('Action-Tab', this.openTabAction);
            EventBus.$on('Action-RenameTab', this.performRenameTab);

            if (this.controlData.Name) {
                // Subscribe by name so Target can be directed to a specific control
                EventBus.$on(`Action-Tab:${this.controlData.Name}`, this.openTabAction);
                EventBus.$on(`Action-RenameTab:${this.controlData.Name}`, this.performRenameTab);
            }
        }

        this.$on('Action-Close', this.closeTabAction);

        // Subscribe by name so Target can be directed to a specific control
        if (this.controlData.Name)
            this.$on(`Action-Close:${this.controlData.Name}`, this.closeTabAction);
    },
    destroyed() {
        if (this.controlData.ListenForTabEvents) {
            EventBus.$off('Action-Tab', this.openTabAction);
            EventBus.$off('Action-RenameTab', this.performRenameTab);

            if (this.controlData.Name) {
                // Subscribe by name so Target can be directed to a specific control
                EventBus.$off(`Action-Tab:${this.controlData.Name}`, this.openTabAction);
                EventBus.$off(`Action-RenameTab:${this.controlData.Name}`, this.performRenameTab);
            }
        }

        this.$off('Action-Close', this.closeTabAction);

        // Subscribe by name so Target can be directed to a specific control
        if (this.controlData.Name)
            this.$off(`Action-Close:${this.controlData.Name}`, this.closeTabAction);

    },
    //Mounted Replaced with preRenderComplete
    computed: {
        SelectedTab: function () {
            if (this.selected_tab_expr)
                return utils.evaluate(this.selected_tab_expr, this);
            else
                return -1;
        },
        ActiveTabIndex: {
            get: function () {
                return this.SelectedTab;
            },
            set: function (index) {
                this.changeTab(index);
            }
        },
        Menus: function () {
            return utils.document_cache.menus;
        },
    },
    watch: {
        // whenever selected_tab_expr changes, this function will run
        SelectedTab: function (newValue, oldValue) {
            utils.log(`DynamicTab ${this.name} SelectedTab changed from ${oldValue} to ${newValue}`);

            if (typeof newValue === 'string')
                this.changeTab(parseInt(newValue));
            else
                this.changeTab(newValue);
        },
        $route: function (newVal, oldVal) {
            if (this.controlData.UseRouter) {
                var rgx = new RegExp(`${this.controlData.RoutingName}/([^/]*)`, 'ig');
                var matches = [...newVal.fullPath.matchAll(rgx)];
                
                if (matches[0] && matches[0].length >= 2) {
                    utils.debug(`Tab newVal: ${newVal.fullPath}; oldVal: ${oldVal.fullPath};`);
                    var index = this.model.findIndex(o => o.$objectid == matches[0][1]);
                    //setDynamicTab
                    utils.executeAndCompileAllActions(this.controlData.Actions, { Data: index }, this);
                }
            }
        }
    },
    methods: {
        async preRenderComplete() {
            await this.Refresh();
            this.finishRenderHandler(this);
        },
        async Refresh() {
            if (this.sourceraw)
                try {
                    this.model = utils.evaluateObject(this.sourceraw, this);
                }
                catch (e) {
                    utils.warn('DynamicTab failed to evaluate SourceRaw ' + this.controlData.SourceRaw + ': ' + e);
                }
            else if (this.sourceurl)
                try {
                    this.model = await utils.api.get(utils.evaluate(this.sourceurl, this));
                }
                catch (e) {
                    utils.warn('DynamicTab failed to evaluate SourceUrl ' + this.controlData.SourceUrl + ': ' + e);
                }
        },
        async changeTab(index) {
            utils.log(`DynamicTab ${this.name} changeTab(index:${index})`);

            // index of selected tab
            if (this.model.length > 0)
            {
                if (this.controlData.UseRouter) {
                    if (!this.$route.fullPath.endsWith(`#/${this.controlData.RoutingName}/${this.model[index].$objectid}`))
                        this.$router.push({ path: `#/${this.controlData.RoutingName}/${this.model[index].$objectid}` });
                }
                else if (this.selectionHistory[this.selectionHistory.length-1] != this.model[index].$objectid) {
                    this.selectionHistory.push(this.model[index].$objectid);
                    
                    await utils.executeAndCompileAllActions(this.controlData.Actions, { Data: index }, this);
                }
            }
        },
        SetActiveTabIndex(index) {
            if (index !== this.SelectedTab)
                this.changeTab(index);
        },
        async closeTab(e, index) {
            if (e) {
                e.cancelBubble = true;
                e.stopPropagation();
            }

            utils.log(`DynamicTab ${this.name} closeTab(index:${index})`);
            
            var routingIndex = -1;

            if (this.controlData.UseRouter) {
                var rgx = new RegExp(`${this.controlData.RoutingName}/([^/]*)`, 'ig');
                var matches = [...this.$route.fullPath.matchAll(rgx)];
                
                if (matches[0] && matches[0].length >= 2)
                    routingIndex = this.model.findIndex(o => o.$objectid == matches[0][1]);
            }
            
            //closeTab
            await utils.executeAndCompileAllActions(this.controlData.CloseActions, { Data: index }, this);
            
            //Navigate
            if (this.controlData.UseRouter) {
                if (routingIndex == index)
                    this.$router.go(-1);
                else {
                    var rgx = new RegExp(`${this.controlData.RoutingName}/([^/]*)`, 'ig');
                    var matches = [...this.$route.fullPath.matchAll(rgx)];
                    
                    if (matches[0] && matches[0].length >= 2) {
                        var newIndex = this.model.findIndex(o => o.$objectid == matches[0][1]);
                        await utils.executeAndCompileAllActions(this.controlData.Actions, { Data: newIndex }, this);
                    }
                }
            } else {
                //set old to navigate to it on next tick
                var old = -1;

                if (this.model && this.model.length) {
                    for (var i = this.selectionHistory.length - 1; i >= 0; i--) {
                        var tab = this.model.findIndex(o => o.$objectid == this.selectionHistory[i]);

                        if (tab != -1 && tab != index-1) {
                            old = tab;
                            this.selectionHistory.splice(i);
                            break;
                        }
                    }
                }

                const c = this;
                Vue.nextTick(() => {
                    if (old >= index) {    
                        utils.executeAndCompileAllActions(this.controlData.Actions, { Data: old - 1 }, c);
                    }
                });
            }
        },

        async performRenameTab(action) {
            utils.log(`DynamicTab RenameTab ${action.ActionData.TabTitle}`);

            if (action.ActionData && action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            if (action.tabtitle) {
                const title = utils.evaluate(action.tabtitle, action.context);

                await utils.executeAndCompileAllActions(this.controlData.RenameActions, { TabTitle: title, Index: this.SelectedTab }, this);

                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
        async openTabAction(action) {
            utils.log(`DynamicTab openTabAction`);

            if (action.ActionData && action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            if (action.ActionData) {
                if (action.actiondatafunc) {
                    if (!action.context.$attrs) action.context.$attrs = {};

                    action.context.$attrs['paramData'] = utils.evaluateObject(action.actiondatafunc, action.context);
                }

                // Clone the action's ControlData so we get a full copy, with no duplicate references
                const controlData = JSON.parse(JSON.stringify(action.ActionData.ControlData));

                // Evaluate the ControlData.Vars here (still using action.context rather than this...)
                // Note that action.vars also exists and resolves the same as action.varsobj - no difference
                if (action.varsobj) {
                    controlData.Vars = utils.evaluateObject(action.varsobj, action.context);
                    controlData.$nointerp = true;
                }

                const act = {
                    Name: action.ActionData.Name,
                    TabID: action.tabID ? utils.evaluate(action.tabID, action.context) : '',
                    TabTitle: action.tabTitle ? utils.evaluate(action.tabTitle, action.context) : '',
                    ActionData: action.context.$attrs ? (action.context.$attrs['paramData'] || {}) : {},
                    ControlData: controlData,
                    OnActivateActions: action.ActionData.OnActivateActions || [],
                    OnDeactivateActions: action.ActionData.OnDeactivateActions || [],
                    context: action.context,
                };

                await utils.executeAndCompileAllActions(this.controlData.OpenActions, { Data: act }, this);

                var index = this.model.findIndex(o => o.ID == act.TabID);

                await this.changeTab(index);

                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
        async closeTabAction(action) {
            // This handles the Close action which is emitted by UIAction_Close, which is typically broadcast from a child
            utils.log(`DynamicTab closeTabAction`);

            if (action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            await this.closeTab(null, this.SelectedTab);
        },
    },
    props: {
    },
    render(h) {
        if (!this.todisplay || !this.controlData)
            return null;

        let items = [];

        const useDark = this.controlData.DarkTheme || false;

        let bgcolor;
        if (this.controlData.StyleHints && this.controlData.StyleHints.BackgroundColor)
            bgcolor = this.controlData.StyleHints.BackgroundColor;

        let fgcolor;
        if (this.controlData.StyleHints && this.controlData.StyleHints.ForegroundColor)
            fgcolor = this.controlData.StyleHints.ForegroundColor;

        if (this.model) {
            for (let tabi = 0; tabi < this.model.length; tabi++) {
                const tab = this.model[tabi];
                if (!tab.$objectid)
                    tab.$objectid = utils.generateUUID();

                if (tab.Icon && tab.Icon.includes('{'))
                    tab.Icon = 'mdi mdi-phone';

                if (!tab.ID)
                    tab.ID = utils.generateUUID();

                let tooltip = tab.Tooltip;

                let icon;
                let title = tab.Title;
                if (title.length > 25) {
                    title = title.substr(0, 25) + '...';
                    if (!tooltip)
                        tooltip = tab.Title;
                }

                if (tooltip) {
                    if (tab.Icon)
                        icon = <v-icon v-tooltip={tooltip}>{tab.Icon}</ v-icon>;
                    //icon = <v-tooltip right scopedSlots={{ activator: ({ on, attrs }) => <v-icon {...{ on }} {...{ attrs }}>{tab.Icon}</v-icon> }}>{tooltip}</v-tooltip>;

                    const tt = { content: tooltip, placement: "bottom-center", offset: 10, delay: { show: 1000, hide: 300, } };
                    title = <span v-tooltip={tt}>{title}</span>;
                }
                else {
                    if (tab.Icon)
                        icon = <v-icon>{tab.Icon}</v-icon>;
                }

                let closeicon;
                if (this.controlData.ShowCloseIcon)
                    closeicon = (
                        <v-btn elevation={0}
                            x-small
                            text
                            depressed
                            fab
                            v-tooltip="Close"
                            on-click={(e) => this.closeTab(e, tabi)}>
                            <v-icon small>mdi mdi-close-circle</v-icon>
                        </v-btn>
                    );

                items.push(
                    <v-tab key={tab.ID}
                        class="text-capitalize"
                        vOn:click_middle={(e) => this.closeTab(e, tabi)}
                        on-click={(e) => this.changeTab(tabi)}
                        style={`backgroundColor: ${bgcolor}; color: ${fgcolor};`}>
                        {icon}
                        {title}
                        {closeicon}
                    </v-tab>
                );
            }
        }

        //next-icon="mdi mdi-menu-right"
        //prev-icon="mdi mdi-menu-left"
        return (
            <v-tabs
                class={{ 'c-DynamicTab': true, [`c-name-${this.name || 'unnamed'}`]: true }}
                style={this.sizeStyle}
                dark={useDark}
                vertical={this.controlData.Orientation == "Vertical"}
                show-arrows
                value={this.SelectedTab}>
                {items}
            </v-tabs>
        );
    }
});