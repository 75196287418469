import Vue from 'vue';
import BaseComponent from './baseFormMixin.jsx';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';
import careHelpfulFunctions from '../../careHelpfulFunctions';

import { appSettings } from '@/Shared/appSettings.js';

const iconPickerMixin = {
    mixins: [BaseComponent],
    data: () => ({
        formatData: null,

        dolookupexpn: null,
        lookupurlexpn: null,
        lookuprawexpn: null,

        displayexpnfunc: null,
        valueexpnfunc: null,
        iconexpnfunc: null,

        item_text: 'name',
        item_value: 'value',
        item_disabled: undefined,
        item_icon: null,

        lookupitems: null,
    }),
    props: {
    },
    created() {
        if (this.element.formatData)
            this.formatData = this.element.formatData;
        else if (this.element.schema && this.element.schema.formatData)
            this.formatData = this.element.schema.formatData;

        if (this.formatData && (this.formatData.LookupType || this.formatData.LookupURL || this.formatData.LookupInterpolatedExpression)) {
            if (this.formatData.LookupExpression)
                this.dolookupexpn = utils.compileExpression(this, this.formatData.LookupExpression);

            if ((!this.formatData.LookupType || this.formatData.LookupType == 'URL') && this.formatData.LookupURL)
                this.lookupurlexpn = utils.compile(this, this.formatData.LookupURL);
            else if (this.formatData.LookupType == 'Interpolated' && this.formatData.LookupInterpolatedExpression)
                this.lookuprawexpn = utils.compileExpression(this, this.formatData.LookupInterpolatedExpression);

            if (this.formatData.DisplayType == 'Expression') {
                const expr = utils.parseExpressionWithFilters(this.formatData.DisplayExpression);
                this.displayexpnfunc = {
                    code: expr,
                    func: new Function('control', 'util', this.formatData.LookupModelAs, `with (control) return ${expr};`)
                };
            }
            if (this.formatData.ValueType == 'Expression') {
                const expr = utils.parseExpressionWithFilters(this.formatData.ValueExpression);
                this.valueexpnfunc = {
                    code: expr,
                    func: new Function('control', 'util', this.formatData.LookupModelAs, `with (control) return ${expr};`)
                };
            }
            if (this.formatData.IconUrlType == 'Expression') {
                const expr = utils.parseExpressionWithFilters(this.formatData.IconUrlExpression);
                this.iconexpnfunc = {
                    code: expr,
                    func: new Function('control', 'util', this.formatData.LookupModelAs, `with (control) return ${expr};`)
                };
            }

            switch (this.formatData.DisplayType) {
                case 'Fieldname': this.item_text = this.formatData.DisplayField; break;
                case 'Expression':
                    this.item_text = (data) => {
                        try {
                            return this.displayexpnfunc.func(this, careHelpfulFunctions, data);
                        }
                        catch (e) {
                            utils.warn(`LookupList DisplayType Expression failed to evaluate: ${this.displayexpnfunc.code}; original: ${this.formatData.DisplayExpression}`, e);
                            return '';
                        }
                    };
                    break;
            }
            switch (this.formatData.ValueType) {
                case 'Fieldname': this.item_value = this.formatData.ValueField; break;
                case 'Expression':
                    this.item_value = (data) => {
                        try {
                            return this.valueexpnfunc.func(this, careHelpfulFunctions, data);
                        }
                        catch (e) {
                            utils.warn(`LookupList ValueType Expression failed to evaluate: ${this.valueexpnfunc.code}; original: ${this.formatData.ValueExpression}`, e);
                            return '';
                        }
                    };
                    break;
            }
            switch (this.formatData.IconUrlType) {
                case 'Fieldname': this.item_icon = this.formatData.IconUrlField; break;
                case 'Expression':
                    this.item_icon = (data) => {
                        try {
                            return this.iconexpnfunc.func(this, careHelpfulFunctions, data);
                        }
                        catch (e) {
                            utils.warn(`formBasicIconPicker IconUrlType Expression failed to evaluate: ${this.iconexpnfunc.code}; original: ${this.formatData.IconUrlExpression}`, e);
                            return '';
                        }
                    };
                    break;
            }
        }
    },
    async mounted() {
        await this.Refresh();
        this.loadComplete();
    },
    computed: {
        tolookup: function () {
            if (this.dolookupexpn)
                return utils.evaluate(this.dolookupexpn, this);
            else
                return true;
        },
        lookupurl: function () {
            if (this.lookupurlexpn)
                try {
                    return utils.evaluate(this.lookupurlexpn, this, false, null, true);
                }
                catch (e) {
                    utils.warn(`basiciconpicker lookupurl could not be evaluated: ${this.lookupurlexpn.code}`, e);
                    return '';
                }
            else
                return '';
        },
        lookupraw: function () {
            if (this.lookuprawexpn)
                try {
                    return utils.evaluate(this.lookuprawexpn, this, false, null, true);
                }
                catch (e) {
                    utils.warn(`basiciconpicker lookupraw could not be evaluated: ${this.lookuprawexpn.code}`, e);
                    return [];
                }
            else
                return '';
        },
    },
    watch: {
        lookupurl: function () {
            this.Refresh();
        },
        lookupraw: function () {
            this.Refresh();
        },
    },
    methods: {
        async Refresh() {
            if (this.tolookup) {
                if (this.lookupurlexpn) {
                    let url = this.lookupurl;
                    if (url)
                        try {
                            this.lookupitems = await utils.api.get(url, false, this.formatData.CacheLookupResult);
                        }
                        catch (e) {
                            utils.warn(`Failed in form basiciconpicker lookupurl:${url}`, e);
                            this.lookupitems = [];
                        }
                }
                else if (this.lookuprawexpn) {
                    this.lookupitems = this.lookupraw;
                }
            }
        },
        getItemValue(item) {
            if (typeof this.item_value === 'function')
                return this.item_value(item);
            else if (typeof this.item_value === 'string')
                return item[this.item_value];
            else
                return null;
        },
        getItemIcon(item) {
            var icon = null;

            if (typeof this.item_icon === 'function') {
                icon = this.item_icon(item);
            } else if (typeof this.item_icon === 'string') {
                icon = item[this.item_icon];
            }
            
            if (!icon && this.formatData.DefaultIcon) {
                return <div style={{ width: '38px', height: '24px', zIndex: 1 }} slot="prepend-inner"><v-icon height={24}>{this.formatData.DefaultIcon}</v-icon></div>;
            } else if (icon) {
                return <v-img width={38} height={24} style={{ zIndex: 1 }} slot="prepend-inner" src={icon}></v-img>;
            } else {
                return null;
            }
        },
        getCurrentItemIcon() {
            const value = this.itemvalue;
            const index = this.lookupitems.findIndex(a => this.getItemValue(a) == value);
            if (index >= 0)
                return this.getItemIcon(this.lookupitems[index]);
            else
                return null;
        },
    },
    render() {
        if (!this.lookupitems)
            return null;

        let scopedSlots = {
            message: ({ message }) => {
                return <translation-container context={this} value={message}></translation-container>
            },
            item: ({ item, on }) => {
                return (
                    <v-list-item
                        inputValue={this.getItemValue(item) == this.itemvalue}
                        value={this.getItemValue(item)}
                        disabled={this.item_disabled ? this.item_disabled(item) : false}
                        on={on}>
                        <v-list-item-icon>
                            {this.getItemIcon(item)}
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                <translation-container
                                    context={this}
                                    value={typeof this.item_text === 'function' ? this.item_text(item) : item[this.item_text]}>
                                </translation-container>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                );
            },
        };

        let slots = [<translation-container slot="label" context={this} value={this.labelText}></translation-container>];

        let icon = this.getCurrentItemIcon();

        if (icon) {
            slots.push(icon)
        }

        let Tag;
        let keyup = ()=>{};

        if (this.element.formatData && this.element.formatData.AllowCustomInput) {
            Tag = 'v-combobox';
            keyup = (e) => this.sync(e.target.value);
        }
        else
            Tag = 'v-autocomplete';

        let input = (
            <Tag
                class="caption pa-0 ma-0" 
                outlined 
                single-line 
                dense 
                hide-details 
                style={{ width: "100%" }} 
                menu-props={{ offsetY: true, auto: true }}
                value={this.itemvalue}
                items={this.lookupitems}
                item-text={this.item_text}
                item-value={this.item_value}
                item-disabled={this.item_disabled}
                return-object={false}
                on-change={(value) => this.sync(value)}
                on-keyup={keyup}
                hint={this.hintText}
                persistent-hint={appSettings.DebugTranslationPrefixSetting}
                scopedSlots={scopedSlots}
            >{slots}</Tag>
        );
        

        if (this.appearance)
            input.componentOptions.propsData = { ...input.componentOptions.propsData, ...this.appearance };

        if (this.directives) {
            if (input.data.directives)
                input.data.directives = [...input.data.directives, ...this.directives];
            else
                input.data.directives = this.directives;
        }
        
        return input;
    }
};

Vue.component('sform-basiciconpicker', {
    mixins: [iconPickerMixin]
});