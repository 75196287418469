import Vue from 'vue';
import utils from '../../Shared/utils.jsx';
import BaseComponent from './BaseComponentMixin.jsx';
import EventBus from '../event-bus.js';
import api from '@/Services/api';
import careHelpfulFunctions from '../careHelpfulFunctions.jsx';
import careService from '@/Services/careService.jsx';
import { PlainWrapper, ObjProxy } from '@/Shared/helperClasses';

Vue.component('dynamic-user-control', {
    mixins: [BaseComponent],
    data: function () {
        return {
            key: null,
            control: null,
            headers: {},
            //controldataexpr: null,
            controlurlexpr: null,
            newscopeitems: {},
            uselocalscope: false,

            showMenu: false,
            x: 0,
            y: 0,

            dynamicData: false,
        }
    },
    created() {
        this.key = utils.generateUUID();

        if (this.controlData) {
            this.controldataexpr = this.utils.compileDynamicObject(this, this.controlData);

            // Intentionally not putting this in the data property - keeps it from getting modified by Vue (which it would do to make it reactive)
            this.controldatavalue = this.utils.evaluateDynamicObject(this.controldataexpr, this);
        }

        if (this.controlURL)
            this.controlurlexpr = this.utils.compile(this, this.controlURL);

        this.watcher$ = this.$watch(
            function () {
                if (this.controlURL)
                    return this.controlURLvalue;
                else
                    return null;
            },
            function (val, oldval) {
                this.Refresh();
            }
        );

        // Listen for EmitControlEvent actions
        this.$on('Action-EmitControlEvent', this.performEmitControlEvent);
    },
    destroyed() {
        this.watcher$();

        this.$off('Action-EmitControlEvent', this.performEmitControlEvent);

        // Remove this user control from the parent's Control object
        if (this.scopeitems && this.controlName && (this.controlName in this.scopeitems))
            delete this.scopeitems[this.controlName];
    },  
    async mounted() {
        await this.Refresh();
    },
    props: {
        controlURL: '',
        controlName: '',
        payload: null,
        controlEvents: null,
    },
    computed: {
        controlURLvalue: function () {
            if (this.controlurlexpr)
                return utils.evaluate(this.controlurlexpr, this);
            else
                return '';
        },
        controlDataValue: function () {
            return this.controldatavalue;
        },
        sizeStyle() {
            //const sizeOptions = { ...this.getSizeDefaults(), ...utils.getSize(this.control.ControlData.SizeOptions, this.parentType, this.$parent) };
            //return sizeOptions;
            //return { display: 'flex', flexDirection: 'column', ...sizeOptions };

            return { display: 'flex', flexDirection: 'column', flexGrow: '1', overflow: 'auto', position: 'relative', alignSelf: 'normal' };
        },

        Params: function () {
            // When Params is referenced in the context of InitialValues for Variables, it must
            // refer to the parameter values passed into the control, which are read through the
            // controldatavalue proxy. But the unpredictable references to Params will come from
            // expressions embedded within parameters that were provided by the parent context.
            // In that case, Params must refer to the parent's Params object, which is the controlscope
            // object passed into this component via its props.

            return this.uselocalscope ? this.controldatavalue : this.controlscope;
        },
        Control: function () {
            // When Control is referenced in the context of InitialValues for Variables, it must
            // refer to the local context, which contains references to the child controls. But
            // the unpredictable references to Control will come from expressions embedded within
            // parameters that were provided by the parent context. In that case, Control must
            // refer to the parent's Control context, which is the scopeitems passed into this
            // component via its props.

            return this.uselocalscope ? this.newscopeitems : this.scopeitems;
        },
    },
    methods: {
        async refreshchildren(e) {
            utils.api.clearCache();
            await this.Refresh();
            this.key = utils.generateUUID();
        },
        async Refresh()
        {
            if (this.controlURL) {
                try {
                    //utils.debug(`UserControl loading from URL: ${this.controlURLvalue}`);
                    //utils.api.clearCache();

                    let apiRequest = {
                        method: 'GET',
                        url: `Document/UserControl/${this.controlURLvalue}`,
                        doNotUseWebsocket: true,
                        flatten: false,
                        cache: this.cacheControl || false
                    };
         
                    let c = await api.apiRequest(apiRequest);
                    let responseControl = c.data?.Result || c.data;
                    if (!responseControl) {
                        apiRequest.url = `Document/UserControl/public/${this.controlURLvalue}`;
                        c = await await api.apiRequest(apiRequest);
                        responseControl = c.data?.Result || c.data;
                    }

                    if (responseControl.$$ScopeItems) {
                        this.compileScopeItems(responseControl.$$ScopeItems);
                        //delete responseControl.$$ScopeItems;
                    }
                    else {
                        // Create default empty control scope for children
                        // this.newscopeitems = {};

                        // Add an empty object as this user control to the parent's control scope object, since we
                        // have no $$ScopeItems to embed into our public object.
                        if (this.scopeitems && this.controlName)
                            this.scopeitems[this.controlName] = {}; // new PlainWrapper({});
                    }

                        this.control = responseControl;
                        this.headers = c.headers;
                }
                catch (e) {
                    utils.warn(`UserControl ${this.controlURL} failed to load`, e);
                    this.control = {};
                }
            }
            else
                this.control = {};

            if (this.controlEvents && this.controlEvents.OnControlLoaded)
                await utils.executeAndCompileAllActions(this.controlEvents.OnControlLoaded, null, this);
        },
        compileScopeItems(items) {
            // this.newscopeitems becomes this.scopeitems for all child controls, which
            // is read as Control. This contains all the Variables, Functions, and control
            // references within the entire user control.

            // Params will be used to access this.controldatavalue, which is the proxy that
            // allows reading parameters passed into the user control from the parent. It
            // is provided to the child controls via the controlscope property.

            const public_vars = { };
            if (items.Variables) {
                // Cause computed Control value to read from controldatavalue instead of controlscope
                this.uselocalscope = true;

                for (let i = 0; i < items.Variables.length; i++) {
                    const v = items.Variables[i];

                    if (typeof v.InitialValue === 'string' && !v.InitialValue.startsWith('{#')) {
                        const f = utils.compile(this, v.InitialValue);
                        Vue.set(this.newscopeitems, v.Name, utils.evaluate(f, this));
                    }
                    else {
                        const f = utils.compileObject(this, v.InitialValue);
                        Vue.set(this.newscopeitems, v.Name, utils.evaluateObject(f, this));
                    }
    
                    if (v.Public)
                        public_vars[v.Name] = this.newscopeitems[v.Name];
                }

                this.uselocalscope = false;
            }

            const public_funcs = { };
            let js = 'function controller(basicService, scope, api, controlModel, util, promiseHelper)\n{\n';
            if(items.Functions) {
                for (let i = 0; i < items.Functions.length; i++) {
                    const f = items.Functions[i];
                    js += `\nthis.${f.Name} = ${f.Body};\n`;
    
                    if (f.Public)
                        public_funcs[f.Name] = true;
                }
            }
            js += '}';

            const finalContext = this;
            const api = utils.apiWrapper; // apiService;
            const chf = careHelpfulFunctions;
            const care = careService;
            const Control = this.newscopeitems;
            const Params = this.controldatavalue;

            let theJavascript = eval("new (" + js + ")(care, null, api, finalContext, chf, Control, Params);");

            for (let key in theJavascript)
                if (typeof theJavascript[key] === 'function') {
                    this.newscopeitems[key] = theJavascript[key].bind(this.newscopeitems);

                    if (key in public_funcs)
                        public_vars[key] = this.newscopeitems[key];
                }

            if(items.Commands) {
                for (let i = 0; i < items.Commands.length; i++) {
                    const cmd = items.Commands[i];
                    public_vars[cmd.Name] = { Actions: cmd.Actions, ControlScope: this.controldatavalue, ScopeItems: this.newscopeitems };
                }
            }

            this.commands = items.Commands;

            // Add this user control to the parent's Control object so they can refer to
            // the public variables and functions in this control by name.
            if (this.scopeitems && this.controlName)
                this.scopeitems[this.controlName] = public_vars; // new PlainWrapper(public_vars);
        },
        showContextMenu(e) {
            e.preventDefault()
            this.showMenu = false
            this.x = e.clientX
            this.y = e.clientY
            this.$nextTick(() => {
                this.showMenu = true
            })
        },
        openForLocalEdit(e, fn) {
            e.preventDefault();
            utils.openForLocalEdit({ Type: 'UserControl', Name: fn });
        },
        openForExternalEdit(e, fn, controlOwnerId, shared) {
            e.preventDefault();
            utils.OpenEditorForExternalEdit({ Type: 'UserControl', Name: fn, OwnerId: controlOwnerId }, shared);
        },
        openDocumentStandAlone(e, fn) {
            e.preventDefault();
            utils.OpenDocumentStandAlone({ Name: fn });
        },

        async performEmitControlEvent(action) {
            utils.log(`UserControl EmitControlEvent ${action.ActionData.EventName}`);

            if (action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            try {
                const eventName = utils.evaluate(action.eventName, action.context);
                const input = utils.evaluateObject(action.input, action.context);

                if (this.controlDataValue[eventName]) {
                    // context is "this" because we want the actions to execute in the scope of the user control's parent, not the action's parent
                    await utils.executeAndCompileAllActions(this.controlDataValue[eventName], input, this);
                }

                try {
                    await utils.success(action);
                }
                catch (e) { }
            }
            catch (e) {
                utils.error(`ActionService EmitControlEvent ${action.ActionData.EventName} failed`, e);

                try {
                    await utils.failure(action);
                }
                catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
    },
    render(h) {
        /*
         *  KNOWN ISSUE (7/9/2021):
         *  When the contents of this referenced user control is nonvisual (i.e. Javascript),
         *  the container that we output is still taking up space in the DOM and throws off
         *  the layout of the surrounding controls. See the administrative Phone Settings
         *  screen for an example. In that screen, I moved the Javascript reference control
         *  down to the bottom of the page. It was above the form and it would force the form
         *  to align to the bottom of the window.
        */

        if (!this.control || !this.control.ControlData) {
            //utils.debug(`UserControl URL:${this.controlURLvalue} rendering blank *********** *!*!*!*`);

            return (
                <transition name="component-fade" mode={document.hasFocus() ? 'out-in' : null}>
                    <div domPropsInnerHTML="<!-- No control -->">
                    </div>
                </transition>
            );
        }

        //utils.debug(`UserControl URL:${this.controlURLvalue} rendering *********** *!*!*!*`);

        let DynamicControl = utils.getDynamicComponent(h, this.control);

        if (!DynamicControl)
            DynamicControl = 'default-unknown';

        //utils.debug(`*********** *!*!*!* UserControl ${this.name || ''} URL:${this.controlURLvalue} of ${DynamicControl} parent ${this.parentType} from ${this.controlURL} *!*!*!*`); // - ${JSON.stringify(this.control.ControlData.SizeOptions || {})} - ${JSON.stringify(this.sizeStyle || {})}`);

        let debug;
        if (this.isdebug)
            debug = {
                controlURLvalue: this.controlURLvalue,
                controlCustomerId: this.headers?.customerid,
                refreshchildren: this.refreshchildren,
                openForLocalEdit: this.openForLocalEdit,
                openForExternalEdit: this.openForExternalEdit,
                openDocumentStandAlone: this.openDocumentStandAlone,
            };

        return (
            <transition name="component-fade" mode={document.hasFocus() ? 'out-in' : null}>
                <DynamicControl
                    class={{ 'c-UserControl': true, 
                            [`c-name-${this.name || 'unnamed'}`]: true}}
                    key={this.key}
                    on={{ 'finished-render': (reference) => this.finishRenderHandler(reference) }}
                    type={this.control.ControlType}
                    name={this.control.ControlData.Name}
                    root={this.root}
                    asbase={this.asbase}
                    parentType={this.parentType}
                    controlData={this.control.ControlData}
                    paramData={this.controlDataValue}
                    payload={this.payload}
                    on={this.$listeners}
                    debug={debug}
                    scopeitems={this.newscopeitems}
                    controlscope={this.controldatavalue}
                    cacheControl={this.control.CacheControl}
                    controlEvents={this.control.Events}
                >
                </DynamicControl>
            </transition>
        );

        // style="display: flex; flex-direction: column; flex-grow: 1; overflow: auto; position: relative; align-self: normal">
        /*
         * This code has been moved to the VerticalStackContent since I cannot add the debug refresh icon
         * without altering the structure of the HTML and breaking the layout. (9/14/2021)
         * 
        if (false && this.isdebug)
            return (
                <div
                    class={{ 'c-UserControl': true, [`c-name-${this.name || 'unnamed'}`]: true }}
                    style={this.sizeStyle}>
                    
                    <i
                        title="Debug Refresh"
                        class="mdi mdi-cached debug-refresh-icon"
                        style={{ cursor: "pointer" }}
                        on-click={(e) => this.refreshchildren(e)}
                        on-contextmenu={(e) => this.showContextMenu(e)}
                    >
                    </i>
                    <transition name="component-fade" mode="out-in">
                        <DynamicControl 
                            class={{ 'c-UserControl': true, [`c-name-${this.name || 'unnamed'}`]: true }}
                            key={this.key}
                            on={{ 'finished-render': (reference) => this.finishRenderHandler(reference) }}
                            type={this.control.ControlType} 
                            name={this.control.ControlData.Name} 
                            root={this.root} 
                            asbase={this.asbase}
                            parentType={this.parentType}
                            controlData={this.control.ControlData} 
                            paramData={this.controlDataValue}
                            payload={this.payload}
                            on={this.$listeners}>
                        </DynamicControl>
                    </transition>
                    <v-menu
                        value={this.showMenu}
                        position-x={this.x}
                        position-y={this.y}
                        absolute
                        offset-y
                        close-on-click
                    >
                        <v-list two-line>
                            <v-subheader class="ml-2 mr-2 subtitle-2">
                                {this.controlURLvalue}
                            </v-subheader>

                            <v-divider></v-divider>

                            <v-list-item-group color="primary">
                                <v-list-item key={0} on-click={(e) => this.refreshchildren(e)}>
                                    <v-list-item-icon>
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-content>
                                        <v-list-item-title>Reload</v-list-item-title>
                                        <v-list-item-subtitle>Flushes cache and reloads</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item key={1} on-click={(e) => this.openForLocalEdit(e, this.controlURLvalue)}>
                                    <v-list-item-icon>
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-content>
                                        <v-list-item-title>Edit</v-list-item-title>
                                        <v-list-item-subtitle>Local Tab</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item key={2} on-click={(e) => this.openForExternalEdit(e, this.controlURLvalue)}>
                                    <v-list-item-icon>
                                        <v-icon>mdi-pencil-box-multiple</v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-content>
                                        <v-list-item-title>Edit</v-list-item-title>
                                        <v-list-item-subtitle>New Window</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item key={3} on-click={(e) => this.openDocumentStandAlone(e, this.controlURLvalue)}>
                                    <v-list-item-icon>
                                        <v-icon>mdi-play-box-multiple</v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-content>
                                        <v-list-item-title>Open Stand-Alone</v-list-item-title>
                                        <v-list-item-subtitle>New Window</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>

                            </v-list-item-group>
                        </v-list>
                    </v-menu>
                </div>
            );
        else
        */

    }
});

