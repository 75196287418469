import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

Vue.component('cc-wizard-dsgn', {
    data: function () {
        return {
            current_step: 0,

            pickervisible: false,
            picker: null,
            pickertarget: 'body',
        }
    },
    computed: {
        Root: function () {
            return this.root._self;
        },
        DesignModel: function () {
            return this.designmodel;
        },
        selected$: function () {
            return this.Root.SelectedNode && this.Root.SelectedModel == this.designmodel;
        },
        selectedStep$: function () {
            if (this.current_step >= 0 && this.current_step < this.controlData.Steps.length)
                return this.Root.SelectedNode && this.Root.SelectedModel == this.controlData.Steps[this.current_step];
            else
                return false;
        },
    },
    methods: {
        ...methods,
        handleClick(e) {
            this.Root.SelectNode(this);
            e.cancelBubble = true;
            e.stopPropagation();
        },
        nextStep(e) {
            this.current_step++;
            if (this.current_step >= this.controlData.Steps.length)
                this.current_step = 0;

            e.cancelBubble = true;
            e.stopPropagation();
        },
        previousStep(e) {
            this.current_step--;
            if (this.current_step < 0)
                this.current_step = this.controlData.Steps.length - 1;

            e.cancelBubble = true;
            e.stopPropagation();
        },
        editStep(e) {
            const step = this.controlData.Steps[this.current_step];
            this.Root.SelectNode(this, step);

            e.cancelBubble = true;
            e.stopPropagation();
        },
        async addStep(e) {
            e.cancelBubble = true;
            e.stopPropagation();

            const schema_ = await utils.schema.get('/schema/public/Platform.Schema.DynamicControls.v1/DynamicControl_WizardStep');
            const schema = utils.schema.resolve_Of(schema_)
            const model = utils.schema.getDefaultModel(schema);

            if (!model.Title) model.Title = 'New Step';
            if (!model.Name) model.Name = 'Unnamed';

            if (!this.controlData.Steps)
                this.controlData.Steps = [];

            this.controlData.Steps.push(model);
            this.current_step = this.controlData.Steps.length - 1;
        },
        deleteStep(e) {
            if (this.current_step >= 0 && this.current_step < this.controlData.Steps.length) {
                this.controlData.Steps.splice(this.current_step, 1);

                if (this.current_step >= this.controlData.Steps.length)
                    this.current_step -= 1;

                //if (this.current_step >= 0 && this.current_step < this.controlData.Steps.length) {
                    //const step = this.controlData.Steps[this.current_step];
                    //this.Root.SelectNode(this, step);
                //}
                //else
                //    this.Root.SelectNode(this);
            }
            e.cancelBubble = true;
            e.stopPropagation();
        },

        addControl(e, target) {
            this.pickertarget = target;
            this.picker = <control-picker confirm={this.confirmAddControl} cancel={this.cancelAddControl}></control-picker>;
            this.pickervisible = true;
            e.cancelBubble = true;
            e.stopPropagation();
        },
        async confirmAddControl(id, title) {
            this.pickervisible = false;

            try {
                const schema_ = await utils.schema.get(id);
                const schema = utils.schema.resolve_Of(schema_)
                const model = utils.schema.getDefaultModel(schema);

                switch (this.pickertarget) {
                    case 'body':
                        if (!this.controlData.Steps[this.current_step].Controls)
                            Vue.set(this.controlData.Steps[this.current_step], 'Controls', []);

                        this.controlData.Steps[this.current_step].Controls.push(model);
                        //this.Root.SelectNode(model);
                        break;

                    case 'footer':
                        if (!this.controlData.Steps[this.current_step].FooterControls)
                            Vue.set(this.controlData.Steps[this.current_step], 'FooterControls', []);

                        this.controlData.Steps[this.current_step].FooterControls.push(model);
                        break;
                }
            }
            catch (e) {
                alert(e);
            }
        },
        cancelAddControl() {
            this.pickervisible = false;
        },
        pickerInput(value) {
            this.pickervisible = value;
        },
    },
    props: {
        name: '',
        root: null,
        designmodel: null,
        parentType: '',
        controlData: {}
    },
    render(h) {
        if (this.Root.SelectedNode == this._self && this.Root.SelectedModel &&
            this.Root.SelectedModel.$typeSchema == '/schema/public/Platform.Schema.DynamicControls.v1/DynamicControl_WizardStep') {
            for (let i = 0; i < this.controlData.Steps.length; i++) {
                if (this.controlData.Steps[i] == this.Root.SelectedModel) {
                    this.current_step = i;
                    break;
                }
            }
        }

        let items = [];
        if (this.current_step >= 0 && this.current_step < this.controlData.Steps.length) {
            const step = this.controlData.Steps[this.current_step];

            let body = [];
            if (step.Controls) {
                for (var i = 0; i < step.Controls.length; i++) {
                    const control = step.Controls[i];
                    let DynamicControl = utils.getDynamicComponent(h, control);

                    const _k = i + '_' + control.ControlType + '_' + control.$objectId;

                    if (!DynamicControl)
                        DynamicControl = 'default-unknown';

                    DynamicControl += '-dsgn';

                    body.push(
                        <DynamicControl key={_k} type={control.ControlType} name={control.ControlData.Name} root={this.root} designmodel={control} parentType="VerticalStack" controlData={control.ControlData}>
                        </DynamicControl>
                    );
                }
            }

            if (this.selectedStep$)
                body.push(
                    <v-btn v-tooltip="Add control to body" x-small icon style={{ zIndex: 992 }} on-click={(e) => this.addControl(e, 'body')}>
                        <v-icon small color="blue darken-2">mdi mdi-plus-circle</v-icon>
                    </v-btn>
                );

            items.push(
                <div style="display: flex; flex-direction: column;">
                    {body}
                </div>
            );

            let footer = [];

            if (step.FooterControls) {
                for (var i = 0; i < step.FooterControls.length; i++) {
                    const control = step.FooterControls[i];
                    let DynamicControl = utils.getDynamicComponent(h, control);

                    const _k = i + '_' + control.ControlType + '_' + control.$objectId;

                    if (!DynamicControl)
                        DynamicControl = 'default-unknown';

                    DynamicControl += '-dsgn';

                    footer.push(
                        <div style={{ float: "left" }}>
                            <DynamicControl key={_k} type={control.ControlType} name={control.ControlData.Name} root={this.root} designmodel={control} parentType="VerticalStack" controlData={control.ControlData}>
                            </DynamicControl>
                        </div>
                    );
                }
            }

            if (this.selectedStep$)
                footer.push(
                    <v-btn v-tooltip="Add control to footer" x-small icon style={{ zIndex: 992 }} on-click={(e) => this.addControl(e, 'footer')}>
                        <v-icon small color="green darken-2">mdi mdi-plus-circle</v-icon>
                    </v-btn>
                );

            items.push(
                <div style="display: flex;">
                    {footer}
                </div>
            );
        }

        const style = {
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            border: "1px solid #ddd",
            margin: "3px",
            borderRadius: "3px",
            ...utils.getSize(this.controlData.SizeOptions, this.parentType, this.$parent)
        };

        const links = [];
        links.push(<div class="design-options" title="Add Step" on-click={(e) => this.addStep(e)} style={{ top: "6px", right: "120px", fontSize: "large", display: this.selected$ ? 'block' : 'none' }}><i class="mdi mdi-plus-circle"></i></div>);
        links.push(<div class="design-options" title="View Previous Step" on-click={(e) => this.previousStep(e)} style={{ top: "6px", right: "100px", fontSize: "large", display: this.selected$ ? 'block' : 'none' }}><i class="mdi mdi-menu-left"></i></div>);
        links.push(<div class="design-options" title="Edit Step" on-click={(e) => this.editStep(e)} style={{ top: "8px", right: "65px", fontSize: "medium", display: this.selected$ ? 'block' : 'none' }}>[ {this.current_step + 1} ]</div>);
        links.push(<div class="design-options" title="View Next Step" on-click={(e) => this.nextStep(e)} style={{ top: "6px", right: "40px", fontSize: "large", display: this.selected$ ? 'block' : 'none' }}><i class="mdi mdi-menu-right"></i></div>);
        links.push(<div class="design-options" title="Delete Step" on-click={(e) => this.deleteStep(e)} style={{ top: "6px", right: "10px", fontSize: "large", display: this.selected$ ? 'block' : 'none' }}><i class="mdi mdi-close-circle-outline"></i></div>);

        let title;
        if (this.controlData.Title)
            title = this.controlData.Title;

        const headerstyle = {
            backgroundColor: "#f5f5f5",
            backgroundImage: "linear-gradient(to bottom,#f5f5f5 0,#e8e8e8 100%)",
            backgroundRepeat: "repeat-x",
            padding: "10px 15px",
            borderTopLeftRadius: "3px",
            borderTopRightRadius: "3px",
        };

        let breadcrumbs;
        if (this.controlData.ShowBreadcrumb) {
            breadcrumbs = [];
            for (let i = 0; i < this.controlData.Steps.length && i < this.current_step + 1; i++) {
                breadcrumbs.push(<span style={{ margin: "2px", paddingLeft: "4px", paddingRight: "4px", float: "left", backgroundColor: "#e8e8e8", borderRadius: "3px" }}>{this.controlData.Steps[i].Title}</span>);
                if (i < this.current_step)
                    breadcrumbs.push(<span style={{ marginTop: "2px", float: "left" }}><i class="mdi mdi-menu-right" /></span>);
            }
            breadcrumbs = <div style={{ fontSize: "smaller", padding: "5px" }}>{breadcrumbs}</div>;
        }

        

        return (
            <div
                class={{ 'designer-container': true, selected: this.selected$ }}
                style={style}
                on-click={(e) => this.handleClick(e)}>
                <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
                <div style={headerstyle}><h3>{title}</h3></div>
                {breadcrumbs}
                {links}
                <div
                    class={{ 'designer-container': true, selected: this.selectedStep$ }}
                    style={{ padding: "6px" }}
                    on-click={(e) => this.editStep(e)}>
                    <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selectedStep$ }}></div>
                    {items}
                </div>

                <v-dialog value={this.pickervisible} max-width="860" scrollable on-input={(value) => this.pickerInput(value)}>
                    {this.picker}
                </v-dialog>
            </div>
        );
    }
});