import BaseComponent from '../BaseComponentMixin.jsx';
import methods from '../../../Shared/methods';
import utils from '../../../Shared/utils.jsx';

export default {
    name: 'DropdownMultiSelectBase',
    data: function () {
        return {
            uniqueref: null,
        }
    },
    props: {
        source: {
            type: Array,
            default: () => []
        },
        ccmodel: null,
        nameField: null,
        valueField: null,
        placeholder: {
            type: String,
            default: ''
        },
        selectionLimit: {
            type: Number,
            default: 0
        },
        showCheckAll: {
            type: Boolean,
            default: true
        },
        showUncheckAll: {
            type: Boolean,
            default: true
        },
        dynamicButtonText: {
            type: Boolean,
            default: false
        },
        dynamicItemCount: {
            type: Number,
            default: 0
        },
        hint: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        hideDetails: {
            type: Boolean,
            default: true
        },
    },
    created() {
        this.uniqueref = utils.generateUUID();
    },
    computed: {
        allSelected() {
            return this.ccmodel?.length === this.source.length || (this.selectionLimit > 0 && this.ccmodel?.length === this.selectionLimit) || (this.$refs && this.$refs[this.uniqueref]?.filteredItems?.length == this.ccmodel?.length);
        },
        someSelected() {
            return this.ccmodel?.length > 0 && !this.allSelected;
        },
        selectAllIcon() {
            if (this.allSelected) return 'mdi-close-box'
            if (this.someSelected) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        localModel() {
            if (this.ccmodel && !Array.isArray(this.ccmodel))
                return [this.ccmodel];
            return this.ccmodel;
        }
    },
    //Created Replaced with preRenderComplete
    methods: {
        ...methods,
        async onInput(item) {
            let value = null;
            // A selection limit of 0 is meant to mean unlimited length, and a selection limit of 1 will emit a 
            // single item. We want to pop the last item off of the array if it violates the selection limit 
            if (Array.isArray(item) && this.selectionLimit != 0 && item.length > this.selectionLimit)
                item.pop();
            if (!Array.isArray(item))
                value = [item];
            else
                value = item;

            if (!value)
                value = [];

            this.$emit('input', value);
        },
        async toggleAll() {
            this.$nextTick(() => {
                let value = null;
                if (this.allSelected)
                    value = [];
                else
                    value = this.$refs[this.uniqueref].filteredItems.map(x => {
                        return this.getNameOrValue(this.valueField, x);
                    }).slice(0, this.selectionLimit > 0 ? this.selectionLimit : undefined);

                this.$emit('input', value);
            });
        },
        getNameOrValue(valueField, sourceObject) {
            if (typeof valueField === 'string')
                return sourceObject[valueField];
            else if (typeof valueField === 'function')
                return valueField(sourceObject);
            else
                return null;
        }
    },
    render(h) {
        try {
            let scopedSlots = {
                selection: ({ item, index, parent }) => {
                    // We can really only show information about the selected items if they are strings. Otherwise we can only show that there 
                    // are a certain number selected.
                    let content = null;
                    if (this.dynamicButtonText && typeof this.getNameOrValue(this.nameField, item) === 'string') {
                        if (index === 0) {
                            content = (
                                <span>{this.getNameOrValue(this.nameField, item)} {parent.isFocused ? <span >&nbsp;-&nbsp;</span> : null }</span>
                            );
                        }
                        else if (index < this.dynamicItemCount) {
                            content = (
                                <span>, {this.getNameOrValue(this.nameField, item)} {parent.isFocused ? <span >&nbsp;-&nbsp;</span> : null }</span>
                            );
                        }
                    }
                    else {
                        if (index === 0)
                            content = (
                                <span>{this.ccmodel?.length} {this.Translate("selected")} {parent.isFocused ? <span >&nbsp;-&nbsp;</span> : null }</span>
                            );
                    }

                    return content;
                }
            }

            let slots = [<span slot="label">{this.$slots.label}</span>];

            if (this.selectionLimit !== 1 && (this.showCheckAll || this.showUncheckAll))
                slots.push(
                    <div slot="prepend-item">
                        <v-list-item
                            ripple
                            on-click={this.toggleAll}
                        >
                            <v-list-item-action>
                                <v-icon color={this.ccmodel?.length > 0 ? 'primary' : ''}>
                                    {this.selectAllIcon}
                                </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {this.allSelected ? this.Translate("Unselect all") : this.Translate("Select all")}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                    </div>);

            return (
                <v-autocomplete
                    dense
                    outlined
                    item-text={this.nameField}
                    item-value={this.valueField}
                    items={this.source}
                    on-input={(val) => this.onInput(val)}
                    value={this.selectionLimit === 1 && Array.isArray(this.ccmodel) ? this.ccmodel[0] : this.ccmodel}
                    placeholder={this.placeholder}
                    multiple={this.selectionLimit !== 1}
                    scopedSlots={scopedSlots}
                    hide-details={this.hideDetails}
                    hint={this.hint}
                    label={this.label}
                    ref={this.uniqueref}
                >
                    {/* We have one button to toggle the check all, so we will show the button if multiple selection is allowed
                        and either show check all is true or show uncheck all is true */}
                    {slots}
                </v-autocomplete>
            );
        }
        catch (e) {
            utils.error('DropdownMultiSelectBase Render failed', e);
            return <div>DropdownMultiSelectBase Failed to Render {e}</div>;
        }
    }
};