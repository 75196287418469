import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import AccordionGroup from './AccordionGroup';
import EventBus from '../event-bus.js';

import _ from 'lodash';
import utils from '../../Shared/utils.jsx';
import methods from '../../Shared/methods';

Vue.component('accordion', {
    mixins: [BaseComponent],
    data: function () {
        return {
            closeOthersEval: null,
            accordionGroupEvals: null,
            openedItems: []
        }
    },
    computed: {
        closeOthers() {
            try {
                if(this.closeOthersEval === null && this.controlData.CloseOthers)
                    this.closeOthersEval = utils.compile(this, this.Translate(this.controlData.CloseOthers));
                if(this.closeOthersEval)
                    return utils.evaluate(this.closeOthersEval, this);
                return '';
            }
            catch (e) {
                utils.warn('CloseOthers could not evaluate expression: ' + this.controlData.CloseOthers + '; ' + e);
                return '';
            }
        },
        styles() {
            return {
                ...this.sizeStyle,
                ...utils.resolveStyleHints(this.styleHints, this),
            };
        }
    },
    //Mounted Replaced with preRenderComplete
    methods: {
        openItem(index) {
            if (this.closeOthers)
                this.openedItems = [index];
            else if (!this.openedItems.includes(index)) {
                utils.debug(`Accordion ${this.name} openItem(index:${index})`);
                this.openedItems.push(index)
            }
        },
        closeItem(index) {
            if(this.openedItems.includes(index))
                this.openedItems = this.openedItems.filter(item => item != index);
        },
        toggleOpen(index){
            if(this.openedItems.includes(index))
                this.closeItem(index);
            else
                this.openItem(index);
        },
        preRenderComplete() {
            if (this.controlData.AccordionGroup) {
                this.openedItems = this.controlData.AccordionGroup.map((v, i) => {
                    if (v.IsOpen)
                        return i;
                    return -1;
                }).filter(v => v > -1);
            }

            this.finishRenderHandler(this);
        }
    },
    props: {
    },
    render(h) {
        try {
            return (
                <div class={{ 'c-Accordion': true, [`c-name-${this.name || 'unnamed'}`]: true }}
                    v-show={this.isvisible} style={this.styles}>
                        <v-expansion-panels
                            multiple={!this.closeOthers}
                            multiple
                            vModel={this.openedItems}
                        >
                            {this.controlData.AccordionGroup.map((groupItem, index) => {
                                return (
                                    <accordion-group 
                                        controlData={groupItem} 
                                        parentType="Accordion" 
                                        name={groupItem.Name} 
                                        type="AccordionGroup"
                                        scopeitems={this.newscopeitems}
                                        controlscope={this.controlscope}
                                        on-open={() => this.openItem(index)}
                                        on-close={() => this.closeItem(index)}
                                        on-toggle-open={() => this.toggleOpen(index)}
                                        on={{ 'finished-render': (reference) => this.finishRenderHandler(reference) }}
                                        root={this.root}
                                    ></accordion-group>
                                )
                            })}
                        </v-expansion-panels>
                </div>
            );
        }
        catch(e) {
            utils.error('Accordion Render failed', e);
            return <div>Accordion Failed to Render {e}</div>;
        }
    }
});