<template>
    <v-card @mouseleave="commitChanges()">
        <v-card-title>{{ item.headerName }}</v-card-title>
        <v-card-subtitle>Histogram Settings</v-card-subtitle>
        <v-divider></v-divider>
        <v-card-text>
            <v-text-field dense outlined hide-details
                          label="Bucket Interval (sec)"
                          type="number"
                          v-model="interval">
            </v-text-field>
            <v-checkbox v-if="false" dense hide-details v-model="ext">
                <template v-slot:label>
                    Extended Bounds
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon class="ml-1" dense color="blue-grey lighten-4" v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
                        </template>
                        Extended bounds ensure the range you have selected is
                        always included even if the data does not exist.
                    </v-tooltip>
                </template>
            </v-checkbox>
            <div vstyle="display: flex; flex-direction: row;" v-if="false && ext">
                <v-text-field style="max-width:100px;" dense outlined hide-details label="Min" v-model="ext_min"></v-text-field>
                <v-text-field style="max-width:100px;" dense outlined hide-details label="Max" v-model="ext_max"></v-text-field>
            </div>
            <v-checkbox v-if="false" dense hide-details v-model="hard">
                <template v-slot:label>
                    Hard Bounds
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon class="ml-1" dense color="blue-grey lighten-4" v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
                        </template>
                        Hard bounds limit the buckets to the specified minimum and maximum.
                    </v-tooltip>
                </template>
            </v-checkbox>
            <div style="display: flex; flex-direction: row;" v-if="false && hard">
                <v-text-field style="max-width:100px;" dense outlined hide-details label="Min" v-model="hard_min"></v-text-field>
                <v-text-field style="max-width:100px;" dense outlined hide-details label="Max" v-model="hard_max"></v-text-field>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
    module.exports = {
        data: function () {
            return {
                interval: 30,
                ext: false,
                ext_min: 0,
                ext_max: 300,
                hard: false,
                hard_min: 0,
                hard_max: 300,
            }
        },
        props: {
            item: Object,
            //menu_is_open: null,
        },
        created() {
            if (this.item.interval)
                this.interval = this.item.interval;

            if (this.item.extended_bounds) {
                this.ext = true;
                this.ext_min = this.item.extended_bounds.min;
                this.ext_max = this.item.extended_bounds.max;
            }

            if (this.item.hard_bounds) {
                this.hard = true;
                this.hard_min = this.item.hard_bounds.min;
                this.hard_min = this.item.hard_bounds.min;
            }
        },
        methods: {
            commitChanges() {
                this.$emit('close');
                //this.menu_is_open = false;

                const newitem = {
                    colId: this.item.ColId,
                    interval: this.interval,
                };
                if (this.ext)
                    newitem.extended_bounds = {
                        min: this.ext_min,
                        max: this.ext_max,
                    };
                else
                    delete newitem.extended_bounds;

                if (this.hard)
                    newitem.hard_bounds = {
                        min: this.hard_min,
                        max: this.hard_max,
                    };
                else
                    delete newitem.hard_bounds;

                this.$emit('change', newitem);
            },
        }
    }
</script>

<style scoped>

</style>