import Vue from 'vue';
import Vuetify from 'vuetify';
import EventBus from '../../event-bus.js';
import utils from '../../../Shared/utils.jsx';

Vue.component('usercontrol-browser', {
    data: () => ({
        list: [],
        filter: '',
    }),
    props: {
        confirm: null,
        cancel: null,
    },
    created() {
    },
    mounted() {
        this.Refresh();
    },
    methods: {
        async Refresh() {
            this.list = await utils.api.get('Apps/UserControls/List');
        },
        returnControl(id, title) {
            this.confirm(id, title);
        },
        search(value) {
            this.filter = value;
        },
    },
    render(h) {
        let items;
        if (this.list.length > 0)
            items = this.list.filter(c => (!this.filter || c.Name.toLowerCase().includes(this.filter.toLowerCase()))).map(c => (
                <tr>
                    <td><span style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }} on-click={() => this.confirm(c.Name)}>{c.Name}</span></td>
                </tr>
            ));
        else
            items = <tr><td>Loading...</td></tr>;

        return (
            <v-card>

                <v-app-bar flat color="rgba(0, 0, 0, 0)" style={{ backgroundColor: 'var(--v-navigation-base)', maxHeight: '60px', height: '60px' }}>
                    <v-toolbar-title class="title white--text pl-0">
                        User Controls
                    </v-toolbar-title>

                    <v-spacer></v-spacer>
                    <v-btn elevation={0} color="white" icon on-click={() => this.Refresh()}>
                        <v-icon>mdi-refresh</v-icon>
                    </v-btn>

                    <v-btn elevation={0} color="white" icon on-click={this.cancel}>
                        <v-icon>mdi-close-box</v-icon>
                    </v-btn>
                    
                </v-app-bar>

                <v-card-text>
                    <v-text-field
                        label="Search"
                        single-line
                        prepend-icon="search"
                        autofocus
                        on-input={(value) => this.search(value)}
                    ></v-text-field>
                </v-card-text>
                <v-card-text style={{ minHeight: "450px", maxHeight: "450px" }}>
                    <table>
                        {items}
                    </table>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" flat on-click={this.cancel}>Cancel</v-btn>
                </v-card-actions>
            </v-card>
        );
    }
});