import Vue from 'vue';
import { appSettings } from '@/Shared/appSettings.js';
import careService from '@/Services/careService';
import token from '@/Services/token';
import './css/TranslationContainer.css';

import utils from '../../Shared/utils.jsx';
import api from '@/Services/api';
import methods from '../../Shared/methods';

Vue.component('translation-container', {
    data: function () {
        return {
            valueEval: null,
            editing: false,
            expanded: this.autoOpenModal,
            editedString: this.value,
            originalValue: this.value,
            keyModel: null,
            textfailed: 1,
        }
    },
    props: {
        context: {
            type: Object, 
            default: () => {}
        },
        value: {
            type: String, 
            default: ""
        },
        isPossibleHtml: {
            type: Boolean,
            default: false
        },
        autoOpenModal: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        textString() {
            try {
                if(this.valueEval === null && this.value)
                    this.valueEval = utils.compile(this.context._self, this.context._self.Translate(this.value));

                if (this.valueEval) {
                    const res = utils.evaluate(this.valueEval, this.context._self, false
                        /* Only activate this if required, it could be a high cost if too many text fields have errors
                        , () => {
                            // This is a callback that will only be called if evaluate throws an exception.
                            // If this happens, we read the textfailed (so Vue knows we care) and then
                            // after 100ms, we update textfailed to cause Vue to re-evaluate textString.
                            // (cap the retry at 10 times with increasing delay each attempt)
                            if (this.textfailed >= 0 && this.textfailed < 10)
                                setTimeout(() => this.textfailed++, 50 * this.textfailed);
                        }
                        */
                    );
                    this.$emit("grid-exported-text", res);
                    return res;
                }
                this.$emit("grid-exported-text", '');
                return '';
            }
            catch (e) {
                utils.warn('TranslationContainer textString could not evaluate expression: ' + this.value + '; ' + e);
                return '';
            }
        }
    },
    watch: {
        value: function () {
            this.valueEval = null;
        }
    },
    methods: {
        async openEdit(e) {
            this.stopPropagation(e);

            if(!this.keyModel) {
                let res;
                try {
                    res = await api.get(`Apps/Translation/TranslationKey?TranslationKeyID=${encodeURIComponent(this.value)}`);
                }
                catch(e){
                    utils.warn('Failed to get translation key');
                }

                if(res?.data?.Result) {
                    this.keyModel = res.data.Result;

                    // If we have a locale for the user's currently selected locale, we want to show that in the input box. By default it shows the original 
                    // document text, but this will update it to be the translation for the current language.
                    if(this.keyModel.locales.length) {
                        let localeIndex = this.keyModel.locales.findIndex(locale => locale.locale === appSettings.LanguageKey);

                        if(localeIndex >= 0) {
                            this.editedString = this.keyModel.locales[localeIndex].translation;
                            this.originalValue = this.keyModel.locales[localeIndex].translation;
                        }
                    }
                    this.keyModel.saved = true;
                }
                else 
                    this.keyModel = { saved: false };
            }
            if(this.autoOpenModal)
                this.expanded = true;
            this.editing = true;
        },
        openExpanded(e) {
            this.stopPropagation(e);

            this.expanded = true;
        },
        closeExpanded(e) {
            this.stopPropagation(e);

            this.expanded = false;

            if(this.autoOpenModal)
                this.editing = false;
        },
        async saveTranslation(e) {
            this.stopPropagation(e);
            if(this.editedString === this.originalValue) {
                this.editing = false;
                return;
            }

            // This key is already saved. We only need to update it
            if(this.keyModel.saved) {
                let localeIndex = -1;

                // There are no locales
                if(!this.keyModel.locales || !Array.isArray(this.keyModel.locales) || !this.keyModel.locales.length)
                    this.keyModel.locales = [
                        {
                            locale: appSettings.LanguageKey,
                            translation: this.editedString
                        }
                    ];
                else {
                    localeIndex = this.keyModel.locales.findIndex(locale => locale.locale === appSettings.LanguageKey);

                    // The current locale is in the list of saved translations, so we just need to update it.
                    if(localeIndex >= 0)
                        this.keyModel.locales[localeIndex].translation = this.editedString;
                    // The current locale does not exist, so we will add it to the array
                    else
                        this.keyModel.locales.push(
                            {
                                locale: appSettings.LanguageKey,
                                translation: this.editedString
                            }
                        )
                }
            }
            // This key has not been saved, so we need to create a new model for it. 
            else {
                this.keyModel = {
                    key: this.value,
                    description: "",
                    customerID: token.CustomerID(),
                    locales: [
                        {
                            locale: appSettings.LanguageKey,
                            translation: this.editedString
                        }
                    ]
                }
            }

            try {
                api.post('Apps/Translation/TranslationKey', this.keyModel);
                if(!careService.globalTranslationList[this.keyModel.customerID || token.CustomerID()])
                    careService.globalTranslationList[this.keyModel.customerID || token.CustomerID()] = {};
                careService.globalTranslationList[this.keyModel.customerID || token.CustomerID()][this.editedString] = this.editedString;
                this.keyModel = null;
                this.originalValue = this.editedString;
                this.valueEval = utils.compile(this.context._self, this.context._self.Translate(this.editedString));
                this.editing = false;
            }
            catch(e) {
                utils.warn('Failed to save translation key ' + this.value);
            }
        },
        async modalSave(e) {
            this.expanded = false;
            await this.saveTranslation(e);
        },
        stopPropagation(e) {
            e.stopPropagation();
            return;
        }
    },
    render(h) {
        if(!this.value)
            return null;

        let theContent = this.textString;

        if(this.isPossibleHtml && theContent.includes('<') && theContent.includes('>'))
            theContent = <div domPropsInnerHTML={theContent}></div>;
        
        if(appSettings.DebugTranslationPrefixSetting && !this.context._self.isExpressionOnly(this.value)) {
            let content;

            if(this.editing)
                content = [
                    <v-btn elevation={0}
                        icon
                        x-small
                        on-click={this.saveTranslation}
                        on-mousedown={this.stopPropagation}
                        on-mouseup={this.stopPropagation}
                        on-focus={this.stopPropagation}
                        style={{color: 'inherit'}}
                    >
                        <v-icon style={{color: 'inherit'}}>mdi-check</v-icon>
                    </v-btn>,
                    <div
                        on-focus={this.stopPropagation}
                        on-click={this.stopPropagation}
                        on-mousedown={this.stopPropagation}
                        on-mouseup={this.stopPropagation}
                    >
                        <v-text-field
                            dense
                            hide-details
                            outlined
                            vModel={this.editedString}
                            on-focus={this.stopPropagation}
                            on-click={this.stopPropagation}
                            on-mousedown={this.stopPropagation}
                            on-mouseup={this.stopPropagation}
                            height="20px"
                            class="pt-1 pb-1"
                            color="black"
                            background-color="white"
                            light
                        >
                            <v-btn elevation={0}
                                light
                                slot="append"
                                icon
                                x-small
                                on-click={this.openExpanded}
                                on-mousedown={this.stopPropagation}
                                on-mouseup={this.stopPropagation}
                                on-focus={this.stopPropagation}
                            >
                                <v-icon>mdi-arrow-expand</v-icon>
                            </v-btn>
                        </v-text-field>
                    </div>,
                    <v-dialog
                        vModel={this.expanded}
                    >
                        <v-card>
                            <v-card-title>
                                <v-spacer></v-spacer>
                                <v-btn elevation={0}
                                    icon
                                    on-click={this.closeExpanded}
                                >
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-card-title>
                            <v-card-text>
                                <div class="d-flex flex-column flex-grow-1 mb-2">
                                    <translation-container class="mr-1 text-h6 font-weight-bold" context={this.context._self} value="Original: "></translation-container>
                                    <span class="mr-auto">{this.value}</span>
                                </div>
                                <v-textarea
                                    vModel={this.editedString}
                                    outlined
                                    dense
                                    hide-details
                                ></v-textarea>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn elevation={0}
                                    color="primary"
                                    on-click={this.modalSave}
                                ><translation-container context={this.context._self} value="Save"></translation-container></v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                ];
            else 
                content = [
                    <v-btn elevation={0}
                        icon
                        x-small
                        hide-details
                        on-click={this.openEdit}
                        on-focus={this.stopPropagation}
                        on-mousedown={this.stopPropagation}
                        on-mouseup={this.stopPropagation}
                        style={{color: 'inherit'}}
                    >
                        <v-icon style={{color: 'inherit'}}>mdi-pencil</v-icon>
                    </v-btn>,
                    <span>{theContent}</span>
                ]

            return (
                <div class="d-flex align-center translation-container"
                >{content}</div>
            )
        }
        else {
            return (
                <span style={{ cursor: 'inherit' }}>{theContent}</span>
            )
        }
    }
});