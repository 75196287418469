import Vue from 'vue';
import utils from '../../Shared/utils.jsx';
import methods from '../../Shared/methods';
import BaseComponentMixin from './BaseComponentMixin.jsx';
import { appSettings } from '@/Shared/appSettings';

Vue.component('default-unknown', {
    mixins: [BaseComponentMixin],
    data: function () {
        return {
        }
    },
    computed: {
    },
    created() {
    },
    //mounted() {
    //    this.$emit("finished-render", this);
    //},
    methods: {
        preRenderComplete() {
            this.finishRenderHandler(this);
        },
    },
    props: {
    },
    render(h) {
        //if (!this.todisplay)
        //    return null;

        let name;
        if (!this.name)
            name = this.type;
        else
            name = this.name + ':' + this.type || 'Unnamed';

        return (
            <div v-show={appSettings.ShowNotImplemented}
                class={{ [`c-${this.type}`]: true, [`c-name-${this.name || 'unnamed'}`]: true }}
                style={{ backgroundColor: "#a0ffa0", ...this.sizeStyle }}>
                {name}<br />
            </div>
        );
    }
});