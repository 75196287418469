import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import EventBus from '../event-bus.js';
import './css/HtmlTable.css';

import utils from '../../Shared/utils.jsx';
import computed from '../../Shared/computed';
import methods from '../../Shared/methods';

Vue.component('html-table-row', {
    data() {
        return {
            finishedRender: false,
            renderCount: 0,
        }
    },
    computed: {
        ...computed,
        totalItems() {
            let count = 1;

            this.columnDefs.forEach(column => {
                column.ContentDefs.forEach(contentDef => {
                    count += contentDef.Controls.length;
                });
            });

            return count
        },
    },
    //Mounted Replaced with preRenderComplete
    methods: {
        ...methods,
        preRenderComplete() {
            this.finishRenderHandler(this);
        },
        finishRenderHandler() {
            if(this.finishedRender)
                return;
            this.renderCount++;
            if(this.renderCount >= this.totalItems) {
                this.renderComplete();
            }
        },
        async renderComplete() {
            this.finishedRender = true;
            this.$emit('finished-render');
        }
    },
    props: {
        root: null,
        parentType: null,
        scopeitems: null,
        controlscope: null,

        sourceData: null,
        columnDefs: null,
        alternatingRowColor: null,//'transparent',
        index: 0
    },
    render(h) {
        let columns = this.columnDefs.map(column => {
            let style = {};
            if(column.PaddingTop)
                style.paddingTop = column.PaddingTop;
            if(column.PaddingBottom)
                style.paddingBottom = column.PaddingBottom;
            if(column.PaddingLeft)
                style.paddingLeft = column.PaddingLeft;
            if(column.PaddingRight)
                style.paddingRight = column.PaddingRight;

            let content = column.ContentDefs.map(contentDef => {
                let items = utils.generateItemsFromArray(h, this, contentDef.Controls, null, 'HorizontalLayout', {'finished-render': function() {this.finishRenderHandler()}.bind(this) });

                return (
                    <div style={{display: 'flex'}}>
                        {items}
                    </div>
                )
            });
            
            return (
                <td style={style} align={column.Align ? column.Align.toLowerCase() : ''}>
                    {content}
                </td>
            );
        })

        return (
            <tr style={{ backgroundColor: this.alternatingRowColor && (this.index % 2) ? this.alternatingRowColor : '' }}>
                {columns}
            </tr>
        )
    }
});

Vue.component('html-table', {
    mixins: [BaseComponent],
    data: function () {
        return {
            sourceUrlEval: null,
            sourceRawInterpolatedEval: null,
            alternatingRowColorEval: null,
            sourceData: null,
            data: null,
        }
    },
    async created() {
        switch(this.controlData.SourceType.toLowerCase()) {
            case 'url': {
                this.sourceUrlEval = utils.compile(this, this.controlData.SourceUrl);
                break;
            }
            case 'rawinterpolated':
            case 'raw': {
                this.sourceRawInterpolatedEval = utils.compileObject(this, this.controlData.SourceRaw);
                break;
            }
        }

        this.renderComplete();
    },
    computed: {
        totalItems() {
            return this.source.length + 1;
        },
        sourceUrl() {
            try {
                return utils.evaluate(this.sourceUrlEval, this) || [];
            }
            catch(err) {
                return [];
            }
        },
        sourceRawInterpolated(){
            try {
                return utils.evaluateObject(this.sourceRawInterpolatedEval, this) || [];
            }
            catch(err) {
                return [];
            }
        },
        alternatingRowColor() {
            try {
                if(this.alternatingRowColorEval === null && this.controlData.AlternatingRowColor)
                    this.alternatingRowColorEval = utils.compile(this, this.controlData.AlternatingRowColor);
                if(this.alternatingRowColorEval)
                    return utils.evaluate(this.alternatingRowColorEval, this);
                return '';
            }
            catch (e) {
                utils.warn('AlternatingRowColor could not evaluate expression: ' + this.controlData.AlternatingRowColor + '; ' + e);
                return '';
            }
        },
        containerStyles() {
            return {
                ...this.sizeStyle,
                display: 'flex',
                flexDirection: 'column'
            }
        }
    },
    methods: {
        async preRenderComplete() {
            await this.Refresh();
            this.finishRenderHandler(this);
        },
        async Refresh() {
            switch (this.controlData.SourceType.toLowerCase()) {
                case 'url': {
                    this.data = await utils.api.get(this.sourceUrl);
                    break;
                }
                case 'rawinterpolated':
                case 'raw': {
                    this.data = this.sourceRawInterpolated;
                    break;
                }
            }
        }
    },
    props: {
    },
    render(h) {
        if (!this.prerender_complete || !this.todisplay || !this.data)
            return null;

        try {
            let rows = this.data.map((item, index) => {
                return (
                    <html-table-row
                        key={'row' + index}
                        root={this.root}
                        parentType="HorizontalLayout"
                        scopeitems={this.scopeitems}
                        controlscope={this.controlscope}
                        sourceData={item}
                        columnDefs={this.controlData.ColumnDefs}
                        index={index}
                        alternatingRowColor={this.alternatingRowColor}
                        on={{'finished-render': () => this.finishRenderHandler() }}
                    ></html-table-row>
                )
            });

            let headerRows = this.controlData.ColumnDefs.map((col, index) => {
                return (
                    <th>{col.Header}</th>
                );
            });

            if(!rows.length)
                rows = (
                    <div>No data</div>
                );
    
            return (
                <div v-show={this.isvisible} class={{ 'c-HtmlTable': true, [`c-name-${this.name || 'unnamed'}`]: true }} 
                    style={this.containerStyles}>
                    <v-simple-table dense>
                        <thead>
                            <tr>
                                {headerRows}
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </v-simple-table>
                </div>
            );
        }
        catch (e) {
            utils.error('HtmlTable Render failed', e);
            return <div>HtmlTable Failed to Render {e}</div>;
        }
    }
});