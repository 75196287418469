<template>
    <span>{{ elapsedTime }}</span>
</template>

<script>
    import careHelpfulFunctions from '@/Application/careHelpfulFunctions.jsx';

    export default {
        name: 'ElapsedTime',
        data: function () {
            return {
            }
        },
        props: {
            starttime: null,
        },
        computed: {
            elapsedTime: function () {
                return careHelpfulFunctions.elapsedTimeAsString(this.starttime, careHelpfulFunctions.date());
            }
        },
    };
</script>

<style scoped>

</style>