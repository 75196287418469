import Vue from 'vue';
import utils from '../../Shared/utils.jsx';
import BaseComponent from './BaseComponentMixin.jsx';
import cloneDeep from 'lodash.clonedeep';

Vue.component('cc-timeline', {
    mixins: [BaseComponent],
    data: function () {
        return {
            sourceUrlEval: null,
            data: null,
            sourceRawInterpolatedEval: null,
            tooltiprenderer: null,
            fontNameEval: null,
            fontSizeEval: null,
            heightEval: null,
            widthEval: null,
            chartPaddingEval: null,
            avoidOverlappingGridLinesEval: null,
            backgroundColorEval: null,
            barLabelStyleEval: null,
            colorByRowLabelEval: null,
            groupByRowLabelEval: null,
            rowLabelStyleEval: null,
            showBarLabelsEval: null,
            showRowLabelsEval: null,
            singleColorEval: null,
            chart: null,
            dataTable: null,
            usefulHandlerListener: null,
            hasActions: false,
            googleOnLoadCallback: null,
            readyToDraw: false
        }
    },
    computed: {
        sourceUrl() {
            try {
                return utils.evaluate(this.sourceUrlEval, this) || null;
            }
            catch (err) {
                return null;
            }
        },
        sourceRawInterpolated() {
            try {
                return utils.evaluateObject(this.sourceRawInterpolatedEval, this) || null;
            }
            catch (err) {
                return null;
            }
        },
        source() {
            switch (this.controlData.SourceType.toLowerCase()) {
                case 'url':
                    return this.data;
                case 'raw':
                case 'rawinterpolated': return this.sourceRawInterpolated;
                default: return null;
            }
        },
        fontName() {
            try {
                if (this.fontNameEval === null && this.controlData.FontName)
                    this.fontNameEval = utils.compile(this, this.controlData.FontName);
                if (this.fontNameEval)
                    return utils.evaluate(this.fontNameEval, this);
                return 'Arial';
            }
            catch (e) {
                utils.warn('Timeline could not evaluate expression: ' + this.controlData.FontName + '; ' + e);
                return 'Arial';
            }
        },
        fontSize() {
            try {
                if (this.fontSizeEval === null && this.controlData.FontSize)
                    this.fontSizeEval = utils.compile(this, this.controlData.FontSize);
                if (this.fontSizeEval)
                    return utils.evaluate(this.fontSizeEval, this);
                return 'automatic';
            }
            catch (e) {
                utils.warn('Timeline could not evaluate expression: ' + this.controlData.FontSize + '; ' + e);
                return 'automatic';
            }
        },
        height() {
            try {
                if (this.heightEval === null && this.controlData.Height)
                    this.heightEval = utils.compile(this, this.controlData.Height);
                if (this.heightEval)
                    return utils.evaluate(this.heightEval, this);
                return '';
            }
            catch (e) {
                utils.warn('Timeline could not evaluate expression: ' + this.controlData.Height + '; ' + e);
                return '';
            }
        },
        width() {
            try {
                if (this.widthEval === null && this.controlData.Width)
                    this.widthEval = utils.compile(this, this.controlData.Width);
                if (this.widthEval)
                    return utils.evaluate(this.widthEval, this);
                return '';
            }
            catch (e) {
                utils.warn('Timeline could not evaluate expression: ' + this.controlData.Width + '; ' + e);
                return '';
            }
        },
        chartPadding() {
            try {
                if (this.chartPaddingEval === null && this.controlData.ChartPadding)
                    this.chartPaddingEval = utils.compile(this, this.controlData.ChartPadding);
                if (this.chartPaddingEval)
                    return utils.evaluate(this.chartPaddingEval, this);
                return '';
            }
            catch (e) {
                utils.warn('Timeline could not evaluate expression: ' + this.controlData.ChartPadding + '; ' + e);
                return '';
            }
        },
        avoidOverlappingGridLines() {
            try {
                if (this.avoidOverlappingGridLinesEval === null && this.controlData.AvoidOverlappingGridLines)
                    this.avoidOverlappingGridLinesEval = utils.compileObject(this, this.controlData.AvoidOverlappingGridLines);
                if (this.avoidOverlappingGridLinesEval)
                    return utils.evaluateObject(this.avoidOverlappingGridLinesEval, this);
                return false;
            }
            catch (e) {
                utils.warn('Timeline could not evaluate expression: ' + this.controlData.AvoidOverlappingGridLines + '; ' + e);
                return false;
            }
        },
        backgroundColor() {
            try {
                if (this.backgroundColorEval === null && this.controlData.BackgroundColor)
                    this.backgroundColorEval = utils.compile(this, this.controlData.BackgroundColor);
                if (this.backgroundColorEval)
                    return utils.evaluate(this.backgroundColorEval, this);
                return '';
            }
            catch (e) {
                utils.warn('Timeline could not evaluate expression: ' + this.controlData.BackgroundColor + '; ' + e);
                return '';
            }
        },
        barLabelStyle() {
            try {
                if (this.barLabelStyleEval === null && this.controlData.BarLabelStyle)
                    this.barLabelStyleEval = utils.compile(this, this.controlData.BarLabelStyle);
                if (this.barLabelStyleEval)
                    return utils.evaluate(this.barLabelStyleEval, this);
                return '';
            }
            catch (e) {
                utils.warn('Timeline could not evaluate expression: ' + this.controlData.BarLabelStyle + '; ' + e);
                return '';
            }
        },
        colorByRowLabel() {
            try {
                if (this.colorByRowLabelEval === null && this.controlData.ColorByRow)
                    this.colorByRowLabelEval = utils.compileObject(this, this.controlData.ColorByRow);
                if (this.colorByRowLabelEval)
                    return utils.evaluateObject(this.colorByRowLabelEval, this);
                return false;
            }
            catch (e) {
                utils.warn('Timeline could not evaluate expression: ' + this.controlData.ColorByRow + '; ' + e);
                return false;
            }
        },
        groupByRowLabel() {
            try {
                if (this.groupByRowLabelEval === null && this.controlData.GroupByRow)
                    this.groupByRowLabelEval = utils.compileObject(this, this.Translate(this.controlData.GroupByRow));
                if (this.groupByRowLabelEval)
                    return utils.evaluateObject(this.groupByRowLabelEval, this);
                return true;
            }
            catch (e) {
                utils.warn('Timeline could not evaluate expression: ' + this.controlData.GroupByRow + '; ' + e);
                return true;
            }
        },
        rowLabelStyle() {
            try {
                if (this.rowLabelStyleEval === null && this.controlData.RowLabelStyle)
                    this.rowLabelStyleEval = utils.compile(this, this.controlData.RowLabelStyle);
                if (this.rowLabelStyleEval)
                    return utils.evaluate(this.rowLabelStyleEval, this);
                return '';
            }
            catch (e) {
                utils.warn('Timeline could not evaluate expression: ' + this.controlData.RowLabelStyle + '; ' + e);
                return '';
            }
        },
        showBarLabels() {
            try {
                if (this.showBarLabelsEval === null && this.controlData.ShowBarLabels)
                    this.showBarLabelsEval = utils.compileObject(this, this.controlData.ShowBarLabels);
                if (this.showBarLabelsEval)
                    return utils.evaluateObject(this.showBarLabelsEval, this);
                return true;
            }
            catch (e) {
                utils.warn('Timeline could not evaluate expression: ' + this.controlData.ShowBarLabels + '; ' + e);
                return true;
            }
        },
        showRowLabels() {
            try {
                if (this.showRowLabelsEval === null && this.controlData.ShowRowLabels)
                    this.showRowLabelsEval = utils.compileObject(this, this.controlData.ShowRowLabels);
                if (this.showRowLabelsEval)
                    return utils.evaluateObject(this.showRowLabelsEval, this);
                return true;
            }
            catch (e) {
                utils.warn('Timeline could not evaluate expression: ' + this.controlData.ShowRowLabels + '; ' + e);
                return true;
            }
        },
        singleColor() {
            try {
                if (this.singleColorEval === null && this.controlData.SingleColor)
                    this.singleColorEval = utils.compileObject(this, this.controlData.SingleColor);
                if (this.singleColorEval)
                    return utils.evaluateObject(this.singleColorEval, this);
                return '';
            }
            catch (e) {
                utils.warn('Timeline could not evaluate expression: ' + this.controlData.SingleColor + '; ' + e);
                return '';
            }
        },
        formattedData() {
            let dataCopy = cloneDeep(this.source);

            if (dataCopy) {
                dataCopy = this.addDates(dataCopy);
                dataCopy = this.removeExtraColumns(dataCopy);
            }

            return dataCopy;
        },
        styles() {
            let styles = {
                ...this.sizeStyle,
                overflow: 'hidden',
                border: '1px solid silver',
            };

            if (this.hasActions)
                styles.cursor = 'pointer'
            return styles;
        },
        hasData() {
            return this.source !== null && this.source.rows && this.source.rows.length !== 0;
        }
    },
    //Created Replaced with preRenderComplete
    mounted() {
    },
    watch: {
        isvisible: function () {
            this.getChart();
        },
        todisplay: function () {
            this.getChart();
        }
    },
    methods: {
        async Refresh() {
            await this.getData();
            this.getChart();
        },
        async preRenderComplete() {
            await this.getData()

            this.finishRenderHandler(this);

            this.readyToDraw = true;

            this.getChart();
        },
        async getData() {
            switch (this.controlData.SourceType.toLowerCase()) {
                case 'url': {
                    this.sourceUrlEval = utils.compile(this, this.controlData.SourceURL);
                    this.data = this.sourceUrl ? await utils.api.get(this.sourceUrl) : null;
                    break;
                }
                case 'rawinterpolated':
                case 'raw': {
                    this.sourceRawInterpolatedEval = utils.compileObject(this, this.controlData.SourceRaw);
                    break;
                }
            }
        },
        getChart() {
            if (!this.readyToDraw)
                return;
            if (google.visualization) {
                this.$nextTick(() => {
                    if (this.$refs.chart)
                        this.chart = new google.visualization.Timeline(this.$refs.chart);

                    this.dataTable = new google.visualization.DataTable();

                    this.drawChart();
                });
            }
            else if (!this.googleOnLoadCallback) {
                this.googleOnLoadCallback = google.charts.setOnLoadCallback(this.getChart);
            }
        },
        drawChart() {
            if (!this.hasData)
                return;

            this.dataTable = new google.visualization.DataTable(this.formattedData);

            let options = {
                timeline: {
                    barLabelStyle: this.barLabelStyle,
                    colorByRowLabel: this.colorByRowLabel,
                    groupByRowLabel: this.groupByRowLabel,
                    rowLabelStyle: this.rowLabelStyle,
                    showBarLabels: this.showBarLabels,
                    showRowLabels: this.showRowLabels,
                    singleColor: this.singleColor,
                },
                height: this.height,
                width: this.width,
                backgroundColor: this.backgroundColor,
                fontName: this.fontName,
                fontSize: this.fontSize,
                avoidOverlappingGridLines: this.avoidOverlappingGridLines,
                tooltip: { isHtml: true },
            };

            if (this.colors) options.colors = this.colors;

            google.visualization.events.addListener(this.chart, 'error', function (err) {
                // supress the errors... i dont know why, but hide them!
                google.visualization.errors.removeError(err.id);
            });

            if (this.controlData.Actions || this.controlData.LabelActions)
                google.visualization.events.addListener(this.chart, 'ready', this.chartReady);

            // must use a timeout to allow the controls to render on the page first so we can truly know if hidden or not
            this.$nextTick(() => {
                // the chart must not be drawn while hidden.
                // if the chart is drawn while hidden the legends will overlap and look terrible, known bug in google chart themselves
                if (this.isvisible && this.todisplay) {
                    // get element height and width, use parent width for sizing on width
                    options.width = (this.$el.parentElement.scrollWidth - 1); // no scrollbars!!!
                    options.height = (this.$el.scrollHeight - 1);

                    if (!this.height) {
                        // gotta draw it so we can resize it
                        this.chart.draw(this.dataTable, options);

                        // this will allow us to "shrink" to the smallest size possible without scroll bars, to only be used if they do not explicitly set a height on timeline chart
                        var paddingHeight = this.chartPadding;
                        var testDiv = this.$el.querySelector('svg'); // find the google chart component svg
                        if (testDiv) {
                            var realHeight = testDiv.children[1].getBBox().height; // height of the chart rows
                            // multiple lines have a seperate SVG for the bottom timeline, grab the correct timeline
                            var svgCount = this.$el.querySelectorAll('svg').length;
                            if (svgCount > 1) {
                                realHeight = this.$el.querySelectorAll('svg')[1].getBBox().height;
                            }


                            var chartHeight = parseInt(realHeight) + parseInt(paddingHeight); // add in the custom padding or 55 offset if they did not supply it
                            options.height = chartHeight;
                        }
                        //}
                        //redraw with realheight
                        //c.chart.draw(c.dataTable, options);
                    }
                    else if (options.height < parseInt(this.height)) {
                        options.height = parseInt(this.height);
                    }

                    this.chart.draw(this.dataTable, options);
                }
            });
        },
        addDates(data) {
            var dateCols = [];
            for (var i = 0; i < data.cols.length; i++) {
                var col = data.cols[i];
                if (col.type == 'date') {
                    dateCols.push(i);
                }
            }

            for (var row of data.rows) {
                for (var i of dateCols) {
                    row.c[i].v = new Date(row.c[i].v);
                }
            }
            return data;
        },
        removeExtraColumns(data) {
            for (var row of data.rows) {
                var rowLength = row.c.length;
                row.c.splice(5, rowLength); // remove the id columns since they cannot be used in the timeline
            }
            return data;
        },
        chartReady() {
            if ((this.controlData.Actions && this.controlData.Actions.length > 0)) {
                // give elements a pointer
                this.hasActions = true;
                if (this.usefulHandlerListener)
                    google.visualization.events.removeListener(this.usefulHandlerListener);
                this.usefulHandlerListener = google.visualization.events.addListener(this.chart, 'select', this.usefulHandler);
            }
            if ((this.controlData.LabelActions && this.controlData.LabelActions.length > 0)) {
                let counter = 0;
                var rowLabels = thid.$el.getElementsByTagName('text');
                Array.prototype.forEach.call(rowLabels, function (label) {
                    if (label.getAttribute('text-anchor') === 'end') {
                        c.labelArray[counter] = label.innerHTML;
                        label.style = 'cursor: pointer';
                        (function (acounter) {
                            label.addEventListener('click', function (sender, acounter) {
                                utils.executeAndCompileAllActions(this.controlData.LabelActions, { Data: sender.target.innerHTML }, this);
                            }, false);
                        })(counter)

                        counter++;
                    }
                });
            }
        },
        async usefulHandler(e) {
            var selectedRow = this.chart.getSelection()[0].row;
            var arrayInfo = this.source.rows[selectedRow].c;
            // var arrayInfo = this.formattedData.rows[selectedRow].c; // includes timelineid AND userid

            await utils.executeAndCompileAllActions(this.controlData.Actions, { Data: arrayInfo }, this);
        }
    },
    props: {
    },
    render(h) {
        try {
            if (!this.todisplay)
                return null;

            return (
                <div
                    class={{ 'c-Timeline': true, [`c-name-${this.name || 'unnamed'}`]: true, 'd-flex flex-column align-center': true, 'ma-2': true }}
                    style={this.styles}
                    v-show={this.isvisible}>
                    {!this.hasData ?
                        <div>{this.Translate('- No data currently available -')}</div> :
                        <div ref="chart"></div>
                    }
                </div>
            );
        }
        catch (e) {
            utils.error('Timeline Render failed', e);
            return <div>Timeline Failed to Render {e}</div>;
        }
    }
});