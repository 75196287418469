import Vue from 'vue';
import BaseComponent from './baseFormMixin.jsx';
import BaseFieldsetComponent from './baseFieldsetMixin.jsx';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

Vue.component('sform-anyof', {
    mixins: [BaseComponent],
    data: function () {
        return {
            listitems: [],
            selected_item: null,
            selected_value: null,
            form_all: [],
        }
    },
    created() {
        const schema = this.element.schema;

        const items = [{ text: '-None-', value: "!!null!!" }];

        for (var i = 0; i < schema.anyOf.length; i++) {
            const s = schema.anyOf[i];
            const item = utils.schema.resolve_Of(s);

            items.push({ text: item.title, value: s.Id });
        }

        this.listitems = items;

        this.chooseAnyOf(schema, schema.default, true);
    },
    mounted() {
        this.loadComplete();
    },
    computed: {
    },
    methods: {
        chooseAnyOf(schema, newvalue, loading) {
            if (!loading)
                this.anyOf_remove();

            const idx = schema.anyOf.findIndex(s => s.Id == newvalue);

            if (idx >= 0) {
                const s = schema.anyOf[idx];
                const item = utils.schema.resolve_Of(s);

                this.selected_item = item;
                this.selected_value = s.Id;

                const value = { ...this.element };
                value.type = 'fieldset';
                value.schema = this.selected_item;
                value.items = [];
                utils.forms.generateAllSchemaItems(value.key, value.items, value.schema);

                this.form_all = [value];

                this.anyOf_govisible();
            }
            else {
                this.selected_item = null;
                this.selected_value = newvalue;
                this.form_all = [];
            }
        },
        async sync_anyof(value) {
            this.chooseAnyOf(this.element.schema, value);
        },
        govisible() {
            // Suppress base mixin default behavior (which populates from the schema default, which would be a string, not an object)
        },
        anyOf_remove() {
            if (!this.ParentModel) {
                //utils.debug(` +++ Schema element ${this.name} has no ParentModel (undefined)`);
                return;
            }

            // Remove the field from the parent model
            const last = this.element.key[this.element.key.length - 1];
            if (last != '[]' && (last in this.ParentModel)) {
                Vue.delete(this.ParentModel, last);
            }
        },
        anyOf_govisible() {
            if (!this.ParentModel) {
                //utils.debug(` +++ Schema element ${this.name} has no ParentModel (undefined)`);
                return;
            }

            // Fill in default value if not already populated
            const last = this.element.key[this.element.key.length - 1];
            if (last != '[]' && !(last in this.ParentModel)) {
                if (this.selected_item && ('default' in this.selected_item)) {
                    //utils.debug(` +++ formAnyOf Schema element ${this.name} going visible - setting field ${last} to ${this.selected_item.default}`);
                    this.writefunc.func(this.cmodel, this.selected_item.default, Vue);
                }
                else if (this.selected_item && this.selected_item.type == 'object') {
                    //utils.debug(` +++ formAnyOf Schema element ${this.name} going visible - setting field ${last} to {}`);
                    this.writefunc.func(this.cmodel, {}, Vue);
                }
                else if (this.selected_item && this.selected_item.type == 'array') {
                    //utils.debug(` +++ formAnyOf Schema element ${this.name} going visible - setting field ${last} to []`);
                    this.writefunc.func(this.cmodel, [], Vue);
                }
                //else
                //    utils.debug(` +++ Schema element ${this.name} going visible - no default, doing nothing (type: ${this.selected_item ? this.selected_item.type : 'unknown'})`);
            }
            //else
            //    utils.debug(` +++ Schema element ${this.name} going visible, doing nothing (already set or is [] : ${last})`);
        },
    },
    props: {
    },
    render(h) {
        let input;

        // The $isTypeRef flag will suppress the dropdown list for choosing the AnyOf type - only useful
        // if you provide a default in the element definition which includes a $ref to the AnyOf type.

        const isTypeRef = this.element && this.element.schema && this.element.schema['$isTypeRef'];
        if (!isTypeRef) {
            input = (
                <v-select outlined single-line dense hide-details
                    items={this.listitems}
                    value={this.selected_value}
                    min-width="160px"
                    style={{ width: "100%" }}
                    on-change={(value) => this.sync_anyof(value)}>
                </v-select>
            );

            if (this.appearance)
                input.componentOptions.propsData = { ...input.componentOptions.propsData, ...this.appearance };

            if (this.directives) {
                if (input.data.directives)
                    input.data.directives = [...input.data.directives, ...this.directives];
                else
                    input.data.directives = this.directives;
            }
        }

        if (this.selected_item) {
            let LayoutType = this.layouttype;
            return (
                <v-card elevation={0} outlined class="mb-2 pa-2">
                    {input}
                    <LayoutType
                        layouttype={this.layouttype}
                        formtype="sform-fieldset"
                        notitle={true}
                        root={this.root}
                        element={this.element}
                        form={this.form_all}
                        cmodel={this.cmodel}
                        modelkey={this.modelkey}
                        appearance={this.appearance}
                        type="FormField"
                        nopadding={true}
                        noinput={this.noinput}
                        depth={this.depth + 1}
                        scopeitems={this.scopeitems}
                        controlscope={this.controlscope}
                    >
                    </LayoutType>
                </v-card>
            );
        }
        else
            return input;
    }
});