<template class="c-ToolPanel">
    <v-card class="c-ToolPanel_1 mx-auto w-100 pa-0" style="width: 100%; display: flex; flex-direction: row; max-width: 750px;" v-if="isvisible" :elevation="0">
        <v-card class="mx-auto w-100 pa-0" style="overflow: auto; width: 100%; flex-grow: 1; display: flex; flex-direction: column;" :elevation="0" color="grey lighten-5">
            <v-card-subtitle class="mt-0 consistentText">
                Constrain the source data by the field values you choose.
            </v-card-subtitle>
            
            <v-text-field outlined dense hide-details clearable label="Search" v-model="search" class="mb-4" background-color="white">
                <template v-slot:prepend-inner>
                    <v-icon>mdi-magnify</v-icon>
                </template>
                <template v-slot:append-outer>

                    <v-menu offset-y :close-on-content-click="false" :close-on-click="false" v-model="filter_menuopen">
                        <template v-slot:activator="{ on, attrs }">
                            <v-badge color="primary" dot offset-y="25" offset-x="10" :value="anyFilters">
                                <v-btn icon small v-bind="attrs" v-on="on" class="ma-0">
                                    <v-icon>mdi-filter</v-icon>
                                </v-btn>
                            </v-badge>
                        </template>
                        <v-card @mouseleave="filter_menuopen = false" class="pa-1" style="_max-height: 520px; min-width: 400px;">
                            <MetricTypeFilter :debugMode="debugMode"
                                              :datatypes="filterDataTypes"
                                              :initial_datatypes_selection="filterDataTypes_selection"
                                              @datatypes_changed="(values) => filterDataTypes_selection = values"

                                              :categories="filterCategories"
                                              :initial_category_selection="filterCategory_selection"
                                              @categories_changed="(values) => filterCategory_selection = values"

                                              :sources="filterSources"
                                              :initial_sources_selection="filterSources_selection"
                                              @sources_changed="(values) => filterSources_selection = values"

                                              @anyfilters="(value) => anyFilters = value">

                            </MetricTypeFilter>
                        </v-card>
                    </v-menu>

                </template>
            </v-text-field>

            <div v-show="!search && metricColumns.length > 0">
                <v-alert v-if="showwarn" dense dark text color="outbound">
                    <template slot="prepend">
                        <v-icon color="outbound" class="mr-4">
                            mdi-alert
                        </v-icon>
                    </template>
                    Filtering displayed metrics may hide rows in the grid, but totals will still reflect all data. Filtering on non-displayed metrics, both the grid and totals will adjust to reflect filtered content.
                </v-alert>

                <v-card-subtitle style="border-bottom: 2px solid silver; color: #9d9d9d;">METRICS</v-card-subtitle>

                <FilterCategory :columns="metricColumns"
                                :column_filters="column_filters"
                                :initial_column_filters="initial_column_filters"
                                :settings="settings"
                                :ReadOnly="ReadOnly"
                                :getColumnComponent="getColumnComponent"
                                :getFilterValue="getFilterValue"
                                :updateFilterValue="updateFilterValue"
                                :EventBus="EventBus"></FilterCategory>
            </div>

            <div v-show="!search" v-for="(cat,k) in filterCategoriesList" :key="k">
                <v-card-subtitle v-if="isCategoryVisible(cat)" class="consistentText" style="border-bottom: 2px solid silver;">{{ filterCategoryPrefix(cat).toUpperCase() }}</v-card-subtitle>

                <v-expansion-panels v-if="isCategoryVisible(cat)"
                                    multiple hover accordion :value="categoryPanels[cat]" @change="(value) => setPanelState(cat,value)">

                    <FilterCategory :columns="getColumnsByCategory(cat)"
                                    :column_filters="column_filters"
                                    :initial_column_filters="initial_column_filters"
                                    :settings="settings"
                                    :ReadOnly="ReadOnly"
                                    :getColumnComponent="getColumnComponent"
                                    :getFilterValue="getFilterValue"
                                    :updateFilterValue="updateFilterValue"
                                    :EventBus="EventBus"></FilterCategory>

                </v-expansion-panels>
            </div>

            <div v-if="search">
                <v-card-subtitle class="consistentText" style="border-bottom: 2px solid silver;">SEARCH RESULTS</v-card-subtitle>

                <FilterCategory :columns="columnsFromSearch"
                                :column_filters="column_filters"
                                :initial_column_filters="initial_column_filters"
                                :settings="settings"
                                :ReadOnly="ReadOnly"
                                :getColumnComponent="getColumnComponent"
                                :getFilterValue="getFilterValue"
                                :updateFilterValue="updateFilterValue"
                                :EventBus="EventBus"></FilterCategory>
            </div>

        </v-card>
    </v-card>
</template>

<script>
    /* eslint-disable */

    import Vue from "vue";
    import utils from '@/Shared/utils.jsx';
    import common from './common.js';

    import { appSettings } from '@/Shared/appSettings';

    import FilterCategory from './filterCategory.vue';
    import MetricTypeFilter from './metricTypeFilterPopup.vue';

    export default {
        components: {
            FilterCategory,
            MetricTypeFilter,
        },
        props: {
            EventBus: null,
            column_list: {
                type: Array
            },
            column_filters: {
                type: Object
            },
            initial_column_filters: null,
            ReadOnly: null,
            settings: null,
            report_settings: null,
            source: null,
            isvisible: null,
            debugMode: null,
        },
        data: function () {
            return {
                columnLookup: {},
                fieldChoices: [],
                filters: [],
                openpanels: [],
                categoryPanels: {},
                search: '',
                filter_menuopen: false,

                filterDataTypes: [
                    { text: 'Counts', filter: 'type', types: ['countColumn', 'floatColumn'] },
                    { text: 'Dates / Times', filter: 'type', types: ['dateColumn', 'dateOnlyColumn', 'timeOnlyColumn'] },
                    { text: 'Durations', filter: 'type', types: ['durationColumn'] },
                    { text: 'Text Values', filter: 'type', types: ['textSetColumn', 'searchableTextColumn', 'channelTypeColumn', 'directionColumn', 'userIDColumn', 'customerIDColumn', 'phoneNumColumn'] },
                    { text: 'True / False', filter: 'type', types: ['booleanColumn'] },
                ],
                filterCategories: [],
                filterSources: [],

                anyFilters: false,

                filterDataTypes_selection: [],
                filterCategory_selection: [],
                filterSources_selection: [],
            }
        },
        watch: {
            filterDataTypes_selection: function (newv) {
                this.saveFilterFilters();
            },
            filterCategory_selection: function (newv) {
                this.saveFilterFilters();
            },
            filterSources_selection: function (newv) {
                this.saveFilterFilters();
            },
        },
        created() {
            for (let cat of [...new Set(this.column_list.filter(a => a.ColumnUse.Filter).map(a => a.FilterCategory || a.Category))])
                this.categoryPanels[cat] = [];

            console.log('Report Filters, report_settings:');
            console.log(JSON.stringify(this.report_settings, null, 3));

            //------------------------ New --------------------//

            // Populate a distinct list of categories for metric filters
            this.filterCategories = [...new Set(this.column_list.filter(a => a.ColumnUse.Filter).map(a => a.FilterCategory || a.Category))]
                .filter(a => a)
                .toSorted((a, b) => common.byField(a, b))
                .map(a => ({ text: a.indexOf('#') <= 1 ? a.substr(a.indexOf('#') + 1) : a, filter: 'Category', category: a }));

            // Populate a distinct list of source names (contacts, users, etc)
            this.filterSources = [...new Set(this.column_list.filter(a => a.ColumnUse.Filter).flatMap(a => a.Sources))]
                .toSorted((a, b) => common.byField(common.sourceTypes[a], common.sourceTypes[b]))
                .map(a => ({ text: a, filter: 'Source', source: a }));

            this.loadFilterFilters();
        },
        mounted() {
            for (let c of this.column_list.filter(a => a.ColumnUse.Filter))
                this.columnLookup[c.ColId] = c;
        },
        computed: {
            filterCategoriesList: function () {
                if (this.ReadOnly)
                    return [...new Set(this.column_list.filter(a => a.ColumnUse.Filter && a.SourceType != 'dateColumn').map(a => a.FilterCategory || a.Category))]
                        .toSorted((a, b) => common.byField(a, b));
                else
                    return [...new Set(this.column_list.filter(a => a.ColumnUse.Filter).map(a => a.FilterCategory || a.Category))]
                        .toSorted((a, b) => common.byField(a, b));
            },

            chosenColumns: function() {
                return this.fieldChoices.map(a => this.columnLookup[a])
                    .toSorted((a, b) => common.byField(a, b, 'Title'));
            },
            filterColumns: function() {
                const cols = this.column_list.filter(a => a.ColumnUse.Filter);
                return cols.toSorted((a, b) => common.byField(a, b, 'Title'));
            },
            metricColumns: function() {
                // These are the fields that are generated by the report - not the source fields.
                // You can filter on either the source fields or the computed metric fields.
                if (!this.report_settings || !this.report_settings.metrics) {
                    console.log('Returning empty metricColumns');
                    return [];
                }

                console.log(`Returning metricColumns with ${this.report_settings.metrics.length} metric(s)`);
                let cols = this.report_settings.metrics.filter(item => item.colId && common.column_lookup[item.colId]?.MetricAggs.Type != 'placeholder').map(item => {
                    const coldef = common.column_lookup[item.colId];
                    const newtype = common.getAggregateColumnType(item.colId, item.function, coldef.SourceType);
                    return {
                        ColId: `${item.colId}_${item.function}_agg`,
                        Title: common.formatHeader({ type: newtype, headerName: coldef.Title, aggFunc: item.function }),
                        SourceType: newtype,
                    };
                });

                if (this.ReadOnly)
                    cols = cols.filter(c => this.column_filters[c.ColId] && this.column_filters[c.ColId].usage);

                return cols.toSorted((a, b) => common.byField(a, b, 'Title'));
            },
            fieldChoicesText: function() {
                return JSON.stringify(this.fieldChoices);
            },
            columnsFromSearch: function() {
                const s = this.search.toLowerCase();
                const cols = this.column_list
                    .filter(a => a.ColumnUse.Filter && a.Title.toLowerCase().includes(s))
                    .concat(this.metricColumns.filter(a => a.Title.toLowerCase().includes(s)))
                    .toSorted((a, b) => common.byField(a, b, 'Title'));

                if (this.ReadOnly)
                    return cols.filter(a => a.ColId != 'datetime' && a.ColId != 'starttime')
                else
                    return cols;
            },
            showwarn: function () {
                return Object.keys(this.column_filters).some(a => a.includes('_agg'));
            },
        },
        methods: {
            loadFilterFilters() {
                for (let i = 0; i < this.filterDataTypes.length; i++)
                    if (appSettings.LocalStorage(this.storageKey + '-filterFilters-type:' + this.filterDataTypes[i].text + '-' + (this.source ? `-${this.source}` : '')) != 'hidden')
                        this.filterDataTypes_selection.push(i);

                for (let i = 0; i < this.filterCategories.length; i++)
                    if (appSettings.LocalStorage(this.storageKey + '-filterFilters-type:' + this.filterCategories[i].text + '-' + (this.source ? `-${this.source}` : '')) != 'hidden')
                        this.filterCategory_selection.push(i);

                if (this.debugMode) {
                    for (let i = 0; i < this.filterSources.length; i++)
                        if (appSettings.LocalStorage(this.storageKey + '-filterFilters-type:' + this.filterSources[i].text + '-' + (this.source ? `-${this.source}` : '')) != 'hidden')
                            this.filterSources_selection.push(i);
                }
                else
                    this.filterSources_selection = this.filterSources.map((a, i) => i);

                this.anyFilters = 
                    this.filterDataTypes_selection.length < this.filterDataTypes.length ||
                    this.filterCategory_selection.length < this.filterCategories.length ||
                    this.filterSources_selection.length < this.filterSources.length;
            },
            saveFilterFilters() {
                for (let i = 0; i < this.filterDataTypes.length; i++)
                    appSettings.LocalStorage(this.storageKey + '-filterFilters-type:' + this.filterDataTypes[i].text + '-' + (this.source ? `-${this.source}` : ''), this.filterDataTypes_selection.includes(i) ? null : 'hidden');

                for (let i = 0; i < this.filterCategories.length; i++)
                    appSettings.LocalStorage(this.storageKey + '-filterFilters-type:' + this.filterCategories[i].text + '-' + (this.source ? `-${this.source}` : ''), this.filterCategory_selection.includes(i) ? null : 'hidden');

                if (this.filterSources)
                    for (let i = 0; i < this.filterSources.length; i++)
                        appSettings.LocalStorage(this.storageKey + '-filterFilters-type:' + this.filterSources[i].text + '-' + (this.source ? `-${this.source}` : ''), this.filterSources_selection.includes(i) ? null : 'hidden');
            },
            isCategoryVisible(category) {
                const selected = this.filterCategory_selection.map(i => this.filterCategories[i].category);
                return selected.some(a => a == category);
            },
            getColumnsByCategory(category) {
                const visibletypes = this.filterDataTypes_selection.flatMap(i => this.filterDataTypes[i].types);
                const visiblesources = this.filterSources_selection.map(i => this.filterSources[i].source);

                let res;
                if (this.ReadOnly)
                    res = this.column_list
                        .filter(a => a.SourceType != 'dateColumn' && a.ColumnUse.Filter && (a.FilterCategory || a.Category) == category && visibletypes.includes(a.SourceType) && a.Sources.some(s => visiblesources.includes(s)))
                        .toSorted((a, b) => common.byField(a, b, 'Title'));
                else
                    res = this.column_list
                        .filter(a => a.ColumnUse.Filter && (a.FilterCategory || a.Category) == category && visibletypes.includes(a.SourceType) && a.Sources.some(s => visiblesources.includes(s)))
                        .toSorted((a, b) => common.byField(a, b, 'Title'));

                return res;
            },
            getColumnComponent(item) {
                return `Filter${item.SourceType}`;
            },

            getFilterValue(col) {
                return this.column_filters[col.ColId];
            },
            updateFilterValue(col, value) {
                // Sample data format for filterModel
                // { "callerid":    { "filterType": "text",   "type": "contains",    "filter": "5432"} }
                // { "contacttime": { "filterType": "number", "type": "greaterThan", "filter": 60 } }
                // { "answered":    { "filterType": "set",    "values": [ "true" ] } }

                if (value)
                    Vue.set(this.column_filters, col.ColId, value);
                else
                    Vue.delete(this.column_filters, col.ColId);

                //console.log('updating column_filters:');
                //console.log(JSON.stringify(this.column_filters,null,3));

                this.$emit('filterschanged', this.column_filters, col.ColId);
            },
            filterCategoryPrefix(cat) {
                if (!cat) return 'Unknown';
                const idx = cat.indexOf('#');
                return idx >= 0 && idx < 3 ? cat.substring(idx + 1) : cat;
            },
            includes(arr, value) {
                for(let i=0; i<arr.length; i++) {
                    const v = arr[i];
                    if (v === value)
                        return true;
                }

                return false;
            },
            isFieldOpen(col, index) {
                const cat = col.FilterCategory || col.Category;
                const open = this.includes(this.categoryPanels[cat], index);

                console.log(`-- -- -- isFieldOpen(col:${col.ColId}, index:${index}), cat:${cat}: ${open}`);

                return open;
            },
            setPanelState(cat, value) {
                console.log(`setPanelState(cat:${cat},value:${JSON.stringify(value)}); ${typeof value}`);

                const arr = this.categoryPanels[cat];
                arr.splice(0, arr.length);

                for(let i of value)
                    arr.push(i);

                console.log(`setPanelState(cat:${cat}) arr: ${JSON.stringify(arr)}; ${typeof value}`);
            },

            clearAllFilters() {
                alert('clearAllFilters in report filters');
            },
        }
    }
</script>

<style scoped>
    .columns-three {
        column-count: 3;
    }

    .verydense {
        max-height: 24px;
        min-height: 24px;
    }

    .mediumdense {
        max-height: 28px;
        min-height: 28px;
    }

    p {
        font-size: 2em;
        text-align: center;
    }

    .metric_normal {

    }
    .metric_inuse {
        color: cornflowerblue;
    }
    .metric_usedup {
        color: silver;
    }
    .consistentText {
        color: var(--v-black-base) !important; 
        opacity: 0.8 !important; 
        font-size: 14px !important; 
        text-rendering: optimizelegibility !important; 
        letter-spacing:0.1 !important;
    }
</style>