<template>
    <form ref="theForm" method="post" :target="target" :action="action">
        <input type="hidden" name="accessToken" :value="accessToken">
        <input type="hidden" name="WindowTitle" :value="WindowTitle">
        <input type="hidden" name="TitleBarName" :value="TitleBarName">
        <input type="hidden" name="refreshToken" :value="refreshToken">
    </form>
</template>

<script>
import utils from '@/Shared/utils.jsx';
import BaseComponent from './BaseComponentMixin.jsx';
import care from '@/Services/careService.jsx';
import EventBus from '../event-bus.js';
import refreshToken from '@/Services/tokenEventListenerService.jsx';
import Vue from 'vue';

export default {
    name: "ChildWindowWithTokenOpener",
    mixins: [BaseComponent],
    components: {
    },
    //Created Replaced with preRenderComplete
    data() {
        return {
            tabId: '_blank',
            action: null,
            target: null,
            accessToken: null,
            refreshToken: null,
            WindowTitle: null,
            TitleBarName: null,
        }
    },
    created() {
        EventBus.$on(`Action-OpenChildWindowWithToken`, this.performOpen);
    },
    destroyed() {
        EventBus.$off(`Action-OpenChildWindowWithToken`, this.performOpen);
    },
    methods: {
        preRenderComplete() {
            this.finishRenderHandler(this);
        },
        async performOpen(action) {
            /*********** ACTION DEBUG **********/
            utils.log(`ChildWithTokenOpener OpenChildWindowWithToken`);
            if (action.ActionData && action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;
            /**********************************/


            try {
                let targetWindowName = utils.evaluate(action.targetWindowName, action.context);
                let url = utils.evaluate(action.url, action.context);
                let specs = utils.evaluate(action.specs, action.context);
                let tokenSource = utils.evaluate(action.tokenSource, action.context);
                let windowTitle = utils.evaluate(action.windowTitle, action.context);
                let titleBarName = utils.evaluate(action.titleBarName, action.context);
                let doNotInheritSessionStorage = utils.evaluate(action.doNotInheritSessionStorage, action.context);

                // We want to use the target name if it's available, but if it's _blank, then generate a name instead because the
                // new window will be blocked (by popup blocker) if it's not named.
                this.tabId = (targetWindowName && targetWindowName != '_blank') ? targetWindowName : care.guid();

                if (!care.ValueInList(this.tabId, ["_self", '_parent', '_top'])) {
                    if ((tokenSource == "User Provided Tokens" || doNotInheritSessionStorage) && window.sessionStorage) {
                        // because child windows inherit a copy of the parent's sessionStorage, we throw a value in that tells our system to clear the storage.
                        // this is because we don't want the access token inherited to the child window giving it a security context when that wasn't intended.
                        window.sessionStorage.setItem("ChildWindowClearSessionStorage", "true");
                    }

                    var childWindow = window.open('about:blank', this.tabId, specs); // if not overwriting current window, open up window to blank page, and then post the form to the same window which should get around pop-up blocker

                    if ((tokenSource == "User Provided Tokens" || doNotInheritSessionStorage) && window.sessionStorage)
                        window.sessionStorage.removeItem("ChildWindowClearSessionStorage");
                    else if (childWindow.sessionStorage)
                        childWindow.sessionStorage.removeItem('InstanceGuid'); // remove the InstanceGuid even if not clearing all SessionStorage. If it is duplicated it creates auth issues
                }

                if (tokenSource == "User Provided Tokens") {
                    if (action.ActionData.ClearSessionStorageBeforeNavigate && window.sessionStorage)
                        window.sessionStorage.clear();

                    this.action = url;
                    this.target = this.tabId;
                    this.accessToken = utils.evaluate(action.accessToken, action.context);
                    this.refreshToken = utils.evaluate(action.refreshToken, action.context);
                    this.WindowTitle = windowTitle || '';
                    this.TitleBarName = titleBarName || '';
                    Vue.nextTick(() => this.$refs.theForm.submit()); // this causes the form submit and the window to open
                }
                else {
                    // Get an extra token to pass to the child window
                    try {
                        let extraToken = await refreshToken.refreshWithExtraToken(action.ActionData.SecurityContextsToRemove)

                        // must be done after refreshWithExtraToken, which will assign a token to session storage
                        if (action.ActionData.ClearSessionStorageBeforeNavigate && window.sessionStorage)
                            window.sessionStorage.clear();

                        this.action = url;
                        this.target = this.tabId;
                        this.accessToken = extraToken.access_token;
                        this.refreshToken = extraToken.refresh_token;
                        this.WindowTitle = windowTitle || '';
                        this.TitleBarName = titleBarName || '';
                        Vue.nextTick(() => this.$refs.theForm.submit()); // this causes the form submit and the window to open
                    }
                    catch (e) {
                        care.logError(e, true);
                    }
                }

                try {
                    await utils.success(action);
                } catch (e) { }
            }
            catch (e) {
                try {
                    await utils.failure(action, { Status: e.status, StatusText: e.statusText });
                } catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
    },
};
</script>

<style scoped>
</style>
