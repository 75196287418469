import EventBus from "@/Application/event-bus";
import utils from "@/Shared/utils";
import {appSettings} from '@/Shared/appSettings';

export default {
    umls: [],
    uml: null,
    order: 10000,
    participants: {},
    
    //This method is only called from the App.Vue when the site gets created so we can subscribe
    created() {
        appSettings.plantUmlService = this;
        EventBus.$on("Action-DebugNewUml", this.newUml.bind(this));
        
        this.beginUML('First');
    },
    //This method is only called from the App.Vue when the site gets destroyed so we can unsubscribe
    destroyed() {
        EventBus.$off("Action-DebugNewUml", this.newUml.bind(this))
    },
    async newUml(action) {
        /*********** ACTION DEBUG **********/
        if (action?.ActionData?.Debug?.BreakPoint) debugger;
        /**********************************/

        try {
            if (this.uml.timeline.length >= 1)
            {
                var previousUml = this.uml;

                this.endUML();
                this.beginUML(action.ActionData.Name);

                // carry forward any activated timelines and also the current state (represented by the lastNoteHexegon) so the nesting will remain the same
                for (const [key, item] of Object.entries(previousUml.actors)) {
                    if (item.activateCount) {
                        for (var x = 0; x < item.activateCount; x++) {   
                            this.passMessage(key, key, '... (from prev diagram)', '->', '#blue'); // in order to have the activate nest properly in the diagram it needs a message prior to it
                            this.activate(key);
                        };

                        if (item.lastNoteHexegon)
                            this.noteHexagon(item.lastNoteHexegon.text, key, item.lastNoteHexegon.orientation, item.lastNoteHexegon.color);
                    }
                }
            }
            try {
                await utils.success(action);
            } catch (e) { }
        } catch (e) {
            try {
                await utils.failure(action, { Data: e });
            } catch (e) { }
        } finally {
            try {
                await utils.complete(action);
            }
            catch (e) { }

            // Complete the promise for the executeAction method
            action?.FinishFunc(true);
        }
    },
    beginUML(name) {
        if (this.uml) {
            utils.error("UML already started.");
            return;
        }

        this.uml = {
            name: name,
            data: "",
            participants: [],
            timeline: [],
            actors: {}
        };
        this.umls.push(this.uml);

        // limit the number of items in the array to 10
        if (this.umls.length > 10)
            this.umls.splice(0, 1);// remove the first element

        this.addLine("@startuml", true);
    },
    endUML() {
        if (!this.uml) {
            utils.error("UML already ended.");
            return;
        }
        
        this.addLine("@enduml", true);
        this.uml = null;
    },
    addParticipant(name, altName, order) {
        if (this.uml) {
            // we keep a global participants list because we stop and start umls constantly (for the same HSM). When the HSM is first created and the participant added, it has an altName
            // when a new uml diagram starts, we will dynamically add the participant when it is first used and we'll need to know what the altName was when it was first used.
            if (!this.participants[name]) {
                // add to global list of participants
                this.participants[name] = {
                    altName: altName || name, // if no alt name given use the original unsanatized name
                    order: order || this.order++
                };
            }

            // if participant is not already in the local uml, add to the local uml's participant list 
            if (this.uml.participants.indexOf(name) == -1) {
                this.uml.participants.push(name);

                if (!altName)
                    altName = this.participants[name].altName; // if no alt name is given, then use the one from the global participants list

                var order = this.participants[name].order;
                var sanitizedName = this.sanitizeName(name, true);

                this.addLine(`participant "${altName}" as ${sanitizedName} order ${order}`);
            }
        }
    },
    passMessage(from, to, message, style, color) {
        color = color || '';
        style = style || '';

        from = this.sanitizeName(from);
        to = this.sanitizeName(to);
        if (!style) {
            style = '->';
        } 
        if (color) {
            color = `[${color}]`;
        }
        this.addLine(`${from} ${color}${style} ${to}: ${message}`);
    },
    /*
        Orientation:
            left
            right
            over
        Actor:
            [actor]
            [actor], [actor]..
        Color:
            #[color]
    */
    noteHexagon(text, actor, orientation, color) {
        var position = this.parsePosition(orientation, actor);
        this.note('h', text, position, color);

        var actorObj = this.getOrCreateActor(actor);
        actorObj.lastNoteHexegon = {
            text: text,
            orientation: orientation,
            color: color
        };
    },
    noteRectangle(text, actor, orientation, color) {
        var position = this.parsePosition(orientation, actor);
        this.note('r', text, position, color);
    },
    /*
        Use noteHexagon or noteRectangle unless you have specific needs.
        Type:
            r - rectangle
            h - hexagon
        Position:
            Should be from parsePosition
    */
    note(type, text, position, color) {
        color = color || '';

        this.addLine(`${type}note ${position} ${color}\n${text}\nend${type}note`);
    },
    activate(actor) {
        var actorObj = this.getOrCreateActor(actor);
        actorObj.activateCount++;

        actor = this.sanitizeName(actor);
        this.addLine(`activate ${actor}`);
    },
    deactivate(actor) {
        var actorObj = this.getOrCreateActor(actor);
        actorObj.activateCount--;

        actor = this.sanitizeName(actor);
        this.addLine(`deactivate ${actor}`);
    },
    createParticipant(actor) {
        this.addLine(`create "${actor}"`);
    },
    sanitizeName(name, preventRecursion) {
        if (!name)
            return '';

        // if the participant doesn't exist yet in the uml diagram, then add
        if (!preventRecursion && this.uml?.participants.indexOf(name) == -1)           
            this.addParticipant(name);

        return name.replace(/-/g, '');
    },
    parsePosition(position, actor) {
        actor = this.sanitizeName(actor);
        if (!position || position == 'over') {
            position = `over ${actor}`;
        } else if (position == 'left') {
            position = `left of ${actor}`;
        } else if (position == 'right') {
            position = `right of ${actor}`;
        }
        return position;
    },
    addLine(line, hideFromTimeline) {
        if (!this.uml) {
            utils.error("No UML to add to.");
            return;
        }
        this.uml.data += line + '\n';

        if (!hideFromTimeline)
            this.uml.timeline.push(line);
    },
    // called when hsm is being destroyed
    endAllActivates(actor) {
        var actorObj = this.getOrCreateActor(actor);
        for (var x = actorObj.activateCount; x > 0; x--) {
            this.passMessage(actor, actor, 'Ending', '->', '#red'); // in order to have the activate nest properly in the diagram it needs a message prior to it
            this.deactivate(actor);
            delete this.participants[actor]; // remove from global participants list
        }
    },
    // used to track some information about an actor so if a new UML diagram is started, it can display activated timelines properly
    getOrCreateActor(actor) {
        var actorObj = this.uml?.actors[actor];

        if (!actorObj) {
            actorObj = {
                activateCount: 0,
                lastNoteHexegon: null
            };

            this.uml.actors[actor] = actorObj;
        }

        return actorObj;
    },
    testRun() {
        this.beginUML();
        this.addParticipant('Bob');
        this.addParticipant('Alice');
        this.passMessage('Bob', 'Alice', 'Hello!');
        this.passMessage('Alice', 'Bob', 'Hello! How are you?');
        this.createParticipant('Eve');
        this.passMessage('Bob', 'Eve', 'I am doing so well!');
        this.endUML();
    }
    // testRun();
    // utils.log('HSM:', c.umls);


}; 