import Vue from 'vue';
import utils from '../../../../Shared/utils.jsx';
import methods from '../../../../Shared/methods';

Vue.component('form-typeschema-dsgn', {
    data: function () {
        return {
        }
    },
    created() {
    },
    computed: {
        ...utils.forms.computed,
    },
    methods: {
        ...methods,
    },
    props: {
        name: '',
        value: '',
        readonly: false,
    },
    render(h) {
        const name = <span style={{ marginLeft: (this.child * 15) + "px" }}>{this.name}</span>;

        let input;
        if (this.readonly)
            input = <input readonly style={{ width: "100%" }} value={this.value} disabled="disabled" />;
        else
            input = <input readonly style={{ width: "100%" }} value={this.value} />;

        return (
            <tr>
                <td style={{ width: "1px", "white-space": "nowrap" }}>
                    {name}
                </td>
                <td>
                    {input}
                </td>
            </tr>
        );
    }
});