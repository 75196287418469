import Vue from "vue";
import utils from "../../Shared/utils.jsx";
import BaseComponent from "./BaseComponentMixin.jsx";
import { appSettings } from "../../Shared/appSettings.js";

Vue.component('g-signin-button', {
    name: 'g-signin-button',
    render: function render(h) {
        return (
            <div ref="signinBtn"></div>
        );
    },
    props: {
        params: {
            type: Object,
            required: !0,
            default: function _default() { return {} }
        }
    },
    mounted() {
        google.accounts.id.initialize({
            client_id: appSettings.WhiteLabel.GoogleClientId,
            ux_mode: "popup",
            callback: (response) => {
                this.$emit('success', response)
            }
        });
        google.accounts.id.renderButton(
            this.$refs.signinBtn,
            {
                locale: appSettings.LocaleKey
            }
        )
    },
});

Vue.component("google-button-v2", {
    mixins: [BaseComponent],
    data: function() {
        return {
            checkStatusOnInitEval: null,
            clientId: null,
        };
    },
    created() {
        
    },
    computed: {
        checkStatusOnInit() {
            try {
                if (this.checkStatusOnInitEval === null && this.controlData.CheckStatusOnInit)
                    this.checkStatusOnInitEval = utils.compile(this, this.controlData.CheckStatusOnInit);

                if (this.checkStatusOnInitEval) {
                    return utils.evaluate(this.checkStatusOnInitEval, this);
                }

                return false;
            } catch (e) {
                utils.warn(
                    "CheckStatusOnInit could not evaluate expression: " + this.controlData.CheckStatusOnInit + "; " + e
                );

                return false;
            }
        },
        styles() {
            return {
                ...this.sizeStyle,
                ...utils.resolveStyleHints(this.styleHints, this),
            };
        },
    },
    //Created replaced with preRenderComplete
    methods: {
        async preRenderComplete() {
            this.finishRenderHandler(this);
        },
        async runActions(authArgs) {
            await utils.executeAndCompileAllActions(this.controlData.Actions, authArgs, this);
        },

        async on_success(CredentialResponse) {
            const authArgs = {
                ProviderType: "Google",
                token: CredentialResponse.credential, // JWT (JSON Web Token)
                clientId: CredentialResponse.clientId,
            };

            await this.runActions(authArgs);
        },
    },
    render(h) {
        try {
            if (!this.todisplay) return null;

            return (
                <div
                    style={this.styles}
                    class={{ "c-GoogleButton d-flex": true, [`c-name-${this.name || "unnamed"}`]: true }}
                >
                    <g-signin-button
                        style="width: 100%; display: flex; justify-content: center;"
                        params={{ client_id: this.clientId }}
                        on-success={(e) => this.on_success(e)} />
                </div>
            );
        } catch (e) {
            utils.error("GoogleButton Render failed", e);
            return <div>GoogleButton Failed to Render {e}</div>;
        }
    },
});
