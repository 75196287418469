import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import careHelpfulFunctions from '../careHelpfulFunctions.jsx';

import utils from '../../Shared/utils.jsx';
import methods from '../../Shared/methods';

Vue.component('basic-input', {
    mixins: [BaseComponent],
    data: function () {
        return {
            typeEval: null,
            textEval: null,
            placeholderEval: null,
            setValueFunction: null,
            isDisabledEval: null,
            model: ''
        }
    },
    props: {},
    created() {
        if(this.text)
            this.publishField = this.text;
    },
    //Mounted Replaced with preRenderComplete
    computed: {
        inputType() {
            try {
                if(this.typeEval === null && this.controlData.Type)
                    this.typeEval = utils.compile(this, this.controlData.Type);
                if(this.typeEval)
                    return utils.evaluate(this.typeEval, this);
                return 'text';
            }
            catch (e) {
                utils.warn('Type could not evaluate expression: ' + this.controlData.Type + '; ' + e);
                return 'text';
            }
        },
        text() {
            try {
                if(this.textEval === null && this.controlData.Text)
                    this.textEval = utils.compile(this, this.controlData.Text);
                if(this.textEval)
                    return utils.evaluate(this.textEval, this);
                return '';
            }
            catch (e) {
                utils.warn('Text could not evaluate expression: ' + this.controlData.Text + '; ' + e);
                return '';
            }
        },
        placeholder() {
            try {
                if(this.placeholderEval === null && this.controlData.PlaceHolder)
                    this.placeholderEval = utils.compile(this, this.controlData.PlaceHolder);
                if(this.placeholderEval)
                    return utils.evaluate(this.placeholderEval, this);
                return '';
            }
            catch (e) {
                utils.warn('Placeholder could not evaluate expression: ' + this.controlData.PlaceHolder + '; ' + e);
                return '';
            }
        },
        publishField: {
            set(value){
                if(this.setValueFunction === null && this.controlData.PublishField) {
                    let vueSet = utils.helpers.convertSetValueToVueSet(this.controlData.PublishField, 'value');
                    this.setValueFunction = new Function('value', 'context', 'util', 'vue', `with (context) { ${vueSet} }`);
                }
                if(this.setValueFunction) {
                    this.setValueFunction(value, this, careHelpfulFunctions, Vue);
                }
                this.model = value;
            },
            get() {
                return this.model;
            }
        },
        isDisabled() {
            try {
                if(this.isDisabledEval === null && this.controlData.IsDisabled)
                    this.isDisabledEval = utils.compile(this, this.controlData.IsDisabled);
                if(this.isDisabledEval)
                    return utils.evaluate(this.isDisabledEval, this);
                return false;
            }
            catch (e) {
                utils.warn('IsDisabled could not evaluate expression: ' + this.controlData.IsDisabled + '; ' + e);
                return false;
            }
        },
        inputStyles() {
            return {
                ...utils.resolveStyleHints(this.styleHints, this),
            };
        },

        Text: {
            set(value) {
                this.publishField = value;
            },
            get() {
                return this.model;
            }
        },
        TextWithChange: {
            set(value) {
                this.publishField = value;

                utils.executeAndCompileAllActions(this.controlData.OnChangeActions, { Data: value }, this);
            },
            get() {
                return this.model;
            }
        },
    },
    
    methods: {
        onInput(item) {
            this.publishField = item;
        },
        async onChange(input) {
            await utils.executeAndCompileAllActions(this.controlData.OnChangeActions, { Text: input }, this);
        },
        async onBlur(input) {
            await utils.executeAndCompileAllActions(this.controlData.BlurActions, { Text: input }, this);
        },
        async onClear(e) {
            this.publishField = '';
            await utils.executeAndCompileAllActions(this.controlData.OnClearActions, null, this);
        },
        preRenderComplete() {
            this.finishRenderHandler(this);
        },
    },
    render(h) {
        try {
            if (!this.todisplay)
                return null;
    
            return (
                <div
                    v-show={this.isvisible}
                    class={{ 'c-BasicInput': true, [`c-name-${this.name || 'unnamed'}`]: true, 'ma-1': true  }}
                    style={this.sizeStyle}
                >
                    {
                        h('v-text-field', {
                            props: {
                                type: this.inputType,
                                value: this.model,
                                placeholder: this.placeholder,
                                style: this.inputStyles,
                                hideDetails: true,
                                dense: true,
                                outlined: true,
                                disabled: this.isDisabled,
                                clearable: this.controlData.ShowClearIcon,
                                label: this.controlData.Title
                            },
                            on: {
                                input: this.onInput,
                                change: this.onChange,
                                blur: (e) => this.onBlur(e.target.value),
                                'click:clear': (e) => this.onClear(e),
                            }
                        })
                    }
                </div>
            );
        }
        catch(e) {
            utils.error('BasicInput Render failed', e);
            return <div>BasicInput Failed to Render {e}</div>;
        }
    }
});