<template>
    <div :style="sizeStyles" v-if="this.todisplay" v-show="this.isvisible" :class="{ 'c-MediaApproval': true, [`c-name-${this.name || 'unnamed'}`]: true }">
        <v-card v-if="deviceStatus === 2" dark class="ma-2" :color="color">
            <v-card-text>
                <translation-container :context="context" :value="Translate('Please click')"></translation-container>
                <v-btn elevation="0"
                    primary
                    text
                    @click="checkForAccess()"
                ><translation-container :context="context" :value="Translate('here')"></translation-container></v-btn>
                <translation-container :context="context" :value="Translate('to bring up the approval window.')"></translation-container>
            </v-card-text>
        </v-card>
        <v-alert v-else dark text :color="color" class="mb-3">
            <template slot="prepend">
                <v-icon :color="color" class="mr-4">
                    {{icon}}
                </v-icon>
            </template>
            <v-card-text>
                <translation-container :context="context" :value="message"></translation-container>
                <span v-if="deviceStatus === 4">{{ this.deviceMessage }}</span>
            </v-card-text>
        </v-alert>
    </div>
</template>

<script>
import utils from '@/Shared/utils.jsx';
import BaseComponent from './BaseComponentMixin.jsx';
import careHelpfulFunctions from '../careHelpfulFunctions.jsx';
import Vue from 'vue';

export default {
    name: "MediaApproval",
    mixins: [BaseComponent],
    components: {
    },
    //Created Replaced with preRenderComplete
    data() {
        return {
            HAS_ACCESS: 0,
            WAITING: 1,
            HAS_DISMISSED: 2,
            HAS_BLOCKED: 3,
            OTHER_ERROR: 4,
            deviceStatus: 1,
            deviceMessage: '',
            setValueFunction: null, 
            model: null,
            context: this
        }
    },
    watch: {
        deviceStatus: function(newVal) {
            this.publishField = newVal;
        },
    },
    methods: {
        preRenderComplete() {
            this.checkForAccess();
            this.finishRenderHandler(this);
        },
        checkForAccess(){
            this.deviceStatus = this.WAITING;

            let constraints = {
                audio: true,
                video: false,
            };

            navigator.mediaDevices.getUserMedia(constraints).then(
                (stream) => {
                    this.deviceStatus = this.HAS_ACCESS;
                    stream.getAudioTracks()[0].stop();
                    this.onSuccessActions();
                },
                (result) => {
                    if (result.name == 'PermissionDeniedError')
                        this.deviceStatus = this.HAS_BLOCKED;

                    else if (result.name == 'PermissionDismissedError')
                        this.deviceStatus = this.HAS_DISMISSED;
                    
                    else if (result.name == "NotSupportedError" && window.location.protocol != "https:") {
                        this.deviceStatus = this.OTHER_ERROR;
                        this.deviceMessage = "Https required (currently using Http)";
                    }
                    else{
                        this.deviceStatus = this.OTHER_ERROR;
                        this.deviceMessage = result.name;
                    }
                }
            );
        },
        onSuccessActions() {
            if(this.controlData.OnSuccessActions)
                utils.executeAndCompileAllActions(this.controlData.OnSuccessActions, this.deviceStatus, this);
        }
    },
    computed: {
        sizeStyles() {
            return utils.getSize({...{ Width: { Mode: 'Fill' } }, ...this.sizeOptions}, this.parentType, this.$parent);
        },
        publishField: {
            set(value){
                if(this.setValueFunction === null && this.controlData.PublishField) {
                    let vueSet = utils.helpers.convertSetValueToVueSet(this.controlData.PublishField, 'value');
                    this.setValueFunction = new Function('value', 'context', 'util', 'vue', `with (context) { ${vueSet} }`);
                }
                if(this.setValueFunction) {
                    this.setValueFunction(value, this, careHelpfulFunctions, Vue);
                }
                this.model = value;
            },
            get() {
                return this.model;
            }
        },
        message() {
            switch(this.deviceStatus){
                case 0:
                    return 'You have given permission. Thanks!';
                case 1:
                    return 'Please click "Allow" to give permission to our site to access your microphone.';
                case 3:
                    return 'The device is currently blocked. Permission is needed in order to make and receive calls.';
                case 4:
                    return 'We received another error when attempting to access your microphone:';
                default:
                    return '';
            }
        },
        color() {
            switch(this.deviceStatus){
                case 0:
                    return 'success';
                case 1:
                case 2:
                    return 'outbound';
                case 3:
                case 4:
                    return 'error';
                default:
                    return 'error';
            }
        },
        icon() {
            switch(this.deviceStatus){
                case 0:
                    return 'mdi mdi-information';
                case 1:
                case 2:
                case 3:
                case 4:
                    return 'mdi mdi-alert';
                default:
                    return 'mdi mdi-alert';
            }
        }
    }
};
</script>

<style scoped>
</style>
