import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import utils from '../../../Shared/utils.jsx';

Vue.component('cc-progress-bar-dsgn', {
    mixins: [BaseComponent],
    data: function () {
        return {
        }
    },
    computed: {
        style() {
            return {
                ...utils.getStyleHints(this.controlData.StyleHints),
                ...utils.getSize(this.controlData.SizeOptions, this.parentType, this.$parent),
                overflow: "none",
            };
        },
        color() {
            if (this.controlData.Color && !this.controlData.Color.includes('{'))
                return this.controlData.Color;
            else
                return 'orange';
        },
        value() {
            if (this.controlData.ValueExpression)
                return utils.helpers.tryParseInt(this.controlData.ValueExpression, 35);
            else
                return 35;
        },
        bufferValue() {
            if (this.controlData.BufferValueExpression)
                return utils.helpers.tryParseInt(this.controlData.ValueExpression, 100);
            else
                return 100;
        },
        height() {
            if (this.controlData.SizeOptions && this.controlData.SizeOptions.Height && this.controlData.SizeOptions.Height.Mode == 'Fixed')
                return this.controlData.SizeOptions.Height.Value || 4;
            else
                return 4;
        },
    },
    methods: {
    },
    render(h) {
        try {
            let scopedSlots;
            if (this.controlData.Content)
                scopedSlots = {
                    default: ({ value }) => <span>{this.controlData.Content}</span>
                };
            
            return (
                <div
                    style={this.style}
                    class={{ 'c-ProgressBarDsgn': true, [`c-name-${this.name || 'unnamed'}`]: true, 'designer-container': true, selected: this.selected$ }}
                    on-click={(e) => this.handleClick(e)}
                >
                    <div class={{ "designer-container-hovering": true, 'designer-container-selected-clickthrough': this.selected$ }}></div>
                    <v-progress-linear
                        style={this.style}
                        value={this.value}
                        buffer-value={this.bufferValue}
                        height={this.height}
                        color={this.color}
                        indeterminate={this.controlData.Indeterminate}
                        query={this.controlData.Query}
                        reverse={this.controlData.Reverse}
                        rounded={this.controlData.Rounded}
                        stream={this.controlData.Stream}
                        striped={this.controlData.Striped}
                        scopedSlots={scopedSlots}
                    >
                    </v-progress-linear>
                </div>
            );
        }
        catch (e) {
            utils.error('ProgressBar Render failed', e);
            return <div>ProgressBar Failed to Render {e}</div>;
        }
    }
});