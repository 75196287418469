import Vue from 'vue';
import Vuetify from 'vuetify';
import EventBus from '../../event-bus.js';
import utils from '../../../Shared/utils.jsx';

const control_icons = {
    'AudioPlayer': { 'icon': 'mdi mdi-volume-high', 'color': 'orange' },
    'AutoComplete': { 'icon': 'mdi-textbox', 'color': 'blue' },
    'BasicButton': { 'icon': 'present_to_all', 'color': 'blue' },
    'BasicFileUpload': { 'icon': 'file_upload', 'color': 'red darken-4' },
    'BasicForm': { 'icon': 'format_align_left', 'color': 'deep-purple darken-1' },
    'BasicGrid': { 'icon': 'grid_on', 'color': 'cyan darken-1' },
    'BasicLink': { 'icon': 'link', 'color': 'blue darken-2' },
    'BasicSwitch': { 'icon': 'mdi mdi-toggle-switch', 'color': 'lime darken-2' },
    'BasicTab': { 'icon': 'mdi mdi-folder', 'color': 'orange' },
    'BroadcastListener': { 'icon': 'radio', 'color': 'teal accent-3' },
    'CardList': { 'icon': 'view_list', 'color': 'blue' },
    'CircleChart': { 'icon': 'pie_chart', 'color': 'blue' },
    'ComboBox': { 'icon': '', 'color': 'blue' },
    'ControlContainer': { 'icon': 'check_box_outline_blank', 'color': 'blue' },
    'Control Designer': { 'icon': 'slideshow', 'color': 'blue' },
    'ConversationView': { 'icon': 'chat_bubble_outline', 'color': 'blue' },
    'CssStyle': { 'icon': 'mdi mdi-language-css3', 'color': 'blue' },
    'Dashboard': { 'icon': 'dashboard', 'color': 'blue' },
    'DebugConsole': { 'icon': 'bug_report', 'color': 'blue' },
    'DebugHsmLog': { 'icon': 'bug_report', 'color': 'blue' },
    'DebugHsmRenderer': { 'icon': 'bug_report', 'color': 'blue' },
    'DebugModelWatcher': { 'icon': 'bug_report', 'color': 'blue' },
    'DesktopNotify': { 'icon': 'mdi mdi-flag-outline', 'color': 'blue' },
    'DocumentEditor': { 'icon': 'mdi-file-tree', 'color': 'blue' },
    'DropdownList': { 'icon': 'arrow_drop_down_circle', 'color': 'blue' },
    'DynamicMenuList': { 'icon': 'mdi mdi-view-list-outline', 'color': 'blue' },
    'DynamicTab': { 'icon': 'mdi mdi-folder-outline', 'color': 'blue' },
    'FacebookButton': { 'icon': 'mdi mdi-facebook', 'color': 'indigo' },
    'FlowChart': { 'icon': 'mdi-chart-scatterplot-hexbin', 'color': 'amber darken-3' },
    'ForEach': { 'icon': 'repeat_one', 'color': 'orange darken-1' },
    'FreshDeskChat': { 'icon': 'headset_mic', 'color': 'green lighten-2' },
    'FusionChart': { 'icon': 'insert_chart', 'color': 'blue' },
    'GoogleButton': { 'icon': 'mdi mdi-google', 'color': 'blue' },
    'GoogleMap': { 'icon': 'mdi mdi-map', 'color': 'blue' },
    'Header Bar': { 'icon': '', 'color': 'blue' },
    'Horizontal Layout': { 'icon': 'mdi-page-layout-sidebar-left', 'color': 'blue' },
    'HSM': { 'icon': 'mdi mdi-group', 'color': 'yellow darken-2' },
    'Html': { 'icon': '', 'color': 'blue' },
    'HtmlEditor': { 'icon': 'format_color_text', 'color': 'blue' },
    'HtmlTable': { 'icon': 'mdi mdi-table-large', 'color': 'brown' },
    'Icon': { 'icon': 'insert_emoticon', 'color': 'pink darken-4' },
    'IconStack': { 'icon': 'mdi-emoticon-cool', 'color': 'pink darken-4' },
    'InfiniteConversationView': { 'icon': 'chat_bubble_outline', 'color': 'indigo lighten-1' },
    'ConversationView': { 'icon': 'chat_bubble_outline', 'color': 'indigo lighten-1' },
    'InfiniteGrid': { 'icon': 'grid_on', 'color': 'light-blue darken-1' },
    'Interval': { 'icon': 'av_timer', 'color': 'red lighten-2' },
    'Javascript': { 'icon': 'mdi mdi-jsfiddle', 'color': 'red' },
    'KeyPad': { 'icon': 'dialpad', 'color': 'blue' },
    'LayoutTile': { 'icon': '', 'color': 'blue' },
    'LineChart': { 'icon': 'mdi mdi-chart-line-variant', 'color': 'blue' },
    'MediaApproval': { 'icon': '', 'color': 'blue' },
    'MediaSelector': { 'icon': 'mic_none', 'color': 'blue' },
    'MicAnalyzer': { 'icon': 'mdi mdi-chip', 'color': 'green darken-4' },
    'NetworkTest': { 'icon': 'mdi-help-network', 'color': 'blue' },
    'PaymentInfo': { 'icon': 'payment', 'color': 'deep-orange darken-3' },
    'ProgressBar': { 'icon': 'mdi mdi-refresh', 'color': 'blue' },
    'PropertyGrid': { 'icon': 'mdi-table-settings', 'color': 'blue' },
    'RealTimeEventListener': { 'icon': 'event', 'color': 'blue' },
    'Recaptcha': { 'icon': 'fingerprint', 'color': 'blue-grey darken-1' },
    'Reference User Control': { 'icon': 'mdi mdi-file-code-outline', 'color': 'blue' },
    'SelectList': { 'icon': 'mdi mdi-format-list-bulleted', 'color': 'blue' },
    'ServerEventListener': { 'icon': 'event', 'color': 'red' },
    'SignalStrength': { 'icon': 'mdi mdi-signal', 'color': 'blue' },
    'SoundPlayer': { 'icon': 'mdi mdi-microphone-message', 'color': 'blue' },
    'SpeechToText': { 'icon': 'speaker_notes', 'color': 'blue' },
    'SplitButton': { 'icon': 'system_update_alt', 'color': 'blue' },
    'Static Bar': { 'icon': '', 'color': 'blue' },
    'Text': { 'icon': 'text_fields', 'color': 'blue' },
    'TextEditor': { 'icon': 'subject', 'color': 'blue' },
    'Timeline': { 'icon': 'trending_flat', 'color': 'blue' },
    'Timeout': { 'icon': 'mdi mdi-clock-outline', 'color': 'blue' },
    'Tree View': { 'icon': 'toc', 'color': 'blue' },
    'Vertical Layout': { 'icon': 'mdi-page-layout-header', 'color': 'blue' },
    '(ignore) VueEmbedReact': { 'icon': '', 'color': 'blue' },
    'Watcher': { 'icon': 'remove_red_eye', 'color': 'blue' },
    'WebRtcPhone': { 'icon': 'phonelink', 'color': 'blue' },
    'Wizard': { 'icon': 'mdi mdi-auto-fix', 'color': 'blue' },
    'Wizard_Old': { 'icon': '', 'color': 'blue' },
    'WizardButton': { 'icon': 'mdi mdi-chevron-right-circle-outline', 'color': 'blue' },
    'YouTubeVideo': { 'icon': 'mdi mdi-youtube', 'color': 'red' },
};

Vue.component('control-picker', {
    data: () => ({
        list: [],
        filter: '',
    }),
    props: {
        confirm: null,
        cancel: null,
    },
    created() {
    },
    async mounted() {
        const schema = await utils.schema.get('/Apps/Schema/AnyOf/schema/public/Platform.Schema.BaseTypes.v1/DynamicControl');
        this.list = schema.anyOf;
        for (let i = 0; i < this.list.length; i++)
            utils.log("'" + this.list[i].title + "': { 'icon': '', 'color': '' },");
    },
    methods: {
        returnControl(id, title) {
            this.confirm(id, title);
        },
        search(value) {
            this.filter = value;
        }
    },
    render(h) {
        const items = this.list.filter(c => (!this.filter || c.title.toLowerCase().includes(this.filter.toLowerCase()))).map(c => (
            <v-flex xs2>
                <v-card>
                    {utils.generateTooltip(h,
                        <v-btn text on-click={(e) => this.returnControl(c.Id, c.title)}>
                            <v-icon small color={c.title in control_icons ? control_icons[c.title].color : 'red'}>
                                {c.title in control_icons ? (control_icons[c.title].icon || 'widgets') : 'widgets'}
                            </v-icon>
                        </v-btn>,
                        c.title, 'top')}
                    <v-card-text>{c.title}</v-card-text>
                </v-card>
            </v-flex>
        ));

        return (
            <v-card>
                <v-card-title class="headline">Controls</v-card-title>
                <v-card-text>
                    <v-text-field
                        label="Search"
                        single-line
                        prepend-icon="search"
                        autofocus
                        on-input={(value) => this.search(value)}
                    ></v-text-field>
                </v-card-text>
                <v-card-text>
                    <v-container fluid grid-list-md style={{ minHeight: "450px", maxHeight: "450px" }}>
                        <v-layout row wrap height="600px">
                            {items}
                        </v-layout>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text on-click={this.cancel}>Cancel</v-btn>
                </v-card-actions>
            </v-card>
        );
    }
});