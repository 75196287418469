import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import EventBus from '../event-bus.js';
import utils from '../../Shared/utils.jsx';

Vue.component('desktop-notify', {
    mixins: [BaseComponent],
    data: function () {
        return {
            serviceWorkerRegistration: null,
            hasPermission: false,
            notifyUniqeIdCounter: 1,
            desktopNotifyActions: {},
            closedNotifyCache: {}, // tracks notifications that were closed by Id but not found in notification list. This could mean the notification hadn't finished showing yet (being rendered async)
        }
    },
    computed: {
    },
    created() {
        EventBus.$on(`Action-DisplayDesktopNotify`, this.performDisplayDesktopNotify);
        EventBus.$on(`Action-CloseDesktopNotify`, this.performCloseDesktopNotify);

        let c = this;

        // Set up the service worker.
        navigator.serviceWorker.register('/sw_v2.js', { scope: '/' })
            .then(function (serviceWorkerRegistration) {
                // registration worked
                //utils.debug('Registration succeeded. Scope is ' + serviceWorkerRegistration.scope);

                c.serviceWorkerRegistration = serviceWorkerRegistration;

                navigator.serviceWorker.addEventListener('message', c.messageReceived);
            })
            .catch(function (error) {
                // registration failed
                utils.error('Registration failed with ' + error, error);
            });


        // Set up the listener for messages from the service worker.
        Notification.requestPermission(function (result) {
            c.hasPermission = (result === 'granted');
        });
    },
    //Mounted Replaced with preRenderComplete
    destroyed() {
        EventBus.$off(`Action-DisplayDesktopNotify`, this.performDisplayDesktopNotify);
        EventBus.$off(`Action-CloseDesktopNotify`, this.performCloseDesktopNotify);

        if (this.serviceWorkerRegistration)
            navigator.serviceWorker.removeEventListener('message', this.messageReceived);
    },
    methods: {
        async performDisplayDesktopNotify(action) {
            utils.log(`DesktopNotify DisplayDesktopNotify`);

            if (action.ActionData && action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            const title = utils.evaluate(action.titleexpn, action.context);
            const body = utils.evaluate(action.bodyexpn, action.context);
            const icon = utils.evaluate(action.iconexpn, action.context);

            const firstActionTitle = utils.evaluate(action.firstActionTitleexpn, action.context);
            const secondActionTitle = utils.evaluate(action.secondActionTitleexpn, action.context);
            const firstActionIcon = utils.evaluate(action.firstActionIconexpn, action.context);
            const secondActionIcon = utils.evaluate(action.secondActionIconexpn, action.context);
            const thecontext = utils.evaluate(action.thecontextexpn, action.context);

            if (this.hasPermission && this.serviceWorkerRegistration) {
                const notifyName = action.ActionData.Name; // if DisplayDesktopNotify is called without a Name, you can still close using the notifyUniqeId returned
                const notifyUniqeId = this.notifyUniqeIdCounter++;

                // Save based on unique Id so that we can open more than one at the same time
                this.desktopNotifyActions[notifyUniqeId] = action;

                const actions = [];
                if (firstActionTitle) {
                    actions.push({
                        title: firstActionTitle,
                        action: 'first',
                        icon: firstActionIcon || undefined,
                    });
                }
                if (secondActionTitle) {
                    actions.push({
                        title: secondActionTitle,
                        action: 'second',
                        icon: secondActionIcon || undefined,
                    });
                }

                const options = {
                    type: "basic",
                    body: body,
                    icon: icon,
                    contextMessage: thecontext,
                    requireInteraction: true,
                    actions: actions,
                    tag: notifyUniqeId, // tag is specifed on principle...we don't actually use it
                    data: {
                        desktopServiceId: this.desktopServiceId,
                        notifyName: notifyName,
                        notifyUniqeId: notifyUniqeId,
                        options: {
                            focus: action.ActionData.FocusOnClick,
                        },
                    },
                };

                const c = this;

                // Display the notification
                // Note: There appears to be a bug in chrome where sometimes the notification shows, but the notification doesn't actually work
                //       I can only reproduce the error when quickly creating notifications in a loop (e.g. TestDesktopNotify(50)), in which case the 
                //       notification shows, but isn't returned in getNotifications(). Also the notification doesn't react to clicks on the actions (it will close if the X is pressed, but no message is sent)
                //       in such cases the notification seems to be unhealthy - it displayed but didn't get added to the notification list properly and therefore can't be interacted with properly.
                //       I wasn't able to produce the problem under normal circumstances (even running 1 dialog manually really fast) - nonetheless there is a problem in chrome somewhere. 
                this.serviceWorkerRegistration.showNotification(title, options).then(function (argv) {
                    //care.logDebug('Desktop Notify:: Show - showed ' + notifyUniqeId);

                    // showNotification doesn't happen instantly and allows a window of time for a close to happen before the notification has actually shown. So once it shows, 
                    // we check the c.closedNotifyCache to see if we should immediatly close ourselves
                    if (c.closedNotifyCache[notifyUniqeId]) {
                        delete c.closedNotifyCache[notifyUniqeId];

                        c.serviceWorkerRegistration.getNotifications().then(
                            function (notifications) {
                                //care.logDebug('Desktop Notify:: Show - found in closedNotifyCache...closing notifications ' + notifyUniqeId + ', notifications ' + notifications.length );

                                for (var x = 0; x < notifications.length; x++) {
                                    var notification = notifications[x];

                                    if (notification.data &&
                                        notification.data.desktopServiceId == c.desktopServiceId &&
                                        notification.data.notifyUniqeId == notifyUniqeId) {
                                        utils.debug('Desktop Notify:: Close - closing from cache ' + notifyUniqeId + ", notifications: " + notifications.length);
                                        notification.close();
                                        break;
                                    }
                                }

                                if (x == notifications.length)
                                    utils.debug('Desktop Notify:: Close - NOT FOUND from cache ' + notifyUniqeId + ", notifications: " + notifications.length);
                            }
                        );
                    }
                    else if (action.ActionData.Timeout) {
                        setTimeout(function () {
                            // the notification may already be closed (if the user closed manually), so don't error if we can't find it
                            c.serviceWorkerRegistration.getNotifications().then(
                                function (notifications) {
                                    for (var x = 0; x < notifications.length; x++) {
                                        var notification = notifications[x];

                                        if (notification.data &&
                                            notification.data.desktopServiceId == c.desktopServiceId &&
                                            notification.data.notifyUniqeId == notifyUniqeId) {
                                            notification.close();
                                            break;
                                        }
                                    }
                                }
                            );

                        }, action.ActionData.Timeout);
                    }
                });

                utils.success(action, { DesktopNotifyId: notifyUniqeId });
                //actionInfo.Finished(careActionCompletionStatus.SUCCESS, { DesktopNotifyId: notifyUniqeId });
            }
            else {
                utils.failure(action);
                //actionInfo.Finished(careActionCompletionStatus.FAIL);
            }

            try {
                await utils.complete(action);
            }
            catch (e) { }

            // Complete the promise for the executeAction method
            action.FinishFunc(true);
        },
        async performCloseDesktopNotify(action) {
            utils.log(`DesktopNotify CloseDesktopNotify`);

            if (action.ActionData && action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            if (this.hasPermission && this.serviceWorkerRegistration) {
                var notifyName = utils.evaluate(action.desktopNotifyName, action.context);
                var notifyUniqeId = utils.evaluate(action.desktopNotifyId, action.context);

                let c = this;

                // Get a list of the notifications.
                this.serviceWorkerRegistration.getNotifications().then(
                    function (notifications) {
                        // Loop through the notifications until a match is found. If the DesktopNotifyId is specified, it is the only criteria used to match. Otherwise it uses the name.
                        // then delete the data and close the notifications.
                        for (var x = 0; x < notifications.length; x++) {
                            var notification = notifications[x];

                            if (notification.data &&
                                notification.data.desktopServiceId == c.desktopServiceId &&
                                (
                                    (
                                        notifyUniqeId && notification.data.notifyUniqeId == notifyUniqeId
                                    )
                                    ||
                                    (
                                        !notifyUniqeId && notification.data.notifyName == notifyName
                                    )
                                )
                            ) {
                                delete c.desktopNotifyActions[notification.data.notifyUniqeId];
                                //care.logDebug('Desktop Notify:: Close FOUND ' + notifyUniqeId + ' closing, Notification Count: ' + notifications.length);
                                notification.close();
                                break;
                            }
                        }

                        // if no notification was found and an Id was specified, then the notification may not have finished showing yet (it renders Async and therefore we can close before it is fully shown)
                        // so cache that it was intended to close. When notifications show, they check the cached closed list to see if they have been programatically closed already.
                        if (x == notifications.length && notifyUniqeId) {
                            c.closedNotifyCache[notifyUniqeId] = true;
                            //care.logDebug('Desktop Notify:: Close NOT FOUND ' + notifyUniqeId + ", Notification Count: " + notifications.length);
                        }
                    }
                );
            }

            utils.success(action);
            // Complete the promise for the executeAction method
            action.FinishFunc(true);

            //actionInfo.Finished(careActionCompletionStatus.SUCCESS);
        },
        preRenderComplete() {
            this.finishRenderHandler(this);
        },

        messageReceived(event) {
            utils.log('Desktop Notify:: message received ' + event.data.action);

            // if we receive a message requesting a guid, the respond with this services ids
            if (event.data.action == 'guid') {
                event.ports[0].postMessage({ desktopServiceId: this.desktopServiceId, windowId: event.data.windowId });
                return;
            }

            var notifyUniqeId = event.data.notifyData.notifyUniqeId;
            if (!notifyUniqeId) {
                return;
            }

            var actionInfo = this.desktopNotifyActions[notifyUniqeId];
            if (actionInfo) {
                // Based on the action, run the associated actions.
                var runActions = null;

                if (event.data.action == 'first') {
                    runActions = actionInfo.ActionData.FirstActionActions;
                }
                else if (event.data.action == 'second') {
                    runActions = actionInfo.ActionData.SecondActionActions;
                }
                else if (event.data.action == 'close') {
                    runActions = actionInfo.ActionData.CloseActions;
                }

                if (runActions) {
                    utils.executeAndCompileAllActions(runActions, null, actionInfo.context);

                    //    actionService.actionBroadcaster({
                    //        actions: runActions,
                    //        scope: actionInfo.scope,
                    //        parent: actionInfo.$controlModel
                    //    });
                }

                // Clean up actions
                delete this.desktopNotifyActions[notifyUniqeId];
            }
        }
    },
    props: {
    },
    render(h) {
        return null;
    }
});