<template>
	<v-container>
		<div id="flexContainer" ref="flexContainer" class="d-flex flex-row flex-wrap" :style="{
			overflowY: (expanded ? 'visible' : 'hidden'),
			maxHeight: (expanded ? '' : '65px') // Set a fixed max height
		}">
			<span class="align-self-center mr-1 consistentText">
				Selected {{typeDescriptor}}:
			</span>
			<v-chip
				v-for="(item,i) in pills"
				:key="i"
				@click:close="removePill(item)"
				close
                pill
                text-color="primary"
                class="pill"
				close-icon="mdi-close"
				style="margin: 3px;"
                color="primaryLighten2"
			>
				<template v-if="item.calculation && item.calculation !== 'bucket'">
					{{ formatHeader({ type: item.SourceType, headerName: item.Title, aggFunc: item.calculation }) }}
				</template>
				<template v-else>
					{{ item.headerName || item.Name || item.Title }}
				</template>
			</v-chip>
            <div role="button" v-if="expanded" @click="toggleExpansion()" class="align-self-center ml-1 consistentText" style="text-decoration: underline;">
                Hide
            </div>
		</div>
		<div role="button" class="consistentText" style="text-decoration: underline;"  v-if="hasMorePills && !expanded" @click="toggleExpansion()">
			Show all
		</div>
	</v-container>
</template>

<script>
import Vue from "vue";
import common from './common.js';

export default {
	props: {
		removePill: null,
		pills: Array,
		typeDescriptor: null,
	},
	data() {
		return {
			expanded: false,
            hasMorePills: false,
            formatHeader: common.formatHeader,
		};
	},
	mounted() {
		Vue.nextTick(() => {
			this.checkOverflow();
		})
	},
	watch: {
		pills: {
			handler() {
				this.checkOverflow();
			},
			deep: true,
		}
	},
	methods: {
		checkOverflow() {
			Vue.nextTick(() => {
				const container = this.$refs.flexContainer;
				const scrollHeight = container.scrollHeight;
				const clientHeight = container.clientHeight;
				console.log("scrollHeight: " + scrollHeight);
				console.log("clientHeight: " + clientHeight);
				this.hasMorePills = scrollHeight > clientHeight;
			});
		},
		toggleExpansion() {
			this.expanded = !this.expanded;
            this.checkOverflow();
		},
	},
};
</script>

<style scoped>
.pill {
    border: 1px solid var(--v-primary-base) !important;
    background-color: var(--v-cyan-base) !important;
}
.pill::before {
    display: none;
}
.consistentText {
	color: var(--v-black-base) !important; 
	opacity: 0.8 !important; 
	font-size: 14px !important; 
	text-rendering: optimizelegibility !important; 
	letter-spacing:0.1 !important;
}
</style>