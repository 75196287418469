import cachingService from '@/Services/cachingService';

export default {
    get(url, config) {
        return this.executeShortcut(url, 'GET', undefined, config);
    },
    head(url, config) {
        return this.executeShortcut(url, 'HEAD', undefined, config);
    },
    post(url, data, config) {
        return this.executeShortcut(url, 'POST', data, config);
    },
    put(url, data, config) {
        return this.executeShortcut(url, 'PUT', data, config);
    },
    delete(url, config) {
        return this.executeShortcut(url, 'DELETE', undefined, config);
    },
    executeShortcut(url, method, data, config) {
        config = config || {};
        config.method = method;
        config.url = url;
        config.body = data;

        return this.request(config);
    },
    request(config) {
        if (config.cache) {
            return cachingService.getOrAddItem(
                'APICache',
                config.url, // the url serves as the cache key
                function (finishPromise) // function to get item if not in cache
                {
                    var noCacheConfig = {...config}; // make shallow copy so we can delete the caching option without affecting original object
                    delete noCacheConfig.cache; // we do not want the normal fetch to handle the caching, so delete the option (we will be handling caching ourselves)

                    fetch((noCacheConfig.url || noCacheConfig.absoluteUrl), noCacheConfig).then(
                        async function (response)
                        {
                            if (response.status >= 200 && response.status < 300) {
                                var text = await response.text()
                                var data = text;
                                try {
                                    data = JSON.parse(text);
                                } catch (err) {
                                    data = text;
                                }
                                
                                finishPromise.resolve({
                                    data: data,
                                    headers: response.headers
                                });
                            } else
                                finishPromise.reject(response);
                        },
                        function (error)
                        {
                            finishPromise.reject(error);
                        }
                    );
                }
            );
        } else {
            let resolver, rejecter;

            const promise = new Promise(function (resolve, reject) {
                resolver = resolve;
                rejecter = reject;
            });

            fetch((config.url || config.absoluteUrl), config)
                .then(async function(response) {
                    if (response.status >= 200 && response.status < 300) {
                        var text = await response.text()
                        var data = text;
                        try {
                            data = JSON.parse(text);
                        } catch (err) {
                            data = text;
                        }
                        
                        resolver({
                            data: data,
                            headers: response.headers
                        });
                    } else
                        rejecter(response);
                })
                .catch(error => {
                    rejecter(error);
                });

            return promise;

            // if not caching then return normal fetch promise
            //let response = await fetch((config.url || config.absoluteUrl), config);
            //try {
            //    return  {
            //        data: await response.json()
            //    }
            //} catch {
            //    return { data: {} }
            //}
        }
    },
    clearCache(key, matchStartsWith) {
        cachingService.clearCache('APICache', key, matchStartsWith);
    }
};