<template>
    <v-menu ref="menu" bottom :right="!position || position == 'right'" :left="position == 'left'" offset-y
            v-model="datePickerOpen"
            :close-on-content-click="false"
            :return-value.sync="daterange"
            @input="menuStateChanged"
            :transition="!iconOnly ? 'scale-transition' : 'fade-transition'">
        <template v-slot:activator="{ on, attrs }">
            <v-text-field v-if="!iconOnly" outlined dense hide-details
                          :style="{ minWidth: width || '460px', maxWidth: width || '460px' }"
                          :class="myclass"
                          :value="displaydaterange"
                          :label="label"
                          _prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on">
                <template v-slot:prepend-inner>
                    <v-icon :color="isactive ? activecolor : inactivecolor"
                            v-bind="attrs"
                            v-on="on"
                            @click="datePickerOpen = true">
                        {{ isactive ? activeicon : inactiveicon }}
                    </v-icon>
                </template>
                <template v-slot:prepend v-if="showtooltip">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon color="grey lighten-1"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="datePickerOpen = true">
                                mdi-information-slab-circle
                            </v-icon>
                        </template>
                        <span v-if="isactive">
                            <slot name="activetooltip">
                                Date range picker (Active)
                            </slot>
                        </span>
                        <span v-else>
                            <slot name="inactivetooltip">
                                Date range picker (Inactive)
                            </slot>
                        </span>
                    </v-tooltip>
                </template>
            </v-text-field>
            <v-btn v-else  icon :elevation="0" v-bind="attrs" v-on="on" :color="isactive ? 'primary' : 'secondary'" class="mt-2 ml-0">
                <v-icon> {{ isactive ? activeicon : inactiveicon }} </v-icon>
            </v-btn>
        </template>
        <v-card>
            <div style="display: flex;">
                <v-list dense :flat="false">
                    <v-list-item-group v-model="preset_choice">
                        <v-list-item v-for="p in presets" :key="p.label" @click="setRange(p.range)">
                            <v-list-item-content>
                                <v-list-item-title>{{p.label}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
                <v-date-picker v-model="date_1" no-title scrollable show-adjacent-months range
                               style="padding: 15px; font-size: large;"
                               color="primary"
                               :picker-date="date_1_month"
                               @update:picker-date="date_1_month_changed"
                               @input="date_1_input">
                    <v-text-field dense solo required hide-details type="date" max="2099-12-31" min="1979-01-01"
                                  v-model="date_1_text" style="max-width: 114px; margin-right: 1px;"
                                  @input="date_1_text_input"
                                  @change="date_1_text_change"></v-text-field>
                    <v-text-field dense solo required hide-details type="time" step="1"
                                  v-model="time_1" style="max-width: 120px;"></v-text-field>
                </v-date-picker>
                <v-date-picker v-model="date_2" no-title scrollable show-adjacent-months range
                               style="padding: 15px; font-size: large;"
                               color="primary"
                               :picker-date="date_2_month"
                               @update:picker-date="date_2_month_changed"
                               @input="date_2_input">
                    <v-text-field dense solo required hide-details type="date" max="2099-12-31" min="1979-01-01"
                                  v-model="date_2_text" style="max-width: 114px; margin-right: 1px; box-shadow: none !important;"
                                  @input="date_2_text_input"
                                  @change="date_2_text_change"></v-text-field>
                    <v-text-field dense solo required hide-details type="time" step="1"
                                  v-model="time_2" style="max-width: 120px;"></v-text-field>
                </v-date-picker>
            </div>

            <v-card-actions style="flex-flow:row-reverse; padding-right: 25px; padding-bottom: 15px;">
                <v-btn text color="primary" @click="save()" :disabled="!isvalid">
                    Apply
                </v-btn>
                <v-btn text _color="primary" @click="datePickerOpen = false">
                    Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <span v-if="isafter" style="color: maroon;" class="ml-5">Warning - start is later than end</span>
            </v-card-actions>
        </v-card>
    </v-menu>
</template>

<script>
    import Vue from "vue";

    import utils from '@/Shared/utils.jsx';
    import filters from '@/Shared/filters.js';
    import dayjs from 'dayjs';

    export default {
        data: function () {
            return {
                daterange: {
                    startDate: '2024-10-20T00:00:00',
                    endDate: '2024-10-20T23:59:59',
                },
                datePickerOpen: false,
                preset_choice: null,

                date_1_text: '2024-10-20',
                date_2_text: '2024-10-21',
                time_1: '00:00:00',
                time_2: '23:59:59',

                date_1_month: '2024-09',
                date_2_month: '2024-10',

                date_1: ['2024-10-20', '2024-10-21'],
                date_2: ['2024-10-20', '2024-10-21'],

                isafter: false,

                presets: [
                    { label: 'Today', range: [dayjs().startOf('day'), dayjs().startOf('day')] },
                    { label: 'Yesterday', range: [dayjs().subtract(1, 'day').startOf('day'), dayjs().subtract(1, 'day').endOf('day')] },
                    { label: 'Last 7 Days', range: [dayjs().subtract(6, 'day').startOf('day'), dayjs().endOf('day')] },
                    { label: 'Last 30 Days', range: [dayjs().subtract(29, 'day').startOf('day'), dayjs().endOf('day')] },
                    { label: 'This Month', range: [dayjs().startOf('month'), dayjs().endOf('month')] },
                    { label: 'Last Month', range: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')] },
                    { label: 'Last 3 Months', range: [dayjs().subtract(3, 'month').startOf('month'), dayjs().endOf('month')] },
                    { label: 'Last 6 Months', range: [dayjs().subtract(6, 'month').startOf('month'), dayjs().endOf('month')] },
                ],
            }
        },
        props: {
            value: {
                type: [Object, String, Date]
            },
            label: null,
            width: null,
            
            iconOnly: false,
            activeicon: null,
            inactiveicon: null,
            activecolor: null,
            inactivecolor: null,
            showtooltip: null,
            position: null,

            myclass: null,
            isactive: {
                type: [Boolean]
            },
        },
        created() {
            if (this.value) {
                console.log(`calendar Oldvalue: ${JSON.stringify(this.value)}`);

                if (typeof this.value === 'string') {
                    const valueobj = JSON.parse(this.value);
                    this.daterange = valueobj;
                }
                else
                    this.daterange = this.value;
            }
        },
        watch: {
            value: function (newv, oldv) {
                console.log(`calendar Changedvalue: ${JSON.stringify(newv)}`);

                this.datePickerOpen = false;

                if (typeof newv === 'string') {
                    const valueobj = JSON.parse(newv);
                    this.daterange = valueobj;
                }
                else
                    this.daterange = newv;
            }
        },
        computed: {
            displaydaterange: function () {
                return `${this.format(this.daterange.startDate)} - ${this.format(this.daterange.endDate)}`;
            },
            textcolor: function () {
                return this.isactive ? '' : 'grey lighten-2';
            },
            isvalid: function () {
                if (this.date_1.length < 2)
                    return false;

                const date1 = `${this.date_1[0]}T${this.time_1}`;
                const date2 = `${this.date_1[1]}T${this.time_2}`;
                return dayjs(date1).isValid() && dayjs(date2).isValid();
            }
        },
        methods: {
            menuStateChanged(state) {
                if (state) {
                    this.preset_choice = null;

                    // Prepopulate the dates to the daterange
                    const range1 = dayjs(this.daterange.startDate);
                    const range2 = dayjs(this.daterange.endDate);

                    this.date_1 = [range1.format('YYYY-MM-DD'), range2.format('YYYY-MM-DD')];
                    this.date_2 = this.date_1;

                    this.date_1_text = this.date_1[0];
                    this.date_2_text = this.date_1[1];

                    this.time_1 = range1.format('HH:mm:ss');
                    this.time_2 = range2.format('HH:mm:ss');

                    this.date_2_month = this.date_2_text.substr(0, 7);
                    if (this.date_2_month == this.date_1_text.substr(0, 7)) {
                        // If the start date is in the same month as the end date, use the previous month for the first calendar
                        // this.date_1_month = dayjs(this.date_1_text).subtract(1, 'month').toISOString().substr(0, 7);
                        this.date_1_month = dayjs(this.date_1_text).subtract(1, 'month').format('YYYY-MM');
                    }
                    else
                        this.date_1_month = this.date_1_text.substr(0, 7);
                }
            },
            setRange(range) {
                const r0 = dayjs(range[0]);
                const r1 = dayjs(range[1]);
                //debugger;

                this.date_1 = [ r0.format('YYYY-MM-DD'), r1.format('YYYY-MM-DD') ];
                this.date_2 = [ r0.format('YYYY-MM-DD'), r1.format('YYYY-MM-DD') ];

                console.log(`setRange: ${range}`);
                console.log(`        : ${this.date_1}`);

                this.date_1_text = this.date_1[0];
                this.date_2_text = this.date_1[1];

                this.time_1 = '00:00:00';
                this.time_2 = '23:59:59';

                if (this.date_2_month != this.date_2_text.substr(0, 7)) {
                    this.date_2_month = this.date_2_text.substr(0, 7);

                    if (this.date_1_month == this.date_2_month || dayjs(this.date_2_month + '-01').isBefore(dayjs(this.date_1_month + '-01')))
                        this.date_1_month = dayjs(this.date_2_month + '-01').subtract(1, 'month').toISOString().substr(0, 7);
                }

                if (this.date_1_text.substr(0, 7) != this.date_2_text.substr(0, 7)) {
                    this.date_1_month = this.date_1_text.substr(0, 7);
                }

                this.preset_choice = null;
            },
            format(datevalue) {
                const dj = dayjs(new Date(datevalue));
                return dj.format('L') + ' ' + dj.format('LTS');
            },
            save() {
                this.preset_choice = null;
                const date1 = `${this.date_1[0]}T${this.time_1}`;
                const date2 = `${this.date_1[1]}T${this.time_2}`;
                const newvalue = { startDate: date1, endDate: date2 };

                this.$refs.menu.save(newvalue);

                this.$emit('change', newvalue);

                console.log(`calendar Newvalue: ${JSON.stringify(newvalue)}`);
            },

            date_1_input(v) {
                if (v.length > 1 && dayjs(v[0]).isAfter(dayjs(v[1])))
                    v = [v[1], v[0]]; // Reverse them if the second comes before the first

                this.date_2 = v;

                this.date_1_text = v[0];
                if (v.length > 1)
                    this.date_2_text = v[1];

                this.isafter = dayjs(this.date_1_text).isAfter(dayjs(this.date_2_text));

                this.preset_choice = null;
            },
            date_2_input(v) {
                if (v.length > 1 && dayjs(v[0]).isAfter(dayjs(v[1])))
                    v = [v[1], v[0]]; // Reverse them if the second comes before the first

                this.date_1 = v;

                this.date_1_text = v[0];
                if (v.length > 1)
                    this.date_2_text = v[1];

                this.isafter = dayjs(this.date_1_text).isAfter(dayjs(this.date_2_text));

                this.preset_choice = null;
            },
            date_1_text_input(v) {
                this.preset_choice = null;
            },
            date_2_text_input(v) {
                this.preset_choice = null;
            },

            date_1_text_change(v) {
                if (dayjs(v).isAfter(dayjs('2099-12-31')))
                    this.date_1_text = '2099-12-31';
                else if (dayjs(v).isBefore(dayjs('1979-01-01')))
                    this.date_1_text = '1979-01-01';

                Vue.set(this.date_1, 0, this.date_1_text);
                Vue.set(this.date_2, 0, this.date_1_text);

                if (this.date_1.length > 1 && dayjs(this.date_1_text).isAfter(dayjs(this.date_1[1]))) {
                    Vue.set(this.date_1, 1, this.date_1_text); // If the first date is after the second, make the second match the first
                    Vue.set(this.date_2, 1, this.date_1_text);
                    this.date_2_text = this.date_1_text;
                }

                if (this.date_1_month != this.date_1_text.substr(0, 7) && this.date_1_text.substr(0, 7) != this.date_2_month) {
                    this.date_1_month = this.date_1_text.substr(0, 7);

                    if (dayjs(this.date_1_month + '-01').isAfter(dayjs(this.date_2_month + '-01')))
                        this.date_2_month = this.date_1_month;
                }
            },
            date_2_text_change(v) {
                if (dayjs(v).isAfter(dayjs('2099-12-31')))
                    this.date_2_text = '2099-12-31';
                else if (dayjs(v).isBefore(dayjs('1979-01-01')))
                    this.date_2_text = '1979-01-01';

                if (this.date_1.length > 1) {
                    Vue.set(this.date_1, 1, this.date_2_text);
                    Vue.set(this.date_2, 1, this.date_2_text);
                }
                else
                    this.date_1.push(this.date_2_text);

                if (this.date_1.length > 1 && dayjs(this.date_1[0]).isAfter(dayjs(this.date_2_text))) {
                    Vue.set(this.date_1, 0, this.date_2_text); // If the first date is after the second, make the second match the first
                    Vue.set(this.date_2, 0, this.date_2_text);
                    this.date_1_text = this.date_2_text;
                }

                if (this.date_2_month != this.date_2_text.substr(0, 7)) {
                    this.date_2_month = this.date_2_text.substr(0, 7);

                    if (dayjs(this.date_1_month + '-01').isAfter(dayjs(this.date_2_month + '-01')))
                        this.date_1_month = this.date_2_month;
                }
            },

            date_1_month_changed(v) {
                this.date_1_month = v;
            },
            date_2_month_changed(v) {
                this.date_2_month = v;
            },
        }
    }
</script>

<style _scoped>
    .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
        box-shadow: none !important;
        border: 1px solid gray;
        border-radius: 5px;
    }
</style>