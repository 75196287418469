import Vue from 'vue';
import BaseComponent from './baseFormMixin.jsx';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

import { appSettings } from '@/Shared/appSettings.js';

Vue.component('sform-number', {
    mixins: [BaseComponent],
    data: () => ({
    }),
    props: {
    },
    created() {
    },
    mounted() {
        this.loadComplete();
    },
    computed: {
        items_translated: function () {
            if (this.element.titleMap)
                return this.element.titleMap.map(item => ({
                    name: this.Translate(item.name),
                    value: item.value,
                }));
            else
                return [];
        }
    },
    methods: {
    },
    render() {
        let input;

        let scopedSlots = {
            message: ({message}) => {
                return <translation-container context={this} value={message}></translation-container>
            },
            item: ({item, on}) => {
                return (
                    <v-list-item 
                        on={on}>
                        <v-list-item-content>
                            <v-list-item-title>
                                <translation-container context={this} value={item.name}></translation-container>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                );
            },
        }

        let slots = [
            <translation-container slot="label" context={this} value={this.labelText} autoOpenModal={!!this.element.titleMap}></translation-container>,
        ];

        if (this.element.titleMap)
            input =
                <v-select
                    class="caption pa-0 ma-0"
                    outlined single-line dense hide-details
                    style={{ width: "100%" }}
                    menu-props={{ offsetY: true, auto: true }}
                    value={this.itemvalue}
                    items={this.items_translated}
                    item-text="name"
                    item-value="value"
                    rules={this.rules}
                    on-change={(value) => this.sync(value)}
                    on-blur={this.onBlur}
                    on-focus={this.onFocus}
                    scopedSlots={scopedSlots}
                    hint={this.hintText}
                    persistent-hint={appSettings.DebugTranslationPrefixSetting}
                >{slots}</v-select>;
        else
            input =
                <v-text-field
                    class="caption pa-0 ma-0"
                    type="number"
                    outlined single-line dense hide-details
                    style={{ width: "100%" }}
                    value={this.itemvalue}
                    rules={this.rules}
                    on-input={(value) => this.sync(parseFloat(value))}
                    on-blur={this.onBlur}
                    on-focus={this.onFocus}
                    scopedSlots={scopedSlots}
                    hint={this.hintText}
                    persistent-hint={appSettings.DebugTranslationPrefixSetting}
                >{slots}</v-text-field>;

        if (this.appearance)
            input.componentOptions.propsData = { ...input.componentOptions.propsData, ...this.appearance };
            
        if (this.directives) {
            if (input.data.directives)
                input.data.directives = [...input.data.directives, ...this.directives];
            else
                input.data.directives = this.directives;
        }

        return input;
    }
});