class CallCorpPlayer extends HTMLElement {    
    constructor() {
        super();
        this.shadow = this.attachShadow({mode:'open'});
        this.shadow.innerHTML = `<div class='cc-videos'></div>`;

        this._accessToken = "";
        this._videos = [];
        this._videoEls = [];
        this._videoMetadata = [];

        this.height = 500;

        //this.addEventListener("click", this.onClickHandler.bind(this));
        // this.shadow.addEventListener("slotchange", this.onSlotChange.bind(this));
    }

    set videos(value) {
        var self = this;
        self._videos = value;
        var minX = 0;
        var minY = 0;
        var maxX;
        var maxY;

        self.shadow.querySelector(".cc-videos").setAttribute("style", `position:relative;height:${self.height}px;width:100%;`);

        self._videos.forEach((video, vIndex) => {
            video.class = `cc-video-${vIndex}`;

            video.width = parseInt(video.width, 10);
            video.height = parseInt(video.height, 10);
            video.x = parseInt(video.x, 10);
            video.y = parseInt(video.y, 10);
            video.timeoffset = parseInt(video.timeoffset, 10);

            var videoEl = document.createElement("video");
            //videoEl.setAttribute("controls", "controls");
            videoEl.setAttribute("muted", "muted");
            videoEl.setAttribute("class", video.class);
            //videoEl.dataset.timeoffset = video.timeoffset;
            self.shadow.querySelector(".cc-videos").appendChild(videoEl);
            self._videoEls.push(videoEl);
            //videoEl.onloadeddata = self.positionScreens;

            //{src: "X.mp4", type: "video/mp4", width: 3840, height: 1440, x: 3840, y: 296, timeoffset: -471}
            self._videoMetadata.push(video);
            
            if (video.x || video.x === 0) {
                if ((!minX && minX !== 0) || minX > video.x) {
                    minX = video.x;
                }
                if ((!maxX && maxX !== 0) || maxX < (video.x + video.width)) {
                    maxX = video.x + video.width;
                }
            }
            if (video.y || video.y === 0) {
                if ((!minY && minY !== 0) || minY > video.y) {
                    minY = video.y;
                }

                if ((!maxY && maxY !== 0) || maxY < (video.y + video.height)) {
                    maxY = video.y + video.height;
                }
            }

            var accessToken = this._accessToken;

            (async function() {
                var response = await fetch(video.src, {
                    method: "GET",
                    mode: "cors",
                    credentials: "include",
                    headers: {
                        "access_token": accessToken
                    }
                })
                    .then(response => response.body)
                    .then(body => {
                        const reader = body.getReader();
                        return new ReadableStream({
                            start(controller) {
                              return pump();
                              function pump() {
                                return reader.read().then(({ done, value }) => {
                                  // When no more data needs to be consumed, close the stream
                                  if (done) {
                                      controller.close();
                                      return;
                                  }
                                  // Enqueue the next data chunk into our target stream
                                  controller.enqueue(value);
                                  return pump();
                                });
                              }
                            }
                        });
                    })
                    .then(stream => new Response(stream))
                    .then(response => response.blob())
                    .then(blob => URL.createObjectURL(blob))
                    .then(url => videoEl.src = url)
                    .catch(err => console.error(err));
            })();
        });

        self.minX = minX;
        self.minY = minY;
        self.maxX = maxX;
        self.maxY = maxY;

        self.positionScreens();
    }

    positionScreens() {
        if (!this.minX && !this.maxX) {
            return;
        }

        var minX = this.minX;
        var minY = this.minY;
        var maxX = this.maxX;
        var maxY = this.maxY;
        var containerHeight = this.shadow.querySelector(".cc-videos").offsetHeight;
        var containerWidth = this.shadow.querySelector(".cc-videos").offsetWidth;
        console.log({ containerHeight, containerWidth });
        var fullWidth = maxX - minX;
        var fullHeight = maxY - minY;
        var hRatio = (fullHeight / containerHeight);
        var wRatio = (fullWidth / containerWidth);
        var ratio = Math.max(hRatio, wRatio);

        var self = this;
        //{src: "X.mp4", type: "video/mp4", width: 3840, height: 1440, x: 3840, y: 296, timeoffset: -471, class}
        this._videoMetadata.forEach((videoData) => {
            var video = self.shadow.querySelector(`.${videoData.class}`);
            video.setAttribute("style", "");
            var height = videoData.height;
            var width = videoData.width;
            
            var vHeight = height / ratio;
            var top = ((videoData.y - minY) / ratio);
            var left = ((videoData.x - minX) / ratio);
            video.style.position = "absolute";
            video.style.width = `${(width / ratio)}px`;
            video.style.height = `${vHeight}px`;
            video.style.top = `${top}px`;
            video.style.left = `${left}px`;

        });
    }

    set accessToken(value) {
        this._accessToken = value;
    }
      
    get videos() {
        return this._videos;
    }

    set controlElementSelector(value) {
        this._controlElementSelector = value;
        this._controlElement = document.querySelector(value);
        this.setupControlElement();
    }

    set playing(value) {
        this._videoEls.forEach(video => {
            if (value) {
                video.play();
            } else {
                video.pause();
            }
        });
    }

    set controlTime(value)
    {
        this.lastUpdatedDate = this.lastUpdatedDate || new Date();
        var now = new Date();
        if (now - this.lastUpdatedDate >= 1000)
        {
            this.setCurrentTime(value);
            this.lastUpdatedDate = new Date();
        }
    }

    setupControlElement() {
        var self = this;
        // this._controlElement.onseeked = function() {
        //     self.setCurrentTime(self._controlElement.currentTime);
        // };
        this._controlElement.onplay = function() {
            self.playing = true;
        };
        this._controlElement.onpause = function() {
            self.playing = false;
        };
        this._controlElement.ontimeupdate = function(event) {
            self._ontimeupdate(self, event);
        };
    }

    _ontimeupdate(player) {
        player.lastUpdatedDate = player.lastUpdatedDate || new Date();
        var now = new Date();
        if (now - player.lastUpdatedDate >= 1000) {
            player.setCurrentTime(player._controlElement.currentTime);
            player.lastUpdatedDate = new Date();
        }
    }

    setCurrentTime(newTime) {
        var self = this;
        this._videoEls.forEach(video => {
            if (Math.abs(video.currentTime - newTime) >= 1)
            {
                video.currentTime = newTime;
                if (self.playing) {
                    video.play();
                }
            }
        });
    }
}

window.customElements.define('callcorp-player', CallCorpPlayer);