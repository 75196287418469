import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import EventBus from '../event-bus.js';
import utils from '@/Shared/utils';

import JointPaper from './vuecontrols/JointPaper';
import { Splitpanes, Pane } from 'splitpanes';

function toHex(n) {
    n = parseInt(n, 10);
    if (isNaN(n)) return "00";
    n = Math.max(0, Math.min(n, 255));
    return "0123456789ABCDEF".charAt((n - n % 16) / 16)
        + "0123456789ABCDEF".charAt(n % 16);
}

function rgbToHex(R, G, B) {
    return toHex(R) + toHex(G) + toHex(B)
}

const empty_tree =
    [
        {
            id: 1,
            name: 'Unnamed.prj',
            children: [
                {
                    id: 2,
                    name: 'API References',
                    children: [
                        {
                            id: 3,
                            name: 'System'
                        },
                        {
                            id: 4,
                            name: 'Platform'
                        }
                    ]
                },
                {
                    id: 5,
                    name: 'Project References',
                    children: [],
                },
                {
                    id: 6,
                    name: 'Resources',
                    children: [],
                },
                {
                    id: 7,
                    name: 'Enums',
                    children: [],
                },
                {
                    id: 8,
                    name: 'Application',
                    children: [],
                }
            ]
        }
    ];

// The arghandler is an object used with a Proxy to control reading and writing
// to an icon which has all parameters saved in an array called Arguments. Each
// param has a PropertyName and PropertyValue as opposed to being an object
// with first class properties.
const arghandler = {
    get(target, prop, receiver) {
        const f = target.find(a => a.PropertyName == prop);
        if (f)
            return f.PropertyValue;

        return undefined;
    },
    set(target, prop, value) {
        const f = target.find(a => a.PropertyName == prop);
        if (f)
            f.PropertyValue = value;

        return true;
    },
    has(target, prop) {
        return !!target.find(a => a.PropertyName == prop);
    }
}

Vue.component('flow-chart', {
    mixins: [BaseComponent],
    components: {
        JointPaper,
        Splitpanes,
        Pane,
    },
    data: function () {
        return {
            next_id: 1,
            selected_menu: -1,
            selected_tab: 0,
            loading: true,

			background: {
				color: '#fffffb'
			},
			gridSize: 10,
			drawGrid: true,
            model: null,

            tabs: [],

            closing_tab: -1,

            internal_apis: {},
            properties_schema: null,
            properties_object: null,

            project_references: {},
        }
	},
	created() {
        if (this.controlData.ProjectName)
            this.projectnameexpn = utils.compileObject(this, this.controlData.ProjectName);
    },
    //Mounted Replaced with preRenderComplete
    computed: {
        toolBar: function () {
            const h = this.$createElement;

            let loading;
            if (this.loading)
                loading = (
                    <v-progress-linear
                        indeterminate
                        color="yellow darken-2"
                    ></v-progress-linear>
                );

            return (
                <div>
                    {loading}
                    <div style="min-height: 60px; border: 1px solid silver; border-radius: 5px; margin: 3px;">
                    </div>
                </div>
            );
        },
        mainTabbedArea: function () {
            const h = this.$createElement;
            const tab_titles = [];
            const tab_body = [];

            for (let i = 0; i < this.tabs.length; i++) {
                const t = this.tabs[i];

                let content;
                let icon;
                switch (t.obj.$type) {
                    case 'MethodLib.Serialization.ProjectClassFlowchart, CompilerCore':
                        icon = (
                            <v-icon small class="mr-1">
                                mdi-sitemap
                            </v-icon>
                        );
                        content = (
                            <JointPaper
                                class={{ [`c-FlowChartPaper_${t.Name}`]: true }}
                                width="5000px"
                                height="5000px"
                                background={this.background}
                                grid-size={this.gridSize}
                                draw-grid={this.drawGrid}
                                on-init={(graph, paper) => this.setupGraph(graph, paper, t.obj.Objects)}>
                            </JointPaper>
                        );
                        break;

                    case 'MethodLib.Serialization.ProjectClassCode, CompilerCore':
                        icon = (
                            <v-icon small class="mr-1">
                                mdi-code-braces-box
                            </v-icon>
                        );
                        content = (
                            <ace-editor
                                lang="csharp"
                                content={t.obj.CodeBlock}
                                on-change-content={(value) => this.onCodeBlockChange(t, value)}>
                            </ace-editor>
                        );
                        break;
                }

                tab_titles.push(
                    <v-tab key={t.id} vOn:click_middle={(e) => this.closeTab(e, t, i)}>
                        {icon}

                        {t.Name.split('/').pop()}

                        <v-btn elevation={0} small icon on-click={(e) => this.closeTab(e, t, i)}>
                            <v-icon small>
                                mdi-close-circle-outline
                            </v-icon>
                        </v-btn>
                    </v-tab>
                );


                tab_body.push(
                    <v-tab-item key={t.id} style="height: 1px;" value={i}>
                        <div style="flex-grow: 1; height: 1px; overflow: auto;">
                            {content}
                        </div>
                    </v-tab-item>
                );
            }

            return ([
                <v-tabs value={this.selected_tab} on-change={(t) => this.changeTab(t)} style="flex-grow: 0;">
                    {tab_titles}
                </v-tabs>
                ,
                <v-tabs-items value={this.selected_tab} style="flex-grow: 1; height: 1px; display: flex; flex-direction: column;">
                    {tab_body}
                </v-tabs-items>
            ]);
        },
        projectTree: function () {
            const h = this.$createElement;

            let items;

            if (!this.model)
                items = empty_tree;
            else {
                items = [
                    {
                        id: this.next_id++,
                        name: this.model.Name,
                        icon: 'mdi-file-table-box',
                        children: [
                            {
                                id: this.next_id++,
                                name: 'API References',
                                icon: 'mdi-folder',
                                children: this.model.References.map(a => ({
                                    id: this.next_id++,
                                    name: a,
                                })),
                            },
                            {
                                id: this.next_id++,
                                name: 'Project References',
                                icon: 'mdi-folder',
                                children: [],
                            },
                            {
                                id: this.next_id++,
                                name: 'Resources',
                                icon: 'mdi-folder',
                                children: [],
                            },
                            {
                                id: this.next_id++,
                                name: 'Enums',
                                icon: 'mdi-folder',
                                children: this.model.Classes.filter(c => c.$type == 'MethodLib.Serialization.ProjectClassEnum, CompilerCore').map(c => ({
                                    id: this.next_id++,
                                    name: c.ClassName,
                                    obj: c,
                                    children: c.Values.map(v => ({
                                        id: this.next_id++,
                                        name: v,
                                    }))
                                })),
                            },
                        ]
                    }
                ];

                for (let i = 0; i < this.model.Classes.length; i++) {
                    const c = this.model.Classes[i];
                    if (c.$type == 'MethodLib.Serialization.ProjectClassEnum, CompilerCore')
                        continue;

                    let members = (c.Members ? c.Members.filter(m => m.$type != 'MethodLib.Serialization.ProjectClassField, CompilerCore').map(m => ({
                        id: this.next_id++,
                        name: m.Name,
                        icon: m.$type == 'MethodLib.Serialization.ProjectClassFlowchart, CompilerCore' ? 'mdi-sitemap' : 'mdi-code-braces-box',
                        obj: m,
                        editable: true,
                        children: m.Locals ? m.Locals.map(o => ({
                            id: this.next_id++,
                            name: o.Name,
                            obj: o,
                        })) : [],
                    })) : []);

                    members = Enumerable.From(members).OrderBy(a => a.name).ToArray();

                    items[0].children.push({
                        id: this.next_id++,
                        name: c.ClassName,
                        obj: c,
                        icon: 'mdi-application',
                        children: [
                            {
                                id: this.next_id++,
                                name: 'Data Fields',
                                icon: 'mdi-folder',
                                children: c.Members.filter(m => m.$type == 'MethodLib.Serialization.ProjectClassField, CompilerCore').map(m => ({
                                    id: this.next_id++,
                                    name: m.Name,
                                }))
                            },
                            ...members],
                    });
                }
            }

            return (
                <v-treeview
                    activatable
                    hoverable
                    dense
                    items={items}
                    open={[1]}
                    scopedSlots={{
                        label: ({ item, active }) => {
                            return <span style="cursor: pointer;">{item.name}</span>
                        },
                        prepend: ({ item, leaf, open }) => {
                            if (item.icon)
                                return <v-icon>{item.icon}</v-icon>;
                            else
                                return null;
                        },
                        append: ({ item, active }) => {
                            if (active && item.editable)
                                return <v-btn elevation={0} icon on-click={(e) => this.open(e, item)}><v-icon>mdi-pencil</v-icon></v-btn>;
                            else
                                return null;
                        },
                    }}
                >
                </v-treeview>
            );
        },
        properties: function () {
            const h = this.$createElement;

            if (!this.properties_schema)
                return <span>Nothing selected</span>;

            return (
                <property-grid
                    name="Noname"
                    root={this.Root}
                    parentType="VerticalStack"
                    schema={this.properties_schema}
                    form={["*"]}
                    cmodel={this.properties_object}
                    child={0}>
                </property-grid>
            );
        },

        topArea: function () {
            return this.toolBar;
        },
        mainArea: function () {
            return this.mainTabbedArea;
        },
        rightTopArea: function () {
            return this.projectTree;
        },
        rightBottomArea: function () {
            return this.properties;
        },

        recentsMenu: function () {
            const h = this.$createElement;

            return [
                <v-list-item on-click={() => { }}>
                    <v-list-item-content>
                        <v-list-item-title>
                            Last item 1
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>,

                <v-list-item on-click={() => { }}>
                    <v-list-item-content>
                        <v-list-item-title>
                            Last item 2
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            ];
        },
        fileMenu: function () {
            const h = this.$createElement;

            return [
                <v-list-item key={0} on-click={() => { }}>
                    <v-list-item-icon>
                        <v-icon>mdi-folder-open</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            Open
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>,

                <v-divider></v-divider>,

                <v-list-item key={1} on-click={() => { }}>
                    <v-list-item-icon>
                        <v-icon>mdi-content-save</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            Save
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>,

                <v-list-item key={2} on-click={() => { }}>
                    <v-list-item-icon>
                        
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            Save As...
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>,

                <v-divider></v-divider>,

                <v-menu
                    dense offset-x
                    scopedSlots={{
                        activator: ({ on, attrs }) =>
                            <v-list-item {...{ on }} {...{ attrs }}>
                                <v-list-item-icon>

                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title>
                                        Recent
                                    </v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-icon>mdi-menu-right</v-icon>
                                </v-list-item-action>
                            </v-list-item>
                        }}
                >
                    <v-list class="grey lighten-3" dense>
                        <v-list-item-group>
                            {this.recentsMenu}
                        </v-list-item-group>
                    </v-list>
                </v-menu>,

                <v-divider></v-divider>,

                <v-list-item key={4} on-click={() => { }}>
                    <v-list-item-icon>
                        <v-icon>mdi-close-box-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            Close
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>,
            ];
        },
        topMenuBar: function () {
            const h = this.$createElement;

            const title = this.model ? this.model.Name : 'Unnamed Project';

            return (
                <div style="display: flex;">
                    <v-menu
                        offset-y
                        close-on-content-click={false}
                        scopedSlots={{
                            activator: ({ on, attrs }) =>
                                <v-btn elevation={0} text {...{ on }} {...{ attrs }}>
                                    File
                                </v-btn>
                        }}
                    >
                        <v-list class="grey lighten-3" dense>
                            <v-list-item-group value={this.selected_menu} on-change={() => this.selected_menu--}>
                                {this.fileMenu}
                            </v-list-item-group>
                        </v-list>
                    </v-menu>

                    <v-spacer></v-spacer>
                    <v-toolbar-title>{title}</v-toolbar-title>
                    <v-spacer></v-spacer>

                    <v-btn elevation={0} icon>
                        <v-icon>mdi-close-box-outline</v-icon>
                    </v-btn>
                </div>
            );
        },
    },
    methods: {
        preRenderComplete() {
            this.finishRenderHandler(this);
            this.Refresh();
		},
        async Refresh() {
            let fn;

            if (this.projectnameexpn)
                fn = utils.evaluate(this.projectnameexpn, this);

            if (fn)
                this.model = await utils.api.get(`Apps/Project/${fn}`);
            else if (this.Query('fn'))
                this.model = await utils.api.get(`Apps/Project/${this.Query('fn')}`);
            //else
            //    this.model = {};

            this.scanAllMembers(this.model);

            await this.loadProjectReferences(this.model);

            this.loading = false;
        },
        async loadProjectReferences(model) {
            if (Array.isArray(model.ProjectRefs) && model.ProjectRefs.length > 0) {
                for (let i = 0; i < model.ProjectRefs.length; i++) {
                    const fn = model.ProjectRefs[i];
                    if (fn in this.project_references)
                        continue;

                    let proj;
                    if (fn.length > 1 && fn.substr(0, 1) == '$')
                        proj = await utils.api.get(`Apps/Projects/Published/${fn.substr(1)}`);
                    else
                        proj = await utils.api.get(`Apps/Project/${fn}`);

                    if (proj) {
                        this.scanAllMembers(proj);
                        this.project_references[fn] = proj;
                        await this.loadProjectReferences(proj);
                    }
                }
            }
        },
        changeTab(index) {
            utils.debug(`try changeTab(index:${index})`);
            if (index === undefined || index == this.closing_tab) {
                this.closing_tab = -1;
                return;
            }

            this.selected_tab = index;
        },
        open(e, item) {
            e.cancelBubble = true;
            e.stopPropagation();

            for (let i = 0; i < this.tabs.length; i++) {
                const t = this.tabs[i];

                if (t.id == item.id) {
                    // Tab already open, activate it
                    this.selected_tab = i;
                    return;
                }
            }

            this.tabs.push({
                id: item.id,
                Name: item.name,
                obj: item.obj,
            });

            this.selected_tab = this.tabs.length - 1;
        },
        onCodeBlockChange(tab, value) {
            tab.obj.CodeBlock = value;
        },
        closeTab(e, tab, index) {
            e.cancelBubble = true;
            e.stopPropagation();

            this.closing_tab = index;

            const old = this.selected_tab;

            this.tabs.splice(index, 1);

            const c = this;
            Vue.nextTick(() => {
                if (old >= index) {
                    c.selected_tab = old - 1;
                }
            });
        },

        scanAllMembers(model) {
            for (let i = 0; i < model.Classes.length; i++) {
                const c = model.Classes[i];
                if (c.Members)
                    for (let j = 0; j < c.Members.length; j++) {
                        const m = c.Members[j];
                        if (m.Key) {
                            

                            this.saveSchemaFromMethod(m);
                        }
                    }
            }
        },

        blank_pointerdown(evt, x, y) {
            if (this.selected)
                this.selected.unhighlight(this.selected.el);

            this.selected = null;
            this.properties_schema = null;
            this.properties_object = null;
        },
        async cell_pointerdown(cellView, evt, x, y) {
            if (this.selected)
                this.selected.unhighlight(this.selected.el);

            cellView.highlight(cellView.el);
            this.selected = cellView;

            const method = cellView.model.attributes.Method;
            const type = encodeURIComponent(method.$type);
            const key = method.ImplementationKey ? encodeURIComponent(method.ImplementationKey) : '';
            let schema = method.ImplementationKey ? this.internal_apis[method.ImplementationKey] : null;
            if (!schema)
                schema = await utils.api.get(`Apps/WebFlowHelpers/GetIconSchema?Type=${type}&APIKey=${key}`);

            //if (method.Name == 'BridgeCalls')
            //    debugger;

            // For MethodItems, method.Arguments contains the model data - we'll have to transform them into a regular JSON object
            this.properties_schema = this.getWrapperSchema(schema);
            this.properties_object = this.getWrapperObject(this.getModelForIcon(method), cellView.model.attributes);

            //var method = cellView.model.attributes.Method;
            // method.$type: "DevClient.SetItem, CompilerCore"
            //
            // var model = getMethodModel(method);
            // Do something - like send this to a modal dialog
        },
        cell_pointerup(cellView, evt, x, y) {
            if (this.changed) {
                // After movement, we rebuild all the objects (maybe we now have more options)
                //this.model.Objects = this.getFlowchartObjects();
                //care.logInfo('rebuilt model from movement');
            }

            this.changed = false;
        },
        cell_pointerdblclick(cellView, evt, x, y) {
            const method = cellView.model.attributes.Method;
            //const model = this.getMethodModel(method);
          
        },

        getWrapperSchema(schema) {
            const p = {
                type: 'object',
                properties: {
                    API: {
                        type: 'object',
                        properties: {
                            Id: {
                                type: 'string',
                                readonly: true,
                            },
                            Namespace: {
                                type: 'string',
                                readonly: true,
                                condition: 'ParentModel.Namespace'
                            },
                            Name: {
                                type: 'string',
                                readonly: true,
                            }
                        }
                    },
                    Arguments: schema,
                }
            };

            return p;
        },
        getWrapperObject(model, icon) {
            const o = {
                API: {
                    Id: icon.ID,
                    Namespace: icon.Method.Namespace,
                    Name: icon.Method.Name,
                },
                Arguments: model,
            };

            return o;
        },
        async getSchemaForCLRType(type) {
            switch (type) {
                case 'string':
                case 'System.String':
                    return {
                        type: 'string',
                    };
                case 'bool':
                case 'System.Boolean':
                    return {
                        type: 'boolean',
                    };
                case 'float':
                case 'double':
                case 'System.Float':
                case 'System.Double':
                    return {
                        type: 'number',
                    };
                case 'int':
                case 'long':
                case 'System.Int16':
                case 'System.Int32':
                case 'System.Int64':
                    return {
                        type: 'integer',
                    };
                case 'object':
                case 'CompilerCore.Evaluators.JsonObj':
                    return {
                        type: 'object',
                    };
                case 'DateTime':
                case 'System.DateTime':
                    return {
                        type: 'string',
                        format: 'date-time',
                    };

                default:
                    return await utils.api.get(`Apps/WebFlowHelpers/GetIconSchema?Type=${encodeURIComponent(type)}`);
            }
        },
        async getSchemaForUserType(ownerid, type, projectName) {
            return { type: 'object', properties: {} };
        },
        async saveSchemaFromMethod(item) {
            //if (item.Name == 'BridgeCalls')
            //    debugger;

            const schema = {
                $schema: `${location.protocol}//${location.hostname}/schema/public/v1/schema-draft04#`,
                id: `${location.protocol}//${location.hostname}/Apps/APISchema/${item.ImplementationKey}`,
                type: 'object',
                description: item.Description,
                properties: {},
            };

            for (let i = 0; i < item.Arguments.length; i++) {
                const a = item.Arguments[i];
                if (a.Hidden) continue;

                let arg = {
                    type: 'string',
                    title: `${a.Name}: ${a.ArgType}`
                };
                
                schema.properties[a.Name] = arg;

                if (a.DefaultValue)
                    arg.default = a.DefaultValue;
                if (a.Description)
                    arg.description = a.Description;

                if (a.Choices && a.Choices.length > 0) {
                    arg.enum = a.Choices;
                }
                else if (a.ArgType == 'bool')
                {
                    arg.titleMap = [{ name: 'true', value: 'true' }, { name: 'false', value: 'false' }];
                    arg.formatData = { AllowCustomInput: true };
                }
                else if (a.LookupAPI) {
                    arg.formatData = {};
                    arg.format = 'LookupList';
                    arg.formatData.LookupURL = a.LookupAPI;
                    arg.formatData.AllowCustomInput = true;
                    if (a.LookupAPIDisplayField) {
                        arg.formatData.DisplayType = 'Fieldname';
                        arg.formatData.DisplayField = a.LookupAPIDisplayField;
                    }
                    if (a.LookupAPIValueField) {
                        arg.formatData.ValueType = 'Fieldname';
                        arg.formatData.ValueField = a.LookupAPIValueField;
                    }
                }
            }

            this.internal_apis[item.Key] = schema;
        },
        getModelForIcon(item) {
            if (!item.Arguments)
                return item;

            // Use a Proxy to translate object format into array format. All
            // arguments are actually stored in an array with PropertyName and PropertyValue
            // instead of being a normal object. But for the property editor
            // to work right, we need the model to appear as a standard object.
            // We could have just built a temporary object based on the array, however,
            // it wouldn't allow writing changes back because it would write to the temp
            // object and not the original array. The Proxy writes back to the actual
            // array.
            return new Proxy(item.Arguments, arghandler);

            // NOTE: This does not work for dropdown lists - the property grid checks
            // to see if the model is an array, which returns TRUE even though the proxy
            // is pretending it is an object - which it should be treated as. so...
        },

        setupGraph(graph, paper, objects) {
            const c = this;
            this.graph = graph;
            this.paper = paper;

            paper.on('blank:pointerdown', this.blank_pointerdown);
            paper.on('cell:pointerdown', this.cell_pointerdown);
            paper.on('cell:pointerup', this.cell_pointerup);
            paper.on('cell:pointerdblclick', this.cell_pointerdblclick);

            graph.on('change:position change:size change:source change:target change:vertices add remove', function (cell) {
                c.changed = true;
            });

            this.loadFlowchart(graph, paper, objects);

		//	const rect = new this.$joint.shapes.standard.Rectangle();
		//	rect.position(100, 30);
		//	rect.resize(100, 40);
		//	rect.attr({
		//		body: {
		//			fill: 'blue'
		//		},
		//		label: {
		//			text: 'Hello',
		//			fill: 'white'
		//		}
		//	});
		//	rect.addTo(graph);
		//	const rect2 = rect.clone();
		//	rect2.translate(300, 0);
		//	rect2.attr('label/text', 'World!');
		//	rect2.addTo(graph);
		//	const link = new this.$joint.shapes.standard.Link();
		//	link.source(rect);
		//	link.target(rect2);
		//	link.addTo(graph);
		},
        loadFlowchart(graph, paper, items) {
            graph.clear();

            const item_ids = [];
            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                let label = item.Label || item.Text;
                if (label == 'DevClient.StartItem') label = 'Start()';

                // Keep an internal dictionary of all methods declared in this project
                // storing the inferred schema for each key. This is needed to allow
                // the property inspector the ability to show the right properties for
                // each internal method.
                if (item.Item && item.Item.ImplementationKey)
                    this.saveSchemaFromMethod(item.Item);

                let type = item.$type.split('.')[2].split(',')[0];
                switch (type) {
                    case 'PropertiesGraphicsMethod':
                        const m1 = new this.$joint.shapes.standard.Rectangle();
                        m1.position(item.Left, item.Top);
                        m1.resize(item.Right - item.Left, item.Bottom - item.Top)
                        m1.attr({ body: { fill: 'blue' }, label: { text: 'unused', fill: 'white' } });

                        label = item.Item.Name + '()';

                        const color = rgbToHex(item.ObjectColor.R, item.ObjectColor.G, item.ObjectColor.B);
                        m1.attr({
                            body: { fill: '#f2f2f2', rx: 5, ry: 5, strokeWidth: 0.5, stroke: '#' + color },
                            label: {
                                textWrap: { text: label, width: -10, height: 25 },
                                fill: '#' + color, //'#3498DB',
                                fontSize: 12,
                                fontWeight: 'normal'
                            }
                        });

                        m1.prop({ Method: item.Item, ID: item.ID });

                        m1.addTo(graph);

                        item_ids[item.ID] = { id: m1.id, obj: m1 };
                        break;

                    case 'PropertiesGraphicsText':
                        const rect = new this.$joint.shapes.standard.Rectangle();
                        rect.position(item.Left, item.Top);
                        rect.resize(item.Right - item.Left, item.Bottom - item.Top);
                        rect.attr({
                            body: { fillOpacity: 0, rx: 5, ry: 5, strokeWidth: 0, stroke: 'black' },
                            label: {
                                textWrap: { text: label, width: -15 }, // Was just text
                                fill: '#3498DB',
                                fontSize: 12,
                                fontWeight: 'normal'
                            }
                        });
                        rect.addTo(graph);
                        break;
                }
            }

            // Do the links
            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                if (!item.Links) continue;

                const graph_item = item_ids[item.ID].obj;

                for (let j = 0; j < item.Links.length; j++) {
                    const target = item_ids[item.Links[j].TargetID].obj;

                    const link = new this.$joint.shapes.standard.Link();
                    link.source(graph_item);
                    link.target(target);
                    link.attr('line/stroke', 'blue');
                    link.attr({
                        line: {
                            stroke: 'blue',
                            strokeWidth: 0.5,
                        //    targetMarker: {
                        //        fill: 'yellow',
                        //        d: 'M 10 0 L 0 5 L 10 10 z'
                        //    }
                        },
                    });
                    if (item.Links[j].Label)
                        link.appendLabel({
                            attrs: {
                                text: {
                                    fill: 'blue',
                                    text: item.Links[j].Label
                                },
                                body: {
                                    fill: 'white',
                                    strokeWidth: 0.5,
                                    stroke: 'silver',
                                }
                            },
                            position: {
                                distance: 0.5,
                                args: {
                                    keepGradient: true,
                                    ensureLegibility: true
                                }
                            }
                        });

                    //link.attr({
                    //    '.connection': { stroke: 'blue' },
                    //    '.marker-target': { fill: 'yellow', d: 'M 10 0 L 0 5 L 10 10 z' }
                    //});
                    //link.set('smooth', true);

                    link.prop({
                        Label: item.Links[j].Label,
                        SourceVar: item.Links[j].SourceVar,
                        Criteria: item.Links[j].Criteria
                    });
                    //link.router('orthogonal');
                    link.connector('rounded');
                    link.connector('jumpover', { size: 10 });

                    link.addTo(graph);
                }
            }
        },

        getFlowchartObjects() {
            const cells = this.graph.getCells();
            const objects = [];

            for (let i = 0; i < cells.length; i++) {
                let cell = cells[i];
                if (cell.attributes.Method) {
                    let item = {
                        $type: 'MethodLib.Serialization.PropertiesGraphicsMethod, CompilerCore',
                        Item: cell.attributes.Method,
                        Links: [],
                        ID: cell.attributes.ID,
                        Label: cell.attributes.attrs.text.text,
                        LineWidth: 0.5,
                        ObjectColor: {
                            R: 30, G: 144, B: 255, A: 255
                        },
                        Left: cell.attributes.position.x,
                        Top: cell.attributes.position.y,
                        Right: cell.attributes.position.x + cell.attributes.size.width,
                        Bottom: cell.attributes.position.y + cell.attributes.size.height,
                        Trace: false,
                        Break: 0
                    };
                    let links = getLinksForId(cell.attributes.id);
                    for (let j = 0; j < links.length; j++) {
                        let link = links[j];
                        let targetCell = this.graph.getCell(link.attributes.target.id);
                        item.Links.push({
                            Label: link.attributes.Label,
                            SourceVar: link.attributes.SourceVar,
                            Criteria: link.attributes.Criteria,
                            TargetID: targetCell.attributes.ID
                        })
                    }

                    // color is in cell.attributes.attrs.rect.fill or stroke
                    objects.push(item);
                }
            }
            return objects;
        },
    },
    props: {
    },
	render(h) {
		//if (!this.model)
		//	return null;

        const style = {
            ...this.sizeStyle,
            ...utils.resolveStyleHints(this.styleHints, this),
            display: 'flex',
            flexDirection: 'column',
            height: '1px', // needed by flex-grow
        };

        return (
            <div
                v-show={this.isvisible}
                style={style}
                class={{ 'default-theme': true, 'c-FlowChart': true, [`c-name-${this.name || 'unnamed'}`]: true }}>

                {this.topMenuBar}

                {this.topArea}

                <splitpanes
                    horizontal={false}
                    push-other-panes={true}
                    first-splitter={false}
                    style="height: 1px; flex-grow: 1;"
                >
                    <pane
                        style="display: flex; flex-direction: column;"
                        key="main"
                        size={75}
                        dbl-click-splitter={true}
                    >
                        {this.mainArea}
                    </pane>

                    <pane
                        key="right"
                        size={25}
                        dbl-click-splitter={true}
                    >
                        <splitpanes
                            horizontal={true}
                            push-other-panes={true}
                            first-splitter={false}
                        >
                            <pane key="right_top" size={50} style="overflow: auto;">
                                <div style={{ display: 'flex', flexDirection: 'column', flexGrow: '1', height: '100px' }}>
                                    {this.rightTopArea}
                                </div>
                            </pane>

                            <pane key="right_bottom" size={50} style="overflow: auto;">
                                <div style={{ display: 'flex', flexDirection: 'column', flexGrow: '1', height: '100px' }}>
                                    {this.rightBottomArea}
                                </div>
                            </pane>
                        </splitpanes>
                    </pane>
                </splitpanes>
            </div>
        );
    }
});