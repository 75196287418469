import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import EventBus from '../event-bus.js';

import utils from '../../Shared/utils.jsx';
import methods from '../../Shared/methods';

Vue.component('css-style', {
    mixins: [BaseComponent],
    data: function () {
        return {
        }
    },
    //Mounted Replaced with preRenderComplete
    computed: {
    },
    methods: {
        preRenderComplete() {
            this.finishRenderHandler(this);
        },
    },
    props: {
    },
    render(h) {
        return <style class={{ 'c-CssStyle': true, [`c-name-${this.name || 'unnamed'}`]: true }} v-show={this.isvisible}>{this.controlData.Style}</style>;
    }
});