import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';
//import combo from '../3rdParty/Combo.jsx';
//import combo from '../vuecontrols/combobox.jsx';
//import ResizeObserver from '../3rdParty/ResizeObserver.jsx';

Vue.component('form-boolean', {
    data: function () {
        return {
            asjson: false,
            uniquekey: null,
        }
    },
    created() {
        this.uniquekey = utils.generateUUID();
    },
    computed: {
        ...utils.forms.computed,
    },
    methods: {
        ...methods,
        //resize() {
        //    this.sel_height = this.$refs.app.clientHeight;
        //    this.sel_width = this.$refs.app.clientWidth;
        //    utils.log('boolean resize: sel_height: ' + this.sel_height + ', sel_width: ' + this.sel_width);
        //},
        sync(prop, value) {
            let v = value;
            if (!this.useexpressions)
                // Convert true and false to actual boolean values
                if (v == 'false') v = false; else if (v == 'true') v = true;

            if (!(prop in this.cmodel))
                Vue.set(this.cmodel, prop, v);
            else
                this.cmodel[prop] = v;
        },
        sync_json(text) {
            try {
                this.cmodel[this.schemakey] = JSON.parse(text);
            }
            catch (e) {
                utils.warn('boolean in propertygrid could not parse JSON', e);
            }
        },
    },
    props: {
        name: '',
        schemakey: '',
        root: null,
        schema: null,
        cmodel: null,
        readonly: false,
        child: 0,
        useexpressions: false,
        propertygrid: false,
    },
    render(h) {
        if (!this.Condition)
            return null;

        let desc;
        if (this.schema.description)
            desc = <i class="mdi mdi-information" title={this.schema.description} style={{ color: "silver", fontSize: "small", marginLeft: "3px" }}></i>;

        const name = <span style={{ marginLeft: (this.child * 15) + "px" }}>{this.name}</span>;
        let input;

        const jsonico = this.asjson ? <i class="mdi mdi-eye-off" title="JSON view"></i> : <i class="mdi mdi-eye" title="JSON view"></i>;
        const jsontgl = <span style={{ fontSize: "smaller", cursor: "pointer", color: "gray" }} on-click={() => this.asjson = !this.asjson}>{jsonico}</span>;

        if (this.asjson || typeof this.cmodel[this.schemakey] === 'string') {
            // Allows viewing/editing the raw JSON of the element model
            const rawjson = JSON.stringify(this.cmodel[this.schemakey], null, 4);
            return (
                <tr>
                    <td style={{ width: "1px", verticalAlign: "top", whiteSpace: "nowrap" }}>
                        {name} {jsontgl} {desc}
                    </td>

                    <td style={{ width: "100%", height: "100px" }}>
                        <div style={{ resize: "vertical", width: "97%", height: "100px", borderStyle: "solid", borderColor: "silver", borderWidth: "1px", padding: "5px", overflow: "auto", position: "relative" }}>
                            <ace-editor
                                editorId={'Editor_' + this.uniquekey}
                                lang="json"
                                on-change-content={(text) => this.sync_json(text)}
                                content={rawjson}>
                            </ace-editor>
                        </div>
                    </td>

                </tr>
            );
        }

        if (this.readonly)
            input = <input type="text" value={this.cmodel[this.schemakey]} readonly />;
        else if (this.useexpressions) {
            input = (
                <v-combobox
                    solo
                    dense
                    hide-details
                    return-object={false}
                    value={this.cmodel[this.schemakey]}
                    items={[{ text: 'true', value: true }, { text: 'false', value: false }]}
                    on-change={(value) => this.sync(this.schemakey, value)}>
                </v-combobox>
            );
        }
        else {
            input = (
                <v-select
                    solo
                    dense
                    hide-details
                    value={this.cmodel[this.schemakey]}
                    items={[{ text: 'true', value: true }, { text: 'false', value: false }]}
                    on-change={(value) => this.sync(this.schemakey, value)}>
                </v-select>
            );
        }

        return (
            <tr>
                <td style={{ width: "1px", verticalAlign: "top" }}>
                    <span style={{ whiteSpace: "nowrap" }}>{name} {jsontgl} {desc}</span>
                </td>
                <td class={{ "property-grid": this.propertygrid }}>
                    {input}
                </td>
            </tr>
        );
    }
});