import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';
import filters from '../../../Shared/filters';
import computed from '../../../Shared/computed';

const baseFormMixin = {
    data: () => ({
        readfunc: null,
        writefunc: null,
        saved_watches: [],
    }),
    props: {
        xxname: '',
        root: null,
        form: null,
        element: null,
        cmodel: null,
        modelkey: null,
        condensed: false,
        depth: 0,
        appearance: null,
        layouttype: null,
        type: null,
        array_index: null,
        noinput: null,
        labelText: null,
        hintText: null,
        autofocus: false,
        notitle: false,
        schema: null,
        scopeitems: null,
        controlscope: null,
        directives: null
    },
    created() {
        if (this.element && this.element.key) {
            this.readfunc = utils.forms.getModelElementReader(this.element.key, this.modelkey);
            this.writefunc = utils.forms.getModelElementWriter(this.element.key, this.modelkey);

            // Make another reader to return the parent
            if (this.element.key.length <= 1)
                this.readparentfunc = { func: (model) => model, code: '(model) => model' };
            else {
                // Trim off the last element to refer to the parent
                const keys = this.element.key.slice(0, this.element.key.length - 1);
                this.readparentfunc = utils.forms.getModelElementReader(keys, this.modelkey);
            }

            //utils.debug(` +++ +++ +++ Schema element ${this.name} created, govisible() to populate default data`);
            this.govisible();

            // Wire up any events on model values according to the schema definition.
            // Consideration: would this be a problem if the same field was in the form more than once?
            // Should we try and account for this? Seems difficult at best. We could setup all watches
            // at a higher level so we only wired up one per member. Something to consider (5/29/2021 DP)
            if (this.element.schema && this.element.schema.events) {
                for (let i = 0; i < this.element.schema.events.length; i++) {
                    const e = this.element.schema.events[i];
                    switch (e.EventType) {
                        case 'OnChange':
                            utils.debug(`Created ${this.name} with watch on change`);

                            const w = this.$watch(
                                function () {
                                    return this.itemvalue;
                                },
                                function (newvalue, old) {
                                    utils.debug(`* * * schema OnChange field: ${this.element.key.join('.')}: from ${old} to ${newvalue} firing actions * * *`);
                                    utils.executeAndCompileAllActions(
                                        e.Actions,
                                        {
                                            ...this.OnChangeData,
                                            FormModel: this.cmodel,
                                            ParentModel: this.ParentModel,
                                            OldValue: old,
                                            NewValue: newvalue,
                                            Value: newvalue,
                                        },
                                        this);
                                }
                            );
                            this.saved_watches.push({ $watcher: w, event: e });
                            break;
                    }
                }
            }

            

            // For schema validation (can't use the watch property, the items get evaluated before the create() method runs):
            if (this.element.schema) {
                const w = this.$watch(function () { return this.itemvalue; }, this.validateField);
                this.saved_watches.push({ $watcher: w });
            }
        }
        else
            this.readfunc = { func: (model) => model, code: '(model) => model' };
    },
    async mounted() {
        //for (let i = 0; i < this.saved_watches.length; i++) {
        //    const w = this.saved_watches[i];
        //    if (w.event && w.event.RunActionsInitially)
        //        await utils.executeAndCompileAllActions(w.event.Actions, {
        //            ...this.OnChangeData,
        //            FormModel: this.cmodel,
        //            ParentModel: this.ParentModel,
        //            OldValue: this.itemvalue,
        //            NewValue: this.itemvalue,
        //            Value: this.itemvalue,
        //        }, this);
        //}
    },
    beforeDestroy() {
        utils.debug(`beforeDestroy ${this.name} with ${this.saved_watches.length} watches`);

        //this.gohidden();
    },
    destroyed() {
        utils.debug(`Destroyed ${this.name} with ${this.saved_watches.length} watches`);

        // dispose of the watchers
        for (let i = 0; i < this.saved_watches; i++)
            this.saved_watches[i].$watcher;
    },
    computed: {
        ...computed,
        autofocus_id: function () {
            if (this.element) {
                if (!this.element.$uniqueId)
                    this.element.$uniqueId = utils.generateUUID();

                return this.element.$uniqueId;
            }
            else
                return undefined;
        },
        rules: function () {
            const r = [];

            if (this.element && this.element.schema)
                // Return a function that performs the schema validation on the current field value (v)
                r.push(v => {
                    const errors = utils.schema.performValidation(this.element.schema, v);

                    // There may be multiple errors, but the validation line below the field can only show one at a time, just return the first
                    return errors.length > 0 ? errors[0].error : true;
                });

            if (this.element && this.element.required)
                r.push(v => {
                    if (v === null || v === undefined || v === '')
                        return 'Required';
                    else
                        return true;
                });

            return r;
        },
        itemvalue: function () {
            try {
                if (!this.readfunc && this.element)
                    this.readfunc = utils.forms.getModelElementReader(this.element.key, this.modelkey);

                if (this.readfunc)
                    return this.readfunc.func(this.cmodel);
                else
                    return null;
            }
            catch (e) {
                utils.warn(`Failed to evaluate readfunc in ${this.name}: ${(this.readfunc ? this.readfunc.code : '-no code-')}`, e);
            }
        },
        name: function () {
            // Use the key array to derive a name
            let name = '';
            if (this.element && this.element.key && Array.isArray(this.element.key) && this.element.key.length > 0) {
                name = this.element.key[0];
                for (let i = 1; i < this.element.key.length; i++) {
                    if (this.element.key[i] != '[]')
                        name += '.';

                    name += this.element.key[i];
                }
            }
            return name;
        },
        Title: function () {
            if (this.element.title)
                return this.element.title;
            else if (this.element.key) {
                let title = this.element.key[this.element.key.length - 1];
                if (title == '[]' && this.element.key.length > 1)
                    title = this.element.key[this.element.key.length - 2];
                return title;
            }
            else
                return null;
        },
        FieldValue: function () {
            return this.itemvalue;
        },
        Value: function () {
            return this.itemvalue;
        },
        ArrayIndex: function () {
            let p = this;
            while (p && (!('array_index' in p) || (typeof p.array_index === 'undefined'))) {
                p = p.$parent;
            }

            if (p)
                return p.array_index;
            else
                return -1;
        },
        FormModel: function () {
            return this.cmodel;
        },
        ParentModel: function () {
            try {
                if (this.readparentfunc)
                {
                    //utils.forms.insureModelPath(this.cmodel, this.element, this.schema);
                    return this.readparentfunc.func(this.cmodel);
                }
                else
                    return null;
            }
            catch (e) {
                utils.warn(`Failed to evaluate ${this.name} readparentfunc: ${this.readparentfunc ? this.readparentfunc.code : 'N/A'}`, e);
                return null;
            }
        },
        OnChangeData: function () {
            return {};
        },
        OnRenderData: function () {
            return {};
        },
        IsVisible: function () {
            // This walks up the parent hierarchy checking the condition property to see if this field is visible.
            // This is mainly used by the OnChange event handler to insure we do not fire OnChange events if the
            // form field is hidden.
            let p = this;
            while (p) {
                if (('condition' in p && !p.condition) || ('todisplay' in p && !p.todisplay))
                    return false;

                p = p.$parent;
            }
            return true;
        },
    },
    methods: {
        ...methods,
        ...filters,
        async loadComplete() {
            for (let i = 0; i < this.saved_watches.length; i++) {
                const w = this.saved_watches[i];
                if (w.event && w.event.RunActionsInitially)
                    await utils.executeAndCompileAllActions(w.event.Actions, {
                        ...this.OnChangeData,
                        FormModel: this.cmodel,
                        ParentModel: this.ParentModel,
                        OldValue: this.itemvalue,
                        NewValue: this.itemvalue,
                        Value: this.itemvalue,
                    }, this);
            }

            if (this.element.schema && this.element.schema.watches) {
                for (let i = 0; i < this.element.schema.watches.length; i++) {
                    const watch = this.element.schema.watches[i];
                    if (watch.WatchedField) {
                        const func = utils.compileExpression(this, watch.WatchedField);

                        const w = this.$watch(
                            function () {
                                return utils.evaluate(func, this);
                            },
                            function (newvalue, old) {
                                utils.executeAndCompileAllActions(
                                    watch.Actions,
                                    {
                                        ...this.OnChangeData,
                                        FormModel: this.cmodel,
                                        ParentModel: this.ParentModel,
                                        OldValue: old,
                                        NewValue: newvalue,
                                        Value: newvalue,
                                        WatchedValue: newvalue,
                                        Schema: this.element.schema,
                                    },
                                    this);
                            },
                            {
                                immediate: watch.RunActionsInitially
                            }
                        );
                        this.saved_watches.push({ $watcher: w, watch: watch });
                    }
                }
            }

            if (this.element.schema && this.element.schema.events) {
                for (let i = 0; i < this.element.schema.events.length; i++) {
                    const e = this.element.schema.events[i];
                    switch (e.EventType) {
                        case 'OnRender':
                            utils.executeAndCompileAllActions(
                                e.Actions,
                                {
                                    ...this.OnRenderData,
                                    FormModel: this.cmodel,
                                    ParentModel: this.ParentModel,
                                },
                                this);
                            break;
                    }
                }
            }
        },
        validateField() {
            if (this.$refs.form)
                // This causes the rules to be evaluated (see rules method above)
                this.$refs.form.validate();
        },
        getElementName(element) {
            // Use the key array to derive a name
            let name = '';
            if (element.key && Array.isArray(element.key) && element.key.length > 0) {
                name = element.key[0];
                for (let i = 1; i < element.key.length; i++) {
                    if (element.key[i] != '[]')
                        name += '.';

                    name += element.key[i];
                }
            }
            return name;
        },
        sync(value) {
            try {
                //utils.debug(`+++ ${this.name} ${this.getElementName(this.element)} sync(${typeof value === 'object' ? JSON.stringify(value0) : value})`);
                this.writefunc.func(this.cmodel, value, Vue);
            }
            catch (e) {
                utils.warn('Failed to evaluate writefunc: ' + this.writefunc.code, e);
            }
        },

        resolveDefault(o) {
            if (typeof o.default === 'string' && (o.default.includes('{{' || o.default.includes('{*')))) {
                o.$$default = utils.compile(this, o.default);
            }
            return o.$$default ? utils.evaluate(o.$$default, this) : o.default;

        },
        govisible() {
            //if (this.element.schema) {
            //    const value = utils.schema.getDefaultModel(this.element.schema);

            //    utils.debug(` +++ Schema element ${this.name} going visible - setting field to ${JSON.stringify(value)}`);

            //    this.writefunc.func(this.cmodel, value, Vue);
            //}
            if (!this.ParentModel) {
                //utils.debug(` +++ Schema element ${this.name} has no ParentModel (undefined)`);
                return;
            }

            // Fill in default value if not already populated
            const last = this.element.key[this.element.key.length - 1];
            if (last != '[]' && !(last in this.ParentModel)) {
                //const oldvalue = this.readfunc.func(this.cmodel);

                if ('default' in this.element) {
                    //utils.debug(` +++ Schema element ${this.name} going visible - setting field ${last} to ${this.element.default}`);
                    
                    const value = this.resolveDefault(this.element);
                    this.writefunc.func(this.cmodel, value, Vue); // this.element.default, Vue);
                }
                else if (this.element.schema && ('default' in this.element.schema)) {
                    //utils.debug(` +++ Schema element ${this.name} going visible - setting field ${last} to ${this.element.schema.default}`);
                    const value = this.resolveDefault(this.element.schema);
                    this.writefunc.func(this.cmodel, value, Vue); // this.element.schema.default, Vue);
                }
                else if (this.element.schema && this.element.schema.type == 'object') {
                    //utils.debug(` +++ Schema element ${this.name} going visible - setting field ${last} to {}`);
                    this.writefunc.func(this.cmodel, {}, Vue);
                }
                else if (this.element.schema && this.element.schema.type == 'array') {
                    //utils.debug(` +++ Schema element ${this.name} going visible - setting field ${last} to []`);
                    this.writefunc.func(this.cmodel, [], Vue);
                }
                //else
                //    utils.debug(` +++ Schema element ${this.name} going visible - no default, doing nothing (type: ${this.element.schema ? this.element.schema.type : 'unknown'})`);
            }
            //else
            //    utils.debug(` +++ Schema element ${this.name} going visible, doing nothing (already set or is [] : ${last})`);
        },
        gohidden() {
            //this.writefunc.func(this.cmodel, null, Vue);

            // Fill in default value if not already populated
            const last = this.element.key[this.element.key.length - 1];
            if (last != '[]' && (last in this.ParentModel)) {
                //utils.debug(` +++ Schema element ${this.name} going hidden - removing ${last} from ParentModel`);
                Vue.delete(this.ParentModel, last);
            }
        },

        onBlur() {
            if (this.element?.OnBlurAction && this.element.OnBlurAction.length > 0) {
                utils.executeAndCompileAllActions(
                    this.element.OnBlurAction,
                    {
                        FormModel: this.cmodel,
                        ParentModel: this.ParentModel,
                        Schema: this.element.schema,
                    },
                    this);
            }
        },

        onFocus() {
            if (this.element?.OnFocusAction && this.element.OnFocusAction.length > 0) {
                utils.executeAndCompileAllActions(
                    this.element.OnFocusAction,
                    {
                        FormModel: this.cmodel,
                        ParentModel: this.ParentModel,
                        Schema: this.element.schema,
                    },
                    this);
            }
        }
    },
};

export default baseFormMixin;