import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import utils from '../../Shared/utils.jsx';

Vue.component('dynamic-control-container', {
    mixins: [BaseComponent],
    data: function () {
        return {
            isbase: false,
            controldataexpr: null,
            internalobjectId: utils.generateUUID(),

            actiondataexpr: null,
        }
    },
    created() {
        this.isbase = this.controlData.IsBase;

        if (this.controlData.Content) {
            this.controldataexpr = utils.compileObject(this, this.controlData.Content);
        }

        if (this.controlData.ActionData) {
            this.actiondataexpr = utils.compileObject(this, this.controlData.ActionData);
        }
    },
    destroyed() {
        if (this.watcher$)
            this.watcher$();
    },
    mounted() {
    },
    props: {
    },
    computed: {
        controldatavalue: function () {
            if (this.controldataexpr) {
                const obj = utils.evaluateObject(this.controldataexpr, this);

                if (obj && !obj.$objectId)
                    obj.$objectId = utils.generateUUID();

                return obj;
            }
            else
                return null;
        },
        paramData: function () {
            return this.actiondataexpr ? utils.evaluateObject(this.actiondataexpr, this) : null;
        },
        objectId: function () {
            return `${this.internalobjectId}_${this.controldatavalue?.$objectId}`;
        },
    },
    methods: {
        async preRenderComplete() {
            if (!this.todisplay || !this.controldatavalue)
                this.finishRenderHandler(this);
        },
    },
    render(h) {
        if (!this.todisplay || !this.controldatavalue)
            return null;

        let Tag = 'stack-vertical';
        if (!this.isvisible)
            Tag = 'hidden-container';

        return (
            <Tag
                key={this.objectId}
                type={this.controlData.ContainerType || 'VerticalStack'}
                on={{ 'finished-render': (reference) => this.finishRenderHandler(reference) }}
                class={{ 'c-DynamicControlContainer': true, [`c-name-${this.name || 'unnamed'}`]: true }}
                style={{ flexGrow: "1", flexShrink: "unset" }}
                asbase={this.isbase}
                name={this.controldatavalue.Name}
                root={this.root}
                parentType={this.parentType}
                controlData={this.controldatavalue}>
            </Tag>
        );
    }
});