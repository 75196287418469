import Vue from 'vue';
import BaseComponent from './baseFormMixin.jsx';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

import { appSettings } from '@/Shared/appSettings.js';

Vue.component('sform-undefined', {
    mixins: [BaseComponent],
    data: () => ({
    }),
    props: {
    },
    created() {
    },
    mounted() {
        this.loadComplete();
    },
    computed: {
    },
    methods: {
    },
    render(h) {
        // This is a catch-all for form controls that are failing to resolve
        let scopedSlots = {
            message: ({message}) => {
                return <translation-container context={this} value={message}></translation-container>
            }
        }

        let slots = [
            <translation-container slot="label" context={this} value={this.labelText}></translation-container>,
        ];

        let input =
            <v-text-field
                type="text"
                class="caption pa-0 ma-0"
                outlined single-line dense hide-details
                style={{ width: "100%" }}
                value={this.itemvalue}
                disabled
                readonly
                scopedSlots={scopedSlots}
                hint={this.hintText}
                persistent-hint={appSettings.DebugTranslationPrefixSetting}
            >{slots}</v-text-field>;

        if (this.appearance)
            input.componentOptions.propsData = { ...input.componentOptions.propsData, ...this.appearance };

        if (this.directives) {
            if (input.data.directives)
                input.data.directives = [...input.data.directives, ...this.directives];
            else
                input.data.directives = this.directives;
        }

        return input;
    }
});