import Vue from 'vue';
import utils from '../../Shared/utils.jsx';
import BaseStackMixin from './BaseStackMixin.jsx';

Vue.component('stack-horizontal', {
    mixins: [BaseStackMixin],
    data: function () {
        return {
        }
    },
    computed: {
        horizontalStackClasses() {
            let classes = [];
            if(this.verticalAlignChildren) {
                switch(this.verticalAlignChildren.toLowerCase()) {
                    case 'top':
                        classes.push('align-start');
                        break;
                    case 'center': {
                        classes.push('align-center');
                        break;
                    }
                    case 'bottom': {
                        classes.push('align-end');
                        break;
                    }
                }
            }

            if(this.horizontalAlignChildren) {
                switch(this.horizontalAlignChildren.toLowerCase()) {
                    case 'left':
                        classes.push('justify-start');
                        break;
                    case 'middle': {
                        classes.push('justify-center');
                        break;
                    }
                    case 'right': {
                        classes.push('justify-end');
                        break;
                    }
                    case 'spacearound': {
                        classes.push('justify-space-around');
                        break;
                    }
                    case 'spacebetween': {
                        classes.push('justify-space-between');
                        break;
                    }
                    case 'spaceevenly': {
                        classes.push('justify-space-evenly');
                        break;
                    }
                }
            }

            return classes
        },
    },
    async created() {
    },
    destroyed() {
    },
    async mounted() {
    },
    methods: {
    },
    props: {
    },
    render(h) {
        try {
            if (!this.isready || !this.prerender_complete || !this.todisplay)
                return null;
    
            if (!this.controlData.Controls)
                return <div></div>;
    
            let items = [];
            let items_id = '_0';
    
            let controlData = this.controlData;
    
            if (this.container_list.length > 0) {
                controlData = this.container_list[this.container_list.length - 1].ActionData.ControlData;
                items_id = `_${this.container_list.length}`;
            }
    
            this.generateItemsFromModel(h, items, controlData, items_id, 'HorizontalStack');
    
            let header = this.getHeader(h, controlData);
    
            if(header)
                header = 
                    <div style={{ display: "flex", flexDirection: "row", flexGrow: "0", flexShrink: "0", padding: "2px", paddingLeft: "8px" }}>
                        {header}
                    </div>;
    
            const breadcrumb = this.generateBreadCrumbs(h);
    
            const style = {
                overflow: this.parentType == "ContentDef" ? "hidden" : "auto",
                display: "flex",
                flexDirection: "column",
                ...this.sizeStyle,
                ...utils.getStyleHints(this.controlData.StyleHints),
            };
    
            const items_style = {
                overflow: this.parentType == "ContentDef" ? "hidden" : "auto",
                display: "flex",
                flexDirection: "row",
                flexGrow: 1,
                gap: this.padding ? this.padding + 'px' : ''
            };
    
            if (this.sizeOptions && this.sizeOptions.Width.Mode === 'Auto' && this.parentType === 'VerticalStack') {
                // In this unique case, we must use the opposite direction on the parent and embed another div
                // to force the 'auto' width -- otherwise, 
                items_style.flexDirection = 'column';
                delete items_style.gap;
                items = (
                    <div style={{ display: "flex", flexDirection: "row", flexGrow: "0", flexShrink: "0", gap: this.padding ? this.padding + 'px' : '' }}>
                        {items}
                    </div>
                );
            }
    
            return (
                <div
                    class={{ 'c-HorizontalStack': true, [`c-name-${this.name || 'unnamed'}`]: true }}
                    v-show={this.isvisible} style={style}>
                    {header}
                    {breadcrumb}
                    {this.feedbackItemsComputed}
                    <div style={items_style}
                        class={this.horizontalStackClasses}>
                        {items}
                    </div>
                </div>
            );
        }
        catch (e) {
            utils.error('HorizontalLayout Render failed', e);
            return <div>HorizontalLayout Failed to Render {e}</div>;
        }
    }
});