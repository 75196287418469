import Vue from 'vue';
import utils from '../../Shared/utils.jsx';
import BaseComponent from './BaseComponentMixin.jsx';
import careHelpfulFunctions from '../careHelpfulFunctions.jsx';
import EventBus from '../event-bus.js';

Vue.component('badge-handler', {
    mixins: [BaseComponent],
    data: function () {
        return {
            sourceraw: null,
            sourceurl: null,
            model: null,

            badges: 0,
            badgeEventKeys: {},
        }
    },
    async created() {
        if (this.controlData.SourceType === 'Raw' && this.controlData.SourceRaw) {
            this.sourceraw = utils.compileObject(this, this.controlData.SourceRaw);
        }
        else if (this.controlData.SourceType === 'Url' && this.controlData.SourceUrl) {
            this.sourceurl = utils.compile(this, this.controlData.SourceUrl);
        }

        EventBus.$on('Action-Badge', this.performBadgeAction);
    },
    destroyed() {
        EventBus.$off('Action-Badge', this.performBadgeAction);

        if (this.$watch_todisplay)
            this.$watch_todisplay();
    },
    //Mounted Replaced with preRenderComplete
    computed: {
        Menus: function () {
            return utils.document_cache.menus;
        },
        DocumentCache: function () {
            return utils.document_cache;
        },
        MenuData: function () {
            return this.model;
        },
        MenuContent: function () {
            return this.cached_content;
        },
    },
    methods: {
        async preRenderComplete() {
            if (this.todisplay)
                await this.Refresh();
            else {
                this.$watch_todisplay = this.$watch('todisplay', function (newval) { if (newval) this.Refresh(); });
            }
            this.finishRenderHandler(this);
        },
        getChildren: function (data, parent, outargs) {
            let path = parent.MenuItemData.Title;
            if (parent.MenuPath)
                path = parent.MenuPath + '::' + path;

            const list = data.filter(m => m.MenuPath == path).sort((a, b) => parseInt(a.Index) - parseInt(b.Index));

            const result = [];
            for (let i = 0; i < list.length; i++) {
                const item = list[i];
                const child = {
                    id: `${outargs.index++}`,
                    name: item.MenuItemData.Title,
                    icon: item.MenuItemData.Icon,
                    item_data: item,
                }
                outargs.content.push(child);
                const children = utils.document_cache.getChildren(data, item, outargs);
                if (children && children.length > 0)
                    child.children = children;

                result.push(child);
            }

            return result;
        },
        getMenuTree: function (data) {
            const result = [];
            const content = [];
            const outargs = { index: 0, content: content };

            let root = data.filter(m => !m.MenuPath).sort((a, b) => parseInt(a.Index) - parseInt(b.Index));
            for (let i = 0; i < root.length; i++) {
                const node = root[i];
                const child = {
                    id: `${outargs.index++}`,
                    name: node.MenuItemData.Title,
                    icon: node.MenuItemData.Icon,
                    item_data: node,
                };
                outargs.content.push(child);
                const children = this.getChildren(data, node, outargs);
                if (children && children.length > 0)
                    child.children = children;

                result.push(child);
            }
            this.cached_result = result;
            this.cached_content = content;
            return result;
        },
        async Refresh() {
            let model;
            if (this.sourceraw)
                try {
                    model = utils.evaluateObject(this.sourceraw, this);
                }
                catch (e) {
                    utils.warn('DynamicTab failed to evaluate SourceRaw ' + this.controlData.SourceRaw + ': ' + e);
                }
            else if (this.sourceurl)
                try {
                    model = await utils.api.get(utils.evaluate(this.sourceurl, this));
                }
                catch (e) {
                    utils.warn('DynamicTab failed to evaluate SourceUrl ' + this.controlData.SourceUrl + ': ' + e);
                }

            if (model && this.controlData.SourceAsMenuItems) {
                // Convert the source model into a tree (it is being provided as an array of MenuItems)
                for (let i = 0; i < model.length; i++) {
                    const m = model[i];
                    if (m.ChildMenus)
                        m.ChildMenus = this.getMenuTree(m.ChildMenus);
                }
                model = model.slice().sort((a, b) => {
                    let indexa;
                    if (typeof a.Index === 'string')
                        indexa = parseInt(a.Index);
                    else
                        indexa = a.Index;

                    let indexb;
                    if (typeof b.Index === 'string')
                        indexb = parseInt(b.Index);
                    else
                        indexb = b.Index;

                    return indexa - indexb;
                });
            }

            this.model = model;
            if (model)
                this.resolveBadges();
        },
        resolveBadges() {
            for (let i = 0; i < this.model.length; i++) {
                const m = this.model[i];
                this.resolveChildBadges(m, m.ChildMenus);
            }
        },
        resolveChildBadges(menu, children, applytoparent) {
            if (menu.MenuItemData && menu.MenuItemData.Badge) {
                const badgeinfo = menu.MenuItemData.Badge;

                if (!menu.MenuItemData.badgecontent && badgeinfo.CountURL && (badgeinfo.LookupType == 'URL' || !badgeinfo.LookupType)) {
                    Vue.set(menu.MenuItemData, 'badgecontent', { $objectId: utils.generateUUID() });
                    this.lookupBadge(badgeinfo.CountURL, badgeinfo.ToolTip, menu.MenuItemData.badgecontent, applytoparent);
                }
                else if (!menu.MenuItemData.badgecontent && badgeinfo.LookupType == 'Interpolated' && badgeinfo.InitialCount) {
                    Vue.set(menu.MenuItemData, 'badgecontent', { $objectId: utils.generateUUID() });
                    this.interpolateBadge(badgeinfo, menu.MenuItemData.badgecontent, applytoparent);
                }

                if (badgeinfo.BadgeEventKey) {
                    if (!menu.MenuItemData.badgecontent)
                        Vue.set(menu.MenuItemData, 'badgecontent', { $objectId: utils.generateUUID() });

                    this.badgeEventKeys[badgeinfo.BadgeEventKey] = {
                        menu: menu,
                        applytoparent: applytoparent,
                    };
                }
            }

            if (children)
                for (let j = 0; j < children.length; j++) {
                    const c = children[j];
                    this.resolveChildBadges(c.item_data, c.children, (item) => {
                        if (!menu.MenuItemData.badgecontent)
                            Vue.set(menu.MenuItemData, 'badgecontent', { $objectId: utils.generateUUID() });

                        // Save the value associated with the child badge, then add all children to the total for the current node
                        menu.MenuItemData.badgecontent[`$child_${item.$objectId}`] = item.count || 0;
                        menu.MenuItemData.badgecontent[`$childtt_${item.$objectId}`] = item.tooltip || [];

                        this.combineBadges(menu.MenuItemData.badgecontent, applytoparent);
                    });
                }
        },
        combineBadges(badgecontent, applytoparent) {
            // Total all children
            let all = Object.keys(badgecontent).filter(k => k.substr(0, 7) == '$child_').map(a => badgecontent[a]);
            let total = (all && all.length > 0) ? all.reduce((a, c) => a + c) : 0;
            total = (total || 0) + (badgecontent.input || 0);

            // Combine all child tooltips
            let alltt = Object.keys(badgecontent).filter(k => k.substr(0, 9) == '$childtt_').map(a => badgecontent[a]);
            let tooltips = (alltt && alltt.length > 0) ? alltt.reduce((a, c) => [...a, ...c]) : [];

            if (badgecontent.input_tooltip && badgecontent.input > 0)
                tooltips.push(badgecontent.input_tooltip);

            Vue.set(badgecontent, 'count', total);
            Vue.set(badgecontent, 'tooltip', tooltips);
            Vue.set(badgecontent, 'showbadge', total > 0 || badgecontent.showbadge || false);

            if (applytoparent)
                applytoparent(badgecontent);
        },

        interpolateBadge(badgeinfo, item, applytoparent) {
            const expn = utils.compile(this, badgeinfo.InitialCount);
            const tooltipexpr = utils.compile(this, badgeinfo.ToolTip, false, 'Input');

            let count = utils.evaluate(expn, this);
            if (typeof count === 'string')
                count = parseInt(count);

            const tooltip = utils.evaluate(tooltipexpr, this, false, null, false, { Count: count });

            Vue.set(item, 'input', count);
            Vue.set(item, 'input_tooltip', tooltip);

            this.combineBadges(item, applytoparent);
        },
        async lookupBadge(url, tooltip, item, applytoparent) {
            if (url) {
                try {

                    const promise = utils.api.get(url);
                    const tooltipexpr = utils.compile(this, tooltip, false, 'Input');
                    const res = await promise;
                    const input = {
                        Count: (typeof res === 'object' && res && 'Result' in res) ? res.Result : res,
                    };

                    this.badges++;
                    utils.debug(`lookupBadge(${url}) : ${input.Count}`);

                    if (input.Count > 0) {
                        const value = utils.evaluate(tooltipexpr, this, false, null, false, input);
                        Vue.set(item, 'input', input.Count);
                        Vue.set(item, 'input_tooltip', value);
                    }
                    else {
                        Vue.set(item, 'input', 0);
                        Vue.set(item, 'input_tooltip', null);
                    }

                    this.combineBadges(item, applytoparent);
                } catch (err) {
                    utils.error('BadgeHandler lookupBadge',err);
                }
            }
        },

        async performBadgeAction(action) {
            utils.log(`BadgeHandler Badge ${action.ActionData.Key} ${action.ActionData.Type} ${action.ActionData.Value}`);

            if (action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            try {
                const type = utils.evaluate(action.type, action.context);
                const key = utils.evaluate(action.key, action.context);

                const badge = this.badgeEventKeys[key];
                if (badge) {
                    const badgeinfo = badge.menu.MenuItemData.Badge;
                    switch (type) {
                        case 'Refresh':
                            if (badgeinfo && badgeinfo.CountURL && (badgeinfo.LookupType == 'URL' || !badgeinfo.LookupType))
                                await this.lookupBadge(badgeinfo.CountURL, badgeinfo.ToolTip, badge.menu.MenuItemData.badgecontent, badge.applytoparent);
                            else if (badgeinfo && badgeinfo.LookupType == 'Interpolated' && badgeinfo.InitialCount)
                                this.interpolateBadge(badgeinfo, badge.menu.MenuItemData.badgecontent, badge.applytoparent);
                            break;

                        case 'Value':
                            let value = utils.evaluate(action.value, action.context);
                            if (typeof value === 'string')
                                value = parseInt(value);

                            if (badge.menu.MenuItemData.badgecontent) {
                                const tooltipexpr = utils.compile(this, badgeinfo.ToolTip, false, 'Input');
                                const tooltip = utils.evaluate(tooltipexpr, this, false, null, false, { Count: value });

                                badge.menu.MenuItemData.badgecontent.input = value;
                                badge.menu.MenuItemData.badgecontent.input_tooltip = tooltip;

                                this.combineBadges(badge.menu.MenuItemData.badgecontent, badge.applytoparent);
                            }
                            break;
                    }

                    try {
                        await utils.success(action);
                    } catch (e) { }
                }
                else
                    try {
                        await utils.failure(action, { Error: `Key '${key}' was not found` });
                    } catch (e) { }
            }
            catch (e) {
                utils.error(`BadgeHandler Badge ${action.ActionData.Key} ${action.ActionData.Type} ${action.ActionData.Value} failed`, e);

                try {
                    await utils.failure(action, { Error: e.toString() });
                } catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
    },
    props: {
    },
    render(h) {
        return null; // <div>BadgeHandler {this.badges} badge(s) resolved</div>;
    }
});