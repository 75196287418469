import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import utils from '../../Shared/utils.jsx';

import vueCheetahGrid from 'vue-cheetah-grid'
Vue.use(vueCheetahGrid)

//const materialDesignTheme = vueCheetahGrid.cheetahGrid.themes.MATERIAL_DESIGN;

Vue.component('cc-cheetah-grid', {
    mixins: [BaseComponent],
    components: {
    },
    data: () => ({
        datatable_id: null,

        modelfunc: null,
        modelurl: null,
        modelvalue: null,

        loading: false,

        userTheme: {
            color: '#2c3e50',
            //frozenRowsColor: '#2c3e50',
            frozenRowsBgColor: '#e8e8e8',
            //borderColor: '#35495e',
            //frozenRowsBorderColor: '#35495e',
            //checkbox: {
            //    checkBgColor: '#35495e',
            //    borderColor: '#35495e',
            //},
            defaultBgColor({ row, grid }) {
                // change the color of the even rows.
                if (row < grid.frozenRowCount) {
                    return null;
                }
                return row % 2 === 0 ? '#f8f8f8' : 'white';
            },
        },
    }),
    created() {
        this.datatable_id = `data-table-${utils.generateUUID()}`;
        this.itemsPerPage = this.controlData.ItemsPerPage || 30;

        if ((this.controlData.DataType === 'URL' || this.controlData.DataType === undefined) && this.controlData.DataURL) {
            this.modelurl = utils.compile(this, this.controlData.DataURL);
        }
        else if (this.controlData.DataType === 'Raw' && this.controlData.Data) {
            this.modelvalue = utils.compileObject(this, this.controlData.Data);
        }
    },
    computed: {
        columnDefs: function () {
            const h = this.$createElement;
            const cols = [];

            if (this.controlData.SelectOptions.Enable && this.controlData.SelectOptions.MultiSelect)
                cols.push(
                    <c-grid-check-column
                        field="check"
                        header-type="check"
                        header-action="check"
                        width="50" />
                );

            for (let i = 0; i < this.controlData.ColumnDefs.length; i++) {
                const hdr = this.controlData.ColumnDefs[i];
                if (!hdr.Visible)
                    continue;

                switch (hdr.Type) {
                    case 'Value':
                        let field = hdr.Field;
                        let filter;

                        if (!hdr.field_extrator) {
                            hdr.field_extrator = new Function('f', `return f.${hdr.Field};`);
                        }

                        if (hdr.CellFilter && !hdr.cellfilter_expn)
                            hdr.cellfilter_expn = utils.compile(this, hdr.CellFilter, false, hdr.ValueModelName || 'value');

                        if (hdr.cellfilter_expn)
                            filter = (v) => utils.evaluate(hdr.cellfilter_expn, this, false, null, false, v);

                        if (hdr.Condition && !hdr.condition_expn) {
                            hdr.condition_expn = utils.compileExpression(this, hdr.Condition, hdr.RowModelName || 'row');
                        }

                        if (hdr.Value && !hdr.value_expn)
                            hdr.value_expn = utils.compile(this, hdr.Value, false, hdr.RowModelName || 'row');

                        if (hdr.value_expn && hdr.condition_expn)
                            field = (v) => utils.evaluate(hdr.condition_expn, this, false, null, false, v) ? utils.evaluate(hdr.value_expn, this, false, null, false, v) : null;
                        else if (hdr.value_expn)
                            field = (v) => utils.evaluate(hdr.value_expn, this, false, null, false, v);
                        else if (hdr.condition_expn)
                            field = (v) => utils.evaluate(hdr.condition_expn, this, false, null, false, v) ? hdr.field_extrator(v) : null;

                        const sort_expn = true; /* (v,x,y,z) => {
                            utils.debug(`Sort column ${x} order ${v}`);
                            return v;
                        }; */

                        cols.push(
                            <c-grid-input-column
                                field={field}
                                filter={filter}
                                width={hdr.Width}
                                sort={sort_expn}
                            >
                                {hdr.DisplayName || hdr.Name || hdr.Field}
                            </c-grid-input-column>
                        );
                        break;

                    case 'Icons':
                        cols.push(
                            <c-grid-icon-column
                                width={hdr.Width}
                                column-style={{ color: 'red' }}
                                icon-name="star_border"
                                field={() => 2}

                            >
                            </c-grid-icon-column>
                        );
                        break;
                }
                
            }

            return cols;
        },
        dataSource: function () {
            return this.modelfunc ? this.modelfunc() : [];
        },
        modelurlvalue: function () {
            if (this.modelurl)
                return utils.evaluate(this.modelurl, this);
            else
                return '';
        },
        modelrawvalue: function () {
            if (this.modelvalue)
                return utils.evaluateObject(this.modelvalue, this);
            else
                return [];
        },
    },
    //Mounted Replaced with preRenderComplete
    methods: {
        async preRenderComplete() {
            this.finishRenderHandler(this);
            await this.Refresh();

            //const self = this;
            //self.updateTable();
            //window.onresize = function (event) {
            //    self.updateTable();
            //};
        },
        async Refresh(clearSelectedRows) {
            if (clearSelectedRows) {
            }

            this.loading = true;
            try {
                let model;
                if (this.controlData.DataType == 'URL' && this.modelurl) {
                    model = this.modelurlvalue ? await utils.api.get(this.modelurlvalue, false, false) : [];

                    if (!this.modelurl_watch$)
                        this.modelurl_watch$ = this.$watch(
                            function (newvalue) {
                                return this.modelurlvalue;
                            },
                            function (val, oldval) {
                                this.Refresh();
                            }
                        );
                }
                else if (this.controlData.DataType == 'Raw' && this.modelvalue)
                    try {
                        model = this.modelrawvalue;

                        if (!this.modelvalue_watch$)
                            this.modelvalue_watch$ = this.$watch(
                                function () {
                                    try {
                                        return this.modelrawvalue;
                                    }
                                    catch (e) {
                                        utils.warn('SlimGrid modelvalue ' + this.controlData.Model.Definition + ' failed to evaluate: ' + e);
                                        return null;
                                    }
                                },
                                function (val, oldval) {
                                    this.Refresh();
                                },
                                {
                                    deep: false
                                }
                            );
                    }
                    catch (e) {
                        utils.warn('BasicGrid modelvalue ' + this.controlData.Data + ' failed to evaluate: ' + e);
                        model = [];
                    }
                else
                    model = [];

                this.modelfunc = () => model;
            }
            finally {
                this.loading = false;
            }
        },
        rowCommandClick(e, data, btn) {
            e.cancelBubble = true;
            e.preventDefault();
            utils.executeAndCompileAllActions(btn.Actions, { RowIndex: data.rowIndex, Data: data.row.data }, this);
        },
        clickIcon(e, hdr, ico, item) {
            e.cancelBubble = true;
            e.preventDefault();
            utils.executeAndCompileAllActions(ico.Actions, { Data: item }, this);
        },

        updateTable() {
            const tableHeight = document.getElementById(this.datatable_id).offsetHeight - 54 - 58;
            this.itemsPerPage = parseInt(tableHeight / 32);
            utils.debug(`** SlimGrid: tableHeight: ${tableHeight}, itemsPerPage:${this.itemsPerPage}`);

            if (this.dataSource.length < this.itemsPerPage)
                this.itemsPerPage = this.dataSource.length;
            
            if (this.itemsPerPage > 1)
                this.itemsPerPage--;

            if (!this.footerProps.itemsPerPageOptions.includes(this.itemsPerPage)) {
                if (this.footerProps.itemsPerPageOptions.length == this.optionsLength) {
                    this.footerProps.itemsPerPageOptions.unshift(this.itemsPerPage);
                } else {
                    this.footerProps.itemsPerPageOptions.shift();
                    this.footerProps.itemsPerPageOptions.unshift(this.itemsPerPage);
                }
            }
        },
    },
    props: {},
    render() {
        if (!this.todisplay)
            return null;

        const style = {
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            padding: '8px',
            ...this.sizeStyle,
        };

        return (
            <c-grid
                id={this.datatable_id}
                class={{ 'c-CheetahGrid': true, [`c-name-${this.name || 'unnamed'}`]: true }}
                style={style}
                data={this.dataSource}
                frozen-col-count={1}
                theme={this.userTheme}
                default-row-height={30}
            >
                {this.columnDefs}
            </c-grid>
        );
    }
});