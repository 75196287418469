import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

Vue.component('basic-watcher-dsgn', {
    data: function () {
        return {
        }
    },
    created() {
    },
    computed: {
        Root: function () {
            return this.root._self;
        },
        selected$: function () {
            return this.Root.SelectedNode && this.Root.SelectedModel == this.designmodel;
        },
    },
    methods: {
        ...methods,
        handleClick(e) {
            this.Root.SelectNode(this);
            e.cancelBubble = true;
            e.stopPropagation();
        },
    },
    props: {
        name: '',
        type: '',
        root: null,
        designmodel: null,
        parentType: '',
        controlData: {}
    },
    render(h) {
        let name;
        if (!this.name)
            name = this.type;
        else
            name = this.name + ':' + 'Watcher';

        return (
            <div
                title={name}
                style={{ backgroundColor: "lightblue", color: "maroon" }}
                class={{ 'designer-container': true, selected: this.selected$ }}
                on-click={(e) => this.handleClick(e)}>
                <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
                <i class="mdi mdi-eye"></i>
            </div>
        );
    }
});