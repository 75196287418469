import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import RoosterDropdownMenu from '../vuecontrols/RoosterDropdownMenu';
import utils from '@/Shared/utils';
import api from '@/Services/api';
import {getPositionRect} from 'roosterjs-editor-dom';

class PickerDataProvider {
    editor; //Editor
    mountPoint; //html element
    pickerOptions;
    insertNodeCallback;
    dropdownElement;
    showingDropdown;

    constructor() {
        this.showingDropdown = false;
    }

    /**
     * Function called when the plugin is intialized to register two callbacks with the data provider and a reference to the Editor.
     * The first is called in order to "commit" a new element to the editor body that isn't handled automatically by the editor plugin.
     * The second sets the isSuggesting value for situations wherethe UX needs to manipulate the suggesting state that's otherwise plugin managed.
     */
    async onInitalize(insertNodeCallback, setIsSuggestingCallback, editor) {
        this.editor = editor;
        this.insertNodeCallback = insertNodeCallback;

        await this.updateToQuery('');

        const document = this.editor.getDocument();
        this.mountPoint = document.createElement('section');
        this.mountPoint.style.position = 'fixed';
        document.body.appendChild(this.mountPoint);
    }

    /**
     * Function called when the picker changes suggesting state. (e.g. when your dialog opens)
     */
    onIsSuggestingChanged(isSuggesting) {
        if (isSuggesting) {
            if (this.mountPoint.childElementCount == 0) {
                this.createDropdown();
            }
        } else {
            this.deleteDropdown();
        }
    }

    onDispose() {
        this.deleteDropdown();
        this.editor = null;
        this.mountPoint = null;
        this.pickerOptions = null;
        this.insertNodeCallback = null;
        this.dropdownElement = null;
        this.showingDropdown = false;
    }

    createDropdown() {
        //Have to use getPosisiontRect from old rooster library because v9 doesn't have this ported over yet
        const rect = getPositionRect(this.editor.getFocusedPosition());

        var DropdownClass = Vue.extend(RoosterDropdownMenu);
        this.dropdownElement = new DropdownClass({
            propsData: {
                showActivator: false,
                showMenu: true,
                options: this.pickerOptions,
                maxHeight: "200",
                positionX: rect.left,
                positionY: rect.bottom
            },
            vuetify: vuetify.instance
        });

        this.dropdownElement.$mount();

        this.dropdownElement.$on('input', (input) => this.pluginPickerSelect(input));

        this.mountPoint.appendChild(this.dropdownElement.$el);

        this.showingDropdown = true;

        this.dropdownElement.setupSelection();
    }

    deleteDropdown() {
        if (this.dropdownElement && this.dropdownElement.$el && this.dropdownElement.$el.parentNode)
        {
            // remove the element from the DOM
            this.dropdownElement.$el.parentNode.removeChild(this.dropdownElement.$el);

            this.dropdownElement.$destroy();
        }
        this.showingDropdown = false;
        if(this.mountPoint)
            this.mountPoint.innerHTML = "";
    }

    refreshDropdown() {
        this.deleteDropdown();
        this.createDropdown();
    }

    /**
     * Function called when the query string (text after the trigger symbol) is updated.
     * called everytime the query string is updated
     */
    queryStringUpdated(queryString) {
        this.updateToQuery(queryString);
        this.refreshDropdown();
    }
    /**
     * Function called when a keypress is issued that would "select" a currently highlighted option.
     * is called when you select tab or enter while creating the querystring
     */
    selectOption() {
        this.dropdownElement.selectCurrent();
    }
    /**
     * Function called when a keypress is issued that would move the highlight on any picker UX.
     */
    shiftHighlight() {
        this.dropdownElement.setupSelection();
    }
    async updateToQuery(queryString) {
        if (utils.global_variables.RoosterCache && utils.global_variables.RoosterCache['Apps/QuickText/GetQuickTextList']) {
            this.pickerOptions = this.filterRoosterCache(queryString.toLowerCase());
        }
        else {
            try {
                let res = await api.get('Apps/QuickText/GetQuickTextList');
                Vue.set(utils.global_variables, 'RoosterCache', {});
                Vue.set(utils.global_variables.RoosterCache, 'Apps/QuickText/GetQuickTextList', res);
                this.pickerOptions = this.filterRoosterCache(queryString.toLowerCase());
            }
            catch(e) {
                utils.warn('Failed to get rooster quick text list', e);
            }
        }
        this.editor.focus();
    }

    filterRoosterCache(queryString) {
        return queryString.split(" ")
            .reduce((acc, curr) =>
                acc.filter(doc => doc.Title.toLowerCase().includes(curr.toLowerCase()) || (doc.Tags && doc.Tags.some(t => t.toLowerCase().includes(curr.toLowerCase())))
                ), utils.global_variables.RoosterCache['Apps/QuickText/GetQuickTextList'])
            .map(document => {
                return {
                    "name": document.Title,
                    "value": document.Id
                }
            })
            .sort((a, b) => (a.name > b.name) ? 1 : -1);

    }

    
    pluginPickerSelect(selectedItem) {
        if (selectedItem) {
            let quickText = utils.global_variables.RoosterCache['Apps/QuickText/GetQuickTextList'].find(item => item.Id === selectedItem);
            const span = this.editor.getDocument().createElement('span');
            span.innerHTML = quickText.Text.RichText;
            this.insertNodeCallback(span);
        } 
    }
}

export default PickerDataProvider;