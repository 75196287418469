import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import utils from '../../../Shared/utils.jsx';

Vue.component('apex-general-chart-dsgn', {
    mixins: [BaseComponent],
    components: {
    },
    data: function () {
        return {
            datamodel: null,
            optionsmodel: null,
        }
    },
    created() {
    },
    //Created Replaced with preRenderComplete
    computed: {
        style() {
            return {
                ...utils.resolveStyleHints(this.styleHints, this),
                ...utils.getSize(this.controlData.SizeOptions, this.parentType, this.$parent),
                display: 'flex',
                flexDirection: 'column',
                overflow: "none"
            };
        },

        chartType: function () {
            // bar, line, etc.
            return this.controlData.ChartType;
        },
        chartData: function () {
            switch (this.controlData.DataType) {
                case 'Raw':
                case 'RawInterpolated':
                    return JSON.parse(JSON.stringify(this.controlData.DataRaw));

                case 'URL':
                    return this.datamodel;

                default:
                    return null;
            }
        },
        chartOptions: function () {
            switch (this.controlData.OptionsType) {
                case 'Raw':
                case 'RawInterpolated':
                    return JSON.parse(JSON.stringify(this.controlData.OptionsRaw));

                case 'URL':
                    return this.optionsmodel;

                default:
                    return null;
            }
        },
    },
    methods: {
        async preRenderComplete() {
            this.finishRenderHandler(this);
            this.Refresh();
        },
        async Refresh() {
            if (this.controlData.OptionsType === 'URL' && !this.controlData.OptionsURL.includes('{')) {
                this.optionsmodel = await utils.api.get(this.controlData.OptionsURL);
            }
            else
                this.optionsmodel = {};

            if (this.controlData.DataType === 'URL' && !this.controlData.DataURL.includes('{')) {
                this.datamodel = await utils.api.get(this.controlData.DataURL);
            }
            else
                this.datamodel = [];
        },
    },
    render(h) {
        return (
            <div>
                <span
                    class={{ 'designer-container': true, selected: this.selected$ }}
                    style={this.style}
                    on-click={(e) => this.handleClick(e)}
                    >
                    <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
                    <apexchart
                        type={this.chartType}
                        height="100%"
                        width="100%"
                        series={this.chartData}
                        options={this.chartOptions}>

                    </apexchart>
                </span>
            </div>
        );
    }
});
