import Vue from 'vue';
import BaseComponent from './baseFormMixin.jsx';
import utils from '../../../Shared/utils.jsx';
import RoosterEditorBase from '../vuecontrols/RoosterEditorBase.vue';
import Editor from '@tinymce/tinymce-vue';

Vue.component('sform-htmleditor', {
    mixins: [BaseComponent],
    components: {
        RoosterEditorBase
    },
    data: () => ({
    }),
    props: {
    },
    created() {
    },
    mounted() {
        this.loadComplete();
    },
    computed: {
        readOnly() {
            try {
                if(this.readOnlyEval === null && this.element.readonly)
                    this.readOnlyEval = utils.compile(this, this.element.readonly);
                if(this.readOnlyEval)
                    return utils.evaluate(this.readOnlyEval, this);
                return false;
            }
            catch (e) {
                utils.warn('ReadOnly could not evaluate expression: ' + this.element.readonly + '; ' + e);
                return false;
            }
        },
    },
    methods: {
        async onChange(value) {
            this.sync(value);
        },
    },
    render() {
        const classes = {
            'mb-3': true,
        };

        let padding;
        if (this.hasfocus)
            padding = "14px 5px 20px 5px";
        else
            padding = "14px 5px 5px 5px";

        let input = // <v-text-field class="caption pa-0 ma-0" outlined single-line dense hide-details style={{ width: "100%" }} value={this.itemvalue} on-input={(value) => this.sync(value)}></v-text-field>;
            <div class={classes} style={{ resize: "vertical", overflow: "hidden", width: "100%", height: this.height, borderStyle: "solid", borderColor: "silver", borderWidth: "1px", borderRadius: "5px", padding: padding, position: "relative" }}>
                <label class="v-input v-label" style="left: 5px; right: auto; position: absolute; transform: translateY(-9px) scale(0.75); top: 10px; transform-origin: top left; color: rgba(0,0,0,0.6);">{this.Title}</label>
                <RoosterEditorBase on-input={this.onChange} initialContent={this.itemvalue} minHeight="200px" readonly={this.readOnly}/>
                <div v-show={this.hasfocus} style="position: absolute; bottom: 0; color: rgba(0,0,0,0.6); font-size: smaller; white-space: nowrap; overflow: hidden;">
                    {this.element.description}
                </div>
            </div>;

        return input;
    }
});