import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

Vue.component('table-cell-dsgn', {
    data: () => ({

    }),
    props: {
        root: null,
        schema: null,
        model: null,
        columnDef: null,
        selected$: false,
        firstrow: false,
        addControl: null,
    },
    computed: {
        Root: function () {
            return this.root._self;
        },
        selectedmodel$: function () {
            return this.Root.SelectedModel && this.Root.SelectedModel == this.columnDef;
        },
    },
    methods: {
        addContent(e, content) {
            this.addControl(content);
            e.cancelBubble = true;
            e.stopPropagation();
        },
        addContentDef(e) {
            if (!this.columnDef.ContentDefs)
                Vue.set(this.columnDef, 'ContentDefs', []);

            this.columnDef.ContentDefs.push({
                $typeSchema: '/schema/public/Platform.Schema.DynamicControls.v1/DynamicControl_Grid_ContentDef',
                $objectId: utils.generateUUID(),
                Controls: [],
            });
            e.cancelBubble = true;
            e.stopPropagation();
        }
    },
    render() {
        const cdef = this.columnDef;
        if (!cdef) return null;

        try {
            if (cdef.ContentDefs && cdef.ContentDefs.length >= 0) {
                const elements = [];
                for (let i = 0; i < cdef.ContentDefs.length; i++) {
                    const content = cdef.ContentDefs[i];
                    if (!content.Controls) continue;

                    for (let j = 0; j < content.Controls.length; j++) {
                        const control = content.Controls[j];

                        let DynamicControl = utils.getDynamicComponent(h, control);
                        if (!DynamicControl) {
                            elements.push(<span>Control type of {control.ControlType} is unknown</span>);
                            continue;
                        }
                        DynamicControl += '-dsgn';

                        elements.push(
                            <DynamicControl type={control.ControlType} name={control.ControlData.Name} root={this.root} controlData={control.ControlData} designmodel={control} rowData={this.model}>
                            </DynamicControl>
                        );
                    }

                    if (this.firstrow && this.selected$ && this.selectedmodel$)
                        elements.push(
                            <div class="property-grid">
                                <v-tooltip right>
                                    <v-btn flat icon style={{ zIndex: 2 }} slot="activator" on-click={(e) => this.addContent(e, content)}>
                                        <v-icon color="blue darken-2">mdi mdi-plus-circle</v-icon>
                                    </v-btn>
                                    <span>Add control to column def #{i + 1}</span>
                                </v-tooltip>
                            </div>
                        );
                }

                if (this.firstrow && this.selected$ && this.selectedmodel$)
                    elements.push(
                        <div class="property-grid">
                            <v-tooltip right>
                                <v-btn flat icon style={{ zIndex: 2 }} slot="activator" on-click={(e) => this.addContentDef(e)}>
                                    <v-icon color="pink darken-2">mdi mdi-plus-circle</v-icon>
                                </v-btn>
                                <span>Add column def</span>
                            </v-tooltip>
                        </div>
                    );

                return (
                    <td style={{ borderColor: "#e0e0e0", borderWidth: "1px", "overflow": "hidden", "max-width": "1px" }}>
                        <div style={{ display: "flex", flexDirection: "row", overflow: "hidden", "white-space": "nowrap", "text-overflow": "ellipsis" }}>
                            {elements}
                        </div>
                    </td>
                );
            }
            else if (cdef.Field && (cdef.Field in this.model)) {
                const cell = this.model[cdef.Field];
                let text;
                if (cell === null || typeof cell === 'undefined')
                    text = '';
                else if (typeof cell === 'string')
                    text = cell;
                else if (typeof cell === 'boolean')
                    text = cell ? 'true' : 'false';
                else if (typeof cell === 'number' || typeof cell === 'integer')
                    text = cell;
                else
                    text = JSON.stringify(cell);

                let addcdef;
                if (this.firstrow && this.selected$ && this.selectedmodel$)
                    addcdef = (
                        <div class="property-grid">
                            <v-tooltip right>
                                <v-btn flat icon style={{ zIndex: 2 }} slot="activator" on-click={(e) => this.addContentDef(e)}>
                                    <v-icon color="pink darken-2">mdi mdi-plus-circle</v-icon>
                                </v-btn>
                                <span>Add column def</span>
                            </v-tooltip>
                        </div>
                    );

                return (
                    <td style={{ borderColor: "#e0e0e0", borderWidth: "1px", "white-space": "nowrap", "text-overflow": "ellipsis", "overflow": "hidden", "max-width": "1px" }}>
                        <span>{text} {addcdef}</span>
                    </td>
                );
            }
            else {
                let addcdef;
                if (this.firstrow && this.selected$ && this.selectedmodel$)
                    addcdef = (
                        <div class="property-grid">
                            <v-tooltip right>
                                <v-btn flat icon style={{ zIndex: 2 }} slot="activator" on-click={(e) => this.addContentDef(e)}>
                                    <v-icon color="pink darken-2">mdi mdi-plus-circle</v-icon>
                                </v-btn>
                                <span>Add column def</span>
                            </v-tooltip>
                        </div>
                    );

                return (
                    <td style={{ borderColor: "#e0e0e0", borderWidth: "1px", "white-space": "nowrap", "text-overflow": "ellipsis", "overflow": "hidden", "max-width": "1px" }}>
                        {addcdef}
                    </td>
                );
            }

            return <td><span>null</span></td>;
        }
        catch (e) {
            return <td><span title={e}>error</span></td>;
        }
    }
});

Vue.component('table-row-dsgn', {
    data: () => ({

    }),
    props: {
        name: '',
        root: null,
        schema: null,
        model: null,
        columnDefs: null,
        even: false,
        selected$: false,
        firstrow: false,
        addControl: null,
    },
    computed: {
        Root: function () {
            return this.root._self;
        },
        selectedmodel$: function () {
            return this.Root.SelectedModel && this.Root.SelectedModel == this.columnDef;
        },
    },
    render() 
    {
        if (!this.model)
            return null;

        const columns = [];

        if (this.columnDefs && this.columnDefs.length > 0) {
            const maxpct = 100 / this.columnDefs.length;
            for (let i = 0; i < this.columnDefs.length; i++) {
                const cdef = this.columnDefs[i];

                columns.push(
                    <table-cell-dsgn
                        selected$={this.selected$}
                        firstrow={this.firstrow}
                        addControl={this.addControl}
                        root={this.root}
                        schema={this.schema}
                        columnDef={cdef}
                        model={this.model}
                        maxpct={maxpct}>
                    </table-cell-dsgn>
                );
            }
        }
        else {
            const maxpct = 100 / Object.keys(this.model).length;
            for (let key in this.model) {
                const cdef = {
                    Field: key,
                };
                columns.push(
                    <table-cell-dsgn
                        selected$={this.selected$}
                        firstrow={this.firstrow}
                        addControl={this.addControl}
                        root={this.root}
                        schema={this.schema}
                        columnDef={cdef}
                        model={this.model}
                        maxpct={maxpct}>
                    </table-cell-dsgn>
                );
            }
        }

        const style = {};

        style.backgroundColor = this.even ? "#f0f0f0" : "white";

        return (
            <tr style={style}>
                {columns}
            </tr>
        );
    }
});

Vue.component('html-table-dsgn', {
    data: () => ({
        displayexpr: null,
        modelurl: null,
        modelvalue: null,
        model: {},
        pickervisible: false,
        picker: null,
        pickercdef: null,
    }),
    created() {
    },
    mounted() {
        this.Refresh();
    },
    computed: {
        Root: function () {
            return this.root._self;
        },
        Model: function () {
            return this.model;
        },
        selected$: function () {
            return this.Root.SelectedNode && this.Root.SelectedNode == this;
        },
    },
    methods: {
        ...methods,
        handleClick(e) {
            this.Root.SelectNode(this);
            e.cancelBubble = true;
            e.stopPropagation();
        },
        selectColumn(e, index) {
            this.Root.SelectNode(this, this.controlData.ColumnDefs[index]);
            e.cancelBubble = true;
            e.stopPropagation();
        },
        deleteColumn(e, index) {
            this.controlData.ColumnDefs.splice(index, 1);
            e.cancelBubble = true;
            e.stopPropagation();
        },
        addColumn(e) {
            if (!this.controlData.ColumnDefs)
                Vue.set(this.controlData, 'ColumnDefs', []);

            this.controlData.ColumnDefs.push({
                $typeSchema: '/schema/public/Platform.Schema.DynamicControls.v1/DynamicControl_Grid_ColumnDef',
                Align: 'Left',
                DisplayName: '',
                Field: 'field' + (this.controlData.ColumnDefs.length+1),
                Sortable: true,
                Visible: true,
                Width: 'Auto',
            });
            this.Root.SelectNode(this, this.controlData.ColumnDefs[this.controlData.ColumnDefs.length - 1]);
            e.cancelBubble = true;
            e.stopPropagation();
        },
        async Refresh() {
            if (this.controlData.SourceType == 'Url' && this.controlData.SourceUrl)
                try {
                    this.model = await utils.api.get(this.controlData.SourceUrl);
                }
                catch (e) {
                    utils.warn('Design HtmlTable SourceUrl ' + this.controlData.SourceUrl + ' failed to load: ' + e);
                    this.model = [{}];
                }
            else if (this.controlData.SourceType == 'Raw' && this.controlData.SourceRaw && Array.isArray(this.controlData.SourceRaw)) {
                this.model = this.controlData.SourceRaw;
            }
            else
                this.model = [{}];
        },

        addControl(cdef) {
            this.pickercdef = cdef;
            this.picker = <control-picker confirm={this.confirmAddControl} cancel={this.cancelAddControl}></control-picker>;
            this.pickervisible = true;
        },
        async confirmAddControl(id, title) {
            this.pickervisible = false;

            try {
                const schema_ = await utils.schema.get(id);
                const schema = utils.schema.resolve_Of(schema_)
                const model = utils.schema.getDefaultModel(schema);
                this.pickercdef.Controls.push(model);
            }
            catch (e) {
                alert(e);
            }
        },
        cancelAddControl() {
            this.pickervisible = false;
        },
        pickerInput(value) {
            this.pickervisible = value;
        },
    },
    props: {
        name: '',
        root: null,
        designmodel: null,
        parentType: '',
        controlData: {}
    },
    render() {
        const elements = [];

        let header;
        if (this.controlData.ColumnDefs && this.controlData.ColumnDefs.length > 0)
        {
            header = [];
            for (let i = 0; i < this.controlData.ColumnDefs.length; i++) {
                const cdef = this.controlData.ColumnDefs[i];

                header.push(
                    <th style={{ width: "150px" }}>
                        <span
                            title="Edit Column"
                            style={{ cursor: "pointer" }}
                            on-click={(e) => this.selectColumn(e, i)}>
                            {cdef.DisplayName || cdef.Field || cdef.Name}
                        </span>
                        <span
                            class="design-options"
                            title="Delete Column"
                            style={{ cursor: "pointer", fontSize: "smaller", display: this.selected$ ? "inline" : "none", color: "blue" }}
                            on-click={(e) => this.deleteColumn(e, i)}><i class="mdi mdi-close"></i></span>
                    </th>
                );
            }
            elements.push(<tr>{header}</tr>);
        }
        else if (this.model && this.model.length > 0)
        {
            header = [];
            const sample = this.model[0];
            for (let head in sample)
            {
                header.push(
                    <th>
                        {head}
                    </th>
                );
            }
            elements.push(<tr>{header}</tr>);
        }

        if (!this.model)
            elements.push(<tr><td>Loading...</td></tr>);
        else {
            let c = this.model.length;
            if (c > 10) c = 10;
            for (let i = 0; i < c; i++) {
                const row = this.model[i];
                if (!row.$objectId)
                    row.$objectId = utils.generateUUID();

                elements.push(
                    <table-row-dsgn
                        key={row.$objectId}
                        selected$={this.selected$}
                        firstrow={i === 0}
                        addControl={this.addControl}
                        root={this.root}
                        schema={this.schema}
                        model={row}
                        columnDefs={this.controlData.ColumnDefs}
                        even={i % 2 === 0}>
                    </table-row-dsgn>
                );
            }
        }

        return (
            <div
                class={{ 'designer-form': true, selected: this.selected$ }}
                style={{ borderStyle: 'solid', borderWidth: '1px', position: "relative", padding: "5px", overflow: "auto", ...utils.getSize(this.controlData.SizeOptions, this.parentType, this.$parent) }}
                on-click={(e) => this.handleClick(e)}>
                <div class={{ "designer-container-hovering": true, 'designer-container-selected-clickthrough': this.selected$ }}></div>

                <div key="1" class="design-options" title="Add Column" on-click={(e) => this.addColumn(e)} style={{ top: "1px", right: "1px", display: this.selected$ ? "block" : "none" }}><i class="mdi mdi-plus-circle"></i></div>

                <table disabled style={{ border: "1px", width: "100%" }}>
                    {elements}
                </table>

                <v-dialog value={this.pickervisible} max-width="860" scrollable on-input={(value) => this.pickerInput(value)}>
                    {this.picker}
                </v-dialog>
            </div>
        );
    }
});