import Vue from 'vue';
import BaseComponent from './baseFormMixin.jsx';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

import { appSettings } from '@/Shared/appSettings.js';

Vue.component('sform-time', {
    mixins: [BaseComponent],
    data: () => ({
    }),
    props: {
    },
    created() {
    },
    mounted() {
        this.loadComplete();
    },
    computed: {
        itemvalue_aslocaldatetime: function () {
            // Cannot use ISO format directly "2200-07-22T06:00:00Z"
            // The format is "yyyy-MM-ddThh:mm" followed by optional ":ss" or ":ss.SSS".
            utils.debug(`itemvalue_aslocaldatetime: ${this.itemvalue}`);

            let dt;
            if (this.itemvalue)
                dt = new Date(Date.parse(this.itemvalue));
            else
                return undefined;

            const h = dt.getHours().toString();
            const m = dt.getMinutes().toString();
            const s = dt.getSeconds().toString();
            return `${h.padStart(2, '0')}:${m.padStart(2, '0')}:${s.padStart(2, '0')}`;
        },
    },
    methods: {
        sync_fromlocaldatetime(value) {
            if (!value.includes('T'))
                value = '2021-01-01T' + value;

            const dt = new Date(value);
            utils.debug(`sync_fromlocaldatetime:'${value}' as '${dt.toISOString()}'`);
            this.sync(dt.toISOString());
        },
    },
    render() {
        let scopedSlots = {
            message: ({message}) => {
                return <translation-container context={this} value={message}></translation-container>
            }
        }

        let slots = [
            <translation-container slot="label" context={this} value={this.labelText}></translation-container>,
        ];

        const input = (
            <v-text-field
                class="caption pa-0 ma-0"
                style={{ width: "100%" }}
                outlined single-line dense hide-details
                value={this.itemvalue_aslocaldatetime}
                type="time"
                on-input={(value) => this.sync_fromlocaldatetime(value)}
                on-blur={this.onBlur}
                on-focus={this.onFocus}
                scopedSlots={scopedSlots}
                hint={this.hintText}
                persistent-hint={appSettings.DebugTranslationPrefixSetting}
            >{slots}</v-text-field>
        );

        if (this.appearance)
            input.componentOptions.propsData = { ...input.componentOptions.propsData, ...this.appearance };

        if (this.directives) {
            if (input.data.directives)
                input.data.directives = [...input.data.directives, ...this.directives];
            else
                input.data.directives = this.directives;
        }

        return input;
    }
});