import Vue from 'vue';
import ResizeObserver from './ResizeObserver.jsx';
import utils from '@/Shared/utils.jsx';

const cloneDeep = require('lodash.clonedeep')

Vue.component('ace-editor', {
    //template: '<div :id="editorId" style="width: 100%; height: 100%;"></div>',
    props: {
        editorId: {
            type: String,
            default: ''
        },
        content: {
            type: String,
            default: ''
        },
        lang: {
            type: String,
            default: 'text'
        },
        theme: {
            type: String,
            default: 'github'
        },
        readOnly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            uniqueid: null,
            uiAceOptions: {},
            editor: Object,
            model: null,
            beforeContent: '',
            hasfocus: false,
        }
    },
    created() {
        this.uniqueid = utils.generateUUID();
    },
    methods: {
        gotoLine(row, column) {
            this.editor.gotoLine(row, column, false);
        },
        beautify(doAppy) {
            utils.log("beautify()");
            let output;

            if (this.lang == "json") { //  this.uiAceOptions.mode == "json") {
                output = JSON.stringify(JSON.parse(this.model), null, "\t");
            }
            else {
                var opts = {
                    indent_size: 1,
                    indent_char: '\t',
                    max_preserve_newlines: 5,
                    preserve_newlines: true,
                    keep_array_indentation: false,
                    break_chained_methods: false,
                    indent_scripts: "normal",
                    brace_style: "expand", // "expand", end-expand, "collapse"
                    space_before_conditional: true,
                    unescape_strings: false,
                    jslint_happy: true,
                    end_with_newline: false,
                    wrap_line_length: "0",
                    indent_inner_html: true,
                    comma_first: false,
                    e4x: false
                };

                if (this.uiAceOptions.mode == "css") {
                    output = this.model; //this.css_beautify(this.model, opts);
                }
                else if (this.looks_like_html(this.model)) {
                    output = this.model; // this.html_beautify(this.model, opts);
                }
                else {
                    output = this.unpacker_filter(this.model); // detect-packers (optional)
                    output = this.model; // this.js_beautify(this.model, opts);
                }
            }

            if (doAppy) // if the CTRL-SHIFT-F is pressed to reformat - it is trapped outside angular and therefore need to be wrapped in $apply
            {
                //Vue.nextTick(function () {
                this.saveEditorPositionAndFoldInfo();
                this.model = output;
                this.editor.setValue(this.model, 1);
                this.restoreEditorPositionAndFoldInfo();
                //});
            }
            else {
                this.model = output;
            }
        },
        saveEditorPositionAndFoldInfo() {
            this.savedFoldData = cloneDeep(this.editor.session.$foldData);
            this.savedScrollTop = this.editor.session.getScrollTop();
            this.savedCursorPosition = this.editor.session.selection.getCursor();
        },
        restoreEditorPositionAndFoldInfo() {
            const c = this;
            Vue.nextTick(function () {
                // before restoring the fold data as was, the rows may have changed when reformatting was applied, so we need to verify that the collapsable rows are still collapsable
                let verifiedFoldData = c.verifyFoldData(c.savedFoldData);
                c.addVerifiedFolds(verifiedFoldData);

                c.editor.session.selection.moveCursorTo(c.savedCursorPosition.row, c.savedCursorPosition.column);
                c.editor.session.setScrollTop(c.savedScrollTop);
            });
        },
        verifyFoldData(foldData) {
            let verifiedFoldData = [];

            for (let x = 0; x < foldData.length; x++) {
                let foldRange = foldData[x].range;

                // Validate that the fold range is still good
                let foldWidget = this.editor.session.getFoldWidget(foldRange.start.row);
                if (foldWidget == "start") {
                    let foldWidgetRange = this.editor.session.getFoldWidgetRange(foldRange.start.row);
                    verifiedFoldData.push(foldWidgetRange);
                }
            }

            return verifiedFoldData;
        },
        addVerifiedFolds(verifiedFoldData) {
            for (let x = 0; x < verifiedFoldData.length; x++) {
                this.editor.session.addFold("...", verifiedFoldData[x])
            }
        },
        unpacker_filter(source) {
            let trailing_comments = '',
                comment = '',
                unpacked = '',
                found = false;

            // cut trailing comments
            do {
                found = false;
                if (/^\s*\/\* /.test(source)) {
                    found = true;
                    comment = source.substr(0, source.indexOf('*/') + 2);
                    source = source.substr(comment.length).replace(/^\s+/, '');
                    trailing_comments += comment + "\n";
                }
                else if (/^\s*\/\//.test(source)) {
                    found = true;
                    comment = source.match(/^\s*\/\/.*/)[0];
                    source = source.substr(comment.length).replace(/^\s+/, '');
                    trailing_comments += comment + "\n";
                }
            } while (found);

            let unpackers = [P_A_C_K_E_R, Urlencoded, /*JavascriptObfuscator,*/ MyObfuscate];
            for (let i = 0; i < unpackers.length; i++) {
                if (unpackers[i].detect(source)) {
                    unpacked = unpackers[i].unpack(source);
                    if (unpacked != source) {
                        source = unpacker_filter(unpacked);
                    }
                }
            }

            return trailing_comments + source;
        },
        looks_like_html(source) {
            // <foo> - looks like html
            // <!--\nalert('foo!');\n--> - doesn't look like html

            let trimmed = source.replace(/^[ \t\n\r]+/, '');
            let comment_mark = '<' + '!-' + '-';
            return (trimmed && (trimmed.substring(0, 1) === '<' && trimmed.substring(0, 4) !== comment_mark));
        },
    },
    watch: {
        'content'(value) {
            if (this.beforeContent !== value) {
                this.editor.setValue(value, 1)
            }
        }
    },
    computed: {
        realId() {
            if (this.editorId)
                return this.editorId + '_' + this.uniqueid;

            return 'Editor_' + this.uniqueid;
        },
    },
    render(e) {
        // <button title="Resizes the editor according to the current width and height" style={{ fontSize: "x-small" }} on-click={(e) => this.editor.resize()}>Resize</button>
        return (
            <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", minHeight: 'inherit', minWidth: 'inherit' }}>
                <resize-observer on-notify={() => this.editor.resize()}></resize-observer>
                <div id={this.realId} style={{ flexGrow: "1" }}>
                </div>
            </div>
        );
    },
    mounted() {
        this.model = this.content || '';
        this.editor = window.ace.edit(this.realId);
        this.editor.setValue(this.model, 1);

        // mode-xxx.js or theme-xxx.jsがある場合のみ有効
        this.editor.getSession().setMode(`ace/mode/${this.lang}`);
        this.editor.setTheme(`ace/theme/${this.theme}`);
        this.editor.setAutoScrollEditorIntoView(true);
        this.editor.setReadOnly(this.readOnly);

        setTimeout(() => {
            //this.editor.moveCursorTo(1, 1);
            this.editor.session.selection.moveCursorTo(0, 0);
            this.editor.session.setScrollTop(1);
        }, 500);

        this.editor.on('focus', () => {
            this.hasfocus = true;
            this.$emit('focus', this.editor.getValue());
        });

        this.editor.on('blur', () => {
            this.hasfocus = false;
            this.beforeContent = this.editor.getValue();
            this.$emit('change-content', this.editor.getValue());
        });

        this.editor.on('change', () => {
            this.model = this.editor.getValue();
        });

        this.$on('Beautify', this.beautify);

        const c = this;
        this.editor.commands.addCommands([
            {
                name: 'formatDocument',
                bindKey: {
                    win: 'Ctrl-Shift-F',
                    mac: 'Command-Shift-F'
                },
                exec: function () {
                    c.$emit('Beautify', true);
                    return false;
                },
                readOnly: true
            }
        ]);
    },
    destroyed() {
        this.$off('Beautify', this.beautify);
        this.editor.destroy();
    }
});