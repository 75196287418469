import Vue from "vue";
import utils from "../../Shared/utils.jsx";
import BaseComponent from "./BaseComponentMixin.jsx";
import { appSettings } from "../../Shared/appSettings.js";
import SAMLPopupManger from "./javascript/SAMLButtonSSOPopupManager";

Vue.component("saml-button", {
    mixins: [BaseComponent],
    data: function() {
        return {
            textexpn: null,
        };
    },
    computed: {
        buttonText() {
            if (!this.textexpn && this.controlData.Text)
                this.textexpn = utils.compile(this, this.controlData.Text);

            const text = this.textexpn ? utils.evaluate(this.textexpn, this) : '';

            return this.Translate(text);
        },
        samlIssuer() {
            if (!this.issuerexpn && this.controlData.SAMLIssuer)
                this.issuerexpn = utils.compile(this, this.controlData.SAMLIssuer);

            return this.issuerexpn ? utils.evaluate(this.issuerexpn, this) : '';
        },
        autoLogin() {
            if (!this.autologinexpn && this.controlData.AutoLogin)
                this.autologinexpn = utils.compile(this, this.controlData.AutoLogin);

            return this.autologinexpn ? utils.evaluate(this.autologinexpn, this) : false;
        },
        styles() {
            return {
                ...this.sizeStyle,
                ...utils.resolveStyleHints(this.styleHints, this),
            };
        },
    },

    //Created replaced with preRenderComplete
    methods: {
        async preRenderComplete() {
            this.finishRenderHandler(this);

            if (this.autoLogin === true || this.autoLogin === "true")
                this.onclick();
        },
        async RunActions(authArgs) {
            await utils.executeAndCompileAllActions(this.controlData.Actions, authArgs, this);
        },

        onclick() {
            const popupManager = new SAMLPopupManger();
            popupManager.open(this.samlIssuer).then((SAMLResponse) => {
                var authargs = {
                    ProviderType: 'SAML',
                    token: SAMLResponse,
                };

                this.RunActions(authargs);
            },
                (result) => {
                    // SAML login was not successful (user either didn't login properly or closed dialog)   
                }
            );

            //care.sendUIEvent(c.$controlModel, "Click");
        },
    },
    render(h) {
        try {
            if (!this.todisplay) return null;

            return (
                <div
                    style={this.styles}
                    class={{ "c-SAMLButton d-flex": true, [`c-name-${this.name || "unnamed"}`]: true }}
                >
                    <v-btn elevation={0} on-click={this.onclick} depressed block>
                        <div class="d-flex align-center ml-8" style="width: 100%">
                            <v-icon class="mr-4" color="#c79b68">
                                mdi-headset
                            </v-icon>
                            {this.buttonText}
                        </div>
                    </v-btn>
                </div>
            );
        } catch (e) {
            utils.error("SAMLButton Render failed", e);
            return <div>SAMLButton Failed to Render {e}</div>;
        }
    },
});
