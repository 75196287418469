import Vue from 'vue';
import utils from '../../Shared/utils.jsx';
import BaseComponent from './BaseComponentMixin.jsx';

Vue.component('select-list', {
    mixins: [BaseComponent],
    data: function () {
        return {
            source: [],
            sourceurl: null,
            sourceraw: null,

            selectedvalueexpr: null,
        }
    },
    created() {
        switch (this.controlData.SourceType)
        {
            case 'Raw':
                this.sourceraw = utils.compileObject(this, this.controlData.Source);
                break;

            case 'URL':
                this.sourceurl = utils.compile(this, this.controlData.SourceURL);
                break;
        }

        if (this.controlData.SelectedValue)
            this.selectedvalueexpr = utils.compile(this, this.controlData.SelectedValue);
    },
    //Mounted Replaced with preRenderComplete
    computed: {
        selectedValue: function () {
            const fields = this.controlData.FieldMappings;

            let value;
            if (this.selectedvalueexpr)
                value = utils.evaluate(this.selectedvalueexpr, this);

            return this.source.findIndex(s => s[fields.Value] == value);
        }
    },
    methods: {
        async preRenderComplete() {
            await this.Refresh();
            this.finishRenderHandler(this);
        },
        async Refresh() {
            if (this.sourceurl) {
                this.source = await utils.api.get(utils.evaluate(this.sourceurl, this));
            }
            else if (this.sourceraw) {
                this.source = utils.evaluateObject(this.sourceraw, this);
            }
        },
        selectItem(e, index, item) {
            const fields = this.controlData.FieldMappings;

            this.utils.executeAndCompileAllActions(this.controlData.Actions, { Value: item[fields.Value], SourceItem: item, SourceIndex: index }, this);
        },
    },
    props: {
    },
    render(h) {
        if(!this.todisplay)
            return null;
        
        try {
            const fields = this.controlData.FieldMappings;
            const items = [];
            const source = this.source || [];
    
            for (let i = 0; i < source.length; i++) {
                const item = source[i];
    
                items.push(
                    <v-list-item
                        key={i}
                        ripple={true}
                        on-click={(e) => this.selectItem(e, i, item)}>
    
                        <v-list-item-content>
                            <v-list-item-title>{item[fields.Title]}</v-list-item-title>
                            <v-list-item-subtitle>{item[fields.Description]}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                );
            }
    
            const style = {
                ...this.sizeStyle,
                ...utils.resolveStyleHints(this.styleHints, this),
            };

            let customClasses = "";
            if (this.styleHints && this.styleHints.CustomClasses) {
                customClasses = utils.resolveCustomClasses(this.styleHints, this);
            }
    
            return (
                <div
                    v-show={this.isvisible}
                    class={{ 'c-SelectList': true,
                    [`c-name-${this.name || 'unnamed'}`]: true,
                    [`${customClasses}`]: true }}
                    style={style}>
                    <v-list
                        nav
                        two-line
                        dense
                    >
                        <v-list-item-group
                            value={this.selectedValue}
                            color="primary"
                        >
                            {items}
                        </v-list-item-group>
                    </v-list>
                </div>
            );
        }
        catch(e){
            utils.error('SelectList Render failed', e);
            return <div>SelectList Failed to Render {e}</div>;
        }
    }
});