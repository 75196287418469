import Vue from 'vue';
import HorizontalStack from './HorizontalStack.jsx';
import VerticalStack from './VerticalStack.jsx';
import BasicButton from './BasicButton.jsx';
import Text from './Text.jsx';
import Icon from './Icon.jsx';
//import SelectList from './SelectList.jsx';
import BasicForm from './BasicForm.jsx';
import BasicGrid from './BasicGrid.jsx';
import TreeView from './TreeView.jsx';
import DefaultUnknown from './DefaultUnknown.jsx';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

function flatMap(arr, mapFunc) {
    const result = [];
    for (const [index, elem] of arr.entries()) {
        const x = mapFunc(elem, index, arr);
        // We allow mapFunc() to return non-Arrays
        if (Array.isArray(x)) {
            result.push(...x);
        } else {
            result.push(x);
        }
    }
    return result;
}
function getMenus(menu)
{
    let items = [menu.element];
    for (let i = 0; i < menu.children.length; i++)
        items = [...items, ...getMenus(menu.children[i])];

    return items;
}
function findParentMenu(parent, path)
{
    let targetname = path;
    if (path.includes('::'))
        targetname = path.split('::')[0];

    const menu = parent.find(m => m.name === targetname);
    if (!menu)
        return null;

    if (path.includes('::')) {
        const index = path.indexOf('::');
        targetname = path.substr(index + 2);
        return findParentMenu(menu.children, targetname);
    }
    return menu;
}
function addPlusButton(h, context, level, menus) {
    for (let i = 0; i < menus.length; i++) {
        const menu = menus[i];
        if (menu.children.length > 0) {
            const lastchild = menu.children[menu.children.length - 1];
            let lastindex = "0";
            let menupath = "";
            if (lastchild.menu) {
                lastindex = lastchild.menu.Index;
                menupath = lastchild.menu.MenuPath;
            }

            const next_index = parseInt(lastindex) + 1;

            menu.children.push({
                menu: null,
                name: '',
                element: (
                    <tr>
                        <td colspan="3" style={{ width: "100%", borderBottom: "1px solid silver" }}>
                            <span style={{ paddingLeft: (15 + 30 * level) + "px" }}>
                                <i class="mdi mdi-plus" title={'Add Submenu Item to ' + menu.name + ' after index ' + lastindex} style={{ cursor: "pointer" }} on-click={(e) => context.addChildMenuItem(e, next_index, menupath)}></i>
                            </span>
                        </td>
                    </tr>
                ),
                children: [],
            });

            addPlusButton(h, context, level + 1, menu.children);
        }
        else if (menu.menu && (!menu.menu.MenuItemData.ControlData || !menu.menu.MenuItemData.ControlData.Controls || menu.menu.MenuItemData.ControlData.Controls.length == 0)) {
            // This is a top-level menu with no body, we will assume it is for child menus and add a plus, if not, when they add a body, this will disappear.
            let menupath = menu.menu.MenuPath || '';
            if (menupath) menupath += '::';
            menupath += menu.menu.MenuItemData.Title;

            const next_index = menu.menu.Index + "1";

            menu.children.push({
                menu: null,
                name: '',
                element: (
                    <tr>
                        <td colspan="3" style={{ width: "100%", borderBottom: "1px solid silver" }}>
                            <span style={{ paddingLeft: (15 + 30 * level) + "px" }}>
                                <i class="mdi mdi-plus" title={'Add Submenu Item to ' + menu.name} style={{ cursor: "pointer" }} on-click={(e) => context.addChildMenuItem(e, next_index, menupath)}></i>
                            </span>
                        </td>
                    </tr>
                ),
                children: [],
            });
        }
    }
}
function getPreviousTopMenu(index, childMenus) {
    index--;
    while (index >= 0)
    {
        const menu = childMenus[index];
        if (!menu.MenuPath) return menu;
        index--;
    }
    return null;
}
function getNextTopMenu(index, childMenus) {
    index++;
    while (index < childMenus.length) {
        const menu = childMenus[index];
        if (!menu.MenuPath) return menu;
        index++;
    }
    return null;
}
function getPreviousChildMenu(menuPath, index, childMenus) {
    // Scan childMenus looking for the same MenuPath, find the one just before the one with the specified index
    let menu;
    for (let i = 0; i < childMenus.length; i++) {
        if (childMenus[i].MenuPath == menuPath) {
            if (childMenus[i].Index == index && menu) return menu;
            menu = childMenus[i];
        }
    }
    return null;
}
function getNextChildMenu(menuPath, index, childMenus) {
    // Scan childMenus looking for the same MenuPath, find the one just after the one with the specified index
    let menu;
    for (let i = 0; i < childMenus.length; i++) {
        if (childMenus[i].MenuPath == menuPath) {
            if (childMenus[i].Index == index && !menu)
                menu = childMenus[i];
            else if (menu)
                return childMenus[i];
        }
    }
    return null;
}

Vue.component('carecenter-popupmenu-dsgn', {
    data: function () {
        return {
            childMenus: null,
            deleteEnabled: false,
        }
    },
    created() {
        if (this.designmodel && this.designmodel._id)
            this.$watch('designmodel._id', function (val, oldval) {
                if ((!this.controlData || !this.controlData.Controls || this.controlData.Controls.length === 0) && this.designmodel.MenuItemData.ChildMenuType)
                    this.RefreshChildMenus();
                else
                    this.childMenus = null;
            });
    },
    computed: {
        Root: function () {
            return this.root._self;
        },
        selected$: function () {
            return this.Root.SelectedNode && this.Root.SelectedModel == this.designmodel;
        }
    },
    mounted() {
        if ((!this.controlData || !this.controlData.Controls || this.controlData.Controls.length === 0) && this.designmodel.MenuItemData.ChildMenuType)
            this.RefreshChildMenus();
        else
            this.childMenus = null;

    },
    methods: {
        ...methods,
        handleClick(e) {
            this.Root.SelectNode(this);
            e.cancelBubble = true;
            e.stopPropagation();
        },
        DesignModel: function () {
            return this.designmodel;
        },
        async RefreshChildMenus() {
            switch (this.designmodel.MenuItemData.ChildMenuType) {
                case 'MenuName':
                    this.childMenus = await utils.api.get('Apps/MenuItems/ListAll/' + this.designmodel.MenuItemData.ChildMenuName);
                    break;

                case 'URL':
                    this.childMenus = await utils.api.get(this.designmodel.MenuItemData.ChildMenuURL);
                    break;

                default:
                    return;
            }
            this.childMenus.sort((a, b) => a.Index - b.Index);
        },
        jumpToMenu(e, menu) {
            this.childMenus = null;
            this.Root.ExternalNavigate('Apps/UI/MenuItem/' + menu._id);

            e.cancelBubble = true;
            e.stopPropagation();
        },
        addChildMenuItem(e, index, path) {
            this.childMenus = null;
            let url = 'Apps/SchemaBootstrap/NewEmptyDocument/MenuChild';
            url += '?MenuName=' + this.designmodel.MenuItemData.ChildMenuName;
            url += '&MenuIndex=' + index;
            url += '&MenuPath=' + (path || '');
            url += '&uuid=' + utils.generateUUID(); // Causes the URL to be unique to insure the Vue engine will re-render

            this.Root.ExternalNavigate(url);

            e.cancelBubble = true;
            e.stopPropagation();
        },
        async swapMenuIndexes(e, menu1, menu2) {
            const url = 'Apps/UI/SwapMenuItemIndexes/' + menu1._id + '/' + menu2._id;
            const res = await utils.api.get(url);
            if (res.Menu1.Success.Success && res.Menu2.Success.Success)
                this.RefreshChildMenus();
            else
                alert(JSON.stringify(res, null, 4));
        },
        async deleteMenu(e, menu) {
            const url = 'Apps/UI/MenuItem/' + menu._id;
            const res = await utils.api.request('DELETE', url);
            if (res === true)
                this.RefreshChildMenus();
            else
                alert(JSON.stringify(res, null, 4));
        }
    },
    props: {
        name: '',
        root: null,
        designmodel: null,
        controlData: {}
    },
    render(h) {
        let icontitle;
        if (this.designmodel.MenuItemData.Icon)
            icontitle = (
                <span style={{ fontSize: "x-large" }}>
                    <i title={this.designmodel.MenuItemData.Icon.Tooltip} class={this.designmodel.MenuItemData.Icon}></i> {this.designmodel.MenuItemData.Title}
                </span>
            );
        else
            icontitle = (
                <span style={{ fontSize: "x-large" }}>
                    <i title="Undefined" class="mdi mdi-help-circle"></i> {this.designmodel.MenuItemData.Title}
                </span>
            );

        if (!this.controlData || !this.controlData.Actions)
            return null;

        let items = [];
        for (let i = 0; i < this.controlData.Actions.length; i++)
        {
            const action = this.controlData.Actions[i];
            let DynamicAction = utils.getDynamicAction(action);

            if (!DynamicAction)
                DynamicAction = 'default-unknown';

            DynamicAction += '-dsgn';

            items.push(
                <DynamicAction type={action.ActionType} root={this.root} designmodel={action} controlData={action.ActionData}>
                </DynamicAction>
            );
        }
        return (
            <div
                class={{ 'designer-container': true, selected: this.selected$ }}
                style={{ ...utils.getSize(this.controlData.SizeOptions) }}
                on-click={(e) => this.handleClick(e)}>
                <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
                {icontitle}
                {this.name}<br />
                {items}
            </div>
        );
    }
});