import Vue from 'vue';
import Vuetify from 'vuetify';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';
import filters from '../../../Shared/filters';
import computed from '../../../Shared/computed';
import BaseComponent from '../BaseComponentMixin.jsx';
import EventBus from '@/Application/event-bus.js';
import careHelpfulFunctions from '../../careHelpfulFunctions.jsx';

/* We need a draggable dialog. Here is one possible solution:
 * https://github.com/vuetifyjs/vuetify/issues/4058
 * 
 * We also need to support resizing, if I find something, I'll paste here.
 * 
 * */

const busyIcon = {
    render(h) {
        return (
            <v-icon
                small
                color="white"
                style={{ visibility: utils.global_variables.api_busy_indicator ? 'visible' : 'hidden' }}>
                mdi mdi-cloud-download
            </v-icon>
        );
    }
};

Vue.component('display-basic-modal', {
    components: {
        busyIcon
    },
    data: () => ({
        vars: {},
        value: '',
        cc_form_changed_indicator: false,
        cc_form_changed_active: false,
        cc_form_changed_linked: false,
        cc_form_changed_button: null,
        cc_form_changed_form: null
    }),
    props: {
        name: '',
        root: null,
        type: null,
        parentType: '',
        isbase: true,
        action: null,
        context: null,
        scopeitems: null,
        controlscope: null,

        okay: null,
        cancel: null,
        accept: null,
    },
    created() {
        this.cc_form_changed_active = !this.action.ActionData.DisableFormTracking;
        this.titlerenderer = utils.compile(this, this.action.ActionData.Title);

        if (this.action.ActionData && this.action.ActionData.Vars) {
            const varsexpn = utils.compileObject(this, this.action.ActionData.Vars);
            try {
                this.vars = utils.evaluateObject(varsexpn, this.action.context);
            }
            catch (e) {
                utils.log('error evaluating Vars: ' + e);
            }
        }

        if (this.action.ActionData && this.action.ActionData.Javascript)
            try {
                const api = utils.apiWrapper; // apiService;
                const chf = careHelpfulFunctions;
                this.Javascript = eval("new (" + this.action.ActionData.Javascript + ")(null, null, api, this, chf);");

                for (let key in this.Javascript)
                    if (typeof this.Javascript[key] === 'function')
                        this.Javascript[key] = this.Javascript[key].bind(this.Javascript);
            }
            catch (e) {
                utils.warn(`Failed evaluating Javascript in ${this.name} ${this.type}: ${this.action.ActionData.Javascript}`, e);
            }

        // Subscribe to a UIAction_Close event, which then emits the close event
        // which is handled within ActionService.jsx, where it imbeds <dialog-basic-modal>
        utils.debug(`DialogBasicModal ${this.name} ${this._uid} $on ActionClose`);
        this.$on('Action-Close', this.performClose);
        EventBus.$on(`Action-Close:${this.name}`, this.performClose);
        EventBus.$on(`Action-Close`, this.performClose);
    },
    destroyed() {
        utils.debug(`DialogBasicModal ${this.name} ${this._uid} $off ActionClose`);
        this.$off('Action-Close', this.performClose);
        EventBus.$off(`Action-Close:${this.name}`, this.performClose);
        EventBus.$off(`Action-Close`, this.performClose);
    },
    mounted() {
    },
    computed: {
        ...computed,
        Base: function () {
            return this;
        },
        Name: function () {
            return this.name;
        },
        Root: function () {
            return this.root ? this.root._self : this;
        },
        Vars: function () {
            return this.vars;
        },
        title: function () {
            return utils.evaluate(this.titlerenderer, this.action.context);
        },
        Input: function () {
            if (this.context)
                return this.context.Input;
            else
                return null;
        },
        formChangedIndicator() {
            if (!this.cc_form_changed_active)
                return false;

            if (!this.cc_form_changed_button?.prerender_complete)
                return false;

            if (!this.cc_form_changed_form?.prerender_complete)
                return false;

            return this.cc_form_changed_indicator;
        },
        AllowClose: function () {
            // If we have a form changed indicator linked then close is determined based on if the form is changed.
            if (this.cc_form_changed_active && this.cc_form_changed_linked)
                return !this.cc_form_changed_indicator;

            // Otherwise close is always allowed
            return true;
        }
    },
    methods: {
        ...methods,
        ...filters,
        confirm() {
            this.okay();
            this.accept(this.value);
        },
        generateItems(h, Controls, items) {
            for (let i = 0; i < Controls.length; i++) {
                const c = Controls[i];

                let DynamicControl = utils.getDynamicComponent(h, c);
                if (!DynamicControl) {
                    items.push(<div>Control type of {c.ControlType} is unknown</div>);
                    continue;
                }

                // Note: c_context causes the VarByName and other methods to search from the specified control rather than the current control
                items.push(
                    <DynamicControl
                        key={i}
                        type={c.ControlType}
                        name={c.ControlData.Name}
                        root={this.root}
                        parentType="HorizontalStack"
                        controlData={c.ControlData}
                        controlURL={c.ControlURL}
                        controlName={c.Name}
                        scopeitems={this.scopeitems}
                        controlscope={this.controlscope}
                        cacheControl={c.CacheControl}
                        controlEvents={c.Events}
                    >
                    </DynamicControl>
                );
            }
        },
        formChanged() {
            if (!this.cc_form_changed_active)
                return;

            if (!this.cc_form_changed_button?.prerender_complete)
                return;

            if (!this.cc_form_changed_form?.prerender_complete)
                return;

            this.cc_form_changed_indicator = true;
        },
        async performClose(action) {
            utils.log(' ** Close Action Received by DialogBasicModal **');

            const dialogresult = action.dialogResult ? utils.evaluateObject(action.dialogResult, action.context) : {};

            //this.cancel();
            this.$emit('closeme', {
                Success: action.ActionData ? action.ActionData.Success : true,
                DialogResult: dialogresult,
                AllowClose: true
            });

            try {
                await utils.success(action);
            } catch (e) { }

            // Complete the promise for the executeAction method
            action.FinishFunc(true);
        },
        finishRenderHandler() { },
        clickedOutside(e) {
            e.stopPropagation();
            e.preventDefault();

            if (this.action.ActionData.CloseOnClickOutside != false) {
                if ((this.cc_form_changed_active && !this.cc_form_changed_linked) || this.action.ActionData.CloseOnClickOutside) {
                    this.$emit('closeme', {
                        Success: this.action.ActionData ? this.action.ActionData.Success : true,
                        DialogResult: false,
                        AllowClose: true
                    });
                }
            }
        }
    },
    render(h) {
        //utils.debug(`DialogBasicModal.render() for ${this.name}`);

        const body = (
            <stack-vertical
                key={utils.generateUUID()}
                on={{ 'finished-render': () => this.finishRenderHandler() }}
                type="VerticalLayout"
                style="height: 100%"
                name={this.action.ActionData.Body.Name}
                root={this.root}
                parentType="DialogBasicModal"
                controlData={this.action.ActionData.Body}
                scopeitems={this.scopeitems}
                controlscope={this.controlscope}
                input={this.Input}
            >
            </stack-vertical>
        );

        const footer = [];

        //if (this.action.ActionData.Body.Controls)
        //    this.generateItems(h, this.action.ActionData.Body.Controls, body);

        if (this.action.ActionData.Footer && this.action.ActionData.Footer.Controls)
            this.generateItems(h, this.action.ActionData.Footer.Controls, footer);

        let header = null;

        if (!this.action.ActionData.HideTitleBar) {       
            header = (
                <v-app-bar flat color="rgba(0, 0, 0, 0)" style={{ backgroundColor: 'var(--v-navigation-base)', maxHeight: '60px', height: '60px' }}>
                    <v-toolbar-title class="title white--text pl-0">
                        {this.title}
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <busyIcon></busyIcon>

                    <v-btn elevation={0} color="white" icon on-click={e => 
                        this.$emit('closeme', {
                            Success: this.action.ActionData ? this.action.ActionData.Success : true,
                            DialogResult: false,
                            AllowClose: this.AllowClose
                        })}>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-app-bar>
            );
        }

        //src="https://cdn.pixabay.com/photo/2020/07/12/07/47/bee-5396362_1280.jpg"
        //<v-app-bar-nav-icon color="white"><v-icon>mdi mdi-menu</v-icon></v-app-bar-nav-icon>
        //<v-icon>mdi mdi-menu-down</v-icon>
        let content = (
            <v-card>
                {header}

                <v-card-text class="mt-2">
                    {body}
                </v-card-text>

                <v-card-actions style="justify-content: flex-end;">
                    {footer}
                </v-card-actions>
            </v-card>
        );

        return content;
    }
});

/*

                <div style="max-height: 700px; overflow-y: auto;">
                    <v-card-text>
                        {body}
                    </v-card-text>
                </div>

 * */