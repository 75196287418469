import Vue from 'vue';
import utils from '../../Shared/utils.jsx';
import BaseComponent from './BaseComponentMixin.jsx';
import EventBus from '@/Application/event-bus';

Vue.component('basic-avatar', {
    mixins: [BaseComponent],
    data: function () {
        return {
            textrenderer: null,
            tooltiprenderer: null,
            colorEval: null,
            fontSizeEval: null,
            cssClassEval: null,
            async_url: null,
            async_text: null,
            async_tooltip: null,
            Input: {},
            backgroundSize: null,
            textvalue: null,
            valueEval: null,
        }
    },
    created() {
        this.textvalue = this.controlData.Text || '';
        this.backgroundSize = this.controlData.BackgroundSize || '50px';
    },
    computed: {
        tooltip() {
            try {
                if(this.tooltiprenderer === null && this.controlData.Tooltip)
                    this.tooltiprenderer = utils.compile(this, this.Translate(this.controlData.Tooltip));
                if(this.tooltiprenderer)
                    return utils.evaluate(this.tooltiprenderer, this);
                return '';
            }
            catch (e) {
                utils.warn('Tooltip could not evaluate expression: ' + this.controlData.Tooltip + '; ' + e);
                return '';
            }
        },
        textString() {
            try {
                if(this.valueEval === null && this.textvalue)
                    this.valueEval = utils.compile(this, this.textvalue);

                if (this.valueEval) {
                    const res = utils.evaluate(this.valueEval, this);
                    return res;
                }
                return '';
            }
            catch (e) {
                utils.warn('Avatar textString could not evaluate expression: ' + this.value + '; ' + e);
                return '';
            }
        },
        fontSize() {
            try {
                if(this.fontSizeEval === null && this.controlData.FontSize)
                this.fontSizeEval = utils.compile(this, this.controlData.FontSize);
                if(this.fontSizeEval)
                return utils.resolveFontSize(utils.evaluate(this.fontSizeEval, this));
                return '';
            }
            catch (e) {
                utils.warn('Font size could not evaluate expression: ' + this.controlData.FontSize + '; ' + e);
                return '14px';
            }
        },
        color() {
            try {
                if(this.colorEval === null && this.controlData.Color)
                    this.colorEval = utils.compile(this, this.controlData.Color);
                if(this.colorEval)
                    return utils.evaluate(this.colorEval, this);
                return '';
            }
            catch (e) {
                utils.warn('Color could not evaluate expression: ' + this.controlData.Color + '; ' + e);
                return '#444';
            }
        },
        cssClasses() {
            try {
                let classes = {};
                if(this.cssClassEval === null && this.controlData.CssClass)
                    this.cssClassEval = utils.compile(this, this.controlData.CssClass);
                if(this.cssClassEval)
                    return utils.evaluate(this.cssClassEval, this)?.split(' ')?.forEach(item => classes[item] = true);

                

                return classes;
            }
            catch (e) {
                utils.warn('CssClasses could not evaluate expression: ' + this.controlData.CssClass + '; ' + e);
                return {};
            }
        },
        customClasses() {
            let classes = { 'c-Text': true, [`c-name-${this.name || 'unnamed'}`]: true };

            let customClasses = "";
            if (this.styleHints && this.styleHints.CustomClasses) {
                customClasses = utils.resolveCustomClasses(this.styleHints, this);
                if (customClasses) {
                    customClasses = customClasses.split(' ');
                    for (let i = 0; i < customClasses.length; i++) {
                        classes[customClasses[i]] = true;
                    }
                }
            }

            return classes;
        },
        textStyles() {
            let styles = {};

            if (this.fontSize)
                styles['font-size'] = this.fontSize;

            if (this.color)
                styles.color = this.color;
            return {
                ...styles,
                ...utils.resolveStyleHints(this.styleHints, this),
            };
        },
        size() {
            return { ...this.sizeStyle, display: 'flex', flexDirection: 'column' };
        },
    },
    methods: {
        preRenderComplete() {
            if (this.controlData.AsyncLookup === true) {
                this.async_url = utils.compile(this, this.controlData.AsyncOptions.URL);
                this.async_text = utils.compile(this, this.controlData.AsyncOptions.Text);
                this.async_tooltip = utils.compile(this, this.controlData.AsyncOptions.Tooltip);
            }
            this.finishRenderHandler(this);

            if (this.controlData.AsyncLookup === true) {
                this.LookupText();
            }
        },
        async LookupText() {
            try {
                const url = utils.evaluate(this.async_url, this);
                if (url) {
                    const res = await utils.api.get(url);
                    if (res)
                        Vue.set(this.Input, 'Data', res);
                    else
                        Vue.set(this.Input, 'Data', '');

                    // Update text with the expression from the async text property - should cause translation component to re-evaluate
                    this.textvalue = this.controlData.AsyncOptions.Text;
                    //this.textrenderer = this.async_text;
                    this.tooltiprenderer = this.async_tooltip;
                }
            }
            catch (e)
            {
                utils.warn('Async lookup failed to evaluate url: ' + this.controlData.AsyncOptions.URL + '; ' + e, e);
            }
        }
    },
    props: {
    },
    render(h) {
        try {
            if (!this.todisplay)
                return null;
    
            return (
                <v-avatar
                    color={this.color}
                    class={this.customClasses}
                    v-show={this.isvisible}
                    overflow="hidden"
                    size={this.backgroundSize}
                    v-tooltip={this.tooltip}>
                    <span
                        style={this.textStyles}>
                        {this.textString}
                    </span>
                </v-avatar>
            );
        }
        catch(e) {
            utils.error('Text Render failed', e);
            return <div>Text Failed to Render {e}</div>;
        }
    }
});