import Vue from 'vue';
import BaseComponent from './baseFormMixin.jsx';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';
import careHelpfulFunctions from '../../careHelpfulFunctions.jsx';
import { Container, Draggable } from 'vue-smooth-dnd';
import VerticalTabs from '../vuecontrols/verticalTabs.vue';

// DnD implemented via:
// https://kutlugsahin.github.io/vue-smooth-dnd

Vue.component('sform-tabarray', {
    mixins: [BaseComponent],
    components: { Container, Draggable, VerticalTabs },
    data: () => ({
        formitem: null,
        tablabelexpr: null,
        selected_tab: -1,
        show_delete: false,
        chosenitems: null,
        availableitems: null,
    }),
    props: {
        layouttype: null,
    },
    created() {
        if (this.form && this.form.length > 0) {
            this.formitem = this.form[0];
        }
        if (this.element.formatData && this.element.formatData.TabLabelExpression) {
            this.tablabelexpr = utils.compile(this, '{{' + this.element.formatData.TabLabelExpression + '}}', false, ['ArrayItem', 'ArrayIndex', 'ArrayItemIndex']);
        }
        else if (this.element.title) {
            this.tablabelexpr = utils.compileExpression(this, `"${ this.element.title }[" + ArrayItemIndex + "]"`, ['ArrayItem', 'ArrayIndex', 'ArrayItemIndex']);
        }
    },
    mounted() {
        this.loadComplete();

        if(this.itemvalue.length > 0)
            this.selected_tab = 0;
    },
    computed: {
        addenabled: function () {
            if (this.availableitems && typeof this.availableitems === 'object') 
                return Object.keys(this.availableitems).every(k => this.availableitems[k]);
            else 
                return true;
        },
        tabs_maxHeight: function () {
            if (this.element.formatData && this.element.formatData.MaxHeight)
                return this.element.formatData.MaxHeight;
            else
                return null;
        }
    },
    methods: {
        addNewItem(e) {
            if (this.itemvalue) {
                this.itemvalue.push(utils.forms.mergeDefaultModel(null, this.element.schema.items, this));

                const this_ref = this;
                Vue.nextTick(function () {
                    this_ref.selected_tab = this_ref.itemvalue.length - 1;
                });
            }
        },
        removeTab(e, index) {
            e.cancelBubble = true;
            e.stopPropagation();

            if (index <= this.selected_tab && this.selected_tab > 0)
                this.selected_tab--;

            this.itemvalue.splice(index, 1);

            if (this.itemvalue.length === 0){
                this.selected_tab = -1;
                this.show_delete = false;
            }
        },
        reOrderItems(result) {
            // Ex: {"removedIndex":2,"addedIndex":0}
            const item = this.itemvalue[result.removedIndex];
            this.itemvalue.splice(result.removedIndex, 1);
            this.itemvalue.splice(result.addedIndex, 0, item);
        },
    },
    render(h) {
        if (!this.form || this.form.length < 1)
            return null;

        let element;

        if (this.form.length > 1) {
            // A manually defined array can have multiple items, wrap in a fieldset
            // discard
            element = {
                type: 'fieldset',
                items: this.form,
            };
        }
        else
            element = this.form[0];

        element.title = this.element.title;

        let Tag = `sform-${element.type}`;

        const items = [];
        const itemlabels = [];

        let modelkeyroot;

        if (element.key) {
            modelkeyroot = [...element.key];

            if (this.modelkey && this.modelkey.length > 0)
                for (let j = 0; j < this.modelkey.length && j < modelkeyroot.length; j++)
                    modelkeyroot[j] = this.modelkey[j];
        }

        if (this.itemvalue)
            for (let i = 0; i < this.itemvalue.length; i++) {
                // Take the index from the arra and replace the indexer expression []
                // within the key expression to include the literal index [{i}]
                let modelkey = modelkeyroot ? modelkeyroot.map(k => k == '[]' ? `[${i}]` : k) : [];

                const value = this.itemvalue[i];

                let label;
                if (this.tablabelexpr)
                    try {
                        label = utils.evaluate(this.tablabelexpr, this, false, null, false, [value, i, i], true);
                    }
                    catch (e) {
                        utils.warn(`Failed to evaluate tab array label: ${this.element.formatData.TabLabelExpression}`, e);
                        label = <span style="cursor: pointer; color: red;" title={`Failed to evaluate tab array label: ${this.element.formatData.TabLabelExpression} - ${e}`}>Err</span>;
                    }
                else if(typeof value === 'object' && 'Name' in value)
                    label = value.Name;
                else
                    label = `[${i + 1}]`;

                itemlabels.push({
                    id: i,
                    title: label,
                });

                items.push(
                    <v-tab-item key={i}>
                        <Tag
                            style="flex-grow: 1;"
                            name={element.title || (modelkey && modelkey.length > 0 ? modelkey[modelkey.length - 1] : '')}
                            root={this.root}
                            element={element}
                            form={element.items}
                            cmodel={this.cmodel}
                            modelkey={modelkey}
                            depth={this.depth + 1}
                            array_index={i}
                            layouttype={this.layouttype}
                            appearance={this.appearance}
                            type="FormField_ArrayItem"
                            readonly={element.readonly || false}
                            schema={this.schema}
                        >
                        </Tag>
                    </v-tab-item>
                );
            }

        const scopedSlots = {
            label: (item) =>
            (
                [
                    <span>{item.title}</span>,
                    <v-btn
                        elevation={0}
                        style={{ visibility: this.show_delete ? "visible" : "hidden" }}
                        icon x-small class="ml-2"
                        on-click={(e) => this.removeTab(e, item.id)}>
                        <v-icon small color="error">mdi-close-circle</v-icon>
                    </v-btn>,
                ]
            )
        };
        var buttonContent = (<div></div>);
        if(!this.element.formatData?.HideButtons) {
            buttonContent = (
                <div style="display: flex; justify-content: center; padding: 5px; gap: 8px;">
                    <v-btn style="flex: 1 1 0;"
                        small
                        color="primary"
                        disabled={!this.addenabled}
                        elevation={0}
                        on-click={(e) => this.addNewItem(e)}>
                        Add
                        <v-icon small class="ml-2">mdi mdi-plus</v-icon>
                        </v-btn>
                    <v-btn style="flex: 1 1 0;"
                        small
                        color={this.show_delete ? 'warning' : 'error'}
                        elevation={0}
                        on-click={(e) => this.show_delete = !this.show_delete}>
                        {this.show_delete ? 'Cancel' : 'Remove'}
                        <v-icon small class="ml-2">mdi mdi-minus-circle</v-icon>
                    </v-btn>
                </div>
            );
        }

        return (
            <div style={{ display: "flex", minWidth: "500px", borderStyle: "solid", borderWidth: "1px", borderRadius: "3px", borderColor: "silver" }}>
                <VerticalTabs
                    items={itemlabels}
                    backgroundImage__="https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg"
                    dark={false}
                    scopedSlots={scopedSlots}
                    value={this.selected_tab}
                    maxHeight={this.tabs_maxHeight}
                    on-change={(index) => this.selected_tab = index}
                    on-blur={this.onBlur}
                    on-focus={this.onFocus}
                >
                    {buttonContent}
                </VerticalTabs>

                <v-tabs-items
                    value={this.selected_tab}
                    vertical
                    style="display: flex; flex-direction: column; flex-grow: 1; padding: 5px; border-left-style: solid; border-left-width: 1px; border-left-color: #e0e0e0; margin-top: 8px; margin-bottom: 5px;">
                    {items}
                </v-tabs-items>
            </div>
        );
    }
});