import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

Vue.component('dynamic-tab-dsgn', {
    data: function () {
        return {
            tab_state: {
                index: 0
            },
        }
    },
    created() {
    },
    computed: {
        Root: function () {
            return this.root._self;
        },
        DesignModel: function () {
            return this.designmodel;
        },
        selected$: function () {
            let anytabs = false;
            if (this.controlData && this.controlData.TabControls)
                for (let i = 0; i < this.controlData.TabControls.length; i++)
                    if (this.Root.SelectedModel == this.controlData.TabControls[i]) {
                        anytabs = true;
                        break;
                    }

            return this.Root.SelectedNode && (this.Root.SelectedModel == this.designmodel || anytabs);
        },
    },
    methods: {
        ...methods,
        handleClick(e) {
            this.Root.SelectNode(this);
            e.cancelBubble = true;
            e.stopPropagation();
        },
    },
    props: {
        name: '',
        type: '',
        root: null,
        designmodel: null,
        parentType: '',
        controlData: {}
    },
    render(h) {
        if (!this.controlData)
            return null;

        const general_tab_style = {
            float: "left",
            borderTop: "1px solid black",
            borderLeft: "1px solid black",
            borderRight: "1px solid black",
            borderRadius: "5px 5px 0 0",
            maxWidth: "250px",
            minWidth: "50px",
            padding: "2px 5px 1px",
            backgroundColor: "gray",
            color: "silver",
        };

        const style = {
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            minWidth: "25px",
            ...utils.getSize(this.controlData.SizeOptions, this.parentType, this.$parent),
            ...utils.getStyleHints(this.controlData.StyleHints),
        };

        return (
            <div
                class={{ 'c-DynamicTab': true, [`c-name-${this.name || 'unnamed'}`]: true, 'designer-container': true, selected: this.selected$ }}
                style={style}
                on-click={(e) => this.handleClick(e)}>
                <div class={{ "designer-container-hovering": true, 'designer-container-selected-clickthrough': this.selected$ }}></div>

                <div>
                    <div style={general_tab_style}>
                        <span style={{ cursor: "pointer" }}>Dynamic Tab</span>
                    </div>
                </div>
            </div>
        );
    }
});