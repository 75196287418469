import Vue from 'vue';
import BaseComponent from './baseFormMixin.jsx';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

import { appSettings } from '@/Shared/appSettings.js';

Vue.component('sform-inputwithheaderbar', {
    mixins: [BaseComponent],
    data: () => ({
    }),
    props: {
    },
    created() {
    },
    mounted() {
        this.loadComplete();
    },
    computed: {
    },
    methods: {
    },
    render() {
        let scopedSlots = {
            message: ({ message }) => {
                return <translation-container context={this} value={message}></translation-container>
            }
        }

        let slots = [
            <translation-container slot="label" context={this} value={this.labelText}></translation-container>,
        ];

        const input = (
            <v-text-field
                class="caption pa-0 ma-0" outlined single-line dense hide-details
                ref={this.autofocus ? this.autofocus_id : undefined}
                style={{ width: "100%" }}
                value={this.itemvalue}
                rules={this.rules}
                on-input={(value) => this.sync(value)}
                on-blur={this.onBlur}
                on-focus={this.onFocus}
                scopedSlots={scopedSlots}
                hint={this.hintText}
                persistent-hint={appSettings.DebugTranslationPrefixSetting}
            >
                {slots}
            </v-text-field>
        );

        if (this.appearance)
            input.componentOptions.propsData = { ...input.componentOptions.propsData, ...this.appearance };

        if (this.directives) {
            if (input.data.directives)
                input.data.directives = [...input.data.directives, ...this.directives];
            else
                input.data.directives = this.directives;
        }

        return (
            <v-card elevation={0} outlined class="mb-3 pb-0">
                <v-card-title class="pa-0">
                    <stack-vertical type="HorizontalLayout" name={this.name} root={this.root} parentType="InputWithHeaderBar" controlData={this.element.formatData}>
                    </stack-vertical>
                </v-card-title>
                <v-card-actions class="pb-0">
                    {input}
                </v-card-actions>
            </v-card>
        );
    }
});