import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

Vue.component('form-input', {
    data: function () {
        return {
        }
    },
    created() {
    },
    computed: {
        ...utils.forms.computed,
    },
    methods: {
        ...methods,
        sync(prop, value) {
            if (!(prop in this.cmodel))
                Vue.set(this.cmodel, prop, value);
            else
                this.cmodel[prop] = value
        },
    },
    props: {
        name: '',
        schemakey: '',
        root: null,
        schema: null,
        cmodel: null,
        extra: null,
        readonly: false,
        child: 0,
        propertygrid: false,
    },
    render(h) {
        if (!this.Condition)
            return null;

        let desc;
        if (this.schema.description)
            desc = <i class="mdi mdi-information" title={this.schema.description} style={{ color: "silver", fontSize: "small", marginLeft: "3px" }}></i>;

        const name = <span style={{ marginLeft: (this.child * 15) + "px" }}>{this.name}</span>;

        let input;
        if (this.readonly)
            input = <v-text-field class="caption pa-0 ma-0" solo single-line dense hide-details style={{ width: "100%" }} value={this.cmodel[this.schemakey]} disabled></v-text-field>;
        else
            input = <v-text-field class="caption pa-0 ma-0" solo single-line dense hide-details style={{ width: "100%" }} value={this.cmodel[this.schemakey]} on-input={(value) => this.sync(this.schemakey, value)}></v-text-field>;

        return (
            <tr class="pa-0 ma-0">
                <td style={{ width: "1px" }}>
                    <span style={{ whiteSpace: "nowrap" }}>{name} {desc}</span>
                </td>
                <td class="pa-0 ma-0" class={{ "property-grid": this.propertygrid }}>
                    {input}
                </td>
            </tr>
        );
    }
});