import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import EventBus from '../event-bus.js';
import careHelpfulFunctions from '../careHelpfulFunctions.jsx';

import utils from '../../Shared/utils.jsx';
import methods from '../../Shared/methods';
import DropdownMultiSelectBase from './vuecontrols/DropdownMultiSelectBase';

Vue.component('dropdown-multi-select', {
    mixins: [BaseComponent],
    data: function () {
        return {
            setValueFunction: null,
            sourceUrlEval: null,
            sourceRawInterpolatedEval: null,
            data: [],
            model: [],
            nameFieldEval: null,
            valueFieldEval: null,
            placeholderEval: null,
            selectionLimitEval: null,
            showCheckAllEval: null,
            showUncheckAllEval: null,
            dynamicButtonTextEval: null,
            dynamicItemCountEval: null,
            defaultEval: null
        }
    },
    components: {
        DropdownMultiSelectBase
    },
    computed: {
        publishField: {
            set(value){
                if(this.setValueFunction === null && this.controlData.PublishField){
                    let vueSet = utils.helpers.convertSetValueToVueSet(this.controlData.PublishField, 'value');
                    this.setValueFunction = new Function('value', 'context', 'util', 'vue', `with (context) { ${vueSet} }`);
                }
                if(this.setValueFunction) {
                    this.setValueFunction(value, this, careHelpfulFunctions, Vue)
                }
                if(this.selectionLimit === 1)
                    this.model = value[0] || '';
                else 
                    this.model = value;
            },
            get() {
                return this.model;
            }
        },
        allSelected() {
            return this.model?.length === this.source.length || (this.selectionLimit > 0 && this.model?.length === this.selectionLimit);
        },
        someSelected() {
            return this.model?.length > 0 && !this.allSelected;
        },
        selectAllIcon(){
            if (this.allSelected) return 'mdi-close-box'
            if (this.someSelected) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        sourceUrl() {
            return utils.evaluate(this.sourceUrlEval, this);
        },
        sourceRawInterpolated(){
            return utils.evaluateObject(this.sourceRawInterpolatedEval, this);
        },
        source() {
            switch(this.controlData.SourceType.toLowerCase()) {
                case 'url':
                    return this.data;
                case 'raw': 
                case 'rawinterpolated': return this.sourceRawInterpolated;
                default: return [];
            }
        },
        nameField() {
            try {
                if(this.nameFieldEval === null && this.controlData?.FieldMappings?.Name)
                    this.nameFieldEval = utils.compile(this, this.controlData.FieldMappings.Name);
                if(this.nameFieldEval)
                    return utils.evaluate(this.nameFieldEval, this);
                return 'Name';
            }
            catch (e) {
                utils.warn('NameField could not evaluate expression: ' + this.controlData.FieldMappings.Name + '; ' + e);
                return 'Name';
            }
        },
        valueField() {
            try {
                if(this.valueFieldEval === null && this.controlData?.FieldMappings?.Value)
                    this.valueFieldEval = utils.compile(this, this.controlData.FieldMappings.Value);
                if(this.valueFieldEval)
                    return utils.evaluate(this.valueFieldEval, this);
                return 'Value';
            }
            catch (e) {
                utils.warn('ValueField could not evaluate expression: ' + this.controlData.FieldMappings.Value + '; ' + e);
                return 'Value';
            }
        },
        placeholder() {
            try {
                if(this.placeholderEval === null && this.controlData.PlaceHolder)
                    this.placeholderEval = utils.compile(this, this.controlData.PlaceHolder);
                if(this.placeholderEval)
                    return utils.evaluate(this.placeholderEval, this);
                return '';
            }
            catch (e) {
                utils.warn('Placeholder could not evaluate expression: ' + this.controlData.PlaceHolder + '; ' + e);
                return '';
            }
        },
        selectionLimit() {
            try {
                if(this.selectionLimitEval === null && this.controlData.SelectionLimit)
                    this.selectionLimitEval = utils.compile(this, this.controlData.SelectionLimit);
                if(this.selectionLimitEval) {
                    try {
                        return Number.parseInt(utils.evaluate(this.selectionLimitEval, this));

                    }
                    catch(err) {
                        return utils.evaluate(this.selectionLimitEval, this);
                    }
                }
                return 0;
            }
            catch (e) {
                utils.warn('SelectionLimit could not evaluate expression: ' + this.controlData.SelectionLimit + '; ' + e);
                return 0;
            }
        },
        showCheckAll() {
            try {
                if(this.showCheckAllEval === null && this.controlData.ShowCheckAll)
                    this.showCheckAllEval = utils.compile(this, this.controlData.ShowCheckAll);
                if(this.showCheckAllEval)
                    return utils.evaluate(this.showCheckAllEval, this);
                return true;
            }
            catch (e) {
                utils.warn('ShowCheckAll could not evaluate expression: ' + this.controlData.ShowCheckAll + '; ' + e);
                return true;
            }
        },
        showUncheckAll() {
            try {
                if(this.showUncheckAllEval === null && this.controlData.ShowUncheckAll)
                    this.showUncheckAllEval = utils.compile(this, this.controlData.ShowUncheckAll);
                if(this.showUncheckAllEval)
                    return utils.evaluate(this.showUncheckAllEval, this);
                return true;
            }
            catch (e) {
                utils.warn('ShowUncheckAll could not evaluate expression: ' + this.controlData.ShowUncheckAll + '; ' + e);
                return true;
            }
        },
        dynamicButtonText() {
            try {
                if(this.dynamicButtonTextEval === null && this.controlData.DynamicButtonText)
                    this.dynamicButtonTextEval = utils.compile(this, this.controlData.DynamicButtonText);
                if(this.dynamicButtonTextEval)
                    return utils.evaluate(this.dynamicButtonTextEval, this);
                return false;
            }
            catch (e) {
                utils.warn('DynamicButtonText could not evaluate expression: ' + this.controlData.DynamicButtonText + '; ' + e);
                return false;
            }
        },
        dynamicItemCount() {
            try {
                if(this.dynamicItemCountEval === null && this.controlData.DynamicItemCount)
                    this.dynamicItemCountEval = utils.compile(this, this.controlData.DynamicItemCount);
                if(this.dynamicItemCountEval)
                    return utils.evaluate(this.dynamicItemCountEval, this);
                return 0;
            }
            catch (e) {
                utils.warn('DynamicItemCount could not evaluate expression: ' + this.controlData.DynamicItemCount + '; ' + e);
                return 0;
            }
        },
        default() {
            try {
                if(this.defaultEval === null && this.controlData.Default)
                    this.defaultEval = utils.compile(this, this.controlData.Default);
                if(this.defaultEval)
                    return utils.evaluate(this.defaultEval, this);
                return '';
            }
            catch (e) {
                utils.warn('Default could not evaluate expression: ' + this.controlData.Default + '; ' + e);
                return '';
            }
        },
        styles() {
            return {
                ...utils.resolveStyleHints(this.styleHints, this),
            }
        }
    },
    //Created Replaced with preRenderComplete
    methods: {
        async preRenderComplete() {
            switch(this.controlData.SourceType.toLowerCase()) {
                case 'url': {
                    this.sourceUrlEval = utils.compile(this, this.controlData.SourceURL);
                    this.data = await utils.api.get(this.sourceUrl);
                    break;
                }
                case 'raw':
                case 'rawinterpolated': {
                    this.sourceRawInterpolatedEval = utils.compileObject(this, this.controlData.Source);
                    break;
                }
            }
    
            if(this.default) {
                this.publishField = this.default;
            }
    
            this.finishRenderHandler(this);
        },
        async onInput(item) {
            this.publishField = item;

            await utils.executeAndCompileAllActions(this.controlData.SelectActions, { Text: 'DropdownMultiSelectActions' }, this);
        },
    },
    props: {
    },
    render(h) {
        if(!this.todisplay)
            return null;
        try {
            let slots = [];

            if (this.controlData?.Title) {
                slots.push(<span slot={'label'}>{this.controlData.Title}</span>);
            }

            return (
                <div style={this.sizeStyle} 
                    class={{ 'c-DropdownMultiSelect': true, [`c-name-${this.name || 'unnamed'}`]: true }}
                    v-show={this.isvisible}>
                    <DropdownMultiSelectBase 
                        class="ma-1"
                        style={this.styles} 
                        on-input={this.onInput} 
                        ccmodel={this.model} 
                        source={this.source} 
                        nameField={this.nameField}
                        valueField={this.valueField}
                        placeholder={this.placeholder}
                        selectionLimit={this.selectionLimit}
                        showCheckAll={this.showCheckAll}
                        showUncheckAll={this.showUncheckAll}
                        dynamicButtonText={this.dynamicButtonText}
                        dynamicItemCount={this.dynamicItemCount}
                    >{slots}</DropdownMultiSelectBase>
                </div>
            );
        }
        catch (e) {
            utils.error('DropdownMultiSelect Render failed', e);
            return <div>DropdownMultiSelect Failed to Render {e}</div>;
        }
    }
});