import Vue from 'vue';
import BasicButton from './BasicButton.jsx';
import Text from './Text.jsx';
import Icon from './Icon.jsx';
//import SelectList from './SelectList.jsx';
import BasicForm from './BasicForm.jsx';
import BasicGrid from './BasicGrid.jsx';
import TreeView from './TreeView.jsx';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';
import controlPicker from './controlPicker.jsx';
import BaseStackMixin from './BaseStackMixin.jsx';
import VerticalStackContent from './VerticalStackContent.jsx';

Vue.component('stack-vertical-dsgn', {
    data: function () {
        return {
            items_id: '_0',
        }
    },
    computed: {
    },
    mounted() {
    },
    methods: {
        generateBreadCrumbs(h) {
            if (this.controlData.BreadCrumb && this.controlData.BreadCrumb.Text) {
                const crumbs = [];
                crumbs.push(
                    <v-btn plain small disabled={true}>{this.controlData.BreadCrumb.Text}</v-btn>
                );
                crumbs.push(<v-icon x-small>mdi mdi-chevron-right</v-icon>);
                return (
                    <div style="display: flex; flex-direction: row;">{crumbs}</div>
                );
            }
            else
                return null;
        },
    },
    props: {
        root: null,
        designmodel: null,
        parentType: null,
        controlData: null,
        sourceData: null,
        type: null,
        asbase: false,
    },
    render(h) {
        let items_id = this.items_id;

        let controlData = this.controlData;

        const style = {
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            minWidth: "15px",
            ...utils.getSize(controlData.SizeOptions, this.parentType, this.$parent),
            ...utils.getStyleHints(controlData.StyleHints),
        };

        const breadcrumb = this.generateBreadCrumbs(h);
        
        return (
            <div
                class={{ [`c-${this.type}`]: true, [`c-name-${this.name || 'unnamed'}`]: true }}
                style={style}
                >

                {breadcrumb}
                <stack-vertical-content-dsgn
                    key={items_id}
                    on={{ 'finished-render': () => this.finishRenderHandler() }}
                    designmodel={this.designmodel}
                    type={this.type}
                    name={controlData.Name}
                    root={this.root}
                    parentType={this.parentType}
                    controlData={controlData}
                    sourceData={this.sourceData}
                    asbase={this.asbase}
                >
                </stack-vertical-content-dsgn>
            </div>
        );
    }
});