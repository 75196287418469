import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import EventBus from '../event-bus.js';

import utils from '../../Shared/utils.jsx';
import methods from '../../Shared/methods';

/**
 * Not Implemented:
 *  *RoundCards
 *  *ShadowColor
 */

Vue.component('card-list-item', {
    mixins: [BaseComponent],
    props: {
        sourceData: null,
        listCardTemplate: {},
        initialSelectedCardEval: null,
        selectCardOnClick: false,
        clickActions: null
    },
    data() {
        return {
            labelEval: null,
            middlePayloadEval: null,
            rightPayloadEval: null,
            colorEval: null,
            cssClassEval: null,
            leftIconEval: null,
            rightIconEval: null,
            listHeightUnitEval: null,
            listHeightEval: null,
            paddingEval: null,
            lineHeightEval: null,
            roundCardsEval: null,
            hoverColorEval: null,
            shadowColorEval: null,
            hovering: false
        }
    },
    mounted() {
        if(this.initialSelectedCardEval && utils.evaluate(this.initialSelectedCardEval, this))
            this.$emit('selected');

        this.renderComplete();
    },
    computed: {
        label() {
            try {
                if(this.labelEval === null && this.listCardTemplate.Label)
                    this.labelEval = utils.compile(this, this.listCardTemplate.Label);
                if(this.labelEval)
                    return utils.evaluate(this.labelEval, this);
                return '';
            }
            catch (e) {
                utils.warn('Label could not evaluate expression: ' + this.listCardTemplate.Label + '; ' + e);
                return '';
            }
        },
        middlePayload() {
            if(this.listCardTemplate.MiddlePayload)
                return this.listCardTemplate.MiddlePayload;
            return '';
        },
        rightPayload() {
            if(this.listCardTemplate.RightPayload)
                return this.listCardTemplate.RightPayload;
            return '';
        },
        color() {
            try {
                if(this.colorEval === null && this.listCardTemplate.Color)
                    this.colorEval = utils.compile(this, this.listCardTemplate.Color);
                if(this.colorEval)
                    return utils.evaluate(this.colorEval, this);
                return '';
            }
            catch (e) {
                utils.warn('Color could not evaluate expression: ' + this.listCardTemplate.Color + '; ' + e);
                return '';
            }
        },
        cssClass() {
            try {
                if(this.cssClassEval === null && this.listCardTemplate.CssClass)
                    this.cssClassEval = utils.compile(this, this.listCardTemplate.CssClass);
                if(this.cssClassEval)
                    return utils.evaluate(this.cssClassEval, this);
                return '';
            }
            catch (e) {
                utils.warn('CssClass could not evaluate expression: ' + this.listCardTemplate.CssClass + '; ' + e);
                return '';
            }
        },
        leftIcon() {
            try {
                if(this.leftIconEval === null && this.listCardTemplate.LeftIcon)
                    this.leftIconEval = utils.compile(this, this.listCardTemplate.LeftIcon);
                if(this.leftIconEval)
                    return utils.evaluate(this.leftIconEval, this);
                return '';
            }
            catch (e) {
                utils.warn('LeftIcon could not evaluate expression: ' + this.listCardTemplate.LeftIcon + '; ' + e);
                return '';
            }
        },
        rightIcon() {
            try {
                if(this.rightIconEval === null && this.listCardTemplate.RightIcon)
                    this.rightIconEval = utils.compile(this, this.listCardTemplate.RightIcon);
                if(this.rightIconEval)
                    return utils.evaluate(this.rightIconEval, this);
                return '';
            }
            catch (e) {
                utils.warn('RightIcon could not evaluate expression: ' + this.listCardTemplate.RightIcon + '; ' + e);
                return '';
            }
        },
        padding() {
            try {
                if(this.paddingEval === null && this.listCardTemplate.Padding)
                    this.paddingEval = utils.compile(this, this.listCardTemplate.Padding);
                if(this.paddingEval)
                    return utils.evaluate(this.paddingEval, this);
                return 0;
            }
            catch (e) {
                utils.warn('Padding could not evaluate expression: ' + this.listCardTemplate.Padding + '; ' + e);
                return 0;
            }
        },
        hoverColor() {
            try {
                if(this.hoverColorEval === null && this.listCardTemplate.HoverColor)
                    this.hoverColorEval = utils.compile(this, this.listCardTemplate.HoverColor);
                if(this.hoverColorEval)
                    return utils.evaluate(this.hoverColorEval, this);
                return '';
            }
            catch (e) {
                utils.warn('HoverColor could not evaluate expression: ' + this.listCardTemplate.HoverColor + '; ' + e);
                return '';
            }
        }
    },
    methods: {
        mouseEnter() {
            this.hovering = true;
        },
        mouseLeave() {
            this.hovering = false;
        },
        async onClick(e) {
            if(this.selectCardOnClick)
                this.$emit('selected');

            if(this.clickActions)
                utils.executeAndCompileAllActions(this.clickActions, {Value: this.sourceData}, this);  
            
            this.$emit('click', e);
        }
    },
    render() {
        let content = [];
        if(this.label)
            content.push((
                <v-card-title>
                    {this.label}
                </v-card-title>
            ));
        
        if(this.middlePayload || this.rightPayload || this.leftIcon || this.rightIcon) {
            let cardTextContent = [];

            if(this.leftIcon)
                cardTextContent.push((
                    <v-icon class="mr-2" small>
                        {this.leftIcon}
                    </v-icon>
                ));

            if(this.middlePayload)
                cardTextContent.push((
                    <span class="mr-2">
                        <translation-container context={this} value={this.middlePayload}></translation-container>
                    </span>
                ));
            
            if(this.rightPayload)
                cardTextContent.push((
                    <span class="mr-2">
                        <translation-container context={this} value={this.rightPayload}></translation-container>
                    </span>
                ));

            if(this.rightIcon)
                cardTextContent.push((
                    <v-icon class="mr-2" small>
                        {this.rightIcon}
                    </v-icon>
                ));

            content.push((
                <v-card-text>
                    {cardTextContent}
                </v-card-text>
            ));
        }

        return (
            <v-card
                on-mouseenter={this.mouseEnter}
                on-mouseleave={this.mouseLeave}
                on-click={this.onClick}
                color={this.hovering ? this.hoverColor : this.color} 
                class={{"ma-1": true, [this.cssClass]: true}} 
                style={{'padding': this.padding + 'px' }} >
                {content}
            </v-card>
        );
    }
})

Vue.component('card-list', {
    mixins: [BaseComponent],
    data() {
        return {
            sourceUrlEval: null,
            sourceRawInterpolatedEval: null,
            data: null,
            actAsDropdownEval: null,
            initialSelectedCardEval: null,
            selectCardOnClickEval: null,
            selectedIndex: 0,
            menushowing: false
        }
    },
    async created() {
        switch(this.controlData.SourceType.toLowerCase()) {
            case 'url': {
                this.sourceUrlEval = utils.compile(this, this.controlData.SourceURL);
                await this.getSourceUrl();
                break;
            }
            case 'rawinterpolated':
            case 'raw': {
                this.sourceRawInterpolatedEval = utils.compileObject(this, this.controlData.Source);
                break;
            }
        }

        if(this.controlData.InitialSelectedCardExpression)
            this.initialSelectedCardEval = utils.compile(this, this.controlData.InitialSelectedCardExpression);

        this.renderComplete();
    },
    //Mounted Replaced with preRenderComplete
    computed: {
        totalItems() {
            return this.source.length + 1;
        },
        sourceUrl() {
            try {
                return utils.evaluate(this.sourceUrlEval, this) || [];
            }
            catch(err) {
                return [];
            }
        },
        sourceRawInterpolated(){
            try {
                return utils.evaluateObject(this.sourceRawInterpolatedEval, this) || [];
            }
            catch(err) {
                return [];
            }
        },
        source() {
            switch(this.controlData.SourceType.toLowerCase()) {
                case 'url':
                    return this.data;
                case 'raw': 
                case 'rawinterpolated': return this.sourceRawInterpolated;
                default: return [];
            }
        },
        actAsDropdown() {
            try {
                if(this.actAsDropdownEval === null && this.controlData.ActAsDropdown)
                    this.actAsDropdownEval = utils.compile(this, this.controlData.ActAsDropdown);
                if(this.actAsDropdownEval)
                    return utils.evaluate(this.actAsDropdownEval, this);
                return false;
            }
            catch (e) {
                utils.warn('ActAsDropdown could not evaluate expression: ' + this.controlData.ActAsDropdown + '; ' + e);
                return false;
            }
        },
        selectCardOnClick() {
            try {
                if(this.selectCardOnClickEval === null && this.controlData.SelectCardOnClick)
                    this.selectCardOnClickEval = utils.compile(this, this.controlData.SelectCardOnClick);
                if(this.selectCardOnClickEval)
                    return utils.evaluate(this.selectCardOnClickEval, this);
                return false;
            }
            catch (e) {
                utils.warn('SelectCardOnClick could not evaluate expression: ' + this.controlData.SelectCardOnClick + '; ' + e);
                return false;
            }
        },
        selectedSourceData() {
            return this.source[this.selectedIndex];
        }
    },
    methods: {
        preRenderComplete() {
            this.finishRenderHandler(this);
        },
        async getSourceUrl() {
            this.data = await utils.api.get(this.sourceUrl);
        },
        onSelected(index) {
            this.selectedIndex = index
        }
    },
    props: {
    },

    render(h) {
        try {
            if (!this.todisplay)
                return null;

            let items = this.source.map((sourceData, index) => {
                return (
                    <card-list-item
                        root={this.Root}
                        listCardTemplate={this.controlData.ListCardTemplate}
                        sourceData={sourceData}
                        initialSelectedCardEval={this.initialSelectedCardEval}
                        selectCardOnClick={this.selectCardOnClick}
                        clickActions={this.controlData.Actions}
                        on-selected={() => this.onSelected(index)}
                    ></card-list-item>
                );
            });

            let content = null;
            if(this.actAsDropdown) {
                const scopedSlots = {
                    activator: ({ on, attrs }) => {
                        return (
                            <card-list-item
                                root={this.Root}
                                listCardTemplate={this.controlData.ListCardTemplate}
                                sourceData={this.selectedSourceData}
                                {...{ on }}
                                {...{ attrs }}>
                            </card-list-item>
                        );
                    }
                };

                content = (
                    <v-menu
                        value={this.menushowing}
                        on-input={(value) => this.menushowing = value}
                        scopedSlots={scopedSlots}
                        offset-y
                        eager
                    >
                        <v-sheet>
                            {items}
                        </v-sheet>
                    </v-menu>
                );
            }
            else 
                content = items;
    
            return (
                <div v-show={this.isvisible} style={{...this.sizeStyle, ...utils.resolveStyleHints(this.styleHints, this)}} class={{ 'c-CardList': true, [`c-name-${this.name || 'unnamed'}`]: true }}>
                    {content}
                </div>
            );
        }
        catch(e) {
            utils.error('CardList Render failed', e);
            return <div>CardList Failed to Render {e}</div>;
        }
    }
});