import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import EventBus from '../event-bus.js';

import utils from '../../Shared/utils.jsx';
import methods from '../../Shared/methods';

Vue.component('wizard-button', {
    mixins: [BaseComponent],
    data: function () {
        return {
            typeEval: null,
            iconEval: null,
            iconPositionEval: null,
            buttonTextEval: null,
            tooltiprenderer: null,
            isdisabledeval: null,
            isDefaultEval: null,
            colorEval: null
        }
    },
    //Mounted Replaced with preRenderComplete
    computed: {
        buttonType() {
            try {
                if(this.typeEval === null && this.controlData.Type)
                    this.typeEval = utils.compile(this, this.controlData.Type);
                if(this.typeEval)
                    return utils.evaluate(this.typeEval, this);
                return 'Next';
            }
            catch (e) {
                utils.warn('Type could not evaluate expression: ' + this.controlData.Type + '; ' + e);
                return 'Next';
            }
        },
        buttonText() {
            try {
                if(this.buttonType === 'Next' || this.buttonType === 'Back' || this.buttonType === 'Finish')
                    return this.buttonType;
                if(this.buttonTextEval === null && this.controlData.Text)
                    this.buttonTextEval = utils.compile(this, this.Translate(this.controlData.Text));
                if(this.buttonTextEval)
                    return utils.evaluate(this.buttonTextEval, this);
                return 'Next';
            }
            catch (e) {
                utils.warn('Text could not evaluate expression: ' + this.controlData.Text + '; ' + e);
                return 'Next';
            }
        },
        icon() {
            try {
                if(this.iconEval === null && this.controlData.Icon)
                    this.iconEval = utils.compile(this, this.controlData.Icon);
                if(this.iconEval)
                    return utils.evaluate(this.iconEval, this);
                if(this.buttonType === 'Back')
                    return 'mdi mdi-chevron-double-left';
                else if(this.buttonType === 'Next')
                    return 'mdi mdi-chevron-double-right';
                return '';
            }
            catch (e) {
                utils.warn('Icon could not evaluate expression: ' + this.controlData.Icon + '; ' + e);
                return 'mdi mdi-chevron-double-right';
            }
        },
        iconPosition() {
            try {
                if(this.buttonType === 'Next')
                    return 'Right';
                else if(this.buttonType === 'Back')
                    return 'Left';
                if(this.iconPositionEval === null && this.controlData.IconPosition)
                    this.iconPositionEval = utils.compile(this, this.controlData.IconPosition);
                if(this.iconPositionEval)
                    return utils.evaluate(this.iconPositionEval, this);
                return 'Right';
            }
            catch (e) {
                utils.warn('IconPosition could not evaluate expression: ' + this.controlData.IconPosition + '; ' + e);
                return 'Right';
            }
        },
        themes() {
            return {
                flat: this.controlData.Themes ? this.controlData.Themes.includes("Flat") || this.controlData.Themes.includes("FlatWhite") || this.controlData.Themes.includes("Transparent") : false,
                small: this.controlData.Themes ? this.controlData.Themes.includes("Condensed") || this.controlData.Themes.includes("CondensedBordered") : false,
                condensedBordered: this.controlData.Themes ? this.controlData.Themes.includes("CondensedBordered") : false,
                transparent: this.controlData.Themes ? this.controlData.Themes.includes("Transparent") || this.controlData.Themes.includes("Condensed") : false,
                leftConnector: this.controlData.Themes ? this.controlData.Themes.includes("LeftConnector") : false,
                rightConnector: this.controlData.Themes ? this.controlData.Themes.includes("RightConnector") : false,
            }
        },
        tooltip() {
            try {
                if(this.tooltiprenderer === null && this.controlData.Tooltip)
                    this.tooltiprenderer = utils.compile(this, this.Translate(this.controlData.Tooltip));
                if(this.tooltiprenderer){
                    return utils.evaluate(this.tooltiprenderer, this);
                }
                return '';
            }
            catch(e) {
                utils.warn('tooltip could not evaluate expression: ' + this.controlData.Tooltip + '; ' + e);
                return '';
            }
        },
        buttonClasses() {
            let classes = {
                'ThemeRightConnector': this.themes.rightConnector,
                'ThemeLeftConnector': this.themes.leftConnector,
            };

            return classes;
        },
        styles() {
            return {
                ...utils.resolveStyleHints(this.styleHints, this),
                overflow: "hidden",
                whiteSpace: "nowrap",
            };
        },
        isDisabled(){
            try {
                if(this.isdisabledeval === null && this.controlData.IsDisabled)
                    this.isdisabledeval = utils.compile(this, this.controlData.IsDisabled);
    
                if (this.isdisabledeval) {
                    const res = utils.evaluate(this.isdisabledeval, this, false,
                        () => {
                            // This is a callback that will only be called if evaluate throws an exception.
                            // If this happens, we read the isdisabledfailed (so Vue knows we care) and then
                            // after 100ms, we update isdisabledfailed to cause Vue to re-evaluate todisplay.
                            // (cap the retry at 10 times with increasing delay each attempt)
                            if (this.isdisabledfailed >= 0 && this.isdisabledfailed < 10)
                                setTimeout(() => this.isdisabledfailed++, 50 * this.isdisabledfailed);
                        });
    
                    return res;
                }
                return false;
            }
            catch(e) {
                utils.warn('isdisabled could not evaluate expression: ' + this.controlData.IconStyle + '; ' + e);
                return false;
            }
        },
        isDefault() {
            try {
                if(this.isDefaultEval === null && this.controlData.IsDefault)
                    this.isDefaultEval = utils.compile(this, this.controlData.IsDefault);
                if(this.isDefaultEval)
                    return utils.evaluate(this.isDefaultEval, this);
                return false;
            }
            catch(e) {
                utils.warn('Icon could not evaluate expression: ' + this.controlData.IconStyle + '; ' + e);
                return false;
            }
        },
        color() {
            try {
                let theColor = "primary";
                if (this.colorEval === null && this.controlData.Color)
                    this.colorEval = utils.compile(this, this.controlData.Color);
                if (this.colorEval) theColor = utils.evaluate(this.colorEval, this);

                if (this.isOutlined && theColor) theColor = theColor.replace("outline-", "");
                else theColor = theColor || "secondary";
                // This helps with backwards compatibility for a version of Bootstrap that used the word 'default' to describe the secondary button
                if (theColor === "default") return "secondary";
                else if (theColor === "danger") return "error";
                return theColor;
            } catch (e) {
                utils.warn("color could not evaluate expression: " + this.controlData.Color + "; " + e);
                return "secondary";
            }
        },
        isOutlined() {
            // Default for non-Next and non-Finish buttons is outline-primary.
            if (!this.controlData.Color && this.buttonType !== 'Next' && this.buttonType !== 'Finish')
                return true;

            return this.controlData.Color && this.controlData.Color.startsWith("outline-");
        },
    },
    methods: {
        preRenderComplete() {
            this.finishRenderHandler(this);
        },
        async doClick(e) {
            if ((!this.controlData.Actions || !this.controlData.Actions.length) && this.ParentByType('Wizard')) {
                switch (this.buttonType) {
                    case 'Back':
                        this.ParentByType('Wizard').PreviousStep();
                        break;

                    case 'Next':
                        this.ParentByType('Wizard').NextStep();
                        break;
                }
            }
            else {
                await utils.executeAndCompileAllActions(this.controlData.Actions, null, this);
            }
        }
    },
    props: {
    },
    render(h) {
        try {
            if (!this.todisplay)
                return null;
    
            let content = [];
    
            if (this.icon) {
                if (this.iconPosition === 'Left') content.push(<v-icon  medium={!this.themes.small} left>{this.icon}</v-icon>);
                    content.push(<span>{this.buttonText}</span>);
                if (!this.iconPosition || this.iconPosition === 'Right') content.push(<v-icon medium={!this.themes.small} right>{this.icon}</v-icon>);
            }
            else
                content.push(<span>{this.buttonText}</span>);

            let button = (
                <v-btn elevation={0}
                    class={this.buttonClasses}
                    style={this.styles}
                    color={this.color}
                    outlined={this.isOutlined || this.themes.condensedBordered}
                    depressed={this.themes.flat}
                    text={this.themes.transparent}
                    x-small={this.themes.small}
                    medium={!this.themes.small}
                    on-click={(e) => this.doClick(e)}
                    type="button"
                    disabled={this.isDisabled == 'true' || this.isDisabled == true}
                    is-default={this.isDefault}
                    block={this.sizeOptions && this.sizeOptions.Width && this.sizeOptions.Width.Mode === 'Fill'}>
                    {content}
                </v-btn>
            );

            let finalContent = utils.generateTooltip(h, button, this.tooltip, 'top');
            
            return (
                <div
                    class={{ 'c-BasicButton': true, [`c-name-${this.name || 'unnamed'}`]: true, 'ma-1': true }}
                    v-show={this.isvisible} style={this.sizeStyle}>
                    {finalContent}
                </div>
            );
        }
        catch (e) {
            utils.error('BasicButton Render failed', e);
            return <div>Button Failed to Render {e}</div>;
        }
    }
});