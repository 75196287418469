import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';
import BaseComponent from './BaseComponentMixin.jsx';

const buttonBase = {
    mixins: [BaseComponent],
    data: function () {
        return {
        }
    },
    created() {
    },
    computed: {
        text() {
            return this.controlData.Text;
        },
        icon() {
            let icon = this.controlData.Icon;

            if (icon && icon.startsWith('{'))
                return 'mdi mdi-help-circle';

            return icon;
        },
        iconPosition() {
            if (this.controlData.IconPosition)
                return this.controlData.IconPosition;
            return 'right';
        },
        iconGap() {
            if (this.controlData.IconGap)
                return this.controlData.IconGap;
            return 10;
        },
        flipHorizontally() {
            if (this.controlData.FlipHorizontally)
                return this.controlData.FlipHorizontally;
            return false;
        },
        flipVertically() {
            if (this.controlData.flipVertically)
                return this.controlData.flipVertically;
            return false;
        },
        border() {
            if (this.controlData.Border)
                return this.controlData.Border;
            return false;
        },
        animation() {
            if (this.controlData.Animation)
                return this.controlData.Animation;
            return 'None';
        },
        color() {
            let theColor = 'secondary';
            if (this.controlData.Color)
                theColor = this.controlData.Color;

            if (this.isOutlined && theColor)
                theColor = theColor.replace('outline-', '');
            else
                theColor = theColor || 'secondary';
            // This helps with backwards compatibility for a version of Bootstrap that used the word 'default' to describe the secondary button
            return theColor === 'default' ? 'secondary' : theColor;
        },
        cssClass() {
            if (this.controlData.CssClass)
                return this.controlData.CssClass;
            return '';
        },
        tooltip() {
            if (this.controlData.Tooltip)
                return this.controlData.Tooltip;
            return '';
        },
        tooltipLocation() {
            let location = this.controlData.TooltipLocation || '';
            return location.split('-')[0].toLowerCase() || 'auto';
        },
        isDefault() {
            if (this.controlData.IsDefault)
                return this.controlData.IsDefault;
            return false;
        },
        isdisabled() {
            if (this.controlData.IsDisabled)
                return this.controlData.IsDisabled == 'true';
            return false;
        },
        stopPropagation() {
            if (this.controlData.StopPropagation)
                return this.controlData.StopPropagation;
            return false;
        },
        styles() {
            return {
                ...utils.getStyleHints(this.controlData.StyleHints),
                overflow: "hidden",
                whiteSpace: "nowrap",
            };
        },
        sizeStyle() {
            return utils.getSize(this.controlData.SizeOptions, this.parentType, this.$parent);
        },
        isOutlined() {
            return this.controlData.Color && this.controlData.Color.startsWith('outline-');
        },
        buttonClasses() {
            let classes = {
                'ThemeRightConnector': this.themes.rightConnector,
                'ThemeLeftConnector': this.themes.leftConnector,
            };

            classes[this.cssClass] = true;

            return classes;
        },
        iconClasses() {
            return {
                'mdi mdi-flip-vertical': this.flipVertically,
                'mdi mdi-flip-horizontal': this.flipHorizontally,
                'mdi mdi-border-all-variant': this.border,
                'mdi-animate-spin': this.animation === 'Spin',
                'mdi-animate-pulse': this.animation === 'Pulse',
            }
        },
        themes() {
            return {
                flat: this.controlData.Themes ? this.controlData.Themes.includes("Flat") || this.controlData.Themes.includes("FlatWhite") || this.controlData.Themes.includes("Transparent") : false,
                small: this.controlData.Themes ? this.controlData.Themes.includes("Condensed") || this.controlData.Themes.includes("CondensedBordered") : false,
                condensedBordered: this.controlData.Themes ? this.controlData.Themes.includes("CondensedBordered") : false,
                transparent: this.controlData.Themes ? this.controlData.Themes.includes("Transparent") : false,
                leftConnector: this.controlData.Themes ? this.controlData.Themes.includes("LeftConnector") : false,
                rightConnector: this.controlData.Themes ? this.controlData.Themes.includes("RightConnector") : false,
            }
        },
    },
    methods: {
        handleClick(e) {
            this.Root.SelectNode(this);
            e.cancelBubble = true;
            e.stopPropagation();
        },
    },
    props: {
    },
    render(h) {
        //const style = {
        //    maxWidth: "250px", // Just for the designer
        //    ...utils.getSize(this.controlData.SizeOptions, this.parentType, this.$parent),
        //    overflow: "hidden",
        //    whiteSpace: "nowrap",
        //    border: '1px solid black',
        //    padding: '2px',
        //    margin: '1px',
        //};


        //let icon;
        //if (this.controlData.Icon && (this.controlData.Icon.includes('{{') || this.controlData.Icon.includes('{*')))
        //    icon = 'mdi mdi-help-circle';
        //else
        //    icon = this.controlData.Icon;

        //let text = this.controlData.Text;

        //if (!text && !icon) {
        //    // This is found in the WizardButton
        //    if (this.controlData.Type)
        //        switch (this.controlData.Type) {
        //            case 'Back': text = 'Back'; icon = 'mdi mdi-chevron-double-left'; break;
        //            case 'Next': text = 'Next'; icon = 'mdi mdi-chevron-double-right'; break;
        //            case 'Finish': text = 'Finish'; break;
        //        }
        //    else
        //        text = 'Untitled';
        //}

        let content = [];

        if (this.text && this.icon) {
            if (this.iconPosition === 'Left') content.push(<v-icon style={{ 'margin-right': this.iconGap + 'px' }} medium={!this.themes.small} class={this.iconClasses} left>{this.icon}</v-icon>);
            content.push(this.text);
            if (!this.iconPosition || this.iconPosition === 'Right') content.push(<v-icon style={{ 'margin-left': this.iconGap + 'px' }} medium={!this.themes.small} class={this.iconClasses} right>{this.icon}</v-icon>);
        }
        else if (this.text)
            content.push(this.text);
        else if (this.icon)
            content.push(<v-icon class={this.iconClasses} small>{this.icon}</v-icon>);
        else if (this.controlData.Type)
            switch (this.controlData.Type) {
                case 'Next':
                case 'Back':
                case 'Finish':
                    content.push(this.controlData.Type);
                    break;
            }

        return (
            <div
                class="ma-1"
                class={{ 'designer-container': true }}
                style={this.sizeStyle}
                on-click={(e) => this.handleClick(e)}>
                <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
                <v-btn
                    v-tooltip={this.controlData.Tooltip ? { content: this.tooltip, placement: this.tooltipLocation } : null}
                    class={this.buttonClasses}
                    style={this.styles}
                    color={this.color}
                    outlined={this.isOutlined || this.themes.condensedBordered}
                    depressed={this.themes.flat}
                    text={this.themes.transparent}
                    x-small={this.themes.small}
                    medium={!this.themes.small}
                    rounded
                    type="button"
                    title={this.tooltip}
                    disabled={this.isdisabled == 'true'}
                    is-default={this.isDefault}
                    block={this.controlData.SizeOptions && this.controlData.SizeOptions.Width && this.controlData.SizeOptions.Width.Mode === 'Fill'}>
                    {content}
                </v-btn>
            </div>
        );

        //return (
        //    <div>
        //        <button
        //            style={style}
        //            class={{ 'designer-button': true, selected: this.selected$ }}
        //            on-click={(e) => this.handleClick(e)}
        //            type="button"
        //            title={this.controlData.Tooltip}>
        //            <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
        //            {text} <i class={icon}></i>
        //        </button>
        //    </div>
        //);
    }
};

Vue.component('basic-button-dsgn', {
    mixins: [buttonBase]
});

Vue.component('wizard-button-dsgn', {
    mixins: [buttonBase]
});