import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import utils from '../../Shared/utils.jsx';
import "./css/CircleChart.css";

//import VueApexCharts from 'vue-apexcharts'
//Vue.use(VueApexCharts);
//Vue.component('apexchart', VueApexCharts);

Vue.component('circle-chart', {
    mixins: [BaseComponent],
    components: {
    },
    data: function () {
        return {
            tooltipEval: null,
            tooltipPlacementEval: null,
            disabledEval: null,
            objectFitEval: null,
            sourcemodel: null,
            centervalueformatter: function (w) {
                // Sum of all values in series
                return w.globals.seriesTotals.reduce((a, b) => {
                    return a + b
                }, 0)
            },
        }
    },
    created() {
        if (this.controlData.SourceType === 'Raw' && this.controlData.SourceRaw) {
            this.sourceraw = this.controlData.SourceRaw;
        }
        else if (this.controlData.SourceType === 'RawInterpolated' && this.controlData.SourceRaw) {
            this.sourceraw = utils.compileObject(this, this.controlData.SourceRaw);
        }
        else if (this.controlData.SourceType === 'URL' && this.controlData.SourceURL) {
            this.sourceurl = utils.compile(this, this.controlData.SourceURL);
        }
    },
    //Created Replaced with preRenderComplete
    computed: {
        style() {
            return {
                ...utils.resolveStyleHints(this.styleHints, this),
                ...this.sizeStyle,
                display: 'flex',
                flexDirection: 'column',
                overflow: "hidden",
            };
        },
        tooltip() {
            try {
                if (this.tooltipEval === null && this.controlData.Tooltip)
                    this.tooltipEval = utils.compile(this, this.Translate(this.controlData.Tooltip));
                if (this.tooltipEval)
                    return utils.evaluate(this.tooltipEval, this);
                return '';
            }
            catch (e) {
                utils.warn('Tooltip could not evaluate expression: ' + this.controlData.Tooltip + '; ' + e);
                return '';
            }
        },
        tooltipPlacement() {
            try {
                if (this.tooltipPlacementEval === null && this.controlData.TooltipPlacement)
                    this.tooltipPlacementEval = utils.compile(this, this.controlData.TooltipPlacement);
                let placement = '';
                if (this.tooltipPlacementEval)
                    placement = utils.evaluate(this.tooltipPlacementEval, this);
                return placement.split('-')[0].toLowerCase() || 'top';
            }
            catch (e) {
                utils.warn('TooltipPlacement could not evaluate expression: ' + this.controlData.TooltipPlacement + '; ' + e);
                return '';
            }
        },
        color() {
            if (!this.color_fn && this.controlData.Color)
                this.color_fn = utils.compile(this, this.controlData.Color);

            return this.color_fn ? utils.evaluate(this.color_fn, this) : undefined;
        },
        value() {
            if (!this.value_fn && this.controlData.ValueExpression)
                this.value_fn = utils.compileExpression(this, this.controlData.ValueExpression);

            return this.value_fn ? utils.evaluate(this.value_fn, this) : 0;
        },
        width() {
            if (!this.width_fn && this.controlData.WidthExpression)
                this.width_fn = utils.compileExpression(this, this.controlData.WidthExpression);

            return this.width_fn ? utils.evaluate(this.width_fn, this) : 0;
        },
        size() {
            if (!this.size_fn && this.controlData.SizeExpression)
                this.size_fn = utils.compileExpression(this, this.controlData.SizeExpression);

            return this.size_fn ? utils.evaluate(this.size_fn, this) : 0;
        },
        rotate() {
            if (!this.rotate_fn && this.controlData.RotateExpression)
                this.rotate_fn = utils.compileExpression(this, this.controlData.RotateExpression);

            return this.rotate_fn ? utils.evaluate(this.rotate_fn, this) : 0;
        },

        title() {
            if (this.controlData.Title && !this.title_fn)
                this.title_fn = utils.compile(this, this.controlData.Title);

            return this.title_fn ? utils.evaluate(this.title_fn, this) : '';
        },
        centerhole() {
            if (this.controlData.CenterHoleSize && !this.centerhole_fn)
                this.centerhole_fn = utils.compileExpression(this, this.controlData.CenterHoleSize);

            const value = this.centerhole_fn ? utils.evaluate(this.centerhole_fn, this) : 0;

            return (value * 100).toFixed(0) + '%';
        },
        fontSize() {
            if (this.controlData.PieSliceTextFontSize) {
                var rgx = /^[0-9]*$/;

                if (rgx.test(this.controlData.PieSliceTextFontSize))
                    return `${this.controlData.PieSliceTextFontSize}px`;
                else
                    return this.controlData.PieSliceTextFontSize;
            }

            return '24px';
        },
        centertext() {
            if (!this.content_fn && this.controlData.CenterText)
                this.content_fn = utils.compile(this, this.controlData.CenterText);

            return this.content_fn ? utils.evaluate(this.content_fn, this) : null;
        },

        sourcerawvalue: function () {
            if (this.controlData.SourceType === 'Raw')
                return this.sourceraw;
            else
                return this.sourceraw ? utils.evaluateObject(this.sourceraw, this) : null;
        },
        sourceurlvalue: function () {
            return this.sourceurl ? utils.evaluate(this.sourceurl, this) : '';
        },
        model: function () {
            switch (this.controlData.SourceType) {
                case 'Raw':
                    return this.sourceraw;

                case 'RawInterpolated':
                    return this.sourceraw ? utils.evaluateObject(this.sourceraw, this) : null;

                case 'URL':
                    return this.sourcemodel;

                default:
                    return null;
            }
        },
        labelFormatter: function () {
            const self = this;

            return function (val, opts) {
                // Causes the actual values to be shown in each pie slice where
                // the val parameter is the percentage, which is less useful.
                switch (self.controlData.PieSliceText) {
                    case 'percentage': return val.toFixed(0) + '%';
                    case 'label': return self.model.labels[opts.seriesIndex];
                    case 'time': return self.model.customtimes[opts.seriesIndex];
                    case 'value': return opts.w.config.series[opts.seriesIndex];
                    case 'none': return '';
                    case 'custom': return [self.model.customcounts[opts.seriesIndex],
                        self.model.customtimes[opts.seriesIndex],
                        self.model.custompercents[opts.seriesIndex]]
                        ;
                }
                return opts.w.config.series[opts.seriesIndex];
            };
        },
        tooltipFormatter: function () {
            const self = this;
            return function ({ series, seriesIndex, dataPointIndex, w }) {
                return self.model.customtooltips !== undefined ? self.model.customtooltips[seriesIndex] : self.model.labels[seriesIndex];
            }
        },
        chartData() {
            return this.model.values;
            //return [1,2,3,4,5]; // [44, 55, 41, 17, 15];
        },
        chartOptions() {
            const legendMaxSize = this.controlData.LegendMaxSize;
            const opts = {
                height: '100%',
                width: '100%',
                title: {
                    text: this.title,
                    style: {
                        fontSize: '24px',
                        fontWeight: 'bold',
                        fontFamily: undefined,
                        color: '#263238'
                    },
                },
                chart: {
                    dropShadow: {
                        enabled: false,
                        enabledOnSeries: undefined,
                        top: 0,
                        left: 0,
                        blur: 0,
                        opacity: 0
                    },
                    animations: {
                        enabled: false,
                        animateGradually: {
                            enabled: false,
                            delay: 150
                        },
                        dynamicAnimation: {
                            enabled: false,
                            speed: 350
                        }
                    }
                },
                noData: {
                    text: this.Translate(this.model.nodata || "Not Enough Data To Display"),
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: { 
                        color: 'black',
                        fontSize: '1.2em',
                    }
                },
                legend: {
                    show: (this.controlData.LegendPosition != 'none' && this.model.values?.length > 0),
                    position: this.controlData.LegendPosition || 'right',
                    width: this.controlData.LegendWidth || 130,
                    
                   formatter: function (val, opts) {
                        let maxchars = val.length;
                        if(legendMaxSize === undefined){
                            maxchars = 12;
                        }else if(legendMaxSize > 0)  {
                            maxchars = legendMaxSize;
                        }else{
                            return val; // i.e when set to 0 or less
                        }
                       return val.length > maxchars ? `${val.substring(0, maxchars)}…` : val;
                   }
                },
                //labels: this.model.labels, 
                //colors: this.model.colors,
                //[
                //    'Inbound',
                //    'Ready',
                //    'Wrap Up',
                //    'Not Ready',
                //    'Outbound',
                //],
                tooltip: {
                    custom: this.tooltipFormatter,
                    enabled: this.controlData.EnableTooltips || false,
                },
                dataLabels: {
                    enabled: true,
                    formatter: this.labelFormatter,
                    //function(val, opts) {
                    //    // Causes the actual values to be shown in each pie slice where
                    //    // the val parameter is the percentage, which is less useful.
                    //    switch (this.controlData.PieSliceText) {
                    //        case 'percentage': return val.toFixed(0);
                    //        case 'label': return this.model.labels[opts.seriesIndex];
                    //        case 'value': return opts.w.config.series[opts.seriesIndex];
                    //        case 'none': return null;
                    //    }
                    //    return opts.w.config.series[opts.seriesIndex];
                    //},
                    style: {
                        fontSize: this.fontSize,
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 'normal',
                        colors: ['white']
                    },
                    dropShadow: {
                        enabled: false,
                        enabledOnSeries: undefined,
                        top: 0,
                        left: 0,
                        blur: 0,
                        opacity: 0
                    }
                },
                plotOptions: {
                    pie: {
                        donut: {
                            size: this.centerhole, // '50%',
                            background: 'transparent',
                            labels: {
                                show: this.controlData.DisplayCenterValue, // Turns on/off center value
                                name: {
                                    show: false, // Turns on/off center label
                                },
                                value: {
                                    // Represents the center value text font
                                    fontSize: '48px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    fontWeight: 600,
                                    color: undefined,
                                    offsetY: 16,
                                },
                                total: {
                                    show: this.controlData.DisplayCenterValue, // Turns on/off center value
                                    showAlways: this.controlData.DisplayCenterValue,

                                    // Controls the center label text
                                    //label: 'Logged In',

                                    // Represents the center label "Total" text font
                                    //fontSize: '16px',
                                    //fontFamily: 'Helvetica, Arial, sans-serif',
                                    //fontWeight: 400,
                                    //color: '#373d3f',

                                    formatter: this.centervalueformatter,
                                }
                            }
                        }
                    }
                }
            };

            if (this.model.labels)
                opts.labels = this.model.labels;
            if (this.model.colors)
                opts.colors = this.model.colors;

            return opts;
        },
    },
    methods: {
        async preRenderComplete() {
            this.finishRenderHandler(this);
            this.Refresh();
        },
        async Refresh() {
            if (this.controlData.SourceType === 'URL' && this.controlData.SourceURL) {
                this.sourcemodel = await utils.api.get(this.sourceurlvalue);
            }
        },

        getContent(value) {
            if (!this.content_fn && this.controlData.Content)
                this.content_fn = utils.compile(this, this.controlData.Content, false, 'value');

            return this.content_fn ? utils.evaluate(this.content_fn, this, false, null, false, value) : null;
        },
    },
    render(h) {
        if (!this.todisplay || !this.model)
            return null;

        try {
            let item = (
                <apexchart
                    type="donut"
                    v-show={this.isvisible}
                    class={{ 'c-CircleChart': true, [`c-name-${this.name || 'unnamed'}`]: true }}
                    style={this.style}
                    series={this.chartData}
                    options={this.chartOptions}
                    height="100%"
                    width="100%"
                >
                    {this.centertext}
                </apexchart>
            );

            if (this.tooltip)
                item = utils.generateTooltip(h, item, this.tooltip, this.tooltipPlacement);

            return item;
        }
        catch (e) {
            utils.error('CircleChart Render failed', e);
            return <div>CircleChart Failed to Render {e}</div>;
        }
    }
});