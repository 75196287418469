import Vue from 'vue';
import utils from './utils.jsx';
import careHelpfulFunctions from '../Application/careHelpfulFunctions.jsx';

// Wrapper for a compiled expression. Allows recognition of the compiled expression and
// also allows us to store the original string (for debugging).
class ObjFunc {
    constructor(code, func) {
        this.code = code;
        if (!func)
            this.func = new Function('context', 'util', 'with (context) return ' + code + ';');
        else
            this.func = func;
    }
}

// Allows objects to be added to an ObjProxy without them getting read through a proxy themselves
class PlainWrapper {
    constructor(obj) {
        this.obj = () => obj;
    }
}

// This is a proxy wrapper for an object with interpolated expressions within its children.
// Allows the user control to defer resolution of the property values to the child components
// that want to read them. Prevents the user control from being refreshed when any of the
// dependent values change and allows child components to refresh instead.
class ObjProxy {
    constructor(target) {
        this.target = () => target;
        this.handler = {
            //context: null, // Set by the evaluateObject method
            get: function (oTarget, oKey, receiver) {
                const item = Reflect.get(oTarget, oKey, receiver);

                if (item instanceof ObjFunc)
                    return item.func(this.context, careHelpfulFunctions);
                else if (item === undefined || item === null)
                    return item;
                else if (item instanceof PlainWrapper)
                    return item.obj();
                else if (typeof item === 'object')
                    return new Proxy(item, this);
                else
                    return item;
            },
            set: function (oTarget, oKey, oValue, receiver) {
                return Reflect.set(oTarget, oKey, oValue, receiver);
            },
            has: function (oTarget, oKey) {
                return Reflect.has(oTarget, oKey);
            },
        };
        this.myproxy = () => new Proxy(target, this.handler);
    }
}

export {
    PlainWrapper,
    ObjProxy,
    ObjFunc,
};