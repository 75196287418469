<template>
    <div class="cc-tabs">
        <div class="cc-tabs-container" :style="{ backgroundImage: `url(${backgroundImage})`, minWidth:150+'px' }">
            <div class="cc-tabs-alltabs" :style="alltabs_style">
                <v-item-group :active-class="activeClass"
                              class="pa-2 cc-tab-item-group"
                              mandatory
                              :dark="dark"
                              v-model="selected_item">

                    <div v-for="item in items" :key="item.id" :class="getTabItemClass(item)">
                        <v-item v-if="!item.separator" v-slot="{ active, toggle }">
                            <v-card class="d-flex align-center mb-1 cc-tab-item pa-0"
                                    :dark="dark"
                                    height="40"
                                    elevation="0"
                                    @click="selectTab(item, toggle)">

                                <div :class="getTabITemContentClass(item)">
                                    <slot name="label" v-bind="item">
                                        <span v-if="item.title">{{ item.title }}</span>
                                        <v-icon v-if="item.icon" small class="ml-2">{{ item.icon }}</v-icon>
                                    </slot>
                                </div>
                            </v-card>
                        </v-item>
                        <div v-else></div>
                    </div>

                </v-item-group>
            </div>
            <slot>
                <div class="cc-tabs-actions">
                    <v-btn class="cc-tabs-actions__button" small color="primary">{{addlabel || 'Add'}}</v-btn>
                    <v-btn class="cc-tabs-actions__button" small color="error">{{addlabel || 'Remove'}}</v-btn>
                </div>
            </slot>
        </div>

        <!--<v-card class="cc-tabs-group">
            <v-tabs-items :value="selected_item"
                          class="cc-tabs-group__items"
                          vertical>
                <slot>Empty</slot>
            </v-tabs-items>
        </v-card>-->
    </div>
</template>

<script>
    import utils from '../../../Shared/utils.jsx';

    export default {
        name: 'c-vertical-Tabs',
        data: function () {
            return {
                selected_item: null,
            }
        },
        props: {
            default_selected: null,
            value: null,
            items: null,
            addlabel: null,
            removelabel: null,
            backgroundImage: null,
            dark: true,
            maxHeight: null,
        },
        created() {
            //if (this.default_selected !== null)
            //    this.selected_item = this.items.findIndex(i => i.id == this.default_selected);
            if (this.value !== null && this.value !== undefined) {
                this.selected_item = this.value;
            }
        },
        watch: {
            value: function (newvalue) {
                this.selected_item = newvalue;
            },
        },
        computed: {
            activeClass: function () {
                return this.dark ? 'cc-tab-item_active_dark' : 'cc-tab-item_active_light';
            },
            alltabs_style: function () {
                if (this.maxHeight !== null)
                    return { maxHeight: this.maxHeight + 'px' };
                else
                    return null;
            }
        },
        methods: {
            getTabItemClass(item) {
                const c = {
                    'cc-tabs-items': true,
                    'cc-tabs-items__separator': !!item.separator,
                };

                if (this.dark)
                    c['cc-theme-dark'] = true;
                else
                    c['cc-theme-light'] = true;

                return c;
            },
            getTabITemContentClass(item) {
                return {
                    'cc-tab-item__content': !item.menu,
                    'cc-tab-item__button': !!item.menu,
                };
            },
            selectTab(item, toggle) {
                if (item.menu) {
                    this.$emit('menu', item);
                }
                else {
                    toggle();
                    this.$emit('change', this.selected_item);
                }
            }
        }
    }
</script>

<style scoped>
    .v-item-group {
        padding-right: unset !important;
    }

    .cc-tabs {
        display: flex;
    }

    .cc-tabs-container {
        display: flex;
        flex-direction: column;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .cc-tabs-alltabs {
        /*max-height: 300px;*/
        display: flex;
        flex-grow: 1;
        overflow-y: auto;
    }

    .cc-tab-item-group {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    .cc-tabs-items__separator {
        flex-grow: 1;
    }

    .cc-tabs-actions {
        display: flex;
        justify-content: center;
    }

    .cc-tabs-actions__button {
        flex: 1 1 0;
    }

    .cc-tabs-group {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        min-width: 250px;
    }

    .cc-tabs-group__items {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 5px;
    }

    .cc-tabs-items {
        margin: 0;
    }

    .cc-theme-dark {
        color: silver;
    }

    .cc-theme-light {
        color: gray;
    }

    /*color: silver;*/

    .cc-tab-item {
        padding: 5px;
        border-radius: 5px 0 0 5px;
        cursor: pointer;
        background: rgba(0, 0, 0, 0);
    }

    .cc-tab-item_active_dark {
        color: white;
        background-color: var(--v-cyan-base);
    }

    .cc-tab-item_active_light {
        color: var(--v-primary-base);
        background-color: var(--v-cyan-base);
    }

    .cc-tab-item__content {
        width: 100%;
        display: flex;
        padding-left: 5px;
        padding-right: 5px;
        justify-content: space-between;
    }

    .cc-tab-item__button {
        width: 100%;
        display: flex;
        padding-left: 0;
        padding-right: 0;
        justify-content: space-between;
    }

    .cc-tabs-alltabs::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,0.3);
        border-radius: 3px;
        background-color: #e0e0e0;
    }

    .cc-tabs-alltabs::-webkit-scrollbar {
        width: 8px;
        background-color: #e0e0e0;
        cursor: pointer;
    }

    .cc-tabs-alltabs::-webkit-scrollbar-thumb {
        border-radius: 3px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #2C92FF;
        cursor: pointer;
    }
</style>