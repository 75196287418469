import Vue from 'vue';
import Vuetify from 'vuetify';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';
import filters from '../../../Shared/filters';
import computed from '../../../Shared/computed';
import BaseComponent from '../BaseComponentMixin.jsx';
import EventBus from '@/Application/event-bus.js';
import careHelpfulFunctions from '../../careHelpfulFunctions.jsx';

/* We need a draggable dialog. Here is one possible solution:
 * https://github.com/vuetifyjs/vuetify/issues/4058
 * 
 * We also need to support resizing, if I find something, I'll paste here.
 * 
 * */

const busyIcon = {
    render(h) {
        return (
            <v-icon
                small
                color="white"
                style={{ visibility: utils.global_variables.api_busy_indicator ? 'visible' : 'hidden' }}>
                mdi mdi-cloud-download
            </v-icon>
        );
    }
};

Vue.component('display-basic-confirm-modal', {
    components: {
        busyIcon
    },
    data: () => ({
        vars: {},
        value: '',
    }),
    props: {
        name: '',
        root: null,
        type: null,
        parentType: '',
        isbase: true,
        action: null,
        context: null,
        scopeitems: null,
        controlscope: null,

        okay: null,
        cancel: null,
        accept: null,
    },
    created() {
        if (this.action.ActionData && this.action.ActionData.Vars) {
            const varsexpn = utils.compileObject(this, this.action.ActionData.Vars);
            try {
                this.vars = utils.evaluateObject(varsexpn, this.action.context);
            }
            catch (e) {
                utils.log('error evaluating Vars: ' + e);
            }
        }

        if (this.action.ActionData && this.action.ActionData.Javascript)
            try {
                const api = utils.apiWrapper; // apiService;
                const chf = careHelpfulFunctions;
                this.Javascript = eval("new (" + this.action.ActionData.Javascript + ")(null, null, api, this, chf);");

                for (let key in this.Javascript)
                    if (typeof this.Javascript[key] === 'function')
                        this.Javascript[key] = this.Javascript[key].bind(this.Javascript);
            }
            catch (e) {
                utils.warn(`Failed evaluating Javascript in ${this.name} ${this.type}: ${this.action.ActionData.Javascript}`, e);
            }


        // Subscribe to a UIAction_Close event, which then emits the close event
        // which is handled within ActionService.jsx, where it imbeds <dialog-basic-confirm-modal>
        utils.debug(`DialogBasicConfirmModal ${this.name} ${this._uid} $on ActionClose`);
        this.$on('Action-Close', this.performClose);
        EventBus.$on(`Action-Close:${this.name}`, this.performClose);
        EventBus.$on(`Action-Close`, this.performClose);
    },
    destroyed() {
        utils.debug(`DialogBasicConfirmModal ${this.name} ${this._uid} $off ActionClose`);
        this.$off('Action-Close', this.performClose);
        EventBus.$off(`Action-Close:${this.name}`, this.performClose);
        EventBus.$off(`Action-Close`, this.performClose);
    },
    mounted() {
    },
    computed: {
        ...computed,
        Base: function () {
            return this;
        },
        Name: function () {
            return this.name;
        },
        Root: function () {
            return this.root ? this.root._self : this;
        },
        Vars: function () {
            return this.vars;
        },
        title: function () {
            return this.action.title ? utils.evaluate(this.action.title, this.action.context) : '';
        },
        message: function () {
            return this.action.message ? utils.evaluate(this.action.message, this.action.context) : '';
        },
        okayButtonText: function () {
            return this.action.okaybuttontext ? utils.evaluate(this.action.okaybuttontext, this.action.context) : '';
        },
        cancelButtonText: function () {
            return this.action.cancelbuttontext ? utils.evaluate(this.action.cancelbuttontext, this.action.context) : '';
        }
    },
    methods: {
        ...methods,
        ...filters,
        async performClose(action) {
            utils.log(' ** Close Action Received by DialogBasicConfirmModal **');

            this.$emit('closeme');

            try {
                await utils.success(action);
            } catch (e) { }

            // Complete the promise for the executeAction method
            action.FinishFunc(true);
        },
        finishRenderHandler() { },
        clickedOutside(e) {
            e.stopPropagation();
            e.preventDefault();
        }
    },
    render(h) {
        const dialogButtons = [];
        if (this.cancelButtonText) dialogButtons.push((<v-btn color="primary" elevation={0} outlined on-click={(e) => this.$emit('closeme', { Success: false, DialogResult: this.cancelButtonText })}>{this.cancelButtonText}</v-btn>));
        if (this.okayButtonText) dialogButtons.push((<v-btn color="primary" elevation={0} on-click={(e) => this.$emit('closeme', { Success: true, DialogResult: this.okayButtonText })}>{this.okayButtonText}</v-btn>));

        let content = (
            <v-card key={this.key}>
                <v-app-bar flat color="rgba(0, 0, 0, 0)" style={{ backgroundColor: 'var(--v-navigation-base)', maxHeight: '60px', height: '60px' }}>
                    <v-toolbar-title class="title white--text pl-0">
                        {this.title}
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <busyIcon></busyIcon>

                    <v-btn color="white" icon on-click={(e) => this.$emit('closeme')}>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-app-bar>

                <v-card-text class="mt-2">
                    {this.message}
                </v-card-text>

                <v-card-actions style="justify-content: flex-end;">
                    {dialogButtons}
                </v-card-actions>
            </v-card>
        );

        return content;
    }
});
