import Vue from 'vue';
import HorizontalStack from './HorizontalStack.jsx';
import VerticalStack from './VerticalStack.jsx';
import BasicButton from './BasicButton.jsx';
import Text from './Text.jsx';
import Icon from './Icon.jsx';
//import SelectList from './SelectList.jsx';
import BasicForm from './BasicForm.jsx';
import BasicGrid from './BasicGrid.jsx';
import TreeView from './TreeView.jsx';
import DefaultUnknown from './DefaultUnknown.jsx';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

Vue.component('control-container-dsgn', {
    data: function () {
        return {
        }
    },
    computed: {
        Root: function () {
            return this.root._self;
        },
        selected$: function () {
            return this.Root.SelectedNode && this.Root.SelectedModel == this.designmodel;
        }
    },
    methods: {
        ...methods,
        handleClick(e) {
            this.Root.SelectNode(this);
            e.cancelBubble = true;
            e.stopPropagation();
        },
        DesignModel: function () {
            return this.designmodel;
        },
    },
    props: {
        name: '',
        root: null,
        designmodel: null,
        controlData: {}
    },
    render(h) {
        if (!this.controlData.Controls)
            return <div></div>;

        let items = [];
        for (var i = 0; i < this.controlData.Controls.length; i++)
        {
            const control = this.controlData.Controls[i];
            let DynamicControl = utils.getDynamicComponent(h, control);

            if (!DynamicControl)
                DynamicControl = 'default-unknown';

            DynamicControl += '-dsgn';

            items.push(
                <DynamicControl type={control.ControlType} name={control.ControlData.Name} root={this.root} designmodel={control} controlData={control.ControlData}>
                </DynamicControl>
            );
        }
        return (
            <div
                class={{ 'designer-container': true, selected: this.selected$ }}
                style={{ ...utils.getSize(this.controlData.SizeOptions) }}
                on-click={(e) => this.handleClick(e)}>
                <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
                {this.name}<br />
                {items}
            </div>
        );
    }
});