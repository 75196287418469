import Vue from 'vue';
import EventBus from '@/Application/event-bus';
import token from '@/Services/token';
import utils from '@/Shared/utils';
import $q from '@/Services/promiseHelper';
import { appSettings } from '@/Shared/appSettings';
import { CreateWebSocketConnection } from '@/Services/webSocketConnectionFactory';

export default {
    connection: null,
    isConnecting: false,
    ensureConnectionTimer: null,
    connectionMessageSent: false,
    keepDisconnected: false,
    apiRequestQueue: [],
    concurrentApiRequests: 0,
    MAX_CONCURRENT_API_REQUESTS: 6,
    accessTokenAssignedBound: null,

    //This method is only called from the App.Vue when the site gets created so we can subscribe
    async created() {
        this.accessTokenAssignedBound = this.accessTokenAssigned.bind(this);
        appSettings.careWebSocketService = this;
        EventBus.$on("AccessTokenAssigned", this.accessTokenAssignedBound);
        await this.startEnsureConnectionTimer();
    },
    //This method is only called from the App.Vue when the site gets destroyed so we can unsubscribe
    destroyed() {
        EventBus.$off("AccessTokenAssigned", this.accessTokenAssignedBound);
    },
    accessTokenAssigned() {
        if (!token.AccessToken() || (this.connection && this.hasUserCustomerOrBandChanged())) {
            // if we no longer have a token, or the user/customer/band has changed immediately kill connection (don't wait for ensureConnectionTimer)
            utils.debug("WebSocket ACCESSTOKEN_ASSIGNED change");
            this.disconnect();
        }

        if (this.IsConnected()) {
            // update access token
            this.connection.RefreshToken(token.AccessToken()).catch(() => {
                // if the RefreshToken didn't work for any reason, disconnect so we don't have a connection using invalid/wrong token. 
                // ensureConnectionTimer will reestablish connection with most recent token
                utils.debug("WebSocket RefreshToken fail");
                this.disconnect();
            });
        }
    },
    hasUserCustomerOrBandChanged() {
        return (this.connection?.connectionData?.bandId != token.BandID() ||
            this.connection?.connectionData?.userId != token.UserID() ||
            this.connection?.connectionData?.customerId != token.CustomerID());
    },
    async ensureConnection() {
        if (this.isConnecting) {
            console.log('already trying a connection');
            return;
        }
        // if the connection has become disconnected/unhealthly, then formally disconnect
        // Note we purposefully use IsDisconnected and not !IsConnected, to allow for connecting and reconnecting states
        if (this.connection && this.IsDisconnected()) {
            utils.error('Websocket disconnected.');
            this.disconnect()
        }

        // create a new connection
        if (!this.connection && !this.keepDisconnected) {
            // don't establish a websocket until we have a band (customer is selected)
            if (token.AccessToken() && token.BandID()) {
                try {
                    utils.error('Attempting reconnect');

                    // If the connection fails for some reason (i.e. error, timeout, etc) the connection state will become "disconnected" and ensureConnection will formally disconnect and attempt to reconnect 
                    var baseUrl = token.GetApiBaseURL();
                    this.isConnecting = true;
                    this.connection = await CreateWebSocketConnection(baseUrl);
                    await this.connection.Connect(baseUrl,
                        //queryParams
                        {},
                        // Connection Notice callback
                        (_, __, connectionId, ___) => {

                            this.isConnecting = false;

                            utils.log('Websocket Notice Event, WebSocketConnectionId: ' + connectionId);
                            appSettings.WebSocketConnectionId = connectionId;

                            // Publishing connected event here, as this is positive confirmation the websocket is connected, and severside session is setup
                            utils.debug(`webSocketService Emitting WebSocketConnected`);
                            this.connectionMessageSent = true;
                            EventBus.$emit('WebSocketConnected');
                        },
                        // channelEventCallback
                        (value) => {
                            //var eventData = JSON.parse(value);
                            for (let i = 0; i < value.length; i++) {
                                const eventData = JSON.parse(value[i]);
                                utils.debug('signalR Event: ' + eventData.EventName + ' (' + eventData.EventId + '): ' + value[i]);
                                EventBus.$emit("ChannelEvent", eventData);
                            }
                        },
                        // rtEventCallback
                        (value) => {
                            //var eventData = JSON.parse(value);
                            const batch = [];
                            for (let i = 0; i < value.length; i++) {
                                const eventData = JSON.parse(value[i]);
                                batch.push(eventData);

                                utils.debug('signalR RTEvent: ' + value[i]);
                                EventBus.$emit("RealTimeEvent", eventData);
                            }
                            EventBus.$emit('RealTimeBatch', batch);
                        },
                        // On close callback
                        this.forceRenegotiate.bind(this)
                        );
                    this.isConnecting = false;
                }
                catch (err) {
                    this.isConnecting = false;
                    utils.log('Websocket connection failed: ' + err.name + ', ' + err.message)
                    utils.log(err)

                    if (err.name == "Error" && (err.message.includes('Unauthorized') || err.message.includes('WebSocket failed to connect'))) {
                        this.stopEnsureConnectionTimer();
                        // Whenever we run into a token problem (such as an expired token), force logout (which will stop the refresh timer too) and display login dialog                        
                        EventBus.$emit("Logout");
                    }

                }
            }
        }        
    },
    forceRenegotiate() {
        // Stop the ensure connection timer. We're going to call the ensure connection method directly, so we'll 
        // stop it to avoid having it be called twice.
        this.stopEnsureConnectionTimer();

        // Force the old websocket to disconnect immediately
        this.disconnect();

        // Set the websocket to reconnect. Normally that just sets the keep disconnected flag to false and waits for the
        // ensure connection timer, but we'll intentionally call the ensure connection timer to make sure it happens quickly
        this.ensureConnection();

        // Restart the normal ensure connection timer.
        this.startEnsureConnectionTimer();
    },

    disconnect() {
        utils.debug("WebSocket Service disconnect() called");

        if (this.connection) {
            let oldConnection = this.connection;
            this.connection = null;
            // only send WEB_SOCKET_DISCONNECTED message if a WEB_SOCKET_CONNECTED was sent
            if (this.connectionMessageSent) {
                EventBus.$emit('WebSocketDisconnected');
                this.connectionMessageSent = false;
            }
            return oldConnection.Disconnect();
        }
    },
    IsConnected() {
        if (this.isConnecting)
            return false;
        return this.connection?.IsConnected();
    },
    IsDisconnected() {
        if (this.isConnecting)
            return false;
        return this.connection?.IsDisconnected();
    },
    async startEnsureConnectionTimer() {
        if (!this.ensureConnectionTimer) {
            await this.ensureConnection(); // immediately call so we don't have to wait for the 1 second interval to make initial connection
            this.ensureConnectionTimer = setInterval(this.ensureConnection.bind(this), 1000);
        }
    },
    stopEnsureConnectionTimer() {
        if (this.ensureConnectionTimer) {
            clearInterval(this.ensureConnectionTimer);
            this.ensureConnectionTimer = null;
        }
        this.disconnect();
    },
    APIRequest(requestObj) {
        var promise = $q.defer();

        this.apiRequestQueue.push({
            requestObj: requestObj,
            promise: promise
        });

        this.processQueue();

        return promise.promise;
    },
    processQueue() {
        if (this.concurrentApiRequests >= this.MAX_CONCURRENT_API_REQUESTS) {
            // if we are executing too many requests concurrently then wait and when one of the requests finishes it will call processQueue() to trigger the next one to run
            utils.log("Throttling WebSocketAPI Request (exceeded concurrent limit: " + this.MAX_CONCURRENT_API_REQUESTS + "), # in queue:" + this.apiRequestQueue.length);
            return;
        }

        var queueItem = this.apiRequestQueue.shift(); // pop the first item off the array

        if (queueItem === undefined) // reached last element
            return;

        // should have been verified before invoking this method
        if (!this.IsConnected()) {
            queueItem.promise.reject({
                status: 503, // Service Unavailable
                fullResponse: "websocket hub not active"
            });

            return;
        }

        this.concurrentApiRequests++;

        this.connection.ApiRequest(queueItem.requestObj)
            .then(response => {
                this.decConcurrentApiRequests();

                var statusCode = response.StatusCode ? response.StatusCode : response.statusCode;
                var responseBody = response.ResponseBody ? response.ResponseBody : response.responseBody;

                if (statusCode == 200) {
                    queueItem.promise.resolve({
                        data: responseBody
                    });
                } else {
                    queueItem.promise.reject({
                        status: statusCode,
                        fullResponse: response
                    });
                }
            })
            .catch(error => {
                this.decConcurrentApiRequests();

                queueItem.promise.reject({
                    status: 502, // Bad Gateway
                    fullResponse: error
                });
            });
    },
    decConcurrentApiRequests() {
        if (this.concurrentApiRequests > 0)
            this.concurrentApiRequests--;

        this.processQueue();
    },
    Subscribe(channelName) {
        if (this.IsConnected())
            this.connection.Subscribe(channelName);
    },
    StartRealTimeMonitor(filter) {
        if (this.IsConnected())
            this.connection.StartRealTimeMonitor(filter);
    },
    StopRealTimeMonitor() {
        if (this.IsConnected())
            this.connection.StopRealTimeMonitor();
    },
    AddRealTimeMonitorView(viewName, args) {
        if (this.IsConnected())
            return this.connection.AddRealTimeMonitorView(viewName, args);
    },
    RemoveRealTimeMonitorView(viewName, args) {
        if (this.IsConnected())
            return this.connection.RemoveRealTimeMonitorView(viewName, args);
    },
    GetNamedListForRealTime(listName, id, sortCol, descending, start, length, columns, filters) {
        if (this.IsConnected())
            return this.connection.GetNamedListForRealTime(listName, id, sortCol, descending, start, length, columns, filters);
    },
    RemoveNamedListForRealTime(listName, id) {
        if (this.IsConnected())
            return this.connection.RemoveNamedListForRealTime(listName, id);
    },
    GetTestResponse() {
        if (this.IsConnected())
            return this.connection.GetTestResponse();
    },
    TestDisconnect(value) {
        if (value) {
            this.disconnect();
            this.keepDisconnected = true;
        } else
            this.keepDisconnected = false;
    },
};

