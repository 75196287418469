import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import utils from '../../Shared/utils.jsx';
import cDateRangePicker from '@/Application/components/vuecontrols/DateRangePicker';
import careHelpfulFunctions from '@/Shared/careHelpfulFunctions';



Vue.component('date-range-picker', {
    mixins: [BaseComponent],
    components: {cDateRangePicker},
    data: function () {
        return {
            setValueFunction: null,
            allowTimeRenderer: null,
            timePickerIncrementRenderer: null,
            isDisabledRenderer: null,
            placeholderRenderer: null,
            defaultRenderer: null,
            allowFutureDateRenderer: null,
            publishedModel: null,
            allowTime: null
        }
    },
    created() {
        this.allowTime = this.controlData.AllowTime;
    },
    computed: {
        publishField: {
            set(value){
                if(this.setValueFunction === null && this.controlData.PublishField){
                    let vueSet = utils.helpers.convertSetValueToVueSet(this.controlData.PublishField, 'value');
                    this.setValueFunction = new Function('value', 'context', 'util', 'vue', `with (context) { ${vueSet} }`);
                }
                if(this.setValueFunction) {
                    this.setValueFunction(value, this, careHelpfulFunctions, Vue)
                }
                
                this.publishedModel = value;
            },
            get() {
                return this.publishedModel;
            }
        },
        isDisabled: function() {
            try {
                if (this.isDisabledRenderer === null && this.controlData.IsDisabled)
                    this.isDisabledRenderer = utils.compile(this, this.controlData.IsDisabled);
                if (this.isDisabledRenderer)
                    return utils.evaluate(this.isDisabledRenderer, this);
                else
                    return false;
            }
            catch (e)
            {
                utils.warn('IsDisabled could not evaluate expression: ' + this.controlData.IsDisabled + '; ' + e);
                return '';
            }
        },
        default: function() {
            try {
                if (this.defaultRenderer === null && this.controlData.Default)
                    this.defaultRenderer = utils.compile(this, this.controlData.Default);
                if (this.defaultRenderer)
                    return utils.evaluate(this.defaultRenderer, this);
                else
                    return {};
            }
            catch (e)
            {
                utils.warn('Default could not evaluate expression: ' + this.controlData.Default + '; ' + e);
                return '';
            }
        },
    },
    methods: {
        async preRenderComplete() {
            this.finishRenderHandler(this);
        },
        async applyDateRangeHandler(date) {
            if(this.controlData.PublishField)
                this.publishField = date;
            await utils.executeAndCompileAllActions(this.controlData.ApplyActions, date, this);

        },
        async clearDateRangeHandler() {
            await utils.executeAndCompileAllActions(this.controlData.ClearActions, null, this);
        },
        SetTimePicker(value) {
            this.allowTime = value;
        },
        ToggleIsTimePicker() {
            this.allowTime = !this.allowTime
        }
    },
    render(h) {
        if(!this.todisplay)
            return null;

        try {
            return <c-Date-Range-Picker
                name={this.name}
                root={this.root}
                parentType={this.parentType}
                controlData={this.controlData}
                scopeitems={this.scopeitems}
                controlscope={this.controlscope}
                type={this.controlData.Type}
                allowTime={this.allowTime}
                timePickerIncrement={this.controlData.TimePickerIncrement}
                isDisabled={this.isDisabled}
                default={this.default}
                placeholder={this.controlData.PlaceHolder}
                allowFutureDate={this.controlData.AllowFutureDate}
                futureDateLimit={this.controlData.FutureDateLimit !== null && this.controlData.FutureDateLimit !== undefined ? this.controlData.FutureDateLimit : null }
                pastDateLimit={this.controlData.PastDateLimit !== null && this.controlData.PastDateLimit !== undefined ? this.controlData.PastDateLimit : null }
                allowPastDate={this.controlData.AllowPastDate}
                on-apply-date-range={this.applyDateRangeHandler}
                on-clear-date-range={this.clearDateRangeHandler}
            ></c-Date-Range-Picker>
        }
        catch (e) {
            utils.error('DateRangePicker Render failed', e);
            return <div>DateRangePicker Failed to Render {e}</div>;
        }
    }
});