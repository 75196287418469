import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

Vue.component('form-text-editor', {
    data: function () {
        return {
            israw: false,
            visible_keys: {},
            type: 'FormField',
        }
    },
    created() {
    },
    computed: {
        ...utils.forms.computed,
    },
    methods: {
        ...methods,
        sync(prop, value) {
            if (this.israw)
                Vue.set(this.cmodel, prop, value);
            else {
                let obj;
                try {
                    obj = JSON.parse(value);
                }
                catch (e) {
                    utils.warn('Input text editor failed to convert value to object: ' + e);
                    return;
                }

                Vue.set(this.cmodel, prop, obj);
            }
        },
        populateDefaultTemplate(e, template) {
            Vue.set(this.cmodel, this.name, template);
        },
    },
    props: {
        name: '',
        schemakey: '',
        root: null,
        schema: null,
        cmodel: null,
        extra: null,
        readonly: false,
        path: null,
        child: 0,
        uniquerowkey: null,
    },
    render(h) {
        if (!this.Condition)
            return null;

        let desc;
        if (this.schema.description)
            desc = <i class="mdi mdi-information" title={this.schema.description} style={{ color: "silver", fontSize: "small", marginLeft: "3px" }}></i>;

        const name = <span style={{ marginLeft: (this.child * 15) + "px" }}>{this.name}</span>;

        const visible_key = [...this.path, this.schemakey].join('.');
        const vis = utils.getVisibility(this, visible_key, 'false');
        const icon = vis ? <i class="mdi mdi-menu-down"></i> : <i class="mdi mdi-menu-right"></i>;
        const openclose = <span style={{ fontSize: "large", cursor: "pointer" }} on-click={() => utils.toggleVisible(this, visible_key, 'false')}>{icon}</span>;

        let value;
        let lang;
        if (this.schema.formatData) {
            this.israw = this.schema.formatData.IsRaw;
            lang = this.schema.formatData.Language || 'javascript';
        }
        else {
            this.israw = false;
            lang = 'json';
        }

        if (this.israw)
            value = this.cmodel[this.schemakey] || '';
        else
            value = this.cmodel[this.schemakey] ? JSON.stringify(this.cmodel[this.schemakey], null, 4) : '';

        let input;
        let link;
        let height = '100px';
        if (this.schema.formatData && this.schema.formatData.Height)
            height = this.schema.formatData.Height + 'px';

        let controls;
        if (this.schema.formatData && this.schema.formatData.Controls && this.schema.formatData.Controls.length > 0) {
            controls = [];
            for (let i = 0; i < this.schema.formatData.Controls.length; i++) {
                const c = this.schema.formatData.Controls[i];

                let DynamicControl = utils.getDynamicComponent(h, c);
                if (!DynamicControl)
                    DynamicControl = 'default-unknown';

                if (!c.$objectId) c.$objectId = utils.generateUUID();

                controls.push(
                    <DynamicControl
                        key={c.$objectId}
                        type={c.ControlType}
                        name={c.ControlData ? c.ControlData.Name : ''}
                        root={this.root}
                        designmodel={c}
                        parentType="HorizontalLayout"
                        controlData={c.ControlData}
                        controlName={c.Name}
                    >
                    </DynamicControl>
                );
            }
        }

        if (vis) {
            if (this.readonly)
                input = (
                    <td style={{ width: "100%", height: "100px" }}>
                        <textarea cols="40" disabled="disabled">{value}</textarea>
                    </td>
                );
            else {
                if (controls)
                    controls = <div style="display: flex;">{controls}</div>;

                input = (
                    <td style={{ width: "100%", height: height }}>
                        {controls}
                        <div style={{ resize: "vertical", width: "97%", height: height, borderStyle: "solid", borderColor: "silver", borderWidth: "1px", padding: "5px", overflow: "auto", position: "relative" }}>
                            <ace-editor
                                editorId={'Editor_' + this.uniquerowkey}
                                lang={lang}
                                on-change-content={(text) => this.sync(this.schemakey, text)}
                                content={value || ''}>
                            </ace-editor>
                        </div>
                    </td>
                );
                if (this.schema.formatData && this.schema.formatData.DefaultTemplate)
                    link = (
                        <span style={{ cursor: "pointer" }} on-click={(e) => this.populateDefaultTemplate(e, this.schema.formatData.DefaultTemplate)}>
                            <i class="mdi mdi-chevron-right-box-outline" title="Set to default template" style={{ color: "lightblue", fontSize: "small", marginLeft: "2px" }}></i>
                        </span>
                    );
            }
        }
        else
            input = (
                <td style={{ width: "100%", borderStyle: "solid", borderColor: "silver", borderWidth: "1px" }}>
                    <span>{value.length} byte(s)</span>
                </td>
            );

        return (
            <tr>
                <td style={{ width: "1px", verticalAlign: "top", whiteSpace: "nowrap" }}>
                    {name} {openclose} {desc} {link}
                </td>
                {input}
            </tr>
        );
    }
});