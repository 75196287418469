import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

Vue.component('form-anyof', {
    data: function () {
        return {
            condition_expn: null
        }
    },
    created() {
    },
    computed: {
        ...utils.forms.computed,
    },
    methods: {
        ...methods,
        async sync(prop, value) {
            let item;
            if (value == '!!null!!')
                item = {};
            else {
                let schema = this.schema.anyOf[value];
                schema = utils.schema.resolve_Of(schema);
                item = utils.schema.getDefaultModel(schema);
            }

            if (Array.isArray(this.cmodel)) {
                if (prop < this.cmodel.length)
                    Vue.set(this.cmodel, prop, item);
                else {
                    while (this.cmodel.length < prop)
                        this.cmodel.push(null);

                    this.cmodel.push(item);
                }
            }
            else
            {
                Vue.set(this.cmodel, prop, item);
            }
        },
        extractDocument() {
            debugger;
        }
    },
    props: {
        name: '',
        schemakey: '',
        root: null,
        schema: null,
        cmodel: null,
        extra: null,
        readonly: false,
        child: 0,
        navigateTo: null,
        canNavigate: false,
        extractor: null,
        propertygrid: false,
    },
    render(h) {
        if (!this.Condition)
            return null;

        let desc;
        if (this.schema.description)
            desc = <i class="mdi mdi-information" title={this.schema.description} style={{ color: "silver", fontSize: "small", marginLeft: "3px" }}></i>;

        const name = <span style={{ marginLeft: (this.child * 15) + "px" }}>{this.name}</span>;
        if (this.readonly)
            return (
                <tr>
                    <td style={{ width: "1px" }}>
                        <span style={{ whiteSpace: "nowrap" }}>{name} {desc}</span>
                    </td>
                    <td class={{ "property-grid": this.propertygrid }}>
                        <v-text-field solo single-line dense hide-details style={{ width: "100%" }} value={this.cmodel[this.schemakey]} disabled></v-text-field>
                    </td>
                </tr>
            );

        const items = [];
        const schema = this.schema;
        let value;
        let found = false;
        for (var i = 0; i < schema.anyOf.length; i++) {
            const item = schema.anyOf[i];

            if (this.cmodel[this.schemakey] && (this.cmodel[this.schemakey].$typeSchema === item.Id)) {
                found = true;
                value = i;
            }

            items.push({ text: item.title, value: i });
        }

        items.splice(0, 0, { text: '-None-', value: "!!null!!" });

        let navigate;
        let extract;
        if (this.canNavigate && found) {
            navigate = <i
                title="Edit"
                class="mdi mdi-open-in-new"
                style={{ cursor: "pointer", color: "blue", marginLeft: "3px" }}
                on-click={() => this.navigateTo(this, this.name)}></i>;

            if (this.extractor && (this.schema.Id == '/Apps/Schema/AnyOf/schema/public/Platform.Schema.BaseTypes.v1/DynamicControl' ||
                                   this.schema.Id == '/Apps/Schema/AnyOf/schema/public/Platform.Schema.BaseTypes.v1/UIAction'))
                extract = (
                    <td>
                        <i
                            title="Extract Document"
                            class="mdi mdi-share"
                            style={{ cursor: "pointer", color: "blue", marginLeft: "3px" }}
                            on-click={() => this.extractor(this, this.name)}></i>
                    </td>
                );
        }

        return (
            <tr>
                <td style={{ width: "1px" }}>
                    <span style={{ whiteSpace: "nowrap" }}>
                        {name} {desc} {navigate}
                    </span>
                </td>
                <td class={{ "property-grid": this.propertygrid }}>
                    <v-select
                        solo dense hide-details
                        menu-props={{ offsetY: true, auto: true }}
                        items={items}
                        value={value}
                        min-width="160px"
                        style={{ width: "100%" }}
                        on-change={(value) => this.sync(this.schemakey, value)}>
                    </v-select>
                </td>
                {extract}
            </tr>
        );
    }
});