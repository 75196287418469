import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

Vue.component('string-lookup-list', {
    data: function () {
        return {
            condition_expn: null,
            list: null,
            lookupurl: null,
            lookupexpn: null,
            displayexpn: null,
            displayfield: null,
            valueexpn: null,
            valuefield: null,
        }
    },
    created() {
        if (this.schema.formatData.LookupType === 'URL' || (!this.schema.formatData.LookupType && this.schema.formatData.LookupURL))
            this.lookupurl = utils.compile(this, this.schema.formatData.LookupURL);
        else if (this.schema.formatData.LookupType === 'Interpolated')
            this.lookupexpn = utils.compileExpression(this, this.schema.formatData.LookupInterpolatedExpression);

        const modelAs = this.schema.formatData.LookupModelAs || 'cmodel';
        if (this.schema.formatData.DisplayType === 'Expression')
        //  this.displayexpn = utils.compileExpression(this, this.schema.formatData.DisplayExpression);
            this.displayexpn = new Function('context', modelAs, 'with (context) return ' + this.schema.formatData.DisplayExpression + ';');
        else
            this.displayfield = new Function(modelAs, 'return ' + modelAs + '.' + (this.schema.formatData.DisplayField || 'Name'));

        if (!this.schema.formatData.ValueType || this.schema.formatData.ValueType === 'Expression')
        //  this.valueexpn = utils.compileExpression(this, this.schema.formatData.ValueExpression); //new Function(modelAs, 'return ' + this.schema.formatData.ValueExpression);
            this.valueexpn = new Function('context', modelAs, 'with (context) return ' + this.schema.formatData.ValueExpression + ';');
        else
            this.valuefield = new Function(modelAs, 'return ' + modelAs + '.' + (this.schema.formatData.ValueField || 'Value'));
    },
    async mounted() {
        this.Refresh();
    },
    computed: {
        ...utils.forms.computed,
    },
    methods: {
        ...methods,
        sync(prop, value) {
            let v = value;
            if (v == '!!null!!') v = null;

            if (Array.isArray(this.cmodel))
            {
                if (prop < this.cmodel.length)
                    Vue.set(this.cmodel, prop, v);
                else {
                    while (this.cmodel.length < prop)
                        this.cmodel.push(null);

                    this.cmodel.push(v);
                }
            }
            else
            {
                if (!(prop in this.cmodel))
                    Vue.set(this.cmodel, prop, v);
                else
                    this.cmodel[prop] = v;
            }
        },
        async Refresh() {
            if (this.lookupurl)
            {
                this.list = await utils.api.get(utils.evaluate(this.lookupurl, this), false, this.schema.formatData.CacheLookupResult);
            }
            else if (this.lookupexpn)
            {
                this.list = utils.evaluate(this.lookupexpn, this, false);
            }

            if (this.list && typeof this.list === 'object' && !Array.isArray(this.list))
            {
                // Convert our object into an array using Key and Value
                this.list = Object.keys(this.list).reduce((out, key) => {
                    out.push({
                        Key: key,
                        Value: this.list[key]
                    });
                    return out;
                }, []);
            }
        }
    },
    props: {
        name: '',
        schemakey: '',
        root: null,
        schema: null,
        cmodel: null,
        extra: null,
        readonly: false,
        child: 0,
        propertygrid: false,
    },
    render(h) {
        if (!this.Condition)
            return null;

        let desc;
        if (this.schema.description)
            desc = <i class="mdi mdi-information" title={this.schema.description} style={{ color: "silver", fontSize: "small", marginLeft: "3px" }}></i>;

        const name = <span style={{ marginLeft: (this.child * 15) + "px" }}>{this.name}</span>;

        if (!this.list)
            return (
                <tr>
                    <td style={{ width: "1px" }}>
                        <span style={{ whiteSpace: "nowrap" }}>{name} {desc}</span>
                    </td>
                    <td class={{ "property-grid": this.propertygrid }}>
                        <v-text-field solo single-line dense hide-details append-icon="mdi mdi-refresh mdi-animate-spin" value={this.cmodel[this.schemakey]} on-input={(value) => this.sync(this.schemakey, value)}></v-text-field>
                    </td>
                </tr>
            );

        // <v-progress-linear indeterminate={true}></v-progress-linear>

        const items = [];
        const choice = this.cmodel[this.schemakey];
        const schema = this.schema;

        if (schema.formatData && schema.formatData.AllowCustomInput) {
            for (var i = 0; i < this.list.length; i++) {
                const item = this.list[i];
                const value = this.valueexpn ? this.valueexpn({ ...this }, item) : this.valuefield(item);
                const txt = this.displayexpn ? this.displayexpn({ ...this }, item) : this.displayfield(item);

                items.push({
                    text: txt,
                    value: value,
                });
            }

            return (
                <tr>
                    <td style={{ width: "1px" }}>
                        <span style={{ whiteSpace: "nowrap" }}>{name} {desc}</span>
                    </td>
                    <td class={{ "property-grid": this.propertygrid }} style={{ whiteSpace: "nowrap" }}>
                        <v-combobox solo dense hide-details return-object={false} value={choice} items={items}
                            on-change={(e) => this.sync(this.schemakey, e.target ? e.target.value : e)}
                            on-keyup={(e) => this.sync(this.schemakey, e.target ? e.target.value : e)}>
                        </v-combobox>
                    </td>
                </tr>
            );
        }

        let found = false;
        for (var i = 0; i < this.list.length; i++) {
            const item = this.list[i];
            const value = this.valueexpn ? this.valueexpn({ ...this }, item) : this.valuefield(item);
            const txt = this.displayexpn ? this.displayexpn({ ...this }, item) : this.displayfield(item);

            if (choice == value)
                found = true;

            items.push({
                text: txt,
                value: value,
                disabled: false,
            });
        }

        if (schema.$allows_null)
            items.splice(0, 0, { text: "", value: "!!null!!", disabled: false });
        else if (!found)
            items.splice(0, 0, { text: "Please choose one", value: choice, disabled: true });

        return (
            <tr>
                <td style={{ width: "1px" }}>
                    <span style={{ whiteSpace: "nowrap" }}>{name} {desc}</span>
                </td>
                <td class={{ "property-grid": this.propertygrid }}>
                    <v-select
                        solo dense hide-details
                        menu-props={{ offsetY: true, auto: true }}
                        value={choice}
                        style={{ width: "100%" }}
                        items={items}
                        on-change={(value) => this.sync(this.schemakey, value)}>
                    </v-select>
                </td>
            </tr>
        );
    }
});