export default {
    primaryText: '#242424',
    secondaryText: '#424242',
    tertiarytext: '#616161',

    primary: '#6264A7',
    primaryActive: '#494B83',

    header: '#464775',
    stateBackground: '#3D3E66',
    lightBlueBackground: '#3D3E66',
    
}