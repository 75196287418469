import Vue from 'vue';
import BaseComponent from './baseFormMixin.jsx';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

Vue.component('sform-radios', {
    mixins: [BaseComponent],
    data: () => ({
    }),
    props: {
    },
    created() {
    },
    mounted() {
        this.loadComplete();
    },
    computed: {
        translatedHintText: function () {
            return this.Translate(this.hintText);
        },
    },
    methods: {
    },
    render(h) {
        let items;
        if (this.element.enum)
            items = this.element.enum.map(e => (
                <v-radio
                    key={e}
                    value={e}
                >
                    <translation-container slot="label" context={this} value={e}></translation-container>
                </v-radio>
            ));
        else if (this.element.titleMap)
            items = this.element.titleMap.map(e => (
                <v-radio
                    key={e.value}
                    value={e.value}
                >
                    <translation-container slot="label" context={this} value={e.name}></translation-container>
                </v-radio>
            ));

        let title = <translation-container context={this} value={this.labelText}></translation-container>;
        if (this.hintText)
            title = <span title={this.translatedHintText}>{title} <v-icon small color="silver">mdi mdi-information</v-icon></span>;

        let input =
            <div style="border-width: 1px; border-color: gray; border-style: solid; border-radius: 5px; padding: 5px 0 0 10px;" class="mb-2">
                {title}
                <v-radio-group
                    value={this.itemvalue}
                    rules={this.rules}
                    on-change={(value) => this.sync(value)}
                    on-blur={this.onBlur}
                    on-focus={this.onFocus}
                >
                    {items}
                </v-radio-group>
            </div>;

        return input;
    }
});