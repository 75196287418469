import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

Vue.component('form-input-multiline', {
    data: function () {
        return {
        }
    },
    created() {
    },
    computed: {
        ...utils.forms.computed,
    },
    methods: {
        ...methods,
        sync(prop, value) {
            let obj;
            try {
                obj = JSON.parse(value);
            }
            catch (e) {
                utils.warn('Input multiline failed to convert value to object: ' + e);
                return;
            }

            Vue.set(this.cmodel, prop, obj);
        },
    },
    props: {
        name: '',
        schemakey: '',
        root: null,
        schema: null,
        cmodel: null,
        extra: null,
        readonly: false,
        child: 0,
    },
    render(h) {
        if (!this.Condition)
            return null;

        let desc;
        if (this.schema.description)
            desc = <i class="mdi mdi-information" title={this.schema.description} style={{ color: "silver", fontSize: "small", marginLeft: "3px" }}></i>;

        const name = <span style={{ marginLeft: (this.child * 15) + "px" }}>{this.name}</span>;

        let value = this.cmodel[this.schemakey] ? JSON.stringify(this.cmodel[this.schemakey], null, 4) : '';

        let input;
        if (this.readonly)
            input = [<textarea cols="40" disabled="disabled">{value}</textarea>];
        else
            input = [<textarea cols="40" on-change={(e) => this.sync(this.schemakey, e.target.value)}>{value}</textarea>];

        return (
            <tr>
                <td style={{ width: "1px", verticalAlign: "top" }}>
                    {name} {desc}
                </td>
                <td>
                    {input}
                </td>
            </tr>
        );
    }
});