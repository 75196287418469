import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import EventBus from '../event-bus.js';
import utils from '../../Shared/utils.jsx';

Vue.component('broadcast-listener', {
    mixins: [BaseComponent],
    data: function () {
        return {
            actions: [],
            actions_compiled: false,
        }
    },
    props: {},
    computed: {
    },
    created() {
        if (this.controlData.MessageType === 'System' && this.controlData.MessageName) {
            EventBus.$on(this.controlData.MessageName, this.invokeSystemActions);
            this.$on(this.controlData.MessageName, this.invokeSystemActions);
        }
        else if (this.controlData.MessageType === 'Action' && this.controlData.ActionType) {
            EventBus.$on(`Action-${this.controlData.ActionType}`, this.invokeActions);
            this.$on(`Action-${this.controlData.ActionType}`, this.invokeActions);
            if (this.controlData.Name)
                EventBus.$on(`Action-${this.controlData.ActionType}:${this.controlData.Name}`, this.invokeActions);
        }
    },
    //Mounted Replaced with preRenderComplete
    destroyed() {
        if (this.controlData.MessageType === 'System' && this.controlData.MessageName) {
            EventBus.$off(this.controlData.MessageName, this.invokeSystemActions);
            this.$off(this.controlData.MessageName, this.invokeSystemActions);
        }
        else if (this.controlData.MessageType === 'Action' && this.controlData.ActionType) {
            EventBus.$off(`Action-${this.controlData.ActionType}`, this.invokeActions);
            this.$off(`Action-${this.controlData.ActionType}`, this.invokeActions);
            if (this.controlData.Name)
                EventBus.$off(`Action-${this.controlData.ActionType}:${this.controlData.Name}`, this.invokeActions);
        }
    },
    methods: {
        async invokeSystemActions(data) {
            await utils.executeAndCompileAllActions(this.controlData.Actions, data, data?.context || this);
        },
        async invokeActions(action) {
            if (this.controlData.ActionType != 'Message' || (action && action.ActionData.Message && action.ActionData.Message == this.controlData.Message)) {
                const message = action.message ? utils.evaluate(action.message, action.context) : action?.Message;
                const messagedata = action.messagedata ? utils.evaluateObject(action.messagedata, action.context) : action?.ActionData;
                const input = {
                    Message: message,
                    MessageData: messagedata,
                }
                await utils.executeAndCompileAllActions(this.controlData.Actions, input, this); // !!! help, I drowning !!! -- was: action?.context || this);
            }
        },
        preRenderComplete() {
            this.finishRenderHandler(this);
        },
    },
    render(h) {
        return null;
    }
});