import Vue from 'vue';
import DocumentBootstrap from './DocumentBootstrap.jsx';
import HorizontalStack from './HorizontalStack.jsx';
import VerticalStack from './VerticalStack.jsx';
import BasicButton from './BasicButton.jsx';
import Text from './Text.jsx';
import SelectList from './SelectList.jsx';
import BasicForm from './BasicForm.jsx';
import BasicGrid from './BasicGrid.jsx';
import InfiniteGrid from './InfiniteGrid.jsx';
import TreeView from './TreeView.jsx';
import DynamicTab from './DynamicTab.jsx';
import DynamicTabContent from './DynamicTabContent.jsx';
import DynamicMenuList from './DynamicMenuList.jsx';
import ControlDesigner from './ControlDesigner.jsx';
import utils from '../../Shared/utils.jsx';
import methods from '../../Shared/methods';

Vue.component('control-container', {
    data: function () {
        return {
        }
    },
    computed: {
        Root: function () {
            return this.root._self;
        },
        Vars: function () {
            if (!this.controlData.Vars)
                Vue.set(this.controlData, 'Vars', {});

            return this.controlData.Vars;
        }
    },
    methods: {
        ...methods
    },
    props: {
        name: '',
        root: null,
        controlData: {}
    },
    render(h) {
        if (!this.controlData.Controls)
            return <div></div>;

        let items = [];
        for (var i = 0; i < this.controlData.Controls.length; i++)
        {
            const control = this.controlData.Controls[i];
            let DynamicControl = utils.getDynamicComponent(h, control);
            if (!DynamicControl)
            {
                items.push(<div>Control type of {control.ControlType} is unknown</div>);
                continue;
            }

            items.push(
                <DynamicControl
                    name={control.ControlData.Name}
                    root={this.root}
                    controlURL={c.ControlURL}
                    controlData={control.ControlData}
                    type={control.ControlType}
                    scopeitems={this.scopeitems}
                    controlscope={this.controlscope}
                    cacheControl={control.CacheControl}
                    controlEvents={control.Events}>
                </DynamicControl>
            );
        }

        return <div style={this.sizeStyle}>{items}</div>;
    }
});