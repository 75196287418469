import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';
import axios from 'axios';
import BaseComponent from './BaseComponentMixin.jsx';

Vue.component('select-list-dsgn', {
    mixins: [BaseComponent],
    data: function () {
        return {
            source: null,
        }
    },
    created() {
    },
    mounted() {
        this.Refresh();
    },
    computed: {
    },
    methods: {
        async Refresh()
        {
            if (this.controlData.SourceType === 'URL')
                try {
                    this.source = await utils.api.get(this.controlData.SourceURL, true);
                }
                catch (e) {
                }
            else if (this.controlData.SourceType == 'Raw') {
                if (typeof this.controlData.Source === 'object' && Array.isArray(this.controlData.Source))
                    this.source = this.controlData.Source;
            }
        }
    },
    props: {
    },
    render(h)
    {
        const fields = this.controlData.FieldMappings;
        let items = [];
        let source = this.source;
        if (!source) {
            const obj = {};
            obj[fields.Title] = "Title";
            obj[fields.Description] = "Description";
            source = [obj];
        }

        for (let i = 0; i < source.length; i++) {
            const item = source[i];

            items.push(
                <v-list-item
                    key={i}
                    ripple={true}>

                    <v-list-item-content>
                        <v-list-item-title>{item[fields.Title]}</v-list-item-title>
                        <v-list-item-subtitle>{item[fields.Description]}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            );
        }

        const style = {
            overflow: "auto",
            ...utils.getSize(this.controlData.SizeOptions, this.parentType, this.$parent),
            ...utils.getStyleHints(this.controlData.StyleHints, this),
        };

        return (
            <div
                class={{ 'designer-container': true }}
                style={style}
                on-click={(e) => this.handleClick(e)}>
                <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
                <v-list
                    nav
                    two-line
                    dense
                >
                    <v-list-item-group color="primary">
                        {items}
                    </v-list-item-group>
                </v-list>
            </div>
        );
    }
});