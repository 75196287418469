import Vue from 'vue';
import utils from '../../../../Shared/utils.jsx';
import methods from '../../../../Shared/methods';

Vue.component('form-input-multiline-dsgn', {
    data: function () {
        return {
        }
    },
    created() {
    },
    computed: {
        ...utils.forms.computed,
        selected$: function () {
            return this.Root.SelectedNode && this.Root.SelectedNode == this;
        },
    },
    methods: {
        ...methods,
        handleClick(e) {
            this.Root.SelectNode(this);
            e.cancelBubble = true;
            e.stopPropagation();
        },
    },
    props: {
        name: '',
        schemakey: '',
        root: null,
        designmodel: null,
        schema: null,
        cmodel: null,
        extra: null,
        readonly: false,
        child: 0,
    },
    render(h) {
        const name = <span style={{ marginLeft: (this.child * 15) + "px", whiteSpace: "nowrap" }}>{this.name}</span>;

        let value = this.cmodel[this.schemakey] ? JSON.stringify(this.cmodel[this.schemakey], null, 4) : '';

        let input;
        if (this.readonly)
            input = [<textarea cols="40" disabled="disabled">{value}</textarea>];
        else
            input = [<textarea cols="40" readonly>{value}</textarea>];

        return (
            <tr>
                <td style={{ width: "1px", verticalAlign: "top" }}>
                    <div
                        class={{ 'designer-form': true }}
                        on-click={(e) => this.handleClick(e)}>
                        <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
                        {name}
                    </div>
                </td>
                <td>
                    <div
                        class={{ 'designer-form': true }}
                        on-click={(e) => this.handleClick(e)}>
                        <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
                        {input}
                    </div>
                </td>
            </tr>
        );
    }
});