import { AspNetCoreWebSocketConnection } from './aspNetCoreWebSocketConnection';
import utils from '@/Shared/utils';

export async function CreateWebSocketConnection(baseuri) {    

    var signalrSession = utils.generateGuid();

    /**
    * 10/17/2023: Benton Smith
    * 
    * Added the below request to make sure that we have a request that includes cookies before negotiating our signalr websocket. 
    * Without this code, the signalr negotiation happens without cookies, which prevents us from doing sticky sessions. 
    * 
    * The problem we set out to solve was that if a user's websocket disconnected, they sometimes re-connected to a different web 
    * server. We prevent this in two ways:
    *  1) For all requests for a single websocket negotiation, we use SignalrSession to make sure that subsequent requests to 
    *     negotiate a single websocket go to the same web server.
    *  2) For requests between websocket sessions, like a reconnect, we use cookies. This makes sure that if your websocket disconnects
    *     and you negotiate a new one, you'll go to the same server despite having a different SignlarSession GUID.
    * 
    * This fetch request includes the "credentials": 'include' property to make sure that we get cookies back and include them in our request.
    */
    await fetch(`${baseuri}/Apps/Web/MyIPAddress?SignalrSession=${signalrSession}`, { credentials: 'include' });

    return new AspNetCoreWebSocketConnection(signalrSession);
}