import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import EventBus from '../event-bus.js';
import cloneDeep from 'lodash.clonedeep';

import utils from '../../Shared/utils.jsx';
import methods from '../../Shared/methods';

Vue.component('wizard-step', {
    props: {
        step: {},
        context: null
    },
    data() {
        return {
            show: false,
        }
    },
    async mounted() {
        try {
            await utils.executeAndCompileAllActions(this.step.PrerenderActions, null, this.context);
        } catch (e) {
            utils.error(e);
        }
        this.show = true;
    },
    render(h) {
        if (!this.show)
            return null;
        let controls = null;
        let footerControls = null;

        if (this.step.Controls && this.step.Controls.length)
            controls = utils.generateItemsFromArray(h, this.context, this.step.Controls, null, 'VerticalLayout', null);
        if (this.step.FooterControls && this.step.FooterControls.length)
            footerControls = utils.generateItemsFromArray(h, this.context, this.step.FooterControls, null, 'HorizontalLayout', null);

        return (
            <div class="d-flex flex-column flex-grow-1">
                <v-card-text class="text--primary mb-auto">
                    {controls}
                </v-card-text>
                <v-card-actions>
                    {footerControls}
                </v-card-actions>
            </div>
        );
    }
});

Vue.component('cc-wizard', {
    mixins: [BaseComponent],
    data: function () {
        return {
            initialStepEval: null,
            currentStep: null,
            showBreadCrumbEval: null,
            colorEval: null,
            breadCrumbs: [],
            localSteps: [],

            currentStepIndex: 0,
        }
    },
    //Mounted Replaced with preRenderComplete
    computed: {
        /**
         * Begin public computed
         */
        CurrentStepName() {
            if(this.currentStep)
                return this.currentStep.Name;
            return ''
        },
        CurrentStep() {
            return this.currentStep;
        },
        /**
         * End public computed
         */
        styles() {
            return {
                ...utils.resolveStyleHints(this.styleHints, this),
                ...this.sizeStyle
            };
        },
        title() {
            try {
                if(!this.titleEval && this.controlData.Title)
                    this.titleEval = utils.compile(this, this.controlData.Title);
                if(this.titleEval)
                    return utils.evaluate(this.titleEval, this);
                return '';
            }
            catch (e) {
                utils.warn('Title could not evaluate expression: ' + this.controlData.Title + '; ' + e);
                return '';
            }
        },
        initialStep() {
            try {
                if(this.initialStepEval === null && this.controlData.InitialStep)
                    this.initialStepEval = utils.compile(this, this.controlData.InitialStep);
                if(this.initialStepEval)
                    return utils.evaluate(this.initialStepEval, this);
                return '';
            }
            catch (e) {
                utils.warn('InitialStep could not evaluate expression: ' + this.controlData.InitialStep + '; ' + e);
                return '';
            }
        },
        showBreadCrumb() {
            try {
                if(this.showBreadCrumbEval === null && this.controlData.ShowBreadCrumb)
                    this.showBreadCrumbEval = utils.compile(this, this.controlData.ShowBreadCrumb);
                if(this.showBreadCrumbEval)
                    return utils.evaluate(this.showBreadCrumbEval, this);
                return false;
            }
            catch (e) {
                utils.warn('ShowBreadCrumb could not evaluate expression: ' + this.controlData.ShowBreadCrumb + '; ' + e);
                return false;
            }
        },
        color() {
            try {
                if(this.colorEval === null && this.controlData.Color)
                    this.colorEval = utils.compile(this, this.controlData.Color);
                if(this.colorEval)
                    return utils.evaluate(this.colorEval, this);
                return '';
            }
            catch (e) {
                utils.warn('Color could not evaluate expression: ' + this.controlData.Color + '; ' + e);
                return '';
            }
        },
        steps() {
            try {
                if(this.controlData.Steps && this.controlData.Steps.length) {
                    // Create a local copy of the steps so that we can interpolate the values of each step.
                    if(!this.localSteps.length)
                        this.localSteps = cloneDeep(this.controlData.Steps);
                    
                    // Compile all of the expressions that need to be interpolated for each step
                    for(let step of this.localSteps) {
                        if(step.Name && !step.NameCompiled)
                            step.NameCompiled = utils.compile(this, this.Translate(step.Name));
                        if(step.Title && !step.TitleCompiled)
                            step.TitleCompiled = utils.compile(this, this.Translate(step.Title));

                        // If there is a compiled value, replace the original version with the evaluated expression
                        if(step.NameCompiled)
                            step.Name = utils.evaluate(step.NameCompiled, this);
                        if(step.TitleCompiled)
                            step.Title = utils.evaluate(step.TitleCompiled, this);
                    }

                    return this.localSteps;
                }
                else 
                    return []
            }
            catch (e) {
                utils.warn('Steps could not evaluate expression: ' + this.controlData.Steps + '; ' + e);
                return [];
            }
        }
    },
    methods: {
        /**
         * Begin public methods
         */
        ClearBreadcrumbs() {
            this.clearBreadCrumbs();
        },
        GotoStepByName(stepName) {
            this.changeStepByName(stepName);
        },
        NextStep() {
            this.nextStep();
        },
        PreviousStep() {
            this.previousStep();
        },
        /**
         * End public methods
         */
        preRenderComplete() {
            this.setInitialStep();
            this.finishRenderHandler(this);
        },
        setInitialStep() {
            if(this.controlData.Steps && this.controlData.Steps.length) {
                if(this.initialStep)
                    this.changeStepByName(this.initialStep);
                else
                    this.changeStep(this.steps[0], 0);
            }
        },
        changeStep(step, index) {
            this.currentStepIndex = index;
            /////
            this.currentStep = step;
            step.index = index;
            this.addBreadCrumb(step);
        },
        addBreadCrumb(step) {
            this.breadCrumbs.push(step);
        },
        changeStepByName(stepName) {
            for(let i in this.steps) {
                let step = this.steps[i];
                if(step.Name === stepName) {
                    this.changeStep(step, parseInt(i));
                    return;
                }
            }
        },
        nextStep() {
            let nextStepIndex = this.currentStep.index + 1
            if(nextStepIndex < this.steps.length) {
                this.changeStep(this.steps[nextStepIndex], nextStepIndex);
            }
        },
        previousStep() {
            let index = this.breadCrumbs.length - 2;
            this.breadCrumbNavigate(this.breadCrumbs[index], index);
        },
        breadCrumbNavigate(breadCrumb, index) {
            // remove all items in the array including selected breadcrumb, since it will be added in the changeStep
            this.breadCrumbs.splice(index, this.breadCrumbs.length - index);

            this.changeStep(breadCrumb, breadCrumb.index);
        },
        clearBreadCrumbs() {
            this.breadCrumbs = [this.currentStep];
        },
        generateBreadCrumbs(h) {
            if (this.breadCrumbs && this.breadCrumbs.length) {
                const crumbs = [];
                for (let i = 0; i < this.breadCrumbs.length; i++) {
                    const crumb = this.breadCrumbs[i];
                    crumbs.push(<v-btn elevation={0} plain small disabled={i >= this.breadCrumbs.length - 1} on-click={(e) => this.breadCrumbNavigate(crumb, i)}>{crumb.Title || crumb.Name}</v-btn>);
                    crumbs.push(<v-icon x-small>mdi mdi-chevron-right</v-icon>);
                }
                return (
                    <div style="display: flex; flex-direction: row;">{crumbs}</div>
                );
            }
            else
                return null;
        },
        generateSteps(h) {
            let items = [];
            for (let i = 0; i < this.steps.length; i++) {
                const step = this.steps[i];
                items.push(
                    <v-tab-item key={i}>
                        <wizard-step step={step} context={this}></wizard-step>
                    </v-tab-item>
                )
            }
            return items;
        },
    },
    props: {
    },
    render(h) {
        try {
            if (!this.todisplay)
                return null;

            let crumbs = this.generateBreadCrumbs(h);
            let items = this.generateSteps(h);

            return (
                <v-card color={this.color} class={{ 'c-Wizard d-flex flex-column': true, [`c-name-${this.name || 'unnamed'}`]: true, 'ma-1': true }} v-show={this.isvisible} style={this.styles}>
                    <v-card-title class="text-h4 cyan">
                        {this.title}
                    </v-card-title>

                    <v-card-text class="text--primary">
                        {crumbs}
                    </v-card-text>

                    <v-tabs-items value={this.currentStepIndex}>
                        {items}
                    </v-tabs-items>
                </v-card>
            );
        }
        catch(e) {
            utils.error('Wizard Render failed', e);
            return <div>Wizard Failed to Render {e}</div>;
        }
    }
});