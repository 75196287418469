<template>
    <v-card>
        <v-app-bar flat color="rgba(0, 0, 0, 0)" style="background-color: var(--v-navigation-base); height: 60px">

            <v-app-bar-nav-icon color="white"></v-app-bar-nav-icon>

            <v-toolbar-title class="title white--text pl-0">
                Debug Form
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn color="white" icon @click="closeForm">
                <v-icon>mdi-close-box</v-icon>
            </v-btn>
        </v-app-bar>

        <v-card-text style="height: 100%; paddingBottom: 0px;">
            <v-tabs v-model="debug_tab_selected">
                <v-tab>Schema</v-tab>
                <v-tab>Form</v-tab>
                <v-tab>Model</v-tab>
            </v-tabs>

            <v-tabs-items v-model="debug_tab_selected" style="height: 90%;">
                <v-tab-item key="0">
                    <pre style="width: 100%; height: 100%; overflow: auto; margin: 0;">{{getJSON(schema || {})}}</pre>
                </v-tab-item>

                <v-tab-item key="1">
                    <pre style="width: 100%; height: 100%; overflow: auto; margin: 0;">{{getJSON(form || [])}}</pre>
                </v-tab-item>

                <v-tab-item key="2">
                    <pre style="width: 100%; height: 100%; overflow: auto; margin: 0;">{{getJSON(cmodel || {})}}</pre>
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>

    </v-card>
</template>

<script>
    const getCircularReplacer = () => {
        const seen = new WeakSet();
        return (key, value) => {
            if (typeof value === "object" && value !== null) {
                if (seen.has(value)) {
                    return;
                }
                seen.add(value);
            }
            return value;
        };
    };

    export default {
        name: 'debug-dialog',
        data: function () {
            return {
                debug_tab_selected: 0
            }
        },
        props: {
            schema: null,
            form: null,
            cmodel: null
        },
        methods: {
            getJSON(obj) {
                return JSON.stringify(obj, getCircularReplacer(), 4);
            },
            closeForm() {
                this.$emit('closeme');
            }
        }
    }
</script>

<style scoped>
    p {
        font-size: 2em;
        text-align: left;
    }
</style>