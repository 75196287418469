import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import BaseComponentMixin from './BaseComponentMixin.jsx';

Vue.component('for-each-dsgn', {
    mixins: [BaseComponentMixin],
    data: function () {
        return {
            model: [],
            sourceraw: null,
            sourceurl: null,

            pickervisible: false,
            picker: null,
            pickertarget: 'body',
        }
    },
    created() {

    },
    destroyed() {
    },
    computed: {
        style() {
            return {};
        },
    },
    //Replaced with preRenderComplete
    mounted() {
        this.Refresh();
    },
    methods: {
        async Refresh() {
            if (this.controlData.SourceType === 'Raw' && this.controlData.SourceRaw && (typeof this.controlData.SourceRaw === 'object')) {
                this.model = this.controlData.SourceRaw;
            }
            else if (this.controlData.SourceType === 'Url' && this.controlData.SourceUrl) {
                try {
                    this.model = await utils.api.get(this.controlData.SourceUrl);
                }
                catch
                {
                    this.model = [];
                }
            }
        },
        generateItems(h, contentDefs, items, model, index, parentType) {
            for (let j = 0; j < contentDefs.length; j++) {
                const cd = contentDefs[j];

                if (cd && cd.Controls)
                    for (let i = 0; i < cd.Controls.length; i++) {
                        const c = cd.Controls[i];
                        if (!(typeof c === 'object'))
                            continue;

                        let DynamicControl = utils.getDynamicComponent(h, c);

                        if (!DynamicControl)
                            DynamicControl = 'default-unknown';

                        DynamicControl += '-dsgn';

                        if (!c.$objectId) c.$objectId = utils.generateUUID();

                        let id = `${this.master_key}_${c.$objectId}`;
                        if (index) id = `${index}_${id}`;

                        items.push(
                            <DynamicControl
                                key={id}
                                type={c.ControlType}
                                name={c.ControlData ? c.ControlData.Name : ''}
                                root={this.root}
                                designmodel={c}
                                parentType={parentType}
                                controlData={c.ControlData}
                                controlURL={c.ControlURL}
                                sourceData={model}
                                controlName={c.Name}
                            >
                            </DynamicControl>
                        );
                    }

                if (this.selected$ && cd)
                    items.push(
                        <v-btn v-tooltip="Add control" x-small icon style={{ zIndex: 992 }} on-click={(e) => this.addControl(e, cd)}>
                            <v-icon small color="blue darken-2">mdi mdi-plus-circle</v-icon>
                        </v-btn>
                    );
            }
        },
        addContent(e) {
            if (!this.controlData.ContentDefs)
                Vue.set(this.controlData, 'ContentDefs', []);

            this.controlData.ContentDefs.push({
                $typeSchema: "/schema/public/Platform.Schema.DynamicControls.v1/DynamicControl_ForEach_ContentDef",
                Controls: [],
            });
        },
        addControl(e, target) {
            this.pickertarget = target;
            this.picker = <control-picker confirm={this.confirmAddControl} cancel={this.cancelAddControl}></control-picker>;
            this.pickervisible = true;
            e.cancelBubble = true;
            e.stopPropagation();
        },
        async confirmAddControl(id, title) {
            this.pickervisible = false;

            try {
                const schema_ = await utils.schema.get(id);
                const schema = utils.schema.resolve_Of(schema_)
                const model = utils.schema.getDefaultModel(schema);

                if (!this.pickertarget.Controls)
                    Vue.set(this.pickertarget, 'Controls', []);

                this.pickertarget.Controls.push(model);
            }
            catch (e) {
                alert(e);
            }
        },
        cancelAddControl() {
            this.pickervisible = false;
        },
        pickerInput(value) {
            this.pickervisible = value;
        },
        generatePicker(h) {
            return (
                <v-dialog value={this.pickervisible} max-width="860" scrollable on-input={(value) => this.pickerInput(value)}>
                    {this.picker}
                </v-dialog>
            );
        },
    },
    props: {
        type: null,
        parentType: null,
    },
    render(h) {
        try {
            let items = [];
            let items_id = '_0';

            if (this.model && typeof this.model === 'object' && this.controlData.ContentDefs) {
                if (Array.isArray(this.model) && this.model.length > 0)
                    for (let i = 0; i < this.model.length; i++) {
                        const item = this.model[i];
                        const item_index = `${i}_${items_id}`;
                        const sub_items = [];
                        this.generateItems(h, this.controlData.ContentDefs, sub_items, item, item_index, this.parentType);

                        items.push(<div style="display: flex; flex-direction: column;">{sub_items}</div>);
                    }
                else if (Object.keys(this.model).length > 0)
                    for (let key in this.model) {
                        const item = this.model[key];
                        const item_index = `${key}_${items_id}`;
                        const sub_items = [];
                        this.generateItems(h, this.controlData.ContentDefs, sub_items, item, item_index, this.parentType);

                        items.push(<div style="display: flex; flex-direction: column;">{sub_items}</div>);
                    }
                else {
                    const sub_items = [];
                    this.generateItems(h, this.controlData.ContentDefs, sub_items, null, '0', this.parentType);

                    items.push(<div style="display: flex; flex-direction: column;">{sub_items}</div>);
                }
            }
            else if (this.controlData.ContentDefs) {
                const sub_items = [];
                this.generateItems(h, this.controlData.ContentDefs, sub_items, null, '0', this.parentType);

                items.push(<div style="display: flex; flex-direction: column;">{sub_items}</div>);
            }

            if (this.selected$)
                items.push(
                    <v-btn v-tooltip="Add content" x-small icon style={{ zIndex: 992 }} on-click={(e) => this.addContent(e)}>
                        <v-icon small color="red darken-2">mdi mdi-plus-circle</v-icon>
                    </v-btn>
                );

            const style = {
                overflow: this.parentType == "ContentDef" ? "hidden" : "auto",
                display: "flex",
                flexDirection: this.controlData.UseFlexRow ? "row" : "column",
                ...this.sizeStyle,
                ...utils.getStyleHints(this.controlData.StyleHints),
            };

            //if (this.sizeOptions && this.sizeOptions.Width.Mode === 'Auto' && this.parentType === 'VerticalStack') {
            //    // In this unique case, we must use the opposite direction on the parent and embed another div
            //    // to force the 'auto' width -- otherwise, 
            //    style.flexDirection = 'column';

            //    items = (
            //        <div style={{ display: "flex", flexDirection: "row", flexGrow: "0", flexShrink: "0" }}>
            //            {items}
            //        </div>
            //    );
            //}

            return (
                <div
                    class={{ 'c-ForEach': true, [`c-name-${this.name || 'unnamed'}`]: true, 'designer-container': true, selected: this.selected$ }}
                    style={style}
                    on-click={(e) => this.handleClick(e)}>
                    <div class={{ "designer-container-hovering": true, 'designer-container-selected-clickthrough': this.selected$ }}></div>
                    {items}
                    {this.generatePicker(h)}
                </div>
            );
        }
        catch(e) {
            utils.error('ForEach Render failed', e);
            return <div>ForEach Failed to Render {e}</div>;
        }
    }
});