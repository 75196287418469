<template>
    <span>{{ text }}</span>
</template>

<script>
    import careHelpfulFunctions from '@/Application/careHelpfulFunctions.jsx';
    import utils from '@/Shared/utils.jsx';

    export default {
        name: 'AsyncTextblock',
        data: function () {
            return {
                text: '',
                result_compiled: null,
            }
        },
        props: {
            settings: null,
        },
        created() {
            if (this.settings.default_text)
                this.text = this.settings.default_text;

            if (this.settings.result_expression)
                this.result_compiled = utils.compileExpression(this.settings.parent, this.settings.result_expression, this.settings.modelas || 'data');
        },
        mounted() {
            if (this.settings.url)
                this.Refresh();
        },
        methods: {
            async Refresh() {
                const data = await utils.api.get(this.settings.url);
                if (data) {
                    if (this.result_compiled)
                        this.text = utils.evaluate(this.result_compiled, this.settings.parent, false, null, false, data);
                    else
                        this.text = `${data}`;
                }
                else if (this.settings.error_text)
                    this.text = this.settings.error_text;
            },
        },
    };
</script>

<style scoped>

</style>