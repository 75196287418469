<template>
    <div v-if="columns.length == 0" class="text-caption ml-3 consistentText" style="flex: auto;">
        No items visible - check your filter settings
    </div>
    <v-expansion-panels v-else multiple accordion hover v-model="panels_open">

        <v-expansion-panel v-for="(col,i) in columns" :key="i">
            <v-expansion-panel-header color="extraLightGrey">
                <span>
                    {{ col.Title }}
                </span>
                <template v-slot:actions>
                    <v-icon color="primary" title="Clear Filter" class="mr-3"
                            v-if="isIconVisible(col)"
                            @click.stop="clear(col)">mdi-close-circle-outline</v-icon>
                    <v-icon>mdi-menu-down</v-icon>
                </template>
            </v-expansion-panel-header>

            <v-expansion-panel-content color="extraLightGrey">
                <v-card class="pa-1" :elevation="0" color="extraLightGrey">

                    <component v-bind:is="getColumnComponent(col)"
                                :key="column_ids[col.ColId]"
                                :item="col"
                                :EventBus="EventBus"
                                :settings="settings"
                                :isopen="isFieldOpen(i)"
                                :value="column_filters[col.ColId]"
                                :initial="initial_column_filters != null ? initial_column_filters[col.ColId] : null"
                                @change="(value) => localUpdateFilterValue(col, value)"></component>

                    <v-switch dense hide-details inset :input-value="usages[col.ColId] > 0" @change="v => setColumnFilterUse(col, v ? 1 : 0)" v-if="!ReadOnly" _true-value="true" _false-value="false">
                        <template v-slot:label>
                            Show filter in view mode
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon dense style="color: lightgrey;" class="ml-1" v-bind="attrs" v-on="on">mdi-information-slab-circle</v-icon>
                                </template>
                                In this mode, filter controls are visible and accessible in view mode. Users can modify<br />
                                the filter settings freely while viewing the report, allowing for dynamic and personalized<br />
                                adjustments. The filter selections made in Edit mode will serve as the default settings<br />
                                when first entering view mode, but users can freely adjust these as needed.
                            </v-tooltip>
                        </template>
                    </v-switch>
                    <v-switch dense hide-details inset :input-value="usages[col.ColId] == 2" @change="v => setColumnFilterUse(col, v ? 2 : 1)" v-if="usages[col.ColId] > 0 && hasFilter(col) && !ReadOnly && limitedFilteringTypes.includes(col.SourceType)">
                        <template v-slot:label>
                            Limit filtering in view mode to selected items only
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon dense style="color:lightgrey;" class="ml-1" v-bind="attrs" v-on="on">mdi-information-slab-circle</v-icon>
                                </template>
                                In this mode, filter controls are available in view mode, but with restrictions. Users<br />
                                can adjust filter settings, but only within the options that were decided upon in Edit<br />
                                mode. The filters selected in Edit mode will be the default settings when the report is<br />
                                first viewed, providing a predefined starting point while still allowing users some<br />
                                flexibility within those constraints.
                            </v-tooltip>
                        </template>
                    </v-switch>

                </v-card>
            </v-expansion-panel-content>
        </v-expansion-panel>

    </v-expansion-panels>
</template>

<script>
    import Vue from "vue";
    import common from './common.js';

    import utils from '@/Shared/utils.jsx';

    import FilterbooleanColumn from './filter_booleanColumn.vue';
    import FilterchannelTypeColumn from './filter_textSetColumn.vue';
    import FiltercountColumn from './filter_durationColumn.vue';
    import FilterfloatColumn from './filter_durationColumn.vue';
    import FiltercurrencyColumn from './filter_durationColumn.vue';
    import FiltercustomerIDColumn from './filter_textSetColumn.vue';
    import FilterdateColumn from './filter_dateColumn.vue';
    import FilterdirectionColumn from './filter_directionColumn.vue';
    import FilterdurationColumn from './filter_durationColumn.vue';
    import FilterpercentColumn from './filter_durationColumn.vue';
    import FilterphoneNumColumn from './filter_textSetColumn.vue';
    import FiltersearchableTextColumn from './filter_searchableTextColumn.vue';
    import FiltertextSetColumn from './filter_textSetColumn.vue';
    import FilteruserIDColumn from './filter_textSetColumn.vue';

    export default {
        components: {
            FilterbooleanColumn,
            FilterchannelTypeColumn,
            FiltercountColumn,
            FiltercurrencyColumn,
            FiltercustomerIDColumn,
            FilterfloatColumn,
            FilterdateColumn,
            FilterdirectionColumn,
            FilterdurationColumn,
            FilterpercentColumn,
            FilterphoneNumColumn,
            FiltersearchableTextColumn,
            FiltertextSetColumn,
            FilteruserIDColumn,
        },
        data: function () {
            return {
                id: 1,
                limitedFilterKey: 1,
                panels_open: [],
                usages: {},
                limitedFilteringTypes: [
                    'textSetColumn',
                    'channelTypeColumn',
                    'userIDColumn',
                    'customerIDColumn',
                    'phoneNumColumn',
                ],
                column_ids: {},
            }
        },
        props: {
            EventBus: null,
            columns: null,
            column_filters: Object,
            initial_column_filters: null,
            settings: null,
            ReadOnly: null,

            getColumnComponent: null,
            getFilterValue: null,
            updateFilterValue: null,
        },
        created() {
            this.EventBus.$on(`ClearAllReportFilters`, this.clearAll);

            // Create unique ids for each dynamic filter column so we can force the component to refresh when needed
            for (let c of this.columns)
                this.column_ids[c.ColId] = utils.generateUUID();

            for (let colid of Object.keys(this.column_filters))
                this.usages[colid] = this.column_filters[colid].usage || 0;
        },
        destroyed() {
            this.EventBus.$off(`ClearAllReportFilters`, this.clearAll);
        },
        methods: {
            clear(col) {
                if (!this.ReadOnly) {
                    if (col.ColId in this.usages)
                        this.usages[col.ColId] = 0;
                    this.updateFilterValue(col, null);
                }
                else {
                    const initial = this.initial_column_filters[col.ColId];
                    if (initial)
                        this.updateFilterValue(col, initial);
                    else
                        this.updateFilterValue(col, { usage: this.usages[col.ColId] || 0 });
                }

                if (col.ColId in this.column_ids)
                    this.column_ids[col.ColId] = utils.generateUUID();
            },
            clearAll() {
                // Clear ALL filters (this will be trigged for every category, not efficient, but oh well)
                for (let col of Object.keys(this.column_filters)) {
                    this.clear({ ColId: col });
                }

                // Force all visible filters to refresh
                for (let col of this.columns) {
                    this.column_ids[col.ColId] = utils.generateUUID();
                }
            },
            isFieldOpen(index) {
                return this.panels_open.includes(index);
            },
            localUpdateFilterValue(col, value) {
                if (!value && !this.usages[col.ColId])
                    this.updateFilterValue(col, null);
                else
                    this.updateFilterValue(col, { ...value, usage: this.usages[col.ColId] || 0 });

                if (!this.hasFilter(col))
                    this.limitedFilterKey++;
            },

            getColumnFilterUse(col) {
                return this.usages[col.ColId] || 0;
            },
            setColumnFilterUse(col, value) {
                this.usages[col.ColId] = value;
                let filter;

                if (value || this.column_filters[col.ColId])
                    filter = { ...this.column_filters[col.ColId], usage: value || 0 };
                else
                    filter = null;

                // Ensure we don't end up with a blank filter with usage = 0
                if (Object.keys(filter).length == 1 && 'usage' in filter && !filter.usage)
                    filter = null;

                this.updateFilterValue(col, filter);
            },
            hasFilter(col) {
                const filter = this.column_filters[col.ColId];
                if (!filter) return false;

                if ((Object.keys(filter).length == 1 && 'usage' in filter) || filter.filter == '{{CurrentUserID}}')
                    return false;

                return true;
            },

            isIconVisible(col) {
                if (this.ReadOnly && this.initial_column_filters)
                {
                    const c = this.column_filters[col.ColId];
                    if (!c) return false;
                    if ('usage' in c && Object.keys(c).length > 1)
                    {
                        if (c.usage == 2) {
                            // Compare initial to current filter
                            const i = this.initial_column_filters[col.ColId];

                            // If not same, icon is visible
                            return !common.compareObjects(c, i);
                        }
                        else
                            return true;
                    }
                    else
                        return false; // don't show that a filter is present if it is baked
                }
                else
                    return col.ColId in this.column_filters;
            },
        }
    }
</script>

<style scoped>
    .consistentText {
        color: var(--v-black-base) !important; 
        opacity: 0.8 !important; 
        font-size: 14px !important; 
        text-rendering: optimizelegibility !important; 
        letter-spacing:0.1 !important;
    }
</style>