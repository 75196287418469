import Vue from 'vue';
import utils from '../../../../Shared/utils.jsx';
import methods from '../../../../Shared/methods';

Vue.component('tab-action-dsgn', {
    data: function () {
        return {
        }
    },
    computed: {
        Root: function () {
            return this.root._self;
        },
        Vars: function () {
            if (!this.controlData.Vars)
                Vue.set(this.controlData, 'Vars', {});

            return this.controlData.Vars;
        },
        DesignModel: function () {
            return this.designmodel;
        },
        selected$: function () {
            return this.Root.SelectedNode && this.Root.SelectedModel == this.designmodel;
        },
    },
    methods: {
        ...methods,
        handleClick(e) {
            this.Root.SelectNode(this);
            e.cancelBubble = true;
            e.stopPropagation();
        },
    },
    props: {
        name: '',
        root: null,
        designmodel: null,
        parentType: '',
        controlData: {}
    },
    render(h) {
        const tabstyle = {
            backgroundColor: "#f8f8f8",            
          //borderRadius: "8px 8px",// first value applies to top-left corner, second value applies to top-right corner, third value applies to bottom-right corner, and fourth value applies to bottom-left corner
            borderTopColor: "gray",
            borderLeftColor: "gray",
            borderRightColor: "gray",
            borderTopStyle: "solid",
            borderLeftStyle: "solid",
            borderRightStyle: "solid",
            borderTopWidth: "1px",
            borderLeftWidth: "1px",
            borderRightWidth: "1px",
            paddingLeft: "2px",
            paddingRight: "2px",
            paddingTop: "2px",
            paddingBottom: "2px",
        };

        const container = (
            <stack-vertical-dsgn root={this.root} type={this.type} parentType="VerticalStack" designmodel={this.designmodel} controlData={this.controlData.ControlData}>
            </stack-vertical-dsgn>
        );

        return (
            <div
                class={{ 'designer-container': true, selected: this.selected$ }}
                style={{ margin: "5px", padding: "5px" }}
                on-click={(e) => this.handleClick(e)}>
                <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
                <div><span style={tabstyle}>{this.controlData.TabTitle}</span></div>
                {container}
            </div>
        );
    }
});