import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';
import BaseComponent from './BaseComponentMixin.jsx';

Vue.component('real-time-grid-dsgn', {
    mixins: [BaseComponent],
    data: () => ({
        pickervisible: false,
        picker: null,
        pickercdef: null,
    }),
    created() {
        this.gridrole_watch$ = this.$watch('controlData.GridRole', function (newval, oldval) {
            if (oldval == 'Custom' && newval != 'Custom' && (!this.controlData.Columns || !this.controlData.Columns.length)) {
                this.createDefaultColumns(newval);
            }
            if (oldval == 'Custom' && newval != 'Custom' && (!this.controlData.Features || !this.controlData.Features.length)) {
                this.createDefaultFeatures(newval);
            }
        });
    },
    destroyed() {
        if (this.gridrole_watch$)
            this.gridrole_watch$();
    },
    mounted() {
    },
    computed: {
    },
    methods: {
        createDefaultColumns(role) {
            switch (this.controlData.ListSource) {
                case 'UserList':
                case 'AllUsers':
                    this.controlData.Columns = [
                        {
                            FieldName: '_UserList_Features_',
                            HeaderText: '',
                            Advanced: {
                                ValueType: 'Features',
                                Sortable: false,
                                InitiallyVisible: true,
                                Align: 'start',
                            }
                        },
                        {
                            FieldName: 'FullName',
                            HeaderText: 'User',
                            Advanced: {
                                ValueType: 'Text',
                                Sortable: true,
                                InitiallyVisible: true,
                                Align: 'start',
                            }
                        },
                        {
                            FieldName: 'Color',
                            HeaderText: 'Presence',
                            Advanced: {
                                ValueType: 'ColoredIcon',
                                Sortable: true,
                                InitiallyVisible: true,
                                Align: 'start',
                            }
                        },
                        {
                            FieldName: 'StateName',
                            HeaderText: 'State',
                            Advanced: {
                                ValueType: 'Text',
                                Sortable: true,
                                InitiallyVisible: true,
                                Align: 'start',
                            }
                        },
                        {
                            FieldName: 'StartTime',
                            HeaderText: 'Time',
                            Advanced: {
                                ValueType: 'ElapsedTime',
                                Sortable: true,
                                InitiallyVisible: true,
                                Align: 'start',
                            }
                        },
                        {
                            FieldName: 'UserID',
                            HeaderText: 'UserID',
                            Advanced: {
                                ValueType: 'Text',
                                Sortable: true,
                                InitiallyVisible: false,
                                Align: 'start',
                            }
                        },
                        {
                            FieldName: 'Queue',
                            HeaderText: 'Queue',
                            Advanced: {
                                ValueType: 'Text',
                                Sortable: true,
                                InitiallyVisible: true,
                                Align: 'start',
                            }
                        },
                        {
                            FieldName: 'Interactions',
                            HeaderText: 'Active Interaction',
                            Advanced: {
                                ValueType: 'Icon',
                                IconName: "{{ row.length ? GlobalVars.ChannelTypes[row[0].ChannelType].Icon : '' }}",
                                Sortable: true,
                                InitiallyVisible: true,
                                Align: 'start',
                            }
                        },
                    ];
                    break;

                case 'Interactions':
                    this.controlData.Columns = [
                        {
                            FieldName: '_Interactions_Features_',
                            HeaderText: '',
                            Advanced: {
                                ValueType: 'Features',
                                Sortable: false,
                                InitiallyVisible: true,
                                Align: 'start',
                            }
                        },
                        {
                            FieldName: 'CallerId',
                            HeaderText: 'Originator',
                            Advanced: {
                                ValueType: 'PhoneNumber',
                                Sortable: true,
                                InitiallyVisible: true,
                                Align: 'start',
                            }
                        },
                        {
                            FieldName: 'FullName',
                            HeaderText: 'User Handling',
                            Advanced: {
                                ValueType: 'Text',
                                Sortable: true,
                                InitiallyVisible: true,
                                Align: 'start',
                            }
                        },
                        {
                            FieldName: 'StateName',
                            HeaderText: 'State',
                            Advanced: {
                                ValueType: 'Text',
                                Sortable: true,
                                InitiallyVisible: true,
                                Align: 'start',
                            }
                        },
                        {
                            FieldName: 'ChannelType',
                            HeaderText: 'Channel Type',
                            Advanced: {
                                ValueType: 'Text',
                                Sortable: true,
                                InitiallyVisible: false,
                                Align: 'start',
                            }
                        },
                        {
                            FieldName: 'Queue',
                            HeaderText: 'Queue',
                            Advanced: {
                                ValueType: 'Text',
                                Sortable: true,
                                InitiallyVisible: false,
                                Align: 'start',
                            }
                        },
                        {
                            FieldName: 'StartTime',
                            HeaderText: 'Duration',
                            Advanced: {
                                ValueType: 'ElapsedTime',
                                Sortable: true,
                                InitiallyVisible: true,
                                Align: 'start',
                            }
                        },
                        {
                            FieldName: 'Destination',
                            HeaderText: 'Destination',
                            Advanced: {
                                ValueType: 'PhoneNumber',
                                Sortable: true,
                                InitiallyVisible: false,
                                Align: 'start',
                            }
                        },
                    ];
                    break;
            }
        },
        createDefaultFeatures(role) {
            switch (this.controlData.ListSource) {
                case 'UserList':
                case 'AllUsers':
                    this.controlData.Features = [
                        "Call", "ForceLogoff", "Presence", "SessionDetails", "SilentMonitor", "Chat"
                    ];
                    break;

                case 'Interactions':
                    this.controlData.Features = [
                        "ChannelType", "InQueue", "Requeue"
                    ];
                    break;
            }
        },
    },
    props: {
    },
    render() {
        let name;
        if (!this.name)
            name = this.type;
        else
            name = this.name + ':' + this.type || 'Unnamed';

        return (
            <div
                class={{ 'designer-container': true, selected: this.selected$ }}
                style={{ backgroundColor: "#a0a0ff", ...utils.getSize(this.controlData ? this.controlData.SizeOptions : null, this.parentType, this.$parent) }}
                on-click={(e) => this.handleClick(e)}>
                <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
                {name}<br />
            </div>
        );
    }
});