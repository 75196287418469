import careHelpfulFunctions from "@/Application/careHelpfulFunctions";

export default function SAMLPopupManger()
{
    var c = this;

    var childwindow = null;
    var callbackName = null;
    var closedWindowChecker = null;
    var promise = null;
    var promiseResolve = null;
    var promiseReject = null;

    // public methods
    c.open = open;

    function open(issuer)
    {
        if (!window.SAMLPopupCounter)
            window.SAMLPopupCounter = 1;
        else 
            window.SAMLPopupCounter++;

        callbackName = "popup_SAMLSSO" + window.SAMLPopupCounter;

        var urlEncodedIssuer = careHelpfulFunctions.urlEncode(issuer);
        var urlEncodedCallbackName = careHelpfulFunctions.urlEncode(callbackName);

        promise = new Promise((resolveFn, rejectFn) => {
            promiseResolve = resolveFn;
            promiseReject = rejectFn;
        });

        window[callbackName] = callbackFunction;

        childwindow = window.open("/SSO/SAMLPopup?Issuer=" + urlEncodedIssuer + "&callbackName=" + urlEncodedCallbackName, "_blank", "location=no,toolbar=no,width=500,height=500,left=100,top=100;");
        closedWindowChecker = window.setInterval(checkForClosedWindow, 500);

        return promise;
    }

    function callbackFunction(result)
    {
        cleanup();
        promiseResolve(result);
    }

    function reject(error)
    {
        cleanup();
        promiseReject(error);
    }

    function checkForClosedWindow()
    {
        if (!childwindow || childwindow.closed)
            reject("window closed");
    }

    function cleanup()
    {
        if (childwindow && !childwindow.closed)
            childwindow.close();

        childwindow = null;

        if (closedWindowChecker)
            window.clearInterval(closedWindowChecker);

        closedWindowChecker = null;

        if (window[callbackName])
            delete window[callbackName];
    }
}