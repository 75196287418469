import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import utils from '../../Shared/utils.jsx';

Vue.component('cc-progress-bar', {
    mixins: [BaseComponent],
    data: function () {
        return {
            tooltipEval: null,
            tooltipPlacementEval: null,
            disabledEval: null,
            objectFitEval: null,
        }
    },
    //Created Replaced with preRenderComplete
    computed: {
        style() {
            return {
                ...utils.resolveStyleHints(this.styleHints, this),
                ...this.sizeStyle,
                overflow: "none",
            };
        },
        tooltip() {
            try {
                if (this.tooltipEval === null && this.controlData.Tooltip)
                    this.tooltipEval = utils.compile(this, this.Translate(this.controlData.Tooltip));
                if (this.tooltipEval)
                    return utils.evaluate(this.tooltipEval, this);
                return '';
            }
            catch (e) {
                utils.warn('Tooltip could not evaluate expression: ' + this.controlData.Tooltip + '; ' + e);
                return '';
            }
        },
        tooltipPlacement() {
            try {
                if (this.tooltipPlacementEval === null && this.controlData.TooltipPlacement)
                    this.tooltipPlacementEval = utils.compile(this, this.controlData.TooltipPlacement);
                let placement = '';
                if (this.tooltipPlacementEval)
                    placement = utils.evaluate(this.tooltipPlacementEval, this);
                return placement.split('-')[0].toLowerCase() || 'top';
            }
            catch (e) {
                utils.warn('TooltipPlacement could not evaluate expression: ' + this.controlData.TooltipPlacement + '; ' + e);
                return '';
            }
        },
        color() {
            if (!this.color_fn && this.controlData.Color)
                this.color_fn = utils.compile(this, this.controlData.Color);

            return this.color_fn ? utils.evaluate(this.color_fn, this) : undefined;
        },
        value() {
            if (!this.value_fn && this.controlData.ValueExpression)
                this.value_fn = utils.compileExpression(this, this.controlData.ValueExpression);

            return this.value_fn ? utils.evaluate(this.value_fn, this) : 0;
        },
        bufferValue() {
            if (!this.buffervalue_fn && this.controlData.BufferValueExpression)
                this.buffervalue_fn = utils.compileExpression(this, this.controlData.BufferValueExpression);

            return this.buffervalue_fn ? utils.evaluate(this.buffervalue_fn, this) : 100;
        },
        height() {
            if (this.controlData.SizeOptions && this.controlData.SizeOptions.Height && this.controlData.SizeOptions.Height.Mode == 'Fixed')
                return this.controlData.SizeOptions.Height.Value || 4;
            else
                return 4;
        },
    },
    methods: {
        async preRenderComplete() {
            this.finishRenderHandler(this);
        },
        getContent(value) {
            if (!this.content_fn && this.controlData.Content)
                this.content_fn = utils.compile(this, this.controlData.Content, false, 'value');

            return this.content_fn ? utils.evaluate(this.content_fn, this, false, null, false, value) : null;
        },
    },
    render(h) {
        if(!this.todisplay)
            return null;

        try {
            let scopedSlots;
            if (this.controlData.Content)
                scopedSlots = {
                    default: ({ value }) => <span>{this.getContent(value)}</span>
                };
            
            let item = (
                <v-progress-linear
                    v-show={this.isvisible}
                    style={this.style}
                    class={{ 'c-ProgressBar': true, [`c-name-${this.name || 'unnamed'}`]: true }}
                    value={this.value}
                    buffer-value={this.bufferValue}
                    height={this.height}
                    color={this.color}
                    indeterminate={this.controlData.Indeterminate}
                    query={this.controlData.Query}
                    reverse={this.controlData.Reverse}
                    rounded={this.controlData.Rounded}
                    stream={this.controlData.Stream}
                    striped={this.controlData.Striped}
                    scopedSlots={scopedSlots}
                >
                </v-progress-linear>
            );

            if (this.tooltip)
                item = utils.generateTooltip(h, item, this.tooltip, this.tooltipPlacement);

            return item;
        }
        catch (e) {
            utils.error('ProgressBar Render failed', e);
            return <div>ProgressBar Failed to Render {e}</div>;
        }
    }
});