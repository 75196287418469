// defaultBroadcastDirection: "Global", "Parent", "Children", "BaseContainerChildren"
const actionDefaults = {
    // System Messages, not intended for ActionMessages

    CustomerNameChanged: { name: 'CustomerNameChanged', defaultBroadcastDirection: "Global" },
    SelectedCustomerChanged: { name: 'SelectedCustomerChanged', defaultBroadcastDirection: "Global" },
    SelectedUserChanged: { name: 'SelectedUserChanged', defaultBroadcastDirection: "Global" },
    Logout: { name: 'Logout', defaultBroadcastDirection: "Global" },
    UserIdleLogout: { name: 'UserIdleLogout', defaultBroadcastDirection: "Global" },
    UserIdleWarning: { name: 'UserIdleWarning', defaultBroadcastDirection: "Global" },
    UserIdleReset: { name: 'UserIdleReset', defaultBroadcastDirection: "Global" },
    UserIdleEnable: { name: 'UserIdleEnable', defaultBroadcastDirection: "Global" },
    UnsubscribeAllServerEvents: { name: 'UnsubscribeAllServerEvents', defaultBroadcastDirection: "Global" },
    WebSocketConnected: { name: 'WebSocketConnected', defaultBroadcastDirection: "Global" },
    WebSocketDisconnected: { name: 'WebSocketDisconnected', defaultBroadcastDirection: "Global" },
    RTWebSocketConnected: { name: 'RTWebSocketConnected', defaultBroadcastDirection: "Global" },
    RTWebSocketDisconnected: { name: 'RTWebSocketDisconnected', defaultBroadcastDirection: "Global" },
    $ROUTE_CHANGE_SUCCESS: { name: '$routeChangeSuccess', defaultBroadcastDirection: "Global" },
    RefreshAccessTokenAsync: { name: 'RefreshAccessTokenAsync', defaultBroadcastDirection: "Global" },
    CloseTab: { name: 'CloseTab', defaultBroadcastDirection: "Global" },
    AccessTokenAssigned: { name: 'AccessTokenAssigned', defaultBroadcastDirection: "Global" },
    jsBeautify: { name: 'jsBeautify', defaultBroadcastDirection: "Parent" },
    PublishModel: { name: 'PublishModel', defaultBroadcastDirection: "Parent" },
    RelayAction: { name: 'RelayAction', defaultBroadcastDirection: "Parent" },
    ControlRenderComplete: { name: 'ControlRenderComplete', defaultBroadcastDirection: "Parent" },
    AsyncLookupComplete: { name: 'AsyncLookupComplete', defaultBroadcastDirection: "Parent" },
    ChannelEvent: { name: 'ChannelEvent', defaultBroadcastDirection: "Global" }, // websocket events
    RealTimeEvent: { name: 'RealTimeEvent', defaultBroadcastDirection: "Global" }, // websocket events
    RealTimeEvent: { name: 'RealTimeEvent', defaultBroadcastDirection: "Global" }, // rt websocket events
    ApiRequestStarted: { name: 'ApiRequestStarted', defaultBroadcastDirection: "Global" },
    ApiRequestFinished: { name: 'ApiRequestFinished', defaultBroadcastDirection: "Global" },
    ViewSpacesTabSelectByName: { name: 'ViewSpacesTabSelectByName', defaultBroadcastDirection: "Global" },
    FacebookInit: { name: 'FacebookInit', defaultBroadcastDirection: "Global" },
    FacebookAuthchange: { name: 'FacebookAuthchange', defaultBroadcastDirection: "Global" },
    CCInfoResponse: { name: 'CCInfoResponse', defaultBroadcastDirection: "Global" },
    UIEvent: { name: 'UIEvent', defaultBroadcastDirection: "Global" },
    ParentToChildrenUIEvent: { name: 'ParentToChildrenUIEvent', defaultBroadcastDirection: "Global" },
    Screenshot: { name: 'Screenshot', defaultBroadcastDirection: "Global" },
    CloseAllOpenPopups: { name: 'CloseAllOpenPopups', defaultBroadcastDirection: "Global" },
    CloseAllOpenDropDowns: { name: 'CloseAllOpenDropDowns', defaultBroadcastDirection: "Global" },


    //----------------------------------------------------------------------------------------------------------------------------------------------

    // Action - GLOBAL Broadcast messages
    Sleep: { name: 'Sleep', defaultBroadcastDirection: "Global" },
    LogEntry: { name: 'LogEntry', defaultBroadcastDirection: "Global" },
    UnauthorizedApi: { name: 'UnauthorizedApi', defaultBroadcastDirection: "Global" },
    DisplayBasicModal: { name: 'DisplayBasicModal', defaultBroadcastDirection: "Global" },
    DisplayConfirmModal: { name: 'DisplayConfirmModal', defaultBroadcastDirection: "Global" },
    DisplayNotificationHeader: { name: 'DisplayNotificationHeader', defaultBroadcastDirection: "Global" },
    RemoveNotificationHeader: { name: 'RemoveNotificationHeader', defaultBroadcastDirection: "Global" },
    Alert: { name: 'Alert', defaultBroadcastDirection: "Global" },
    DisplayPopup: { name: 'DisplayPopup', defaultBroadcastDirection: "Global" },
    ClosePopup: { name: 'ClosePopup', defaultBroadcastDirection: "Global" },
    DisplayDesktopNotify: { name: 'DisplayDesktopNotify', defaultBroadcastDirection: "Global" },
    CloseDesktopNotify: { name: 'CloseDesktopNotify', defaultBroadcastDirection: "Global" },
    Broadcast: { name: 'Broadcast', defaultBroadcastDirection: "Global" },
    EditorShowWindow: { name: 'EditorShowWindow', defaultBroadcastDirection: "Global" },
    FileDownload: { name: 'FileDownload', defaultBroadcastDirection: "Global" },
    FileDelete: { name: 'FileDelete', defaultBroadcastDirection: "Global" },
    Tab: { name: 'Tab', defaultBroadcastDirection: "Global" },
    ApiRequest: { name: 'ApiRequest', defaultBroadcastDirection: "Global" },
    FlushControlCache: { name: 'FlushControlCache', defaultBroadcastDirection: "Global" },
    FlushUrlCache: { name: 'FlushUrlCache', defaultBroadcastDirection: "Global" },
    ForceRecaching: { name: 'ForceRecaching', defaultBroadcastDirection: "Global" },
    ForEach: { name: 'ForEach', defaultBroadcastDirection: "Global" },
    IfElse: { name: 'IfElse', defaultBroadcastDirection: "Global" },
    EvaluateExpression: { name: 'EvaluateExpression', defaultBroadcastDirection: "Global" },
    SetLanguage: { name: 'SetLanguage', defaultBroadcastDirection: "Global" },
    OpenChildWindow: { name: 'OpenChildWindow', defaultBroadcastDirection: "Global" },
    OpenChildWindowWithToken: { name: 'OpenChildWindowWithToken', defaultBroadcastDirection: "Global" },
    DebugNodeSelected: { name: 'DebugNodeSelected', defaultBroadcastDirection: "Global" },
    DebugShowWindow: { name: 'DebugShowWindow', defaultBroadcastDirection: "Global" },
    DebugAddWatch: { name: 'DebugAddWatch', defaultBroadcastDirection: "Global" },
    DebugRemoveWatch: { name: 'DebugRemoveWatch', defaultBroadcastDirection: "Global" },
    DebugNewUml: { name: 'DebugNewUml', defaultBroadcastDirection: "Global" },
    SetValue: { name: 'SetValue', defaultBroadcastDirection: "Global" },
    DeleteValue: { name: 'DeleteValue', defaultBroadcastDirection: "Global" },
    ReferenceUserAction: { name: 'ReferenceUserAction', defaultBroadcastDirection: "Global" },
    Badge: { name: 'Badge', defaultBroadcastDirection: "Global" },
    SelectMenuItem: { name: 'SelectMenuItem', defaultBroadcastDirection: "Global" },
    RunActions: { name: 'RunActions', defaultBroadcastDirection: "Global" },
    HelpHighlight: { name: 'HelpHighlight', defaultBroadcastDirection: "Global" },
    HelpHighlightStop: { name: 'HelpHighlightStop', defaultBroadcastDirection: "Global" },
    HelpTour: { name: 'HelpTour', defaultBroadcastDirection: "Global" },
    HelpTourStop: { name: 'HelpTourStop', defaultBroadcastDirection: "Global" },
    ShowWaitOverlay: { name: 'ShowWaitOverlay', defaultBroadcastDirection: "Global" },
    HideWaitOverlay: { name: 'HideWaitOverlay', defaultBroadcastDirection: "Global" },
    FullScreen: { name: 'FullScreen', defaultBroadcastDirection: "Global" },
    OpenDocumentEditor: { name: 'OpenDocumentEditor', defaultBroadcastDirection: "Global" },

    WebRTCConnectWebSocket: { name: 'WebRTCConnectWebSocket', defaultBroadcastDirection: "Global" },
    WebRTCVideoConnectWebSocket: { name: 'WebRTCVideoConnectWebSocket', defaultBroadcastDirection: "Global" },
    HSMSetPersistedState: { name: 'HSMSetPersistedState', defaultBroadcastDirection: "Parent" },

    // Action - PARENT Broadcast messages
    SchemaModelChanged: { name: 'SchemaModelChanged', defaultBroadcastDirection: "Parent" },
    FormFieldChanged: { name: 'FormFieldChanged', defaultBroadcastDirection: "Parent" },
    ReplaceContent: { name: 'ReplaceContent', defaultBroadcastDirection: "Parent" }, // ActionBroadcastTarget allowed
    DisplayFeedback: { name: 'DisplayFeedback', defaultBroadcastDirection: "Parent" }, // ActionBroadcastTarget allowed
    ChildViewSpace: { name: 'ChildViewSpace', defaultBroadcastDirection: "Parent" },
    Close: { name: 'Close', defaultBroadcastDirection: "Parent" },

    Refresh: { name: 'Refresh', defaultBroadcastDirection: "Parent" }, // ActionBroadcastTarget allowed
    RenameTab: { name: 'RenameTab', defaultBroadcastDirection: "Global" }, // "Parent" }, // For Vue, changed to Global (Tabs are not parents of content)
    SystemNotify: { name: 'SystemNotify', defaultBroadcastDirection: "Parent" }, // Normally used by internal controls that need to send messages

    HSMEvent: { name: 'HSMEvent', defaultBroadcastDirection: "Parent" },
    HSMStartServerEventListener: { name: 'HSMStartServerEventListener', defaultBroadcastDirection: "Parent" },
    HSMStopServerEventListener: { name: 'HSMStopServerEventListener', defaultBroadcastDirection: "Parent" },
    HSMSetNextState: { name: 'HSMSetNextState', defaultBroadcastDirection: "Parent" },
    HSMAddChildHSM: { name: 'HSMAddChildHSM', defaultBroadcastDirection: "Parent" },
    HSMRemoveChildHSM: { name: 'HSMRemoveChildHSM', defaultBroadcastDirection: "Parent" },

    // Action - Sent to base container, reflected back to children by baseContainer
    GridRefresh: { name: 'GridRefresh', defaultBroadcastDirection: "BaseContainerChildren" }, // ActionBroadcastTarget allowed
    TextEditorRefresh: { name: 'TextEditorRefresh', defaultBroadcastDirection: "BaseContainerChildren" }, // ActionBroadcastTarget allowed
    GridClearSelectedRows: { name: 'GridClearSelectedRows', defaultBroadcastDirection: "BaseContainerChildren" }, // ActionBroadcastTarget allowed
    SchemaFormTabSelectionChanged: { name: 'SchemaFormTabSelectionChanged', defaultBroadcastDirection: "BaseContainerChildren" }, // ActionBroadcastTarget allowed
    Message: { name: 'Message', defaultBroadcastDirection: "BaseContainerChildren" }, // generally used by form field events to trigger actions on other form fields
    ValidateForm: { name: 'ValidateForm', defaultBroadcastDirection: "GlobalNoTarget_BaseContainerChildrenWithTarget" },

    // New for VuePortal
    EmitControlEvent: { name: 'EmitControlEvent', defaultBroadcastDirection: "Parent" },
    RunControlCommand: { name: 'RunControlCommand', defaultBroadcastDirection: "Global" },
};

export default actionDefaults;