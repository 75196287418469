
export default {
    checkDevices: async function (currentMicrophoneId, currentSpeakerId) {
        const sourceInfos = await navigator.mediaDevices.enumerateDevices();

        let microphoneId = null;
        let speakerId = 'default';
        for (let i = 0; i !== sourceInfos.length; ++i) {
            const sourceInfo = sourceInfos[i];
            if (currentMicrophoneId == sourceInfo.deviceId) {
                microphoneId = currentMicrophoneId;
            }

            if (currentSpeakerId == sourceInfo.deviceId) {
                speakerId = currentSpeakerId;
            }

            if (microphoneId == currentMicrophoneId && speakerId == currentSpeakerId) {
                break;
            }
        }

        return { microphoneId, speakerId };
    }
};