import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import utils from '../../../Shared/utils.jsx';

Vue.component('cc-progress-circle-dsgn', {
    mixins: [BaseComponent],
    data: function () {
        return {
            tooltipEval: null,
            tooltipPlacementEval: null,
            disabledEval: null,
            objectFitEval: null,
        }
    },
    //Created Replaced with preRenderComplete
    computed: {
        style() {
            return {
                ...utils.getStyleHints(this.controlData.StyleHints),
                ...utils.getSize(this.controlData.SizeOptions, this.parentType, this.$parent),
                overflow: "none",
            };
        },
        color() {
            if (this.controlData.Color && !this.controlData.Color.includes('{'))
                return this.controlData.Color;
            else
                return 'orange';
        },
        value() {
            if (this.controlData.ValueExpression)
                return utils.helpers.tryParseInt(this.controlData.ValueExpression, 35);
            else
                return 35;
        },
        width() {
            if (this.controlData.WidthExpression)
                return utils.helpers.tryParseInt(this.controlData.WidthExpression, 10);
            else
                return 10;
        },
        size() {
            if (this.controlData.SizeExpression)
                return utils.helpers.tryParseInt(this.controlData.SizeExpression, 25);
            else
                return 25;
        },
        rotate() {
            if (this.controlData.RotateExpression)
                return utils.helpers.tryParseInt(this.controlData.RotateExpression, 0);
            else
                return 0;
        },
    },
    methods: {
    },
    render(h) {
        try {
            return (
                <div
                    style={this.style}
                    class={{ 'c-ProgressCircleDsgn': true, [`c-name-${this.name || 'unnamed'}`]: true, 'designer-container': true, selected: this.selected$ }}
                    on-click={(e) => this.handleClick(e)}
                >
                    <div class={{ "designer-container-hovering": true, 'designer-container-selected-clickthrough': this.selected$ }}></div>
                    <v-progress-circular
                        style={this.style}
                        value={this.value}
                        size={this.size}
                        width={this.width}
                        color={this.color}
                        rotate={this.rotate}
                        indeterminate={this.controlData.Indeterminate}
                    >
                        {this.controlData.Content}
                    </v-progress-circular>
                </div>
            );
        }
        catch (e) {
            utils.error('ProgressCircle Render failed', e);
            return <div>ProgressCircle Failed to Render {e}</div>;
        }
    }
});