import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import SchemaForm from './schemaform/SchemaForm.jsx';
import PropertyGrid from './form/PropertyGrid.jsx';
import utils from '../../Shared/utils.jsx';
import EventBus from '../event-bus.js';
//import Tv4 from '@/JavaScript/tv4/v1.0.17/care-tv4';
import careHelpfulFunctions from '../careHelpfulFunctions.jsx';

import debugDialog from './vuecontrols/debugDialog.vue';

const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
        if (typeof value === "object" && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        return value;
    };
};

Vue.component('basic-form', {
    mixins: [BaseComponent],
    components: {
        debugDialog
    },
    data: () => ({
        schemaurl: null,
        schemaraw: null,
        modelurl: null,
        modelvalue: null,
        defaultModel: {},
        formurl: null,
        formvalue: null,
        schema: null,
        model: {},
        form: ['*'],
        hasChanged: false,
        isValid: false,
        IsValid: function () {
            Validate();
            return this.isValid;
        },

        debug_form: false,
        debug_tab_selected: 0,
        formfinal: null,

        noinputexpr: null,
        arraystartexpr: null,
        destroystrategyexpr: null,

        validationExpressions: null,
        watch_model: false,
        notify_object: null,
    }),
    props: {},
    computed: {
        Model: function () {
            return this.model;
        },
        FormModel: function () {
            return this.model;
        },
        SchemaUrlValue: function () {
            return this.schemaurl ? utils.evaluate(this.schemaurl, this) : '';
        },
        SchemaRawValue: function () {
            return this.schemaraw ? utils.evaluateObject(this.schemaraw, this) : null;
        },
        ModelUrlValue: function () {
            return this.modelurl ? utils.evaluate(this.modelurl, this) : '';
        },
        ModelRawValue: function () {
            return this.modelvalue ? utils.evaluateObject(this.modelvalue, this) : null;
        },
        FormUrlValue: function () {
            return this.formurl ? utils.evaluate(this.formurl, this) : '';
        },
        FormRawValue: function () {
            return this.formvalue ? utils.evaluateObject(this.formvalue, this) : null;
        },
        NoInputValue: function () {
            if (this.noinputexpr)
                return utils.evaluateObject(this.noinputexpr, this);
            else
                return this.controlData.FormOptions.NoInput;
        },
        ArrayStartValue: function () {
            if (this.arraystartexpr)
                return utils.evaluateObject(this.arraystartexpr, this);
            else
                return this.controlData.FormOptions.ArrayStart;
        },
        DestroyStrategyVaue: function () {
            if (this.destroystrategyexpr)
                return utils.evaluateObject(this.destroystrategyexpr, this);
            else
                return this.controlData.FormOptions.DestroyStrategy;
        },
        FormHasChanged: function () {
            return this.hasChanged;
        },

        title() {
            if (this.controlData.Title)
                return (
                    <translation-container context={this} value={this.controlData.Title}></translation-container>
                );
            return null;
        },
    },
    watch: {
        SchemaUrlValue: function (newvalue) {
            if (!this.prerender_complete) return;
            utils.debug(`BasicForm ${this.name} SchemaUrlValue changed:${newvalue} - Refreshing`);
            this.Refresh();
        },
        SchemaRawValue: function (newvalue) {
            if (!this.prerender_complete) return;
            utils.debug(`BasicForm ${this.name} SchemaRawValue changed - Refreshing`);
            this.Refresh();
        },
        FormUrlValue: function (newvalue) {
            if (!this.prerender_complete) return;
            utils.debug(`BasicForm ${this.name} FormUrlValue changed: ${newvalue} - Refreshing`);
            this.Refresh();
        },
        FormRawValue: function (newvalue) {
            if (!this.prerender_complete) return;
            utils.debug(`BasicForm ${this.name} FormRawValue changed - Refreshing`);
            this.Refresh();
        },
        ModelUrlValue: async function (newvalue, oldvalue) {
            if (!this.prerender_complete) return;
            utils.debug(`BasicForm ${this.name} url changed from ${oldvalue} to ${newvalue}`);
            this.model = await utils.api.get(newvalue);
            utils.forms.mixModels(this.defaultModel, this.model);
        },
        //ModelRawValue: function (newvalue) {
        //    if (!this.prerender_complete) return;
        //    utils.debug(`BasicForm ${this.name} model raw value changed`);
        //    this.model = newvalue;
        //    utils.forms.mixModels(this.defaultModel, this.model);
        //}
    },
    created() {
        if (this.controlData.Schema && this.controlData.Schema.Type == 'URL' && this.controlData.Schema.Definition)
            this.schemaurl = utils.compile(this, this.controlData.Schema.Definition);
        else if (this.controlData.Schema && (this.controlData.Schema.Type == 'Raw' || this.controlData.Schema.Type == 'RawInterpolated') && this.controlData.Schema.Definition)
            this.schemaraw = utils.compileObject(this, this.controlData.Schema.Definition);
        else if (this.controlData.Schema)
            this.schema = this.controlData.Schema.Definition;

        if (this.controlData.Model && this.controlData.Model.Type == 'URL' && this.controlData.Model.Definition)
            this.modelurl = utils.compile(this, this.controlData.Model.Definition);
        else if (this.controlData.Model && this.controlData.Model.Type == 'Raw' && this.controlData.Model.Definition)
            this.model = this.controlData.Model.Definition;
        else if (this.controlData.Model && this.controlData.Model.Type == 'RawInterpolated' && this.controlData.Model.Definition)
            this.modelvalue = utils.compileObject(this, this.controlData.Model.Definition);

        if (this.controlData.Form && this.controlData.Form.Type == 'URL' && this.controlData.Form.Definition)
            this.formurl = utils.compile(this, this.controlData.Form.Definition);
        else if (this.controlData.Form && this.controlData.Form.Type == 'Raw' && this.controlData.Form.Definition)
            this.form = this.controlData.Form.Definition;
        else if (this.controlData.Form && this.controlData.Form.Type == 'RawInterpolated' && this.controlData.Form.Definition)
            this.formvalue = utils.compileObject(this, this.controlData.Form.Definition);
        else if (this.controlData.Form)
            this.form = this.controlData.Form.Definition;

        if (this.controlData.DefaultModel) {
            const defaultModelexpn = utils.compileObject(this, this.controlData.DefaultModel);
            this.defaultModel = utils.evaluateObject(defaultModelexpn, this);
        }

        if (this.controlData.FormOptions.NoInput && typeof this.controlData.FormOptions.NoInput === 'string')
            this.noinputexpr = utils.compileObject(this, this.controlData.FormOptions.NoInput);

        if (this.controlData.FormOptions.ArrayStart && typeof this.controlData.FormOptions.ArrayStart === 'string')
            this.arraystartexpr = utils.compileObject(this, this.controlData.FormOptions.ArrayStart);

        if (this.controlData.FormOptions.DestroyStrategy && typeof this.controlData.FormOptions.DestroyStrategy === 'string')
            this.destroystrategyexpr = utils.compileObject(this, this.controlData.FormOptions.DestroyStrategy);


        if (this.controlData.ValidationExpressions) {
            let validationExpressions = [];
            this.controlData.ValidationExpressions.forEach(exp => {
                validationExpressions.push({
                    expn: utils.compileExpression(this, exp.Expression),
                    error: utils.compile(this, exp.ErrorMessage),
                });
            });
            this.validationExpressions = validationExpressions;
        }

        // Moved this into ActionService
        //EventBus.$on('Action-ValidateForm', this.performValidateForm);

        this.$on('Action-ValidateForm', this.performValidateForm);

        if (this.name)
            EventBus.$on(`Action-ValidateForm:${this.name}`, this.performValidateForm);

    },
    //Mounted Replaced with preRenderComplete
    destroyed() {
        if (this.publish_watch$) {
            this.publish_watch$();
        }
        if (this.rawmodelvaluewatch$)
            this.rawmodelvaluewatch$();

        // Moved this into ActionService
        //EventBus.$off('Action-ValidateForm', this.performValidateForm);

        this.$off('Action-ValidateForm', this.performValidateForm);

        if (this.name)
            EventBus.$off(`Action-ValidateForm:${this.name}`, this.performValidateForm);
    },
    methods: {
        async preRenderComplete() {
            utils.debug(`BasicForm ${this.name} preRenderComplete - Refreshing`);
            await this.Refresh();
            this.finishRenderHandler(this);
        },
        async Refresh() {
            if (this.publish_watch$) {
                this.publish_watch$();
                this.publish_watch$ = undefined;
            }

            if (this.controlData.Schema && this.controlData.Schema.Type == 'URL' && this.schemaurl)
                try {
                    // This is async and takes some time, so clearing the schema first insures that the form will dispose of all schema-dependent fields while we wait.
                    this.schema = {};

                    const schemaurl = this.SchemaUrlValue;

                    if (schemaurl.toLowerCase().substr(0, 5) == 'apps/')
                        this.schema = await utils.api.get(schemaurl);
                    else
                        this.schema = await utils.schema.get(schemaurl, true);

                    //this.schema = utils.schema.resolve_Of(s);
                }
                catch (e) {
                    utils.warn(`BasicForm ${this.name} schemaurl ${this.controlData.Schema.Definition} failed to load (${this.SchemaUrlValue}): `, e);
                    this.schema = null;
                }
            else if (this.schemaraw) {
                this.schema = this.SchemaRawValue;
                await utils.schema.resolve(this.schema);
            }
            else if (this.schema)
                await utils.schema.resolve(this.schema);

            //if (this.schema)
            //    this.schema = utils.schema.resolve_Of(this.schema);

            if (this.controlData.Model && this.controlData.Model.Type == 'URL' && this.modelurl) {
                if (this.ModelUrlValue)
                    try {
                        this.model = {};
                        this.model = await utils.api.get(this.ModelUrlValue);

                        if (this.model && this.model.Record)
                            this.model = this.model.Record;
                    }
                    catch (e) {
                        utils.warn(`BasicForm failed to get model via URL: ${this.ModelUrlValue}`, e);
                    }
            }
            else if (this.controlData.Model && this.controlData.Model.Type == 'RawInterpolated' && this.modelvalue)
                try {
                    this.model = this.ModelRawValue || {};

                    if (typeof this.controlData.Model.Definition === 'string' &&
                        this.controlData.Model.Definition.startsWith('{%') &&
                        this.controlData.Model.Definition.endsWith('%}') &&
                        !this.rawmodelvaluewatch$)
                        this.rawmodelvaluewatch$ = this.$watch(
                            function () {
                                return this.ModelRawValue;
                            },
                            function (newv, oldv) {
                                this.Refresh();
                            }
                        );
                }
                catch (e) {
                    utils.warn('BasicForm modelvalue ' + this.controlData.Model.Definition + ' failed to evaluate: ' + e);
                    this.model = {};
                }
            else if (this.controlData.Model && this.controlData.Model.Type == 'Raw' && this.controlData.Model.Definition)
                this.model = this.controlData.Model.Definition;
            else
                this.model = {};

            if (this.controlData.Form && this.controlData.Form.Type == 'URL' && this.formurl) {
                this.form = [];
                this.form = await utils.api.get(this.FormUrlValue);
            }
            else if (this.controlData.Form && this.controlData.Form.Type == 'RawInterpolated' && this.formvalue)
                try {
                    this.form = this.FormRawValue;
                }
                catch (e) {
                    utils.warn('BasicForm formvalue ' + this.controlData.Form.Definition + ' failed to evaluate: ' + e);
                    this.form = {};
                }
            else if (this.controlData.Form && (this.controlData.Form.Type == 'Raw' || this.controlData.Form.Type == 'Inline') && this.controlData.Form.Definition)
                this.form = this.controlData.Form.Definition;

            if (!this.model)
                this.model = {};

            utils.forms.mixModels(this.defaultModel, this.model);

            if (this.controlData.PublishField) {
                let vueSet = utils.helpers.convertSetValueToVueSet(this.controlData.PublishField, 'value');
                this.setValueFunction = new Function('value', 'context', 'util', 'vue', `with (context) { ${vueSet} }`);
            }

            // Look for a form_changed_indicator up the parent hierarchy
            let p = this.$parent;
            while (p && (!('cc_form_changed_indicator' in p) || !p.cc_form_changed_active))
                p = p.$parent;

            if (p) {
                p.cc_form_changed_linked = true;
                p.cc_form_changed_form = this;
                this.notify_object = p;
            }

            this.watch_model = !!(this.notify_object || this.setValueFunction);

            this.hasChanged = false;
            this.isready = true;
        },
        modelChanged(newvalue) {
            if (this.setValueFunction)
                this.setValueFunction(newvalue, this, careHelpfulFunctions, Vue);

            this.hasChanged = true;
            utils.warn(`BasicForm ${this.name} change fired, notifying ${this.notify_object ? this.notify_object.name : 'null'}`);

            // If the form model changes set the cc_form_changed_indicator
            if (this.notify_object) {
                utils.warn(`BasicForm ${this.name} change fired, notifying ${this.notify_object ? this.notify_object.name : 'null'}`);
                //utils.warn(JSON.stringify(newvalue));

                this.notify_object.formChanged();
            }
        },
        showForm(e) {
            e.cancelBubble = true;
            e.stopPropagation();

            this.debug_form = true;
        },
        checkModelValue(form, field_key, model, errors) {
            let m = model;
            for (let j = 0; j < field_key.length && typeof m === 'object' && !!m; j++) {
                const k = field_key[j];

                if (k == '[]' && Array.isArray(m) && m.length > 0) {
                    // We have encountered an array indicator that is non-specific.
                    // We now need to generate a loop for each element in the model's array and
                    // call checkFormRequired using the indexes in place of [] for each element.
                    const key = [...field_key];
                    for (let x = 0; x < m.length; x++) {
                        key[j] = x;
                        this.checkModelValue(form, key, model, errors);
                    }
                    return;
                }

                m = m[k];
            }

            if (m === null || m === undefined || m === '') {
                errors.push({
                    element: utils.forms.joinFieldKey(field_key),
                    error: `Missing Required field named ${form.title || field_key[field_key.length - 1]}`,
                    reason: 'required'
                });
            }
        },
        checkFormRequired(form, model, errors) {
            for (let i = 0; i < form.length; i++) {
                const field = form[i];

                if (field.required)
                    this.checkModelValue(field, field.key, model, errors);

                if (field.items)
                    this.checkFormRequired(field.items, model, errors);
            }
        },
        Validate() {
            let errors = this.schema ? utils.schema.performValidation(this.schema, this.model) : [];

            //if (this.formfinal)
            //    this.checkFormRequired(this.formfinal, this.model, errors);

            // Remove duplicates
            errors = errors.filter((e, index) => {
                return errors.findIndex(a => a.element == e.element && a.reason == e.reason) === index;
            });

            if (this.validationExpressions)
                this.validationExpressions.forEach(exp => {
                    const isvalid = utils.evaluate(exp.expn, this);
                    if (!isvalid)
                        errors.push({
                            element: 'Root object',
                            error: utils.evaluate(exp.error, this),
                            reason: 'validation'
                        });
                });

            // Invoke validation on each field using the Vuetify form validation rules
            if (this.schemaForm?.$refs?.form) {
                const res = this.schemaForm.$refs.form.validate();
                if (!res) errors.push({
                    element: 'Form fields',
                    error: 'Form field validation failed',
                    reason: 'validation'
                });
            }

            //set is valid based on if we have errors or not.
            this.isValid = errors ? errors.length == 0 : true;

            // Send a DisplayFeedback action to display the errors (or clear them if needed)
            const feedback = {
                PrimitiveAction: true,
                ActionType: 'DisplayFeedback',
                ActionBroadcastDirection: 'Parent',
                ActionData: {
                    Messages: [{
                        MessageType: 'Error',
                        Message: errors.map(e => `<span title="Field location: ${e.element}">${e.error}</span>`).join('<br />')
                    }],
                    MessageGroup: this.name || 'unnamed_form',
                },
            };

            utils.debug(`BasicForm:${this.name} invoking DisplayFeedback with ${errors.length} errors`);
            utils.executeAndCompileAllActions([feedback], { Errors: errors }, this);

            return errors;
        },
        async performValidateForm(action) {
            utils.log(`BasicForm ValidateForm`);

            if (action.ActionData && action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            try {
                const errors = this.Validate();

                utils.log(`BasicForm ${this.name} validation discovered ${errors.length} error(s) [${errors.map(e => e.element + ':' + e.error).join('; ')}]`);

                try {
                    if (errors.length === 0)
                        await utils.success(action);
                    else
                        await utils.failure(action, { Errors: errors });
                } catch (e) { }
            }
            catch (e) {
                utils.warn(`BasicForm ${this.name} failed to validate`, e);

                try {
                    await utils.failure(action);
                } catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
        saveSchemaForm(f, s) {
            this.formfinal = f;
            this.schemaForm = s;
        },
    },
    render() {
        if (!this.isready || !this.prerender_complete || !this.todisplay || !this.model)
            return null;

        let Control = 'schema-form';
        if (this.controlData.FormType === 'PropertyGrid')
            Control = 'property-grid';

        const style = {
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            ...utils.getSize({ Width: { Mode: 'Fill' }, ...this.sizeOptions }, this.parentType, this.$parent),
            ...utils.resolveStyleHints(this.styleHints, this),
            //padding: "10px"
        };

        const control = (
            <Control
                v-show={this.isvisible}
                name={this.controlData.Name || 'unnamed'}
                root={this.root}
                schema={this.schema}
                form={this.form}
                cmodel={this.model}
                noinput={this.NoInputValue}
                enable_watch={this.watch_model}
                on-formfinal={(f, s) => this.saveSchemaForm(f, s)}
                on-change={(v) => this.modelChanged(v)}
                scopeitems={this.scopeitems}
                controlscope={this.controlscope}
            >
            </Control>
        );

        // Provide a debug form that will show the contents of the model as formatted JSON
        if (this.isdebug)
            return (
                <div
                    style={{ ...style, position: "relative" }} class={{ 'c-BasicForm': true, [`c-name-${this.name || 'unnamed'}`]: true }}>
                    <h1>{this.title}</h1>
                    <i title="Debug Form" class="mdi mdi-bug debug-form-icon" style={{ cursor: "pointer" }} on-click={(e) => this.showForm(e)}></i>
                    {control}

                    <v-dialog value={this.debug_form} persistent max-width="1000" scrollable>
                        <debug-dialog schema={this.schema} form={this.formfinal || this.form} cmodel={this.model} on-closeme={() => this.debug_form = false}>
                        </debug-dialog>
                    </v-dialog>
                </div>
            );
        else
            return (
                <div style={style} class={{ 'c-BasicForm': true, [`c-name-${this.name || 'unnamed'}`]: true }} >
                    <h1>{this.title}</h1>
                    {control}
                </div>
            );
    }
});