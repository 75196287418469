<template>
    <div ref="joint"></div>
</template>

<script>
export default {
	name: 'JointPaper',
	props: {
		width: {
			type: [String],
			default: "3000px"
		},
		height: {
			type: [String],
			default: "3000px"
		},
		gridSize: {
			type: Number,
			default: 1
		},
		drawGrid: {
			type: [Object, Boolean],
			default: false
		},
		background: {
			type: [Object, Boolean],
			default: false
		},
		readonly: {
			type: Boolean,
			default: false
		},
		defaultLink: {
			type: [Function],
			default: () => new this.$joint.shapes.standard.Link(),
		},
        linkPinning: {
            type: Boolean,
            default: false
		},
        validateConnection: {
            type: [Function],
            default: () => true,
        },
	},
	created() {
		this.name = this.$options.name;
		console.log(`[${this.name}] Created`);
		this.graph = new this.$joint.dia.Graph({}, { cellNamespace: this.$joint.shapes });
	},
	mounted() {
		console.log(`[${this.name}] Mounted:`, this.$refs.joint);
		this.paper = new this.$joint.dia.Paper({
			el: this.$refs.joint,
			cellViewNamespace: this.$joint.shapes,
			model: this.graph,
			width: this.width,
			height: this.height,
			gridSize: this.gridSize,
			drawGrid: this.drawGrid,
			background: this.background,
			interactive: !this.readonly,
			defaultLink: this.defaultLink,
			linkPinning: this.linkPinning,
            validateConnection: this.validateConnection,
		});
		this.$emit('init', this.graph, this.paper);
	}
};
</script>

<style scoped>
</style>