import Vue from 'vue';
import Vuetify from 'vuetify';
import utils from '../../../Shared/utils.jsx';

Vue.component('input-dialog', {
    data: () => ({
        value: '',
    }),
    props: {
        root: null,
        okay: null,
        cancel: null,
        accept: null,
        title: '',
        label: '',
        default_value: null,
    },
    mounted() {
        if (this.default_value)
            this.value = this.default_value;
    },
    methods: {
        confirm() {
            this.okay();
            this.accept(this.value);
        }
    },
    render(h) {
        let content = (
            <v-card>
                <v-card-title class="headline">{this.title}</v-card-title>
                <v-card-text>
                    <v-text-field
                        label={this.label}
                        value={this.value}
                        on-input={(value) => this.value = value}>
                    </v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation={0} color="green darken-1" on-click={this.confirm}>OK</v-btn>
                    <v-btn elevation={0} color="green darken-1" on-click={this.cancel}>Cancel</v-btn>
                </v-card-actions>
            </v-card>
        );                

        return content;
    }
});