import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';

import utils from '../../Shared/utils.jsx';
import methods from '../../Shared/methods';

Vue.component('popup-menu-button', {
    mixins: [BaseComponent],
    data: function () {
        return {
            textrenderer: null,
            iconrenderer: null,
            iconpositioner: null,
            icongapeval: null,
            flipHorizontallyEval: null,
            flipVerticallyEval: null,
            borderEval: null,
            animationEval: null,
            colorEval: null,
            cssClassEval: null,
            tooltiprenderer: null,
            tooltipLocationEval: null,
            popupMenuPlacementEval: null,
            popupMenuBackgroundColorEval: null,
            popupMenuFontColorEval: null,
            popupMenuWidthEval: null,
            popupMenuBodyPaddingEval: null,
            isDefaultEval: null,
            isdisabledeval: null,

            menu_data: null,
        }
    },
    created() {
        this.textrenderer = utils.compile(this, this.controlData.Text);
        this.tooltiprenderer = utils.compile(this, this.controlData.Tooltip);
        this.iconrenderer = utils.compile(this, this.controlData.Icon);
        this.isdisabledeval = utils.compile(this, this.controlData.IsDisabled);

        switch (this.controlData.MenuSourceType) {
            case 'Json':
                this.menusourceexpn = utils.compileObject(this, this.controlData.MenuJson);
                this.menu_data = utils.evaluateObject(this.menusourceexpn, this);

                if (!this.modelvalue_watch$)
                    this.modelvalue_watch$ = this.$watch(
                        function () {
                            try {
                                return utils.evaluateObject(this.menusourceexpn, this);
                            }
                            catch (e) {
                                utils.warn('PopupMenuButton MenuJson ' + this.controlData.MenuJson + ' failed to evaluate', e);
                                return null;
                            }
                        },
                        function (val, oldval) {
                            this.Refresh();
                        },
                        {
                            deep: false
                        }
                    );
                break;

            case 'URL':
                this.menuurlexpn = utils.compile(this, this.controlData.MenuURL);
                break;

            case 'Inline':
            default:
                this.menu_data = this.controlData.Menu;
                break;
        }
    },
    destroyed() {
        if (this.modelvalue_watch$)
            this.modelvalue_watch$();
    },
    //Mounted Replaced with preRenderComplete
    computed: {
        text() {
            if (this.controlData.Text)
                return (
                    <translation-container context={this} value={this.controlData.Text}></translation-container>
                );
            return null;
        },
        icon() {
            if (this.iconrenderer === null)
                this.iconrenderer = utils.compile(this, this.controlData.Icon);
            let icon = utils.evaluate(this.iconrenderer, this);

            return icon;
        },
        iconPosition() {
            if (this.iconpositioner === null && this.controlData.IconPosition)
                this.iconpositioner = utils.compile(this, this.controlData.IconPosition);
            if (this.iconpositioner)
                return utils.evaluate(this.iconpositioner, this);
            return 'right';
        },
        iconGap() {
            if (this.icongapeval === null && this.controlData.IconGap)
                this.icongapeval = utils.compile(this, this.controlData.IconGap);
            if (this.icongapeval)
                return utils.evaluate(this.icongapeval, this);
            return 10;
        },
        flipHorizontally() {
            if (this.flipHorizontallyEval === null && this.controlData.FlipHorizontally)
                this.flipHorizontallyEval = utils.compile(this, this.controlData.FlipHorizontally);
            if (this.flipHorizontallyEval)
                return utils.evaluate(this.flipHorizontallyEval, this);
            return false;
        },
        flipVertically() {
            if (this.flipVerticallyEval === null && this.controlData.flipVertically)
                this.flipVerticallyEval = utils.compile(this, this.controlData.flipVertically);
            if (this.flipVerticallyEval)
                return utils.evaluate(this.flipVerticallyEval, this);
            return false;
        },
        border() {
            if (this.borderEval === null && this.controlData.Border)
                this.borderEval = utils.compile(this, this.controlData.Border);
            if (this.borderEval)
                return utils.evaluate(this.borderEval, this);
            return false;
        },
        animation() {
            if (this.animationEval === null && this.controlData.Animation)
                this.animationEval = utils.compile(this, this.controlData.Animation);
            if (this.animationEval)
                return utils.evaluate(this.animationEval, this);
            return 'None';
        },
        color() {
            let theColor = 'secondary';
            if (this.colorEval === null && this.controlData.Color)
                this.colorEval = utils.compile(this, this.controlData.Color);
            if (this.colorEval)
                theColor = utils.evaluate(this.colorEval, this);

            if (this.isOutlined && theColor)
                theColor = theColor.replace('outline-', '');
            else
                theColor = theColor || 'secondary';
            // This helps with backwards compatibility for a version of Bootstrap that used the word 'default' to describe the secondary button
            return theColor === 'default' ? 'secondary' : theColor;
        },
        cssClass() {
            if (this.cssClassEval === null && this.controlData.CssClass)
                this.cssClassEval = utils.compile(this, this.controlData.CssClass);
            if (this.cssClassEval)
                return utils.evaluate(this.cssClassEval, this);
            return '';
        },
        tooltip() {
            if (this.tooltiprenderer === null && this.controlData.Tooltip)
                this.tooltiprenderer = utils.compile(this, this.Translate(this.controlData.Tooltip));
            if (this.tooltiprenderer) {
                return utils.evaluate(this.tooltiprenderer, this);
            }
            return '';
        },
        tooltipLocation() {
            let location = '';
            if (this.tooltipLocationEval === null && this.controlData.TooltipLocation)
                this.tooltipLocationEval = utils.compile(this, this.controlData.TooltipLocation);
            if (this.tooltipLocationEval)
                location = utils.evaluate(this.tooltipLocationEval, this);

            return location.split('-')[0].toLowerCase() || 'top';
        },
        popupMenuPlacement() {
            if (this.popupMenuPlacementEval === null && this.controlData.PopupMenuPlacement)
                this.popupMenuPlacementEval = utils.compile(this, this.controlData.PopupMenuPlacement);
            if (this.popupMenuPlacementEval)
                return utils.evaluate(this.popupMenuPlacementEval, this);
            return 'bottom';
        },
        popupMenuBackgroundColor() {
            if (this.popupMenuBackgroundColorEval === null && this.controlData.PopupMenuBackgroundColor)
                this.popupMenuBackgroundColorEval = utils.compile(this, this.controlData.PopupMenuBackgroundColor);
            if (this.popupMenuBackgroundColorEval)
                return utils.evaluate(this.popupMenuBackgroundColorEval, this);
            return '';
        },
        popupMenuFontColor() {
            if (this.popupMenuFontColorEval === null && this.controlData.PopupMenuFontColor)
                this.popupMenuFontColorEval = utils.compile(this, this.controlData.PopupMenuFontColor);
            if (this.popupMenuFontColorEval)
                return utils.evaluate(this.popupMenuFontColorEval, this);
            return '';
        },
        popupMenuWidth() {
            if (this.popupMenuWidthEval === null && this.controlData.PopupMenuWidth)
                this.popupMenuWidthEval = utils.compile(this, this.controlData.PopupMenuWidth);
            if (this.popupMenuWidthEval)
                return utils.evaluate(this.popupMenuWidthEval, this);
            return '';
        },
        popupMenuBodyPadding() {
            if (this.popupMenuBodyPaddingEval === null && this.controlData.PopupMenuBodyPadding)
                this.popupMenuBodyPaddingEval = utils.compile(this, this.controlData.PopupMenuBodyPadding);
            if (this.popupMenuBodyPaddingEval)
                return utils.evaluate(this.popupMenuBodyPaddingEval, this);
            return '';
        },
        isDefault() {
            if (this.isDefaultEval === null && this.controlData.IsDefault)
                this.isDefaultEval = utils.compile(this, this.controlData.IsDefault);
            if (this.isDefaultEval)
                return utils.evaluate(this.isDefaultEval, this);
            return false;
        },
        isdisabled() {
            if (this.isdisabledeval === null && this.controlData.IsDisabled)
                this.isdisabledeval = utils.compile(this, this.controlData.IsDisabled);
            if (this.isdisabledeval)
                return utils.evaluate(this.isdisabledeval, this);
            return false;
        },
        isOutlined() {
            return this.controlData.Color && this.controlData.Color.startsWith('outline-');
        },
        buttonClasses() {
            let classes = {
                'ThemeRightConnector': this.themes.rightConnector,
                'ThemeLeftConnector': this.themes.leftConnector,
            };

            classes[this.cssClass] = true;

            return classes;
        },
        iconClasses() {
            return {
                'mdi mdi-flip-vertical': this.flipVertically,
                'mdi mdi-flip-horizontal': this.flipHorizontally,
                'mdi mdi-border-all-variant': this.border,
                'mdi-animate-spin': this.animation === 'Spin',
                'mdi-animate-pulse': this.animation === 'Pulse',
            }
        },
        themes() {
            return {
                flat: this.controlData.Themes ? this.controlData.Themes.includes("Flat") || this.controlData.Themes.includes("FlatWhite") || this.controlData.Themes.includes("Transparent") : false,
                small: this.controlData.Themes ? this.controlData.Themes.includes("Condensed") || this.controlData.Themes.includes("CondensedBordered") : false,
                condensedBordered: this.controlData.Themes ? this.controlData.Themes.includes("CondensedBordered") : false,
                transparent: this.controlData.Themes ? this.controlData.Themes.includes("Transparent") : false,
                leftConnector: this.controlData.Themes ? this.controlData.Themes.includes("LeftConnector") : false,
                rightConnector: this.controlData.Themes ? this.controlData.Themes.includes("RightConnector") : false,
            }
        },
        styles() {
            return {
                ...utils.resolveStyleHints(this.styleHints, this),
                overflow: "hidden",
                whiteSpace: "nowrap",
            };
        }
    },
    methods: {
        preRenderComplete(){
            this.finishRenderHandler(this);
            this.Refresh();
        },
        async Refresh() {
            switch (this.controlData.MenuSourceType) {

                case 'Json':
                    this.menu_data = utils.evaluateObject(this.menusourceexpn, this);
                    break;

                case 'URL':
                    const url = utils.evaluate(this.menuurlexpn, this);
                    this.menu_data = await utils.api.get(url);
                    break;
            }
        },
        async doClick(e, h) {
            e.cancelBubble = true;
            e.stopPropagation();
        },
        menuOpenClose(state) {
            if (this.controlData.OnMenuOpen)
                utils.executeAndCompileAllActions(this.controlData.OnMenuOpen, { State: state }, this);
        },
        menuClick(e, m) {
            if (m.Actions)
                utils.executeAndCompileAllActions(m.Actions, {}, this);
        },
    },
    props: {
    },
    render(h) {
        if (!this.todisplay)
            return null;

        let content = [];

        if (this.text && this.icon) {
            if (this.iconPosition === 'Left') content.push(<v-icon style={{ 'margin-right': this.iconGap + 'px' }} medium={!this.themes.small} class={this.iconClasses} left>{this.icon}</v-icon>);
            content.push(this.text);
            if (!this.iconPosition || this.iconPosition === 'Right') content.push(<v-icon style={{ 'margin-left': this.iconGap + 'px' }} medium={!this.themes.small} class={this.iconClasses} right>{this.icon}</v-icon>);
        }
        else if (this.text)
            content.push(this.text);
        else if (this.icon)
            content.push(<v-icon class={this.iconClasses} small>{this.icon}</v-icon>);

        let menu;

        if (this.menu_data && this.menu_data.length > 0) {
            const scopedSlots = {
                activator: ({ on, attrs }) => {
                    let button = (
                        <v-btn elevation={0}
                            class={this.buttonClasses}
                            style={this.styles}
                            color={this.color}
                            outlined={this.isOutlined || this.themes.condensedBordered}
                            depressed={this.themes.flat}
                            text={this.themes.transparent}
                            x-small={this.themes.small && !!this.text}
                            small={this.themes.small && !this.text}
                            medium={!this.themes.small}
                            icon={!this.text}
                            type="button"
                            disabled={this.isdisabled == 'true'}
                            is-default={this.isDefault}
                            block={this.sizeOptions && this.sizeOptions.Width && this.sizeOptions.Width.Mode === 'Fill'}
                            {...{ on }}
                            {...{ attrs }}>
                            {content}
                        </v-btn>
                    );

                    return utils.generateTooltip(h, button, this.tooltip, this.tooltipLocation);
                }
            };

            const items = [];
            for (let i = 0; i < this.menu_data.length; i++) {
                const m = this.menu_data[i];
                if (m.Separator)
                    items.push(<v-divider></v-divider>);
                else {
                    if (m.EnabledExpression && !m.$$enabledexpn)
                        m.$$enabledexpn = utils.compileExpression(this, m.EnabledExpression);

                    if (m.Icon && !m.$$iconexpn)
                        m.$$iconexpn = utils.compile(this, m.Icon);

                    if (m.Icon && m.IconColor && !m.$$iconcolorexpn)
                        m.$$iconcolorexpn = utils.compile(this, m.IconColor);

                    if(m.Title && !m.$$titleexpn)
                        m.$$titleexpn = utils.compile(this, m.Title);

                    let menuicon;
                    if (m.$$iconexpn) {
                        let iconcolor;
                        if (m.$$iconcolorexpn)
                            iconcolor = utils.evaluate(m.$$iconcolorexpn, this);

                        menuicon =
                            <v-list-item-icon>
                                <v-icon style={{ color: iconcolor }} small>{utils.evaluate(m.$$iconexpn, this)}</v-icon>
                            </v-list-item-icon>;
                    }

                    items.push(
                        <v-list-item
                            key={i}
                            disabled={(m.$$enabledexpn && !utils.evaluate(m.$$enabledexpn, this)) ? true : false}
                            on-click={(e) => this.menuClick(e, m)}>
                            {menuicon}
                            <v-list-item-content>
                                <v-list-item-title>{utils.evaluate(m.$$titleexpn, this)}</v-list-item-title>
                            </v-list-item-content>

                        </v-list-item>
                    );
                }
            }

            menu = (
                <v-menu
                    offset-y
                    close-on-click={true}
                    close-on-content-click={true}
                    scopedSlots={scopedSlots}
                    on-input={(state) => this.menuOpenClose(state)}
                >
                    <v-list dense>
                        <v-list-item-group>
                            {items}
                        </v-list-item-group>
                    </v-list>
                </v-menu>
            );
        }
        else
            menu =
                <v-btn elevation={0}
                    class={this.buttonClasses}
                    style={this.styles}
                    color={this.color}
                    outlined={this.isOutlined || this.themes.condensedBordered}
                    depressed={this.themes.flat}
                    text={this.themes.transparent}
                    x-small={this.themes.small && !!this.text}
                    small={this.themes.small && !this.text}
                    medium={!this.themes.small}
                    icon={!this.text}
                    type="button"
                    disabled={this.isdisabled == 'true'}
                    is-default={this.isDefault}
                    block={this.sizeOptions && this.sizeOptions.Width && this.sizeOptions.Width.Mode === 'Fill'}>
                    {content}
                </v-btn>;

        return (
            <div
                class={{ 'c-PopupMenuButton': true, [`c-name-${this.name || 'unnamed'}`]: true }}
                v-show={this.isvisible} class="ma-1" style={this.sizeStyle}>
                {menu}
            </div>
        );
    }
});