import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import BaseComponent from './BaseComponentMixin.jsx';

Vue.component('dropdown-list-dsgn', {
    mixins: [BaseComponent],
    data: function () {
        return {
            Value: null,
            items: [],
        }
    },
    created() {
    },
    computed: {
    },
    mounted() {
        this.Refresh();
    },
    methods: {
        async Refresh() {
        },
    },
    props: {
    },
    render(h) {
        const style = {
            ...utils.getSize(this.controlData.SizeOptions, this.parentType, this.$parent),
            ...utils.getStyleHints(this.controlData.StyleHints, this),
            overflow: "hidden",
            whiteSpace: "nowrap",
            display: "inline-block",
            minWidth: "300px",
        };

        const fields = this.controlData.FieldMappings;

        let select = (
            <v-select
                solo
                dense
                single-line
                hide-details
                label={this.controlData.Label}>
            </v-select>
        );

        if (this.label)
            select = <span style="">{this.controlData.Label} {select}</span>;

        return (
            <div
                class={{ 'designer-container': true }}
                style={style}
                on-click={(e) => this.handleClick(e)}>
                <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
                {select}
            </div>
        );
    }
});