import Vue from 'vue';
import BaseComponent from './baseFormMixin.jsx';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

Vue.component('sform-password', {
    mixins: [BaseComponent],
    data: () => ({
    }),
    props: {
    },
    created() {
    },
    mounted() {
        this.loadComplete();
    },
    computed: {
    },
    methods: {
    },
    render() {
        let scopedSlots = {};
        if (this.element.fieldAddonLeft)
            scopedSlots['prepend-inner'] = () => this.element.fieldAddonLeft;

        scopedSlots.message = ({ message }) => {
            return <translation-container context={this} value={message}></translation-container>
        };

        let slots = [
            <translation-container slot="label" context={this} value={this.labelText}></translation-container>,
        ];

        let input =
            <v-text-field
                type="password"
                class="caption pa-0 ma-0"
                outlined single-line dense hide-details
                style={{ width: "100%" }}
                scopedSlots={scopedSlots}
                value={this.itemvalue}
                rules={this.rules}
                on-input={(value) => this.sync(value)}
                on-blur={this.onBlur}
                on-focus={this.onFocus}>
                {slots}
            </v-text-field>;

        if (this.appearance)
            input.componentOptions.propsData = { ...input.componentOptions.propsData, ...this.appearance };

        if (this.directives) {
            if (input.data.directives)
                input.data.directives = [...input.data.directives, ...this.directives];
            else
                input.data.directives = this.directives;
        }

        return input;
    }
});