import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';
import usercontrolBrowser from './usercontrolBrowser.jsx';

Vue.component('dynamic-user-control-dsgn', {
    data: function () {
        return {
            control: {},
            pickervisible: false,
            picker: null,
        }
    },
    created() {
        this.watcher$ = this.$watch('designmodel.ControlURL', function (val, oldval) {
            this.Refresh();
        });
    },
    destroyed() {
        this.watcher$();
    },  
    async mounted() {
        await this.Refresh();
    },
    props: {
        name: '',
        root: null,
        designmodel: null,
        controlData: null,
    },
    computed: {
        Root: function () {
            return this.root._self;
        },
        selected$: function () {
            return this.Root.SelectedNode && this.Root.SelectedModel == this.designmodel;
        },
    },
    methods: {
        ...methods,
        async Refresh()
        {
            if (this.designmodel.ControlURL) {
                try {
                    let c = await utils.api.get('Apps/UI/UserControl/' + this.designmodel.ControlURL);
                    if (!c)
                        c = await utils.api.get('Apps/UI/UserControl/public/' + this.designmodel.ControlURL);

                    this.control = c;
                }
                catch (e) {
                    this.control = {};
                }
            }
            else
                this.control = {};
        },
        SelectNode(node) {
            // Ignore node arg, we will only allow the designer to edit the control definition, not
            // individual members of a user control (because it is in a separate document)
            this.Root.SelectNode(this);
        },
        handleClick(e) {
            this.Root.SelectNode(this);
            e.cancelBubble = true;
            e.stopPropagation();
        },
        open(e) {
            this.Root.ExternalNavigateToName('UserControl', this.designmodel.ControlURL);
            //this.Root.ExternalNavigate('Apps/UIWhole/UserControl/' + this.designmodel.ControlURL);

            e.cancelBubble = true;
            e.stopPropagation();
        },
        browse(e) {
            this.picker = <usercontrol-browser confirm={this.confirm} cancel={this.cancel}></usercontrol-browser>;
            this.pickervisible = true;
            e.cancelBubble = true;
            e.stopPropagation();
        },
        confirm(name) {
            this.pickervisible = false;
            this.designmodel.ControlURL = name;
            this.Refresh();
        },
        cancel() {
            this.pickervisible = false;
        },
        pickerInput(value) {
            this.pickervisible = value;
        },
    },
    render(h) {
        if (!this.control.ControlData)
            return (
                <div
                    class={{ 'designer-container': true }}
                    style={{ minHeight: "40px", minWidth: "80px", border: "1px solid black" }}
                    on-click={(e) => this.handleClick(e)}>
                    <div class="design-options" title="Browse user controls" on-click={(e) => this.browse(e)} style={{ top: "4px", left: "44px", display: this.selected$ ? "block" : "none" }}>Browse</div>

                    <div class={{ "designer-user-control-hovering": true, 'designer-user-control-selected': this.selected$ }}></div>

                    <v-dialog value={this.pickervisible} max-width="860" scrollable on-input={(value) => this.pickerInput(value)}>
                        {this.picker}
                    </v-dialog>
                </div>
            );

        let DynamicControl = utils.getDynamicComponent(h, this.control);

        if (!DynamicControl)
            DynamicControl = 'default-unknown';

        DynamicControl += '-dsgn';

        const open_title = "Open " + this.designmodel.ControlURL;

        return (
            <div
                class={{ 'designer-container': true }}
                style={{ minHeight: "40px", minWidth: "80px", border: "1px solid black" }}
                on-click={(e) => this.handleClick(e)}>
                <div class="design-options" title={open_title} on-click={(e) => this.open(e)} style={{ top: "4px", left: "4px", display: this.selected$ ? "block" : "none" }}>Open</div>
                <div class="design-options" title="Browse user controls" on-click={(e) => this.browse(e)} style={{ top: "4px", left: "44px", display: this.selected$ ? "block" : "none" }}>Browse</div>

                <div class={{ "designer-user-control-hovering": true, 'designer-user-control-selected': this.selected$ }}></div>
                <DynamicControl type={this.control.ControlType} name={this.control.ControlData.Name} root={this} controlData={this.control.ControlData}>
                </DynamicControl>

                <v-dialog value={this.pickervisible} max-width="560" scrollable on-input={(value) => this.pickerInput(value)}>
                    {this.picker}
                </v-dialog>
            </div>
        );
    }
});

