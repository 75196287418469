import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import api from '@/Services/api'

import utils from '@/Shared/utils.jsx';

Vue.component('debug-hsm-log', {
    mixins: [BaseComponent],
    data: function () {
        return {
            data: null,
            umlEval: null,
            model: '',
            uml: null,
            selectedItemId: 0,
        }
    },
    //Created Replaced with preRenderComplete
    computed: {
        umls() {
            return utils.evaluateObject(this.umlEval, this);
        },
    },
    methods: {
        async preRenderComplete() {
            this.umlEval = utils.compileObject(this, this.controlData.Umls);
            
            this.selectedItemId = this.umls.length - 1;
            this.refreshDiagram();
            this.finishRenderHandler(this);
        },
        refreshDiagram() {
            this.uml = this.umls[this.selectedItemId];
            if (this.uml) {
                var type = this.uml.url ? "url" : "data";
                var data = type == "data" ? this.uml.data : {};
                var url = type == "data" ? "/PlantUml/Create" : this.uml.url;
                // generate the image on the server. It will return the new image name, which is then used to render the image
                api.apiRequest({
                    method: 'POST',
                    data: {
                        data: data
                    },
                    url: url,
                    doNotUseWebsocket: true,
                    doNotStandardizeUrl: true
                }).then(response => {
                    var topsvg = this.$el.getElementsByClassName('topsvg')[0];
                    var bottomsvg = this.$el.getElementsByClassName('bottomsvg')[0];
                    topsvg.innerHTML = response.data;
                    bottomsvg.innerHTML = response.data;
                    this.restore();
                    this.zoom(-30); // zoom out by 30% initially
                });
            }
        },
        onSelect(index) {
            this.selectedItemId = index;
            this.refreshDiagram();
        },
        restore() {
            var topsvgdiv = this.$el.getElementsByClassName('topsvg')[0];
            var topsvg = this.$el.getElementsByClassName('topsvg')[0].children[0];
            var bottomsvgdiv = this.$el.getElementsByClassName('bottomsvg')[0]; 
            var bottomsvg = this.$el.getElementsByClassName('bottomsvg')[0].children[0];

            this.restoreHeightWidth(topsvgdiv);
            this.restoreHeightWidth(topsvg);
            this.restoreTop(bottomsvgdiv);
            this.restoreHeightWidth(bottomsvg);
        },
        restoreHeightWidth(element) {
            if (element?.originalHeightWidth) {
                element.style.height = element.originalHeightWidth.height;
                element.style.width = element.originalHeightWidth.width;
                element.zoomFactor = 100;
            }
        },
        restoreTop(element) {
            if (element?.originalTop) {
                element.style.top = element.originalTop.top;
                element.zoomFactor = 100;
            }
        },
        zoom(amt) {
            var topsvgdiv = this.$el.getElementsByClassName('topsvg')[0]; // adjust the height on the stationary header
            var topsvg = this.$el.getElementsByClassName('topsvg')[0].children[0]; // adjust the svg itselv
            var bottomsvgdiv = this.$el.getElementsByClassName('bottomsvg')[0]; // adjust the top offset on the non-stationary image
            var bottomsvg = this.$el.getElementsByClassName('bottomsvg')[0].children[0];

            this.scaleHeightWidth(topsvgdiv, amt);
            this.scaleHeightWidth(topsvg, amt);
            this.scaleTop(bottomsvgdiv, amt);
            this.scaleHeightWidth(bottomsvg, amt);
        },
        scaleHeightWidth(element, amt) {
            if (!element.originalHeightWidth) {
                element.originalHeightWidth = {
                    height: element.style.height,
                    width: element.style.width
                }

                element.zoomFactor = 100;
            }

            element.zoomFactor += amt;

            var height = Number(element.originalHeightWidth.height.replace("px", ""));
            height = height * (element.zoomFactor / 100.0);
            element.style.height = height + "px";

            // the stationary header doesn't have a width, so don't adjust if no width defined
            if (element.originalHeightWidth.width) {
                var width = Number(element.originalHeightWidth.width.replace("px", ""));
                width = width * (element.zoomFactor / 100.0);
                element.style.width = width + "px";
            }
        },
        scaleTop(element, amt) {
            if (!element?.originalTop) {
                element.originalTop = {
                    top: element.style.top,
                }

                element.zoomFactor = 100;
            }

            element.zoomFactor += amt;

            var top = Number(element.originalTop.top.replace("px", ""));
            top = top * (element.zoomFactor / 100.0);
            element.style.top = top + "px";
        }
    },
    props: {},
    render(h) {
        if(!this.todisplay)
            return null;

        try {
            return (
                <div class={{ 'c-DebugHsmLog DebugFullSpaceControl HsmRenderer d-flex flex-column overflow-auto ': true, [`c-name-${this.name || 'unnamed'}`]: true, 'ma-1': true }}
                style={this.sizeStyle}>
                    {this.umls.length > 0
                    ? ( 
                        <div class="headerMenus noselect">
                            <ul class="nav nav-pills">
                                { this.umls.map((uml, index) => {
                                    return (
                                        <li class={{'active': this.selectedItemId == index }} on-click={(e) => this.onSelect(index)}>
                                            <a hreg="#"> HSM Log { uml.name || '#' + index } </a>
                                        </li>
                                    )}) 
                                }
                            </ul>
                        </div>
                    )  
                    : null}
                    <div style="gap:10px; display:flex;"> 
                        <v-btn elevation={0} on-click={(e) => this.zoom(-10)}>Zoom out</v-btn>
                        <v-btn elevation={0} on-click={(e) => this.zoom(10)}>Zoom In</v-btn>
                        <v-btn elevation={0} on-click={(e) => this.restore()}>Restore</v-btn>
                    </div>
                    <div class="mainContent d-flex flex-column overflow-auto">
                        <div>
                            <div class="topsvg heightFixed" style="height: 40px; overflow: hidden; position: sticky; align-self: flex-start;top: 0;z-index:99;background-color: white;">
                            </div>
                            <div class="bottomsvg" style="top: -40px;position: relative;">
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        catch (e) {
            utils.error('DebugHsmLog Render failed', e);
            return <div>DebugHsmLog Failed to Render {e}</div>;
        }
    }
});