import Vue from 'vue';
import utils from '../../Shared/utils.jsx';
import BaseComponent from './BaseComponentMixin.jsx';

Vue.component('for-each', {
    mixins: [BaseComponent],
    data: function () {
        return {
            model: [],
            sourceraw: null,
            sourceurl: null,
        }
    },
    created() {
        if (this.controlData.SourceType === 'Raw' && this.controlData.SourceRaw) {
            this.sourceraw = utils.compileObject(this, this.controlData.SourceRaw);
            this.$watch_source = this.$watch('sourcerawvalue', function (newv, oldv) { this.Refresh(); });
        }
        else if (this.controlData.SourceType === 'Url' && this.controlData.SourceUrl) {
            this.sourceurl = utils.compile(this, this.controlData.SourceUrl);
            this.$watch_source = this.$watch('sourceurlvalue', function (newv, oldv) { this.Refresh(); });
        }
    },
    destroyed() {
        if (this.$watch_todisplay)
            this.$watch_todisplay();

        if (this.$watch_source)
            this.$watch_source();
    },
    computed: {
        sourcerawvalue: function () {
            return this.sourceraw ? utils.evaluateObject(this.sourceraw, this) : null;
        },
        sourceurlvalue: function () {
            return this.sourceurl ? utils.evaluate(this.sourceurl, this) : '';
        },
    },
    //Replaced with preRenderComplete
    mounted() {
    },
    methods: {
        async preRenderComplete() {
            if (this.todisplay)
                await this.Refresh();

            this.$watch_todisplay = this.$watch('todisplay', function (newval, oldval) { if (newval) this.Refresh(); });

            this.finishRenderHandler(this);
        },
        async Refresh() {
            if (this.controlData.SourceType === 'Raw' && this.controlData.SourceRaw) {
                this.model = this.sourcerawvalue;
            }
            else if (this.controlData.SourceType === 'Url' && this.controlData.SourceUrl) {
                this.model = await utils.api.get(this.sourceurlvalue);
            }
        },
        generateItems(h, contentDefs, items, model, index, parentType) {
            for (let j = 0; j < contentDefs.length; j++) {
                const cd = contentDefs[j];

                if (cd.DisplayExpression && !cd.$$DisplayExpression)
                    cd.$$DisplayExpression = utils.compileExpression(this, cd.DisplayExpression);

                if (!cd.$$DisplayExpression || utils.evaluate(cd.$$DisplayExpression, this))
                    for (let i = 0; i < cd.Controls.length; i++) {
                        const c = cd.Controls[i];
                        if (!(typeof c === 'object'))
                            continue;

                        let DynamicControl = utils.getDynamicComponent(h, c);

                        if (!DynamicControl)
                            DynamicControl = 'default-unknown';

                        if (!c.$objectId) c.$objectId = utils.generateUUID();

                        let id = `${this.master_key}_${c.$objectId}`;
                        if (index) id = `${index}_${id}`;

                        items.push(
                            <DynamicControl
                                key={id}
                                on={{ 'finished-render': () => this.finishRenderHandler() }}
                                type={c.ControlType}
                                name={c.ControlData ? c.ControlData.Name : ''}
                                root={this.root}
                                parentType={parentType}
                                controlData={c.ControlData}
                                controlURL={c.ControlURL}
                                sourceData={model}
                                controlName={c.Name}
                                scopeitems={this.scopeitems}
                                controlscope={this.controlscope}
                                cacheControl={c.CacheControl}
                                controlEvents={c.Events}
                            >
                            </DynamicControl>
                        );
                    }
            }
        },
    },
    props: {
    },
    render(h) {
        try {
            if (!this.todisplay)
                return null;

            let items = [];
            let items_id = '_0';

            if (this.model && typeof this.model === 'object' && this.controlData.ContentDefs) {
                if (Array.isArray(this.model))
                    for (let i = 0; i < this.model.length; i++) {
                        const item = this.model[i];
                        const item_index = `${i}_${items_id}`;
                        const sub_items = [];
                        this.generateItems(h, this.controlData.ContentDefs, sub_items, item, item_index, this.parentType);
                        items.push(<div style="display: flex; flex-direction: column;">{sub_items}</div>);
                    }
                else
                    for(let key in this.model) {
                        const item = this.model[key];
                        const item_index = `${key}_${items_id}`;
                        const sub_items = [];
                        this.generateItems(h, this.controlData.ContentDefs, sub_items, item, item_index, this.parentType);
                        items.push(<div style="display: flex; flex-direction: column;">{sub_items}</div>);
                    };
            }
    
            const style = {
                overflow: this.parentType == "ContentDef" ? "hidden" : "auto",
                display: "flex",
                flexDirection: this.controlData.UseFlexRow ? "row" : "column",
                ...this.sizeStyle,
                ...utils.getStyleHints(this.controlData.StyleHints),
            };

            //if (this.sizeOptions && this.sizeOptions.Width.Mode === 'Auto' && this.parentType === 'VerticalStack') {
            //    // In this unique case, we must use the opposite direction on the parent and embed another div
            //    // to force the 'auto' width -- otherwise, 
            //    style.flexDirection = 'column';

            //    items = (
            //        <div style={{ display: "flex", flexDirection: "row", flexGrow: "0", flexShrink: "0" }}>
            //            {items}
            //        </div>
            //    );
            //}

            return (
                <div
                    class={{ 'c-ForEach': true, [`c-name-${this.name || 'unnamed'}`]: true }}
                    v-show={this.isvisible} style={style}>
                    {items}
                </div>
            );
        }
        catch(e) {
            utils.error('ForEach Render failed', e);
            return <div>ForEach Failed to Render {e}</div>;
        }
    }
});