import Vue from 'vue';
import BaseComponent from './baseFormMixin.jsx';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

Vue.component('sform-controls-with-label', {
    mixins: [BaseComponent],
    data: () => ({
    }),
    props: {
    },
    created() {
    },
    mounted() {
        this.loadComplete();
    },
    computed: {
    },
    methods: {
    },
    render(h) {
        // This is handled in the layoutCard now - any form element can have a Controls array.

        //const items = utils.generateItemsFromArray(h, this, this.element.Controls || [], null, 'ControlsWithLabel');

        //let input = (
        //    <div
        //        class={{ 'c-ControlsWithLabel': true, [`c-name-${this.name || 'unnamed'}`]: true }}
        //        style="overflow: auto; display: flex; flex-direction: column;">
        //        {items}
        //    </div>
        //);

        return undefined; // input;
    }
});