import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import utils from '../../Shared/utils.jsx';

Vue.component('dynamic-tree', {
    mixins: [BaseComponent],
    data: function () {
        return {
            unique_id: null,
            selected_values: [],
            selected_key: null,

            model: [],
            allkeys: {},

            icon_fn: null,
            iconTooltip_fn: null,
            iconColor_fn: null,
        }
    },
    created() {
        this.unique_id = `tree_${utils.generateUUID()}`;

        if (this.controlData.SourceType === 'Raw' && this.controlData.SourceRaw) {
            this.watch_source$ = this.$watch('sourcerawvalue', function () { this.Refresh(); });
        }
        else if (this.controlData.SourceType === 'Url' && this.controlData.SourceUrl) {
            this.watch_source$ = this.$watch('sourceurlvalue', function () { this.Refresh(); });
        }

        if (this.controlData.IconExpression)
            this.icon_fn = utils.compileExpression(this, this.controlData.IconExpression, 'node');
        if (this.controlData.IconTooltipExpression)
            this.iconTooltip_fn = utils.compileExpression(this, this.controlData.IconTooltipExpression, 'node');
        if (this.controlData.IconColorExpression)
            this.iconColor_fn = utils.compileExpression(this, this.controlData.IconColorExpression, 'node');
    },
    destroyed() {
        if (this.watch_source$)
            this.watch_source$();
    },
    //Created Replaced with preRenderComplete
    computed: {
        sourcerawvalue: function () {
            if (!this.sourceraw)
                this.sourceraw = utils.compileObject(this, this.controlData.SourceRaw);

            return utils.evaluateObject(this.sourceraw, this);
        },
        sourceurlvalue: function () {
            if (!this.sourceurl)
                this.sourceurl = utils.compile(this, this.controlData.SourceUrl);

            return utils.evaluate(this.sourceurl, this);
        },
        autoExpandAll: function () {
            if (typeof this.controlData.AutoExpandAll === 'string' && !this.autoExpandAll_fn)
                this.autoExpandAll_fn = utils.compileObject(this, this.controlData.AutoExpandAll);

            if (this.autoExpandAll_fn)
                return utils.evaluateObject(this.autoExpandAll_fn, this);

            return this.controlData.AutoExpandAll;
        },
        lazyLoadChildren: function () {
            if (typeof this.controlData.LazyLoadChildren === 'string' && !this.lazyLoadChildren_fn)
                this.lazyLoadChildren_fn = utils.compileObject(this, this.controlData.LazyLoadChildren);

            if (this.lazyLoadChildren_fn)
                return utils.evaluateObject(this.lazyLoadChildren_fn, this);

            return this.controlData.LazyLoadChildren;
        },
    },
    methods: {
        async preRenderComplete() {
            this.finishRenderHandler(this);

            this.Refresh();
        },
        saveAllKeys(items) {
            const itemkey = this.controlData.ItemKey || 'id';
            const itemchildren = this.controlData.ItemChildren || 'children';

            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                this.allkeys[item[itemkey]] = item;

                if (item[itemchildren] && Array.isArray(item[itemchildren]))
                    this.saveAllKeys(item[itemchildren]);
            }
        },

        async Refresh() {
            for (var prop in this.allkeys) {
                if (this.allkeys.hasOwnProperty(prop))
                    Vue.delete(this.allkeys, prop);
            }

            let model;
            if (this.sourceraw)
                try {
                    model = this.sourcerawvalue;
                }
                catch (e) {
                    utils.warn('DynamicTree failed to evaluate SourceRaw ' + this.controlData.SourceRaw + ': ' + e);
                }
            else if (this.sourceurl)
                try {
                    model = await utils.api.get(this.sourceurlvalue);
                }
                catch (e) {
                    utils.warn('DynamicTree failed to evaluate SourceUrl ' + this.controlData.SourceUrl + ': ' + e);
                }

            this.saveAllKeys(model);

            this.model = model;

            const c = this;
            if (this.autoExpandAll && this.unique_id in this.$refs)
                Vue.nextTick(function () {
                    c.$refs[c.unique_id].updateAll(true);
                });
        },

        SetChildItems(model) {
            // Add keys for newly added items
            for (let i = 0; i < model.length; i++)
                this.allkeys[model[i][this.controlData.ItemKey || 'id']] = model[i];

            this.added_children = model;
        },
        ExpandAll() {
            const c = this;
            if (this.unique_id in this.$refs)
                Vue.nextTick(function () {
                    c.$refs[c.unique_id].updateAll(true);
                });
        },
        CollapseAll() {
            const c = this;
            if (this.unique_id in this.$refs)
                Vue.nextTick(function () {
                    c.$refs[c.unique_id].updateAll(false);
                });
        },

        async loadChildren(item) {
            await utils.executeAndCompileAllActions(this.controlData.OnLazyLoadActions, { Data: item }, this);
            if (this.added_children) {
                item.Items.push(...this.added_children);
                this.added_children = null;
            }
            return;

            let url = utils.evaluate(this.sourceurl, this);

            const model = await utils.api.get(`${url}?Namespace=${item.Key}`);

            if (!model) {
                utils.log('No data');
                return;
            }

            for (let i = 0; i < model.length; i++)
                this.allkeys[model[i][this.controlData.ItemKey || 'id']] = model[i];

            //utils.log(JSON.stringify(model));

            item.Items.push(...model.map(a => ({ ...a, Name: a.Type == 'Namespace' ? a.Key.substr(item.Key.length + 1) : a.Name })));
        },
        async onInput(selectedvalues) {
            // selectedvalues is an array of Ids that match the id property within the selected menu item
            if (selectedvalues.length > 0) {
                this.selected_values = selectedvalues;
                this.selected_key = selectedvalues[0];
                const value = this.allkeys[this.selected_key];

                //utils.log(`DynamicTree onInput:${JSON.stringify(selectedvalues)}; value:${JSON.stringify(value)}`);

                utils.executeAndCompileAllActions(this.controlData.SelectMenuActions, { Data: this.selected_key, SelectedItem: value }, this);
            }
        },
    },
    render(h) {
        if(!this.todisplay)
            return null;
        
        try {

            const style = {
                minWidth: "25px",
                ...this.sizeStyle,
                ...utils.resolveStyleHints(this.styleHints, this),
                overflow: "auto",
            };

            const scopedSlots = {};

            if (this.controlData.IconExpression) {
                scopedSlots.append = (node) => {
                    let icon = utils.evaluate(this.icon_fn, this, false, null, false, node);

                    if (icon) {
                        let color;
                        let tooltip;
                        if (this.iconColor_fn) color = utils.evaluate(this.iconColor_fn, this, false, null, false, node);
                        if (this.iconTooltip_fn) tooltip = utils.evaluate(this.iconTooltip_fn, this, false, null, false, node);

                        switch (this.controlData.IconSize) {
                            case 'Small':
                                icon = <v-icon small style={{ color: color }}>{icon}</v-icon>;
                                break;

                            case 'Extra Small':
                                icon = <v-icon x-small style={{ color: color }}>{icon}</v-icon>;
                                break;

                            default:
                                icon = <v-icon style={{ color: color }}>{icon}</v-icon>;
                                break;
                        }

                        if (tooltip)
                            icon = utils.generateTooltip(h, icon, tooltip, 'right');

                        return icon;
                    }
                    else
                        return undefined;
                };
            }

            if (this.controlData.PrependItemControls?.length) {
                scopedSlots.prepend = (node) => {
                    return <div class="d-flex mr-2">
                        {utils.generateItemsFromArray(h, this, this.controlData.PrependItemControls, node, 'HorizontalLayout', {'finished-render': (item) => this.finishRenderHandler(item) })}
                    </div>;

                } 
            }

            const props = {
                activatable: true,
                hoverable: true,
                transition: true,
                openOnClick: true,
                dense: true,
                class: 'ma-1',
                items: this.model || [],
                active: this.selected_values,
            };

            if (this.controlData.ItemText) props.itemText = this.controlData.ItemText;
            if (this.controlData.ItemKey) props.itemKey = this.controlData.ItemKey;
            if (this.controlData.ItemDisabled) props.itemDisabled = this.controlData.ItemDisabled;
            if (this.controlData.ItemChildren) props.itemChildren = this.controlData.ItemChildren;
            if (this.lazyLoadChildren) props.loadChildren = this.loadChildren;
            if (this.autoExpandAll) props.openAll = true;

            // JSX cannot handle an event with a colon in the middle (update:active) so I have to declare this manually:
            const tv = h('v-treeview', {
                ref: this.unique_id,
                props: props,
                scopedSlots: scopedSlots,
                on: {
                    'update:active': (e) => this.onInput(e),
                }
            });

            return (
                <div
                    class={{ 'c-DynamicTree': true, [`c-name-${this.name || 'unnamed'}`]: true }}
                    style={style}>
                    {tv}
                </div>
            );
        }
        catch (e) {
            utils.error('DynamicTree Render failed', e);
            return <div>DynamicTree Failed to Render {e}</div>;
        }
    }
});