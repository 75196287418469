import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import EventBus from '../event-bus.js';
import Editor from '@tinymce/tinymce-vue';
import './css/HtmlEditor.css';
import careHelpfulFunctions from '../careHelpfulFunctions.jsx';

import utils from '../../Shared/utils.jsx';
import methods from '../../Shared/methods';

Vue.component('html-editor', {
    mixins: [BaseComponent],
    data: function () {
        return {
            readOnlyEval: null,
            setValueFunction: null,
            wordWrapEval: null,
            sourceUrlEval: null,
            data: null,
            sourceRawInterpolatedEval: null,
            model: '',
        }
    },
    components: {
        'editor': Editor
    },
    //Created Replaced with preRenderComplete
    computed: {
        readOnly() {
            try {
                if(this.readOnlyEval === null && this.controlData.Readonly)
                    this.readOnlyEval = utils.compile(this, this.controlData.Readonly);
                if(this.readOnlyEval)
                    return utils.evaluate(this.readOnlyEval, this);
                return false;
            }
            catch (e) {
                utils.warn('ReadOnly could not evaluate expression: ' + this.controlData.Readonly + '; ' + e);
                return false;
            }
        },
        wordWrap() {
            try {
                if(this.wordWrapEval === null && this.controlData.WordWrap)
                    this.wordWrapEval = utils.compile(this, this.controlData.WordWrap);
                if(this.wordWrapEval)
                    return utils.evaluate(this.wordWrapEval, this);
                return false;
            }
            catch (e) {
                utils.warn('WordWrap could not evaluate expression: ' + this.controlData.WordWrap + '; ' + e);
                return false;
            }
        },
        publishField: {
            set(value){
                if(this.setValueFunction === null && this.controlData.PublishField) {
                    let vueSet = utils.helpers.convertSetValueToVueSet(this.controlData.PublishField, 'value');
                    this.setValueFunction = new Function('value', 'context', 'util', 'vue', `with (context) { ${vueSet} }`);
                }
                if(this.setValueFunction) {
                    this.setValueFunction(value, this, careHelpfulFunctions, Vue);
                }
                this.model = value;
            },
            get() {
                return this.model;
            }
        },
        sourceUrl() {
            return utils.evaluate(this.sourceUrlEval, this);
        },
        sourceRawInterpolated(){
            return utils.evaluateObject(this.sourceRawInterpolatedEval, this);
        },
        source() {
            switch(this.controlData.DataType.toLowerCase()) {
                case 'url':
                case 'raw': 
                    return this.data;
                case 'rawinterpolated': return this.sourceRawInterpolated;
                default: return [];
            }
        },
        htmlOptions() {
            return {
                menubar: false,
                elementpath: false,
                language: 'en', // TODO: TRANSLATION
                browser_spellcheck: true,
                readonly: this.readOnly ? 1 : 0,
                branding: false,
                statusbar: !this.readOnly,
                //toolbar: 'forecolor backcolor bold italic underline strikethrough',
                //toolbar: 'undo redo | fontselect fontsizeselect forecolor backcolor bold italic underline strikethrough |  outdent indent bullist numlist | alignleft aligncenter alignright | emoticons image | code ',
                plugins: ['lists', 'emoticons', 'code image imagetools paste'],
                //toolbar: 'forecolor backcolor',
                //plugins: ["textcolor", "colorpicker", "lists link emoticons image imagetools", "searchreplace visualblocks code fullscreen"],
                toolbar: this.readOnly ? false : "undo redo | fontselect fontsizeselect forecolor backcolor bold italic | styleselect | alignleft aligncenter alignright alignjustify |  outdent indent bullist numlist | link image imagetools | code",
                image_advtab: true,
                paste_data_images: true,
                paste_enable_default_filters: false,
                automatic_uploads: true,
                urlconverter_callback: (url, node, on_save, name) =>{
                    // this looks for an image url that is not local. If found, we request the url, convert to base64,  replace the image src with the new server url
                    if (node === "img" && name === "src" && url.toLowerCase().startsWith("http")){
                        let img = new Image();
                        //img.setAttribute('crossOrigin', 'anonymous');

                        img.onload = () => {
                            let canvas = document.createElement("canvas");
                            canvas.width = this.width;
                            canvas.height = this.height;

                            let ctx = canvas.getContext("2d");
                            ctx.drawImage(this, 0, 0);

                            let contentType = "image/png";
                            let dataURL = canvas.toDataURL(contentType);
                            // we now have a in inline image url. Example: data:image/png;base64....replace the url with the inline image url
                            // didn't do a regex because some URLs have special characters we didn't want to escape with regex.  so we did the loop instead
                            while (this.model.indexOf(url) != -1){
                                this.model.replace(url, dataURL);
                            }
                        };

                        img.src = "/ProxyRequest?proxyurl="+ encodeURIComponent(url);
                    }

                    return url;
                },
                images_upload_handler: (blobInfo, success, failure) => {
                    var imageUrl = "data:" + blobInfo.blob().type + ";base64," + blobInfo.base64();
                    success(imageUrl);
                },
                forced_root_block: 'div'
            };
        },
        styles() {
            if(this.wordWrap)
                return {
                    wordBreak: 'break-word',
                    whiteSpace: 'pre-wrap'
                };
            return {};
        }   
    },
    methods: {
        async onChange(event) {
            event.stopPropagation();
            event.preventDefault();

            this.publishField = event.level.content;
        },
        async preRenderComplete() {
            switch(this.controlData.DataType.toLowerCase()) {
                case 'url': {
                    this.sourceUrlEval = utils.compile(this, this.controlData.DataURL);
                    this.data = await utils.api.get(this.sourceUrl);
                    break;
                }
                case 'raw': {
                    this.data = this.controlData.Data;
                    break;
                }
                case 'rawinterpolated': {
                    this.sourceRawInterpolatedEval = utils.compileObject(this, this.controlData.Data);
                    break;
                }
            }
    
            if(this.source)
                this.publishField = this.source;
    
            await utils.executeAndCompileAllActions(this.controlData.OnInitActions, null, this);
    
            this.finishRenderHandler(this);
        },
    },
    props: {},
    render(h) {
        if(!this.todisplay)
            return null;

        try {
            return (
                <div class={{ 'c-HtmlEditor': true, [`c-name-${this.name || 'unnamed'}`]: true, 'ma-1': true }}
                style={this.sizeStyle}>
                    <editor
                        style={this.styles}
                        value={this.model}
                        on-onChange={this.onChange}
                        init={this.htmlOptions}
                    />
                </div>
            );
        }
        catch (e) {
            utils.error('HtmlEditor Render failed', e);
            return <div>HtmlEditor Failed to Render {e}</div>;
        }
    }
});