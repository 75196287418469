import Vue from 'vue';
import utils from '../../Shared/utils.jsx';
import BaseComponent from './BaseComponentMixin.jsx';

Vue.component('icon-stack', {
    mixins: [BaseComponent],
    data: function () {
        return {
            icon: null,
            color: null,
            caption: null,
            tooltip: null,
        }
    },
    async created() {
        this.icon = utils.compile(this, this.controlData.Icon);
        this.color = utils.compile(this, this.controlData.Color);
        this.caption = utils.compileObject(this, this.controlData.Caption);
        this.tooltip = utils.compile(this, this.Translate(this.controlData.Tooltip));
    },
    //Mounted Replaced with preRenderComplete
    computed: {
    },
    methods: {
        preRenderComplete() {
            this.finishRenderHandler(this);
        },
        getIconStyles: function (icon) {
            const styles = {};

            if (this.controlData.FontSize)
                styles['font-size'] = utils.resolveFontSize(this.controlData.FontSize);

            if (icon.Color)
                styles.color = icon.Color;
            else
                styles.color = '#444';

            return styles;
        },
        getIconClass: function (icon) {
            let iconClass = icon.Icon;
            iconClass += (icon.FlipHorizontally) ? ' mdi mdi-flip-vertical ' : '';
            iconClass += (icon.FlipVertically) ? ' mdi mdi-flip-horizontal ' : '';
            iconClass += (icon.StackSize == 'Large') ? ' mdi mdi-layers-triple-2x ' : ' mdi mdi-layers ';
            iconClass += (icon.Border) ? ' mdi mdi-border-all-variant ' : '';
            iconClass += (icon.Animation == "Spin") ? ' mdi-animate-spin ' : '';
            iconClass += (icon.Animation == "Pulse") ? ' mdi-animate-pulse ' : '';
            return iconClass;
        },
        async onClick(e) {
            e.cancelBubble = true;
            e.stopPropagation();

            await utils.executeAndCompileAllActions(this.controlData.Actions, null, this);
        },
    },
    props: {
    },
    render(h) {
        if (!this.todisplay)
            return null;

        const style = {
            ...this.sizeStyle,
            ...utils.resolveStyleHints(this.styleHints, this),
        };

        if (this.controlData.Actions && this.controlData.Actions.length > 0)
            style.cursor = "pointer";

        const icons = [];

        for (let i = 0; i < this.controlData.Icons.length; i++) {
            const icon = this.controlData.Icons[i];
            icons.push(
                <i class={this.getIconClass(icon)} style={this.getIconStyles(icon)}></i>
            );
        }

        return (
            <div
                class={{ 'c-IconStack': true, [`c-name-${this.name || 'unnamed'}`]: true }}
                style={style}
                disabled={this.controlData.IsDisabled}
                title={!this.controlData.Caption?.Text ? this.controlData.Tooltip : ''}
                on-click={(e) => this.onClick(e)}>
                <span class="mdi mdi-layers-triple">
                    {icons}
                </span>
                <div class="CareIconCaption">{this.controlData.Caption?.Text }</div>
            </div>
        );
    }
});