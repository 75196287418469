import Vue from 'vue';
import utils from '../../Shared/utils.jsx';
import BaseComponent from './BaseComponentMixin.jsx';

Vue.component('basic-watcher', {
    mixins: [BaseComponent],
    data: function () {
        return {
            oldstr: '',
            watchfunc: null,
        }
    },
    async created() {
        this.watchfunc = utils.compileExpression(this, this.controlData.WatchedField);
        //const f = new Function('context', 'with (context) return ' + this.controlData.WatchedField + ';');

        // Save serialized version so we can compare when the watch fires
        if (typeof tmp === 'object')
            this.oldstr = JSON.stringify(tmp);

        this.watcher$ = this.$watch(
            function () {
                try {
                    //utils.log('Watcher watching ' + this.controlData.WatchedField);
                    return this.watchedvalue;
                }
                catch (e) {
                    utils.log('Watcher failed to evaluate expression ' + this.controlData.WatchedField + '; ' + e);
                    return null;
                }
            },
            function (val) {
                utils.log('Watcher change detected on ' + this.controlData.WatchedField + ', executing actions');

                const valstr = typeof val === 'object' ? JSON.stringify(val) : val;

                //utils.debug('From: ' + this.oldstr);
                //utils.debug('To: ' + valstr);

                if (valstr != this.oldstr) {
                    this.oldstr = valstr;
                    this.doActions(val);
                }
            },
            {
                deep: this.controlData.DeepWatch
            }
        );
    },
    //Mounted Replaced with preRenderComplete
    destroyed() {
        this.watcher$();
    },  
    computed: {
        watchedvalue: function () {
            return utils.evaluate(this.watchfunc, this);
        },
    },
    methods: {
        preRenderComplete() {
            if (this.controlData.RunActionsInitially)
                this.doActions(this.watchedvalue);

            this.finishRenderHandler(this);
        },
        async doActions(newvalue) {
            await utils.executeAndCompileAllActions(this.controlData.Actions, { NewValue: newvalue }, this);
        },
    },
    props: {
    },
    render(h) {
        return null;
    }
});