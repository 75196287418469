import Vue from 'vue';
import BaseComponent from './baseFormMixin.jsx';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

import { appSettings } from '@/Shared/appSettings.js';

const datetimeregex1 = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d/;
const datetimeregex2 = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d/;
const datetimeregex3 = /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/;

Vue.component('sform-datepicker', {
    mixins: [BaseComponent],
    data: () => ({
    }),
    props: {
    },
    created() {
    },
    mounted() {
        this.loadComplete();
    },
    computed: {
        itemvalue_aslocaldate: function () {
            // Cannot use ISO format directly "2200-07-22T06:00:00Z"
            // The format is "yyyy-MM-dd"
            if (!this.itemvalue)
                return '';

            const dt = new Date(Date.parse(this.itemvalue));
            const year = dt.getFullYear();
            const month = (dt.getMonth() + 1).toString();
            const day = (dt.getDate()).toString();

            const str = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;

            utils.debug(`DatePicker reading date from model value:'${this.itemvalue}' as '${str}'`);

            return str;
        },
        itemvalue_aslocaltime: function () {
            // Cannot use ISO format directly "2200-07-22T06:00:00Z"
            // The format is "hh:mm" followed by optional ":ss" or ":ss.SSS".
            if (!this.itemvalue)
                return '';

            let str;
            if (this.itemvalue.match(datetimeregex1) || this.itemvalue.match(datetimeregex2) || this.itemvalue.match(datetimeregex3)) {
                const dt = new Date(Date.parse(this.itemvalue));
                const hour = (dt.getHours()).toString();
                const minute = (dt.getMinutes()).toString();
                const second = (dt.getSeconds()).toString();

                str = `${hour.padStart(2, '0')}:${minute.padStart(2, '0')}:${second.padStart(2, '0')}`;
            }
            else {
                // Assume HH:MM[:SS] (seconds are optional)
                const dt = this.itemvalue.split(':');
                const hour = dt.length > 0 ? dt[0] : '00';
                const minute = dt.length > 1 ? dt[1] : '00';
                const second = dt.length > 2 ? dt[2] : '00';

                str = `${hour.padStart(2, '0')}:${minute.padStart(2, '0')}:${second.padStart(2, '0')}`;
            }
            utils.debug(`DatePicker reading time from model value:'${this.itemvalue}' as '${str}'`);

            return str;
        },
    },
    methods: {
        sync_fromlocaldate(value) {
            const dt = new Date(value);
            const year = dt.getFullYear();
            const month = (dt.getMonth() + 1).toString();
            const day = (dt.getDate()).toString();
            const str = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;

            utils.debug(`DatePicker writing date to model value:'${value}' as '${str}'`);

            this.sync(str);
        },
        sync_fromlocaltime(value) {
            // Assume HH:MM[:SS] (seconds are optional)
            const dt = value.split(':');
            const hour = dt.length > 0 ? dt[0] : '00';
            const minute = dt.length > 1 ? dt[1] : '00';
            const second = dt.length > 2 ? dt[2] : '00';
            const str = `1900-01-01T${hour.padStart(2, '0')}:${minute.padStart(2, '0')}:${second.padStart(2, '0')}`;

            utils.debug(`DatePicker writing time to model value:'${value}' as '${str}'`);

            this.sync(str);
        },
    },
    render() {
        let input;

        let scopedSlots = {
            message: ({message}) => {
                return <translation-container context={this} value={message}></translation-container>
            }
        }

        let slots = [
            <translation-container slot="label" context={this} value={this.labelText}></translation-container>,
        ];

        if (this.element.format == 'date')
            input = (
                <v-text-field
                    class="caption pa-0 ma-0"
                    style={{ width: "100%" }}
                    outlined single-line dense hide-details
                    value={this.itemvalue_aslocaldate}
                    rules={this.rules}
                    type="date"
                    on-input={(value) => this.sync_fromlocaldate(value)}
                    scopedSlots={scopedSlots}
                    hint={this.hintText}
                    on-blur={this.onBlur}
                    on-focus={this.onFocus}
                    persistent-hint={appSettings.DebugTranslationPrefixSetting}
                >{slots}</v-text-field>
            );
        else if (this.element.format == 'time')
            input = (
                <v-text-field
                    class="caption pa-0 ma-0"
                    style={{ width: "100%" }}
                    outlined single-line dense hide-details
                    value={this.itemvalue_aslocaltime}
                    rules={this.rules}
                    type="time"
                    on-input={(value) => this.sync_fromlocaltime(value)}
                    scopedSlots={scopedSlots}
                    hint={this.hintText}
                    on-blur={this.onBlur}
                    on-focus={this.onFocus}
                    persistent-hint={appSettings.DebugTranslationPrefixSetting}
                >{slots}</v-text-field>
            );

        if (input && this.appearance)
            input.componentOptions.propsData = { ...input.componentOptions.propsData, ...this.appearance };

        if (this.directives) {
            if (input.data.directives)
                input.data.directives = [...input.data.directives, ...this.directives];
            else
                input.data.directives = this.directives;
        }

        return input;
    }
});