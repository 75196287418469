import Vue from 'vue';
import _ from 'lodash';
import apiService from '@/Services/api';
import token from '@/Services/token';
import utils from '@/Shared/utils';
import { appSettings, carei18nLanguages } from '@/Shared/appSettings';
import localeService from '@/Services/locale';

export default {
    isLoaded: false,
    internalLog: null,
    translationList: {},
    globalTranslationList: {},
    uploadInterval: null,

    //This method is only called from the App.Vue when the site gets created so we can subscribe
    async created() {
        appSettings.careService = this;
        this.internalLog = appSettings?.DebugInfo?.ConsoleLog;
        let storedLanguage = null;
        let localeKey = appSettings.NoContextLocalStorage('careLocaleKey');
        // This is a backwards compatibility fix. The locale key was once set in local storage without using our wrapper. We want to make sure we read 
        // that out. The setSelectedLanguage method call below will make sure we save it using our wrapper.
        if (!localeKey)
            localeKey = localStorage.getItem('careLocaleKey');

        let languageKey = appSettings.NoContextLocalStorage('careLanguageKey');
        // This is a backwards compatibility fix. The language key was once set in local storage without using our wrapper. We want to make sure we read 
        // that out. The setSelectedLanguage method call below will make sure we save it using our wrapper.
        if (!languageKey)
            languageKey = localStorage.getItem('careLanguageKey')

        if (localeKey !== null) {
            storedLanguage = localeKey;
        } else if (languageKey !== null) {
            storedLanguage = languageKey;
        } else {
            storedLanguage = this.getBrowserLocale();
        }

        this.setSelectedLanguage(storedLanguage);

        await this.getTranslatedList();

        // TODO: Once we add the doc info, we can start uploading new language keys on an interval
        this.uploadInterval = setInterval(() => {
            this.uploadNewLanguageKeys();
        }, 300000);

        // run the job to update translations 
        this.isLoaded = true;
    },
    getBrowserLocale() {
        let navigatorLanguage = navigator.language;

        // remove any sub language part and only use the main language setting
        if (navigatorLanguage.indexOf('-') > -1) {
            navigatorLanguage = navigatorLanguage.substr(0, navigatorLanguage.indexOf('-'));
        }

        return navigatorLanguage;
    },
    async uploadNewLanguageKeys() {

        if (!_.isEmpty(this.translationList)) {
            // call API in promise then refresh /Translation/TranslationList
            let currentTranslationList = this.translationList;
            let totalKeys = 0;
            for (let property in currentTranslationList)
                totalKeys += Object.keys(currentTranslationList[property]).length;

            try {
                let res = await apiService.apiRequest({
                    method: 'POST',
                    data: currentTranslationList,
                    url: '/Language/UpdateTranslationList',
                    doNotUseWebsocket: true
                });

                if (res) {
                    this.translationList = {};
                    this.logInfo("Translation Keys Added: " + totalKeys);
                }

            }
            catch (error) {
                this.logError(`Error adding Translation Keys reason: ${error.type}`);
            }


        }
    },
    updateTranslationList(key, docinfo) {
        let customerid = "50";
        if (docinfo?.CustomerID)
            customerid = docinfo.CustomerID;

        if (!this.translationList[customerid] || !this.translationList[customerid][key]) {
            // add the missing key to the translation list to be uploaded to server
            if (!this.translationList[customerid])
                this.translationList[customerid] = {};

            let transDoc = this.translateDocInfo(docinfo);
            this.translationList[customerid][key] = transDoc;

            // also add to the current in memory translation list
            if (!this.globalTranslationList[customerid])
                this.globalTranslationList[customerid] = {};

            this.globalTranslationList[customerid][key] = ""; // if the key and value are the same we optimize by making the value ""
        }
    },
    translateDocInfo(docinfo) {
        //This is temporary until we have DocInfo Populated
        return {
            ObjectId: null,
            FullPath: null,
            DocType: null,
            DocURL: null,
            CustomerID: "50",
            Id: null,
            PublicBool: false,
            Description: "Website Experience translation"
        };
        /* C# needs object names it can translate instead of $objectId and public */
        var rtnVal = null;

        if (docinfo) {
            rtnVal = {
                ObjectId: docinfo.$objectId,
                FullPath: docinfo.fullPath,
                DocType: docinfo.docType,
                DocURL: docinfo.docURL,
                CustomerID: docinfo.CustomerID,
                Id: docinfo.id,
                PublicBool: docinfo.public,
                Description: docinfo.description
            };
        }
        return rtnVal;
    },
    getDescription(item) {
        var docDesc = item.Name || item.DocURL || item.ControlURL || "";

        if (item.MenuName)
            docDesc = item.MenuName;

        if (item.MenuPath)
            docDesc += "/" + item.MenuPath;

        if (item.MenuItemData?.Title)
            docDesc += "/" + item.MenuItemData.Title;

        return docDesc;
    },
    async getTranslatedList() {
        try {
            var res = await apiService.apiRequest({
                method: 'GET',
                url: "/Language/TranslationList/" + appSettings.LanguageKey,
                doNotUseWebsocket: true
            });

            this.globalTranslationList = res.data;
            return res;
        }
        catch (error) {
            this.logError("ERROR Retreiving Translation List Keys. " + error);
            return error;
        }
    },
    //Accepts a language key such as "en" for English, "de" for German
    setSelectedLanguage(language) {
        this.uploadNewLanguageKeys(); // send off what we have to database

        language = language.toLowerCase();

        let locale = '';

        if (language.includes('-')) {
            let parts = language.split('-');
            locale = language;
            language = parts[0];
        } else {
            locale = language;
        }

        let lang = carei18nLanguages.find(langItem => langItem.key == language || langItem.name.toLowerCase() == language);

        if (lang) {
            appSettings.LanguageKey = lang.key;
            appSettings.NoContextLocalStorage('careLanguageKey', appSettings.LanguageKey);

            appSettings.LocaleKey = locale;
            appSettings.NoContextLocalStorage('careLocaleKey', locale);

            localeService.setLocale(locale);

            //if (token.UserID()) {
            //    apiService.apiRequest({
            //        method: "POST",
            //        url: "/Apps/Locales/SaveUserLocale?Locale=" + language,
            //        doNotUseWebsocket: true
            //    }).catch( 
            //        error => {
            //            logError("ERROR Saving User Locale. " + error);
            //        }
            //    );
            //}

            if (this.isLoaded) {
                // system refresh if we change language after were loaded. This is done so the language list can reload and the controls can all update with new translations
                window.location.reload();
            }

            return;
        }
        else {
            utils.error("Invalid LanguageKey: " + language);

            // fall back to english
            this.setSelectedLanguage("en-us");
        }
    },
    guid() {
        return utils.generateUUID();
    },
    convertCamelToSnake(camelString) {
        var result = camelString.replace(/(?:^|\.?)([A-Z])/g, function (x, y) { return "-" + y.toLowerCase() }).replace(/^\-/, "");
        return result;
    },
    alert(alertString) {
        this.logInfo(alertString);
        $window.alert(alertString);
    },
    formatJson(json) {
        return typeof json === 'string' ? json : JSON.stringify(json, undefined, 2);
    },
    logDateFormat() {
        var date = new Date();
        return `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}.${date.getMilliseconds()}`;
    },
    logInternally(type, date, message, functionName, lineNumber, fileName) {
        if (this.internalLog.length > appSettings.DEBUG_CONSOLE_LINES) {
            this.internalLog.splice(0, 1);
        }

        this.internalLog.push({
            type: type,
            date: date,
            message: message,
            functionName: functionName,
            lineNumber: lineNumber,
            fileName: fileName,
            $$ID: this.guid()
        });
    },
    logInfo(message, css) {
        var date = this.logDateFormat();

        if (appSettings.ShowAllLogMessages) {
            console.log(`%c${date} - ${message}`, (css || 'color: green'));
            this.logInternally('info', date, message);
        }
    },
    logDebug(message, css) {
        if (appSettings.ShowAllLogMessages) {
            var date = this.logDateFormat();

            console.log(`%c${date} - ${message}`, (css || 'color: blue'));
            this.logInternally('debug', date, message);
        }
    },
    // NOTE: if you log with stacktrace, the console message will be out of order because the stack trace is retrieved async and will appear in log when data is returned
    // this can make looking at console stream very confusing because the error messages do not appear when they occured (although timestamp will be correct)
    logError(message, withStackTrace) {
        var date = this.logDateFormat();

        //if (withStackTrace)
        //{
        //    var traceResult = StackTrace.get({ offline: true });

        //    if (traceResult.promise)
        //        traceResult.promise.then(stackFrames =>  this.finishLogError(stackFrames, message, date) );
        //    else
        //        this.finishLogError(traceResult.result, message, date);
        //}
        //else
        {
            console.error(`%c${date} - ${message}`, 'color: red');
            this.logInternally('error', date, message);
        }
    },
    logErrorWithDocDebugLink(message, context) {
        var docInfo = JSON.stringify(context.DocInfo());

        obj = {
            get OpenDocument() {
                appSettings.OpenDocumentForDebug(JSON.parse(docInfo));
                return "Opening Document";
            },
            docInfo: JSON.parse(docInfo)
        };

        var date = this.logDateFormat();
        console.error(`%c${date} - ${message}\nDocument: ${context.DocInfo().docURL}\nOpen Document: %o`, 'color: red', obj);
        this.logInternally('error', date, message);
    },
    finishLogError(stackFrames, message, date) {
        if (stackFrames.length > 2) {
            var fileName = stackFrames[2].fileName;
            var functionName = stackFrames[2].functionName;
            var lineNumber = stackFrames[2].lineNumber;

            console.log(`%c${date} - ${message}, %c${functionName}(), %cLine: ${lineNumber}, %c${fileName}`,
                'color: red', 'color: green', 'color: purple', 'color: brown');

            this.logInternally('error', date, message, functionName, lineNumber, fileName);
        }
    },
    urlEncode(uri) {
        return window.encodeURIComponent(uri);
    },
    ValueInList(value, list) {
        if (value && list && _.isArray(list)) {
            return !!list.find(element => element == value);
        } else if (value === list) {
            return true;
        }
        return false;
    },

    // remove all the properties on an object and then copy the properties from another object into it.
    // this is done so that references to the original object remain intact - rather than assign a new object
    clearAndExtend(objToClear, sourceObj) {
        // modify the existing model object so existing references remain intact
        this.clearObject(objToClear);
        Object.assign(objToClear, sourceObj);
    },
    clearObject(objToClear) {
        // modify the existing model object so existing references remain intact
        for (let prop in objToClear) {
            if (objToClear.hasOwnProperty(prop))
                Vue.delete(objToClear, prop);
        }
    },

    // This will remove any unwanted properties from data before sending back to server. ($$hashKeys added by the grid)
    prepareObjectForServer(originalData) {
        if (!originalData)
            return originalData;

        var filteredData = _.cloneDeep(originalData); // makes a deep copy of the object

        // remove all unwanted properties from copy
        this.recursivelyRemoveUnwantedObjectProperties(filteredData);

        return filteredData;
    },

    recursivelyRemoveUnwantedObjectProperties(obj) {
        if (!obj)
            return;

        // this will loop through all array or object members
        for (const [key, item] of Object.entries(obj)) {
            if (typeof key == 'string' && (key.substr(0, 2) == "$$" || (key.startsWith('$') && key.endsWith('_expanded')))) {
                delete obj[key];
            } else if (typeof item == 'object')
                this.recursivelyRemoveUnwantedObjectProperties(item);
        };
    }

};