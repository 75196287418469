import Vue from 'vue';
import vuetify from './plugins/vuetify';
import App from './App.vue';
import VTooltip from 'v-tooltip';
import Snotify from 'vue-snotify';
import VueRouter from 'vue-router';

Vue.use(VTooltip, {
    defaultBoundariesElement: '.v-application--wrap',
});
Vue.config.productionTip = false;

Vue.use(Snotify);
Vue.use(VueRouter);

const routes = [
    //define routes here.
]

const router = new VueRouter({
    routes
})

new Vue({
    vuetify: vuetify.instance,
    render: h => h(App),
    router
}).$mount('#app');
