import utils from '@/Shared/utils';
import Vue from 'vue';
import EventBus from '@/Application/event-bus';
import {appSettings, careTokenSettings} from '@/Shared/appSettings';

export default {
    PrivateVariables: {
        // The contents of this are set from startApplication.jsx in order to let them be reactive
    },

    ClearAccessTokenBound: null,

    //This method is only called from the App.Vue when the site gets created so we can subscribe
    created() {
        appSettings.careTokenService = this;
        utils.log("Initializing Token Management");
        this.SetGAUserID();
        
        // If the server did not echo back tokenInfo (server will echo token info when a child window opens with token) 
        if (window.careTokenSettings?.info) {
            console.log('##### In the if care token settings: ');
            console.log(window.careTokenSettings);
           
            this.UpdateToken(window.careTokenSettings.info); 
            // if careTokenSettings.info is set, it's because a form posted info and it is being echo'd back. If you refresh the page, you will cause the original form info
            // to resubmit, which will echo back the old token... The token could be expired, or even for the wrong company (if they had subsequently changed companies).
            // this will prevent a refresh from resubitting the form data, but making it a simple get request to the current url.
            window.history.replaceState(null, null, window.location.href); 
        }

        this.ClearAccessTokenBound = this.ClearAccessToken.bind(this);

        EventBus.$on('Logout', this.ClearAccessTokenBound); // On logout clear the access token

        // If the browser is refreshed, then the token refresh timer must be set based on the remaining time before expiration. This calculates the remaining time and restarts the refresh timer.
        this.RestartRefreshTimerBasedOnAbsoluteExpireTime();
        this.UpdateServiceVars();
    },
    //This method is only called from the App.Vue when the site gets destroyed so we can unsubscribe
    destroyed() {
        EventBus.$off('Logout', this.ClearAccessTokenBound);

        this.UpdateServiceVars();
    },
    
    RestartRefreshTimerBasedOnAbsoluteExpireTime() {
        var expireAbsolutetime = this.ExpiresAbsoluteTime(); // read cached token expiration time (in client absolute time)

        if (expireAbsolutetime) {
            utils.log("Cached ExpireAbsolutetime: " + expireAbsolutetime);

            // calculate remaining time
            var now = new Date();
            var remainingSeconds = Math.floor((new Date(expireAbsolutetime) - now) / 1000);
            utils.log("Calculated remaining(seconds): " + remainingSeconds);

            // Assigning ExpiresIn, causes the refresh timer to start.
            if (remainingSeconds > 0) 
                this.ExpiresIn(remainingSeconds);
        }
    },
    /* Stores data in sessionStorage (not shared between browser windows) or falls-back to memory in unavailable */
    /* mainly used to store data (such as accesstoken) between browser refreshes so user doesn't have to relogin */
    SessionStorageOrMemory(privateVarible, key, value) {
        // Changing to always read from the privateVariable - these are initialized at startup in startApplication.jsx
        // Updates will store in privateVariable and in sessionStorage

        if (typeof (value) === 'undefined') {
            //if (sessionStorage)
            //    return sessionStorage.getItem(key);
            //else
            if (privateVarible)
                return privateVarible.Value;
            else
                return undefined;
        } else {
            if (sessionStorage) {
                if (value == null)
                    sessionStorage.removeItem(key);
                else
                    sessionStorage.setItem(key, value);
            }
            //else
            if (privateVarible)
                privateVarible.Value = value;
        }
    },
    AccessToken(accessToken) {
        if (typeof(accessToken) === 'undefined')
            return this.SessionStorageOrMemory(this.PrivateVariables.AccessToken, "AccessToken", accessToken);
        else {
            this.SessionStorageOrMemory(this.PrivateVariables.AccessToken, "AccessToken", accessToken);
            utils.debug(`AccessTokenAssigned -- break here!`);
            EventBus.$emit('AccessTokenAssigned');
        }
    },
    RefreshToken(refreshToken) {
        return this.SessionStorageOrMemory(this.PrivateVariables.RefreshToken, "RefreshToken", refreshToken);
    },
    // expiresIn is expressed in seconds
    ExpiresIn(expiresIn) {
        var result = this.SessionStorageOrMemory(this.PrivateVariables.ExpiresIn, "ExpiresIn", expiresIn);

        // if we are setting the expiration date, then we also need to update the refreshTimer
        if (expiresIn) {
            // clear any previous timer so it can be re-setup
            if (this.RefreshTimer != null) {
                clearTimeout(this.RefreshTimer);
                this.RefreshTimer = null;
            }

            if (expiresIn > 0) {
                this.RefreshTimer = setTimeout(this.RefreshAccessTokenAsync, expiresIn * 1000); // the server has a 1 min cushion, so it is okay to refresh at the expiresIn

                // Set the absolute expire time, this will be used on browser refresh to restart the timer based on cached value.
                var timeObject = new Date();
                this.ExpiresAbsoluteTime(new Date(timeObject.getTime() + (expiresIn * 1000)));
                utils.log("RefreshToken expiresIn (seconds): " + expiresIn + ", ExpireAbsolutetime: " + this.ExpiresAbsoluteTime());
            }
            else
                this.ExpiresAbsoluteTime(null);
        }

        return result;
    },
    RefreshAccessTokenAsync() {
        // Initiate async accesstoken refresh
        EventBus.$emit('RefreshAccessTokenAsync');
    },
    ExpiresAbsoluteTime(expiresAbsoluteTime) {
        return this.SessionStorageOrMemory(this.PrivateVariables.ExpiresAbsoluteTime, "ExpiresAbsoluteTime", expiresAbsoluteTime);
    },
    UserSecurityContextID(userSecurityContextID) {
        if (typeof(userSecurityContextID) === 'undefined') {
            return this.SessionStorageOrMemory(this.PrivateVariables.UserSecurityContextID, "UserSecurityContextID", userSecurityContextID);
        } else if (this.UserSecurityContextID() != userSecurityContextID){
            // only broadcast if the value actually changed
            this.SessionStorageOrMemory(this.PrivateVariables.UserSecurityContextID, "UserSecurityContextID", userSecurityContextID);
            EventBus.$emit('SelectedUserChanged');
        }
    },
    UserID(userID) {
        if (typeof(userID) === 'undefined')
            return this.SessionStorageOrMemory(this.PrivateVariables.UserID, "UserID", userID);
        else {
            this.SessionStorageOrMemory(this.PrivateVariables.UserID, "UserID", userID);
            this.SetGAUserID();
        }
    },
    Email(email) {
        if (typeof (email) === 'undefined')
            return this.SessionStorageOrMemory(this.PrivateVariables.Email, "Email", email);
        else {
            this.SessionStorageOrMemory(this.PrivateVariables.Email, "Email", email);
        }
    },
    CustomerSecurityContextID(customerSecurityContextID) {
        if (typeof(customerSecurityContextID) === 'undefined')
            return this.SessionStorageOrMemory(this.PrivateVariables.CustomerSecurityContextID, "CustomerSecurityContextID", customerSecurityContextID);
        else {
            // only broadcast if the value actually changed
            if (this.CustomerSecurityContextID() != customerSecurityContextID) {
                this.SessionStorageOrMemory(this.PrivateVariables.CustomerSecurityContextID, "CustomerSecurityContextID", customerSecurityContextID);
                EventBus.$emit('SelectedCustomerChanged');
            }
        }
    },
    CustomerID(customerID) {
        if (typeof(customerID) === 'undefined')
            return this.SessionStorageOrMemory(this.PrivateVariables.CustomerID, "CustomerID", customerID);
        else {
            this.SessionStorageOrMemory(this.PrivateVariables.CustomerID, "CustomerID", customerID);
            this.SetGAUserID();
        }
    },
    CustomerName(customerName) {
        if (typeof(customerName) === 'undefined') {
            return this.SessionStorageOrMemory(this.PrivateVariables.CustomerName, "CustomerName", customerName);
        } else if (this.CustomerName() != customerName) {
            document.title = customerName;
            // only broadcast if the value actually changed
            this.SessionStorageOrMemory(this.PrivateVariables.CustomerName, "CustomerName", customerName);
            this.SetGAUserID();
            EventBus.$emit('CustomerNameChanged');
        }
    },
    CustomerTimezone(customerTimezone) {
        return this.SessionStorageOrMemory(this.PrivateVariables.CustomerTimezone, "CustomerTimezone", customerTimezone);
    },
    CustomerRegionCode(customerRegionCode) {
        return this.SessionStorageOrMemory(this.PrivateVariables.CustomerRegionCode, "CustomerRegionCode", customerRegionCode);
    },
    CustomerPhoneNumberFormat(phoneNumberFormat) {
        return this.SessionStorageOrMemory(this.PrivateVariables.CustomerPhoneNumberFormat, "CustomerPhoneNumberFormat", phoneNumberFormat);
    },
    CustomerDefaultPromptLanguage(defaultPromptLanguage) {
        return this.SessionStorageOrMemory(this.PrivateVariables.CustomerDefaultPromptLanguage, "CustomerDefaultPromptLanguage", defaultPromptLanguage);
    },
    E911Compliance(E911Compliance) {
        return this.SessionStorageOrMemory(this.PrivateVariables.E911Compliance, "E911Compliance", E911Compliance);
    },
    CustomerRoles(customerRoles) {
        if (customerRoles)
        {
            utils.debug(` ** ** ** ** customerRoles being assigned now: ${JSON.stringify(customerRoles)} ** ** ** ** `);

            return this.SessionStorageOrMemory(this.PrivateVariables.CustomerRoles, "CustomerRoles", JSON.stringify(customerRoles));
        }
        else 
            return JSON.parse(this.SessionStorageOrMemory(this.PrivateVariables.CustomerRoles, "CustomerRoles", customerRoles));
    },
    CustomerTextToSpeechVoice(voice) {
        return this.SessionStorageOrMemory(this.PrivateVariables.CustomerTextToSpeechVoice, "CustomerTextToSpeechVoice", voice);
    },
    CustomerAbbreviation(customerAbbreviation) {
        return this.SessionStorageOrMemory(this.PrivateVariables.CustomerAbbreviation, "CustomerAbbreviation", customerAbbreviation);
    },
    BandID(bandID) {
        return this.SessionStorageOrMemory(this.PrivateVariables.BandID, "BandID", bandID);
    },
    ProviderType(providerType) {
        return this.SessionStorageOrMemory(this.PrivateVariables.ProviderType, "ProviderType", providerType);
    },
    UserName(userName) {
        return this.SessionStorageOrMemory(this.PrivateVariables.UserName, "UserName", userName);
    },
    ClearAccessToken() {
        this.BandID(null);
        this.AccessToken(null);
        this.RefreshToken(null);
        this.ExpiresIn(0); // a value of 0 will NOT setup a new refresh timer
        // NOTE: UserSecurityContextID is left untouched, so when user logs-in again it can reselect their context
        this.UpdateServiceVars();
    },
    GetApiBaseURL() {
        // If we have selected a customer and therefore have a bandId, all requests should go to that band
        var bandId = this.BandID();

        if (bandId)
            return appSettings?.ApiURLBandTemplate?.replace("{bandId}", bandId); // example "https://api-{bandId}.callcorp.com"
        else
            return appSettings.ApiBase; // if there is no band (haven't selected a customer) then use the generic base ApiURL
    },
    GetUserEventKey() {
        return this.UserID() + "_" + this.CustomerID();
    },
    GetCustomerEventKey() {
        return "GeneralEvent_" + this.CustomerID();
    },
    GetChannelName() {
        var userName = this.UserID() ? this.UserID() : "UnknownUser";

        // remove special characters that will hinder the subscriptions
        userName = userName.replaceAll(":", "").replaceAll("/", "");
        userName = window.encodeURIComponent(userName);

        var channelName = "vuePortal_" + userName + "_" + appSettings.InstanceGuid;

        return channelName;
    },
    
    // This takes the full response from an Api request that returns the users security contexts
    /*
        Example Info object:
        {
            "success": true,
            "expires_in": 1200,
            "access_token": null,
            "refresh_token": null,
            "SecurityContexts": {
            "Public": {
                "UserSecurityContextID": "<guid>",
                "SecurityContextTypeID": "Public",
                "Data": null,
                "UserID": null,
                "PermissionSetID": null,
                "BandID": null
            },
            "MFA": {
                "UserSecurityContextID": "<guid>",
                "SecurityContextTypeID": "MFA",
                "Data": ".CARE;test@test.com",
                "UserID": null,
                "PermissionSetID": null,
                "BandID": null
            },
            "User": {
                "UserSecurityContextID": "<guid>",
                "SecurityContextTypeID": "User",
                "Data": "<user id>"",
                "UserID": "<user id>"",
                "PermissionSetID": null,
                "BandID": null,
                "Email": "<email>"
            },
            "Customer": {
                "UserSecurityContextID": "<guid>",
                "SecurityContextTypeID": "Customer",
                "Data": "<customer id>",
                "UserID": "<user id>",
                "PermissionSetID": null,
                "BandID": "<bandid>"
            }
            },
            "user_list": null,
            "tokens": [
            {
                "access_token": "<encrypted token>",
                "refresh_token": "<encrypted token>"
            },
            {
                "access_token": "<encrypted token>",
                "refresh_token": "<encrypted token>"
            }
            ]
        }
    */
    UpdateToken(info) {
        /** Customer 
         **      this has to be done before the access token is assigned, because AccessToken() will broadcast a message that causes websockets to be reestablished, 
            **      and it will need the updated bandId and CustomerID to create the correct websocket uri for the band
            **/
        if (info.SecurityContexts?.Customer) {
            var customer = info.SecurityContexts.Customer;
            this.BandID(customer.BandID);
            this.CustomerID(customer.Data);
        } else {
            this.BandID(null);
            this.CustomerID(null);
        }

        /** MFA **/
        if (info.SecurityContexts?.MFA) {
            var mfa = info.SecurityContexts.MFA;

            // Sample Data: ".CARE;test@test.com"
            var parts = mfa.Data.split(';');
            this.ProviderType(parts[0]);
            this.UserName(parts[1]);
        } else {
            this.ProviderType(null);
            this.UserName(null);
        }

        /** User **/
        if (info.SecurityContexts?.User) {
            var user = info.SecurityContexts.User;

            this.UserSecurityContextID(user.UserSecurityContextID);
            this.UserID(user.Data);
            this.Email(user.Email);
        } else {
            this.UserSecurityContextID(null);
            this.UserID(null);
            this.Email(null);
        }

        /* Token - Read either the main token or the first in the array of tokens. */
        var tokenSource = (info.tokens ? info.tokens[0] : info);

        if (tokenSource.access_token)
            this.AccessToken(tokenSource.access_token);

        if (tokenSource.refresh_token)
            this.RefreshToken(tokenSource.refresh_token);

        if (info.expires_in)
            this.ExpiresIn(info.expires_in);


        /** Customer */
        /* This must be done AFTER the access token is assigned because it will cause the customer name API to be retrieved, and that needs to be done with the new access token */
        if (info.SecurityContexts?.Customer)
        {
            var customer = info.SecurityContexts.Customer;

            this.CustomerSecurityContextID(customer.UserSecurityContextID);
            this.UserID(customer.UserID); // this will override the UserID from the User security context (if exists), however, should be same so won't matter
        } else {
            // Do not clear the CustomerSecurityContextID. When re-authenticating for an expired token, the system will re-select the prev customer.
            // CustomerSecurityContextID(null);
        }

        this.UpdateServiceVars();
    },
    SetGAUserID() {
        if (window.ga) {
            window.ga('set', 'userId', (this.UserName() || "") + "_" + (this.UserID() || ''));
            window.ga('set', 'CustomerName', (this.CustomerName() || "")); // custom dimension for tracking by CustomerName
        }
    },
    UpdateServiceVars() {
        Vue.set(utils.service_variables.tokenVars, 'AccessToken', this.AccessToken())
        Vue.set(utils.service_variables.tokenVars, 'RefreshToken', this.RefreshToken())
        Vue.set(utils.service_variables.tokenVars, 'ExpiresIn', this.ExpiresIn())
        Vue.set(utils.service_variables.tokenVars, 'ExpiresAbsoluteTime', this.ExpiresAbsoluteTime())
        Vue.set(utils.service_variables.tokenVars, 'UserSecurityContextID', this.UserSecurityContextID())
        Vue.set(utils.service_variables.tokenVars, 'UserID', this.UserID())
        Vue.set(utils.service_variables.tokenVars, 'Email', this.Email())
        Vue.set(utils.service_variables.tokenVars, 'CustomerSecurityContextID', this.CustomerSecurityContextID())
        Vue.set(utils.service_variables.tokenVars, 'CustomerID', this.CustomerID())
        Vue.set(utils.service_variables.tokenVars, 'CustomerName', this.CustomerName())
        Vue.set(utils.service_variables.tokenVars, 'CustomerTimezone', this.CustomerTimezone())
        Vue.set(utils.service_variables.tokenVars, 'CustomerRegionCode', this.CustomerRegionCode())
        Vue.set(utils.service_variables.tokenVars, 'E911Compliance', this.E911Compliance())
        Vue.set(utils.service_variables.tokenVars, 'CustomerRoles', this.CustomerRoles())
        Vue.set(utils.service_variables.tokenVars, 'CustomerAbbreviation', this.CustomerAbbreviation())
        Vue.set(utils.service_variables.tokenVars, 'BandID', this.BandID())
        Vue.set(utils.service_variables.tokenVars, 'ProviderType', this.ProviderType())
        Vue.set(utils.service_variables.tokenVars, 'UserName', this.UserName())
        Vue.set(utils.service_variables.tokenVars, 'InstanceGuid', appSettings.InstanceGuid)
    }
};