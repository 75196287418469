import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';

import utils from '../../Shared/utils.jsx';

Vue.component('notification', {
    mixins: [BaseComponent],
    data: () => ({
        titleexpr: null,
        bodyexpr: null,
        subbodyexpr: null,
        colorexpr: null,
    }),
    props: {
    },
    created() {
        if (this.controlData.Title)
            this.titleexpr = utils.compile(this, this.controlData.Title);

        if (this.controlData.Body)
            this.bodyexpr = utils.compile(this, this.controlData.Body);
        
        if (this.controlData.SubBody)
            this.subbodyexpr = utils.compile(this, this.controlData.SubBody);
    },
    mounted() {
    },
    computed: {
        title() {
            if(this.titleexpr)
                return <translation-container context={this} value={utils.evaluate(this.titleexpr, this)}></translation-container>;
            return null;
        },
        body() {
            if (this.bodyexpr)
                return <translation-container context={this} value={utils.evaluate(this.bodyexpr, this)} isPossibleHtml={true}></translation-container>;
            return null;
        },
        subBody() {
            if (this.subbodyexpr)
                return <translation-container context={this} value={utils.evaluate(this.subbodyexpr, this)} isPossibleHtml={true}></translation-container>;
            return null;
        },
        color() {
            if (this.controlData.Color)
                return this.controlData.Color;

            return null;
        },
        dismissible() {
            if (this.controlData.Dismissible)
                return this.controlData.Dismissible;

            return null;
        },
        styles() {
            return {
                margin: '0 10px',
                ...this.sizeStyle,
                ...utils.resolveStyleHints(this.styleHints, this),
            };
        }
    },
    methods: {
        preRenderComplete() {
            this.finishRenderHandler(this);
        }
    },
    render() {
        if (!this.prerender_complete || !this.todisplay)
            return null;

        let type = this.color;
        let textColor = this.color;
        let icon = 'mdi mdi-information';

        if (type == 'info') {
            textColor = 'primary';
            icon = 'mdi mdi-information';
        }
        else if (type == 'warning') {
            textColor = 'outbound';
            icon = 'mdi mdi-alert'
        }
        else if (type == 'error') {
            textColor = 'error';
            icon = 'mdi mdi-alert'
        }
        else if (type == 'success') {
            textColor = 'success';
            icon = 'mdi mdi-information'
        }


        let title;
        let body;
        let notificationControls;

        if(this.controlData?.Controls?.length)
            notificationControls = <div class="d-flex ml-4">{utils.generateItemsFromArray(h, this, this.controlData.Controls, null, 'HorizontalLayout', {'finished-render': (item) => this.finishRenderHandler(item) })}</div>;

        if (this.title && this.body) {
            title = <v-card-title style={{ padding: 0, color: textColor }}>{this.title}</v-card-title>;
            body = <v-card-text style={{ padding: 0, color: textColor }}>{this.body}</v-card-text>;
        }
        else if (this.title)
            title = <v-card-text style={{ padding: 0, color: textColor }}>{this.title}</v-card-text>;
        else if (this.body)
            body = <v-card-text style={{ padding: 0, color: textColor }}>{this.body}</v-card-text>;

        return (
            <div class={{ 'c-Notification': true, [`c-name-${this.name || 'unnamed'}`]: true }}
                    v-show={this.isvisible} style={this.styles} >
                <v-alert dark text dismissible={this.dismissible} color={textColor} class="mb-3">
                    <template slot="prepend">
                        <v-icon color={textColor} class="mr-4">
                            {icon}
                        </v-icon>
                    </template>
                    {title}
                    {body}
                    <v-card-text class="text-caption" style={{ padding: 0, color: textColor }}>{this.subBody}</v-card-text>
                    <template slot="append">
                        {notificationControls}
                    </template>
                </v-alert>
            </div>

        );
    }
});