import $q from '@/Services/promiseHelper';

export default {
    MAX_LIST_SIZE: 2000,
    MIN_CACHE_TIME_SECONDS: 300,
    forceRecaching: false,
    caches: {},

    getOrCreateCache(cacheName) {
        var cache = this.caches[cacheName];

        if (!cache) {
            cache = { cachedItems: [] };
            this.caches[cacheName] = cache;
        }

        return cache;
    },
    getOrAddItem(cacheName, key, fn, data) {
        var cache = this.getOrCreateCache(cacheName);

        var promise = $q.defer();
        var item;

        cache?.cachedItems?.every((cachedItem, index) => {
            if (cachedItem.key != key)
                return true;

            // if we are forcingRecaching, then delete item from cache so it will be recached
            if (this.forceRecaching)
                cache.cachedItems.splice(index, 1); // delete from array
            else
            {
                item = cachedItem;
                item.lastUsed = new Date(); // track last time the item was used (this will allow us to purge old items so we don't grow cache memory forever)
                    
                // move the found item to the top of the array list. Thus Items will be in most recently used order, making frequently referenced items towards the top of the list
                if (index != 0)
                {
                    cache.cachedItems.splice(index, 1); // delete from array
                    cache.cachedItems.unshift(item); // unshift inserts items to the top of the array 
                }
            }
            return false;
        });

        if (item) {
            promise.promise.wasInCache = true; // put a flag in the promise to indicate the item was found in cache
        } else {
            // if the item wasn't found in cache, create a new item and add to cache
            item = new CacheItem(key, fn, data);
            cache.cachedItems.unshift(item); // unshift inserts new items to the top of the array so used items will be found faster

            // The array is in most recently used order, so the last item in the array is the oldest item. 
            // loop backwards and remove any items over MAX_LIST_SIZE and older than 1 hour. This means the shorted amount of time we will cache something is 1 hour
            for (var y = cache.cachedItems.length - 1; y >= this.MAX_LIST_SIZE; y--) {
                // trim up any items over the max that are older than MIN_CACHE_TIME_SECONDS
                var seconds = (new Date() - item.lastUsed) / 1000;
                if (seconds > this.MIN_CACHE_TIME_SECONDS)
                    cache.cachedItems.splice(y, 1); // delete from array
                else 
                    break; // as soon as we find an item less than 1 hour old we can exit, because we are looping through an ordered list
            }
        }

        item.queuePromise(promise); // queue a promise to be resolved when item is complete. If the item is already complete this will immediately resolve the promise.
        return promise.promise;
    },
    clearCache(cacheName, key, matchStartsWith) {
        var cache = this.getOrCreateCache(cacheName);

        if (!key) {
            cache.cachedItems = [];
            return;
        }

        // when removing items from cache we do a case insensitive compare
        key = key.toLowerCase();

        for (var x = cache.cachedItems.length - 1; x >= 0; x--) {
            if ((matchStartsWith && cache.cachedItems[x].key.toLowerCase().startsWith(key)) ||
                (!matchStartsWith && cache.cachedItems[x].key.toLowerCase() == key)
                ) {
                cache.cachedItems.splice(x, 1); // remove the matching item from cache
            }
        }
    }
};

class CacheItem {
    state = "new";
    promiseQueue = [];
    success;
    data;
    key;
    finishPromise = $q.defer();
    lastUsed = new Date(); // track last time the item was used (this will allow us to purge old items so we don't grow cache memory forever)

    constructor(key, fn, fnData) {
        this.key = key;
        this.finishPromise.promise.then((theData) => {
            // only allow item to be finished once.
            if (this.state == "new") {
                this.success = true;
                this.data = theData;
                this.state = "finished";
            }

            this.processQueuedPromises();
        },
        (theData) => {
            // only allow item to be finished once.
            if (this.state == "new") {
                this.success = false;
                this.data = theData;
                this.state = "finished";
            }

            this.processQueuedPromises();
        });

        fn(this.finishPromise, fnData); // invoke the function to get the data to cache. This function should invoke "resolve/reject" and pass the data to cache.
    }

    queuePromise(promise) {
        this.promiseQueue.push(promise);
        this.processQueuedPromises(); // if the item is already finished, then the queue will imediatly be processed - otherwise it will wait until finish is called
    }

    processQueuedPromises() {
        if (this.state != "finished")
            return;

        while (true) {
            var promise = this.promiseQueue.shift(); // removes the first item of the array, and returns that item

            if (!promise)
                break;

            this.success ? promise.resolve({...this.data}) : promise.reject({...this.data});
        }
    }
}