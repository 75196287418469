import Vue from 'vue';
import BaseComponent from './baseFormMixin.jsx';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

Vue.component('sform-texteditor', {
    mixins: [BaseComponent],
    data: () => ({
        uniqueid: '',
        israw: false,
        lang: 'json',
        hasfocus: false,
        height: "80px",
    }),
    props: {
    },
    created() {
        this.uniqueid = utils.generateUUID();

        if (this.element.formatData) {
            this.israw = this.element.formatData.IsRaw;
            this.lang = this.element.formatData.Language || 'javascript';
            this.height = this.element.formatData.Height ? `${this.element.formatData.Height}px` : '80px';
        }
        else {
            this.israw = false;
            this.lang = 'json';
        }
    },
    mounted() {
        this.loadComplete();
    },
    computed: {
        textvalue: function () {
            if (this.israw)
                return this.itemvalue || '';
            else
                return this.itemvalue ? JSON.stringify(this.itemvalue, null, 4) : '';
        },
    },
    methods: {
        synctext(text) {
            try {
                this.hasfocus = false;
                if (this.israw)
                    this.sync(text);
                else
                    this.sync(JSON.parse(text));
            }
            catch (e) {
                utils.warn('Failed to parse JSON', e);
            }
        },
    },
    render() {
        const classes = {
            'mb-3': true,
        };

        let padding;
        if (this.hasfocus)
            padding = "14px 5px 20px 5px";
        else
            padding = "14px 5px 5px 5px";

        let input = // <v-text-field class="caption pa-0 ma-0" outlined single-line dense hide-details style={{ width: "100%" }} value={this.itemvalue} on-input={(value) => this.sync(value)}></v-text-field>;
            <div class={classes} style={{ resize: "vertical", overflow: "hidden", width: "100%", height: this.height, borderStyle: "solid", borderColor: "silver", borderWidth: "1px", borderRadius: "5px", padding: padding, position: "relative" }}>
                <label class="v-input v-label" style="left: 5px; right: auto; position: absolute; transform: translateY(-9px) scale(0.75); top: 10px; transform-origin: top left; color: rgba(0,0,0,0.6);">{this.Title}</label>
                <ace-editor
                    editorId={'Editor_' + this.uniqueid}
                    lang={this.lang}
                    readOnly={this.element.readonly}
                    on-change-content={(text) => this.synctext(text)}
                    on-focus={() => {
                        this.hasfocus = true;
                        this.onFocus();
                    }}
                    on-blur={this.onBlur}
                    content={this.textvalue}>
                </ace-editor>
                <div v-show={this.hasfocus} style="position: absolute; bottom: 0; color: rgba(0,0,0,0.6); font-size: smaller; white-space: nowrap; overflow: hidden;">
                    {this.element.description}
                </div>
            </div>;

        return input;
    }
});