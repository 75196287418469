import { HubConnectionBuilder, LogLevel, HubConnectionState, HttpTransportType } from '@/JavaScript/Microsoft.AspNetCore.SignalR.JS/v7.0.2/signalr';
import api from '@/Services/api';
import token from '@/Services/token';
import utils from '@/Shared/utils';
import { appSettings } from '@/Shared/appSettings';

export class AspNetCoreWebSocketConnection {
    hubConnection;
    signalrSession;

    constructor(signalrSession) {
        this.signalrSession = signalrSession;
    }

    Connect(baseuri, queryParams, noticeCallback, channelEventCallback, rtEventCallback, onCloseCallback) {
        var url = `${baseuri}/EventingHub?SignalrSession=${this.signalrSession}&${new URLSearchParams(queryParams).toString()}`;

        var options = {
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets
         };

        if (!queryParams || !queryParams.apikey)
            options.accessTokenFactory = () => { return token.AccessToken(); };

        this.hubConnection = new HubConnectionBuilder()
            .withUrl(url, options)
            .configureLogging(appSettings.ShowAllLogMessage ? LogLevel.Information : LogLevel.None)
            .build();

        this.hubConnection.onclose((err) => {
            utils.log(`SignalR State Changed (onclose)=> ${JSON.stringify(err)} ${JSON.stringify(this.hubConnection.state)} ${new Date().toISOString()}`)
        })

        this.hubConnection.on("Notice", noticeCallback);
        this.hubConnection.on('Event', channelEventCallback);
        this.hubConnection.on('RTEvent', rtEventCallback);
        this.hubConnection.on('Close', () =>
        {
            utils.log("Server Side request to close WebSocketConnection");
            onCloseCallback();
        });

        return this.hubConnection.start();
    }



    Disconnect() {
        utils.log("signalR WebSocketConnection - Disconnect()");

        this.hubConnection.stop();
    }

    async invoke(method, body) {
        return await this.hubConnection.invoke(method, body);
    }

    IsConnected() {
        if (this.hubConnection)
            return this.hubConnection.state === HubConnectionState.Connected;
        return false;
    }

    IsDisconnected() {
        if (this.hubConnection)
            return this.hubConnection.state !== HubConnectionState.Connected;
        return false;
    }
    ConnectionId() {
        return this.hubConnection.connectionId;
    }

    ApiRequest(body) {
        return this.hubConnection.invoke('APIRequest', body);
    }

    CreateAndSubscribe(channelName) {
        return this.hubConnection.invoke('CreateAndSubscribe', channelName);
    }

    Subscribe(channelName) {
        // this "Subscribe" is different than the "SubscribeForSessionEvents". This sets up the callback on webserver for receiveing events from the channel
        // whereas SubscribeForSessionEvents binds the channel for certain events
        return this.hubConnection.invoke('Subscribe', channelName)
            .then(() => {
                return api.get(`Apps/Eventing/Subscribe/${token.GetUserEventKey()}/${channelName}`);
            });
    }

    StartRealTimeMonitor(filter) {
        return this.hubConnection.invoke('StartRealTimeMonitor', filter);
    }

    StopRealTimeMonitor() {
        return this.hubConnection.invoke('StopRealTimeMonitor');
    }

    AddRealTimeMonitorView(viewName, args) {
        return this.hubConnection.invoke('AddRealTimeMonitorView', { Name: viewName, Args: args });
    }

    RemoveRealTimeMonitorView(viewName, args) {
        return this.hubConnection.invoke('RemoveRealTimeMonitorView', { Name: viewName, Args: args });
    }

    GetNamedListForRealTime(listName, id, sortCol, descending, start, length, columns, filters) {
        return this.hubConnection.invoke('GetNamedListForRealTime', {
            listName: listName,
            id: id,
            sortCol: sortCol,
            descending: descending,
            start: start,
            length: length,
            columns: columns,
            filters: filters
        });
    }

    RemoveNamedListForRealTime(listName, id) {
        return this.hubConnection.invoke('RemoveNamedListForRealTime', { listName: listName, id: id });
    }

    GetTestResponse() {
        return this.hubConnection.invoke('GetTestResponse', {});
    }

    RefreshToken(token) {
        return this.hubConnection.invoke('RefreshToken', token);
    }

    ReplaceTokenAndChannel(data) {
        return this.hubConnection.invoke('ReplaceTokenAndChannel', data);
    }
}
