import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import careHelpfulFunctions from '../careHelpfulFunctions.jsx';

import utils from '../../Shared/utils.jsx';

Vue.component('combo-box', {
    mixins: [BaseComponent],
    data: function () {
        return {
            publishFieldEval: null,
            setValueFunction: null,
            sourceUrlEval: null,
            sourceRawInterpolatedEval: null,
            data: [],
            model: ''
        }
    },
    props: {},
    watch: {
        sourceUrl: async function() {
            this.data = await utils.api.get(utils.evaluate(this.sourceUrlEval, this));
        }
    },
    computed: {
        publishField: {
            set(value){
                if(this.setValueFunction === null && this.controlData.PublishField) {
                    let vueSet = utils.helpers.convertSetValueToVueSet(this.controlData.PublishField, 'value');
                    this.setValueFunction = new Function('value', 'context', 'util', 'vue', `with (context) { ${vueSet} }`);
                }
                if(this.setValueFunction) {
                    this.setValueFunction(value, this, careHelpfulFunctions, Vue)
                }
                this.model = value;
            },
            get() {
                return this.model;
            }
        },
        sourceUrl() {
            return utils.evaluate(this.sourceUrlEval, this);
        },
        sourceRawInterpolated(){
            return utils.evaluateObject(this.sourceRawInterpolatedEval, this);
        },
        sourceData() {
            switch(this.controlData.SourceType.toLowerCase()) {
                case 'url':
                case 'raw': 
                    return this.data;
                case 'rawinterpolated': return this.sourceRawInterpolated;
                default: return [];
            }
        },
        styles() {
            return {
                ...utils.resolveStyleHints(this.styleHints, this),
                ...this.sizeStyle
            }
        }
    },
    //Created Replaced with preRenderComplete
    methods: {
        // The combobox does not support the item-value prop, so the on input event emits the entire item. If the item is only 
        // a string, we can just save it off. If we have a value field, we can get that from the object. If we don't have anything, we'll just 
        // save the whole object.
        onInput(item) {
            if(typeof item === 'string')
                this.publishField = item;
            else if(this.controlData.ValueField)
                this.publishField = item[this.controlData.ValueField];
            else 
                this.publishField = item;
        },
        async preRenderComplete() {
            switch(this.controlData.SourceType.toLowerCase()) {
                case 'url': {
                    this.sourceUrlEval = utils.compile(this, this.controlData.SourceURL);
                    this.data = await utils.api.get(this.sourceUrl);
                    break;
                }
                case 'raw': {
                    this.data = this.controlData.SourceRaw;
                    break;
                }
                case 'rawinterpolated': {
                    this.sourceRawInterpolatedEval = utils.compileObject(this, this.controlData.SourceRaw);
                    break;
                }
            }
            this.finishRenderHandler(this);
        },
    },
    render(h) {
        if (!this.todisplay)
                return null;
        try {
            return (
                <div
                    class={{ 'c-ComboBox': true, [`c-name-${this.name || 'unnamed'}`]: true }}
                    style={this.styles} class="ma-1" v-show={this.isvisible}>
                    <v-combobox
                        items={this.sourceData} 
                        item-text={this.controlData.DisplayField} 
                        placeholder={this.controlData.PlaceHolder}
                        on-input={this.onInput}
                        hide-details
                        dense
                        outlined
                    ></v-combobox>
                </div>
            );
        }
        catch (e) {
            utils.error('ComboBox Render failed', e);
            return <div>ComboBox Failed to Render {e}</div>;
        }
    }
});