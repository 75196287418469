import Vue from 'vue';
import utils from '../../Shared/utils.jsx';
import BaseComponentMixin from './BaseComponentMixin.jsx';
import BaseStackMixin from './BaseStackMixin.jsx';
import EventBus from '../event-bus.js';

Vue.component('stack-vertical-content-debug', {
    data: function () {
        return {
            showMenu: false,
        }
    },
    props: {
        debug: null,
    },
    methods: {
        showContextMenu(e) {
            e.preventDefault()
            this.showMenu = false
            this.x = e.clientX
            this.y = e.clientY
            this.$nextTick(() => {
                this.showMenu = true
            })
        },
    },
    render(h) {
        return (
            <i
                title="Debug Refresh"
                class="mdi mdi-cached debug-refresh-icon"
                style={{ cursor: "pointer", visibility: utils.global_variables.debug_reveal ? 'visible' : 'hidden' }}
                on-click={(e) => this.debug.refreshchildren(e)}
                on-contextmenu={(e) => this.showContextMenu(e)}
            >
                <v-menu
                    value={this.showMenu}
                    position-x={this.x}
                    position-y={this.y}
                    absolute
                    offset-y
                    close-on-click
                >
                    <v-list two-line>
                        <v-subheader class="ml-2 mr-2 subtitle-2">
                            {this.debug.controlURLvalue}
                        </v-subheader>

                        <v-divider></v-divider>

                        <v-list-item-group color="primary">
                            <v-list-item key={0} on-click={(e) => this.debug.refreshchildren(e)}>
                                <v-list-item-icon>
                                    <v-icon>mdi-refresh</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title>Reload</v-list-item-title>
                                    <v-list-item-subtitle>Flushes cache and reloads</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item key={1} on-click={(e) => this.debug.openForLocalEdit(e, this.debug.controlURLvalue)}>
                                <v-list-item-icon>
                                    <v-icon>mdi-pencil</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title>Edit</v-list-item-title>
                                    <v-list-item-subtitle>Local Tab</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item key={2} on-click={(e) => this.debug.openForExternalEdit(e, this.debug.controlURLvalue, this.debug.controlCustomerId, true)}>
                                <v-list-item-icon>
                                    <v-icon>mdi-pencil-box-multiple</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title>Edit</v-list-item-title>
                                    <v-list-item-subtitle>New Window</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item key={3} on-click={(e) => this.debug.openForExternalEdit(e, this.debug.controlURLvalue, this.debug.controlCustomerId, false)}>
                                <v-list-item-icon>
                                    <v-icon>mdi-pencil-box-multiple-outline</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title>Edit</v-list-item-title>
                                    <v-list-item-subtitle>New Browser Tab</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item key={4} on-click={(e) => this.debug.openDocumentStandAlone(e, this.debug.controlURLvalue)}>
                                <v-list-item-icon>
                                    <v-icon>mdi-play-box-multiple</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title>Open Stand-Alone</v-list-item-title>
                                    <v-list-item-subtitle>New Window</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                        </v-list-item-group>
                    </v-list>
                </v-menu>
            </i>
        );
    }
});

Vue.component('stack-vertical-content', {
    mixins: [BaseComponentMixin],
    data: function () {
        return {
            master_key: null,
            sourceraw: null,
            sourceurl: null,
            model: null,
            feedbackItems: [],

            displayFeedbackEval: null,
            directionEval: null,
            formSizeEval: null,
            verticalAlignChildrenEval: null,
            horizontalAlignChildrenEval: null,
            paddingEval: null,

            cc_form_changed_indicator: false,
            cc_form_changed_active: false,
            cc_form_changed_linked: false,
            cc_form_changed_button: null,
            cc_form_changed_form: null
        }
    },
    computed: {
        isbase: function () {
            return this.asbase;
            //return this.type == 'ControlContainer' || this.asbase;
        },
        totalItems() {
            let total = 0;

            // Each of the controls in the controls array will be rendered at least once.
            if (Array.isArray(this.controlData.Controls)) {
                total += this.controlData.Controls.length;
            }

            // There is a model and it is an object. In this case, we will repeat all of the controls for each item in the model.
            if (this.model && typeof this.model === 'object') {
                if (Array.isArray(this.model))
                    total = total * this.model.length;
                else
                    total = total * Object.keys(this.model).length;
            }

            // Control Groups for adding customizations based on installed modules
            if (this.controlData && this.controlData.ControlGroupName && this.controlData.ControlGroupName in utils.document_cache.usercontrols) {
                let groupitems = utils.document_cache.usercontrols[this.controlData.ControlGroupName];
                if (Array.isArray(groupitems))
                    total += groupitems.length;
            }

            // Add one to include the component itself.
            total++;

            return total;
        },
        feedbackItemsComputed() {
            let items = [];
            if (this.feedbackItems.length) {
                items = this.feedbackItems.map(item => {
                    let type = item.messageType;
                    let textColor = item.messageType;
                    let icon = 'mdi mdi-information';

                    if (type == 'Info') {
                        textColor = 'primary';
                        icon = 'mdi mdi-information';
                    }
                    else if (type == 'Warning') {
                        textColor = 'outbound';
                        icon = 'mdi mdi-alert'
                    }
                    else if (type == 'Error') {
                        textColor = 'error';
                        icon = 'mdi mdi-alert'
                    }
                    else if (type == 'Success') {
                        textColor = 'success';
                        icon = 'mdi mdi-information'
                    }

                    let body;
                    if (item.message)
                        body = <v-card-text style={{ padding: 0, color: textColor }}><span domPropsInnerHTML={item.message}></span></v-card-text>;

                    return (
                        <v-alert dark text color={textColor} max-width='1000px' key={item.message} class="mb-3 mt-1 ml-2">
                            <template slot="prepend">
                                <v-icon color={textColor} class="mr-4">
                                    {icon}
                                </v-icon>
                            </template>
                            {body}
                        </v-alert>
                    );
                });
            }

            return (
                <div class="d-flex flex-column">
                    <transition-group name="fieldset-list">
                        {items}
                    </transition-group>
                </div>
            );
        },
        displayFeedback() {
            if (!this.displayFeedbackEval && this.controlData.DisplayFeedback)
                this.displayFeedbackEval = utils.compile(this, this.controlData.DisplayFeedback);

            let displayFeedback = (this.displayFeedbackEval ? utils.evaluate(this.displayFeedbackEval, this) : 'Default').toLowerCase();

            return displayFeedback == 'true' || (displayFeedback == 'default' && this.isbase);
        },
        direction() {
            if (!this.directionEval && this.controlData.Direction)
                this.directionEval = utils.compile(this, this.controlData.Direction);
            if (this.directionEval)
                return utils.evaluate(this.directionEval, this);
            return 'Normal';
        },
        formSize() {
            if (!this.formSizeEval && this.controlData.FormSize)
                this.formSizeEval = utils.compile(this, this.controlData.FormSize);
            if (this.formSizeEval)
                return utils.evaluate(this.formSizeEval, this);
            return 'Normal';
        },
        verticalAlignChildren() {
            if (!this.verticalAlignChildrenEval && this.controlData.VerticalAlignChildren)
                this.verticalAlignChildrenEval = utils.compile(this, this.controlData.VerticalAlignChildren);
            if (this.verticalAlignChildrenEval)
                return utils.evaluate(this.verticalAlignChildrenEval, this);
            return '';
        },
        horizontalAlignChildren() {
            if (!this.horizontalAlignChildrenEval && this.controlData.HorizontalAlignChildren)
                this.horizontalAlignChildrenEval = utils.compile(this, this.controlData.HorizontalAlignChildren);
            if (this.horizontalAlignChildrenEval)
                return utils.evaluate(this.horizontalAlignChildrenEval, this);
            return '';
        },
        stackClasses() {
            let classes = [];
            if(this.isVertical) {
                if (this.horizontalAlignChildren) {
                    switch (this.horizontalAlignChildren.toLowerCase()) {
                        case 'middle': {
                            classes.push('align-center');
                            break;
                        }
                        case 'right': {
                            classes.push('align-end');
                            break;
                        }
                        default:
                            classes.push('align-start');
                            break;
                    }
                }
                else {
                    classes.push('align-start');
                }
    
                if (this.verticalAlignChildren) {
                    switch (this.verticalAlignChildren.toLowerCase()) {
                        case 'center': {
                            classes.push('justify-center');
                            break;
                        }
                        case 'bottom': {
                            classes.push('justify-end');
                            break;
                        }
                        case 'spacearound': {
                            classes.push('justify-space-around');
                            break;
                        }
                        case 'spacebetween': {
                            classes.push('justify-space-between');
                            break;
                        }
                        case 'spaceevenly': {
                            classes.push('justify-space-evenly');
                            break;
                        }
                        default:
                            classes.push('justify-start');
                            break;
                    }
                }
                else {
                    classes.push('justify-start');
                }
            }
            else {
                if(this.verticalAlignChildren) {
                    switch(this.verticalAlignChildren.toLowerCase()) {
                        case 'center': {
                            classes.push('align-center');
                            break;
                        }
                        case 'bottom': {
                            classes.push('align-end');
                            break;
                        }
                        default:
                            classes.push('align-start');
                            break;
                    }
                }
                else {
                    classes.push('align-start');
                }
    
                if(this.horizontalAlignChildren) {
                    switch(this.horizontalAlignChildren.toLowerCase()) {
                        case 'middle': {
                            classes.push('justify-center');
                            break;
                        }
                        case 'right': {
                            classes.push('justify-end');
                            break;
                        }
                        case 'spacearound': {
                            classes.push('justify-space-around');
                            break;
                        }
                        case 'spacebetween': {
                            classes.push('justify-space-between');
                            break;
                        }
                        case 'spaceevenly': {
                            classes.push('justify-space-evenly');
                            break;
                        }
                        default:
                            classes.push('justify-start');
                            break;
                    }
                }
                else {
                    classes.push('justify-start');
                }
            }

            return classes
        },
        padding() {
            if (!this.paddingEval && this.controlData.Padding)
                this.paddingEval = utils.compile(this, this.controlData.Padding);
            if (this.paddingEval)
                return utils.evaluate(this.paddingEval, this);
            return '';
        },
        isVertical() {
            return this.type && (this.type.toLowerCase().includes('vertical') || this.type.toLowerCase() === 'controlcontainer');
        },
        isHorizontal() {
            return this.type && this.type.toLowerCase().includes('horizontal');
        },
        overflowSetting() {
            if (this.isHorizontal && (this.parentType === 'ContentDef' || this.parentType == 'LayoutCard'))
                return 'hidden';
            else
                return 'auto';
        },
        formChangedIndicator() {
            if (!this.cc_form_changed_active)
                return false;

            if (!this.cc_form_changed_button?.prerender_complete)
                return false;

            if (!this.cc_form_changed_form?.prerender_complete)
                return false;

            return this.cc_form_changed_indicator;
        },
        sourcerawvalue: function () {
            if (this.sourceraw)
                return utils.evaluateObject(this.sourceraw, this);
            else
                return null;
        },
        sourceurlvalue: function () {
            if (this.sourceurl)
                return utils.evaluate(this.sourceurl, this);
            else
                return '';
        },

        Input: function () {
            if ('input' in this.$attrs)
                return this.$attrs.input;
            else
                return null;
        },
    },
    async created() {
        //utils.debug(`VerticalStackContent:${this.type}:${this.name}:${this._uid} create...`);

        this.master_key = utils.generateUUID();

        if (this.controlData.SourceType === 'Raw' && this.controlData.SourceRaw) {
            this.sourceraw = utils.compileObject(this, this.controlData.SourceRaw);

            if (this.todisplay)
                this.$sourcerawwatch = this.$watch(
                    function () { return this.sourcerawvalue; },
                    function (newvalue, oldvalue) {
                        this.Refresh();
                    },
                    {
                        deep: true
                    }
                );
        }
        else if (this.controlData.SourceType === 'Url' && this.controlData.SourceUrl) {
            this.sourceurl = utils.compile(this, this.controlData.SourceUrl);

            this.$sourceurlwatch = this.$watch(
                function () { return this.sourceurlvalue; },
                function (newvalue, oldvalue) {
                    this.Refresh();
                });
            
        }

        // Subscribe to the UIAction_DisplayFeedback event
        if (this.displayFeedback) {
            this.$on('Action-DisplayFeedback', this.performDisplayFeedback);

            if (this.controlData.Name) {
                this.$on(`Action-DisplayFeedback:${this.controlData.Name}`, this.performDisplayFeedback);
                EventBus.$on(`Action-DisplayFeedback:${this.controlData.Name}`, this.performDisplayFeedback);
            }
        }

        // Switch isready to cause the render to run again
        this.isready = true;
    },
    watch: {
        todisplay: function (newvalue) {
            if (newvalue && !this.$sourcerawwatch)
                this.$sourcerawwatch = this.$watch(
                    function () { return this.sourcerawvalue; },
                    function (newvalue, oldvalue) {
                        this.Refresh();
                    },
                    {
                        deep: true
                    }
                );
        },
    },
    //Mounted Replaced with preRenderComplete
    destroyed() {
        // Unsubscribe from the UIAction_DisplayFeedback event
        if (this.displayFeedback) {
            this.$off('Action-DisplayFeedback', this.performDisplayFeedback);

            if (this.controlData.Name) {
                this.$off(`Action-DisplayFeedback:${this.controlData.Name}`, this.performDisplayFeedback);
                EventBus.$off(`Action-DisplayFeedback:${this.controlData.Name}`, this.performDisplayFeedback);
            }
        }

        if (this.$sourcerawwatch)
            this.$sourcerawwatch();
        if (this.$sourceurlwatch)
            this.$sourceurlwatch();
    },
    methods: {
        async preRenderComplete() {
            //utils.debug(`VerticalStackContent:${this.type}:${this.name}:${this._uid} preRenderComplete`);

            await this.Refresh();
            this.finishRenderHandler(this);
        },
        formChanged() {
            if (!this.cc_form_changed_active)
                return;

            if (!this.cc_form_changed_button?.prerender_complete)
                return;

            if (!this.cc_form_changed_form?.prerender_complete)
                return;

            this.cc_form_changed_indicator = true;
        },
        async performRefresh(action) {
            utils.log(`BaseStackMixin ${this.name} Refresh action`);

            if (action.ActionData && action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            try {
                // Reload any dynamic content (sources)
                await this.Refresh();

                // Update the master key which will cause all child controls to reload
                this.master_key = utils.generateUUID();

                try {
                    await utils.success(action);
                } catch (e) { }
            }
            catch (e) {
                try {
                    await utils.failure(action, { Data: e });
                } catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
        async Refresh() {
            if (this.sourceraw)
                this.model = this.sourcerawvalue;
            else if (this.sourceurl)
                try {
                    this.model = await utils.api.get(this.sourceurlvalue);
                }
                catch (e) {
                    utils.warn(this.type + ' failed to evaluate SourceUrl ' + this.controlData.SourceUrl + ': ' + e);
                }
            // this.finishRenderHandler();
        },
        performDisplayFeedback(action) {
            try {
                utils.log(`${this.type} ${this.name} DisplayFeedback ${action.ActionData.MessageGroup} ${action.ActionData.Messages?.length || '0'}`);

                if (action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

                let messageGroup = action.messagegroup ? utils.evaluate(action.messagegroup) : '';

                // If there is a message group, the new messages will replace any existing items for 
                // that message group. We'll loop through the array backwards so we don't mess things up 
                // when we remove an item.
                let i = this.feedbackItems.length;
                while (i--) {
                    if (this.feedbackItems[i].messageGroup === messageGroup) {
                        this.feedbackItems.splice(i, 1);
                    }
                }

                let finalArray = [];

                if (action.messages_expn) {
                    const messages = utils.evaluateObject(action.messages_expn, action.context);

                    if (typeof messages === 'object' && Array.isArray(messages) && messages.length)
                        messages.forEach(message => {
                            finalArray.push({
                                messageType: message.MessageType,
                                message: message.Message,
                                messageGroup: messageGroup,
                            });
                        });
                }
                else if (typeof action.messages === 'object' && Array.isArray(action.messages) && action.messages.length) {
                    action.messages.forEach(message => {
                        finalArray.push({
                            messageType: message.messagetype,
                            message: utils.evaluate(message.message, action.context),
                            messageGroup: messageGroup,
                        });
                    });
                }

                if (finalArray.length > 0)
                    this.feedbackItems.push(...finalArray);

                utils.success(action)
            }
            catch (e) {
                utils.failure(action, { Error: e })
            }
            finally {
                utils.complete(action);

                action.FinishFunc(true);
            }
        },

        generateItems(h, controlData, items, model, index, parentType, modelindex) {
            let tempItems = []
            for (let i = 0; i < controlData.Controls.length; i++) {
                const c = controlData.Controls[i];
                if (!(typeof c === 'object'))
                    continue;

                let DynamicControl = utils.getDynamicComponent(h, c);

                if (!DynamicControl)
                    DynamicControl = 'default-unknown';

                if (!c.$objectId) c.$objectId = utils.generateUUID();

                let id = `${this.master_key}_${c.$objectId}`;
                if (index) id = `${index}_${id}`;

                tempItems.push(
                    <DynamicControl
                        key={id}
                        on={{ 'finished-render': (reference) => this.finishRenderHandler(reference) }}
                        type={c.ControlType}
                        name={c.ControlData ? c.ControlData.Name : ''}
                        root={this.root}
                        parentType={parentType}
                        controlData={c.ControlData}
                        controlURL={c.ControlURL}
                        sourceData={model}
                        sourceIndex={modelindex}
                        controlName={c.Name}
                        scopeitems={this.scopeitems}
                        controlscope={this.controlscope}
                        on-grid-exported-text={(val) => this.$emit("grid-exported-text", val)}
                        cacheControl={c.CacheControl}
                        controlEvents={c.Events}
                    >
                    </DynamicControl>
                );
            }
            if (this.direction === 'Reversed')
                tempItems.reverse();
            items.push(...tempItems);
        },
        getHeader(h, controlData) {
            if (controlData.HeaderControls && controlData.HeaderControls.length > 0) {
                const header = [];

                for (let i = 0; i < controlData.HeaderControls.length; i++) {
                    const c = controlData.HeaderControls[i];
                    let DynamicControl = utils.getDynamicComponent(h, c);
                    if (!DynamicControl)
                        DynamicControl = 'default-unknown';

                    if (!c.$objectId) c.$objectId = utils.generateUUID();

                    let id = `${this.master_key}_${c.$objectId}_${i}`;

                    header.push(
                        <DynamicControl
                            on={{ 'finished-render': (reference) => this.finishRenderHandler(reference) }}
                            type={c.ControlType}
                            key={id}
                            name={c.ControlData ? (c.ControlData.Name || 'unnamed') : 'unnamed'}
                            root={this.root}
                            parentType="HorizontalStack"
                            controlData={c.ControlData}
                            controlURL={c.ControlURL}
                            controlName={c.Name}
                            scopeitems={this.scopeitems}
                            controlscope={this.controlscope}
                            on-grid-exported-text={(val) => this.$emit("grid-exported-text", val)}
                            cacheControl={c.CacheControl}
                            controlEvents={c.Events}
                        >
                        </DynamicControl>
                    );
                }
                return header;
            }
            return null;
        },
        generateItemsFromModel(h, items, controlData, items_id, parentType) {
            if (this.model && typeof this.model === 'object') {
                if (Array.isArray(this.model))
                    for (let i = 0; i < this.model.length; i++) {
                        const item = this.model[i];
                        const item_index = `${i}_${items_id}`;
                        this.generateItems(h, controlData, items, item, item_index, parentType, i);
                    }
                else
                    Object.keys(this.model).forEach(key => {
                        const item = this.model[key];
                        const item_index = `${key}_${items_id}`;

                        this.generateItems(h, controlData, items, item, item_index, parentType, key);
                    });
            }
            else if (controlData.Controls)
                this.generateItems(h, controlData, items, null, items_id, parentType);

            // Control Groups for adding customizations based on installed modules
            if (controlData.ControlGroupName && controlData.ControlGroupName in utils.document_cache.usercontrols) {
                let groupitems = utils.document_cache.usercontrols[controlData.ControlGroupName];
                groupitems = { Controls: groupitems.map(c => c.Data) };
                this.generateItems(h, groupitems, items, null, items_id, parentType);
            }
        },
    },
    props: {
        type: null,
        asbase: false,
    },
    render(h) {
        try {
            if (!this.prerender_complete || !this.todisplay)
                return null;
    
            let items = [];
            let items_id = '_0';
    
            let controlData = this.controlData;
    
            const header = this.getHeader(h, controlData);
            let headerContent = null;
            if (header)
                headerContent = 
                    <div style={{ display: "flex", flexDirection: "row", flexGrow: "0", flexShrink: "0", padding: "2px", paddingLeft: "8px" }}>
                        {header}
                    </div>;

            this.generateItemsFromModel(h, items, controlData, items_id, this.type);

            const style = {
                overflow: 'auto',
                display: "flex",
                flexDirection: "column",
                ...utils.resolveStyleHints(this.styleHints, this),
                width: this.isFullScreen ? '100%' : '', // Support full screen
                flexGrow: "1",
                flexShrink: "1"
            };

            let customClasses = "";
            if (this.styleHints && this.styleHints.CustomClasses) {
                customClasses = utils.resolveCustomClasses(this.styleHints, this);
            }
            
            const bodyStyle = {
                overflow: this.overflowSetting,
                display: "flex",
                flexDirection: this.isVertical ? "column" : 'row',
                flexGrow: 1,
                gap: this.padding ? this.padding + 'px' : '',
                padding: this.padding ? this.padding + 'px' : '',
            };

            let debug;
            if (this.debug)
                // Insert the debug refresh icon in the upper left corner of the control.
                // Hold down the control key to make this visible. Right click for more options.
                debug = (
                    <stack-vertical-content-debug debug={this.debug}>
                    </stack-vertical-content-debug>
                );

            return (
                <div
                    v-show={this.isvisible}
                    class={{ 
                        'c-StackContent': true, 
                        [`c-name-${this.name || 'unnamed'}`]: true, 
                        [`${customClasses}`]: true }}
                    style={style}>
                    {debug}
                    {headerContent}
                    {this.feedbackItemsComputed}
                    <div style={bodyStyle}
                        class={this.stackClasses}>
                        {items}
                    </div>
                </div>
            );
        }
        catch (e) {
            utils.error('VerticalStackContent Render failed', e);
            return <div>VerticalStackContent Failed to Render {e}</div>;
        }
    }
});