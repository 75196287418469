import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import utils from '../../Shared/utils.jsx';
import EventBus from '../event-bus.js';

Vue.component('basic-popup-menu', {
    mixins: [BaseComponent],
    data: function () {
        return {
            textrenderer: null,
            iconrenderer: null,
            iconpositioner: null,
            icongapeval: null,
            flipHorizontallyEval: null,
            flipVerticallyEval: null,
            borderEval: null,
            animationEval: null,
            colorEval: null,
            cssClassEval: null,
            tooltiprenderer: null,
            tooltipLocationEval: null,
            popupMenuPlacementEval: null,
            popupMenuBackgroundColorEval: null,
            popupMenuFontColorEval: null,
            popupMenuWidthEval: null,
            popupMenuMinHeightEval: null,
            popupMenuMaxHeightEval: null,
            popupMenuBodyPaddingEval: null,
            isDefaultEval: null,
            isdisabledeval: null,

            menushowing: false,
        }
    },
    props: {},
    computed: {
        text() {
            if(this.controlData.Text)
                return (
                    <translation-container context={this} value={this.controlData.Text}></translation-container>
                );
            return null;
        },
        icon() {
            if(this.iconrenderer === null)
                this.iconrenderer = utils.compile(this, this.controlData.Icon);
            let icon = utils.evaluate(this.iconrenderer, this);

            return icon;
        },
        iconPosition() {
            if(this.iconpositioner === null && this.controlData.IconPosition)
                this.iconpositioner = utils.compile(this, this.controlData.IconPosition);
            if(this.iconpositioner)
                return utils.evaluate(this.iconpositioner, this);
            return 'right';
        },
        iconGap(){
            if(this.icongapeval === null && this.controlData.IconGap)
                this.icongapeval = utils.compile(this, this.controlData.IconGap);
            if(this.icongapeval)
                return utils.evaluate(this.icongapeval, this);
            return 10;
        },
        flipHorizontally() {
            if(this.flipHorizontallyEval === null && this.controlData.FlipHorizontally)
                this.flipHorizontallyEval = utils.compile(this, this.controlData.FlipHorizontally);
            if(this.flipHorizontallyEval)
                return utils.evaluate(this.flipHorizontallyEval, this);
            return false;
        },
        flipVertically() {
            if(this.flipVerticallyEval === null && this.controlData.flipVertically)
                this.flipVerticallyEval = utils.compile(this, this.controlData.flipVertically);
            if(this.flipVerticallyEval)
                return utils.evaluate(this.flipVerticallyEval, this);
            return false;
        },
        border() {
            if(this.borderEval === null && this.controlData.Border)
                this.borderEval = utils.compile(this, this.controlData.Border);
            if(this.borderEval)
                return utils.evaluate(this.borderEval, this);
            return false;
        },
        animation() {
            if(this.animationEval === null && this.controlData.Animation)
                this.animationEval = utils.compile(this, this.controlData.Animation);
            if(this.animationEval)
                return utils.evaluate(this.animationEval, this);
            return 'None';
        },
        color() {
            let theColor = 'secondary';
            if(this.colorEval === null && this.controlData.Color)
                this.colorEval = utils.compile(this, this.controlData.Color);
            if(this.colorEval)
                theColor = utils.evaluate(this.colorEval, this);
            
            if(this.isOutlined && theColor)
                theColor = theColor.replace('outline-', '');
            else 
                theColor = theColor || 'secondary';
            // This helps with backwards compatibility for a version of Bootstrap that used the word 'default' to describe the secondary button
            return theColor === 'default' ? 'secondary' : theColor;
        },
        cssClass() {
            if(this.cssClassEval === null && this.controlData.CssClass)
                this.cssClassEval = utils.compile(this, this.controlData.CssClass);
            if(this.cssClassEval)
                return utils.evaluate(this.cssClassEval, this);
            return '';
        },
        tooltip() {
            if(this.tooltiprenderer === null && this.controlData.Tooltip)
                this.tooltiprenderer = utils.compile(this, this.Translate(this.controlData.Tooltip));
            if(this.tooltiprenderer) {
                return utils.evaluate(this.tooltiprenderer, this);
            }
            return '';
        },
        tooltipLocation() {
            let location = '';
            if(this.tooltipLocationEval === null && this.controlData.TooltipLocation)
                this.tooltipLocationEval = utils.compile(this, this.controlData.TooltipLocation);
            if(this.tooltipLocationEval) 
                location = utils.evaluate(this.tooltipLocationEval, this);
            
            return location.split('-')[0].toLowerCase() || 'top';
        },
        popupMenuPlacement() {
            if(this.popupMenuPlacementEval === null && this.controlData.PopupMenuPlacement)
                this.popupMenuPlacementEval = utils.compile(this, this.controlData.PopupMenuPlacement);
            if(this.popupMenuPlacementEval)
                return utils.evaluate(this.popupMenuPlacementEval, this);
            return 'bottom';
        },
        popupMenuBackgroundColor() {
            if(this.popupMenuBackgroundColorEval === null && this.controlData.PopupMenuBackgroundColor)
                this.popupMenuBackgroundColorEval = utils.compile(this, this.controlData.PopupMenuBackgroundColor);
            if(this.popupMenuBackgroundColorEval)
                return utils.evaluate(this.popupMenuBackgroundColorEval, this);
            return '';
        },
        popupMenuFontColor() {
            if(this.popupMenuFontColorEval === null && this.controlData.PopupMenuFontColor)
                this.popupMenuFontColorEval = utils.compile(this, this.controlData.PopupMenuFontColor);
            if(this.popupMenuFontColorEval)
                return utils.evaluate(this.popupMenuFontColorEval, this);
            return '';
        },
        popupMenuWidth() {
            if(this.popupMenuWidthEval === null && this.controlData.PopupMenuWidth)
                this.popupMenuWidthEval = utils.compile(this, this.controlData.PopupMenuWidth);
            if(this.popupMenuWidthEval)
                return utils.evaluate(this.popupMenuWidthEval, this);
            return '';
        },
        popupMenuMinHeight() {
            if (this.popupMenuMinHeightEval === null && this.controlData.PopupMenuMinHeight)
                this.popupMenuMinHeightEval = utils.compile(this, this.controlData.PopupMenuMinHeight);
            if (this.popupMenuMinHeightEval)
                return utils.evaluate(this.popupMenuMinHeightEval, this);
            return '';
        },
        popupMenuMaxHeight() {
            if (this.popupMenuMaxHeightEval === null && this.controlData.PopupMenuMaxHeight)
                this.popupMenuMaxHeightEval = utils.compile(this, this.controlData.PopupMenuMaxHeight);
            if (this.popupMenuMaxHeightEval)
                return utils.evaluate(this.popupMenuMaxHeightEval, this);
            return 'auto';
        },
        popupMenuBodyPadding() {
            if(this.popupMenuBodyPaddingEval === null && this.controlData.PopupMenuBodyPadding)
                this.popupMenuBodyPaddingEval = utils.compile(this, this.controlData.PopupMenuBodyPadding);
            if(this.popupMenuBodyPaddingEval)
                return utils.evaluate(this.popupMenuBodyPaddingEval, this);
            return '';
        },
        isDefault() {
            if(this.isDefaultEval === null && this.controlData.IsDefault)
                this.isDefaultEval = utils.compile(this, this.controlData.IsDefault);
            if(this.isDefaultEval)
                return utils.evaluate(this.isDefaultEval, this);
            return false;
        },
        isdisabled() {
            if(this.isdisabledeval === null && this.controlData.IsDisabled)
                this.isdisabledeval = utils.compile(this, this.controlData.IsDisabled);
            if(this.isdisabledeval)
                return utils.evaluate(this.isdisabledeval, this);
            return false;
        },
        isOutlined() {
            return this.controlData.Color && this.controlData.Color.startsWith('outline-');
        },
        buttonClasses() {
            let classes = {
                'ThemeRightConnector': this.themes.rightConnector,
                'ThemeLeftConnector': this.themes.leftConnector,
            };

            classes[this.cssClass] = true;

            return classes;
        },
        iconClasses() {
            return {
                'mdi mdi-flip-vertical': this.flipVertically, 
                'mdi mdi-flip-horizontal': this.flipHorizontally,
                'mdi mdi-border-all-variant': this.border,
                'mdi-animate-spin': this.animation === 'Spin',
                'mdi-animate-pulse': this.animation === 'Pulse',
            }
        },
        themes() {
            return {
                flat: this.controlData.Themes ? this.controlData.Themes.includes("Flat") || this.controlData.Themes.includes("FlatWhite") || this.controlData.Themes.includes("Transparent") : false,
                small: this.controlData.Themes ? this.controlData.Themes.includes("Condensed") || this.controlData.Themes.includes("CondensedBordered") : false,
                condensedBordered: this.controlData.Themes ? this.controlData.Themes.includes("CondensedBordered") : false,
                transparent: this.controlData.Themes ? this.controlData.Themes.includes("Transparent") : false,
                leftConnector: this.controlData.Themes ? this.controlData.Themes.includes("LeftConnector") : false,
                rightConnector: this.controlData.Themes ? this.controlData.Themes.includes("RightConnector") : false,
            }
        },
        styles() {
            return {
                ...utils.resolveStyleHints(this.styleHints, this),
                overflow: "hidden",
                whiteSpace: "nowrap",
            };
        }
    },
    created() {
        // Subscribe to a UIAction_Close event, which then emits the close event
        // which is handled within ActionService.jsx, where it imbeds <dialog-basic-modal>
        this.$on('Action-Close', this.performClose);

        if (this.controlData.Name) {
            this.$on(`Action-Close:${this.controlData.Name}`, this.performClose);
            EventBus.$on(`Action-Close:${this.controlData.Name}`, this.performClose);
        }
    },
    //Mounted Replaced with preRenderComplete
    methods: {
        async performClose(action) {
            utils.log(' ** Close Action Received by BasicPopupMenu **');

            this.menushowing = false;

            try {
                await utils.success(action);
            } catch (e) { }

            // Complete the promise for the executeAction method
            action.FinishFunc(true);
        },
        async menuStateChange(value) {
            this.menushowing = value;

            if (value && this.controlData.Actions && this.controlData.Actions.length)
                await utils.executeAndCompileAllActions(this.controlData.Actions, null, this);
        },
        async doClick(e) {
            e.cancelBubble = true;
            e.stopPropagation();

            if(this.controlData.Actions.length)
                await utils.executeAndCompileAllActions(this.controlData.Actions, null, this);
        },
        preRenderComplete() {
            this.finishRenderHandler(this);
        },
    },
    render(h) {
        try {
            if (!this.todisplay)
                return null;
    
            let content = [];
    
            if (this.text && this.icon) {
                if (this.iconPosition === 'Left') content.push(<v-icon style={{'margin-right': this.iconGap + 'px'}} medium={!this.themes.small} class={this.iconClasses} left>{this.icon}</v-icon>);
                content.push(this.text);
                if (!this.iconPosition || this.iconPosition === 'Right') content.push(<v-icon style={{'margin-left': this.iconGap + 'px'}} medium={!this.themes.small} class={this.iconClasses} right>{this.icon}</v-icon>);
            }
            else if (this.text)
                content.push(this.text);
            else if (this.icon)
                content.push(<v-icon size="20px" class={this.iconClasses}>{this.icon}</v-icon>);
    
            const scopedSlots = {
                activator: ({ on, attrs }) => {
                    let button = (
                        <v-btn
                            elevation={0}
                            class={this.buttonClasses}
                            style={this.styles}
                            color={this.color}
                            outlined={this.isOutlined || this.themes.condensedBordered}
                            depressed={this.themes.flat}
                            text={this.themes.transparent}
                            x-small={this.themes.small && !!this.text}
                            width={this.themes.small && !this.text ? "32px" : ""}  //Custom size instead of small
                            height={this.themes.small && !this.text ? "32px" : ""}  //Custom size instead of small
                            medium={!this.themes.small}
                            icon={!this.text}
                            on-click={(e) => this.doClick(e)}
                            type="button"
                            disabled={this.isdisabled == 'true'}
                            is-default={this.isDefault}
                            block={this.sizeOptions && this.sizeOptions.Width && this.sizeOptions.Width.Mode === 'Fill'}
                            {...{ on }}
                            {...{ attrs }}>
                            {content}
                        </v-btn>
                    );

                    return utils.generateTooltip(h, button, this.tooltip, this.tooltipLocation);
                }
            };
    
            let menuContent = utils.generateItemsFromArray(h, this, this.controlData.Controls || [], null, 'BasicPopupMenu');
            let titleContent = [];
            let title = null;
    
            if (this.controlData.PopupMenuTitle?.Text && this.controlData.PopupMenuTitle?.Icon) {
                if (this.controlData.PopupMenuTitle?.IconPosition === 'Left') titleContent.push(<v-icon style={{'margin-right': this.controlData.PopupMenuTitle.IconGap + 'px'}}>{this.controlData.PopupMenuTitle.Icon}</v-icon>);
                titleContent.push(this.controlData.PopupMenuTitle.Text);
                if (!this.controlData.PopupMenuTitle?.IconPosition || this.controlData.PopupMenuTitle?.IconPosition === 'Right') titleContent.push(<v-icon style={{'margin-left': this.controlData.PopupMenuTitle.IconGap + 'px'}}>{this.controlData.PopupMenuTitle.Icon}</v-icon>);
            }
            else if (this.controlData.PopupMenuTitle?.Text)
                titleContent.push(this.controlData.PopupMenuTitle?.Text);
            else if (this.controlData.PopupMenuTitle?.Icon)
                titleContent.push(<v-icon>{this.controlData.PopupMenuTitle?.Icon}</v-icon>);

            if(titleContent.length)
                title = <div style="border-bottom: 1px solid #46465a; font-weight: bold; padding: 8px;">{titleContent}</div>;
    
            return (
                <div
                    class={{ 'c-BasicPopupMenu': true, [`c-name-${this.name || 'unnamed'}`]: true }}
                    v-show={this.isvisible} class="ma-1" style={this.sizeStyle}>
                    <v-menu
                        value={this.menushowing}
                        on-input={(value) => this.menuStateChange(value)}
                        offset-y={this.popupMenuPlacement === 'top' || this.popupMenuPlacement === 'bottom' || this.popupMenuPlacement === 'right-top'}
                        min-width="0"
                        max-height={this.popupMenuMaxHeight}
                        disabled={this.isdisabled == 'true'}
                        bottom={this.popupMenuPlacement === 'bottom'}
                        top={this.popupMenuPlacement === 'top' || this.popupMenuPlacement === 'right-top'}
                        left={this.popupMenuPlacement === 'left'}
                        right={this.popupMenuPlacement === 'right' || this.popupMenuPlacement === 'right-top'}
                        offset-x={this.popupMenuPlacement === 'right' || this.popupMenuPlacement === 'left' || this.popupMenuPlacement === 'right-top'}
                        scopedSlots={scopedSlots}
                        close-on-content-click={false}
                    >
                        <v-sheet color={this.popupMenuBackgroundColor} width={this.popupMenuWidth} min-height={this.popupMenuMinHeight}>
                            <div class="d-flex flex-column" style={{padding: this.popupMenuBodyPadding || '14px', color: this.popupMenuFontColor}}>
                                {title}
                                {menuContent}
                            </div>
                        </v-sheet>
                    </v-menu>
                </div>
            );
        }
        catch (e) {
            utils.error('BasicPopupMenu Render failed', e);
            return <div>BasicPopupMenu Failed to Render {e}</div>;
        }
    }
});