import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import defaultTheme from '@/plugins/themes/DefaultTheme';
import msTeamsTheme from '@/plugins/themes/MSTeamsTheme';
import * as VuetifyComponents from 'vuetify/lib/components';
import * as VuetifyDirectives from 'vuetify/lib/directives';
import * as VuetifyColors from 'vuetify/lib/util/colors';

Vue.use(Vuetify, {
    components: {
        ...VuetifyComponents,
    },
    directives: VuetifyDirectives,
    colors: VuetifyColors
});

let themes = {
    defaultTheme,
    msTeamsTheme
};

export default {
    instance: new Vuetify({
        theme: {
            options: { 
                customProperties: true, 
                variations: false 
            },
            themes: {
                light: defaultTheme
                
            },
            currenttheme: "",
        },
        breakpoint: {
            thresholds: {
                sm: 625,
            },
        },
    }),
    getTheme(name) {
        let check = (name) ? name : this.instance.framework.theme.currenttheme;
        if (this.instance.framework.theme.themes[check])
            return this.instance.framework.theme.themes[check];

        return this.instance.framework.theme.themes[this.instance.framework.theme.currenttheme];
    },
    setTheme(name) {
        this.instance.framework.theme.themes.light = { ...this.instance.framework.theme.themes.light, ...themes[name] };
    },
    importWhiteLabelSettings(settings, currentthemename) {
        this.instance.framework.theme.currenttheme = currentthemename || "light";
        themes.whiteLabel = JSON.parse(settings.ColorVariables);
        this.setTheme('whiteLabel');
    }
}
