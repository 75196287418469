import { appSettings } from "@/Shared/appSettings";

export default {
    /**
    * This method makes a copy of local storage and calls the dispatch method. This will send all local storage values to the containing page.
    */
    dispatchAllLocalStorage() {
        let localStorageCopy = JSON.parse(JSON.stringify(localStorage));

        this.dispatchLocalStorageUpdate(localStorageCopy);
    },
    /**
    * Method to dispatch a local storage update to the ancestor page. The ancestor page should handle this 
    * message by putting all objects that you pass in the data object in local storage.
    *
    * @param {Object} data An object containing only key value pairs where the value is a string. These keys will be written to local storage on the
    * parent window, so all keys and values must be valid to be placed in local storage. 
    */
    dispatchLocalStorageUpdate(data) {
        if(!this.iframe)
            return;
        let message = {
            messageName: 'UpdateLocalStorageKeys',
            messageData: data
        };

        this.iframe.contentWindow.postMessage(message, `${this.origin}/localstoragesync`);
    },
    /**
     * Method to request a local storage update from our embedded iframe. 
     */
    requestLocalStorageUpdate() {
        if(!this.iframe)
            return;

        let message = {
            messageName: 'RequestLocalStorageUpdate'
        };

        this.iframe.contentWindow.postMessage(message, `${this.origin}/localstoragesync`);
    },
    /**
    * Method to read an object and put each key in local storage. If a key or value fails to be put in local storage, we'll ignore the error and move on 
    * as to ensure we write all keys.
    * 
    * @param {Object} data An object containing only key value pairs where the value is a string. These keys will be written to local storage,
    * so all keys and values must be valid to be placed in local storage. 
    */
    updateLocalStorageKeys(data) {
        Object.keys(data).forEach(key => {
            try{
                let value = data[key];
                if(value === undefined || value === null)
                    localStorage.removeItem(key);
                else
                    localStorage.setItem(key, data[key]);
            }
            catch(err) { console.error(err) }
        });
    },
    /**
     * This method starts a process that will make sure we mirror our local storage between different bands. It will send a message to an iframe that we've 
     * embedded that has the generic host for all bands. This makes sure that even if we're on a band-specific domain, we still have all of our important local
     * storage values.
     * 
     * @param { String } origin The origin that we want to use to sync our local storage with. 
     */
    initializeLocalStorageSync(origin) {
        this.origin = origin;

        let syncLocalStoragePromise = new Promise((resolve) => {
            // The iframe will communicate to us through the postMessage method, which sends messages to our window. 
            window.addEventListener('message', (event) => {
                // CRITICAL: If we don't check the origin, then theoretically anyone could send us messages that we would listen to. 
                if(event.origin !== this.origin)
                    return;
                if(event.data.messageName === 'UpdateLocalStorageKeys') {
                    this.updateLocalStorageKeys(event.data.messageData);
                }
            });

            // We use an iframe to sync local storage between domains. If we have already created an iframe, we'll go ahead and request that it updates us 
            // with the most recent version of it's local storage. This is highly unlikely, but can happen during development. No sense making multiple iframes.
            let iframe = document.querySelector('#callcorp-localstoragesync-frame');
            if(iframe) {
                this.requestLocalStorageUpdate();
                this.dispatchAllLocalStorage();
            }
            else {
                // Create an iframe that embeds a special page that will allow us to communicate between it and us. 
                iframe = document.createElement('iframe');
                iframe.id = 'callcorp-localstoragesync-frame';
                iframe.style.display = 'none';
                iframe.style.height = '0';
                iframe.style.width = '0';
                iframe.tabIndex = -1;
                iframe.src = `${this.origin}/localstoragesync`;
                iframe.onload = () => {
                    this.dispatchAllLocalStorage();
                    resolve();
                }
                document.body.appendChild(iframe);
            }
            this.iframe = iframe;
        });

        let timeoutPromise = new Promise((resolve) => setTimeout(() => {
            resolve();
        }, 2000));

        return Promise.race([syncLocalStoragePromise, timeoutPromise]);
    },
    iframe: null,
    origin: null,
};