import Vue from 'vue';
import utils from '../../../../Shared/utils.jsx';
import methods from '../../../../Shared/methods';

Vue.component('display-basic-modal-dsgn', {
    data: function () {
        return {
        }
    },
    computed: {
        Root: function () {
            return this.root._self;
        },
        Vars: function () {
            if (!this.controlData.Vars)
                Vue.set(this.controlData, 'Vars', {});

            return this.controlData.Vars;
        },
        DesignModel: function () {
            return this.designmodel;
        },
        selected$: function () {
            return this.Root.SelectedNode && this.Root.SelectedModel == this.designmodel;
        },
    },
    methods: {
        ...methods,
        handleClick(e) {
            this.Root.SelectNode(this);
            e.cancelBubble = true;
            e.stopPropagation();
        },
    },
    props: {
        name: '',
        root: null,
        designmodel: null,
        parentType: '',
        controlData: {}
    },
    render(h) {
        const style = {
            overflow: "auto",
            width: "500px",
            borderRadius: "10px",
            borderStyle: "solid",
            borderColor: "gray",
            padding: "10px",
            margin: "10px",
            display: "flex",
            flexDirection: "column",
        };

        const container = (
            <stack-vertical-dsgn root={this.root} type={this.type} parentType="VerticalStack" designmodel={this.designmodel} controlData={this.controlData.Body}>
            </stack-vertical-dsgn>
        );

        const footer = (
            <stack-horizontal-dsgn root={this.root} type={this.type} parentType="HorizontalStack" designmodel={this.designmodel} controlData={this.controlData.Footer}>
            </stack-horizontal-dsgn>
        );

        return (
            <div
                class={{ 'designer-container': true, selected: this.selected$ }}
                style={style}
                on-click={(e) => this.handleClick(e)}>
                <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
                <span style={{ backgroundColor: "gray", color: "white", textAlign: "center" }}>{this.controlData.Title}</span>
                {container}
                <hr />
                {footer}
            </div>
        );
    }
});