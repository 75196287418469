import Vue from 'vue';
import utils from '../../Shared/utils.jsx';
import methods from '../../Shared/methods';
import BaseComponent from './BaseComponentMixin.jsx';

function findTextInModel(cmodel, findText, casesensitive, occurrence) {
    if (!cmodel) return null;

    if (Array.isArray(cmodel))
        for (let i = 0; i < cmodel.length; i++) {
            const node = cmodel[i];
            if (typeof node === 'object') {
                const found = findTextInModel(node, findText, casesensitive, occurrence);
                if (found)
                    return found;
            }
            
            if (typeof node === 'string')
                if (((casesensitive && node.includes(findText)) || (!casesensitive && node.toLowerCase().includes(findText))) && --occurrence.value <= 0)
                    return cmodel;
        }
    else
        for (let key in cmodel) {
            if (key.substr(0, 1) === '$') continue;
            const node = cmodel[key];

            if (((casesensitive && key.includes(findText)) || (!casesensitive && key.toLowerCase().includes(findText))) && --occurrence.value <= 0)
                return cmodel;

            if (typeof node === 'object') {
                const found = findTextInModel(node, findText, casesensitive, occurrence);
                if (found)
                    return found;
            }

            if (typeof node === 'string')
                if (((casesensitive && node.includes(findText)) || (!casesensitive && node.toLowerCase().includes(findText))) && --occurrence.value <= 0)
                    return cmodel;
        }

    return null;
}

Vue.component('tree-label', {
    data: () => ({
        title: null,
        titletext: null,
        schema: null,
        color: null,
    }),
    created() {
        if (this.cmodel && '$typeSchema' in this.cmodel)
            this.$watch('cmodel.$typeSchema', function (val,oldval) { if (this.typeSchema) this.Refresh(); });
    },
    mounted() {
        if (this.typeSchema)
            this.Refresh();
    },
    computed: {
        ControlData: function () {
            return this.cmodel.ControlData;
        },
        ActionData: function () {
            return this.cmodel.ActionData;
        },
        ActionExpression: function () {
            return this.cmodel.ActionExpression;
        },
        Field: function () {
            return this.cmodel.Field;
        },
        Name: function () {
            return this.cmodel.Name;
        },
        StateName: function () {
            return this.cmodel.StateName;
        },
        EventName: function () {
            return this.cmodel.EventName;
        },
        NextStatePath: function () {
            return this.cmodel.NextStatePath;
        },
    },
    methods: {
        ...methods,
        async Refresh() {
            try {
                this.schema = await utils.schema.get(this.typeSchema);
                const res = utils.schema.resolve_Of(this.schema);

                this.titletext = res.title;

                if (res.documentEditorTitle)
                    this.title = utils.compile(this, res.documentEditorTitle);
                else
                    this.title = null;
            }
            catch (e) {
                utils.warn('Error resolving documentEditorTitle in TreeView with typeSchema:' + this.typeSchema + '; ' + e);
                this.title = '';
            }
        }
    },
    props: {
        label: '',
        text: '',
        typeSchema: null,
        cmodel: null,
        parent: null,
    },
    render() {
        let title = this.titletext || this.text || '';
        if (this.title)
            try {
                title = utils.evaluate(this.title, this.cmodel);
            }
            catch (e) {
                utils.warn('TreeView title failed to evaluate title: ' + e);
            }

        let label = this.label;
        const suffix = title || (this.schema ? this.schema.title : '');
        if (suffix)
            label += ': ' + suffix;

        //const color = ('$typeSchema' in this.cmodel) ? 'black' : 'gray';
        let highlight;
        if (this.cmodel.ActionExpression && this.cmodel.ActionExpression.startsWith('false'))
            highlight = true;

        const classNames = {
            'treeview-node': true,
            selected: this.parent.SelectedNode == this.cmodel,
            highlight: highlight,
            hastype: '$typeSchema' in this.cmodel && !highlight,
            hasnotype: !('$typeSchema' in this.cmodel) && !highlight,
        };

        let styles;
        if (this.color)
            styles = { color: this.color };

        //if (this.parent.SelectedNode == this.cmodel) style={{ whiteSpace: "nowrap", fontWeight: "bold" }} 
            return <span class={classNames} style={styles} on-click={() => this.parent.SelectNode(this.cmodel)}> {label}</span>;
        //else
        //    return <span class="treeview-node" style={{ whiteSpace: "nowrap", color: color }} on-click={() => this.parent.SelectNode(this.cmodel)}> {label}</span>;
    }
});

Vue.component('tree-node', {
    data: () => ({
        schema: null,
        title: null,
    }),
    methods: {
        ...methods,
        toggleVisible: function(key)
        {
            this.cmodel[key] = this.cmodel[key] === 'true' ? 'false' : 'true';
        }
    },
    props: {
        name: '',
        root: null,
        parent: null,
        cmodel: null,
        level: 0,
    },
    render() {
        if (!this.cmodel)
            return null;

        const elements = [];
        if (Array.isArray(this.cmodel))
            for (let i = 0; i < this.cmodel.length; i++) {
                const node = this.cmodel[i];

                if (typeof node === 'object') {
                    const visible_key = '$' + i + '_expanded';
                    if (!this.cmodel[visible_key])
                        Vue.set(this.cmodel, visible_key, this.level <= 0 ? 'true' : 'false');

                    const vis = this.cmodel[visible_key] === 'true';
                    const visctl = vis ? <i class="mdi mdi-minus-box-outline"></i> : <i class="mdi mdi-plus-box"></i>;

                    elements.push(
                        <div style={{ whiteSpace: "nowrap" }}>
                            <span class="treeview-expand-collapse" on-click={() => this.toggleVisible(visible_key)}>{visctl}</span>
                            <tree-label label={'(' + i + ')'} typeSchema={node.$typeSchema} parent={this.parent} cmodel={node}></tree-label>
                        </div>
                    );

                    if (vis)
                        elements.push(<tree-node name={i} root={this.root} parent={this.parent} cmodel={node} level={this.level + 1}></tree-node>);
                }
                //else
                //    elements.push(<span>[{i}]</span>);
            }
        else
            for (let key in this.cmodel)
            {
                if (key.substr(0, 1) === '$') continue;
                const node = this.cmodel[key];

                if (node && typeof node === 'object')
                {
                    const visible_key = '$' + key + '_expanded';
                    if (!this.cmodel[visible_key])
                        Vue.set(this.cmodel, visible_key, this.level <= 0 ? 'true' : 'false');

                    const vis = this.cmodel[visible_key] === 'true';
                    const visctl = vis ? <i class="mdi mdi-minus-box-outline"></i> : <i class="mdi mdi-plus-box"></i>;

                    elements.push(
                        <div style={{ whiteSpace: "nowrap" }}>
                            <span class="treeview-expand-collapse" on-click={() => this.toggleVisible(visible_key)}>{visctl}</span>
                            <tree-label label={key} typeSchema={node.$typeSchema} parent={this.parent} cmodel={node}></tree-label>
                        </div>
                    );
                    if (vis)
                        elements.push(<tree-node name={key} root={this.root} parent={this.parent} cmodel={node} level={this.level + 1}></tree-node>);
                }
                //else
                //    elements.push(<span>{key}</span>);
            }

        return (
            <div style={{ marginLeft: "15px" }}>
                {elements}
            </div>
        );
    }
});

Vue.component('tree-view', {
    mixins: [BaseComponent],
    data: () => ({
        title: null,
        sourceurl: null,
        sourceraw: null,
        cmodel: null,
        selectednode: {},
        actions: [],
        resizable: false,
        tree_width: 400,
        findText: '',
        findOccurrence: 1,
        findCaseSensitive: false,
        sourceurl_literal: null,
        history: []
    }),
    async created() {
        if (this.controlData.SourceType === 'Url' && this.controlData.SourceUrl)
        {
            this.sourceurl = utils.compile(this, this.controlData.SourceUrl);

            this.$watch(
                function () {
                    return utils.evaluate(this.sourceurl, this);
                },
                function (val, oldval) {
                    this.Refresh();
                }
            );
        }
        else if (this.controlData.SourceType === 'Raw' && this.controlData.SourceRaw)
            this.sourceraw = utils.compileObject(this, this.controlData.SourceRaw);

        this.title = utils.compile(this, this.controlData.Title);

        this.resizable = (
            this.controlData.WidthResizeBarVisible &&
            this.controlData.SizeOptions &&
            this.controlData.SizeOptions.Width &&
            this.controlData.SizeOptions.Width.Mode == 'Fixed' &&
            this.controlData.SizeOptions.Width.Units == 'Pixels' &&
            this.controlData.SizeOptions.Width.Value ) ? true : false;

        if (this.resizable)
            this.tree_width = parseInt(this.controlData.SizeOptions.Width.Value);

        //if (this.controlData.Actions)
        //    for (var i = 0; i < this.controlData.Actions.length; i++)
        //        this.actions.push(await utils.compileAction(this, this.controlData.Actions[i]));
    },
    //Mounted Replaced with preRenderComplete
    computed: {
        Model: function () {
            return this.cmodel;
        },
        SelectedNode: function ()
        {
            return this.selectednode;
        },
    },
    methods: {
        async preRenderComplete() {
            await this.Refresh();
            this.finishRenderHandler(this);
        },
        ExpandToSelected()
        {
            this.ExpandToNode(this.selectednode);
        },
        ExpandToNode(node)
        {
            const path = [];
            if (utils.findNodePath(path, this.cmodel, node))
            {
                // path now points to the node in the cmodel, just walk back down and flag all elements as expanded
                for (let i = 0; i < path.length - 1; i++)
                {
                    const item = path[i];

                    let visible_key;
                    if ('name' in item)
                        visible_key = '$' + item.name + '_expanded';
                    else if ('index' in item)
                        visible_key = '$' + item.index + '_expanded';

                    Vue.set(item.node, visible_key, 'true');
                }
                return path;
            }
        },
        SelectNode(node, expandto)
        {
            let path;
            this.selectednode = node;
            if (expandto)
                path = this.ExpandToNode(node);

            utils.executeAndCompileAllActions(this.controlData.Actions, null, this);

            //for (var i = 0; i < this.actions.length; i++)
            //    utils.executeAction(this.actions[i], null, this);

            return path;
        },
        SetModel(cmodel) {
            this.cmodel = cmodel;
        },
        async SetSourceUrl(value) {
            if (this.sourceurl_literal) {
                this.history.push(this.sourceurl_literal);
                this.sourceurl_literal = value;
            }
            this.cmodel = null;
            this.cmodel = await utils.api.get(value);
        },
        async Refresh() {
            if (this.controlData.SourceType == 'Url' && this.sourceurl) {
                this.cmodel = null;
                this.sourceurl_literal = utils.evaluate(this.sourceurl, this);
                this.cmodel = await utils.api.get(this.sourceurl_literal);
            }
            else if (this.sourceraw)
                this.cmodel = utils.evaluateObject(this.sourceraw, this);
            else
                this.cmodel = {};
        },
        splitterMouseDown(e) {
            document.addEventListener('mousemove', this.splitterMouseMove);
            document.addEventListener('mouseup', this.splitterMouseUp);
            this.initialX = e.clientX;
            this.initialWidth = this.tree_width;
            e.cancelBubble = true;
            e.stopPropagation();
        },
        splitterMouseMove(e) {
            const dx = e.clientX - this.initialX;
            this.tree_width = this.initialWidth + dx;
            e.cancelBubble = true;
            e.stopPropagation();
        },
        splitterMouseUp(e) {
            document.removeEventListener('mousemove', this.splitterMouseMove);
            document.removeEventListener('mouseup', this.splitterMouseUp);
            e.cancelBubble = true;
            e.stopPropagation();
        },
        setFindText(value) {
            if (this.findText !== value) {
                this.findText = value;
                this.findOccurrence = 0;
            }
        },
        find(e, value) {
            // If you keep clicking Find, it will progressively search for more matches. If you change
            // the find text, it will reset findOccurrence to 0 which will make it start at the beginning.
            this.findOccurrence++;

            const v = this.findCaseSensitive ? value : value.toLowerCase();
            const node = findTextInModel(this.cmodel, v, this.findCaseSensitive, { value: this.findOccurrence });
            if (node) {
                const path = this.SelectNode(node, true);
                // Walk up the path until we find a $typeSchema member - leave that as the selected node
                for (let i = path.length - 1; i >= 0; i--) {
                    const item = path[i];
                    if ('$typeSchema' in item.node) {
                        if (item.node != node)
                            this.SelectNode(item.node, false);
                        break;
                    }
                }
            }
            else {
                // If no match is found, always reset to the beginning, deselect everything
                this.findOccurrence = 0;
                this.SelectNode(null, false);
            }
        },
        async navigateBack() {
            if (this.history.length > 0) {
                const last = this.history[this.history.length - 1];
                this.history.splice(this.history.length - 1);

                this.cmodel = null;
                this.cmodel = await utils.api.get(last);
            }
        }
    },
    props: {
    },
    render() {
        if (!this.todisplay)
            return null;

        if (!this.cmodel)
            return <div>Loading...</div>;

        const style = {
            borderStyle: 'solid',
            borderWidth: '1px',
            //padding: "5px",
            //overflow: "auto",
            display: "flex",
            flexDirection: "row",
            flexGrow: "0",
            flexShrink: "1",
            fontSize: "14px"
        };

        let resizer;
        let resizeWidth;
        if (this.resizable) {
            resizer = (
                <div
                    style={{
                        backgroundColor: "gray", width: "5px", minWidth: "5px", maxWidth: "5px", overflow: "hidden", cursor: "ew-resize"
                    }}
                    on-mousedown={(e) => this.splitterMouseDown(e)}>
                </div>
            );
            resizeWidth = {
                minWidth: this.tree_width + 'px',
                maxWidth: this.tree_width + 'px'
            };
            style.alignSelf = "normal";
        }
        else
            style = { ...style, ...utils.getSize(this.controlData.SizeOptions, this.parentType, this.$parent) };

        let historybtn;
        if (this.history.length > 0)
            historybtn = (
                <v-btn elevation={0} x-small color="blue" title={'Back to ' + this.history[this.history.length - 1]} on-click={(e) => this.navigateBack()}>
                    <v-icon>mdi mdi-arrow-left-circle</v-icon>
                </v-btn>
            );

        return (
            <div
                class={{ 'c-TreeView': true, [`c-name-${this.name || 'unnamed'}`]: true }}
                style={style}>
                <div class="property-grid" style={{ overflow: "hidden", padding: "0px", ...resizeWidth, display: "flex", flexDirection: "column" }}>
                    <h3>{historybtn} {utils.evaluate(this.title, this)}</h3>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "5px" }}>
                        <v-text-field solo single-line hide-details value={this.findText} dense clearable flat placeholder="Enter Search Text" on-input={(value) => this.setFindText(value)} style={{ maxWidth: "220px" }} ></v-text-field>
                        <v-btn elevation={0} x-small title="Search" color="blue" on-click={(e) => this.find(e, this.findText)}><v-icon small>mdi mdi-magnify</v-icon></v-btn>
                        <v-btn elevation={0} x-small title="Match Case" color="blue" outlined={this.findCaseSensitive} on-click={(e) => this.findCaseSensitive = !this.findCaseSensitive}><v-icon small>mdi mdi-format-size</v-icon></v-btn>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", overflow: "auto", marginTop: "6px", flexGrow: "1" }}>
                        <div style={{ whiteSpace: "nowrap" }}>
                            <tree-label label="Root" typeSchema={this.cmodel.$typeSchema} parent={this._self} cmodel={this.cmodel}></tree-label>
                        </div>
                        <tree-node name={this.controlData.Name || 'Tree'} root={this.root} parent={this._self} cmodel={this.cmodel} level={0}></tree-node>
                    </div>
                </div>
                {resizer}
            </div>
        );
    }
});