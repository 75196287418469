import Vue from 'vue';
import utils from '../../../Shared/utils.jsx';
import BaseComponent from './BaseComponentMixin.jsx';
import controlPicker from './controlPicker.jsx';

var baseStackMixin = {
    mixins: [BaseComponent],
    data: function () {
        return {
            model: [],
            pickervisible: false,
            picker: null,
            pickertarget: 'body',
        }
    },
    computed: {
        DesignModel: function () {
            return this.designmodel;
        },
        selected$: function () {
            return this.Root.SelectedNode && this.Root.SelectedModel == this.designmodel;
        },
    },
    async created() {
    },
    destroyed() {
    },
    async mounted() {
        await this.Refresh();
    },
    methods: {
        addControl(e, target) {
            this.pickertarget = target;
            this.picker = <control-picker confirm={this.confirmAddControl} cancel={this.cancelAddControl}></control-picker>;
            this.pickervisible = true;
            e.cancelBubble = true;
            e.stopPropagation();
        },
        async confirmAddControl(id, title) {
            this.pickervisible = false;

            try {
                const schema_ = await utils.schema.get(id);
                const schema = utils.schema.resolve_Of(schema_)
                const model = utils.schema.getDefaultModel(schema);

                switch (this.pickertarget) {
                    case 'header':
                        if (!this.controlData.HeaderControls)
                            Vue.set(this.controlData, 'HeaderControls', []);

                        this.controlData.HeaderControls.push(model);
                        // Hmmm. How to select the node, it isn't created yet, not until it is rendered when this layout stack re-renders
                        //this.Root.SelectNode(model);
                        break;

                    case 'body':
                        if (!this.controlData.Controls)
                            Vue.set(this.controlData, 'Controls', []);

                        this.controlData.Controls.push(model);
                        //this.Root.SelectNode(model);
                        break;
                }
            }
            catch (e) {
                alert(e);
            }
        },
        cancelAddControl() {
            this.pickervisible = false;
        },
        pickerInput(value) {
            this.pickervisible = value;
        },
        async Refresh() {
            if (this.controlData.SourceRaw)
                this.model = this.controlData.SourceRaw;
            // Ignore this for now -- rendering the source data in design mode is kind of annoying
            //else if (this.controlData.SourceUrl)
            //    try {
            //        this.model = await utils.api.get(this.controlData.SourceUrl);
            //    }
            //    catch (e) {
            //        utils.warn('VerticalStack failed to evaluate SourceUrl ' + this.controlData.SourceUrl + ': ' + e);
            //    }
        },
        generateItems(h, controlData, items, model, index, parentType) {
            if (!controlData || !controlData.Controls)
                return;

            for (let i = 0; i < controlData.Controls.length; i++) {
                const c = controlData.Controls[i];
                if (!(typeof c === 'object'))
                    continue;

                let DynamicControl = utils.getDynamicComponent(h, c);

                if (!DynamicControl)
                    DynamicControl = 'default-unknown';

                DynamicControl += '-dsgn';

                if (!c.$objectId) c.$objectId = utils.generateUUID();

                let id = c.$objectId;
                if (index) id += '_' + index;

                items.push(
                    <DynamicControl
                        key={id}
                        type={c.ControlType}
                        name={c.ControlData ? c.ControlData.Name : ''}
                        root={this.root}
                        designmodel={c}
                        parentType={parentType}
                        controlData={c.ControlData}
                        controlURL={c.ControlURL}
                        sourceData={model}>
                    </DynamicControl>
                );
            }
        },
        getHeader(h, controlData) {
            if (controlData.HeaderControls && controlData.HeaderControls.length > 0) {
                const header = [];

                for (let i = 0; i < controlData.HeaderControls.length; i++) {
                    const c = controlData.HeaderControls[i];
                    let DynamicControl = utils.getDynamicComponent(h, c);
                    if (!DynamicControl)
                        DynamicControl = 'default-unknown';

                    DynamicControl += '-dsgn';

                    if (!c.$objectId) c.$objectId = utils.generateUUID();

                    let id = `${c.$objectId}_${i}`;

                    header.push(
                        <DynamicControl key={id} name={c.ControlData ? c.ControlData.Name : ''} root={this.root} designmodel={c} parentType={this.type} controlData={c.ControlData} controlURL={c.ControlURL}>
                        </DynamicControl>
                    );
                }
                return header;
            }
            return null;
        },
        generateItemsFromModel(h, items, controlData, items_id, parentType) {
            if (controlData.Controls)
                this.generateItems(h, controlData, items, null, items_id, parentType);
        },
        generateBreadCrumbs(h) {
            if (this.controlData.BreadCrumb && this.controlData.BreadCrumb.Text) {
                const crumbs = [];
                crumbs.push(
                    <v-btn plain small>{this.controlData.BreadCrumb.Text}</v-btn>
                );
                crumbs.push(<v-icon x-small>mdi mdi-chevron-right</v-icon>);
                return (
                    <div style="display: flex; flex-direction: row;">{crumbs}</div>
                );
            }
            else
                return null;
        },
        generatePicker(h) {
            return (
                <v-dialog value={this.pickervisible} max-width="860" scrollable on-input={(value) => this.pickerInput(value)}>
                    {this.picker}
                </v-dialog>
            );
        },
    },
    props: {
        parentType: null,
    }
};

export default baseStackMixin;