import token from '@/Services/token';
import care from '@/Services/careService';
import EventBus from '@/Application/event-bus';
import utils from '@/Shared/utils';
import $q from '@/Services/promiseHelper';
import webSocket from '@/Services/webSocketService';
import $http from '@/Services/careFetchService';
import axios from 'axios';
import { appSettings } from '@/Shared/appSettings';


export default {
    RequestQueue: [],
    apiQueuingControlModel: null,
    cacheBandId: null,
    cacheUserId: null,
    cacheCustomerId: null,
    clearRequestQueueBound: null,
    clearHttpCacheOnTokenAssignBound: null,

    //This method is only called from the App.Vue when the site gets created so we can subscribe
    created() {
        appSettings.careApiService = this;

        this.clearRequestQueueBound = this.clearRequestQueue.bind(this);
        this.clearHttpCacheOnTokenAssignBound = this.clearHttpCacheOnTokenAssign.bind(this);

        EventBus.$on('Logout', this.clearRequestQueueBound);
        EventBus.$on('AccessTokenAssigned', this.clearHttpCacheOnTokenAssignBound);
        EventBus.$on('SelectedCustomerChanged', this.clearRequestQueueBound);
    },

    //This method is only called from the App.Vue when the site gets destroyed so we can unsubscribe
    destroyed() {
        EventBus.$off('Logout', this.clearRequestQueueBound);
        EventBus.$off('AccessTokenAssigned', this.clearHttpCacheOnTokenAssignBound);
        EventBus.$off('SelectedCustomerChanged', this.clearRequestQueueBound);
    },
    // Listen for a "LoginComplete" event (sent at the end of the login dialog sequence) to cause any requests queued up (while waiting for a valid token) to be run.
    startQueuing(data) {
        this.apiQueuingControlModel = data;
    },
    endQueuing() {
        this.apiQueuingControlModel = null;
        this.processRequestQueue();
    },
    isQueing() {
        return !!this.apiQueuingControlModel;
    },
    standardizeUrl(url) {
        // Check if the url starts with "http or //", if not then add the ApiBase
        if (url && !url.match("^http|^//")) {
            if (!url.match("^/")) // if the url doesn't have an absolute path, add a leading /
                url = '/' + url;

            url = token.GetApiBaseURL() + url;
        }

        return url;
    },
    addAccessTokenHeader(requestOptions) {
        var accessToken = token.AccessToken();

        if (accessToken)
            requestOptions.headers['access_token'] = accessToken;
    },
    cachedGet(url) {
        return this.apiRequest({
            method: 'GET',
            cache: true,
            url: url,
            flatten: true
        });
    },
    get(url) {
        return this.apiRequest({
            method: 'GET',
            url: url,
            flatten: true
        });
    },
    delete_(url) {
        return this.apiRequest({
            method: 'DELETE',
            url: url,
            flatten: true
        });
    },
    post(url, data) {
        return this.apiRequest({
            method: 'POST',
            data: data,
            url: url,
            flatten: true
        });
    },
    put(url, data) {
        return this.apiRequest({
            method: 'PUT',
            data: data,
            url: url,
            flatten: true
        });
    },
    patch(url, data) {
        return this.apiRequest({
            method: 'PATCH',
            data: data,
            url: url,
            flatten: true
        });
    },
    foreachControlModelParent(controlModel, fn) {
        while (controlModel) {
            if (fn(controlModel)) // parent found
                return controlModel;

            controlModel = controlModel.Parent;
        }

        return null;
    },
    queueApiRequest(controlModel) {
        if (!this.isQueing())
            return false;

        // if ApiQueuing (presummably we are in the login dialog sequence) then all APIs not invoked from a child of the controlModel that enabled the queuing should queue.
        // if no controlModel passed in then we can't determine if it's a child of the controlModel that enabled queuing - so return true
        if (controlModel) {
            // loop through controlModel parents, see if any match ApiQueuingControlModel
            var obj = this.foreachControlModelParent(controlModel, (parentObj) => parentObj == this.apiQueuingControlModel);

            if (obj) // a parent of the controlModel matched the apiQueuingControlModel (meaning we are a child of the action that requested ApiQueuing, so we don't queue this request
                return false;
        }

        return true;
    },
    apiRequest(apiOptions) {
        apiOptions.method = apiOptions.method || 'GET';
        apiOptions.addAccessTokenInHeader = (apiOptions.addAccessTokenInHeader === undefined) ? true : apiOptions.addAccessTokenInHeader;
        apiOptions.deferred = apiOptions.deferred || $q.defer();
        apiOptions.cache = apiOptions.cache || false;
        apiOptions.multipart = apiOptions.multipart || false;
        apiOptions.doNotQueue = apiOptions.doNotQueue || false;
        apiOptions.loginRequest = apiOptions.loginRequest || false;
        apiOptions.doNotUseWebsocket = apiOptions.doNotUseWebsocket || false;
        apiOptions.doNotStandardizeUrl = apiOptions.doNotStandardizeUrl || false;

        // If the login dialog is displayed, then simply queue requests (other than the requests related to the Login itself
        if (!apiOptions.loginRequest && this.queueApiRequest(apiOptions.context)) {
            if (!apiOptions.doNotQueue)
                this.RequestQueue.push(apiOptions);
        } else {
            var requestOptions = {
                method: apiOptions.method,
                url: apiOptions.doNotStandardizeUrl ? apiOptions.url : this.standardizeUrl(apiOptions.url),
                cache: apiOptions.cache,
                headers: apiOptions.headers || {},
                absoluteUrl: !!(apiOptions.url.match("^http|^//")), // used to determine if websocket can be used (external addresses don't use websocket), we also don't include the access token for external calls
                addAccessTokenInHeader: apiOptions.addAccessTokenInHeader,
                doNotUseWebsocket: apiOptions.doNotUseWebsocket
            };

            if (apiOptions.multipart) {
                requestOptions.data = apiOptions.data;
                requestOptions.multipart = apiOptions.multipart;
            } else {
                // This will remove any unwanted properties from data before sending back to server. ($$hashKeys added by the grid)
                requestOptions.data = care.prepareObjectForServer(apiOptions.data);
            }





            if (apiOptions.addAccessTokenInHeader && !requestOptions.absoluteUrl) // Normally, the only time we don't add the accessToken to the header is when refreshing tokens
                this.addAccessTokenHeader(requestOptions);

            var startTime = new Date().getTime();
            EventBus.$emit('ApiRequestStarted', { startTime: startTime, options: requestOptions });
            utils.global_variables.api_busy_indicator = true;

            var executeAPIPromise = this.executeAPI(requestOptions, startTime);
            executeAPIPromise
                .then(response => {
                    this.logApiStop(requestOptions, startTime, executeAPIPromise.wasInCache, executeAPIPromise.webSocketRequest, response);

                    EventBus.$emit('ApiRequestFinished', {
                        success: true,
                        options: requestOptions,
                        defaultToast: apiOptions.defaultToast,
                        toast: apiOptions.toast,
                        context: apiOptions.context,
                    });
                    utils.global_variables.api_busy_indicator = false;

                    let flattenResults = response.data?.Result || response.data;
                    if(response.headers)
                        response.headers = Object.assign(...Array.from(response.headers).map(([k,v]) => ({[k]: v})));
                    apiOptions.deferred.resolve(apiOptions.flatten ? flattenResults : response); // echo successful request to chained promises
                })
                .catch(error => {
                    const reason = error.fullResponse || error.response || error; // error.fullResponse;
                    this.logApiStop(requestOptions, startTime, executeAPIPromise.wasInCache, executeAPIPromise.webSocketRequest);

                    EventBus.$emit('ApiRequestFinished', {
                        success: false,
                        options: requestOptions,
                        error: reason,
                        defaultToast: apiOptions.defaultToast,
                        toast: apiOptions.toast,
                        context: apiOptions.context,
                    });
                    utils.global_variables.api_busy_indicator = false;

                    // if it's a token problem and this api call was not initated by the login process, then queue up and begin login 
                    //if (reason.StatusCode == 401 && !this.isQueing()) {
                    if (((reason.StatusCode ? reason.StatusCode : reason.statusCode) || reason.status) == 401 && !this.isQueing()) {
                        token.ClearAccessToken(); // Whenever we run into a token problem (such as an expired token), clear the current token (which will stop the refresh timer too) and display login dialog

                        // Queue up request. These will be processed after successful login (when there is a valid token)
                        if (!apiOptions.doNotQueue)
                            this.RequestQueue.push(apiOptions);

                        EventBus.$emit('UnauthorizedApi');
                    } else {
                        // not a token problem, echo reason to chained promises
                        apiOptions.deferred.reject(reason);
                    }
                });
        }

        return apiOptions.deferred.promise; // by returning the request promise, the caller can add it's own ".then" methods for success/failure
    },
    logApiStop(requestOptions, startTime, wasInCache, webSocketRequest, response) {
        var duration = (new Date().getTime()) - startTime;
        utils.debug((webSocketRequest ? "websocket" : "http") + " apiRequest " + (wasInCache ? "**FOUND IN CACHE** " : "") + "Stop: Method: " + requestOptions.method +
            ", URL: " + requestOptions.url +
            ", Duration: " + duration + "ms");
    },
    async executeAPI(requestOptions, startTime) {
        var logMsg = "Start: Method: " + requestOptions.method + ", URL: " + requestOptions.url + ", StartTime: " + startTime + ((typeof requestOptions.data === 'object') ? (", APIRequestData: " + JSON.stringify(requestOptions.data)) : "");

        // If this is a cached request, use the standard angular request which handles caching already.
        if (!requestOptions.absoluteUrl &&
            !requestOptions.cache &&
            !requestOptions.multipart && // multi-part request. Needs to use $http
            !requestOptions.doNotUseWebsocket &&
            requestOptions.addAccessTokenInHeader && // only use websocket if we are using the accesstoken. Technically the access token in the header isn't used at all for a websocket, because the connection has the token cached from when it was established
            webSocket.IsConnected()) {

            var apiRequestObj = {
                Verb: requestOptions.method.toUpperCase(),
                Uri: requestOptions.url,
                Body: requestOptions.data
            };

            // The request is changed to a string (rather than sending object and having SignalR convert) because SignalR (server side) has a max JSON deserialization depth of 20
            // if we send deeply nested objects SignalR would error.
            var stringApiRequestObj = JSON.stringify(apiRequestObj);

            // WebSockets are best for short messages. If the message is too big we use a normal HTTP request rather than websocket.
            // The signalR team recommends keeping messages < 32K 
            if (stringApiRequestObj.length < 32768) {
                utils.debug("websocket apiRequest: " + logMsg, "color: lightblue");
                return await webSocket.APIRequest(stringApiRequestObj);

                // Old code
                var webSocketAPIPromise = webSocket.APIRequest(stringApiRequestObj); // returns a promise
                webSocketAPIPromise.webSocketRequest = true; // Flag indicating it was a websocket request
                return webSocketAPIPromise;
            }
        }

        utils.debug("http apiRequest: " + logMsg);

        let fetchOptions = { ...requestOptions };
        if (requestOptions.data && requestOptions.method.toUpperCase() != 'GET')
            fetchOptions.body = (requestOptions.data instanceof FormData || requestOptions.data instanceof File || typeof requestOptions.data === 'string') ? requestOptions.data : JSON.stringify(requestOptions.data);
        if(!fetchOptions.cache)
            delete fetchOptions.cache;
        delete fetchOptions.data;
        if(fetchOptions.multipart)
            delete fetchOptions.multipart;

        return await $http.request(fetchOptions); // returns a promise

        //const config = {
        //    headers: requestOptions.headers,
        //    method: requestOptions.method.toLowerCase(),
        //    url: requestOptions.url,
        //    data: requestOptions.data,
        //};
        //return await axios.request(config);
    },
    processRequestQueue() {
        // this will loop until there are no more items in the requestQueue
        while (true) {
            var apiOptions = this.RequestQueue.shift(); // pop the first item off the array

            if (apiOptions === undefined) // reached last element
                break;

            this.apiRequest(apiOptions);
        }
    },
    clearRequestQueue() {
        this.RequestQueue = [];
    },
    clearHttpCacheOnTokenAssign(event) {
        var bandId = token.BandID();
        var userId = token.UserID();
        var customerId = token.CustomerID();

        // don't flush the cache if the band, user and customer are the same as the last time.
        if (this.cacheBandId !== bandId || this.cacheUserId !== userId || this.cacheCustomerId !== customerId) {
            this.cacheBandId = bandId;
            this.cacheUserId = userId;
            this.cacheCustomerId = customerId;

            this.clearHttpCache();
        }
    },
    clearHttpCache(url, matchStartsWith) {
        utils.debug("Flushed HTTP Cache");

        if (typeof (url) === 'undefined') {
            $http.clearCache();
        } else {
            url = this.standardizeUrl(url);
            $http.clearCache(url, matchStartsWith);
        }
    }
};