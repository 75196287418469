import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import utils from '../../Shared/utils.jsx';

Vue.component('cc-progress-circle', {
    mixins: [BaseComponent],
    data: function () {
        return {
            tooltipEval: null,
            tooltipPlacementEval: null,
            disabledEval: null,
            objectFitEval: null,
        }
    },
    //Created Replaced with preRenderComplete
    computed: {
        style() {
            return {
                ...utils.resolveStyleHints(this.styleHints, this),
                ...this.sizeStyle,
                overflow: "none",
            };
        },
        tooltip() {
            try {
                if (this.tooltipEval === null && this.controlData.Tooltip)
                    this.tooltipEval = utils.compile(this, this.Translate(this.controlData.Tooltip));
                if (this.tooltipEval)
                    return utils.evaluate(this.tooltipEval, this);
                return '';
            }
            catch (e) {
                utils.warn('Tooltip could not evaluate expression: ' + this.controlData.Tooltip + '; ' + e);
                return '';
            }
        },
        tooltipPlacement() {
            try {
                if (this.tooltipPlacementEval === null && this.controlData.TooltipPlacement)
                    this.tooltipPlacementEval = utils.compile(this, this.controlData.TooltipPlacement);
                let placement = '';
                if (this.tooltipPlacementEval)
                    placement = utils.evaluate(this.tooltipPlacementEval, this);
                return placement.split('-')[0].toLowerCase() || 'top';
            }
            catch (e) {
                utils.warn('TooltipPlacement could not evaluate expression: ' + this.controlData.TooltipPlacement + '; ' + e);
                return '';
            }
        },
        color() {
            if (!this.color_fn && this.controlData.Color)
                this.color_fn = utils.compile(this, this.controlData.Color);

            return this.color_fn ? utils.evaluate(this.color_fn, this) : undefined;
        },
        value() {
            if (!this.value_fn && this.controlData.ValueExpression)
                this.value_fn = utils.compileExpression(this, this.controlData.ValueExpression);

            return this.value_fn ? utils.evaluate(this.value_fn, this) : 0;
        },
        width() {
            if (!this.width_fn && this.controlData.WidthExpression)
                this.width_fn = utils.compileExpression(this, this.controlData.WidthExpression);

            return this.width_fn ? utils.evaluate(this.width_fn, this) : 0;
        },
        size() {
            if (!this.size_fn && this.controlData.SizeExpression)
                this.size_fn = utils.compileExpression(this, this.controlData.SizeExpression);

            return this.size_fn ? utils.evaluate(this.size_fn, this) : 0;
        },
        rotate() {
            if (!this.rotate_fn && this.controlData.RotateExpression)
                this.rotate_fn = utils.compileExpression(this, this.controlData.RotateExpression);

            return this.rotate_fn ? utils.evaluate(this.rotate_fn, this) : 0;
        },
        content() {
            if (!this.content_fn && this.controlData.Content)
                this.content_fn = utils.compile(this, this.controlData.Content);

            return this.content_fn ? utils.evaluate(this.content_fn, this) : null;
        },
    },
    methods: {
        async preRenderComplete() {
            this.finishRenderHandler(this);
        },
        getContent(value) {
            if (!this.content_fn && this.controlData.Content)
                this.content_fn = utils.compile(this, this.controlData.Content, false, 'value');

            return this.content_fn ? utils.evaluate(this.content_fn, this, false, null, false, value) : null;
        },
    },
    render(h) {
        if(!this.todisplay)
            return null;

        try {
            let item = (
                <v-progress-circular
                    v-show={this.isvisible}
                    style={this.style}
                    class={{ 'c-ProgressCircle': true, [`c-name-${this.name || 'unnamed'}`]: true }}
                    value={this.value}
                    size={this.size}
                    width={this.width}
                    color={this.color}
                    rotate={this.rotate}
                    indeterminate={this.controlData.Indeterminate}
                >
                    {this.content}
                </v-progress-circular>
            );

            if (this.tooltip)
                item = utils.generateTooltip(h, item, this.tooltip, this.tooltipPlacement);

            return item;
        }
        catch (e) {
            utils.error('ProgressCircle Render failed', e);
            return <div>ProgressCircle Failed to Render {e}</div>;
        }
    }
});