import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import EventBus from '../event-bus.js';

import utils from '../../Shared/utils.jsx';
import methods from '../../Shared/methods';

Vue.component('iframe-component', {
    mixins: [BaseComponent],
    data: function () {
        return {
            sourceUrlEval: null,
            data: null,
        }
    },
    //Created Replaced with preRenderComplete
    computed: {
        sourceUrl() {
            if (!this.sourceUrlEval && this.controlData.Url)
                this.sourceUrlEval = utils.compile(this, this.controlData.Url);

            return this.sourceUrlEval ? utils.evaluate(this.sourceUrlEval, this) : '';
        },
        style() {
            return {
                ...this.sizeStyle,
                overflow: 'auto',
                display: 'flex',
            }
        }
    },
    methods: {
        async preRenderComplete() {
            this.finishRenderHandler(this);
        },
    },
    render(h) {
        if(!this.todisplay)
            return null;

        try {
            return (
                <div
                    v-show={this.isvisible}
                    style={this.style}
                    class={{ 'c-Html': true, [`c-name-${this.name || 'unnamed'}`]: true }}>
                    <iframe style="flex-grow: 1;" src={this.sourceUrl}></iframe>
                </div>
            );
        }
        catch (e) {
            utils.error('IFrame Render failed', e);
            return <div>IFrame Failed to Render {e}</div>;
        }
    }
});