import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import utils from '../../Shared/utils.jsx';

Vue.component('image-control', {
    mixins: [BaseComponent],
    data: function () {
        return {
            tooltipEval: null,
            tooltipPlacementEval: null,
            disabledEval: null,
            objectFitEval: null,
        }
    },
    //Created Replaced with preRenderComplete
    computed: {
        src() {
            if (this.controlData.Src && !this.srcexpn)
                this.srcexpn = utils.compile(this, this.controlData.Src);

            if (this.srcexpn)
                return utils.evaluate(this.srcexpn, this);
            else
                return '';
        },
        style() {
            const styles = {
                ...utils.resolveStyleHints(this.styleHints, this),
                ...this.sizeStyle,
            };
            if (this.controlData.Actions && this.controlData.Actions.length > 0 && !this.disabled)
                styles.cursor = "pointer";
            
            if (this.objectFit)
                styles.objectFit = this.objectFit;

            return styles;
        },
        disabled() {
            try {
                if (this.disabledEval === null && this.controlData.IsDisabled)
                    this.disabledEval = utils.compile(this, this.controlData.IsDisabled);
                if (this.disabledEval) {
                    let value = utils.evaluate(this.disabledEval, this);
                    return (value === true || value === 'true');
                }
                return false;
            }
            catch (e) {
                utils.warn('IsDisabled could not evaluate expression: ' + this.controlData.IsDisabled + '; ' + e);
                return false;
            }
        },
        objectFit() {
            try {
                if (this.objectFitEval === null && this.controlData.ObjectFit)
                    this.objectFitEval = utils.compile(this, this.controlData.ObjectFit);
                if (this.objectFitEval) {
                    return utils.evaluate(this.objectFitEval, this);
                }
                return false;
            }
            catch (e) {
                utils.warn('ObjectFit could not evaluate expression: ' + this.controlData.ObjectFit + '; ' + e);
                return false;
            }
        },
        tooltip() {
            try {
                if (this.tooltipEval === null && this.controlData.Tooltip)
                    this.tooltipEval = utils.compile(this, this.Translate(this.controlData.Tooltip));
                if (this.tooltipEval)
                    return utils.evaluate(this.tooltipEval, this);
                return '';
            }
            catch (e) {
                utils.warn('Tooltip could not evaluate expression: ' + this.controlData.Tooltip + '; ' + e);
                return '';
            }
        },
        tooltipPlacement() {
            try {
                if (this.tooltipPlacementEval === null && this.controlData.TooltipPlacement)
                    this.tooltipPlacementEval = utils.compile(this, this.controlData.TooltipPlacement);
                let placement = '';
                if (this.tooltipPlacementEval)
                    placement = utils.evaluate(this.tooltipPlacementEval, this);
                return placement.split('-')[0].toLowerCase() || 'top';
            }
            catch (e) {
                utils.warn('TooltipPlacement could not evaluate expression: ' + this.controlData.TooltipPlacement + '; ' + e);
                return '';
            }
        },
    },
    methods: {
        async preRenderComplete() {
            this.finishRenderHandler(this);
        },
        async onClick(e) {
            if (this.disabled)
                return;

            e.cancelBubble = true;
            e.stopPropagation();

            await utils.executeAndCompileAllActions(this.controlData.Actions, null, this);
        },
    },
    render(h) {
        if(!this.todisplay)
            return null;

        try {
            let image = (
                <img
                    v-show={this.isvisible}
                    style={this.style}
                    class={{ 'c-Image': true, [`c-name-${this.name || 'unnamed'}`]: true }}
                    src={this.src}
                    on-click={(e) => this.onClick(e)}
                />
            );

            if (this.tooltip)
                image = utils.generateTooltip(h, image, this.tooltip, this.tooltipPlacement);

            return image;
        }
        catch (e) {
            utils.error('Image Render failed', e);
            return <div>Image Failed to Render {e}</div>;
        }
    }
});