import Vue from 'vue';
import utils from '../../../../Shared/utils.jsx';
import methods from '../../../../Shared/methods';

Vue.component('form-anyof-dsgn', {
    data: function () {
        return {
        }
    },
    created() {
    },
    computed: {
        ...utils.forms.computed,
    },
    methods: {
        ...methods,
    },
    props: {
        name: '',
        schemakey: '',
        root: null,
        schema: null,
        cmodel: null,
        extra: null,
        readonly: false,
        child: 0,
    },
    render(h) {
        const name = <span style={{ marginLeft: (this.child * 15) + "px", whiteSpace: "nowrap" }}>{this.name}</span>;
        if (this.readonly)
            return (
                <tr>
                    <td style={{ width: "1px", "white-space": "nowrap" }}>
                        {name}
                    </td>
                    <td>
                        <input style={{ width: "100%" }} value={this.cmodel[this.schemakey]} disabled="disabled" />
                    </td>
                </tr>
            );

        const items = [];
        const schema = this.schema;

        if (!this.cmodel[this.schemakey])
            items.push(<option selected disabled="">Please select one</option>);

        for (var i = 0; i < schema.anyOf.length; i++) {
            const item = schema.anyOf[i];

            if (this.cmodel[this.schemakey] && (this.cmodel[this.schemakey].$typeSchema === item.Id))
                items.push(<option key={i} value={i} selected>{item.title}</option>);
        }
        
        return (
            <tr>
                <td style={{ width: "1px", "white-space": "nowrap" }}>
                    {name}
                </td>
                <td>
                    <select disabled style={{ width: "100%" }}>
                        {items}
                    </select>
                </td>
            </tr>
        );
    }
});