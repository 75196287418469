import Vue from 'vue';
import Vuetify from 'vuetify';
import EventBus from '../../event-bus.js';
import utils from '../../../Shared/utils.jsx';

Vue.component('field-picker', {
    data: () => ({
        filter: '',
        selected: {},
        choices: {},
        controls: null,
    }),
    props: {
        schema: null,
        confirm: null,
        cancel: null,
    },
    created() {
    },
    async mounted() {
        const formcontrol = await utils.schema.get('/Apps/Schema/AnyOf/schema/public/Platform.Schema.BaseTypes.v1/FormControl');
        if (formcontrol)
            this.controls = formcontrol.anyOf;
    },
    computed: {
    },
    methods: {
        returnControl() {
            const selected = {};
            for (let key in this.selected) {
                if (this.selected[key])
                    selected[key] = {
                        type: this.controls[this.choices[key]],
                    };
            }
            debugger;
            this.confirm(selected);
        },
        toggle(e, key) {
            Vue.set(this.selected, key, e.target.checked)
        },
        selectAll(e) {
            if (!e.target.checked) {
                this.selected = {};
                return;
            }

            for (let key in this.schema.properties)
                Vue.set(this.selected, key, true);
        },
    },
    render(h) {
        if (!this.controls)
            return <div>Loading...</div>;

        let defaultIndex = 0;
        const controlItems = [];
        for (let i = 0; i < this.controls.length; i++) {
            const c = this.controls[i];
            if (c.Id === "/schema/public/Platform.Schema.FormControls.v1/FormControl_AutoField")
                defaultIndex = i;

            controlItems.push({ text: c.title, value: i });
        }

        const items = [];
        for (let key in this.schema.properties) {
            const c = this.schema.properties[key];
            if (!(key in this.choices))
                Vue.set(this.choices, key, defaultIndex);

            items.push(
                <tr>
                    <td><input type="checkbox" checked={this.selected[key]} on-change={(e) => this.toggle(e,key)} /></td>
                    <td>{key}</td>
                    <td>{c.type}</td>
                    <td style={{ minWidth: "145px" }} class="property-grid">
                        <v-select solo dense items={controlItems} value={this.choices[key]} min-width="160px" style={{ width: "100%" }} on-change={(value) => this.choices[key] = value}>
                        </v-select>
                    </td>
                    <td style={{ maxWidth: "220px" }}>{c.description}</td>
                </tr>
            );
        }

        return (
            <v-card>
                <v-card-title class="headline">Fields</v-card-title>
                <v-card-text>
                    <table id="tableFieldPicker">
                        <tr>
                            <th><input type="checkbox" on-change={(e) => this.selectAll(e)} /></th>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Control</th>
                            <th>Description</th>
                        </tr>
                        {items}
                    </table>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text on-click={this.returnControl}>Add</v-btn>
                    <v-btn color="green darken-1" text on-click={this.cancel}>Cancel</v-btn>
                </v-card-actions>
            </v-card>
        );
    }
//                    <v-btn color="green darken-1" flat on-click={this.returnControl()}>Add</v-btn>
});