import Vue from "vue";
import BaseComponent from "./baseFormMixin.jsx";
import utils from "../../../Shared/utils.jsx";
import methods from "../../../Shared/methods";
import careHelpfulFunctions from "../../careHelpfulFunctions.jsx";

import { appSettings } from "@/Shared/appSettings.js";

/* This component handles three different schema configurations:
 * 1. items with an enum
 * 2. items with a titleMap
 * 3. formatData with LookupURL
 * 
 * All three produce a list of items which will generate a set of checkbox input elements.
 * 
 * Example of a titleMap:

            "Notifications": {
              "$typeSchema": "/schema/public/Platform.Schema.SchemaSchemas.v1/SchemaSchema_Type_Array-CheckBoxes-TitleMap",
              "type": "array",
              "arrayStart": "FormDefault",
              "format": "checkboxes",
              "uniqueItems": false,
              "dynamicColumns": 1,
              "items": {
                "type": "string",
                "titleMap": [
                  {
                    "name": "Main Contact",
                    "value": "Main"
                  },
                  {
                    "name": "Billing",
                    "value": "Billing"
                  },
                  {
                    "name": "Technical Requests/Issuses",
                    "value": "Technical"
                  },
                  {
                    "name": "Platform Maintenance",
                    "value": "Maintenance"
                  },
                  {
                    "name": "Platform Outage",
                    "value": "Outage"
                  }
                ]
              }
            }

 * The output of this form element is a string array. Ex: ["Main","Technical","Maintenance",...]
 */

Vue.component("sform-checkboxes-array", {
    mixins: [BaseComponent],
    data: () => ({
        items: null,
        lookupurl: null,
        lookupraw: null,
        displayexpr: null,
        valueexpr: null,
        prependIconExpr: null,
        appendIconsExpr: null,
        isdisabledExpr: null,
        readOnlyEval: null,
    }),
    props: {},
    created() {
        if (
            !this.element.formatData &&
            this.element.items &&
            this.element.items.length > 0 &&
            this.element.items[0].titleMap
        ) {
            this.items = this.element.items[0].titleMap;
        } else if (
            !this.element.formatData &&
            this.element.items &&
            this.element.items.length > 0 &&
            this.element.items[0].enum
        ) {
            this.items = this.element.items[0].enum.map((i) => ({ name: i, value: i }));
        } else if (this.element.formatData && this.element.formatData.LookupType == 'URL' && this.element.formatData.LookupURL) {
            // Compile url, create functions for mapping item name and item value
            this.lookupurl = utils.compile(this, this.element.formatData.LookupURL);
        } else if (this.element.formatData && this.element.formatData.LookupInterpolatedExpression) {
            this.lookupraw = utils.compileExpression(this, this.element.formatData.LookupInterpolatedExpression);
        }

        if (this.element.formatData) {    
            if (this.element.formatData.DisplayType == "Expression" && this.element.formatData.DisplayExpression)
                this.displayexpr = new Function(
                    "context",
                    this.element.formatData.LookupModelAs,
                    "util",
                    "with(context) return " + this.element.formatData.DisplayExpression + ";"
                );

            if (this.element.formatData.ValueType == "Expression" && this.element.formatData.ValueExpression)
                this.valueexpr = new Function(
                    "context",
                    this.element.formatData.LookupModelAs,
                    "util",
                    `with(context) return ${this.element.formatData.ValueExpression};`
                );

            if (this.element.formatData.PrependIconExpression)
                this.prependIconExpr = new Function(
                    "context",
                    this.element.formatData.LookupModelAs,
                    "util",
                    `with(context) return ${this.element.formatData.PrependIconExpression};`
                );

            if (this.element.formatData.AppendIconsExpression)
                this.appendIconsExpr = new Function(
                    "context",
                    this.element.formatData.LookupModelAs,
                    "util",
                    `with(context) return ${this.element.formatData.AppendIconsExpression};`
                );
            if (this.element.formatData.IsDisabledExpression)
                this.isdisabledExpr = new Function(
                    "context",
                    this.element.formatData.LookupModelAs,
                    "util",
                    `with(context) return ${this.element.formatData.IsDisabledExpression};`
                );
        }

        if (this.lookupurlvalue)
            this.lookupurlvalue_watch$ = this.$watch(
                function () {
                    return this.lookupurlvalue;
                },
                function (newval, oldval) {
                    this.Refresh();
                }
            );
    },
    watch: {
        lookuprawvalue: function () {
            if (this.element.formatData.WatchForChanges) this.Refresh();
        },
    },
    async mounted() {
        await this.Refresh();

        this.loadComplete();
    },
    computed: {
        readOnly() {
            try {
                if(this.readOnlyEval === null && this.element.readonly)
                    this.readOnlyEval = utils.compile(this, this.element.readonly);
                if(this.readOnlyEval)
                    return JSON.parse(utils.evaluate(this.readOnlyEval, this));
                return false;
            }
            catch (e) {
                utils.warn('ReadOnly could not evaluate expression: ' + this.element.readonly + '; ' + e);
                return false;
            }
        },
        lookupurlvalue: function() {
            if (this.lookupurl)
                try {
                    return utils.evaluate(this.lookupurl, this, false, null, true);
                }
                catch (e) {
                    utils.warn(`LookupList lookupurl could not be evaluated: ${this.lookupurl.code}`, e);
                    return '';
                }
            else
                return '';
        },
        lookuprawvalue: function() {
            return this.lookupraw ? utils.evaluate(this.lookupraw, this) : [];
        },
        items_translated: function() {
            if (this.items)
                return this.items.map((item) => {
                    let name;
                    let value;
                    let icon;
                    let icons;
                    let isDisabled;

                    // If using a LookupURL, do the name/value transformations
                    if (
                        this.element.formatData &&
                        (this.element.formatData.LookupURL || this.element.formatData.LookupInterpolatedExpression)
                    ) {
                        name = this.displayexpr
                            ? this.displayexpr(this._self, item, careHelpfulFunctions)
                            : item[this.element.formatData.DisplayField];
                        value = this.valueexpr
                            ? this.valueexpr(this._self, item, careHelpfulFunctions)
                            : item[this.element.formatData.ValueField];
                    } else {
                        name = item.name;
                        value = item.value;
                    }

                    if (this.prependIconExpr) {
                        icon = this.prependIconExpr(this._self, item, careHelpfulFunctions);
                    }

                    if (this.appendIconsExpr) {
                        icons = this.appendIconsExpr(this._self, item, careHelpfulFunctions);
                    }

                    if (this.isdisabledExpr) {
                        isDisabled = this.isdisabledExpr(this._self, item, careHelpfulFunctions);
                    }

                    return {
                        name: name,
                        value: value,
                        icon: icon,
                        icons: icons,
                        isDisabled: isDisabled
                    };
                });
            else return [];
        },
    },
    methods: {
        async Refresh() {
            if (this.lookupurl) this.items = await utils.api.get(this.lookupurlvalue);
            else if (this.lookupraw) this.items = this.lookuprawvalue;
        },
        getItemValue(key) {
            return this.itemvalue ? this.itemvalue.some((v) => v == key) : false;
        },
        setItemValue(key, enabled) {
            let tmp = this.itemvalue;
            if (!tmp) tmp = [];

            // Look for the item in the existing array
            const idx = tmp.findIndex((i) => i == key);
            if (enabled) {
                // If we are supposed to add, only add if it isn't already there
                if (idx < 0) tmp.push(key);
            } else {
                // If we are supposed to remove, only remove if it is there
                if (idx >= 0) tmp.splice(idx, 1);
            }

            // Most often, this isn't necessary since we are modifying the referenced array.
            // But in the case where the original value is null, we have to set it.
            this.sync(tmp);
        },
        isDisabledItem(key) {
            if (key != null && key == 'true')
            {
                return true;
            }
            return false;
        },
    },
    render(h) {
        const elements = this.items_translated.map((item) => {
            let scopedSlots = {
                message: ({ message }) => {
                    if (this.element.formatData?.Translate)
                        return <translation-container context={this} value={message}></translation-container>
                    else
                        return message;
                },
            };

            let slots = [];

            if (this.element.formatData?.Translate) {
                slots = [
                    <translation-container slot="label" context={this} value={item.name}></translation-container>,
                ];
            }
            else {
                slots = [
                    item.name,
                ];
            }

            if (item.icons) {
                slots.push(<div slot="append" style={{ width: 'max-content' }}>{item.icons.map(i => <v-icon dense>{i}</v-icon>)}</div>);
            }
            
            if (item.icon) {
                slots.push(<v-icon slot="prepend" dense>{item.icon}</v-icon>);
            }
            //comment
            return (
                <v-checkbox
                    class="caption pa-0 ma-0"
                    outlined
                    single-line
                    dense
                    hide-details
                    style={{ width: "100%" }}
                    label={item.name}
                    input-value={this.getItemValue(item.value)}
                    on-change={(v) => this.setItemValue(item.value, v == true)}
                    scopedSlots={scopedSlots}
                    hint={this.hintText}
                    on-blur={this.onBlur}
                    on-focus={this.onFocus}
                    persistent-hint={appSettings.DebugTranslationPrefixSetting}
                    disabled={this.isDisabledItem(item.isDisabled) || this.readOnly}
                >
                    {slots}
                </v-checkbox>
            );
        });

        if (this.element.schema.displayFormat == "Grid" && this.element.schema.displayColumns > 0) {
            var rows = [];
            var template = [];

            for (var i = 0; i < this.element.schema.displayColumns; i++) {
                template.push(undefined);
            }

            for (var i = 0; i < elements.length; i++) {
                var colIndex = i % this.element.schema.displayColumns;

                if (colIndex == 0) { // new row so make a new row
                    rows.push([...template]);
                }

                rows[rows.length - 1][colIndex] = elements[i];
            }

            var rowMapped = rows.map((rowElements) => {
                var chks = rowElements.map((chkbx) => {
                    return (<v-col>{chkbx}</v-col>);
                });

                return (<v-row no-gutters>{ chks }</v-row>);
            });

            return (
                <v-sheet elevation={0} outlined rounded class="mt-2 mb-2 pa-3">
                    {this.Title}
                    <br />
                    {rowMapped}
                </v-sheet>
            );
        } else {       
            return (
                <v-sheet elevation={0} outlined rounded class="mt-2 mb-2 pa-3">
                    {this.Title}
                    <br />
                    {elements}
                </v-sheet>
            );
        }
    },
});
