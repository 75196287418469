<template>
    <div :style="style" v-if="todisplay" v-show="isvisible" :class="{ 'c-ApexGeneralChart': true, [`c-name-${this.name || 'unnamed'}`]: true }">
        <apexchart :type="chartType"
                   height="100%"
                   width="100%"
                   :series="chartData"
                   :options="chartOptions">

        </apexchart>
    </div>
</template>

<script>
import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import utils from '../../Shared/utils.jsx';
import careHelpfulFunctions from '../careHelpfulFunctions.jsx';

export default {
    name: "ApexGeneralChart",
    mixins: [BaseComponent],
    components: {
    },
    data: function () {
        return {
            datamodel: null,
            optionsmodel: null,
        }
    },
    created() {
        if (this.controlData.DataType === 'URL' && this.controlData.DataURL) {
            this.dataurlexpn = utils.compile(this, this.controlData.DataURL);
        }
        if (this.controlData.OptionsType === 'URL' && this.controlData.OptionsURL) {
            this.optionsurlexpn = utils.compile(this, this.controlData.OptionsURL);
        }
    },
    //Created Replaced with preRenderComplete
    computed: {
        style() {
            return {
                ...utils.resolveStyleHints(this.styleHints, this),
                ...this.sizeStyle,
                display: 'flex',
                flexDirection: 'column',
                overflow: "none"
            };
        },

        labelFormatter: function () {
            const self = this;

            return function (val, opts) {
                // Causes the actual values to be shown in each pie slice where
                // the val parameter is the percentage, which is less useful.
                switch (self.controlData.LabelFormat) {
                    case 'time': return careHelpfulFunctions.durationSecondsAsString(opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex]);
                    case 'percentage': return opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].toFixed(0) + '%';
                    case 'label': return self.model.labels[opts.seriesIndex];
                    case 'value': return opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex];
                    case 'label value': return self.model.labels[opts.seriesIndex] + ' ' + opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex];
                    case 'series value': return opts.w.config.series[opts.seriesIndex].name + ' ' + opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex];
                    case 'series value time': return opts.w.config.series[opts.seriesIndex].name + ' - ' + careHelpfulFunctions.durationSecondsAsString(opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex]);
                    case 'none': return '';
                }
                return opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex];
            };
        },
        legendFormatter: function () {
            const self = this;

            return function (val, opts) {
                // Causes the actual values to be shown in each pie slice where
                // the val parameter is the percentage, which is less useful.
                switch (self.controlData.LegendFormat) {
                    case 'time': return careHelpfulFunctions.durationSecondsAsString(opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex]);
                    case 'percentage': return opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].toFixed(0) + '%';
                    case 'label': return self.model.labels[opts.seriesIndex];
                    case 'value': return opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex];
                    case 'label value': return self.model.labels[opts.seriesIndex] + ' ' + opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex];
                    case 'series value': return opts.w.config.series[opts.seriesIndex].name + ' ' + opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex];
                    case 'series value time': return opts.w.config.series[opts.seriesIndex].name + ' - ' + careHelpfulFunctions.durationSecondsAsString(opts.w.config.series[opts.seriesIndex].data[0]);
                    case 'none': return '';
                }
                return opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex];
            };
        },
        tooltipFormatter: function () {
            const self = this;

            return function (val, opts) {
                // Causes the actual values to be shown in each pie slice where
                // the val parameter is the percentage, which is less useful.
                switch (self.controlData.ToolTipFormat) {
                    case 'time': return careHelpfulFunctions.durationSecondsAsString(opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex]);
                    case 'percentage': return opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].toFixed(0) + '%';
                    case 'label': return self.model.labels[opts.seriesIndex];
                    case 'value': return opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex];
                    case 'label value': return self.model.labels[opts.seriesIndex] + ' ' + opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex];
                    case 'series value': return opts.w.config.series[opts.seriesIndex].name + ' ' + opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex];
                    case 'series value time': return opts.w.config.series[opts.seriesIndex].name + ' - ' + careHelpfulFunctions.durationSecondsAsString(opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex]);
                    case 'none': return '';
                }
                return opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex];
            };
        },

        chartType: function () {
            // bar, line, etc.
            return this.controlData.ChartType;
        },
        chartData: function () {
            switch (this.controlData.DataType) {
                case 'Raw':
                    return this.controlData.DataRaw;

                case 'RawInterpolated':
                    if (!this.datarawexpn)
                        this.datarawexpn = utils.compileObject(this, this.controlData.DataRaw);

                    return this.datarawexpn ? utils.evaluateObject(this.datarawexpn, this) : null;

                case 'URL':
                    return this.datamodel;

                default:
                    return null;
            }
        },
        chartOptions() {
            switch (this.controlData.OptionsType) {
                case 'Raw':
                    return this.controlData.OptionsRaw;

                case 'RawInterpolated':
                    if (!this.optionsrawexpn)
                        this.optionsrawexpn = utils.compileObject(this, this.controlData.OptionsRaw);

                    return this.optionsrawexpn ? utils.evaluateObject(this.optionsrawexpn, this) : null;

                case 'URL':
                    return this.optionsmodel;

                default:
                    return null;
            }
        },
    },
    methods: {
        async preRenderComplete() {
            this.finishRenderHandler(this);
            this.Refresh();
        },
        async Refresh() {
            if (this.controlData.OptionsType === 'URL' && this.optionsurlexpn) {
                const optionsurl = utils.evaluate(this.optionsurlexpn, this);
                this.optionsmodel = await utils.api.get(optionsurl);
            }
            if (this.controlData.DataType === 'URL' && this.dataurlexpn) {
                const dataurl = utils.evaluate(this.dataurlexpn, this);
                this.datamodel = await utils.api.get(dataurl);
            }
        },
    },
};
</script>

<style scoped>
</style>