import Vue from 'vue';
import formInput from './input.jsx';
//import formNumber from './number.jsx';
//import formCheckbox from './checkbox.jsx';
import formSelect from './select.jsx';
import formAnyOf from './anyof.jsx';
import formArray from './array.jsx';
//import schemaProperties from './SchemaProperties.jsx';
import utils from '../../../../Shared/utils.jsx';
import methods from '../../../../Shared/methods';

function toggleVisible(cmodel, key)
{
    cmodel[key] = cmodel[key] === 'true' ? 'false' : 'true';
    utils.log('Key : ' + key + ' = ' + cmodel[key]);
}

function getFormElements(h, control, schema, element_model, elements, root, child)
{
    for (let key in schema.properties) {
        if (key.substr(0, 1) == '$') continue;
        const value = element_model[key];
        let element = utils.schema.resolve_Of(schema.properties[key]);
        let cmodel = element_model;
        let Tag;

        switch (element.type) {
            case 'string':
                if (('anyOf' in element))
                    Tag = 'form-anyof-dsgn';
                else if (('enum' in element) || ('titleMap' in element))
                    Tag = 'form-select-dsgn';
                else
                    Tag = 'form-input-dsgn';
                break;
            case 'integer':
            case 'number': Tag = 'form-number'; break;
            case 'boolean': Tag = 'form-checkbox'; break;
            case 'array': Tag = 'form-array-dsgn'; break;
            case 'object':
                if (!cmodel[key])
                    Vue.set(cmodel, key, {});

                const visible_key = '$' + key + '_visible';
                if (!cmodel[visible_key])
                    Vue.set(cmodel, visible_key, child <= 0 ? 'true' : 'false');

                const vis = cmodel[visible_key] === 'true';

                elements.push(
                    <tr style={{ backgroundColor: "silver" }} on-click={() => toggleVisible(cmodel, visible_key)}>
                        <td colspan="2">
                            <span style={{ marginLeft: (child * 15) + "px" }}>[{vis ? '-' : '+'}] {element.title || key}</span>
                        </td>
                    </tr>
                );
                if (vis) {
                    if (element.format === 'SchemaProperty')
                        elements.push(
                            <tr>
                                <td colspan="2">
                                    <schema-properties
                                        name={element.title || key}
                                        schemakey={key}
                                        schema={element}
                                        root={root}
                                        cmodel={cmodel}>
                                    </schema-properties>
                                </td>
                            </tr>
                        );
                    else
                        getFormElements(h, control, element, cmodel[key], elements, root, child + 1);
                }
                continue;
            default:
                if ('$typeSchema' in element) {
                    const typeSchema = element.$typeSchema;
                    const parts = typeSchema.split('/');
                    elements.push(<form-typeschema-dsgn name={element.title || key} value={parts[parts.length - 1]}></form-typeschema-dsgn>);
                    continue;
                }

                if ('format' in element)
                    switch (element.format)
                    {
                        case 'TextEditor':
                            elements.push(
                                <tr>
                                    <td style={{ width: "1px" }}>
                                        {element.title || key}<br />
                                    </td>
                                    <td>
                                        <textarea cols="40" on-input={(e) => control.textChanged(key, cmodel, e.target.value)}>
                                            {cmodel[key] ? JSON.stringify(cmodel[key], null, 4) : ''}
                                        </textarea>
                                    </td>
                                </tr>
                            );
                            //elements.push(<input-textarea title={element.title || key} name={key} cmodel={cmodel}></input-textarea>);
                            continue;
                    }

                let type = ('anyOf' in element) ? 'anyOf' :
                    ('allOf' in element) ? 'allOf' :
                        ('oneOf' in element) ? 'oneOf' :
                            ('unknown:' + element.type);

                utils.log('skipping type: ' + type);
                Tag = 'div';
                continue;
        }

        elements.push(
            <Tag
                name={element.title || key}
                schemakey={key}
                schema={element}
                root={root}
                cmodel={cmodel}
                child={child}
                readonly={element.readonly || false}>
            </Tag>
        );
    }
}

Vue.component('property-grid-dsgn', {
    data: () => ({
    }),
    props: {
        name: '',
        schemakey: null,
        root: null,
        schema: null,
        form: null,
        cmodel: null,
        child: 0,
        readonly: false,
        extra: null
    },
    methods: {
        textChanged(prop, cmodel, value) {
            try {
                const obj = JSON.parse(value);
                Vue.set(cmodel, prop, obj);
            }
            catch (e)
            {
                utils.warn('Property grid failed to convert value to object: ' + e);
            }
        }
    },
    render(h) {
        let schema = this.schema;
        let elements = [];
        if (!schema)
            return null;

        schema = utils.schema.resolve_Of(schema);

        if (schema.type !== 'object')
            return <div>Schema must be an object</div>;

        let cmodel = this.cmodel;
        if (this.schemakey !== null && !(typeof this.schemakey === 'undefined'))
            cmodel = this.cmodel[this.schemakey];

        getFormElements(h, this, schema, cmodel, elements, this.root, this.child);
        
        return (
            <table style={{ width: "100%" }}>
                {elements}
            </table>
        );
    }
});