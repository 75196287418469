import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import EventBus from '../event-bus.js';
import utils from '../../Shared/utils.jsx';

Vue.component('realtime-widget-listener', {
    mixins: [BaseComponent],
    data: function () {
        return {
            active_name: null,
            active_args: null,
        }
    },
    props: {},
    computed: {
        rtData: function () {
            return this.rteventsourceexpn ? utils.evaluate(this.rteventsourceexpn, this) : null;
        },
        widgetName: function () {
            return this.widgetnameexpn ? utils.evaluate(this.widgetnameexpn, this) : null;
        },
        filters: function () {
            return this.filtersexpn ? utils.evaluateObject(this.filtersexpn, this) : null;
        },

        //---- Public properties ----//

        ViewName: function () {
            let view = this.active_name;

            if (this.active_args && JSON.stringify(this.active_args) != '{}')
                view += '~!!~' + JSON.stringify(this.active_args);

            return view;
        },
        ViewData: function () {
            return this.rtData.Views[this.ViewName];
        },
        ViewIsReady: function () {
            return !!this.rtData.Views && !!(this.rtData.Views[this.ViewName]);
        },
    },
    created() {
        if (this.controlData.RTEventSource)
            this.rteventsourceexpn = utils.compileExpression(this, this.controlData.RTEventSource);

        if (this.controlData.WidgetName)
            this.widgetnameexpn = utils.compile(this, this.controlData.WidgetName);

        if (this.controlData.Filters)
            this.filtersexpn = utils.compileObject(this, this.controlData.Filters);
    },
    //Mounted Replaced with preRenderComplete
    destroyed() {
        if (this.filters_watch$)
            this.filters_watch$();

        if (this.active_name)
            this.rtData.RemoveView(this.active_name, this.active_args);
    },
    methods: {
        preRenderComplete() {
            this.finishRenderHandler(this);

            this.updateRegistration();

            if (!this.filters_watch$)
                this.filters_watch$ = this.$watch(
                    function (newvalue) {
                        return this.filters;
                    },
                    function (val, oldval) {
                        this.updateRegistration();
                    },
                    {
                        deep: true
                    }
                );
        },
        updateRegistration() {
            if (this.active_name)
                this.rtData.RemoveView(this.active_name, this.active_args);

            if (this.widgetName)
                this.rtData.AddView(this.widgetName, this.filters);

            this.active_name = this.widgetName;
            this.active_args = this.filters;
        },
    },
    render(h) {
        return null;
    }
});