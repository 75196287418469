import Vue from 'vue';
import HorizontalStack from './HorizontalStack.jsx';
import VerticalStack from './VerticalStack.jsx';
import BasicButton from './BasicButton.jsx';
import Text from './Text.jsx';
import Icon from './Icon.jsx';
//import SelectList from './SelectList.jsx';
import BasicForm from './BasicForm.jsx';
import BasicGrid from './BasicGrid.jsx';
import TreeView from './TreeView.jsx';
import DefaultUnknown from './DefaultUnknown.jsx';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

function flatMap(arr, mapFunc) {
    const result = [];
    for (const [index, elem] of arr.entries()) {
        const x = mapFunc(elem, index, arr);
        // We allow mapFunc() to return non-Arrays
        if (Array.isArray(x)) {
            result.push(...x);
        } else {
            result.push(x);
        }
    }
    return result;
}

function getMenus(menu)
{
    let items = [menu.element];
    for (let i = 0; i < menu.children.length; i++)
        items = [...items, ...getMenus(menu.children[i])];

    return items;
}

function findParentMenu(parent, path)
{
    let targetname = path;
    if (path.includes('::'))
        targetname = path.split('::')[0];

    const menu = parent.find(m => m.name === targetname);
    if (!menu)
        return null;

    if (path.includes('::')) {
        const index = path.indexOf('::');
        targetname = path.substr(index + 2);
        return findParentMenu(menu.children, targetname);
    }
    return menu;
}

Vue.component('background-service-dsgn', {
    data: function () {
        return {
            childMenus: null,
        }
    },
    created() {
    },
    computed: {
        Root: function () {
            return this.root._self;
        },
        selected$: function () {
            return this.Root.SelectedNode && this.Root.SelectedModel == this.designmodel;
        }
    },
    methods: {
        ...methods,
        handleClick(e) {
            this.Root.SelectNode(this);
            e.cancelBubble = true;
            e.stopPropagation();
        },
        DesignModel: function () {
            return this.designmodel;
        },
        jumpToMenu(e, menu) {
            this.childMenus = null;
            this.Root.ExternalNavigate('Apps/UI/MenuItem/' + menu._id);

            e.cancelBubble = true;
            e.stopPropagation();
        }
    },
    props: {
        name: '',
        root: null,
        designmodel: null,
        controlData: {}
    },
    render(h) {
        let icontitle = (
                <span style={{ fontSize: "x-large" }}>
                    {this.designmodel.MenuItemData.Title}
                </span>
            );

        if (!this.controlData || !this.controlData.Controls)
            return null;

        let items = [];
        for (let i = 0; i < this.controlData.Controls.length; i++)
        {
            const control = this.controlData.Controls[i];
            let DynamicControl = utils.getDynamicComponent(h, control);

            if (!DynamicControl)
                DynamicControl = 'default-unknown';

            DynamicControl += '-dsgn';

            items.push(
                <DynamicControl type={control.ControlType} name={control.ControlData ? control.ControlData.Name : null} root={this.root} designmodel={control} controlData={control.ControlData}>
                </DynamicControl>
            );
        }
        return (
            <div
                class={{ 'designer-container': true, selected: this.selected$ }}
                style={{ ...utils.getSize(this.controlData.SizeOptions) }}
                on-click={(e) => this.handleClick(e)}>
                <div class={{ "designer-container-hovering": true, 'designer-container-selected': this.selected$ }}></div>
                {icontitle}
                {this.name}<br />
                {items}
            </div>
        );
    }
});