import Vue from 'vue';
import utils from '../../Shared/utils.jsx';
import methods from '../../Shared/methods';
import api from '@/Services/api';

Vue.component('document-bootstrap', {
    data: function () {
        return {
            urlexpn: null,
            ready: false,
        }
    },
    async created() {
        this.urlexpn = utils.compile(this, this.controlData.BootstrapURL);
    },
    async mounted() {
        try {
            const url = utils.evaluate(this.urlexpn, this);
            
            if (this.controlData.Type && this.controlData.Type == 'Schema') {

                const schemaAge = new Date().getTime() - (utils.cache().schema_date?.getTime() || 0);
                const cacheExpiry = 60 * 1000;
                if(schemaAge > cacheExpiry){
                    // Load all the schema documents (required by the document editor)
                    let apiRequest = {
                        method: 'GET',
                        url: url || 'Apps/Schema/Bootstrap/Load',
                        doNotUseWebsocket: true,
                        flatten: true
                    };
                    const s = await api.apiRequest(apiRequest);
                    if (s)
                        await utils.setSchemaBootstrap(s);
                    else
                        alert('Bad response');
                        
                    utils.log(`DocumentBootstrap Schema finished, loaded ${Object.keys(s || {}).length} record(s) from API`);
                }else{
                    utils.log(`DocumentBootstrap Schema finished, loaded ${Object.keys(utils.cache().schema || {}).length} record(s) from cache`);
                }

                this.ready = true;
            }
            else if (!this.controlData.Type || this.controlData.Type == 'MenuItems') {
                // Load the menu documents (based upon user and call center, from installed modules)
                let apiRequest = {
                    method: 'GET',
                    url: url,
                    doNotUseWebsocket: true,
                    flatten: true
                };
                const res = await api.apiRequest(apiRequest);

                if (res.Success)
                    utils.global.SetAllDocuments(res.Records);
                else
                    alert('Bad response, JSON: ' + JSON.stringify(res));

                utils.log('DocumentBootstrap MenuItems finished, loaded ' + Object.keys(utils.document_cache.menus).length);
                this.ready = true;
            }
            else if (this.controlData.Type == 'SettingsMenuList') {
                // Load the menu documents (used by the user profile (Settings) page)
                let apiRequest = {
                    method: 'GET',
                    url: url,
                    doNotUseWebsocket: true,
                    flatten: true
                };
                const res = await api.apiRequest(apiRequest);

                if (res && Array.isArray(res))
                    utils.global.SetAllDocuments(res);
                else
                    alert('Bad response, JSON: ' + JSON.stringify(res));

                utils.log('DocumentBootstrap SettingsMenuList finished, loaded ' + Object.keys(utils.document_cache.menus).length);
                this.ready = true;
            }

            this.$emit('finished-render', this);
        }
        catch (e) {
            utils.warn('DocumentBootstrap failed to evaluate BootstrapURL ' + this.controlData.BootstrapURL + ': ' + e);
        }
    },
    computed: {
        ...utils.global,
        Root: function () {
            return this.root._self;
        },
        IsReady: function () {
            return this.ready;
        }
    },
    methods: {
        ...methods,
    },
    props: {
        name: '',
        root: null,
        parentType: '',
        controlData: {}
    },
    render(h) {
        return null;
    }
});